import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CurrencyIcon } from '@spectrocoin/sc-currencies';
import { RootState } from '../../../../redux/Store';

import styles from './AutoconvertField.module.scss';
import TestIds from '../../../../test/TestIds';

type AutoconvertFieldProps = {
	accountId: string | null;
};

const AutoconvertField: FC<AutoconvertFieldProps> = ({ accountId }) => {
	const { wallets } = useSelector((state: RootState) => state.AccountsState);

	const autoConvertCurrency = useMemo(() => {
		return wallets?.find((x) => x.id === accountId);
	}, [accountId, wallets]);

	return (
		<div className={styles.container} data-cy={TestIds.FieldAutoconvert}>
			{!autoConvertCurrency && <span>-</span>}
			{autoConvertCurrency && (
				<>
					<CurrencyIcon
						className={styles.icon}
						currencyType={autoConvertCurrency.currencyCode}
					/>
					{autoConvertCurrency.currencyCode}
				</>
			)}
		</div>
	);
};

export default AutoconvertField;
