/* eslint-disable import/prefer-default-export */
import { AxiosError } from 'axios';
import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RemoteError } from '../../../interfaces/RemoteData';
import { getMerchantsOrderPaymentUrl } from '../../endpoints';
import { AppDispatch, RootState } from '../../Store';
import { TwoFaCode } from '../../TwoFaState/TwoFaTypes';
import OrderPaymentSelectors from './OrderPaymentSelectors';
import {
	OrderPaymentActionType,
	OrderPaymentFetchOrderPendingAction,
	OrderPaymentFetchOrderRejectedAction,
	OrderPaymentFetchOrderFulfilledAction,
	OrderPaymentSetFormAction,
	PaymentOrder,
	OrderPaymentForm,
	OrderPaymentPayOrderFulfilledAction,
	OrderPaymentPayOrderPendingAction,
	OrderPaymentPayOrderRejectedAction,
} from './OrderPaymentTypes';
import axiosInstance from '../../../helpers/axiosInstance';

const fetchOrderPending: ActionCreator<OrderPaymentFetchOrderPendingAction> = () => ({
	type: OrderPaymentActionType.FETCH_OREDER_PENDING,
});

const fetchOrderRejected: ActionCreator<OrderPaymentFetchOrderRejectedAction> = (
	error?: RemoteError
) => ({
	type: OrderPaymentActionType.FETCH_OREDER_REJECTED,
	payload: error,
});

const fetchOrderFulfilled: ActionCreator<OrderPaymentFetchOrderFulfilledAction> = (
	order: PaymentOrder
) => ({
	type: OrderPaymentActionType.FETCH_OREDER_FULFILLED,
	payload: order,
});

const fetchOrder = (id: string) => async (dispatch: AppDispatch) => {
	dispatch(fetchOrderPending(id));

	await axiosInstance
		.get<PaymentOrder>(getMerchantsOrderPaymentUrl(id))
		.then(({ data }) => {
			dispatch(fetchOrderFulfilled(data));
		})
		.catch((e: AxiosError) => {
			dispatch(fetchOrderRejected(e.response?.data));
		});
};

const setForm: ActionCreator<OrderPaymentSetFormAction> = (form: Partial<OrderPaymentForm>) => ({
	type: OrderPaymentActionType.SET_FORM,
	payload: form,
});

const payPending: ActionCreator<OrderPaymentPayOrderPendingAction> = () => ({
	type: OrderPaymentActionType.PAY_OREDER_PENDING,
});

const payRejected: ActionCreator<OrderPaymentPayOrderRejectedAction> = (error?: RemoteError) => ({
	type: OrderPaymentActionType.PAY_OREDER_REJECTED,
	payload: error,
});

const payFulfilled: ActionCreator<OrderPaymentPayOrderFulfilledAction> = (order: PaymentOrder) => ({
	type: OrderPaymentActionType.PAY_OREDER_FULFILLED,
	payload: order,
});

const pay = (id: string): ThunkAction<Promise<void>, RootState, undefined, any> => async (
	dispatch: AppDispatch,
	getState
) => {
	const state = getState();
	const { walletId } = OrderPaymentSelectors.getForm(state);

	dispatch(payPending(id));

	await axiosInstance
		.post<PaymentOrder>(getMerchantsOrderPaymentUrl(id), {
			accountId: walletId,
		})
		.then(({ data }) => {
			dispatch(payFulfilled(data));
		})
		.catch((e) => {
			if (e.response?.data?.errorCode !== TwoFaCode.TWO_FA_REQUIRED) {
				dispatch(payRejected(e.response?.data));
			}
		});
};

export default {
	setForm,
	fetchOrder,
	pay,
	payFulfilled,
	payRejected,
};
