import { FC } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import styles from './StatusSelect.module.scss';
import { PayoutStatus } from '../../../../redux/MerchantsState/CryptoPayouts/PayoutsData/PayoutsDataTypes';
import statusMsg from '../../../../messages/status.messages';

interface Props {
	isOpen: boolean;
	onChange: (status: PayoutStatus) => void;
	options: PayoutStatus[];
}

const StatusSelect: FC<Props> = ({ isOpen, onChange, options }) => {
	const { formatMessage } = useIntl();

	return (
		<ul
			className={classNames(styles.dropdown, {
				[styles.open]: isOpen,
			})}
		>
			<li onClick={() => onChange(PayoutStatus.NEW)}>
				<div
					className={classNames(styles.checkbox, {
						[styles.active]: options.includes(PayoutStatus.NEW),
					})}
				>
					{options.includes(PayoutStatus.NEW) && (
						<FontAwesomeIcon icon={faCheck} className={styles.icon} />
					)}
				</div>
				<div className={classNames(styles.option, styles.NEW)}>
					{formatMessage(statusMsg.NEW)}
				</div>
			</li>
			<li onClick={() => onChange(PayoutStatus.PENDING)}>
				<div
					className={classNames(styles.checkbox, {
						[styles.active]: options.includes(PayoutStatus.PENDING),
					})}
				>
					{options.includes(PayoutStatus.PENDING) && (
						<FontAwesomeIcon icon={faCheck} className={styles.icon} />
					)}
				</div>
				<div className={classNames(styles.option, styles.PENDING)}>
					{formatMessage(statusMsg.PENDING)}
				</div>
			</li>
			<li onClick={() => onChange(PayoutStatus.PAID)}>
				<div
					className={classNames(styles.checkbox, {
						[styles.active]: options.includes(PayoutStatus.PAID),
					})}
				>
					{options.includes(PayoutStatus.PAID) && (
						<FontAwesomeIcon icon={faCheck} className={styles.icon} />
					)}
				</div>
				<div className={classNames(styles.option, styles.PAID)}>
					{formatMessage(statusMsg.PAID)}
				</div>
			</li>
			<li onClick={() => onChange(PayoutStatus.EXPIRED)}>
				<div
					className={classNames(styles.checkbox, {
						[styles.active]: options.includes(PayoutStatus.EXPIRED),
					})}
				>
					{options.includes(PayoutStatus.EXPIRED) && (
						<FontAwesomeIcon icon={faCheck} className={styles.icon} />
					)}
				</div>
				<div className={classNames(styles.option, styles.EXPIRED)}>
					{formatMessage(statusMsg.EXPIRED)}
				</div>
			</li>
			<li onClick={() => onChange(PayoutStatus.FAILED)}>
				<div
					className={classNames(styles.checkbox, {
						[styles.active]: options.includes(PayoutStatus.FAILED),
					})}
				>
					{options.includes(PayoutStatus.FAILED) && (
						<FontAwesomeIcon icon={faCheck} className={styles.icon} />
					)}
				</div>
				<div className={classNames(styles.option, styles.FAILED)}>
					{formatMessage(statusMsg.FAILED)}
				</div>
			</li>
			<li onClick={() => onChange(PayoutStatus.CANCELLED)}>
				<div
					className={classNames(styles.checkbox, {
						[styles.active]: options.includes(PayoutStatus.CANCELLED),
					})}
				>
					{options.includes(PayoutStatus.CANCELLED) && (
						<FontAwesomeIcon icon={faCheck} className={styles.icon} />
					)}
				</div>
				<div className={classNames(styles.option, styles.CANCELLED)}>
					{formatMessage(statusMsg.CANCELLED)}
				</div>
			</li>
		</ul>
	);
};

export default StatusSelect;
