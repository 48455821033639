import { defineMessages } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router';
import { useState } from 'react';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import onlineTransactionsImgPath from './images/enableonlinetransactions.svg';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import styles from './OnlineTransactions.module.scss';
import CardStatus, { CardStatusEnum } from '../../Shared/CardStatus/CardStatus';

const messages = defineMessages({
	title: {
		id: 'onlineTransactions.title',
		defaultMessage: 'Enable online transactions',
	},
	post: {
		id: 'onlineTransactions.post',
		defaultMessage: 'Do you want to enable online transactions?',
	},
	yes: {
		id: 'onlineTransactions.yes',
		defaultMessage: 'Yes',
	},
	no: {
		id: 'onlineTransactions.no',
		defaultMessage: 'No',
	},
	back: {
		id: 'cardActivate.back',
		defaultMessage: 'Back to cards',
	},
	successTitle: {
		id: 'cardActivate.successTitle',
		defaultMessage: 'Activation successful',
	},
	successPost: {
		id: 'cardActivate.successPost',
		defaultMessage: 'Now you can enjoy your new SpectroCoin card!',
	},
});

const OnlineTransactions = () => {
	const { push } = useHistory();
	const { url } = useRouteMatch();
	const [isSuccess, setIsSuccess] = useState<boolean>(false);

	const handleButtonClick = (param: boolean) => {
		if (!param) return setIsSuccess(true);
		return push(url.replace('enable-online-transactions', 'online-transactions-password'));
	};

	return (
		<WhiteContainer>
			{isSuccess ? (
				<CardStatus
					status={CardStatusEnum.COMPLETED}
					title={messages.successTitle}
					text={messages.successPost}
					link="history"
				/>
			) : (
				<>
					<InfoHead
						title={messages.title}
						img={onlineTransactionsImgPath}
						text={messages.post}
					/>
					<Button
						type={ButtonType.BUTTON}
						text={messages.yes}
						className={styles.button}
						onClick={() => handleButtonClick(true)}
					/>
					<Button
						type={ButtonType.BUTTON}
						buttonStyle={ButtonStyle.BORDERLESS}
						text={messages.no}
						className={styles.button}
						onClick={() => handleButtonClick(false)}
					/>
				</>
			)}
		</WhiteContainer>
	);
};

export default OnlineTransactions;
