import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import {
	GetCurrentCard,
	isCardFunctionsEnabled,
	isCardOnHold,
	isCardRha,
} from '../../../helpers/cardsHelper/cardsHelper';
import { RootState } from '../../../redux/Store';
import { CardStatusType, CardType } from '../../../redux/CardsState/CardsTypes';
import {
	toggleCardControl,
	toggleCardsInfoBanner,
	toggleCardsSidebar,
	toggleMarketingSlider,
} from '../../../redux/AppState/AppActions';
import BalanceBlock, { BalanceBlockType } from '../../../components/BalanceBlock/BalanceBlock';
import LinkedWallets from './LinkedWallets/LinkedWallets';
import BackButton from '../../../components/BackButton/BackButton';
import ViewCardDetails from './ViewCardDetails/ViewCardDetails';
import ViewCardPin from './ViewCardPin/ViewCardPin';
import CardSecurity from './CardSecurity/CardSecurity';
import LockCard from './LockCard/LockCard';
import ReportCard from './ReportCard/ReportCard';
import NewCard from './NewCard/NewCard';
import Load from './Load/Load';
import Unload from './Unload/Unload';
import CardsHistory from './CardsHistory/CardsHistory';
import CardsActivate from './CardsActivate/CardsActivate';
import OnlineTransactions from './OnlineTransactions/OnlineTransactions';
import OnlineTransactionsPassword from '../Shared/OnlineTransactionsPassword/OnlineTransactionsPassword';

const messages = defineMessages({
	back: {
		id: 'cardsList.backToCards',
		defaultMessage: 'Back to cards',
	},
});

const CardsAccount = () => {
	const { locale } = useIntl();
	const card = GetCurrentCard();
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { url } = useRouteMatch();
	const { isMobile, isTablet, showCardControl: showBalance } = useSelector(
		(state: RootState) => state.AppState
	);

	useEffect(() => {
		const currentPathname = pathname.split('/')[pathname.split('/').length - 1];

		const showCardControl = currentPathname === 'history' ? true : !isTablet;

		dispatch(toggleCardsSidebar(true));
		dispatch(toggleMarketingSlider(false));
		dispatch(toggleCardsInfoBanner(false));
		dispatch(toggleCardControl(showCardControl));
	}, [dispatch, isTablet, url, pathname]);

	if (!card) {
		return null;
	}

	return (
		<>
			{!isMobile && !isTablet && (
				<BackButton link={`/${locale}/cards`} text={messages.back} />
			)}
			<>
				{card.status !== CardStatusType.NOT_ACTIVATED && !isMobile && showBalance && (
					<BalanceBlock
						availableBalance={card.accountBalance}
						currencyCode={CurrencyEnum.EUR}
						id={card.id}
						type={BalanceBlockType.CARD}
						isCardOptionsEnabled={card.status === CardStatusType.ACTIVE}
						isRhaTooltipVisible={isCardRha(card.cardType)}
					/>
				)}
				{!isMobile && showBalance && isCardRha(card.cardType) && (
					<LinkedWallets id={card.id} />
				)}
				<Route exact path={`${url}/history`} component={CardsHistory} />
				<Route exact path={`${url}/new-card`} component={NewCard} />
				<Route
					exact
					path={`${url}/enable-online-transactions`}
					component={OnlineTransactions}
				/>
				<Route
					exact
					path={`${url}/online-transactions-password`}
					component={OnlineTransactionsPassword}
				/>
				{!isCardOnHold(card.status) && isCardFunctionsEnabled(card.cardType) && (
					<>
						<Route
							exact
							path={`${url}/view-card-details`}
							component={ViewCardDetails}
						/>
						<Route exact path={`${url}/card-security`} component={CardSecurity} />
					</>
				)}
				{card.type === CardType.PLASTIC && (
					<Route exact path={`${url}/view-card-pin`} component={ViewCardPin} />
				)}
				{!isCardOnHold(card?.status) && (
					<>
						<Route exact path={`${url}/lock-card`} component={LockCard} />
						<Route exact path={`${url}/report-card`} component={ReportCard} />
						<Route exact path={`${url}/load`} component={Load} />
					</>
				)}
				{!isCardOnHold(card?.status) && (
					<Route exact path={`${url}/unload`} component={Unload} />
				)}
				{card.status === CardStatusType.NOT_ACTIVATED && (
					<Route exact path={`${url}/activate`} component={CardsActivate} />
				)}
			</>
		</>
	);
};

export default CardsAccount;
