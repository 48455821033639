/* eslint-disable no-underscore-dangle */
import { useRef } from 'react';
import classNames from 'classnames';
import { useIntl, defineMessages, MessageDescriptor } from 'react-intl';
import CopyToClipboard from 'react-copy-to-clipboard';
import Tooltip from '../Tooltip/Tooltip';
import copyIcon from './Copy.svg';
import styles from './CopyButton.module.scss';
import SvgIcon from '../SvgIcon/SvgIcon';

const messages = defineMessages({
	success: {
		id: 'payments.copied',
		defaultMessage: 'Copied',
	},
});

type Props = {
	text?: MessageDescriptor;
	className?: string;
	iconClassName?: string;
	dataToCopy: string;
	isDisabled?: boolean;
};

const CopyButton = ({ text, dataToCopy, className, iconClassName, isDisabled }: Props) => {
	const { formatMessage } = useIntl();
	const tooltipRef = useRef<Element & { _tippy: any }>(null);

	const onCopy = () => {
		if (isDisabled) return;
		tooltipRef.current?._tippy?.show();
		setTimeout(() => tooltipRef.current?._tippy?.hide(), 1000);
	};

	return (
		<div className={classNames(styles.container, styles.flex, className)}>
			<CopyToClipboard text={dataToCopy} onCopy={() => onCopy()}>
				<div className={styles.flex}>
					<Tooltip
						ref={tooltipRef}
						content={formatMessage(messages.success)}
						trigger="manual"
					>
						<div className={styles.flex}>
							{text ? (
								formatMessage(text)
							) : (
								<SvgIcon
									className={classNames(styles.flex, styles.icon, iconClassName)}
									src={copyIcon}
									data-cy="copyButton"
								/>
							)}
						</div>
					</Tooltip>
				</div>
			</CopyToClipboard>
		</div>
	);
};

export default CopyButton;
