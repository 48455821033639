import { FC, useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import useValidation from '../../../../../hooks/useValidation';
import TestIds from '../../../../../test/TestIds';
import ProjectForm, { useProjectFormValidator } from '../../../Shared/ProjectForm/ProjectForm';
import MerchantsActions from '../../../../../redux/MerchantsState/MerchantsActions';
import Button, { ButtonType } from '../../../../../components/Button/Button';
import PageTitle, { TextPosition } from '../../../Shared/PageTitle/PageTitle';
import messages from '../../../../../redux/MerchantsState/MerchantsMessages';

import styles from './NewMerchantsProjectCreating.module.scss';
import baseMsg from '../../../../../messages/base.messages';
import NewMerchantsProjectContext from '../Context/NewMerchantsProjectContext';
import { RemoteStatus } from '../../../../../interfaces/RemoteData';
import { getMerchantsProjectsUrl } from '../../../../../redux/endpoints';
import {
	Project,
	ProjectsCreateViewState,
} from '../../../../../redux/MerchantsState/MerchantTypes';
import axiosInstance from '../../../../../helpers/axiosInstance';

const NewMerchantsProjectCreating: FC = () => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const { form, action, updateForm, setAction, setState } = useContext(
		NewMerchantsProjectContext
	);

	const {
		name,
		description,
		autoConvert,
		currencies,
		publicKey,
		testStatus,
		isEnabled,
		forVerifiedPayers,
		forPayerFee,
	} = form;

	const onSubmit = useCallback(() => {
		setAction({ status: RemoteStatus.InProgress });

		void axiosInstance
			.post<Project>(getMerchantsProjectsUrl(), {
				description,
				name,
				publicKey,
				testStatus,
				enabled: isEnabled,
				receiveAccountId: autoConvert,
				payCurrencies: currencies,
				forVerifiedPayers,
				forPayerFee,
			})
			.then(({ data }) => {
				dispatch(MerchantsActions.projects.create.projectCreated(data));
				setAction({ status: RemoteStatus.Done, data });
				setState(ProjectsCreateViewState.Success);
			})
			.catch((e) => {
				setState(ProjectsCreateViewState.Error);
				setAction({ status: RemoteStatus.Error, error: e.response?.data });
			})
			.then(() => {
				setAction({ status: RemoteStatus.None });
			});
	}, [
		name,
		description,
		autoConvert,
		currencies,
		publicKey,
		testStatus,
		isEnabled,
		forVerifiedPayers,
		forPayerFee,
	]);

	const validator = useProjectFormValidator();
	const [isValid] = useValidation({ name, description }, validator);

	return (
		<>
			<PageTitle
				title={formatMessage(messages.createNewProject)}
				className={styles.title}
				position={TextPosition.Center}
			/>
			<ProjectForm
				name={name}
				onNameChange={(value) => updateForm({ name: value })}
				description={description}
				onDescriptionChange={(value) => updateForm({ description: value })}
				autoConvert={autoConvert}
				onAutoConvertChange={(value) => updateForm({ autoConvert: value })}
				acceptCurrencies={currencies}
				onAcceptCurrenciesChange={(value) => updateForm({ currencies: value ?? [] })}
				publicKey={publicKey}
				onPublicKeyChange={(value) => updateForm({ publicKey: value })}
				testStatus={testStatus}
				onTestStatusChange={(value) => updateForm({ testStatus: value })}
				isEnabled={isEnabled}
				onIsEnabledChange={(value) => updateForm({ isEnabled: value })}
				forVerifiedPayers={forVerifiedPayers}
				onForVerifiedPayersChange={(value) => updateForm({ forVerifiedPayers: value })}
				forPayerFee={forPayerFee}
				onForPayerFeeChange={(value) => updateForm({ forPayerFee: value })}
			>
				<Button
					data-cy={TestIds.ProjectCreateSubmit}
					isDisabled={action.status === RemoteStatus.InProgress || !name || !isValid}
					className={styles.submit}
					type={ButtonType.BUTTON}
					text={baseMsg.submit}
					onClick={onSubmit}
				/>
			</ProjectForm>
		</>
	);
};

export default NewMerchantsProjectCreating;
