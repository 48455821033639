import { useDispatch } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import useEffectOnce from '../../../hooks/useEffectOnce';
import { toggleCardsSidebar } from '../../../redux/AppState/AppActions';
import { CardType } from '../../../redux/CardsState/CardsTypes';
import CardsLimitReached from './CardsLimitReached/CardsLimitReached';
// import NotAvailableCards from './NotAvailableCards/NotAvailableCards';
import NotEnoughMoney from './NotEnoughMoney/NotEnoughMoney';
import OrderCard from './OrderCard/OrderCard';
import OrderFlow from './OrderFlow/OrderFlow';
import VerificationStatus from './VerificationStatus/VerificationStatus';

const OrderPage = () => {
	const { url } = useRouteMatch();
	const dispatch = useDispatch();

	useEffectOnce(() => {
		dispatch(toggleCardsSidebar(false));
	});

	return (
		<div>
			<Route exact path={url} component={OrderCard} />
			<Route exact path={`${url}/verify`} component={VerificationStatus} />
			<Route exact path={`${url}/plastic`}>
				<OrderFlow type={CardType.PLASTIC} />
			</Route>
			<Route exact path={`${url}/virtual`}>
				<OrderFlow type={CardType.VIRTUAL} />
			</Route>
			<Route exact path={`${url}/rhaPlastic`}>
				<OrderFlow type={CardType.PLASTIC} isRha />
			</Route>
			<Route exact path={`${url}/rhaVirtual`}>
				<OrderFlow type={CardType.VIRTUAL} isRha />
			</Route>
			<Route exact path={`${url}/not-enough-funds`} component={NotEnoughMoney} />
			<Route exact path={`${url}/cards-limit-reached`} component={CardsLimitReached} />
			{/* <Route exact path={`${url}/not-available`} component={NotAvailableCards} /> */}
		</div>
	);
};

export default OrderPage;
