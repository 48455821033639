import { Fragment, useMemo } from 'react';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
	formatPrecision,
	isFeeAccountNeeded,
	toDecimal,
} from '../../../../helpers/currencyHelper/currencyHelper';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import { FeeResponse } from '../CryptoWithdrawForm/CryptoWithdrawForm';
import baseMsg from '../../../../messages/base.messages';
import { isAmountGreaterThanZero } from '../../../../helpers/inputValidation/inputValidation';
import { toPlainAmount } from '../../../../helpers/currencyAmountHelper/currencyAmountHelper';
import { RootState } from '../../../../redux/Store';
import { Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import useFormatAmount from '../../../../hooks/useFormatAmount';

interface DataProps {
	[key: string]: string;
}

interface CryptoConfirmProps {
	data: DataProps[];
	currency: CurrencyEnum;
	className: string;
	memoMsg?: string;
	fee?: FeeResponse | null;
	network?: string | null;
	feeAccountId?: string;
}

const CryptoConfirm = ({
	data,
	currency,
	className,
	memoMsg,
	fee,
	network,
	feeAccountId,
}: CryptoConfirmProps): JSX.Element => {
	const { formatMessage } = useIntl();
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const amountFormatter = useFormatAmount();
	const feeAccount = useMemo(
		() => wallets?.find((wallet: Wallet) => wallet.id === feeAccountId),
		[feeAccountId, wallets]
	);

	const fullAmount = toDecimal(
		data?.reduce((accumulator: string, { amount }: DataProps) => {
			if (amount) return toDecimal(accumulator).plus(toPlainAmount(amount, true)).toString();
			return '0';
		}, '0') || '0'
	)
		.plus(toDecimal(fee?.feeAmount || '0'))
		.toString();

	return (
		<>
			{network && <InfoInput className={className} label={baseMsg.network} text={network} />}
			{data.map(({ address, amount, message }: DataProps) => (
				<Fragment key={address}>
					<InfoInput className={className} label={baseMsg.receiver} text={address} />
					{amount && (
						<InfoInput
							className={className}
							label={baseMsg.withdrawAmount}
							text={`${amountFormatter(
								formatPrecision(amount, currency, undefined, true)
							)} ${currency}`}
						/>
					)}
					{message && (
						<InfoInput
							className={className}
							label={
								currency === CurrencyEnum.XEM
									? formatMessage(baseMsg.message)
									: currency === CurrencyEnum.XLM
									? formatMessage(baseMsg.memo)
									: formatMessage(baseMsg.destinationTag)
							}
							text={message}
						/>
					)}
				</Fragment>
			))}
			{fee && currency && isAmountGreaterThanZero(fee?.feeAmount) && (
				<InfoInput
					className={className}
					label={baseMsg.fee}
					text={`${amountFormatter(
						formatPrecision(
							fee?.feeAmount,
							isFeeAccountNeeded(currency) ? feeAccount?.currencyCode! : currency
						)
					)} ${isFeeAccountNeeded(currency) ? feeAccount?.currencyCode : currency}`}
				/>
			)}
			{isAmountGreaterThanZero(fullAmount) &&
				fee?.feeAmount &&
				!isFeeAccountNeeded(currency) && (
					<InfoInput
						className={className}
						label={baseMsg.totalAmountWithFee}
						text={`${amountFormatter(
							formatPrecision(fullAmount, currency)
						)} ${currency}`}
					/>
				)}
			{memoMsg && <InfoInput className={className} label={baseMsg.memo} text={memoMsg} />}
		</>
	);
};

export default CryptoConfirm;
