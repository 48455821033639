/* eslint-disable no-param-reassign */
import { RemoteStatus } from '../../../interfaces/RemoteData';
import {
	DonationActionType,
	DonationFetchButtonFulfilledAction,
	DonationFetchButtonPendingAction,
	DonationFetchButtonRejectedAction,
	DonationFetchCurrenciesFulfilledAction,
	DonationFetchCurrenciesPendingAction,
	DonationFetchCurrenciesRejectedAction,
	DonationPayDonationFulfilledAction,
	DonationPayDonationPendingAction,
	DonationPayDonationRejectedAction,
	DonationSetFormAction,
	DonationState,
} from './DonationTypes';

const initialState: DonationState = {
	button: { status: RemoteStatus.None },
	currencies: { status: RemoteStatus.None },
	form: { amount: '0.00', walletId: null },
	pay: { status: RemoteStatus.None },
};

export default (
	state = initialState,
	action:
		| DonationFetchButtonFulfilledAction
		| DonationFetchButtonPendingAction
		| DonationFetchButtonRejectedAction
		| DonationFetchCurrenciesFulfilledAction
		| DonationFetchCurrenciesPendingAction
		| DonationFetchCurrenciesRejectedAction
		| DonationPayDonationPendingAction
		| DonationPayDonationRejectedAction
		| DonationPayDonationFulfilledAction
		| DonationSetFormAction
): DonationState => {
	switch (action.type) {
		case DonationActionType.FETCH_BUTTON_PENDING:
			return {
				...state,
				button: { status: RemoteStatus.InProgress },
			};
		case DonationActionType.FETCH_BUTTON_REJECTED:
			return {
				...state,
				button: { status: RemoteStatus.Error, error: action.payload },
			};
		case DonationActionType.FETCH_BUTTON_FULFILLED:
			return {
				...state,
				button: {
					status: RemoteStatus.Done,
					data: action.payload,
				},
			};
		case DonationActionType.FETCH_CURRENCIES_PENDING:
			return {
				...state,
				currencies: { status: RemoteStatus.InProgress },
			};
		case DonationActionType.FETCH_CURRENCIES_REJECTED:
			return {
				...state,
				currencies: { status: RemoteStatus.Error, error: action.payload },
			};
		case DonationActionType.FETCH_CURRENCIES_FULFILLED:
			return {
				...state,
				currencies: {
					status: RemoteStatus.Done,
					data: action.payload,
				},
			};
		case DonationActionType.PAY_DONATION_PENDING:
			return {
				...state,
				pay: { status: RemoteStatus.InProgress },
			};
		case DonationActionType.PAY_DONATION_REJECTED:
			return {
				...state,
				pay: { status: RemoteStatus.Error, error: action.payload },
			};
		case DonationActionType.PAY_DONATION_FULFILLED:
			return {
				...state,
				pay: { status: RemoteStatus.Done },
			};
		case DonationActionType.SET_FORM:
			return {
				...state,
				form: {
					...state.form,
					...action.payload,
				},
			};
		default:
			return state;
	}
};
