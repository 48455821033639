/* eslint-disable react/no-array-index-key */
import { ReactNode, useState } from 'react';
import classNames from 'classnames';
import styles from './Accordion.module.scss';
import Arrow from '../../images/expand_triangle.svg';
import Checkbox from '../Checkbox/Checkbox';

interface AccordionSingleItemInterface {
	id?: string;
	title: string;
	answer: ReactNode | string;
	isChecked?: boolean;
}

interface AccordionItemInterface {
	item: AccordionSingleItemInterface;
	isOpened?: boolean;
	hasCheckbox?: boolean;
	onCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AccordionItem = ({
	item,
	isOpened = false,
	hasCheckbox = false,
	onCheckboxChange,
}: AccordionItemInterface) => {
	const [opened, setOpened] = useState<boolean>(isOpened);

	const handleToggle = () => {
		setOpened((prev) => !prev);
	};

	return (
		<li className={classNames(styles.accordionItem, { [styles.active]: opened })}>
			<div className={styles.accordionItemBody}>
				{hasCheckbox && onCheckboxChange && (
					<Checkbox
						id={item.id}
						checked={item.isChecked!}
						onChange={onCheckboxChange}
						className={styles.checkboxLabel}
					/>
				)}
				{item.title && (
					<button
						className={styles.accordionButton}
						onClick={handleToggle}
						data-cy={`${item.title}AccordionButton`}
						type="button"
					>
						{item.title}
						<span>
							<img
								src={Arrow}
								alt="arrow"
								className={classNames(styles.arrow, {
									[styles.toggledArrow]: opened,
								})}
							/>
						</span>
					</button>
				)}
			</div>
			<div className={classNames(styles.answerWrapper, { [styles.opened]: opened })}>
				<div className={styles.answer}>{item.answer}</div>
			</div>
		</li>
	);
};

interface AccordionInterface {
	items: AccordionSingleItemInterface[];
	hasCheckbox?: boolean;
	onCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Accordion = ({ items, hasCheckbox = false, onCheckboxChange }: AccordionInterface) => {
	return (
		<ul className={styles.accordion}>
			{items.map((item, idx) => {
				return (
					<AccordionItem
						item={item}
						key={`accordion-${idx}`}
						data-cy={`accordion-${idx}`}
						isOpened={items.length === 1}
						hasCheckbox={hasCheckbox}
						onCheckboxChange={onCheckboxChange}
					/>
				);
			})}
		</ul>
	);
};

export default Accordion;
