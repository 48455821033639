import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import Seo from '../../components/Seo/Seo';
import TransfersSidebar, { SidebarType } from '../../layout/TransfersSidebar/TransfersSidebar';
import ApiCreate from '../../pages/Settings/Api/Create/Create';
import ApiEdit from '../../pages/Settings/Api/Edit/Edit';
import ApiSettings from '../../pages/Settings/Api/View/View';
import Documents from '../../pages/Settings/Documents/Documents';
import SettingsMessages from '../../redux/SettingsState/SettingsMessages';
import { SettingsTypes } from '../../redux/SettingsState/SettingsTypes';
import { RootState } from '../../redux/Store';
import TransfersOptionContainer from '../TransfersOptionContainer/TransfersOptionContainer';
import styles from './SettingsContainer.module.scss';

const SettingsContainer = () => {
	const { pathname } = useLocation();
	const { replace } = useHistory();
	const { formatMessage, locale } = useIntl();
	const { url }: { url: string } = useRouteMatch();
	const { isMobile, isTablet } = useSelector((state: RootState) => state.AppState);

	const settingsPages = {
		[SettingsTypes.DOCUMENTS]: {
			icon: SettingsTypes.DOCUMENTS,
			link: `${url}/documents`,
			name: formatMessage(SettingsMessages.documents),
			showOptionsCount: false,
		},
		[SettingsTypes.API]: {
			icon: SettingsTypes.API,
			link: `${url}/api`,
			name: formatMessage(SettingsMessages.api).toUpperCase(),
			showOptionsCount: false,
			isExternalLink: false,
		},
	};

	// always redirect to crypto page as default on desktop
	useEffect(() => {
		if (!(isMobile || isTablet) && pathname === url) {
			replace(`${url}/documents`);
		}
	}, [url, replace, isMobile, pathname, isTablet]);

	return (
		<>
			<Seo title={SettingsMessages.metaSettingsTitle} />
			<TransfersSidebar
				sidebarType={SidebarType.SETTINGS}
				types={settingsPages}
				isOptionVisibleOnZeroCount={false}
			/>
			<TransfersOptionContainer
				title={formatMessage(SettingsMessages.title)}
				previousPageLink={url}
				optionContainerClassName={styles.optionContainer}
			>
				<Route exact path={`${url}/documents`} component={Documents} />
				<Route exact path={`${url}/api`} component={ApiSettings} />
				<Route exact path={`${url}/api/create`} component={ApiCreate} />
				<Route exact path={`${url}/api/:clientId/edit`} component={ApiEdit} />
			</TransfersOptionContainer>
		</>
	);
};

export default SettingsContainer;
