import { defineMessages, MessageDescriptor } from 'react-intl';
import { AxiosError } from 'axios';
import { RemoteError } from '../../interfaces/RemoteData';
import { fromEntries } from '../objectHelper/objectHelper';

export interface ErrorResponse {
	errorCode: string;
	category: string;
	key: string;
	defaultMessage: string;
}

const errorMessages = defineMessages({
	// General errors
	GEN_1: {
		id: 'cardMessages.GEN_1',
		defaultMessage: 'unexpected_error',
	},
	GEN_3: {
		id: 'generalMessages.GEN_3',
		defaultMessage: 'invalid_request',
	},
	GEN_8: {
		id: 'cardMessages.GEN_8',
		defaultMessage: 'entity_not_found',
	},

	// AUTH errors
	AP_23: {
		id: 'errors.AP_23',
		defaultMessage: '2FA submission limit is reached',
	},
	AP_24: {
		id: 'validate.AP_24',
		defaultMessage: 'You do not have permission to perform this action',
	},
	AP_25: {
		id: 'validate.AP_25',
		defaultMessage: 'Selected user requires sca type',
	},

	// Card errors
	DC_1: {
		id: 'cardMessages.DC_1',
		defaultMessage: 'unexpected_debit_card_service_exception',
	},
	DC_2: {
		id: 'cardMessages.DC_2',
		defaultMessage: 'unsupported_load_action',
	},
	DC_3: {
		id: 'cardMessages.DC_3',
		defaultMessage: 'debit_card_load_amount_limit_reached',
	},
	DC_4: {
		id: 'cardMessages.DC_4',
		defaultMessage: 'daily_load_count_limit_reached',
	},
	DC_5: {
		id: 'cardMessages.DC_5',
		defaultMessage: 'Debit card load amount is too low. Min amount: {amount} {currency}',
	},
	DC_6: {
		id: 'cardMessages.DC_6',
		defaultMessage: 'not_enough_funds',
	},
	DC_7: {
		id: 'cardMessages.DC_7',
		defaultMessage: 'load_amount_higher_than_unlocked',
	},
	DC_9: {
		id: 'cardMessages.DC_9',
		defaultMessage: 'account_disabled',
	},
	DC_12: {
		id: 'cardMessages.DC_12',
		defaultMessage: 'debit_card_not_found',
	},
	DC_17: {
		id: 'cardMessages.DC_17',
		defaultMessage: 'Debit card unload amount is too low. Min amount: {minAmount} EUR',
	},
	DC_18: {
		id: 'cardMessages.DC_18',
		defaultMessage: 'Debit card load balance limit reached. Max amount: {amount} {currency}',
	},
	DC_20: {
		id: 'cardMessages.DC_20',
		defaultMessage: 'Debit card unload amount exceeded. Max amount: {maxAmount} EUR',
	},
	DC_21: {
		id: 'cardMessages.DC_21',
		defaultMessage: 'Debit card load amount exceeded. Max amount: {maxAmount} EUR',
	},
	DC_22: {
		id: 'cardMessages.DC_22',
		defaultMessage: 'Daily count limit for card unload reached.',
	},
	DC_23: {
		id: 'cardMessages.DC_23',
		defaultMessage: 'Debit card order not possible because user from restricted country',
	},

	// Withdraw errors
	WS_1: {
		id: 'withdrawError.WS_1',
		defaultMessage: 'CRYPTO_WITHDRAW_FAILED',
	},
	WS_2: {
		id: 'withdrawError.WS_2',
		defaultMessage: 'WITHDRAW_CRYPTO_ADDRESS_LIMIT_REACHED',
	},
	WS_3: {
		id: 'withdrawError.WS_3',
		defaultMessage: 'WITHDRAW_TO_LONG_RECEIVER',
	},
	WS_4: {
		id: 'withdrawError.WS_4',
		defaultMessage: 'VALIDATE__NOT_ENOUGH_BALANCE',
	},
	WS_5: {
		id: 'withdrawError.WS_5',
		defaultMessage: 'WITHDRAW_SEND_FAILED',
	},
	WS_6: {
		id: 'withdrawError.WS_6',
		defaultMessage: 'VALIDATE__AMOUNT_TOO_SMALL',
	},
	WS_7: {
		id: 'withdrawError.WS_7',
		defaultMessage: 'VALIDATE__USER_NOT_VERIFIED',
	},
	WS_8: {
		id: 'withdrawError.WS_8',
		defaultMessage: 'INCORRECT_RECEIVER',
	},
	WS_9: {
		id: 'withdrawError.WS_9',
		defaultMessage: 'INVALID_DESTINATION_TAG',
	},
	WS_10: {
		id: 'withdrawError.WS_10',
		defaultMessage: 'INVALID_MEMO',
	},
	WS_11: {
		id: 'withdrawError.WS_11',
		defaultMessage: 'WITHDRAW_INTERNAL_CRYPTO_MESSAGE_EMPTY',
	},
	WS_12: {
		id: 'withdrawError.WS_12',
		defaultMessage: 'WITHDRAW_INVALID_ADDRESS_MESSAGE_COMBINATION',
	},
	WS_13: {
		id: 'withdrawError.WS_13',
		defaultMessage: 'WITHDRAW_ALREADY_USED_REFID',
	},
	WS_14: {
		id: 'withdrawError.WS_14',
		defaultMessage: 'WITHDRAW_IS_NOT_VALID',
	},
	WS_15: {
		id: 'withdrawError.WS_15',
		defaultMessage: 'UNEXPECTED_WITHDRAW_SERVICE_EXCEPTION',
	},
	WS_16: {
		id: 'withdrawError.WS_16',
		defaultMessage: 'UNSUPPORTED_WITHDRAW_CURRENCY',
	},
	WS_17: {
		id: 'withdrawError.WS_17',
		defaultMessage: 'FEE_ESTIMATE_FAILED',
	},
	WS_18: {
		id: 'withdrawError.WS_18',
		defaultMessage: 'SAME_WITHDRAW_USER',
	},
	WS_19: {
		id: 'withdrawError.WS_19',
		defaultMessage: 'INVALID_CRYPTO_ADDRESS',
	},
	WS_20: {
		id: 'withdrawError.WS_20',
		defaultMessage: 'Before you proceed, please fill your profile information',
	},
	WS_21: {
		id: 'withdrawError.WS_21',
		defaultMessage: 'Insufficient funds for withdrawal',
	},
	WS_22: {
		id: 'withdrawError.WS_22',
		defaultMessage: 'Insufficient amount',
	},
	WS_23: {
		id: 'withdrawError.WS_23',
		defaultMessage: 'Invalid Id and withdraw amount provided',
	},
	WS_24: {
		id: 'withdrawError.WS_24',
		defaultMessage: 'Voucher Not Found',
	},
	WS_25: {
		id: 'withdrawError.WS_25',
		defaultMessage: 'Invalid fee specified',
	},
	WS_26: {
		id: 'withdrawError.WS_26',
		defaultMessage: 'Fixed Send Is Not Available For User',
	},
	WS_27: {
		id: 'withdrawError.WS_27',
		defaultMessage: 'Send Account Cannot be Empty',
	},
	WS_28: {
		id: 'withdrawError.WS_28',
		defaultMessage: 'Send Account Not Found',
	},
	WS_29: {
		id: 'withdrawError.WS_29',
		defaultMessage: 'Invalid email',
	},
	WS_30: {
		id: 'withdrawError.WS_30',
		defaultMessage: 'Sender and receiver should be different',
	},
	WS_31: {
		id: 'withdrawError.WS_31',
		defaultMessage: 'Receiver should be provided',
	},
	WS_36: {
		id: 'withdrawError.account_number_is_incorrect',
		defaultMessage: 'Account number is incorrect',
	},
	WS_46: {
		id: 'withdrawError.WS_46',
		defaultMessage: 'Amount should be more than {AvailableAmount} {CurrencyName}',
	},
	WS_47: {
		id: 'withdrawError.WS_47',
		defaultMessage: 'Amount should be less than {AvailableAmount} {CurrencyName}',
	},
	WS_49: {
		id: 'withdrawError.withdraw_invalid',
		defaultMessage: 'Withdraw is not valid',
	},
	WS_50: {
		id: 'withdrawError.WS_50',
		defaultMessage:
			'Your Fixed send daily limit is {DailyLimit} {CurrencyName}. You can still spend {AvailableAmount} {CurrencyName}.',
	},
	WS_51: {
		id: 'withdrawError.WS_51',
		defaultMessage: 'Daily fixed send count limit reached!',
	},
	WS_53: {
		id: 'withdrawError.WS_53',
		defaultMessage: 'Invalid merchant order status',
	},
	WS_54: {
		id: 'withdrawError.invalid_callback_url',
		defaultMessage: 'Invalid callback url',
	},
	WS_55: {
		id: 'withdrawError.withdraw_already_processed',
		defaultMessage: 'Withdraw already processed',
	},
	WS_56: {
		id: 'withdrawError.withdraw_could_not_be_processed',
		defaultMessage: 'Withdraw could not be processed',
	},
	WS_57: {
		id: 'withdrawError.withdraw_refid_already_exists',
		defaultMessage: 'Withdraw refId already exists',
	},
	WS_58: {
		id: 'withdrawError.unsupported_withdraw_status',
		defaultMessage: 'Unsupported withdraw status',
	},
	WS_59: {
		id: 'withdrawError.min_receive_amount_too_small',
		defaultMessage: 'Min receive amount is {MinReceiveAmount} eur.',
	},
	WS_65: {
		id: 'withdrawError.payment_method_not_iban',
		defaultMessage: 'Payment method is not IBAN',
	},
	WS_66: {
		id: 'withdrawError.empty_receiver_bank_name',
		defaultMessage: 'Receiver bank name is empty',
	},
	WS_67: {
		id: 'withdrawError.empty_receiver_bank_country',
		defaultMessage: 'Receiver bank country is empty',
	},
	WS_68: {
		id: 'withdrawError.empty_receiver_bank_address',
		defaultMessage: 'Receiver bank address is empty',
	},
	WS_69: {
		id: 'withdrawError.empty_receiver_province',
		defaultMessage: 'Receiver province is empty',
	},
	WS_70: {
		id: 'withdrawError.empty_receiver_city',
		defaultMessage: 'Receiver city is empty',
	},
	WS_71: {
		id: 'withdrawError.empty_receiver_postal_code',
		defaultMessage: 'Receiver postal code is empty',
	},
	WS_72: {
		id: 'withdrawError.account_is_not_iban',
		defaultMessage: 'Account is not IBAN',
	},
	WS_73: {
		id: 'withdrawError.sepa_payments_not_supported',
		defaultMessage: 'SEPA payments are not supported',
	},
	WS_74: {
		id: 'withdrawError.institution_information_missing',
		defaultMessage: 'Institution information missing',
	},

	// Purchase errors
	PS_1: {
		id: 'withdrawError.PS_1',
		defaultMessage: 'Unexpected error occurred.',
	},
	PS_2: {
		id: 'withdrawError.PS_2',
		defaultMessage: 'Balance is not enough',
	},
	PS_3: {
		id: 'withdrawError.PS_3',
		defaultMessage: 'Selected product or item is disabled',
	},
	PS_4: {
		id: 'withdrawError.PS_4',
		defaultMessage: 'Selected plan not available for this phone number',
	},
	PS_5: {
		id: 'withdrawError.PS_5',
		defaultMessage: 'Selected operator not available for this phone number',
	},
	PS_6: {
		id: 'withdrawError.PS_6',
		defaultMessage: 'Purchase amount is too small',
	},
	PS_7: {
		id: 'withdrawError.PS_7',
		defaultMessage: 'Purchase amount is too high',
	},
	PS_8: {
		id: 'withdrawError.PS_8',
		defaultMessage: 'Invalid currency',
	},
	PS_9: {
		id: 'withdrawError.PS_9',
		defaultMessage: 'Phone number is out of range',
	},

	// Deposit errors
	DP_1: {
		id: 'depositMessages.csv_export_failed',
		defaultMessage: 'Failed to export data to CSV',
	},
	DP_2: {
		id: 'depositMessages.pdf_export_failed',
		defaultMessage: 'Failed to export data to PDF',
	},
	DP_3: {
		id: 'depositMessages.sepa_deposit_with_iban',
		defaultMessage: 'Sepa deposit with iban is not possible',
	},
	DP_4: {
		id: 'depositMessages.missing_profile_info',
		defaultMessage: 'Missing profile information',
	},
	DP_5: {
		id: 'depositMessages.currency_missmatch',
		defaultMessage: 'Deposit and receive currency is different',
	},
	DP_8: {
		id: 'depositMessages.limit_exceeded',
		defaultMessage: 'Deposit limit exceeded',
	},

	// Linked Card errors
	LC_1: {
		id: 'linkedcards.user_card_exist',
		defaultMessage: 'This card exist in your cards list',
	},
	LC_2: {
		id: 'linkedcards.max_card_limit_exceeded',
		defaultMessage: 'Max card limit already exceeded',
	},
	LC_3: {
		id: 'linkedcards.max_card_limit_weekly_exceeded',
		defaultMessage: 'Max weekly card limit exceeded',
	},
	LC_4: {
		id: 'linkedcards.annual_card_limit_exceeded',
		defaultMessage: 'Annual card limit exceeded',
	},
	LC_5: {
		id: 'linkedcards.card_country_is_blocked',
		defaultMessage: 'Card is not accepted',
	},
	LC_6: {
		id: 'linkedcards.user_country_for_card_usage_is_blocked',
		defaultMessage: 'Card functionality is restricted in your country',
	},
	LC_7: {
		id: 'linkedcards.unsupported_linked_card_type',
		defaultMessage: 'Unsupported linked card type',
	},
	LC_8: {
		id: 'linkedcards.expired_card_provided',
		defaultMessage: 'Expired card is provided',
	},
	LC_9: {
		id: 'linkedcards.user_not_verified',
		defaultMessage: 'User must be verified in order to confirm linked card',
	},
	LC_11: {
		id: 'linkedcards.link_card_is_blocked',
		defaultMessage: 'Card is blocked',
	},
	LC_12: {
		id: 'linkedcards.card_expired',
		defaultMessage: 'Card expired',
	},
	LC_13: {
		id: 'linkedcards.link_card_is_activated',
		defaultMessage: 'Card is activated',
	},
	LC_16: {
		id: 'linkedcards.validation_name_not_match',
		defaultMessage: `The owner’s name of the card and the SpectroCoin account are different. Please get in touch with our customer support for further information or wait for a response from our team.`,
	},
	LC_18: {
		id: 'linkedcards.blocked_card',
		defaultMessage: 'This card is blocked and cannot be added',
	},
	LC_19: {
		id: 'linkedcards.amount_less_than_min',
		defaultMessage: 'The amount is less than the minimum allowed',
	},
	LC_20: {
		id: 'linkedcards.amount_more_than_max',
		defaultMessage: 'The amount is more than the maximum allowed',
	},
	LC_21: {
		id: 'linkedcards.deposit_limit_exceeded',
		defaultMessage: 'The deposit limit is exceeded',
	},
	LC_22: {
		id: 'linkedcards.card_deposit_failed',
		defaultMessage: 'Card deposit failed',
	},

	// Merchant errors
	MA_0: {
		id: 'merchants.errors.not_supported_currency',
		defaultMessage: 'Not supported currency',
	},
	MA_1: {
		id: 'merchants.errors.pay_or_receive_amount_is_required',
		defaultMessage: 'Pay or receive amount is required',
	},
	MA_2: {
		id: 'merchants.errors.merchant_api_not_enabled',
		defaultMessage: 'Merchant api not enabled',
	},
	MA_3: {
		id: 'merchants.errors.callback_url_invalid',
		defaultMessage: 'Callback url invalid',
	},
	MA_4: {
		id: 'merchants.errors.success_url_invalid',
		defaultMessage: 'Success url invalid',
	},
	MA_5: {
		id: 'merchants.errors.failure_url_invalid',
		defaultMessage: 'Failure url invalid',
	},
	MA_6: {
		id: 'merchants.errors.invalid_merchant_order_status',
		defaultMessage: 'Invalid merchant order status',
	},
	MA_7: {
		id: 'merchants.errors.invalid_receive_account',
		defaultMessage: 'Invalid receive account',
	},
	MA_8: {
		id: 'merchants.errors.invalid_pay_currency',
		defaultMessage: 'Invalid pay currency',
	},
	MA_9: {
		id: 'merchants.errors.merchant_new_crypto_address_limit_reached',
		defaultMessage: 'Merchant new crypto address limit reached',
	},
	MA_11: {
		id: 'merchants.errors.callback_daily_limit_reached',
		defaultMessage: 'Callback daily limit reached',
	},
	MA_12: {
		id: 'merchants.errors.duplicate_order_id',
		defaultMessage: 'Duplicate orderId',
	},
	MA_13: {
		id: 'merchants.errors.callback_failed',
		defaultMessage: 'Callback failed',
	},
	MA_14: {
		id: 'merchants.errors.invoices_limit_reached',
		defaultMessage: 'Invoices limit reached',
	},
	MA_15: {
		id: 'merchants.errors.merchant_api_update_not_supported',
		defaultMessage: 'Merchant api update not supported',
	},
	MA_16: {
		id: 'merchants.errors.insufficient_funds',
		defaultMessage: 'Insufficient funds',
	},
	MA_17: {
		id: 'merchants.errors.verification_required',
		defaultMessage: 'Verification required',
	},
	MA_18: {
		id: 'merchants.errors.merchant_order_paid',
		defaultMessage: 'Merchant order paid',
	},
	MA_19: {
		id: 'merchants.errors.unverified_user',
		defaultMessage: 'Unverified user',
	},
	MA_20: {
		id: 'merchants.errors.invalid_user_email',
		defaultMessage: 'Invalid user email',
	},
	MA_21: {
		id: 'merchants.errors.same_user',
		defaultMessage: 'Same user',
	},

	// Payment button errors
	PB_3: {
		id: 'merchants.new_address_blocked',
		defaultMessage: 'New address blocked',
	},
	PB_4: {
		id: 'merchants.unsupported_pay_currency',
		defaultMessage: 'Unsupported pay currency',
	},
	PB_5: {
		id: 'merchants.invalid_pay_amount',
		defaultMessage: 'Invalid pay amount',
	},
	PB_6: {
		id: 'merchants.verification_required',
		defaultMessage: 'Verification required',
	},
	PB_7: {
		id: 'merchants.unsupported_action',
		defaultMessage:
			'Payment button information can not be edited as it contains executed payments',
	},

	// Auth error
	AP_18: {
		id: 'twoFABox.termporaryBanned',
		defaultMessage: 'User temporary banned',
	},
	US_3: {
		id: 'login.support_should_be_contacted',
		defaultMessage: 'Cannot login. Please contact support',
	},
	A_22: {
		id: 'error.entity_not_found',
		defaultMessage: 'Requested entity not found',
	},

	// wallet error
	AC_6: {
		id: 'wallet.error.AC_6',
		defaultMessage: 'This account type is disabled',
	},

	// user documents
	UD_1: {
		id: 'documents.UD_1',
		defaultMessage: 'Some error occurred.',
	},
	UD_2: {
		id: 'documents.UD_2',
		defaultMessage: 'User File Storage not found',
	},
	UD_3: {
		id: 'documents.UD_3',
		defaultMessage: 'Can not find file for user',
	},
	UD_4: {
		id: 'documents.UD_4',
		defaultMessage: 'User can only delete GDPR docs',
	},
	UD_5: {
		id: 'documents.UD_5',
		defaultMessage: 'Failed to get requested doc type',
	},
	UD_6: {
		id: 'documents.UD_6',
		defaultMessage: 'You have already submitted the request.',
	},
	UD_7: {
		id: 'documents.UD_7',
		defaultMessage: 'You can submit new request after 24h from the moment it is processed.',
	},
});

export const ErrorMessageCodes = fromEntries(
	Object.keys(errorMessages).map((x: keyof typeof errorMessages) => [x, x])
) as Record<keyof typeof errorMessages, keyof typeof errorMessages>;

export type ErrorMessageCodesType = keyof typeof ErrorMessageCodes;

export const isMessageDescription = (value: any): value is MessageDescriptor =>
	value?.id && value?.defaultMessage;

export const isRemoteError = (value: any): value is RemoteError => value?.errorCode;

export const isErrorString = (value: any): value is string => typeof value === 'string';

export const isAxiosError = (value: any): value is AxiosError<RemoteError> =>
	value?.response?.data?.errorCode;

export const getErrorMessageOrDefault = (
	error?: RemoteError | MessageDescriptor | string | AxiosError<RemoteError>
): MessageDescriptor =>
	isMessageDescription(error)
		? error
		: isAxiosError(error)
		? errorMessages[error.response?.data?.errorCode || 'GEN_1']
		: isRemoteError(error)
		? errorMessages[error.errorCode] || errorMessages.GEN_1
		: isErrorString(error)
		? errorMessages[error] || { id: 'unknown_message', defaultMessage: error }
		: errorMessages.GEN_1;

export default errorMessages;
