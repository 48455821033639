export enum ProfileActionType {
	SET_PROFILE = 'SET_PROFILE',
	SET_VERIFICATION_STATUS = 'SET_VERIFICATION_STATUS',
	SET_COMPANY = 'SET_COMPANY',
	SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN',
	SET_IS_SCA = 'SET_IS_SCA',
	RESET_IDLE_TIMER = 'RESET_IDLE_TIMER',
	IDLE_TIMER_TICK = 'IDLE_TIMER_TICK',
	SET_IS_CHANGE_PASS_AVAILABLE = 'SET_IS_CHANGE_PASS_AVAILABLE',
}

export enum UserType {
	PRIVATE_PERSON = 'PRIVATE_PERSON',
	COMPANY_REPRESENTATIVE = 'COMPANY_REPRESENTATIVE',
}

export enum UserCompany {
	LT = 'LT',
	EE = 'EE',
	SF_BVI = 'SF_BVI',
	SF_SYSTEMS_BVI = 'SF_SYSTEMS_BVI',
	BNK_SYSTEMS_BVI = 'BNK_SYSTEMS_BVI',
	BNK_OPERATIONS_BVI = 'BNK_OPERATIONS_BVI',
	BNK_SOLUTIONS_BVI = 'BNK_SOLUTIONS_BVI',
}

export enum VerificationStatus {
	NEW = 'NEW',
	PENDING = 'PENDING',
	VERIFIED = 'VERIFIED',
	UNVERIFIED = 'UNVERIFIED',
	PENDING_PROVIDER = 'PENDING_PROVIDER',
	FAILED = 'FAILED',
}

interface ReverificationData {
	userVerificationId: string | null;
	verificationUrl: string | null;
	reverificationForced: boolean;
}

export interface ProfileVerificationStatus {
	companyMovement: boolean;
	status: VerificationStatus;
}

export interface Profile {
	addressLine1: string | null;
	addressLine2: string | null;
	beneficiaryName: string | null;
	city: string | null;
	companyBusinessAddress: string | null;
	companyBusinessCountry: string | null;
	companyName: string | null;
	companyPhone: string | null;
	companyRegistrationAddress: string | null;
	companyRegistrationCode: string | null;
	companyRegistrationCountry: string | null;
	companyRegistrationDate: string | null;
	country: string | null;
	dob: string | null;
	email: string;
	id: string;
	name: string | null;
	phone: string | null;
	postcode: string | null;
	surname: string | null;
	verified: boolean;
	verifiedObsolete: boolean;
	phoneVerified: boolean;
	type: UserType;
	userRegistrationDate: string;
	referralCode: string;
	isReverificationForced: ReverificationData;
	verificationStatus: ProfileVerificationStatus;
	userAddress: {
		streetName: string | null;
		buildingNumber: string | null;
		apartmentNumber: string | null;
		city: string | null;
		country: string;
		postCode: string | null;
		addressLine: string | null;
	} | null;
}

export interface SetProfileAction {
	type: typeof ProfileActionType.SET_PROFILE;
	data: Profile | null;
}

export interface SetVerificationStatus {
	type: typeof ProfileActionType.SET_VERIFICATION_STATUS;
	data: ProfileVerificationStatus;
}

export interface SetCompanyAction {
	type: typeof ProfileActionType.SET_COMPANY;
	data: string;
}

export interface SetIsLoggedInAction {
	type: typeof ProfileActionType.SET_IS_LOGGED_IN;
	data: boolean;
}

export interface InitialState {
	user: Profile | null;
	company: UserCompany | null;
	isLoggedIn: boolean;
	idleTimer: number;
	isSCA: boolean;
}

export interface SetIsSCAAction {
	type: typeof ProfileActionType.SET_IS_SCA;
	data: boolean;
}

export interface ResetIdleTimerAction {
	type: typeof ProfileActionType.RESET_IDLE_TIMER;
}
