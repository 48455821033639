import createSelector from '../../createSelector';

const getState = createSelector((state) => state.PaymentState.donations);

const getButton = createSelector(getState, (state) => state.button);
const getCurrencies = createSelector(getState, (state) => state.currencies);
const getForm = createSelector(getState, (state) => state.form);
const getButtonData = createSelector(getButton, (state) => state.data);
const getCurrenciesData = createSelector(getCurrencies, (state) => state.data);
const getPay = createSelector(getState, (state) => state.pay);

export default {
	getState,
	getButton,
	getCurrencies,
	getForm,
	getButtonData,
	getCurrenciesData,
	getPay,
};
