import { FC, PropsWithChildren } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import LoginPage from '../../pages/Login/Login';
import LoginCallbackPage from '../../pages/Login/LoginCallback/LoginCallback';
import config from '../../configs/config';
import useEffectOnce from '../../hooks/useEffectOnce';

const Setup = () => {
	const { search } = useLocation();

	useEffectOnce(() => {
		window.location.assign(`${config.PROFILE_DOMAIN}/setup${search}`);
	});

	return null;
};

const AuthContainer: FC<PropsWithChildren> = ({ children }) => {
	return (
		<Switch>
			<Route path={['/login/user-setup', '/:lng/login/user-setup']}>
				<Setup />
			</Route>
			<Route
				// need all these paths below as all does exactly the same just with different path
				// to differiante the flow parameter passed into auth flow
				path={['/login', '/:lng?/login', '/signup', '/:lng?/signup']}
			>
				<LoginPage />
			</Route>
			<Route path={['/login-callback', '/:lng?/login-callback']}>
				<LoginCallbackPage />
			</Route>
			<Route>{children}</Route>
		</Switch>
	);
};

export default AuthContainer;
