/* eslint-disable no-param-reassign */
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import {
	OrdersHistoryActionType,
	OrdersHistoryFetchOrdersPendingAction,
	OrdersHistoryFetchOrdersRejectedAction,
	OrdersHistoryFetchOrdersFulfilledAction,
	OrdersHistoryState,
} from './OrdersHistoryTypes';

const initialState: OrdersHistoryState = {
	data: [],
	totalCount: 0,
	status: RemoteStatus.None,
	pageSize: 20,
};

export default (
	state = initialState,
	action:
		| OrdersHistoryFetchOrdersPendingAction
		| OrdersHistoryFetchOrdersRejectedAction
		| OrdersHistoryFetchOrdersFulfilledAction
): OrdersHistoryState => {
	switch (action.type) {
		case OrdersHistoryActionType.FETCH_ORDERS_PENDING:
			return {
				...state,
				status: RemoteStatus.InProgress,
			};
		case OrdersHistoryActionType.FETCH_ORDERS_REJECTED:
			return {
				...state,
				status: RemoteStatus.Error,
				error: action.payload,
			};
		case OrdersHistoryActionType.FETCH_ORDERS_FULFILLED:
			return {
				...state,
				totalCount: action.payload.totalElements,
				status: RemoteStatus.Done,
				pageSize: action.payload.pageable.pageSize,
				data: action.payload.content.map((x) => x.id),
			};
		default:
			return state;
	}
};
