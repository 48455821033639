import { createContext } from 'react';
import { RemoteData, RemoteStatus } from '../../../../../../interfaces/RemoteData';
import {
	PaymentButton,
	PaymentButtonEditForm,
} from '../../../../../../redux/MerchantsState/MerchantTypes';

export default createContext<{
	form: PaymentButtonEditForm;
	action: RemoteData<PaymentButton>;
	updateForm: (value: Partial<PaymentButtonEditForm>) => void;
	setAction: (value: RemoteData<PaymentButton>) => void;
}>({
	form: {} as PaymentButtonEditForm,
	action: { status: RemoteStatus.None },
	updateForm: () => {},
	setAction: () => {},
});
