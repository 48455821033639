import createSelector from '../../../createSelector';
import { RemoteStatus } from '../../../../interfaces/RemoteData';

const getState = createSelector((state) => state.MerchantState.orders.data);

const getOrderById = (id: string) =>
	createSelector(getState, (state) => state.orders[id] || { status: RemoteStatus.None });

const getOrderDataById = (id: string) => createSelector(getOrderById(id), (state) => state?.data);

const getOrdersDataByIds = (ids: string[]) =>
	createSelector(getState, (state) =>
		ids
			.map((id) => state.orders[id])
			.filter((x) => x.status === RemoteStatus.Done)
			.map((x) => x.data!)
	);

export default {
	getState,
	getOrderById,
	getOrderDataById,
	getOrdersDataByIds,
};
