import { FC } from 'react';
import classNames from 'classnames';
import SvgIcon from '../SvgIcon/SvgIcon';
import successIcon from '../../images/message_icons/success.svg';
import warningIcon from '../../images/message_icons/warning.svg';
import dangerIcon from '../../images/message_icons/danger.svg';
import infoIcon from '../../images/message_icons/info.svg';

import styles from './NotificationMessage.module.scss';

export enum NotificationStyle {
	Fill = 'FILL',
	Border = 'BORDER',
}

export enum NotificationType {
	Info = 'INFO',
	Warning = 'WARNING',
	Error = 'DANGER',
	Success = 'SUCCESS',
}

const icons = {
	[NotificationType.Warning]: warningIcon,
	[NotificationType.Error]: dangerIcon,
	[NotificationType.Success]: successIcon,
	[NotificationType.Info]: infoIcon,
} as const;

type NotificationMessageProps = {
	message: string | JSX.Element;
	style: NotificationStyle;
	type: NotificationType;
	withIcon?: boolean;
	className?: string;
	messageClassName?: string;
};

const NotificationMessage: FC<NotificationMessageProps> = ({
	message,
	style,
	type,
	withIcon,
	className,
	messageClassName,
	...rest
}) => (
	<div
		data-cy={`${type}Notification`}
		{...rest}
		className={classNames(styles.container, styles[style], styles[type], className)}
	>
		{withIcon && icons[type] && <SvgIcon className={styles.icon} src={icons[type]} />}
		<div className={classNames(styles.message, messageClassName)}>{message}</div>
	</div>
);

export default NotificationMessage;
