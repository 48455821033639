import { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import baseMsg from '../../messages/base.messages';
import { formatPrecision, toDecimal } from '../../helpers/currencyHelper/currencyHelper';
import { RootState } from '../../redux/Store';
import Button, { ButtonStyle, ButtonType } from '../Button/Button';
import cardImgPath from './images/spectrocard.svg';
import mobileCardImgPath from './images/spectrocard_mobile.svg';
import styles from './CardsBanner.module.scss';
import { getAllCards, getIsCardUser } from '../../redux/CardsState/CardsActions';
import { isCardOnHold } from '../../helpers/cardsHelper/cardsHelper';
import { CardStatusType, CardType, CardTypeFromBE } from '../../redux/CardsState/CardsTypes';
import plasticCardImgPath from '../CardsList/images/plastic.svg';
import virtualCardImgPath from '../CardsList/images/virtual.svg';
import lockImgPath from '../CardsList/images/lock.svg';
import blockedImgPath from '../CardsList/images/blocked.svg';
import statusMsg from '../../messages/status.messages';
import { isAmountGreaterThanZero } from '../../helpers/inputValidation/inputValidation';
import config from '../../configs/config';
import { UserCompany } from '../../redux/ProfileState/ProfileTypes';
import useFormatAmount from '../../hooks/useFormatAmount';

const messages = defineMessages({
	order: {
		id: 'cardBanner.order',
		defaultMessage: 'Order card',
	},
	verify: {
		id: 'cardBanner.verify',
		defaultMessage: 'Verify and Order',
	},
	plasticCryptoCard: {
		id: 'orderCard.plasticCryptoCard',
		defaultMessage: 'Plastic crypto card',
	},
	virtualCryptoCard: {
		id: 'orderCard.virtualCryptoCard',
		defaultMessage: 'Virtual crypto card',
	},
	PLASTIC: {
		id: 'orderCard.plastic',
		defaultMessage: 'Plastic',
	},
	VIRTUAL: {
		id: 'orderCard.virtual',
		defaultMessage: 'Virtual',
	},
	activate: {
		id: 'cardsList.active',
		defaultMessage: 'Activate',
	},
	SUSPENDED: {
		id: 'cardsList.SUSPENDED',
		defaultMessage: 'LOCKED',
	},
	learnMore: {
		id: 'base.learnMore',
		defaultMessage: 'Learn more',
	},
});

const CardsBanner = () => {
	const dispatch = useDispatch();
	const [showSummary, setShowSummary] = useState(false);
	const { locale, formatMessage } = useIntl();
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const { isMobile } = useSelector((state: RootState) => state.AppState);
	const { cardsList, isCardUser } = useSelector((state: RootState) => state.CardsState);
	const amountFormatter = useFormatAmount();

	const getCardTypeLabel = (cardType: CardTypeFromBE, type: CardType) => {
		switch (cardType) {
			case CardTypeFromBE.PERVESK_RHA_PLASTIC:
				return formatMessage(messages.plasticCryptoCard);
			case CardTypeFromBE.PERVESK_RHA_VIRTUAL:
				return formatMessage(messages.virtualCryptoCard);
			default:
				return formatMessage(messages[type]);
		}
	};

	useEffect(() => {
		if (isCardUser === null) dispatch(getIsCardUser());
		if (isCardUser && cardsList === null) dispatch(getAllCards());
		if (cardsList?.length) setShowSummary(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cardsList, isCardUser]);

	if (!user) return null;
	return (
		<div className={styles.container}>
			<div className={styles.bannerHeader}>
				<h3 className={styles.title}>
					<FormattedMessage id="cardsBanner.cardsTitle" defaultMessage="Cards" />
				</h3>
				{showSummary && (
					<Link to={`/${locale}/cards`} className={styles.seeAllLink}>
						<FormattedMessage id="ratesBanner.seeAll" defaultMessage="See all" />
					</Link>
				)}
			</div>
			<div className={classNames(styles.content, { [styles.summaryContent]: showSummary })}>
				{showSummary ? (
					cardsList
						?.sort(({ accountBalance }, { accountBalance: secondBalance }) =>
							toDecimal(secondBalance).comparedTo(toDecimal(accountBalance))
						)
						.slice(0, 3)
						.map(
							({
								status,
								cardId,
								type,
								pan,
								accountBalance,
								reservationsBalance,
								cardType,
							}) => (
								<div className={styles.cardItem} key={cardId}>
									<div className={styles.iconContainer}>
										<div className={styles.iconPosition}>
											<img
												className={classNames(styles.typeImg, {
													[styles.blocked]: isCardOnHold(
														CardStatusType[status]
													),
													[styles.notActive]:
														status === CardStatusType.NOT_ACTIVATED,
												})}
												src={
													type === CardType.PLASTIC
														? plasticCardImgPath
														: virtualCardImgPath
												}
											/>
											{((status === CardStatusType.BLOCKED &&
												cardType === CardTypeFromBE.C_PASTIC) ||
												status === CardStatusType.SUSPENDED) && (
												<img src={lockImgPath} className={styles.lock} />
											)}
											{((status === CardStatusType.BLOCKED &&
												cardType !== CardTypeFromBE.C_PASTIC) ||
												status === CardStatusType.CLOSED) && (
												<img src={blockedImgPath} className={styles.lock} />
											)}
										</div>
									</div>
									<div className={styles.balanceContent}>
										<span
											className={classNames(styles.panNumber, {
												[styles.opacity]: isCardOnHold(
													CardStatusType[status]
												),
											})}
										>
											<span className={styles.number}>
												<span className={styles.dots}>
													&#183;&#183;&#183;&#183;
												</span>
												{` ${pan.slice(-4)}`}
											</span>
											<span
												className={classNames(styles.type, {
													[styles.opacity]: isCardOnHold(
														CardStatusType[status]
													),
												})}
											>
												{getCardTypeLabel(cardType, type)}
											</span>
										</span>
										<div>
											<span
												className={classNames(styles.balance, {
													[styles.opacity]: isCardOnHold(
														CardStatusType[status]
													),
												})}
											>
												{`${
													accountBalance.search('-') !== -1 ? '-' : ''
												} ${amountFormatter(
													formatPrecision(
														accountBalance,
														CurrencyEnum.EUR
													)
												)} EUR`}
											</span>
											{isAmountGreaterThanZero(reservationsBalance) && (
												<span className={styles.reservedAmount}>
													{`${formatMessage(baseMsg.labeledValue, {
														Label: formatMessage(statusMsg.RESERVED),
														Value: reservationsBalance,
													})} ${CurrencyEnum.EUR}`}
												</span>
											)}
										</div>
									</div>
								</div>
							)
						)
				) : (
					<>
						<div>
							<div className={styles.bannerHead}>
								{isMobile && <img src={mobileCardImgPath} className={styles.img} />}
								<div>
									<h2 className={styles.heading}>
										<FormattedMessage
											id="cardBanner.heading"
											defaultMessage="Get your SpectroCoin prepaid card"
										/>
									</h2>
									<p className={styles.paragraph}>
										<FormattedMessage
											id="cardBanner.post"
											defaultMessage="Exchange cryptocurrencies and spend with your Visa debit card in millions of shops around the world."
										/>
									</p>
								</div>
							</div>
							{!isMobile && (
								<>
									<Button
										text={messages.order}
										type={ButtonType.LINK}
										className={styles.orderBtn}
										link={`/${locale}/cards/order`}
									/>
									<Button
										text={messages.learnMore}
										type={ButtonType.ANCHOR_LINK}
										buttonStyle={ButtonStyle.TERTIARY}
										link={`/${locale}/bitcoin-debit-card.html`}
									/>
								</>
							)}
						</div>
						{isMobile && (
							<>
								<Button
									text={user?.verified ? messages.order : messages.verify}
									type={user?.verified ? ButtonType.LINK : ButtonType.ANCHOR_LINK}
									className={styles.orderBtn}
									link={
										user?.verified
											? `/${locale}/cards/order`
											: `${config.PROFILE_DOMAIN}/${locale}/verification/verify/${UserCompany.EE}`
									}
								/>
								<Button
									text={messages.learnMore}
									type={ButtonType.ANCHOR_LINK}
									buttonStyle={ButtonStyle.TERTIARY}
									link={`/${locale}/bitcoin-debit-card.html`}
								/>
							</>
						)}
						{!isMobile && <img src={cardImgPath} className={styles.img} />}
					</>
				)}
			</div>
		</div>
	);
};

export default CardsBanner;
