import { AxiosRequestConfig } from 'axios';

export enum TwoFaActionType {
	SET_2FA_DATA = 'SET_2FA_DATA',
	RESET_2FA_DATA = 'RESET_2FA_DATA',
	SET_2FA_ERROR_CODE = 'SET_2FA_ERROR_CODE',
}

export enum TwoFaCode {
	CANCEL = '2FA_CANCEL',
	PHONE_ERROR = '2FA_4',
	FIRST_TIME_INIT_NEW_TYPE = '2FA_11',
	INIT_NEW_TYPE = '2FA_10',
	TWO_FA_DUO_PUSH_WAITING = 'AP_13',
	TWO_FA_PUSH_ME_WAITING = 'AP_20',
	TWO_FA_WAITING = '2FA_22',
	TWO_FA_EMPTY = 'AP_13',
	TWO_FA_INCORRECT = 'AP_6',
	TWO_FA_REQUIRED = 'AP_5',
	LOGIN_FAILED = 'AP_2',
	TEMPORARY_BANNED = 'AP_18',
	ERROR = '2FA_2',
	GENERAL_ERROR = 'GEN_1',
}

export enum TwoFaType {
	EMAIL = 'EMAIL',
	SMS = 'SMS',
	GOOGLE_AUTH = 'GOOGLE_AUTH',
	PUSH_ME = 'PUSH_ME',
	DUO_PUSH = 'DUO_PUSH',
	TELEGRAM = 'TELEGRAM',
}

export interface TwoFaState {
	authType: TwoFaType | null;
	txId: string | null;
	twoFaCode: string | null;
	requestData: AxiosRequestConfig | null;
	errorCode: TwoFaCode | null;
	expDate: string | null;
	email: string | null;
}

export interface Set2FaDataAction {
	type: typeof TwoFaActionType.SET_2FA_DATA;
	data: TwoFaState;
}

export interface Reset2FaDataAction {
	type: typeof TwoFaActionType.RESET_2FA_DATA;
}

export interface Set2FaErrorCodeAction {
	type: typeof TwoFaActionType.SET_2FA_ERROR_CODE;
	code: TwoFaCode;
}
