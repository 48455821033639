/* eslint-disable react/no-this-in-sfc */
import classNames from 'classnames';
import Joi from 'joi';
import React, { FC, FormEvent, useMemo, useState } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Accordion from '../../../../../components/Accordion/Accordion';
import Button, { ButtonStyle, ButtonType } from '../../../../../components/Button/Button';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import Input from '../../../../../components/Input/Input';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../../components/NotificationMessage/NotificationMessage';
import TextNode from '../../../../../components/TextNode/TextNode';
import { getErrorMessageOrDefault } from '../../../../../helpers/errorMessageHelper/errorMessageHelper';
import useValidation, { validate } from '../../../../../hooks/useValidation';
import { RemoteStatus } from '../../../../../interfaces/RemoteData';
import SettingsMessages from '../../../../../redux/SettingsState/SettingsMessages';
import { RootState } from '../../../../../redux/Store';
import styles from './Form.module.scss';

export enum ApiScopes {
	ACCOUNT_READ = 'account:read',
	DEPOSIT_READ = 'deposit:read',
	DEPOSIT_CRYPTO_ADDRESS_READ = 'deposit-crypto-address:read',
	DEPOSIT_CRYPTO_ADDRESS_CREATE = 'deposit-crypto-address:create',
	EXCHANGE_READ = 'exchange:read',
	EXCHANGE_CREATE = 'exchange:create',
	WITHDRAW_CRYPTO_READ = 'withdraw-crypto:read',
	WITHDRAW_CRYPTO_CREATE = 'withdraw-crypto:create',
	WITHDRAW_BANK_CREATE = 'withdraw-bank:create',
	WITHDRAW_BANK_READ = 'withdraw-bank:read',
	WITHDRAW_WITH_EXCHANGE_READ = 'withdraw-with-exchange:read',
	WITHDRAW_WITH_EXCHANGE_CREATE = 'withdraw-with-exchange:create',
	MERCHANTS_PREORDERS_READ = 'merchants-preorders:read',
	MERCHANTS_PREORDERS_CREATE = 'merchants-preorders:create',
	MERCHANTS_ORDERS_READ = 'merchants-orders:read',
	MERCHANTS_ORDERS_CREATE = 'merchants-orders:create',
	MERCHANTS_ORDERS_CANCEL = 'merchants-orders:cancel',
	ACCOUNT_TRANSACTION_READ = 'account-transaction:read',
	PAYMENT_METHOD_READ = 'payment-method:read',
	MERCHANTS_PROJECTS_READ = 'merchants-projects:read',
	MERCHANTS_PROJECTS_CREATE = 'merchants-projects:create',
	MERCHANTS_PROJECTS_WRITE = 'merchants-projects:write',
	MERCHANTS_ORDERS_PAY = 'merchants-orders:pay',
	MERCHANTS_ORDERS_CALLBACK = 'merchants-orders:callback',
	MERCHANTS_CALLBACKS_READ = 'merchants-callbacks:read',
	MERCHANTS_TRANSACTIONS_READ = 'merchants-transactions:read',
	DEPOSIT_FIAT_CREATE = 'deposit-fiat:create',
	DEPOSIT_FIAT_READ = 'deposit-fiat:read',
	DEPOSIT_CRYPTO_READ = 'deposit-crypto:read',
	PURCHASE_GIFT_CARD_READ = 'purchase-gift-card:read',
	PURCHASE_GIFT_CARD_PAY = 'purchase-gift-card:pay',
	WITHDRAW_VOUCHER_READ = 'withdraw-voucher:read',
	WITHDRAW_VOUCHER_CREATE = 'withdraw-voucher:create',
	WITHDRAW_E_WALLET_READ = 'withdraw-e-wallet:read',
	WITHDRAW_E_WALLET_CREATE = 'withdraw-e-wallet:create',
}

export interface FormState {
	name: string;
	created?: string;
	enabled?: boolean;
	ipAddress: string;
	ipWhitelist: string[];
	scopes: Record<ApiScopes, { isChecked: boolean }>;
}

enum ValidationScope {
	None,
	OnDemand,
}

const useAPIKeyFormValidator = (hasTerms: boolean, ipWhitelist: string[]) => {
	const { formatMessage } = useIntl();

	return useMemo(
		() =>
			Joi.object<Partial<FormState & { terms: boolean }>>()
				.when('$scope', {
					is: ValidationScope.None,
					then: {
						name: Joi.string().allow('', null),
						ipAddress: Joi.string().allow('', null),
						terms: Joi.boolean(),
					},
				})
				.when('$scope', {
					is: ValidationScope.OnDemand,
					then: {
						name: Joi.string()
							.required()
							.messages({
								'string.empty': formatMessage(SettingsMessages.apiNameRequired),
							}),
						ipAddress: Joi.string()
							.allow('', null)
							.ip()
							.custom((value: string, helper: any) => {
								if (ipWhitelist.includes(value)) {
									return helper.message(
										formatMessage(SettingsMessages.ipAddressAlreadyAdded)
									);
								}
								return true;
							})
							.messages({
								'string.ip': formatMessage(SettingsMessages.invalidIpAddress),
							}),
						...(hasTerms && {
							terms: Joi.boolean()
								.valid(true)
								.messages({
									'any.only': formatMessage(SettingsMessages.acceptTermsRequired),
								}),
						}),
					},
				}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ipWhitelist]
	);
};

type BaseProps = {
	handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
	hasTerms?: boolean;
	formState: FormState;
	setFormState: (state: FormState) => void;
	submitButtonText: MessageDescriptor | string;
};

type Props = {
	showCancel?: false;
	cancelButtonText?: undefined;
	onCancel?: undefined;
} & BaseProps;

type PropsWithCancel = {
	showCancel: true;
	cancelButtonText: MessageDescriptor | string;
	onCancel: () => void;
} & BaseProps;

const DescriptionText = (message: MessageDescriptor) => {
	return (
		<FormattedMessage
			{...message}
			values={{ li: (txt) => <li className={styles.listItem}>{txt}</li> }}
		/>
	);
};

const Form: FC<Props | PropsWithCancel> = ({
	handleSubmit,
	hasTerms = false,
	showCancel = false,
	submitButtonText,
	cancelButtonText,
	onCancel,
	formState,
	setFormState,
}) => {
	const { formatMessage, locale } = useIntl();
	const {
		list: { status: listStatus },
		edit: { status: editStatus },
		error,
	} = useSelector((state: RootState) => state.SettingsState.apiKeys);
	const [termsAccepted, setTermsAccepted] = useState(false);
	const [validationScope, setValidationScope] = useState<ValidationScope>(ValidationScope.None);

	const validator = useAPIKeyFormValidator(hasTerms, formState.ipWhitelist);
	const [isValid, validationResult] = useValidation(
		{ name: formState.name, ipAddress: formState.ipAddress, terms: termsAccepted },
		validator,
		{
			scope: validationScope,
		}
	);

	const isLoading = [listStatus, editStatus].includes(RemoteStatus.InProgress);
	const isError = [listStatus, editStatus].includes(RemoteStatus.Error);

	const handleInputChange = (e: any) => {
		const { name, value } = e.target;

		setValidationScope(ValidationScope.None);
		if (e.target.type === 'checkbox') {
			setFormState({ ...formState, [name]: e.target.checked });
			return;
		}
		setFormState({ ...formState, [name]: value });
	};

	const handleScopesCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { id, checked } = e.target;

		setValidationScope(ValidationScope.None);
		setFormState({
			...formState,
			scopes: {
				...formState.scopes,
				[id]: {
					isChecked: checked,
				},
			},
		});
	};

	const handleAddIpAddress = () => {
		if (!formState.ipAddress) {
			return;
		}
		setValidationScope(ValidationScope.None);
		setFormState({
			...formState,
			ipWhitelist: [...formState.ipWhitelist, formState.ipAddress],
			ipAddress: '',
		});
	};

	const handleRemoveIpAddress = (ip: string) => {
		setFormState({
			...formState,
			ipWhitelist: formState.ipWhitelist.filter((item) => item !== ip),
		});
	};

	const availableScopes = useMemo(
		() => [
			{
				id: ApiScopes.ACCOUNT_READ,
				title: formatMessage(SettingsMessages.userAccountReadScope),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.userAccountReadScopeDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.DEPOSIT_READ,
				title: formatMessage(SettingsMessages.depositReadScope),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.depositReadScopeDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.DEPOSIT_CRYPTO_ADDRESS_READ,
				title: formatMessage(SettingsMessages.depositCryptoAddressRead),
				answer: (
					<TextNode>
						<DescriptionText
							{...SettingsMessages.depositCryptoAddressReadDescription}
						/>
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.DEPOSIT_CRYPTO_ADDRESS_CREATE,
				title: formatMessage(SettingsMessages.depositCryptoAddressCreate),
				answer: (
					<TextNode>
						<DescriptionText
							{...SettingsMessages.depositCryptoAddressCreateDescription}
						/>
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.EXCHANGE_READ,
				title: formatMessage(SettingsMessages.exchangeReadScope),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.exchangeReadScopeDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.EXCHANGE_CREATE,
				title: formatMessage(SettingsMessages.exchangeCreateScope),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.exchangeCreateScopeDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.WITHDRAW_CRYPTO_READ,
				title: formatMessage(SettingsMessages.withdrawCryptoReadScope),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.withdrawCryptoReadScopeDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.WITHDRAW_CRYPTO_CREATE,
				title: formatMessage(SettingsMessages.withdrawCryptoCreateScope),
				answer: (
					<TextNode>
						<DescriptionText
							{...SettingsMessages.withdrawCryptoCreateScopeDescription}
						/>
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.WITHDRAW_BANK_READ,
				title: formatMessage(SettingsMessages.withdrawBankRead),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.withdrawBankReadDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.WITHDRAW_BANK_CREATE,
				title: formatMessage(SettingsMessages.withdrawBankCreate),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.withdrawBankCreateDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.WITHDRAW_WITH_EXCHANGE_READ,
				title: formatMessage(SettingsMessages.withdrawWithExchangeRead),
				answer: (
					<TextNode>
						<DescriptionText
							{...SettingsMessages.withdrawWithExchangeReadDescription}
						/>
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.WITHDRAW_WITH_EXCHANGE_CREATE,
				title: formatMessage(SettingsMessages.withdrawWithExchangeCreate),
				answer: (
					<TextNode>
						<DescriptionText
							{...SettingsMessages.withdrawWithExchangeCreateDescription}
						/>
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.MERCHANTS_PREORDERS_READ,
				title: formatMessage(SettingsMessages.merchantsPreordersRead),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.merchantsPreordersReadDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.MERCHANTS_PREORDERS_CREATE,
				title: formatMessage(SettingsMessages.merchantsPreordersCreate),
				answer: (
					<TextNode>
						<DescriptionText
							{...SettingsMessages.merchantsPreordersCreateDescription}
						/>
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.MERCHANTS_ORDERS_READ,
				title: formatMessage(SettingsMessages.merchantsOrderRead),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.merchantsOrderReadDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.MERCHANTS_ORDERS_CREATE,
				title: formatMessage(SettingsMessages.merchantsOrderCreate),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.merchantsOrderCreateDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.MERCHANTS_ORDERS_CANCEL,
				title: formatMessage(SettingsMessages.merchantsOrderCancel),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.merchantsOrderCancelDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.ACCOUNT_TRANSACTION_READ,
				title: formatMessage(SettingsMessages.accountTransactionRead),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.accountTransactionReadDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.PAYMENT_METHOD_READ,
				title: formatMessage(SettingsMessages.paymentMethodRead),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.paymentMethodReadDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.MERCHANTS_PROJECTS_READ,
				title: formatMessage(SettingsMessages.merchantsProjectsRead),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.merchantsProjectsReadDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.MERCHANTS_PROJECTS_CREATE,
				title: formatMessage(SettingsMessages.merchantsProjectsCreate),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.merchantsProjectsCreateDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.MERCHANTS_PROJECTS_WRITE,
				title: formatMessage(SettingsMessages.merchantsProjectsWrite),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.merchantsProjectsWriteDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.MERCHANTS_ORDERS_PAY,
				title: formatMessage(SettingsMessages.merchantsOrdersPay),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.merchantsOrdersPayDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.MERCHANTS_ORDERS_CALLBACK,
				title: formatMessage(SettingsMessages.merchantsOrdersCallback),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.merchantsOrdersCallbackDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.MERCHANTS_CALLBACKS_READ,
				title: formatMessage(SettingsMessages.merchantsCallbacksRead),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.merchantsCallbacksReadDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.MERCHANTS_TRANSACTIONS_READ,
				title: formatMessage(SettingsMessages.merchantsTransactionsRead),
				answer: (
					<TextNode>
						<DescriptionText
							{...SettingsMessages.merchantsTransactionsReadDescription}
						/>
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.DEPOSIT_FIAT_CREATE,
				title: formatMessage(SettingsMessages.depositFiatCreate),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.depositFiatCreateDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.DEPOSIT_FIAT_READ,
				title: formatMessage(SettingsMessages.depositFiatRead),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.depositFiatReadDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.DEPOSIT_CRYPTO_READ,
				title: formatMessage(SettingsMessages.depositCryptoRead),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.depositCryptoReadDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.PURCHASE_GIFT_CARD_READ,
				title: formatMessage(SettingsMessages.purchaseGiftCardRead),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.purchaseGiftCardReadDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.PURCHASE_GIFT_CARD_PAY,
				title: formatMessage(SettingsMessages.purchaseGiftCardPay),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.purchaseGiftCardPayDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.WITHDRAW_VOUCHER_READ,
				title: formatMessage(SettingsMessages.withdrawVoucherRead),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.withdrawVoucherReadDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.WITHDRAW_VOUCHER_CREATE,
				title: formatMessage(SettingsMessages.withdrawVoucherCreate),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.withdrawVoucherCreateDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.WITHDRAW_E_WALLET_READ,
				title: formatMessage(SettingsMessages.withdrawEWalletRead),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.withdrawEWalletReadDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
			{
				id: ApiScopes.WITHDRAW_E_WALLET_CREATE,
				title: formatMessage(SettingsMessages.withdrawEWalletCreate),
				answer: (
					<TextNode>
						<DescriptionText {...SettingsMessages.withdrawEWalletCreateDescription} />
					</TextNode>
				),
				get isChecked() {
					return formState.scopes[this.id].isChecked;
				},
			},
		],
		[formState.scopes]
	);

	const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const [isFormValid] = validate(
			{
				name: formState.name,
				ipAddress: formState.ipAddress,
				...(hasTerms && { terms: termsAccepted }),
			},
			validator,
			{
				scope: ValidationScope.OnDemand,
			}
		);
		if (!isFormValid) {
			setValidationScope(ValidationScope.OnDemand);
			return;
		}

		handleSubmit(e);
	};

	return (
		<form onSubmit={handleSubmitForm}>
			<Input
				label={SettingsMessages.apiName}
				placeholder={SettingsMessages.apiNamePlaceholder}
				labelClassName={styles.label}
				onChangeEvent={handleInputChange}
				value={formState.name}
				name="name"
				inputGroupClassName={styles.input}
				className={styles.fullWidth}
				errorMessage={validationResult.name}
			/>
			<section id="scopes">
				<TextNode className={styles.sectionTitle}>
					<FormattedMessage {...SettingsMessages.scopeGroups} />
				</TextNode>
				<TextNode className={styles.sectionDescription}>
					<FormattedMessage {...SettingsMessages.scopesGroupDescription} />
				</TextNode>
				<Accordion
					items={availableScopes}
					hasCheckbox
					onCheckboxChange={handleScopesCheckboxChange}
				/>
			</section>
			<section id="ip-whitelist">
				<TextNode className={styles.sectionTitle}>
					<FormattedMessage {...SettingsMessages.whitelistedIps} />
				</TextNode>
				<TextNode className={styles.sectionDescription}>
					<FormattedMessage {...SettingsMessages.whitelistedIpsDescription} />
				</TextNode>
				{formState.ipWhitelist.length > 0 && (
					<ul className={styles.ipList}>
						{formState.ipWhitelist.map((ip) => (
							<li key={ip} className={styles.ipItem}>
								{ip}
								<div
									className={styles.closeIcon}
									onClick={() => handleRemoveIpAddress(ip)}
								/>
							</li>
						))}
					</ul>
				)}
				<Input
					placeholder={SettingsMessages.enterIpAddressPlaceholder}
					onChangeEvent={handleInputChange}
					value={formState.ipAddress}
					name="ipAddress"
					inputGroupClassName={classNames(styles.input, styles.ipInput, {
						[styles.inputError]: validationResult.ipAddress,
					})}
					className={styles.fullWidth}
					errorMessage={validationResult.ipAddress}
				/>
				<Button
					text={SettingsMessages.additionalIpAddresses}
					type={ButtonType.LINK}
					buttonStyle={ButtonStyle.LINK}
					className={styles.addIpButton}
					onClick={handleAddIpAddress}
					plusIcon
				/>
			</section>
			{hasTerms && (
				<div className={styles.terms}>
					<Checkbox
						id="terms"
						name="terms"
						onChange={() => setTermsAccepted(!termsAccepted)}
						className={styles.termsCheckbox}
						checked={termsAccepted}
					>
						<FormattedMessage
							{...SettingsMessages.acceptTerms}
							values={{
								link: (chunks) => (
									<a
										href={`${window.location.origin}/${locale}/terms/acceptablePolicy.html`}
									>
										{chunks}
									</a>
								),
							}}
						/>
					</Checkbox>
					{validationResult.terms && (
						<div className={styles.error}>{validationResult.terms}</div>
					)}
				</div>
			)}
			{isError && error && (
				<NotificationMessage
					type={NotificationType.Error}
					style={NotificationStyle.Border}
					message={formatMessage(getErrorMessageOrDefault(error))}
					withIcon
				/>
			)}
			<Button
				text={submitButtonText}
				type={ButtonType.SUBMIT}
				buttonStyle={ButtonStyle.PRIMARY}
				className={styles.createButton}
				isLoading={isLoading}
				isDisabled={!isValid || !formState.name || (hasTerms && !termsAccepted)}
			/>
			{showCancel && (
				<Button
					text={cancelButtonText}
					type={ButtonType.BUTTON}
					buttonStyle={ButtonStyle.SECONDARY_WITHOUT_BORDER}
					className={styles.createButton}
					onClick={onCancel}
				/>
			)}
		</form>
	);
};

export default Form;
