import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import BackButton from '../../../../components/BackButton/BackButton';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import { setOrderStep, setVerificationType } from '../../../../redux/CardsState/CardsActions';
import { OrderSteps, VerificationType } from '../../../../redux/CardsState/CardsTypes';
import poaImg from './images/poa.svg';
import phoneImg from './images/phone.svg';
import styles from './ChooseVerification.module.scss';

const messages = defineMessages({
	title: {
		id: 'chooseVerification.title',
		defaultMessage: 'Address verification',
	},
	subTitle: {
		id: 'chooseVerification.subTitle',
		defaultMessage:
			'To finish ordering a virtual card <b>one of</b> the following verification must be completed:',
	},
	poaTitle: {
		id: 'chooseVerification.poaTitle',
		defaultMessage: 'Proof of Address',
	},
	phoneTitle: {
		id: 'chooseVerification.phoneTitle',
		defaultMessage: 'Phone number verification',
	},
	separatorText: {
		id: 'chooseVerification.or',
		defaultMessage: 'or',
	},
});

const ChooseVerification = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const onClick = (option: VerificationType) => {
		dispatch(setOrderStep(OrderSteps.VERIFICATION));
		dispatch(
			setVerificationType(
				option === VerificationType.POA ? VerificationType.POA : VerificationType.PHONE
			)
		);
	};

	return (
		<div className={styles.container}>
			<BackButton
				className={styles.back}
				onClick={() => dispatch(setOrderStep(OrderSteps.DELIVERY_ADDRESS))}
			/>
			<div className={styles.formContainer}>
				<InfoHead
					title={messages.title}
					subTitle={
						formatMessage(messages.subTitle, {
							b: (chunks) => <b>{chunks}</b>,
						}) as JSX.Element
					}
				/>
				<div className={styles.selectContainer}>
					<div onClick={() => onClick(VerificationType.POA)} className={styles.option}>
						<div className={styles.optionTitle}>
							<img src={poaImg} />
							<p>{formatMessage(messages.poaTitle)}</p>
						</div>
						<FontAwesomeIcon className={styles.icon} icon={faChevronRight} size="1x" />
					</div>
					<div className={styles.separator}>
						<div />
						<p>{formatMessage(messages.separatorText)}</p>
						<div />
					</div>
					<div onClick={() => onClick(VerificationType.PHONE)} className={styles.option}>
						<div className={styles.optionTitle}>
							<img src={phoneImg} />
							<p>{formatMessage(messages.phoneTitle)}</p>
						</div>
						<FontAwesomeIcon className={styles.icon} icon={faChevronRight} size="1x" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChooseVerification;
