import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import queryString, { ParsedQuery } from 'query-string';
import { HistoryTableData } from '../../../../hooks/useHistoryTableData';
import useDidMount from '../../../../hooks/useDidMount';
import useQueryParams from '../../../../hooks/useQueryParams';
import { RootState } from '../../../../redux/Store';
import {
	getInstantHistory,
	resetConvertHistory,
} from '../../../../redux/ConvertHistoryState/ConvertHistoryActions';

const useInstantHistoryDataProvider = (): HistoryTableData<any> => {
	const { search } = useLocation();

	const didMount = useDidMount();
	const dispatch = useDispatch();
	const { type }: ParsedQuery<any> = queryString.parse(search);
	const [[page, startDate, endDate]] = useQueryParams({
		page: undefined,
		startDate: undefined,
		endDate: undefined,
	});

	const { totalCount, isLoading, data } = useSelector(
		(state: RootState) => state.ConvertHistoryState
	);

	useEffect(() => {
		if (didMount) {
			dispatch(
				getInstantHistory(typeof page === 'string' ? +page : page, startDate, endDate)
			);
		}
		return () => {
			dispatch(resetConvertHistory());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, didMount, startDate, endDate, type]);

	return {
		data,
		isLoading,
		totalCount,
	};
};

export default useInstantHistoryDataProvider;
