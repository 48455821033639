import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import successIconPath from '../../../images/status/success.svg';
import pendingIconPath from '../../../images/status/pending.svg';
import failureIconPath from '../../../images/status/failure.svg';
import Button, { ButtonType } from '../../../components/Button/Button';
import styles from './DepositResult.module.scss';
import baseMsg from '../../../messages/base.messages';
import Seo from '../../../components/Seo/Seo';
import depositMessages from '../../../redux/DepositState/DepositMessages';
import TestIds from '../../../test/TestIds';

const messages = defineMessages({
	success: {
		id: 'result_status.success',
		defaultMessage: 'Deposit successful',
	},
	failure: {
		id: 'result_status.failure',
		defaultMessage: 'Deposit failed',
	},
	pending: {
		id: 'result_status.pending',
		defaultMessage: 'Deposit pending',
	},
});

export enum ResultStatus {
	PENDING = 'PENDING',
	SUCCESS = 'SUCCESS',
	FAILURE = 'FAILURE',
}

interface ResultStatusInterface {
	status: ResultStatus;
	previousPageLink: string;
	children?: React.ReactNode;
	className?: string;
}

const DepositResult = ({
	status,
	previousPageLink,
	className,
	children,
}: ResultStatusInterface) => {
	const getStatusImage = () => {
		switch (status) {
			case ResultStatus.FAILURE:
				return {
					img: <img src={failureIconPath} alt="failure" />,
					text: <FormattedMessage {...messages.failure} />,
				};
			case ResultStatus.PENDING:
				return {
					img: <img src={pendingIconPath} alt="pending" />,
					text: <FormattedMessage {...messages.pending} />,
				};
			case ResultStatus.SUCCESS:
			default:
				return {
					img: <img src={successIconPath} alt="success" />,
					text: <FormattedMessage {...messages.success} />,
				};
		}
	};

	const { img, text } = getStatusImage();

	return (
		<>
			<Seo title={depositMessages.metaCryptoDepositsTitle} />
			<div className={classNames(styles.container, className)}>
				<div className={styles.statusImg}>{img}</div>
				<div className={styles.statusText} data-cy={TestIds.depositsStatus}>
					{text}
				</div>
				{children}
				<Button
					type={ButtonType.LINK}
					link={previousPageLink}
					text={baseMsg.close}
					className={styles.button}
					data-cy={TestIds.button}
				/>
			</div>
		</>
	);
};

export default DepositResult;
