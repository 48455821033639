import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { NetworkNameCode } from '../../redux/AccountsState/AccountsTypes';
import config from '../../configs/config';

const noNetwork = [CurrencyEnum.ETH, CurrencyEnum.SOL, CurrencyEnum.BTC];

export const noBuy = [CurrencyEnum.XRP, CurrencyEnum.XLM, CurrencyEnum.XEM];

export const formatNetworkName = (networkName: NetworkNameCode, currencyCode: CurrencyEnum) => {
	return `${currencyCode.toLowerCase()}${(() => {
		if (noNetwork.includes(currencyCode)) return '';

		switch (networkName) {
			case NetworkNameCode.ETH:
				return '_ethereum';
			case NetworkNameCode.BSC:
				return '_bsc';
			case NetworkNameCode.TRX:
				return '_tron';
			case NetworkNameCode.DASH:
				return '_dash';
			case NetworkNameCode.BTC:
				return '_bitcoin';
			case NetworkNameCode.ADA:
				return '_cardano';
			case NetworkNameCode.ALGO:
				return '_algorand';
			case NetworkNameCode.AVAXC:
				return '_avaxc';
			case NetworkNameCode.BCH:
				return '_bitcoincash';
			case NetworkNameCode.DOT:
				return '_polkadot';
			case NetworkNameCode.LTC:
				return '_litecoin';
			case NetworkNameCode.NEAR:
				return '_near';
			case NetworkNameCode.SOL:
				return '_solana';
			case NetworkNameCode.XEM:
				return '_nem';
			case NetworkNameCode.XLM:
				return '_stellar';
			case NetworkNameCode.XRP:
				return '_ripple';
			default:
				return '';
		}
	})()}`;
};

export default (
	refCurrency: CurrencyEnum,
	parsedWalletNameForOnramper: string,
	walletAddress: string
) =>
	`https://buy.onramper.com?themeName=bluey&apiKey=${
		config.ONRAMPER_API_KEY
	}&mode=buy&defaultFiat=${refCurrency.toLowerCase()}&defaultCrypto=${parsedWalletNameForOnramper}&onlyCryptos=${parsedWalletNameForOnramper}&wallets=${parsedWalletNameForOnramper}:${walletAddress}`;
