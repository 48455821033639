import { createContext } from 'react';
import { RemoteData, RemoteStatus } from '../../../../../interfaces/RemoteData';
import {
	Project,
	ProjectCreateForm,
	ProjectsCreateViewState,
} from '../../../../../redux/MerchantsState/MerchantTypes';

export default createContext<{
	form: ProjectCreateForm;
	action: RemoteData<Project>;
	state: ProjectsCreateViewState;
	updateForm: (value: Partial<ProjectCreateForm>) => void;
	setAction: (value: RemoteData<Project>) => void;
	setState: (value: ProjectsCreateViewState) => void;
}>({
	form: {} as ProjectCreateForm,
	action: { status: RemoteStatus.None },
	state: ProjectsCreateViewState.None,
	updateForm: () => {},
	setAction: () => {},
	setState: () => {},
});
