/* eslint-disable no-param-reassign */
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import { fromEntries } from '../../../../helpers/objectHelper/objectHelper';
import {
	PreordersDataActionType,
	PreordersDataUpdateOrdersAction,
	PreordersDataFetchOrderFulfilledAction,
	PreordersDataFetchOrderPendingAction,
	PreordersDataFetchOrderRejectedAction,
	PreordersDataUpdateOrderAction,
	PreordersDataState,
} from './PreordersDataTypes';

const initialState: PreordersDataState = {
	preorders: {},
};

export default (
	state = initialState,
	action:
		| PreordersDataUpdateOrdersAction
		| PreordersDataFetchOrderFulfilledAction
		| PreordersDataFetchOrderPendingAction
		| PreordersDataFetchOrderRejectedAction
		| PreordersDataUpdateOrderAction
): PreordersDataState => {
	switch (action.type) {
		case PreordersDataActionType.FETCH_PREORDER_PENDING:
			return {
				...state,
				preorders: {
					...state.preorders,
					[action.payload]: { status: RemoteStatus.InProgress },
				},
			};
		case PreordersDataActionType.FETCH_PREORDER_REJECTED:
			return {
				...state,
				preorders: {
					...state.preorders,
					[action.payload]: { status: RemoteStatus.Error },
				},
			};
		case PreordersDataActionType.FETCH_PREORDER_FULFILLED:
			return {
				...state,
				preorders: {
					...state.preorders,
					[action.payload.id]: {
						status: RemoteStatus.Done,
						data: {
							...action.payload,
							created: action.payload.created,
						},
					},
				},
			};
		case PreordersDataActionType.UPDATE_PREORDER:
			return {
				...state,
				preorders: {
					...state.preorders,
					[action.payload.id]: {
						status: RemoteStatus.Done,
						data: {
							...action.payload,
							created: action.payload.created,
						},
					},
				},
			};
		case PreordersDataActionType.UPDATE_PREORDERS:
			return {
				...state,
				preorders: {
					...state.preorders,
					...fromEntries(
						action.payload.map((x) => [
							x.id,
							{
								status: RemoteStatus.Done,
								data: {
									...x,
									created: x.created,
								},
							},
						])
					),
				},
			};
		default:
			return state;
	}
};
