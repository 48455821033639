import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { defineMessages, FormattedMessage, useIntl, MessageDescriptor } from 'react-intl';
import classNames from 'classnames';
import { CurrencyEnum, CurrencyIcon } from '@spectrocoin/sc-currencies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faClock, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faRightLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { LinkedCardStatus, ProviderType } from '../../redux/DepositState/DepositTypes';
import SvgIcon from '../SvgIcon/SvgIcon';
import styles from './ListItem.module.scss';
import Loader from '../Loader/Loader';
import { formatPrecision } from '../../helpers/currencyHelper/currencyHelper';
import useDurationString from '../../hooks/useDurationString';
import RemoveCardModal from '../Modal/RemoveCardModal/RemoveCardModal';
import toggleModal from '../../redux/ModalState/ModalActions';
import baseMsg from '../../messages/base.messages';
import TestIds, { formatTestId } from '../../test/TestIds';
import useFormatAmount from '../../hooks/useFormatAmount';
import { RootState } from '../../redux/Store';
import useIBANRedirect from '../../hooks/useIBANRedirect';

export enum ListItemType {
	ANCHOR_LINK = 'anchorLink',
	LINK = 'link',
	CLICK_HANDLER = 'clickHandler',
	NON_CLICKABLE = 'nonClickable',
}

interface ListItemProps {
	id?: string;
	status?: LinkedCardStatus | null;
	disabled?: boolean;
	clickableWhenDisabled?: boolean;
	duration?: string | null;
	link?: string;
	logo: string;
	onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
	paymentMethodCurrencies?: string[];
	title: string;
	type?: ListItemType;
	withDescription?: boolean;
	dynamicIcon?: boolean;
	giftCards?: boolean;
	limits?: {
		baseCurrencyCode?: string;
		fee?: number;
		maxDepositAmount?: number;
		maxFeeAmount?: number;
		minDepositAmount?: number;
		minFeeAmount?: number;
	};
	isRemovalEnabled?: boolean;
	fetchData?: (val: boolean) => void;
	isIBAN?: boolean;
	providerType?: ProviderType;
	providerKey?: string;
}

const messages = defineMessages({
	RISKY_CARD: {
		id: 'linkedCards.RISKY_CARD',
		defaultMessage: 'Risky card',
	},
	EXPIRED: {
		id: 'linkedCards.EXPIRED',
		defaultMessage: 'Expired',
	},
	BLOCKED: {
		id: 'linkedCards.BLOCKED',
		defaultMessage: 'Blocked',
	},
	somethingWentWrong: {
		id: 'linkedCards.somethingWentWrong',
		defaultMessage: 'Something went wrong. Please try again later.',
	},
	additionalVerificationNeeded: {
		id: 'base.additionalVerificationNeeded',
		defaultMessage: 'Additional verification needed',
	},
	additionalVerificationInProgress: {
		id: 'base.additionalVerificationInProgress',
		defaultMessage: 'Additional verification in progress',
	},
	minDepositAmount: {
		id: 'base.minDepositAmount',
		defaultMessage: 'Min deposit amount',
	},
	upToLimitText: {
		id: 'base.upToLimit',
		defaultMessage: 'Up to',
	},
	minFee: {
		id: 'base.minFee',
		defaultMessage: 'Min fee',
	},
	maxFee: {
		id: 'base.maxFee',
		defaultMessage: 'Max fee',
	},
	duration: {
		id: 'base.takes_up_to',
		defaultMessage: 'Takes up to: {duration}',
	},
});

const ListItem = ({
	clickableWhenDisabled,
	duration,
	link,
	onClick,
	logo,
	title,
	id,
	fetchData,
	limits = {},
	paymentMethodCurrencies = [],
	disabled = false,
	isRemovalEnabled = false,
	withDescription = false,
	dynamicIcon = false,
	giftCards = false,
	status = null,
	type = ListItemType.LINK,
	isIBAN,
	providerType,
	providerKey,
}: ListItemProps) => {
	const [icon, setIcon] = useState('');
	const { formatMessage, locale } = useIntl();
	const { isMobile } = useSelector((state: RootState) => state.AppState);
	const checkIBANRedirect = useIBANRedirect();
	const dispatch = useDispatch();
	const amountFormatter = useFormatAmount();
	const [error, setError] = useState<MessageDescriptor | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [blockType, setBlockType] = useState<ListItemType>(type);
	const wallet = useSelector((state: RootState) => state?.AccountsState).wallets?.find(
		(o) => o.currencyCode === CurrencyEnum.EUR
	)!;

	const [linkURL, setLinkURL] = useState<string | undefined>(link);

	useEffect(() => {
		if (dynamicIcon) {
			setIcon(logo);
		} else {
			void (async () => {
				try {
					const module = await import(
						`../../images/payment_method_icons/${logo || 'default'}.${
							giftCards ? 'png' : 'svg'
						}`
					);
					setIcon(module.default);
				} catch {
					const fallbackModule = await import(
						`../../images/payment_method_icons/default.svg`
					);
					setIcon(fallbackModule.default);
				}
			})();
		}
	}, [logo, dynamicIcon, giftCards]);

	useEffect(() => {
		setBlockType(isRemovalEnabled ? ListItemType.NON_CLICKABLE : type);
	}, [isRemovalEnabled, type]);

	const { baseCurrencyCode, fee, maxDepositAmount } = limits;

	const getFeesAndLimits = () => {
		const maxDepositText = maxDepositAmount
			? `${formatMessage(messages.upToLimitText)} ${amountFormatter(
					formatPrecision(maxDepositAmount, CurrencyEnum[baseCurrencyCode!])
			  )} ${baseCurrencyCode}`
			: null;

		const feeText = `${fee ? parseFloat(String(fee)) : 0}%`;

		return {
			feeText,
			maxDepositText,
		};
	};

	const getDisabledMessageComponent = (cardStatus: LinkedCardStatus | null) => {
		if (
			cardStatus === LinkedCardStatus.BLOCKED ||
			cardStatus === LinkedCardStatus.EXPIRED ||
			cardStatus === LinkedCardStatus.RISKY_CARD
		)
			return <FormattedMessage {...messages[cardStatus]} />;
		return null;
	};

	const removeCard = (cId: string | null) => {
		if (cId) {
			dispatch(
				toggleModal(
					<RemoveCardModal
						cardId={cId}
						setIsLoading={setIsLoading}
						setError={setError}
						fetchData={fetchData}
						cardNumber={title}
					/>
				)
			);
		}
		return null;
	};

	// set linkURL to claim iban or verification
	useEffect(() => {
		if (
			providerType === ProviderType.WIRE &&
			providerKey === 'pervesk_swift' &&
			isIBAN &&
			!wallet?.iban &&
			wallet?.id
		) {
			void checkIBANRedirect().then(({ url, verified }) => {
				setLinkURL(url);
				setBlockType(verified ? ListItemType.LINK : ListItemType.ANCHOR_LINK);
			});
		}
	}, [isIBAN, locale, providerKey, providerType, wallet]);

	const { maxDepositText, feeText } = getFeesAndLimits();
	const durationString = useDurationString(duration);
	const renderContent = () => (
		<div className={styles.flex}>
			<div className={styles.itemWrapper}>
				<div
					className={classNames(styles.icon, {
						[styles.heightAuto]: dynamicIcon,
					})}
				>
					{icon && (
						<SvgIcon
							src={icon}
							className={classNames(styles.iconWrapper, {
								[styles.heightAuto]: dynamicIcon,
							})}
						/>
					)}
				</div>
				<div className={styles.body}>
					<div
						className={classNames(styles.titleWrapper, {
							[styles.noBottomMargin]: !withDescription,
						})}
					>
						<div className={styles.title}>{title}</div>
					</div>
					{disabled && status !== null ? (
						<div className={styles.descriptionWrapper}>
							<div className={styles.description}>
								{getDisabledMessageComponent(status)}
							</div>
						</div>
					) : withDescription && !isMobile ? (
						<div className={styles.descriptionWrapper}>
							<div className={styles.description}>
								{durationString && (
									<div className={styles.duration}>
										<FontAwesomeIcon icon={faClock} />
										{durationString}
									</div>
								)}
								{maxDepositAmount && (
									<div className={styles.limits}>
										<FontAwesomeIcon icon={faRightLeft} />
										{maxDepositText}
									</div>
								)}
								<div className={styles.fee}>
									{formatMessage(baseMsg.fee)}: {feeText}
								</div>
							</div>
							<div
								className={styles.currencies}
								data-cy={formatTestId(TestIds.listItemCurrencies, id)}
							>
								{paymentMethodCurrencies
									.filter((c) => c)
									.map((c) => (
										<CurrencyIcon
											key={c}
											className={styles.currency}
											currencyType={c as CurrencyEnum}
											data-cy={formatTestId(TestIds.currencyIcon_0, c)}
										/>
									))}
							</div>
						</div>
					) : null}
				</div>
				{isRemovalEnabled && paymentMethodCurrencies.length > 0 && (
					<FontAwesomeIcon
						data-cy={`DepositListItem_removeCard_button`}
						onClick={() => removeCard(id || null)}
						className={styles.closeIcon}
						icon={faTimes}
					/>
				)}
				{!isRemovalEnabled && (
					<FontAwesomeIcon className={styles.chevronRight} icon={faChevronRight} />
				)}
			</div>
			{withDescription && isMobile && (
				<div className={styles.mobileDescriptionWrapper}>
					<div className={styles.description}>
						{durationString && (
							<div className={styles.duration}>
								<FontAwesomeIcon icon={faClock} />
								{durationString}
							</div>
						)}
						<div className={styles.feesAndLimits}>
							{maxDepositAmount && (
								<div className={styles.limits}>
									<FontAwesomeIcon icon={faRightLeft} />
									{maxDepositText}
								</div>
							)}
							<div className={styles.fee}>
								{formatMessage(baseMsg.fee)}: {feeText}
							</div>
						</div>
					</div>
					<div
						className={styles.currencies}
						data-cy={formatTestId(TestIds.listItemCurrencies, id)}
					>
						{paymentMethodCurrencies
							.filter((c) => c)
							.map((c) => (
								<CurrencyIcon
									key={c}
									className={styles.currency}
									currencyType={c as CurrencyEnum}
									data-cy={formatTestId(TestIds.currencyIcon_0, c)}
								/>
							))}
					</div>
				</div>
			)}
		</div>
	);

	return (
		<li
			className={classNames(styles.listItem, {
				[styles.disabled]: disabled || isLoading,
				[styles.clickableWhenDisabled]: clickableWhenDisabled,
			})}
		>
			{isLoading && <Loader className={styles.loader} />}
			{blockType === ListItemType.LINK && (
				<Link
					className={styles.link}
					to={linkURL!}
					data-cy={formatTestId(TestIds.listItem, id)}
				>
					{renderContent()}
				</Link>
			)}
			{blockType === ListItemType.ANCHOR_LINK && (
				<a
					className={styles.link}
					href={linkURL!}
					rel="noopener noreferrer"
					data-cy={formatTestId(TestIds.listItem, id)}
				>
					{renderContent()}
				</a>
			)}
			{blockType === ListItemType.CLICK_HANDLER && (
				<div
					className={styles.link}
					onClick={onClick}
					data-cy={formatTestId(TestIds.listItem, id)}
				>
					{renderContent()}
				</div>
			)}
			{blockType === ListItemType.NON_CLICKABLE && (
				<div className={styles.link} data-cy={formatTestId(TestIds.listItem, id)}>
					{renderContent()}
				</div>
			)}
			{error && isRemovalEnabled && (
				<div className={styles.error} data-cy={TestIds.listItemError}>
					<FormattedMessage {...error} />
				</div>
			)}
		</li>
	);
};

export default ListItem;
