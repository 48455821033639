import {
	ConvertActionTypes,
	ConvertState,
	SetConvertPairsAction,
	SetIsRecurringEnabledAction,
} from './ConvertTypes';

const initialState: ConvertState = {
	pairs: null,
	isRecurringEnabled: false,
};

export default (
	state = initialState,
	action: SetConvertPairsAction | SetIsRecurringEnabledAction
) => {
	switch (action.type) {
		case ConvertActionTypes.SET_CONVERT_PAIRS:
			return {
				...state,
				pairs: action.data,
			};
		case ConvertActionTypes.SET_IS_RECURRING_ENABLED:
			return {
				...state,
				isRecurringEnabled: action.data,
			};
		default:
			return state;
	}
};
