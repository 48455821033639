import { FC } from 'react';
import { Route, Switch } from 'react-router';
import useMerchantRoutes, { MerchantRoutes } from '../../../hooks/useMerchantRoutes';
import PayoutsHistory from './PayoutsHistory/PayoutsHistory';
import PayoutSummary from './PayoutSummary/PayoutSummary';

const CryptoPayouts: FC = () => {
	const { getPath } = useMerchantRoutes();

	return (
		<Switch>
			<Route exact path={getPath(MerchantRoutes.CryptoPayouts)} component={PayoutsHistory} />
			<Route
				exact
				path={getPath(MerchantRoutes.CryptoPayoutById)}
				component={PayoutSummary}
			/>
		</Switch>
	);
};

export default CryptoPayouts;
