import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { WithdrawType } from '../../../../redux/WithdrawState/WithdrawTypes';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import withdrawIconPath from './images/withdraw.svg';
import styles from './WithdrawSummary.module.scss';
import VoucherSummary from '../../VoucherWithdraws/VoucherSummary/VoucherSummary';
import OffchainSummary from '../../Offchain/OffchainSummary/OffchainSummary';
import EWalletSummary from '../../EWalletWithdraw/EWalletSummary/EWalletSummary';
import CryptoSummary from '../../CryptoWithdraws/CryptoSummary/CryptoSummary';
import GiftCardsSummary from '../../GiftCards/GiftCardsSummary/GiftCardsSummary';
import BankWithdrawSummary from '../../BankWithdraw/BankWithdrawSummary/BankWithdrawSummary';
import CloseIcon from '../../../../components/CloseIcon/CloseIcon';

const messages = defineMessages({
	withdrawId: {
		id: 'cryptoSummaryModal.withdrawId',
		defaultMessage: '{currency} withdrawal number',
	},
	title: {
		id: 'cryptoSummaryModal.title',
		defaultMessage: '{currency} withdrawal summary',
	},
	eWalletTitle: {
		id: 'cryptoSummaryModal.eWalletTitle',
		defaultMessage: '{provider} withdrawal summary',
	},
});

interface SummaryModalProps {
	formData: any;
	currency: CurrencyEnum;
	type: WithdrawType;
	provider?: string;
	providerTitle?: string;
	hasIban?: boolean;
	network?: string | null;
	feeAccountId?: string;
}

const WithdrawSummary = ({
	formData,
	currency,
	type,
	provider,
	providerTitle,
	hasIban,
	network,
	feeAccountId,
}: SummaryModalProps) => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	return (
		<div className={styles.container}>
			<CloseIcon onClick={() => dispatch(toggleModal())} />
			<img src={withdrawIconPath} alt="Withdrawal icon" />
			{[
				WithdrawType.VOUCHER,
				WithdrawType.OFFCHAIN,
				WithdrawType.CRYPTOCURRENCY,
				WithdrawType.BANK,
				WithdrawType.EWALLET,
			].includes(type) && (
				<h3 className={styles.title}>
					{[
						WithdrawType.VOUCHER,
						WithdrawType.OFFCHAIN,
						WithdrawType.CRYPTOCURRENCY,
						WithdrawType.BANK,
					].includes(type) && formatMessage(messages.title, { currency })}
					{type === WithdrawType.EWALLET &&
						formatMessage(messages.eWalletTitle, { provider })}
				</h3>
			)}
			<ul className={styles.list}>
				{type === WithdrawType.BANK && (
					<BankWithdrawSummary
						data={formData}
						dateClass={styles.date}
						infoClass={styles.info}
						statusClass={styles.status}
						provider={provider}
						hasIban={hasIban}
					/>
				)}
				{type === WithdrawType.VOUCHER && (
					<VoucherSummary
						data={formData}
						currency={currency}
						dateClass={styles.date}
						infoClass={styles.info}
					/>
				)}
				{type === WithdrawType.EWALLET && provider && (
					<EWalletSummary
						data={formData}
						dateClass={styles.date}
						infoClass={styles.info}
						provider={provider}
						providerTitle={providerTitle}
					/>
				)}
				{type === WithdrawType.OFFCHAIN && (
					<OffchainSummary data={formData} currency={currency} infoClass={styles.info} />
				)}
				{type === WithdrawType.CRYPTOCURRENCY && (
					<CryptoSummary
						data={formData}
						currency={currency}
						dateClass={styles.date}
						infoClass={styles.info}
						statusClass={styles.status}
						network={network}
						feeAccountId={feeAccountId}
					/>
				)}
				{[WithdrawType.GIFT, WithdrawType.TOPUP].includes(type) && (
					<GiftCardsSummary
						data={formData}
						dateClass={styles.date}
						infoClass={styles.info}
						titleClass={styles.title}
					/>
				)}
			</ul>
		</div>
	);
};

export default WithdrawSummary;
