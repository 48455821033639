/* eslint-disable @typescript-eslint/no-var-requires */
// import dotenv from 'dotenv';
import { MessageDescriptor, useIntl } from 'react-intl';

// eslint-disable-next-line import/no-dynamic-require
const supportedLanguages = require(`../../../env/config.${
	process.env.REACT_APP_ENV === 'local' ? 'local' : 'prod'
}.js`).SUPPORTED_LANGUAGES;

const getLanguageFromPath = (path: string) => {
	if (!path) {
		return 'en';
	}
	const language = path.split('/')[1];
	return supportedLanguages.includes(language) ? language : 'en';
};

export default getLanguageFromPath;

export const useFormatRoute = (route?: MessageDescriptor | string) => {
	const { formatMessage, locale } = useIntl();

	if (typeof route === 'string') {
		return route;
	}

	if (!route) {
		return locale === 'en' ? '/' : `/${locale}`;
	}

	return `/${locale}/${formatMessage(route)}.html`;
};
