import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { FC } from 'react';
import LimitDuration from '../../../enums/LimitDuration';
import {
	ActivePaymentMethodFee,
	PaymentMethodLimit,
} from '../../../redux/DepositState/DepositTypes';
import { formatPrecision } from '../../../helpers/currencyHelper/currencyHelper';
import baseMsg from '../../../messages/base.messages';
import TestIds from '../../../test/TestIds';
import useFormatAmount from '../../../hooks/useFormatAmount';
import styles from './PaymentMethodLimits.module.scss';
import { HowToType } from '../HowTo/HowTo';

const messages = defineMessages({
	limits: {
		id: 'paymentProviderLimits.heading',
		defaultMessage: '{paymentMethodName} limits',
	},
	minAmount: {
		id: 'base.minAmount',
		defaultMessage: 'Min {type} amount',
	},
	maxAmount: {
		id: 'base.maxAmount',
		defaultMessage: 'Max {type} amount',
	},
	minFee: {
		id: 'base.minFee',
		defaultMessage: 'Min fee',
	},
	maxFee: {
		id: 'base.maxFee',
		defaultMessage: 'Max fee',
	},
	limitDAILY: {
		id: 'base.dailyLimit',
		defaultMessage: 'Daily limit',
	},
	limitWEEKLY: {
		id: 'base.weeklyLimit',
		defaultMessage: 'Weekly limit',
	},
	limitMONTHLY: {
		id: 'base.monthlyLimit',
		defaultMessage: 'Monthly limit',
	},
	limitANNUAL: {
		id: 'base.yearlyLimit',
		defaultMessage: 'Annual limit',
	},
});

const durationSortOrder = {
	[LimitDuration.DAILY]: 0,
	[LimitDuration.WEEKLY]: 1,
	[LimitDuration.MONTHLY]: 2,
	[LimitDuration.ANNUAL]: 3,
};

interface PaymentMethodLimitsProps {
	depositCurrencyCode?: string;
	activePaymentMethodFees?: any[];
	baseCurrencyCode?: string;
	fee?: any;
	limits?: any[];
	maxAmount?: any;
	maxFee?: any;
	minAmount?: any;
	minFee?: any;
	paymentMethodName?: MessageDescriptor | string;
	title?: string;
	type: HowToType;
}

interface Fee {
	percent: number | string | undefined;
	min: number | string | undefined;
	max: number | string | undefined;
}

const PaymentMethodLimits: FC<PaymentMethodLimitsProps> = ({
	depositCurrencyCode,
	activePaymentMethodFees,
	baseCurrencyCode,
	fee: feePercent,
	limits,
	maxAmount,
	maxFee,
	minAmount,
	minFee,
	paymentMethodName,
	title,
	type,
}) => {
	const amountFormatter = useFormatAmount();
	const depositCurrencyBasedFee = activePaymentMethodFees?.find(
		(activeFee) => activeFee?.currencyType === depositCurrencyCode
	);

	const fee: Fee = {
		percent: depositCurrencyBasedFee?.fee || feePercent,
		min: depositCurrencyBasedFee?.minFee || minFee,
		max: depositCurrencyBasedFee?.maxFee || maxFee,
	};

	if (
		limits?.length === 0 &&
		!maxAmount &&
		!minAmount &&
		!fee?.percent &&
		!fee?.min &&
		!fee?.max
	) {
		return null;
	}

	const sortedPaymentMethodLimits = limits
		? [...limits]?.sort((a, b) =>
				durationSortOrder[a?.limitDuration] > durationSortOrder[b?.limitDuration] ? 1 : -1
		  )
		: [];
	return (
		<div data-cy={TestIds.paymentMethodLimist}>
			<h2 className={styles.heading}>
				{title || (
					<FormattedMessage
						{...messages.limits}
						values={{ paymentMethodName: paymentMethodName as any }}
					/>
				)}
			</h2>
			{!!minAmount && (
				<RowItem
					label={messages.minAmount}
					value={`${amountFormatter(
						formatPrecision(minAmount, CurrencyEnum[baseCurrencyCode!])
					)} ${baseCurrencyCode}`}
					type={type}
				/>
			)}
			{!!maxAmount && (
				<RowItem
					label={messages.maxAmount}
					value={`${amountFormatter(
						formatPrecision(maxAmount, CurrencyEnum[baseCurrencyCode!])
					)} ${baseCurrencyCode}`}
					type={type}
				/>
			)}
			{!!fee?.percent && (
				<RowItem
					label={baseMsg.fee}
					value={`${parseFloat(String(fee?.percent))}%`}
					type={type}
				/>
			)}
			{!!fee?.min && (
				<RowItem
					label={messages.minFee}
					value={`${amountFormatter(
						formatPrecision(fee?.min, CurrencyEnum[baseCurrencyCode!])
					)} ${baseCurrencyCode}`}
					type={type}
				/>
			)}
			{!!fee?.max && (
				<RowItem
					label={messages.maxFee}
					value={`${formatPrecision(
						fee?.max,
						CurrencyEnum[baseCurrencyCode!]
					)} ${baseCurrencyCode}`}
					type={type}
				/>
			)}
			{sortedPaymentMethodLimits
				// .filter((limit: PaymentMethodLimit) => limit.currencyCode === baseCurrencyCode)
				?.map((limit: PaymentMethodLimit) => (
					<RowItem
						key={limit?.limitDuration}
						label={messages[`limit${limit?.limitDuration}`]}
						value={`${formatPrecision(
							limit?.limitAmount,
							CurrencyEnum[baseCurrencyCode!]
						)} ${baseCurrencyCode}`}
						type={type}
					/>
				))}
		</div>
	);
};

export default PaymentMethodLimits;

interface RowItemProps {
	label: MessageDescriptor;
	value: string;
	type: HowToType;
}

const RowItem: FC<RowItemProps> = ({ label, value, type }) => (
	<div data-cy={label.id} className={styles.row}>
		<div className={styles.label}>
			<FormattedMessage
				{...label}
				values={{
					type:
						type === HowToType.WITHDRAW ? (
							<FormattedMessage id="howTo.withdraw" defaultMessage="withdraw" />
						) : (
							<FormattedMessage id="howTo.deposit" defaultMessage="deposit" />
						),
				}}
			/>
		</div>
		<div className={styles.value}>{value}</div>
	</div>
);
