import { useIntl } from 'react-intl';
import { WithdrawHistoryItem } from '../../../../redux/WithdrawHistoryState/WithdrawHistoryTypes';
import statusMsg from '../../../../messages/status.messages';
import { DetailsModalType } from '../../../../components/Modal/DetailsModal/DetailsModal';
import HistoryListItem from '../../../../components/HistoryTable/HistoryListItem/HistoryListItem';

interface Props {
	data: WithdrawHistoryItem[];
}

const GiftCardsWithdrawHistory = ({ data }: Props): JSX.Element => {
	const { formatMessage } = useIntl();
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{data?.length > 0 &&
				data.map(
					({
						itemTitle,
						id,
						date,
						payAmount,
						payCurrencyCode,
						status,
						receiver,
						code,
						sellAmount,
						sellCurrencyCode,
					}: WithdrawHistoryItem) => (
						<HistoryListItem
							key={id}
							title={itemTitle}
							code={code}
							dateCreated={date}
							currency={payCurrencyCode}
							status={formatMessage(statusMsg[status])}
							detailsModalType={DetailsModalType.GIFT}
							withdrawAccount={receiver}
							amount={payAmount}
							sellAmount={sellAmount}
							sellCurrencyCode={sellCurrencyCode}
						/>
					)
				)}
		</>
	);
};

export default GiftCardsWithdrawHistory;
