import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { Project, ProjectTestStatus } from '../ProjectsTypes';

export type ProjectEditForm = {
	name: string;
	description: string | null;
	autoConvert: string | null;
	currencies: CurrencyEnum[];
	publicKey: string | null;
	testStatus: ProjectTestStatus | null;
	isEnabled: boolean;
	forVerifiedPayers: boolean;
	forPayerFee: boolean;
};

export type ProjectsEditState = {};

export enum ProjectsEditActionType {
	ACTION_FULFILLED = 'MERCHANTS/PROJECTS/EDIT/ACTION/FULFILLED',
}

export type ProjectsEditActionFulfilledAction = {
	type: ProjectsEditActionType.ACTION_FULFILLED;
	payload: Project;
};
