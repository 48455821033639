/* eslint-disable no-param-reassign */
import {
	CardsActionType,
	OrderSteps,
	CardsState,
	SetOrderStepAction,
	SetOrderCardFormDataAction,
	ResetOrderCardFormAction,
	SetIsCardUserAction,
	SetAllCardsAction,
	SetProductOptions,
	SetOrderIdAction,
	SetIsLoadingCardListAction,
	SetCardByIdAction,
	SetGeneralErrorAction,
	SetCompanyMovementAction,
	VerificationType,
	SetVerificationTypeAction,
	SetAvailableCountriesAction,
	SetRequiresVerificationAction,
} from './CardsTypes';

const initialState: CardsState = {
	isLoading: false,
	verificationType: VerificationType.POA,
	cardsList: null,
	isCardUser: null,
	orderStep: OrderSteps.TERMS,
	formData: null,
	availableCountries: [],
	requiresVerification: false,
	productOptions: [],
	orderData: null,
	generalError: false,
	companyMovement: null,
};

export default (
	state = initialState,
	action:
		| SetOrderStepAction
		| SetOrderCardFormDataAction
		| ResetOrderCardFormAction
		| SetIsCardUserAction
		| SetAllCardsAction
		| SetProductOptions
		| SetOrderIdAction
		| SetIsLoadingCardListAction
		| SetCardByIdAction
		| SetGeneralErrorAction
		| SetCompanyMovementAction
		| SetVerificationTypeAction
		| SetAvailableCountriesAction
		| SetRequiresVerificationAction
) => {
	switch (action.type) {
		case CardsActionType.SET_CARD_BY_ID:
			return {
				...state,
				generalError: false,
				cardsList: [
					...state?.cardsList!.filter((singleAccount) => {
						if (singleAccount.id === action.data.id) {
							singleAccount.accountBalance = action.data.accountBalance;
							singleAccount.status = action.data.status;
							singleAccount.created3dsPassword = action.data.created3dsPassword;
							singleAccount.enabled3DSecure = action.data.enabled3DSecure;
							singleAccount.cardSecurity = action.data.cardSecurity;
						}
						return singleAccount;
					}),
				],
			};
		case CardsActionType.SET_ORDER_STEP:
			return {
				...state,
				orderStep: action.step,
			};
		case CardsActionType.SET_ORDER_CARD_FORM_DATA:
			return {
				...state,
				formData: {
					...state.formData,
					...action.data,
				},
			};
		case CardsActionType.SET_IS_CONTIS_USER:
			return {
				...state,
				isCardUser: action.isCardUser,
			};
		case CardsActionType.SET_CARD_LIST_IS_LOADING:
			return {
				...state,
				isLoading: action.isLoading,
				generalError: false,
			};
		case CardsActionType.SET_ALL_CARDS:
			return {
				...state,
				isLoading: false,
				cardsList: action.data,
				generalError: false,
			};
		case CardsActionType.RESET_ORDER_CARD_FORM:
			return {
				...state,
				orderStep: OrderSteps.TERMS,
				formData: null,
			};
		case CardsActionType.SET_PRODUCT_OPTIONS:
			return {
				...state,
				productOptions: action.data,
			};
		case CardsActionType.SET_ORDER_DATA:
			return {
				...state,
				orderData: action.orderData,
			};
		case CardsActionType.SET_GENERAL_CARD_ERROR:
			return {
				...state,
				isLoading: false,
				generalError: action.data,
			};
		case CardsActionType.SET_COMPANY_MOVEMENT:
			return {
				...state,
				companyMovement: action.data,
			};
		case CardsActionType.SET_VERIFICATION_TYPE:
			return {
				...state,
				verificationType: action.verificationType,
			};
		case CardsActionType.SET_AVAILABLE_COUNTRIES:
			return {
				...state,
				availableCountries: action.availableCountries,
			};
		case CardsActionType.SET_REQUIRES_VERIFICATION:
			return {
				...state,
				requiresVerification: action.requiresVerification,
			};
		default:
			return state;
	}
};
