import { AxiosError } from 'axios';
import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RemoteError } from '../../../../interfaces/RemoteData';
import type { RootState } from '../../../Store';
import PageableResponse from '../../../../interfaces/PageableResponse';
import { getPaymentButtonsUrl, getPaymentButtonUrl } from '../../../endpoints';
import {
	PaymentButtonsListState,
	PaymentButtonsListMode,
	PaymentButtonsListActionType,
	PaymentButtonsListFetchButtonsPendingAction,
	PaymentButtonsListFetchButtonsRejectedAction,
	PaymentButtonsListFetchButtonsFulfilledAction,
	PaymentButtonsListStatus,
} from './PaymentButtonsListTypes';
import { values } from '../../../../helpers/objectHelper/objectHelper';
import { getFilter } from './PaymentButtonsListSelectors';
import { PaymentButton } from '../PaymentButtonsTypes';
import axiosInstance from '../../../../helpers/axiosInstance';

const fetchButtonsPending: ActionCreator<PaymentButtonsListFetchButtonsPendingAction> = (
	page: number
) => ({
	type: PaymentButtonsListActionType.FETCH_BUTTONS_PENDING,
	payload: page,
});

const fetchButtonsRejected: ActionCreator<PaymentButtonsListFetchButtonsRejectedAction> = (
	page: number,
	error?: RemoteError
) => ({
	type: PaymentButtonsListActionType.FETCH_BUTTONS_REJECTED,
	payload: {
		page,
		error,
	},
});

const fetchButtonsFulfilled: ActionCreator<PaymentButtonsListFetchButtonsFulfilledAction> = (
	page: number,
	buttons: PageableResponse<PaymentButton[]>
) => ({
	type: PaymentButtonsListActionType.FETCH_BUTTONS_FULFILLED,
	payload: {
		page,
		buttons,
	},
});

const fetchButtons = (
	page: number,
	reset = false
): ThunkAction<
	Promise<PageableResponse<PaymentButton[]> | void>,
	RootState,
	undefined,
	any
> => async (dispatch, getState) => {
	dispatch(fetchButtonsPending(page));

	const state = getState();
	const { search, tags } = getFilter(state);

	const appendTags = values(tags).some((x) => x);

	const query = {
		page,
		name: search || undefined,
		...(appendTags
			? {
					enabled: tags.enabled === tags.disabled ? undefined : tags.enabled,
					virtual: tags.crypto === tags.fiat ? undefined : tags.crypto,
			  }
			: {}),
	};

	return axiosInstance
		.get<PageableResponse<PaymentButton[]>>(getPaymentButtonsUrl(), {
			params: {
				...query,
				sort: 'created,desc',
				size: 10,
			},
		})
		.then(({ data }) => {
			if (reset) dispatch(resetPages());
			dispatch(fetchButtonsFulfilled(page, data));
			return data;
		})
		.catch((e: AxiosError) => {
			dispatch(fetchButtonsRejected(page, e.response?.data));
		});
};

const setTags = (tags: Partial<PaymentButtonsListState['filter']['tags']>) => ({
	type: PaymentButtonsListActionType.SET_TAGS,
	payload: tags,
});

const setSearch = (search: string) => ({
	type: PaymentButtonsListActionType.SET_SEARCH,
	payload: search,
});

const setMode = (mode: PaymentButtonsListMode) => ({
	type: PaymentButtonsListActionType.SET_MODE,
	payload: mode,
});

const setStatus = (status: PaymentButtonsListStatus) => ({
	type: PaymentButtonsListActionType.SET_STATUS,
	payload: status,
});

const resetPages = () => ({
	type: PaymentButtonsListActionType.RESET_PAGES,
});

const resetDelete = () => ({
	type: PaymentButtonsListActionType.RESET_DELETE,
});

const deleteButtonPending = () => ({
	type: PaymentButtonsListActionType.DELETE_PENDING,
});

const deleteButtonRejected = (error?: RemoteError) => ({
	type: PaymentButtonsListActionType.DELETE_REJECTED,
	payload: error,
});

const deleteButtonFulfilled = (id: string) => ({
	type: PaymentButtonsListActionType.DELETE_FULFILLED,
	payload: id,
});

const deleteButton = (
	id: string,
	page: number
): ThunkAction<Promise<void>, RootState, undefined, any> => async (dispatch) => {
	dispatch(deleteButtonPending());
	await axiosInstance
		.delete<PageableResponse<PaymentButton[]>>(getPaymentButtonUrl(id))
		.then(() => {
			void dispatch(deleteButtonFulfilled(id));
			void dispatch(fetchButtons(page));
		})
		.catch((e) => {
			dispatch(deleteButtonRejected(e.response?.data));
		});
};

export default {
	fetchButtons,
	resetPages,
	resetDelete,
	setSearch,
	setTags,
	setMode,
	setStatus,
	deleteButton,
};
