import { defineMessages } from 'react-intl';

const withdrawMessages = defineMessages({
	metaWithdrawsTitle: {
		id: 'withdraw.metaTitleWithdraws',
		defaultMessage: 'Withdraws',
	},
	metaOffchainTitle: {
		id: 'withdraw.metaOffchainTitle',
		defaultMessage: 'Offchain withdrawal',
	},
	metaCryptoHistoryTitle: {
		id: 'withdraw.metaCryptoHistoryTitle',
		defaultMessage: 'Cryptocurrency withdraw history',
	},
	metaEwalletHistoryTitle: {
		id: 'withdraw.metaEwalletHistoryTitle',
		defaultMessage: 'E-Wallet withdraw history',
	},
	metaBankHistoryTitle: {
		id: 'withdraw.metaBankHistoryTitle',
		defaultMessage: 'Bank withdraw history',
	},
	metaGiftHistoryTitle: {
		id: 'withdraw.metaGiftHistoryTitle',
		defaultMessage: 'Gift Cards History',
	},
	metaVoucherHistoryTitle: {
		id: 'withdraw.metaVoucherHistoryTitle',
		defaultMessage: 'Voucher withdraw history',
	},
	metaMobileTopupHistoryTitle: {
		id: 'withdraw.metaMobileTopupHistoryTitle',
		defaultMessage: 'Mobile Top-ups history',
	},
	metaCryptoTitle: {
		id: 'withdraw.metaCryptoTitle',
		defaultMessage: 'Cryptocurrency withdraws',
	},
	metaEwalletTitle: {
		id: 'withdraw.metaEwalletTitle',
		defaultMessage: 'E-Wallet withdraws',
	},
	metaSingleCryptoTitle: {
		id: 'withdraw.metaSingleCryptoTitle',
		defaultMessage: 'withdraw',
	},
	metaTopupTitle: {
		id: 'withdraw.metaTopupTitle',
		defaultMessage: 'Mobile Top-ups',
	},
	metaVoucherTitle: {
		id: 'withdraw.metaVoucherTitle',
		defaultMessage: 'Voucher withdraws',
	},
	metaGiftTitle: {
		id: 'withdraw.metaGiftTitle',
		defaultMessage: 'Gift Cards',
	},
	metaBankTitle: {
		id: 'withdraw.metaBankTitle',
		defaultMessage: 'Bank withdraws',
	},
	metaBulkTitle: {
		id: 'withdraw.metaBulkTitle',
		defaultMessage: 'BTC Bulk Send',
	},
	sortCodeLabel: {
		id: 'bankWithdrawForm.sortCodeLabel',
		defaultMessage: 'Sort Code',
	},
	voucherCode: {
		id: 'withdraw.voucherCode',
		defaultMessage: 'Voucher code',
	},
	giftCode: {
		id: 'withdraw.giftCode',
		defaultMessage: 'Gift Card code',
	},
	swiftLabel: {
		id: 'bankWithdrawForm.swiftLabel',
		defaultMessage: 'Bank SWIFT/BIC',
	},
	details: {
		id: 'bankWithdrawForm.details',
		defaultMessage: 'Details',
	},
	receiverBankAddressLabel: {
		id: 'bankWithdrawForm.receiverBankAddressLabel',
		defaultMessage: 'Receiver bank address',
	},
	receiverBankNameLabel: {
		id: 'bankWithdrawForm.receiverBankNameLabel',
		defaultMessage: 'Receiver bank name',
	},
	receiverBankCountryLabel: {
		id: 'bankWithdrawForm.receiverBankCountryLabel',
		defaultMessage: 'Receiver bank country',
	},
	receiverLabel: {
		id: 'bankWithdrawForm.receiverLabel',
		defaultMessage: 'Receiver (name and surname or company name)',
	},
	receiverAccountLabel: {
		id: 'bankWithdrawForm.receiverAccountLabel',
		defaultMessage: 'Receiver account',
	},
	receiverAddressLabel: {
		id: 'bankWithdrawForm.receiverAddressLabel',
		defaultMessage: 'Receiver address',
	},
	receiverCountryLabel: {
		id: 'bankWithdrawForm.receiverCountryLabel',
		defaultMessage: 'Receiver country',
	},
	receiverProvinceLabel: {
		id: 'bankWithdrawForm.receiverProvinceLabel',
		defaultMessage: 'Receiver province',
	},
	receiverCityLabel: {
		id: 'bankWithdrawForm.receiverCityLabel',
		defaultMessage: 'Receiver city',
	},
	receiverPostCodeLabel: {
		id: 'bankWithdrawForm.receiverPostCodeLabel',
		defaultMessage: 'Receiver post code',
	},
	receiverNameOrCompanyNameLabel: {
		id: 'bankWithdrawForm.receiverNameOrCompanyNameLabel',
		defaultMessage: 'Receiver (name and surname or company name)',
	},
	accountLabel: {
		id: 'bankWithdrawForm.accountLabel',
		defaultMessage: 'IBAN/Account number',
	},
	accountNumberIbanLabel: {
		id: 'bankWithdrawForm.accountNumberIbanLabel',
		defaultMessage: 'Account number / IBAN',
	},
	withdrawalMethod: {
		id: 'bankWithdrawForm.withdrawalMethod',
		defaultMessage: 'Withdrawal method',
	},
	cryptoWithdraws: {
		id: 'withdrawOptions.crypto',
		defaultMessage: 'Cryptocurrency withdraws',
	},
	eWalletWithdraws: {
		id: 'withdrawOptions.eWallet',
		defaultMessage: 'E-wallet withdraws',
	},
	bankWithdraws: {
		id: 'withdrawOptions.bank',
		defaultMessage: 'Bank withdraws',
	},
	giftCardsWithdraws: {
		id: 'withdrawOptions.giftCards',
		defaultMessage: 'Gift Cards',
	},
	voucherWithdraws: {
		id: 'withdrawOptions.voucher',
		defaultMessage: 'Voucher withdraws',
	},
	topupWithdraws: {
		id: 'withdrawOptions.topup',
		defaultMessage: 'Mobile Top-ups',
	},
	offchainWithdraws: {
		id: 'withdrawOptions.offchainWithdraws',
		defaultMessage: 'Offchain withdrawal',
	},
	name: {
		id: 'walletBlock.name',
		defaultMessage: 'Name',
	},
	balance: {
		id: 'walletBlock.balance',
		defaultMessage: 'Balance',
	},
	backToWithdrawOptions: {
		id: 'base.back_to_withdraw_options',
		defaultMessage: 'Back to withdraw options',
	},
	withdrawCryptoConfirmationTitle: {
		id: 'withdrawOption.withdrawCryptoConfirmationTitle',
		defaultMessage: '{currency} withdrawal confirmation',
	},
	withdrawTitle: {
		id: 'withdrawHistoryTable.withdrawTitle',
		defaultMessage: '{currCode} Withdraw',
	},
	voucherTitle: {
		id: 'withdrawHistoryTable.voucherTitle',
		defaultMessage: '{receiveCurrencyCode} Voucher',
	},
	cryptoHistoryTitle: {
		id: 'withdrawHistoryTable.cryptoHistoryTitle',
		defaultMessage: 'Cryptocurrencies withdraw history',
	},
	'e-walletHistoryTitle': {
		id: 'withdrawHistoryTable.ewalletHistoryTitle',
		defaultMessage: 'E-Wallet Withdraws History',
	},
	bankHistoryTitle: {
		id: 'withdrawHistoryTable.bankHistoryTitle',
		defaultMessage: 'Bank Withdraws History',
	},
	'gift-cardsHistoryTitle': {
		id: 'withdrawHistoryTable.gift-cardsHistoryTitle',
		defaultMessage: 'Gift Cards History',
	},
	vouchersHistoryTitle: {
		id: 'withdrawHistoryTable.vouchersHistoryTitle',
		defaultMessage: 'Vouchers History',
	},
	'mobile-top-upsHistoryTitle': {
		id: 'withdrawHistoryTable.mobile-top-upsHistoryTitle',
		defaultMessage: 'Mobile Top-ups History',
	},
	withdrawalWallet: {
		id: 'cryptoWithdrawForm.withdrawalWallet',
		defaultMessage: 'Withdrawal wallet',
	},
	amountTooLow: {
		id: 'withdraw.amountTooLow',
		defaultMessage: 'Amount should be greater than 0',
	},
	notEnoughFunds: {
		id: 'withdraw.notEnoughFunds',
		defaultMessage: 'Not enough funds',
	},
	minAmount: {
		id: 'withdraw.minAmount',
		defaultMessage: 'Min withdraw amount: {amount}',
	},
	maxAmount: {
		id: 'withdraw.maxAmount',
		defaultMessage: 'Max withdraw amount: {amount}',
	},
	withdrawalAmount: {
		id: 'withdraw.withdrawalAmount',
		defaultMessage: 'Withdraw amount',
	},
	note: {
		id: 'withdraw.note',
		defaultMessage: 'Note',
	},
	receiversPhone: {
		id: 'withdraw.receiversPhone',
		defaultMessage: 'Receiver’s phone number',
	},
	purchaseProduct: {
		id: 'withdraw.purchaseProduct',
		defaultMessage: 'Purchase product',
	},
	purchaseItem: {
		id: 'withdraw.purchaseItem',
		defaultMessage: 'Purchase item',
	},
	emailLabel: {
		id: 'cryptoWithdrawForm.emailLabel',
		defaultMessage: '{providerType} account email',
	},
	emailOrAccountLabel: {
		id: 'cryptoWithdrawForm.emailOrAccountLabel',
		defaultMessage: '{providerType} account number or email',
	},
	ewalletAccountLabel: {
		id: 'cryptoWithdrawForm.ewalletAccountLabel',
		defaultMessage: '{providerType} account number',
	},
	emailPlaceholder: {
		id: 'cryptoWithdrawForm.emailPlaceholder',
		defaultMessage: 'Enter email',
	},
	emailOrAccountPlaceholder: {
		id: 'cryptoWithdrawForm.emailOrAccountPlaceholder',
		defaultMessage: 'Enter account number or email',
	},
	ewalletAccountPlaceholder: {
		id: 'cryptoWithdrawForm.ewalletAccountPlaceholder',
		defaultMessage: 'Enter account number',
	},
});

export default withdrawMessages;
