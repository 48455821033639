/* eslint-disable consistent-return */
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useLayoutEffect } from 'react';
import PaymentsMessages from '../../redux/PaymentsState/PaymentsMessages';
import useReferralRoutes, { ReferralRoutes } from '../../hooks/useReferralRoutes';
import styles from './ReferralContainer.module.scss';
import Seo from '../../components/Seo/Seo';
import Terms from '../../pages/Referrals/Terms/Terms';
import Home from '../../pages/Referrals/Home/Home';
import Callbacks from '../../pages/Referrals/Callbacks/Callbacks';
import ReferralIds from '../../pages/Referrals/ReferralIds/ReferralIds';
import { consentQuery } from '../../redux/ReferralsState/Queries/ReferralQueries';
import Loading from '../../pages/Merchants/Shared/Loading/Loading';

const ReferralContainer = () => {
	const { getPath, getUrl } = useReferralRoutes();
	const { push } = useHistory();

	const { data, isFetching } = useQuery({
		queryKey: consentQuery.getKey(),
		queryFn: consentQuery.getFn(),
	});

	useLayoutEffect(() => {
		if (data && data.accepted !== true) push(getUrl(ReferralRoutes.Terms));
	}, [data?.accepted]);

	return (
		<div className={styles.container}>
			<Seo title={PaymentsMessages.metaTitle} />
			<div className={styles.flexGrid}>
				<div className={styles.content}>
					{isFetching ? (
						<Loading />
					) : (
						<Switch>
							<Route exact path={getPath(ReferralRoutes.Terms)} component={Terms} />
							<Route exact path={getPath(ReferralRoutes.Home)} component={Home} />
							<Route
								exact
								path={getPath(ReferralRoutes.Callbacks)}
								component={Callbacks}
							/>
							<Route
								exact
								path={getPath(ReferralRoutes.ReferralIds)}
								component={ReferralIds}
							/>
							<Redirect to={getUrl(ReferralRoutes.Home)} />
						</Switch>
					)}
				</div>
			</div>
		</div>
	);
};

export default ReferralContainer;
