import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Payout } from '../../../../redux/MerchantsState/CryptoPayouts/PayoutsData/PayoutsDataTypes';
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import useDidMount from '../../../../hooks/useDidMount';
import { HistoryTableData } from '../../../../hooks/useHistoryTableData';
import useQueryParams from '../../../../hooks/useQueryParams';
import MerchantsSelectors from '../../../../redux/MerchantsState/MerchantsSelectors';
import MerchantsActions from '../../../../redux/MerchantsState/MerchantsActions';

const usePayoutsListProvider = (): HistoryTableData<Payout> => {
	const didMount = useDidMount();
	const dispatch = useDispatch();
	const [[page = 1, status]] = useQueryParams({
		page: undefined,
		status: undefined,
	});

	const {
		data: historyPayoutIds,
		status: historyStatus,
		totalCount: historyPayoutsTotalCount,
		pageSize: historyPayoutsPageSize,
	} = useSelector(MerchantsSelectors.payouts.history.getState);

	const historyPayouts = useSelector(MerchantsSelectors.payouts.history.getState);

	useEffect(() => {
		if (didMount)
			void dispatch(
				MerchantsActions.payouts.history.fetchPayouts({
					page: +page - 1,
					status,
				})
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, didMount, status]);

	return {
		data: historyPayouts.data as any,
		isLoading: historyStatus === RemoteStatus.InProgress,
		totalCount: historyPayoutsTotalCount,
		pageSize: historyPayoutsPageSize,
	};
};

export default usePayoutsListProvider;
