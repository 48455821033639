enum StorageKey {
	ACCESS_TOKEN = 'ACCESS_TOKEN',
	REDIRECT = 'REDIRECT',
	SEGWIT_TOGGLE = 'SEGWIT_TOGGLE',
	COOKIE_PREFERENCES = 'isCookiesAllowed_1912',
	USER_SETUP_STEP_INDEX = 'USER_SETUP_STEP_INDEX',
	USER_SETUP_STEPS_REQUIRED = 'USER_SETUP_STEPS_REQUIRED',
	USER_SETUP_USER_ID = 'USER_SETUP_USER_ID',
	ARE_COOKIES_ALLOWED = 'isCookiesAllowed_1912',
	UTM_UUID = 'utm_uuid',
}

export default StorageKey;
