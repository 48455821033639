const DepositsTestIds = {
	// general
	receiveAmount: 'receiveAmount',
	button: 'button',
	depositTitle: 'deposits.title',
	depositsStatus: 'deposits.status',
	receiveAmountValue: 'deposit.receiveAmount.value',
	receiveAmountError: 'deposit.receiveAmount.error',
	depositResultStatus: 'deposit.result.status',
	depositResultBody: 'deposit.result.body',
	howToDeposit: 'deposit.howToDeposit',
	paymentMethodLimist: 'deposit.paymentMethodLimits',
	depositAmountCurrencyInput: 'depositAmountCurrencyInput',

	// crypto
	cryptoDepositsDetailsView: 'deposit.crypto.details.view',
	cryptoDepositsView: 'deposit.crypto.view',
	segwitButton: 'deposit.crypto.segwit.button',
	legacyButton: 'deposit.crypto.legacy.button',
	qrCode: 'deposit.crypto.qrCode',
	cryptoAddress: 'deposit.crypto.address',
	cryptoAddressCopyButton: 'deposit.crypto.address.copyButton',
	cryptoMessage: 'deposit.crypto.message',
	getNewAddressButton: 'deposit.crypto.getNewAddress.button',
	getNewAddressButtonLoader: 'deposit.crypto.getNewAddress.button.loader',
	getNewAddressButtonError: 'deposit.crypto.getNewAddress.button.error',
	disclaimerERC20: 'deposit.crypto.disclaimer.ERC20',
	cryptoDepositOption: 'deposit.crypto.depositOption.0',
	cryptoCurrencyName: 'deposit.crypto.currency.name.0',
	cryptoCurrencyBalanceRow: 'deposit.crypto.currency.balance.row',
	cryptoCurrencyBalance: 'deposit.crypto.currency.balance.0',
	cryptoCurrencyAvailableBalance: 'deposit.crypto.currency.availableBalance.0',

	// bank
	bankDepositsDetailsView: 'deposit.bank.details.view',
	bankDepositsView: 'deposit.bank.view',
	bankShareDetailsLink: 'deposit.bank.shareDetails.link',
	bankDetailsItem: 'deposit.bank.details.item.0',

	// cards
	cardDepositsDetailsView: 'deposit.card.details.view',
	cardDepositsView: 'deposit.card.view',
	linkNewCardView: 'deposit.card.linkNewCard.view',
	availableCards: 'deposit.cards.availableCards',
	unavailableCards: 'deposit.cards.unavailableCards',
	cardsLoader: 'deposit.cards.loader',
	cardNumber: 'deposit.cards.cardNumber',
	cardExpirationDate: 'deposit.cards.cardExpirationDate',
	cardCvv: 'deposit.cards.cardCvv',
	cardHolderName: 'deposit.cards.cardHolderName',
	saveCardCheckBox: 'deposit.cards.saveCard.checkbox',
	removeCardNumber: 'deposit.cards.removeCardNumber',
	removeCard: 'deposit.cards.removeCard',
	ecommpayDepositsDetailsView: 'deposit.ecommpay.details.view',

	// e-wallet
	ewalletDepositsDetailsView: 'deposit.ewallet.details.view',
	ewalletDepositsView: 'deposit.ewallet.view',

	// cash
	cashDepositsDetailsView: 'deposit.cash.details.view',
	cashDepositsView: 'deposit.cash.view',

	// voucher
	voucherDepositsView: 'deposit.voucher.view',
	voucherCode: 'voucherCode',
	howToUseVoucherCodes: 'howToUseVoucherCodes',
	withdrawVoucherLink: 'withdrawVoucherLink',
	withdrawHistory: 'withdrawHistory',
	voucherFailureText: 'deposit.voucher.failure.text',
	voucherGoToWalletButton: 'deposit.voucher.goToWallet.button',
	voucherContactSupportButton: 'deposit.voucher.contactSupport.button',
} as const;

export default DepositsTestIds;
