/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import Cookies from 'js-cookie';
import {
	ProfileActionType,
	SetCompanyAction,
	SetIsLoggedInAction,
	SetProfileAction,
	ResetIdleTimerAction,
	SetVerificationStatus,
	SetIsSCAAction,
} from './ProfileTypes';
import { AppDispatch } from '../Store';
import config from '../../configs/config';
import AuthErrorCode from '../../enums/AuthErrorCode';
import StorageKey from '../../enums/StorageKey';
import userManagerInstance from '../../helpers/userManager/userManager';
import {
	getProfileURL,
	getLogoutURL,
	getCompanyURL,
	getRemovePlayUserCacheURL,
	getUserLoginURL,
	getSetPlayUserCacheURL,
	getProfileVerificationStatusURL,
} from '../endpoints';
import { isRedirectSafe } from '../../helpers/xssHelper/xssHelper';
import axiosInstance from '../../helpers/axiosInstance';

export const getProfile = () => (dispatch: AppDispatch) => {
	return axiosInstance
		.get(getProfileURL())
		.then(({ data }) => {
			dispatch<SetProfileAction>({ type: ProfileActionType.SET_PROFILE, data });
			return dispatch<SetIsLoggedInAction>({
				type: ProfileActionType.SET_IS_LOGGED_IN,
				data: true,
			});
		})
		.catch(({ response }) => {
			if (response?.status === 401) {
				return null;
			}
			return console.error(`Couldn't get profile from endpoint`);
		});
};

export const getProfileVerificationStatus = () => (dispatch: AppDispatch) => {
	return axiosInstance
		.get(getProfileVerificationStatusURL())
		.then(({ data }) => {
			return dispatch<SetVerificationStatus>({
				type: ProfileActionType.SET_VERIFICATION_STATUS,
				data,
			});
		})
		.catch(() => {
			return console.error(`Couldn't get profile verification status from endpoint`);
		});
};

export const logout = (redirectURI?: string) => (dispatch: AppDispatch) => {
	// add unload event listener to fire Broadcast message
	// after the redirection to prevent infinity loops
	window.addEventListener('unload', () => {
		if (typeof BroadcastChannel !== 'undefined') {
			const broadcastChannel = new BroadcastChannel('tabs');
			broadcastChannel.postMessage('logout');
		}
	});

	return axiosInstance
		.post(getRemovePlayUserCacheURL(), {})
		.then(() => {
			Cookies.remove('PLAY_SESSION');
			Cookies.remove('P_SESSION_ID');
		})
		.catch((e) => {
			console.error(`Couldn't remove Play User Cache URL`, e);
		})
		.then(() =>
			axiosInstance
				.post(getLogoutURL(), {}, { headers: { 'Content-Type': 'application/json' } })
				.then(async () => {
					const URI = redirectURI || `${window.location.origin}/login`;
					dispatch<SetIsLoggedInAction>({
						type: ProfileActionType.SET_IS_LOGGED_IN,
						data: false,
					});
					dispatch<SetProfileAction>({ type: ProfileActionType.SET_PROFILE, data: null });
					await userManagerInstance.clearStaleState();
					window.location.assign(
						`${config.SSO_LOGOUT_URL}?redirectUri=${encodeURIComponent(
							URI
						)}&logoutOtherClients=true`
					);
				})
				.catch((e) => {
					console.error(`Couldn't logout`, e);
				})
		);
};

export const getCompany = () => (dispatch: AppDispatch) => {
	return axiosInstance
		.get(getCompanyURL())
		.then(({ data }) => {
			return dispatch<SetCompanyAction>({
				type: ProfileActionType.SET_COMPANY,
				data: data.company,
			});
		})
		.catch(({ response }) => {
			if (response?.status === 401) {
				return null;
			}
			return console.error(`Couldn't get users company`);
		});
};

export const setIsSCA = (scaRequired: boolean) => (dispatch: AppDispatch) => {
	return dispatch<SetIsSCAAction>({
		type: ProfileActionType.SET_IS_SCA,
		data: scaRequired,
	});
};

export const resetIdleTimer = () => (dispatch: AppDispatch) => {
	return dispatch<ResetIdleTimerAction>({
		type: ProfileActionType.RESET_IDLE_TIMER,
	});
};

export const userLogin = (push: any, locale: string) => (dispatch: AppDispatch) => {
	return axiosInstance
		.post(getUserLoginURL())
		.then(() => {
			dispatch<SetIsLoggedInAction>({ type: ProfileActionType.SET_IS_LOGGED_IN, data: true });
			const redirect = localStorage.getItem(StorageKey.REDIRECT);
			if (redirect && isRedirectSafe(redirect)) {
				localStorage.removeItem(StorageKey.REDIRECT);
				return window.location.assign(decodeURI(redirect));
			}
			push(`/${locale || 'en'}/account`);
			return dispatch<any>(playLogin());
		})
		.catch(({ response }) => {
			const { data } = response;
			const { errorCode } = data;
			switch (errorCode) {
				case AuthErrorCode.US_2: {
					const redirect =
						localStorage.getItem(StorageKey.REDIRECT) ||
						`${window.location.origin}/${locale || 'en'}/account`;
					return window.location.assign(
						`${config.PROFILE_DOMAIN}/setup?redirectUrl=${encodeURIComponent(redirect)}`
					);
				}
				case AuthErrorCode.US_3: {
					throw data;
				}
				default:
					break;
			}
		});
};

export const playLogin = () => (dispatch: AppDispatch) => {
	return axiosInstance.post(getSetPlayUserCacheURL()).catch((e) => {
		// ECONNABORTED: if a redirect to play cancels this pending request user is logged out unintentionally.
		if (process.env.NODE_ENV !== 'development' && e.code !== 'ECONNABORTED') {
			console.error(`Couldn't set Play User Cache URL`, e);
			return dispatch<any>(logout());
		}
	});
};
