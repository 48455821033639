import { FC, PropsWithChildren } from 'react';
import CopyButton from '../CopyButton/CopyButton';

import styles from './CopyField.module.scss';

type CopyFieldProps = {
	label?: string;
	copyContent: string;
};

const CopyField: FC<PropsWithChildren<CopyFieldProps>> = ({ children, copyContent, label }) => {
	return (
		<div className={styles.container}>
			{label && <label className={styles.label}>{label}</label>}
			<div className={styles.input}>
				<div className={styles.content}>{children}</div>
				<CopyButton
					className={styles.icon}
					dataToCopy={copyContent}
					iconClassName={styles.svg}
				/>
			</div>
		</div>
	);
};

export default CopyField;
