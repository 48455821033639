import { RemoteData, RemoteError } from '../../interfaces/RemoteData';
import PageableResponse from '../../interfaces/PageableResponse';
import { Project } from './Projects/ProjectsTypes';
import { PaymentButton } from './PaymentButtons/PaymentButtonsTypes';

export * from './Projects/ProjectsTypes';
export * from './Orders/OrdersTypes';
export * from './Preorders/PreorderTypes';
export * from './OrderCallbacks/OrderCallbacksTypes';
export * from './PaymentButtons/PaymentButtonsTypes';

export type MerchantsLoadState = RemoteData<void>;

export enum MerchantsActionType {
	LOAD_DATA_PENDING = `MERCHANTS/LOAD_DATA/PENDING`,
	LOAD_DATA_REJECTED = 'MERCHANTS/LOAD_DATA/REJECTED',
	LOAD_DATA_FULFILLED = 'MERCHANTS/LOAD_DATA/FULFILLED',
}

export type MerchantsLoadDataPendingAction = {
	type: MerchantsActionType.LOAD_DATA_PENDING;
};

export type MerchantsLoadDataRejectedAction = {
	type: MerchantsActionType.LOAD_DATA_REJECTED;
	error?: RemoteError;
};

export type MerchantsLoadDataFulfilledAction = {
	type: MerchantsActionType.LOAD_DATA_FULFILLED;
	payload: {
		projects: PageableResponse<Project[]>;
		paymentButtons: PageableResponse<PaymentButton[]>;
	};
};
