import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RemoteData } from '../../../../interfaces/RemoteData';

export enum ProjectStatus {
	New = 'NEW',
	Approved = 'APPROVED',
}

export enum ProjectTestStatus {
	EXPIRED = 'EXPIRED',
	PAID = 'PAID',
}

export type Project = {
	baseCurrencyCode: string;
	created: string;
	description: string | null;
	enabled: boolean;
	forVerifiedPayers: boolean;
	forPayerFee: boolean;
	id: string;
	name: string;
	publicAccessKey: string;
	publicKey: string | null;
	receiveAccountId: string | null;
	status: ProjectStatus;
	testStatus: ProjectTestStatus | null;
	payCurrencies: CurrencyEnum[] | null;
	isUsed: boolean | null; // only present in GET /apis/{id}
};

export type ProjectsDataState = {
	projects: Record<string, RemoteData<Project>>;
};

export enum ProjectsDataActionType {
	FETCH_PROJECT_PENDING = 'MERCHANTS/PROJECTS/DATA/FETCH_PROJECT/PENDING',
	FETCH_PROJECT_REJECTED = 'MERCHANTS/PROJECTS/DATA/FETCH_PROJECT/REJECTED',
	FETCH_PROJECT_FULFILLED = 'MERCHANTS/PROJECTS/DATA/FETCH_PROJECT/FULFILLED',

	UPDATE_PROJECT = 'MERCHANTS/PROJECTS/DATA/UPDATE_PROJECT',
	UPDATE_PROJECTS = 'MERCHANTS/PROJECTS/DATA/UPDATE_PROJECTS',

	DELETE_PROJECT = 'MERCHANTS/PROJECTS/DATA/DELETE_PROJECT',
}

export type ProjectDataFetchProjectPendingAction = {
	type: ProjectsDataActionType.FETCH_PROJECT_PENDING;
	payload: string;
};

export type ProjectDataFetchProjectRejectedAction = {
	type: ProjectsDataActionType.FETCH_PROJECT_REJECTED;
	payload: string;
};

export type ProjectDataFetchProjectFulfilledAction = {
	type: ProjectsDataActionType.FETCH_PROJECT_FULFILLED;
	payload: Project;
};

export type ProjectDataUpdateProjectAction = {
	type: ProjectsDataActionType.UPDATE_PROJECT;
	payload: Project;
};

export type ProjectDataUpdateProjectsAction = {
	type: ProjectsDataActionType.UPDATE_PROJECTS;
	payload: Project[];
};

export type ProjectDataDeleteProjectAction = {
	type: ProjectsDataActionType.DELETE_PROJECT;
	payload: string;
};
