import PaymentMethod, {
	CardBrand,
	LinkedCard,
	LinkedCardState,
	LinkedCardStatus,
	ProviderType,
} from '../../../redux/DepositState/DepositTypes';
import { CardAndMethod } from './CardDeposits';

export const getCardState = (
	status: LinkedCardStatus,
	companyMovementNeeded: boolean,
	userVerificationNeeded: boolean
) => {
	if (userVerificationNeeded) return LinkedCardState.USER_VERIFICATION_NEEDED;
	if (companyMovementNeeded) return LinkedCardState.COMPANY_MOVEMENT_NEEDED;
	switch (status) {
		case LinkedCardStatus.VERIFIED:
		case LinkedCardStatus.CREATED:
			return LinkedCardState.AVAILABLE;
		case LinkedCardStatus.REQUIRES_VERIFICATION:
			return LinkedCardState.VERIFICATION_NEEDED;
		case LinkedCardStatus.THREEDSECURE: // User is inputting authorization code and will return to us via callback
		case LinkedCardStatus.PENDING_FOR_APPROVAL:
			return LinkedCardState.ACTIVATION_INIT_NEEDED; // User needs to confirm he wishes to begin card activation by deducting some money from his account.
		case LinkedCardStatus.BLOCKED:
		case LinkedCardStatus.COUNTRY_NOT_MATCHED:
			return LinkedCardState.CONTACT_SUPPORT;
		case LinkedCardStatus.EXPIRED:
		case LinkedCardStatus.RISKY_CARD:
		case LinkedCardStatus.FAILED_CVV:
		case LinkedCardStatus.FAILED_3D_SECURE_CODE:
		default:
			return LinkedCardState.FAILED;
	}
};

export const getCardTypeFromBrand = (cardBrand: CardBrand) => {
	switch (cardBrand?.toUpperCase()) {
		case CardBrand.DELTA:
		case CardBrand.ELECTRON:
		case CardBrand.PURCHASING:
		case CardBrand.VISA:
		case CardBrand.VISAELECTRON:
		case CardBrand.VPAY:
			return 'visa';
		case CardBrand.MASTERCARD:
		case CardBrand.MASTERCARDDEBIT:
			return 'mastercard';
		case CardBrand.MAESTRO:
			return 'maestro';
		case CardBrand.AMERICAN_EXPRESS:
		case CardBrand.AMEX:
			return 'amex';
		default:
			return 'other';
	}
};

export const getCardLogo = (cardBrand: CardBrand) => {
	switch (cardBrand) {
		case CardBrand.DELTA:
		case CardBrand.ELECTRON:
		case CardBrand.PURCHASING:
		case CardBrand.VISA:
		case CardBrand.VISAELECTRON:
			return 'visa';
		case CardBrand.MASTERCARD:
		case CardBrand.MASTERCARDDEBIT:
			return 'mastercard';
		default:
			return 'default';
	}
};

export const getCardDepositMethodsAndCount = (
	linkedCards: LinkedCard[],
	cardPaymentMethods: PaymentMethod[],
	isUserVerified: boolean,
	canAddNewCard: boolean
) => {
	const cardsAndMethods = linkedCards.reduce((accumulator, linkedCard) => {
		const paymentMethod = cardPaymentMethods.find(
			(method) =>
				method.id === linkedCard.paymentMethodId &&
				(method.isEnabled || method.isEnabledForVerified)
		);
		if (paymentMethod) {
			accumulator.push({
				linkedCard,
				paymentMethod,
				linkedCardState: getCardState(
					linkedCard.status,
					paymentMethod.companyMovementNeeded,
					paymentMethod.isEnabledForVerified && !isUserVerified
				),
			});
		}
		return accumulator;
	}, [] as CardAndMethod[]);

	const ecommpayMethods = cardPaymentMethods.filter(
		(x) => x.providerType === ProviderType.ECOMMPAY
	).length;

	const depositOptionCount =
		cardsAndMethods.filter(({ linkedCardState }) => {
			return (
				linkedCardState === LinkedCardState.AVAILABLE ||
				linkedCardState === LinkedCardState.USER_VERIFICATION_NEEDED
			);
		}).length +
		(canAddNewCard ? 1 : 0) +
		ecommpayMethods;

	const available = cardsAndMethods?.filter(
		({ linkedCardState }) => linkedCardState === LinkedCardState.AVAILABLE
	);
	const unavailable = cardsAndMethods?.filter(
		({ linkedCardState }) => linkedCardState !== LinkedCardState.AVAILABLE
	);

	return { available, unavailable, depositOptionCount };
};
