import { AxiosRequestConfig } from 'axios';
import { CombinedState } from 'redux';
import { TwoFaCode } from '../redux/TwoFaState/TwoFaTypes';
import { setTwoFaData } from '../redux/TwoFaState/TwoFaActions';

export default (
	store: CombinedState<any>,
	requestData: AxiosRequestConfig,
	authData: any,
	errorCode: TwoFaCode
) => {
	const { messageParameters } = authData;

	const { value: authType } = messageParameters.find(
		(param: { key: string; value: string }) => param.key === '2fa_type'
	);

	const { value: txId } = messageParameters.find(
		(param: { key: string }) => param.key === 'tx_id'
	);

	const { value: twoFaCode } = messageParameters.find(
		(param: { key: string; value: string }) => param.key === '2fa_code'
	);

	const { value: expDate } = messageParameters.find(
		(param: { key: string }) => param.key === 'expiration_date'
	);

	const { value: email } =
		messageParameters.find((param: { key: string; value: string }) => param.key === 'email') ||
		{};

	const data = {
		data: requestData.data,
		headers: { ...requestData.headers },
		baseURL: requestData.baseURL,
		method: requestData.method,
		params: requestData.params,
		url: requestData.url,
		skip2FA: requestData.skip2FA,
	};

	delete data.headers['2fa-fallback'];

	return store.dispatch(
		setTwoFaData({ requestData: data, authType, txId, twoFaCode, expDate, errorCode, email })
	);
};
