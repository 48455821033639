/* eslint-disable spaced-comment */
import { useEffect, useState } from 'react';
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { currencyUtils } from '@spectrocoin/sc-currencies';
import CryptoDeposits from '../../pages/Deposit/CryptoDeposits/CryptoDeposits';
import EWalletDeposits from '../../pages/Deposit/EWalletDeposits/EWalletDeposits';
import CryptoDepositDetails from '../../pages/Deposit/CryptoDeposits/CryptoDepositDetails/CryptoDepositDetails';
import TransfersSidebar, { SidebarType } from '../../layout/TransfersSidebar/TransfersSidebar';
import { RootState } from '../../redux/Store';
import PaymentMethod, { DepositType } from '../../redux/DepositState/DepositTypes';
import DepositMessages from '../../redux/DepositState/DepositMessages';
import {
	fetchAllPaymentMethods,
	fetchLinkedCards,
	fetchLinkedCardStatus,
} from '../../redux/DepositState/DepositActions';
import { Wallet } from '../../redux/AccountsState/AccountsTypes';
import CashDeposits from '../../pages/Deposit/CashDeposits/CashDeposits';
import CashDepositDetails from '../../pages/Deposit/CashDeposits/CashDepositDetails/CashDepositDetails';
import DepositHistory from '../../pages/Deposit/DepositHistory/DepositHistory';
import { HistoryType } from '../../hooks/useHistoryTableData';
import TransfersOptionContainer from '../TransfersOptionContainer/TransfersOptionContainer';
import EWalletDepositDetails from '../../pages/Deposit/EWalletDeposits/EWalletDepositDetails/EWalletDepositDetails';
import BankDeposits from '../../pages/Deposit/BankDeposits/BankDeposits';
import BankDepositDetails from '../../pages/Deposit/BankDeposits/BankDepositDetails/BankDepositDetails';
import DepositResult, { ResultStatus } from '../../pages/Deposit/DepositResult/DepositResult';
import VoucherDeposits from '../../pages/Deposit/VoucherDeposits/VoucherDeposits';
import { getCardDepositMethodsAndCount } from '../../pages/Deposit/CardDeposits/helpers';
import CardDeposits from '../../pages/Deposit/CardDeposits/CardDeposits';
import LinkNewCardDetails from '../../pages/Deposit/CardDeposits/LinkNewCardDetails/LinkNewCardDetails';
import CardDepositDetails from '../../pages/Deposit/CardDeposits/CardDepositDetails/CardDepositDetails';
import Seo from '../../components/Seo/Seo';
import useEffectOnce from '../../hooks/useEffectOnce';
import GooglePayDetails from '../../pages/Deposit/CardDeposits/ECommPayDetails/ECommPayDetails';

const DepositContainer = () => {
	const dispatch = useDispatch();
	const { replace } = useHistory();
	const { pathname } = useLocation();
	const { formatMessage } = useIntl();
	const { url }: { url: string } = useRouteMatch();
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const { isMobile, isTablet } = useSelector((state: RootState) => state.AppState);
	const { linkedCards, paymentMethods } = useSelector((state: RootState) => state.DepositState);
	const { ewallet, bank, cash, card } = paymentMethods;
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const cryptoAccounts = wallets?.filter(
		({ currencyCode }: Wallet) =>
			currencyUtils.getConfigOrDefault(currencyCode).isVirtual &&
			!currencyUtils.getConfigOrDefault(currencyCode).noAddress
	) as Wallet[];
	const [cardDepositOptionsCount, setCardDepositOptionsCount] = useState<number | null>(null);

	const depositTypes = {
		[DepositType.CRYPTOCURRENCY]: {
			icon: DepositType.CRYPTOCURRENCY,
			link: `${url}/crypto`,
			name: formatMessage(DepositMessages.cryptocurrencyDeposits),
			optionsCount: cryptoAccounts?.length || 0,
		},
		[DepositType.EWALLET]: {
			icon: DepositType.EWALLET,
			name: formatMessage(DepositMessages.eWalletDeposits),
			optionsCount: ewallet.totalCount,
			link: `${url}/e-wallet`,
		},
		[DepositType.BANK]: {
			icon: DepositType.BANK,
			name: formatMessage(DepositMessages.bankDeposits),
			link: `${url}/bank`,
			optionsCount: bank.totalCount,
		},
		[DepositType.CARD]: {
			icon: DepositType.CARD,
			name: formatMessage(DepositMessages.cardDeposits),
			link: `${url}/card`,
			optionsCount: cardDepositOptionsCount || 0,
		},
		[DepositType.CASH]: {
			icon: DepositType.CASH,
			name: formatMessage(DepositMessages.cashDeposits),
			link: `${url}/cash`,
			optionsCount: cash.totalCount,
		},
		[DepositType.VOUCHER]: {
			icon: DepositType.VOUCHER,
			name: formatMessage(DepositMessages.voucherDeposit),
			link: `${url}/voucher?init`,
			optionsCount: 1,
		},
	};

	// always redirect to crypto page as default on desktop
	useEffect(() => {
		if (!(isMobile || isTablet) && pathname === url) {
			replace(`${url}/crypto`);
		}
	}, [url, replace, isMobile, pathname, isTablet]);

	useEffectOnce(() => {
		void dispatch(fetchLinkedCards());
		void dispatch(fetchLinkedCardStatus());
		void dispatch(fetchAllPaymentMethods());
	});

	useEffect(() => {
		if (
			user &&
			linkedCards.isLoaded &&
			linkedCards.status.isLoaded &&
			card.isLoaded &&
			!cardDepositOptionsCount
		) {
			const cardCountLimitExceeded = !!linkedCards.status.data?.limitExceeded;
			const enabledOrPossibleToEnablePaymentMethods = card.data.filter(
				(method: PaymentMethod) => method.isEnabled || method.isEnabledForVerified
			);
			const addCardVisible =
				enabledOrPossibleToEnablePaymentMethods.length > 0 && !cardCountLimitExceeded;
			const { depositOptionCount } = getCardDepositMethodsAndCount(
				linkedCards.data,
				card.data,
				!!user.verified,
				addCardVisible
			);
			setCardDepositOptionsCount(depositOptionCount);
		}
	}, [linkedCards, paymentMethods, cardDepositOptionsCount, user, card.isLoaded, card.data]);

	return (
		<>
			<Seo title={DepositMessages.metaDepositsTitle} />
			<TransfersSidebar sidebarType={SidebarType.DEPOSIT} types={depositTypes} />
			<TransfersOptionContainer
				title={formatMessage(DepositMessages.backToDepositOptions)}
				previousPageLink={url}
				showStartingBlock={pathname.search('bank') !== -1}
			>
				<Route
					exact
					path={`${url}/crypto`}
					render={() => <CryptoDeposits accounts={cryptoAccounts} />}
				/>
				<Route
					exact
					path={`${url}/crypto/:currency/:id`}
					render={() => <CryptoDepositDetails />}
				/>
				<Route exact path={`${url}/e-wallet`} render={() => <EWalletDeposits />} />
				<Route
					exact
					path={`${url}/e-wallet/:paymentMethodId`}
					render={() => <EWalletDepositDetails />}
				/>
				<Route exact path={`${url}/card`} render={() => <CardDeposits />} />
				<Route exact path={`${url}/new-card`} render={() => <LinkNewCardDetails />} />
				<Route
					exact
					path={`${url}/ecommpay/:paymentMethodId`}
					render={() => <GooglePayDetails />}
				/>
				<Route exact path={`${url}/card/:cardId`} render={() => <CardDepositDetails />} />
				<Route exact path={`${url}/bank`} render={() => <BankDeposits />} />
				<Route
					exact
					path={`${url}/bank/:paymentMethodId`}
					render={() => <BankDepositDetails />}
				/>
				<Route exact path={`${url}/cash`} render={() => <CashDeposits />} />
				<Route
					exact
					path={`${url}/cash/:paymentMethodId`}
					render={() => <CashDepositDetails />}
				/>
				<Route exact path={`${url}/voucher`} render={() => <VoucherDeposits />} />
				<Route
					exact
					path={`${url}/history/crypto`}
					render={() => (
						<DepositHistory
							historyType={HistoryType.CRYPTO}
							previousPageLink={`${url}/crypto`}
							titleMessage={DepositMessages.cryptoDepositHistory}
						/>
					)}
				/>
				<Route
					exact
					path={`${url}/history/fiat`}
					render={() => (
						<DepositHistory
							historyType={HistoryType.FIAT}
							previousPageLink={`${url}`}
							titleMessage={DepositMessages.fiatDepositHistory}
						/>
					)}
				/>
				<Route
					exact
					path={`${url}/success`}
					render={() => (
						<DepositResult status={ResultStatus.SUCCESS} previousPageLink={url} />
					)}
				/>
				<Route
					exact
					path={`${url}/pending`}
					render={() => (
						<DepositResult status={ResultStatus.PENDING} previousPageLink={url} />
					)}
				/>
				<Route
					exact
					path={`${url}/failure`}
					render={() => (
						<DepositResult status={ResultStatus.FAILURE} previousPageLink={url} />
					)}
				/>
			</TransfersOptionContainer>
		</>
	);
};

export default DepositContainer;
