import { FC } from 'react';
import { InstantHistoryItemProps } from '../../../../../redux/ConvertHistoryState/ConvertHistoryTypes';
import InstantHistoryItem from '../InstantHistoryItem/InstantHistoryItem';

const InstantHistoryList: FC<{ data: InstantHistoryItemProps[] }> = ({ data }) => (
	// eslint-disable-next-line react/jsx-no-useless-fragment
	<>
		{data?.length > 0 &&
			data.map(
				({
					id,
					created,
					exchangeRate,
					payAmount,
					payCurrencyCode,
					receiveAmount,
					receiveCurrencyCode,
					status,
				}: InstantHistoryItemProps) => (
					<InstantHistoryItem
						key={id}
						id={id}
						created={created}
						exchangeRate={exchangeRate}
						payAmount={payAmount}
						payCurrencyCode={payCurrencyCode}
						receiveAmount={receiveAmount}
						receiveCurrencyCode={receiveCurrencyCode}
						status={status}
					/>
				)
			)}
	</>
);

export default InstantHistoryList;
