import Cookies from 'js-cookie';
import {
	AppState,
	AppActionType,
	ToggleViewAction,
	SetRefCurrencyAction,
	SetDeviceAction,
	SetDeviceOSAction,
	SetGeneralError,
	SetTwoFaRetrySetting,
	RateAmount,
	SetRateAmount,
} from './AppTypes';
import PublicSetting from '../../enums/PublicSetting';

const initialState: AppState = {
	showCardControl: false,
	showCardsInfoBanner: true,
	showCardsSidebar: true,
	showWalletSidebar: true,
	showCurrentRates: true,
	showCurrentRatesGraph: false,
	showMarketingSlider: true,
	showStartingBlock: true,
	rateAmount: RateAmount.DAY,
	referenceCurrency: Cookies.get('primaryCurrency') || 'USD',
	isMobile: false,
	isTablet: false,
	deviceOS: null,
	generalError: false,
	settings: {
		[PublicSetting.MAX_2FA_RETRY_COUNT]: 3,
	},
};

export default (
	state = initialState,
	action:
		| ToggleViewAction
		| SetRefCurrencyAction
		| SetDeviceAction
		| SetDeviceOSAction
		| SetGeneralError
		| SetTwoFaRetrySetting
		| SetRateAmount
) => {
	switch (action.type) {
		case AppActionType.IS_MOBILE:
			return {
				...state,
				isMobile: action.data,
			};
		case AppActionType.IS_TABLET:
			return {
				...state,
				isTablet: action.data,
			};
		case AppActionType.TOGGLE_CARDS_SIDEBAR:
			return {
				...state,
				showCardsSidebar: action.isVisible,
			};
		case AppActionType.TOGGLE_CARD_CONTROL:
			return {
				...state,
				showCardControl: action.isVisible,
			};
		case AppActionType.TOGGLE_CARDS_INFO_BANNER:
			return {
				...state,
				showCardsInfoBanner: action.isVisible,
			};
		case AppActionType.TOGGLE_WALLET_SIDEBAR:
			return {
				...state,
				showWalletSidebar: action.isVisible,
			};
		case AppActionType.TOGGLE_CURRENT_RATES:
			return {
				...state,
				showCurrentRates: action.isVisible,
			};
		case AppActionType.TOGGLE_CURRENT_RATES_GRAPH:
			return {
				...state,
				showCurrentRatesGraph: action.isVisible,
			};
		case AppActionType.TOGGLE_STARTING_BLOCK:
			return {
				...state,
				showStartingBlock: action.isVisible,
			};
		case AppActionType.TOGGLE_MARKETING_SLIDER:
			return {
				...state,
				showMarketingSlider: action.isVisible,
			};
		case AppActionType.SET_REFERENCE_CURRENCY:
			return {
				...state,
				referenceCurrency: action.currency,
			};
		case AppActionType.SET_DEVICE_OS:
			return {
				...state,
				deviceOS: action.data,
			};
		case AppActionType.SET_GENERAL_ERROR:
			return {
				...state,
				generalError: action.isError,
				showWalletSidebar: false,
			};
		case AppActionType.SET_RATE_AMOUNT:
			return {
				...state,
				rateAmount: action.rateAmount,
			};
		case AppActionType.SET_2FA_RETRY_SETTING:
			return {
				...state,
				settings: {
					...state.settings,
					[PublicSetting.MAX_2FA_RETRY_COUNT]: action.data,
				},
			};
		default:
			return state;
	}
};
