import { useIntl } from 'react-intl';
import { FC, ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import TextNode, { TextStyles } from '../../../../components/TextNode/TextNode';
import TestIds, { formatTestId } from '../../../../test/TestIds';
import useMerchantRoutes, { MerchantRoutes } from '../../../../hooks/useMerchantRoutes';
import { RootState } from '../../../../redux/Store';
import allProjectsIcon from '../../../../images/merchants/all_projects.svg';
import newProjectIcon from '../../../../images/merchants/new_project.svg';
import payoutIcon from '../../../../images/merchants/payout.svg';
import integrationToolsIcon from '../../../../images/merchants/integration_tools.svg';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';

import SvgIcon from '../../../../components/SvgIcon/SvgIcon';

import styles from './MerchantsMenuPanel.module.scss';

enum ActiveLinkResolution {
	Partial,
	Full,
}

type MerchantMenuOptionProps = {
	id: string;
	link: string;
	icon: ReactNode;
	activeClassName?: string;
	text: string;
	linkResolution: ActiveLinkResolution;
};
const MerchantMenuOption: FC<MerchantMenuOptionProps> = ({
	id,
	link,
	icon,
	text,
	linkResolution,
	activeClassName,
}) => {
	const { pathname } = useLocation();
	const { isMobile } = useSelector((state: RootState) => state.AppState);

	const linkTokens = link.split('/');
	const pathTokens = pathname.split('/');
	const active =
		isMobile ||
		(() => {
			switch (linkResolution) {
				case ActiveLinkResolution.Full:
					return link === pathname;
				case ActiveLinkResolution.Partial:
					return linkTokens.every((pathToken, index) => pathToken === pathTokens[index]);
				default:
					return false;
			}
		})();

	return (
		<Link
			data-cy={formatTestId(TestIds.PanelItem_0, id)}
			to={link}
			className={classNames(
				styles.link,
				{
					[styles.active]: active,
				},
				active && activeClassName
			)}
		>
			<div className={styles.iconContainer}>{icon}</div>
			<TextNode style={TextStyles.Ellipsis} className={styles.optionText}>
				<TextNode style={TextStyles.Ellipsis}>{text}</TextNode>
			</TextNode>
		</Link>
	);
};

const MerchantsMenuOptions: FC = () => {
	const { isMobile } = useSelector((state: RootState) => state.AppState);
	const { getUrl } = useMerchantRoutes();

	const { formatMessage } = useIntl();

	const options = useMemo(() => {
		return [
			{
				link: getUrl(MerchantRoutes.ProjectsList),
				icon: <SvgIcon src={allProjectsIcon} />,
				text: formatMessage(messages.allProjects),
				linkResolution: ActiveLinkResolution.Full,
			},
			{
				link: getUrl(MerchantRoutes.ProjectCreate),
				icon: <SvgIcon src={newProjectIcon} />,
				text: formatMessage(messages.newProject),
				linkResolution: ActiveLinkResolution.Full,
			},
			{
				link: getUrl(MerchantRoutes.IntegrationToolsRoot),
				icon: <SvgIcon src={integrationToolsIcon} />,
				text: formatMessage(messages.integrationTools),
				linkResolution: ActiveLinkResolution.Partial,
			},
			{
				link: getUrl(MerchantRoutes.CryptoPayouts),
				icon: <SvgIcon src={payoutIcon} />,
				text: formatMessage(messages.cryptoPayouts),
				linkResolution: ActiveLinkResolution.Partial,
			},
		];
	}, [getUrl, formatMessage]);

	return (
		<div className={classNames(styles.menu, { [styles.separators]: isMobile })}>
			<div className={styles.header}>
				<h3 className={styles.title}>{formatMessage(messages.options)}</h3>
			</div>
			{options.map((option) => {
				return <MerchantMenuOption key={option.link} id={option.text} {...option} />;
			})}
		</div>
	);
};

type MerchantsMenuPanelProps = {
	className?: string;
};

const MerchantsMenuPanel: FC<MerchantsMenuPanelProps> = ({ className }) => {
	return (
		<div className={className}>
			<MerchantsMenuOptions />
		</div>
	);
};

export default MerchantsMenuPanel;
