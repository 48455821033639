/* eslint-disable react/no-unknown-property */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Tippy, { TippyProps } from '@tippyjs/react';
import { forwardRef } from 'react';
import styles from './Tooltip.module.scss';
import 'tippy.js/dist/tippy.css'; // optional

type TooltipProps = {
	children?: any;
	content: any;
	infoIconVisible?: boolean;
} & TippyProps;

const Tooltip = forwardRef<Element, TooltipProps>(
	({ content, children, infoIconVisible, ...props }, ref) => {
		if (!content) return children;
		return (
			<Tippy ref={ref} theme="light" content={content} className={styles.tooltip} {...props}>
				{infoIconVisible ? <div className={styles.infoBubble}>i</div> : children}
			</Tippy>
		);
	}
);

export default Tooltip;
