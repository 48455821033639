import {
	FC,
	// useState
} from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import convertMsg from '../../convertMsg';
import styles from './OrderHistory.module.scss';
// import OpenOrdersFilter from './OpenOrdersFilter/OpenOrdersFilter';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import HistoryTable from '../../../../components/HistoryTable/HistoryTable';
import { HistoryType } from '../../../../hooks/useHistoryTableData';
import useOpenOrderDataProvider from './useOpenOrderDataProvider';
// import { ConvertStatusType } from '../../../redux/ConvertState/ConvertTypes';
// import useQueryParams from '../../../hooks/useQueryParams';

const OrderHistory: FC = () => {
	// const [nameFilter, setNameFilter] = useState<string>('');
	const { url }: { url: string } = useRouteMatch();
	const history = useOpenOrderDataProvider('');
	const { formatMessage } = useIntl();
	// const [[status]] = useQueryParams({
	// 	status: undefined,
	// });
	// const [keyword, setKeyword] = useState<ConvertStatusType | null>(
	// 	status ? ConvertStatusType[status] : null
	// );

	// const changeKeywork = (k: ConvertStatusType) => setKeyword(keyword === k ? null : k);

	// const changeFilter = (val: string) => setNameFilter(val);

	return (
		<div>
			<PageTitle
				previousPageLink={url.replace('/history', '')}
				isPreviousPageLinkVisibleOnDesktop
				isPreviousPageLinkVisibleOnMobile
				title={formatMessage(convertMsg.backButton)}
				className={styles.noPadding}
			/>
			{/* <OpenOrdersFilter
				filter={nameFilter}
				changeFilter={changeFilter}
				changeKeyword={changeKeywork}
				keyword={keyword}
			/> */}
			<HistoryTable
				data={history.data}
				isLoading={history.isLoading}
				totalCount={history.totalCount}
				historyType={HistoryType.OPEN_ORDERS}
			/>
		</div>
	);
};

export default OrderHistory;
