import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CurrencyEnum, CurrencyIcon } from '@spectrocoin/sc-currencies';
import { FormattedMessage } from 'react-intl';
import { RootState } from '../../../../redux/Store';

import styles from './AcceptedCurrenciesField.module.scss';
import TestIds from '../../../../test/TestIds';
import MerchantsMessages from '../../../../redux/MerchantsState/MerchantsMessages';

type AcceptedCurrenciesFieldProps = {
	currencies: CurrencyEnum[] | null;
};

const AcceptedCurrenciesField: FC<AcceptedCurrenciesFieldProps> = ({ currencies }) => {
	const { wallets } = useSelector((state: RootState) => state.AccountsState);

	const acceptedCurrencies = useMemo(() => {
		return currencies ? wallets?.filter((x) => currencies.includes(x.currencyCode)) || [] : [];
	}, [currencies, wallets]);

	return (
		<div className={styles.container} data-cy={TestIds.FieldAcceptedCurrencies}>
			{!acceptedCurrencies.length && (
				<FormattedMessage {...MerchantsMessages.allCurrencies} />
			)}
			{acceptedCurrencies.length === 1 && (
				<>
					<CurrencyIcon
						className={styles.icon}
						currencyType={acceptedCurrencies[0].currencyCode}
					/>
					{acceptedCurrencies[0].currencyCode}
				</>
			)}
			{acceptedCurrencies.length > 1 && (
				<FormattedMessage
					{...MerchantsMessages.x_currencies}
					values={{ Count: acceptedCurrencies.length }}
				/>
			)}
		</div>
	);
};

export default AcceptedCurrenciesField;
