import { defineMessages } from 'react-intl';

const messages = defineMessages({
	unexpectedError: {
		id: 'base.unexpectedError',
		defaultMessage: 'Unexpected error. Please contact support.',
	},
});

export default messages;
