/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThunkAction } from 'redux-thunk';
import { AppDispatch, RootState } from '../Store';
import {
	CardHistoryActionType,
	SetCardHistoryDataAction,
	ResetCardHistoryDataAction,
} from './CardHistoryTypes';
import { getDebitCardHistoryByIDURL } from '../endpoints';
import { isCardFunctionsEnabled } from '../../helpers/cardsHelper/cardsHelper';
import axiosInstance from '../../helpers/axiosInstance';

const setCardHistoryIsLoading = (isLoading: boolean) => ({
	type: CardHistoryActionType.CARD_HISTORY_IS_LOADING,
	isLoading,
});

export const getCardHistory = (
	id: string,
	startDate?: string,
	endDate?: string,
	page?: number
): ThunkAction<any, RootState, undefined, any> => async (dispatch, getState) => {
	dispatch<any>(setCardHistoryIsLoading(true));
	const currentCard = getState().CardsState.cardsList?.find(({ id: cardId }) => cardId === id);
	const isContis = currentCard ? !isCardFunctionsEnabled(currentCard.cardType) : false;

	try {
		const { data } = await axiosInstance.get(
			getDebitCardHistoryByIDURL(id, isContis, startDate, endDate, page)
		);
		const transactions = [
			data?.transactions,
			data?.pendingAuthorizationCount,
		].sort((a: any, b: any) => (new Date(a.tranDate) > new Date(b.tranDate) ? -1 : 1));

		const formattedData = {
			...data,
			...transactions,
		};
		dispatch<SetCardHistoryDataAction>({
			type: CardHistoryActionType.SET_CARD_DATA,
			data: formattedData,
		});
	} catch {
		null;
	}
	return dispatch(setCardHistoryIsLoading(false));
};

export const resetCardHistory = () => (dispatch: AppDispatch) => {
	return dispatch<ResetCardHistoryDataAction>({ type: CardHistoryActionType.RESET_CARD_DATA });
};
