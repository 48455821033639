import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Loading from '../../Shared/Loading/Loading';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';
import {
	Project,
	ProjectCreateForm,
	ProjectsCreateViewState,
	ProjectsListStatus,
} from '../../../../redux/MerchantsState/MerchantTypes';
import MerchantsSelectors from '../../../../redux/MerchantsState/MerchantsSelectors';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import NewMerchantsProjectCreating from './NewMerchantsProjectCreating/NewMerchantsProjectCreating';
import NewMerchantsProjectFailure from './NewMerchantsProjectFailure/NewMerchantsProjectFailure';
import NewMerchantsProjectSuccess from './NewMerchantsProjectSuccess/NewMerchantsProjectSuccess';
import merchantsSvg from './sc_merchants.svg';

import styles from './NewMerchantsProject.module.scss';
import NewMerchantsProjectContext from './Context/NewMerchantsProjectContext';
import { RemoteData, RemoteStatus } from '../../../../interfaces/RemoteData';

const NewMerchantsProject: FC = () => {
	const [state, setState] = useState(ProjectsCreateViewState.Creating);
	const [form, setForm] = useState<ProjectCreateForm>({
		autoConvert: null,
		currencies: [],
		description: null,
		isEnabled: true,
		testStatus: null,
		name: '',
		publicKey: null,
		forVerifiedPayers: false,
		forPayerFee: false,
	});
	const updateForm = useCallback((value: Partial<ProjectCreateForm>) => {
		setForm((current) => ({ ...current, ...value }));
	}, []);
	const [action, setAction] = useState<RemoteData<Project>>({ status: RemoteStatus.None });

	const { status: listStatus } = useSelector(MerchantsSelectors.projects.list.getState);

	const content = useMemo(() => {
		switch (state) {
			case ProjectsCreateViewState.Creating:
				return (
					<>
						{listStatus === ProjectsListStatus.Empty && (
							<WhiteContainer className={styles.infoBox}>
								<div>
									<div className={styles.title}>
										<FormattedMessage
											{...messages.acceptCrypoInYourBusinessTitle}
										/>
									</div>
									<div className={styles.details}>
										<div>
											<FormattedMessage
												{...messages.acceptCrypoInYourBusinessDetails}
											/>
										</div>
									</div>
								</div>
								<div>
									<SvgIcon className={styles.icon} src={merchantsSvg} />
								</div>
							</WhiteContainer>
						)}
						<WhiteContainer className={styles.container}>
							{<NewMerchantsProjectCreating />}
						</WhiteContainer>
					</>
				);
			case ProjectsCreateViewState.Error:
				return (
					<WhiteContainer className={styles.container}>
						{<NewMerchantsProjectFailure />}
					</WhiteContainer>
				);
			case ProjectsCreateViewState.Success:
				return (
					<WhiteContainer className={styles.container}>
						{<NewMerchantsProjectSuccess />}
					</WhiteContainer>
				);
			default:
				return null;
		}
	}, [state, listStatus]);

	if (listStatus === ProjectsListStatus.Loading) return <Loading />;

	const context = { form, updateForm, action, setAction, state, setState };

	return (
		<NewMerchantsProjectContext.Provider value={context}>
			<div className={styles.host}>{content}</div>
		</NewMerchantsProjectContext.Provider>
	);
};

export default NewMerchantsProject;
