import { defineMessages, MessageDescriptor } from 'react-intl';

interface Messages {
	[key: string]: MessageDescriptor;
}

const statusMsg: Messages = defineMessages({
	CONFIRMED: {
		id: 'status.CONFIRMED',
		defaultMessage: 'Confirmed',
	},
	PAID: {
		id: 'status.PAID',
		defaultMessage: 'Paid',
	},
	CLOSED: {
		id: 'status.CLOSED',
		defaultMessage: 'Closed',
	},
	ACTIVE: {
		id: 'status.ACTIVE',
		defaultMessage: 'Active',
	},
	FAILED: {
		id: 'status.FAILED',
		defaultMessage: 'Failed',
	},
	PENDING: {
		id: 'status.PENDING',
		defaultMessage: 'Pending',
	},
	SUCCESS: {
		id: 'status.SUCCESS',
		defaultMessage: 'Success',
	},
	COMPLETED: {
		id: 'status.COMPLETED',
		defaultMessage: 'Completed',
	},
	NEW: {
		id: 'status.NEW',
		defaultMessage: 'New',
	},
	TEST: {
		id: 'status.TEST',
		defaultMessage: 'Test',
	},
	EXPIRED: {
		id: 'status.EXPIRED',
		defaultMessage: 'Expired',
	},
	RESERVED: {
		id: 'status.RESERVED',
		defaultMessage: 'Reserved',
	},
	REVERSED: {
		id: 'status.REVERSED',
		defaultMessage: 'Reversed',
	},
	DECLINED: {
		id: 'status.DECLINED',
		defaultMessage: 'Declined',
	},
	CREATED: {
		id: 'status.CREATED',
		defaultMessage: 'Created',
	},
	CANCELLED: {
		id: 'status.cancelled',
		defaultMessage: 'Cancelled',
	},
});

export default statusMsg;
