import { Middleware } from 'redux';
import { RootState } from '../../Store';
import PreordersActions from './PreordersActions';

import { PreordersCreateActionFulfilledAction, PreordersCreateActionType } from './PreorderTypes';

const PreordersMiddleware: Middleware<void, RootState> = (storeApi) => (next) => (
	action: PreordersCreateActionFulfilledAction
) => {
	switch (action.type) {
		case PreordersCreateActionType.ACTION_FULFILLED:
			storeApi.dispatch(PreordersActions.data.updatePreorder(action.payload));
			break;
		default:
			break;
	}

	next(action);
};

export default [PreordersMiddleware];
