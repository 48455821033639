/* eslint-disable no-restricted-syntax */
import { useEffect, useState, useRef, FormEvent } from 'react';
import { useIntl, FormattedMessage, MessageDescriptor } from 'react-intl';
import ReactTextTransition, { presets } from 'react-text-transition';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { CurrencyIcon, CurrencyEnum } from '@spectrocoin/sc-currencies';
import { format } from 'date-fns';
import baseMsg from '../../../../messages/base.messages';
import { RootState } from '../../../../redux/Store';
import { getAccounts } from '../../../../redux/AccountsState/AccountsActions';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import {
	calcAmount,
	postExchange,
	postRecurringOrder,
} from '../../../../redux/ConvertState/ConvertActions';
import { CalcType, ConvertStatusType } from '../../../../redux/ConvertState/ConvertTypes';
import Seo from '../../../../components/Seo/Seo';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import styles from './ConvertConfirm.module.scss';
import ConvertLiveRates from '../ConvertLiveRates/ConvertLiveRates';
import { toPlainAmount } from '../../../../helpers/currencyAmountHelper/currencyAmountHelper';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import convertMsg from '../../convertMsg';
import { ConvertType } from '../ConvertForm/ConvertForm';
import useFormatAmount from '../../../../hooks/useFormatAmount';

enum PERIOD {
	DAILY = 'DAILY',
	WEEKLY = 'WEEKLY',
	MONTHLY = 'MONTHLY',
}

const intervalTime = 5000;

const getURL = (status: ConvertStatusType, url: string) => {
	switch (status) {
		case ConvertStatusType.PENDING:
		case ConvertStatusType.NEW:
			return url.replace('confirm', 'pending');
		case ConvertStatusType.ACTIVE:
		case ConvertStatusType.PAID:
			return url.replace('confirm', 'success');
		default:
			return url.replace('confirm', 'failed');
	}
};

const ConvertConfirm = () => {
	const timer = useRef<number>();
	const { url }: { url: string } = useRouteMatch();
	const amountFormatter = useFormatAmount();
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const { push }: any = useHistory();
	const [pay, setPay] = useState<string>('');
	const [receive, setReceive] = useState<string>('');
	const [error, setError] = useState<MessageDescriptor | null>(null);
	const [errorParams, setErrorParams] = useState<any>(null);
	const { state } = useLocation();

	const submitForm = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(true);
		const payAccountId = wallets?.find((o: any) => {
			return o.currencyCode === state.from;
		})?.id;
		const receiveAccountId = wallets?.find((o: any) => {
			return o.currencyCode === state.to;
		})?.id;
		if (payAccountId && receiveAccountId) {
			clearInterval(timer.current);
			if (state.convertType === ConvertType.INSTANT)
				await instantExchange(payAccountId, receiveAccountId);
			if (state.convertType === ConvertType.RECURRING)
				await recurringOrder(payAccountId, receiveAccountId);
		}
		return null;
	};

	const instantExchange = async (payAccountId: string, receiveAccountId: string) => {
		return postExchange(state.id, toPlainAmount(pay.toString()), payAccountId, receiveAccountId)
			.then((data) => handleResponse(data, receiveAccountId))
			.catch(() => null)
			.then(() => setIsLoading(false));
	};

	const recurringOrder = async (payAccountId: string, receiveAccountId: string) => {
		await postRecurringOrder(
			state.id,
			state.frequency,
			payAccountId,
			receiveAccountId,
			pay.toString(),
			state.startDate ? format(state.startDate, 'yyyy-MM-dd') : '',
			state.endDate ? format(state.endDate, 'yyyy-MM-dd') : ''
		)
			.then((data) => handleResponse(data, receiveAccountId))
			.catch(() => null)
			.then(() => setIsLoading(false));
	};

	const handleResponse = (data: any, receiveAccountId: string) => {
		if (data?.errorCode) {
			const params = {};
			const { errorParameters } = data;
			if (errorParameters) {
				for (const key of errorParameters) {
					params[key.key] =
						key.value === PERIOD.DAILY ||
						key.value === PERIOD.WEEKLY ||
						key.value === PERIOD.MONTHLY
							? formatMessage(baseMsg[key.value])
							: key.value;
				}
				setErrorParams(params);
				timer.current = window.setInterval(() => calculateAmount(), intervalTime);
				return setError(convertMsg[data.key]);
			}
		}
		void dispatch(getAccounts());
		return push({
			pathname: getURL(data?.status, url),
			state: {
				frequency: state.frequency,
				convertType: state.convertType,
				type: data?.status,
				form: state,
				backUrl: url.replace('/confirm', ''),
				id: receiveAccountId,
			},
		});
	};

	const calculateAmount = async () =>
		calcAmount(
			CalcType.RECEIVE,
			state.id,
			toPlainAmount(state.payAmount.toString())
		).then(({ amount }) => setReceive(amount));

	useEffect(() => {
		if (state) {
			const { payAmount, receiveAmount } = state;
			setPay(payAmount);
			return setReceive(receiveAmount);
		}
		return push(url.replace('/confirm', ''));
	}, [state, push, url]);

	useEffectOnce(() => {
		if (!timer.current) {
			timer.current = window.setInterval(() => calculateAmount(), intervalTime);
		}
		return () => clearInterval(timer.current);
	});

	if (state && state.from && state.to && pay && receive)
		return (
			<>
				<Seo title={convertMsg.convert} />
				<h2 className={styles.title}>
					{state.convertType === ConvertType.INSTANT && formatMessage(convertMsg.convert)}
					{state.convertType === ConvertType.RECURRING &&
						formatMessage(convertMsg.recurringOrder)}
				</h2>
				<form className={styles.form} onSubmit={(e) => submitForm(e)}>
					<div className={styles.amount}>
						<CurrencyIcon className={styles.icon} currencyType={state?.from} />
						{amountFormatter(
							formatPrecision(toPlainAmount(pay.toString()), CurrencyEnum[state.from])
						)}
						<div className={styles.currencyCode}>{state.from}</div>
					</div>
					<FontAwesomeIcon className={styles.arrowIcon} size="1x" icon={faArrowDown} />
					{receive && (
						<div className={styles.amount}>
							<CurrencyIcon className={styles.icon} currencyType={state?.to} />
							{state.frequency && '≈'}
							<ReactTextTransition
								text={amountFormatter(
									formatPrecision(
										toPlainAmount(receive.toString()),
										CurrencyEnum[state.to]
									)
								)}
								delay={100}
								noOverflow
								inline
								springConfig={presets.wobbly}
							/>
							<div className={styles.currencyCode}>{state.to}</div>
						</div>
					)}
					<ConvertLiveRates
						payCurrency={state.from}
						receiveCurrency={state.to}
						pairId={state.id}
						isWide
					/>
					{state.frequency && state.frequency && (
						<div className={styles.frequency}>
							{formatMessage(convertMsg.frequency)}
							<span>{formatMessage(convertMsg[state.frequency])}</span>
						</div>
					)}
					<div className={styles.relative}>
						<Button
							text={baseMsg.confirm}
							type={ButtonType.SUBMIT}
							className={styles.button}
							minWidth={176}
							isDisabled={isLoading}
							isLoading={isLoading}
						/>
						{error && (
							<div className={styles.error}>
								<FormattedMessage {...error} values={{ ...errorParams }} />
							</div>
						)}
						<Button
							text={baseMsg.back}
							link={url.replace('/confirm', '')}
							buttonStyle={ButtonStyle.BORDERLESS}
							type={ButtonType.LINK}
							className={styles.button}
							minWidth={176}
							isDisabled={isLoading}
							isLoading={isLoading}
						/>
					</div>
				</form>
			</>
		);
	return null;
};

export default ConvertConfirm;
