import IsFunctionalityAvailableResponse from '../../interfaces/IsFunctionalityAvailable';

export enum CardsActionType {
	SET_CARD_BY_ID = 'SET_CARD_BY_ID',
	SET_AVAILABLE_COUNTRIES = 'SET_AVAILABLE_COUNTRIES',
	ADD_SECURITY_DATA = 'ADD_SECURITY_DATA',
	SET_ORDER_STEP = 'SET_ORDER_STEP',
	SET_ORDER_CARD_IS_RHA = 'SET_ORDER_CARD_IS_RHA',
	SET_VERIFICATION_TYPE = 'SET_VERIFICATION_TYPE',
	SET_ORDER_CARD_FORM_DATA = 'SET_ORDER_CARD_FORM_DATA',
	RESET_ORDER_CARD_FORM = 'RESET_ORDER_CARD_FORM',
	SET_IS_CONTIS_USER = 'SET_IS_CONTIS_USER',
	SET_ALL_CARDS = 'SET_ALL_CARDS',
	SET_PRODUCT_OPTIONS = 'SET_PRODUCT_OPTIONS',
	SET_ORDER_DATA = 'SET_ORDER_DATA',
	SET_IS_LOADING_SECURITY = 'SET_IS_LOADING_SECURITY',
	SET_CARD_LIST_IS_LOADING = 'SET_CARD_LIST_IS_LOADING',
	SET_GENERAL_CARD_ERROR = 'SET_GENERAL_CARD_ERROR',
	SET_COMPANY_MOVEMENT = 'SET_COMPANY_MOVEMENT',
	SET_REQUIRES_VERIFICATION = 'SET_REQUIRES_VERIFICATION',
}

export enum CardStatusType {
	ACTIVE = 'ACTIVE',
	PENDING = 'PENDING',
	BLOCKED = 'BLOCKED',
	EXPIRED = 'EXPIRED',
	NOT_ACTIVATED = 'NOT_ACTIVATED',
	WAITING_PINSET = 'WAITING_PINSET',
	FAILED = 'FAILED',
	SUBMITTED = 'SUBMITTED',
	CONFIRMED = 'CONFIRMED',
	NEW = 'NEW',
	SUSPENDED = 'SUSPENDED',
	CLOSED = 'CLOSED',
}

export enum CardType {
	PLASTIC = 'PLASTIC',
	VIRTUAL = 'VIRTUAL',
}

export enum ShippingType {
	POST = 'POST',
	REGISTERED_POST = 'REGISTERED_POST',
	COURIER = 'COURIER',
}

export enum POAVerificationStatus {
	NEW = 'NEW',
	PENDING = 'PENDING',
	REJECTED = 'REJECTED',
	CONFIRMED = 'CONFIRMED',
	INCOMPLETE = 'INCOMPLETE',
	EXPIRED = 'EXPIRED',
}

export enum OrderSteps {
	TERMS = 0,
	TIN = 1,
	DELIVERY_ADDRESS = 2,
	SHIPPING = 3,
	VERIFICATION = 4,
	LINKED_WALLETS = 5,
	CONFIRM = 6,
	SET_PIN = 7,
	SUCCESS = 8,
	FAILURE = 9,
	SET_PASSWORD = 10,
}

export enum DeliveryType {
	DAY = 'DAY',
	WEEK = 'WEEK',
	MONTH = 'MONTH',
	INSTANT = 'INSTANT',
}

export enum CardTypeFromBE {
	C_PASTIC = 7,
	C_VIRTUAL = 8,
	PLASTIC = 9,
	VIRTUAL = 10,
	PERVESK_RHA_PLASTIC = 11,
	PERVESK_RHA_VIRTUAL = 12,
}

export enum SecurityType {
	ALLOW_CONTACTLESS = 'ALLOW_CONTACTLESS',
	ALLOW_CASHOUT = 'ALLOW_CASHOUT',
	ALLOW_ECOMMERCE = 'ALLOW_ECOMMERCE',
}

export enum LockType {
	STOPPED = 'STOPPED',
	LOST = 'LOST',
	STOLEN = 'STOLEN',
	DAMAGED = 'DAMAGED',
	BLOCK = 'BLOCK',
}

export enum LoadStatus {
	PENDING = 'PENDING',
	COMPLETED = 'COMPLETED',
	FAILED = 'FAILED',
}

export enum VerificationType {
	POA = 'POA',
	PHONE = 'PHONE',
}

export interface FormData {
	cardType?: {
		type: CardType;
		price: string;
	} | null;
	delvery?: {
		type: DeliveryType;
		value: string;
	} | null;
	tin?: string | null;
	tinCountry?: string | null;
	fullName?: string | null;
	streetName?: string | null;
	buildingNumber?: string | null;
	apartmentNumber?: string | null;
	city?: string | null;
	postCode?: string | null;
	deliveryCountry?: string | null;
	shipping?: {
		option: ShippingType;
		price: string;
	} | null;
	rha?: boolean;
	linkedAccounts?: {
		accountId: string;
		priorityIndex: number;
	}[];
}
export interface CardsState {
	isLoading: boolean;
	cardsList: CardItem[] | null;
	isCardUser: boolean | null;
	orderStep: OrderSteps;
	verificationType: VerificationType;
	availableCountries: string[];
	requiresVerification: boolean;
	formData: FormData | null;
	productOptions: ProductOption[] | null;
	orderData: OrderDataProps | null;
	generalError: boolean;
	companyMovement: IsFunctionalityAvailableResponse | null;
}

export interface SecurityReponse {
	allowCashout: boolean;
	allowContactless: boolean;
	allowEcommerce: boolean;
	isLoading: boolean;
}
export interface CardItem {
	id: string;
	status: CardStatusType;
	pan: string;
	userGuid: string;
	nameOnCard: string;
	date: string;
	accountBalance: string;
	reservationsBalance: string;
	holdsBalance: string;
	debtsBalance: string;
	blocksBalance: string;
	enabled3DSecure?: boolean;
	created3dsPassword?: boolean;
	cardType: CardTypeFromBE;
	type: CardType;
	cardId?: string;
	cardSecurity: SecurityReponse | null;
	expirationDate: string | null;
}

export interface OrderDataProps {
	pinId: string;
	cardId: string;
}

export interface ProductOption {
	type: CardType;
	rha: boolean;
	price: string;
	delivery: {
		type: DeliveryType;
		value: string;
	};
	requiresVerification: boolean;
}

export interface SetProductOptions {
	type: typeof CardsActionType.SET_PRODUCT_OPTIONS;
	data: ProductOption[];
}

export interface SetOrderStepAction {
	type: typeof CardsActionType.SET_ORDER_STEP;
	step: OrderSteps;
}

export interface SetVerificationTypeAction {
	type: typeof CardsActionType.SET_VERIFICATION_TYPE;
	verificationType: VerificationType;
}

export interface SetAvailableCountriesAction {
	type: typeof CardsActionType.SET_AVAILABLE_COUNTRIES;
	availableCountries: string[];
}

export interface SetRequiresVerificationAction {
	type: typeof CardsActionType.SET_REQUIRES_VERIFICATION;
	requiresVerification: boolean;
}

export interface SetOrderCardFormDataAction {
	type: typeof CardsActionType.SET_ORDER_CARD_FORM_DATA;
	data: FormData | null;
}

export interface ResetOrderCardFormAction {
	type: typeof CardsActionType.RESET_ORDER_CARD_FORM;
}

export interface SetIsCardUserAction {
	type: typeof CardsActionType.SET_IS_CONTIS_USER;
	isCardUser: boolean;
}

export interface SetAllCardsAction {
	type: typeof CardsActionType.SET_ALL_CARDS;
	data: CardItem[];
}

export interface SetOrderIdAction {
	type: typeof CardsActionType.SET_ORDER_DATA;
	orderData: OrderDataProps;
}

export interface SetIsLoadingSecurityAction {
	type: typeof CardsActionType.SET_IS_LOADING_SECURITY;
	isLoading: boolean;
}

export interface SetIsLoadingCardListAction {
	type: typeof CardsActionType.SET_CARD_LIST_IS_LOADING;
	isLoading: boolean;
}

export interface SetCardByIdAction {
	type: typeof CardsActionType.SET_CARD_BY_ID;
	data: CardItem;
}

export interface SetGeneralErrorAction {
	type: typeof CardsActionType.SET_GENERAL_CARD_ERROR;
	data: boolean;
}

export interface SetCompanyMovementAction {
	type: typeof CardsActionType.SET_COMPANY_MOVEMENT;
	data: IsFunctionalityAvailableResponse;
}
