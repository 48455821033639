/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState, useCallback } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import StorageKey from '../../enums/StorageKey';
import CookieSettings from './CookieSettings/CookieSettings';
import Button, { ButtonStyle, ButtonType } from '../Button/Button';
import styles from './CookieBanner.module.scss';
import useEffectOnce from '../../hooks/useEffectOnce';
import {
	loadNecessaryCookieScripts,
	loadPreferenceCookieScripts,
	loadStatisticalCookieScripts,
	loadMarketingCookieScripts,
	getGoogleAnalyticsScript,
} from '../../helpers/globalScriptHelper/globalScriptHelper';
import { getCookieRevokeURL, getGiveCookieConsentURL } from '../../redux/endpoints';
import axiosInstance from '../../helpers/axiosInstance';

const messages = defineMessages({
	title: {
		id: 'cookie.title',
		defaultMessage: 'We value your privacy',
	},
	acceptButton: {
		id: 'cookie.accept',
		defaultMessage: 'Agree',
	},
	showPurposesButton: {
		id: 'cookie.settings',
		defaultMessage: 'Cookie settings',
	},
	body: {
		id: 'cookie.body',
		defaultMessage: `We and our partners use technology such as cookies to ensure that the website functions properly and safely, as well as to analyse our traffic. Click on “Agree" if you consent to the use of this technology on our site. Please note that if you choose other options from "Cookie Settings" or decide to provide your consent later on, only the cookies necessary for the website operation will be implemented. Disabling cookies may slow down your browsing, restrict the functionality of certain features or block your access to the website. For more information on this topic, please visit our <PolicyLink>Cookie Policy</PolicyLink>.`,
	},
});

interface CookieBannerProps {
	facebookPixelId: string;
	gtmId?: string;
	hotjarId: string;
	includeFBPixel: boolean;
	isHotjarTrackingEnabled: boolean;
	livechatKey: string;
	siftScienceKey: string;
	twitterPixelId: string;
	userEmail?: string;
	userName?: string;
	userReferralCode?: string;
	userSurname?: string;
	utmTracking?: string;
	authDomain?: string;
}

export interface AllowedCookiesMap {
	[key: string]: boolean;
}

interface CookiePreference extends AllowedCookiesMap {
	marketingCookies: boolean;
	statisticalCookies: boolean;
	preferenceCookies: boolean;
}

export enum CookieConsentStatus {
	ACCEPTED = 'ACCEPTED',
	REVOKED = 'REVOKED',
}

const cookiesActiveByDefault: CookiePreference = {
	marketingCookies: false,
	statisticalCookies: true,
	preferenceCookies: true,
};

const CookieBanner = ({
	facebookPixelId,
	gtmId,
	hotjarId,
	includeFBPixel,
	isHotjarTrackingEnabled,
	livechatKey,
	siftScienceKey,
	twitterPixelId,
	userEmail = '',
	userName = '',
	userReferralCode = '',
	userSurname = '',
	utmTracking = '',
	authDomain = '',
}: CookieBannerProps) => {
	const [isCookieBannerVisible, setIsCookieBannerVisible] = useState(false);
	const [isCookieSettingsVisible, setIsCookieSettingsVisible] = useState(false);
	const location = typeof window !== 'undefined' ? window.location.pathname : '';
	const history = useHistory();
	const savedCookiePreferences = Cookies.get(StorageKey.ARE_COOKIES_ALLOWED);
	const { locale } = useIntl();
	const { preferenceCookies, statisticalCookies, marketingCookies } = savedCookiePreferences
		? JSON.parse(savedCookiePreferences)
		: cookiesActiveByDefault;

	const handleRevokeCookiesButtonClick = useCallback(() => {
		Cookies.remove(StorageKey.ARE_COOKIES_ALLOWED);
		setIsCookieBannerVisible(true);
		axiosInstance.post(getCookieRevokeURL());
	}, []);

	const showCookieSettings = useCallback(() => {
		setIsCookieSettingsVisible(true);
	}, []);

	const hideCookieSettings = useCallback(() => {
		setIsCookieSettingsVisible(false);
	}, []);

	useEffect(() => {
		const revokeCookiesButton = document.querySelector('.cookie-revoke-button');
		if (revokeCookiesButton) {
			revokeCookiesButton.addEventListener('click', handleRevokeCookiesButtonClick);
		}

		return () => {
			if (revokeCookiesButton) {
				revokeCookiesButton.removeEventListener('click', handleRevokeCookiesButtonClick);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleRevokeCookiesButtonClick, location]);

	useEffect(() => {
		setIsCookieBannerVisible(!savedCookiePreferences);
	}, [savedCookiePreferences]);

	useEffect(() => {
		loadNecessaryCookieScripts(userEmail, siftScienceKey);
	}, [siftScienceKey, userEmail]);

	useEffectOnce(() => {
		// scripts are loaded on mount. banner action triggers a reload
		if (preferenceCookies) {
			loadPreferenceCookieScripts(userEmail, userName, userSurname, livechatKey, authDomain);
		}
		if (statisticalCookies) {
			loadStatisticalCookieScripts(isHotjarTrackingEnabled, hotjarId);
		}
		if (marketingCookies) {
			loadMarketingCookieScripts(includeFBPixel, facebookPixelId, twitterPixelId);
		}

		getGoogleAnalyticsScript(
			userReferralCode,
			utmTracking,
			gtmId,
			marketingCookies,
			statisticalCookies
		);

		if (window.debugMode) {
			if (marketingCookies) {
				console.log('GA enabled | anonymizeIp: false | allowAdFeatures: true');
			} else if (statisticalCookies) {
				console.log('GA enabled | anonymizeIp: true | allowAdFeatures: false');
			} else {
				console.log('GA disabled');
			}
		}

		return history.listen(({ pathname }: any) => {
			if (pathname && window.gtag) {
				window.gtag('set', 'page_path', pathname);
			}
		});
	});

	const handleButtonClick = (
		status: CookieConsentStatus,
		allowedCookies: AllowedCookiesMap = cookiesActiveByDefault,
		hasToReload = true
	) => {
		let cookieExpirationDate: number | Date = 1; // Give 1 day if endpoint fails.
		void axiosInstance
			.post(getGiveCookieConsentURL(), {
				status,
				categories: {
					...cookiesActiveByDefault,
					...allowedCookies,
				},
			})
			.then((response) => {
				const { expiryDate } = response.data;
				cookieExpirationDate = new Date(expiryDate);
			})
			.catch(() => {
				return null;
			})
			.then(() => {
				Cookies.set(
					StorageKey.ARE_COOKIES_ALLOWED,
					JSON.stringify({
						...cookiesActiveByDefault,
						...allowedCookies,
					}),
					{ expires: cookieExpirationDate, domain: '.spectrocoin.com' }
				);

				if (!allowedCookies.marketingCookies && typeof window.fbq === 'function') {
					// User can open this banner and disable marketing cookies anytime
					window.fbq('consent', 'revoke');
					/* eslint-disable-next-line */
					if (window.debugMode) console.log('FB pixel: disabled');
				}

				setIsCookieBannerVisible(false);
				hideCookieSettings();
				if (hasToReload) window.location.reload();
			});
	};

	const handleAcceptButtonClick = (
		allowedCookies: AllowedCookiesMap = cookiesActiveByDefault,
		hasToReload = true
	) => {
		handleButtonClick(CookieConsentStatus.ACCEPTED, allowedCookies, hasToReload);
	};

	const handleRejectButtonClick = () => {
		handleButtonClick(CookieConsentStatus.REVOKED, {
			marketingCookies: false,
			statisticalCookies: false,
			preferenceCookies: false,
		});
	};

	if (!isCookieBannerVisible || process.env.REACT_APP_ENV === 'local') return null;
	return (
		<>
			<div data-cy="cookie-policy" className={styles.container}>
				<div className={styles.body}>
					<div className={styles.content}>
						<div className={styles.title}>
							<FormattedMessage {...messages.title} />
						</div>
						<div className={styles.text}>
							<FormattedMessage
								{...messages.body}
								values={{
									PolicyLink: (chunks) => (
										<a
											href={`https://spectrocoin.com/${locale}/terms/cookiePolicy.html`}
										>
											{chunks}
										</a>
									),
								}}
							/>
						</div>
					</div>
					<div className={styles.buttonGroup}>
						<Button
							data-cy="accept-btn"
							buttonStyle={ButtonStyle.PRIMARY}
							className={styles.button}
							onClick={() => handleAcceptButtonClick(cookiesActiveByDefault, false)}
							type={ButtonType.BUTTON}
						>
							<FormattedMessage {...messages.acceptButton} />
						</Button>
						<Button
							data-cy="purpose-btn"
							buttonStyle={ButtonStyle.LINK}
							className={styles.buttonLink}
							onClick={showCookieSettings}
							type={ButtonType.BUTTON}
						>
							<FormattedMessage {...messages.showPurposesButton} />
						</Button>
					</div>
				</div>
			</div>
			{isCookieSettingsVisible && (
				<CookieSettings
					hideCookieSettings={hideCookieSettings}
					onAcceptButtonClick={handleAcceptButtonClick}
					onRejectButtonClick={handleRejectButtonClick}
				/>
			)}
		</>
	);
};

export default CookieBanner;
