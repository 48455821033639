import { ChangeEvent } from 'react';
import classNames from 'classnames';
import { MessageDescriptor, useIntl } from 'react-intl';
import Cleave from 'cleave.js/react';
import inputStyles from '../../../../components/Input/Input.module.scss';

interface CardExpirationDateInputProps {
	autoComplete?: string;
	className?: string;
	id?: string;
	label?: string;
	name?: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
	type?: string;
	value?: string;
	errorMessage?: MessageDescriptor | string | null;
}

const CardExpirationDateInput = ({
	autoComplete = 'off',
	className,
	label,
	name,
	errorMessage,
	value,
	...restProps
}: CardExpirationDateInputProps) => {
	const { formatMessage } = useIntl();

	return (
		<div className={classNames(inputStyles.inputGroup, className)}>
			<label className={inputStyles.label} htmlFor={name}>
				{label}
			</label>
			<Cleave
				autoComplete={autoComplete}
				className={classNames(inputStyles.input, {
					[inputStyles.errorInput]: errorMessage,
				})}
				name={name}
				value={value}
				options={{
					date: true,
					datePattern: ['m', 'y'],
				}}
				placeholder="MM/YY"
				{...restProps}
			/>
			{errorMessage && (
				<div className={inputStyles.error}>
					{typeof errorMessage === 'string' ? errorMessage : formatMessage(errorMessage)}
				</div>
			)}
		</div>
	);
};

export default CardExpirationDateInput;
