import { createContext } from 'react';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { HistoryType } from '../../hooks/useHistoryTableData';

export type DefaultHistoryContextData = {
	id?: string;
	historyType: HistoryType;
	filter?: CurrencyEnum;
};

const HistoryContext = createContext<Record<string, any>>({});

export default HistoryContext;
