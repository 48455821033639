import { createContext, useContext } from 'react';

// Define the shape of your context value
interface NotificationContextValue {
	reload: () => void;
}

// Create the context
export const NotificationContext = createContext<NotificationContextValue | undefined>(undefined);

// Create a custom hook to access the context
export const useNotificationContext = () => {
	const context = useContext(NotificationContext);
	if (!context) {
		throw new Error('useNotificationContext must be used within a NotificationContextProvider');
	}
	return context;
};
