import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { useRouteMatch, useHistory } from 'react-router';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import {
	getCardActivationTokenURL,
	getCardActivationURL,
	getOldDebitCardActivateURL,
} from '../../../../redux/endpoints';
import { getCard, updateDebitCard } from '../../../../redux/CardsState/CardsActions';
import {
	GetCurrentCard,
	isCardFunctionsEnabled,
} from '../../../../helpers/cardsHelper/cardsHelper';
import activateImgPath from './images/activate.svg';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import Button, { ButtonType } from '../../../../components/Button/Button';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import Input from '../../../../components/Input/Input';
import styles from './CardsActivate.module.scss';
import Seo from '../../../../components/Seo/Seo';
import baseMsg from '../../../../messages/base.messages';
import inputErrors from '../../../../messages/inputErrors.messages';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	cvvError: {
		id: 'cardsActivate.cvvError',
		defaultMessage: 'Incorrect CVV code',
	},
	title: {
		id: 'cardsActivate.title',
		defaultMessage: 'Activate your card',
	},
	metaTitle: {
		id: 'cardsActivate.metaTitle',
		defaultMessage: 'Activate your card',
	},
	post: {
		id: 'cardsActivate.post',
		defaultMessage:
			'Enter the CVV code of your card to activate it. You should find the code on the back of your card.',
	},
	cvv: {
		id: 'cardActivate.cvv',
		defaultMessage: 'CVV code',
	},
	enterCode: {
		id: 'cardActivate.enterCode',
		defaultMessage: 'Enter code',
	},
	wrongCvv: {
		id: 'cardActivate.wrongCvv',
		defaultMessage: 'Wrong CVV code',
	},
});

const CardsActivate = () => {
	const { url } = useRouteMatch();
	const dispatch = useDispatch();
	const { push } = useHistory();
	const card = GetCurrentCard();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [cvv, setCvv] = useState<string>('');
	const [cvvError, setCvvError] = useState<MessageDescriptor | null>(null);

	const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
		if (value.length > 3) return null;
		setCvvError(null);
		return setCvv(value);
	};

	const activateCard = useCallback(
		async (Authorization: string) => {
			if (card)
				await axiosInstance
					.post(
						getCardActivationURL(card?.cardId || ''),
						{ cvv },
						{ headers: { Authorization } }
					)
					.then(() => {
						void updateDebitCard(card?.id).then(() => {
							return dispatch(getCard(card?.id));
						});
						return push(url.replace('activate', 'enable-online-transactions'));
					})
					.catch(() => {
						return setCvvError(messages.wrongCvv);
					})
					.then(() => setIsLoading(false));
		},
		[card, cvv, dispatch, push, url]
	);

	const handelSubmit = (event: FormEvent) => {
		event.preventDefault();
		if (!cvv) return setCvvError(inputErrors.cannotBeEmpty);
		if (cvv.length !== 3) return setCvvError(messages.cvvError);
		setIsLoading(true);
		if (card && isCardFunctionsEnabled(card?.cardType)) {
			return axiosInstance
				.get(getCardActivationTokenURL(card?.id))
				.then(({ data }: AxiosResponse) => {
					return activateCard(data.authorizationHeader);
				});
		}
		return axiosInstance
			.post(getOldDebitCardActivateURL(card?.id || ''), { cvv })
			.then(() => {
				void dispatch(getCard(card?.id || ''));
				return push(url.replace('activate', 'history'));
			})
			.catch(() => setCvvError(messages.wrongCvv))
			.then(() => setIsLoading(false));
	};

	return (
		<WhiteContainer>
			<Seo title={messages.metaTitle} />
			<>
				<InfoHead title={messages.title} text={messages.post} img={activateImgPath} />
				<form className={styles.form} onSubmit={handelSubmit}>
					<Input
						label={messages.cvv}
						labelClassName={styles.label}
						onChangeEvent={handleChange}
						placeholder={messages.enterCode}
						value={cvv}
						name="cvv"
						className={styles.input}
						errorMessage={cvvError}
						type="number"
					/>
					<Button
						text={baseMsg.submit}
						type={ButtonType.SUBMIT}
						className={styles.button}
						minWidth={176}
						isLoading={isLoading}
						isDisabled={isLoading}
					/>
				</form>
			</>
		</WhiteContainer>
	);
};

export default CardsActivate;
