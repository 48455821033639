import classNames from 'classnames';
import { ChangeEvent, TextareaHTMLAttributes, useCallback, useState } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import generateUUID from '../../helpers/generateUUID/generateUUID';
import styles from './Textarea.module.scss';

interface TextareaProps
	extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'placeholder' | 'onChange'> {
	inputGroupClassName?: string;
	labelClassName?: string;
	className?: string;
	errorMessage?: MessageDescriptor | string | null;
	label?: MessageDescriptor | string;
	onChange?: (value: string) => void;
	onChangeEvent?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
	placeholder?: MessageDescriptor | string;
	value: string;
	onBlur?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea = ({
	value,
	placeholder,
	label,
	labelClassName,
	id,
	className,
	inputGroupClassName,
	onChange,
	onChangeEvent,
	errorMessage,
	onBlur,
	...rest
}: TextareaProps) => {
	const { formatMessage } = useIntl();
	const [inputId] = useState(id || generateUUID());

	const onInputChange = useCallback(
		(event: ChangeEvent<HTMLTextAreaElement>) => {
			if (typeof onChange === 'function') {
				onChange(event.target.value);
			}
			if (typeof onChangeEvent === 'function') onChangeEvent(event);
			return null;
		},
		[onChange, onChangeEvent]
	);

	const onInputBlur = useCallback(
		(event: ChangeEvent<HTMLTextAreaElement>) => {
			if (typeof onBlur === 'function') onBlur(event);
			return null;
		},
		[onBlur]
	);

	return (
		<div className={classNames(styles.inputGroup, inputGroupClassName)}>
			{label && (
				<label htmlFor={inputId} className={classNames(styles.label, labelClassName)}>
					{typeof label === 'string' && label}
					{typeof label !== 'string' && <FormattedMessage {...label} />}
				</label>
			)}
			<textarea
				className={classNames(styles.textarea, className, {
					[styles.errorInput]: errorMessage,
				})}
				value={value}
				id={inputId}
				onBlur={(event) => onInputBlur(event)}
				onChange={(event) => onInputChange(event)}
				placeholder={
					typeof placeholder === 'string'
						? placeholder
						: placeholder
						? formatMessage(placeholder)
						: ''
				}
				{...rest}
			/>
			{errorMessage && (
				<div className={styles.error} data-cy={`${id || ''}TextAreaErrorMessage`}>
					{typeof errorMessage === 'string' ? (
						errorMessage
					) : (
						<FormattedMessage {...errorMessage} />
					)}
				</div>
			)}
		</div>
	);
};

export default Textarea;
