import classNames from 'classnames';
import { ChangeEvent, useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import getUUID from '../../helpers/generateUUID/generateUUID';
import styles from './RadioBox.module.scss';

export interface RadioOption {
	title: MessageDescriptor | string;
	value: string;
}

interface RadioBoxProps {
	label?: MessageDescriptor | string | null;
	id?: string;
	options: RadioOption[];
	name: string;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	checked: string;
	disabled?: boolean;
}

const RadioBox = ({
	label,
	id,
	options,
	name,
	onChange,
	checked,
	disabled = false,
}: RadioBoxProps) => {
	const [inputId] = useState(id || getUUID());
	const { formatMessage } = useIntl();

	return (
		<div className={classNames(styles.inputGroup, { [styles.disabled]: disabled })}>
			{label && (
				<label className={styles.label} htmlFor={inputId}>
					{typeof label === 'string' ? label : formatMessage(label)}
				</label>
			)}
			{options.map(({ title, value }: RadioOption) => (
				<div key={value} className={styles.radioBox}>
					<input
						type="radio"
						className={styles.input}
						id={value}
						name={name}
						value={value}
						checked={value === checked}
						onChange={onChange}
						disabled={disabled}
					/>
					{title && (
						<label className={styles.radioButton} htmlFor={value}>
							<span
								className={classNames(styles.checkmark, {
									[styles.active]: value === checked,
								})}
							/>
							{typeof title === 'string' ? title : formatMessage(title)}
						</label>
					)}
				</div>
			))}
		</div>
	);
};
export default RadioBox;
