import { defineMessages, MessageDescriptor } from 'react-intl';

interface Messages {
	[key: string]: MessageDescriptor;
}

const transfersMsg: Messages = defineMessages({
	howToTitle: {
		id: 'deposit.howToTitle',
		defaultMessage: 'How to {type} with {paymentMethodName}',
	},
	fundsWillBeInYourAccountIn: {
		id: 'deposit.fundsWillBeInYourAccountIn',
		defaultMessage: 'The funds will be in your account in {duration}',
	},
});

export default transfersMsg;
