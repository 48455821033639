import { FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TestIds from '../../../../../test/TestIds';
import useValidation from '../../../../../hooks/useValidation';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../../components/NotificationMessage/NotificationMessage';
import { getErrorMessageOrDefault } from '../../../../../helpers/errorMessageHelper/errorMessageHelper';
import { RemoteData, RemoteStatus } from '../../../../../interfaces/RemoteData';
import Button, { ButtonType } from '../../../../../components/Button/Button';
import MerchantsActions from '../../../../../redux/MerchantsState/MerchantsActions';
import MerchantsMessages from '../../../../../redux/MerchantsState/MerchantsMessages';
import PaymentButtonForm, {
	usePaymentButtonFormValidator,
} from '../../../Shared/PaymentButtonForm/PaymentButtonForm';
import useMerchantRoutes, { MerchantRoutes } from '../../../../../hooks/useMerchantRoutes';
import PageTitle from '../../../Shared/PageTitle/PageTitle';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';

import styles from './PaymentButtonsCreate.module.scss';
import baseMsg from '../../../../../messages/base.messages';
import PaymentButtonsCreateContext from './Context/PaymentButtonsCreateContext';
import {
	PaymentButton,
	PaymentButtonCreateForm,
} from '../../../../../redux/MerchantsState/MerchantTypes';
import { getPaymentButtonsUrl } from '../../../../../redux/endpoints';
import axiosInstance from '../../../../../helpers/axiosInstance';

const PaymentButtonsCreate: FC = () => {
	const { push } = useHistory();
	const { getUrl } = useMerchantRoutes();
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();

	const [form, setForm] = useState<PaymentButtonCreateForm>({
		accountId: null,
		currencies: [],
		description: null,
		isEnabled: true,
		name: '',
	});
	const updateForm = useCallback((value: Partial<PaymentButtonCreateForm>) => {
		setForm((current) => ({ ...current, ...value }));
	}, []);

	const [action, setAction] = useState<RemoteData<PaymentButton>>({ status: RemoteStatus.None });

	const { accountId, description, isEnabled, name, currencies } = form;
	const { status, data: createdButton, error } = action;

	const create = useCallback(() => {
		setAction({ status: RemoteStatus.InProgress });
		void axiosInstance
			.post<PaymentButton>(getPaymentButtonsUrl(), {
				description,
				enabled: isEnabled,
				name,
				payCurrencyCodes: currencies,
				receiveAccountId: accountId,
			})
			.then(({ data }) => {
				dispatch(MerchantsActions.paymentButtons.create.setActionFulfilled(data));
				setAction({ status: RemoteStatus.Done, data });
			})
			.catch((e) => {
				setAction({ status: RemoteStatus.Error, error: e.response?.data });
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [description, isEnabled, name, currencies, accountId]);

	const validator = usePaymentButtonFormValidator();
	const [isValid] = useValidation({ name, description }, validator);

	useEffect(() => {
		if (status === RemoteStatus.Done)
			push(getUrl(MerchantRoutes.PaymentButton, { paymentButtonId: createdButton!.id }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	// eslint-disable-next-line react/jsx-no-constructed-context-values
	const context = { form, updateForm, action, setAction };

	return (
		<PaymentButtonsCreateContext.Provider value={context}>
			<WhiteContainer className={styles.container}>
				<PageTitle
					className={styles.title}
					title={formatMessage(MerchantsMessages.createNewPaymentButton)}
					backLink={getUrl(MerchantRoutes.PaymentButtons)}
				/>
				<PaymentButtonForm
					formError={
						status === RemoteStatus.Error && (
							<NotificationMessage
								withIcon
								data-cy={TestIds.PaymentButtonCreateError}
								type={NotificationType.Error}
								style={NotificationStyle.Border}
								message={formatMessage(getErrorMessageOrDefault(error))}
							/>
						)
					}
					className={styles.form}
					autoConvert={accountId}
					acceptCurrencies={currencies}
					description={description}
					isEnabled={isEnabled}
					name={name}
					onSetAutoConvert={(value) => {
						updateForm({ accountId: value });
					}}
					onAcceptCurrenciesChange={(value) => {
						updateForm({ currencies: value || [] });
					}}
					onSetDescription={(value) => {
						updateForm({ description: value });
					}}
					onSetIsEnabled={(value) => {
						updateForm({ isEnabled: value });
					}}
					onSetName={(value) => {
						updateForm({ name: value });
					}}
				>
					<Button
						data-cy={TestIds.PaymentButtonCreateSubmit}
						className={styles.button}
						type={ButtonType.BUTTON}
						isDisabled={!name || status === RemoteStatus.InProgress || !isValid}
						onClick={create}
					>
						<FormattedMessage {...baseMsg.submit} />
					</Button>
				</PaymentButtonForm>
			</WhiteContainer>
		</PaymentButtonsCreateContext.Provider>
	);
};

export default PaymentButtonsCreate;
