import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RootState } from '../../../../redux/Store';
import { Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import {
	AvailableVouchersResponse,
	WithdrawType,
} from '../../../../redux/WithdrawState/WithdrawTypes';
import Select from '../../../../components/Select/Select';
import WalletOption from '../WalletOption/WalletOption';
import styles from './AccountSelect.module.scss';

const messages = defineMessages({
	label: {
		id: 'cryptoWithdrawForm.withdrawalWallet',
		defaultMessage: 'Withdrawal wallet',
	},
});

interface OptionProps {
	value: string;
	label: JSX.Element;
}

interface AccountSelectProps {
	onChange: (value: string) => void;
	value: string;
	label?: MessageDescriptor;
	errorMessage: MessageDescriptor | string | null;
	type: WithdrawType;
	paymentCurrencies?: CurrencyEnum[];
}

const AccountSelect = ({
	onChange,
	value,
	label,
	errorMessage,
	type,
	paymentCurrencies,
}: AccountSelectProps): JSX.Element => {
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const { paymentMethods } = useSelector((state: RootState) => state.WithdrawState);
	const { voucher } = paymentMethods;
	const { data } = voucher;

	const [options, setOptions] = useState<OptionProps[]>([]);

	useEffect(() => {
		if (wallets && wallets.length > 0 && options.length === 0) {
			setOptions(
				wallets
					.filter(
						({ currencyCode }: Wallet) =>
							([
								WithdrawType.BANK,
								WithdrawType.EWALLET,
								WithdrawType.OFFCHAIN,
							].includes(type) &&
								paymentCurrencies &&
								paymentCurrencies.length > 0 &&
								paymentCurrencies.includes(CurrencyEnum[currencyCode])) ||
							(type === WithdrawType.VOUCHER &&
								data
									.map(
										({ currencyCode: curCode }: AvailableVouchersResponse) =>
											curCode
									)
									.includes(currencyCode as CurrencyEnum)) ||
							[WithdrawType.GIFT, WithdrawType.TOPUP].includes(type)
					)
					.reduce(
						(
							acc: OptionProps[],
							{ id, currencyCode, availableBalance, currencyName }: Wallet
						) => {
							const item = {
								value: `${currencyCode}/${currencyName}/${id}`,
								label: (
									<WalletOption
										currencyCode={currencyCode}
										currencyName={currencyName}
										availableBalance={availableBalance}
									/>
								),
							};
							acc.push(item);
							return acc;
						},
						[]
					)
			);
		}
	}, [data, options.length, paymentCurrencies, type, wallets]);

	useEffect(() => {
		if (!value && options.length > 0) {
			onChange(options[0].value);
		}
	}, [options, onChange, value]);

	return (
		<Select
			value={value}
			options={options}
			labelClassName={styles.label}
			label={label || messages.label}
			onChange={onChange}
			errorMessage={errorMessage}
			id="account"
		/>
	);
};

export default AccountSelect;
