import { defineMessages, MessageDescriptor } from 'react-intl';

interface Messages {
	[key: string]: MessageDescriptor;
}

const convertMsg: Messages = defineMessages({
	cancelOrderTitle: {
		id: 'convert.cancelOrderTitle',
		defaultMessage: 'Cancel order',
	},
	cancelOrderPost: {
		id: 'convert.cancelOrderPost',
		defaultMessage: 'Are you sure you want to cancel this order?',
	},
	cancelButton: {
		id: 'convert.cancelButton',
		defaultMessage: 'Yes, cancel',
	},
	instantHistoryBack: {
		id: 'convert.instantHistoryBack',
		defaultMessage: 'Open instant exchange',
	},
	exchangeRate: {
		id: 'convert.exchangeRate',
		defaultMessage: 'Exchange rate',
	},
	receiveAmount: {
		id: 'convert.receiveAmount',
		defaultMessage: 'Receive amount',
	},
	sellAmount: {
		id: 'convert.sellAmount',
		defaultMessage: 'Sell amount',
	},
	convert: {
		id: 'convert.convert',
		defaultMessage: 'Instant exchange',
	},
	toTooltip: {
		id: 'convert.toTooltip',
		defaultMessage:
			'The amount received for future orders will depend on the market rate at the time of execution. For the first order, if the start date is now, it will be executed immediately and the amount will be similar to the estimated amount.',
	},
	to: {
		id: 'convert.to',
		defaultMessage: 'To (estimated)',
	},
	period_receive_limit_exceeded: {
		id: 'convert.period_receive_limit_exceeded',
		defaultMessage: '{period} incoming payment limit of {amountLimit} {currency} exceeded',
	},
	period_pay_limit_exceeded: {
		id: 'convert.period_pay_limit_exceeded',
		defaultMessage: '{period} outgoing payment limit of {amountLimit} {currency} exceeded',
	},
	not_enough_balance: {
		id: 'convert.insufficientFunds',
		defaultMessage: 'Insufficient funds.',
	},
	too_small_pay_amount: {
		id: 'convert.too_small_pay_amount',
		defaultMessage: 'Minimum outgoing payment is {amount} {currency}',
	},
	too_small_receive_amount: {
		id: 'convert.too_small_receive_amount',
		defaultMessage: 'Minimum incoming payment is {amount} {currency}',
	},
	openOrderHistory: {
		id: 'convert.openOrderHistory',
		defaultMessage: 'View open orders',
	},
	instantHistory: {
		id: 'convert.instantHistory',
		defaultMessage: 'View history',
	},
	previewExchange: {
		id: 'convert.preview',
		defaultMessage: 'Preview exchange',
	},
	previewOrder: {
		id: 'convert.previewOrder',
		defaultMessage: 'Preview order',
	},
	enterAmount: {
		id: 'convert.enterAmount',
		defaultMessage: 'Enter amount to convert',
	},
	greaterThenZero: {
		id: 'convert.greaterThenZero',
		defaultMessage: 'Input value should be greater then zero',
	},
	recurringOrder: {
		id: 'convert.recurringOrder',
		defaultMessage: 'Recurring order',
	},
	selectOrderConditions: {
		id: 'convert.selectOrderConditions',
		defaultMessage: 'Select order conditions',
	},
	frequency: {
		id: 'convert.frequency',
		defaultMessage: 'Frequency',
	},
	DAILY: {
		id: 'convert.DAILY',
		defaultMessage: 'Daily',
	},
	WEEKLY: {
		id: 'convert.WEEKLY',
		defaultMessage: 'Weekly',
	},
	BIWEEKLY: {
		id: 'convert.BIWEEKLY',
		defaultMessage: 'Bi-weekly',
	},
	MONTHLY: {
		id: 'convert.MONTHLY',
		defaultMessage: 'Monthly',
	},
	DAILYtext: {
		id: 'convert.DAILYtext',
		defaultMessage: 'Recurring exchanges every day',
	},
	WEEKLYtext: {
		id: 'convert.WEEKLYtext',
		defaultMessage: 'Recurring exchanges every 7 days',
	},
	BIWEEKLYtext: {
		id: 'convert.BIWEEKLYtext',
		defaultMessage: 'Recurring exchanges every 14 days',
	},
	MONTHLYtext: {
		id: 'convert.MONTHLYtext',
		defaultMessage: 'Recurring exchanges every 30 days',
	},
	noResult: {
		id: 'convertInput.noResult',
		defaultMessage: 'No result',
	},
	estimatedRate: {
		id: 'convert.estimatedRate',
		defaultMessage: 'Estimated rate:',
	},
	instantFailed: {
		id: 'convertResult.instantFailed',
		defaultMessage: 'Your instant exchange failed',
	},
	instantPending: {
		id: 'convertResult.instantPending',
		defaultMessage: 'Your instant exchange is pending',
	},
	instantSuccess: {
		id: 'convertResult.instantSuccess',
		defaultMessage: 'Your instant exchange has been successful',
	},
	exchangeInfo: {
		id: 'convertResult.exchangeInfo',
		defaultMessage: 'You have exchanged {from} to {to}.',
	},
	tryAgain: {
		id: 'convertFailed.tryAgain',
		defaultMessage: 'Try again',
	},
	linkText: {
		id: 'convertFailed.contactSupport',
		defaultMessage: 'Contact support',
	},
	backToExchange: {
		id: 'convertResult.backToExchange',
		defaultMessage: 'Back to Exchange',
	},
	goToWallet: {
		id: 'convertResult.goToWallet',
		defaultMessage: 'Go to {currency} Wallet',
	},
	backButton: {
		id: 'convert.backToRecurring',
		defaultMessage: 'Open recurring orders',
	},
	additionalInfo: {
		id: 'convert.additionalInfo',
		defaultMessage: 'Amounts quoted are approximate, and are subject to minor fluctuations.',
	},
	readMore: {
		id: 'convert.readMore',
		defaultMessage: 'Read and learn conditions of <a>Virtual Currency Sale & Purchase</a>.',
	},
	available: {
		id: 'convert.available',
		defaultMessage: 'Available: {amount}',
	},
	active: {
		id: 'convert.active',
		defaultMessage: 'Active',
	},
	paused: {
		id: 'convert.paused',
		defaultMessage: 'Paused',
	},
	filterTitle: {
		id: 'convert.filterTitle',
		defaultMessage: 'Filter by Keywords',
	},
	assets: {
		id: 'convert.assets',
		defaultMessage: 'Assets',
	},
	interval: {
		id: 'convert.interval',
		defaultMessage: 'Interval',
	},
	payAmountHistory: {
		id: 'convert.payAmountHistory',
		defaultMessage: 'Pay amount',
	},
	status: {
		id: 'convert.status',
		defaultMessage: 'Status',
	},
	now: {
		id: 'convert.now',
		defaultMessage: 'Now',
	},
	never: {
		id: 'convert.never',
		defaultMessage: 'Never',
	},
	start: {
		id: 'convert.start',
		defaultMessage: 'Start (optional)',
	},
	end: {
		id: 'convert.end',
		defaultMessage: 'End (optional)',
	},
	orderFrequency: {
		id: 'convert.orderFrequency',
		defaultMessage: 'Order frequency',
	},
	endTooltip: {
		id: 'convert.endTooltip',
		defaultMessage:
			'Please note that recurring orders can be stopped or cancelled at any time in the open orders view.',
	},
	recurringSuccess: {
		id: 'convert.recurringSuccess',
		defaultMessage: 'You have set to exchange {payAmount} to {receiveCurrency} {frequency}.',
	},
	viewOpenOrders: {
		id: 'convert.viewOpenOrders',
		defaultMessage: 'View open orders',
	},
	recurringSuccessTitle: {
		id: 'convert.recurringSuccessTitle',
		defaultMessage: 'Your recurring order has been placed',
	},
	historyPeriodTextDAILY: {
		id: 'convert.everyDay',
		defaultMessage: 'Every day',
	},
	historyPeriodTextWEEKLY: {
		id: 'convert.everyWeek',
		defaultMessage: 'Every month',
	},
	historyPeriodTextBIWEEKLY: {
		id: 'convert.every14days',
		defaultMessage: 'Every 14 days',
	},
	historyPeriodTextMONTHLY: {
		id: 'convert.everyMonth',
		defaultMessage: 'Every month',
	},
	openOrdersStart: {
		id: 'convert.openOrdersStart',
		defaultMessage: 'Start',
	},
	openOrdersEnd: {
		id: 'convert.openOrdersEnd',
		defaultMessage: 'End',
	},
	untilCancel: {
		id: 'convert.untilCancel',
		defaultMessage: 'Until cancel',
	},
});

export default convertMsg;
