import { ChangeEvent, FormEvent, useEffect, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import countries from 'i18n-iso-countries';
import { AxiosResponse } from 'axios';
import { getCountryCallingCode } from 'react-phone-number-input';
import baseMsg from '../../../../messages/base.messages';
import inputErrors from '../../../../messages/inputErrors.messages';
import { RootState } from '../../../../redux/Store';
import { setFormData, setOrderStep } from '../../../../redux/CardsState/CardsActions';
import { getDeliveryCountriesListURL, getPOAStatusURL } from '../../../../redux/endpoints';
import {
	CardType,
	OrderSteps,
	POAVerificationStatus,
} from '../../../../redux/CardsState/CardsTypes';
import { ErrorMessageCodes } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import Loader from '../../../../components/Loader/Loader';
import BackButton from '../../../../components/BackButton/BackButton';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import Input from '../../../../components/Input/Input';
import Select, { SelectStyleType } from '../../../../components/Select/Select';
import CountryUnavailable from '../CountryUnavailable/CountryUnavailable';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import styles from './DeliveryAddress.module.scss';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	wrongCountry: {
		id: 'deliveryAddress.wrongCountry',
		defaultMessage:
			'Cards to your country are coming soon. Currently cards are only available for residents of European Economic Area (EEA)',
	},
	plasticTitle: {
		id: 'deliveryAddress.title',
		defaultMessage: 'Delivery address',
	},
	virtualTitle: {
		id: 'deliveryAddress.virtualTitle',
		defaultMessage: 'Your address',
	},
	subTitle: {
		id: 'deliveryAddress.subTitle',
		defaultMessage: 'Confirm your delivery address',
	},
	deliverTo: {
		id: 'deliveryAddress.deliverTo',
		defaultMessage: 'Deliver to',
	},
	edit: {
		id: 'deliveryAddress.edit',
		defaultMessage: 'Edit address',
	},
	cancelEdit: {
		id: 'deliveryAddress.cancelEdit',
		defaultMessage: 'Cancel edit',
	},
	selectLabel: {
		id: 'deliveryAddress.country',
		defaultMessage: 'Country',
	},
	fullName: {
		id: 'deliveryAddress.fullName',
		defaultMessage: 'Full name',
	},
	streetName: {
		id: 'deliveryAddress.streetName',
		defaultMessage: 'Street name',
	},
	buildingNumber: {
		id: 'deliveryAddress.buildingNumber',
		defaultMessage: 'Building number or building name',
	},
	apartmentNumber: {
		id: 'deliveryAddress.apartmentNumber',
		defaultMessage: 'Apartment number (optional)',
	},
	city: {
		id: 'deliveryAddress.city',
		defaultMessage: 'City',
	},
	postCode: {
		id: 'deliveryAddress.postCode',
		defaultMessage: 'Postal code',
	},
	saved: {
		id: 'deliveryAddress.saved',
		defaultMessage: 'Your address is saved securely',
	},
});

interface FormProps {
	fullName: string;
	streetName: string;
	buildingNumber: string;
	apartmentNumber: string;
	city: string;
	postCode: string;
	deliveryCountry: string;
}

interface FormErrorProps {
	fullName: MessageDescriptor | null;
	streetName: MessageDescriptor | null;
	buildingNumber: MessageDescriptor | null;
	apartmentNumber: MessageDescriptor | null;
	city: MessageDescriptor | null;
	postCode: MessageDescriptor | null;
	deliveryCountry: MessageDescriptor | null;
}

interface DeliveryAddressProps {
	type: CardType;
	isRha?: boolean;
}

const DeliveryAddress: FC<DeliveryAddressProps> = ({ type, isRha }) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const { formData, isCardUser, requiresVerification } = useSelector(
		(state: RootState) => state.CardsState
	);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [availableCountries, setAvailableCountries] = useState<string[]>([]);
	const [countryOptions, setCountryOptions] = useState([]);
	const [nextStep, setNextStep] = useState(OrderSteps.SHIPPING);
	const [showForm, setShowForm] = useState<boolean>(type === CardType.VIRTUAL);
	const [minPhoneVal, setMinPhoneVal] = useState('+');
	const [form, setForm] = useState<FormProps>({
		fullName: '',
		streetName: '',
		buildingNumber: '',
		apartmentNumber: '',
		city: '',
		postCode: '',
		deliveryCountry: '',
	});
	const countryCode = countries.getAlpha2Code(form.deliveryCountry, 'en');
	const [formError, setFormError] = useState<FormErrorProps>({
		fullName: null,
		streetName: null,
		buildingNumber: null,
		apartmentNumber: null,
		city: null,
		postCode: null,
		deliveryCountry: null,
	});

	const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = target;
		setFormError({
			...formError,
			[name]: null,
		});
		return setForm({
			...form,
			[name]: value,
		});
	};

	const handleSelectChange = (val: string) => {
		setFormError({
			...formError,
			deliveryCountry: null,
		});
		if (
			!availableCountries.includes(
				countries.getSimpleAlpha3Code(countries.getName(val, 'en'), 'en')
			)
		) {
			setFormError({
				...formError,
				deliveryCountry: messages.wrongCountry,
			});
		}
		return setForm({
			...form,
			deliveryCountry: countries.getName(val, 'en'),
		});
	};

	const validation = () => {
		let valid = true;
		let errorList = {};
		Object.keys(form).forEach((item: any) => {
			if (!form[item] && item !== 'apartmentNumber') {
				errorList = {
					...errorList,
					[item]: inputErrors.cannotBeEmpty,
				};
				valid = false;
			}
		});
		if (
			form.deliveryCountry &&
			!availableCountries.includes(countries.getSimpleAlpha3Code(form.deliveryCountry, 'en'))
		) {
			errorList = {
				...errorList,
				deliveryCountry: messages.wrongCountry,
			};
			valid = false;
		}
		setFormError({
			...formError,
			...errorList,
		});
		return valid;
	};

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (validation()) return onSubmit();
		return null;
	};

	const onSubmit = () => {
		const { fullName, streetName, buildingNumber, city, postCode, deliveryCountry } = formData!;
		let data = null;
		if (showForm) {
			data = form;
		}
		if (
			!showForm &&
			!fullName &&
			!streetName &&
			!buildingNumber &&
			!city &&
			!postCode &&
			!deliveryCountry
		) {
			data = {
				fullName: `${user?.name} ${user?.surname}`,
				streetName: user?.userAddress?.streetName,
				buildingNumber: user?.userAddress?.buildingNumber,
				apartmentNumber: user?.userAddress?.apartmentNumber,
				city: user?.userAddress?.city,
				postCode: user?.userAddress?.postCode,
				deliveryCountry:
					user?.userAddress?.country &&
					countries
						.getName(user?.userAddress?.country, 'en')
						.replace(', Province of China', ''),
			};
		}
		if (
			!showForm &&
			fullName &&
			streetName &&
			!buildingNumber &&
			city &&
			postCode &&
			deliveryCountry
		)
			data = formData;

		dispatch(setFormData(data));
		return dispatch(setOrderStep(nextStep));
	};

	useEffect(() => {
		if (type === CardType.VIRTUAL) {
			if (user?.phone?.includes(minPhoneVal) && user?.phoneVerified) {
				if (isRha) {
					setNextStep(OrderSteps.LINKED_WALLETS);
					return;
				}

				setNextStep(OrderSteps.CONFIRM);
				return;
			}

			void axiosInstance
				.get(getPOAStatusURL())
				.then(({ data }) => {
					if (data.status === POAVerificationStatus.PENDING) {
						setNextStep(OrderSteps.VERIFICATION);
						return;
					}
					if (data.status === POAVerificationStatus.CONFIRMED) {
						if (isRha) {
							dispatch(setOrderStep(OrderSteps.LINKED_WALLETS));
							return;
						}

						setNextStep(OrderSteps.CONFIRM);
						return;
					}

					setNextStep(OrderSteps.SHIPPING);
				})
				.catch((err) => {
					if (err.response.data.errorCode === ErrorMessageCodes.GEN_8) {
						// If there's no POA file uploaded GEN_8 --> No entity found
						setNextStep(OrderSteps.SHIPPING);
					}
					return null;
				})
				.then(() => setIsLoading(false));
		}
	}, [type, minPhoneVal]);

	useEffectOnce(() => {
		const countriesList = Object.keys(countries.getAlpha2Codes());
		const options = countriesList.reduce((acc: any, curr: any) => {
			const item = {
				value: curr,
				label: countries.getName(curr, 'en').replace(', Province of China', ''),
			};
			acc.push(item);
			return acc;
		}, []);
		setCountryOptions(options!);
	});

	useEffectOnce(() => {
		void axiosInstance
			.get(getDeliveryCountriesListURL(type))
			.then(({ data }: AxiosResponse) => setAvailableCountries(data.deliveryCountries))
			.catch(() => null)
			.then(() => setIsLoading(false));
	});

	useEffect(() => {
		if (user) {
			setForm({
				fullName: `${user?.name} ${user?.surname}`,
				streetName: user?.userAddress?.streetName || '',
				buildingNumber: user?.userAddress?.buildingNumber || '',
				apartmentNumber: user?.userAddress?.apartmentNumber || '',
				city: user?.userAddress?.city || '',
				postCode: user?.userAddress?.postCode || '',
				deliveryCountry: user?.userAddress?.country
					? countries.getName(user?.userAddress?.country, 'en')
					: '',
			});
		}
	}, [user]);

	useEffect(() => {
		setMinPhoneVal(
			type === CardType.VIRTUAL && countryCode
				? `+${getCountryCallingCode(countryCode as any)}`
				: '+'
		);
	}, [type, countryCode]);

	if (isLoading) return <Loader />;
	if (
		(availableCountries.length > 0 &&
			user?.country &&
			!availableCountries?.includes(user?.country) &&
			!showForm &&
			type === CardType.PLASTIC) ||
		(!requiresVerification && type === CardType.VIRTUAL)
	)
		return (
			<div className={styles.container}>
				<BackButton
					className={styles.back}
					onClick={() =>
						dispatch(setOrderStep(!isCardUser ? OrderSteps.TIN : OrderSteps.TERMS))
					}
				/>
				<CountryUnavailable editAction={setShowForm} countriesList={availableCountries} />
			</div>
		);
	return (
		<div className={styles.container}>
			<BackButton
				className={styles.back}
				onClick={() =>
					dispatch(setOrderStep(!isCardUser ? OrderSteps.TIN : OrderSteps.TERMS))
				}
			/>
			<InfoHead
				title={type === CardType.VIRTUAL ? messages.virtualTitle : messages.plasticTitle}
				subTitle={
					showForm || type === CardType.VIRTUAL
						? undefined
						: formatMessage(messages.subTitle)
				}
			/>
			{showForm ? (
				<form onSubmit={handleSubmit} className={styles.form}>
					<Input
						label={messages.fullName}
						labelClassName={styles.label}
						onChangeEvent={handleInputChange}
						value={form.fullName}
						name="fullName"
						inputGroupClassName={styles.input}
						className={styles.fullWidth}
						errorMessage={formError.fullName}
						readOnly
					/>
					<Input
						label={messages.streetName}
						labelClassName={styles.label}
						onChangeEvent={handleInputChange}
						value={form.streetName}
						name="streetName"
						inputGroupClassName={styles.input}
						className={styles.fullWidth}
						errorMessage={formError.streetName}
					/>
					<Input
						label={messages.buildingNumber}
						labelClassName={styles.label}
						onChangeEvent={handleInputChange}
						value={form.buildingNumber}
						name="buildingNumber"
						inputGroupClassName={styles.input}
						className={styles.fullWidth}
						errorMessage={formError.buildingNumber}
					/>
					<Input
						label={messages.apartmentNumber}
						labelClassName={styles.label}
						onChangeEvent={handleInputChange}
						value={form.apartmentNumber}
						name="apartmentNumber"
						inputGroupClassName={styles.input}
						className={styles.fullWidth}
					/>
					<Input
						label={messages.city}
						labelClassName={styles.label}
						onChangeEvent={handleInputChange}
						value={form.city}
						name="city"
						inputGroupClassName={styles.input}
						className={styles.fullWidth}
						errorMessage={formError.city}
					/>
					<Input
						label={messages.postCode}
						labelClassName={styles.label}
						onChangeEvent={handleInputChange}
						value={form.postCode}
						name="postCode"
						inputGroupClassName={styles.input}
						className={styles.fullWidth}
						errorMessage={formError.postCode}
					/>
					<Select
						label={messages.selectLabel}
						labelClassName={styles.label}
						className={styles.deliverySelect}
						options={countryOptions}
						onChange={handleSelectChange}
						value={countries.getAlpha2Code(form.deliveryCountry, 'en')}
						errorMessage={formError.deliveryCountry}
						styleType={SelectStyleType.BLOCK}
					/>
					<Button
						buttonStyle={ButtonStyle.PRIMARY}
						type={ButtonType.SUBMIT}
						text={baseMsg.confirm}
						className={styles.button}
					/>
					{type === CardType.PLASTIC && (
						<Button
							type={ButtonType.BUTTON}
							buttonStyle={ButtonStyle.LINK}
							text={messages.cancelEdit}
							className={styles.editBtn}
							onClick={() => setShowForm(!showForm)}
						/>
					)}
				</form>
			) : (
				<>
					<div className={styles.dataBox}>
						{type === CardType.PLASTIC && (
							<div className={styles.title}>{formatMessage(messages.deliverTo)}</div>
						)}
						<div className={styles.value}>
							{formData?.fullName || `${user?.name} ${user?.surname}`}
						</div>
						<div className={styles.value}>
							{formData?.streetName || user?.userAddress?.streetName}
						</div>
						<div className={styles.value}>
							{formData?.buildingNumber || user?.userAddress?.buildingNumber}
						</div>
						{user?.userAddress?.apartmentNumber && (
							<div className={styles.value}>
								{formData?.apartmentNumber || user?.userAddress?.apartmentNumber}
							</div>
						)}
						<div className={styles.value}>
							{formData?.city || user?.userAddress?.city}
						</div>
						{user?.country && user?.userAddress?.postCode && (
							<div className={styles.value}>
								{formData?.postCode ||
									`${
										user?.userAddress?.postCode?.search('-') > 0 &&
										user?.userAddress?.country
											? ''
											: `${`${countries.getSimpleAlpha2Code(
													countries.getName(
														user?.userAddress?.country,
														'en'
													),
													'en'
											  )}-`}`
									}${user?.userAddress?.postCode}`}
							</div>
						)}
						<div className={styles.value}>
							{formData?.deliveryCountry
								? formData?.deliveryCountry.replace(', Province of China', '')
								: user?.country &&
								  countries
										.getName(user?.country, 'en')
										.replace(', Province of China', '')}
						</div>
						<div className={styles.value}>{user?.phone}</div>
					</div>
					<Button
						onClick={() => onSubmit()}
						buttonStyle={ButtonStyle.PRIMARY}
						type={ButtonType.BUTTON}
						text={baseMsg.confirm}
						className={styles.button}
					/>
					<Button
						type={ButtonType.BUTTON}
						buttonStyle={ButtonStyle.LINK}
						text={messages.edit}
						className={styles.editBtn}
						onClick={() => setShowForm(!showForm)}
					/>
				</>
			)}
		</div>
	);
};

export default DeliveryAddress;
