import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import SvgIcon from '../../../../../components/SvgIcon/SvgIcon';
import { PaymentButtonsListStatus } from '../../../../../redux/MerchantsState/MerchantTypes';
import Loading from '../../../Shared/Loading/Loading';
import MerchantsSelectors from '../../../../../redux/MerchantsState/MerchantsSelectors';
import MerchantsMessages from '../../../../../redux/MerchantsState/MerchantsMessages';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';
import useMerchantRoutes, { MerchantRoutes } from '../../../../../hooks/useMerchantRoutes';
import PageTitle from '../../../Shared/PageTitle/PageTitle';
import Button, { ButtonType } from '../../../../../components/Button/Button';
import TestIds from '../../../../../test/TestIds';

import bannerSvg from './banner.svg';

import styles from './PaymentButtonsHome.module.scss';

const Content: FC = () => {
	const { formatMessage } = useIntl();
	const { getUrl } = useMerchantRoutes();

	return (
		<WhiteContainer className={styles.container} data-cy={TestIds.PaymentButtonsHome}>
			<PageTitle
				className={styles.title}
				title={formatMessage(MerchantsMessages.paymentButtons)}
				backLink={getUrl(MerchantRoutes.IntegrationTools)}
			/>
			<SvgIcon className={styles.banner} src={bannerSvg} />
			<div>
				<FormattedMessage
					{...MerchantsMessages.paymentButtonsInfoBlock}
					values={{
						ul: (token) => (
							<ul className={styles.list}>
								<div>{token}</div>
							</ul>
						),
						li: (token) => (
							<li className={styles.listItem}>
								<div>{token}</div>
							</li>
						),
					}}
				/>
			</div>
			<div className={styles.actions}>
				<Button
					className={styles.action}
					type={ButtonType.LINK}
					link={getUrl(MerchantRoutes.PaymentButtonsCreate)}
				>
					<FormattedMessage {...MerchantsMessages.createButton} />
				</Button>
			</div>
		</WhiteContainer>
	);
};

const PaymentButtonsHome: FC = () => {
	const { replace } = useHistory();
	const { status } = useSelector(MerchantsSelectors.paymentButtons.list.getState);
	const { getUrl } = useMerchantRoutes();

	useEffect(() => {
		if (status === PaymentButtonsListStatus.Ready)
			replace(getUrl(MerchantRoutes.PaymentButtonsList));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	switch (status) {
		case PaymentButtonsListStatus.Loading:
			return <Loading />;
		default:
			return <Content />;
	}
};

export default PaymentButtonsHome;
