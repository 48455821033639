import { ReactNode } from 'react';
import styles from './TransfersLayout.module.scss';

interface LayoutProps {
	children: ReactNode | ReactNode[];
}

const TransfersLayout = ({ children }: LayoutProps) => {
	return <div className={styles.container}>{children}</div>;
};

export default TransfersLayout;
