import { useIntl, defineMessages } from 'react-intl';
import plasticCardImgPath from '../../pages/Cards/OrderPage/Terms/images/plastic.svg';
import styles from './CardsInfoBanner.module.scss';

const messages = defineMessages({
	title: {
		id: 'cardsInfoBanner.title',
		defaultMessage: 'SpectroCoin card',
	},
	text: {
		id: 'cardsInfoBanner.text',
		defaultMessage:
			'Exchange cryptocurrencies and spend with your Visa debit card in millions of shops around the world.',
	},
});

const CardsInfoBanner = () => {
	const { formatMessage } = useIntl();

	return (
		<div className={styles.container}>
			<img src={plasticCardImgPath} className={styles.img} />
			<h3 className={styles.title}>{formatMessage(messages.title)}</h3>
			<p className={styles.text}>{formatMessage(messages.text)}</p>
		</div>
	);
};

export default CardsInfoBanner;
