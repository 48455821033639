import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { PaymentButton } from '../PaymentButtonsData/PaymentButtonsDataTypes';

export type PaymentButtonEditForm = {
	name: string;
	description: string | null;
	accountId: string | null;
	currencies: CurrencyEnum[];
	isEnabled: boolean;
};

export enum PaymentButtonEditActionType {
	ACTION_FULFILLED = 'MERCHANTS/PAYMENT_BUTTONS/EDIT/ACTION/FULFILLED',
}

export type PaymentButtonEditActionFulfilledAction = {
	type: PaymentButtonEditActionType.ACTION_FULFILLED;
	payload: PaymentButton;
};
