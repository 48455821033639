import { FormattedMessage, useIntl } from 'react-intl';
import convertMsg from '../../convertMsg';
import styles from './ConvertInfo.module.scss';

const ConvertInfo = () => {
	const { locale } = useIntl();
	return (
		<>
			<p className={styles.infoBox}>
				<FormattedMessage {...convertMsg.additionalInfo} />
			</p>
			<p className={styles.readMore}>
				<FormattedMessage
					{...convertMsg.readMore}
					values={{
						a: (text) => (
							<a href={`/${locale}/terms/virtualCurrencyAgreement.html`}>{text}</a>
						),
					}}
				/>
			</p>
		</>
	);
};

export default ConvertInfo;
