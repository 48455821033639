import { RefObject, useEffect, useRef, useState, memo, ReactNode } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import { CurrencyIcon, CurrencyEnum, currencyUtils } from '@spectrocoin/sc-currencies';
import TestIds from '../../../../test/TestIds';
import { RootState } from '../../../../redux/Store';
import { DeviceNameType } from '../../../../redux/AppState/AppTypes';
import { CalcType } from '../../../../redux/ConvertState/ConvertTypes';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import usePrevious from '../../../../hooks/usePrevious';
import Loader from '../../../../components/Loader/Loader';
import styles from './ConvertInput.module.scss';
import ConvertSelect from '../ConvertSelect/ConvertSelect';
import ConvertSelectModal from '../ConvertSelectModal/ConvertSelectModal';
import SelectOption from '../../../../interfaces/SelectOption';

interface ConvertInputProps {
	inputId: string;
	currency: CurrencyEnum;
	label: MessageDescriptor;
	labelTooltip?: ReactNode;
	error: MessageDescriptor | null;
	errorProps?: any;
	placeholder: MessageDescriptor;
	amount: string;
	options: SelectOption[];
	onChangeSelect: (val: string) => void;
	onChangeInput: (val: string, type: CalcType, useAll: boolean) => void;
	isLoading?: boolean;
	isSearchable?: boolean;
}

const ConvertInput = ({
	inputId,
	currency,
	label,
	labelTooltip,
	error,
	errorProps,
	placeholder,
	amount,
	onChangeSelect,
	options,
	onChangeInput,
	isSearchable = false,
	isLoading = false,
}: ConvertInputProps) => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const [showAmount, setShowAmount] = useState('');
	const containerRef = useRef() as RefObject<HTMLDivElement>;
	const [fixedDecimal, setFixedDecimal] = useState(!!amount);
	const { isMobile, isTablet, deviceOS } = useSelector((state: RootState) => state.AppState);
	const prevCurrency = usePrevious(currency);
	const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

	useOnClickOutside(containerRef, () => setIsSelectOpen(false));

	const selectChange = (val: string) => onChangeSelect(val);

	useEffect(() => {
		if (prevCurrency !== currency) setIsSelectOpen(false);
	}, [prevCurrency, currency]);

	useEffect(() => onChangeSelect(currency), [currency, onChangeSelect]);

	useEffect(() => {
		if ((isMobile || isTablet) && isSelectOpen) {
			dispatch(
				toggleModal(
					<ConvertSelectModal
						options={options}
						selected={currency}
						onChangeSelect={(val: any) => onChangeSelect(val)}
						setIsSelectOpen={(isOpen: boolean) => setIsSelectOpen(isOpen)}
					/>
				)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSelectOpen, isMobile, isTablet]);

	useEffect(() => setShowAmount(amount), [amount, currency]);

	return (
		<div data-cy={TestIds.ConvertInput} className={styles.container}>
			<label className={styles.label} htmlFor={inputId}>
				{formatMessage(label)}
				{labelTooltip}
			</label>
			{isLoading && <Loader className={styles.inputLoader} />}
			<NumericFormat
				data-cy={TestIds.ConvertInputNumberFormat}
				className={classNames(styles.numberInput, {
					[styles.errorInput]: error,
					[styles.opacity]: isLoading,
				})}
				decimalSeparator={deviceOS === DeviceNameType.IPHONE ? ',' : '.'}
				decimalScale={currency && currencyUtils.getConfigOrDefault(currency).precision}
				allowNegative={false}
				fixedDecimalScale={fixedDecimal}
				onBlur={() => setFixedDecimal(true)}
				onFocus={() => setFixedDecimal(false)}
				onKeyUp={(e: any) => {
					setShowAmount(e.target.value);
					onChangeInput(
						e.target.value,
						inputId === 'pay' ? CalcType.RECEIVE : CalcType.PAY,
						false
					);
				}}
				inputMode="decimal"
				thousandSeparator={deviceOS === DeviceNameType.IPHONE ? '.' : ','}
				id={inputId}
				disabled={!currency || isLoading}
				value={showAmount}
				autoComplete="off"
				placeholder={formatMessage(placeholder)}
			/>
			{options && currency && (
				<div
					ref={!isMobile && !isTablet ? containerRef : null}
					className={classNames(styles.currencySelect, {
						[styles.active]: isSelectOpen,
						[styles.noBg]: isLoading,
					})}
					onClick={() => setIsSelectOpen(!isSelectOpen)}
				>
					<CurrencyIcon currencyType={currency} className={styles.icon} />
					<div data-cy={TestIds.ConvertInputCurrency} className={styles.code}>
						{currency}
					</div>
				</div>
			)}
			{isSelectOpen && !isMobile && !isTablet && (
				<ConvertSelect
					id={inputId}
					options={options}
					currency={currency}
					onChange={selectChange}
					isOpen={isSelectOpen}
					isSearchable={isSearchable}
				/>
			)}
			{error && (
				<div className={styles.error}>
					<FormattedMessage {...error} values={errorProps} />
				</div>
			)}
		</div>
	);
};

export default memo(ConvertInput);
