import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import classNames from 'classnames';

import styles from './HowToDeposit.module.scss';
import { ProviderType } from '../../../../redux/DepositState/DepositTypes';
import useDurationString from '../../../../hooks/useDurationString';
import TestIds from '../../../../test/TestIds';

const messages = defineMessages({
	howToDepositWith: {
		id: 'deposit.howToDepositWith',
		defaultMessage: 'How to deposit with {paymentMethodName}',
	},
	fundsWillBeInYourAccountIn: {
		id: 'deposit.fundsWillBeInYourAccountIn',
		defaultMessage: 'The funds will be in your account in {duration}',
	},
});

const paymentMethodMessages = defineMessages({
	howToDepositLOCAL: {
		id: 'deposit.howToDepositLOCAL.bank',
		defaultMessage: `
			<li>Enter the deposit amount</li>
			<li>You will be given the bank account details to transfer the money to</li>
			<li>You must use only personal bank account for deposits.</li>
			<li>If you do not enter the payment details correctly, your bank transfer will be held up.</li>
			<li>Please make sure you enter payment details/memos/payment instructions correctly. If you fail to enter payment details accurately we reserve the right to reverse the payment to you.</li>
			<li>WIRE transfers are ONLY accepted in EUR currency. You will be charged a fee if you enter a different currency.</li>
			<li>If you do not transfer the payment correctly, we will not be responsible for any charges or delays! Thank you for understanding.</li>
			<li>Keep your bank wire receipt as we may request it.</li>
			{DurationString}
			<instructions> With any questions, be sure to contact our customer support team. </instructions>
		`,
	},
	howToDepositPERFECT_MONEY: {
		id: 'deposit.howToDepositPERFECT_MONEY',
		defaultMessage: `<li>Fill the form</li>
		<li>You will be redirected to {paymentMethodName} page where you can finish the operation</li>
		{DurationString}
		<instructions> With any questions, be sure to contact our customer support team. </instructions>`,
	},
	howToDepositMAXIMA: {
		id: 'deposit.howToDepositMAXIMA',
		defaultMessage: 'How to deposit: MAXIMA',
	},
	howToDepositPAYEER: {
		id: 'deposit.howToDepositPAYEER',
		defaultMessage: `<li>Fill the form</li>
		<li>You will be redirected to {paymentMethodName} page where you can finish the operation</li>
		{DurationString}
		<instructions> With any questions, be sure to contact our customer support team. </instructions>`,
	},
	howToDepositSEPA: {
		id: 'deposit.howToDepositSEPA.bank',
		defaultMessage: `
			<li>Enter the deposit amount</li>
			<li>You will be given the bank account details to transfer the money to</li>
			<li>You must use only personal bank account for deposits.</li>
			<li>If you do not enter the payment details correctly, your bank transfer will be held up.</li>
			<li>Please make sure you enter payment details/memos/payment instructions correctly. If you fail to enter payment details accurately we reserve the right to reverse the payment to you.</li>
			<li>SEPA transfers are ONLY accepted in EUR currency. You will be charged a fee if you enter a different currency.</li>
			<li>If you do not transfer the payment correctly, we will not be responsible for any charges or delays! Thank you for understanding.</li>
			<li>Keep your bank wire receipt as we may request it.</li>
			{DurationString}
			<instructions> With any questions, be sure to contact our customer support team. </instructions>
		`,
	},
	howToDepositWIRE: {
		id: 'deposit.howToDepositWIRE.bank',
		defaultMessage: `
			<li>Enter the deposit amount</li>
			<li>You will be given the bank account details to transfer the money to</li>
			<li>You must use only personal bank account for deposits.</li>
			<li>If you do not enter the payment details correctly, your bank transfer will be held up.</li>
			<li>Please make sure you enter payment details/memos/payment instructions correctly. If you fail to enter payment details accurately we reserve the right to reverse the payment to you.</li>
			<li>WIRE transfers are ONLY accepted in EUR currency. You will be charged a fee if you enter a different currency.</li>
			<li>If you do not transfer the payment correctly, we will not be responsible for any charges or delays! Thank you for understanding.</li>
			<li>Keep your bank wire receipt as we may request it.</li>
			{DurationString}
			<instructions> With any questions, be sure to contact our customer support team. </instructions>
		`,
	},
	howToDepositSKRILL: {
		id: 'deposit.howToDepositSKRILL',
		defaultMessage: `<li>Fill the form</li>
		<li>You will be redirected to {paymentMethodName} page where you can finish the operation</li>
		{DurationString}
		<instructions> With any questions, be sure to contact our customer support team. </instructions>`,
	},
	howToDepositNETELLER: {
		id: 'deposit.howToDepositNETELLER',
		defaultMessage: `<li>Fill the form</li>
		<li>You will be redirected to {paymentMethodName} page where you can finish the operation</li>
		{DurationString}
		<instructions> With any questions, be sure to contact our customer support team. </instructions>`,
	},
	howToDepositDECTA: {
		id: 'deposit.howToDepositCARD',
		defaultMessage: `
			<li>Select the currency and the deposit amount.</li>
			<li>When filling in your card information, make sure to double-check your card number.</li>
			<li>Please note that you can only add and deposit from your own card.</li>
			<li>Be sure to write your first name and surname; avoid providing only initials.</li>
			<li>If you wish to save this card for later deposits, you can check the box "Save card for future deposits" at the bottom of this form.</li>
			{DurationString}
			<instructions> With any questions, be sure to contact our customer support team. </instructions>
		`,
	},
	howToDepositSIMPLEX: {
		id: 'deposit.howToDepositSIMPLEX',
		defaultMessage: 'How to deposit: SIMPLEX',
	},
	howToDepositPAYBOX: {
		id: 'deposit.howToDepositPAYBOX',
		defaultMessage: 'How to deposit: PAYBOX',
	},
	howToDepositADV_CASH: {
		id: 'deposit.howToDepositADV_CASH',
		defaultMessage: `<li>Fill the form</li>
		<li>You will be redirected to {paymentMethodName} page where you can finish the operation</li>
		{DurationString}
		<instructions> With any questions, be sure to contact our customer support team. </instructions>`,
	},
	howToDepositIBAN: {
		id: 'deposit.howToDepositSEPA.iban',
		defaultMessage: `
			<li>Enter the deposit amount</li>
			<li>You will be given the bank account details to transfer the money to</li>
			{DurationString}
			<instructions> With any questions, be sure to contact our customer support team. </instructions>
		`,
	},
	howToDepositFOXBOX: {
		id: 'deposit.howToDepositFOXBOX',
		defaultMessage: `
			<li>Look for the nearest FOXBOX machine.</li>
			<li>Choose "Virtual wallet" in the FOXBOX machine.</li>
			<li>Press "Bitcoin", write your email, and choose the amount you want to pay for bitcoins.</li>
			<li>After you pay the exact amount, bitcoins will be included into your SpectroCoin account (if you are not registered in the system yet, please note that you have to use the same email you used for the deposit. After the registration, the money will be included into your account balance).</li>
			{DurationString}
			<instructions> With any questions, be sure to contact our customer support team. </instructions>
		`,
	},
	howToDepositQIWI: {
		id: 'deposit.howToDepositQIWI',
		defaultMessage: `
			<li>Look for the nearest QIWI machine</li>
			<li>Find "Bitcoin" deposit option in the QIWI machine, enter your email and choose the amount you want to pay for bitcoins.</li>
			<li>After you pay the full amount, bitcoins will be credited to your SpectroCoin account (If you are not registered in the system yet, please note that you have to use the same email (the one you used for the deposit) for the signup process. After the registration, the money will be included in your account balance).</li>
			{DurationString}
			<instructions> With any questions, be sure to contact our customer support team. </instructions>
		`,
	},
	howToDepositLINKED_CARD: {
		id: 'deposit.howToDepositCARD',
		defaultMessage: `
			<li>Select the currency and the deposit amount.</li>
			<li>When filling in your card information, make sure to double-check your card number.</li>
			<li>Please note that you can only add and deposit from your own card.</li>
			<li>Be sure to write your first name and surname; avoid providing only initials.</li>
			<li>If you wish to save this card for later deposits, you can check the box "Save card for future deposits" at the bottom of this form.</li>
			{DurationString}
			<instructions> With any questions, be sure to contact our customer support team. </instructions>
		`,
	},
});

interface MessageProps {
	id: string;
	defaultMessage?: string;
	values: Record<string, any>;
}

interface HowToDepositProps {
	className?: string;
	duration?: string | null;
	isIban?: boolean;
	paymentMethodName: string;
	howToHeading?: MessageDescriptor | null;
	providerType?: ProviderType;
}

const HowToDeposit = ({
	className,
	duration,
	isIban = false,
	paymentMethodName,
	providerType,
	howToHeading = null,
}: HowToDepositProps) => {
	const { formatMessage } = useIntl();
	const durationString = useDurationString(duration);

	const getMessageProps = (): MessageProps | null => {
		const message = isIban
			? paymentMethodMessages.howToDepositIBAN
			: paymentMethodMessages[`howToDeposit${providerType}`];
		if (!message) return null;

		return {
			...message,
			values: {
				li: (text: React.ReactNode) => (
					<li key={text?.toString()} className={styles.listItem}>
						{text}
					</li>
				),
				paymentMethodName,
				br: <br />,
				instructions: (text: React.ReactNode) => (
					<p className={styles.instructionsText}>{text}</p>
				),
				DurationString: () =>
					durationString && (
						<li className={styles.listItem}>
							<FormattedMessage
								{...messages.fundsWillBeInYourAccountIn}
								values={{ duration: durationString }}
							/>
						</li>
					),
			},
		};
	};

	const messageProps = getMessageProps();
	if (!messageProps) return null;

	return (
		<div data-cy={TestIds.howToDeposit} className={classNames(styles.container, className)}>
			<h2 className={styles.heading}>
				{formatMessage(howToHeading || messages.howToDepositWith, {
					paymentMethodName,
				})}
			</h2>
			<ol className={styles.list}>
				<FormattedMessage {...messageProps} />
			</ol>
		</div>
	);
};

export default HowToDeposit;
