import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { PaymentButton } from '../PaymentButtonsData/PaymentButtonsDataTypes';

export type PaymentButtonCreateForm = {
	name: string;
	description: string | null;
	accountId: string | null;
	currencies: CurrencyEnum[];
	isEnabled: boolean;
};

export enum PaymentButtonCreateActionType {
	ACTION_FULFILLED = 'MERCHANTS/PAYMENT_BUTTONS/CREATE/ACTION/FULFILLED',
}

export type PaymentButtonCreateActionFulfilledAction = {
	type: PaymentButtonCreateActionType.ACTION_FULFILLED;
	payload: PaymentButton;
};
