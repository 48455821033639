/* eslint-disable no-param-reassign */
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import {
	PreordersCreateResetAction,
	PreordersCreateActionFulfilledAction,
	PreordersCreateActionPendingAction,
	PreordersCreateActionRejectedAction,
	PreordersCreateActionType,
	PreordersCreateState,
	PreordersCreateSetFormAction,
} from './PreordersCreateTypes';

const initialState: PreordersCreateState = {
	form: {
		currency: null,
		allowMultiplePayments: false,
		callbackUrl: null,
		description: null,
		emailNotifications: false,
		failureUrl: null,
		orderId: null,
		price: '',
		successUrl: null,
	},
	action: { status: RemoteStatus.None },
};

export default (
	state = initialState,
	action:
		| PreordersCreateResetAction
		| PreordersCreateSetFormAction
		| PreordersCreateActionFulfilledAction
		| PreordersCreateActionPendingAction
		| PreordersCreateActionRejectedAction
): PreordersCreateState => {
	switch (action.type) {
		case PreordersCreateActionType.RESET:
			return {
				...initialState,
			};
		case PreordersCreateActionType.SET_FORM:
			return {
				...state,
				form: {
					...state.form,
					...action.payload,
				},
				action: { status: RemoteStatus.None },
			};
		case PreordersCreateActionType.ACTION_PENDING:
			return {
				...state,
				action: { status: RemoteStatus.InProgress },
			};
		case PreordersCreateActionType.ACTION_REJECTED:
			return {
				...state,
				action: { status: RemoteStatus.Error, error: action.payload },
			};
		case PreordersCreateActionType.ACTION_FULFILLED:
			return {
				...state,
				action: { status: RemoteStatus.Done, data: action.payload.id },
			};
		default:
			return state;
	}
};
