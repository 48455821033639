import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { RemoteStatus } from '../../../../../interfaces/RemoteData';
import MerchantsSelectors from '../../../../../redux/MerchantsState/MerchantsSelectors';
import MerchantsActions from '../../../../../redux/MerchantsState/MerchantsActions';
import useQueryParams from '../../../../../hooks/useQueryParams';
import { HistoryTableData } from '../../../../../hooks/useHistoryTableData';
import { Order, OrderStatus } from '../../../../../redux/MerchantsState/MerchantTypes';
import MerchantOrderHistoryContext from '../Contexts/MerchantHistoryContext';
import useDidMount from '../../../../../hooks/useDidMount';

const useMerchantOrderHistoryData = (): HistoryTableData<Order> => {
	const { projectId } = useContext(MerchantOrderHistoryContext);

	const didMount = useDidMount();
	const dispatch = useDispatch();
	const [[page = 1, startDate, endDate, status]] = useQueryParams({
		page: undefined,
		startDate: undefined,
		endDate: undefined,
		status: undefined,
	});

	const {
		data: historyOrderIds,
		status: historyStatus,
		totalCount: historyOrdersTotalCount,
		pageSize: historyOrdersPageSize,
	} = useSelector(MerchantsSelectors.orders.history.getState);

	const historyOrders = useSelector(
		MerchantsSelectors.orders.data.getOrdersDataByIds(historyOrderIds || [])
	);

	useEffect(() => {
		if (didMount)
			dispatch(
				MerchantsActions.orders.history.fetchOrders(projectId, {
					fromDate: startDate,
					toDate: endDate,
					page: +page - 1,
					status: status as OrderStatus,
				})
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endDate, page, projectId, startDate, didMount, status]);

	return {
		data: historyOrders,
		isLoading: historyStatus === RemoteStatus.InProgress,
		totalCount: historyOrdersTotalCount,
		pageSize: historyOrdersPageSize,
	};
};

export default useMerchantOrderHistoryData;
