/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { endOfMonth, isFuture, parse } from 'date-fns';

import { useDispatch, useSelector } from 'react-redux';
import { CurrencyIcon, CurrencyEnum } from '@spectrocoin/sc-currencies';
import SelectOption from '../../../../interfaces/SelectOption';
import styles from './LinkNewCardDetails.module.scss';
import { RootState } from '../../../../redux/Store';
import { getCardTypeFromBrand } from '../helpers';
import {
	addLinkedCardsURL,
	depositCalculateReceiveAmountURL,
	depositLinkedCardURL,
	getLinkedCardPaymentMethodURL,
	getPaymentMethodsDetailsURL,
} from '../../../../redux/endpoints';
import {
	fetchLinkedCards,
	fetchPaymentMethodsByGroup,
} from '../../../../redux/DepositState/DepositActions';
import PaymentMethod, {
	CardBrand,
	CardType,
	PaymentMethodGroup,
	ProviderType,
} from '../../../../redux/DepositState/DepositTypes';
import Select from '../../../../components/Select/Select';
import Loader from '../../../../components/Loader/Loader';
import CurrencyInput from '../../../../components/CurrencyInput/CurrencyInput';
import Input from '../../../../components/Input/Input';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import { isAlpha, isValidCardNumber } from './helpers';
import useDebounce from '../../../../hooks/useDebounce';
import CardNumberInput from '../CardNumberInput/CardNumberInput';
import CardExpirationDateInput from '../CardExpirationDateInput/CardExpirationDateInput';
import CardCvvInput from '../CardCvvInput/CardCvvInput';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import { formatPrecision, toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import HowToDeposit from '../../Shared/HowToDeposit/HowToDeposit';
import PaymentMethodLimits from '../../Shared/PaymentMethodLimits/PaymentMethodLimits';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { isNumeric } from '../../../../helpers/globalFunctionHelpers/globalFunctionHelpers';
import depositMessages from '../../../../redux/DepositState/DepositMessages';
import inputStyles from '../../../../components/Input/Input.module.scss';
import errorMessages from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import { toPlainAmount } from '../../../../helpers/currencyAmountHelper/currencyAmountHelper';
import baseMsg from '../../../../messages/base.messages';
import inputErrors from '../../../../messages/inputErrors.messages';
import Seo from '../../../../components/Seo/Seo';
import TestIds from '../../../../test/TestIds';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import useFormatAmount from '../../../../hooks/useFormatAmount';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	saveLabel: {
		id: 'linkedcards.saveLabel',
		defaultMessage: 'Save card details for future deposits',
	},
	depositAmountPlaceholder: {
		id: 'linkedcards.depositAmountPlaceholder',
		defaultMessage: '0.00 {currencyCode}',
	},
	depositAmount: {
		id: 'linkedcards.depositAmount',
		defaultMessage: 'Deposit amount',
	},
	title: {
		id: 'linkedcards.deposit_new_card',
		defaultMessage: 'Deposit with new card',
	},
	howToHeading: {
		id: 'linkedcards.how_to_deposit_with_card_title',
		defaultMessage: 'How to deposit with your card',
	},
	depositCurrency: {
		id: 'linkedcards.depositCurrency',
		defaultMessage: 'Deposit currency',
	},
	cardNumber: {
		id: 'creditcardlink.cardNumber',
		defaultMessage: 'Card number',
	},
	fullName: {
		id: 'creditcardlink.full_name',
		defaultMessage: 'Full name (as displayed on card)',
	},
	fullNamePlaceholder: {
		id: 'walletHistory.walletHistory_name_lastname',
		defaultMessage: 'Name Lastname',
	},
	validUntil: {
		id: 'linkedcards.valid',
		defaultMessage: 'Valid until',
	},
	cvv: {
		id: 'debitCardCredentials.cvv',
		defaultMessage: 'CVV',
	},
	next: {
		id: 'base.next',
		defaultMessage: 'Next',
	},
	success: {
		id: 'linkedcards.link_card_success',
		defaultMessage: 'Card was linked successfully. To use it, proceed to card verification. ',
	},
	verifyNow: {
		id: 'linkedcards.verify_now',
		defaultMessage: 'Verify Now',
	},
	errorInvalidCardNumber: {
		id: 'linkedcards.card_number_invalid',
		defaultMessage: 'Card number is not valid',
	},
	errorInvalidHolderName: {
		id: 'linkedcards.holder_name_invalid',
		defaultMessage:
			'Name must only contain latin letters and spaces, up to 50 characters are allowed',
	},
	errorInvalidField: {
		id: 'base.invalidField',
		defaultMessage: 'Field is not valid',
	},
	errorUnsupportedCardType: {
		id: 'linkedcards.unsupported_card',
		defaultMessage:
			'{unsupportedType} cards are not currently supported. We support {supportedTypes}',
	},
	errorDefault: {
		id: 'scvalidationexception.validate.unexpected_error.key',
		defaultMessage: 'Unexpected error occurred.',
	},
	card: {
		id: 'base.card',
		defaultMessage: 'Card',
	},
	currencySelectPlaceholder: {
		// NEW as short as possible, longer text than this might not fit and will be clipped. This is for placeholder inside a select input, which only activates when you select card. So mostly
		// user will ba able to tell what it is even if you brief it to "Enter card no. first"
		id: 'linkedcards.enter_card_number_to_select_currency',
		defaultMessage: 'Enter card number to select currency',
	},
});

/* eslint-disable */
type errorResponseKey =
	| 'user_card_exist'
	| 'max_card_limit_exceeded'
	| 'max_card_limit_weekly_exceeded'
	| 'annual_card_limit_exceeded'
	| 'card_country_is_blocked'
	| 'user_country_for_card_usage_is_blocked'
	| 'unsupported_linked_card_type'
	| 'expired_card_provided'
	| 'amount_less_than_min'
	| 'amount_more_than_max'
	| 'deposit_limit_exceeded'
	| 'card_deposit_failed';

const errorResponseMessages = defineMessages({
	errorInvalidDepositAmount: {
		id: 'linkedcards.errorInvalidDepositAmount',
		defaultMessage: 'Deposit amount cannot be empty',
	},
	user_card_exist: {
		id: 'linkedcards.validation_user_card_exist',
		defaultMessage: 'This card is already linked with your account',
	},
	max_card_limit_exceeded: {
		id: 'linkedcards.validation_max_card_limit_exceeded',
		defaultMessage: 'You have reached the card count limit and can not add any more cards',
	},
	max_card_limit_weekly_exceeded: {
		id: 'linkedcards.validation_max_card_limit_weekly_exceeded',
		defaultMessage: 'Weekly card link limit exceeded',
	},
	annual_card_limit_exceeded: {
		id: 'linkedcards.validation_annual_card_limit_exceeded',
		defaultMessage: 'Yearly card link limit exceeded',
	},
	card_country_is_blocked: {
		id: 'linkedcards.validation_card_country_is_blocked',
		defaultMessage: 'Cards from {country} can not be linked',
	},
	user_country_for_card_usage_is_blocked: {
		id: 'linkedcards.validation_user_country_for_card_usage_is_blocked',
		defaultMessage: 'Card functionality is restricted in your country ({country})',
	},
	unsupported_linked_card_type: {
		id: 'linkedcards.validation_unsupported_linked_card_type',
		defaultMessage: 'Unsupported linked card type',
	},
	expired_card_provided: {
		id: 'scvalidationexception.13013_deposit.card_expired.key',
		defaultMessage: 'Card has expired',
	},
	'validation.constraints.only.latin.letters.allowed': {
		id: 'linkedcards.validation_only_latin_letters_allowed',
		defaultMessage: 'Name must only contain latin letters and spaces',
	},
});
/* eslint-enable */

const isCardValid = (validUntil: string) => {
	if (!validUntil.includes('/') || validUntil.length !== 5) {
		return false;
	}
	const year = `20${validUntil.split('/')[1]}`;
	const month = validUntil.split('/')[0];
	// Cards expire at the end of month specified at "valid until" date
	const date = endOfMonth(parse(`${year}-${month}-01`, 'yyyy-MM-dd', new Date()));
	return isFuture(date);
};

interface DepositCalculateResponse {
	payAmount: string;
	payCurrencyCode: string;
	receiveAmount: number;
	receiveCurrencyCode: string;
}

interface DepositSuccessResponse {
	depositAmount: number;
	depositCurrencyCode: string;
	id: string;
	receiveAmount: number;
	receiveCurrencyCode: string;
	redirectUri: string;
}

interface FormErrorState {
	cardNumber?: MessageDescriptor | null;
	currencyCode?: MessageDescriptor | null;
	cvv?: MessageDescriptor | null;
	holderName?: MessageDescriptor | null;
	validUntil?: MessageDescriptor | null;
	depositAmount?: MessageDescriptor | null;
	receiveAmount?: MessageDescriptor | null;
	saveCard?: MessageDescriptor | null;
}

interface FormState {
	cardNumber: string;
	currencyCode: string;
	cvv: string;
	holderName: string;
	validUntil: string;
	depositAmount: string;
	receiveAmount: string;
	saveCard: boolean;
}

const LinkNewCardDetails = () => {
	const { formatMessage, locale } = useIntl();
	const { wallets: accounts } = useSelector((state: RootState) => state.AccountsState);
	const { user: profile } = useSelector((state: RootState) => state.ProfileState);
	const {
		paymentMethods: { card: cardPaymentMethods },
	} = useSelector((state: RootState) => state.DepositState);

	const currencyOptionsInitialState = [
		{
			value: 'placeholder',
			label: formatMessage(messages.currencySelectPlaceholder),
		},
	];

	const [cardType, setCardType] = useState<CardType | null>(null);
	const [currencyCodeOptions, setCurrencyCodeOptions] = useState<SelectOption[]>(
		currencyOptionsInitialState
	);
	const [paymentMethodDetails, setPaymentMethodDetails] = useState<PaymentMethod>();
	const [formSubmitError, setFormSubmitError] = useState<MessageDescriptor | string | null>(null);
	const [isCardTypeSupported, setIsCardTypeSupported] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [isRequestPending, setIsRequestPending] = useState(false);
	const [isUpdateRequested, setIsUpdateRequested] = useState(false);
	const [supportedCardTypes, setSupportedCardTypes] = useState<CardType[] | null>(null);
	const [
		cardNumberForWhichCurrencyOptionsAreLoaded,
		setCardNumberForWhichCurrencyOptionsAreLoaded,
	] = useState<string | null>(null);
	const dispatch = useDispatch();
	const amountFormatter = useFormatAmount();

	const [formState, setFormState] = useState<FormState>({
		cardNumber: '',
		currencyCode: '',
		cvv: '',
		holderName: '',
		validUntil: '',
		depositAmount: '',
		receiveAmount: '0.00',
		saveCard: false,
	});

	const [formErrorState, setFormErrorState] = useState<FormErrorState>({
		cardNumber: null,
		currencyCode: null,
		cvv: null,
		holderName: null,
		validUntil: null,
		depositAmount: null,
		receiveAmount: null,
		saveCard: null,
	});

	const {
		cardNumber,
		currencyCode,
		cvv,
		holderName,
		validUntil,
		depositAmount,
		receiveAmount,
		saveCard,
	} = formState;

	const debouncedDepositAmount = useDebounce(depositAmount, 1000);
	const debouncedCardNumber = useDebounce(cardNumber, 1000);

	const getDepositAmount = useCallback(
		(paymentMethodId: string) => {
			if (debouncedDepositAmount && isNumeric(debouncedDepositAmount)) {
				void axiosInstance
					.post(depositCalculateReceiveAmountURL(), {
						payAmount: toPlainAmount(debouncedDepositAmount, true),
						payCurrencyCode: currencyCode,
						paymentMethodId,
						receiveCurrencyCode: currencyCode,
					})
					.then(({ data }: AxiosResponse<DepositCalculateResponse>) => {
						setFormState((prevState) => ({
							...prevState,
							receiveAmount: toDecimal(data.receiveAmount).toFixed(),
						}));
					})
					.catch((error) => {
						const { data } = error.response || {};
						if (data) return setFormSubmitError(data.message);
						return setFormSubmitError(formatMessage(messages.errorDefault));
					})
					.then(() => {
						setIsRequestPending(false);
					});
			}
		},
		[debouncedDepositAmount, currencyCode, formatMessage]
	);

	const getPaymentId = useCallback(() => {
		const formattedCardNumber = cardNumber.split(' ').join('');

		if (formattedCardNumber.length === 16 && isValidCardNumber(formattedCardNumber)) {
			void axiosInstance
				.get(getLinkedCardPaymentMethodURL(formattedCardNumber))
				.then(({ data }: any) => {
					if (data?.id) {
						getDepositAmount(data.id);
					}
				});
		}
	}, [cardNumber, getDepositAmount]);

	useEffectOnce(() => {
		void dispatch(fetchPaymentMethodsByGroup(PaymentMethodGroup.CARD));
		setIsUpdateRequested(true);
	});

	useEffect(() => {
		if (isUpdateRequested && cardPaymentMethods.isLoaded && isLoading) {
			const availableCardBrands = cardPaymentMethods.data.map(
				(method: PaymentMethod) => method.key as CardBrand
			);
			const availableCardTypes = Array.from(
				new Set(
					availableCardBrands.map(
						(brand: CardBrand) => getCardTypeFromBrand(brand) as CardType
					)
				)
			);
			setSupportedCardTypes([...availableCardTypes]);
			setIsLoading(false);
		}
	}, [cardPaymentMethods, isUpdateRequested, isLoading]);

	const handleLinkedCardDeposit = (receiveAccountId: string, userLinkedCardId: string) => {
		axiosInstance
			.post(depositLinkedCardURL(), {
				cvv,
				receiveAccountId,
				receiveAmount,
				userLinkedCardId,
			})
			.then(({ data }: AxiosResponse<DepositSuccessResponse>) => {
				return window.location.assign(data.redirectUri);
			})
			.catch((error) => {
				const { data } = error.response || {};
				if (data?.errorCode === 'LC_8') void dispatch(fetchLinkedCards());
				if (data) return setFormSubmitError(data.message);
				return setFormSubmitError(formatMessage(messages.errorDefault));
			});
	};

	const validateForm = () => {
		let isValid = true;
		let errorList = {};
		const holderNameWithoutSpaces = holderName.split(' ').join('');

		Object.keys(formState).forEach((item: any) => {
			if (!formState[item] && item !== 'saveCard') {
				errorList = {
					...errorList,
					[item]: inputErrors.cannotBeEmpty,
				};
				isValid = false;
			}
		});

		if (
			paymentMethodDetails?.minAmount &&
			toDecimal(receiveAmount).lessThan(toDecimal(paymentMethodDetails?.minAmount))
		) {
			errorList = {
				...errorList,
				receiveAmount: formatMessage(depositMessages.errorDepositAmountBelowMin, {
					minAmount: toDecimal(paymentMethodDetails?.minAmount).toFixed(),
				}),
			};
			isValid = false;
		} else if (
			paymentMethodDetails?.maxAmount &&
			toDecimal(receiveAmount).greaterThan(toDecimal(paymentMethodDetails?.maxAmount))
		) {
			errorList = {
				...errorList,
				receiveAmount: formatMessage(depositMessages.errorDepositAmountAboveMax, {
					maxAmount: toDecimal(paymentMethodDetails?.maxAmount).toFixed(),
				}),
			};
			isValid = false;
		} else if (!cardNumber || (cardType && !isCardTypeSupported)) {
			errorList = {
				...errorList,
				cardNumber: formatMessage(messages.errorUnsupportedCardType, {
					unsupportedType: cardType || '',
					supportedTypes: supportedCardTypes?.join(', ') || '',
				}),
			};
			isValid = false;
		} else if (!isValidCardNumber(cardNumber)) {
			errorList = {
				...errorList,
				cardNumber: formatMessage(messages.errorInvalidCardNumber),
			};
			isValid = false;
		} else if (!holderName || holderName.length > 50 || !isAlpha(holderNameWithoutSpaces)) {
			errorList = {
				...errorList,
				holderName: formatMessage(messages.errorInvalidHolderName),
			};
			isValid = false;
		} else if (
			// Allow unhandled card types (for ex. MAESTRO) potentially not have CVV
			(cardType === CardType.amex && cvv.length > 4) ||
			(cardType !== CardType.amex && cvv.length > 3)
		) {
			errorList = {
				...errorList,
				cvv: formatMessage(messages.errorInvalidField),
			};
			isValid = false;
		} else if (!validUntil || !isCardValid(validUntil)) {
			errorList = {
				...errorList,
				validUntil: formatMessage(errorResponseMessages.expired_card_provided),
			};
			isValid = false;
		}
		setIsButtonDisabled(isValid);
		setFormErrorState({
			...formErrorState,
			...errorList,
		});
		return isValid;
	};

	const processDeposit = (e: any) => {
		e.preventDefault();
		if (!validateForm() || !profile?.verified) return;
		setIsButtonDisabled(true);
		setIsRequestPending(true);

		const body = {
			cardNumber: cardNumber.replaceAll(' ', ''),
			currencyCode,
			expMonth: parseInt(validUntil.split('/')[0], 10),
			expYear: parseInt(`20${validUntil.split('/')[1]}`, 10),
			holderName,
			save: saveCard,
		};

		void axiosInstance
			.post(addLinkedCardsURL(), body)
			.then(({ data }) => {
				const selectedAccount = accounts?.find(
					(account) => account.currencyCode === currencyCode
				);
				if (selectedAccount) {
					handleLinkedCardDeposit(selectedAccount.id, data.id);
				}
			})
			.catch(({ response }) => {
				const { key: cardError, errorParameters, errorCode } = response.data || {};
				const validationError = errorParameters?.[0]?.value;
				const messageKeys = Object.keys(errorResponseMessages);
				let errorMessage;

				if (validationError && messageKeys.includes(validationError)) {
					errorMessage = errorResponseMessages[validationError as errorResponseKey];
				} else if (cardError && messageKeys.includes(cardError)) {
					errorMessage = errorResponseMessages[cardError as errorResponseKey];
				}

				return setFormSubmitError(
					formatMessage(errorMessage || errorMessages[errorCode]) || messages.errorDefault
				);
			})
			.then(() => {
				setIsButtonDisabled(false);
				return setIsRequestPending(false);
			});
	};

	const handleChange = ({ target: { value, name } }: ChangeEvent<HTMLInputElement>) => {
		setFormErrorState((prevState: FormErrorState) => ({
			...prevState,
			[name]: null,
		}));

		setFormState((prevState) => ({
			...prevState,
			[name]: name === 'holderName' ? value.toUpperCase() : value,
		}));
	};

	const handleSelectChange = ({ value }: SelectOption) => {
		setFormState((prevState) => ({
			...prevState,
			currencyCode: value,
		}));
	};

	const handleCheckboxChange = (value: boolean) => {
		return setFormState((prevState) => ({
			...prevState,
			saveCard: value,
		}));
	};

	useEffect(() => {
		if (
			cardNumber &&
			isValidCardNumber(cardNumber) &&
			isCardTypeSupported &&
			cardNumber !== cardNumberForWhichCurrencyOptionsAreLoaded &&
			cardNumber.replaceAll(' ', '').length === 16
		) {
			setCardNumberForWhichCurrencyOptionsAreLoaded(cardNumber);
			axiosInstance
				.get(getLinkedCardPaymentMethodURL(cardNumber.replaceAll(' ', '')))
				.then(async (response) => {
					const { paymentMethodCurrencies, id } = response.data || {};
					void axiosInstance
						.get<PaymentMethod>(getPaymentMethodsDetailsURL(id))
						.then(({ data }) => {
							setPaymentMethodDetails(data);

							if (paymentMethodCurrencies) {
								const selectOptions = paymentMethodCurrencies.map(
									(currency: CurrencyEnum) => ({
										label: (
											<div className={styles.option}>
												<CurrencyIcon
													currencyType={currency}
													className={styles.currencyIcon}
												/>
												<label>{currency}</label>
											</div>
										),
										value: currency,
									})
								);
								setCurrencyCodeOptions(selectOptions);
								setFormState({
									...formState,
									currencyCode: selectOptions[0].value,
								});
							}
						});
				})
				.catch(() => {
					return setFormSubmitError(formatMessage(messages.errorDefault));
				});
		}
	}, [
		formState,
		isCardTypeSupported,
		cardNumberForWhichCurrencyOptionsAreLoaded,
		formatMessage,
		cardNumber,
	]);

	useEffect(() => {
		const isSupportedCardType = !!cardType && !!supportedCardTypes?.includes(cardType);
		setIsCardTypeSupported(isSupportedCardType);
	}, [cardType, supportedCardTypes]);

	useEffect(() => {
		if (debouncedCardNumber && debouncedDepositAmount && currencyCode) {
			getPaymentId();
		}
	}, [debouncedCardNumber, debouncedDepositAmount, currencyCode, getPaymentId]);

	const handleDepositAmountChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
		setFormState((prevState: FormState) => ({
			...prevState,
			depositAmount: value,
		}));
	};

	if (isLoading) return <Loader className={styles.loader} />;
	return (
		<div data-cy={TestIds.linkNewCardView}>
			<Seo title={depositMessages.metaCardDepositsTitle} />
			<PageTitle
				historyLink={{
					pathname: `/${locale}/deposit/history/fiat`,
					state: { backUrl: window.location.pathname },
				}}
				isPreviousPageLinkVisibleOnDesktop
				isPreviousPageLinkVisibleOnMobile={false}
				previousPageLink={`/${locale}/deposit/card`}
				title={formatMessage(messages.title)}
			/>
			<div className={styles.container}>
				<div className={styles.column}>
					{formSubmitError && (
						<NotificationMessage
							withIcon
							type={NotificationType.Error}
							style={NotificationStyle.Border}
							message={
								typeof formSubmitError === 'string' ? (
									formSubmitError
								) : (
									<FormattedMessage {...formSubmitError} />
								)
							}
						/>
					)}
					<form onSubmit={(e) => e.preventDefault()} className={styles.form}>
						<CardNumberInput
							className={classNames(styles.formInput, styles.firstInput)}
							label={formatMessage(messages.cardNumber)}
							name="cardNumber"
							onChange={handleChange}
							onCreditCardTypeChanged={setCardType}
							value={cardNumber}
							autoComplete="cc-number"
							errorMessage={
								cardType && !isCardTypeSupported
									? formatMessage(messages.errorUnsupportedCardType, {
											unsupportedType: cardType || '',
											supportedTypes: supportedCardTypes?.join(', ') || '',
									  })
									: cardNumber.replaceAll(' ', '').length === 16 &&
									  !isValidCardNumber(cardNumber)
									? formatMessage(messages.errorInvalidCardNumber)
									: formErrorState.cardNumber
							}
							data-cy={TestIds.cardNumber}
						/>
						<Select
							inputGroupClassName={styles.input}
							labelClassName={styles.label}
							id="wallet"
							disabled={currencyCode === 'placeholder'}
							label={formatMessage(messages.depositCurrency)}
							name="currencyCode"
							placeholder="Enter card number first"
							onChangeObject={handleSelectChange}
							options={currencyCodeOptions}
							value={currencyCode || null}
						/>
						<CurrencyInput
							id="depositAmount"
							labelClassName={styles.label}
							label={messages.depositAmount}
							value={depositAmount}
							onChange={handleDepositAmountChange}
							currencyCode={CurrencyEnum[currencyCode]}
							error={formErrorState.depositAmount}
						/>
						<div className={styles.receiveAmount} data-cy={TestIds.receiveAmount}>
							<span className={styles.label}>
								{formatMessage(baseMsg.receiveAmount)}
							</span>
							<span className={styles.value} data-cy={TestIds.receiveAmountValue}>
								{amountFormatter(formatPrecision(receiveAmount, CurrencyEnum.EUR))}{' '}
								{currencyCode}
							</span>
							{formErrorState.receiveAmount &&
								typeof formErrorState.receiveAmount === 'string' && (
									<div
										className={classNames(
											inputStyles.error,
											styles.receiveError
										)}
										data-cy={TestIds.receiveAmountError}
									>
										{formErrorState.receiveAmount}
									</div>
								)}
						</div>
						<Input
							id="holderName"
							name="holderName"
							type="text"
							inputGroupClassName={styles.input}
							className={styles.formInput}
							labelClassName={styles.label}
							label={messages.fullName}
							placeholder={messages.fullNamePlaceholder}
							onChangeEvent={handleChange}
							value={holderName}
							errorMessage={formErrorState.holderName}
							autoComplete="cc-name"
						/>
						<CardExpirationDateInput
							className={styles.input}
							label={formatMessage(messages.validUntil)}
							name="validUntil"
							onChange={handleChange}
							value={validUntil}
							autoComplete="cc-exp"
							errorMessage={formErrorState.validUntil}
							data-cy={TestIds.cardExpirationDate}
						/>
						<CardCvvInput
							className={styles.formInput}
							label={formatMessage(messages.cvv)}
							name="cvv"
							autoComplete="cc-csc"
							value={cvv}
							onChange={handleChange}
							cvvDigitCount={cardType === CardType.amex ? 4 : 3}
							errorMessage={formErrorState.cvv}
							data-cy={TestIds.cardCvv}
						/>
						<Checkbox
							checked={saveCard}
							className={styles.checkbox}
							labelClassName={styles.checkboxLabel}
							onChange={() => handleCheckboxChange(!saveCard)}
							id={TestIds.saveCardCheckBox}
						>
							<div className={styles.checkBoxLabel}>
								<FormattedMessage {...messages.saveLabel} />
							</div>
						</Checkbox>
						<Button
							buttonStyle={ButtonStyle.PRIMARY}
							className={styles.nextButton}
							isLoading={isLoading || isRequestPending}
							isDisabled={
								!cardNumber ||
								!holderName ||
								!validUntil ||
								!depositAmount ||
								!isNumeric(depositAmount) ||
								!isValidCardNumber(cardNumber) ||
								cvv?.length !== (cardType === CardType.amex ? 4 : 3) ||
								isButtonDisabled
							}
							type={ButtonType.BUTTON}
							onClick={processDeposit}
							text={formatMessage(messages.next)}
							data-cy={TestIds.button}
						/>
					</form>
				</div>
				<div className={classNames(styles.column, styles.howToDepositColumn)}>
					<HowToDeposit
						duration={paymentMethodDetails?.duration}
						paymentMethodName={paymentMethodDetails?.title || ''}
						providerType={ProviderType.LINKED_CARD}
						howToHeading={messages.howToHeading}
					/>
					<PaymentMethodLimits
						depositCurrencyCode={currencyCode}
						activePaymentMethodFees={paymentMethodDetails?.activePaymentMethodFees}
						baseCurrencyCode={paymentMethodDetails?.baseCurrencyCode}
						limits={paymentMethodDetails?.paymentMethodLimits || []}
						maxAmount={paymentMethodDetails?.maxAmount}
						minAmount={paymentMethodDetails?.minAmount}
						minFee={paymentMethodDetails?.minFee}
						maxFee={paymentMethodDetails?.maxFee}
						fee={paymentMethodDetails?.fee}
						paymentMethodName={formatMessage(messages.card)}
					/>
				</div>
			</div>
		</div>
	);
};

export default LinkNewCardDetails;
