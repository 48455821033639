import { FC } from 'react';
import classNames from 'classnames';
import { CurrencyEnum, CurrencyIcon } from '@spectrocoin/sc-currencies';
import styles from './AutoConvertCurrency.module.scss';

type AutoConvertCurrencyProps = {
	className?: string;
	labelClassName?: string;
	iconClassName?: string;
	currencyName: string;
	currencyCode: CurrencyEnum;
	showLabel?: boolean;
	shortLabel?: boolean;
};

const AutoConvertCurrency: FC<AutoConvertCurrencyProps> = ({
	className,
	iconClassName,
	labelClassName,
	currencyName,
	currencyCode,
	showLabel = true,
	shortLabel = false,
	...rest
}) => {
	return (
		<div className={classNames(styles.autoConvert, className)} {...rest}>
			<CurrencyIcon
				currencyType={currencyCode}
				className={classNames(styles.currencyIcon, iconClassName)}
			/>
			{showLabel && (
				<div className={classNames(styles.currencyLabel, labelClassName)}>
					{shortLabel ? currencyCode : `${currencyName} (${currencyCode})`}
				</div>
			)}
		</div>
	);
};

export default AutoConvertCurrency;
