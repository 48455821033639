import { FC } from 'react';
import { useIntl } from 'react-intl';
import Loading from '../../Shared/Loading/Loading';
import { useOrderStatusTranslator } from '../../../../helpers/merchantsHelper/merchantsHelper';
import LabeledField from '../../Shared/LabeledField/LabeledField';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import PageTitle from '../../Shared/PageTitle/PageTitle';
import NoProjects from '../../Shared/NotFound/NotFound';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';
import useMerchantRoutes, { MerchantRoutes } from '../../../../hooks/useMerchantRoutes';

import styles from './ViewMerchantsCallback.module.scss';
import convertToLocalTime from '../../../../helpers/dateHelper/dateHelper';
import TestIds from '../../../../test/TestIds';
import useFormatAmount from '../../../../hooks/useFormatAmount';
import { useOrderCallbackDataByCallbackId } from '../../../../redux/MerchantsState/OrderCallbacks/OrderCallbacksQueries';

const Content: FC = () => {
	const { formatMessage } = useIntl();
	const { getUrl, getParams } = useMerchantRoutes();
	const { callbackId = '' } = getParams();
	const translateStatus = useOrderStatusTranslator();
	const amountFormatter = useFormatAmount();

	const { data } = useOrderCallbackDataByCallbackId(callbackId);

	if (!data) return null;

	const {
		merchantOrderId,
		id,
		orderStatus,
		receivedAmount,
		receiveCurrencyCode,
		statusCode,
		created,
		callbackResponse,
	} = data;

	return (
		<>
			<PageTitle
				className={styles.title}
				title={formatMessage(messages.orderCallbackDetails)}
				backLink={getUrl(MerchantRoutes.OrderView, { orderId: merchantOrderId })}
			/>
			<div className={styles.content}>
				<LabeledField data-cy={TestIds.CallbackViewId} label={formatMessage(messages.id)}>
					{id}
				</LabeledField>
				<LabeledField
					data-cy={TestIds.CallbackViewOrderStatus}
					label={formatMessage(messages.orderStatus)}
				>
					{translateStatus(orderStatus)}
				</LabeledField>
				<LabeledField
					data-cy={TestIds.CallbackViewReceive}
					label={formatMessage(messages.receive)}
				>
					{`${amountFormatter(
						formatPrecision(receivedAmount, receiveCurrencyCode)
					)} ${receiveCurrencyCode}`}
				</LabeledField>
				<LabeledField
					data-cy={TestIds.CallbackViewStatusCode}
					label={formatMessage(messages.responseStatusCode)}
				>
					{statusCode}
				</LabeledField>
				<LabeledField
					data-cy={TestIds.CallbackViewDate}
					label={formatMessage(messages.date)}
				>
					{convertToLocalTime(created, 'yyyy-MM-dd hh:mm:ss')}
				</LabeledField>
				<LabeledField
					data-cy={TestIds.CallbackViewResponse}
					label={formatMessage(messages.response)}
				>
					{callbackResponse}
				</LabeledField>
			</div>
		</>
	);
};

const ViewMerchantsCallback: FC = () => {
	const { formatMessage } = useIntl();
	const { getParams } = useMerchantRoutes();

	const { callbackId = '' } = getParams();

	const { isFetching, data, error } = useOrderCallbackDataByCallbackId(callbackId);

	return (
		<WhiteContainer className={styles.container} data-cy={TestIds.CallbackView}>
			{isFetching && <Loading />}
			{error && <NoProjects message={formatMessage(messages.callbackNotFound)} />}
			{data && <Content />}
		</WhiteContainer>
	);
};

export default ViewMerchantsCallback;
