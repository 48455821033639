import {
	ChangeEvent,
	createContext,
	FC,
	ReactNode,
	useContext,
	useEffect,
	useMemo,
	useState,
	MouseEvent,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { CurrencyIcon, CurrencyEnum, currencyUtils } from '@spectrocoin/sc-currencies';
import Joi from 'joi';
import inputErrors from '../../../../messages/inputErrors.messages';
import { RemoteError } from '../../../../interfaces/RemoteData';
import errorMessages from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import useValidation from '../../../../hooks/useValidation';
import AccountSelect from '../../../../components/AccountSelect/AccountSelect';
import { RootState } from '../../../../redux/Store';
import { Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import Toggle from '../../../../components/Toggle/Toggle';
import Input from '../../../../components/Input/Input';
import OptionalField from '../../../../components/OptionalField/OptionalField';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import TestIds from '../../../../test/TestIds';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';
import CurrencyOption from '../AccountSelectOption/AccountSelectOption';
import { compareReceiveCurrencies } from '../../../../helpers/merchantsHelper/merchantsHelper';

import styles from './PreOrderForm.module.scss';
import { DeviceNameType } from '../../../../redux/AppState/AppTypes';

type CurrencyInputContextType = {
	currency: CurrencyEnum;
	currencyName: string;
	price: string;
	onSetPrice: (value: string) => void;
};

const CurrencyInputContext = createContext<CurrencyInputContextType>({
	currency: CurrencyEnum.BTC,
	currencyName: '',
	price: '',
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSetPrice: () => {},
});

const CurrencyInput: FC<{
	amount: string;
	showLabel?: boolean;
	currencyType: CurrencyEnum;
	currencyName: string;
	onChange: (value: string) => void;
}> = ({ amount, currencyType: currency, currencyName, onChange, showLabel = true }) => {
	const { formatMessage } = useIntl();
	const { deviceOS } = useSelector((state: RootState) => state.AppState);
	const [fixedDecimal, setFixedDecimal] = useState(false);
	const [showAmount, setShowAmount] = useState('');

	useEffect(() => {
		setShowAmount(amount);
	}, [amount, currency]);

	return (
		<div data-cy={TestIds.PreOrderFormAutoconvertPriceField} className={styles.currencyInput}>
			{showLabel && (
				<label className={styles.label}>
					<FormattedMessage {...messages.orderPrice} />
				</label>
			)}
			<div className={styles.input}>
				<CurrencyIcon currencyType={currency} className={styles.inputCurrencyIcon} />
				<NumericFormat
					data-cy={TestIds.PreOrderFormAutoconvertPriceInput}
					className={styles.numberInput}
					decimalSeparator={deviceOS === DeviceNameType.IPHONE ? ',' : '.'}
					decimalScale={currency && currencyUtils.getConfigOrDefault(currency).precision}
					allowNegative={false}
					fixedDecimalScale={fixedDecimal}
					onFocus={() => setFixedDecimal(false)}
					onBlur={() => setFixedDecimal(true)}
					onKeyUp={(e: any) => {
						setShowAmount(e.target.value);
						onChange(e.target.value);
					}}
					inputMode="decimal"
					thousandSeparator={deviceOS === DeviceNameType.IPHONE ? '.' : ','}
					value={showAmount}
					autoComplete="off"
					placeholder={formatMessage(messages.enterAmount)}
				/>
				<div className={styles.currencyInfo}>
					<div
						data-cy={TestIds.PreOrderFormAutoconvertPriceCurrency}
						className={styles.code}
					>
						{currency}
					</div>
					<div className={styles.name}>{currencyName}</div>
				</div>
			</div>
		</div>
	);
};

const SelectedCurrencyInput: FC = () => {
	const { formatMessage } = useIntl();
	const { deviceOS } = useSelector((state: RootState) => state.AppState);
	const [fixedDecimal, setFixedDecimal] = useState(false);
	const [showAmount, setShowAmount] = useState('');
	const { currency, price, currencyName, onSetPrice } = useContext(CurrencyInputContext);

	useEffect(() => setShowAmount(price), [currency, price]);

	return (
		<div
			className={styles.selectedCurrencyInput}
			data-cy={TestIds.PreOrderFormSelectPriceField}
		>
			<CurrencyIcon
				currencyType={currency}
				className={styles.inputCurrencyIcon}
				onClick={() => {
					/* there is some black magic voodoo going on here, mobile needs da voodoo */
				}}
			/>
			<NumericFormat
				onClick={(e: MouseEvent) => {
					e.stopPropagation();
				}}
				data-cy={TestIds.PreOrderFormSelectPriceNumberFormat}
				className={styles.numberInput}
				decimalSeparator={deviceOS === DeviceNameType.IPHONE ? ',' : '.'}
				fixedDecimalScale={fixedDecimal}
				onFocus={() => setFixedDecimal(false)}
				onBlur={() => setFixedDecimal(true)}
				decimalScale={currency && currencyUtils.getConfigOrDefault(currency).precision}
				allowNegative={false}
				onKeyUp={(e: any) => {
					setShowAmount(e.target.value);
					onSetPrice(e.target.value);
				}}
				inputMode="decimal"
				thousandSeparator={deviceOS === DeviceNameType.IPHONE ? '.' : ','}
				value={showAmount}
				autoComplete="off"
				autoFocus
				placeholder={formatMessage(messages.enterAmount)}
			/>
			<div
				className={styles.currencyInfo}
				onClick={() => {
					/* there is some black magic voodoo going on here, mobile needs da voodoo */
				}}
			>
				<div data-cy={TestIds.PreOrderFormSelectPriceCurrency} className={styles.code}>
					{currency}
				</div>
				<div className={styles.name}>{currencyName}</div>
			</div>
		</div>
	);
};

type Form = {
	selectedCurrency: CurrencyEnum;
	price: string;
	orderId: string | null;
	description: string | null;
	callbackUrl: string | null;
	successUrl: string | null;
	failureUrl: string | null;
	emailNotifications: boolean;
	allowMultiplePayments: boolean;
};

function throwExpression(): never {
	throw Error();
}
export const usePreorderFormValidator = (error?: RemoteError) => {
	const { formatMessage } = useIntl();

	const errorCode = error?.errorCode || null;

	return useMemo(
		() =>
			Joi.object<Partial<Form>>({
				orderId: Joi.string()
					.allow('', null)
					.max(255)
					.message(
						formatMessage(inputErrors.x_lessOrEqual_y, {
							Field: formatMessage(messages.orderId),
							Max: 255,
						})
					)
					.custom((v) => (errorCode === 'MA_12' ? throwExpression() : v))
					.message(formatMessage(errorMessages.MA_12)),
				description: Joi.string()
					.allow('', null)
					.max(255)
					.message(
						formatMessage(inputErrors.x_lessOrEqual_y, {
							Field: formatMessage(messages.description),
							Max: 255,
						})
					),
				callbackUrl: Joi.string()
					.custom((v) => (errorCode === 'MA_3' ? throwExpression() : v))
					.message(formatMessage(errorMessages.MA_3))
					.allow('', null)
					.uri({ scheme: ['http', 'https'] })
					.message(formatMessage(errorMessages.MA_3)),
				successUrl: Joi.string()
					.custom((v) => (errorCode === 'MA_4' ? throwExpression() : v))
					.message(formatMessage(errorMessages.MA_4))
					.allow('', null)
					.uri({ scheme: ['http', 'https'] })
					.message(formatMessage(errorMessages.MA_4)),
				failureUrl: Joi.string()
					.custom((v) => (errorCode === 'MA_5' ? throwExpression() : v))
					.message(formatMessage(errorMessages.MA_5))
					.allow('', null)
					.uri({ scheme: ['http', 'https'] })
					.message(formatMessage(errorMessages.MA_5)),
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[errorCode]
	);
};

type PreOrderFormProps = {
	currencies: CurrencyEnum[];
	onSetCurrency: (value: CurrencyEnum) => void;
	onSetPrice: (value: string) => void;
	onSetOrderId: (value: string | null) => void;
	onSetDescription: (value: string | null) => void;
	onSetCallbackUrl: (value: string | null) => void;
	onSetSuccessUrl: (value: string | null) => void;
	onSetFailureUrl: (value: string | null) => void;
	onSetEmailNotifications: (value: boolean) => void;
	onSetAllowMultiplePayments: (value: boolean) => void;
	children?: JSX.Element;
	formError?: ReactNode;
	error?: RemoteError;
} & Form;

const PreOrderForm: FC<PreOrderFormProps> = ({
	currencies,
	selectedCurrency,
	onSetCurrency,
	price,
	onSetPrice,
	orderId,
	onSetOrderId,
	description,
	onSetDescription,
	callbackUrl,
	onSetCallbackUrl,
	successUrl,
	onSetSuccessUrl,
	failureUrl,
	onSetFailureUrl,
	emailNotifications,
	onSetEmailNotifications,
	allowMultiplePayments,
	onSetAllowMultiplePayments,
	children,
	formError,
	error,
}) => {
	const { formatMessage } = useIntl();
	const { wallets } = useSelector((state: RootState) => state.AccountsState);

	const currencyOptions = useMemo(() => {
		return currencies
			.map((x) => wallets?.find((wallet) => wallet.currencyCode === x) as Wallet)
			.filter((x) => x)
			.map(({ currencyCode, currencyName, availableBalance }) => ({
				value: currencyCode,
				currencyCode,
				currencyName,
				balance: availableBalance,
			}))
			.sort((a, b) => compareReceiveCurrencies(a.currencyCode, b.currencyCode));
	}, [wallets, currencies]);

	const { value, currencyName } = useMemo(() => {
		return currencyOptions.find(
			({ value: currencyValue }) => selectedCurrency === currencyValue
		)!;
	}, [currencyOptions, selectedCurrency]);

	const validator = usePreorderFormValidator(error);
	const [_, validationResult] = useValidation(
		{ orderId, description, callbackUrl, successUrl, failureUrl },
		validator
	);

	return (
		<CurrencyInputContext.Provider
			value={{ currency: value as CurrencyEnum, currencyName, price, onSetPrice }}
		>
			<div data-cy={TestIds.PreOrderForm} className={styles.form}>
				{formError}
				{currencyOptions.length === 1 ? (
					<CurrencyInput
						amount={price}
						currencyType={currencyOptions[0].value as CurrencyEnum}
						currencyName={currencyOptions[0].currencyName}
						onChange={onSetPrice}
					/>
				) : (
					<AccountSelect
						label={formatMessage(messages.orderPrice)}
						labelClassName={styles.label}
						onChange={onSetCurrency}
						value={selectedCurrency}
						componentOverrides={{
							SingleValue: SelectedCurrencyInput,
							Option: CurrencyOption,
						}}
						options={currencyOptions}
					/>
				)}

				<OptionalField
					data-cy={TestIds.PreOrderFormIdField}
					id="merchant-order-id"
					name="merchant-order-id"
					label={messages.orderId}
					onChange={onSetOrderId}
					initialState={!!orderId}
				>
					{({ onChange }) => (
						<Input
							value={orderId || ''}
							onChange={onChange}
							errorMessage={validationResult.orderId}
						/>
					)}
				</OptionalField>

				<OptionalField
					data-cy={TestIds.PreOrderFormDescriptionField}
					id="merchant-order-desdcription"
					name="merchant-order-description"
					label={messages.description}
					onChange={onSetDescription}
					initialState={!!description}
				>
					{({ onChange }) => (
						<Input
							value={description || ''}
							onChange={onChange}
							errorMessage={validationResult.description}
						/>
					)}
				</OptionalField>

				<OptionalField
					data-cy={TestIds.PreOrderFormCallbackField}
					id="merchant-order-callback"
					name="merchant-order-callback"
					label={messages.callbackUrl}
					onChange={onSetCallbackUrl}
					initialState={!!callbackUrl}
				>
					{({ onChange }) => (
						<Input
							value={callbackUrl || ''}
							onChange={onChange}
							errorMessage={validationResult.callbackUrl}
						/>
					)}
				</OptionalField>

				<OptionalField
					data-cy={TestIds.PreOrderFormSuccessField}
					id="merchant-order-success"
					name="merchant-order-success"
					label={messages.successUrl}
					onChange={onSetSuccessUrl}
					initialState={!!successUrl}
				>
					{({ onChange }) => (
						<Input
							value={successUrl || ''}
							onChange={onChange}
							errorMessage={validationResult.successUrl}
						/>
					)}
				</OptionalField>

				<OptionalField
					data-cy={TestIds.PreOrderFormFailureField}
					id="merchant-order-failure"
					name="merchant-order-failure"
					label={messages.failureUrl}
					onChange={onSetFailureUrl}
					initialState={!!failureUrl}
				>
					{({ onChange }) => (
						<Input
							value={failureUrl || ''}
							onChange={onChange}
							errorMessage={validationResult.failureUrl}
						/>
					)}
				</OptionalField>

				<Toggle
					data-cy={TestIds.PreOrderFormEmailNotificationsInput}
					checked={emailNotifications}
					id="merchant-order-email"
					name="merchant-order-email"
					label={messages.emailNotification}
					labelClassName={styles.label}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						onSetEmailNotifications(e.target.checked)
					}
				/>

				<Toggle
					data-cy={TestIds.PreOrderFormMultiplePaymentsInput}
					id="merchant-order-multiple"
					name="merchant-order-multiple"
					checked={allowMultiplePayments}
					onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
						onSetAllowMultiplePayments(target.checked)
					}
					labelClassName={classNames(styles.label, styles.inlineLabel)}
					label={
						<>
							<span>
								<FormattedMessage {...messages.allowMultiplePayments} />
							</span>
							<Tooltip
								infoIconVisible
								content={
									<div className={styles.tooltip}>
										<FormattedMessage
											{...messages.allowMultiplePaymentsTooltip}
										/>
									</div>
								}
							/>
						</>
					}
				/>
				{children}
			</div>
		</CurrencyInputContext.Provider>
	);
};

export default PreOrderForm;
