import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import MerchantsActions from '../../../../redux/MerchantsState/MerchantsActions';
import { RootState } from '../../../../redux/Store';
import MerchantsSelectors from '../../../../redux/MerchantsState/MerchantsSelectors';
import { ProjectsListMode, Project } from '../../../../redux/MerchantsState/MerchantTypes';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation';

import styles from './ProjectAction.module.scss';
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import TestIds from '../../../../test/TestIds';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import useQueryPagination from '../../../../hooks/useQueryPagination';

type ProjectActionProps = {
	project: Project;
};

const NoAction: FC = () => {
	const { isMobile } = useSelector((state: RootState) => state.AppState);
	return !isMobile ? (
		<FontAwesomeIcon className={styles.icon} icon={faChevronRight} size="lg" />
	) : null;
};

const DeleteProjectConfirmation: FC<ProjectActionProps> = ({ project }) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const { status, error } = useSelector(MerchantsSelectors.projects.list.getDelete);
	const [page] = useQueryPagination();

	useEffect(() => {
		if (status === RemoteStatus.Done) {
			dispatch(toggleModal());
			dispatch(MerchantsActions.projects.list.resetDelete());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	return (
		<DeleteConfirmation
			isDisabled={status === RemoteStatus.InProgress}
			name={project.name}
			onDelete={async () => {
				await dispatch(MerchantsActions.projects.list.deleteProject(project.id, page - 1));
			}}
		>
			{error && (
				<NotificationMessage
					withIcon
					data-cy={TestIds.ProjectRemoveError}
					type={NotificationType.Error}
					style={NotificationStyle.Border}
					message={formatMessage(getErrorMessageOrDefault(error))}
				/>
			)}
		</DeleteConfirmation>
	);
};

const DeleteAction: FC<ProjectActionProps> = ({ project }) => {
	const dispatch = useDispatch();

	const deleteProject = (e: React.MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();
		dispatch(toggleModal(<DeleteProjectConfirmation project={project} />));
		return false;
	};

	return (
		<FontAwesomeIcon
			data-cy={TestIds.ProjectListRowDelete}
			className={classNames(styles.icon, styles.deleteIcon)}
			icon={faTimes}
			onClick={deleteProject}
			size="lg"
		/>
	);
};

const ProjectAction: FC<ProjectActionProps> = ({ project, ...props }) => {
	const { mode } = useSelector(MerchantsSelectors.projects.list.getState);

	const content = (() => {
		switch (mode) {
			case ProjectsListMode.Delete:
				return <DeleteAction {...props} project={project} />;

			default:
				return <NoAction {...props} />;
		}
	})();

	return <div className={styles.container}>{content}</div>;
};

export default ProjectAction;
