import { FC } from 'react';
import classNames from 'classnames';
import styles from './IBANToggler.module.scss';

export enum IbanOption {
	SEPA = 'SEPA',
	SWIFT = 'SWIFT',
}

interface Props {
	onClick: (props: IbanOption) => void;
	selected: IbanOption;
}

const IBANToggler: FC<Props> = ({ onClick, selected }) => {
	return (
		<ul className={styles.ibanOptions}>
			<li>
				<button
					className={classNames({
						[styles.active]: selected === IbanOption.SEPA,
					})}
					onClick={() => onClick(IbanOption.SEPA)}
					type="button"
				>
					{IbanOption.SEPA}
				</button>
			</li>
			<li>
				<button
					className={classNames({
						[styles.active]: selected === IbanOption.SWIFT,
					})}
					onClick={() => onClick(IbanOption.SWIFT)}
					type="button"
				>
					{IbanOption.SWIFT}
				</button>
			</li>
		</ul>
	);
};

export default IBANToggler;
