/* eslint-disable no-useless-escape */
import React, { KeyboardEventHandler, useCallback, useMemo, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { defineMessages, useIntl } from 'react-intl';
import { InputActionMeta, MultiValue } from 'react-select';
import Joi from 'joi';
import styles from './MultiEmailInput.module.scss';

interface IProps {
	items: string[];
	onChange: (items: string[]) => void;
}

const messages = defineMessages({
	placeholderText: {
		id: 'multiEmailInput.placeholder',
		defaultMessage: 'Enter email addresses',
	},
	alreadyAdded: {
		id: 'multiEmailInput.alreadyAdded',
		defaultMessage: '{Email} has already been added.',
	},
	notValidEmail: {
		id: 'multiEmailInput.notValidEmail',
		defaultMessage: '{Email} is not a valid email address.',
	},
});

interface Option {
	readonly label: string;
	readonly value: string;
}

const createOption = (label: string): Option => ({
	label,
	value: label,
});

const MultiEmailInput: React.FC<IProps> = ({ items, onChange }) => {
	const { formatMessage } = useIntl();
	const [error, setError] = useState('');
	const [inputValue, setInputValue] = useState('');
	const emails = useMemo(() => items.map(createOption), [items]);

	const emailValidator = useMemo(() => Joi.string().email({ tlds: { allow: false } }), []);

	const getValidationError = useCallback(
		(email: string) => {
			let validationError = null;

			if (items.includes(email.toLowerCase())) {
				validationError = formatMessage(messages.alreadyAdded, {
					Email: email.toLowerCase(),
				});
			}

			if (emailValidator.validate(email).error) {
				validationError = formatMessage(messages.notValidEmail, { Email: email });
			}

			return validationError;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[emailValidator, items]
	);

	const isValidEmail = useCallback((email: string) => !getValidationError(email), [
		getValidationError,
	]);

	const onKeyDown: KeyboardEventHandler = useCallback(
		(event) => {
			if (!inputValue) return;
			switch (event.key) {
				case 'Enter':
				case 'Tab':
				case ' ':
				case ',':
					{
						const validationError = getValidationError(inputValue);
						if (validationError) {
							setError(validationError);
						} else {
							onChange([
								...emails.map((x) => x.value),
								createOption(inputValue).value,
							]);
							setInputValue('');
						}

						event.preventDefault();
					}
					break;
				default:
					break;
			}
		},
		[emails, getValidationError, inputValue, onChange]
	);

	const onInputChange = useCallback((newValue: string, action: InputActionMeta) => {
		if (action.action !== 'input-change') return;
		setError('');
		setInputValue(newValue);
	}, []);

	const onEmailsChange = useCallback(
		(values: MultiValue<Option>) => {
			return onChange(values.map((x) => x.value));
		},
		[onChange]
	);

	return (
		<>
			<CreatableSelect
				components={{ DropdownIndicator: null }}
				value={emails}
				inputValue={inputValue}
				onKeyDown={onKeyDown}
				onChange={onEmailsChange}
				onInputChange={onInputChange}
				isValidNewOption={isValidEmail}
				placeholder={formatMessage(messages.placeholderText)}
				onBlur={() => setError('')}
				isMulti
				isClearable
				menuIsOpen={false}
			/>
			{error && <p className={styles.error}>{error}</p>}
		</>
	);
};

export default MultiEmailInput;
