import { ModalType, ToggleModalAction, ModalState } from './ModalTypes';

const initialState: ModalState | Record<string, never> = {};

export default (state = initialState, action: ToggleModalAction) => {
	switch (action.type) {
		case ModalType.TOGGLE_MODAL:
			if (state[action.type]) {
				return initialState;
			}
			return {
				...state,
				[action.type]: {
					content: action.content,
					isOutsideClickable: action.isOutsideClickable,
					isHeightAuto: action.isHeightAuto,
				},
			};
		default:
			return state;
	}
};
