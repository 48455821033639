import classNames from 'classnames';
import { FC, FormEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import createIconPath from '../../../../images/settings/create_modal_icon.svg';
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import baseMsg from '../../../../messages/base.messages';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import { createApiKey } from '../../../../redux/SettingsState/SettingsActions';
import SettingsMessages from '../../../../redux/SettingsState/SettingsMessages';
import { RootState } from '../../../../redux/Store';
import DetailField from '../Shared/DetailsField/DetailsField';
import Form, { ApiScopes, FormState } from '../Shared/Form/Form';
import Loading from '../Shared/Loading/Loading';
import Modal from '../Shared/Modal/Modal';
import styles from './Create.module.scss';
import { values } from '../../../../helpers/objectHelper/objectHelper';

type ModalProps = {
	name: string;
	clientId: string;
	clientSecret: string;
};

const CreateModal: FC<ModalProps> = ({ name, clientId, clientSecret }) => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();

	return (
		<Modal
			title={formatMessage(SettingsMessages.apiCreateModalTitle)}
			iconPath={createIconPath}
			body={
				<>
					<DetailField label={formatMessage(SettingsMessages.apiName)} value={name} />
					<DetailField
						label={formatMessage(SettingsMessages.apiKey)}
						value={clientId}
						isCopyable
					/>
					<DetailField
						label={formatMessage(SettingsMessages.apiSecret)}
						value={clientSecret}
						isCopyable
					/>
					<NotificationMessage
						type={NotificationType.Warning}
						style={NotificationStyle.Border}
						message={formatMessage(SettingsMessages.apiSecretWarning)}
						className={styles.modalNotification}
						withIcon
					/>
				</>
			}
			footer={
				<Button
					text={baseMsg.close}
					type={ButtonType.BUTTON}
					buttonStyle={ButtonStyle.PRIMARY}
					className={styles.modalCloseButton}
					onClick={() => dispatch(toggleModal())}
				/>
			}
		/>
	);
};

const ApiCreate = () => {
	const dispatch = useDispatch();
	const { push } = useHistory();
	const { locale } = useIntl();
	const { isTablet } = useSelector((state: RootState) => state.AppState);
	const {
		edit: { status },
	} = useSelector((state: RootState) => state.SettingsState.apiKeys);

	const [form, setForm] = useState<FormState>({
		name: '',
		ipAddress: '',
		ipWhitelist: [],
		scopes: values(ApiScopes).reduce((acc, key) => {
			acc[key] = { isChecked: false };
			return acc;
		}, {} as Record<ApiScopes, { isChecked: boolean }>),
	});

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();
		void dispatch(createApiKey(form, CreateModal));
	};

	useEffect(() => {
		if (status === RemoteStatus.Done) push(`/${locale}/settings/api`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	if (status === RemoteStatus.InProgress) return <Loading />;
	return (
		<>
			<PageTitle
				title={SettingsMessages.apiCreateTitle}
				previousPageLink={`/${locale}/settings/api`}
				isPreviousPageLinkVisibleOnDesktop
				isPreviousPageLinkVisibleOnMobile={false}
			/>
			<div
				className={classNames(styles.container, {
					[styles.mobile]: isTablet,
				})}
			>
				<Form
					hasTerms
					handleSubmit={handleSubmit}
					submitButtonText={SettingsMessages.apiCreateButton}
					formState={form}
					setFormState={setForm}
				/>
			</div>
		</>
	);
};

export default ApiCreate;
