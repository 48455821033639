import { RemoteData, RemoteError } from '../../../../interfaces/RemoteData';
import PageableResponse from '../../../../interfaces/PageableResponse';
import { PaymentButton } from '../PaymentButtonsData/PaymentButtonsDataTypes';

export enum PaymentButtonsListMode {
	View = 1,
	Delete = 2,
}

export enum PaymentButtonsListStatus {
	Initial,
	Loading,
	Empty,
	Error,
	Ready,
}

export type PaymentButtonsListState = {
	pages: Record<number, RemoteData<string[]>>;
	totalCount: number;
	status: PaymentButtonsListStatus;
	delete: RemoteData<void>;
	mode: PaymentButtonsListMode;
	pageSize: number;
	filter: {
		search: string;
		tags: {
			enabled: boolean;
			disabled: boolean;
			fiat: boolean;
			crypto: boolean;
		};
	};
};

export enum PaymentButtonsListActionType {
	FETCH_BUTTONS_PENDING = `MERCHANTS/PAYMENT_BUTTONS/LIST/FETCH_BUTTONS/PENDING`,
	FETCH_BUTTONS_REJECTED = 'MERCHANTS/PAYMENT_BUTTONS/LIST/FETCH_BUTTONS/REJECTED',
	FETCH_BUTTONS_FULFILLED = 'MERCHANTS/PAYMENT_BUTTONS/LIST/FETCH_BUTTONS/FULFILLED',
	RESET_PAGES = 'MERCHANTS/PAYMENT_BUTTONS/LIST/RESET_PAGES',
	SET_TAGS = 'MERCHANTS/PAYMENT_BUTTONS/LIST/SET_TAGS',
	SET_SEARCH = 'MERCHANTS/PAYMENT_BUTTONS/LIST/SET_SEARCH',
	SET_STATUS = 'MERCHANTS/PAYMENT_BUTTONS/LIST/SET_STATUS',
	SET_MODE = 'MERCHANTS/PAYMENT_BUTTONS/LIST/SET_MODE',
	RESET_DELETE = 'MERCHANTS/PAYMENT_BUTTONS/LIST/RESET_DELETE',
	DELETE_PENDING = `MERCHANTS/PAYMENT_BUTTONS/LIST/DELETE/PENDING`,
	DELETE_REJECTED = 'MERCHANTS/PAYMENT_BUTTONS/LIST/DELETE/REJECTED',
	DELETE_FULFILLED = 'MERCHANTS/PAYMENT_BUTTONS/LIST/DELETE/FULFILLED',
}

export type PaymentButtonsListFetchButtonsPendingAction = {
	type: PaymentButtonsListActionType.FETCH_BUTTONS_PENDING;
	payload: number;
};
export type PaymentButtonsListFetchButtonsRejectedAction = {
	type: PaymentButtonsListActionType.FETCH_BUTTONS_REJECTED;
	payload: {
		page: number;
		error?: RemoteError;
	};
};
export type PaymentButtonsListFetchButtonsFulfilledAction = {
	type: PaymentButtonsListActionType.FETCH_BUTTONS_FULFILLED;
	payload: {
		page: number;
		buttons: PageableResponse<PaymentButton[]>;
	};
};

export type PaymentButtonsListResetPagesAction = {
	type: PaymentButtonsListActionType.RESET_PAGES;
};

export type PaymentButtonsListResetDeleteAction = {
	type: PaymentButtonsListActionType.RESET_DELETE;
};

export type PaymentButtonsListSetSearchAction = {
	type: PaymentButtonsListActionType.SET_SEARCH;
	payload: string;
};

export type PaymentButtonsListSetTagsAction = {
	type: PaymentButtonsListActionType.SET_TAGS;
	payload: Partial<PaymentButtonsListState['filter']['tags']>;
};

export type PaymentButtonsListSetModeAction = {
	type: PaymentButtonsListActionType.SET_MODE;
	payload: PaymentButtonsListMode;
};

export type PaymentButtonsListSetStatusAction = {
	type: PaymentButtonsListActionType.SET_STATUS;
	payload: PaymentButtonsListStatus;
};

export type PaymentButtonsListDeletePendingAction = {
	type: PaymentButtonsListActionType.DELETE_PENDING;
};

export type PaymentButtonsListDeleteRejectedAction = {
	type: PaymentButtonsListActionType.DELETE_REJECTED;
	payload?: RemoteError;
};

export type PaymentButtonsListDeleteFulfilledAction = {
	type: PaymentButtonsListActionType.DELETE_FULFILLED;
	payload: string;
};
