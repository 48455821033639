/* eslint-disable import/prefer-default-export */
import { AxiosError } from 'axios';
import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { toPlainAmount } from '../../../helpers/currencyAmountHelper/currencyAmountHelper';
import { RemoteError } from '../../../interfaces/RemoteData';
import { getDonationPaymentUrl, getPublicPaymentButtonCurrenciesUrl } from '../../endpoints';
import { AppDispatch, RootState } from '../../Store';
import { TwoFaCode } from '../../TwoFaState/TwoFaTypes';
import DonationSelectors from './DonationSelectors';
import {
	DonationActionType,
	DonationCurrency,
	DonationFetchButtonFulfilledAction,
	DonationFetchButtonPendingAction,
	DonationFetchButtonRejectedAction,
	DonationFetchCurrenciesFulfilledAction,
	DonationFetchCurrenciesPendingAction,
	DonationFetchCurrenciesRejectedAction,
	DonationForm,
	DonationPayDonationFulfilledAction,
	DonationPayDonationPendingAction,
	DonationPayDonationRejectedAction,
	DonationSetFormAction,
	PaymentButton,
} from './DonationTypes';
import axiosInstance from '../../../helpers/axiosInstance';

const fetchButtonPending: ActionCreator<DonationFetchButtonPendingAction> = () => ({
	type: DonationActionType.FETCH_BUTTON_PENDING,
});

const fetchButtonRejected: ActionCreator<DonationFetchButtonRejectedAction> = (
	error?: RemoteError
) => ({
	type: DonationActionType.FETCH_BUTTON_REJECTED,
	payload: error,
});

const fetchButtonFulfilled: ActionCreator<DonationFetchButtonFulfilledAction> = (
	order: PaymentButton
) => ({
	type: DonationActionType.FETCH_BUTTON_FULFILLED,
	payload: order,
});

const fetchButton = (id: string) => async (dispatch: AppDispatch) => {
	dispatch(fetchButtonPending(id));

	await axiosInstance
		.get<PaymentButton>(getDonationPaymentUrl(id))
		.then(({ data }) => {
			dispatch(fetchButtonFulfilled(data));
		})
		.catch((e: AxiosError) => {
			dispatch(fetchButtonRejected(e.response?.data));
		});
};

const fetchCurrenciesPending: ActionCreator<DonationFetchCurrenciesPendingAction> = () => ({
	type: DonationActionType.FETCH_CURRENCIES_PENDING,
});

const fetchCurrenciesRejected: ActionCreator<DonationFetchCurrenciesRejectedAction> = (
	error?: RemoteError
) => ({
	type: DonationActionType.FETCH_CURRENCIES_REJECTED,
	payload: error,
});

const fetchCurrenciesFulfilled: ActionCreator<DonationFetchCurrenciesFulfilledAction> = (
	currencies: DonationCurrency[]
) => ({
	type: DonationActionType.FETCH_CURRENCIES_FULFILLED,
	payload: currencies,
});

const fetchCurrencies = (id: string) => async (dispatch: AppDispatch) => {
	dispatch(fetchCurrenciesPending(id));

	await axiosInstance
		.get<PaymentButton>(getPublicPaymentButtonCurrenciesUrl(id))
		.then(({ data }) => {
			dispatch(fetchCurrenciesFulfilled(data));
		})
		.catch((e: AxiosError) => {
			dispatch(fetchCurrenciesRejected(e.response?.data));
		});
};

const setForm: ActionCreator<DonationSetFormAction> = (form: Partial<DonationForm>) => ({
	type: DonationActionType.SET_FORM,
	payload: form,
});

const payPending: ActionCreator<DonationPayDonationPendingAction> = () => ({
	type: DonationActionType.PAY_DONATION_PENDING,
});

const payRejected: ActionCreator<DonationPayDonationRejectedAction> = (error?: RemoteError) => ({
	type: DonationActionType.PAY_DONATION_REJECTED,
	payload: error,
});

const payFulfilled: ActionCreator<DonationPayDonationFulfilledAction> = (
	button: PaymentButton
) => ({
	type: DonationActionType.PAY_DONATION_FULFILLED,
	payload: button,
});

const pay = (id: string): ThunkAction<Promise<void>, RootState, undefined, any> => async (
	dispatch: AppDispatch,
	getState
) => {
	const state = getState();
	const { walletId, amount } = DonationSelectors.getForm(state);

	dispatch(payPending(id));

	await axiosInstance
		.post<PaymentButton>(getDonationPaymentUrl(id), {
			accountGuid: walletId,
			amount: toPlainAmount(amount),
		})
		.then(({ data }) => {
			dispatch(payFulfilled(data));
		})
		.catch((e) => {
			if (e.response?.data?.errorCode !== TwoFaCode.TWO_FA_REQUIRED) {
				dispatch(payRejected(e.response?.data));
			}
		});
};

export default {
	setForm,
	fetchButton,
	fetchCurrencies,
	fetchCurrenciesFulfilled,
	pay,
	payFulfilled,
	payRejected,
};
