import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import useValidation from '../../../../hooks/useValidation';
import Loading from '../../Shared/Loading/Loading';
import { RootState } from '../../../../redux/Store';
import Button, { ButtonType } from '../../../../components/Button/Button';
import TestIds from '../../../../test/TestIds';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import PreOrderForm, { usePreorderFormValidator } from '../../Shared/PreOrderForm/PreOrderForm';
import PageTitle from '../../Shared/PageTitle/PageTitle';
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';
import NoProjects from '../../Shared/NotFound/NotFound';
import MerchantsActions from '../../../../redux/MerchantsState/MerchantsActions';
import MerchantsSelectors from '../../../../redux/MerchantsState/MerchantsSelectors';
import useMerchantRoutes, { MerchantRoutes } from '../../../../hooks/useMerchantRoutes';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';

import styles from './NewMerchantsPreOrder.module.scss';
import baseMsg from '../../../../messages/base.messages';
import useEffectOnce from '../../../../hooks/useEffectOnce';

const Content: FC = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { getParams } = useMerchantRoutes();
	const { push } = useHistory();
	const { getUrl } = useMerchantRoutes();

	const { projectId = '' } = getParams();

	const {
		currency,
		allowMultiplePayments,
		description,
		callbackUrl,
		emailNotifications,
		failureUrl,
		orderId,
		price,
		successUrl,
	} = useSelector(MerchantsSelectors.preorders.create.getCreateForm);

	const { status, error, data: preorderId } = useSelector(
		MerchantsSelectors.preorders.create.getAction
	);

	const { receiveAccountId } = useSelector(
		MerchantsSelectors.projects.data.getProjectDataById(projectId)
	)!;

	const { wallets } = useSelector((state: RootState) => state.AccountsState);

	const allowedCurrencies = useMemo(() => {
		if (!wallets) return [];
		if (!receiveAccountId) return wallets.map((x) => x.currencyCode as CurrencyEnum);
		const projectCurrency = wallets.find((x) => x.id === receiveAccountId);
		return projectCurrency ? [projectCurrency.currencyCode as CurrencyEnum] : [];
	}, [receiveAccountId, wallets]);

	const selectedCurrency =
		allowedCurrencies.length === 1 ? allowedCurrencies[0] : currency || CurrencyEnum.EUR;

	const onSubmit = useCallback(() => {
		dispatch(MerchantsActions.preorders.create.createPreorder(projectId));
	}, [dispatch, projectId]);

	const onCurrencyChange = useCallback(
		(value: CurrencyEnum) => {
			if (!receiveAccountId) {
				dispatch(MerchantsActions.preorders.create.setForm({ currency: value }));
			}
		},
		[dispatch, receiveAccountId]
	);

	const validator = usePreorderFormValidator(error);
	const [isValid] = useValidation(
		{ orderId, description, callbackUrl, successUrl, failureUrl },
		validator
	);

	// ensure currency is always set when autoconvert is disabled
	useEffect(() => {
		if (!receiveAccountId && !currency)
			dispatch(MerchantsActions.preorders.create.setForm({ currency: selectedCurrency }));
	});

	useEffectOnce(() => () => {
		dispatch(MerchantsActions.preorders.create.reset());
	});

	useEffect(() => {
		if (status === RemoteStatus.Done) {
			push(
				getUrl(MerchantRoutes.PreOrderView, {
					preorderId,
				})
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	if (!receiveAccountId && !currency) return null;

	return (
		<>
			<PageTitle
				title={formatMessage(messages.createOrder)}
				className={styles.title}
				backLink={getUrl(MerchantRoutes.ProjectView)}
			/>
			<PreOrderForm
				formError={
					status === RemoteStatus.Error && (
						<NotificationMessage
							withIcon
							type={NotificationType.Error}
							style={NotificationStyle.Border}
							message={formatMessage(getErrorMessageOrDefault(error))}
						/>
					)
				}
				error={error}
				currencies={allowedCurrencies}
				selectedCurrency={selectedCurrency}
				onSetCurrency={onCurrencyChange}
				price={price}
				onSetPrice={(value) =>
					dispatch(MerchantsActions.preorders.create.setForm({ price: value }))
				}
				orderId={orderId}
				onSetOrderId={(value) =>
					dispatch(MerchantsActions.preorders.create.setForm({ orderId: value }))
				}
				description={description}
				onSetDescription={(value) =>
					dispatch(MerchantsActions.preorders.create.setForm({ description: value }))
				}
				callbackUrl={callbackUrl}
				onSetCallbackUrl={(value) =>
					dispatch(MerchantsActions.preorders.create.setForm({ callbackUrl: value }))
				}
				successUrl={successUrl}
				onSetSuccessUrl={(value) =>
					dispatch(MerchantsActions.preorders.create.setForm({ successUrl: value }))
				}
				failureUrl={failureUrl}
				onSetFailureUrl={(value) =>
					dispatch(MerchantsActions.preorders.create.setForm({ failureUrl: value }))
				}
				emailNotifications={emailNotifications}
				onSetEmailNotifications={(value) =>
					dispatch(
						MerchantsActions.preorders.create.setForm({ emailNotifications: value })
					)
				}
				allowMultiplePayments={allowMultiplePayments}
				onSetAllowMultiplePayments={(value) =>
					dispatch(
						MerchantsActions.preorders.create.setForm({ allowMultiplePayments: value })
					)
				}
			>
				<Button
					data-cy={TestIds.PreOrderCreateSubmit}
					isDisabled={status === RemoteStatus.InProgress || !price}
					className={styles.submit}
					type={ButtonType.BUTTON}
					text={baseMsg.submit}
					onClick={onSubmit}
				/>
			</PreOrderForm>
		</>
	);
};

const NewMerchantsOrder: FC = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { getParams } = useMerchantRoutes();

	const { projectId = '' } = getParams();

	const { status: projectStatus } = useSelector(
		MerchantsSelectors.projects.data.getProjectById(projectId)
	);

	const project = useSelector(MerchantsSelectors.projects.data.getProjectDataById(projectId));

	useEffect(() => {
		if (projectStatus === RemoteStatus.None)
			dispatch(MerchantsActions.projects.data.fetchProject(projectId));
	}, [dispatch, projectId, projectStatus]);

	if (projectStatus === RemoteStatus.None) return null;

	return (
		<WhiteContainer className={styles.container}>
			{projectStatus === RemoteStatus.InProgress && <Loading />}
			{(projectStatus === RemoteStatus.Error || project?.enabled === false) && (
				<NoProjects message={formatMessage(messages.projectNotFound)} />
			)}
			{projectStatus === RemoteStatus.Done && project?.enabled && <Content />}
		</WhiteContainer>
	);
};

export default NewMerchantsOrder;
