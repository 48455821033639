import { Fragment } from 'react';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import baseMsg from '../../../../messages/base.messages';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import useFormatAmount from '../../../../hooks/useFormatAmount';

interface DataProps {
	[key: string]: string;
}

interface OffchainConfirmProps {
	data: DataProps[];
	currency: CurrencyEnum;
	className: string;
}

const OffchainConfirm = ({ data, currency, className }: OffchainConfirmProps): JSX.Element => {
	const amountFormatter = useFormatAmount();
	return (
		<>
			{data.map(({ amount, email, memo, receiveAmount }: DataProps) => (
				<Fragment key={email}>
					<InfoInput className={className} label={baseMsg.receiver} text={email} />
					<InfoInput
						className={className}
						label={baseMsg.withdrawAmount}
						text={`${amountFormatter(
							formatPrecision(amount, currency, undefined, true)
						)} ${currency}`}
					/>
					<InfoInput
						className={className}
						label={baseMsg.totalConvertedAmount}
						text={`${amountFormatter(
							formatPrecision(receiveAmount, CurrencyEnum.BTC)
						)} ${CurrencyEnum.BTC}`}
					/>
					{memo && <InfoInput className={className} label={baseMsg.memo} text={memo} />}
				</Fragment>
			))}
		</>
	);
};

export default OffchainConfirm;
