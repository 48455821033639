import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import ReactSelect, { Theme, components } from 'react-select';
import SelectOption from '../../../../interfaces/SelectOption';
import convertMsg from '../../convertMsg';
import styles from './ConvertSelect.module.scss';

interface Props {
	id: string;
	options: SelectOption[];
	isOpen: boolean;
	currency: CurrencyEnum;
	onChange: any;
	isSearchable?: boolean;
	value?: string;
}

const selectStyles = (isSearchable: boolean) => ({
	control: (provided: any) => ({
		...provided,
		boxShadow: 'none',
		border: 'none',
		background: 'transparent',
		color: '#102D6F',
		fontSize: '12px',
		display: 'flex',
		cursor: 'pointer',
		position: 'absolute',
	}),
	input: (provided: any) => ({
		...provided,
		visibility: 'visible',
		cursor: 'pointer',
		fontSize: '14px',
		height: '30px',
		zIndex: 50,
		marginLeft: '10px',
		marginTop: '12px',
		flex: '0 1 100%',
		width: '433px',
		textAlign: 'left',
		padding: '5px 15px 5px 25px',
		borderRadius: '3px',
		color: 'black',
		border: '1px solid #D9DBE5',
		pointerEvents: 'none',
	}),
	singleValue: (provided: any) => ({
		...provided,
		display: 'none',
	}),
	valueContainer: (provided: any) => ({
		...provided,
		padding: '0',
		minWidth: '155px',
		cursor: 'pointer',
		height: '45px',
		textAlign: 'right',
		alignItem: 'center',
		flexFlow: 'row wrap',
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	dropdownIndicator: (prodived: any) => ({
		...prodived,
		display: 'none',
	}),
	menu: (provided: any) => ({
		...provided,
		background: '#ffffff',
		borderRadius: '4px',
		padding: '0',
		zIndex: '10',
		cursor: 'pointer',
		textAlign: 'left',
		border: '0',
		...(isSearchable && { paddingTop: '45px' }),
	}),
	menuList: (provided: any) => ({
		...provided,
		background: '#ffffff',
		cursor: 'pointer',
		padding: '0',
		border: '0',
	}),
	option: (provided: any, { isSelected, isFocused }: any) => ({
		...provided,
		background: isSelected || isFocused ? '#F5F8FF' : 'white',
		color: isSelected || isFocused ? 'white !important' : 'white !important',
	}),
});

const selectThemeColors = {
	primary: '#4972f4',
	primary25: '#f5f8ff',
	primary50: '#f5f8ff',
	primary75: '#4972f4',
};

const { Input } = components;

const SearchInput = ({ ...props }: any) => (
	<>
		<div className={styles.inputContainer}>
			<i className="fa fa-search" aria-hidden="true" />
		</div>
		<Input {...props} id="searchInput" className={styles.searchInput} />
	</>
);

const ConvertSelect: FC<Props> = ({
	id,
	options,
	isOpen,
	currency,
	onChange,
	value,
	isSearchable = false,
}) => {
	const { formatMessage } = useIntl();

	return (
		<ReactSelect
			components={{ Input: SearchInput }}
			inputId={`${id}Account`}
			isDisabled={false}
			styles={{ ...selectStyles(isSearchable) }}
			onChange={({ value: val }: SelectOption) => onChange(val)}
			options={options}
			isSearchable={isSearchable}
			isClearable={false}
			autoFocus
			value={value || options.filter((option: SelectOption) => option.value === currency)[0]}
			noOptionsMessage={() => formatMessage(convertMsg.noResult)}
			name={`${id}Account`}
			menuIsOpen={isOpen}
			classNamePrefix="convert"
			theme={(theme: Theme) => ({
				...theme,
				colors: {
					...theme.colors,
					...selectThemeColors,
				},
			})}
		/>
	);
};

export default ConvertSelect;
