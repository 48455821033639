import { useIntl, defineMessages } from 'react-intl';
import Seo from '../../components/Seo/Seo';

const messages = defineMessages({
	notFound: {
		id: 'notFound.notFound',
		defaultMessage: 'Not Found',
	},
});

const NotFoundPage = () => {
	const { locale, formatMessage } = useIntl();
	return (
		<>
			<Seo title={messages.notFound} />
			<h1>
				{locale} {formatMessage(messages.notFound)}
			</h1>
		</>
	);
};

export default NotFoundPage;
