import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import UserContractCompany from '../../enums/UserContractCompanyEnum';

export enum WithdrawActionType {
	SET_PAYMENT_METHODS_BY_GROUP = 'WITHDRAW/SET_PAYMENT_METHODS_BY_GROUP',
	RESET_PAYMENT_BY_GROUP = 'WITHDRAW/RESET_PAYMENT_BY_GROUP',
	SET_TOPUP_COUNTRY = 'SET_TOPUP_COUNTRY',
	SET_IS_OFFCHAIN_ENABLED = 'SET_IS_OFFCHAIN_ENABLED',
}

export enum WithdrawType {
	OFFCHAIN = 'offchain',
	CRYPTOCURRENCY = 'crypto',
	EWALLET = 'e-wallet',
	BANK = 'bank',
	GIFT = 'gift',
	VOUCHER = 'voucher',
	TOPUP = 'topup',
}

export enum ProviderType {
	PERFECT_MONEY = 'PERFECT_MONEY',
	PAYEER = 'PAYEER',
	SKRILL = 'SKRILL',
	ADV_CASH = 'ADV_CASH',
	SEPA = 'SEPA',
	WIRE = 'WIRE',
	LOCAL = 'LOCAL',
	UK_LOCAL = 'UK_LOCAL',
}

export interface BankSuccessResponse {
	externalFormUrl: string | null;
	accountNumber: string;
	date: string | null;
	feeAmount: string | null;
	id: string | null;
	other: string;
	receiveAmount: string;
	receiveCurrencyCode: CurrencyEnum;
	receiver: string;
	receiverAddress: string | null;
	sortCode: string | null;
	status: WithdrawStatus;
	swift: string;
	withdrawAmount: string;
	withdrawCurrencyCode: CurrencyEnum;
}

export interface OffchainSuccessResponse {
	sendAccount: string;
	sendAmount: string;
	receiver: string;
	totalAmount: string | null;
	receiveAmount: string;
	confirm: boolean;
	memo: string;
}

export interface EWalletSuccessResponse {
	accountNumber: string;
	date: string;
	id: string;
	receiveAmount: string;
	receiveCurrencyCode: CurrencyEnum;
	status: string;
	withdrawAmount: string;
	withdrawCurrencyCode: CurrencyEnum;
}

export interface PurchaseSuccessResponse {
	id: string;
	payAmount: string;
	payCurrencyCode: CurrencyEnum;
	sellAmount: string | null;
	sellCurrencyCode: CurrencyEnum;
	receiver: string;
	status: WithdrawStatus;
	date: string;
	code: string | null;
	productTitle: string | null;
	itemTitle: string;
}

export interface AvailableVouchersResponse {
	currencyCode: CurrencyEnum;
	id: string;
}

export interface VoucherResponse {
	date: string;
	id: string;
	receiveAmount: string;
	withdrawAmount: string;
}

export interface PurchaseItem {
	fee: string;
	id: string;
	logo: string;
	maxPrice: string;
	minPrice: string;
	title: string;
}

export interface AvailablePurchaseResponse {
	content: PurchaseItem[];
	size: number;
	totalElements: number;
	totalPages: number;
}

export interface PaymentMethodResponse {
	paymentMethods?:
		| PaymentMethod[]
		| CurrencyEnum[]
		| string[]
		| AvailableVouchersResponse[]
		| PurchaseItem[];
	count?: number;
}

export interface PaymentMethod {
	id: string;
	enabledCountries: string | null;
	disabledCountries: string | null;
	key: string | null;
	logo: string;
	title: string;
	baseCurrencyCode: CurrencyEnum;
	providerType: ProviderType;
	minAmount: string | null;
	maxAmount: string | null;
	fee: string | null;
	minFee: string | null;
	maxFee: string | null;
	unverifiedAmount: string | null;
	companies: string | null;
	isEnabledForVerified: boolean;
	isEnabled: boolean;
	duration: string | null;
	toCompany: UserContractCompany | null;
	companyMovementNeeded: boolean | null;
	isIBAN: boolean;
	paymentMethodLimits: {
		currencyCode: CurrencyEnum | string;
		limitAmount: string;
		limitDuration: string;
	}[];
	paymentMethodCurrencies: CurrencyEnum[];
	activePaymentMethodFees: {
		currencyType: CurrencyEnum;
		minFee: string;
		fee: string;
		maxFee: string;
	}[];
}

export enum PaymentMethodGroup {
	CRYPTO = 'CRYPTO',
	EWALLET = 'EWALLET',
	BANK = 'BANK',
	GIFT_CARDS = 'GIFT_CARDS',
	VOUCHER = 'VOUCHER',
	TOPUPS = 'TOPUPS',
	OFFCHAIN = 'OFFCHAIN',
}

export enum WithdrawStatus {
	PENDING = 'PENDING',
	PAID = 'PAID',
	FAILED = 'FAILED',
}

export interface WithdrawState {
	paymentMethods: {
		offchain: {
			isLoaded: boolean;
			data: CurrencyEnum[];
		};
		crypto: {
			isLoaded: boolean;
			data: CurrencyEnum[];
			totalCount: number;
		};
		ewallet: {
			isLoaded: boolean;
			data: PaymentMethod[];
			totalCount: number;
		};
		bank: {
			isLoaded: boolean;
			data: PaymentMethod[];
			totalCount: number;
		};
		// eslint-disable-next-line camelcase
		gift_cards: {
			isLoaded: boolean;
			data: PurchaseItem[];
			totalCount: number;
		};
		voucher: {
			isLoaded: boolean;
			data: AvailableVouchersResponse[];
			totalCount: number;
		};
		topups: {
			isLoaded: boolean;
			data: PurchaseItem[];
			totalCount: number;
			countryCode: string | null;
		};
	};
}

export interface CryptoWithdrawResponse {
	feeAmount: string;
	receiveAmount: string;
	status: WithdrawStatus;
	transNo: string | null;
	withdrawAccount: string;
	withdrawAmount: string;
	memo: string;
	date: string;
	id?: string;
}

export interface SetWithdrawPaymentMethodsByGroup {
	type: typeof WithdrawActionType.SET_PAYMENT_METHODS_BY_GROUP;
	data: PaymentMethodResponse;
	group: PaymentMethodGroup;
}

export interface ResetWithdrawPaymentMethodsByGroup {
	type: typeof WithdrawActionType.RESET_PAYMENT_BY_GROUP;
	group: PaymentMethodGroup;
}

export interface SetMobileTopupCountryAction {
	type: typeof WithdrawActionType.SET_TOPUP_COUNTRY;
	countryCode: string;
}
