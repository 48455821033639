/* eslint-disable no-param-reassign */
/* eslint-disable no-var */
/* eslint-disable no-sequences */
/* eslint-disable prefer-rest-params */
/* eslint-disable vars-on-top */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */

import axios from 'axios';

const client = axios.create({ withCredentials: true });

const LiveChatScriptLoader = () =>
	(function (n, t, c) {
		function i(n) {
			return e._h ? e._h.apply(null, n) : e._q.push(n);
		}
		var e = {
			_q: [],
			_h: null,
			_v: '2.0',
			on() {
				i(['on', c.call(arguments)]);
			},
			once() {
				i(['once', c.call(arguments)]);
			},
			off() {
				i(['off', c.call(arguments)]);
			},
			get() {
				if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
				return i(['get', c.call(arguments)]);
			},
			call() {
				i(['call', c.call(arguments)]);
			},
			init() {
				const n = t.createElement('script');
				(n.async = !0),
					(n.type = 'text/javascript'),
					(n.src = `${
						document.location.protocol === 'https:' ? 'https://' : 'http://'
					}cdn.livechatinc.com/tracking.js`),
					t.head.appendChild(n);
			},
		};
		!n.__lc.asyncInit && e.init(), (n.LiveChatWidget = n.LiveChatWidget || e);
	})(window, document, [].slice);

const getTokenAsync = () =>
	client
		.post(`/api/fe/uaa/api/live-chat/user-identity-token`, {})
		.then(({ data }) => data?.token)
		.catch(() => null);

export default (userEmail, userName, userSurname, livechatKey, authDomain) => {
	setTimeout(() => {
		window.__lc = window.__lc || {};
		window.__lc.license = livechatKey;

		if (userEmail) {
			window.__lc.visitor = {
				name: `${userName} ${userSurname}`,
				email: userEmail,
			};
		}

		window.LC_API = window.LC_API || {};
		window.LC_API.on_chat_started = async () => {
			if (!authDomain) return;

			const token = await getTokenAsync();
			if (!token) return;

			window.LiveChatWidget.call('set_session_variables', {
				authDomain,
				token,
			});
		};

		LiveChatScriptLoader();
	}, 2000);
};
