/* eslint-disable no-param-reassign */
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import { fromEntries } from '../../../../helpers/objectHelper/objectHelper';
import {
	PayoutsDataActionType,
	PayoutsDataUpdatePayoutsAction,
	PayoutsDataFetchPayoutFulfilledAction,
	PayoutsDataFetchPayoutPendingAction,
	PayoutsDataFetchPayoutRejectedAction,
	PayoutsDataUpdatePayoutAction,
	PayoutsDataState,
} from './PayoutsDataTypes';

const initialState: PayoutsDataState = {
	payouts: {},
};

export default (
	state = initialState,
	action:
		| PayoutsDataUpdatePayoutsAction
		| PayoutsDataFetchPayoutFulfilledAction
		| PayoutsDataFetchPayoutPendingAction
		| PayoutsDataFetchPayoutRejectedAction
		| PayoutsDataUpdatePayoutAction
): PayoutsDataState => {
	switch (action.type) {
		case PayoutsDataActionType.FETCH_PAYOUT_PENDING:
			return {
				...state,
				payouts: {
					...state.payouts,
					[action.payload]: { status: RemoteStatus.InProgress },
				},
			};
		case PayoutsDataActionType.FETCH_PAYOUT_REJECTED:
			return {
				...state,
				payouts: {
					...state.payouts,
					[action.payload]: { status: RemoteStatus.Error },
				},
			};
		case PayoutsDataActionType.FETCH_PAYOUT_FULFILLED:
			return {
				...state,
				payouts: {
					...state.payouts,
					[action.payload.id]: {
						status: RemoteStatus.Done,
						data: {
							...action.payload,
							// created: action.payload.created,
							// validUntil: action.payload.validUntil,
						},
					},
				},
			};
		case PayoutsDataActionType.UPDATE_PAYOUT:
			return {
				...state,
				payouts: {
					...state.payouts,
					[action.payload.id]: {
						status: RemoteStatus.Done,
						data: {
							...action.payload,
							// created: action.payload.created,
							// validUntil: action.payload.validUntil,
						},
					},
				},
			};
		case PayoutsDataActionType.UPDATE_PAYOUTS:
			return {
				...state,
				payouts: {
					...state.payouts,
					...fromEntries(
						action.payload.map((x) => [
							x.id,
							{
								status: RemoteStatus.Done,
								data: {
									...x,
									// created: x.created,
									// validUntil: x.validUntil,
								},
							},
						])
					),
				},
			};
		default:
			return state;
	}
};
