import { FC } from 'react';
import { useDispatch } from 'react-redux';
import toggleModal from '../../../../../redux/ModalState/ModalActions';
import styles from './Modal.module.scss';

type Props = {
	iconPath: any;
	title: string;
	description?: string;
	body: React.ReactNode;
	footer: React.ReactNode;
};

const Modal: FC<Props> = ({ iconPath, title, description = '', body, footer }) => {
	const dispatch = useDispatch();
	return (
		<div className={styles.container}>
			<div
				className={styles.closeIcon}
				onClick={() => dispatch(toggleModal())}
				data-cy="closeModal"
			/>
			<div className={styles.header}>
				<img src={iconPath} alt="modal icon" />
				<h2 className={styles.title} data-cy="modalTitle">
					{title}
				</h2>
				{description && <p className={styles.description}>{description}</p>}
			</div>
			<div className={styles.body}>{body}</div>
			<div className={styles.footer}>{footer}</div>
		</div>
	);
};

export default Modal;
