import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ProviderType } from '../../../redux/DepositState/DepositTypes';
import { ProviderType as WithdrawProviderType } from '../../../redux/WithdrawState/WithdrawTypes';
import useDurationString from '../../../hooks/useDurationString';
import TestIds from '../../../test/TestIds';
import styles from './HowTo.module.scss';
import transfersMsg from '../transfers.messages';

const paymentMethodMessages = defineMessages({
	howToDepositLOCAL: {
		id: 'deposit.howToDepositLOCAL.bank',
		defaultMessage: `
			<li>Enter the deposit amount</li>
			<li>You will be given the bank account details to transfer the money to</li>
			<li>You must use only personal bank account for deposits.</li>
			<li>If you do not enter the payment details correctly, your bank transfer will be held up.</li>
			<li>Please make sure you enter payment details/memos/payment instructions correctly. If you fail to enter payment details accurately we reserve the right to reverse the payment to you.</li>
			<li>WIRE transfers are ONLY accepted in EUR currency. You will be charged a fee if you enter a different currency.</li>
			<li>If you do not transfer the payment correctly, we will not be responsible for any charges or delays! Thank you for understanding.</li>
			<li>Keep your bank wire receipt as we may request it.</li>
			{DurationString}
			<instructions> With any questions, be sure to contact our customer support team. </instructions>
		`,
	},
	howToDepositPERFECT_MONEY: {
		id: 'deposit.howToDepositPERFECT_MONEY',
		defaultMessage: `<li>Fill the form</li>
		<li>You will be redirected to {paymentMethodName} page where you can finish the operation</li>
		{DurationString}
		<instructions> With any questions, be sure to contact our customer support team. </instructions>`,
	},
	howToDepositMAXIMA: {
		id: 'deposit.howToDepositMAXIMA',
		defaultMessage: 'How to deposit: MAXIMA',
	},
	howToDepositPAYEER: {
		id: 'deposit.howToDepositPAYEER',
		defaultMessage: `<li>Fill the form</li>
		<li>You will be redirected to {paymentMethodName} page where you can finish the operation</li>
		{DurationString}
		<instructions> With any questions, be sure to contact our customer support team. </instructions>`,
	},
	howToDepositSEPA: {
		id: 'deposit.howToDepositSEPA.bank',
		defaultMessage: `
			<li>Enter the deposit amount</li>
			<li>You will be given the bank account details to transfer the money to</li>
			<li>You must use only personal bank account for deposits.</li>
			<li>If you do not enter the payment details correctly, your bank transfer will be held up.</li>
			<li>Please make sure you enter payment details/memos/payment instructions correctly. If you fail to enter payment details accurately we reserve the right to reverse the payment to you.</li>
			<li>SEPA transfers are ONLY accepted in EUR currency. You will be charged a fee if you enter a different currency.</li>
			<li>If you do not transfer the payment correctly, we will not be responsible for any charges or delays! Thank you for understanding.</li>
			<li>Keep your bank wire receipt as we may request it.</li>
			{DurationString}
			<instructions> With any questions, be sure to contact our customer support team. </instructions>
		`,
	},
	howToDepositWIRE: {
		id: 'deposit.howToDepositWIRE.bank',
		defaultMessage: `
			<li>Enter the deposit amount</li>
			<li>You will be given the bank account details to transfer the money to</li>
			<li>You must use only personal bank account for deposits.</li>
			<li>If you do not enter the payment details correctly, your bank transfer will be held up.</li>
			<li>Please make sure you enter payment details/memos/payment instructions correctly. If you fail to enter payment details accurately we reserve the right to reverse the payment to you.</li>
			<li>WIRE transfers are ONLY accepted in EUR currency. You will be charged a fee if you enter a different currency.</li>
			<li>If you do not transfer the payment correctly, we will not be responsible for any charges or delays! Thank you for understanding.</li>
			<li>Keep your bank wire receipt as we may request it.</li>
			{DurationString}
			<instructions> With any questions, be sure to contact our customer support team. </instructions>
		`,
	},
	howToDepositSKRILL: {
		id: 'deposit.howToDepositSKRILL',
		defaultMessage: `<li>Fill the form</li>
		<li>You will be redirected to {paymentMethodName} page where you can finish the operation</li>
		{DurationString}
		<instructions> With any questions, be sure to contact our customer support team. </instructions>`,
	},
	howToDepositNETELLER: {
		id: 'deposit.howToDepositNETELLER',
		defaultMessage: `<li>Fill the form</li>
		<li>You will be redirected to {paymentMethodName} page where you can finish the operation</li>
		{DurationString}
		<instructions> With any questions, be sure to contact our customer support team. </instructions>`,
	},
	howToDepositDECTA: {
		id: 'deposit.howToDepositCARD',
		defaultMessage: `
			<li>Select the currency and the deposit amount.</li>
			<li>When filling in your card information, make sure to double-check your card number.</li>
			<li>Please note that you can only add and deposit from your own card.</li>
			<li>Be sure to write your first name and surname; avoid providing only initials.</li>
			<li>If you wish to save this card for later deposits, you can check the box "Save card for future deposits" at the bottom of this form.</li>
			{DurationString}
			<instructions> With any questions, be sure to contact our customer support team. </instructions>
		`,
	},
	howToDepositSIMPLEX: {
		id: 'deposit.howToDepositSIMPLEX',
		defaultMessage: 'How to deposit: SIMPLEX',
	},
	howToDepositPAYBOX: {
		id: 'deposit.howToDepositPAYBOX',
		defaultMessage: 'How to deposit: PAYBOX',
	},
	howToDepositADV_CASH: {
		id: 'deposit.howToDepositADV_CASH',
		defaultMessage: `<li>Fill the form</li>
		<li>You will be redirected to {paymentMethodName} page where you can finish the operation</li>
		{DurationString}
		<instructions> With any questions, be sure to contact our customer support team. </instructions>`,
	},
	howToDepositIBAN: {
		id: 'deposit.howToDepositSEPA.iban',
		defaultMessage: `
			<li>Enter the deposit amount</li>
			<li>You will be given the bank account details to transfer the money to</li>
			{DurationString}
			<instructions> With any questions, be sure to contact our customer support team. </instructions>
		`,
	},
	howToDepositFOXBOX: {
		id: 'deposit.howToDepositFOXBOX',
		defaultMessage: `
			<li>Look for the nearest FOXBOX machine.</li>
			<li>Choose "Virtual wallet" in the FOXBOX machine.</li>
			<li>Press "Bitcoin", write your email, and choose the amount you want to pay for bitcoins.</li>
			<li>After you pay the exact amount, bitcoins will be included into your SpectroCoin account (if you are not registered in the system yet, please note that you have to use the same email you used for the deposit. After the registration, the money will be included into your account balance).</li>
			{DurationString}
			<instructions> With any questions, be sure to contact our customer support team. </instructions>
		`,
	},
	howToDepositQIWI: {
		id: 'deposit.howToDepositQIWI',
		defaultMessage: `
			<li>Look for the nearest QIWI machine</li>
			<li>Find "Bitcoin" deposit option in the QIWI machine, enter your email and choose the amount you want to pay for bitcoins.</li>
			<li>After you pay the full amount, bitcoins will be credited to your SpectroCoin account (If you are not registered in the system yet, please note that you have to use the same email (the one you used for the deposit) for the signup process. After the registration, the money will be included in your account balance).</li>
			{DurationString}
			<instructions> With any questions, be sure to contact our customer support team. </instructions>
		`,
	},
	howToDepositLINKED_CARD: {
		id: 'deposit.howToDepositCARD',
		defaultMessage: `
			<li>Select the currency and the deposit amount.</li>
			<li>When filling in your card information, make sure to double-check your card number.</li>
			<li>Please note that you can only add and deposit from your own card.</li>
			<li>Be sure to write your first name and surname; avoid providing only initials.</li>
			<li>If you wish to save this card for later deposits, you can check the box "Save card for future deposits" at the bottom of this form.</li>
			{DurationString}
			<instructions> With any questions, be sure to contact our customer support team. </instructions>
		`,
	},
	howToDepositecommpay_google_pay: {
		id: 'deposit.howToDepositecommpay_google_pay',
		defaultMessage: `
			<li>Fill the form</li>
			<li>You will be redirected to Google Pay page where you can finish the operation</li>
			<li>The funds will be in your account in X days</li>
		`,
	},
	howToDepositecommpay_apple_pay: {
		id: 'deposit.howToDepositecommpay_apple_pay',
		defaultMessage: `
			<li>Fill the form</li>
			<li>You will be redirected to Apple Pay page where you can finish the operation</li>
			<li>The funds will be in your account in X days</li>
		`,
	},
	howToWithdrawGiftCard: {
		id: 'withdraw.howToWithdrawGiftCard',
		defaultMessage: `
			<li>You can buy Amazon Gift cards to send as a gift to friends and family or use them to shop on Amazon.</li>
			<li>Ensure that you have selected an Amazon Gift Card applicable to your region, as you won't be able to use it on Amazon otherwise. Note that SpectroCoin will not redeem these gift cards.</li>
			<li>If you have already purchased a gift card, you can find the code in the <link>withdrawal history</link>. If you have any questions, please don't hesitate to contact our customer support team.</li>
		`,
	},
	howToWithdrawVoucher: {
		id: 'withdraw.howToWithdrawVoucher',
		defaultMessage: `
			<li>You can purchase a SpectroCoin voucher and send it as a gift to a friend or family member.</li>
			<li>If you have already purchased the voucher, you can find the code in the <link>withdrawal history</link>. If you have any questions, be sure to contact our customer support team.</li>
		`,
	},
	howToWithdrawMobileTopup: {
		id: 'withdraw.howToWithdrawMobileTopup',
		defaultMessage: `
			<li>Ensure that you're entering the correct mobile number. Any top-ups sent to the wrong number cannot be retrieved.</li>
			<li>Be aware that some mobile operators may charge service fees for top-ups. These fees are not collected by us but by the mobile operator.</li>
			<li>Mobile top-ups are typically processed instantly, but in some cases, it may take up to 24 hours for the top-up to be received.</li>
		`,
	},
	howToWithdrawOffchain: {
		id: 'withdraw.howToWithdrawOffchain',
		defaultMessage: `
			<li>Make sure you're sending assets to the correct email address associated with a SpectroCoin account.</li>
			<li>Transactions using the off-chain feature are typically instant. However, in some cases, the processing time may vary</li>
		`,
	},
	howToWithdrawSEPA: {
		id: 'withdraw.howToWithdrawSEPA',
		defaultMessage: `
			<li>Funds are usually received on the same or the next business day.</li>
			<li>To avoid errors, copy and paste the recipient’s bank details. SpectroCoin is not responsible for funds sent to the wrong account.</li>
			<li>We recommend withdrawing funds to the same account used for transfers to SpectroCoin for faster transaction processing.</li>
		`,
	},
	howToWithdrawWIRE: {
		id: 'withdraw.howToWithdrawWIRE',
		defaultMessage: `
			<li>Funds are typically received within 3-5 business days. The payment processing time depends on the availability of our banking provider, correspondent bank, and receiving bank.</li>
			<li>To avoid errors, copy and paste the recipient’s bank details. SpectroCoin is not responsible for funds sent to the wrong account.</li>
			<li>We recommend withdrawing funds to the same account used for transfers to SpectroCoin for faster transaction processing.</li>
		`,
	},
	howToWithdrawCrypto: {
		id: 'withdraw.howToWithdrawCrypto',
		defaultMessage: `
			<li>Ensure that you're sending your funds to the correct wallet address and on the right network. Transfers to wrong addresses or incorrect networks cannot be retrieved.</li>
			<li>Be aware of the network fees when making a withdrawal. These fees are not collected by the exchange but by the network to process the transaction.</li>
			<li>Cryptocurrency transactions are irreversible. Once you've made a withdrawal, it cannot be cancelled or reversed.</li>
		`,
	},
	howToWithdrawPERFECT_MONEY: {
		id: 'withdraw.howToWithdrawEWallet',
		defaultMessage: `
			<li>You must use only your personal e-wallet account for withdrawals. Third-party payments are not allowed.</li>
			<li>To prevent mistakes, copy and paste your e-wallet details. SpectroCoin is not responsible for funds sent to an incorrect account.</li>
			<li>For faster transaction processing, we recommend withdrawing funds to e-wallets registered with the same email address as your SpectroCoin account.</li>
		`,
	},
	howToWithdrawPAYEER: {
		id: 'withdraw.howToWithdrawEWallet',
		defaultMessage: `
			<li>You must use only your personal e-wallet account for withdrawals. Third-party payments are not allowed.</li>
			<li>To prevent mistakes, copy and paste your e-wallet details. SpectroCoin is not responsible for funds sent to an incorrect account.</li>
			<li>For faster transaction processing, we recommend withdrawing funds to e-wallets registered with the same email address as your SpectroCoin account.</li>
		`,
	},
	howToWithdrawSKRILL: {
		id: 'withdraw.howToWithdrawEWallet',
		defaultMessage: `
			<li>You must use only your personal e-wallet account for withdrawals. Third-party payments are not allowed.</li>
			<li>To prevent mistakes, copy and paste your e-wallet details. SpectroCoin is not responsible for funds sent to an incorrect account.</li>
			<li>For faster transaction processing, we recommend withdrawing funds to e-wallets registered with the same email address as your SpectroCoin account.</li>
		`,
	},
	howToWithdrawADV_CASH: {
		id: 'withdraw.howToWithdrawEWallet',
		defaultMessage: `
			<li>You must use only your personal e-wallet account for withdrawals. Third-party payments are not allowed.</li>
			<li>To prevent mistakes, copy and paste your e-wallet details. SpectroCoin is not responsible for funds sent to an incorrect account.</li>
			<li>For faster transaction processing, we recommend withdrawing funds to e-wallets registered with the same email address as your SpectroCoin account.</li>
		`,
	},
});

interface MessageProps {
	id: string;
	defaultMessage?: string;
	values: Record<string, any>;
}

export enum HowToType {
	WITHDRAW = 'Withdraw',
	DEPOSIT = 'Deposit',
}

interface HowToProps {
	title: MessageDescriptor | string;
	className?: string;
	duration?: string | null;
	isIban?: boolean;
	paymentMethodName: string | null | undefined;
	howToHeading?: MessageDescriptor | null;
	providerType?: ProviderType | WithdrawProviderType | string | null | undefined;
	type: HowToType;
}

const HowTo = ({
	title,
	className,
	duration,
	isIban = false,
	paymentMethodName,
	providerType,
	type,
}: HowToProps) => {
	const { formatMessage, locale } = useIntl();
	const durationString = useDurationString(duration);

	const getMessageProps = (): MessageProps | null => {
		const message = isIban
			? paymentMethodMessages.howToDepositIBAN
			: paymentMethodMessages[`howTo${type}${providerType}`];
		if (!message) return null;

		return {
			...message,
			values: {
				li: (text: React.ReactNode) => (
					<li key={text?.toString()} className={styles.listItem}>
						{text}
					</li>
				),
				paymentMethodName,
				br: <br />,
				instructions: (text: React.ReactNode) => (
					<p className={styles.instructionsText}>{text}</p>
				),
				DurationString: () =>
					durationString && (
						<li className={styles.listItem}>
							<FormattedMessage
								{...transfersMsg.fundsWillBeInYourAccountIn}
								values={{ duration: durationString }}
							/>
						</li>
					),
				link: (text: string[]) => (
					<Link
						to={`/${locale}/withdraw/history/${
							providerType === 'Vouchers' ? 'voucher' : 'gift-cards'
						}`}
						className={styles.link}
					>
						{text}
					</Link>
				),
			},
		};
	};

	const messageProps = getMessageProps();
	if (!messageProps) return null;

	return (
		<div data-cy={TestIds.howToDeposit} className={classNames(styles.container, className)}>
			<h2 className={styles.heading}>
				{typeof title === 'string'
					? formatMessage(transfersMsg.howToTitle, {
							paymentMethodName: title,
							type:
								type === HowToType.WITHDRAW ? (
									<FormattedMessage
										id="howTo.withdraw"
										defaultMessage="withdraw"
									/>
								) : (
									<FormattedMessage id="howTo.deposit" defaultMessage="deposit" />
								),
					  })
					: formatMessage(title)}
			</h2>
			<ol className={styles.list}>
				<FormattedMessage {...messageProps} />
			</ol>
		</div>
	);
};

export default HowTo;
