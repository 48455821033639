import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { CurrencyEnum, CurrencyIcon } from '@spectrocoin/sc-currencies';
import { formatPrecision } from '../../helpers/currencyHelper/currencyHelper';
import CopyButton from '../CopyButton/CopyButton';
import styles from './InfoInput.module.scss';
import TestIds, { formatTestId } from '../../test/TestIds';
import useFormatAmount from '../../hooks/useFormatAmount';

const messages = defineMessages({
	copy: {
		id: 'copyButton.copy',
		defaultMessage: 'Copy',
	},
});

interface InfoInputProps {
	id?: string;
	label: MessageDescriptor | string;
	balance?: string;
	currency?: CurrencyEnum;
	hasWalletName?: boolean;
	currencyName?: string;
	text?: string;
	className?: string;
	isCopyVisible?: boolean;
	isTextWithIcon?: boolean;
	isHTML?: boolean;
}

const InfoInput = ({
	id,
	label,
	balance,
	currency,
	hasWalletName = false,
	currencyName,
	text,
	className,
	isCopyVisible = false,
	isHTML = false,
	isTextWithIcon = false,
	...props
}: InfoInputProps) => {
	const { formatMessage } = useIntl();
	const amountFormatter = useFormatAmount();

	const dataToShow = () => {
		if (text)
			return isTextWithIcon && currency ? (
				<div className={styles.textWithIconContainer}>
					<CurrencyIcon currencyType={currency} className={styles.currencyIcon} />
					{text}
				</div>
			) : (
				text
			);
		if (balance) {
			return !text && hasWalletName && currencyName && currency
				? `${currencyName} (${amountFormatter(
						formatPrecision(balance, currency)
				  )} ${currency})`
				: !text && currency
				? `${amountFormatter(formatPrecision(balance, currency))} ${currency}`
				: '0';
		}
		return null;
	};

	const dataToCopy = () => {
		if (text && isTextWithIcon) return text;
		if (dataToShow()) return dataToShow() as string;
		return '';
	};

	return (
		<div className={className} {...props}>
			<label
				className={styles.label}
				data-cy={formatTestId(TestIds.InfoInputLabel, id || currency)}
			>
				{typeof label === 'string' ? label : formatMessage(label)}
				{isCopyVisible && (
					<CopyButton
						dataToCopy={dataToCopy()}
						text={messages.copy}
						className={styles.copyButton}
					/>
				)}
			</label>
			{isHTML && !isTextWithIcon ? (
				<div
					className={styles.value}
					data-cy={formatTestId(TestIds.InfoInputValue, id || currency)}
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: dataToShow() as string }}
				/>
			) : (
				<div
					className={styles.value}
					data-cy={formatTestId(TestIds.InfoInputValue, id || currency)}
				>
					{dataToShow()}
				</div>
			)}
		</div>
	);
};

export default InfoInput;
