import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import { useFormatRoute } from '../../../../helpers/languagePathHelper/languagePathHelper';
import routes from '../../../../route.messages';
import errorImgPath from './images/error.svg';
import successImgPath from './images/success.svg';
import pendingImgPath from './images/pending.svg';
import { ConvertStatusType } from '../../../../redux/ConvertState/ConvertTypes';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import styles from './ConvertResult.module.scss';
import convertMsg from '../../convertMsg';
import { ConvertType } from '../ConvertForm/ConvertForm';
import useFormatAmount from '../../../../hooks/useFormatAmount';

const ConvertResult = () => {
	const { formatMessage, locale } = useIntl();
	const {
		state: { type, form, backUrl, id, convertType },
	} = useLocation();

	const { push } = useHistory();
	const amountFormatter = useFormatAmount();
	const link = useFormatRoute(routes.contact);

	const failed = !(
		type === ConvertStatusType.PAID ||
		type === ConvertStatusType.PENDING ||
		type === ConvertStatusType.NEW ||
		type === ConvertStatusType.ACTIVE
	);

	const imgPath = useCallback(() => {
		switch (type) {
			case ConvertStatusType.PAID:
			case ConvertStatusType.ACTIVE:
				return successImgPath;
			case ConvertStatusType.PENDING:
			case ConvertStatusType.NEW:
				return pendingImgPath;
			default:
				return errorImgPath;
		}
	}, [type]);

	const text = useCallback(() => {
		if (convertType === ConvertType.INSTANT) {
			switch (type) {
				case ConvertStatusType.PAID:
					return formatMessage(convertMsg.instantSuccess);
				case ConvertStatusType.PENDING:
				case ConvertStatusType.NEW:
					return formatMessage(convertMsg.instantPending);
				default:
					return formatMessage(convertMsg.instantFailed);
			}
		}
		if (convertType === ConvertType.RECURRING) {
			switch (type) {
				case ConvertStatusType.ACTIVE:
					return formatMessage(convertMsg.recurringSuccessTitle);
				default:
					return formatMessage(convertMsg.instantFailed);
			}
		}
		return null;
	}, [convertType, formatMessage, type]);

	useEffect(() => {
		if (!type) {
			push(`/${locale}/account`);
		}
	}, [type]);

	return (
		<div className={styles.container}>
			<h3 className={styles.title}>{text()}</h3>
			<img src={imgPath()} className={styles.img} />
			<h4 className={styles.info}>
				{convertType === ConvertType.INSTANT &&
					formatMessage(
						{ ...convertMsg.exchangeInfo },
						{
							from: (
								<strong>{`${amountFormatter(
									formatPrecision(form.payAmount, form.from)
								)} ${form.from}`}</strong>
							),
							to: (
								<strong>{`${amountFormatter(
									formatPrecision(form.receiveAmount, form.to)
								)} ${form.to}`}</strong>
							),
						}
					)}
				{convertType === ConvertType.RECURRING &&
					formatMessage(
						{ ...convertMsg.recurringSuccess },
						{
							payAmount: (
								<strong>{`${amountFormatter(
									formatPrecision(form.payAmount, form.from)
								)} ${form.from}`}</strong>
							),
							receiveCurrency: <strong>{form.to}</strong>,
							frequency: formatMessage(convertMsg[form.frequency]),
						}
					)}
			</h4>
			<Button
				type={ButtonType.LINK}
				text={
					failed
						? convertMsg.tryAgain
						: convertType === ConvertType.INSTANT
						? formatMessage({ ...convertMsg.goToWallet }, { currency: form.to })
						: formatMessage(convertMsg.viewOpenOrders)
				}
				link={
					failed
						? backUrl
						: convertType === ConvertType.INSTANT
						? `/${locale}/account/history/${form.to}/${id}`
						: `${backUrl}/history`
				}
				className={styles.button}
			/>
			{!failed && (
				<Button
					type={ButtonType.LINK}
					buttonStyle={ButtonStyle.BORDERLESS}
					text={formatMessage(convertMsg.backToExchange)}
					link={backUrl}
					className={styles.link}
				/>
			)}
			{failed && (
				<a href={link} className={styles.link}>
					{formatMessage(convertMsg.linkText)}
				</a>
			)}
		</div>
	);
};

export default ConvertResult;
