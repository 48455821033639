import { FC, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from '../../../../components/LoadingScreen/spectrocoin_loader.json';

import styles from './Loading.module.scss';
import TestIds from '../../../../test/TestIds';
import useEffectOnce from '../../../../hooks/useEffectOnce';

const Loading: FC = () => {
	const element = useRef<HTMLDivElement>(null);

	useEffectOnce(() => {
		lottie.loadAnimation({
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData,
			container: element.current!,
		});
	});

	return <div data-cy={TestIds.Loading} ref={element} className={styles.loading} />;
};

export default Loading;
