import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { useDispatch } from 'react-redux';

import AppState from './AppState/AppReducer';
import LanguageState from './LanguageState/LanguageReducer';
import ProfileState from './ProfileState/ProfileReducer';
import AccountsState from './AccountsState/AccountsReducer';
import RatesState from './RatesState/RatesReducer';
import WalletHistoryState from './WalletHistoryState/WalletHistoryReducer';
import ModalState from './ModalState/ModalReducer';
import NotificationsState from './NotificationsState/NotificationsReducer';
import ConvertState from './ConvertState/ConvertReducer';
import ConvertHistoryState from './ConvertHistoryState/ConvertHistoryReducer';
import CardsState from './CardsState/CardsReducer';
import DepositState from './DepositState/DepositReducer';
import DepositHistoryState from './DepositHistoryState/DepositHistoryReducer';
import CardHistoryState from './CardHistoryState/CardHistoryReducer';
import TwoFaState from './TwoFaState/TwoFaReducer';
import WithdrawState from './WithdrawState/WithdrawReducer';
import WithdrawHistoryState from './WithdrawHistoryState/WithdrawHistoryReducer';
import MerchantState from './MerchantsState/MerchantsReducer';
import MerchantsMiddleware from './MerchantsState/MerchantsMiddleware';
import PaymentState from './PaymentsState/PaymentsReducer';
import SettingsState from './SettingsState/SettingsReducer';
import DocumentsHistoryState from './DocumentsHistoryState/DocumentsHistoryReducer';
import ProfileMiddleware from './ProfileState/ProfileMiddleware';

const reducers = {
	AppState,
	LanguageState,
	ProfileState,
	AccountsState,
	RatesState,
	WalletHistoryState,
	ModalState,
	NotificationsState,
	ConvertState,
	ConvertHistoryState,
	CardsState,
	CardHistoryState,
	TwoFaState,
	DepositState,
	DepositHistoryState,
	WithdrawState,
	WithdrawHistoryState,
	MerchantState,
	PaymentState,
	SettingsState,
	DocumentsHistoryState,
};

export const middleware = [...MerchantsMiddleware, ...ProfileMiddleware];

export const rootReducer = combineReducers(reducers);
const composeEnhancers = composeWithDevTools({});
const store =
	process.env.NODE_ENV === 'production'
		? createStore(rootReducer, applyMiddleware(thunk, ...middleware))
		: createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, ...middleware)));

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
