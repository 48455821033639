/* eslint-disable no-param-reassign */
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import {
	ProjectsListActionType,
	ProjectsListFetchProjectsPendingAction,
	ProjectsListFetchProjectsRejectedAction,
	ProjectsListFetchProjectsFulfilledAction,
	ProjectsListState,
	ProjectsListMode,
	ProjectsListSetModeAction,
	ProjectsListSetStatusAction,
	ProjectsListStatus,
	ProjectsListSetSearchAction,
	ProjectsListSetTagsAction,
	ProjectsListResetPagesAction,
	ProjectsListProjectDeletePendingAction,
	ProjectsListProjectDeleteRejectedAction,
	ProjectsListProjectDeleteFulfilledAction,
	ProjectsListResetDeleteAction,
} from './ProjectsListTypes';

const initialState: ProjectsListState = {
	pages: {},
	status: ProjectsListStatus.Initial,
	totalCount: 0,
	pageSize: 20,
	mode: ProjectsListMode.View,
	filter: {
		search: '',
		tags: {
			crypto: false,
			disabled: false,
			enabled: false,
			fiat: false,
		},
	},
	delete: { status: RemoteStatus.None },
};

export default (
	state = initialState,
	action:
		| ProjectsListFetchProjectsPendingAction
		| ProjectsListFetchProjectsRejectedAction
		| ProjectsListFetchProjectsFulfilledAction
		| ProjectsListSetTagsAction
		| ProjectsListSetSearchAction
		| ProjectsListSetStatusAction
		| ProjectsListSetModeAction
		| ProjectsListResetPagesAction
		| ProjectsListProjectDeletePendingAction
		| ProjectsListProjectDeleteRejectedAction
		| ProjectsListProjectDeleteFulfilledAction
		| ProjectsListResetDeleteAction
): ProjectsListState => {
	switch (action.type) {
		case ProjectsListActionType.FETCH_PROJECTS_PENDING:
			return {
				...state,
				pages: {
					...state.pages,
					[action.payload]: { status: RemoteStatus.InProgress, data: [] },
				},
			};
		case ProjectsListActionType.FETCH_PROJECTS_REJECTED:
			return {
				...state,
				pages: {
					...state.pages,
					[action.payload.page]: {
						status: RemoteStatus.Error,
						data: [],
						error: action.payload.error,
					},
				},
			};
		case ProjectsListActionType.FETCH_PROJECTS_FULFILLED:
			return {
				...state,
				totalCount: action.payload.projects.totalElements,
				pageSize: action.payload.projects.pageable.pageSize,
				pages: {
					...state.pages,
					[action.payload.page]: {
						status: RemoteStatus.Done,
						data: action.payload.projects.content.map((x) => x.id),
					},
				},
			};
		case ProjectsListActionType.SET_TAGS:
			return {
				...state,
				filter: {
					...state.filter,
					tags: {
						...state.filter.tags,
						...action.payload,
					},
				},
			};
		case ProjectsListActionType.SET_SEARCH:
			return {
				...state,
				filter: {
					...state.filter,
					search: action.payload,
				},
			};
		case ProjectsListActionType.SET_STATUS:
			return {
				...state,
				status: action.payload,
			};
		case ProjectsListActionType.SET_MODE:
			return {
				...state,
				mode: action.payload,
			};
		case ProjectsListActionType.RESET_PAGES:
			return {
				...state,
				pages: {},
			};
		case ProjectsListActionType.RESET_DELETE:
			return {
				...state,
				delete: { status: RemoteStatus.None },
			};
		case ProjectsListActionType.DELETE_PENDING:
			return {
				...state,
				delete: { status: RemoteStatus.InProgress },
			};
		case ProjectsListActionType.DELETE_REJECTED:
			return {
				...state,
				delete: { status: RemoteStatus.Error, error: action.payload },
			};
		case ProjectsListActionType.DELETE_FULFILLED:
			return {
				...state,
				delete: { status: RemoteStatus.Done },
			};
		default:
			return state;
	}
};
