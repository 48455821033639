import { FC, PropsWithChildren } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { useIntl } from 'react-intl';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import styles from './ProfitSummary.module.scss';
import { profitSummaryQuery } from '../../../../redux/ReferralsState/Queries/ReferralQueries';
import useFormatAmount from '../../../../hooks/useFormatAmount';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import ReferralsMessages from '../../../../redux/ReferralsState/ReferralsMessages';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import zaibukasSvg from './zaibukas.svg';
import { useFormatRoute } from '../../../../helpers/languagePathHelper/languagePathHelper';
import routes from '../../../../route.messages';

const Block: FC<PropsWithChildren<{ title: string }>> = ({ title, children }) => {
	return (
		<div className={styles.block}>
			<div className={styles.blockTitle}>{title}</div>
			<div className={styles.blockValue}>{children}</div>
		</div>
	);
};

const ProfitSummary: FC = () => {
	const { data } = useQuery({
		queryKey: profitSummaryQuery.getKey(),
		queryFn: profitSummaryQuery.getFn(),
	});

	const { formatMessage } = useIntl();
	const amountFormatter = useFormatAmount();
	const referralTerms = useFormatRoute(routes.referralTerms);

	return (
		<div className={styles.root}>
			<WhiteContainer className={styles.container}>
				<div className={styles.header}>
					<SvgIcon className={styles.zaibukas} src={zaibukasSvg} />
					<span className={styles.title}>
						{formatMessage(ReferralsMessages.profitSummary)}
					</span>
				</div>
				<div className={styles.content}>
					<Block title={formatMessage(ReferralsMessages.commissions)}>
						{data
							? `${amountFormatter(
									formatPrecision(data.commissions, CurrencyEnum.EUR)
							  )} €`
							: '-'}
					</Block>
					<Block title={formatMessage(ReferralsMessages.referrals)}>
						{' '}
						{data?.signups || '-'}
					</Block>
					<Block title={formatMessage(ReferralsMessages.cashbacks)}>
						{data
							? `${amountFormatter(
									formatPrecision(data.cashbacks, CurrencyEnum.EUR)
							  )} €`
							: '-'}
					</Block>
				</div>
			</WhiteContainer>
			<span className={styles.note}>
				{formatMessage(ReferralsMessages.profitSummaryNote, {
					link: (txt) => (
						<a
							className={styles.link}
							href={`${window.location.origin}${referralTerms}`}
						>
							{txt}
						</a>
					),
				})}
			</span>
		</div>
	);
};

export default ProfitSummary;
