/* eslint-disable consistent-return */
/* eslint-disable radix */
/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { formatPrecision } from '../currencyHelper/currencyHelper';
import {
	OperationType,
	TransactionStatus,
	TransactionType,
} from '../../redux/CardHistoryState/CardHistoryTypes';
import { CardItem, CardStatusType, CardTypeFromBE } from '../../redux/CardsState/CardsTypes';
import { RootState } from '../../redux/Store';
import statusMsg from '../../messages/status.messages';

const messages = defineMessages({
	TOPUP: {
		id: 'cardHistoryOperationType.TOPUP',
		defaultMessage: 'Card load',
	},
	TOP_UP_RHA: {
		id: 'cardHistoryOperationType.TOPUP',
		defaultMessage: 'Card load',
	},
	DEBT: {
		id: 'cardHistoryOperationType.DEBT',
		defaultMessage: 'Card purchase (Debt)',
	},
	WITHDRAWAL: {
		id: 'cardHistoryOperationType.WITHDRAWAL',
		defaultMessage: 'Card unload',
	},
	CARD_PURCHASE: {
		id: 'cardHistoryOperationType.CARD_PURCHASE',
		defaultMessage: 'Card purchase',
	},
	CARD_PURCHASE_REVERSAL: {
		id: 'cardHistoryOperationType.CARD_PURCHASE_REVERSAL',
		defaultMessage: 'Card purchase reversal',
	},
	CARD_PURCHASE_WITH_CASHBACK: {
		id: 'cardHistoryOperationType.CARD_PURCHASE_WITH_CASHBACK',
		defaultMessage: 'Card purchase with cashback',
	},
	CARD_PURCHASE_WITH_CASHBACK_REVERSAL: {
		id: 'cardHistoryOperationType.CARD_PURCHASE_WITH_CASHBACK_REVERSAL',
		defaultMessage: 'Card purchase with cashback reversal',
	},
	CARD_PURCHASE_UNIQUE: {
		id: 'cardHistoryOperationType.CARD_PURCHASE_UNIQUE',
		defaultMessage: 'Card purchase unique',
	},
	CARD_PURCHASE_UNIQUE_REVERSAL: {
		id: 'cardHistoryOperationType.CARD_PURCHASE_UNIQUE_REVERSAL',
		defaultMessage: 'Card purchase unique reversal',
	},
	CARD_REFUND: {
		id: 'cardHistoryOperationType.CARD_REFUND',
		defaultMessage: 'Card refund',
	},
	CARD_REFUND_REVERSAL: {
		id: 'cardHistoryOperationType.CARD_REFUND_REVERSAL',
		defaultMessage: 'Card refund reversal',
	},
	CARD_CASH_WITHDRAWAL: {
		id: 'cardHistoryOperationType.CARD_CASH_WITHDRAWAL',
		defaultMessage: 'Card cash withdrawal',
	},
	CARD_CASH_WITHDRAWAL_REVERSAL: {
		id: 'cardHistoryOperationType.CARD_CASH_WITHDRAWAL_REVERSAL',
		defaultMessage: 'Card cash withdrawal reversal',
	},
	CARD_CASH_WITHDRAWAL_POS: {
		id: 'cardHistoryOperationType.CARD_CASH_WITHDRAWAL_POS',
		defaultMessage: 'Card cash withdrawal at POS',
	},
	CARD_CASH_WITHDRAWAL_POS_REVERSAL: {
		id: 'cardHistoryOperationType.CARD_CASH_WITHDRAWAL_POS_REVERSAL',
		defaultMessage: 'Card cash withdrawal at POS reversal',
	},
	CARD_CASH_ADVANCE: {
		id: 'cardHistoryOperationType.CARD_CASH_ADVANCE',
		defaultMessage: 'Card cash withdrawal from credit limit',
	},
	CARD_CASH_ADVANCE_REVERSAL: {
		id: 'cardHistoryOperationType.CARD_CASH_ADVANCE_REVERSAL',
		defaultMessage: 'Card cash withdrawal from credit limit reversal',
	},
	CARD_CASH_IN: {
		id: 'cardHistoryOperationType.CARD_CASH_IN',
		defaultMessage: 'Card cash in',
	},
	CARD_CASH_IN_REVERSAL: {
		id: 'cardHistoryOperationType.CARD_CASH_IN_REVERSAL',
		defaultMessage: 'Card cash in reversal',
	},
	CARD_ADJUSTMENT_CREDIT: {
		id: 'cardHistoryOperationType.CARD_ADJUSTMENT_CREDIT',
		defaultMessage: 'Card adjustment credit',
	},
	CARD_ADJUSTMENT_CREDIT_REVERSAL: {
		id: 'cardHistoryOperationType.CARD_ADJUSTMENT_CREDIT_REVERSAL',
		defaultMessage: 'Card adjustment credit reversal',
	},
	CARD_ADJUSTMENT_DEBIT: {
		id: 'cardHistoryOperationType.CARD_ADJUSTMENT_DEBIT',
		defaultMessage: 'Card adjustment debit',
	},
	CARD_ADJUSTMENT_DEBIT_REVERSAL: {
		id: 'cardHistoryOperationType.CARD_ADJUSTMENT_DEBIT_REVERSAL',
		defaultMessage: 'Card adjustment debit reversal',
	},
	CARD_PAYMENT_CREDIT: {
		id: 'cardHistoryOperationType.CARD_PAYMENT_CREDIT',
		defaultMessage: 'Card Payment credit',
	},
	CARD_PAYMENT_CREDIT_REVERSAL: {
		id: 'cardHistoryOperationType.CARD_PAYMENT_CREDIT_REVERSAL',
		defaultMessage: 'Card Payment credit reversal',
	},
	CARD_ACCOUNT_FUNDING_TRANSACTION: {
		id: 'cardHistoryOperationType.CARD_ACCOUNT_FUNDING_TRANSACTION',
		defaultMessage: 'Card Account Funding transaction',
	},
	CARD_ACCOUNT_FUNDING_TRANSACTION_REVERSAL: {
		id: 'cardHistoryOperationType.CARD_ACCOUNT_FUNDING_TRANSACTION_REVERSAL',
		defaultMessage: 'Card Account Funding transaction reversal',
	},
	CARD_ACCOUNT_VERIFICATION: {
		id: 'cardHistoryOperationType.CARD_ACCOUNT_VERIFICATION',
		defaultMessage: 'Card Account Verification transaction',
	},
});

export const GetCurrentCard = () => {
	const { pathname } = useLocation();
	const { cardsList } = useSelector((state: RootState) => state.CardsState);
	const [card, setCard] = useState<CardItem | null>(null);
	const cardId: string = pathname.split('/')[pathname.split('/').length - 2];

	useEffect(() => {
		const currentCard = cardsList?.find((o: CardItem) => o.id === cardId);
		setCard(currentCard || null);
	}, [cardId, cardsList, pathname]);

	return card;
};

export const GetIsCardListAvailable = () => {
	const { locale } = useIntl();
	const { cardsList, isCardUser, companyMovement } = useSelector(
		(state: RootState) => state.CardsState
	);
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const [link, setLink] = useState<string | null>(null);

	useEffect(() => {
		if (companyMovement && user && isCardUser !== null) {
			if (companyMovement.available === false) {
				return setLink(`/${locale}/cards/order/verify`);
			}
			if (companyMovement.available === true) {
				if (!user.verified || !user.phoneVerified) {
					return setLink(`/${locale}/cards/order/verify`);
				}
				if (user.verified && user.phoneVerified && cardsList && cardsList?.length === 0) {
					return setLink(`/${locale}/cards/order`);
				}
				if (user.verified && user.phoneVerified && cardsList && cardsList.length > 0) {
					return setLink(`/${locale}/cards`);
				}
			}
		}
	}, [
		isCardUser,
		cardsList,
		locale,
		companyMovement?.available,
		companyMovement,
		user?.phoneVerified,
		user?.verified,
		user,
	]);

	return link;
};

export const isCardOnHold = (status: CardStatusType) => {
	return (
		status === CardStatusType.EXPIRED ||
		status === CardStatusType.BLOCKED ||
		status === CardStatusType.CLOSED ||
		status === CardStatusType.SUSPENDED
	);
};

export const isCardRha = (type: CardTypeFromBE) => {
	return (
		type === CardTypeFromBE.PERVESK_RHA_PLASTIC || type === CardTypeFromBE.PERVESK_RHA_VIRTUAL
	);
};

export const isCardFunctionsEnabled = (type: CardTypeFromBE) => {
	const validCardTypes = [
		CardTypeFromBE.PLASTIC,
		CardTypeFromBE.VIRTUAL,
		CardTypeFromBE.PERVESK_RHA_PLASTIC,
		CardTypeFromBE.PERVESK_RHA_VIRTUAL,
	];

	return validCardTypes.includes(type);
};

export const getAmountWithSign = (
	transactionAmount: string,
	transactionType: TransactionType,
	currency?: CurrencyEnum
) => {
	const debitTypes = [
		TransactionType.UNLOAD,
		TransactionType.PAY,
		TransactionType.ADMINISTRATION,
	];
	const sign = debitTypes.includes(transactionType) ? '-' : '+';
	const formattedAmount = formatPrecision(transactionAmount, CurrencyEnum.EUR);
	return `${sign}${formattedAmount} ${currency || ''}`;
};

export const getTranslatedCardStatus = (status: TransactionStatus) => {
	switch (status) {
		case TransactionStatus.FAILED:
		case TransactionStatus.REJECTED:
		case TransactionStatus.REMOVED:
		case TransactionStatus.CANCELED:
			return statusMsg.DECLINED;
		case TransactionStatus.PENDING:
		case TransactionStatus.INITIATED:
		case TransactionStatus.CONFIRMED:
		case TransactionStatus.PROCESSING:
		case TransactionStatus.WAITING:
		case TransactionStatus.VERIFICATION:
		case TransactionStatus.AUTHORIZED:
			return statusMsg.RESERVED;
		case TransactionStatus.COMPLETED:
			return statusMsg.COMPLETED;
		case TransactionStatus.REVERSED:
			return statusMsg.REVERSED;
		case TransactionStatus.SUSPENDED:
		default:
			return statusMsg.PENDING;
	}
};

export const getTranslatedCardOperationType = (type: OperationType) => {
	return messages[type];
};
