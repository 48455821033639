import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import MerchantCodeIcon from '../../../pages/Cards/Shared/MerchantCodeIcon/MerchantCodeIcon';
import SvgIcon from '../../SvgIcon/SvgIcon';
import { getTranslatedCardOperationType } from '../../../helpers/cardsHelper/cardsHelper';
import withdrawMessages from '../../../redux/WithdrawState/WithdrawMessages';
import { OperationType } from '../../../redux/CardHistoryState/CardHistoryTypes';
import toggleModal from '../../../redux/ModalState/ModalActions';
import transferIconPath from './images/transfer.svg';
import CardLoadIcon from './images/mcc_load.svg';
import CardUnloadIcon from './images/mcc_unload.svg';
import WithdrawIcon from './images/withdraw.svg';
import styles from './DetailsModal.module.scss';
import { formatPrecision, getBlockchainLink } from '../../../helpers/currencyHelper/currencyHelper';
import { toPlainAmount } from '../../../helpers/currencyAmountHelper/currencyAmountHelper';
import { isAmountGreaterThanZero } from '../../../helpers/inputValidation/inputValidation';
import convertToLocalTime from '../../../helpers/dateHelper/dateHelper';
import baseMsg from '../../../messages/base.messages';
import { formatTestId } from '../../../test/TestIds';
import WalletTestIds from '../../../test/Wallets/WalletTestIds';
import Button, { ButtonType } from '../../Button/Button';
import CopyButton from '../../CopyButton/CopyButton';
import { NetworkNameCode } from '../../../redux/AccountsState/AccountsTypes';
import Tooltip from '../../Tooltip/Tooltip';
import statusMsg from '../../../messages/status.messages';
import useFormatAmount from '../../../hooks/useFormatAmount';
import useCurrentWallet from '../../../hooks/useCurrentWallet';
import { getAccountNetwork } from '../../../redux/AccountsState/AccountsActions';

const messages = defineMessages({
	orderNumber: {
		id: 'detailsModal.orderNumber',
		defaultMessage: 'Order number',
	},
	receivedAmount: {
		id: 'detailsModal.receivedAmount',
		defaultMessage: 'Received amount',
	},
	receivedFrom: {
		id: 'detailsModal.receivedFrom',
		defaultMessage: 'Received from',
	},
	receivedTo: {
		id: 'detailsModal.receivedTo',
		defaultMessage: 'Received to',
	},
	transferDetails: {
		id: 'detailsModal.transferDetails',
		defaultMessage: 'Transfer details',
	},
	description: {
		id: 'detailsModal.description',
		defaultMessage: 'Description',
	},
	exchangeRate: {
		id: 'detailsModal.exchangeRate',
		defaultMessage: 'Exchange rate',
	},
	amount: {
		id: 'detailsModal.amount',
		defaultMessage: 'Amount',
	},
	reservedDate: {
		id: 'detailsModal.reservedDate',
		defaultMessage: 'Reserved on',
	},
	completedDate: {
		id: 'detailsModal.completedDate',
		defaultMessage: 'Completed on',
	},
	withdrawAccount: {
		id: 'detailsModal.withdrawAccount',
		defaultMessage: 'Withdraw account',
	},
	price: {
		id: 'detailsModal.price',
		defaultMessage: 'Price',
	},
	bankTitle: {
		id: 'history.bankWithdraw',
		defaultMessage: 'Bank withdrawal',
	},
	viewOnBlockchain: {
		id: 'history.viewOnBlockchain',
		defaultMessage: 'View on blockchain explorer',
	},
	infoCryptoContent: {
		id: 'history.infoCryptoContent',
		defaultMessage:
			'Transaction hash details can be viewed using a block explorer, which is a website where you can search the hash and see the ledger.',
	},
	sender: {
		id: 'history.sender',
		defaultMessage: 'Sender',
	},
	senderAccount: {
		id: 'history.senderAccount',
		defaultMessage: 'Sender account',
	},
	paymentDetails: {
		id: 'history.paymentDetails',
		defaultMessage: 'Payment details',
	},
	originalPaymentDetails: {
		id: 'history.originalPaymentDetails',
		defaultMessage: 'Original payment details',
	},
});

export enum DetailsModalType {
	WALLET = 'WALLET',
	CARD = 'CARD',
	FIAT = 'FIAT',
	CRYPTO = 'CRYPTO',
	CRYPTO_WITHDRAW = 'CRYPTO_WITHDRAW',
	EWALLET_WITHDRAW = 'EWALLET_WITHDRAW',
	GIFT = 'GIFT',
	TOPUP = 'TOPUP',
	BANK_WITHDRAW = 'BANK_WITHDRAW',
}

interface DetailsModalProps {
	code?: string;
	type: DetailsModalType;
	merchantName?: string | null;
	amount: number | string;
	receivedFrom?: string;
	receivedTo?: string;
	transactionAmount?: string | number | null;
	transactionCurrency?: CurrencyEnum | string | null;
	operationType?: OperationType | null;
	serviceType?: string | null;
	exchangeRate?: string | null;
	fee?: string | null;
	feeCurrency?: CurrencyEnum | null;
	completedDate?: string | null;
	reservedDate?: string | null;
	status?: string;
	id?: string;
	description?: string | null;
	date?: string;
	transactionName?: string | null;
	currencyCode?: CurrencyEnum;
	merchantCategoryCode?: string | null;
	withdrawAccount?: string;
	totalAmount?: string;
	sellAmount?: string;
	sellCurrencyCode?: CurrencyEnum;
	purchaseItem?: string;
	receiver?: string;
	other?: string;
	accountNumber?: string;
	swift?: string;
	sortCode?: string;
	receiverAddress?: string;
	network?: string;
	areFieldsCopyable?: boolean;
	isCryptoWithdraw?: boolean;
	withdrawId?: string;
	sender?: string;
	senderAccount?: string;
	paymentDetails?: string;
	originalPaymentAmount?: string;
	originalPaymentCurrency?: string;
	originalPaymentFee?: string;
	originalPaymentFeeCurrency?: string;
}

interface DetailFieldProps {
	label: MessageDescriptor;
	value: string | JSX.Element | null;
	isHTML?: boolean;
	isCopyable?: boolean;
	isCryptoWithdraw?: boolean;
}

export const DetailField = ({
	label,
	value = '',
	isHTML = false,
	isCopyable = false,
	isCryptoWithdraw = false,
	...rest
}: DetailFieldProps) => {
	const { formatMessage } = useIntl();
	return (
		<div {...rest}>
			<div id="label" className={styles.label}>
				{formatMessage(label)}
				{isCryptoWithdraw && (
					<Tooltip
						infoIconVisible
						content={
							<div className={styles.toolTipContainer}>
								<div className={styles.toggleToolTip}>
									{formatMessage(messages.infoCryptoContent)}
								</div>
								<div className={styles.toolTipBottomArrow} />
							</div>
						}
					/>
				)}
				{isCopyable && (
					<CopyButton
						dataToCopy={typeof value === 'string' ? value : ''}
						text={baseMsg.copy}
					/>
				)}
			</div>
			{isHTML ? (
				<div
					id="value"
					className={styles.value}
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: typeof value === 'string' ? value : '' }}
				/>
			) : (
				<div id="value" className={styles.value}>
					{value}
				</div>
			)}
		</div>
	);
};

const DetailsModal = ({
	withdrawId,
	code,
	type,
	merchantName,
	amount,
	transactionAmount,
	transactionCurrency,
	receivedFrom,
	receivedTo,
	operationType,
	serviceType,
	exchangeRate,
	fee,
	feeCurrency,
	completedDate,
	reservedDate,
	status,
	id,
	description,
	date,
	transactionName,
	currencyCode,
	merchantCategoryCode,
	withdrawAccount,
	totalAmount,
	sellAmount,
	sellCurrencyCode,
	purchaseItem,
	receiver,
	other,
	accountNumber,
	swift,
	sortCode,
	receiverAddress,
	network,
	sender,
	senderAccount,
	paymentDetails,
	originalPaymentAmount,
	originalPaymentCurrency,
	originalPaymentFee,
	originalPaymentFeeCurrency,
	areFieldsCopyable = false,
	isCryptoWithdraw = false,
}: DetailsModalProps) => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const amountFormatter = useFormatAmount();
	const currentWallet = useCurrentWallet(currencyCode);
	const [networkPublicName, setNetworkPublicName] = useState<string | undefined>(undefined);
	const [showOriginal, setShowOriginal] = useState(false);

	const renderDetails = (renderType: DetailsModalType) => {
		switch (renderType) {
			case DetailsModalType.WALLET:
				return (
					<>
						<div className={styles.header}>
							<img src={transferIconPath} alt="transfer icon" />
							<h2 className={styles.transactionName} data-cy="transactionName">
								{transactionName}
							</h2>
							<h4 className={styles.id}>{id}</h4>
							<h5 className={styles.date} data-cy="date">
								{date && convertToLocalTime(date, 'yyyy-MM-dd HH:mm:ss')}
							</h5>
							<h6 className={styles.status} data-cy="status">
								{status}
							</h6>
						</div>
						<div className={styles.content}>
							{id && (
								<DetailField
									label={baseMsg.transactionNumber}
									value={id}
									data-cy="transactionName"
								/>
							)}
							<DetailField
								label={messages.receivedAmount}
								value={`${amountFormatter(
									formatPrecision(
										amount ? toPlainAmount(amount.toString()) : '0',
										CurrencyEnum[currencyCode!]
									)
								)} ${currencyCode}`}
								data-cy={formatTestId(WalletTestIds.ReceivedAmount)}
							/>
							{description && (
								<DetailField
									label={messages.description}
									data-cy={formatTestId(WalletTestIds.Description)}
									value={description}
								/>
							)}
						</div>
					</>
				);
			case DetailsModalType.CRYPTO:
				return (
					<>
						<div className={styles.header}>
							<img src={transferIconPath} alt="transfer icon" />
							<h2 className={styles.transactionName} data-cy="transactionName">
								{transactionName}
							</h2>
							<h4 className={styles.id}>{id}</h4>
							<h5 className={styles.date} data-cy="date">
								{date && convertToLocalTime(date, 'yyyy-MM-dd HH:mm:ss')}
							</h5>
							<h6 className={styles.status} data-cy="status">
								{status}
							</h6>
						</div>
						<div className={styles.content}>
							{networkPublicName && (
								<DetailField label={baseMsg.network} value={networkPublicName} />
							)}
							{receivedTo && (
								<DetailField label={messages.receivedTo} value={receivedTo} />
							)}
							{receivedFrom && (
								<DetailField label={messages.receivedFrom} value={receivedFrom} />
							)}
							<DetailField
								label={messages.receivedAmount}
								value={`${amountFormatter(
									formatPrecision(amount!, CurrencyEnum[currencyCode!])
								)} ${currencyCode}`}
								data-cy="totalAmount"
							/>
							{description && (
								<DetailField
									label={messages.description}
									data-cy={formatTestId(WalletTestIds.Description)}
									value={description}
								/>
							)}
						</div>
					</>
				);
			case DetailsModalType.FIAT:
				return (
					<>
						<div className={styles.header}>
							<img src={transferIconPath} alt="transfer icon" />
							<h2 className={styles.transactionName} data-cy="transactionName">
								{transactionName}
							</h2>
							<h4 className={styles.id}>{id}</h4>
							<h5 className={styles.date} data-cy="date">
								{date && convertToLocalTime(date, 'yyyy-MM-dd HH:mm:ss')}
							</h5>
							<h6 className={styles.status} data-cy="status">
								{status}
							</h6>
						</div>
						<div className={styles.content}>
							<DetailField
								label={messages.amount}
								value={`${amount} ${currencyCode}`}
								data-cy="amount"
							/>
							<DetailField
								label={messages.receivedAmount}
								value={`${amountFormatter(
									formatPrecision(transactionAmount!, CurrencyEnum[currencyCode!])
								)} ${currencyCode}`}
								data-cy="receivedAmount"
							/>
							<DetailField
								label={baseMsg.fee}
								value={`${amountFormatter(
									formatPrecision(fee!, CurrencyEnum[currencyCode!])
								)} ${currencyCode}`}
								data-cy="fee"
							/>
							{sender && (
								<DetailField
									label={messages.sender}
									value={sender}
									data-cy="sender"
								/>
							)}
							{senderAccount && (
								<DetailField
									label={messages.senderAccount}
									value={senderAccount}
									data-cy="senderAccount"
								/>
							)}
							{paymentDetails && (
								<DetailField
									label={messages.paymentDetails}
									value={paymentDetails}
									data-cy="paymentDetails"
								/>
							)}
							{description && (
								<DetailField label={messages.description} value={description} />
							)}
							{(originalPaymentAmount || originalPaymentFee) && (
								<div
									className={classNames(styles.originalPaymentContainer, {
										[styles.expanded]: showOriginal,
									})}
								>
									<div
										onClick={() => setShowOriginal(!showOriginal)}
										className={styles.toggleContainer}
									>
										<h1>{formatMessage(messages.originalPaymentDetails)}</h1>
										<FontAwesomeIcon icon={faChevronUp} />
									</div>
									{showOriginal && (
										<div className={styles.originalPaymentDetails}>
											{originalPaymentAmount && (
												<DetailField
													label={messages.amount}
													value={`${amountFormatter(
														formatPrecision(
															originalPaymentAmount!,
															CurrencyEnum[originalPaymentCurrency!]
														)
													)} ${originalPaymentCurrency}`}
													data-cy="originalPaymentAmount"
												/>
											)}
											{originalPaymentFee && (
												<DetailField
													label={baseMsg.fee}
													value={`${amountFormatter(
														formatPrecision(
															originalPaymentFee!,
															CurrencyEnum[
																originalPaymentFeeCurrency!
															]
														)
													)} ${originalPaymentFeeCurrency}`}
													data-cy="originalPaymentFee"
												/>
											)}
										</div>
									)}
								</div>
							)}
						</div>
					</>
				);
			case DetailsModalType.CARD:
				return (
					<>
						<div className={styles.header}>
							<div className={styles.iconContainer}>
								{!merchantCategoryCode && (
									<SvgIcon
										style={{ width: 64, heigth: 64 }}
										src={
											amount.toString().indexOf('+') > -1
												? CardLoadIcon
												: CardUnloadIcon
										}
									/>
								)}
								{merchantCategoryCode && (
									<MerchantCodeIcon
										merchantCode={merchantCategoryCode || '0'}
										isIngoings={amount.toString().indexOf('+') > -1}
										size={64}
									/>
								)}
							</div>
							<h2 className={styles.transactionName} data-cy="transactionName">
								{merchantName && merchantName}
								{!merchantName &&
									operationType &&
									formatMessage(getTranslatedCardOperationType(operationType))}
								{!merchantName && !operationType && serviceType && serviceType}
							</h2>
							<h3
								className={classNames(styles.amount, {
									[styles.green]: amount.toString().indexOf('+') > -1,
								})}
							>{`${amount} ${currencyCode}`}</h3>
							{transactionAmount && transactionCurrency && (
								<h6
									className={styles.text}
								>{`${transactionAmount} ${transactionCurrency}`}</h6>
							)}
							<h6 className={styles.text}>
								{merchantName &&
									operationType &&
									formatMessage(getTranslatedCardOperationType(operationType))}
							</h6>
							<h6 className={styles.status} data-cy="status">
								{status}
							</h6>
						</div>
						<div className={styles.content}>
							{exchangeRate && transactionCurrency && (
								<DetailField
									label={messages.exchangeRate}
									value={`1 ${currencyCode} = ${exchangeRate} ${transactionCurrency}`}
								/>
							)}
							{fee && (
								<DetailField label={baseMsg.fee} value={`${fee} ${currencyCode}`} />
							)}
							<DetailField
								label={messages.amount}
								value={`${amount} ${currencyCode}`}
								data-cy="totalAmount"
							/>
							{reservedDate && (
								<DetailField
									label={messages.reservedDate}
									value={convertToLocalTime(reservedDate, 'yyyy-MM-dd HH:mm:ss')}
								/>
							)}
							{completedDate && (
								<DetailField
									label={messages.completedDate}
									value={convertToLocalTime(completedDate, 'yyyy-MM-dd HH:mm:ss')}
								/>
							)}
							{description && (
								<DetailField label={messages.description} value={description} />
							)}
						</div>
					</>
				);
			case DetailsModalType.CRYPTO_WITHDRAW:
			case DetailsModalType.GIFT:
			case DetailsModalType.TOPUP:
			case DetailsModalType.EWALLET_WITHDRAW:
			case DetailsModalType.BANK_WITHDRAW: {
				const networkName = currencyCode === CurrencyEnum.AVAX ? currencyCode : network!;
				return (
					<div className={styles.header}>
						<SvgIcon style={{ width: 96, heigth: 96 }} src={WithdrawIcon} />
						<h2 className={styles.transactionName} data-cy="transactionName">
							{renderType === DetailsModalType.BANK_WITHDRAW
								? formatMessage(messages.bankTitle)
								: transactionName}
						</h2>
						<h5 className={styles.date} data-cy="date">
							{date && convertToLocalTime(date, 'yyyy-MM-dd HH:mm:ss')}
						</h5>
						<h6 className={styles.status} data-cy="status">
							{status}
						</h6>
						<div className={styles.content}>
							{code && (
								<DetailField
									label={
										renderType === DetailsModalType.GIFT
											? withdrawMessages.giftCode
											: withdrawMessages.voucherCode
									}
									value={code}
									data-cy="code"
								/>
							)}
							{withdrawId && <DetailField label={baseMsg.id} value={withdrawId} />}
							{(id ||
								![
									formatMessage(statusMsg.PAID),
									formatMessage(statusMsg.FAILED),
								].includes(status ?? formatMessage(statusMsg.PAID))) && (
								<DetailField
									label={
										renderType === DetailsModalType.TOPUP
											? baseMsg.purchaseOrderNumber
											: baseMsg.transactionNumber
									}
									isCopyable={areFieldsCopyable}
									isCryptoWithdraw={isCryptoWithdraw}
									value={id || formatMessage(statusMsg.PENDING)}
								/>
							)}
							{networkPublicName &&
								(id ||
									![
										formatMessage(statusMsg.PAID),
										formatMessage(statusMsg.FAILED),
									].includes(status ?? formatMessage(statusMsg.PAID))) && (
									<DetailField
										label={baseMsg.network}
										value={networkPublicName}
									/>
								)}
							{accountNumber && (
								<DetailField
									label={withdrawMessages.accountLabel}
									value={accountNumber}
								/>
							)}
							{sortCode && (
								<DetailField
									label={withdrawMessages.sortCodeLabel}
									value={sortCode}
								/>
							)}
							{swift && (
								<DetailField label={withdrawMessages.swiftLabel} value={swift} />
							)}
							{receiverAddress && (
								<DetailField
									label={withdrawMessages.receiverBankAddressLabel}
									value={receiverAddress}
								/>
							)}
							{receiver && <DetailField label={baseMsg.receiver} value={receiver} />}
							{withdrawAccount && (
								<DetailField
									label={
										renderType === DetailsModalType.TOPUP
											? baseMsg.receiver
											: messages.withdrawAccount
									}
									isHTML
									value={withdrawAccount.split(',').join(',<br/>')}
									isCopyable={areFieldsCopyable}
								/>
							)}
							<DetailField
								label={
									[DetailsModalType.GIFT, DetailsModalType.TOPUP].includes(
										renderType
									)
										? messages.price
										: messages.receivedAmount
								}
								value={`${amountFormatter(
									formatPrecision(amount!, CurrencyEnum[currencyCode!])
								)} ${currencyCode}`}
								data-cy="receivedAmount"
							/>
							{fee && isAmountGreaterThanZero(fee) && (
								<DetailField
									label={baseMsg.fee}
									value={`${amountFormatter(
										formatPrecision(fee, CurrencyEnum[feeCurrency!])
									)} ${feeCurrency}`}
								/>
							)}
							{sellAmount && (
								<DetailField
									label={baseMsg.cardAmount}
									value={`${amountFormatter(
										formatPrecision(sellAmount, CurrencyEnum[sellCurrencyCode!])
									)} ${sellCurrencyCode}`}
								/>
							)}
							{purchaseItem && (
								<DetailField label={baseMsg.purchaseItem} value={purchaseItem} />
							)}
							{totalAmount && (
								<DetailField
									label={baseMsg.totalAmountWithFee}
									value={`${amountFormatter(
										formatPrecision(totalAmount, currencyCode || null)
									)} ${currencyCode}`}
									data-cy="totalAmount"
								/>
							)}
							{description && (
								<DetailField label={baseMsg.memo} value={description} />
							)}
							{other && (
								<DetailField label={withdrawMessages.details} value={other} />
							)}
							{isCryptoWithdraw &&
								networkPublicName &&
								currencyCode &&
								(id ||
									![
										formatMessage(statusMsg.PAID),
										formatMessage(statusMsg.FAILED),
									].includes(status ?? formatMessage(statusMsg.PAID))) && (
									<div className={styles.block}>
										<Button
											text={messages.viewOnBlockchain}
											type={ButtonType.ANCHOR_LINK}
											target="__blank"
											link={getBlockchainLink(
												NetworkNameCode[networkName],
												CurrencyEnum[currencyCode],
												id ?? ''
											)}
											isDisabled={!id}
											className={styles.btn}
										/>
									</div>
								)}
						</div>
					</div>
				);
			}
			default:
				return null;
		}
	};

	useEffect(() => {
		if (
			currentWallet &&
			!currentWallet?.networkList &&
			[DetailsModalType.CRYPTO_WITHDRAW, DetailsModalType.CRYPTO].includes(type)
		)
			dispatch(getAccountNetwork(currentWallet?.currencyCode, currentWallet?.id));
	}, [currencyCode, currentWallet, dispatch, type]);

	useEffect(() => {
		if (currentWallet?.networkList)
			setNetworkPublicName(
				currentWallet?.networkList?.find(({ networkName }) => networkName === network)
					?.publicName
			);
	}, [currentWallet?.networkList, network]);

	return (
		<div className={styles.container}>
			<div
				className={styles.closeIcon}
				onClick={() => dispatch(toggleModal())}
				data-cy="closeModal"
			/>
			{renderDetails(type)}
		</div>
	);
};

export default DetailsModal;
