export enum UserPreviousPageForCompanyMovement {
	PAYMENT_METHOD = 'PAYMENT_METHOD',
	VERIFICATION = 'VERIFICATION',
	WITHDRAW = 'WITHDRAW',
	LINKED_CARDS = 'LINKED_CARDS',
	CLAIM_IBAN = 'CLAIM_IBAN',
	ORDER_DEBIT_CARD = 'ORDER_DEBIT_CARD',
}

enum UserContractCompany {
	LT = 'LITHUANIA',
	EE = 'ESTONIA',
	SF_BVI = 'SF_BVI',
	SF_SYSTEMS_BVI = 'SF_SYSTEMS_BVI',
	BNK_SYSTEMS_BVI = 'BNK_SYSTEMS_BVI',
	BNK_OPERATIONS_BVI = 'BNK_OPERATIONS_BVI',
	BNK_SOLUTIONS_BVI = 'BNK_SOLUTIONS_BVI',
}

export default UserContractCompany;
