import { useIntl } from 'react-intl';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import { PurchaseSuccessResponse } from '../../../../redux/WithdrawState/WithdrawTypes';
import baseMsg from '../../../../messages/base.messages';
import convertToLocalTime from '../../../../helpers/dateHelper/dateHelper';
import useFormatAmount from '../../../../hooks/useFormatAmount';

interface GiftCardsSummaryProps {
	data: PurchaseSuccessResponse[];
	titleClass?: string;
	dateClass?: string;
	infoClass?: string;
}

const GiftCardsSummary = ({ data, dateClass, infoClass, titleClass }: GiftCardsSummaryProps) => {
	const { formatMessage } = useIntl();
	const amountFormatter = useFormatAmount();

	return (
		<>
			{data.map(({ date, receiver, itemTitle, payAmount, payCurrencyCode }: any) => {
				return (
					<li key={receiver}>
						{itemTitle && <h3 className={titleClass}>{itemTitle}</h3>}
						{date && (
							<div className={dateClass}>
								{convertToLocalTime(date, 'yyyy-MM-dd HH:mm:ss')}
							</div>
						)}
						{receiver && (
							<InfoInput
								label={formatMessage(baseMsg.receiver)}
								text={receiver}
								className={infoClass}
							/>
						)}
						{payAmount && (
							<InfoInput
								label={formatMessage(baseMsg.payAmount)}
								text={`${amountFormatter(
									formatPrecision(payAmount, payCurrencyCode)
								)} ${payCurrencyCode}`}
								className={infoClass}
							/>
						)}
					</li>
				);
			})}
		</>
	);
};

export default GiftCardsSummary;
