import { FC } from 'react';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { DocumentItem } from '../../../../../redux/DocumentsHistoryState/DocumentsHistoryTypes';
import { getUserFilesURL } from '../../../../../redux/endpoints';
import DocumentsModal from '../DocumentsModal/DocumentsModal';
import toggleModal from '../../../../../redux/ModalState/ModalActions';
import styles from './DocumentsItem.module.scss';

const DocumentsItem: FC<DocumentItem> = ({ date, id, name }) => {
	const dispatch = useDispatch();

	const onClick = () => dispatch(toggleModal(<DocumentsModal id={id} />, true, true));

	return (
		<li className={styles.item}>
			<div>
				<div className={styles.name}>{name}</div>
				<div className={styles.text}>
					<FormattedMessage
						id="documents.documentsInfo"
						defaultMessage="You can now download the requested data regarding personal information gathered to provide SpectroCoin services for you. - {date}"
						values={{ date: format(new Date(date), 'yyyy-MM-dd HH:mm:ss') }}
					/>
				</div>
			</div>
			<ul className={styles.buttonList}>
				<li>
					<a href={getUserFilesURL({ id })}>
						<FormattedMessage id="merchants.download" defaultMessage="Download" />
					</a>
				</li>
				<li onClick={() => onClick()}>
					<FormattedMessage id="documents.delete" defaultMessage="Delete" />
				</li>
			</ul>
		</li>
	);
};

export default DocumentsItem;
