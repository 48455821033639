/* eslint-disable import/no-dynamic-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import {
	ProfileActionType,
	SetProfileAction,
	SetCompanyAction,
	SetIsLoggedInAction,
	InitialState,
	SetIsSCAAction,
	ResetIdleTimerAction,
	SetVerificationStatus,
} from './ProfileTypes';

const scaIdleTime: number = require(`../../../env/config.${
	process.env.REACT_APP_ENV === 'local' ? 'local' : 'prod'
}.js`).SCA_IDLE_TIMER;

const nonSCAIdleTime: number = require(`../../../env/config.${
	process.env.REACT_APP_ENV === 'local' ? 'local' : 'prod'
}.js`).NON_SCA_IDLE_TIMER;

export const initialState: InitialState = {
	user: null,
	company: null,
	isLoggedIn: false,
	idleTimer: scaIdleTime,
	isSCA: false,
};

export default (
	state = initialState,
	action:
		| SetProfileAction
		| SetCompanyAction
		| SetIsLoggedInAction
		| SetIsSCAAction
		| ResetIdleTimerAction
		| SetVerificationStatus
) => {
	switch (action.type) {
		case ProfileActionType.SET_COMPANY:
			return {
				...state,
				company: action.data,
			};
		case ProfileActionType.SET_PROFILE:
			return {
				...state,
				user: {
					...state.user,
					...action.data,
				},
			};
		case ProfileActionType.SET_IS_LOGGED_IN:
			return {
				...state,
				isLoggedIn: action.data,
			};
		case ProfileActionType.SET_IS_SCA:
			return {
				...state,
				isSCA: action.data,
			};
		case ProfileActionType.RESET_IDLE_TIMER:
			return {
				...state,
				idleTimer: state.isSCA
					? new Date(new Date().getTime() + scaIdleTime).getTime()
					: new Date(new Date().getTime() + nonSCAIdleTime).getTime(),
			};
		case ProfileActionType.SET_VERIFICATION_STATUS:
			return {
				...state,
				user: {
					...state.user,
					verificationStatus: action.data,
				},
			};
		default:
			return state;
	}
};
