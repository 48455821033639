import { AppDispatch } from '../Store';
import {
	DocumentsHistoryType,
	SetDocumentsHistoryDataAction,
	ResetDocumentsHistoryAction,
} from './DocumentsHistoryTypes';
import { getUserFilesURL } from '../endpoints';
import axiosInstance from '../../helpers/axiosInstance';

const setIsLoading = (isLoading: boolean) => ({
	type: DocumentsHistoryType.IS_LOADING,
	isLoading,
});

export const getDocumentsHistory = (page?: number) => (dispatch: AppDispatch) => {
	dispatch(setIsLoading(true));
	void axiosInstance
		.get(getUserFilesURL({ page }))
		.then(({ data }) => {
			const { content, totalElements } = data;
			dispatch<SetDocumentsHistoryDataAction>({
				type: DocumentsHistoryType.SET_DATA,
				data: content.sort((a: any, b: any) => {
					if (new Date(a.date) > new Date(b.date)) return -1;
					return 1;
				}),
				totalCount: totalElements,
			});
		})
		.catch((e) => console.log(`${e} happened`))
		.then(() => dispatch(setIsLoading(false)));
};

export const resetDocumentsHistory = () => (dispatch: AppDispatch) =>
	dispatch<ResetDocumentsHistoryAction>({ type: DocumentsHistoryType.RESET_DATA });

export const removeDocumentItem = (data: string) => (dispatch: AppDispatch) =>
	dispatch({
		type: DocumentsHistoryType.DELETE_DOCUMENT,
		data,
	});
