import { AxiosError } from 'axios';
import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RemoteError } from '../../../../interfaces/RemoteData';
import PageableQuery from '../../../../interfaces/PageableQuery';
import type { RootState } from '../../../Store';
import PageableResponse from '../../../../interfaces/PageableResponse';
import { getMerchantsOrdersUrl } from '../../../endpoints';
import {
	OrdersHistoryFetchOrdersFulfilledAction,
	OrdersHistoryFetchOrdersPendingAction,
	OrdersHistoryFetchOrdersRejectedAction,
	OrdersHistoryActionType,
} from './OrdersHistoryTypes';
import { Order } from '../OrdersTypes';
import axiosInstance from '../../../../helpers/axiosInstance';

const fetchOrdersPending: ActionCreator<OrdersHistoryFetchOrdersPendingAction> = () => ({
	type: OrdersHistoryActionType.FETCH_ORDERS_PENDING,
});

const fetchOrdersRejected: ActionCreator<OrdersHistoryFetchOrdersRejectedAction> = (
	error?: RemoteError
) => ({
	type: OrdersHistoryActionType.FETCH_ORDERS_REJECTED,
	payload: error,
});

const fetchOrdersFulfilled: ActionCreator<OrdersHistoryFetchOrdersFulfilledAction> = (
	orders: PageableResponse<Order[]>
) => ({
	type: OrdersHistoryActionType.FETCH_ORDERS_FULFILLED,
	payload: orders,
});

const fetchOrders = (
	projectId: string,
	query: PageableQuery<Order>
): ThunkAction<Promise<PageableResponse<Order[]> | void>, RootState, undefined, any> => async (
	dispatch
) => {
	dispatch(fetchOrdersPending());

	return axiosInstance
		.get<PageableResponse<Order[]>>(getMerchantsOrdersUrl(), {
			params: {
				merchantProjectId: projectId,
				...query,
				sort: 'created,desc',
				size: 10,
			},
		})
		.then(({ data }) => {
			dispatch(fetchOrdersFulfilled(data));
			return data;
		})
		.catch((e: AxiosError) => {
			dispatch(fetchOrdersRejected(e.response?.data));
		});
};

export default {
	fetchOrders,
};
