import { defineMessages } from 'react-intl';

export default defineMessages({
	metaTitle: {
		id: 'referrals.metaTitle',
		defaultMessage: 'Referrals',
	},
	termsAndConditions: {
		id: 'referrals.terms_and_conditions',
		defaultMessage: 'Referral Program Terms and Conditions',
	},
	terms: {
		id: 'referrals.terms',
		defaultMessage:
			'Welcome to our Referral Program. Before proceeding, please review the <link>Terms and Conditions</link> that govern your participation in this program. \n\nBy continuing, you are agreeing to these terms, which outline the guidelines, incentives, and obligations of our Referral Program. Your participation benefits both you and us by fostering collective growth. \n\nWe value your trust and strive to provide complete information. To acknowledge your acceptance of these terms, please click the “Confirm” button below.',
	},
	viewTerms: {
		id: 'referrals.view_terms',
		defaultMessage: 'View Terms and Conditions',
	},
	referralProgram: {
		id: 'referrals.referral_program',
		defaultMessage: 'Referral program',
	},
	earnUpTo20: {
		id: 'referrals.earn_up_to_20',
		defaultMessage: 'Earn up to 20%',
	},
	recommentSC: {
		id: 'referrals.recomment_sc',
		defaultMessage: 'Recommend SpectroCoin to your friends and earn rewards',
	},
	inviteFriends: {
		id: 'referrals.invite_friends',
		defaultMessage: 'Invite friends',
	},
	scMediaKit: {
		id: 'referrals.sc_media_kit',
		defaultMessage: 'SpectroCoin Media Kit',
	},
	commissions: {
		id: 'referrals.commissions',
		defaultMessage: 'Commissions',
	},
	referrals: {
		id: 'referrals.referrals',
		defaultMessage: 'Referrals',
	},
	cashbacks: {
		id: 'referrals.cashbacks',
		defaultMessage: 'Cashbacks',
	},
	profitSummary: {
		id: 'referrals.profit_summary',
		defaultMessage: 'Profit summary',
	},
	profitSummaryNote: {
		id: 'referrals.profit_summary_note',
		defaultMessage: 'Referral program terms and conditions can be found <link>here</link>.',
	},
	inviteFriendsInfo: {
		id: 'referrals.invite_friends_info',
		defaultMessage:
			'Send invitations to your friends via email and earn up to 20% from all fees paid by your referrals.',
	},
	sendNow: {
		id: 'referrals.send_now',
		defaultMessage: 'Send now',
	},
	bulkInvites: {
		id: 'referrals.bulk_invites',
		defaultMessage: 'Bulk invites',
	},
	invalidEmails: {
		id: 'referrals.invalid_emails',
		defaultMessage: 'Invalid emails found',
	},
	defaultLink: {
		id: 'referrals.your_default_link',
		defaultMessage: 'Your default referral link',
	},
	viewRefIds: {
		id: 'referrals.view_ref_ids',
		defaultMessage: 'View all referral IDs',
	},
	youReceive: {
		id: 'referrals.you_receive',
		defaultMessage: 'You receive',
	},
	friendsReceive: {
		id: 'referrals.friends_receive',
		defaultMessage: 'Friends receive',
	},
	refId: {
		id: 'referrals.ref_id',
		defaultMessage: 'Referral ID',
	},
	refLink: {
		id: 'referrals.ref_link',
		defaultMessage: 'Referral link',
	},
	shareOn: {
		id: 'referrals.share_on',
		defaultMessage: 'Share on',
	},
	inviteFriendsWithTrackId: {
		id: 'referrals.invite_friends_with_track_id',
		defaultMessage: 'Invite and track friends with your tracking ID',
	},
	trackingInfo: {
		id: 'referrals.tracking_info',
		defaultMessage:
			'Easily customize your referral link by setting up your track IDs. You can run multiple referral campaigns on websites or forums and track different referral sources.',
	},
	customizeTrackId: {
		id: 'referrals.customize_track_id',
		defaultMessage: 'Customize tracking ID',
	},
	viewCallbacks: {
		id: 'referrals.view_callbacks',
		defaultMessage: 'View referral callbacks',
	},
	copyClose: {
		id: 'referrals.copy_close',
		defaultMessage: 'Copy & Close',
	},
	setTrackId: {
		id: 'referrals.set_track_id',
		defaultMessage: 'Set your tracking ID',
	},
	trackingId: {
		id: 'referrals.tracking_id',
		defaultMessage: 'Tracking ID',
	},
	linkWithTrackId: {
		id: 'referrals.link_with_track_id',
		defaultMessage: 'Referral link with your tracking ID',
	},
	refSummary: {
		id: 'referrals.ref_summary',
		defaultMessage: 'Referral sumarry',
	},
	refSummeryNote: {
		id: 'referrals.ref_summery_note',
		defaultMessage:
			'Referral commission payouts are processed on a daily basis, with a minimum payout threshold of 1 EUR.',
	},
	email: {
		id: 'offchain.email',
		defaultMessage: 'Email',
	},
	trackId: {
		id: 'referrals.track_id',
		defaultMessage: 'Track ID',
	},
	commissionsCount: {
		id: 'referrals.commissions_count',
		defaultMessage: 'Commissions count',
	},
	totalCommissions: {
		id: 'referrals.total_commissions',
		defaultMessage: 'Total commissions',
	},
	notReferralsYet: {
		id: 'referrals.not_referrals_yet',
		defaultMessage: 'You do not have any referrals yet.',
	},
	notCallbacksYet: {
		id: 'referrals.not_callbacks_yet',
		defaultMessage: 'You do not have any callbacks yet.',
	},
	yourCallbacks: {
		id: 'referrals.your_callbacks',
		defaultMessage: 'Your referral callbacks',
	},
	yourCallbacksNote: {
		id: 'referrals.your_callbacks_note',
		defaultMessage:
			'SpectroCoin allows to provide callback URL and return callbacks to get information about new users, who registered via your referral link.',
	},
	statusCode: {
		id: 'referrals.status_code',
		defaultMessage: 'Status code',
	},
	statusCodeTooltip: {
		id: 'referrals.status_code_tooltip',
		defaultMessage:
			'<b>200</b> - Ok\n<b>400</b> - Bad Request\n<b>404</b> - Not Found\n<b>500</b> - Internal Server Error',
	},
	date: {
		id: 'merchants.date',
		defaultMessage: 'Date',
	},
	editCallbackUrl: {
		id: 'referrals.edit_callback_url',
		defaultMessage: 'Edit Callback URL',
	},
	yourRefIds: {
		id: 'referrals.your_ref_ids',
		defaultMessage: 'Your referral IDs',
	},
	yourRefIdsNode: {
		id: 'referrals.your_ref_ids_note',
		defaultMessage:
			'All of your generated referral IDs remain active indefinitely. You can always generate new IDs for your needs, but please note that the maximum number of referral IDs is limited to 20.',
	},
	createNew: {
		id: 'referrals.create_new',
		defaultMessage: 'Create new',
	},
	setAsDefault: {
		id: 'referrals.set_as_default',
		defaultMessage: 'Set as Default',
	},
	friends: {
		id: 'referrals.friends',
		defaultMessage: 'Friends',
	},
	friend: {
		id: 'referrals.friend',
		defaultMessage: 'Friend',
	},
	note: {
		id: 'withdraw.note',
		defaultMessage: 'Note',
	},
	action: {
		id: 'referrals.action',
		defaultMessage: 'Action',
	},
	default: {
		id: 'referrals.default',
		defaultMessage: 'Default',
	},
	createNewRefLink: {
		id: 'referrals.create_new_ref_link',
		defaultMessage: 'Create new referral link',
	},
	notToSelf: {
		id: 'referrals.not_to_self',
		defaultMessage: 'Enter note for yourself',
	},
	splitRatio: {
		id: 'referrals.split_ratio',
		defaultMessage: 'Set commission split ratio',
	},
	mine: {
		id: 'referrals.mine',
		defaultMessage: 'Mine',
	},
	defaultRefLink: {
		id: 'referrals.default_ref_link',
		defaultMessage: 'Default referral link',
	},
	createNewLink: {
		id: 'referrals.create_new_link',
		defaultMessage: 'Create new link',
	},
	linksRemaining: {
		id: 'referrals.links_remaining',
		defaultMessage: 'Links remaining',
	},
	empty: {
		id: 'manageWallets.empty',
		defaultMessage: 'Empty',
	},
	setRefCallbacks: {
		id: 'referrals.set_ref_callbacks',
		defaultMessage: 'Set referral callbacks',
	},
	refCallbacksNote: {
		id: 'referrals.ref_callbacks_note',
		defaultMessage:
			'Enter the URL address to receive data on newly registered referrals. Get more information about referral callbacks <Link>here</Link>.',
	},
	callbackUrl: {
		id: 'referrals.callback_url',
		defaultMessage: 'Callback URL',
	},
	callbackSecret: {
		id: 'referrals.callback_secret',
		defaultMessage: 'Callback secret key',
	},
	setCallbacks: {
		id: 'referrals.set_callbacks',
		defaultMessage: 'Set callbacks',
	},
});
