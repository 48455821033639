/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import { useState, useEffect, useCallback, useRef } from 'react';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, FormattedMessage, defineMessages } from 'react-intl';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { CurrencyEnum, currencyUtils } from '@spectrocoin/sc-currencies';
import baseMsg from '../../messages/base.messages';
import {
	toggleCurrentRates,
	toggleCurrentRatesGraph,
	toggleWalletSidebar,
	toggleMarketingSlider,
	setRateAmount,
} from '../../redux/AppState/AppActions';
import Input from '../Input/Input';
import { toDecimal } from '../../helpers/currencyHelper/currencyHelper';
import {
	getAccountTypesURL,
	getAddNewAccountURL,
	getHideIfEmptyURL,
	getHiddenBalanceURL,
} from '../../redux/endpoints';
import { RootState } from '../../redux/Store';
import { addNewWallet, getAccounts } from '../../redux/AccountsState/AccountsActions';
import { Wallet } from '../../redux/AccountsState/AccountsTypes';
import noTransactionsImgPath from '../../images/empty.svg';
import Loader from '../Loader/Loader';
import sortArrowsImgPath from '../../images/sort_arrows.svg';
import StartingBlock from '../StartingBlock/StartingBlock';
import SingleWallet from '../SingleWallet/SingleWallet';
import useEffectOnce from '../../hooks/useEffectOnce';
import TestIds from '../../test/TestIds';
import { RateAmount } from '../../redux/AppState/AppTypes';
import GestureDrawer, { WidthType } from '../GestureDrawer/GestureDrawer';
import styles from './WalletBlocks.module.scss';
import axiosInstance from '../../helpers/axiosInstance';

const nonAlphaNumericRegex = /[\W_]+/g;

const messages = defineMessages({
	showEmptyWallets: {
		id: 'walletBlocks.showEmptyWallets',
		defaultMessage: 'Show empty wallets',
	},
	hideEmptyWallets: {
		id: 'walletBlocks.hideEmptyWallets',
		defaultMessage: 'Hide empty wallets',
	},
	name: {
		id: 'walletBlock.name',
		defaultMessage: 'Name',
	},
	balance: {
		id: 'walletBlock.balance',
		defaultMessage: 'Balance',
	},
	addWallets: {
		id: 'walletBlock.addNewWallet',
		defaultMessage: 'Add wallet',
	},
	hideBalance: {
		id: 'walletBlock.hideBalance',
		defaultMessage: 'Hide balance',
	},
	showBalance: {
		id: 'walletBlock.showBalance',
		defaultMessage: 'Show balance',
	},
	hideIfEmpty: {
		id: 'walletBlock.hideIfEmpty',
		defaultMessage: 'Hide if empty',
	},
	showIfEmpty: {
		id: 'walletBlock.showEmpty',
		defaultMessage: 'Show empty',
	},
	title: {
		id: 'manageWallets.title',
		defaultMessage: 'Manage wallets',
	},
	filterTitle: {
		id: 'manageWallets.filterTitle',
		defaultMessage: 'Filter by Keywords',
	},
	fiat: {
		id: 'manageWallets.fiat',
		defaultMessage: 'Fiat',
	},
	crypto: {
		id: 'manageWallets.crypto',
		defaultMessage: 'Crypto',
	},
	erc20: {
		id: 'manageWallets.erc20',
		defaultMessage: 'ERC 20',
	},
	new: {
		id: 'manageWallets.new',
		defaultMessage: 'New',
	},
	hidden: {
		id: 'manageWallets.hidden',
		defaultMessage: 'Hidden',
	},
	hideLabel: {
		id: 'manageWallets.hideLabel',
		defaultMessage: 'Hide if empty',
	},
	hideInfo: {
		id: 'manageWallets.hideInfo',
		defaultMessage:
			'Please note that this option will only hide your empty wallets in your portfolio page.',
	},
	empty: {
		id: 'manageWallets.empty',
		defaultMessage: 'Empty',
	},
	back: {
		id: 'history.backToPortfolio',
		defaultMessage: 'Back to Portfolio',
	},
	metaTitle: {
		id: 'manageWallets.metaTitle',
		defaultMessage: 'Manage wallets',
	},
	changeTitle: {
		id: 'manageWallets.changeTitle',
		defaultMessage: 'Price and {change} change',
	},
	priceChange: {
		id: 'walletBlock.priceChange',
		defaultMessage: 'Price',
	},
	emptyList: {
		id: 'walletBlock.emptyList',
		defaultMessage: 'No wallets found, that match your inquiry!',
	},
	all: {
		id: 'walletBlock.all',
		defaultMessage: 'All assets',
	},
});

export enum MobileCol {
	PRICE = 'PRICE',
	BALANCE = 'BALANCE',
}

enum KeywordType {
	FIAT = 'fiat',
	CRYPTO = 'crypto',
	ERC20 = 'erc20',
	NEW = 'new',
	HIDDEN = 'hidden',
	EMPTY = 'empty',
}

enum SortType {
	NAME = 'NAME',
	BALANCE = 'BALANCE',
	PRICE = 'PRICE',
	RATE = 'RATE',
}

const ratePropNames = {
	[RateAmount.DAY]: 'last24HChange',
	[RateAmount.MONTH]: 'lastMonthChange',
	[RateAmount.WEEK]: 'lastWeekChange',
};

const filterOptions = [
	{
		label: messages.all,
		value: '',
	},
	{
		label: messages.fiat,
		value: KeywordType.FIAT,
	},
	{
		label: messages.erc20,
		value: KeywordType.ERC20,
	},
	{
		label: messages.new,
		value: KeywordType.NEW,
	},
	{
		label: messages.crypto,
		value: KeywordType.CRYPTO,
	},
];

const rateOptions = [
	{
		label: '24h%',
		value: RateAmount.DAY,
	},
	{
		label: '7d%',
		value: RateAmount.WEEK,
	},
	{
		label: '30d%',
		value: RateAmount.MONTH,
	},
];

const colOptions = [
	{
		label: messages.balance,
		value: MobileCol.BALANCE,
	},
	{
		label: messages.priceChange,
		value: MobileCol.PRICE,
	},
];

interface WalletBlocksProps {
	refCurrency: CurrencyEnum | string;
	isHeaderVisible?: boolean;
	className?: string;
	disabledLinks?: boolean;
	isToggleVisible?: boolean;
	isEmptyTabVisible?: boolean;
	isFilterBarVisible?: boolean;
	isRatesVisible?: boolean;
	nameFilter?: string;
	keywordFilter?: KeywordType[] | null;
	isStartingBlockVisible?: boolean;
	isAddNewWalletsVisible?: boolean;
	smallerContainer?: boolean;
	linkSmaller?: boolean;
	buyButton?: boolean;
	isActionsVisible?: boolean;
}

const WalletBlocks = ({
	refCurrency,
	isHeaderVisible = false,
	className,
	disabledLinks = false,
	isToggleVisible = false,
	isFilterBarVisible = false,
	isAddNewWalletsVisible = false,
	isRatesVisible = false,
	isStartingBlockVisible = false,
	smallerContainer = false,
	linkSmaller = false,
	buyButton = false,
	isActionsVisible: showActions = false,
}: WalletBlocksProps) => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const accounts = useSelector((state: RootState) => state?.AccountsState?.wallets);
	const { rates, ratesDetails } = useSelector((state: RootState) => state?.RatesState);
	const { isMobile, rateAmount } = useSelector((state: RootState) => state.AppState);
	const timeoutRef = useRef<NodeJS.Timeout>();
	const [mobileColValue, setMobileColValue] = useState(MobileCol.BALANCE);
	const [filter, setFilter] = useState(filterOptions[0]);
	const [inputValue, setInputValue] = useState('');
	const [isWalletsHidden, setIsWalletsHidden] = useState<boolean>(
		accounts?.length ? accounts[0].hideIfEmpty : false
	);
	const [sortType, setSortType] = useState<SortType | null>(null);
	const [sortAsc, setSortAsc] = useState(true);
	const [sortOtherType, setSortOtherType] = useState<SortType | null>(SortType.NAME);
	const [sortOtherAsc, setSortOtherAsc] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [isBalanceHidden, setHiddenBalance] = useState(accounts?.[0]?.hideBalance ?? false);
	const [otherWallets, setOtherWallets] = useState([]);

	const changeSortType = (type: SortType) => {
		setSortType(type);
		return setSortAsc(!sortAsc);
	};

	const changeSortOtherType = (type: SortType) => {
		setSortOtherType(type);
		return setSortOtherAsc(!sortOtherAsc);
	};

	const addNewAccount = (currencyCode: string) => {
		return axiosInstance
			.post(getAddNewAccountURL(), { currencyCode, label: '' })
			.then(({ data }: AxiosResponse<Wallet>) => {
				const addedWallet = otherWallets.find((wallet: any) => {
					return wallet.currencyCode === data.currencyCode;
				});
				if (typeof addedWallet !== 'undefined') {
					const index = otherWallets.indexOf(addedWallet);
					if (index > -1) {
						otherWallets.splice(index, 1);
						setOtherWallets([...otherWallets]);
						return dispatch<any>(addNewWallet(data));
					}
				}
			});
	};

	const getRateTitle = (rate: RateAmount) => {
		switch (rate) {
			case RateAmount.DAY:
				return '24h%';
			case RateAmount.WEEK:
				return '7d%';
			case RateAmount.MONTH:
				return '30d%';
			default:
				return '24h%';
		}
	};

	const hideIfEmptyCall = () => {
		setIsLoading(true);
		void axiosInstance
			.put(getHideIfEmptyURL(!isWalletsHidden))
			.then(() => {
				dispatch<any>(getAccounts());
				setIsWalletsHidden(!isWalletsHidden);
			})
			.catch((err) => {
				console.error(err);
			})
			.then(() => {
				setIsLoading(false);
			});
	};

	const hiddenBalanceCall = () => {
		setIsLoading(true);
		void axiosInstance
			.put(getHiddenBalanceURL(!isBalanceHidden))
			.then(() => {
				dispatch<any>(getAccounts());
				setHiddenBalance(!isBalanceHidden);
			})
			.catch((err) => {
				console.error(err);
			})
			.then(() => {
				setIsLoading(false);
			});
	};

	const searchFilter = (account: Wallet) =>
		account.currencyCode
			.replace(nonAlphaNumericRegex, '')
			.toLowerCase()
			.includes(inputValue.replace(nonAlphaNumericRegex, '').toLowerCase()) ||
		account.currencyName
			.replace(nonAlphaNumericRegex, '')
			.toLowerCase()
			.includes(inputValue.replace(nonAlphaNumericRegex, '').toLowerCase());

	const onChangeInput = useCallback((value: string) => setInputValue(value), []);

	const filterBarFilters = (account: Wallet) => {
		if (filter) {
			if (filter.value === KeywordType.NEW && !account.isNew) {
				if (inputValue) return searchFilter(account);
				return false;
			}
			if (
				filter.value === KeywordType.EMPTY &&
				!toDecimal(account.availableBalance).equals(0)
			) {
				if (inputValue) return searchFilter(account);
				return false;
			}
			if (filter.value === KeywordType.FIAT) {
				if (inputValue) return searchFilter(account);
				if (currencyUtils.getConfigOrDefault(account.currencyCode).isVirtual) return false;
			}
			if (filter.value === KeywordType.CRYPTO) {
				if (inputValue) return searchFilter(account);
				if (!currencyUtils.getConfigOrDefault(account.currencyCode).isVirtual) return false;
			}
			if (filter.value === KeywordType.ERC20) {
				if (inputValue) return searchFilter(account);
				if (!currencyUtils.getConfigOrDefault(account.currencyCode).isErc) return false;
			}
		}
		if (inputValue) return searchFilter(account);
		return true;
	};

	useEffectOnce(() => {
		dispatch(toggleCurrentRates(true));
		dispatch(toggleCurrentRatesGraph(false));
		dispatch(toggleWalletSidebar(true));
		dispatch(toggleMarketingSlider(true));
	});

	useEffect(() => () => clearTimeout(timeoutRef.current), []);

	useEffect(() => {
		setIsWalletsHidden(accounts?.length ? accounts[0].hideIfEmpty : false);
	}, [accounts]);

	useEffect(() => {
		if (isAddNewWalletsVisible)
			void axiosInstance
				.get(getAccountTypesURL())
				.then(({ data }) => {
					return setOtherWallets(
						data.map((wallet: Record<string, string>) => ({
							currencyName: wallet.name,
							...wallet,
						}))
					);
				})
				.catch((e) => console.error(e));
	}, [isAddNewWalletsVisible]);

	if (!rates || rates?.length === 0 || !accounts || accounts?.length === 0)
		return <Loader className={styles.loader} />;
	return (
		<div className={classNames(styles.container, className)}>
			{isStartingBlockVisible && <StartingBlock />}
			{isHeaderVisible && (
				<h3 className={styles.title}>
					<span>
						<FormattedMessage id="accounts.walletsTitle" defaultMessage="Wallets" />
					</span>
				</h3>
			)}
			{isFilterBarVisible && (
				<div className={styles.filterBarContainer}>
					<div className={styles.toggleContentContainer}>
						<div className={styles.header}>
							<Input
								inputGroupClassName={classNames({
									[styles.input]: !smallerContainer,
									[styles.smallerInput]: smallerContainer,
								})}
								placeholder={baseMsg.search}
								faIcon={faSearch}
								value={inputValue}
								onChange={onChangeInput}
							/>
							<div className={styles.filterContainer}>
								<div className={classNames(styles.drop, styles.grow)}>
									<GestureDrawer
										widthType={WidthType.LARGER}
										options={filterOptions}
										onChange={(val) =>
											setFilter(val as typeof filterOptions[0])
										}
										selectedOption={filter}
										dataTag={TestIds.TypeFilter}
									/>
								</div>
								{!isAddNewWalletsVisible && (
									<>
										<div className={classNames(styles.drop, styles.grow)}>
											{!isMobile ? (
												<GestureDrawer
													widthType={WidthType.SMALLER}
													options={rateOptions}
													onChange={({ value }) =>
														dispatch(setRateAmount(value as RateAmount))
													}
													dataTag={TestIds.RateFilter}
													selectedOption={
														rateOptions.find(
															({ value }) => value === rateAmount
														) ?? rateOptions[0]
													}
												/>
											) : (
												<GestureDrawer
													widthType={WidthType.LARGER}
													options={colOptions}
													onChange={({ value }) =>
														setMobileColValue(value as MobileCol)
													}
													selectedOption={
														colOptions.find(
															({ value }) => value === mobileColValue
														) ?? colOptions[0]
													}
												/>
											)}
										</div>
										<div
											className={classNames(
												styles.drop,
												styles.settingsContainer
											)}
										>
											<GestureDrawer
												widthType={WidthType.SMALLER}
												options={[]}
												onChange={() => undefined}
												settingsDropdown
												hiddenBalanceCall={hiddenBalanceCall}
												hideIfEmptyCall={hideIfEmptyCall}
												isBalanceHidden={isBalanceHidden}
												isWalletsHidden={isWalletsHidden}
												isLoading={isLoading}
												selectedOption={{ label: '', value: '' }}
											/>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
			{!isAddNewWalletsVisible && (
				<ul className={styles.walletList} data-cy={TestIds.WalletList}>
					<li>
						<span
							className={classNames({ [styles.addRight]: isMobile })}
							onClick={() => changeSortType(SortType.NAME)}
						>
							<FormattedMessage {...messages.name} />
							<img src={sortArrowsImgPath} alt="Sort arrows" />
						</span>
						{!isMobile && isRatesVisible && (
							<>
								<span
									className={classNames({
										[styles.priceText]: !isToggleVisible,
										[styles.managePriceText]: isToggleVisible,
									})}
									onClick={() => changeSortType(SortType.PRICE)}
								>
									<FormattedMessage {...messages.priceChange} />
									<img src={sortArrowsImgPath} alt="Sort arrows" />
								</span>
								<span
									className={classNames({
										[styles.rateText]: !isToggleVisible,
										[styles.manageRateText]: isToggleVisible,
									})}
									onClick={() => changeSortType(SortType.RATE)}
								>
									{getRateTitle(rateAmount)}
									<img src={sortArrowsImgPath} alt="Sort arrows" />
								</span>
							</>
						)}
						{!isMobile ? (
							<span
								className={classNames({
									[styles.addWalletBalance]: isAddNewWalletsVisible,
								})}
								onClick={() => changeSortType(SortType.BALANCE)}
							>
								<FormattedMessage {...messages.balance} />
								<img src={sortArrowsImgPath} alt="Sort arrows" />
							</span>
						) : mobileColValue === MobileCol.BALANCE ? (
							<span
								className={classNames({
									[styles.addWalletBalance]: isAddNewWalletsVisible,
								})}
								onClick={() => changeSortType(SortType.BALANCE)}
							>
								<FormattedMessage {...messages.balance} />
								<img src={sortArrowsImgPath} alt="Sort arrows" />
							</span>
						) : (
							<span
								className={classNames({
									[styles.addWalletBalance]: isAddNewWalletsVisible,
								})}
								onClick={() => changeSortType(SortType.PRICE)}
							>
								<FormattedMessage {...messages.priceChange} />
								<img src={sortArrowsImgPath} alt="Sort arrows" />
							</span>
						)}
					</li>
					{!isLoading &&
						accounts &&
						accounts
							?.filter(filterBarFilters)
							.filter((account) =>
								isWalletsHidden && !inputValue && !isToggleVisible
									? !toDecimal(account.availableBalance).equals(0)
									: true
							).length === 0 && (
							<div className={styles.imgContainer}>
								<img src={noTransactionsImgPath} alt="No wallets found" />
								<p>{formatMessage(messages.emptyList)}</p>
							</div>
						)}
					{accounts
						?.filter(filterBarFilters)
						.filter((account) =>
							isWalletsHidden && !inputValue && !isToggleVisible
								? !toDecimal(account.availableBalance).equals(0)
								: true
						)
						.sort((a: Wallet, b: Wallet) => {
							if (sortType) {
								if (sortType === SortType.NAME) {
									if (sortAsc) return a.currencyName > b.currencyName ? 1 : -1;
									if (!sortAsc) return a.currencyName < b.currencyName ? 1 : -1;
								}
								if (sortType === SortType.BALANCE) {
									const aRate = rates?.find(
										({ currencyCode }) => currencyCode === a.currencyCode
									)?.value;
									const bRate = rates?.find(
										({ currencyCode }) => currencyCode === b.currencyCode
									)?.value;

									const aValue = toDecimal(a.availableBalance).dividedBy(
										toDecimal(aRate ? aRate.toString() : '1')
									);
									const bValue = toDecimal(b.availableBalance).dividedBy(
										toDecimal(bRate ? bRate.toString() : '1')
									);

									const aSmallerThan = aValue
										.sub(bValue)
										.lessThan(bValue.sub(aValue));

									return sortAsc
										? aSmallerThan
											? -1
											: 1
										: aSmallerThan
										? 1
										: -1;
								}
								if (sortType === SortType.PRICE) {
									const aPrice =
										rates.find(
											({ currencyCode }) => currencyCode === a.currencyCode
										)?.value ?? 0;
									const bPrice =
										rates.find(
											({ currencyCode }) => currencyCode === b.currencyCode
										)?.value ?? 0;

									return sortAsc
										? toDecimal(aPrice).greaterThan(bPrice)
											? -1
											: 1
										: toDecimal(bPrice).greaterThan(aPrice)
										? -1
										: 1;
								}

								if (sortType === SortType.RATE) {
									const aRate = ratesDetails?.find(
										({ currencyCode }) => currencyCode === a.currencyCode
									)![ratePropNames[rateAmount]];

									const bRate = ratesDetails?.find(
										({ currencyCode }) => currencyCode === b.currencyCode
									)![ratePropNames[rateAmount]];

									return sortAsc
										? toDecimal(aRate).greaterThan(bRate)
											? -1
											: 1
										: toDecimal(bRate).greaterThan(aRate)
										? -1
										: 1;
								}
							}
							return a.sortIndex - b.sortIndex;
						})
						.map(
							({
								currencyCode,
								sortIndex,
								id,
								hideBalance,
								availableBalance,
								hideIfEmpty,
								isNew,
								currencyName,
							}) => {
								if (toDecimal(availableBalance).equals(0) && hideIfEmpty) {
									return (
										<SingleWallet
											id={id}
											key={`${sortIndex}-${currencyCode}-${id}`}
											currencyCode={currencyCode}
											mobileColValue={mobileColValue}
											currencyName={currencyName}
											isBalanceVisible={!hideBalance}
											availableBalance={availableBalance}
											refCurrency={refCurrency}
											disabledLinks={disabledLinks}
											isToggleVisible={isToggleVisible}
											isNew={isNew}
											isRatesVisible={isRatesVisible}
											linkSmaller={linkSmaller}
											buyButton={buyButton}
											isActionsVisible={showActions}
										/>
									);
								}
								return (
									<SingleWallet
										linkSmaller={linkSmaller}
										isRatesVisible={isRatesVisible}
										id={id}
										mobileColValue={mobileColValue}
										isBalanceVisible={!hideBalance}
										key={`${sortIndex}-${currencyCode}-${id}`}
										currencyCode={currencyCode}
										currencyName={currencyName}
										availableBalance={availableBalance}
										refCurrency={refCurrency}
										disabledLinks={disabledLinks}
										isToggleVisible={isToggleVisible}
										isNew={isNew}
										buyButton={buyButton}
										isActionsVisible={showActions}
									/>
								);
							}
						)}
				</ul>
			)}
			{isAddNewWalletsVisible && !!otherWallets.length && (
				<ul className={styles.walletList} data-cy={TestIds.WalletList}>
					<li>
						<span onClick={() => changeSortOtherType(SortType.NAME)}>
							<FormattedMessage {...messages.name} />
							<img src={sortArrowsImgPath} alt="Sort arrows" />
						</span>
						{!isMobile && isRatesVisible && (
							<span
								className={classNames({
									[styles.priceText]: !isToggleVisible && !isAddNewWalletsVisible,
									[styles.managePriceText]: isToggleVisible,
									[styles.addWalletRateText]: isAddNewWalletsVisible,
								})}
							>
								<FormattedMessage
									{...messages.changeTitle}
									values={{
										change: getRateTitle(rateAmount),
									}}
								/>
							</span>
						)}
						{!isMobile && (
							<span
								className={classNames({
									[styles.addNewBalanceText]: isAddNewWalletsVisible,
								})}
							>
								<FormattedMessage {...messages.balance} />
							</span>
						)}
					</li>
					{otherWallets
						?.filter(filterBarFilters)
						?.sort((a: Wallet, b: Wallet) => {
							if (sortOtherType === SortType.NAME) {
								if (sortOtherAsc) return a.currencyCode > b.currencyCode ? 1 : -1;
								if (!sortOtherAsc) return a.currencyCode < b.currencyCode ? 1 : -1;
							}
							return a.sortIndex - b.sortIndex;
						})
						.map(
							(
								{ currencyCode, currencyName, isNew, hideBalance, id }: Wallet,
								i: number
							) => {
								return (
									<SingleWallet
										key={`other-${currencyCode}-${i}`}
										currencyCode={currencyCode}
										mobileColValue={mobileColValue}
										currencyName={currencyName}
										availableBalance={'0'}
										id={id}
										isBalanceVisible={!hideBalance}
										refCurrency={refCurrency}
										disabledLinks
										addButton
										isNew={isNew}
										addNewWalletMethod={addNewAccount}
										buyButton={buyButton}
										isActionsVisible={showActions}
										isRatesVisible={isRatesVisible}
									/>
								);
							}
						)}
				</ul>
			)}
		</div>
	);
};

export default WalletBlocks;
