import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RemoteData } from '../../../../interfaces/RemoteData';

export enum PreorderStatus {
	NEW = 'NEW',
	PENDING = 'PENDING',
	PAID = 'PAID',
	FAILED = 'FAILED',
	EXPIRED = 'EXPIRED',
	TEST = 'TEST',
}

export type Preorder = {
	id: string;
	merchantProjectId: string;
	orderId: string;
	receiveAmount: string;
	description: string | null;
	callbackURL: string | null;
	successURL: string | null;
	failureURL: string | null;
	receiveCurrencyCode: CurrencyEnum;
	created: string;
	isMultiple: boolean;
};

export type PreordersDataState = {
	preorders: Record<string, RemoteData<Preorder>>;
};

export enum PreordersDataActionType {
	FETCH_PREORDER_PENDING = 'MERCHANTS/PREORDERS/DATA/FETCH_PREORDER/PENDING',
	FETCH_PREORDER_REJECTED = 'MERCHANTS/PREORDERS/DATA/FETCH_PREORDER/REJECTED',
	FETCH_PREORDER_FULFILLED = 'MERCHANTS/PREORDERS/DATA/FETCH_PREORDER/FULFILLED',

	UPDATE_PREORDER = 'MERCHANTS/PREORDERS/DATA/UPDATE_PREORDER',
	UPDATE_PREORDERS = 'MERCHANTS/PREORDERS/DATA/UPDATE_PREORDERS',
}

export type PreordersDataFetchOrderPendingAction = {
	type: PreordersDataActionType.FETCH_PREORDER_PENDING;
	payload: string;
};

export type PreordersDataFetchOrderRejectedAction = {
	type: PreordersDataActionType.FETCH_PREORDER_REJECTED;
	payload: string;
};

export type PreordersDataFetchOrderFulfilledAction = {
	type: PreordersDataActionType.FETCH_PREORDER_FULFILLED;
	payload: Preorder;
};

export type PreordersDataUpdateOrderAction = {
	type: PreordersDataActionType.UPDATE_PREORDER;
	payload: Preorder;
};

export type PreordersDataUpdateOrdersAction = {
	type: PreordersDataActionType.UPDATE_PREORDERS;
	payload: Preorder[];
};
