import PaymentButtonsDataSelectors from './PaymentButtonsData/PaymentButtonsDataSelectors';
import PaymentButtonsListSelectors from './PaymentButtonsList/PaymentButtonsListSelectors';
import { RootState } from '../../Store';

const getListButtonsDataByPage = (page: number) => (state: RootState) => {
	const ids = PaymentButtonsListSelectors.getButtonIdsByPage(page)(state) || [];
	return PaymentButtonsDataSelectors.getPaymentButtonDataByIds(ids)(state);
};

export default {
	getListButtonsDataByPage,
	data: PaymentButtonsDataSelectors,
	list: PaymentButtonsListSelectors,
};
