import {
	ChangeEvent,
	FC,
	FormEvent,
	PropsWithChildren,
	ReactNode,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { CurrencyIcon, CurrencyEnum } from '@spectrocoin/sc-currencies';
import { components } from 'react-select';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import {
	GetCurrentCard,
	isCardFunctionsEnabled,
} from '../../../../helpers/cardsHelper/cardsHelper';
import { RemoteError } from '../../../../interfaces/RemoteData';
import { formatPrecision, toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import { RootState } from '../../../../redux/Store';
import { getCard } from '../../../../redux/CardsState/CardsActions';
import { getAccountById } from '../../../../redux/AccountsState/AccountsActions';
import { LoadStatus } from '../../../../redux/CardsState/CardsTypes';
import {
	getDebitCardUnloadAuthURL,
	getDebitCardUnloadStatusURL,
	getDebitCardUnloadURL,
} from '../../../../redux/endpoints';
import { Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import Select from '../../../../components/Select/Select';
import CurrencyInput from '../../../../components/CurrencyInput/CurrencyInput';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import ThirdParty2FA from '../../Shared/ThirdParty2FA/ThirdParty2FA';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import CardStatus from '../../Shared/CardStatus/CardStatus';
import styles from './Unload.module.scss';
import Seo from '../../../../components/Seo/Seo';
import { isAmountGreaterThanZero } from '../../../../helpers/inputValidation/inputValidation';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import inputErrors from '../../../../messages/inputErrors.messages';
import { toPlainAmount } from '../../../../helpers/currencyAmountHelper/currencyAmountHelper';
import { fromEntries } from '../../../../helpers/objectHelper/objectHelper';
import useFormatAmount from '../../../../hooks/useFormatAmount';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	amountTooSmall: {
		id: 'load.amountTooSmall',
		defaultMessage: 'Amount too small',
	},
	successMsg: {
		id: 'unload.success',
		defaultMessage: 'Card unloaded successfully',
	},
	pendingMsg: {
		id: 'unload.pending',
		defaultMessage: 'Card unload is pending',
	},
	failedMsg: {
		id: 'unload.failed',
		defaultMessage: 'Card unload failed',
	},
	noResult: {
		id: 'load.noResult',
		defaultMessage: 'No result',
	},
	title: {
		id: 'unload.title',
		defaultMessage: 'Unload card',
	},
	metaTitle: {
		id: 'unload.metaTitle',
		defaultMessage: 'Unload card',
	},
	loadFrom: {
		id: 'unload.unloadFrom',
		defaultMessage: 'Unload to',
	},
	loadAmount: {
		id: 'unload.unloadAmount',
		defaultMessage: 'Unload amount',
	},
	cancel: {
		id: 'load.cancel',
		defaultMessage: 'Cancel',
	},
	unload: {
		id: 'unload.submit',
		defaultMessage: 'Unload',
	},
	mobileBack: {
		id: 'load.back',
		defaultMessage: 'Back to card',
	},
});

interface OptionsProps {
	value: string;
	label: ReactNode;
	currencyName: string;
	currencyCode: string;
	balance: string;
}

interface FormProps {
	from: string;
	amount: string;
}

interface FormErrorProps {
	from: MessageDescriptor | null;
	amount: MessageDescriptor | null;
}

interface PostDataProps {
	debitCardUnloadId: string | null;
	transactionId: string | null;
}

const SingleValue: FC<PropsWithChildren<{ innerProps: any; data: OptionsProps }>> = (props) => {
	const { currencyCode, balance } = props.data;
	const amountFormatter = useFormatAmount();

	return (
		<components.SingleValue className={styles.option} {...(props as any)}>
			<CurrencyIcon currencyType={CurrencyEnum[currencyCode]} className={styles.icon} />
			<div className={styles.balance}>
				{amountFormatter(formatPrecision(balance, CurrencyEnum[currencyCode]))}
			</div>
		</components.SingleValue>
	);
};

const Unload = () => {
	let attempt = 0;
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const { url } = useRouteMatch();
	const { push }: any = useHistory();
	const card = GetCurrentCard();
	const amountFormatter = useFormatAmount();
	const { state } = useLocation();
	const { isTablet } = useSelector((store: RootState) => store.AppState);
	const { wallets } = useSelector((store: RootState) => store.AccountsState);
	const [processStatus, setProcessStatus] = useState<LoadStatus | null>(null);
	const [isFromReportCard, setIsFromReportCard] = useState<boolean>(false);
	const [error, setError] = useState<MessageDescriptor | string | null>(null);
	const [errorParams, setErrorParams] = useState<{ [key: string]: string }[]>([]);
	const [postData, setPostData] = useState<PostDataProps>({
		debitCardUnloadId: null,
		transactionId: null,
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [options, setOptions] = useState<OptionsProps[]>([]);
	const [form, setForm] = useState<FormProps>({
		from: '',
		amount: '',
	});
	const [formError, setFormError] = useState<FormErrorProps>({
		from: null,
		amount: null,
	});

	const pollStatus = useCallback(async () => {
		const { debitCardUnloadId } = postData;
		if (debitCardUnloadId) {
			const { status } = await axiosInstance
				.get(getDebitCardUnloadStatusURL(debitCardUnloadId))
				.then(({ data }: AxiosResponse) => {
					return data;
				});
			if (status !== LoadStatus.COMPLETED && attempt < 5) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				attempt += 1;
				setTimeout(() => pollStatus(), 1500);
			} else {
				setProcessStatus(status);
				void dispatch(getCard(card?.id || ''));
				void dispatch(getAccountById(form.from));
				setPostData({
					debitCardUnloadId: null,
					transactionId: null,
				});
			}
			if (isFromReportCard)
				return push({
					pathname: url.replace('unload', 'report-card'),
					state: {
						fromUnload: true,
					},
				});
		}
		return null;
	}, [card?.id, dispatch, form.from, isFromReportCard, postData, push, url]);

	const postUnload = useCallback(
		async (otp: string) => {
			const { transactionId, debitCardUnloadId } = postData;
			setIsLoading(true);
			if (debitCardUnloadId)
				await axiosInstance
					.put(getDebitCardUnloadAuthURL(debitCardUnloadId), { otp, transactionId })
					.then(async ({ data }: AxiosResponse) => {
						const { authorized } = data;
						if (authorized) {
							await pollStatus();
						} else {
							setIsLoading(false);
							setProcessStatus(LoadStatus.FAILED);
						}
					})
					.catch(() => {
						setIsLoading(false);
						setProcessStatus(LoadStatus.FAILED);
					});
		},
		[pollStatus, postData]
	);

	const validation = () => {
		let errors: FormErrorProps = {
			from: null,
			amount: null,
		};

		if (!form.from) {
			errors = {
				...errors,
				from: inputErrors.cannotBeEmpty,
			};
		}
		if (!form.amount) {
			errors = {
				...errors,
				amount: inputErrors.cannotBeEmpty,
			};
		}
		if (form.amount && !isAmountGreaterThanZero(form.amount, true)) {
			errors = {
				...errors,
				amount: messages.amountTooSmall,
			};
		}
		if (form.amount && toDecimal(form.amount, true).greaterThan(card?.accountBalance || 0)) {
			errors = {
				...errors,
				amount: inputErrors.insufficientFunds,
			};
		}
		setFormError({
			...formError,
			...errors,
		});
		return Object.values(errors).every((value) => {
			if (value === null) {
				return true;
			}

			return false;
		});
	};

	const handleSubmit = (event?: FormEvent) => {
		event?.preventDefault();
		if (validation()) {
			setIsLoading(true);
			return axiosInstance
				.post(getDebitCardUnloadURL(card?.id || ''), {
					receiveAccountId: form.from,
					unloadAmount: toPlainAmount(form.amount, true),
				})
				.then(({ data }: AxiosResponse) => {
					const { debitCardUnloadId, transactionId } = data;
					return setPostData({
						debitCardUnloadId,
						transactionId,
					});
				})
				.catch(({ response }) => {
					if (!response) return null;
					setErrorParams(response.data.errorParameters);
					return setError(getErrorMessageOrDefault(response.data as RemoteError));
				})
				.then(() => setIsLoading(false));
		}
		return null;
	};

	const handleChange = ({ target: { value, name } }: ChangeEvent<HTMLInputElement>) => {
		setError(null);
		setErrorParams([]);
		setFormError({
			...formError,
			[name]: null,
		});
		setForm({
			...form,
			[name]: value,
		});
	};

	useEffect(() => {
		if (wallets?.length !== 0 && options.length === 0) {
			const walletOptions = wallets?.reduce(
				(
					acc: OptionsProps[],
					{ id, currencyCode, currencyName, availableBalance }: Wallet
				) => {
					if (CurrencyEnum[currencyCode] === CurrencyEnum.EUR)
						acc.push({
							value: `${id}`,
							label: (
								<div className={styles.option}>
									<CurrencyIcon
										currencyType={CurrencyEnum[currencyCode]}
										className={styles.icon}
									/>
									<div className={styles.balance}>
										{amountFormatter(
											formatPrecision(
												availableBalance,
												CurrencyEnum[currencyCode]
											)
										)}
									</div>
									<div className={styles.details}>
										<div className={styles.code}>{currencyCode}</div>
										<div className={styles.name}>{currencyName}</div>
									</div>
								</div>
							),
							currencyCode,
							currencyName,
							balance: availableBalance,
						});
					return acc;
				},
				[]
			);
			setOptions(walletOptions || []);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options?.length, wallets]);

	useEffect(() => {
		if (!form.from && options && options?.length !== 0)
			setForm({
				...form,
				from: options[0].value,
			});
	}, [form, options]);

	useEffect(() => {
		if (state?.fromReportCard) setIsFromReportCard(!!state?.fromReportCard);
	}, [state]);

	if (!card) return null;
	return (
		<WhiteContainer
			backButtonLink={isTablet ? url.replace('unload', 'history') : ''}
			backButtonText={messages.mobileBack}
		>
			<Seo title={messages.metaTitle} />
			{processStatus && (
				<CardStatus
					status={processStatus}
					title={
						processStatus === LoadStatus.COMPLETED
							? messages.successMsg
							: processStatus === LoadStatus.PENDING
							? messages.pendingMsg
							: messages.failedMsg
					}
					link={'history'}
				/>
			)}
			{!processStatus && (
				<>
					<InfoHead title={messages.title} />
					<form className={styles.form} onSubmit={handleSubmit}>
						{error && (
							<NotificationMessage
								withIcon
								type={NotificationType.Error}
								className={styles.mb20}
								style={NotificationStyle.Border}
								message={
									typeof error === 'string' ? (
										error
									) : (
										<FormattedMessage
											{...error}
											values={fromEntries<Record<string, string>>(
												errorParams.map(({ key, value }) => [key, value])
											)}
										/>
									)
								}
							/>
						)}
						<Select
							isSearchable={false}
							label={messages.loadFrom}
							options={options}
							labelClassName={styles.label}
							disabled={isLoading}
							onChangeObject={({ value }: OptionsProps) =>
								setForm({ ...form, from: value })
							}
							componentOverrides={{
								SingleValue,
							}}
							className={styles.select}
							noResultsText={formatMessage(messages.noResult)}
							name="from"
							value={form.from}
							colors={{
								primary: '#f5f8ff',
								primary25: '#F6F8FF',
								primary50: '#F6F8FF',
								primary75: '#f5f8ff',
							}}
							classNamePrefix="from"
						/>
						<CurrencyInput
							id="amount"
							value={form.amount}
							label={messages.loadAmount}
							currencyCode={CurrencyEnum.EUR}
							onChange={handleChange}
							isLoading={isLoading}
							error={formError.amount}
						/>
						{!postData.debitCardUnloadId && (
							<>
								<Button
									type={ButtonType.SUBMIT}
									text={messages.unload}
									className={styles.button}
									isDisabled={isLoading}
									isLoading={isLoading}
								/>
								<Button
									text={messages.cancel}
									type={ButtonType.LINK}
									link={url.replace('unload', 'history')}
									buttonStyle={ButtonStyle.BORDERLESS}
									className={classNames(styles.button, {
										[styles.hidden]: isLoading,
									})}
									isDisabled={isLoading}
									isLoading={isLoading}
								/>
							</>
						)}
					</form>
					{postData.debitCardUnloadId && (
						<ThirdParty2FA
							onSubmit={postUnload}
							isLoading={isLoading}
							resend={handleSubmit}
							codeLength={isCardFunctionsEnabled(card.cardType) ? 6 : 8}
						/>
					)}
				</>
			)}
		</WhiteContainer>
	);
};

export default Unload;
