import { AxiosError } from 'axios';
import { ThunkAction } from 'redux-thunk';
import { getMerchantsProjectsUrl, getPaymentButtonsUrl } from '../endpoints';
import PageableResponse from '../../interfaces/PageableResponse';
import { RootState } from '../Store';
import { MerchantsActionType, PaymentButton, Project } from './MerchantTypes';
import ProjectsActions from './Projects/ProjectsActions';
import OrdersActions from './Orders/OrdersActions';
import PreordersActions from './Preorders/PreordersActions';
import PaymentButtonsActions from './PaymentButtons/PaymentButtonsActions';
import PayoutsActions from './CryptoPayouts/PayoutsActions';
import axiosInstance from '../../helpers/axiosInstance';

const loadDataPending = () => ({
	type: MerchantsActionType.LOAD_DATA_PENDING,
});

const loadDataFulfilled = (
	projects: PageableResponse<Project[]>,
	paymentButtons: PageableResponse<PaymentButton[]>
) => ({
	type: MerchantsActionType.LOAD_DATA_FULFILLED,
	payload: { projects, paymentButtons },
});

const loadDataRejected = (error: string) => ({
	type: MerchantsActionType.LOAD_DATA_REJECTED,
	payload: error,
});

const loadData = (): ThunkAction<Promise<void>, RootState, undefined, any> => async (dispatch) => {
	dispatch(loadDataPending());
	Promise.all([
		axiosInstance.get<PageableResponse<Project[]>>(getMerchantsProjectsUrl(), {
			params: { size: 1 },
		}),
		axiosInstance.get<PageableResponse<PaymentButton[]>>(getPaymentButtonsUrl(), {
			params: { size: 1 },
		}),
	])
		.then(([projects, paymentButtons]) => {
			dispatch(loadDataFulfilled(projects.data, paymentButtons.data));
		})
		.catch((e: AxiosError) => {
			dispatch(loadDataRejected(e.response?.statusText || 'error'));
		});
};

export default {
	loadData,
	projects: ProjectsActions,
	orders: OrdersActions,
	preorders: PreordersActions,
	paymentButtons: PaymentButtonsActions,
	payouts: PayoutsActions,
};
