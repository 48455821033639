import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CurrencyEnum, CurrencyIcon } from '@spectrocoin/sc-currencies';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons/faLongArrowRight';
import classNames from 'classnames';
import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import statusMsg from '../../../../../messages/status.messages';
import { InstantHistoryItemProps } from '../../../../../redux/ConvertHistoryState/ConvertHistoryTypes';
import convertToLocalTime from '../../../../../helpers/dateHelper/dateHelper';
import { formatPrecision } from '../../../../../helpers/currencyHelper/currencyHelper';
import toggleModal from '../../../../../redux/ModalState/ModalActions';
import styles from './InstantHistoryItem.module.scss';
import InstantHistoryModal from '../InstantHistoryModal/InstantHistoryModal';
import useFormatAmount from '../../../../../hooks/useFormatAmount';

const InstantHistoryItem: FC<InstantHistoryItemProps> = ({
	id,
	created,
	exchangeRate,
	payAmount,
	payCurrencyCode,
	receiveAmount,
	receiveCurrencyCode,
	status,
}) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const amountFormatter = useFormatAmount();

	const onClick = useCallback(() => {
		return dispatch(
			toggleModal(
				<InstantHistoryModal
					id={id}
					created={created}
					exchangeRate={exchangeRate}
					payAmount={payAmount}
					payCurrencyCode={payCurrencyCode}
					receiveAmount={receiveAmount}
					receiveCurrencyCode={receiveCurrencyCode}
					status={status}
				/>
			)
		);
	}, [
		created,
		dispatch,
		exchangeRate,
		id,
		payAmount,
		payCurrencyCode,
		receiveAmount,
		receiveCurrencyCode,
		status,
	]);

	return (
		<li className={styles.item} onClick={onClick}>
			<div>
				<span className={styles.assets}>
					<CurrencyIcon currencyType={payCurrencyCode} className={styles.icon} />
					<CurrencyIcon
						currencyType={receiveCurrencyCode}
						className={classNames(styles.icon, styles.margin)}
					/>
					{payCurrencyCode}
					<FontAwesomeIcon icon={faLongArrowRight} className={styles.arrow} />
					{receiveCurrencyCode}
				</span>
				<div className={styles.details}>
					{convertToLocalTime(created, 'yyyy-MM-dd HH:mm')}
				</div>
			</div>
			<div className={styles.amountContainer}>
				<span className={styles.amount} data-cy="transactionAmount">
					{`+${amountFormatter(
						formatPrecision(receiveAmount, CurrencyEnum[receiveCurrencyCode])
					)} ${receiveCurrencyCode}`}
				</span>
				<span className={styles.statusText} data-cy="transactionStatus">
					{formatMessage(statusMsg[status])}
				</span>
			</div>
		</li>
	);
};

export default InstantHistoryItem;
