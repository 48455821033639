import { ChangeEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { MessageDescriptor, useIntl } from 'react-intl';
import Cleave from 'cleave.js/react';
import inputStyles from '../../../../components/Input/Input.module.scss';

interface CardCvvInnputProps {
	autoComplete?: string;
	className?: string;
	cvvDigitCount: number;
	id?: string;
	label?: string;
	name?: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
	type?: string;
	value?: string;
	errorMessage?: MessageDescriptor | string | null;
}

const CardCvvInput = ({
	autoComplete = 'off',
	className,
	label,
	name,
	cvvDigitCount,
	value,
	errorMessage,
	...restProps
}: CardCvvInnputProps) => {
	const [digitCount, setDigitCount] = useState(3);
	const { formatMessage } = useIntl();

	useEffect(() => {
		// Avoid rerendering Cleave internals on each prop cvvDigitCount change by only updating if actual value changed
		setDigitCount(cvvDigitCount);
	}, [cvvDigitCount]);

	return (
		<div className={classNames(inputStyles.inputGroup, className)}>
			<label className={inputStyles.label} htmlFor={name}>
				{label}
			</label>
			<Cleave
				autoComplete={autoComplete}
				className={classNames(inputStyles.input, {
					[inputStyles.errorInput]: errorMessage,
				})}
				inputMode="numeric"
				key={`cvvInput_${digitCount}`}
				name={name}
				value={value}
				pattern="[0-9]*"
				placeholder="123"
				type="text"
				options={{
					blocks: [digitCount],
					numericOnly: true,
				}}
				{...restProps}
			/>
			{errorMessage && (
				<div className={inputStyles.error}>
					{typeof errorMessage === 'string' ? errorMessage : formatMessage(errorMessage)}
				</div>
			)}
		</div>
	);
};

export default CardCvvInput;
