import Joi from 'joi';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
	isAmountGreaterThan,
	isAmountGreaterThanZero,
} from '../../../../helpers/inputValidation/inputValidation';
import inputErrors from '../../../../messages/inputErrors.messages';

const useVoucherFormValidator = (balance: string) => {
	const { formatMessage } = useIntl();
	return useMemo(
		() =>
			Joi.object({
				currency: Joi.string()
					.required()
					.messages({ 'string.empty': formatMessage(inputErrors.cannotBeEmpty) }),
				receive: Joi.string()
					.required()
					.custom((value: string, helper: any) => {
						if (!isAmountGreaterThanZero(value))
							return helper.message(formatMessage(inputErrors.amountTooLow));
						if (isAmountGreaterThan(value, balance))
							return helper.message(formatMessage(inputErrors.insufficientFunds));
						return true;
					})
					.messages({
						'string.empty': formatMessage(inputErrors.cannotBeEmpty),
					}),
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[balance]
	);
};

export default useVoucherFormValidator;
