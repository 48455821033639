/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import { QRCodeSVG } from 'qrcode.react';
import { CurrencyEnum, CurrencyIcon, NetworkEnum, currencyUtils } from '@spectrocoin/sc-currencies';
import {
	getAccountNetwork,
	getIsNewAddressAvailable,
	getNewCryptoAddress,
	getWalletAddress,
	setError,
} from '../../redux/AccountsState/AccountsActions';
import { getQRValue } from '../../helpers/currencyHelper/currencyHelper';
import { RootState } from '../../redux/Store';
import depositMessages from '../../redux/DepositState/DepositMessages';
import CopyButton from '../CopyButton/CopyButton';
import Loader from '../Loader/Loader';
import styles from './CryptoAddressBlock.module.scss';
import baseMsg from '../../messages/base.messages';
import TestIds, { formatTestId } from '../../test/TestIds';
import WalletTestIds from '../../test/Wallets/WalletTestIds';
import Select from '../Select/Select';
import SelectOption from '../../interfaces/SelectOption';
import useCurrentWallet from '../../hooks/useCurrentWallet';
import { BTCAddressType, CurrencyNetwork } from '../../redux/AccountsState/AccountsTypes';

const messages = defineMessages({
	address: {
		id: 'cryptoAddressBlock.address',
		defaultMessage: 'Address',
	},
	getNewAddress: {
		id: 'cryptoAddressBlock.getNewAddress',
		defaultMessage: 'Get new address',
	},
	copied: {
		id: 'cryptoAddressBlock.copied',
		defaultMessage: 'Copied',
	},
	erc20Alert: {
		id: 'cryptoAddressBlock.alert',
		defaultMessage: 'This wallet is only for the Ethereum network (ERC20).',
	},
	addressType: {
		id: 'cryptoAddressBlock.addressType',
		defaultMessage: 'Address type',
	},
});

interface CryptoAddressBlockProps {
	id: string;
	currencyCode: CurrencyEnum;
	isVirtual: boolean;
	network: CurrencyNetwork['networkName'] | null;
	setNetwork: (value: CurrencyNetwork['networkName'] | null) => void;
}

const CryptoAddressBlock = ({
	id,
	currencyCode,
	isVirtual,
	network,
	setNetwork,
}: CryptoAddressBlockProps) => {
	const wallet = useCurrentWallet();
	const dispatch = useDispatch();
	const isBTC = currencyCode === CurrencyEnum.BTC;
	const { formatMessage } = useIntl();
	const [list, setList] = useState<SelectOption[]>([]);
	const { isLoading, error } = useSelector((state: RootState) => state?.AccountsState);
	const [isSegWit, setIsSegWit] = useState(isBTC);
	const [isNewAddressAvailable, setIsNewAddressAvailable] = useState<boolean>(false);
	const address = isSegWit ? wallet?.cryptoAddressSegWit : wallet?.cryptoAddress || '';

	const getAddress = () => {
		if (network)
			return dispatch(
				getNewCryptoAddress(
					id,
					network,
					isBTC
						? isSegWit
							? BTCAddressType.BECH32
							: !isSegWit
							? BTCAddressType.LEGACY
							: null
						: null
				)
			);
		return null;
	};

	useEffect(() => {
		if (error !== null) setTimeout(() => dispatch(setError(null)), 2500);
	}, [error]);

	useEffect(() => {
		if (!wallet?.networkList) void dispatch(getAccountNetwork(currencyCode, id));
	}, [wallet]);

	useEffect(() => {
		if (wallet?.networkList && wallet?.networkList.length !== 0) {
			setList(
				wallet?.networkList?.map(({ networkName, feeCurrencyCode, publicName }) => {
					return {
						label: (
							<div className={styles.option}>
								<CurrencyIcon
									currencyType={CurrencyEnum[feeCurrencyCode]}
									className={styles.icon}
								/>
								<span className={styles.text}>{publicName}</span>
							</div>
						),
						value: networkName,
					};
				})
			);
		}
	}, [wallet?.networkList]);

	useEffect(() => {
		if (list?.length > 0) {
			setNetwork(list[0].value);
		}
	}, [list]);

	useEffect(() => {
		if (network) {
			void dispatch(
				getWalletAddress(
					id,
					network,
					currencyCode,
					isBTC ? BTCAddressType.LEGACY : undefined
				)
			);
			if (isBTC) {
				void dispatch(getWalletAddress(id, network, currencyCode, BTCAddressType.BECH32));
			}
		}
	}, [network]);

	useEffect(() => {
		void (async () => {
			setIsNewAddressAvailable(await getIsNewAddressAvailable(id, network as NetworkEnum));
		})();
	}, [network]);

	if (!isVirtual || currencyUtils.getConfigOrDefault(currencyCode).noAddress) return null;
	if (
		isVirtual &&
		!currencyUtils.getConfigOrDefault(currencyCode).noAddress &&
		!address &&
		!wallet?.message &&
		list.length === 0
	)
		return <Loader className={styles.loadingAddress} />;
	return (
		<div className={styles.container}>
			<div className={styles.qrCodeContainer}>
				<div className={styles.borders} />
				<QRCodeSVG
					data-cy={formatTestId(WalletTestIds.QrCode)}
					size={90}
					level="M"
					className={classNames(styles.qrCode, { [styles.opacity]: isLoading })}
					value={getQRValue(currencyCode, address!, wallet?.message!, null)}
				/>
			</div>
			<div
				className={classNames({
					[styles.rightContainer]: currencyCode === CurrencyEnum.BTC,
				})}
			>
				<Select
					label={formatMessage(baseMsg.network)}
					inputGroupClassName={styles.selectInputGroup}
					className={styles.select}
					labelClassName={styles.label}
					disabled={list.length === 0 || list.length === 1}
					value={network}
					options={list}
					onChange={setNetwork}
				/>
				{isBTC && (
					<>
						<div className={styles.label}>{formatMessage(messages.addressType)}</div>
						<ul className={styles.list} data-cy={TestIds.AddressTypes}>
							<li
								onClick={() => setIsSegWit(true)}
								className={classNames({
									[styles.selected]: isSegWit,
								})}
							>
								{formatMessage(depositMessages.segWitAddress)}
							</li>
							<li
								onClick={() => setIsSegWit(false)}
								className={classNames({
									[styles.selected]: !isSegWit,
								})}
							>
								{formatMessage(depositMessages.legacyAddress)}
							</li>
						</ul>
					</>
				)}
				<div className={styles.label}>{formatMessage(messages.address)}</div>
				<div
					data-cy={formatTestId(WalletTestIds.Address)}
					className={classNames(styles.value, { [styles.opacity]: isLoading })}
				>
					{address}
					<CopyButton dataToCopy={address!} className={styles.copyButton} />
				</div>
				{wallet?.message && (
					<>
						<div className={classNames(styles.label, styles.mt11)}>
							{currencyCode === CurrencyEnum.XEM && formatMessage(baseMsg.message)}
							{currencyCode === CurrencyEnum.XLM && formatMessage(baseMsg.memo)}
							{currencyCode === CurrencyEnum.XRP &&
								formatMessage(baseMsg.destinationTag)}
						</div>
						<div
							data-cy={TestIds.Message}
							className={classNames(styles.value, { [styles.opacity]: isLoading })}
						>
							{wallet?.message}
							<CopyButton
								dataToCopy={wallet?.message || ''}
								className={styles.copyButton}
							/>
						</div>
					</>
				)}
				{isNewAddressAvailable && (
					<button
						className={styles.getNewAddress}
						onClick={getAddress}
						disabled={isLoading}
						type="button"
					>
						{formatMessage(messages.getNewAddress)}
						{isLoading && <Loader className={styles.loader} />}
						{error && <div className={styles.error}>{formatMessage(error)}</div>}
					</button>
				)}
			</div>
		</div>
	);
};

export default CryptoAddressBlock;
