import { defineMessages } from 'react-intl';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import Seo from '../../components/Seo/Seo';
import WalletBlocks from '../../components/WalletBlocks/WalletBlocks';
import CardsBanner from '../../components/CardsBanner/CardsBanner';
import styles from './Accounts.module.scss';

const messages = defineMessages({
	metaTitle: {
		id: 'accounts.metaTitle',
		defaultMessage: 'Account',
	},
});
interface AccountsProps {
	refCurrency: CurrencyEnum | string;
}

const Accounts = ({ refCurrency }: AccountsProps) => (
	<>
		<Seo title={messages.metaTitle} />
		<div className={styles.content}>
			<WalletBlocks
				smallerContainer
				isRatesVisible
				isActionsVisible
				isHeaderVisible
				isFilterBarVisible
				isStartingBlockVisible
				buyButton
				refCurrency={refCurrency}
			/>
			<CardsBanner />
		</div>
	</>
);

export default Accounts;
