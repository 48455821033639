/* eslint-disable @typescript-eslint/no-explicit-any */
export default (func: any, wait: number) => {
	let timeout: any;

	// eslint-disable-next-line func-names
	return function executedFunction(...args: any) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};
