/* eslint-disable react/no-array-index-key */
import { useEffect } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RootState } from '../../redux/Store';
import { toggleWalletSidebar } from '../../redux/AppState/AppActions';
import Seo from '../../components/Seo/Seo';
import BackButton from '../../components/BackButton/BackButton';
import RefCurrencySelect from '../../components/RefCurrencySelect/RefCurrencySelect';
import WalletBlocks from '../../components/WalletBlocks/WalletBlocks';
import styles from './ManageWallets.module.scss';

const messages = defineMessages({
	title: {
		id: 'manageWallets.title',
		defaultMessage: 'Add wallets',
	},
	back: {
		id: 'history.backToPortfolio',
		defaultMessage: 'Back to Portfolio',
	},
	metaTitle: {
		id: 'manageWallets.metaTitle',
		defaultMessage: 'Add wallets',
	},
});

interface ManageWalletsProps {
	refCurrency: CurrencyEnum | string;
}

const ManageWallets = ({ refCurrency }: ManageWalletsProps) => {
	const dispatch = useDispatch();
	const { showWalletSidebar } = useSelector((state: RootState) => state?.AppState);
	const { formatMessage, locale } = useIntl();

	useEffect(() => {
		if (showWalletSidebar) dispatch(toggleWalletSidebar(false));
	}, [dispatch, showWalletSidebar]);

	return (
		<>
			<Seo title={messages.metaTitle} />
			<div className={styles.title}>
				<BackButton
					text={messages.back}
					link={`/${locale}/account`}
					className={styles.mb0}
				/>
				<div className={styles.refCurrency}>
					<RefCurrencySelect />
				</div>
			</div>
			<div className={classNames(styles.title, styles.ml)}>
				{formatMessage(messages.title)}
			</div>
			<WalletBlocks
				linkSmaller
				refCurrency={refCurrency}
				className={styles.walletBlocks}
				disabledLinks
				isToggleVisible
				isFilterBarVisible
				isRatesVisible
				isAddNewWalletsVisible
			/>
		</>
	);
};

export default ManageWallets;
