import { ReactNode, isValidElement, ChangeEventHandler } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import classNames from 'classnames';
import TestIds from '../../test/TestIds';
import styles from './Toggle.module.scss';

interface ToggleProps {
	id: string;
	name: string;
	disabled?: boolean;
	checked: boolean;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	value?: string;
	label?: MessageDescriptor | ReactNode;
	className?: string;
	labelClassName?: string;
	children?: ReactNode;
}

const Toggle = ({
	id,
	name,
	disabled = false,
	checked,
	onChange,
	value,
	label,
	className,
	labelClassName,
	children,
	...rest
}: ToggleProps) => {
	const { formatMessage } = useIntl();
	return (
		<label
			htmlFor={id}
			className={classNames(styles.field, className, { [styles.disabled]: disabled })}
		>
			{children && children}
			{label && (
				<span className={classNames(styles.label, labelClassName)}>
					{isValidElement(label) ? label : formatMessage(label as MessageDescriptor)}
				</span>
			)}
			<div className={styles.toggle}>
				<input
					data-cy={TestIds.Toggle}
					type="checkbox"
					checked={checked}
					className={styles.checkbox}
					disabled={disabled}
					id={id}
					name={name}
					onChange={onChange}
					value={value}
					{...rest}
				/>
				<div className={styles.slider} />
			</div>
		</label>
	);
};

export default Toggle;
