import queryString from 'query-string';
import { useContext } from 'react';
import { format } from 'date-fns';
import { defineMessages, useIntl } from 'react-intl';
import { ExportType } from '../../../../../redux/WalletHistoryState/WalletHistoryTypes';
import { getMerchantsOrdersUrl } from '../../../../../redux/endpoints';
import useQueryParams from '../../../../../hooks/useQueryParams';
import { ExportOptionConfig } from '../../../../../components/HistoryExportButton/HistoryExportButton';
import MerchantOrderHistoryContext from '../Contexts/MerchantHistoryContext';

const messages = defineMessages({
	exportOrders: {
		id: 'export.orders',
		defaultMessage: 'orders',
	},
});

const useMerchantOrderHistoryExportOptions = (): Array<ExportOptionConfig> => {
	const { formatMessage } = useIntl();
	const { projectId } = useContext(MerchantOrderHistoryContext);
	const [[page = 1, startDate, endDate, status]] = useQueryParams({
		page: undefined,
		startDate: undefined,
		endDate: undefined,
		status: undefined,
	});

	const params = queryString.stringify({
		merchantProjectId: projectId,
		page,
		fromDate: startDate,
		toDate: endDate,
		status,
	});

	const generationTime = format(new Date(), `yyyy-MM-dd'T'HH:mm`);
	const filenameDateBlock = `${format(new Date(startDate || 0), 'yyyy-MM-dd')}-${format(
		endDate ? new Date(endDate) : new Date(),
		'yyyy-MM-dd'
	)}_${generationTime}`;

	return [
		{
			type: ExportType.PDF,
			url: `${getMerchantsOrdersUrl()}/pdf?${params}`,
			filename: `${formatMessage(messages.exportOrders)}_${filenameDateBlock}.pdf`,
		},
		{
			type: ExportType.CSV,
			url: `${getMerchantsOrdersUrl()}/csv?${params}`,
			filename: `${formatMessage(messages.exportOrders)}_${filenameDateBlock}.csv`,
		},
	];
};

export default useMerchantOrderHistoryExportOptions;
