import { ReactNode } from 'react';
import WalletSidebar from '../WalletSidebar/WalletSidebar';
import styles from './AccountLayout.module.scss';

interface AccountLayoutProps {
	children: ReactNode | ReactNode[];
}

const AccountLayout = ({ children }: AccountLayoutProps) => {
	return (
		<div className={styles.container}>
			<div className={styles.flexGrid}>
				<WalletSidebar />
				{children}
			</div>
		</div>
	);
};

export default AccountLayout;
