/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { AppDispatch } from '../Store';
import { getDepositsFiatHistoryURL, getDepositsHistoryURL } from '../endpoints';
import {
	DepositHistoryActionType,
	ResetDepositHistoryAction,
	SetDepositHistoryDataAction,
} from './DepositHistoryTypes';
import axiosInstance from '../../helpers/axiosInstance';

const setDepositHistoryIsLoading = (isLoading: boolean) => ({
	type: DepositHistoryActionType.IS_LOADING,
	isLoading,
});

export const getDepositCryptoHistory = (
	startDate?: string,
	endDate?: string,
	page?: number,
	filter?: CurrencyEnum
) => (dispatch: AppDispatch) => {
	dispatch<any>(setDepositHistoryIsLoading(true));
	return axiosInstance
		.get(getDepositsHistoryURL(startDate, endDate, page, filter))
		.then(({ data }: AxiosResponse) => {
			dispatch<any>(setDepositHistoryIsLoading(false));
			return dispatch<SetDepositHistoryDataAction>({
				type: DepositHistoryActionType.SET_DATA,
				data,
			});
		});
};

export const getDepositFiatHistory = (startDate?: string, endDate?: string, page?: number) => (
	dispatch: AppDispatch
) => {
	dispatch<any>(setDepositHistoryIsLoading(true));
	return axiosInstance
		.get(getDepositsFiatHistoryURL(startDate, endDate, page))
		.then(({ data }: AxiosResponse) => {
			dispatch<any>(setDepositHistoryIsLoading(false));
			return dispatch<SetDepositHistoryDataAction>({
				type: DepositHistoryActionType.SET_DATA,
				data,
			});
		});
};

export const resetDepositsHistory = () => (dispatch: AppDispatch) => {
	return dispatch<ResetDepositHistoryAction>({ type: DepositHistoryActionType.RESET_DATA });
};
