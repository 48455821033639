import { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { values } from '../helpers/objectHelper/objectHelper';

enum RouteTokens {
	Language = 'lng',
	OrderId = 'orderId',
	PaymentButtonId = 'buttonId',
	DonationCurrency = 'currency',
}

export enum PaymentRoutes {
	Root,
	Order,
	Donation,
}

const PaymentRoutePaths = {
	get [PaymentRoutes.Root]() {
		return `/:${RouteTokens.Language}?/payments`;
	},

	get [PaymentRoutes.Order]() {
		return `${PaymentRoutePaths[PaymentRoutes.Root]}/orders/:${RouteTokens.OrderId}`;
	},

	get [PaymentRoutes.Donation]() {
		return `${PaymentRoutePaths[PaymentRoutes.Root]}/donations/:${
			RouteTokens.PaymentButtonId
		}/:${RouteTokens.DonationCurrency}?`;
	},
} as const;

type RouteParams = Partial<{ [key in RouteTokens]: string }>;

const usePaymentRoutes = () => {
	const { params } = useRouteMatch<RouteParams>();

	const getPath = useCallback((route: PaymentRoutes) => {
		return PaymentRoutePaths[route];
	}, []);

	const getParams = useCallback(() => {
		return params;
	}, [params]);

	const getUrl = useCallback(
		(route: PaymentRoutes, routeParams: RouteParams = {}) => {
			const mergedParams = { ...getParams(), ...routeParams };
			const path = getPath(route);
			const tokens = values(RouteTokens);
			return tokens
				.reduce((acc, token) => {
					return acc.replace(new RegExp(`:${token}\\?*`), mergedParams[token] || '');
				}, path)
				.replace(/\/\//g, '/');
		},
		[getPath, getParams]
	);

	return {
		getUrl,
		getPath,
		getParams,
	} as const;
};

export default usePaymentRoutes;
