import { fromEntries } from '../../../../helpers/objectHelper/objectHelper';
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import {
	PaymentButtonsDataState,
	PaymentButtonDataFetchPaymentButtonFulfilledAction,
	PaymentButtonDataFetchPaymentButtonPendingAction,
	PaymentButtonDataFetchPaymentButtonRejectedAction,
	PaymentButtonDataUpdatePaymentButtonAction,
	PaymentButtonDataUpdatePaymentButtonsAction,
	PaymentButtonsDataActionType,
	PaymentButtonDataDeletePaymentButtonAction,
} from './PaymentButtonsDataTypes';

const initialState: PaymentButtonsDataState = {
	paymentButtons: {},
};

export default (
	state = initialState,
	action:
		| PaymentButtonDataFetchPaymentButtonFulfilledAction
		| PaymentButtonDataFetchPaymentButtonPendingAction
		| PaymentButtonDataFetchPaymentButtonRejectedAction
		| PaymentButtonDataUpdatePaymentButtonAction
		| PaymentButtonDataUpdatePaymentButtonsAction
		| PaymentButtonDataDeletePaymentButtonAction
): PaymentButtonsDataState => {
	switch (action.type) {
		case PaymentButtonsDataActionType.UPDATE_PAYMENT_BUTTON:
			return {
				...state,
				paymentButtons: {
					...state.paymentButtons,
					[action.payload.id]: {
						status: RemoteStatus.Done,
						data: {
							...action.payload,
							isUsed:
								action.payload.isUsed === null
									? state.paymentButtons[action.payload.id]?.data?.isUsed ||
									  action.payload.isUsed
									: action.payload.isUsed,
						},
					},
				},
			};
		case PaymentButtonsDataActionType.DELETE_PAYMENT_BUTTON:
			return {
				...state,
				paymentButtons: {
					...state.paymentButtons,
					[action.payload]: { status: RemoteStatus.Error },
				},
			};
		case PaymentButtonsDataActionType.UPDATE_PAYMENT_BUTTONS:
			return {
				...state,
				paymentButtons: {
					...state.paymentButtons,
					...fromEntries(
						action.payload.map((x) => [
							x.id,
							{
								status: RemoteStatus.Done,
								data: {
									...x,
									isUsed:
										x.isUsed === null
											? state.paymentButtons[x.id]?.data?.isUsed || x.isUsed
											: x.isUsed,
								},
							},
						])
					),
				},
			};
		case PaymentButtonsDataActionType.FETCH_PAYMENT_BUTTON_PENDING:
			return {
				...state,
				paymentButtons: {
					...state.paymentButtons,
					[action.payload]: { status: RemoteStatus.InProgress },
				},
			};
		case PaymentButtonsDataActionType.FETCH_PAYMENT_BUTTON_REJECTED:
			return {
				...state,
				paymentButtons: {
					...state.paymentButtons,
					[action.payload.id]: {
						status: RemoteStatus.Error,
						error: action.payload.error,
					},
				},
			};
		case PaymentButtonsDataActionType.FETCH_PAYMENT_BUTTON_FULFILLED:
			return {
				...state,
				paymentButtons: {
					...state.paymentButtons,
					[action.payload.id]: {
						status: RemoteStatus.Done,
						data: {
							...action.payload,
							isUsed:
								action.payload.isUsed === null
									? state.paymentButtons[action.payload.id]?.data?.isUsed ||
									  action.payload.isUsed
									: action.payload.isUsed,
						},
					},
				},
			};
		default:
			return state;
	}
};
