/* eslint-disable radix */
/* eslint-disable no-plusplus */
import Decimal from 'decimal.js';
import { CurrencyEnum, currencyUtils } from '@spectrocoin/sc-currencies';
import { stringify } from 'query-string';
import CurrencyType from '../../enums/CurrencyType';
import { toPlainAmount } from '../currencyAmountHelper/currencyAmountHelper';
import { NetworkNameCode } from '../../redux/AccountsState/AccountsTypes';

export { CurrencyType };

Decimal.set({ rounding: Decimal.ROUND_DOWN });

export const toDecimal = (value: number | string, isInput = false) => {
	return new Decimal(toPlainAmount(value.toString(), isInput));
};

export const currencySymbols = {
	EUR: '€',
	USD: '$',
	GBP: '£',
};

export const swapSeparators = (value: string) => {
	return value
		.split('')
		.map((char) => (char === ',' ? '.' : char === '.' ? ',' : char))
		.join('');
};

export const getCurrencyProtocol = (currencyCode: CurrencyEnum) => {
	switch (currencyCode) {
		case CurrencyEnum.BTC:
			return 'bitcoin';
		case CurrencyEnum.LTC:
			return 'litecoin';
		case CurrencyEnum.XRP:
			return 'ripple';
		case CurrencyEnum.XLM:
			return 'stellar';
		case CurrencyEnum.ALGO:
			return 'algorand';
		default:
			return currencyCode.toLowerCase();
	}
};

export const formatPrecision = (
	amount: string | number,
	currencyCode: CurrencyEnum | null,
	isSeparatorOn = true,
	isInput = false,
	precision?: number
) => {
	const currencyPrecision =
		precision || (currencyCode && currencyUtils.tryGetConfig(currencyCode)?.precision);

	const overflowingPrecisionValue = currencyPrecision
		? new RegExp(`^(?<rounded>0\\.0{${currencyPrecision},}[^0])\\d*$`).exec(`${amount}`)?.groups
				?.rounded
		: null;

	const number =
		overflowingPrecisionValue ??
		toDecimal(amount, isInput).abs().toFixed(currencyPrecision).toString();

	if (isSeparatorOn) {
		return `${number.split('.')[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}${
			number.split('.')[1] ? `.${number.split('.')[1]}` : ``
		}`;
	}
	return number;
};

const getMessage = (currCode: CurrencyEnum, message: string | null) => {
	switch (currCode) {
		case CurrencyEnum.XLM:
		case CurrencyEnum.XEM:
			return { memo: message };
		case CurrencyEnum.XRP:
			return { dt: message };
		default:
			return {};
	}
};

const getAmount = (amount: string | null) => {
	return amount ? { amount } : {};
};

export const getQRValue = (
	currCode: CurrencyEnum,
	cAddress: string,
	msg: string | null,
	amount: string | null
) => {
	const params = stringify({ ...getAmount(amount), ...getMessage(currCode, msg) });
	return `${getCurrencyProtocol(currCode)}:${cAddress}${params ? `?${params}` : ''}`;
};

export const isErcCurrency = (currency: CurrencyEnum) =>
	currencyUtils.getConfigOrDefault(currency).isErc;

export const isFeeAccountNeeded = (currency: CurrencyEnum) => {
	if (isErcCurrency(currency) || currency === CurrencyEnum.TRX || currency === CurrencyEnum.BNB)
		return true;
	return false;
};

export const getCurrencyType = (currCode: CurrencyEnum): CurrencyType => {
	const currency = currencyUtils.tryGetConfig(currCode);
	if (!currency) return CurrencyType.None;
	return currency.isVirtual ? CurrencyType.Virtual : CurrencyType.Fiat;
};

export const getBlockchainLink = (
	network: NetworkNameCode,
	currencyCode: CurrencyEnum,
	transactionHash: string
): string => {
	if (network === NetworkNameCode.BTC && currencyCode === CurrencyEnum.BTC)
		return `https://mempool.space/tx/${transactionHash}`;
	if (
		network === NetworkNameCode.ETH &&
		(currencyCode === CurrencyEnum.ETH ||
			currencyCode === CurrencyEnum.USDT ||
			currencyCode === CurrencyEnum.USDC ||
			currencyCode === CurrencyEnum.TUSD ||
			currencyCode === CurrencyEnum.USDP ||
			currencyCode === CurrencyEnum.WBTC ||
			currencyCode === CurrencyEnum.LINK ||
			currencyCode === CurrencyEnum.DAI ||
			currencyCode === CurrencyEnum.UNI ||
			currencyCode === CurrencyEnum.MKR ||
			currencyCode === CurrencyEnum.AAVE ||
			currencyCode === CurrencyEnum.COMP ||
			currencyCode === CurrencyEnum.BAT ||
			currencyCode === CurrencyEnum.SUSHI ||
			currencyCode === CurrencyEnum.SHIB ||
			currencyCode === CurrencyEnum.MATIC ||
			currencyCode === CurrencyEnum.ZRX ||
			currencyCode === CurrencyEnum.MANA ||
			currencyCode === CurrencyEnum.UMA ||
			currencyCode === CurrencyEnum.HOT ||
			currencyCode === CurrencyEnum.CHZ ||
			currencyCode === CurrencyEnum.GRT ||
			currencyCode === CurrencyEnum.SNX ||
			currencyCode === CurrencyEnum.ENJ ||
			currencyCode === CurrencyEnum.EURC ||
			currencyCode === CurrencyEnum.SAND ||
			currencyCode === CurrencyEnum.BNK ||
			currencyCode === CurrencyEnum.BUSD)
	)
		return `https://etherscan.io/tx/${transactionHash}`;
	if (network === NetworkNameCode.XRP && currencyCode === CurrencyEnum.XRP)
		return `https://xrpscan.com/tx/${transactionHash}`;
	if (network === NetworkNameCode.XEM && currencyCode === CurrencyEnum.XEM)
		return `https://explorer.nemtool.com/#/s_tx?hash=${transactionHash}`;
	if (network === NetworkNameCode.XLM && currencyCode === CurrencyEnum.XLM)
		return `https://stellarchain.io/transactions/${transactionHash}`;
	if (network === NetworkNameCode.DASH && currencyCode === CurrencyEnum.DASH)
		return `https://explorer.dash.org/insight/tx/${transactionHash}`;
	if (network === NetworkNameCode.ALGO && currencyCode === CurrencyEnum.ALGO)
		return `https://algoexplorer.io/tx/${transactionHash}`;
	if (network === NetworkNameCode.LTC && currencyCode === CurrencyEnum.LTC)
		return `https://blockchair.com/litecoin/transaction/${transactionHash}`;
	if (network === NetworkNameCode.ADA && currencyCode === CurrencyEnum.ADA)
		return `https://cardanoscan.io/transaction/${transactionHash}`;
	if (network === NetworkNameCode.SOL && currencyCode === CurrencyEnum.SOL)
		return `https://solscan.io/tx/${transactionHash}`;
	if (network === NetworkNameCode.BCH && currencyCode === CurrencyEnum.BCH)
		return `https://blockchair.com/bitcoin-cash/transaction/${transactionHash}`;
	if (network === NetworkNameCode.AVAXC && currencyCode === CurrencyEnum.AVAX)
		return `https://snowtrace.io/tx/${transactionHash}`;
	if (network === NetworkNameCode.DOT && currencyCode === CurrencyEnum.DOT)
		return `https://polkadot.subscan.io/extrinsic/${transactionHash}`;
	if (network === NetworkNameCode.NEAR && currencyCode === CurrencyEnum.NEAR)
		return `https://explorer.near.org/transactions/${transactionHash}`;
	if (
		network === NetworkNameCode.TRX &&
		(currencyCode === CurrencyEnum.TRX ||
			currencyCode === CurrencyEnum.USDC ||
			currencyCode === CurrencyEnum.USDT)
	)
		return `https://tronscan.org/#/transaction/${transactionHash}`;
	if (
		network === NetworkNameCode.BSC &&
		(currencyCode === CurrencyEnum.BNB ||
			currencyCode === CurrencyEnum.BUSD ||
			currencyCode === CurrencyEnum.USDC ||
			currencyCode === CurrencyEnum.USDT)
	)
		return `https://bscscan.com/tx/${transactionHash}`;

	return '';
};
