/* eslint-disable no-param-reassign */
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import {
	PaymentButtonsListActionType,
	PaymentButtonsListDeleteFulfilledAction,
	PaymentButtonsListDeletePendingAction,
	PaymentButtonsListDeleteRejectedAction,
	PaymentButtonsListFetchButtonsFulfilledAction,
	PaymentButtonsListFetchButtonsPendingAction,
	PaymentButtonsListFetchButtonsRejectedAction,
	PaymentButtonsListResetPagesAction,
	PaymentButtonsListSetModeAction,
	PaymentButtonsListSetSearchAction,
	PaymentButtonsListSetTagsAction,
	PaymentButtonsListMode,
	PaymentButtonsListState,
	PaymentButtonsListResetDeleteAction,
	PaymentButtonsListSetStatusAction,
	PaymentButtonsListStatus,
} from './PaymentButtonsListTypes';

const initialState: PaymentButtonsListState = {
	pages: {},
	totalCount: 0,
	pageSize: 20,
	status: PaymentButtonsListStatus.Initial,
	delete: { status: RemoteStatus.None },
	mode: PaymentButtonsListMode.View,
	filter: {
		search: '',
		tags: {
			crypto: false,
			disabled: false,
			enabled: false,
			fiat: false,
		},
	},
};

export default (
	state = initialState,
	action:
		| PaymentButtonsListDeleteFulfilledAction
		| PaymentButtonsListDeletePendingAction
		| PaymentButtonsListDeleteRejectedAction
		| PaymentButtonsListFetchButtonsFulfilledAction
		| PaymentButtonsListFetchButtonsPendingAction
		| PaymentButtonsListFetchButtonsRejectedAction
		| PaymentButtonsListResetPagesAction
		| PaymentButtonsListSetModeAction
		| PaymentButtonsListSetSearchAction
		| PaymentButtonsListSetTagsAction
		| PaymentButtonsListResetDeleteAction
		| PaymentButtonsListSetStatusAction
): PaymentButtonsListState => {
	switch (action.type) {
		case PaymentButtonsListActionType.FETCH_BUTTONS_PENDING:
			return {
				...state,
				pages: {
					...state.pages,
					[action.payload]: { status: RemoteStatus.InProgress, data: [] },
				},
			};
		case PaymentButtonsListActionType.FETCH_BUTTONS_REJECTED:
			return {
				...state,
				pages: {
					...state.pages,
					[action.payload.page]: {
						status: RemoteStatus.Error,
						data: [],
						error: action.payload.error,
					},
				},
			};
		case PaymentButtonsListActionType.FETCH_BUTTONS_FULFILLED:
			return {
				...state,
				totalCount: action.payload.buttons.totalElements,
				pageSize: action.payload.buttons.pageable.pageSize,
				pages: {
					...state.pages,
					[action.payload.page]: {
						status: RemoteStatus.Done,
						data: action.payload.buttons.content.map((x) => x.id),
					},
				},
			};
		case PaymentButtonsListActionType.SET_TAGS:
			return {
				...state,
				filter: {
					...state.filter,
					tags: {
						...state.filter.tags,
						...action.payload,
					},
				},
			};
		case PaymentButtonsListActionType.SET_SEARCH:
			return {
				...state,
				filter: {
					...state.filter,
					search: action.payload,
				},
			};
		case PaymentButtonsListActionType.SET_MODE:
			return {
				...state,
				mode: action.payload,
			};
		case PaymentButtonsListActionType.SET_STATUS:
			return {
				...state,
				status: action.payload,
			};
		case PaymentButtonsListActionType.RESET_PAGES:
			return {
				...state,
				pages: {},
			};
		case PaymentButtonsListActionType.RESET_DELETE:
			return {
				...state,
				delete: {
					status: RemoteStatus.None,
				},
			};
		case PaymentButtonsListActionType.DELETE_PENDING:
			return {
				...state,
				delete: {
					status: RemoteStatus.InProgress,
				},
			};
		case PaymentButtonsListActionType.DELETE_REJECTED:
			return {
				...state,
				delete: {
					status: RemoteStatus.Error,
					error: action.payload,
				},
			};
		case PaymentButtonsListActionType.DELETE_FULFILLED:
			return {
				...state,
				delete: {
					status: RemoteStatus.Done,
				},
			};
		default:
			return state;
	}
};
