import { FC, FormEvent, useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/Store';
import { getCardContractURL } from '../../../../redux/endpoints';
import BackButton from '../../../../components/BackButton/BackButton';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import { getAvailableCountries, setOrderStep } from '../../../../redux/CardsState/CardsActions';
import { CardType, OrderSteps } from '../../../../redux/CardsState/CardsTypes';
import Button, { ButtonType, ButtonStyle } from '../../../../components/Button/Button';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import plasticCardImgPath from './images/plastic.svg';
import virtualCardImgPath from './images/virtual.svg';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import styles from './Terms.module.scss';
import Loader from '../../../../components/Loader/Loader';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	title: {
		id: 'terms.title',
		defaultMessage: 'Terms & Conditions',
	},
	subTitle: {
		id: 'terms.subTitle',
		defaultMessage: 'You have to agree to the Cards terms and conditions.',
	},
	checkboxLabel: {
		id: 'terms.checkboxLabel',
		defaultMessage:
			'I read and accept the <termsLink>Terms and Conditions</termsLink> and <privacyLink>Privacy Policy</privacyLink>',
	},
	buttonText: {
		id: 'terms.buttonText',
		defaultMessage: 'Next',
	},
	somethingWentWrong: {
		id: 'claimIBAN.somethingWentWrong',
		defaultMessage: 'Something went wrong. Please try again later.',
	},
});

interface TermsProps {
	type: CardType;
	isRha?: boolean;
}

const Terms: FC<TermsProps> = ({ type, isRha }) => {
	const dispatch = useDispatch();
	const { locale, formatMessage } = useIntl();
	const { formData, isCardUser, availableCountries, requiresVerification } = useSelector(
		(state: RootState) => state.CardsState
	);
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const [agree, setAgree] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<MessageDescriptor | null>(null);

	const handleInputChange = () => {
		setError(null);
		return setAgree(!agree);
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setError(null);
		setIsLoading(true);
		return axiosInstance
			.post(getCardContractURL(), {})
			.then(() => {
				if (isCardUser) {
					if (
						!requiresVerification &&
						availableCountries.includes(user!.country!) &&
						formData?.cardType?.type === CardType.VIRTUAL
					) {
						if (isRha) {
							return dispatch(setOrderStep(OrderSteps.LINKED_WALLETS));
						}

						return dispatch(setOrderStep(OrderSteps.CONFIRM));
					}
					return dispatch(setOrderStep(OrderSteps.DELIVERY_ADDRESS));
				}
				return dispatch(setOrderStep(OrderSteps.TIN));
			})
			.catch(() => {
				return setError(messages.somethingWentWrong);
			})
			.then(() => setIsLoading(false));
	};

	useEffectOnce(() => {
		void dispatch(getAvailableCountries(type));
	});

	if (!availableCountries) return <Loader />;

	return (
		<div className={styles.container}>
			<BackButton link={`/${locale}/cards/order`} className={styles.back} />
			<InfoHead
				title={messages.title}
				subTitle={formatMessage(messages.subTitle)}
				img={
					formData?.cardType?.type === CardType.PLASTIC
						? plasticCardImgPath
						: formData?.cardType?.type === CardType.VIRTUAL
						? virtualCardImgPath
						: null
				}
			/>
			<form onSubmit={handleSubmit} className={styles.form}>
				<Checkbox
					id="terms"
					checked={agree}
					onChange={handleInputChange}
					className={styles.checkbox}
					isDisabled={isLoading}
				>
					<FormattedMessage
						{...messages.checkboxLabel}
						values={{
							termsLink: (text: string[]) => (
								<a
									href={`/${locale}/terms/pervesk-card-terms-and-conditions.html`}
									target="_blank"
									rel="noreferrer"
								>
									{text}
								</a>
							),
							privacyLink: (text: string[]) => (
								<a
									href={`/${locale}/terms/pervesk-privacy-policy.html`}
									target="_blank"
									rel="noreferrer"
								>
									{text}
								</a>
							),
						}}
					/>
				</Checkbox>
				<Button
					text={messages.buttonText}
					type={ButtonType.SUBMIT}
					buttonStyle={ButtonStyle.PRIMARY}
					className={styles.button}
					isDisabled={!agree || isLoading}
					isLoading={isLoading}
				/>
				{error && <div className={styles.error}>{formatMessage(error)}</div>}
			</form>
		</div>
	);
};

export default Terms;
