import { FC } from 'react';
import classNames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import TestIds, { formatTestId } from '../../test/TestIds';
import styles from './TagFilter.module.scss';

const messages = defineMessages({
	title: {
		id: 'manageWallets.filterTitle',
		defaultMessage: 'Filter by Keywords',
	},
});

export type Tag = {
	id: string;
	label: string;
	isActive: boolean;
};

type TagFilterProps = {
	title?: string;
	tags: Tag[];
	onToggle: (tag: Tag) => void;
};

const TagFilter: FC<TagFilterProps> = ({ tags, title, onToggle }) => {
	const { formatMessage } = useIntl();
	return (
		<div className={styles.container}>
			<span className={styles.title}>{title || formatMessage(messages.title)}</span>
			<div className={styles.tags}>
				{tags.map((tag) => (
					<div
						data-cy={`${formatTestId(TestIds.TagFilterTag_0, tag.id)}`}
						key={tag.id}
						className={classNames(styles.tag, { [styles.active]: tag.isActive })}
						onClick={() => onToggle(tag)}
					>
						{tag.label}
					</div>
				))}
			</div>
		</div>
	);
};

export default TagFilter;
