import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { RemoteData, RemoteError, RemoteStatus } from '../interfaces/RemoteData';

type ActionType<TResult, TParams extends unknown[]> = (...args: TParams) => Promise<TResult>;

const useRemote = <TResult, TParams extends unknown[]>(action: ActionType<TResult, TParams>) => {
	const [status, setStatus] = useState<RemoteData<TResult>>({ status: RemoteStatus.None });

	const doAction = useCallback(
		(...args: TParams) => {
			setStatus({ status: RemoteStatus.InProgress });
			return action(...args)
				.then((response) => {
					setStatus({ status: RemoteStatus.Done, data: response });
					return response;
				})
				.catch((error: AxiosError<RemoteError>) => {
					setStatus({ status: RemoteStatus.Error, error: error.response?.data });
					throw error;
				});
		},
		[action]
	);

	return [status, doAction] as const;
};

export const useRemoteShared = <TResult>() => {
	const [status, setStatus] = useState<RemoteData<TResult>>({ status: RemoteStatus.None });

	const doAction = useCallback((action: Promise<TResult>) => {
		setStatus({ status: RemoteStatus.InProgress });
		return action
			.then((response) => {
				setStatus({ status: RemoteStatus.Done, data: response });
				return response;
			})
			.catch((error: AxiosError<RemoteError>) => {
				setStatus({ status: RemoteStatus.Error, error: error.response?.data });
				throw error;
			});
	}, []);

	return [status, doAction] as const;
};

export default useRemote;
