import { AxiosError } from 'axios';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
	Notification as NotificationComponent,
	NotificationDto,
} from '@spectrocoin/user-notifications';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import qs from 'query-string';
import styles from './NotificationsList.module.scss';
import useQueryPagination from '../../../hooks/useQueryPagination';
import { RemoteData, RemoteError, RemoteStatus } from '../../../interfaces/RemoteData';
import PageableResponse from '../../../interfaces/PageableResponse';
import { getNotificationsMarkURL, getNotificationsURL } from '../../../redux/endpoints';
import Pagination from '../../../components/Pagination/Pagination';
import Loading from '../../Merchants/Shared/Loading/Loading';
import { getErrorMessageOrDefault } from '../../../helpers/errorMessageHelper/errorMessageHelper';
import WhiteContainer from '../../../components/WhiteContainer/WhiteContainer';
import PageTitle from '../PageTitle/PageTitle';
import NotFound from '../../Merchants/Shared/NotFound/NotFound';
import { NotificationContext } from '../Context/NotificationContext';
import NotificationActions from '../../../redux/NotificationsState/NotificationsActions';
import axiosInstance from '../../../helpers/axiosInstance';

const messages = defineMessages({
	newNotifications: {
		id: 'notifications.newNotifications',
		defaultMessage: 'New notifications',
	},
	allNotifications: {
		id: 'notifications.allNotifications',
		defaultMessage: 'All notifications',
	},
	markAll: {
		id: 'notifications.markAll',
		defaultMessage: 'Mark all as read',
	},
	notifications: {
		id: 'notifications.notifications',
		defaultMessage: 'Notifications',
	},
	empty: {
		id: 'notifications.empty',
		defaultMessage: 'No notifications',
	},
});

const Section: FC<{ notifications: NotificationDto[]; header: ReactNode }> = ({
	notifications,
	header,
}) => {
	const { locale } = useIntl();
	return (
		<div className={styles.section}>
			{header}
			{notifications.map((notification) => (
				<div key={notification.id} className={styles.sectionItem}>
					<div
						className={classNames(styles.bubble, {
							[styles.show]: !notification.isRead,
						})}
					/>
					<NotificationComponent
						className={styles.row}
						key={notification.id}
						notification={notification}
						lang={locale}
					/>
				</div>
			))}
		</div>
	);
};

const List: FC<{ notifications: NotificationDto[] }> = ({ notifications }) => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();

	const groupedNotifications = notifications.reduce(
		(groups, notification) => {
			if (notification.isRead) groups.read.push(notification);
			else groups.unread.push(notification);
			return groups;
		},
		{ read: [] as NotificationDto[], unread: [] as NotificationDto[] }
	);

	const unreadNotificationIds = groupedNotifications.unread.map((n) => n.id);

	useEffect(() => {
		if (unreadNotificationIds.length === 0) return;
		axiosInstance
			.put(getNotificationsMarkURL(), { ids: unreadNotificationIds })
			.then(async () => {
				await dispatch(NotificationActions.getUnreadNotificationCount());
				await dispatch(NotificationActions.getUnreadNotifications());
			})
			.catch((e: AxiosError<RemoteError>) => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(unreadNotificationIds)]);

	if (notifications.length === 0) return <NotFound message={formatMessage(messages.empty)} />;

	return (
		<>
			{groupedNotifications.unread.length > 0 && (
				<Section
					notifications={groupedNotifications.unread}
					header={<PageTitle title={formatMessage(messages.newNotifications)} />}
				/>
			)}
			{groupedNotifications.read.length > 0 && (
				<Section
					notifications={groupedNotifications.read}
					header={<PageTitle title={formatMessage(messages.allNotifications)} />}
				/>
			)}
		</>
	);
};

export default function () {
	const { formatMessage } = useIntl();
	const [queryPage, setPage] = useQueryPagination();
	const [{ status, data, error }, setData] = useState<
		RemoteData<PageableResponse<NotificationDto[]>>
	>({
		status: RemoteStatus.None,
	});

	const load = useCallback((page: number) => {
		setData((current) => ({
			status: RemoteStatus.InProgress,
			data: current.data,
		}));

		void axiosInstance
			.get(getNotificationsURL(), {
				params: { page: page - 1, sort: ['isRead,asc', 'created,desc'], size: 10 },
				paramsSerializer: {
					serialize: (params: any) => {
						return qs.stringify(params, { arrayFormat: 'none' });
					},
				},
			})
			.then((res) => {
				setData({
					status: RemoteStatus.Done,
					data: res.data,
				});
			})
			.catch((e: AxiosError<RemoteError>) => {
				setData({
					status: RemoteStatus.Error,
					error: e.response?.data,
				});
			});
	}, []);

	useEffect(() => {
		load(queryPage);
	}, [queryPage]);

	return (
		<NotificationContext.Provider
			value={{ reload: () => (queryPage !== 1 ? setPage(1) : load(1)) }}
		>
			<div className={styles.root}>
				<h4 className={styles.title}>{formatMessage(messages.notifications)}</h4>
				<WhiteContainer className={styles.container}>
					<div className={styles.body}>
						{(() => {
							switch (status) {
								case RemoteStatus.InProgress:
									return <Loading />;
								case RemoteStatus.Error:
									return formatMessage(getErrorMessageOrDefault(error));
								case RemoteStatus.Done:
									return <List notifications={data!.content} />;
								default:
									return null;
							}
						})()}
					</div>
					{status !== RemoteStatus.None && (
						<div className={styles.pagination}>
							<Pagination totalCount={data?.totalElements} pageSize={data?.size} />
						</div>
					)}
				</WhiteContainer>
			</div>
		</NotificationContext.Provider>
	);
}
