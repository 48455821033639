import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import styles from './CashDepositDetails.module.scss';
import PaymentMethod from '../../../../redux/DepositState/DepositTypes';
import { getPaymentMethodsDetailsURL } from '../../../../redux/endpoints';
import Loader from '../../../../components/Loader/Loader';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import Seo from '../../../../components/Seo/Seo';
import depositMessages from '../../../../redux/DepositState/DepositMessages';
import TestIds from '../../../../test/TestIds';
import HowTo from '../../Shared/HowToDeposit/HowToDeposit';
import PaymentMethodLimits from '../../Shared/PaymentMethodLimits/PaymentMethodLimits';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	done: {
		id: 'base.done',
		defaultMessage: 'Done',
	},
	depositWith: {
		id: 'walletdeposit.withTitle',
		defaultMessage: 'Deposit with {provider}',
	},
});

interface Params {
	paymentMethodId: string;
}

const CashDepositDetails = () => {
	const { formatMessage, locale } = useIntl();
	const { paymentMethodId } = useParams<Params>();
	const [isLoading, setIsLoading] = useState(true);
	const [paymentMethodDetails, setPaymentMethodDetails] = useState<PaymentMethod>();

	const getPaymentMethodDetails = useCallback(async () => {
		const response = await axiosInstance.get<PaymentMethod>(
			getPaymentMethodsDetailsURL(paymentMethodId)
		);
		return response.data;
	}, [paymentMethodId]);

	useEffect(() => {
		void (async () => {
			setIsLoading(true);
			const paymentMethod = await getPaymentMethodDetails();
			if (!paymentMethod) return;

			setPaymentMethodDetails(paymentMethod);
			setIsLoading(false);
		})();
	}, [formatMessage, getPaymentMethodDetails]);

	if (isLoading) return <Loader className={styles.loader} />;

	const {
		activePaymentMethodFees,
		baseCurrencyCode,
		fee,
		maxAmount,
		maxFee,
		minAmount,
		minFee,
		paymentMethodLimits,
		title,
		duration,
		providerType,
	} = paymentMethodDetails!;

	return (
		<div data-cy={TestIds.cashDepositsDetailsView}>
			<Seo title={depositMessages.metaCashDepositsTitle} />
			<PageTitle
				isPreviousPageLinkVisibleOnDesktop
				title={formatMessage(messages.depositWith, {
					provider: title,
				})}
				previousPageLink={`/${locale}/deposit/cash`}
				isPreviousPageLinkVisibleOnMobile={false}
			/>
			<div className={styles.container}>
				<div className={styles.column}>
					<HowTo
						duration={duration}
						paymentMethodName={title}
						providerType={providerType}
					/>
					<Button
						buttonStyle={ButtonStyle.PRIMARY}
						className={styles.button}
						link={`/${locale}/account`}
						text={formatMessage(messages.done)}
						type={ButtonType.LINK}
						data-cy={TestIds.button}
					/>
				</div>
				<div className={classNames(styles.column, styles.limits)}>
					<PaymentMethodLimits
						depositCurrencyCode={baseCurrencyCode}
						activePaymentMethodFees={activePaymentMethodFees}
						baseCurrencyCode={baseCurrencyCode}
						fee={fee}
						limits={paymentMethodLimits}
						maxAmount={maxAmount}
						maxFee={maxFee}
						minAmount={minAmount}
						minFee={minFee}
						paymentMethodName={title}
					/>
				</div>
			</div>
		</div>
	);
};

export default CashDepositDetails;
