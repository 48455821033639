import axios from 'axios';
import {
	axiosConfig,
	getAxiosRequestInterceptor,
	getAxiosResponseInterceptor,
} from '../configs/axios.config';

const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.request.use(...getAxiosRequestInterceptor());
axiosInstance.interceptors.response.use(...getAxiosResponseInterceptor());

export default axiosInstance;
