import {
	createContext,
	FC,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';
import Select, { components, Theme, StylesConfig, GroupBase } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import baseMsg from '../../messages/base.messages';
import { RootState } from '../../redux/Store';
import toggleModal from '../../redux/ModalState/ModalActions';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import AutoConvertCurrency from '../../pages/Merchants/Shared/AutoConvertCurrency/AutoConvertCurrency';
import { RTLLanguages } from '../../redux/LanguageState/LanguageTypes';
import TestIds, { formatTestId } from '../../test/TestIds';

import styles from './AccountSelect.module.scss';
import useEffectOnce from '../../hooks/useEffectOnce';

const messages = defineMessages({
	noResult: {
		id: 'convertInput.noResult',
		defaultMessage: 'No result',
	},
	chooseCurrency: {
		id: 'merchants.choose_currency_account',
		defaultMessage: 'Choose currency and settlement account',
	},
});

type AccountOption = {
	currencyCode: CurrencyEnum;
	currencyName: string;
	value: string;
};

const selectStyles: StylesConfig<AccountOption, false, GroupBase<AccountOption>> = {
	control: (provided) => ({
		...provided,
		boxShadow: 'none',
		border: 'none',
		background: 'transparent',
		color: '#102D6F',
		fontSize: '12px',
		display: 'flex',
		cursor: 'pointer',
		position: 'absolute',
		width: '100%',
	}),
	input: (provided, props) => ({
		...provided,
		visibility: 'visible',
		cursor: 'pointer',
		fontSize: '14px',
		height: '30px',
		zIndex: 50,
		marginLeft: '10px',
		marginRight: '10px',
		marginTop: '12px',
		// eslint-disable-next-line @typescript-eslint/dot-notation, dot-notation
		textAlign: props['isRtl'] ? 'right' : 'left',
		// eslint-disable-next-line @typescript-eslint/dot-notation, dot-notation
		padding: props['isRtl'] ? '5px 5px 5px 30px' : '5px 30px 5px 5px',
		borderRadius: '3px',
		color: 'black',
		border: '1px solid #D9DBE5',
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: '0',
		minWidth: '155px',
		cursor: 'pointer',
		height: '45px',
		textAlign: 'right',
		alignItem: 'center',
		flexFlow: 'row wrap',
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	dropdownIndicator: (prodived) => ({
		...prodived,
		display: 'none',
	}),
	menu: (provided) => ({
		...provided,
		background: '#ffffff',
		borderRadius: '4px',
		padding: '0',
		zIndex: '10',
		cursor: 'pointer',
		textAlign: 'left',
		border: '0',
		paddingTop: '45px',
	}),
	menuList: (provided) => ({
		...provided,
		background: '#ffffff',
		cursor: 'pointer',
		padding: '0',
		border: '0',
	}),
};

const selectThemeColors = {
	primary: '#4972f4',
	primary25: '#f5f8ff',
	primary50: '#f5f8ff',
	primary75: '#4972f4',
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type AccountSelectComponentProps<T = {}> = {
	data: {
		value: string;
		currencyCode: CurrencyEnum;
		currencyName: string;
	} & T;
	innerProps?: any;
};

const optionFilter = ({ currencyCode, currencyName }: AccountOption, input: string) =>
	input
		.split(' ')
		.every((inputToken) =>
			[currencyName, currencyCode].some((searchToken) =>
				new RegExp(inputToken.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i').test(
					searchToken
				)
			)
		);

type MobileAccountSelectModalProps = {
	options: AccountOption[];
	selected: string;
	onChangeSelect: (val: AccountOption) => void;
	setIsSelectOpen: (isOpen: boolean) => void;
	componentOverrides: {
		Option: FC<AccountSelectComponentProps>;
	};
};

const MobileAccountSelectModal: FC<MobileAccountSelectModalProps> = ({
	options,
	selected,
	onChangeSelect,
	setIsSelectOpen,
	componentOverrides: { Option },
}) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const [nameFilter, setNameFilter] = useState<string>('');
	const { deviceOS } = useSelector((state: RootState) => state.AppState);

	const onClick = (val: AccountOption) => {
		onChangeSelect(val);
		setIsSelectOpen(false);
		return dispatch<any>(toggleModal());
	};

	const closeModal = () => {
		setIsSelectOpen(false);
		return dispatch<any>(toggleModal());
	};

	return (
		<div className={styles.modal}>
			<div className={styles.closeIcon} onClick={() => closeModal()} />
			<div className={classNames(styles.mobileInputContainer)}>
				<input
					className={styles.searchInput}
					placeholder={formatMessage(baseMsg.search)}
					onChange={(event: any) => setNameFilter(event.target.value)}
					type="text"
				/>
			</div>
			<ul className={styles.mobileSelect}>
				{options
					.filter((o: AccountOption) => optionFilter(o, nameFilter))
					.map((o: AccountOption) => {
						return (
							<li
								key={o.value}
								className={classNames({
									[styles.selected]: selected === o.value,
								})}
								onClick={() => onClick(o)}
							>
								<Option data={o} />
							</li>
						);
					})}
			</ul>
		</div>
	);
};

type AccountSelectContextType = {
	isOpen: boolean;
	options: AccountOption[];
};

const AccountSelectContext = createContext<AccountSelectContextType>({
	isOpen: false,
	options: [],
});

const SearchInput = ({ ...props }: any) => {
	const { formatMessage } = useIntl();
	const { isOpen } = useContext(AccountSelectContext);

	if (!isOpen) return null;
	return (
		<div
			className={styles.inputContainer}
			onClick={(e) => e.nativeEvent.stopImmediatePropagation()}
		>
			<div className={styles.inputGroup}>
				<components.Input
					{...props}
					data-cy={TestIds.AccountSelectSearch}
					id="searchInput"
					className={styles.searchInput}
					placeholder={formatMessage(baseMsg.search)}
				/>
			</div>
		</div>
	);
};

const MenuList = ({ ...props }: any) => {
	return (
		<div data-cy={TestIds.AccountSelectOptions}>
			<components.MenuList {...props} />
		</div>
	);
};

const AutoConvertCurrencyOption = ({
	innerProps,
	data: { value, currencyCode, currencyName },
}: AccountSelectComponentProps<any>) => {
	return (
		<div
			className={styles.selectOption}
			data-cy={formatTestId(TestIds.AccountSelectOption_0, value)}
			{...innerProps}
		>
			<AutoConvertCurrency
				className={classNames(styles.autoConvertOption, 'convert_option')}
				iconClassName={styles.autoConvertIcon}
				currencyCode={currencyCode}
				currencyName={currencyName}
			/>
		</div>
	);
};

const AutoCovertCurrencySelectedOption = ({
	innerProps,
	data: { value: selectedValue },
}: AccountSelectComponentProps) => {
	const { options } = useContext(AccountSelectContext);
	const wallet = options.find((x) => x.value === selectedValue);
	if (!wallet) return null;
	return (
		<AutoConvertCurrency
			className={classNames(styles.autoConvertSelectedOption, 'convert_selected')}
			iconClassName={styles.autoConvertIcon}
			currencyCode={wallet.currencyCode}
			currencyName={wallet.currencyName}
			{...innerProps}
		/>
	);
};

type AccountSelectProps = {
	label: string;
	labelClassName?: string;
	value: string | null;
	onChange: (value: string) => void;
	componentOverrides?: {
		Option?: FC<AccountSelectComponentProps>;
		SingleValue?: FC<AccountSelectComponentProps>;
	};
	options: AccountOption[];
};

const AccountSelect: FC<AccountSelectProps> = ({
	value,
	onChange,
	label,
	labelClassName,
	componentOverrides = {},
	options,
}) => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const dispatch = useDispatch();
	const containerRef = useRef<HTMLDivElement>(null);
	const selectRef = useRef<any /* types are garbage on this version */>(null);
	const { isTablet } = useSelector((state: RootState) => state?.AppState);
	const { activeLanguage } = useSelector((state: RootState) => state.LanguageState);

	const isRTL = RTLLanguages.includes(activeLanguage.toLowerCase());

	useOnClickOutside(
		containerRef,
		() => {
			setIsOpen(false);
		},
		true
	);

	const selectValue = useMemo(() => options.find((x) => x.value === value) || options[0], [
		options,
		value,
	]);

	const onSelectChange = useCallback(
		({ value: newValue }: AccountOption) => {
			setIsOpen(false);
			onChange(newValue!);
		},
		[onChange]
	);

	useEffect(() => {
		if (isTablet && isOpen) {
			dispatch<any>(
				toggleModal(
					<MobileAccountSelectModal
						options={options}
						selected={selectValue.value}
						onChangeSelect={(val: any) => onSelectChange(val)}
						setIsSelectOpen={setIsOpen}
						componentOverrides={{
							Option: componentOverrides?.Option || AutoConvertCurrencyOption,
						}}
					/>
				)
			);
		}
	}, [isOpen, isTablet]);

	useEffectOnce(() => {
		if (!value) onSelectChange(selectValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	useEffect(() => {
		if (isOpen) selectRef.current?.focus();
	}, [isOpen]);

	return (
		<AccountSelectContext.Provider value={{ isOpen, options }}>
			<div className={styles.host} data-cy={TestIds.AccountSelect}>
				<label className={classNames(styles.label, labelClassName)}>{label}</label>
				<div
					className={styles.container}
					data-cy={formatTestId(TestIds.AccountSelectValue_0, selectValue.value)}
				>
					<div
						data-cy={TestIds.AccountSelectValue}
						className={classNames(styles.selectedValue, {
							[styles.selectActive]: isOpen,
						})}
						ref={containerRef}
						onClick={() => setIsOpen((current) => !current)}
					>
						{(componentOverrides.SingleValue && (
							<componentOverrides.SingleValue data={selectValue} />
						)) || <AutoCovertCurrencySelectedOption data={selectValue} />}
					</div>
					{isOpen && !isTablet && (
						<Select
							ref={selectRef}
							noOptionsMessage={() => formatMessage(messages.noResult)}
							value={selectValue}
							onChange={onSelectChange}
							options={options}
							isSearchable
							isClearable={false}
							components={{
								Option: AutoConvertCurrencyOption,
								...componentOverrides,
								Input: SearchInput,
								SingleValue: () => null,
								MenuList,
							}}
							menuIsOpen={isOpen}
							filterOption={({ data }, input) => optionFilter(data, input)}
							styles={selectStyles}
							classNamePrefix="select_account"
							isRtl={isRTL}
							theme={(theme: Theme) => ({
								...theme,
								colors: {
									...theme.colors,
									...selectThemeColors,
								},
							})}
						/>
					)}
				</div>
			</div>
		</AccountSelectContext.Provider>
	);
};

export default AccountSelect;
