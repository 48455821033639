import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './PageTitle.module.scss';
import TextNode, { TextStyles } from '../../../components/TextNode/TextNode';

export enum TextPosition {
	Left = 'left',
	Center = 'center',
}

type PageTitleProps = {
	title: string;
	children?: ReactNode;
	className?: string;
	position?: TextPosition;
};

const PageTitle: FC<PageTitleProps> = ({
	title,
	children,
	className,
	position = TextPosition.Left,
}) => {
	return (
		<div className={classNames(styles.container, styles[position], className)}>
			<div className={styles.title}>
				<TextNode style={TextStyles.BreakWord}>{title}</TextNode>
			</div>
			{children && <div className={styles.actions}>{children}</div>}
		</div>
	);
};

export default PageTitle;
