/* eslint-disable no-unused-expressions */
import 'react-app-polyfill/ie11';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { isLiveEnvironment } from './helpers/globalScriptHelper/globalScriptHelper';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'core-js/stable';
import './stylesheets/styles.scss';
import 'font-awesome/css/font-awesome.css';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
isLiveEnvironment({ allowPP: true }) &&
	Sentry.init({
		dsn: 'https://5e548c33b6c540b2b65cf9937bc95d18@o1071378.ingest.sentry.io/6098257',
		integrations: [new Integrations.BrowserTracing()],
		environment: isLiveEnvironment() ? 'production' : 'pp',

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});

const root = createRoot(document.getElementById('root')!);

root.render(
	<StrictMode>
		<App />
	</StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
// 	if ('serviceWorker' in navigator) {
// 		navigator.serviceWorker.register(`/service-worker.js`);
// 	}
// }
