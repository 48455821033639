import { useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';
import MarketingSlider from '../../components/MarketingSlider/MarketingSlider';
import CardsInfoBanner from '../../components/CardsInfoBanner/CardsInfoBanner';
import CardControl from '../../pages/Cards/Shared/CardControl/CardControl';
import styles from './CardsSidebar.module.scss';

const WalletSidebar = () => {
	const {
		showCardsSidebar,
		isMobile,
		isTablet,
		showMarketingSlider,
		showCardsInfoBanner,
		showCardControl,
	} = useSelector((state: RootState) => state.AppState);
	if (showCardsSidebar)
		return (
			<div className={styles.sidebar}>
				{showCardControl && <CardControl />}
				{!isMobile && !isTablet && showCardsInfoBanner && <CardsInfoBanner />}
				{!isMobile && !isTablet && showMarketingSlider && <MarketingSlider />}
			</div>
		);
	return null;
};

export default WalletSidebar;
