import { defineMessages, MessageDescriptor } from 'react-intl';

interface Messages {
	[key: string]: MessageDescriptor;
}

const currencies: Messages = defineMessages({
	BTC: {
		id: 'currencies.btc.name',
		defaultMessage: 'Bitcoin',
	},
	CHP: {
		id: 'currencies.chp.name',
		defaultMessage: 'CoinPoker',
	},
	SOL: {
		id: 'currencies.sol.name',
		defaultMessage: 'Solana',
	},
	BNB: {
		id: 'currencies.bnb.name',
		defaultMessage: 'BNB',
	},
	BUSD: {
		id: 'currencies.busd.name',
		defaultMessage: 'Binance USD',
	},
	EURC: {
		id: 'currencies.eurc.nam',
		defaultMessage: 'EURC',
	},
	SAND: {
		id: 'currencies.sand.name',
		defaultMessage: 'Sandbox',
	},
	MASK: {
		id: 'currencies.mask.name',
		defaultMessage: 'Mask Network',
	},
	ETH: {
		id: 'currencies.eth.name',
		defaultMessage: 'Ether',
	},
	XEM: {
		id: 'currencies.xem.name',
		defaultMessage: 'Nem',
	},
	DASH: {
		id: 'currencies.dash.name',
		defaultMessage: 'Dash',
	},
	BNK: {
		id: 'currencies.bnk.name',
		defaultMessage: 'Banker',
	},
	BNK223: {
		id: 'currencies.bnk223.name',
		defaultMessage: 'Banker223',
	},
	LTC: {
		id: 'currencies.ltc.name',
		defaultMessage: 'Litecoin',
	},
	USDT: {
		id: 'currencies.usdt.name',
		defaultMessage: 'Tether',
	},
	TUSD: {
		id: 'currencies.tusd.name',
		defaultMessage: 'TrueUSD',
	},
	USDC: {
		id: 'currencies.usdc.name',
		defaultMessage: 'USDC',
	},
	USDP: {
		id: 'currencies.usdp.name',
		defaultMessage: 'PAX Dollar',
	},
	XRP: {
		id: 'currencies.xrp.name',
		defaultMessage: 'Ripple',
	},
	XLM: {
		id: 'currencies.xlm.name',
		defaultMessage: 'Stellar Lumens',
	},
	ZEC: {
		id: 'currencies.zec.name',
		defaultMessage: 'Zcash',
	},
	WBTC: {
		id: 'currencies.wbtc.name',
		defaultMessage: 'Wrapped Bitcoin',
	},
	LINK: {
		id: 'currencies.link.name',
		defaultMessage: 'Chainlink',
	},
	DAI: {
		id: 'currencies.dai.name',
		defaultMessage: 'Dai',
	},
	UNI: {
		id: 'currencies.uni.name',
		defaultMessage: 'Uniswap',
	},
	MKR: {
		id: 'currencies.mkr.name',
		defaultMessage: 'Maker',
	},
	AAVE: {
		id: 'currencies.aave.name',
		defaultMessage: 'Aave',
	},
	COMP: {
		id: 'currencies.comp.name',
		defaultMessage: 'Compound',
	},
	ZRX: {
		id: 'currencies.zrx.name',
		defaultMessage: '0x',
	},
	BAT: {
		id: 'currencies.bat.name',
		defaultMessage: 'Basic Attention Token',
	},
	SUSHI: {
		id: 'currencies.sushi.name',
		defaultMessage: 'SushiSwap',
	},
	ALGO: {
		id: 'currencies.algo.name',
		defaultMessage: 'Algorand',
	},
	DOGE: {
		id: 'currencies.doge.name',
		defaultMessage: 'Dogecoin',
	},
	SHIB: {
		id: 'currencies.shib.name',
		defaultMessage: 'Shiba Inu',
	},
	MATIC: {
		id: 'currencies.matic.name',
		defaultMessage: 'Polygon',
	},
	MANA: {
		id: 'currencies.mana.name',
		defaultMessage: 'Decentraland',
	},
	UMA: {
		id: 'currencies.uma.name',
		defaultMessage: 'UMA',
	},
	HOT: {
		id: 'currencies.hot.name',
		defaultMessage: 'Holo',
	},
	CHZ: {
		id: 'currencies.chz.name',
		defaultMessage: 'Chiliz',
	},
	GRT: {
		id: 'currencies.grt.name',
		defaultMessage: 'The Graph',
	},
	SNX: {
		id: 'currencies.snx.name',
		defaultMessage: 'Synthetix',
	},
	ENJ: {
		id: 'currencies.enj.name',
		defaultMessage: 'Enjin Coin',
	},
	ADA: {
		id: 'currencies.ada.name',
		defaultMessage: 'Cardano',
	},
	DOT: {
		id: 'currencies.dot.name',
		defaultMessage: 'Polkadot',
	},
	FIL: {
		id: 'currencies.fil.name',
		defaultMessage: 'Filecoin',
	},
	VET: {
		id: 'currencies.vet.name',
		defaultMessage: 'VeChain',
	},
	ZIL: {
		id: 'currencies.zil.name',
		defaultMessage: 'Zilliqa',
	},
	MIOTA: {
		id: 'currencies.miota.name',
		defaultMessage: 'IOTA',
	},
	ONT: {
		id: 'currencies.ont.name',
		defaultMessage: 'Ontology',
	},
	ATOM: {
		id: 'currencies.atom.name',
		defaultMessage: 'Cosmos',
	},
	XTZ: {
		id: 'currencies.xtz.name',
		defaultMessage: 'Tezos',
	},
	CELO: {
		id: 'currencies.celo.name',
		defaultMessage: 'Celo',
	},
	NEO: {
		id: 'currencies.neo.name',
		defaultMessage: 'Neo',
	},
	'1INCH': {
		id: 'currencies.1inch.name',
		defaultMessage: '1inch',
	},
	EOS: {
		id: 'currencies.eos.name',
		defaultMessage: 'EOS',
	},
	TRX: {
		id: 'currencies.trx.name',
		defaultMessage: 'TRON',
	},
	AVAX: {
		id: 'currencies.avax.name',
		defaultMessage: 'Avalanche',
	},
	NEAR: {
		id: 'currencies.near.name',
		defaultMessage: 'NEAR protocol',
	},
	BCH: {
		id: 'currencies.bch.name',
		defaultMessage: 'Bitcoin Cash',
	},
});

export default currencies;
