import { Middleware } from 'redux';
import { RootState } from '../../Store';
import ProjectsActions from './ProjectsActions';
import ProjectsSelectors from './ProjectsSelectors';
import {
	ProjectsCreateProjectCreatedAction,
	ProjectsEditActionFulfilledAction,
	ProjectsListFetchProjectsFulfilledAction,
	ProjectsListProjectDeleteFulfilledAction,
	ProjectsCreateActionType,
	ProjectsEditActionType,
	ProjectsListActionType,
	ProjectsListStatus,
} from './ProjectsTypes';

const MerchantProjectsMiddleware: Middleware<void, RootState> = (storeApi) => (next) => (
	action:
		| ProjectsListFetchProjectsFulfilledAction
		| ProjectsCreateProjectCreatedAction
		| ProjectsListProjectDeleteFulfilledAction
		| ProjectsEditActionFulfilledAction
		| ProjectsCreateProjectCreatedAction
) => {
	switch (action.type) {
		case ProjectsListActionType.FETCH_PROJECTS_FULFILLED:
			storeApi.dispatch(ProjectsActions.data.updateProjects(action.payload.projects.content));
			break;
		case ProjectsListActionType.DELETE_FULFILLED: {
			storeApi.dispatch(ProjectsActions.data.deleteProject(action.payload));
			if (!ProjectsSelectors.data.getHasAny(storeApi.getState()))
				storeApi.dispatch(ProjectsActions.list.setStatus(ProjectsListStatus.Empty));
			storeApi.dispatch(ProjectsActions.list.resetPages());
			break;
		}
		case ProjectsEditActionType.ACTION_FULFILLED:
			storeApi.dispatch(ProjectsActions.data.updateProject(action.payload));
			storeApi.dispatch(ProjectsActions.list.resetPages());
			break;
		case ProjectsCreateActionType.PROJECT_CREATED:
			storeApi.dispatch(ProjectsActions.data.updateProject(action.payload));
			storeApi.dispatch(ProjectsActions.list.resetPages());
			storeApi.dispatch(ProjectsActions.list.setStatus(ProjectsListStatus.Ready));
			break;
		default:
			break;
	}

	next(action);
};

export default [MerchantProjectsMiddleware];
