import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { values } from '../../../../helpers/objectHelper/objectHelper';
import { useOrderStatusTranslator } from '../../../../helpers/merchantsHelper/merchantsHelper';
import useQueryParams from '../../../../hooks/useQueryParams';
import { RootState } from '../../../../redux/Store';
import { OrderStatus } from '../../../../redux/MerchantsState/MerchantTypes';
import Select from '../../../../components/Select/Select';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';
import { HistoryType } from '../../../../hooks/useHistoryTableData';
import HistoryBlock from '../../../../components/HistoryBlock/HistoryBlock';
import merchantOrderhistoryDataProvider from './Providers/merchantOrdersHistoryDataProvider';
import merchantOrderHistoryExportOptionsProvider from './Providers/merchantOrdersHistoryExportOptionsProvider';

import styles from './MerchantsOrdersHistory.module.scss';
import MerchantOrderHistoryContext from './Contexts/MerchantHistoryContext';

const MerchantsHistoryStatusFilter: FC = () => {
	const { formatMessage } = useIntl();
	const statusTranslator = useOrderStatusTranslator();

	const options = [
		{ value: '', label: formatMessage(messages.orderStatusAll) },
		...values(OrderStatus).map((status) => ({
			value: status,
			label: statusTranslator(status),
		})),
	];

	const [[status], update] = useQueryParams({
		status: undefined,
	});

	const onChange = useCallback(
		(value: OrderStatus) => {
			update({
				status: value || null,
				page: '1',
			});
		},
		[update]
	);

	return (
		<div>
			<Select
				inputGroupClassName={styles.statusFilter}
				labelClassName={styles.label}
				label={formatMessage(messages.filterByStatus)}
				placeholderText={formatMessage(messages.selectStatus)}
				options={options}
				value={status}
				onChange={onChange}
			/>
		</div>
	);
};

type MerchantsOrdersHistoryProps = {
	projectId: string;
};

const MerchantsOrdersHistory: FC<MerchantsOrdersHistoryProps> = ({ projectId }) => {
	const { isMobile } = useSelector((state: RootState) => state.AppState);

	return (
		<MerchantOrderHistoryContext.Provider value={{ projectId }}>
			<HistoryBlock
				historyType={HistoryType.ORDERS}
				id={projectId}
				title={messages.orderHistory}
				customFilters={!isMobile && <MerchantsHistoryStatusFilter />}
				dataProvider={merchantOrderhistoryDataProvider}
				exportOptionsProvider={merchantOrderHistoryExportOptionsProvider}
			/>
		</MerchantOrderHistoryContext.Provider>
	);
};

export default MerchantsOrdersHistory;
