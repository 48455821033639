import { ActionCreator } from 'redux';
import { getMerchantsPreOrderUrl } from '../../../endpoints';
import { AppDispatch } from '../../../Store';
import {
	PreordersDataUpdateOrdersAction,
	PreordersDataFetchOrderFulfilledAction,
	PreordersDataFetchOrderPendingAction,
	PreordersDataFetchOrderRejectedAction,
	PreordersDataActionType,
	Preorder,
	PreordersDataUpdateOrderAction,
} from './PreordersDataTypes';
import axiosInstance from '../../../../helpers/axiosInstance';

const updatePreorders: ActionCreator<PreordersDataUpdateOrdersAction> = (
	preorders: Preorder[]
) => ({
	type: PreordersDataActionType.UPDATE_PREORDERS,
	payload: preorders,
});

const updatePreorder: ActionCreator<PreordersDataUpdateOrderAction> = (order: Preorder) => ({
	type: PreordersDataActionType.UPDATE_PREORDER,
	payload: order,
});

const fetchPreoderPending: ActionCreator<PreordersDataFetchOrderPendingAction> = (id: string) => ({
	type: PreordersDataActionType.FETCH_PREORDER_PENDING,
	payload: id,
});

const fetchPreorderRejected: ActionCreator<PreordersDataFetchOrderRejectedAction> = (
	id: string
) => ({
	type: PreordersDataActionType.FETCH_PREORDER_REJECTED,
	payload: id,
});

const fetchPreorderFulfilled: ActionCreator<PreordersDataFetchOrderFulfilledAction> = (
	preorder: Preorder
) => ({
	type: PreordersDataActionType.FETCH_PREORDER_FULFILLED,
	payload: preorder,
});

const fetchPreorder = (id: string) => async (dispatch: AppDispatch) => {
	dispatch(fetchPreoderPending(id));

	await axiosInstance
		.get<Preorder>(getMerchantsPreOrderUrl(id))
		.then(({ data }) => {
			dispatch(fetchPreorderFulfilled(data));
		})
		.catch(() => {
			dispatch(fetchPreorderRejected(id));
		});
};

export default {
	updatePreorder,
	updatePreorders,
	fetchPreorder,
};
