import { FC, PropsWithChildren, useCallback } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useIntl } from 'react-intl';
import Textarea from '../../../../../../components/Textarea/Textarea';
import useMerchantRoutes, { MerchantRoutes } from '../../../../../../hooks/useMerchantRoutes';
import MerchantsMessages from '../../../../../../redux/MerchantsState/MerchantsMessages';
import PageTitle from '../../../../Shared/PageTitle/PageTitle';
import WhiteContainer from '../../../../../../components/WhiteContainer/WhiteContainer';

import styles from './PaymentButtonStyle.module.scss';
import TestIds from '../../../../../../test/TestIds';

const buttonIconHost =
	process.env.NODE_ENV === 'production' ? window.location.origin : 'https://dev.spectrocoin.com';

enum ButtonStyle {
	Primary,
	Secondary,
}

const DonateButton: FC<
	PropsWithChildren<{ link: string; style: ButtonStyle; lineHeight: number }>
> = ({ link, style, lineHeight, children }) => {
	const getStyle = useCallback(() => {
		switch (style) {
			case ButtonStyle.Primary:
				return {
					backgroundColor: '#4972F4',
					color: 'white',
				};
			case ButtonStyle.Secondary:
				return {
					backgroundColor: '#EBF1FF',
					color: '#4972F4',
				};
			default:
				return {};
		}
	}, [style]);

	return (
		<a
			href={link}
			style={{
				display: 'inline-block',
				padding: '14px 24px',
				fontSize: '14px',
				borderRadius: '4px',
				fontWeight: 500,
				textDecoration: 'none',
				fontFamily: '"Red Hat Text", sans-serif',
				textAlign: 'center',
				...getStyle(),
			}}
		>
			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" />
			<link
				href="https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@500&display=swap"
				rel="stylesheet"
			/>
			<span style={{ display: 'inline-block', lineHeight: `${lineHeight}px` }}>
				{children}
			</span>
		</a>
	);
};

const ButtonWithCode: FC<PropsWithChildren> = ({ children }) => {
	const code = children ? renderToStaticMarkup(children as JSX.Element) : '';
	return (
		<div className={styles.buttonBlock}>
			<div className={styles.button}>{children}</div>
			<Textarea inputGroupClassName={styles.code} value={code} />
		</div>
	);
};

const PaymentButtonStyle: FC = () => {
	const { getParams, getUrl } = useMerchantRoutes();
	const { paymentButtonId = '' } = getParams();
	const { formatMessage } = useIntl();

	const publicUrl = new URL(
		getUrl(MerchantRoutes.PublicDonation, { paymentButtonId }),
		window.location.origin
	).href;

	return (
		<WhiteContainer className={styles.container} data-cy={TestIds.PaymentButtonStyle}>
			<PageTitle
				className={styles.title}
				title={formatMessage(MerchantsMessages.paymentButtonStyle)}
			/>
			<div className={styles.buttons}>
				<ButtonWithCode>
					<DonateButton link={publicUrl} style={ButtonStyle.Primary} lineHeight={26}>
						{formatMessage(MerchantsMessages.donateCryptoWith_0, {
							Spectro: (
								<img
									style={{
										verticalAlign: 'bottom',
										height: '24px',
										width: '100px',
									}}
									src={`${buttonIconHost}/pb/spectrocoin_large_white.svg`}
								/>
							),
						})}
					</DonateButton>
				</ButtonWithCode>
				<ButtonWithCode>
					<DonateButton link={publicUrl} style={ButtonStyle.Secondary} lineHeight={26}>
						{formatMessage(MerchantsMessages.donateCryptoWith_0, {
							Spectro: (
								<img
									style={{
										verticalAlign: 'bottom',
										height: '24px',
										width: '100px',
									}}
									src={`${buttonIconHost}/pb/spectrocoin_large_blue.svg`}
								/>
							),
						})}
					</DonateButton>
				</ButtonWithCode>
				<ButtonWithCode>
					<DonateButton link={publicUrl} style={ButtonStyle.Primary} lineHeight={20}>
						<img
							style={{ verticalAlign: 'bottom', height: '18px', width: '36px' }}
							src={`${buttonIconHost}/pb/spectrocoin_small_white.svg`}
						/>
						{formatMessage(MerchantsMessages.donateWithCrypto)}
					</DonateButton>
				</ButtonWithCode>
				<ButtonWithCode>
					<DonateButton link={publicUrl} style={ButtonStyle.Secondary} lineHeight={20}>
						<img
							style={{ verticalAlign: 'bottom', height: '18px', width: '36px' }}
							src={`${buttonIconHost}/pb/spectrocoin_small_blue.svg`}
						/>
						{formatMessage(MerchantsMessages.donateWithCrypto)}
					</DonateButton>
				</ButtonWithCode>
				<ButtonWithCode>
					<DonateButton link={publicUrl} style={ButtonStyle.Primary} lineHeight={20}>
						<img
							style={{ verticalAlign: 'bottom', height: '18px', width: '36px' }}
							src={`${buttonIconHost}/pb/spectrocoin_small_white.svg`}
						/>
						{formatMessage(MerchantsMessages.donate)}
					</DonateButton>
				</ButtonWithCode>
				<ButtonWithCode>
					<DonateButton link={publicUrl} style={ButtonStyle.Secondary} lineHeight={20}>
						<img
							style={{ verticalAlign: 'bottom', height: '18px', width: '36px' }}
							src={`${buttonIconHost}/pb/spectrocoin_small_blue.svg`}
						/>
						{formatMessage(MerchantsMessages.donate)}
					</DonateButton>
				</ButtonWithCode>
			</div>
		</WhiteContainer>
	);
};

export default PaymentButtonStyle;
