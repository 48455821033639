import { FC, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getCountryCallingCode } from 'react-phone-number-input';
import { RootState } from '../../../../redux/Store';
import {
	CardType,
	OrderSteps,
	POAVerificationStatus,
} from '../../../../redux/CardsState/CardsTypes';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import countries from '../../../../helpers/countryHelper/countryHelper';
import { getPOAStatusURL } from '../../../../redux/endpoints';
import styles from './OrderProgress.module.scss';
import Loader from '../../../../components/Loader/Loader';
import axiosInstance from '../../../../helpers/axiosInstance';

interface ProgressProps {
	type: CardType;
	isRha?: boolean;
}

const OrderProgress: FC<ProgressProps> = ({ type, isRha }) => {
	const { orderStep, isCardUser } = useSelector((state: RootState) => state.CardsState);
	const { formData, availableCountries } = useSelector((state: RootState) => state.CardsState);
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const countryCode = formData?.deliveryCountry
		? countries.getAlpha2Code(formData?.deliveryCountry!, 'en')
		: countries.alpha3ToAlpha2(user?.country!);
	const [showVerification, setShowVerification] = useState(true);
	const minPhoneVal = `+${getCountryCallingCode(countryCode as any)}`;

	useEffectOnce(() => {
		if (user?.phone?.includes(minPhoneVal) && user?.phoneVerified) {
			setShowVerification(false);
		} else {
			axiosInstance
				.get(getPOAStatusURL())
				.then(({ data }) => {
					setShowVerification(
						data.status === POAVerificationStatus.CONFIRMED ? false : showVerification
					);
				})
				.catch((err) => console.error(err));
		}
	});

	if (!availableCountries) {
		return <Loader />;
	}

	return (
		<ul className={styles.progress}>
			<li className={classNames({ [styles.active]: orderStep >= OrderSteps.TERMS })} />
			{!isCardUser && (
				<li className={classNames({ [styles.active]: orderStep >= OrderSteps.TIN })} />
			)}
			{((!availableCountries.includes(user?.country!) &&
				CardType[type!] === CardType.VIRTUAL) ||
				CardType[type!] === CardType.PLASTIC) && (
				<li
					className={classNames({
						[styles.active]: orderStep >= OrderSteps.DELIVERY_ADDRESS,
					})}
				/>
			)}
			{((!availableCountries.includes(user?.country!) &&
				CardType[type!] === CardType.VIRTUAL) ||
				CardType[type!] === CardType.PLASTIC) && (
				<li
					className={classNames({
						[styles.active]: orderStep >= OrderSteps.SHIPPING,
					})}
				/>
			)}
			{!isCardUser && CardType[type!] === CardType.VIRTUAL && showVerification && (
				<li
					className={classNames({
						[styles.active]: orderStep >= OrderSteps.VERIFICATION,
					})}
				/>
			)}
			{isRha && (
				<li
					className={classNames({
						[styles.active]: orderStep >= OrderSteps.LINKED_WALLETS,
					})}
				/>
			)}
			<li className={classNames({ [styles.active]: orderStep >= OrderSteps.CONFIRM })} />
			<li className={classNames({ [styles.active]: orderStep >= OrderSteps.SET_PIN })} />
		</ul>
	);
};

export default OrderProgress;
