import { FC, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router';
import PageTitle from '../../Merchants/Shared/PageTitle/PageTitle';
import WhiteContainer from '../../../components/WhiteContainer/WhiteContainer';
import styles from './Terms.module.scss';
import ReferralsMessages from '../../../redux/ReferralsState/ReferralsMessages';
import termsSvg from './terms.svg';
import SvgIcon from '../../../components/SvgIcon/SvgIcon';
import Button, { ButtonStyle, ButtonType } from '../../../components/Button/Button';
import baseMsg from '../../../messages/base.messages';
import { RemoteData, RemoteError, RemoteStatus } from '../../../interfaces/RemoteData';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../components/NotificationMessage/NotificationMessage';
import { getReferralConsentAcceptUrl } from '../../../redux/endpoints';
import { getErrorMessageOrDefault } from '../../../helpers/errorMessageHelper/errorMessageHelper';
import useReferralRoutes, { ReferralRoutes } from '../../../hooks/useReferralRoutes';
import { useFormatRoute } from '../../../helpers/languagePathHelper/languagePathHelper';
import routes from '../../../route.messages';
import axiosInstance from '../../../helpers/axiosInstance';

const Terms: FC = () => {
	const { formatMessage } = useIntl();
	const { getUrl } = useReferralRoutes();
	const [action, setAction] = useState<RemoteData<void>>({ status: RemoteStatus.None });
	const { push } = useHistory();
	const referralTerms = useFormatRoute(routes.referralTerms);

	const onAccept = useCallback(() => {
		setAction({ status: RemoteStatus.InProgress });
		axiosInstance
			.put(getReferralConsentAcceptUrl())
			.then(() => {
				setAction({ status: RemoteStatus.Done });
				push(getUrl(ReferralRoutes.Home));
			})
			.catch((e: AxiosError<RemoteError>) => {
				setAction({ status: RemoteStatus.Error, error: e.response?.data });
			});
	}, [getUrl]);

	return (
		<WhiteContainer className={styles.block}>
			<PageTitle title={formatMessage(ReferralsMessages.termsAndConditions)} />
			<SvgIcon className={styles.svg} src={termsSvg} />
			<div className={styles.content}>
				{formatMessage(ReferralsMessages.terms, {
					link: (txt) => (
						<a
							className={styles.link}
							href={`${window.location.origin}${referralTerms}`}
						>
							{txt}
						</a>
					),
				})}
			</div>
			<div className={styles.actions}>
				{action.status === RemoteStatus.Error && (
					<NotificationMessage
						withIcon
						type={NotificationType.Error}
						style={NotificationStyle.Border}
						message={<FormattedMessage {...getErrorMessageOrDefault(action.error)} />}
					/>
				)}
				<Button
					className={styles.button}
					buttonStyle={ButtonStyle.PRIMARY}
					onClick={onAccept}
					isDisabled={action.status === RemoteStatus.InProgress}
				>
					{formatMessage(baseMsg.confirm)}
				</Button>
				<Button
					className={styles.button}
					buttonStyle={ButtonStyle.SECONDARY_WITHOUT_BORDER}
					type={ButtonType.ANCHOR_LINK}
					link={referralTerms}
				>
					<span>{formatMessage(ReferralsMessages.viewTerms)}</span>
					<FontAwesomeIcon className={styles.arrow} icon={faArrowRight} />
				</Button>
			</div>
		</WhiteContainer>
	);
};

export default Terms;
