import { FC } from 'react';
import { Route, Switch } from 'react-router';
import useMerchantRoutes, { MerchantRoutes } from '../../../hooks/useMerchantRoutes';
import ViewMerchantsCallback from './ViewMerchantsCallback/ViewMerchantsCallback';

const MerchantCallback: FC = () => {
	const { getPath } = useMerchantRoutes();

	return (
		<>
			<Route
				exact
				path={getPath(MerchantRoutes.CallbackView)}
				component={ViewMerchantsCallback}
			/>
		</>
	);
};

const MerchantCallbacks: FC = () => {
	const { getPath } = useMerchantRoutes();

	return (
		<Switch>
			<Route path={getPath(MerchantRoutes.CallbackRoot)} component={MerchantCallback} />
		</Switch>
	);
};

export default MerchantCallbacks;
