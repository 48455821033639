import { defineMessages, useIntl } from 'react-intl';
import { toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import { WithdrawHistoryItem } from '../../../../redux/WithdrawHistoryState/WithdrawHistoryTypes';
import { DetailsModalType } from '../../../../components/Modal/DetailsModal/DetailsModal';
import statusMsg from '../../../../messages/status.messages';
import HistoryListItem from '../../../../components/HistoryTable/HistoryListItem/HistoryListItem';

interface Props {
	data: WithdrawHistoryItem[];
}

export const providerName = defineMessages({
	PERFECT_MONEY: {
		id: 'withdrawProvider.PERFECT_MONEY',
		defaultMessage: 'Perfect Money',
	},
	PAYEER: {
		id: 'withdrawProvider.PAYEER',
		defaultMessage: 'PAYEER',
	},
	SKRILL: {
		id: 'withdrawProvider.SKRILL',
		defaultMessage: 'Skrill',
	},
	ADV_CASH: {
		id: 'withdrawProvider.ADV_CASH',
		defaultMessage: 'Adv cash',
	},
});

const EWalletWithdrawHistory = ({ data }: Props): JSX.Element => {
	const { formatMessage } = useIntl();
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{data?.length > 0 &&
				data.map(
					({
						accountNumber,
						date,
						id,
						receiveAmount,
						status,
						type,
						withdrawAmount,
						withdrawCurrencyCode,
					}: WithdrawHistoryItem) => (
						<HistoryListItem
							key={id}
							title={formatMessage(providerName[type])}
							dateCreated={date}
							currency={withdrawCurrencyCode}
							status={formatMessage(statusMsg[status])}
							detailsModalType={DetailsModalType.EWALLET_WITHDRAW}
							fee={toDecimal(withdrawAmount)
								.minus(toDecimal(receiveAmount))
								.toString()}
							withdrawAccount={accountNumber}
							feeCurrency={withdrawCurrencyCode}
							amount={receiveAmount}
							totalAmount={withdrawAmount}
						/>
					)
				)}
		</>
	);
};

export default EWalletWithdrawHistory;
