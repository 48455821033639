import { Middleware } from 'redux';
import { RootState } from '../../Store';
import OrdersActions from './OrdersActions';
import { OrdersHistoryFetchOrdersFulfilledAction, OrdersHistoryActionType } from './OrdersTypes';

const OrdersMiddleware: Middleware<void, RootState> = (storeApi) => (next) => (
	action: OrdersHistoryFetchOrdersFulfilledAction
) => {
	switch (action.type) {
		case OrdersHistoryActionType.FETCH_ORDERS_FULFILLED:
			storeApi.dispatch(OrdersActions.data.updateOrders(action.payload.content));
			break;
		default:
			break;
	}

	next(action);
};

export default [OrdersMiddleware];
