import Cookies from 'js-cookie';
import { AppDispatch } from '../Store';
import {
	AppActionType,
	SetRefCurrencyAction,
	ToggleViewAction,
	SetDeviceAction,
	SetDeviceOSAction,
	DeviceNameType,
	SetTwoFaRetrySetting,
	RateAmount,
	SetRateAmount,
} from './AppTypes';
import { getPublicSettingUrl } from '../endpoints';
import PublicSetting from '../../enums/PublicSetting';
import axiosInstance from '../../helpers/axiosInstance';

export const setGeneralError = (isError: boolean) => ({
	type: AppActionType.SET_GENERAL_ERROR,
	isError,
});

export const toggleCardsSidebar = (isVisible: boolean) => (dispach: AppDispatch) => {
	return dispach<ToggleViewAction>({
		type: AppActionType.TOGGLE_CARDS_SIDEBAR,
		isVisible,
	});
};

export const setRateAmount = (rateAmount: RateAmount) => (dispatch: AppDispatch) => {
	return dispatch<SetRateAmount>({
		type: AppActionType.SET_RATE_AMOUNT,
		rateAmount,
	});
};

export const toggleCardsInfoBanner = (isVisible: boolean) => (dispatch: AppDispatch) => {
	return dispatch<ToggleViewAction>({
		type: AppActionType.TOGGLE_CARDS_INFO_BANNER,
		isVisible,
	});
};

export const toggleCardControl = (isVisible: boolean) => (dispatch: AppDispatch) => {
	return dispatch<ToggleViewAction>({
		type: AppActionType.TOGGLE_CARD_CONTROL,
		isVisible,
	});
};

export const toggleStartingBlock = (isVisible: boolean) => (dispatch: AppDispatch) => {
	return dispatch<ToggleViewAction>({
		type: AppActionType.TOGGLE_STARTING_BLOCK,
		isVisible,
	});
};

export const toggleWalletSidebar = (isVisible: boolean) => (dispatch: AppDispatch) => {
	return dispatch<ToggleViewAction>({
		type: AppActionType.TOGGLE_WALLET_SIDEBAR,
		isVisible,
	});
};

export const toggleCurrentRates = (isVisible: boolean) => (dispatch: AppDispatch) => {
	return dispatch<ToggleViewAction>({
		type: AppActionType.TOGGLE_CURRENT_RATES,
		isVisible,
	});
};

export const toggleMarketingSlider = (isVisible: boolean) => (dispatch: AppDispatch) => {
	return dispatch<ToggleViewAction>({
		type: AppActionType.TOGGLE_MARKETING_SLIDER,
		isVisible,
	});
};

export const toggleCurrentRatesGraph = (isVisible: boolean) => (dispatch: AppDispatch) => {
	return dispatch<ToggleViewAction>({
		type: AppActionType.TOGGLE_CURRENT_RATES_GRAPH,
		isVisible,
	});
};

export const setReferenceCurrency = (currency: string) => (dispatch: AppDispatch) => {
	Cookies.set('primaryCurrency', currency);
	return dispatch<SetRefCurrencyAction>({
		type: AppActionType.SET_REFERENCE_CURRENCY,
		currency,
	});
};

export const setIsMobile = (isMobile: boolean) => (dispatch: AppDispatch) => {
	return dispatch<SetDeviceAction>({ type: AppActionType.IS_MOBILE, data: isMobile });
};

export const setIsTablet = (isTablet: boolean) => (dispatch: AppDispatch) => {
	return dispatch<SetDeviceAction>({ type: AppActionType.IS_TABLET, data: isTablet });
};

export const setDeviceOS = (device: string) => (dispatch: AppDispatch) => {
	let data = DeviceNameType.OTHER;
	if (/Android/i.test(device)) {
		data = DeviceNameType.ANDROID;
	}
	if (/iPhone/i.test(device) || /iPad/i.test(device) || /iPod/i.test(device)) {
		data = DeviceNameType.IPHONE;
	}
	if (/Windows Phone/i.test(device)) {
		data = DeviceNameType.WINDOWS;
	}
	return dispatch<SetDeviceOSAction>({ type: AppActionType.SET_DEVICE_OS, data });
};

export const set2FARetrySetting = (value: number) => (dispatch: AppDispatch) => {
	return dispatch<SetTwoFaRetrySetting>({
		type: AppActionType.SET_2FA_RETRY_SETTING,
		data: value,
	});
};

export const get2FARetrySettings = () => (dispatch: AppDispatch) => {
	return axiosInstance
		.get<{ name: string; value: string }>(
			getPublicSettingUrl(PublicSetting.MAX_2FA_RETRY_COUNT)
		)
		.then(({ data }) => {
			return dispatch(set2FARetrySetting(+data.value));
		})
		.catch(() => {});
};
