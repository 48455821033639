import { useCallback, useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { Footer } from '@spectrocoin/sc-footer';
import { Header } from '@spectrocoin/sc-header';
import Cookies from 'js-cookie';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { AxiosError } from 'axios';
import PaymentsContainer from './containers/PaymentsContainer/PaymentsContainer';
import usePaymentRoutes, { PaymentRoutes } from './hooks/usePaymentRoutes';
import useMerchantRoutes, { MerchantRoutes } from './hooks/useMerchantRoutes';
import { RootState } from './redux/Store';
import useReferral from './hooks/useReferral';
import { RTLLanguages } from './redux/LanguageState/LanguageTypes';
import AppContainer from './containers/AppContainer/AppContainer';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import ModalWrapper from './components/Modal/ModalWrapper';
import CookieBanner from './components/CookieBanner/CookieBanner';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import AuthContainer from './containers/AuthContainer/AuthContainer';
import NotFound from './pages/NotFound/NotFound';
import ConvertContainer from './containers/ConvertContainer/ConvertContainer';
import CardsContainer from './containers/CardsContainer/CardsContainer';
import AccountLayout from './layout/AccountLayout/AccountLayout';
import CardsLayout from './layout/CardsLayout/CardsLayout';
import CardStatus, { CardStatusEnum } from './pages/Cards/Shared/CardStatus/CardStatus';
import WalletsContainer from './containers/WalletsContainer/WalletsContainer';
import StorageKey from './enums/StorageKey';
import config from './configs/config';
import TransfersLayout from './layout/TransfersLayout/TransfersLayout';
import DepositContainer from './containers/DepositContainer/DepositContainer';
import { changeLanguage } from './redux/LanguageState/LanguageActions';
import LiveChat from './helpers/globalScriptHelper/liveChat';
import { GetIsCardListAvailable } from './helpers/cardsHelper/cardsHelper';
import WithdrawContainer from './containers/WithdrawContainer/WithdrawContainer';
import MerchantsLayout from './layout/MerchantsLayout/MerchantsLayout';
import MerchantsContainer from './containers/MerchantsContainer/MerchantsContainer';
import { setReferenceCurrency } from './redux/AppState/AppActions';
import { logout, setIsSCA } from './redux/ProfileState/ProfileActions';
import NotificationActions from './redux/NotificationsState/NotificationsActions';
import useEffectOnce from './hooks/useEffectOnce';
import SettingsContainer from './containers/SettingsContainer/SettingsContainer';
import NotificationsContainer from './containers/NotificationsContainer/NotificationsContainer';
import { getNotificationsMarkURL } from './redux/endpoints';
import { RemoteData, RemoteError, RemoteStatus } from './interfaces/RemoteData';
import useReferralRoutes, { ReferralRoutes } from './hooks/useReferralRoutes';
import ReferralContainer from './containers/ReferralContainer/ReferralContainer';
import {
	usePsAvailableAccountsQuery,
	usePsProfileQuery,
} from './redux/ProfileState/ProfileQueries';
import { getAccessToken } from './helpers/authHelper/authHelper';
import { useTwoFaQuery } from './redux/TwoFaState/TwoFaQueries';
import axiosInstance from './helpers/axiosInstance';

const messages = defineMessages({
	generalCardErrorTitle: {
		id: 'cardsContainer.generalCardErrorTitle',
		defaultMessage: 'Oops!',
	},
	generalCardError: {
		id: 'cardsContainer.generalCardError',
		defaultMessage: 'Something went wrong! Please try again later.',
	},
});

const AppRoutes = () => {
	const dispatch = useDispatch();
	const { locale } = useIntl();
	const cardLink = GetIsCardListAvailable();
	const { count: notificationCount, notifications } = useSelector(
		(state: RootState) => state.NotificationsState
	);
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const { generalError, referenceCurrency } = useSelector((state: RootState) => state.AppState);
	const { company, user, isLoggedIn } = useSelector((state: RootState) => state.ProfileState);
	const { activeLanguage, availableLanguagesList } = useSelector(
		(state: RootState) => state.LanguageState
	);

	const { data: psProfile } = usePsProfileQuery({ enabled: isLoggedIn });
	const { data: availableAccounts } = usePsAvailableAccountsQuery({ enabled: isLoggedIn });
	const { data: twoFaInfo } = useTwoFaQuery({ enabled: isLoggedIn });

	const { referralID } = useReferral();
	const history = useHistory();
	const { getPath: getMerchantPath } = useMerchantRoutes();
	const { getPath: getPaymentPath } = usePaymentRoutes();
	const { getPath: getReferralPath } = useReferralRoutes();

	const changeLang = (lng: string) => dispatch(changeLanguage(lng));
	const [markAction, setMarkAction] = useState<RemoteData<void>>({ status: RemoteStatus.None });
	const markAsRead = useCallback((ids: string[]) => {
		if (ids.length <= 0) return;
		setMarkAction({ status: RemoteStatus.InProgress });
		axiosInstance
			.put(getNotificationsMarkURL(), { ids })
			.then(async () => {
				await dispatch(NotificationActions.getUnreadNotificationCount());
				await dispatch(NotificationActions.getUnreadNotifications());
				setMarkAction({ status: RemoteStatus.Done });
			})
			.catch((e: AxiosError<RemoteError>) => {
				setMarkAction({ status: RemoteStatus.InProgress, error: e.response?.data });
			});
	}, []);

	const setAccount = useCallback((id: string) => {
		const params = new URLSearchParams();
		// loans uses another client_id and will write it into the token every single time
		params.append('client_id', getAccessToken()?.client_id || config.CLIENT_ID);
		params.append('grant_type', 'select_user');
		params.append('selected_user', id);
		params.append('alter_sso_session', 'true');

		void axiosInstance
			.post(config.TOKEN_ENDPOINT, params, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
			.then(() => {
				window.location.assign(`/${locale}/account`);
			});
	}, []);

	useEffectOnce(() => {
		new BroadcastChannel('tabs').onmessage = (ev) => {
			if (ev.data === 'logout') {
				void dispatch(logout());
			}
		};
	});

	useEffect(() => {
		if (RTLLanguages.includes(activeLanguage.toLowerCase())) {
			document.body.setAttribute('dir', 'rtl');
		} else {
			document.body.setAttribute('dir', 'ltr');
		}
	}, [activeLanguage]);

	useEffect(() => {
		dispatch(setIsSCA(psProfile?.scaRequired || false));
	}, [psProfile?.scaRequired]);

	useEffect(() => {
		const { preferenceCookies } = JSON.parse(
			Cookies.get(StorageKey.COOKIE_PREFERENCES) || '{}'
		);
		if (preferenceCookies && config.IS_LIVECHAT_ENABLED && config.LIVECHAT_KEY) {
			LiveChat(
				user?.email || '',
				user?.name || '',
				user?.surname || '',
				config.LIVECHAT_KEY,
				config.AUTH_DOMAIN
			);
		}
	}, [user?.email, user?.name, user?.surname]);

	useEffect(() => {
		if (user && isLoggedIn) {
			void dispatch(NotificationActions.getUnreadNotificationCount());
			void dispatch(NotificationActions.getUnreadNotifications());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, isLoggedIn]);

	return (
		<AuthContainer>
			<AppContainer>
				<CookieBanner
					facebookPixelId={config.FACEBOOK_PIXEL_ID!}
					gtmId={config.GTM_ID!}
					hotjarId={config.HOTJAR_ID!}
					includeFBPixel={config.INCLUDE_FB_PIXEL!}
					isHotjarTrackingEnabled={config.IS_HOTJAR_TRACKING_ENABLED!}
					livechatKey={config.LIVECHAT_KEY!}
					siftScienceKey={config.SIFT_SCIENCE_KEY!}
					twitterPixelId={config.TWITTER_PIXEL_ID!}
					userEmail={''}
					userName={''}
					userReferralCode={referralID || ''}
					userSurname={''}
					utmTracking={localStorage.getItem(StorageKey.UTM_UUID) || undefined}
					authDomain={config.AUTH_DOMAIN}
				/>
				<ModalWrapper />
				<Header
					history={history}
					activeLanguage={activeLanguage}
					isAuthenticated={isLoggedIn}
					origin="internal"
					config={{
						PROFILE_DOMAIN: config.PROFILE_DOMAIN,
					}}
					changeLang={changeLang}
					changeRefCurr={(value: string) => dispatch(setReferenceCurrency(value))}
					currentRefCurr={referenceCurrency as CurrencyEnum}
					cardLink={cardLink}
					notifications={{
						data: notifications,
						totalCount: notificationCount,
						markAsRead: {
							handler: markAsRead,
							isDisabled: markAction.status === RemoteStatus.InProgress,
						},
					}}
					logout={() => dispatch(logout())}
					user={user}
					accounts={availableAccounts as any}
					currentAccount={psProfile as any}
					setAccount={setAccount}
					addAccount={() => dispatch(logout(config.SIGNUP_URI))}
					twoFaType={(twoFaInfo?.type as any) || null}
				/>
				{(wallets === null || company === null) && !generalError && <LoadingScreen />}
				{(wallets === null || company === null) && generalError && (
					<CardStatus
						status={CardStatusEnum.FAILED}
						title={messages.generalCardErrorTitle}
						text={messages.generalCardError}
					/>
				)}
				{wallets !== null && company !== null && !generalError && (
					<div className="content">
						<Switch>
							<PrivateRoute path="/:lng?/account">
								<AccountLayout>
									<WalletsContainer />
								</AccountLayout>
							</PrivateRoute>
							<PrivateRoute path="/:lng?/convert">
								<TransfersLayout>
									<ConvertContainer />
								</TransfersLayout>
							</PrivateRoute>
							<PrivateRoute path="/:lng?/cards">
								<CardsLayout>
									<CardsContainer />
								</CardsLayout>
							</PrivateRoute>
							<PrivateRoute path="/:lng?/deposit">
								<TransfersLayout>
									<DepositContainer />
								</TransfersLayout>
							</PrivateRoute>
							<PrivateRoute path="/:lng?/withdraw">
								<TransfersLayout>
									<WithdrawContainer />
								</TransfersLayout>
							</PrivateRoute>
							<PrivateRoute path="/:lng?/settings">
								<TransfersLayout>
									<SettingsContainer />
								</TransfersLayout>
							</PrivateRoute>
							<PrivateRoute path="/:lng?/notifications">
								<NotificationsContainer />
							</PrivateRoute>
							<PrivateRoute path={getMerchantPath(MerchantRoutes.Root)}>
								<MerchantsLayout>
									<MerchantsContainer />
								</MerchantsLayout>
							</PrivateRoute>
							<PrivateRoute path={getPaymentPath(PaymentRoutes.Root)}>
								<PaymentsContainer />
							</PrivateRoute>
							<PrivateRoute path={getReferralPath(ReferralRoutes.Root)}>
								<ReferralContainer />
							</PrivateRoute>
							<Route path="*" component={NotFound} />
						</Switch>
					</div>
				)}
				<Footer
					history={history}
					isInnerURLs
					dir={RTLLanguages.includes(activeLanguage.toLowerCase()) ? 'rtl' : 'ltr'}
					activeLanguage={activeLanguage}
					languageList={availableLanguagesList}
					changeLanguage={changeLang}
					cardLink={GetIsCardListAvailable()}
				/>
			</AppContainer>
		</AuthContainer>
	);
};

export default AppRoutes;
