import ReactModal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { RootState } from '../../redux/Store';
import toggleModal from '../../redux/ModalState/ModalActions';
import styles from './ModalWrapper.module.scss';

const ModalWrapper = () => {
	const dispatch = useDispatch();
	const { TOGGLE_MODAL } = useSelector((state: RootState) => state.ModalState);
	const content = TOGGLE_MODAL?.content;
	const isOutsideClickable = TOGGLE_MODAL?.isOutsideClickable;
	const isHeightAuto = TOGGLE_MODAL?.isHeightAuto;
	const isOpen = !!TOGGLE_MODAL;

	const closeModal = () => {
		if (isOutsideClickable) return dispatch(toggleModal());
		return null;
	};

	if (!isOpen && !content) return null;
	return (
		<ReactModal
			isOpen={content && true}
			className={classNames(styles.modal, {
				[styles.heightAuto]: isHeightAuto,
			})}
			overlayClassName={styles.overlay}
			ariaHideApp={false}
			onRequestClose={() => closeModal()}
		>
			{content}
		</ReactModal>
	);
};

export default ModalWrapper;
