import { useState } from 'react';
import { defineMessages } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getCard } from '../../../../redux/CardsState/CardsActions';
import { CardTypeFromBE, LockType } from '../../../../redux/CardsState/CardsTypes';
import { getLockCardURL } from '../../../../redux/endpoints';
import {
	GetCurrentCard,
	isCardFunctionsEnabled,
} from '../../../../helpers/cardsHelper/cardsHelper';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import tempLockImgPath from './images/lock_card.svg';
import styles from './LockCard.module.scss';
import { RootState } from '../../../../redux/Store';
import Seo from '../../../../components/Seo/Seo';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	lockCard: {
		id: 'lockCard.lockCard',
		defaultMessage: 'Lock card',
	},
	cancel: {
		id: 'lockCard.cancel',
		defaultMessage: 'Cancel',
	},
	title: {
		id: 'lockCard.title',
		defaultMessage: 'Temporarily lock card',
	},
	post: {
		id: 'lockCard.post',
		defaultMessage:
			'If the card is locked you will not be able to use it. You will have the option to unlock the card later.',
	},
	mobileBack: {
		id: 'load.back',
		defaultMessage: 'Back to card',
	},
	metaTitle: {
		id: 'lockCard.metaTitle',
		defaultMessage: 'Lock card',
	},
});

const LockCard = () => {
	const card = GetCurrentCard();
	const dispatch = useDispatch();
	const { replace } = useHistory();
	const { url } = useRouteMatch();
	const { isTablet } = useSelector((store: RootState) => store.AppState);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const lock = (id: string, cardType: CardTypeFromBE) => {
		setIsLoading(true);
		void axiosInstance
			.put(
				getLockCardURL(
					id,
					isCardFunctionsEnabled(cardType) ? LockType.STOPPED : LockType.BLOCK
				),
				{}
			)
			.then(() => {
				return dispatch(getCard(id));
			})
			.then(() => {
				setIsLoading(false);
				return replace(url.replace('lock-card', 'history'));
			});
	};

	return (
		<WhiteContainer
			backButtonLink={isTablet ? url.replace('lock-card', 'history') : ''}
			backButtonText={messages.mobileBack}
		>
			<Seo title={messages.metaTitle} />
			<>
				<InfoHead title={messages.title} img={tempLockImgPath} text={messages.post} />
				{card && (
					<Button
						text={messages.lockCard}
						type={ButtonType.BUTTON}
						onClick={() => lock(card?.id, card?.cardType)}
						className={styles.button}
						isDisabled={isLoading || !card?.id}
						isLoading={isLoading || !card?.id}
					/>
				)}
				<Button
					text={messages.cancel}
					buttonStyle={ButtonStyle.BORDERLESS}
					type={ButtonType.LINK}
					link={url.replace('lock-card', 'history')}
					className={styles.button}
					isDisabled={isLoading}
					isLoading={isLoading}
				/>
			</>
		</WhiteContainer>
	);
};

export default LockCard;
