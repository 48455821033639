const WithdrawTestIds = {
	// Shared
	WithdrawSidebar: 'withdraw.Sidebar',
	transfersOptionsContainer: 'transfers.OptionsContainer',
	// Bank withdraw
	bankWithdrawForm: 'withdraw.bank.form',
	bankWithdrawCountrySelect: 'withdraw.bank.countrySelect',
	bankWithdrawCurrencySelect: 'withdraw.bank.currencySelect',
	bankWithdrawReceiverBankCountrySelect: 'withdraw.bank.receiverBankCountrySelect',
	bankWithdrawReceiverCountrySelect: 'withdraw.bank.receiverCountrySelect',
	bankWithdrawAccountInput: 'withdraw.bank.accountInput',
	bankWithdrawSwiftInput: 'withdraw.bank.swift',
	bankWithdrawBankAccountInput: 'withdraw.bank.bankAccountInput',
	bankWithdrawReceiverBankNameInput: 'withdraw.bank.receiverBankNameInput',
	bankWithdrawreceiverAddressInput: 'withdraw.bank.receiverAddressInput',
	bankWithdrawReceiverProvinceInput: 'withdraw.bank.receiverProvinceInput',
	bankWithdrawReceiverCityInput: 'withdraw.bank.receiverCityInput',
	bankWithdrawReceiverPostCodeInput: 'withdraw.bank.receiverPostCodeInput',
	bankWithdrawReceiverInput: 'withdraw.bank.receiverInput',
	bankWithdrawSortCodeInput: 'withdraw.bank.sortInput',
	bankWithdrawAmountInput: 'withdraw.bank.amountInput',
	bankWithdrawTotalAmountText: 'withdraw.bank.totalAmountText',
	bankWithdrawDescriptionText: 'withdraw.bank.description',
	bankWithdrawSubmitButton: 'withdraw.bank.submit',
} as const;

export default WithdrawTestIds;
