import Config from '../interfaces/Config';

const localDevelopmentConfig = {
	AUTH_SERVER_LOGOUT_ENDPOINT:
		'https://dev-auth.spectrocoin.com/api/users/logout?redirectUri=http://spectrocoin.local:9001',
	AUTHORITY: 'https://dev-auth.spectrocoin.com',
	AUTHORIZATION_ENDPOINT: 'https://dev-auth.spectrocoin.com/oauth/authorize',
	CLIENT_ID: 'spectrocoin-dev_fe',
	CLIENT_LOGOUT_ENDPOINT: 'http://spectrocoin.local:9001/api/fe/proxy/logout',
	ISSUER: 'https://auth-sandbox.bankera.com/',
	REDIRECT_URI: 'http://spectrocoin.local:9001/login-callback',
	SCOPE: '+',
	SIGNUP_URI: 'https://dev-auth.spectrocoin.com/signup',
	LOGIN_PAGE_URI: 'https://dev-auth.spectrocoin.com/login',
	TOKEN_ENDPOINT: 'http://spectrocoin.local:9001/api/fe/proxy/oauth/token',
	LIVECHAT_KEY: '8419881',
	IS_LIVECHAT_ENABLED: true,
	SSO_LOGOUT_URL: 'https://dev-auth.spectrocoin.com/api/users/logout',
	FACEBOOK_PIXEL_ID: '814978501880345',
	HOTJAR_ID: '868148',
	SIFT_SCIENCE_KEY: '6fb7bf241c',
	INCLUDE_FB_PIXEL: true,
	IS_HOTJAR_TRACKING_ENABLED: false,
	TWITTER_PIXEL_ID: '814978501880345',
	REFERRAL_COOKIE_NAME: 'REFERRAL_DETAILS',
	REFERRAL_COOKIE_EXPIRATION_PERIOD_IN_DAYS: 14,
	AUTH_DOMAIN: '',
	FILE_SIZE_POA: 8388608,
	PROFILE_DOMAIN: 'https://dev-profile.spectrocoin.com',
	USER_LANG_COOKIE_KEY: 'userLang',
	USER_LANG_COOKIE_DOMAIN: '.spectrocoin.local',
	APP_STORE_URI: 'https://apps.apple.com/lt/app/bankera/id1555256201',
	PLAY_STORE_URI: 'https://play.google.com/store/apps/details?id=com.bankera.wallet',
	ONRAMPER_API_KEY: 'pk_prod_01GTC8JT9MDSW8G11HPPKSVBTJ',
	GIP_URL: 'https://cis-tst.bankinglab.cards/api/gip203',
};

declare global {
	interface Window {
		config: Config;
		debugMode: boolean;
		gtag: (...x: any) => void;
		fbq: any;
	}
}

const config = {
	...(process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
		? localDevelopmentConfig
		: {}),
	...window.config,
};

export default config;
