import { formatInTimeZone } from 'date-fns-tz';
import getCurrentTimeZone from './getCurrentTimeZone';

export const dateFixer = (date: string) => (/z/i.test(date) ? date : `${date}Z`);

const convertToLocalTime = (date: string, formatDate: string) => {
	return formatInTimeZone(dateFixer(date), getCurrentTimeZone(), formatDate);
};

export default convertToLocalTime;
