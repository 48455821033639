import {
	FC,
	PropsWithChildren,
	ReactNode,
	useCallback,
	useMemo,
	useState,
	useTransition,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Joi from 'joi';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import useValidation from '../../../../hooks/useValidation';
import inputErrors from '../../../../messages/inputErrors.messages';
import AccountSelect from '../../../../components/AccountSelect/AccountSelect';
import TestIds, { formatTestId } from '../../../../test/TestIds';
import {
	getMerchantsPaymentOptions,
	getMerchantsProjectKeyPairUrl,
} from '../../../../redux/endpoints';
import { RootState } from '../../../../redux/Store';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import Textarea from '../../../../components/Textarea/Textarea';
import CopyButton from '../../../../components/CopyButton/CopyButton';
import Input from '../../../../components/Input/Input';
import OptionalField from '../../../../components/OptionalField/OptionalField';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';
import PrivateKeyModal from './PrivateKeyModal/PrivateKeyModal';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import { compareReceiveCurrencies } from '../../../../helpers/merchantsHelper/merchantsHelper';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import MultiAccountSelect from '../../../../components/MultiAccountSelect/MultiAccountSelect';
import { RemoteData, RemoteStatus } from '../../../../interfaces/RemoteData';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';

import styles from './ProjectForm.module.scss';
import Toggle from '../../../../components/Toggle/Toggle';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import statusMsg from '../../../../messages/status.messages';
import { ProjectTestStatus } from '../../../../redux/MerchantsState/MerchantTypes';
import MerchantTestIds from '../../../../test/Merchants/MerchantTestIds';
import axiosInstance from '../../../../helpers/axiosInstance';

const PublicKey: FC<{
	publicKey: string | null;
	onPublicKeyChange: (value: string | null) => void;
}> = ({ publicKey, onPublicKeyChange, ...rest }) => {
	const dispatch = useDispatch();
	const [generating, setGenerating] = useState(false);

	const generateKeyPairs = useCallback(async () => {
		setGenerating(true);
		const { data } = await axiosInstance.post(getMerchantsProjectKeyPairUrl());
		onPublicKeyChange(data.publicKey);
		dispatch(toggleModal(<PrivateKeyModal privateKey={data.privateKey} />));
		setGenerating(false);
	}, [dispatch, onPublicKeyChange]);

	const onGenerate = useCallback(() => {
		onPublicKeyChange(null);
		generateKeyPairs();
	}, [generateKeyPairs, onPublicKeyChange]);

	useEffectOnce(() => {
		if (!publicKey) generateKeyPairs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	return (
		<div className={styles.publicKey}>
			<Textarea
				data-cy={TestIds.ProjectFormPublicKeyTextarea}
				className={styles.key}
				value={publicKey || ''}
				disabled={generating}
				readOnly
			/>
			<div className={styles.publicKeyActions}>
				<CopyButton
					text={messages.copy}
					className={classNames(styles.copy, styles.action)}
					dataToCopy={publicKey || ''}
					isDisabled={!publicKey}
				/>
				<div
					data-cy={TestIds.ProjectFormPublicKeyGenerate}
					className={classNames(styles.generate, styles.action)}
					onClick={onGenerate}
				>
					<FormattedMessage {...messages.generate} />
				</div>
			</div>
		</div>
	);
};

type Form = {
	name: string;
	description: string | null;
	autoConvert: string | null;
	acceptCurrencies: CurrencyEnum[];
	publicKey: string | null;
	testStatus: ProjectTestStatus | null;
	isEnabled: boolean;
	forVerifiedPayers: boolean;
	forPayerFee: boolean;
};

export const useProjectFormValidator = () => {
	const { formatMessage } = useIntl();

	return useMemo(
		() =>
			Joi.object<Partial<Form>>({
				name: Joi.string()
					.allow('', null)
					.max(255)
					.message(
						formatMessage(inputErrors.x_lessOrEqual_y, {
							Field: formatMessage(messages.name),
							Max: 255,
						})
					),
				description: Joi.string()
					.allow('', null)
					.max(512)
					.message(
						formatMessage(inputErrors.x_lessOrEqual_y, {
							Field: formatMessage(messages.description),
							Max: 512,
						})
					),
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
};

type ProjectFormProps = {
	onNameChange: (value: string) => void;
	onDescriptionChange: (value: string | null) => void;
	onAutoConvertChange?: (value: string | null) => void;
	onAcceptCurrenciesChange?: (value: CurrencyEnum[] | null) => void;
	onPublicKeyChange: (value: string | null) => void;
	onTestStatusChange: (value: ProjectTestStatus | null) => void;
	onIsEnabledChange: (value: boolean) => void;
	onForVerifiedPayersChange?: (value: boolean | undefined) => void;
	onForPayerFeeChange?: (value: boolean | undefined) => void;
	formError?: ReactNode;
} & Form;

const ProjectForm: FC<PropsWithChildren<ProjectFormProps>> = ({
	name,
	onNameChange,
	description,
	onDescriptionChange,
	autoConvert,
	onAutoConvertChange,
	acceptCurrencies,
	onAcceptCurrenciesChange,
	publicKey,
	onPublicKeyChange,
	testStatus,
	onTestStatusChange,
	isEnabled,
	onIsEnabledChange,
	forVerifiedPayers,
	onForVerifiedPayersChange,
	forPayerFee,
	onForPayerFeeChange,
	children,
	formError,
}) => {
	const { formatMessage } = useIntl();
	const [_1, startTransition] = useTransition();
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const options = useMemo(
		() =>
			(
				wallets?.map(({ id, currencyCode, currencyName }) => ({
					value: id,
					currencyCode,
					currencyName,
				})) || []
			).sort((a, b) => compareReceiveCurrencies(a.currencyCode, b.currencyCode)),
		[wallets]
	);
	const [previousPayCurrencies] = useState<Array<CurrencyEnum>>(acceptCurrencies || []);

	const [availablePayCurrencies, setAvailablePayCurrencies] = useState<
		RemoteData<Array<CurrencyEnum>>
	>({ status: RemoteStatus.None });

	const fetchCurrencies = (value: string | null) => {
		const newWallet = wallets?.find((x) => x.id === value);
		setAvailablePayCurrencies({ status: RemoteStatus.InProgress });
		void axiosInstance
			.get<Array<{ currencyCode: CurrencyEnum }>>(
				getMerchantsPaymentOptions(newWallet?.currencyCode)
			)
			.then(({ data }) => {
				const newCurrencies = data.map((x) => x.currencyCode);
				setAvailablePayCurrencies({
					status: RemoteStatus.Done,
					data: newCurrencies,
				});

				if (!onAcceptCurrenciesChange) return;

				const supported = acceptCurrencies?.filter((x) => newCurrencies.includes(x)) || [];
				if (supported && supported.length !== (acceptCurrencies?.length || 0))
					onAcceptCurrenciesChange(supported);
			})
			.catch((e) => {
				setAvailablePayCurrencies({ status: RemoteStatus.Error, error: e.response.data });
			});
	};

	const currencyOptions = useMemo(
		() =>
			options
				.filter(
					(x) =>
						availablePayCurrencies.data?.includes(x.currencyCode) ||
						previousPayCurrencies.includes(x.currencyCode)
				)
				.map((option) => ({
					...option,
					value: option.currencyCode,
					disabled: !availablePayCurrencies.data?.includes(option.currencyCode),
				})),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[options, availablePayCurrencies.data]
	);

	const autoconvertChangeHandler = useCallback((value: string | null) => {
		startTransition(() => {
			onAutoConvertChange!(value);
			fetchCurrencies(value);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffectOnce(() => {
		fetchCurrencies(autoConvert);
	});

	const validator = useProjectFormValidator();
	const [_, validationResult] = useValidation({ name, description }, validator);

	return (
		<div data-cy={TestIds.ProjectForm} className={styles.form}>
			{formError}
			<Input
				data-cy={TestIds.ProjectFormNameInput}
				value={name}
				label={messages.projectName}
				onChange={onNameChange}
				errorMessage={validationResult.name}
			/>
			<OptionalField
				data-cy={TestIds.ProjectFormDescriptionField}
				id="merchant-project-description"
				name="merchant-project-description"
				label={messages.description}
				onChange={onDescriptionChange}
				initialState={!!description}
			>
				{({ onChange }) => (
					<Input
						data-cy={TestIds.ProjectFormDescriptionInput}
						value={description || ''}
						onChange={onChange}
						errorMessage={validationResult.description}
					/>
				)}
			</OptionalField>
			{onAutoConvertChange && (
				<OptionalField
					data-cy={TestIds.ProjectFormAutoConvertField}
					id="merchant-project-autoconvert"
					name="merchant-project-autoconvert"
					label={
						<div className={styles.labelWithTooltip}>
							<span>
								<FormattedMessage {...messages.autoConvertCurrency} />
							</span>
							<Tooltip
								infoIconVisible
								content={
									<div className={styles.tooltip}>
										{formatMessage(messages.autoConvertTooltip)}
									</div>
								}
							/>
						</div>
					}
					initialState={!!autoConvert}
					onChange={autoconvertChangeHandler}
				>
					{({ onChange }) => (
						<AccountSelect
							options={options}
							label={formatMessage(messages.chooseCurrency)}
							value={autoConvert}
							onChange={onChange}
						/>
					)}
				</OptionalField>
			)}
			{onAcceptCurrenciesChange && (
				<OptionalField
					data-cy={TestIds.ProjectFormAcceptCurrenciesField}
					id="merchant-project-currencies"
					name="merchant-project-currencies"
					label={
						<div className={styles.labelWithTooltip}>
							<span>
								<FormattedMessage {...messages.acceptAllCurrencies} />
							</span>
							<Tooltip
								infoIconVisible
								content={
									<div className={styles.tooltip}>
										{formatMessage(messages.acceptAllCurrenciesTooltip)}
									</div>
								}
							/>
						</div>
					}
					onChange={onAcceptCurrenciesChange}
					initialState={!acceptCurrencies?.length}
					inverted
				>
					{({ onChange }) => (
						<div className={styles.acceptCurrencies}>
							<MultiAccountSelect
								options={currencyOptions}
								label={formatMessage(messages.chooseCurrencyToAccept)}
								values={acceptCurrencies}
								onChange={onChange as (value: string[]) => void}
								isLoading={availablePayCurrencies.status !== RemoteStatus.Done}
							/>
							{previousPayCurrencies.length > 0 && (
								<NotificationMessage
									data-cy={TestIds.ProjectFormAcceptCurrenciesFieldInfo}
									message={formatMessage(messages.payCurrenciesMutabilityInfo)}
									type={NotificationType.Info}
									style={NotificationStyle.Fill}
								/>
							)}
						</div>
					)}
				</OptionalField>
			)}
			<OptionalField
				data-cy={TestIds.ProjectFormPublicKeyField}
				id="merchant-project-public-key"
				name="merchant-project-public-key"
				label={
					<div className={styles.labelWithTooltip}>
						<span>
							<FormattedMessage {...messages.publicKey} />
						</span>
						<Tooltip
							infoIconVisible
							content={
								<div className={styles.tooltip}>
									{formatMessage(messages.publicKeyTooltip)}
								</div>
							}
						/>
					</div>
				}
				onChange={onPublicKeyChange}
				initialState={!!publicKey}
			>
				{() => <PublicKey publicKey={publicKey} onPublicKeyChange={onPublicKeyChange} />}
			</OptionalField>
			<div className={styles.mode}>
				<span>{formatMessage(messages.mode)}</span>
			</div>
			<Toggle
				id="merchant-project-enable"
				name="merchant-project-enable"
				data-cy={TestIds.ProjectFormEnabled}
				checked={isEnabled}
				onChange={(value) => {
					onIsEnabledChange(value.target.checked);
				}}
				className={styles.toggle}
			>
				<div className={classNames(styles.labelWithTooltip, styles.label)}>
					<span>
						<FormattedMessage {...messages.enabled} />
					</span>
					<Tooltip
						infoIconVisible
						content={
							<div className={styles.tooltip}>
								<FormattedMessage {...messages.projectEnabledTooltip} />
							</div>
						}
					/>
				</div>
			</Toggle>
			<OptionalField
				data-cy={TestIds.ProjectFormTest}
				id="merchant-project-test"
				name="merchant-project-test"
				label={
					<div className={classNames(styles.labelWithTooltip, styles.label)}>
						<span>
							<FormattedMessage {...messages.test} />
						</span>
						<Tooltip
							infoIconVisible
							content={
								<div className={styles.tooltip}>
									<FormattedMessage {...messages.testTooltip} />
								</div>
							}
						/>
					</div>
				}
				onChange={onTestStatusChange}
				initialState={!!testStatus}
				initialValue={testStatus || ProjectTestStatus.EXPIRED}
				isDisabled={!isEnabled}
				onToggle={(value) => {
					if (value && !isEnabled) onIsEnabledChange(true);
				}}
			>
				{({ onChange }) => (
					<div className={styles.testContainer}>
						<span className={styles.testTitle}>
							<FormattedMessage {...messages.testSelect} />
						</span>
						<div>
							<Checkbox
								data-cy={formatTestId(
									MerchantTestIds.ProjectFormTest_0,
									ProjectTestStatus.EXPIRED
								)}
								labelClassName={styles.testLabel}
								checked={testStatus === ProjectTestStatus.EXPIRED}
								onChange={() => {
									onChange(ProjectTestStatus.EXPIRED);
									onIsEnabledChange(true);
								}}
								isRadio
							>
								<FormattedMessage {...statusMsg.EXPIRED} />
							</Checkbox>
							<Checkbox
								data-cy={formatTestId(
									MerchantTestIds.ProjectFormTest_0,
									ProjectTestStatus.PAID
								)}
								labelClassName={styles.testLabel}
								checked={testStatus === ProjectTestStatus.PAID}
								onChange={() => {
									onChange(ProjectTestStatus.PAID);
									onIsEnabledChange(true);
								}}
								isRadio
							>
								<FormattedMessage {...statusMsg.PAID} />
							</Checkbox>
						</div>
					</div>
				)}
			</OptionalField>
			{onForPayerFeeChange && (
				<Toggle
					id="merchant-project-payer-fee"
					name="merchant-project-payer-fee"
					data-cy={TestIds.ProjectFormPayerFee}
					checked={forPayerFee}
					onChange={(value) => onForPayerFeeChange(value.target.checked)}
					className={styles.toggle}
				>
					<div className={classNames(styles.labelWithTooltip, styles.label)}>
						<span>
							<FormattedMessage {...messages.forPayerFee} />
						</span>
						<Tooltip
							infoIconVisible
							content={
								<div className={styles.tooltip}>
									<FormattedMessage {...messages.forPayerFeeTooltip} />
								</div>
							}
						/>
					</div>
				</Toggle>
			)}
			{onForVerifiedPayersChange && (
				<Toggle
					id="merchant-project-verified-payers"
					name="merchant-project-verified-payers"
					data-cy={TestIds.ProjectFormVerifiedPayers}
					checked={forVerifiedPayers}
					onChange={(value) => onForVerifiedPayersChange(value.target.checked)}
					className={styles.toggle}
				>
					<div className={classNames(styles.labelWithTooltip, styles.label)}>
						<span>
							<FormattedMessage {...messages.forVerifiedPayers} />
						</span>
						<Tooltip
							infoIconVisible
							content={
								<div className={styles.tooltip}>
									<FormattedMessage {...messages.forVerifiedPayersTooltip} />
								</div>
							}
						/>
					</div>
				</Toggle>
			)}
			{children}
		</div>
	);
};

export default ProjectForm;
