const messages = {
    'ar': require('./src/translations/ar.json'),
'de': require('./src/translations/de.json'),
'en': require('./src/translations/en.json'),
'es-ES': require('./src/translations/es-ES.json'),
'et': require('./src/translations/et.json'),
'fr': require('./src/translations/fr.json'),
'it': require('./src/translations/it.json'),
'lt': require('./src/translations/lt.json'),
'ru': require('./src/translations/ru.json'),
'uk': require('./src/translations/uk.json'),
'zh-TW': require('./src/translations/zh-TW.json'),
};

module.exports = messages;
