/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import {
	toggleCardControl,
	toggleCardsInfoBanner,
	toggleCardsSidebar,
	toggleMarketingSlider,
} from '../../redux/AppState/AppActions';
import { RootState } from '../../redux/Store';
import Button, { ButtonStyle, ButtonType } from '../Button/Button';
import { isCardOnHold } from '../../helpers/cardsHelper/cardsHelper';
import { formatPrecision } from '../../helpers/currencyHelper/currencyHelper';
import { KeywordType, SortType } from '../../pages/Cards/CardsHub/CardsHub';
import {
	CardItem,
	CardStatusType,
	CardType,
	CardTypeFromBE,
} from '../../redux/CardsState/CardsTypes';
import Loader from '../Loader/Loader';
import plasticCardImgPath from './images/plastic.svg';
import virtualCardImgPath from './images/virtual.svg';
import lockImgPath from './images/lock.svg';
import blockedImgPath from './images/blocked.svg';
import sortArrowsImgPath from '../../images/sort_arrows.svg';
import styles from './CardsList.module.scss';
import convertToLocalTime from '../../helpers/dateHelper/dateHelper';
import useEffectOnce from '../../hooks/useEffectOnce';
import useFormatAmount from '../../hooks/useFormatAmount';

const messages = defineMessages({
	activate: {
		id: 'cardsList.active',
		defaultMessage: 'Activate',
	},
	plasticCryptoCard: {
		id: 'orderCard.plasticCryptoCard',
		defaultMessage: 'Plastic crypto card',
	},
	virtualCryptoCard: {
		id: 'orderCard.virtualCryptoCard',
		defaultMessage: 'Virtual crypto card',
	},
	PLASTIC: {
		id: 'orderCard.plastic',
		defaultMessage: 'Plastic',
	},
	VIRTUAL: {
		id: 'orderCard.virtual',
		defaultMessage: 'Virtual',
	},
	EXPIRED: {
		id: 'cardsList.EXPIRED',
		defaultMessage: 'EXPIRED',
	},
	ACTIVE: {
		id: 'cardsList.ACTIVE',
		defaultMessage: 'ACTIVE',
	},
	LOCKED: {
		id: 'cardsList.LOCKED',
		defaultMessage: 'LOCKED',
	},
	BLOCKED: {
		id: 'cardsList.BLOCKED',
		defaultMessage: 'BLOCKED',
	},
	CLOSED: {
		id: 'cardsList.BLOCKED',
		defaultMessage: 'BLOCKED',
	},
	SUSPENDED: {
		id: 'cardsList.SUSPENDED',
		defaultMessage: 'LOCKED',
	},
	card: {
		id: 'cardsList.card',
		defaultMessage: 'Card',
	},
	validThru: {
		id: 'cardsList.validThru',
		defaultMessage: 'Valid thru',
	},
	status: {
		id: 'cardsList.status',
		defaultMessage: 'Status',
	},
	balance: {
		id: 'cardsList.balance',
		defaultMessage: 'Balance',
	},
});

interface CardsListProps {
	list: any;
	keywordFilter: KeywordType[];
	sortType: SortType | null;
	sortAsc: boolean;
	changeSortType: (type: SortType) => void;
}

const CardsList = ({ list, keywordFilter, sortType, sortAsc, changeSortType }: CardsListProps) => {
	const { locale, formatMessage } = useIntl();
	const { isLoading } = useSelector((state: RootState) => state.CardsState);
	const { isMobile } = useSelector((state: RootState) => state.AppState);
	const amountFormatter = useFormatAmount();
	const dispatch = useDispatch();

	useEffectOnce(() => {
		dispatch<any>(toggleCardsSidebar(true));
		dispatch<any>(toggleCardsInfoBanner(true));
		dispatch<any>(toggleMarketingSlider(true));
		dispatch<any>(toggleCardControl(false));
	});

	const listItem = ({ status, type, pan, expirationDate, id, accountBalance, cardType }: any) => {
		const getCardTypeLabel = () => {
			switch (cardType) {
				case CardTypeFromBE.PERVESK_RHA_PLASTIC:
					return formatMessage(messages.plasticCryptoCard);
				case CardTypeFromBE.PERVESK_RHA_VIRTUAL:
					return formatMessage(messages.virtualCryptoCard);
				default:
					return formatMessage(messages[type]);
			}
		};

		return (
			<>
				<span className={styles.cardIcon}>
					<span className={styles.iconContainer}>
						<img
							className={classNames({
								[styles.blocked]: isCardOnHold(CardStatusType[status]),
								[styles.notActive]: status === CardStatusType.NOT_ACTIVATED,
							})}
							src={
								type === CardType.PLASTIC ? plasticCardImgPath : virtualCardImgPath
							}
						/>
						{((status === CardStatusType.BLOCKED &&
							cardType === CardTypeFromBE.C_PASTIC) ||
							status === CardStatusType.SUSPENDED) && (
							<img src={lockImgPath} className={styles.lock} />
						)}
						{((status === CardStatusType.BLOCKED &&
							cardType !== CardTypeFromBE.C_PASTIC) ||
							status === CardStatusType.CLOSED) && (
							<img src={blockedImgPath} className={styles.lock} />
						)}
					</span>
					<span
						className={classNames(styles.cardLabel, {
							[styles.opacity]: isCardOnHold(CardStatusType[status]),
						})}
					>
						<span className={styles.number}>
							<span className={styles.dots}>&#183;&#183;&#183;&#183;</span>
							{` ${pan.slice(-4)}`}
						</span>
						<span
							className={classNames(styles.type, {
								[styles.opacity]: isCardOnHold(CardStatusType[status]),
							})}
						>
							{getCardTypeLabel()}
						</span>
					</span>
				</span>
				{!isMobile && (
					<span
						className={classNames(styles.date, {
							[styles.opacity]: isCardOnHold(CardStatusType[status]),
						})}
					>
						{convertToLocalTime(expirationDate, 'MM/yyyy')}
					</span>
				)}
				{!isMobile && (
					<span
						className={classNames(styles.status, {
							[styles.opacity]: isCardOnHold(CardStatusType[status]),
							[styles.blue]: status === CardStatusType.ACTIVE,
						})}
					>
						{status === CardStatusType.NOT_ACTIVATED ? (
							<Button
								type={ButtonType.LINK}
								buttonStyle={ButtonStyle.PRIMARY}
								className={styles.button}
								text={messages.activate}
								link={`/${locale}/cards/account/${id}/activate`}
							/>
						) : (
							<>
								{status === CardStatusType.BLOCKED &&
								cardType === CardTypeFromBE.C_PASTIC
									? formatMessage(messages.SUSPENDED)
									: formatMessage(messages[status])}
							</>
						)}
					</span>
				)}
				<span
					className={classNames(styles.balance, {
						[styles.opacity]: isCardOnHold(CardStatusType[status]),
					})}
				>
					{`${accountBalance.search('-') !== -1 ? '-' : ''} ${amountFormatter(
						formatPrecision(accountBalance, CurrencyEnum.EUR)
					)} EUR`}
					{isMobile && (
						<span
							className={classNames(styles.status, styles.mobileStatus, {
								[styles.opacity]: isCardOnHold(CardStatusType[status]),
								[styles.blue]: status === CardStatusType.ACTIVE,
							})}
						>
							{status === CardStatusType.NOT_ACTIVATED ? (
								<Button
									type={ButtonType.LINK}
									buttonStyle={ButtonStyle.PRIMARY}
									className={styles.button}
									text={messages.activate}
									link={`/${locale}/cards/account/${id}/activate`}
								/>
							) : (
								<>
									{status === CardStatusType.BLOCKED &&
									cardType === CardTypeFromBE.C_PASTIC
										? formatMessage(messages.SUSPENDED)
										: formatMessage(messages[status])}
								</>
							)}
						</span>
					)}
				</span>
			</>
		);
	};

	return (
		<ul className={styles.list}>
			<li>
				<span className={styles.cardIcon}>{formatMessage(messages.card)}</span>
				{!isMobile && (
					<span onClick={() => changeSortType(SortType.DATE)}>
						{formatMessage(messages.validThru)}
						<img src={sortArrowsImgPath} alt="Arrows" />
					</span>
				)}
				{!isMobile && <span>{formatMessage(messages.status)}</span>}
				<span onClick={() => changeSortType(SortType.BALANCE)}>
					{formatMessage(messages.balance)}
					<img src={sortArrowsImgPath} alt="Arrows" />
				</span>
			</li>
			{isLoading && <Loader className={styles.loader} />}
			{list
				?.sort((a: any, b: any) => {
					if (sortType) {
						if (sortType === SortType.DATE) {
							if (sortAsc)
								return new Date(a.expirationDate) > new Date(b.expirationDate)
									? 1
									: -1;
							if (!sortAsc)
								return new Date(a.expirationDate) < new Date(b.expirationDate)
									? 1
									: -1;
						}
						if (sortType === SortType.BALANCE) {
							return sortAsc
								? (a.accountBalance as any) - (b.accountBalance as any)
								: (b.accountBalance as any) - (a.accountBalance as any);
						}
					}

					return !a.sortIndex || !b.sortIndex
						? new Date(a.date) > new Date(b.date)
							? -1
							: 1
						: a.sortIndex - b.sortIndex;
				})
				.map(
					({
						type,
						pan,
						id,
						expirationDate,
						status,
						accountBalance,
						cardType,
					}: CardItem) => {
						return (
							<li key={id}>
								{status === CardStatusType.NOT_ACTIVATED ? (
									<div className={styles.item}>
										{listItem({
											status,
											type,
											pan,
											expirationDate,
											id,
											accountBalance,
											cardType,
										})}
									</div>
								) : (
									<Link
										to={`/${locale}/cards/account/${id}/history`}
										className={styles.item}
									>
										{listItem({
											status,
											type,
											pan,
											expirationDate,
											id,
											accountBalance,
											cardType,
										})}
									</Link>
								)}
							</li>
						);
					}
				)}
		</ul>
	);
};

export default CardsList;
