import { useEffect, useMemo, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GetCurrentCard } from '../../../../helpers/cardsHelper/cardsHelper';
import shallowEqual from '../../../../helpers/shallowEqual/shallowEqual';
import {
	getCardDetailsInitURL,
	getCardDetailsTokenURL,
	getCardDetailsURL,
} from '../../../../redux/endpoints';
import Button, { ButtonType } from '../../../../components/Button/Button';
import CopyButton from '../../../../components/CopyButton/CopyButton';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import ThirdParty2FA from '../../Shared/ThirdParty2FA/ThirdParty2FA';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import styles from './ViewCardDetails.module.scss';
import { RootState } from '../../../../redux/Store';
import Seo from '../../../../components/Seo/Seo';
import convertToLocalTime from '../../../../helpers/dateHelper/dateHelper';
import baseMsg from '../../../../messages/base.messages';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	title: {
		id: 'viewCardDetails.title',
		defaultMessage: 'View card details',
	},
	wrongPin: {
		id: 'viewCardPin.wrongPin',
		defaultMessage: 'Wrong 2FA code',
	},
	somethingWentWrong: {
		id: 'viewCardPin.somethingWentWrong',
		defaultMessage: 'Something went wrong. Please try again later.',
	},
	timerText: {
		id: 'viewCardDetails.timerText',
		defaultMessage: 'CVV and card number will disappear in <span></span> s.',
	},
	cardNumber: {
		id: 'viewCardDetails.cardNumber',
		defaultMessage: 'Card number',
	},
	validThru: {
		id: 'viewCardDetails.validThru',
		defaultMessage: 'Valid thru',
	},
	cvv: {
		id: 'viewCardDetails.cvv',
		defaultMessage: 'CVV',
	},
	cardHolder: {
		id: 'viewCardDetails.cardHolder',
		defaultMessage: 'Cardholder',
	},
	mobileBack: {
		id: 'load.back',
		defaultMessage: 'Back to card',
	},
	metaTitle: {
		id: 'viewCardDetails.metaTitle',
		defaultMessage: 'View card details',
	},
});

interface DetailsProps {
	cardOwner: string;
	cardCvv: string;
	cardExpiry: string;
	cardPan: string;
}

const oneSec = 1000;

const ViewCardDetails = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { formatMessage } = useIntl();
	const card = GetCurrentCard();
	const { url } = useRouteMatch();
	const { isTablet } = useSelector((store: RootState) => store.AppState);
	const timer = useRef<number>();
	const [details, setDetails] = useState<DetailsProps | null>(null);
	const [time, setTime] = useState<number>(45);
	const [error, setError] = useState<MessageDescriptor | null>(null);

	const hiddenDetails = useMemo(() => {
		if (card)
			return {
				cardOwner: card?.nameOnCard,
				cardCvv: '***',
				cardExpiry: card?.expirationDate
					? convertToLocalTime(card?.expirationDate, 'MM/yyyy')
					: '',
				cardPan: `************${card?.pan.slice(-4)}`,
			};
		return null;
	}, [card]);

	const getFullCapitalizedName = (fullName: string) => {
		const arr = fullName.split(' ');
		for (let i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
		}

		const fullCapitalizedName = arr.join(' ');
		return fullCapitalizedName;
	};

	const initDetails = async (id: string) => {
		setIsLoading(true);
		await axiosInstance.post(getCardDetailsInitURL(id), {}).then(() => setIsLoading(false));
	};

	const getDetails = (auth: string) => {
		return axiosInstance
			.get(getCardDetailsURL(card?.cardId || ''), {
				headers: {
					Authorization: auth,
				},
			})
			.then(({ data }: AxiosResponse) => {
				const { cardOwner, cardCvv, cardExpiry, cardPan } = data;
				setTime(45);
				const formattedDate = `20${cardExpiry.slice(2)}-${cardExpiry.slice(0, 2)}-01`;

				return setDetails({
					cardOwner,
					cardCvv,
					cardExpiry: card?.date ? convertToLocalTime(formattedDate, 'MM/yyyy') : '',
					cardPan,
				});
			})
			.catch(() => {
				return setError(messages.somethingWentWrong);
			})
			.then(() => {
				return setIsLoading(false);
			});
	};

	const getDetailsToken = (password: string, id: string) => {
		setIsLoading(true);
		return axiosInstance
			.put(getCardDetailsTokenURL(id), { password })
			.then(({ data }) => {
				return getDetails(data.authorizationHeader);
			})
			.catch(() => {
				setIsLoading(false);
				return setError(messages.wrongPin);
			});
	};

	useEffect(() => {
		if (card?.id && !details) initDetails(card?.id);
	}, [card, details]);

	useEffect(() => {
		if (details && !shallowEqual(details, hiddenDetails)) {
			timer.current = window.setInterval(() => {
				setTime(time - 1);
			}, oneSec);
		}

		return () => clearInterval(timer.current);
	}, [details, hiddenDetails, time]);

	useEffect(() => {
		if (time === 0 && details && !shallowEqual(details, hiddenDetails)) {
			setDetails(hiddenDetails);
		}
	}, [time, details, hiddenDetails]);

	return (
		<WhiteContainer
			backButtonLink={isTablet ? url.replace('view-card-details', 'history') : ''}
			backButtonText={messages.mobileBack}
		>
			<Seo title={messages.metaTitle} />
			<>
				<InfoHead title={messages.title} />
				{details ? (
					<div className={styles.container}>
						<span className={styles.detail}>
							{details?.cardPan && (
								<>
									<div>
										<div>{formatMessage(messages.cardNumber)}</div>
										<div className={styles.value}>
											{details?.cardPan.match(/.{1,4}/g)?.join(' ')}
										</div>
									</div>
									<CopyButton
										dataToCopy={details.cardPan}
										className={styles.copy}
										isDisabled={details?.cardPan === hiddenDetails?.cardPan}
									/>
								</>
							)}
						</span>
						<span className={styles.detail}>
							{details?.cardExpiry && (
								<>
									<div>
										<div>{formatMessage(messages.validThru)}</div>
										<div className={styles.value}>{details?.cardExpiry}</div>
									</div>
									<CopyButton
										dataToCopy={details.cardExpiry}
										className={styles.copy}
									/>
								</>
							)}
						</span>
						<span className={styles.detail}>
							{details?.cardCvv && (
								<>
									<div>
										<div>{formatMessage(messages.cvv)}</div>
										<div className={styles.value}>{details?.cardCvv}</div>
									</div>
									<CopyButton
										dataToCopy={details.cardCvv}
										className={styles.copy}
										isDisabled={details?.cardCvv === hiddenDetails?.cardCvv}
									/>
								</>
							)}
						</span>
						<span className={styles.detail}>
							{details?.cardOwner && (
								<>
									<div>
										<div>{formatMessage(messages.cardHolder)}</div>
										<div className={styles.value}>
											{getFullCapitalizedName(details?.cardOwner)}
										</div>
									</div>
									<CopyButton
										dataToCopy={details.cardOwner}
										className={styles.copy}
									/>
								</>
							)}
						</span>
						{time !== 0 && !shallowEqual(details, hiddenDetails) && (
							<div className={styles.timer}>
								<FormattedMessage
									{...messages.timerText}
									values={{ span: () => <span>{time}</span> }}
								/>
							</div>
						)}
						<Button
							text={baseMsg.close}
							className={styles.button}
							type={ButtonType.LINK}
							link={url.replace('view-card-details', 'history')}
						/>
					</div>
				) : (
					<ThirdParty2FA
						onSubmit={(twoFa: string) => getDetailsToken(twoFa, card?.id || '')}
						isLoading={isLoading}
						error={error}
						resend={() => initDetails(card?.id || '')}
					/>
				)}
			</>
		</WhiteContainer>
	);
};

export default ViewCardDetails;
