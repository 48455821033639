import { Fragment } from 'react';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';
import baseMsg from '../../../../messages/base.messages';
import { toPlainAmount } from '../../../../helpers/currencyAmountHelper/currencyAmountHelper';
import useFormatAmount from '../../../../hooks/useFormatAmount';

interface DataProps {
	[key: string]: string;
}

interface GiftCardsConfirmProps {
	data: DataProps[];
	currency: CurrencyEnum;
	className: string;
}

const GiftCardsConfirm = ({ data, currency, className }: GiftCardsConfirmProps): JSX.Element => {
	const amountFormatter = useFormatAmount();

	return (
		<>
			{data.map(({ payAmount, receiveAmount, giftTitle, receiveCurrency }: DataProps) => (
				<Fragment key={payAmount}>
					<InfoInput
						className={className}
						label={withdrawMessages.purchaseProduct}
						text={giftTitle}
					/>
					<InfoInput
						className={className}
						label={withdrawMessages.withdrawalAmount}
						text={`${amountFormatter(
							formatPrecision(toPlainAmount(payAmount), currency)
						)} ${currency}`}
					/>
					<InfoInput
						className={className}
						label={baseMsg.receiveAmount}
						text={`${amountFormatter(
							formatPrecision(
								toPlainAmount(receiveAmount),
								receiveCurrency as CurrencyEnum
							)
						)} ${receiveCurrency}`}
					/>
				</Fragment>
			))}
		</>
	);
};

export default GiftCardsConfirm;
