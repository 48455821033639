import { Route, useRouteMatch } from 'react-router-dom';
import ConvertConfirm from '../Shared/ConvertConfirm/ConvertConfirm';
import ConvertResult from '../Shared/ConvertResult/ConvertResult';
import ConvertForm, { ConvertType } from '../Shared/ConvertForm/ConvertForm';
import OrderHistory from './OrderHistory/OrderHistory';
import styles from './RecurringOrder.module.scss';

const RecurringOrder = () => {
	const { url }: { url: string } = useRouteMatch();

	return (
		<div className={styles.container}>
			<Route
				exact
				path={url}
				render={() => <ConvertForm convertType={ConvertType.RECURRING} />}
			/>
			<Route exact path={`${url}/history`} component={OrderHistory} />
			<Route exact path={`${url}/confirm`} component={ConvertConfirm} />
			<Route
				exact
				path={[`${url}/success`, `${url}/failed`, `${url}/pending`]}
				render={() => <ConvertResult />}
			/>
		</div>
	);
};

export default RecurringOrder;
