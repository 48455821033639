import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import Joi from 'joi';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import {
	isAmountGreaterThan,
	isAmountGreaterThanZero,
	isAmountLessThan,
} from '../../../../helpers/inputValidation/inputValidation';
import inputErrors from '../../../../messages/inputErrors.messages';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';
import { GiftItemResponse } from './GiftCardsForm';

const useGiftCardsFormValidator = (currency: string, item: GiftItemResponse, balance: string) => {
	const { formatMessage } = useIntl();
	return useMemo(
		() =>
			Joi.object({
				payWallet: Joi.string()
					.required()
					.messages({ 'string.empty': formatMessage(inputErrors.cannotBeEmpty) }),
				payAmount: Joi.string()
					.required()
					.custom((value: string, helper: any) => {
						if (!isAmountGreaterThanZero(value))
							return helper.message(formatMessage(inputErrors.amountTooLow));
						if (isAmountGreaterThan(value, balance))
							return helper.message(formatMessage(inputErrors.insufficientFunds));
						return true;
					})
					.messages({
						'string.empty': formatMessage(inputErrors.cannotBeEmpty),
					}),
				receiveAmount: Joi.string()
					.required()
					.custom((value: string, helper: any) => {
						if (!isAmountGreaterThanZero(value))
							return helper.message(formatMessage(inputErrors.amountTooLow));
						if (item?.maxPrice && isAmountGreaterThan(value, item?.maxPrice || '0'))
							return helper.message(
								formatMessage(withdrawMessages.maxAmount, {
									amount: `${formatPrecision(
										item?.maxPrice || '0',
										CurrencyEnum[item?.itemCurrency]
									)} ${item?.itemCurrency}`,
								})
							);
						if (item?.minPrice && isAmountLessThan(value, item?.minPrice || '0'))
							return helper.message(
								formatMessage(withdrawMessages.minAmount, {
									amount: `${formatPrecision(
										item?.minPrice || '0',
										CurrencyEnum[item?.itemCurrency]
									)} ${item?.itemCurrency}`,
								})
							);
						return true;
					})
					.messages({
						'string.empty': formatMessage(inputErrors.cannotBeEmpty),
					}),
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[currency, balance, item]
	);
};

export default useGiftCardsFormValidator;
