import { defineMessages } from 'react-intl';

const messages = defineMessages({
	metaDepositsTitle: {
		id: 'depositsMeta.base',
		defaultMessage: 'Deposits',
	},
	metaCryptoDepositsTitle: {
		id: 'depositsMeta.cryptoDeposits',
		defaultMessage: 'Cryptocurrency Deposits',
	},
	metaBankDepositsTitle: {
		id: 'depositsMeta.bankDeposits',
		defaultMessage: 'Bank Deposits',
	},
	metaCashDepositsTitle: {
		id: 'depositsMeta.cashDeposits',
		defaultMessage: 'Cash Deposits',
	},
	metaCardDepositsTitle: {
		id: 'depositsMeta.cardDeposits',
		defaultMessage: 'Card Deposits',
	},
	metaEWalletDepositsTitle: {
		id: 'depositsMeta.ewalletDeposits',
		defaultMessage: 'E-Wallet Deposits',
	},
	metaVoucherDepositsTitle: {
		id: 'depositsMeta.voucherDeposits',
		defaultMessage: 'Voucher Deposits',
	},
	metaDepositsHistoryTitle: {
		id: 'depositsMeta.depositsHistory',
		defaultMessage: 'Deposits History',
	},
	metaDepositResultTitle: {
		id: 'depositsMeta.depositResult',
		defaultMessage: 'Deposit Status',
	},
	eWalletDeposits: {
		id: 'depositOptions.eWallet',
		defaultMessage: 'E-wallet deposits',
	},
	bankDeposits: {
		id: 'depositOptions.bank',
		defaultMessage: 'Bank deposits',
	},
	cardDeposits: {
		id: 'depositOptions.card',
		defaultMessage: 'Card deposits',
	},
	cryptocurrencyDeposits: {
		id: 'depositOptions.cryptocurrencyDeposits',
		defaultMessage: 'Cryptocurrency deposits',
	},
	backToDepositOptions: {
		id: 'base.back_to_deposit_options',
		defaultMessage: 'Back to deposit options',
	},
	cryptoDepositHistory: {
		id: 'base.crypto_deposit_history',
		defaultMessage: 'Cryptocurrency deposit history',
	},
	fiatDepositHistory: {
		id: 'base.fiat_deposit_history',
		defaultMessage: 'Fiat deposit history',
	},
	receivedFrom: {
		id: 'base.received_from',
		defaultMessage: 'Received from {txid}',
	},
	cashDeposits: {
		id: 'depositOptions.cash',
		defaultMessage: 'Cash deposits',
	},
	genericDeposit: {
		id: 'depositOptions.deposit',
		defaultMessage: '{option} Deposit',
	},
	genericCryptoDetailsTitle: {
		id: 'depositOptions.cryptoDetailsTitle',
		defaultMessage: '{currencyName} ({currencyCode})',
	},
	voucherDeposit: {
		id: 'depositOptions.voucher',
		defaultMessage: 'Voucher deposit',
	},
	name: {
		id: 'walletBlock.name',
		defaultMessage: 'Name',
	},
	balance: {
		id: 'walletBlock.balance',
		defaultMessage: 'Balance',
	},
	segWitAddress: {
		id: 'cryptoAddressBlock.segWit',
		defaultMessage: 'SegWit address',
	},
	legacyAddress: {
		id: 'cryptoAddressBlock.legacy',
		defaultMessage: 'Legacy address',
	},
	errorDepositAmountBelowMin: {
		id: 'deposit.deposit_amount_below_mininum',
		defaultMessage: 'Min. deposit amount is {minAmount}',
	},
	errorDepositAmountAboveMax: {
		id: 'deposit.deposit_amount_above_maximum',
		defaultMessage: 'Max. deposit amount is {maxAmount}',
	},
	errorDepositAmountIsNumber: {
		id: 'deposit.deposit_amount_is_number',
		defaultMessage: 'Deposit amount should be a number',
	},
	errorDepositAmountGreaterThanZero: {
		id: 'deposit.deposit_amount_greater_than_0',
		defaultMessage: 'Deposit amount should be greater than 0',
	},
});

export default messages;
