import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RemoteData } from '../../../../interfaces/RemoteData';

export enum OrderStatus {
	NEW = 'NEW',
	PENDING = 'PENDING',
	PAID = 'PAID',
	FAILED = 'FAILED',
	EXPIRED = 'EXPIRED',
	TEST = 'TEST',
}

export type Order = {
	callbackURL: string | null;
	created: string;
	depositAddress: string | null;
	description: string | null;
	failureURL: string | null;
	id: string;
	orderId: string;
	payAmount: string;
	payNetworkName: string;
	feeCurrencyCode: CurrencyEnum;
	payCurrencyCode: CurrencyEnum;
	receiveAmount: string;
	receiveCurrencyCode: CurrencyEnum;
	status: OrderStatus;
	successURL: string | null;
	validUntil: string;
};

export type OrdersDataState = {
	orders: Record<string, RemoteData<Order>>;
};

export enum OrdersDataActionType {
	FETCH_ORDER_PENDING = 'MERCHANTS/ORDERS/DATA/FETCH_ORDER/PENDING',
	FETCH_ORDER_REJECTED = 'MERCHANTS/ORDERS/DATA/FETCH_ORDER/REJECTED',
	FETCH_ORDER_FULFILLED = 'MERCHANTS/ORDERS/DATA/FETCH_ORDER/FULFILLED',

	UPDATE_ORDER = 'MERCHANTS/ORDERS/DATA/UPDATE_ORDER',
	UPDATE_ORDERS = 'MERCHANTS/ORDERS/DATA/UPDATE_ORDERS',
}

export type OrderDataFetchOrderPendingAction = {
	type: OrdersDataActionType.FETCH_ORDER_PENDING;
	payload: string;
};

export type OrderDataFetchOrderRejectedAction = {
	type: OrdersDataActionType.FETCH_ORDER_REJECTED;
	payload: string;
};

export type OrderDataFetchOrderFulfilledAction = {
	type: OrdersDataActionType.FETCH_ORDER_FULFILLED;
	payload: Order;
};

export type OrderDataUpdateOrderAction = {
	type: OrdersDataActionType.UPDATE_ORDER;
	payload: Order;
};

export type OrderDataUpdateOrdersAction = {
	type: OrdersDataActionType.UPDATE_ORDERS;
	payload: Order[];
};
