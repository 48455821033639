import { FC } from 'react';
import { useIntl } from 'react-intl';
import Loading from '../../Shared/Loading/Loading';
import { useTransactionStatusTranslator } from '../../../../helpers/merchantsHelper/merchantsHelper';
import LabeledField from '../../Shared/LabeledField/LabeledField';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import PageTitle from '../../Shared/PageTitle/PageTitle';
import NoProjects from '../../Shared/NotFound/NotFound';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';
import useMerchantRoutes, { MerchantRoutes } from '../../../../hooks/useMerchantRoutes';

import styles from './ViewMerchantsTransaction.module.scss';
import convertToLocalTime from '../../../../helpers/dateHelper/dateHelper';
import TestIds from '../../../../test/TestIds';
import baseMsg from '../../../../messages/base.messages';
import useFormatAmount from '../../../../hooks/useFormatAmount';
import { useOrderTransactionQuery } from '../../../../redux/MerchantsState/OrderTransactions/Queries/OrderTransactionQueries';

const Content: FC = () => {
	const { formatMessage } = useIntl();
	const { getUrl, getParams } = useMerchantRoutes();
	const { transactionId = '' } = getParams();
	const statusTranslator = useTransactionStatusTranslator();
	const amountFormatter = useFormatAmount();
	const { data, isLoading } = useOrderTransactionQuery(transactionId);

	if (isLoading) return null;

	const {
		id,
		status,
		depositValue,
		currencyCode,
		inputAddress,
		inputTransactionHash,
		created,
	} = data!;

	return (
		<>
			<PageTitle
				className={styles.title}
				title={formatMessage(messages.orderDepositTransactionDetails)}
				backLink={getUrl(MerchantRoutes.OrderView)}
			/>
			<div className={styles.content}>
				<LabeledField
					data-cy={TestIds.TransactionViewId}
					label={formatMessage(messages.id)}
				>
					{id}
				</LabeledField>
				<LabeledField
					data-cy={TestIds.TransactionViewStatus}
					label={formatMessage(messages.status)}
				>
					{statusTranslator(status)}
				</LabeledField>
				<LabeledField
					data-cy={TestIds.TransactionViewDeposited}
					label={formatMessage(messages.deposited)}
				>
					{`${amountFormatter(
						formatPrecision(depositValue, currencyCode)
					)} ${currencyCode}`}
				</LabeledField>
				<LabeledField
					data-cy={TestIds.TransactionViewAddress}
					label={formatMessage(messages.address)}
				>
					{inputAddress}
				</LabeledField>
				<LabeledField
					data-cy={TestIds.TransactionViewHash}
					label={formatMessage(baseMsg.transactionHash)}
				>
					{inputTransactionHash}
				</LabeledField>
				<LabeledField
					data-cy={TestIds.TransactionViewDate}
					label={formatMessage(messages.date)}
				>
					{convertToLocalTime(created, 'yyyy-MM-dd hh:mm:ss')}
				</LabeledField>
			</div>
		</>
	);
};

const ViewMerchantsTransaction: FC = () => {
	const { formatMessage } = useIntl();
	const { getParams } = useMerchantRoutes();

	const { transactionId = '' } = getParams();

	const { data, isLoading, error } = useOrderTransactionQuery(transactionId);

	return (
		<WhiteContainer className={styles.container} data-cy={TestIds.TransactionView}>
			{isLoading && <Loading />}
			{error && <NoProjects message={formatMessage(messages.transactionNotFound)} />}
			{data && <Content />}
		</WhiteContainer>
	);
};

export default ViewMerchantsTransaction;
