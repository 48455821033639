import { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { getLockCardURL } from '../../../../redux/endpoints';
import { LockType } from '../../../../redux/CardsState/CardsTypes';
import { getCard } from '../../../../redux/CardsState/CardsActions';
import { GetCurrentCard } from '../../../../helpers/cardsHelper/cardsHelper';
import { toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import reportCardImgPath from './images/report_card.svg';
import unloadBeforeImgPath from './images/unload_before.svg';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import styles from './ReportCard.module.scss';
import { RootState } from '../../../../redux/Store';
import Seo from '../../../../components/Seo/Seo';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	yes: {
		id: 'reportCard.yes',
		defaultMessage: 'Yes, report card',
	},
	cancel: {
		id: 'reportCard.cancel',
		defaultMessage: 'Cancel',
	},
	title: {
		id: 'reportCard.title',
		defaultMessage: 'Report card as lost or stolen',
	},
	post: {
		id: 'reportCard.post',
		defaultMessage:
			'Are you sure want to report your card? Your card will be blocked lorem ipsum dolor sit amet, consectetur adipiscing elit.',
	},
	unloadBeforeTitle: {
		id: 'reportCard.unloadBeforeTitle',
		defaultMessage: 'Unload your card before blocking',
	},
	unloadBeforeText: {
		id: 'reportCard.unloadBeforeText',
		defaultMessage:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non tortor id tellus sodales aliquet a nec nibh.',
	},
	unloadButton: {
		id: 'reportCard.unloadButton',
		defaultMessage: 'Unload card',
	},
	blockCardAnyways: {
		id: 'reportCard.blockCardAnyways',
		defaultMessage: 'Block card with current balance',
	},
	successMsg: {
		id: 'unload.success',
		defaultMessage: 'Card unloaded successfully',
	},
	wouldYouLikeToProceed: {
		id: 'reportCard.wouldYouLikeToProceed',
		defaultMessage: 'Would you like to proceed and block your card now?',
	},
	blockCard: {
		id: 'reportCard.blockCard',
		defaultMessage: 'Block card',
	},
	metaTitle: {
		id: 'reportCard.metaTitle',
		defaultMessage: 'Block card',
	},
	mobileBack: {
		id: 'load.back',
		defaultMessage: 'Back to card',
	},
});

interface UnloadBeforeLockProps {
	onSubmit: () => void;
	isLoading: boolean;
}

const UnloadBeforeLock = ({ onSubmit, isLoading }: UnloadBeforeLockProps) => {
	const { url } = useRouteMatch();
	const { push }: any = useHistory();

	const redirectToUnload = () => {
		return push({
			pathname: url.replace('report-card', 'unload'),
			state: {
				fromReportCard: true,
			},
		});
	};

	return (
		<>
			<InfoHead
				title={messages.unloadBeforeTitle}
				img={unloadBeforeImgPath}
				text={messages.unloadBeforeText}
			/>
			<Button
				text={messages.unloadButton}
				type={ButtonType.BUTTON}
				onClick={() => redirectToUnload()}
				className={styles.button}
				isLoading={isLoading}
				isDisabled={isLoading}
			/>
			<Button
				text={messages.blockCardAnyways}
				type={ButtonType.BUTTON}
				onClick={() => onSubmit()}
				buttonStyle={ButtonStyle.BORDERLESS}
				className={classNames(styles.button, styles.link)}
				isLoading={isLoading}
				isDisabled={isLoading}
			/>
		</>
	);
};

const ReportCard = () => {
	const { url } = useRouteMatch();
	const { replace } = useHistory();
	const dispatch = useDispatch();
	const card = GetCurrentCard();
	const { state } = useLocation();
	const { isTablet } = useSelector((store: RootState) => store.AppState);
	const [isFromUnload, setIsFromUnload] = useState<boolean>(false);
	const [isUnloadNeeded, setIsUnloadNeeded] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const postRequest = (id: string) => {
		setIsLoading(true);
		void axiosInstance
			.put(getLockCardURL(id, LockType.LOST), {})
			.then(() => {
				return dispatch(getCard(id));
			})
			.catch(() => {
				return null;
			})
			.then(() => {
				setIsLoading(false);
				return replace(url.replace('report-card', 'new-card'));
			});
	};

	const reportCard = (isAmountNotZero: boolean, id: string) => {
		if (isAmountNotZero) return postRequest(id);
		return setIsUnloadNeeded(true);
	};

	useEffect(() => {
		if (state?.fromUnload) setIsFromUnload(state?.fromUnload);
	}, [state]);

	if (!card) return null;
	return (
		<WhiteContainer
			backButtonLink={isTablet ? url.replace('report-card', 'history') : ''}
			backButtonText={messages.mobileBack}
		>
			<Seo title={messages.metaTitle} />
			{isUnloadNeeded ? (
				<UnloadBeforeLock onSubmit={() => postRequest(card.id)} isLoading={isLoading} />
			) : (
				<>
					<InfoHead
						title={isFromUnload ? messages.successMsg : messages.title}
						img={reportCardImgPath}
						text={isFromUnload ? messages.wouldYouLikeToProceed : messages.post}
					/>
					<Button
						text={isFromUnload ? messages.blockCard : messages.yes}
						type={ButtonType.BUTTON}
						onClick={() =>
							reportCard(!!toDecimal(card.accountBalance).equals(0), card.id)
						}
						isLoading={isLoading}
						isDisabled={isLoading}
						className={styles.button}
					/>
					<Button
						text={messages.cancel}
						type={ButtonType.LINK}
						link={url.replace('report-card', 'history')}
						buttonStyle={ButtonStyle.BORDERLESS}
						className={styles.button}
						isLoading={isLoading}
						isDisabled={isLoading}
					/>
				</>
			)}
		</WhiteContainer>
	);
};

export default ReportCard;
