import { useDispatch } from 'react-redux';
import { FC, PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { useIntl } from 'react-intl';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';
import TestIds from '../../../../test/TestIds';

import deleteSvg from './Images/deleteIcon.svg';

import styles from './DeleteConfirmation.module.scss';
import TextNode, { TextStyles } from '../../../../components/TextNode/TextNode';

type DeleteProjectConfirmationProps = {
	onDelete: () => Promise<void>;
	name: string;
	isDisabled?: boolean;
};

const DeleteProjectConfirmation: FC<PropsWithChildren<DeleteProjectConfirmationProps>> = ({
	onDelete,
	name,
	isDisabled = false,
	children,
}) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const onYes = async () => {
		await onDelete();
	};

	const onNo = () => {
		dispatch(toggleModal());
	};

	return (
		<div className={styles.container} data-cy={TestIds.DeleteConfirmation}>
			<div className={styles.header}>
				<FontAwesomeIcon
					className={styles.closeIcon}
					icon={faTimes}
					onClick={onNo}
					size="lg"
				/>
			</div>
			<div className={styles.body}>
				<SvgIcon className={styles.icon} src={deleteSvg} />
				<TextNode style={TextStyles.BreakWord} className={styles.text}>
					{formatMessage(messages.delete_x, {
						project: name,
					})}
				</TextNode>
				{children}
				<div className={styles.buttons}>
					<Button
						isDisabled={isDisabled}
						data-cy={TestIds.DeleteConfirmationYes}
						className={styles.button}
						type={ButtonType.BUTTON}
						onClick={onYes}
					>
						{formatMessage(messages.yes)}
					</Button>
					<Button
						isDisabled={isDisabled}
						data-cy={TestIds.DeleteConfirmationNo}
						buttonStyle={ButtonStyle.SECONDARY_WITHOUT_BORDER}
						type={ButtonType.BUTTON}
						className={styles.button}
						onClick={onNo}
					>
						{formatMessage(messages.no)}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default DeleteProjectConfirmation;
