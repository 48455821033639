import { useState } from 'react';
import useEffectOnce from './useEffectOnce';

const useWindowWidth = () => {
	const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1200);

	useEffectOnce(() => {
		const handleWindowResize = () => setWidth(window.innerWidth);
		window.addEventListener('resize', handleWindowResize);

		return () => window.removeEventListener('resize', handleWindowResize);
	});

	return width;
};

export default useWindowWidth;
