import { FC } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import styles from './DefaultLink.module.scss';
import PageTitle from '../../../Merchants/Shared/PageTitle/PageTitle';
import ReferralsMessages from '../../../../redux/ReferralsState/ReferralsMessages';
import CopyButton from '../../../../components/CopyButton/CopyButton';
import { defaultCodeQuery } from '../../../../redux/ReferralsState/Queries/ReferralQueries';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import facebookSvg from './facebook.svg';
import xSvg from './x.svg';
import messagesSvg from './messages.svg';
import mailSvg from './mail.svg';
import gmailSvg from './gmail.svg';
import useReferralRoutes, { ReferralRoutes } from '../../../../hooks/useReferralRoutes';

const Block: FC<{ title: string; text: string; isBold?: boolean; canCopy?: boolean }> = ({
	title,
	text,
	isBold = false,
	canCopy = false,
}) => {
	return (
		<div className={styles.block}>
			<div className={styles.blockTitle}>{title}</div>
			<div className={classNames(styles.blockValue, isBold && styles.bold)}>
				<span>{text}</span>
				{canCopy && <CopyButton className={styles.copy} dataToCopy={text} />}
			</div>
		</div>
	);
};

const DefaultLink: FC = () => {
	const { formatMessage } = useIntl();
	const { getUrl } = useReferralRoutes();
	const { data } = useQuery({
		queryKey: defaultCodeQuery.getKey(),
		queryFn: defaultCodeQuery.getFn(),
	});
	const referralUrl = data ? `${window.location.host}/?referralId=${data.id}` : '-';
	const fullReferralUrl = data ? `${window.location.origin}/?referralId=${data.id}` : '-';

	return (
		<WhiteContainer>
			<PageTitle title={formatMessage(ReferralsMessages.defaultLink)}>
				<Link className={styles.viewAll} to={getUrl(ReferralRoutes.ReferralIds)}>
					{formatMessage(ReferralsMessages.viewRefIds)}
				</Link>
			</PageTitle>
			<div className={styles.content}>
				<div className={styles.row}>
					<Block
						isBold
						title={formatMessage(ReferralsMessages.youReceive)}
						text={data ? `${data.commission}%` : '-'}
					/>
					<Block
						isBold
						title={formatMessage(ReferralsMessages.friendsReceive)}
						text={data ? `${data.cashback}%` : '-'}
					/>
				</div>
				<div className={styles.row}>
					<Block
						canCopy
						title={formatMessage(ReferralsMessages.refId)}
						text={data?.id ?? '-'}
					/>
					<Block
						canCopy
						title={formatMessage(ReferralsMessages.refLink)}
						text={referralUrl}
					/>
				</div>
				<div className={styles.share}>
					<label>{formatMessage(ReferralsMessages.shareOn)}:</label>
					<div className={styles.social}>
						<a
							href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
								fullReferralUrl
							)}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<SvgIcon className={styles.socialIcon} src={facebookSvg} />
						</a>
						<a
							href={`https://twitter.com/share?url=${encodeURIComponent(
								fullReferralUrl
							)}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<SvgIcon className={styles.socialIcon} src={xSvg} />
						</a>
						<a
							href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
								fullReferralUrl
							)}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<SvgIcon className={styles.socialIcon} src={messagesSvg} />
						</a>
						<a
							href={`mailto:?body=${encodeURIComponent(fullReferralUrl)}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<SvgIcon className={styles.socialIcon} src={mailSvg} />
						</a>
						<a
							href={`https://mail.google.com/mail/u/0/?view=cm&body=${encodeURIComponent(
								fullReferralUrl
							)}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<SvgIcon className={styles.socialIcon} src={gmailSvg} />
						</a>
					</div>
				</div>
			</div>
		</WhiteContainer>
	);
};

export default DefaultLink;
