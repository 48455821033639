/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppDispatch } from '../Store';
import { ModalType, ToggleModalAction } from './ModalTypes';

export default (content: any = null, isOutsideClickable = true, isHeightAuto = false) => (
	dispatch: AppDispatch
) => {
	return dispatch<ToggleModalAction>({
		type: ModalType.TOGGLE_MODAL,
		content,
		isOutsideClickable,
		isHeightAuto,
	});
};
