import { FC } from 'react';
import { Link } from 'react-router-dom';
import useMerchantRoutes, { MerchantRoutes } from '../../../../hooks/useMerchantRoutes';
import { PaymentButton as PaymentButtonType } from '../../../../redux/MerchantsState/MerchantTypes';
import TestIds, { formatTestId } from '../../../../test/TestIds';
import AcceptedCurrenciesField from '../AcceptedCurrenciesField/AcceptedCurrenciesField';
import AutoconvertField from '../AutoconvertField/AutoconvertField';
import Mode from '../Mode/Mode';
import NameField from '../NameField/NameField';
import PaymentButtonAction from '../PaymentButtonAction/PaymentButtonAction';

import styles from './PaymentButton.module.scss';

type PaymentButtonProps = {
	button: PaymentButtonType;
};

const PaymentButton: FC<PaymentButtonProps> = ({ button }) => {
	const { getUrl } = useMerchantRoutes();

	return (
		<Link
			data-cy={formatTestId(TestIds.PaymentButton_0, button.id)}
			className={styles.button}
			to={getUrl(MerchantRoutes.PaymentButton, { paymentButtonId: button.id })}
		>
			<NameField name={button.name} />
			<AutoconvertField accountId={button.receiveAccountId} />
			<AcceptedCurrenciesField currencies={button.payCurrencyCodes} />
			<Mode status={{ enabled: button.enabled }} />
			<PaymentButtonAction button={button} />
		</Link>
	);
};

export default PaymentButton;
