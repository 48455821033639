import { useIntl, defineMessages, MessageDescriptor, FormattedMessage } from 'react-intl';
import { FormEvent, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString, { ParsedQuery } from 'query-string';
import { useDispatch } from 'react-redux';
import { CurrencyEnum, CurrencyIcon, currencyUtils } from '@spectrocoin/sc-currencies';
import { AxiosResponse } from 'axios';
import { getEstimateFeeURL, createWithdrawCryptosURL } from '../../../../redux/endpoints';
import Input from '../../../../components/Input/Input';
import Button, { ButtonType } from '../../../../components/Button/Button';
import Textarea from '../../../../components/Textarea/Textarea';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import WithdrawFormContainer, {
	WithdrawSteps,
} from '../../Shared/WithdrawFormContainer/WithdrawFormContainer';
import { FormData, FeeResponse } from '../CryptoWithdrawForm/CryptoWithdrawForm';
import { formatPrecision, toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';
import { CurrencyNetwork } from '../../../../redux/AccountsState/AccountsTypes';
import WithdrawConfirm from '../../Shared/WithdrawConfirm/WithdrawConfirm';
import {
	CryptoWithdrawResponse,
	WithdrawType,
} from '../../../../redux/WithdrawState/WithdrawTypes';
import WithdrawSuccess from '../../Shared/WithdrawSuccess/WithdrawSuccess';
import styles from './CryptoBulkWithdrawForm.module.scss';
import {
	isAmountGreaterThan,
	isAmountGreaterThanZero,
} from '../../../../helpers/inputValidation/inputValidation';
import baseMsg from '../../../../messages/base.messages';
import inputErrors from '../../../../messages/inputErrors.messages';
import Seo from '../../../../components/Seo/Seo';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import useCurrentWallet from '../../../../hooks/useCurrentWallet';
import SelectOption from '../../../../interfaces/SelectOption';
import { getAccountNetwork } from '../../../../redux/AccountsState/AccountsActions';
import Select from '../../../../components/Select/Select';
import TransfersFormLayout from '../../../../layout/TransfersFormLayout/TransfersFormLayout';
import { HowToType } from '../../../../layout/TransfersFormLayout/HowTo/HowTo';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	title: {
		id: 'cryptoBulkWithdrawForm.title',
		defaultMessage: 'Bulk BTC Send',
	},
	addressLabel: {
		id: 'cryptoWithdrawForm.addressLabel',
		defaultMessage: 'BTC address/email and amount',
	},
	addressPlaceholder: {
		id: 'cryptoWithdrawForm.addressPlaceholder',
		defaultMessage: `BTC address; amount; 
BTC address; amount;
BTC address; amount; 
BTC address; amount; 
BTC address; amount; 
BTC address; amount; 
BTC address; amount;
BTC address; amount;
    `,
	},
	invalidPattern: {
		id: 'cryptoWithdrawForm.invalidPattern',
		defaultMessage: 'Invalid input pattern',
	},
	amountTooLow: {
		id: 'cryptoWithdrawForm.amountTooLow',
		defaultMessage: 'Added amounts should be bigger then 0',
	},
	totalWithdrawAmount: {
		id: 'cryptoWithdrawForm.totalWithdrawAmount',
		defaultMessage: 'Total withdrawal amount:',
	},
	remainingBalance: {
		id: 'cryptoWithdrawForm.remainingBalance',
		defaultMessage: 'Remaining balance:',
	},
	amountIsTooBig: {
		id: 'cryptoWithdrawForm.amountIsTooBig',
		defaultMessage: 'Amount is bigger then it could be',
	},
});

interface Params {
	id: string;
}

const numericRegex = /^-?\d+\.?\d*$/;

const initFeeData: FeeResponse = {
	feeAmount: '0',
	gasLimit: 0,
	gasPrice: '0',
};

const CryptoBulkWithdrawForm = () => {
	const dispatch = useDispatch();
	const { pathname, search } = useLocation();
	const { replace } = useHistory();
	const { locale, formatMessage } = useIntl();
	const { id } = useParams<Params>();
	const parsedQuery: ParsedQuery<string> = queryString.parse(search);
	const { step } = parsedQuery;
	const [feeData, setFeeData] = useState<FeeResponse>(initFeeData);
	const [form, setForm] = useState<FormData[]>([]);
	const currentWallet = useCurrentWallet();
	const [bulk, setBulk] = useState<string>('');
	const [errorMessage, setErrorMessage] = useState<MessageDescriptor>();
	const [bulkError, setBulkError] = useState<MessageDescriptor | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [memo, setMemo] = useState<string>('');
	const [successData, setSuccessData] = useState<CryptoWithdrawResponse[]>([]);
	const [totalWithdrawAmount, setTotalWithdrawAmount] = useState<string>('0');
	const [remainingAmount, setRemainingAmount] = useState<string>(
		currentWallet?.availableBalance || '0'
	);
	const [list, setList] = useState<SelectOption[]>([]);
	const [network, setNetwork] = useState<CurrencyNetwork['networkName'] | null>(null);
	const [networkPublicName, setNetworkPublicName] = useState<string | undefined>(undefined);

	const estimateFee = (receivers: FormData[]) =>
		new Promise((resolve, reject) => {
			setIsLoading(true);
			void axiosInstance
				.post(getEstimateFeeURL(), {
					currencyCode: CurrencyEnum.BTC,
					receivers,
					network,
				})
				.then(({ data }: AxiosResponse<FeeResponse>) => {
					setFeeData(data);
					resolve(data);
				})
				.catch(({ response: { data } }) => {
					reject(data);
				})
				.then(() => {
					setIsLoading(false);
				});
		});

	const onBlur = () => {
		const formData = formatFields(bulk);
		if (getIsFormValid()) {
			return estimateFee(formData)
				.then((data: FeeResponse) => setFeeData(data))
				.catch(() => setFeeData(initFeeData));
		}
		return null;
	};

	const calculateAllAmountInputValues = (data: FormData[]) =>
		data.reduce((accumulator: string, object: FormData) => {
			return toDecimal(accumulator)
				.plus(toDecimal(object.amount || 0))
				.toString();
		}, '0');

	const formatFields = (data: string) => {
		if (data.search(';') !== -1) {
			const separatedReceivers = data
				.replace(/\s/g, '')
				.split(';')
				.slice(
					0,
					data.replace(/\s/g, '').split(';').length % 2 === 0
						? data.replace(/\s/g, '').split(';').length
						: -1
				);
			return separatedReceivers.reduce((acc: FormData[], cur: string, index: number) => {
				if (!numericRegex.test(cur)) {
					acc.push({
						address: cur,
						amount: numericRegex.test(separatedReceivers[index + 1])
							? formatPrecision(
									toDecimal(separatedReceivers[index + 1]).toString(),
									CurrencyEnum.BTC
							  )
							: '',
					});
				}
				return acc;
			}, []);
		}
		return [];
	};

	const getIsFormValid = () => {
		let isValid = true;
		const formData = formatFields(bulk);
		if (formData.length === 0) {
			setBulkError(inputErrors.cannotBeEmpty);
			isValid = false;
		}
		if (formData.length > 0) {
			const fullAmount = toDecimal(calculateAllAmountInputValues(formData)).plus(
				toDecimal(feeData.feeAmount)
			);
			if (
				isValid &&
				isAmountGreaterThan(fullAmount.toString(), currentWallet?.availableBalance || 0)
			) {
				setBulkError(messages.amountIsTooBig);
				isValid = false;
			}
			formData.forEach(({ address, amount }: FormData) => {
				if (isValid && (!address || !amount || !numericRegex.test(amount))) {
					setBulkError(messages.invalidPattern);
					isValid = false;
				}
				if (
					isValid &&
					!Number.isNaN(parseFloat(amount)) &&
					!isAmountGreaterThanZero(amount)
				) {
					setBulkError(messages.amountTooLow);
					isValid = false;
				}
			});
		}
		if (isValid) setErrorMessage(undefined);

		return isValid;
	};

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!getIsFormValid()) return null;
		return estimateFee(formatFields(bulk))
			.then((data: FeeResponse) => {
				setForm(formatFields(bulk));
				setFeeData(data);
				return replace({
					pathname,
					search: `step=${WithdrawSteps.CONFIRM}`,
				});
			})
			.catch((err) => {
				setErrorMessage(getErrorMessageOrDefault(err));
			});
	};

	const postData = () => {
		setIsLoading(true);
		return axiosInstance.post(createWithdrawCryptosURL(), {
			accountId: id,
			currencyCode: CurrencyEnum.BTC,
			fee: feeData.feeAmount,
			feeAccount: id,
			memo,
			network,
			receivers: form,
		});
	};

	const onSuccess = (data: CryptoWithdrawResponse[]) => setSuccessData(data);

	useEffect(() => {
		if (!step) {
			setSuccessData([]);
			setForm([]);
			setBulk('');
			setMemo('');
			setIsLoading(false);
		}
	}, [pathname, replace, step]);

	useEffect(() => {
		if (bulk) setBulkError(null);
	}, [bulk]);

	useEffect(() => {
		if (bulk && feeData.feeAmount) {
			const formData = formatFields(bulk);
			const calcTotalAmount = toDecimal(calculateAllAmountInputValues(formData)).plus(
				toDecimal(feeData.feeAmount)
			);
			const calcRemainingAmount = toDecimal(currentWallet?.availableBalance || 0)
				.minus(toDecimal(calculateAllAmountInputValues(formData)))
				.minus(toDecimal(feeData.feeAmount));
			setTotalWithdrawAmount(calcTotalAmount.toString());
			setRemainingAmount(
				isAmountGreaterThanZero(calcRemainingAmount.toString())
					? calcRemainingAmount.toString()
					: '0'
			);
		}
	}, [bulk, currentWallet?.availableBalance, feeData]);

	useEffect(() => {
		if (currentWallet && !currentWallet?.networkList)
			void dispatch(getAccountNetwork(CurrencyEnum.BTC, id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentWallet]);

	useEffect(() => {
		if (currentWallet?.networkList && currentWallet?.networkList.length !== 0) {
			setList(
				currentWallet?.networkList?.map(({ networkName, feeCurrencyCode, publicName }) => {
					return {
						label: (
							<div className={styles.option}>
								<CurrencyIcon
									currencyType={CurrencyEnum[feeCurrencyCode]}
									className={styles.icon}
								/>
								<span className={styles.text}>{publicName}</span>
							</div>
						),
						value: networkName,
					};
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentWallet?.networkList]);

	useEffect(() => {
		if (list?.length > 0) {
			setNetwork(list[0].value);
		}
	}, [list]);

	useEffect(() => {
		if (currentWallet?.networkList)
			setNetworkPublicName(
				currentWallet?.networkList?.find(({ networkName }) => networkName === network)
					?.publicName
			);
	}, [currentWallet?.networkList, network]);

	return (
		<>
			<Seo title={withdrawMessages.metaBulkTitle} />
			<WithdrawFormContainer
				walletId={id}
				isLoaded={!!currentWallet && !!currentWallet.networkList}
				title={
					<PageTitle
						previousPageLink={
							step === WithdrawSteps.CONFIRM
								? `/${locale}/withdraw/crypto/BTC/${id}/bulk?step=${WithdrawSteps.PREPARE}`
								: `/${locale}/withdraw/crypto/BTC/${id}`
						}
						isPreviousPageLinkVisibleOnDesktop={step !== WithdrawSteps.DONE}
						isPreviousPageLinkVisibleOnMobile={step !== WithdrawSteps.DONE}
						title={step !== WithdrawSteps.DONE ? formatMessage(messages.title) : ''}
					/>
				}
				form={
					<TransfersFormLayout
						title={'Crypto'}
						providerType={'Crypto'}
						method={null}
						type={HowToType.WITHDRAW}
					>
						<form className={styles.form} onSubmit={handleSubmit}>
							<InfoInput
								label={withdrawMessages.withdrawalWallet}
								balance={currentWallet?.availableBalance || '0'}
								currency={CurrencyEnum.BTC}
							/>
							<div className={styles.inputGroup}>
								<Select
									label={formatMessage(baseMsg.network)}
									inputGroupClassName={styles.selectInputGroup}
									className={styles.select}
									labelClassName={styles.label}
									disabled={list.length === 0 || list.length === 1}
									value={network}
									options={list}
									onChange={setNetwork}
								/>
							</div>
							<Textarea
								label={messages.addressLabel}
								labelClassName={styles.input}
								inputGroupClassName={styles.inputGroup}
								value={bulk}
								onChange={setBulk}
								placeholder={messages.addressPlaceholder}
								errorMessage={bulkError}
								onBlur={() => onBlur()}
							/>
							<Input
								value={memo}
								label={baseMsg.memo}
								onChange={setMemo}
								labelClassName={styles.input}
								placeholder={baseMsg.transactionMemoPlaceholder}
								inputGroupClassName={styles.inputGroup}
							/>
							<InfoInput
								label={messages.totalWithdrawAmount}
								balance={totalWithdrawAmount}
								currency={CurrencyEnum.BTC}
							/>
							<InfoInput
								label={messages.remainingBalance}
								balance={remainingAmount}
								currency={CurrencyEnum.BTC}
							/>
							{errorMessage && (
								<NotificationMessage
									withIcon
									type={NotificationType.Error}
									style={NotificationStyle.Border}
									className={styles.errorMessage}
									message={<FormattedMessage {...errorMessage} />}
								/>
							)}
							<Button
								className={styles.button}
								text={baseMsg.submit}
								type={ButtonType.SUBMIT}
								isLoading={isLoading}
								isDisabled={isLoading}
							/>
						</form>
					</TransfersFormLayout>
				}
				confirm={
					<WithdrawConfirm
						type={WithdrawType.CRYPTOCURRENCY}
						currency={CurrencyEnum.BTC}
						fee={feeData}
						formData={form}
						memo={memo}
						onSuccess={onSuccess}
						onSubmit={postData}
						network={network ? networkPublicName : ''}
					/>
				}
				done={
					<WithdrawSuccess
						data={successData}
						currency={CurrencyEnum.BTC}
						type={WithdrawType.CRYPTOCURRENCY}
						network={network ? networkPublicName : ''}
					/>
				}
				isDone={successData.length > 0}
			/>
		</>
	);
};

export default CryptoBulkWithdrawForm;
