/* eslint-disable camelcase */
import {
	ChangeEvent,
	FC,
	PropsWithChildren,
	ReactNode,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { components } from 'react-select';
import { AxiosResponse } from 'axios';
import { CurrencyIcon, CurrencyEnum } from '@spectrocoin/sc-currencies';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { RemoteError } from '../../../../interfaces/RemoteData';
import {
	GetCurrentCard,
	isCardFunctionsEnabled,
} from '../../../../helpers/cardsHelper/cardsHelper';
import { formatPrecision, toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import { RootState } from '../../../../redux/Store';
import { LoadStatus } from '../../../../redux/CardsState/CardsTypes';
import { getCard } from '../../../../redux/CardsState/CardsActions';
import { getAccountById } from '../../../../redux/AccountsState/AccountsActions';
import { resetTwoFaData } from '../../../../redux/TwoFaState/TwoFaActions';
import { getDebitCardLoadURL, getDebitCardLoadStatusURL } from '../../../../redux/endpoints';
import { Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import Select from '../../../../components/Select/Select';
import CurrencyInput from '../../../../components/CurrencyInput/CurrencyInput';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import CardStatus from '../../Shared/CardStatus/CardStatus';
import styles from './Load.module.scss';
import Seo from '../../../../components/Seo/Seo';
import { isAmountGreaterThanZero } from '../../../../helpers/inputValidation/inputValidation';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import inputErrors from '../../../../messages/inputErrors.messages';
import { toPlainAmount } from '../../../../helpers/currencyAmountHelper/currencyAmountHelper';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import { fromEntries } from '../../../../helpers/objectHelper/objectHelper';
import useFormatAmount from '../../../../hooks/useFormatAmount';
import TwoFaContainer from '../../../../containers/TwoFaContainer/TwoFaContainer';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	amountTooSmall: {
		id: 'load.amountTooSmall',
		defaultMessage: 'Amount too small',
	},
	successMsg: {
		id: 'load.success',
		defaultMessage: 'Card loaded successfully',
	},
	pendingMsg: {
		id: 'load.pending',
		defaultMessage: 'Card load is pending',
	},
	failedMsg: {
		id: 'load.failed',
		defaultMessage: 'Card load failed',
	},
	noResult: {
		id: 'load.noResult',
		defaultMessage: 'No result',
	},
	title: {
		id: 'load.title',
		defaultMessage: 'Load card',
	},
	metaTitle: {
		id: 'load.metaTitle',
		defaultMessage: 'Load card',
	},
	loadFrom: {
		id: 'load.loadFrom',
		defaultMessage: 'Load from',
	},
	loadAmount: {
		id: 'load.loadAmount',
		defaultMessage: 'Load amount',
	},
	load: {
		id: 'load.submit',
		defaultMessage: 'Load',
	},
	mobileBack: {
		id: 'load.back',
		defaultMessage: 'Back to card',
	},
});

interface OptionsProps {
	value: string;
	label: ReactNode;
	currencyName: string;
	currencyCode: string;
	balance: string;
}

interface FormProps {
	from: string;
	amount: string;
}

interface FormErrorProps {
	from: MessageDescriptor | null;
	amount: MessageDescriptor | null;
}

interface SuccessDataProps {
	debitCardLoadId: string;
}

const SingleValue: FC<PropsWithChildren<{ innerProps: any; data: OptionsProps }>> = (props) => {
	const { currencyCode, balance } = props.data;
	const amountFormatter = useFormatAmount();

	return (
		<components.SingleValue className={styles.option} {...(props as any)}>
			<CurrencyIcon currencyType={CurrencyEnum[currencyCode]} className={styles.icon} />
			<div className={styles.balance}>
				{amountFormatter(formatPrecision(balance, CurrencyEnum[currencyCode]))}
			</div>
		</components.SingleValue>
	);
};

const Load = () => {
	let attempt = 0;
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const amountFormatter = useFormatAmount();
	const { url } = useRouteMatch();
	const card = GetCurrentCard();
	const { isTablet } = useSelector((state: RootState) => state.AppState);
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const [processStatus, setProcessStatus] = useState<LoadStatus | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<MessageDescriptor | string | null>(null);
	const [errorParams, setErrorParams] = useState<{ [key: string]: string }[]>([]);
	const [options, setOptions] = useState<OptionsProps[] | null>(null);
	const [form, setForm] = useState<FormProps>({
		from: '',
		amount: '',
	});
	const [formError, setFormError] = useState<FormErrorProps>({
		from: null,
		amount: null,
	});

	const pollStatus = useCallback(
		async (debitCardLoadId: string) => {
			setIsLoading(true);
			const { status } = await axiosInstance
				.get(getDebitCardLoadStatusURL(debitCardLoadId))
				.then(({ data }: AxiosResponse) => {
					return data;
				});
			if (status !== LoadStatus.COMPLETED && attempt < 5) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				attempt += 1;
				setTimeout(() => pollStatus(debitCardLoadId), 1500);
			} else {
				setProcessStatus(status);
				setIsLoading(false);
				dispatch(resetTwoFaData());
				void dispatch(getCard(card?.id || ''));
			}
		},
		[attempt, card?.id, dispatch]
	);

	const onSuccess = (data: SuccessDataProps) => {
		const { debitCardLoadId } = data;
		setIsLoading(false);
		if (debitCardLoadId) {
			void dispatch(getAccountById(form.from));
			return pollStatus(debitCardLoadId);
		}
		return null;
	};

	const validation = () => {
		let errors: FormErrorProps = {
			from: null,
			amount: null,
		};

		if (!form.from) {
			errors = {
				...errors,
				from: inputErrors.cannotBeEmpty,
			};
		}
		if (!form.amount) {
			errors = {
				...errors,
				amount: inputErrors.cannotBeEmpty,
			};
		}
		if (form.amount && !isAmountGreaterThanZero(form.amount, true)) {
			errors = {
				...errors,
				amount: messages.amountTooSmall,
			};
		}
		if (
			form.amount &&
			toDecimal(form.amount, true).greaterThan(
				wallets?.filter((o) => {
					return o?.id === form?.from;
				})[0]?.availableBalance || 0
			)
		) {
			errors = {
				...errors,
				amount: inputErrors.insufficientFunds,
			};
		}
		setFormError({
			...formError,
			...errors,
		});
		return Object.values(errors).every((value) => {
			if (value === null) {
				return true;
			}

			return false;
		});
	};

	const onFailure = (err: RemoteError) => {
		setError(getErrorMessageOrDefault(err as RemoteError));
		setErrorParams(err?.errorParameters || []);
		setIsLoading(false);
	};

	const handleSubmit = () => {
		if (validation()) {
			setIsLoading(true);
			return axiosInstance
				.post(
					getDebitCardLoadURL(card?.id || '', !isCardFunctionsEnabled(card!.cardType)),
					{
						loadAmount: toPlainAmount(form.amount, true),
						payAccountId: form.from,
						receiveAmount: toPlainAmount(form.amount, true),
						receiveCurrency: CurrencyEnum.EUR,
						cardNumber: card?.pan,
					}
				)
				.then(({ data }: AxiosResponse<SuccessDataProps>) => {
					return onSuccess(data);
				})
				.catch(({ response: { data } }) => {
					const { errorParameters } = data;
					setError(getErrorMessageOrDefault(data as RemoteError));
					setErrorParams(errorParameters);
					setIsLoading(false);
				});
		}
		return null;
	};

	const handleChange = ({ target: { value, name } }: ChangeEvent<HTMLInputElement>) => {
		setError(null);
		setErrorParams([]);
		setFormError({
			...formError,
			[name]: null,
		});
		setForm({
			...form,
			[name]: value,
		});
	};

	useEffect(() => {
		if (wallets?.length !== 0) {
			const walletOptions = wallets?.reduce((acc: OptionsProps[], curr: Wallet) => {
				if (CurrencyEnum[curr.currencyCode] === CurrencyEnum.EUR)
					acc.push({
						value: `${curr.id}`,
						label: (
							<div className={styles.option}>
								<CurrencyIcon
									currencyType={CurrencyEnum[curr.currencyCode]}
									className={styles.icon}
								/>
								<div className={styles.balance}>
									{amountFormatter(
										formatPrecision(
											curr.availableBalance,
											CurrencyEnum[curr.currencyCode]
										)
									)}
								</div>
								<div className={styles.details}>
									<div className={styles.code}>{curr.currencyCode}</div>
									<div className={styles.name}>{curr.currencyName}</div>
								</div>
							</div>
						),
						currencyCode: curr.currencyCode,
						currencyName: curr.currencyName,
						balance: curr.availableBalance,
					});

				return acc;
			}, []);
			setOptions(walletOptions || null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [wallets]);

	useEffect(() => {
		if (!form.from && options && options?.length !== 0)
			setForm({
				...form,
				from: options[0].value,
			});
	}, [form, options]);

	useEffectOnce(() => {
		dispatch(resetTwoFaData());
	});

	return (
		<WhiteContainer
			backButtonLink={isTablet ? url.replace('load', 'history') : ''}
			backButtonText={messages.mobileBack}
		>
			<Seo title={messages.metaTitle} />
			<>
				{processStatus && (
					<CardStatus
						status={processStatus}
						title={
							processStatus === LoadStatus.COMPLETED
								? messages.successMsg
								: processStatus === LoadStatus.PENDING
								? messages.pendingMsg
								: messages.failedMsg
						}
						link={'history'}
					/>
				)}
				{!processStatus && (
					<TwoFaContainer
						onSuccess={onSuccess}
						onFailure={onFailure}
						isLoading={isLoading}
						onSubmit={handleSubmit}
						buttonText={messages.load}
						cancelButtonLink={url.replace('load', 'history')}
					>
						<>
							<InfoHead title={messages.title} />
							<div className={styles.form}>
								{error && (
									<NotificationMessage
										withIcon
										type={NotificationType.Error}
										style={NotificationStyle.Border}
										className={styles.mb20}
										message={
											typeof error === 'string' ? (
												error
											) : (
												<FormattedMessage
													{...error}
													values={
														errorParams &&
														fromEntries<Record<string, string>>(
															errorParams?.map(({ key, value }) => [
																key,
																value,
															])
														)
													}
												/>
											)
										}
									/>
								)}
								<Select
									isSearchable={false}
									label={messages.loadFrom}
									options={options}
									labelClassName={styles.label}
									disabled={isLoading}
									onChangeObject={({ value }: OptionsProps) =>
										setForm({ ...form, from: value })
									}
									componentOverrides={{
										SingleValue,
									}}
									className={styles.select}
									noResultsText={formatMessage(messages.noResult)}
									name="from"
									value={form.from}
									classNamePrefix="from"
									colors={{
										primary: '#f5f8ff',
										primary25: '#F6F8FF',
										primary50: '#F6F8FF',
										primary75: '#f5f8ff',
									}}
								/>
								<CurrencyInput
									id="amount"
									value={form.amount}
									label={messages.loadAmount}
									currencyCode={CurrencyEnum.EUR}
									onChange={handleChange}
									isLoading={isLoading}
									error={formError.amount}
								/>
							</div>
						</>
					</TwoFaContainer>
				)}
			</>
		</WhiteContainer>
	);
};

export default Load;
