import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { History } from 'history';
import TransfersOptionContainer from '../TransfersOptionContainer/TransfersOptionContainer';
import { WithdrawType } from '../../redux/WithdrawState/WithdrawTypes';
import WithdrawMessages from '../../redux/WithdrawState/WithdrawMessages';
import TransfersSidebar, { SidebarType } from '../../layout/TransfersSidebar/TransfersSidebar';
import { RootState } from '../../redux/Store';
import { fetchAllWithdrawMethods } from '../../redux/WithdrawState/WithdrawActions';
import EWalletWithdraw from '../../pages/Withdraw/EWalletWithdraw/EWalletWithdraw';
import GiftCards from '../../pages/Withdraw/GiftCards/GiftCards';
import BankWithdraw from '../../pages/Withdraw/BankWithdraw/BankWithdraw';
import MobileTopups from '../../pages/Withdraw/MobileTopups/MobileTopups';
import VoucherWithdrawsForm from '../../pages/Withdraw/VoucherWithdraws/VoucherWithdrawsForm/VoucherWithdrawsForm';
import CryptoWithdrawForm from '../../pages/Withdraw/CryptoWithdraws/CryptoWithdrawForm/CryptoWithdrawForm';
import CryptoBulkWithdrawForm from '../../pages/Withdraw/CryptoWithdraws/CryptoBulkWithdrawForm/CryptoBulkWithdrawForm';
import EWalletWithdrawForm from '../../pages/Withdraw/EWalletWithdraw/EWalletWithdrawForm/EWalletWithdrawForm';
import BankWithdrawForm from '../../pages/Withdraw/BankWithdraw/BankWithdrawForm/BankWithdrawForm';
import CryptoWithdraws from '../../pages/Withdraw/CryptoWithdraws/CryptoWithdraws';
import WithdrawHistory from '../../pages/Withdraw/Shared/WithdrawHistory/WithdrawHistory';
import GiftCardsForm from '../../pages/Withdraw/GiftCards/GiftCardsForm/GiftCardsForm';
import MobileTopupsForm from '../../pages/Withdraw/MobileTopups/MobileTopupsForm/MobileTopupsForm';
import OffchainForm from '../../pages/Withdraw/Offchain/OffchainForm/OffchainForm';
import Seo from '../../components/Seo/Seo';
import useEffectOnce from '../../hooks/useEffectOnce';

const WithdrawContainer = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { url }: { url: string } = useRouteMatch();
	const { pathname } = useLocation();
	const { replace }: History = useHistory();
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const { isMobile, isTablet } = useSelector((state: RootState) => state.AppState);
	const { paymentMethods } = useSelector((state: RootState) => state.WithdrawState);
	// eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
	const { crypto, ewallet, bank, gift_cards, voucher, topups, offchain } = paymentMethods;

	const types = {
		[WithdrawType.CRYPTOCURRENCY]: {
			icon: WithdrawType.CRYPTOCURRENCY,
			link: `${url}/crypto`,
			name: formatMessage(WithdrawMessages.cryptoWithdraws),
			optionsCount: crypto?.totalCount || 0,
		},
		[WithdrawType.EWALLET]: {
			icon: WithdrawType.EWALLET,
			name: formatMessage(WithdrawMessages.eWalletWithdraws),
			optionsCount: ewallet?.totalCount,
			link: `${url}/e-wallet`,
		},
		[WithdrawType.BANK]: {
			icon: WithdrawType.BANK,
			name: formatMessage(WithdrawMessages.bankWithdraws),
			link: `${url}/bank`,
			optionsCount: bank.totalCount,
		},
		[WithdrawType.GIFT]: {
			icon: WithdrawType.GIFT,
			name: formatMessage(WithdrawMessages.giftCardsWithdraws),
			link: `${url}/gift-cards`,
			optionsCount: gift_cards.totalCount,
		},
		[WithdrawType.VOUCHER]: {
			icon: WithdrawType.VOUCHER,
			name: formatMessage(WithdrawMessages.voucherWithdraws),
			link: `${url}/vouchers`,
			optionsCount: voucher.totalCount,
			isVerified: user?.verified || !!(user?.name && user?.surname && user?.dob),
		},
		...(offchain?.data?.length > 0 && {
			[WithdrawType.OFFCHAIN]: {
				icon: WithdrawType.OFFCHAIN,
				link: `${url}/offchain`,
				name: formatMessage(WithdrawMessages.offchainWithdraws),
				optionsCount: 1,
			},
		}),
		[WithdrawType.TOPUP]: {
			icon: WithdrawType.TOPUP,
			name: formatMessage(WithdrawMessages.topupWithdraws),
			link: `${url}/mobile-top-ups`,
			optionsCount: topups.totalCount,
		},
	};

	// always redirect to crypto page as default on desktop
	useEffect(() => {
		if (!(isMobile || isTablet) && pathname === url) {
			replace(`${url}/crypto`);
		}
	}, [url, replace, isMobile, pathname, isTablet]);

	useEffectOnce(() => {
		void dispatch(fetchAllWithdrawMethods());
	});

	return (
		<>
			<Seo title={WithdrawMessages.metaWithdrawsTitle} />
			<TransfersSidebar sidebarType={SidebarType.WITHDRAW} types={types} />
			<TransfersOptionContainer
				title={formatMessage(WithdrawMessages.backToWithdrawOptions)}
				previousPageLink={url}
				showStartingBlock={pathname.search('bank') !== -1}
			>
				{offchain?.data?.length > 0 && (
					<Route exact path={`${url}/offchain`} component={OffchainForm} />
				)}
				<Route
					exact
					path={`${url}/crypto`}
					render={() => <CryptoWithdraws accounts={crypto?.data} />}
				/>
				<Route exact path={`${url}/crypto/:currency/:id`} component={CryptoWithdrawForm} />
				<Route
					exact
					path={`${url}/crypto/BTC/:id/bulk`}
					component={CryptoBulkWithdrawForm}
				/>
				<Route exact path={`${url}/e-wallet`} component={EWalletWithdraw} />
				<Route exact path={`${url}/e-wallet/:id`} component={EWalletWithdrawForm} />
				<Route exact path={`${url}/bank`} component={BankWithdraw} />
				<Route exact path={`${url}/bank/:id`} component={BankWithdrawForm} />
				<Route exact path={`${url}/gift-cards`} component={GiftCards} />
				<Route exact path={`${url}/gift-cards/:id`} component={GiftCardsForm} />
				<Route exact path={`${url}/vouchers`} component={VoucherWithdrawsForm} />
				<Route exact path={`${url}/mobile-top-ups`} component={MobileTopups} />
				<Route
					exact
					path={`${url}/mobile-top-ups/:countryCode/:id`}
					component={MobileTopupsForm}
				/>
				<Route
					exact
					path={`${url}/history/:withdrawType/:filter?`}
					component={WithdrawHistory}
				/>
			</TransfersOptionContainer>
		</>
	);
};

export default WithdrawContainer;
