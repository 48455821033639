import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import classNames from 'classnames';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { CurrencyIcon, currencyUtils, CurrencyEnum } from '@spectrocoin/sc-currencies';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { RootState } from '../../../../redux/Store';
import { RatesDetailsResponse } from '../../../../redux/RatesState/RatesTypes';
import { formatPrecision, toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import styles from './CustomInput.module.scss';
import { DeviceNameType } from '../../../../redux/AppState/AppTypes';
import TestIds from '../../../../test/TestIds';
import useFormatAmount from '../../../../hooks/useFormatAmount';

interface CustomInputProps {
	id: string;
	label?: MessageDescriptor;
	currency: CurrencyEnum;
	onChange: ({ target: { value, id } }: ChangeEvent<HTMLInputElement>) => void;
	isLoading: boolean;
	value: string;
	isIconVisible?: boolean;
	maxValue?: string | null;
	errorMessage?: MessageDescriptor | string | null;
	isRefAmountVisible?: boolean;
	disabled?: boolean;
	className?: string;
	setAllAmount?: () => void;
}

const CustomInput = ({
	className,
	id,
	label,
	currency,
	onChange,
	isLoading,
	value,
	isIconVisible = false,
	maxValue = null,
	errorMessage = null,
	isRefAmountVisible = true,
	disabled = false,
	setAllAmount,
	...rest
}: CustomInputProps) => {
	const { formatMessage } = useIntl();
	const [showAmount, setShowAmount] = useState('');
	const { ratesDetails } = useSelector((state: RootState) => state?.RatesState);
	const { deviceOS } = useSelector((state: RootState) => state?.AppState);
	const isAppleOS = useMemo(() => deviceOS === DeviceNameType.IPHONE, [deviceOS]);
	const amountFormatter = useFormatAmount();
	const refCurrency = Cookies.get('primaryCurrency') || CurrencyEnum.USD;
	const [fixedDecimal, setFixedDecimal] = useState(!!value);
	const refCurrData = ratesDetails?.find(
		({ currencyCode }: RatesDetailsResponse) => currencyCode === currency
	);

	useEffect(() => setShowAmount(value), [value, currency]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => (isLoading ? setFixedDecimal(true) : setFixedDecimal(fixedDecimal)), [
		isLoading,
	]);

	return (
		<div
			className={classNames(
				styles.container,
				{
					[styles.disabled]: disabled || !refCurrData,
				},
				className
			)}
			{...rest}
		>
			{label && (
				<label className={styles.label} htmlFor={id}>
					{formatMessage(label)}
				</label>
			)}
			<div
				className={classNames(styles.inputWithAddon, {
					[styles.withIcon]: isIconVisible,
					[styles.errorInput]: errorMessage,
				})}
			>
				{isIconVisible && <CurrencyIcon currencyType={currency} className={styles.icon} />}
				<div className={styles.control}>
					<div>
						<NumericFormat
							data-cy={TestIds.CustomInputValue}
							className={classNames(styles.numberInput)}
							decimalSeparator={isAppleOS ? ',' : '.'}
							decimalScale={
								currency ? currencyUtils.getConfigOrDefault(currency).precision : 0
							}
							allowNegative={false}
							onKeyUp={(event: any) => {
								setShowAmount(event.target.value);
								onChange(event);
							}}
							inputMode="decimal"
							thousandSeparator={isAppleOS ? '.' : ','}
							fixedDecimalScale={fixedDecimal}
							onFocus={() => setFixedDecimal(false)}
							onBlur={() => setFixedDecimal(true)}
							id={id}
							name={id}
							disabled={isLoading}
							value={showAmount}
							autoComplete="off"
							placeholder={amountFormatter(formatPrecision(0, currency))}
						/>
						<div className={styles.baseCurCode}>{currency}</div>
					</div>
					{isRefAmountVisible && refCurrency !== currency && (
						<div data-cy={TestIds.CustomInputRef} className={styles.poa}>
							<div className={styles.refCurValue}>
								{amountFormatter(
									formatPrecision(
										toDecimal(value || 0, true)
											.times(toDecimal(refCurrData?.value || 0))
											.toFixed(refCurrData?.scale)
											.toString(),
										CurrencyEnum[refCurrency]
									)
								)}
							</div>
							<div className={styles.refCurCode}>{refCurrency}</div>
						</div>
					)}
				</div>
				{errorMessage && (
					<div className={styles.error} data-cy={`${id || ''}CustomInputErrorMessage`}>
						{typeof errorMessage === 'string'
							? errorMessage
							: formatMessage(errorMessage)}
					</div>
				)}
			</div>
			{maxValue && (
				<div
					className={classNames(styles.useAll, { [styles.hidden]: errorMessage })}
					onClick={() => {
						if (typeof setAllAmount === 'function') {
							return setAllAmount();
						}
						return onChange({
							target: {
								value: amountFormatter(toDecimal(maxValue).toString()),
								id,
								name: id,
							},
						} as ChangeEvent<HTMLInputElement>);
					}}
				>
					<FormattedMessage
						id="customInput.useAll"
						defaultMessage="Use all {amount}"
						values={{
							amount: amountFormatter(formatPrecision(maxValue, currency)),
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default CustomInput;
