import { AxiosError } from 'axios';
import { ActionCreator } from 'redux';
import type { AppDispatch } from '../../../Store';
import { getMerchantsProjectUrl } from '../../../endpoints';
import {
	ProjectDataDeleteProjectAction,
	ProjectDataFetchProjectFulfilledAction,
	ProjectDataFetchProjectPendingAction,
	ProjectDataFetchProjectRejectedAction,
	ProjectDataUpdateProjectAction,
	ProjectDataUpdateProjectsAction,
	Project,
	ProjectsDataActionType,
} from './ProjectsDataTypes';
import axiosInstance from '../../../../helpers/axiosInstance';

const fetchProjectPending: ActionCreator<ProjectDataFetchProjectPendingAction> = (id: string) => ({
	type: ProjectsDataActionType.FETCH_PROJECT_PENDING,
	payload: id,
});

const fetchProjectRejected: ActionCreator<ProjectDataFetchProjectRejectedAction> = (
	id: string
) => ({
	type: ProjectsDataActionType.FETCH_PROJECT_REJECTED,
	payload: id,
});

const fetchProjectFulfilled: ActionCreator<ProjectDataFetchProjectFulfilledAction> = (
	project: Project
) => ({
	type: ProjectsDataActionType.FETCH_PROJECT_FULFILLED,
	payload: project,
});

const updateProject: ActionCreator<ProjectDataUpdateProjectAction> = (project: Project) => ({
	type: ProjectsDataActionType.UPDATE_PROJECT,
	payload: project,
});

const updateProjects: ActionCreator<ProjectDataUpdateProjectsAction> = (projects: Project[]) => ({
	type: ProjectsDataActionType.UPDATE_PROJECTS,
	payload: projects,
});

const deleteProject: ActionCreator<ProjectDataDeleteProjectAction> = (id: string) => ({
	type: ProjectsDataActionType.DELETE_PROJECT,
	payload: id,
});

const fetchProject = (id: string) => async (dispatch: AppDispatch) => {
	dispatch(fetchProjectPending(id));

	await axiosInstance
		.get<Project>(getMerchantsProjectUrl(id))
		.then(({ data }) => {
			dispatch(fetchProjectFulfilled(data));
		})
		.catch((e: AxiosError) => {
			dispatch(fetchProjectRejected(id));
		});
};

export default {
	updateProject,
	updateProjects,
	deleteProject,
	fetchProject,
};
