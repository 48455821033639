import { FC } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import baseMsg from '../../../../../messages/base.messages';
import TextNode, { TextStyles } from '../../../../../components/TextNode/TextNode';
import useMerchantRoutes, { MerchantRoutes } from '../../../../../hooks/useMerchantRoutes';
import { useOrderStatusTranslator } from '../../../../../helpers/merchantsHelper/merchantsHelper';
import { formatPrecision } from '../../../../../helpers/currencyHelper/currencyHelper';
import messages from '../../../../../redux/MerchantsState/MerchantsMessages';
import { Order } from '../../../../../redux/MerchantsState/MerchantTypes';

import styles from './MerchantsOrdersHistoryItem.module.scss';
import convertToLocalTime from '../../../../../helpers/dateHelper/dateHelper';
import TestIds, { formatTestId } from '../../../../../test/TestIds';
import useFormatAmount from '../../../../../hooks/useFormatAmount';

type MerchantsOrdersHistoryItemProps = {
	order: Order;
};

const MerchantsOrdersHistoryItem: FC<MerchantsOrdersHistoryItemProps> = ({ order }) => {
	const { formatMessage } = useIntl();
	const statusTranslator = useOrderStatusTranslator();
	const amountFormatter = useFormatAmount();
	const { getUrl } = useMerchantRoutes();

	const text = formatMessage(messages.receivedX, {
		amount: `${amountFormatter(formatPrecision(order.payAmount, order.payCurrencyCode))} ${
			order.payCurrencyCode
		}`,
	});

	return (
		<li className={classNames(styles.container, styles[order.status])}>
			<Link
				data-cy={formatTestId(TestIds.OrderHistoryItem_0, order.id)}
				className={styles.link}
				to={getUrl(MerchantRoutes.OrderView, {
					orderId: order.id,
				})}
			>
				<div className={styles.details}>
					<div className={styles.label} data-cy={TestIds.OrderHistoryItemLabel}>
						{text}
					</div>
					<div className={styles.amount} data-cy={TestIds.OrderHistoryItemAmount}>
						{`${amountFormatter(
							formatPrecision(order.receiveAmount, order.receiveCurrencyCode)
						)} ${order.receiveCurrencyCode}`}
					</div>
				</div>
				<div className={styles.subtext}>
					<div className={styles.info} data-cy={TestIds.OrderHistoryItemDetails}>
						{order.description && <div>{order.description}</div>}
						<div className={styles.inline}>
							<TextNode style={TextStyles.Ellipsis}>
								{formatMessage(baseMsg.labeledValue, {
									Label: formatMessage(messages.orderId),
									Value: order.orderId,
								})}
							</TextNode>
							<div>{convertToLocalTime(order.created, 'yyyy-MM-dd HH:mm')}</div>
						</div>
					</div>
					<div className={styles.status} data-cy={TestIds.OrderHistoryItemStatus}>
						{statusTranslator(order.status)}
					</div>
				</div>
			</Link>
		</li>
	);
};

export default MerchantsOrdersHistoryItem;
