import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RemoteData, RemoteError } from '../../../../interfaces/RemoteData';
import { Preorder } from '../PreorderTypes';

export type PreorderCreateForm = {
	price: string;
	currency: CurrencyEnum | null;
	orderId: string | null;
	description: string | null;
	callbackUrl: string | null;
	successUrl: string | null;
	failureUrl: string | null;
	emailNotifications: boolean;
	allowMultiplePayments: boolean;
};
export type PreordersCreateState = {
	form: PreorderCreateForm;
	action: RemoteData<string>;
};

export enum PreordersCreateActionType {
	SET_FORM = 'MERCHANTS/PREORDERS/CREATE/SET_FORM',
	RESET = 'MERCHANTS/PREORDERS/CREATE/RESET',
	ACTION_PENDING = 'MERCHANTS/PREORDERS/CREATE/ACTION/PENDING',
	ACTION_REJECTED = 'MERCHANTS/PREORDERS/CREATE/ACTION/REJECTED',
	ACTION_FULFILLED = 'MERCHANTS/PREORDERS/CREATE/ACTION/FULFILLED',
}

export type PreordersCreateSetFormAction = {
	type: PreordersCreateActionType.SET_FORM;
	payload: Partial<PreorderCreateForm>;
};

export type PreordersCreateResetAction = {
	type: PreordersCreateActionType.RESET;
};

export type PreordersCreateActionPendingAction = {
	type: PreordersCreateActionType.ACTION_PENDING;
};

export type PreordersCreateActionRejectedAction = {
	type: PreordersCreateActionType.ACTION_REJECTED;
	payload?: RemoteError;
};

export type PreordersCreateActionFulfilledAction = {
	type: PreordersCreateActionType.ACTION_FULFILLED;
	payload: Preorder;
};
