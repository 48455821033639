import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import Joi from 'joi';
import { AxiosError } from 'axios';
import styles from './SetCallbackUrl.module.scss';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import ReferralsMessages from '../../../../redux/ReferralsState/ReferralsMessages';
import Input from '../../../../components/Input/Input';
import useValidation from '../../../../hooks/useValidation';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import baseMsg from '../../../../messages/base.messages';
import callbackLogo from './callbackLogo.svg';
import inputErrors from '../../../../messages/inputErrors.messages';
import { RemoteData, RemoteError, RemoteStatus } from '../../../../interfaces/RemoteData';
import { getReferralCallbacksURLUrl } from '../../../../redux/endpoints';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import axiosInstance from '../../../../helpers/axiosInstance';

const useCallbackValidator = () => {
	const { formatMessage } = useIntl();
	return useMemo(
		() =>
			Joi.object<{ url: string; secret: string }>({
				url: Joi.string()
					.allow(null, '')
					.uri({ scheme: ['http', 'https'] })
					.message(formatMessage(inputErrors.invalidUrl)),
				secret: Joi.string().allow(null, ''),
			}),
		[formatMessage]
	);
};

const SetCallbackUrl: FC = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const [url, setUrl] = useState<string>('');
	const [secret, setSecret] = useState<string>('');
	const [action, setAction] = useState<RemoteData<void>>({ status: RemoteStatus.None });
	const callbackValidator = useCallbackValidator();

	const [isValid, validationResult] = useValidation({ url, secret }, callbackValidator);

	const onClose = useCallback(() => {
		dispatch(toggleModal());
	}, []);

	const onSetCallbackUrl = useCallback(() => {
		setAction({ status: RemoteStatus.InProgress });
		axiosInstance
			.patch(getReferralCallbacksURLUrl(), { callbackUrl: url, callbackSecret: secret })
			.then(() => {
				setAction({ status: RemoteStatus.Done });
				dispatch(toggleModal());
			})
			.catch((e: AxiosError<RemoteError>) => {
				setAction({ status: RemoteStatus.Error, error: e.response?.data });
			});
	}, [url, secret]);

	return (
		<div className={styles.container}>
			<div>
				<SvgIcon src={callbackLogo} className={styles.icon} />
				<FontAwesomeIcon
					className={styles.closeIcon}
					icon={faTimes}
					onClick={() => onClose()}
				/>
			</div>
			<div className={styles.content}>
				<div className={styles.title}>
					{formatMessage(ReferralsMessages.setRefCallbacks)}
				</div>
				<div>
					{formatMessage(ReferralsMessages.refCallbacksNote, {
						Link: (txt) => (
							<a
								className={styles.link}
								href="https://docs.spectrocoin.com/referral-callbacks"
								target="_blank"
								rel="noreferrer"
							>
								{txt}
							</a>
						),
					})}
				</div>
				<Input
					label={formatMessage(ReferralsMessages.callbackUrl)}
					className={styles.value}
					inputGroupClassName={styles.inputGroup}
					value={url}
					onChange={setUrl}
					errorMessage={validationResult.url}
				/>
				<Input
					label={formatMessage(ReferralsMessages.callbackSecret)}
					className={styles.value}
					inputGroupClassName={styles.inputGroup}
					value={secret}
					onChange={setSecret}
					errorMessage={validationResult.secret}
				/>
			</div>
			<div className={styles.actions}>
				{action.status === RemoteStatus.Error && (
					<NotificationMessage
						withIcon
						type={NotificationType.Error}
						style={NotificationStyle.Border}
						message={formatMessage(getErrorMessageOrDefault(action.error))}
					/>
				)}
				<Button
					className={styles.button}
					buttonStyle={ButtonStyle.PRIMARY}
					type={ButtonType.SUBMIT}
					onClick={onSetCallbackUrl}
					isDisabled={
						!url || !secret || !isValid || action.status === RemoteStatus.InProgress
					}
				>
					{formatMessage(ReferralsMessages.setCallbacks)}
				</Button>
				<Button
					className={styles.button}
					buttonStyle={ButtonStyle.SECONDARY_WITHOUT_BORDER}
					type={ButtonType.SUBMIT}
					onClick={onClose}
				>
					{formatMessage(baseMsg.cancel)}
				</Button>
			</div>
		</div>
	);
};

export default SetCallbackUrl;
