const WalletTestIds = {
	WalletOption_0: 'wallet.option.0',

	// Filter settings
	SettingsWheel: 'wallet.settings',
	AddWalletBtn: 'wallet.addWallet',
	HideBalanceToggle: 'wallet.hideBalance',
	HideEmptyToggle: 'wallet.hideEmpty',
	TypeFilter: 'wallet.type',
	RateFilter: 'wallet.rate',
	SettingsList: 'wallet.list',

	// Wallet title
	WalletTitle: 'wallet.title',
	BackButton: 'wallet.back',

	// Balance block
	BalanceDeposit_0: 'action.deposit.0',
	BalanceExchange_0: 'action.exchange.0',
	BalanceWithdraw_0: 'action.withdraw.0',
	BalanceBuy_0: 'action.buy.0',

	// Address block
	QrCode: 'wallet.qrCode',
	Address: 'wallet.address',
	AddressTypes: 'wallet.types',
	Message: 'wallet.message',

	// Details modal
	ReceivedAmount: 'detail.receivedAmount',
	Description: 'detail.description',

	// History block
	ExportButton: 'history.export',
	OptionsList: 'history.options',

	// Sidebar
	Sidebar: 'wallet.sidebar',
	PortfolioDiagram: 'wallet.portfolio',
	RateGraphWidget: 'wallet.rateGraph',
	RatesDetails: 'wallet.ratesDetails',
	RatesLabel: 'wallet.ratesLabel',
	RatesPercentage: 'wallet.percentage',
	SocialBlock: 'wallet.socialBlock',

	// Portfolio diagram
	PortfolioCurrList: 'portfolio.currencyList',
	PorftolioSelect: 'portfolio.select',
	PortfolioTotal: 'portfolio.total',
};

export default WalletTestIds;
