import { AllHTMLAttributes, ForwardedRef, forwardRef } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import Loader from '../Loader/Loader';
import styles from './Button.module.scss';

// eslint-disable-next-line no-shadow
export enum ButtonStyle {
	DEFAULT = 'default',
	BORDERLESS = 'borderless',
	LINK = 'link',
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	SECONDARY_WITHOUT_BORDER = 'secondaryWithoutBorder',
	TERTIARY = 'tertiary',
}

// eslint-disable-next-line no-shadow
export enum ButtonType {
	ANCHOR_LINK = 'a',
	BUTTON = 'button',
	LINK = 'link',
	SUBMIT = 'submit',
}

interface ButtonProps extends AllHTMLAttributes<HTMLElement> {
	buttonStyle?: ButtonStyle;
	hasIcon?: boolean;
	hasTransition?: boolean;
	link?: string | MessageDescriptor;
	text?: string | MessageDescriptor;
	type?: ButtonType;
	children?: React.ReactNode;
	isDisabled?: boolean;
	isLoading?: boolean;
	plusIcon?: boolean;
	minWidth?: number;
}

const Button = (
	{
		buttonStyle = ButtonStyle.PRIMARY,
		className,
		hasIcon = false,
		hasTransition = false,
		link,
		text,
		type,
		children,
		isDisabled = false,
		isLoading = false,
		plusIcon = false,
		minWidth,
		...attributes
	}: ButtonProps,
	ref: ForwardedRef<any>
) => {
	const { formatMessage } = useIntl();
	const buttonClassName = classNames(
		styles.button,
		styles[buttonStyle],
		hasTransition && styles.hasTransition,
		isDisabled && styles.disabled,
		className
	);
	const buttonText = text && typeof text !== 'string' ? formatMessage(text) : text;
	const buttonLink = link;

	const wrappedAttributes = {
		// this is for testing purposes. since anchor does not carry a disabled indicator assign a custom attribute for asserts
		...(isDisabled ? { 'data-disabled': true } : {}),
		...attributes,
	};

	const child = (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{children || (
				<>
					{plusIcon && <span className={styles.plus}>+</span>}
					{buttonText}
					{hasIcon && (
						<FontAwesomeIcon
							className={styles.icon}
							icon={faLongArrowRight}
							size="1x"
						/>
					)}
				</>
			)}
		</>
	);

	switch (type) {
		case ButtonType.BUTTON:
		case ButtonType.SUBMIT:
			return (
				<button
					ref={ref}
					className={buttonClassName}
					type={type}
					{...wrappedAttributes}
					disabled={isDisabled || isLoading}
					style={{ minWidth: `${minWidth}px` }}
				>
					{isLoading ? <Loader className={styles.loader} /> : child}
				</button>
			);

		case ButtonType.LINK:
			return (
				<Link
					ref={ref}
					className={buttonClassName}
					to={buttonLink as string}
					{...wrappedAttributes}
					style={{ minWidth: `${minWidth}px` }}
				>
					<div>{isLoading ? <Loader className={styles.loader} /> : child}</div>
				</Link>
			);

		case ButtonType.ANCHOR_LINK:
		default:
			return (
				<a
					ref={ref}
					href={buttonLink as string}
					className={buttonClassName}
					{...wrappedAttributes}
					style={{ minWidth: `${minWidth}px` }}
				>
					<div>{isLoading ? <Loader className={styles.loader} /> : child}</div>
				</a>
			);
	}
};

export default forwardRef(Button);
