import { useIntl, MessageDescriptor, FormattedMessage } from 'react-intl';
import styles from './InfoHead.module.scss';

interface InfoHeadProps {
	className?: string;
	title?: MessageDescriptor | string;
	text?:
		| (MessageDescriptor & { values?: Record<string, (chunks: string) => JSX.Element> })
		| string;
	subTitle?: string | JSX.Element;
	img?: string | null;
}

const InfoHead = ({ title, text, img, subTitle, className, ...rest }: InfoHeadProps) => {
	const { formatMessage } = useIntl();
	return (
		<div className={className} {...rest}>
			{title && (
				<h2 className={styles.title}>
					{typeof title === 'string' ? title : formatMessage(title)}
				</h2>
			)}
			{subTitle && (
				<h6
					className={styles.subTitle}
					// eslint-disable-next-line react/no-danger
				>
					{subTitle}
				</h6>
			)}
			{img && <img className={styles.img} src={img} />}
			{text && (
				<p className={styles.paragraph}>
					{typeof text === 'string' ? text : <FormattedMessage {...(text as any)} />}
				</p>
			)}
		</div>
	);
};

export default InfoHead;
