import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FC, useState } from 'react';
import TestIds from '../../../../test/TestIds';
import Block from '../Block/Block';

import styles from './PropertyBlock.module.scss';

type Property = {
	label: string;
	content: JSX.Element;
	attr?: any;
	expandableContent?: JSX.Element;
};

const PropertyValue: FC<Property> = ({ label, content, expandableContent, attr = {} }) => {
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div
			data-cy={TestIds.PropertyBlockProperty}
			data-expandable={!!expandableContent}
			key={label}
			className={classNames(
				styles.property,
				expandableContent && styles.expandable,
				isExpanded && styles.expanded
			)}
			onClick={() => setIsExpanded((x) => !!expandableContent && !x)}
			{...attr}
		>
			<div className={styles.content}>
				<div className={styles.label}>{label}</div>
				<div className={styles.value}>{content}</div>
				{expandableContent && (
					<FontAwesomeIcon className={styles.expander} icon={faChevronDown} />
				)}
			</div>
			{isExpanded && (
				<div
					data-cy={TestIds.PropertyBlockPropertyExpanded}
					className={styles.expandedContent}
				>
					{expandableContent}
				</div>
			)}
		</div>
	);
};

type PropertyBlockProps = {
	properties: Property[];
};

const PropertyBlock: FC<PropertyBlockProps> = ({ properties }) => {
	return (
		<Block className={styles.propertyBlock} data-cy={TestIds.PropertyBlock}>
			{properties.map((property) => (
				<PropertyValue key={property.label} {...property} />
			))}
		</Block>
	);
};

export default PropertyBlock;
