import PublicSetting from '../../enums/PublicSetting';

export enum AppActionType {
	TOGGLE_WALLET_SIDEBAR = 'TOGGLE_WALLET_SIDEBAR',
	TOGGLE_CARDS_SIDEBAR = 'TOGGLE_CARDS_SIDEBAR',
	TOGGLE_CURRENT_RATES = 'TOGGLE_CURRENT_RATES',
	TOGGLE_CURRENT_RATES_GRAPH = 'TOGGLE_CURRENT_RATES_GRAPH',
	TOGGLE_STARTING_BLOCK = 'TOGGLE_STARTING_BLOCK',
	SET_REFERENCE_CURRENCY = 'SET_REFERENCE_CURRENCY',
	IS_MOBILE = 'IS_MOBILE',
	IS_TABLET = 'IS_TABLET',
	TOGGLE_MARKETING_SLIDER = 'TOGGLE_MARKETING_SLIDER',
	SET_DEVICE_OS = 'SET_DEVICE_OS',
	TOGGLE_CARDS_INFO_BANNER = 'TOGGLE_CARDS_INFO_BANNER',
	TOGGLE_CARD_CONTROL = 'TOGGLE_CARD_CONTROL',
	SET_GENERAL_ERROR = 'SET_GENERAL_ERROR',
	SET_2FA_RETRY_SETTING = 'SET_2FA_RETRY_SETTING',
	SET_RATE_AMOUNT = 'SET_RATE_AMOUNT',
}

export enum DeviceNameType {
	ANDROID = 'ANDROID',
	IPHONE = 'IPHONE',
	WINDOWS = 'WINDOWS',
	OTHER = 'OTHER',
}

export enum RateAmount {
	DAY = 'DAY',
	WEEK = 'WEEK',
	MONTH = 'MONTH',
}

export interface AppState {
	showCardControl: boolean;
	showCardsInfoBanner: boolean;
	showCardsSidebar: boolean;
	showWalletSidebar: boolean;
	showCurrentRates: boolean;
	showCurrentRatesGraph: boolean;
	showMarketingSlider: boolean;
	showStartingBlock: boolean;
	referenceCurrency: string | null;
	rateAmount: RateAmount;
	isMobile: boolean;
	isTablet: boolean;
	deviceOS: string | null;
	generalError: boolean;
	settings: {
		[PublicSetting.MAX_2FA_RETRY_COUNT]: number;
	};
}

export interface ToggleViewAction {
	type:
		| typeof AppActionType.TOGGLE_CURRENT_RATES
		| typeof AppActionType.TOGGLE_CURRENT_RATES_GRAPH
		| typeof AppActionType.TOGGLE_WALLET_SIDEBAR
		| typeof AppActionType.TOGGLE_MARKETING_SLIDER
		| typeof AppActionType.TOGGLE_CARDS_SIDEBAR
		| typeof AppActionType.TOGGLE_CARDS_INFO_BANNER
		| typeof AppActionType.TOGGLE_CARD_CONTROL
		| typeof AppActionType.TOGGLE_STARTING_BLOCK;
	isVisible: boolean;
}

export interface SetRateAmount {
	type: typeof AppActionType.SET_RATE_AMOUNT;
	rateAmount: RateAmount;
}

export interface SetRefCurrencyAction {
	type: typeof AppActionType.SET_REFERENCE_CURRENCY;
	currency: string;
}

export interface SetDeviceAction {
	type: typeof AppActionType.IS_MOBILE | typeof AppActionType.IS_TABLET;
	data?: boolean;
}

export interface SetDeviceOSAction {
	type: typeof AppActionType.SET_DEVICE_OS;
	data: string;
}

export interface SetGeneralError {
	type: typeof AppActionType.SET_GENERAL_ERROR;
	isError: boolean;
}

export interface SetTwoFaRetrySetting {
	type: typeof AppActionType.SET_2FA_RETRY_SETTING;
	data: number;
}
