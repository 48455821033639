import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { CryptoWithdrawResponse } from '../../../../redux/WithdrawState/WithdrawTypes';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import convertToLocalTime from '../../../../helpers/dateHelper/dateHelper';
import baseMsg from '../../../../messages/base.messages';
import statusMsg from '../../../../messages/status.messages';
import { isAmountGreaterThanZero } from '../../../../helpers/inputValidation/inputValidation';
import { RootState } from '../../../../redux/Store';
import { Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import useFormatAmount from '../../../../hooks/useFormatAmount';

interface CryptoSummaryProps {
	data: CryptoWithdrawResponse[];
	dateClass?: string;
	infoClass?: string;
	statusClass?: string;
	currency: CurrencyEnum;
	network?: string | null;
	feeAccountId?: string;
}

const CryptoSummary = ({
	data,
	dateClass,
	infoClass,
	currency,
	statusClass,
	network,
	feeAccountId,
}: CryptoSummaryProps) => {
	const { formatMessage } = useIntl();
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const amountFormatter = useFormatAmount();
	const feeAccount = useMemo(
		() => wallets?.find((wallet: Wallet) => wallet.id === feeAccountId),
		[feeAccountId, wallets]
	);

	return (
		<>
			{data.map(
				({
					date,
					id,
					status,
					withdrawAccount,
					withdrawAmount,
					feeAmount,
					memo,
					transNo,
					receiveAmount,
				}) => {
					return (
						<li key={id}>
							{date && (
								<div className={dateClass}>
									{convertToLocalTime(date, 'yyyy-MM-dd HH:mm:ss')}
								</div>
							)}
							{status && (
								<div className={statusClass}>
									{formatMessage(statusMsg[status])}
								</div>
							)}
							{transNo && (
								<InfoInput
									label={formatMessage(baseMsg.transactionHash)}
									isCopyVisible
									text={transNo}
									className={infoClass}
								/>
							)}
							{network && (
								<InfoInput
									label={baseMsg.network}
									text={network}
									className={infoClass}
								/>
							)}
							{withdrawAccount && (
								<InfoInput
									label={baseMsg.receiver}
									text={withdrawAccount.split(',').join(',<br/>')}
									className={infoClass}
									isHTML
								/>
							)}
							{withdrawAmount && (
								<InfoInput
									label={formatMessage(baseMsg.withdrawAmount)}
									text={`${amountFormatter(
										formatPrecision(withdrawAmount, currency)
									)} ${currency}`}
									className={infoClass}
								/>
							)}
							{feeAmount && isAmountGreaterThanZero(feeAmount) && (
								<InfoInput
									label={formatMessage(baseMsg.fee)}
									text={`${amountFormatter(
										formatPrecision(
											feeAmount,
											feeAccount?.currencyCode || currency
										)
									)} ${feeAccount?.currencyCode || currency}`}
									className={infoClass}
								/>
							)}
							{receiveAmount && (
								<InfoInput
									label={formatMessage(baseMsg.receiveAmount)}
									text={`${amountFormatter(
										formatPrecision(receiveAmount, currency)
									)} ${currency}`}
									className={infoClass}
								/>
							)}
							{memo && (
								<InfoInput
									label={
										currency === CurrencyEnum.XEM
											? formatMessage(baseMsg.message)
											: currency === CurrencyEnum.XLM
											? formatMessage(baseMsg.memo)
											: currency === CurrencyEnum.XRP
											? formatMessage(baseMsg.destinationTag)
											: formatMessage(baseMsg.memo)
									}
									text={memo}
									className={infoClass}
								/>
							)}
						</li>
					);
				}
			)}
		</>
	);
};

export default CryptoSummary;
