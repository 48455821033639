import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { ProviderType, WithdrawStatus } from '../WithdrawState/WithdrawTypes';

export enum WithdrawHistoryActionType {
	IS_LOADING = 'WITHDRAW_HISTORY_IS_LOADING',
	SET_DATA = 'WITHDRAW_SET_DATA',
	RESET_DATA = 'WITHDRAW_RESET_DATA',
}

export interface WithdrawHistoryItem {
	accountNumber: string;
	date: string;
	feeAmount: string;
	id: string;
	memo: string | null;
	receiveAmount: string;
	status: WithdrawStatus;
	transNo: string | null;
	withdrawAccount: string;
	withdrawAmount: string;
	currencyCode: CurrencyEnum;
	feeCurrencyCode: CurrencyEnum;
	receiveCurrencyCode: CurrencyEnum;
	withdrawCurrencyCode: CurrencyEnum;
	code: string;
	itemTitle: string;
	payAmount: string;
	payCurrencyCode: CurrencyEnum;
	productTitle: string;
	receiver: string;
	sellAmount: string;
	sellCurrencyCode: CurrencyEnum;
	type: ProviderType;
	other: string;
	receiverAddress: string;
	sortCode: string;
	swift: string;
	network?: string;
}

export interface WithdrawCryptoResponse {
	content: WithdrawHistoryItem[];
	size: number;
	totalElements: number;
	totalPages: number;
}

export interface WithdrawHistoryState {
	data: WithdrawHistoryItem[];
	totalCount: number;
	isLoading: boolean;
}

export interface SetHistoryIsLoadingAction {
	type: typeof WithdrawHistoryActionType.IS_LOADING;
	data: boolean;
}

export interface SetHistoryDataAction {
	type: typeof WithdrawHistoryActionType.SET_DATA;
	data: WithdrawHistoryItem[];
	totalCount: number;
}

export interface SetInitialStateAction {
	type: typeof WithdrawHistoryActionType.RESET_DATA;
}
