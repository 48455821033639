import {
	DepositActionTypes,
	DepositState,
	RequireAccountSelect,
	ResetVoucher,
	SetAccountId,
	SetCountries,
	SetIsRemoveEnabled,
	SetLinkedCards,
	SetLinkedCardsStatus,
	SetPaymentMethodsByGroup,
	SetUserProfile,
	SetVoucher,
	SetVoucherCode,
	SetVoucherError,
	VoucherDepositView,
} from './DepositTypes';

const initialState: DepositState = {
	countries: null,
	linkedCards: {
		isRemoveEnabled: false,
		isLoaded: false,
		status: {
			isLoaded: false,
			data: null,
		},
		data: [],
		totalCount: 0,
	},
	paymentMethods: {
		ewallet: {
			isLoaded: false,
			data: [],
			totalCount: 0,
		},
		bank: {
			isLoaded: false,
			data: [],
			totalCount: 0,
		},
		cash: {
			isLoaded: false,
			data: [],
			totalCount: 0,
		},
		card: {
			isLoaded: false,
			data: [],
			totalCount: 0,
		},
	},
	voucher: {
		accountId: null,
		accountListForSelection: [],
		isRequestPending: false,
		view: VoucherDepositView.ENTER_CODE,
		voucherCode: null,
		error: null,
		amount: null,
		isAccountSelectVisible: false,
	},
};

export default (
	state = initialState,
	action:
		| SetLinkedCards
		| SetLinkedCardsStatus
		| SetUserProfile
		| SetCountries
		| SetPaymentMethodsByGroup
		| SetIsRemoveEnabled
		| SetVoucher
		| ResetVoucher
		| RequireAccountSelect
		| SetAccountId
		| SetVoucherCode
		| SetVoucherError
) => {
	switch (action.type) {
		case DepositActionTypes.SET_LINKED_CARDS:
			return {
				...state,
				linkedCards: {
					...state.linkedCards,
					data: action.data,
					isLoaded: true,
				},
			};
		case DepositActionTypes.SET_LINKED_CARD_STATUS:
			return {
				...state,
				linkedCards: {
					...state.linkedCards,
					status: {
						...state.linkedCards.status,
						isLoaded: true,
						data: action.data,
					},
				},
			};
		case DepositActionTypes.SET_COUNTRIES:
			return {
				...state,
				countries: action.data,
			};
		case DepositActionTypes.SET_PAYMENT_METHODS_BY_GROUP:
			return {
				...state,
				paymentMethods: {
					...state.paymentMethods,
					[action.group.toLowerCase()]: {
						...state.paymentMethods[action.group.toLowerCase()],
						isLoaded: true,
						data:
							action?.data?.paymentMethods ||
							state?.paymentMethods[action.group.toLowerCase()]?.data,
						totalCount:
							action?.data?.count ||
							state?.paymentMethods[action.group.toLowerCase()]?.totalCount,
					},
				},
			};
		case DepositActionTypes.SET_IS_REMOVE_ENABLED:
			return {
				...state,
				linkedCards: { ...state.linkedCards, isRemoveEnabled: action.isRemoveEnabled },
			};
		case DepositActionTypes.SET_VOUCHER:
			return {
				...state,
				voucher: {
					...state.voucher,
					accountId: action.accountId,
					amount: action.amount,
					view: VoucherDepositView.SUCCESS,
				},
			};
		case DepositActionTypes.REQUIRE_ACCOUNT_SELECT:
			return {
				...state,
				voucher: {
					...state.voucher,
					isAccountSelectVisible: true,
					accountListForSelection: action.accountListForSelection,
					view: VoucherDepositView.SELECT_ACCOUNT,
				},
			};
		case DepositActionTypes.SET_ACCOUNT_ID:
			return {
				...state,
				voucher: { ...state.voucher, accountId: action.accountId },
			};
		case DepositActionTypes.SET_VOUCHER_CODE:
			return {
				...state,
				voucher: { ...state.voucher, voucherCode: action.voucherCode },
			};
		case DepositActionTypes.VOUCHER_ERROR:
			return {
				...state,
				voucher: {
					...state.voucher,
					isRequestPending: false,
					successAccountId: null,
					successAmount: null,
					view: VoucherDepositView.ERROR,
				},
			};
		case DepositActionTypes.RESET_VOUCHER:
			return {
				...state,
				voucher: {
					...initialState.voucher,
				},
			};
		default:
			return state;
	}
};
