import { useQuery } from '@tanstack/react-query';
import PageableResponse from '../../../../interfaces/PageableResponse';
import { getMerchantsTransactionUrl, getMerchantsTransactionsUrl } from '../../../endpoints';
import { throwLoaderError } from '../../../../helpers/loaderUtils';
import { RemoteError } from '../../../../interfaces/RemoteData';
import { OrderTransaction } from '../OrderTransactionsData/OrderTransactionsDataTypes';
import axiosInstance from '../../../../helpers/axiosInstance';

export const getOrderTransactionQuery = {
	getKey: (id: string) => ['merchants.transaction', id],
	getFn: (id: string) => () =>
		axiosInstance
			.get<OrderTransaction>(getMerchantsTransactionUrl(id))
			.then(({ data }) => data)
			.catch(throwLoaderError),
};

export const useOrderTransactionQuery = (transactionId: string) =>
	useQuery({
		queryKey: getOrderTransactionQuery.getKey(transactionId),
		queryFn: getOrderTransactionQuery.getFn(transactionId),
	});

export const getOrderTransactionsListQuery = {
	getKey: (orderId: string) => ['merchants.transactions.list', orderId],
	getFn: (orderId: string) => () =>
		axiosInstance
			.get<PageableResponse<Array<OrderTransaction>>>(getMerchantsTransactionsUrl(), {
				params: {
					orderId,
					pageSize: 2000,
					sort: 'created,desc',
				},
			})
			.then(({ data }) => data)
			.catch(throwLoaderError),
};

export const useOrderTransactions = (orderId: string) =>
	useQuery<PageableResponse<Array<OrderTransaction>>, RemoteError>({
		queryKey: getOrderTransactionsListQuery.getKey(orderId),
		queryFn: getOrderTransactionsListQuery.getFn(orderId),
	});
