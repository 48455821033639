import { FC, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectsCreateViewState } from '../../../../../redux/MerchantsState/MerchantTypes';
import { getErrorMessageOrDefault } from '../../../../../helpers/errorMessageHelper/errorMessageHelper';
import TestIds from '../../../../../test/TestIds';
import MerchantsSelectors from '../../../../../redux/MerchantsState/MerchantsSelectors';
import MerchantsActions from '../../../../../redux/MerchantsState/MerchantsActions';
import { useFormatRoute } from '../../../../../helpers/languagePathHelper/languagePathHelper';
import Button, { ButtonType } from '../../../../../components/Button/Button';
import SvgIcon from '../../../../../components/SvgIcon/SvgIcon';
import messages from '../../../../../redux/MerchantsState/MerchantsMessages';
import routes from '../../../../../route.messages';
import errorIcon from '../../../../../images/iban_icons/error.svg';

import styles from './NewMerchantsProjectFailure.module.scss';
import NewMerchantsProjectContext from '../Context/NewMerchantsProjectContext';
import { RemoteStatus } from '../../../../../interfaces/RemoteData';

const NewMerchantsProjectFailure: FC = () => {
	const { formatMessage } = useIntl();
	const contactLink = useFormatRoute(routes.contact);
	const { action, setState } = useContext(NewMerchantsProjectContext);

	const onRetry = useCallback(() => {
		setState(ProjectsCreateViewState.Creating);
	}, []);

	return (
		<div data-cy={TestIds.ProjectCreateFailure} className={styles.container}>
			<SvgIcon className={styles.icon} src={errorIcon} />
			<p className={styles.text}>{formatMessage(getErrorMessageOrDefault())}</p>
			<Button
				isDisabled={action.status === RemoteStatus.InProgress}
				className={styles.button}
				text={messages.tryAgain}
				type={ButtonType.BUTTON}
				onClick={onRetry}
			/>
			<Link to={contactLink} className={styles.support}>
				{formatMessage(messages.contactSupport)}
			</Link>
		</div>
	);
};

export default NewMerchantsProjectFailure;
