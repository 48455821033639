import { useState } from 'react';
import classNames from 'classnames';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import generateUUID from '../../helpers/generateUUID/generateUUID';
import styles from './PhoneNumberInput.module.scss';

const messages = defineMessages({
	placeholder: {
		id: 'phoneNumberInput.placeholder',
		defaultMessage: 'Add phone number',
	},
});

interface PhoneNumberInputProps {
	country?: any;
	id?: string;
	label?: MessageDescriptor;
	value: string;
	setValue: (val: string) => void;
	errorMessage?: MessageDescriptor | string | null;
	countries?: any;
	readOnly?: boolean;
}

const PhoneNumberInput = ({
	country,
	id,
	label,
	value,
	setValue,
	errorMessage,
	countries,
	readOnly = false,
}: PhoneNumberInputProps) => {
	const { formatMessage } = useIntl();
	const [inputId] = useState(id || generateUUID());

	return (
		<div className={styles.inputGroup}>
			{label && (
				<label className={styles.label} htmlFor={inputId}>
					{formatMessage(label)}
				</label>
			)}
			<PhoneInput
				className={classNames(styles.input, { [styles.error]: errorMessage })}
				id={inputId}
				defaultCountry={country}
				addInternationalOption={false}
				placeholder={formatMessage(messages.placeholder)}
				value={value}
				readOnly={readOnly}
				onChange={setValue}
				countrySelectComponent={undefined}
				countries={countries}
			/>
			{errorMessage && (
				<div className={styles.errorMessage}>
					{typeof errorMessage === 'string' ? errorMessage : formatMessage(errorMessage)}
				</div>
			)}
		</div>
	);
};

export default PhoneNumberInput;
