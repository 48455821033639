import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import queryString, { ParsedQuery } from 'query-string';
import { HistoryTableData } from '../../../../hooks/useHistoryTableData';
import useDidMount from '../../../../hooks/useDidMount';
import useQueryParams from '../../../../hooks/useQueryParams';
import { RootState } from '../../../../redux/Store';
import {
	getDocumentsHistory,
	resetDocumentsHistory,
} from '../../../../redux/DocumentsHistoryState/DocumentsHistoryActions';

const useDocumentsHistoryDataProvider = (): HistoryTableData<any> => {
	const { search } = useLocation();

	const didMount = useDidMount();
	const dispatch = useDispatch();
	const { type }: ParsedQuery<any> = queryString.parse(search);
	const [[page]] = useQueryParams({
		page: undefined,
	});

	const { totalCount, isLoading, data } = useSelector(
		(state: RootState) => state.DocumentsHistoryState
	);

	useEffect(() => {
		if (didMount) {
			void dispatch(getDocumentsHistory(typeof page === 'string' ? +page : page));
		}
		return () => {
			dispatch(resetDocumentsHistory());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, didMount, type]);

	return {
		data,
		isLoading,
		totalCount,
	};
};

export default useDocumentsHistoryDataProvider;
