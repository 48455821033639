import {
	Dispatch,
	FC,
	SetStateAction,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import Joi from 'joi';
import { AxiosError } from 'axios';
import styles from './Invite.module.scss';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import inviteSvg from './invite.svg';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import ReferralsMessages from '../../../../redux/ReferralsState/ReferralsMessages';
import Input from '../../../../components/Input/Input';
import Textarea from '../../../../components/Textarea/Textarea';
import useValidation from '../../../../hooks/useValidation';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import { getReferralInviteUrl } from '../../../../redux/endpoints';
import { RemoteData, RemoteError, RemoteStatus } from '../../../../interfaces/RemoteData';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	notValidEmail: {
		id: 'multiEmailInput.notValidEmail',
		defaultMessage: '{Email} is not a valid email address.',
	},
});

type View = 'list' | 'bulk';

interface InviteContextProps {
	view: View;
	setView: Dispatch<SetStateAction<View>>;
	action: RemoteData<void>;
	onSendInvite: (emails: string[]) => void;
}

export const InviteContext = createContext<InviteContextProps>({
	view: 'list',
	setView: () => {},
	action: { status: RemoteStatus.None },
	onSendInvite: () => {},
});

enum ValidationScope {
	None,
	OnDemand,
}

const useEmailValidator = (message: string) => {
	return useMemo(
		() =>
			Joi.object<{ email: string }>()
				.when('$scope', {
					is: ValidationScope.None,
					then: {
						email: Joi.string().allow('', null),
					},
				})
				.when('$scope', {
					is: ValidationScope.OnDemand,
					then: {
						email: Joi.string()
							.email({ tlds: { allow: false } })
							.message(message),
					},
				}),
		[message]
	);
};

const InviteList: FC = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const [email, setEmail] = useState<string>('');
	const { setView, onSendInvite, action } = useContext(InviteContext);
	const [validationScope, setValidationScope] = useState<ValidationScope>(ValidationScope.None);
	const emailValidator = useEmailValidator(
		formatMessage(messages.notValidEmail, { Email: email })
	);

	const [isValid, validationResult] = useValidation({ email }, emailValidator, {
		scope: validationScope,
	});

	const onClose = useCallback(() => {
		dispatch(toggleModal());
	}, []);

	const onSend = useCallback(() => {
		setValidationScope(ValidationScope.OnDemand);
		if (
			emailValidator.validate({ email }, { context: { scope: ValidationScope.OnDemand } })
				.error
		)
			return;

		onSendInvite([email]);
	}, [onSendInvite, emailValidator, email]);

	const onChange = useCallback((value: string) => {
		setValidationScope(ValidationScope.None);
		setEmail(value);
	}, []);

	return (
		<div className={styles.container}>
			<div>
				<SvgIcon src={inviteSvg} className={styles.icon} />
				<FontAwesomeIcon
					className={styles.closeIcon}
					icon={faTimes}
					onClick={() => onClose()}
				/>
			</div>
			<div className={styles.content}>
				<div className={styles.title}>{formatMessage(ReferralsMessages.inviteFriends)}</div>
				<div>{formatMessage(ReferralsMessages.inviteFriendsInfo)}</div>
				<Input
					className={styles.value}
					value={email}
					onChange={onChange}
					errorMessage={validationResult.email}
				/>
			</div>
			<div className={styles.actions}>
				{action.status === RemoteStatus.Error && (
					<NotificationMessage
						withIcon
						type={NotificationType.Error}
						style={NotificationStyle.Border}
						message={formatMessage(getErrorMessageOrDefault(action.error))}
					/>
				)}
				<Button
					className={styles.button}
					buttonStyle={ButtonStyle.PRIMARY}
					type={ButtonType.SUBMIT}
					onClick={onSend}
					isDisabled={!email || !isValid || action.status === RemoteStatus.InProgress}
				>
					{formatMessage(ReferralsMessages.sendNow)}
				</Button>
				<Button
					className={styles.button}
					buttonStyle={ButtonStyle.PRIMARY}
					type={ButtonType.SUBMIT}
					onClick={() => setView('bulk')}
				>
					{formatMessage(ReferralsMessages.bulkInvites)}
				</Button>
			</div>
		</div>
	);
};

const InviteBulk: FC = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const [value, setValue] = useState<string>('');
	const { setView, onSendInvite, action } = useContext(InviteContext);
	const emailValidator = useEmailValidator('');
	const multiEmailValidator = useMemo(() => Joi.array().items(emailValidator), [emailValidator]);
	const emails = useMemo(() => value.split(/[\n\r\s\t;]+/).filter((x) => !!x), [value]);
	const validEmails = useMemo(() => {
		return emails.filter((email) => {
			return !emailValidator.validate(
				{ email },
				{ context: { scope: ValidationScope.OnDemand } }
			).error;
		});
	}, [emails]);

	const [isValid] = useValidation(
		emails.map((email) => ({ email })),
		multiEmailValidator,
		{
			scope: ValidationScope.OnDemand,
		}
	);

	const onClose = useCallback(() => {
		dispatch(toggleModal());
	}, []);

	const onSend = useCallback(() => {
		onSendInvite(validEmails);
	}, [onSendInvite, validEmails]);

	return (
		<div className={styles.container}>
			<div>
				<FontAwesomeIcon
					className={styles.backIcon}
					icon={faArrowLeft}
					onClick={() => setView('list')}
				/>
				<SvgIcon src={inviteSvg} className={styles.icon} />
				<FontAwesomeIcon
					className={styles.closeIcon}
					icon={faTimes}
					onClick={() => onClose()}
				/>
			</div>
			<div className={styles.content}>
				<div className={styles.title}>{formatMessage(ReferralsMessages.inviteFriends)}</div>
				<div>{formatMessage(ReferralsMessages.inviteFriendsInfo)}</div>
				<Textarea
					value={value}
					onChange={setValue}
					errorMessage={
						!isValid ? formatMessage(ReferralsMessages.invalidEmails) : undefined
					}
				/>
			</div>
			{action.status === RemoteStatus.Error && (
				<NotificationMessage
					withIcon
					type={NotificationType.Error}
					style={NotificationStyle.Border}
					message={formatMessage(getErrorMessageOrDefault(action.error))}
				/>
			)}
			<Button
				className={styles.button}
				buttonStyle={ButtonStyle.PRIMARY}
				type={ButtonType.SUBMIT}
				onClick={onSend}
				isDisabled={
					validEmails.length === 0 ||
					!isValid ||
					action.status === RemoteStatus.InProgress
				}
			>
				{`${formatMessage(ReferralsMessages.sendNow)}${
					validEmails.length > 0 ? ` (${validEmails.length})` : ''
				}`}
			</Button>
		</div>
	);
};

const Invite: FC = () => {
	const dispatch = useDispatch();
	const [view, setView] = useState<'list' | 'bulk'>('list');
	const [action, setAction] = useState<RemoteData<void>>({ status: RemoteStatus.None });

	const onSendInvite = useCallback((emails: string[]) => {
		setAction({ status: RemoteStatus.InProgress });
		axiosInstance
			.post(getReferralInviteUrl(), { emails })
			.then(() => {
				setAction({ status: RemoteStatus.Done });
				dispatch(toggleModal());
			})
			.catch((e: AxiosError<RemoteError>) => {
				setAction({ status: RemoteStatus.Error, error: e.response?.data });
			});
	}, []);

	return (
		<InviteContext.Provider value={{ view, setView, action, onSendInvite }}>
			{(() => {
				switch (view) {
					case 'list':
						return <InviteList />;
					case 'bulk':
						return <InviteBulk />;
					default:
						return null;
				}
			})()}
		</InviteContext.Provider>
	);
};

export default Invite;
