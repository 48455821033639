import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import { useEffect } from 'react';
import StorageKey from '../../enums/StorageKey';
import Message, { MessageType } from '../../components/Message/Message';
import userManagerInstance from '../../helpers/userManager/userManager';
import messages from './Login.messages';
import { isRedirectSafe } from '../../helpers/xssHelper/xssHelper';
import useEffectOnce from '../../hooks/useEffectOnce';
import store from '../../redux/Store';
import { DeviceNameType } from '../../redux/AppState/AppTypes';
import config from '../../configs/config';

const Login = () => {
	const { deviceOS, isMobile } = store.getState().AppState;

	const { search, state, pathname } = useLocation();
	const error = state?.error;

	const getRouteFromPath = () => {
		const isSignupRoute = pathname.includes('signup');
		const { flow } = queryString.parse(search);
		return flow ? `${flow}` : isSignupRoute ? 'signup' : '';
	};

	useEffect(() => {
		const { appSuggest } = queryString.parse(search);
		if (appSuggest && deviceOS && config.PLAY_STORE_URI && config.APP_STORE_URI) {
			if (isMobile && deviceOS === DeviceNameType.ANDROID) {
				window.location.href = config.PLAY_STORE_URI;
			}
			if (isMobile && deviceOS === DeviceNameType.IPHONE) {
				window.location.href = config.APP_STORE_URI;
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deviceOS]);

	useEffectOnce(() => {
		if (error) return;
		const { redirect, appSuggest } = queryString.parse(search);
		if (redirect && isRedirectSafe(redirect as string)) {
			window.localStorage.setItem(StorageKey.REDIRECT, redirect as string);
		} else {
			window.localStorage.removeItem(StorageKey.REDIRECT);
		}
		if (!appSuggest || !config.PLAY_STORE_URI || !config.APP_STORE_URI) {
			// if url has appSuggest param, don't redirect to login page
			// as user has requested to open the app

			void userManagerInstance.clearStaleState();
			// Go get code from auth server login page
			void userManagerInstance.signinRedirect({
				redirectMethod: 'replace',
				extraQueryParams: { flow: getRouteFromPath() },
			});
		}
	});

	if (error) {
		return (
			<Message type={MessageType.ERROR}>
				<FormattedMessage {...messages.unexpectedError} />
			</Message>
		);
	}

	return null;
};

export default Login;
