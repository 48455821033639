import { CurrencyEnum, CurrencyIcon } from '@spectrocoin/sc-currencies';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import styles from './WalletOption.module.scss';
import useFormatAmount from '../../../../hooks/useFormatAmount';

interface WalletOptionProps {
	currencyCode: CurrencyEnum;
	currencyName: string;
	availableBalance: string;
}

const WalletOption = ({
	currencyCode,
	currencyName,
	availableBalance,
}: WalletOptionProps): JSX.Element => {
	const amountFormatter = useFormatAmount();

	return (
		<div className={styles.option}>
			<CurrencyIcon
				currencyType={CurrencyEnum[currencyCode]}
				className={styles.currencyIcon}
			/>
			<label className={styles.currencyLabel}>{`${currencyName} (${amountFormatter(
				formatPrecision(availableBalance, currencyCode as CurrencyEnum)
			)} ${currencyCode})`}</label>
		</div>
	);
};

export default WalletOption;
