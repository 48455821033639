import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useMerchantRoutes, { MerchantRoutes } from '../../../../hooks/useMerchantRoutes';
import { Project } from '../../../../redux/MerchantsState/MerchantTypes';
import AutoconvertField from '../AutoconvertField/AutoconvertField';
import ProjectMode from '../Mode/Mode';
import NameField from '../NameField/NameField';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';
import ProjectAction from '../ProjectAction/ProjectAction';
import LabeledField, { LabeledFieldStyle } from '../LabeledField/LabeledField';

import styles from './MobileProject.module.scss';
import AcceptedCurrenciesField from '../AcceptedCurrenciesField/AcceptedCurrenciesField';

type ProjectProps = {
	className?: string;
	project: Project;
};

const MobileProject: FC<ProjectProps> = ({ project, className }) => {
	const { getUrl } = useMerchantRoutes();
	const { formatMessage } = useIntl();

	return (
		<Link
			className={classNames(styles.project, className)}
			to={getUrl(MerchantRoutes.ProjectView, { projectId: project.id })}
		>
			<div className={styles.data}>
				<LabeledField
					className={styles.labeled}
					style={LabeledFieldStyle.Row}
					label={formatMessage(messages.name)}
				>
					<NameField name={project.name} />
				</LabeledField>
				<LabeledField
					className={styles.labeled}
					style={LabeledFieldStyle.Row}
					label={formatMessage(messages.autoconvert)}
				>
					<AutoconvertField accountId={project.receiveAccountId} />
				</LabeledField>
				<LabeledField
					className={styles.labeled}
					style={LabeledFieldStyle.Row}
					label={formatMessage(messages.acceptedCurrencies)}
				>
					<AcceptedCurrenciesField currencies={project.payCurrencies} />
				</LabeledField>
				<LabeledField
					className={styles.labeled}
					style={LabeledFieldStyle.Row}
					label={formatMessage(messages.mode)}
				>
					<ProjectMode status={project} />
				</LabeledField>
			</div>
			<div className={styles.action}>
				<ProjectAction project={project} />
			</div>
		</Link>
	);
};

export default MobileProject;
