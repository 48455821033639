import { useCallback } from 'react';
import useQueryParam from './useQueryParams';

const useQueryPagination = () => {
	const [[page = '1'], update] = useQueryParam({ page: '1' });

	const updatePage = useCallback(
		(newValue: number | null) => update({ page: newValue !== null ? `${newValue}` : null }),
		[update]
	);
	return [+page, updatePage] as const;
};

export default useQueryPagination;
