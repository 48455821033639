import { FC } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { CurrencyIcon } from '@spectrocoin/sc-currencies';
import TextNode, { TextStyles } from '../../../../components/TextNode/TextNode';
import Loading from '../../Shared/Loading/Loading';
import { useTransactionStatusTranslator } from '../../../../helpers/merchantsHelper/merchantsHelper';
import LabeledField, { LabeledFieldStyle } from '../../Shared/LabeledField/LabeledField';
import { RootState } from '../../../../redux/Store';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import MerchantsMessages from '../../../../redux/MerchantsState/MerchantsMessages';
import useMerchantRoutes, { MerchantRoutes } from '../../../../hooks/useMerchantRoutes';
import TestIds, { formatTestId } from '../../../../test/TestIds';
import convertToLocalTime from '../../../../helpers/dateHelper/dateHelper';

import styles from './MerchantsTransactionsList.module.scss';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import useFormatAmount from '../../../../hooks/useFormatAmount';
import { useOrderTransactions } from '../../../../redux/MerchantsState/OrderTransactions/Queries/OrderTransactionQueries';
import { OrderTransaction } from '../../../../redux/MerchantsState/OrderTransactions/OrderTransactionsData/OrderTransactionsDataTypes';

const Empty: FC = () => {
	return (
		<span className={styles.empty} data-cy={TestIds.TransactionListEmpty}>
			<FormattedMessage {...MerchantsMessages.noOrderDeposits} />
		</span>
	);
};

const Header: FC = () => {
	return (
		<li className={classNames(styles.header, styles.row)}>
			<div>
				<FormattedMessage {...MerchantsMessages.id} />
			</div>
			<div>
				<FormattedMessage {...MerchantsMessages.status} />
			</div>
			<div>
				<FormattedMessage {...MerchantsMessages.deposited} />
			</div>
			<div>
				<FormattedMessage {...MerchantsMessages.date} />
			</div>
		</li>
	);
};

const ListItem: FC<{ transaction: OrderTransaction }> = ({
	transaction: { id, status, depositValue, currencyCode, created },
}) => {
	const { getUrl } = useMerchantRoutes();
	const statusTranslator = useTransactionStatusTranslator();

	return (
		<li
			className={classNames(styles.listItem, styles.row)}
			data-cy={formatTestId(TestIds.TransactionListItem_0, id)}
		>
			<div className={styles.link}>
				<Link
					data-cy={TestIds.TransactionListItemId}
					to={getUrl(MerchantRoutes.TransactionView, {
						transactionId: id,
					})}
				>
					{id}
				</Link>
			</div>
			<div data-cy={TestIds.TransactionListItemStatus}>
				<TextNode style={TextStyles.BreakWord}>{statusTranslator(status)}</TextNode>
			</div>
			<div data-cy={TestIds.TransactionListItemDeposited} className={styles.currency}>
				<CurrencyIcon
					currencyType={currencyCode}
					className={classNames(styles.currencyIcon)}
				/>
				{`${formatPrecision(depositValue, currencyCode)} ${currencyCode}`}
			</div>
			<div data-cy={TestIds.TransactionListItemDate}>
				{convertToLocalTime(created, 'yyyy-MM-dd hh:mm:ss')}
			</div>
		</li>
	);
};

const MobileListItem: FC<{ transaction: OrderTransaction }> = ({
	transaction: { id, status, depositValue, currencyCode, created },
}) => {
	const { formatMessage } = useIntl();
	const { getUrl } = useMerchantRoutes();
	const statusTranslator = useTransactionStatusTranslator();
	const amountFormatter = useFormatAmount();

	return (
		<li
			className={classNames(styles.listItem, styles.row, styles.mobileListItem)}
			data-cy={formatTestId(TestIds.TransactionListItem_0, id)}
		>
			<LabeledField
				className={styles.mobileProperty}
				labelClassName={styles.mobileLabel}
				contentClassName={styles.mobileContent}
				style={LabeledFieldStyle.Row}
				label={formatMessage(MerchantsMessages.id)}
			>
				<div className={styles.link}>
					<Link
						data-cy={TestIds.TransactionListItemId}
						to={getUrl(MerchantRoutes.TransactionView, {
							transactionId: id,
						})}
					>
						{id}
					</Link>
				</div>
			</LabeledField>
			<LabeledField
				data-cy={TestIds.TransactionListItemStatus}
				className={styles.mobileProperty}
				labelClassName={styles.mobileLabel}
				contentClassName={styles.mobileContent}
				style={LabeledFieldStyle.Row}
				label={formatMessage(MerchantsMessages.status)}
			>
				<TextNode style={TextStyles.BreakWord}>{statusTranslator(status)}</TextNode>
			</LabeledField>
			<LabeledField
				data-cy={TestIds.TransactionListItemDeposited}
				className={styles.mobileProperty}
				labelClassName={styles.mobileLabel}
				contentClassName={styles.mobileContent}
				style={LabeledFieldStyle.Row}
				label={formatMessage(MerchantsMessages.deposited)}
			>
				<div className={styles.currency}>
					<CurrencyIcon
						currencyType={currencyCode}
						className={classNames(styles.currencyIcon)}
					/>
					{`${amountFormatter(
						formatPrecision(depositValue, currencyCode)
					)} ${currencyCode}`}
				</div>
			</LabeledField>
			<LabeledField
				data-cy={TestIds.TransactionListItemDate}
				className={styles.mobileProperty}
				labelClassName={styles.mobileLabel}
				contentClassName={styles.mobileContent}
				style={LabeledFieldStyle.Row}
				label={formatMessage(MerchantsMessages.date)}
			>
				{convertToLocalTime(created, 'yyyy-MM-dd hh:mm:ss')}
			</LabeledField>
		</li>
	);
};

const MobileContent: FC = () => {
	const { getParams } = useMerchantRoutes();

	const { orderId = '' } = getParams();
	const { data } = useOrderTransactions(orderId);

	if (!data) return null;

	const transactions = data!.content || [];

	return (
		<ul className={styles.table}>
			{transactions.map((transaction) => (
				<MobileListItem key={transaction.id} transaction={transaction} />
			))}
		</ul>
	);
};

const Content: FC = () => {
	const { getParams } = useMerchantRoutes();

	const { orderId = '' } = getParams();
	const { data } = useOrderTransactions(orderId);

	if (!data) return null;

	const transactions = data!.content || [];

	return (
		<ul className={styles.table}>
			<Header />
			{transactions.map((transaction) => (
				<ListItem key={transaction.id} transaction={transaction} />
			))}
		</ul>
	);
};

const MerchantsTransactionsList: FC = () => {
	const { isMobile } = useSelector((state: RootState) => state.AppState);
	const { formatMessage } = useIntl();
	const { getParams } = useMerchantRoutes();

	const { orderId = '' } = getParams();

	const { data, isLoading, error } = useOrderTransactions(orderId);

	return (
		<div className={styles.container} data-cy={TestIds.TransactionList}>
			<div className={styles.title}>
				<FormattedMessage {...MerchantsMessages.orderDeposits} />
			</div>
			<div className={styles.body}>
				{isLoading && <Loading />}
				{error && (
					<NotificationMessage
						withIcon
						data-cy={TestIds.TransactionListError}
						message={formatMessage(getErrorMessageOrDefault(error))}
						type={NotificationType.Error}
						style={NotificationStyle.Border}
					/>
				)}
				{data && !data.content.length && <Empty />}
				{data && !!data.content.length && (isMobile ? <MobileContent /> : <Content />)}
			</div>
		</div>
	);
};

export default MerchantsTransactionsList;
