import {
	ChangeEvent,
	FormEvent,
	Fragment,
	ReactNode,
	useCallback,
	useEffect,
	useState,
} from 'react';
import queryString, { ParsedQuery } from 'query-string';
import classNames from 'classnames';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';
import { CurrencyEnum, CurrencyIcon } from '@spectrocoin/sc-currencies';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import depositMessages from '../../../../redux/DepositState/DepositMessages';
import currencyMessages from '../../../../messages/currency.messages';
import errorMessages, {
	getErrorMessageOrDefault,
} from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import {
	isAmountGreaterThan,
	isAmountGreaterThanZero,
} from '../../../../helpers/inputValidation/inputValidation';
import {
	CryptoWithdrawResponse,
	WithdrawType,
} from '../../../../redux/WithdrawState/WithdrawTypes';
import {
	formatPrecision,
	isErcCurrency,
	isFeeAccountNeeded,
	swapSeparators,
	toDecimal,
} from '../../../../helpers/currencyHelper/currencyHelper';
import { toPlainAmount } from '../../../../helpers/currencyAmountHelper/currencyAmountHelper';
import {
	getEstimateFeeURL,
	getValidateWithdrawAddressURL,
	createWithdrawCryptosURL,
} from '../../../../redux/endpoints';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';
import { CurrencyNetwork, Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import { RootState } from '../../../../redux/Store';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import Input from '../../../../components/Input/Input';
import Select from '../../../../components/Select/Select';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import CustomInput from '../../Shared/CustomInput/CustomInput';
import styles from './CryptoWithdrawForm.module.scss';
import WithdrawConfirm from '../../Shared/WithdrawConfirm/WithdrawConfirm';
import infoImgPath from './images/info.svg';
import WithdrawSuccess from '../../Shared/WithdrawSuccess/WithdrawSuccess';
import WithdrawFormContainer, {
	WithdrawSteps,
} from '../../Shared/WithdrawFormContainer/WithdrawFormContainer';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import WalletOption from '../../Shared/WalletOption/WalletOption';
import baseMsg from '../../../../messages/base.messages';
import inputErrors from '../../../../messages/inputErrors.messages';
import useDebounce from '../../../../hooks/useDebounce';
import Seo from '../../../../components/Seo/Seo';
import { DeviceNameType } from '../../../../redux/AppState/AppTypes';
import useCurrentWallet from '../../../../hooks/useCurrentWallet';
import { getAccountNetwork } from '../../../../redux/AccountsState/AccountsActions';
import SelectOption from '../../../../interfaces/SelectOption';
import TransfersFormLayout from '../../../../layout/TransfersFormLayout/TransfersFormLayout';
import { HowToType } from '../../../../layout/TransfersFormLayout/HowTo/HowTo';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	withdrawalWallet: {
		id: 'cryptoWithdrawForm.withdrawalWallet',
		defaultMessage: 'Withdrawal wallet',
	},
	receiverPlaceholder: {
		id: 'cryptoWithdrawForm.receiverPlaceholder',
		defaultMessage: 'spectro@user.com or {currency} address ',
	},
	bulkSend: {
		id: 'cryptoWithdrawForm.bulkSend',
		defaultMessage: 'Bulk {currency} send',
	},
	addReceiver: {
		id: 'cryptoWithdrawForm.addReceiver',
		defaultMessage: '+ Add receiver',
	},
	invalidAddress: {
		id: 'cryptoWithdrawForm.invalidAddress',
		defaultMessage: 'Invalid address',
	},
	messageLabel: {
		id: 'cryptoWithdrawForm.messageLabel',
		defaultMessage: 'Please enter short XEM transaction message',
	},
	memoXLMLabel: {
		id: 'cryptoWithdrawForm.memoXLMLabel',
		defaultMessage: 'Please enter short XLM transaction memo',
	},
	destinationTagLabel: {
		id: 'cryptoWithdrawForm.destinationTagLabel',
		defaultMessage: 'Please enter XRP destination tag',
	},
	amountIsTooLow: {
		id: 'cryptoWithdrawForm.amountIsTooLow',
		defaultMessage: 'Amount cannot be equal to 0',
	},
	amountIsTooBig: {
		id: 'cryptoWithdrawForm.amountIsTooBig',
		defaultMessage: 'Amount is bigger then it could be',
	},
	accountForFeeLabel: {
		id: 'cryptoWithdrawForm.accountForFeeLabel',
		defaultMessage: 'Gas wallet',
	},
	ercInfo: {
		id: 'cryptoWithdrawForm.ercInfo',
		defaultMessage: 'This wallet only supports transactions on the Ethereum network (ERC20).',
	},
	removeReceiver: {
		id: 'cryptoWithdrawForm.removeReceiver',
		defaultMessage: 'Remove receiver',
	},
});

const INVALID_ADDRESS = 'WS_19';

const currenciesWithMessage = [CurrencyEnum.XEM, CurrencyEnum.XLM, CurrencyEnum.XRP];

export interface FormData {
	address: string;
	amount: string;
	message?: string;
}

export interface FeeResponse {
	feeAmount: string;
	gasLimit: number;
	gasPrice: string;
}

interface FormErrors {
	address: MessageDescriptor | null;
	amount: MessageDescriptor | null;
	message: MessageDescriptor | null;
}

interface Params {
	currency: CurrencyEnum;
	id: string;
}

interface OptionsProps {
	value: string;
	label: ReactNode;
}

const CryptoWithdrawForm = () => {
	const dispatch = useDispatch();
	const { locale, formatMessage } = useIntl();
	const { push } = useHistory();
	const { pathname, search } = useLocation();
	const { currency, id } = useParams<Params>();
	const { url } = useRouteMatch();
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const { deviceOS } = useSelector((state: RootState) => state.AppState);
	const parsedQuery: ParsedQuery<string> = queryString.parse(search);
	const { step } = parsedQuery;
	const currentWallet = useCurrentWallet();
	const [feeData, setFeeData] = useState<FeeResponse>({
		feeAmount: '0',
		gasLimit: 0,
		gasPrice: '0',
	});
	const [feeOptions, setFeeOptions] = useState<OptionsProps[]>([]);
	const [successData, setSuccessData] = useState<CryptoWithdrawResponse[]>([]);
	const [form, setForm] = useState<FormData[]>([
		{
			address: '',
			amount: '',
			message: '',
		},
	]);
	const [formErrors, setFormErrors] = useState<FormErrors[]>([
		{
			address: null,
			amount: null,
			message: null,
		},
	]);
	const [generalError, setGeneralError] = useState<MessageDescriptor | null>(null);
	const [feeAccount, setFeeAccount] = useState<string>(currentWallet?.id || '');
	const [feeAccountError, setFeeAccountError] = useState<MessageDescriptor | null>(null);
	const [memo, setMemo] = useState<string>('');
	const [isLoading, setIsLoading] = useState(false);
	const [markForAddressValidation, setMarkForAddressValidation] = useState<string>('');
	const [list, setList] = useState<SelectOption[]>([]);
	const [network, setNetwork] = useState<CurrencyNetwork['networkName'] | null>(null);
	const [networkError, setNetworkError] = useState<MessageDescriptor | null>(null);
	const [networkPublicName, setNetworkPublicName] = useState<string | undefined>(undefined);

	const debounceForm = useDebounce(form, 1000);
	const debounceFirstAddress = useDebounce(form[0].address, 1000);
	const debounceFirstAmount = useDebounce(form[0].amount, 1000);

	const resetForm = () => {
		setSuccessData([]);
		setForm([
			{
				amount: '',
				address: '',
				message: '',
			},
		]);
		setMemo('');
	};

	const addReceiver = () => {
		setForm([...form, { address: '', amount: '', message: '' }]);
		setFormErrors([...formErrors, { address: null, amount: null, message: null }]);
	};

	const removeReceiver = (i: number) => {
		setForm((current) => {
			const newForm = [...current];
			newForm.splice(i, 1);
			return newForm;
		});
		setFormErrors((current) => {
			const newForm = [...current];
			newForm.splice(i, 1);
			return newForm;
		});
	};

	const formatData = useCallback(
		(data: FormData[]) =>
			data.map((receivers) => ({
				...receivers,
				amount: toPlainAmount(receivers.amount, true),
			})),
		[]
	);

	const calculateAllAmountInputValues: () => string = useCallback(() => {
		return form.reduce((accumulator: string, object: FormData) => {
			return toDecimal(accumulator)
				.plus(toPlainAmount(object.amount, true) || 0)
				.toString();
		}, '0');
	}, [form]);

	const onInputChange = ({ target: { value, id: inputId } }: ChangeEvent<HTMLInputElement>) => {
		const inputData = inputId.split('-');
		setGeneralError(null);
		setFormErrors([
			...formErrors?.filter((record, index) => {
				if (index === +inputData[1]) {
					// eslint-disable-next-line no-param-reassign
					record[inputData[0]] = null;
				}
				return record;
			}),
		]);
		return setForm([
			...form?.filter((record, index) => {
				if (index === +inputData[1]) {
					// eslint-disable-next-line no-param-reassign
					record[inputData[0]] = value;
				}
				if (inputData[0] === 'address') setMarkForAddressValidation(`${index}`);
				return record;
			}),
		]);
	};

	const validateAddress = useCallback(
		(value: string, inputName: string, inputIndex: string) =>
			axiosInstance
				.post(getValidateWithdrawAddressURL(), {
					address: value,
					currencyCode: currency,
					network,
				})
				.then(({ data }) => {
					const { valid } = data;
					if (!valid) {
						const errors = [
							...formErrors.filter((record, index) => {
								if (index === +inputIndex) {
									// eslint-disable-next-line no-param-reassign
									record[inputName] = messages.invalidAddress;
								}
								return record;
							}),
						];
						setFormErrors(errors);
					}
					if (valid) {
						const errors = [
							...formErrors.filter((record, index) => {
								if (index === +inputIndex) {
									// eslint-disable-next-line no-param-reassign
									record[inputName] = null;
								}
								return record;
							}),
						];
						setFormErrors(errors);
					}
				})
				.catch((e) => {
					const errors = [
						...formErrors.filter((record, index) => {
							if (index === +inputIndex) {
								// eslint-disable-next-line no-param-reassign
								record[inputName] = messages.invalidAddress;
							}
							return record;
						}),
					];
					setFormErrors(errors);
				}),
		[currency, formErrors, network]
	);

	const estimateFee = useCallback(() => {
		setIsLoading(true);
		return axiosInstance.post(getEstimateFeeURL(), {
			currencyCode: currency,
			receivers: formatData(form),
			network,
		}) as Promise<AxiosResponse<FeeResponse>>;
	}, [currency, form, formatData, network]);

	const allAmountCalc = useCallback(() => {
		void estimateFee()
			.then(({ data }) => {
				const { feeAmount } = data;
				const calculatedAmount = isFeeAccountNeeded(currency)
					? toDecimal(currentWallet?.availableBalance || '0').toString()
					: toDecimal(currentWallet?.availableBalance || '0')
							.minus(toDecimal(feeAmount))
							.toString();
				return setForm([
					...form.filter((record, index) => {
						if (index === 0) {
							// eslint-disable-next-line no-param-reassign
							record.amount = isAmountGreaterThanZero(calculatedAmount)
								? deviceOS === DeviceNameType.IPHONE
									? swapSeparators(formatPrecision(calculatedAmount, currency))
									: formatPrecision(calculatedAmount, currency)
								: '0';
						}
						return record;
					}),
				]);
			})
			.catch(({ response: { data } }) => {
				const { errorCode } = data;
				return setFormErrors([
					...formErrors.filter((record, index) => {
						if (index === 0) {
							// eslint-disable-next-line no-param-reassign
							record.address =
								errorCode === INVALID_ADDRESS ? errorMessages.WS_19 : null;
						}
						return record;
					}),
				]);
			})
			.then(() => setIsLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currency, currentWallet?.availableBalance, estimateFee, form, formErrors]);

	const getIsFormValid = useCallback(() => {
		let isValid = true;
		let errors = [...formErrors];
		form.forEach((input, index: number) => {
			Object.keys(input).forEach((key) => {
				errors = formErrors?.filter((error) => {
					if (formErrors.indexOf(error) === index) {
						if (key === 'address') {
							// eslint-disable-next-line no-param-reassign
							error[key] = !input[key] ? inputErrors.cannotBeEmpty : error[key];
						}
						if (key === 'amount') {
							// eslint-disable-next-line no-param-reassign
							error[key] = !input[key]
								? inputErrors.cannotBeEmpty
								: input[key] && toDecimal(input[key]).equals(0)
								? messages.amountIsTooLow
								: input[key] &&
								  isAmountGreaterThan(
										input[key],
										currentWallet?.availableBalance || 0,
										true
								  )
								? messages.amountIsTooBig
								: error[key];
						}
					}
					if (!network) {
						setNetworkError(inputErrors.cannotBeEmpty);
						isValid = false;
					}
					if (error[key] !== null) isValid = false;
					return error;
				});
			});
		});
		setFormErrors([...errors]);
		return isValid;
	}, [currentWallet?.availableBalance, form, formErrors, network]);

	const handleSubmit = useCallback(
		(e: FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			setNetworkError(null);
			if (getIsFormValid()) {
				void estimateFee()
					.then(({ data }) => {
						const { feeAmount } = data;
						const feeWallet = wallets?.find(({ id: feeId }) => feeId === feeAccount);
						if (
							isFeeAccountNeeded(currency) &&
							toDecimal(feeAmount).greaterThan(feeWallet?.availableBalance || '0')
						) {
							return setFeeAccountError(inputErrors.insufficientFunds);
						}
						if (
							!isFeeAccountNeeded(currency) &&
							toDecimal(feeAmount)
								.plus(calculateAllAmountInputValues())
								.greaterThan(currentWallet?.availableBalance || '0')
						) {
							return setFormErrors([
								...formErrors.filter((record) => {
									// eslint-disable-next-line no-param-reassign
									record.amount = inputErrors.insufficientFunds;
									return record;
								}),
							]);
						}
						setFeeData(data);
						return push({
							pathname,
							search: `step=${WithdrawSteps.CONFIRM}`,
						});
					})
					.catch((err) => {
						const { errorCode } = err.response.data;
						setGeneralError(getErrorMessageOrDefault(errorCode));
					})
					.then(() => setIsLoading(false));
			}
		},
		[
			calculateAllAmountInputValues,
			currency,
			currentWallet?.availableBalance,
			estimateFee,
			feeAccount,
			formErrors,
			getIsFormValid,
			pathname,
			push,
			wallets,
		]
	);

	const postData = () => {
		const isErc = currency === CurrencyEnum.ETH || isErcCurrency(currency);
		setIsLoading(true);
		return axiosInstance.post(createWithdrawCryptosURL(), {
			accountId: id,
			currencyCode: currency,
			...(!isFeeAccountNeeded(currency) && feeData && { fee: feeData.feeAmount }),
			feeAccount: isFeeAccountNeeded(currency) ? feeAccount : id,
			memo,
			network,
			receivers:
				isErc && feeData
					? [
							{
								...formatData(form)[0],
								fee: feeData.feeAmount,
								gasLimit: feeData.gasLimit,
								gasPrice: feeData.gasPrice,
							},
					  ]
					: formatData(form),
		});
	};

	const onSuccess = (data: CryptoWithdrawResponse[]) => setSuccessData(data);

	useEffect(() => {
		if (isFeeAccountNeeded(currency) && network) {
			const feeCurrencyCode = currentWallet?.networkList?.find(
				({ networkName }) => network === networkName
			)?.feeCurrencyCode;
			const options = wallets?.reduce(
				(
					acc: OptionsProps[],
					{ currencyCode, id: accountId, availableBalance, currencyName }: Wallet
				) => {
					if (feeCurrencyCode === currencyCode) {
						acc.push({
							value: accountId,
							label: (
								<WalletOption
									currencyCode={currencyCode}
									currencyName={currencyName}
									availableBalance={availableBalance}
								/>
							),
						});
					}
					return acc;
				},
				[]
			);
			setFeeOptions(options || []);
			setFeeAccount(options ? options[0]?.value : '');
		}
	}, [currency, currentWallet?.networkList, network, wallets]);

	useEffect(() => {
		if (!step) resetForm();
		setIsLoading(false);
	}, [step]);

	useEffect(() => {
		if ((debounceForm && !isLoading && markForAddressValidation) || network) {
			form.forEach(({ address }) => {
				if (address)
					void validateAddress(address, `address`, `${markForAddressValidation}`);
				setMarkForAddressValidation('');
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debounceForm, isLoading, network]);

	useEffect(() => {
		if (
			debounceFirstAddress &&
			debounceFirstAmount &&
			toDecimal(debounceFirstAmount, true).equals(
				toDecimal(currentWallet?.availableBalance || '0')
			)
		) {
			allAmountCalc();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentWallet?.availableBalance, debounceFirstAddress, debounceFirstAmount]);

	useEffect(() => {
		if (currentWallet && !currentWallet?.networkList)
			void dispatch(getAccountNetwork(currency, id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentWallet]);

	useEffect(() => {
		if (currentWallet?.networkList && currentWallet?.networkList.length !== 0) {
			setList(
				currentWallet?.networkList?.map(({ networkName, feeCurrencyCode, publicName }) => {
					return {
						label: (
							<div className={styles.option}>
								<CurrencyIcon
									currencyType={CurrencyEnum[feeCurrencyCode]}
									className={styles.icon}
								/>
								<span className={styles.text}>{publicName}</span>
							</div>
						),
						value: networkName,
					};
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentWallet?.networkList]);

	useEffect(() => {
		if (list?.length > 0) {
			setNetwork(list[0].value);
		}
	}, [list]);

	useEffect(() => {
		if (currentWallet?.networkList)
			setNetworkPublicName(
				currentWallet?.networkList?.find(({ networkName }) => networkName === network)
					?.publicName
			);
	}, [currentWallet?.networkList, network]);

	return (
		<>
			<Seo currency={currency} title={withdrawMessages.metaSingleCryptoTitle} />
			<WithdrawFormContainer
				walletId={id}
				isLoaded={!!currentWallet && !!currentWallet.networkList}
				title={
					<PageTitle
						previousPageLink={
							step === WithdrawSteps.CONFIRM
								? `/${locale}/withdraw/crypto/${currency}/${id}?step=${WithdrawSteps.PREPARE}`
								: `/${locale}/withdraw/crypto`
						}
						isPreviousPageLinkVisibleOnDesktop={step !== WithdrawSteps.DONE}
						isPreviousPageLinkVisibleOnMobile={step !== WithdrawSteps.DONE}
						title={
							step === WithdrawSteps.CONFIRM
								? formatMessage(withdrawMessages.withdrawCryptoConfirmationTitle, {
										currency,
								  })
								: step === WithdrawSteps.DONE
								? ''
								: formatMessage(depositMessages.genericCryptoDetailsTitle, {
										currencyCode: currency,
										currencyName: formatMessage(currencyMessages[currency]),
								  })
						}
					/>
				}
				form={
					<TransfersFormLayout
						method={null}
						type={HowToType.WITHDRAW}
						title={'crypto'}
						providerType={'Crypto'}
					>
						<form className={styles.form} onSubmit={handleSubmit}>
							{generalError && (
								<NotificationMessage
									withIcon
									style={NotificationStyle.Border}
									type={NotificationType.Warning}
									message={<FormattedMessage {...generalError} />}
									className={styles.generalError}
								/>
							)}
							<InfoInput
								label={messages.withdrawalWallet}
								balance={currentWallet?.availableBalance}
								currency={currency}
								currencyName={currentWallet?.currencyName}
								hasWalletName
							/>
							<div className={styles.inputGroup}>
								<Select
									label={formatMessage(baseMsg.network)}
									inputGroupClassName={styles.selectInputGroup}
									className={styles.select}
									labelClassName={styles.label}
									disabled={list.length === 0 || list.length === 1}
									value={network}
									options={list}
									onChange={setNetwork}
									errorMessage={networkError}
								/>
							</div>
							{form.map(({ address, amount, message }, index) => (
								// eslint-disable-next-line react/no-array-index-key
								<Fragment key={`fields-${index}`}>
									{index !== 0 && <div className={styles.line} />}
									<Input
										id={`address-${index}`}
										value={address}
										label={`${formatMessage(baseMsg.receiver)} ${
											index !== 0 ? `#${index + 1}` : ''
										}`}
										onChangeEvent={onInputChange}
										placeholder={formatMessage(messages.receiverPlaceholder, {
											currency,
										})}
										labelClassName={styles.label}
										inputGroupClassName={styles.inputGroup}
										errorMessage={
											formErrors.filter((_, i) => {
												return index === i;
											})[0]?.address
										}
									/>
									<CustomInput
										maxValue={
											index === 0
												? currentWallet?.availableBalance || '0'
												: undefined
										}
										id={`amount-${index}`}
										label={baseMsg.withdrawAmount}
										currency={currency}
										onChange={onInputChange}
										isLoading={isLoading}
										value={amount}
										isIconVisible
										errorMessage={
											formErrors.filter((_, i) => {
												return index === i;
											})[0]?.amount
										}
									/>
									{currenciesWithMessage.includes(currency) && (
										<>
											<div className={styles.info}>
												<img src={infoImgPath} alt="Information icon" />
												{currency === CurrencyEnum.XEM && (
													<FormattedMessage
														id="cryptoWithdrawForm.messageInfo"
														defaultMessage="The message (if the receiving wallet requires it) must be included in the payment, or your funds will be lost"
													/>
												)}
												{currency === CurrencyEnum.XLM && (
													<FormattedMessage
														id="cryptoWithdrawForm.memoInfo"
														defaultMessage="The Memo (if the receiving wallet requires it) must be included in the payment, or your funds will be lost"
													/>
												)}
												{currency === CurrencyEnum.XRP && (
													<FormattedMessage
														id="cryptoWithdrawForm.destinatioTagInfo"
														defaultMessage="The Destination tag (if the receiving wallet requires it) must be included in the payment, or your funds will be lost"
													/>
												)}
											</div>
											<Input
												id={`message-${index}`}
												value={message || ''}
												label={
													currency === CurrencyEnum.XEM
														? formatMessage(baseMsg.message)
														: currency === CurrencyEnum.XLM
														? formatMessage(baseMsg.memo)
														: formatMessage(baseMsg.destinationTag)
												}
												onChangeEvent={onInputChange}
												placeholder={
													currency === CurrencyEnum.XEM
														? formatMessage(messages.messageLabel)
														: currency === CurrencyEnum.XLM
														? formatMessage(messages.memoXLMLabel)
														: formatMessage(
																messages.destinationTagLabel
														  )
												}
												labelClassName={styles.label}
												inputGroupClassName={styles.inputGroup}
												errorMessage={
													formErrors.filter((_, i) => {
														return index === i;
													})[0]?.message
												}
											/>
										</>
									)}
									{index !== 0 && (
										<button
											type="button"
											onClick={() => removeReceiver(index)}
											className={styles.removeButton}
										>
											<FontAwesomeIcon icon={faXmark} />
											{formatMessage(messages.removeReceiver)}
										</button>
									)}
								</Fragment>
							))}
							{(currency === CurrencyEnum.BTC || currency === CurrencyEnum.DASH) && (
								<button
									className={styles.addButton}
									type="button"
									onClick={addReceiver}
								>
									{formatMessage(messages.addReceiver)}
								</button>
							)}
							{(isErcCurrency(currency) || currency === CurrencyEnum.TRX) && (
								<div className={styles.inputGroup}>
									<label htmlFor="" className={styles.label}>
										{formatMessage(messages.accountForFeeLabel)}
										<Tooltip
											content={
												<div className={styles.tooltipContent}>
													<FormattedMessage
														id="cryptoWithdrawForm.gasWalletInfo"
														defaultMessage="Gas wallet refers to a wallet fee that is needed to execute a transaction on the ETH network."
													/>
												</div>
											}
										>
											<div className={styles.tooltipToggle}>i</div>
										</Tooltip>
									</label>
									<Select
										options={feeOptions}
										onChange={(value: string) => setFeeAccount(value)}
										classNamePrefix="fee"
										errorMessage={feeAccountError}
										value={feeAccount}
									/>
								</div>
							)}
							{![CurrencyEnum.XEM, CurrencyEnum.XRP, CurrencyEnum.XLM].includes(
								currency
							) && (
								<Input
									value={memo}
									label={baseMsg.memo}
									onChange={setMemo}
									labelClassName={styles.label}
									placeholder={baseMsg.transactionMemoPlaceholder}
									inputGroupClassName={styles.inputGroup}
								/>
							)}
							<Button
								className={styles.button}
								text={baseMsg.submit}
								type={ButtonType.SUBMIT}
								isLoading={isLoading}
								isDisabled={isLoading}
							/>
							{currency === CurrencyEnum.BTC && (
								<Button
									buttonStyle={ButtonStyle.TERTIARY}
									type={ButtonType.LINK}
									link={`${url}/bulk`}
									className={classNames(styles.button, styles.smallerMargin)}
									text={formatMessage(messages.bulkSend, { currency })}
								/>
							)}
						</form>
					</TransfersFormLayout>
				}
				confirm={
					<WithdrawConfirm
						type={WithdrawType.CRYPTOCURRENCY}
						currency={CurrencyEnum[currency]}
						fee={feeData}
						formData={form}
						memo={memo}
						feeAccountId={feeAccount}
						onSuccess={onSuccess}
						onSubmit={postData}
						network={network ? networkPublicName : ''}
					/>
				}
				done={
					<WithdrawSuccess
						data={successData}
						currency={CurrencyEnum[currency]}
						type={WithdrawType.CRYPTOCURRENCY}
						network={network ? networkPublicName : ''}
						feeAccountId={feeAccount}
					/>
				}
				isDone={successData.length > 0}
			/>
		</>
	);
};

export default CryptoWithdrawForm;
