import { ActionCreator } from 'redux';
import { RemoteError } from '../../../../interfaces/RemoteData';
import { AppDispatch } from '../../../Store';
import { getPaymentButtonUrl } from '../../../endpoints';
import {
	PaymentButton,
	PaymentButtonDataDeletePaymentButtonAction,
	PaymentButtonDataFetchPaymentButtonFulfilledAction,
	PaymentButtonDataFetchPaymentButtonPendingAction,
	PaymentButtonDataFetchPaymentButtonRejectedAction,
	PaymentButtonDataUpdatePaymentButtonAction,
	PaymentButtonDataUpdatePaymentButtonsAction,
	PaymentButtonsDataActionType,
} from './PaymentButtonsDataTypes';
import axiosInstance from '../../../../helpers/axiosInstance';

const updateButton: ActionCreator<PaymentButtonDataUpdatePaymentButtonAction> = (
	button: PaymentButton
) => ({
	type: PaymentButtonsDataActionType.UPDATE_PAYMENT_BUTTON,
	payload: button,
});

const updateButtons: ActionCreator<PaymentButtonDataUpdatePaymentButtonsAction> = (
	buttons: PaymentButton[]
) => ({
	type: PaymentButtonsDataActionType.UPDATE_PAYMENT_BUTTONS,
	payload: buttons,
});

const fetchButtonPending: ActionCreator<PaymentButtonDataFetchPaymentButtonPendingAction> = (
	id: string
) => ({
	type: PaymentButtonsDataActionType.FETCH_PAYMENT_BUTTON_PENDING,
	payload: id,
});

const fetchButtonRejected: ActionCreator<PaymentButtonDataFetchPaymentButtonRejectedAction> = (
	id: string,
	error?: RemoteError
) => ({
	type: PaymentButtonsDataActionType.FETCH_PAYMENT_BUTTON_REJECTED,
	payload: { id, error },
});

const fetchButtonFulfilled: ActionCreator<PaymentButtonDataFetchPaymentButtonFulfilledAction> = (
	button: PaymentButton
) => ({
	type: PaymentButtonsDataActionType.FETCH_PAYMENT_BUTTON_FULFILLED,
	payload: button,
});

const fetchButton = (id: string) => async (dispatch: AppDispatch) => {
	dispatch(fetchButtonPending(id));

	await axiosInstance
		.get<PaymentButton>(getPaymentButtonUrl(id))
		.then(({ data }) => {
			dispatch(fetchButtonFulfilled(data));
		})
		.catch((e) => {
			dispatch(fetchButtonRejected(id, e.response?.data));
		});
};

const deleteButton: ActionCreator<PaymentButtonDataDeletePaymentButtonAction> = (id: string) => ({
	type: PaymentButtonsDataActionType.DELETE_PAYMENT_BUTTON,
	payload: id,
});

export default {
	deleteButton,
	updateButton,
	updateButtons,
	fetchButton,
};
