/* eslint-disable consistent-return */
import { Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Donation from '../../pages/Payments/Donation/Donation';
import PaymentsMessages from '../../redux/PaymentsState/PaymentsMessages';
import OrderPayment from '../../pages/Payments/OrderPayment/OrderPayment';
import usePaymentRoutes, { PaymentRoutes } from '../../hooks/usePaymentRoutes';
import MerchantsActions from '../../redux/MerchantsState/MerchantsActions';
import styles from './PaymentsContainer.module.scss';
import Seo from '../../components/Seo/Seo';
import useEffectOnce from '../../hooks/useEffectOnce';

const PaymentsContainer = () => {
	const dispatch = useDispatch();
	const { getPath } = usePaymentRoutes();

	useEffectOnce(() => {
		void dispatch(MerchantsActions.loadData());
	});

	return (
		<div className={styles.container}>
			<Seo title={PaymentsMessages.metaTitle} />
			<div className={styles.flexGrid}>
				<div className={styles.content}>
					<Route exact path={getPath(PaymentRoutes.Order)} component={OrderPayment} />
					<Route exact path={getPath(PaymentRoutes.Donation)} component={Donation} />
				</div>
			</div>
		</div>
	);
};

export default PaymentsContainer;
