/* eslint-disable no-param-reassign */
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import { fromEntries } from '../../../../helpers/objectHelper/objectHelper';
import {
	ProjectsDataActionType,
	ProjectDataFetchProjectPendingAction,
	ProjectDataFetchProjectRejectedAction,
	ProjectDataFetchProjectFulfilledAction,
	ProjectDataUpdateProjectAction,
	ProjectDataUpdateProjectsAction,
	ProjectsDataState,
	ProjectDataDeleteProjectAction,
} from './ProjectsDataTypes';

const initialState: ProjectsDataState = {
	projects: {},
};

export default (
	state = initialState,
	action:
		| ProjectDataFetchProjectPendingAction
		| ProjectDataFetchProjectRejectedAction
		| ProjectDataFetchProjectFulfilledAction
		| ProjectDataUpdateProjectAction
		| ProjectDataUpdateProjectsAction
		| ProjectDataDeleteProjectAction
): ProjectsDataState => {
	switch (action.type) {
		case ProjectsDataActionType.FETCH_PROJECT_PENDING:
			return {
				...state,
				projects: {
					...state.projects,
					[action.payload]: { status: RemoteStatus.InProgress },
				},
			};
		case ProjectsDataActionType.FETCH_PROJECT_REJECTED:
			return {
				...state,
				projects: {
					...state.projects,
					[action.payload]: { status: RemoteStatus.Error },
				},
			};
		case ProjectsDataActionType.FETCH_PROJECT_FULFILLED:
			return {
				...state,
				projects: {
					...state.projects,
					[action.payload.id]: {
						status: RemoteStatus.Done,
						data: {
							...action.payload,
							created: action.payload.created,
							isUsed:
								action.payload.isUsed === null
									? state.projects[action.payload.id]?.data?.isUsed ||
									  action.payload.isUsed
									: action.payload.isUsed,
						},
					},
				},
			};
		case ProjectsDataActionType.UPDATE_PROJECT:
			return {
				...state,
				projects: {
					...state.projects,
					[action.payload.id]: {
						status: RemoteStatus.Done,
						data: {
							...action.payload,
							created: action.payload.created,
							isUsed:
								action.payload.isUsed === null
									? state.projects[action.payload.id]?.data?.isUsed ||
									  action.payload.isUsed
									: action.payload.isUsed,
						},
					},
				},
			};
		case ProjectsDataActionType.UPDATE_PROJECTS:
			return {
				...state,
				projects: {
					...state.projects,
					...fromEntries(
						action.payload.map((x) => [
							x.id,
							{
								status: RemoteStatus.Done,
								data: {
									...x,
									created: x.created,
									isUsed:
										x.isUsed === null
											? state.projects[x.id]?.data?.isUsed || x.isUsed
											: x.isUsed,
								},
							},
						])
					),
				},
			};
		case ProjectsDataActionType.DELETE_PROJECT:
			return {
				...state,
				projects: {
					...state.projects,
					[action.payload]: { status: RemoteStatus.Error },
				},
			};
		default:
			return state;
	}
};
