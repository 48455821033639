/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import { useEffect, FC, useRef, PropsWithChildren, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { differenceInMinutes } from 'date-fns';
import { useIntl } from 'react-intl';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RootState } from '../../redux/Store';
import { UserCompany } from '../../redux/ProfileState/ProfileTypes';
import {
	getProfile,
	getCompany,
	getProfileVerificationStatus,
} from '../../redux/ProfileState/ProfileActions';
import toggleModal from '../../redux/ModalState/ModalActions';
import { getAccounts } from '../../redux/AccountsState/AccountsActions';
import { getRates, getRatesDetails } from '../../redux/RatesState/RatesActions';
import SessionExpirationModal from '../../components/Modal/SessionExpirationModal/SessionExpirationModal';
import useEffectOnce from '../../hooks/useEffectOnce';
import { getUserLoginStatusURL } from '../../redux/endpoints';
import AuthErrorCode from '../../enums/AuthErrorCode';
import config from '../../configs/config';
import { get2FARetrySettings } from '../../redux/AppState/AppActions';
import axiosInstance from '../../helpers/axiosInstance';

const oneSec = 1000;
const oneMin: number = 60 * 1000;

const AppContainer: FC<PropsWithChildren> = ({ children }: any) => {
	const history: any = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const { locale } = useIntl();
	const timer = useRef<number>();
	const { company, user, idleTimer } = useSelector((state: RootState) => state.ProfileState);
	const { TOGGLE_MODAL } = useSelector((state: RootState) => state.ModalState);
	const referenceCurrency = Cookies.get('primaryCurrency') || CurrencyEnum.USD;
	const [showExpirationModal, setShowExpirationModal] = useState(false);

	useEffectOnce(() => {
		dispatch<any>(getProfile());
		dispatch<any>(getAccounts());
		if (!company) void dispatch(getCompany());
		dispatch<any>(getRates());
		dispatch<any>(get2FARetrySettings());
		dispatch<any>(getProfileVerificationStatus());
		axiosInstance.get(getUserLoginStatusURL()).catch((e) => {
			const { errorCode } = e.response?.data || {};
			if (errorCode === AuthErrorCode.US_2) {
				window.location.assign(
					`${config.PROFILE_DOMAIN}/setup?redirectUrl=${encodeURIComponent(
						window.location.href
					)}`
				);
			}
		});
	});

	useEffect(() => {
		dispatch<any>(getRatesDetails(referenceCurrency));
	}, [referenceCurrency, Cookies.get('primaryCurrency')]);

	useEffect(() => {
		if (user && user !== null && company && company !== null) {
			if (user?.verified === false && company !== UserCompany.SF_SYSTEMS_BVI)
				window.location.assign(`${config.PROFILE_DOMAIN}/${locale}/verification/verify`);
		}
	}, [user, company, locale]);

	useEffect(() => {
		history.listen((loc: any, action: any) => {
			if (loc.pathname !== location.pathname && action === 'PUSH') window.scrollTo(0, 0);
		});
	}, [history]);

	useEffect(() => {
		timer.current = window.setInterval(() => {
			const nowDate = new Date().getTime();
			const idleTimeMinusMinute = new Date(idleTimer - oneMin).getTime();

			setShowExpirationModal(differenceInMinutes(idleTimeMinusMinute, nowDate) <= 0);
		}, oneSec);

		return () => clearInterval(timer.current);
	}, [idleTimer]);

	useEffect(() => {
		if (!showExpirationModal) return;
		if (TOGGLE_MODAL) dispatch(toggleModal());
		dispatch(toggleModal(<SessionExpirationModal />, false));
	}, [showExpirationModal]);

	return children;
};

export default AppContainer;
