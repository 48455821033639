/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-dynamic-require */
import Cookies from 'js-cookie';
import config from '../../configs/config';
import { LanguageActionType, LanguageList, ChangeLanguageAction } from './LanguageTypes';

const SUPPORTED_LANGUAGES_MOCK = [{ value: 'en', label: 'English' }];

const { SUPPORTED_LANGUAGES } = require(`../../../env/config.${
	process.env.REACT_APP_ENV === 'local' ? 'local' : 'prod'
}.js`);

const { pathname } = window.location;
const lngFromURL = pathname.split('/')[1];

const useMock = false;

const languageList = useMock
	? SUPPORTED_LANGUAGES_MOCK.reduce((acc: string[], curr: LanguageList) => {
			acc.push(curr.value);
			return acc;
	  }, [])
	: SUPPORTED_LANGUAGES;

export const initialState = {
	availableLanguagesList: languageList,
	activeLanguage: languageList.includes(lngFromURL)
		? lngFromURL
		: Cookies.get('userLang') || 'en',
};

if (!Cookies.get(config.USER_LANG_COOKIE_KEY!)) {
	Cookies.set(config.USER_LANG_COOKIE_KEY!, initialState.activeLanguage, {
		domain: config.USER_LANG_COOKIE_DOMAIN,
	});
}

export default (state = initialState, action: ChangeLanguageAction) => {
	switch (action.type) {
		case LanguageActionType.SET_LANGUAGE:
			return {
				...state,
				activeLanguage: action.lng,
			};
		default:
			return state;
	}
};
