import { SetStateAction, isValidElement, useCallback, useEffect, useState } from 'react';
import { MessageDescriptor } from 'react-intl';
import Toggle from '../Toggle/Toggle';

import styles from './OptionalField.module.scss';

type OptionalFieldProps<T> = {
	name: string;
	id: string;
	label: MessageDescriptor | React.ReactNode;
	initialState?: boolean;
	initialValue?: T;
	onChange: (value: T | null) => void;
	children(options: { onChange: (value: T | null) => void }): React.ReactNode;
	inverted?: boolean;
	onToggle?: (value: boolean) => void;
	isDisabled?: boolean;
};

const OptionalField = <T,>({
	id,
	name,
	label,
	initialState = false,
	initialValue,
	onChange,
	children,
	onToggle,
	inverted = false,
	isDisabled = false,
	...rest
}: OptionalFieldProps<T>) => {
	const [enabled, setEnabled] = useState(initialState);
	const toggle = useCallback(
		(value: SetStateAction<boolean>) => {
			if (onToggle) onToggle(typeof value === 'boolean' ? value : value(enabled));
			setEnabled(value);
		},
		[onToggle, enabled]
	);

	const setValue = useCallback(
		(value: T | null) => {
			onChange(value);
		},
		[onChange]
	);

	const show = inverted ? !enabled : enabled;

	useEffect(() => {
		if (!show) setValue(null);
		if (initialValue && show) setValue(initialValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enabled]);

	useEffect(() => {
		if (isDisabled && show) toggle(false);
	}, [isDisabled]);

	return (
		<div className={styles.container} {...rest}>
			<Toggle
				className={styles.toggle}
				checked={enabled}
				name={name}
				id={id}
				label={(!isValidElement(label) && (label as MessageDescriptor)) || undefined}
				onChange={() => toggle((x) => !x)}
				disabled={isDisabled}
			>
				{isValidElement(label) && label}
			</Toggle>
			{show && <div className={styles.field}>{children({ onChange: setValue })}</div>}
		</div>
	);
};

export default OptionalField;
