import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import MerchantsActions from '../../../../redux/MerchantsState/MerchantsActions';
import { RootState } from '../../../../redux/Store';
import MerchantsSelectors from '../../../../redux/MerchantsState/MerchantsSelectors';
import {
	PaymentButton,
	PaymentButtonsListMode,
} from '../../../../redux/MerchantsState/MerchantTypes';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation';

import styles from './PaymentButtonAction.module.scss';
import TestIds from '../../../../test/TestIds';
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import useQueryPagination from '../../../../hooks/useQueryPagination';

type PaymentButtonActionProps = {
	button: PaymentButton;
};

const NoAction: FC = () => {
	const { isMobile } = useSelector((state: RootState) => state.AppState);
	return !isMobile ? (
		<FontAwesomeIcon className={styles.icon} icon={faChevronRight} size="lg" />
	) : null;
};

const DeleteButtonConfirmation: FC<PaymentButtonActionProps> = ({ button }) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const { status, error } = useSelector(MerchantsSelectors.paymentButtons.list.getDelete);
	const [page] = useQueryPagination();

	useEffect(() => {
		if (status === RemoteStatus.Done) {
			dispatch(toggleModal());
			dispatch(MerchantsActions.paymentButtons.list.resetDelete());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	return (
		<DeleteConfirmation
			isDisabled={status === RemoteStatus.InProgress}
			name={button.name}
			onDelete={async () => {
				await dispatch(
					MerchantsActions.paymentButtons.list.deleteButton(button.id, page - 1)
				);
			}}
		>
			{error && (
				<NotificationMessage
					withIcon
					data-cy={TestIds.PaymentButtonRemoveError}
					type={NotificationType.Error}
					style={NotificationStyle.Border}
					message={formatMessage(getErrorMessageOrDefault(error))}
				/>
			)}
		</DeleteConfirmation>
	);
};

const DeleteAction: FC<PaymentButtonActionProps> = ({ button }) => {
	const dispatch = useDispatch();

	const deleteProject = (e: React.MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();
		dispatch(toggleModal(<DeleteButtonConfirmation button={button} />));
		return false;
	};

	return (
		<FontAwesomeIcon
			data-cy={TestIds.PaymentButtonListRowDelete}
			className={classNames(styles.icon, styles.deleteIcon)}
			icon={faTimes}
			onClick={deleteProject}
			size="lg"
		/>
	);
};

const PaymentButtonAction: FC<PaymentButtonActionProps> = ({ button, ...props }) => {
	const { mode } = useSelector(MerchantsSelectors.paymentButtons.list.getState);

	const content = (() => {
		switch (mode) {
			case PaymentButtonsListMode.Delete:
				return <DeleteAction {...props} button={button} />;

			default:
				return <NoAction {...props} />;
		}
	})();

	return <div className={styles.container}>{content}</div>;
};

export default PaymentButtonAction;
