/* eslint-disable no-param-reassign */
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import {
	PayoutsHistoryActionType,
	PayoutsHistoryFetchPayoutsPendingAction,
	PayoutsHistoryFetchPayoutsRejectedAction,
	PayoutsHistoryFetchPayoutsFulfilledAction,
	PayoutsHistoryState,
} from './PayoutsHistoryTypes';

const initialState: PayoutsHistoryState = {
	data: [],
	totalCount: 0,
	status: RemoteStatus.None,
	pageSize: 20,
};

export default (
	state = initialState,
	action:
		| PayoutsHistoryFetchPayoutsPendingAction
		| PayoutsHistoryFetchPayoutsRejectedAction
		| PayoutsHistoryFetchPayoutsFulfilledAction
): PayoutsHistoryState => {
	switch (action.type) {
		case PayoutsHistoryActionType.FETCH_PAYOUTS_PENDING:
			return {
				...state,
				status: RemoteStatus.InProgress,
			};
		case PayoutsHistoryActionType.FETCH_PAYOUTS_REJECTED:
			return {
				...state,
				status: RemoteStatus.Error,
				error: action.payload,
			};
		case PayoutsHistoryActionType.FETCH_PAYOUTS_FULFILLED:
			return {
				...state,
				totalCount: action.payload.totalElements,
				status: RemoteStatus.Done,
				pageSize: action.payload.pageable.pageSize,
				data: action.payload.content as any,
			};
		default:
			return state;
	}
};
