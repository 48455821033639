import { FC, memo } from 'react';
import styles from './Sidebar.module.scss';
import Program from '../Program/Program';
import ProfitSummary from '../ProfitSummary/ProfitSummary';

const Sidebar: FC = () => {
	return (
		<div className={styles.sidebar}>
			<Program />
			<ProfitSummary />
		</div>
	);
};

export default memo(Sidebar);
