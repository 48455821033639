/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import * as Sentry from '@sentry/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import store from './redux/Store';
import AppRoutes from './AppRoutes';
import LangProvider from './components/LangProvider/LangProvider';
import { setDeviceOS, setIsMobile, setIsTablet } from './redux/AppState/AppActions';
import useEffectOnce from './hooks/useEffectOnce';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			retry: false,
			// retry: 5,
			// retryDelay: (failureCount) => failureCount * 5000 + 5000,
		},
	},
});

const App = () => {
	const checkResolution = () => {
		store.dispatch<any>(setIsMobile(window.innerWidth <= 767));
		store.dispatch<any>(setIsTablet(window.innerWidth <= 991));
		window.onresize = () => {
			store.dispatch<any>(setIsMobile(window.innerWidth <= 767));
			store.dispatch<any>(setIsTablet(window.innerWidth <= 991));
		};
	};

	useEffectOnce(() => {
		store.dispatch<any>(setDeviceOS(navigator.userAgent));
		checkResolution();
	});

	return (
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<LangProvider>
					<Router>
						<AppRoutes />
					</Router>
				</LangProvider>
			</Provider>
		</QueryClientProvider>
	);
};

export default Sentry.withProfiler(App);
