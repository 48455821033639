import classNames from 'classnames';
import { ForwardedRef, forwardRef, MouseEventHandler } from 'react';
import styles from './SvgIcon.module.scss';

interface SvgIconProps {
	src: string;
	className?: string;
	style?: any;
	onClick?: MouseEventHandler;
}

const SvgIcon = (
	{ src, className, style, onClick, ...rest }: SvgIconProps,
	ref: ForwardedRef<HTMLDivElement>
) => (
	<div
		ref={ref}
		className={classNames(styles.imgContainer, className)}
		style={style}
		onClick={onClick}
		{...rest}
	>
		<img src={src} />
	</div>
);

export default forwardRef(SvgIcon);
