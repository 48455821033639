import { defineMessages, useIntl } from 'react-intl';
import { c } from 'msw/lib/glossary-de6278a9';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';
import {
	EWalletSuccessResponse,
	ProviderType,
} from '../../../../redux/WithdrawState/WithdrawTypes';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import convertToLocalTime from '../../../../helpers/dateHelper/dateHelper';
import baseMsg from '../../../../messages/base.messages';
import useFormatAmount from '../../../../hooks/useFormatAmount';

const messages = defineMessages({
	ewalletWithdrawNumber: {
		id: 'eWalletSummary.ewalletWithdrawNumber',
		defaultMessage: '{provider} withdraw number',
	},
});

interface EWalletSummaryProps {
	data: EWalletSuccessResponse[];
	dateClass?: string;
	infoClass?: string;
	provider: string;
	providerTitle?: string;
}

const EWalletSummary = ({
	data,
	dateClass,
	infoClass,
	provider,
	providerTitle,
}: EWalletSummaryProps) => {
	const { formatMessage } = useIntl();
	const amountFormatter = useFormatAmount();

	return (
		<>
			{data.map(
				({
					date,
					id,
					receiveAmount,
					withdrawAmount,
					accountNumber,
					withdrawCurrencyCode,
					receiveCurrencyCode,
				}) => {
					return (
						<li key={id}>
							{date && (
								<div className={dateClass}>
									{convertToLocalTime(date, 'yyyy-MM-dd HH:mm:ss')}
								</div>
							)}
							{id && (
								<InfoInput
									label={formatMessage(messages.ewalletWithdrawNumber, {
										provider,
									})}
									text={id}
									className={infoClass}
								/>
							)}
							{accountNumber && (
								<InfoInput
									label={
										provider === ProviderType.SKRILL
											? formatMessage(withdrawMessages.emailLabel, {
													providerType: providerTitle,
											  })
											: provider === ProviderType.ADV_CASH
											? formatMessage(withdrawMessages.emailOrAccountLabel, {
													providerType: providerTitle,
											  })
											: formatMessage(withdrawMessages.ewalletAccountLabel, {
													providerType: providerTitle,
											  })
									}
									text={accountNumber}
									className={infoClass}
								/>
							)}
							{withdrawAmount && (
								<InfoInput
									label={formatMessage(baseMsg.withdrawAmount)}
									text={`${amountFormatter(
										formatPrecision(withdrawAmount, withdrawCurrencyCode)
									)} ${withdrawCurrencyCode}`}
									className={infoClass}
								/>
							)}
							{receiveAmount && (
								<InfoInput
									label={formatMessage(baseMsg.receiveAmount)}
									text={`${amountFormatter(
										formatPrecision(receiveAmount, receiveCurrencyCode)
									)} ${receiveCurrencyCode}`}
									className={infoClass}
								/>
							)}
						</li>
					);
				}
			)}
		</>
	);
};

export default EWalletSummary;
