import { FC, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useMerchantRoutes, { MerchantRoutes } from '../../../hooks/useMerchantRoutes';
import { RootState } from '../../../redux/Store';
import MerchantsMenuPanel from '../Shared/MerchantsMenuPanel/MerchantsMenuPanel';

const MobileHomepage: FC = () => {
	const { isMobile } = useSelector((state: RootState) => state?.AppState);
	const { replace } = useHistory();
	const { getUrl } = useMerchantRoutes();

	useLayoutEffect(() => {
		if (!isMobile) replace(getUrl(MerchantRoutes.ProjectsList));
	}, [getUrl, isMobile, replace]);

	return <MerchantsMenuPanel />;
};

export default MobileHomepage;
