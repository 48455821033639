/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import userManagerInstance from '../../../helpers/userManager/userManager';
import { getAccessToken } from '../../../helpers/authHelper/authHelper';
import { logout, userLogin } from '../../../redux/ProfileState/ProfileActions';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import config from '../../../configs/config';
import styles from './LoginCallback.module.scss';
import useEffectOnce from '../../../hooks/useEffectOnce';
import AuthErrorCode from '../../../enums/AuthErrorCode';
import { RemoteError } from '../../../interfaces/RemoteData';
import { getErrorMessageOrDefault } from '../../../helpers/errorMessageHelper/errorMessageHelper';
import img from '../../../images/status/failure.svg';
import InfoHead from '../../../components/InfoHead/InfoHead';
import Button, { ButtonStyle, ButtonType } from '../../../components/Button/Button';

const messages = defineMessages({
	login: {
		id: 'login.login',
		defaultMessage: 'Back to Login',
	},
	support: {
		id: 'cardStatus.contactSupport',
		defaultMessage: 'Contact support',
	},
});

const getIsCodeInURL = (location: ReturnType<typeof useLocation>) => {
	const searchParams = new URLSearchParams(location.search);
	return Boolean(searchParams.get('code'));
};

const LoginCallback = () => {
	const location = useLocation();
	const { locale } = useIntl();
	const searchParams = new URLSearchParams(location.search);
	const isCodeInURL = getIsCodeInURL(location);
	const dispatch = useDispatch();
	const { push } = useHistory();
	const [error, setError] = useState(
		JSON.stringify(location.state?.error) || searchParams.get('error')
	);
	const [accessError, setAccessError] = useState<MessageDescriptor>();

	useEffectOnce(() => {
		if (!isCodeInURL || error) return;
		// Exchange code received from the callback URL to token using authorization server endpoint
		userManagerInstance
			.signinCallback()
			.then((user) => {
				if (user) {
					return dispatch<any>(userLogin(push, locale));
				}
				return console.error('No user data returned from endpoint');
			})
			.catch((e) => {
				console.error(e || 'something went wrong');
				// For now swallow any FE errors as long as backend considers us
				// logged in
				if (getAccessToken()?.user_id) {
					return dispatch<any>(userLogin(push, locale));
				}
				Sentry.captureException(e);
				return setError(JSON.stringify(e));
			})
			.catch((e: RemoteError | null) => {
				console.error(e || 'something went wrong');
				if (e?.errorCode === AuthErrorCode.US_3)
					setAccessError(getErrorMessageOrDefault(e));
				else Sentry.captureException(e);
				setTimeout(() => dispatch(logout()), 10000);
			});
	});

	useEffect(() => {
		if (!isCodeInURL || error) {
			dispatch<any>(logout(`${config.LOGIN_PAGE_URI}?error=unexpected_error`));
		}
	}, [dispatch, isCodeInURL, error]);

	if (accessError)
		return (
			<div className={styles.error}>
				<InfoHead
					img={img}
					text={getErrorMessageOrDefault(accessError)}
					className={styles.head}
				/>
				<Button
					type={ButtonType.BUTTON}
					text={messages.login}
					className={styles.button}
					onClick={() => dispatch(logout())}
				/>
				<Button
					type={ButtonType.ANCHOR_LINK}
					buttonStyle={ButtonStyle.SECONDARY_WITHOUT_BORDER}
					text={messages.support}
					className={styles.button}
					link="mailto:helpdesk@spectrocoin.com"
				/>
			</div>
		);
	return <LoadingScreen className={styles.loadingScreen} />;
};

export default LoginCallback;
