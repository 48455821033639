import { RemoteStatus } from '../../interfaces/RemoteData';
import {
	ApiKeyCreateErrorAction,
	ApiKeyCreateSuccessAction,
	ApiKeyDeleteSuccessAction,
	ApiKeyEditErrorAction,
	ApiKeyEditSuccessAction,
	ApiKeysErrorAction,
	ApiKeysPendingAction,
	ApiKeysSuccessAction,
	ResetApiKeysStateAction,
	ApiKeyEditLoadingAction,
	ApiKeyDeleteLoadingAction,
	SettingsActionTypes,
	SettingsState,
	ApiSingleKeyErrorAction,
	ApiSingleKeyPendingAction,
	ApiSingleKeySuccessAction,
} from './SettingsTypes';

const initialState: SettingsState = {
	apiKeys: {
		data: null, // single apikey data
		list: { status: RemoteStatus.None, data: [] },
		delete: { status: RemoteStatus.None },
		edit: { status: RemoteStatus.None },
		error: null,
	},
};

export default (
	state = initialState,
	action:
		| ApiKeysPendingAction
		| ApiKeysSuccessAction
		| ApiKeysErrorAction
		| ApiSingleKeySuccessAction
		| ApiSingleKeyErrorAction
		| ApiSingleKeyPendingAction
		| ApiKeyCreateSuccessAction
		| ApiKeyEditSuccessAction
		| ApiKeyCreateErrorAction
		| ApiKeyEditErrorAction
		| ApiKeyDeleteSuccessAction
		| ResetApiKeysStateAction
		| ApiKeyEditLoadingAction
		| ApiKeyDeleteLoadingAction
) => {
	switch (action.type) {
		case SettingsActionTypes.API_KEYS_LOADING:
		case SettingsActionTypes.API_SIGNLE_KEY_LOADING:
			return {
				...state,
				apiKeys: {
					...state.apiKeys,
					list: {
						...state.apiKeys.list,
						status: RemoteStatus.InProgress,
					},
					error: null,
				},
			};
		case SettingsActionTypes.API_KEYS_SUCCESS:
			return {
				...state,
				apiKeys: {
					...state.apiKeys,
					list: {
						data: action.data,
						status: RemoteStatus.Done,
					},
					error: null,
				},
			};
		case SettingsActionTypes.API_SIGNLE_KEY_SUCCESS:
			return {
				...state,
				apiKeys: {
					...state.apiKeys,
					list: {
						...state.apiKeys.list,
						status: RemoteStatus.Done,
					},
					data: action.data,
					error: null,
				},
			};
		case SettingsActionTypes.API_KEYS_ERROR:
		case SettingsActionTypes.API_KEY_EDIT_ERROR:
		case SettingsActionTypes.API_KEY_CREATE_ERROR:
		case SettingsActionTypes.API_SIGNLE_KEY_ERROR:
			return {
				...state,
				apiKeys: {
					...state.apiKeys,
					list: {
						...state.apiKeys.list,
						status: RemoteStatus.Error,
					},
					delete: {
						...state.apiKeys.delete,
						status: RemoteStatus.Error,
					},
					edit: {
						...state.apiKeys.edit,
						status: RemoteStatus.Error,
					},
					error: action.error,
				},
			};
		case SettingsActionTypes.API_KEY_CREATE_SUCCESS:
			return {
				...state,
				apiKeys: {
					...state.apiKeys,
					list: {
						...state.apiKeys.list,
						data: [...state.apiKeys.list.data!, action.data],
					},
					edit: {
						...state.apiKeys.edit,
						status: RemoteStatus.Done,
					},
					error: null,
				},
			};
		case SettingsActionTypes.API_KEY_EDIT_SUCCESS:
			return {
				...state,
				apiKeys: {
					...state.apiKeys,
					list: {
						...state.apiKeys.list,
						data: state.apiKeys.list.data!.map((apiKey) => {
							if (apiKey.clientId === action.data.clientId) {
								return action.data;
							}
							return apiKey;
						}),
					},
					edit: {
						...state.apiKeys.edit,
						status: RemoteStatus.Done,
					},
					error: null,
				},
			};
		case SettingsActionTypes.API_KEY_EDIT_LOADING:
			return {
				...state,
				apiKeys: {
					...state.apiKeys,
					edit: {
						...state.apiKeys.edit,
						status: RemoteStatus.InProgress,
					},
					error: null,
				},
			};
		case SettingsActionTypes.API_KEY_DELETE_SUCCESS:
			return {
				...state,
				apiKeys: {
					...state.apiKeys,
					list: {
						...state.apiKeys.list,
						data: state.apiKeys.list.data!.filter(
							(apiKey) => apiKey.clientId !== action.clientId
						),
					},
					delete: {
						...state.apiKeys.delete,
						status: RemoteStatus.Done,
					},
					error: null,
				},
			};
		case SettingsActionTypes.API_KEY_DELETE_LOADING:
			return {
				...state,
				apiKeys: {
					...state.apiKeys,
					delete: {
						...state.apiKeys.delete,
						status: RemoteStatus.InProgress,
					},
					error: null,
				},
			};
		case SettingsActionTypes.RESET_API_KEYS_STATE:
			return {
				...state,
				apiKeys: {
					...state.apiKeys,
					list: {
						...state.apiKeys.list,
						data: state.apiKeys.list.data,
						status: RemoteStatus.None,
					},
					edit: {
						...state.apiKeys.edit,
						status: RemoteStatus.None,
					},
					delete: {
						...state.apiKeys.delete,
						status: RemoteStatus.None,
					},
					error: undefined,
				},
			};
		default:
			return state;
	}
};
