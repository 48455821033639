import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { DepositStatus } from '../DepositHistory';
import HistoryListItem from '../../../../components/HistoryTable/HistoryListItem/HistoryListItem';
import { DetailsModalType } from '../../../../components/Modal/DetailsModal/DetailsModal';
import statusMsg from '../../../../messages/status.messages';
import { DepositFiatHistoryItem } from '../../../../redux/DepositHistoryState/DepositHistoryTypes';
import styles from './FiatDepositHistoryList.module.scss';

const PendingCategoryDepositStatuses = [
	'NEW',
	'PENDING',
	'SUSPENDED_HIGH_RISK',
	'FROZEN',
	'PENDING_AML_VERIFICATION',
	'FROZEN_AML_VERIFICATION',
	'SUSPENDED_AML_VERIFICATION',
	'CHARGED_BACK',
];

const ConfirmedCategoryDepositStatuses = ['CONFIRMED', 'FAILED', 'PAID'];

const depositHistoryCategories = {
	[DepositStatus.PENDING]: PendingCategoryDepositStatuses,
	[DepositStatus.CONFIRMED]: ConfirmedCategoryDepositStatuses,
};

interface Props {
	data: DepositFiatHistoryItem[];
}

const FiatDepostHistoryList = ({ data }: Props) => {
	const { formatMessage } = useIntl();

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{data &&
				data.length > 0 &&
				Object.keys(depositHistoryCategories).map((c: DepositStatus) => {
					const fiatDepositData = data.filter((h: DepositFiatHistoryItem) =>
						depositHistoryCategories[c].includes(h.status)
					);

					if (fiatDepositData.length < 1) return null;
					return (
						<>
							<div className={styles.depositCategoryTitle}>
								{formatMessage(statusMsg[c])}
							</div>
							{fiatDepositData.map(
								({
									id,
									depositReference,
									created,
									depositAmount,
									receiveAmount,
									depositCurrency,
									title,
									status,
									feeAmount,
									sender,
									senderAccount,
									paymentDetails,
									originalPaymentAmount,
									originalPaymentCurrency,
									originalPaymentFee,
									originalPaymentFeeCurrency,
								}: DepositFiatHistoryItem) => {
									return (
										<HistoryListItem
											key={id}
											className={classNames({
												[styles.pendingTran]: c === DepositStatus.PENDING,
											})}
											transNo={depositReference}
											dateCreated={created}
											status={formatMessage(statusMsg[DepositStatus[status]])}
											amount={depositAmount}
											originalPaymentAmount={originalPaymentAmount}
											originalPaymentCurrency={originalPaymentCurrency}
											originalPaymentFee={originalPaymentFee}
											originalPaymentFeeCurrency={originalPaymentFeeCurrency}
											receiveAmount={receiveAmount}
											fee={feeAmount.toString()}
											sender={sender}
											senderAccount={senderAccount}
											paymentDetails={paymentDetails}
											currency={depositCurrency}
											title={title}
											detailsModalType={DetailsModalType.FIAT}
										/>
									);
								}
							)}
						</>
					);
				})}
		</>
	);
};

export default FiatDepostHistoryList;
