type Key<T> = keyof T;
type Value<T> = T[Key<T>];
type Accumulator = Record<string, unknown>;

export type Entry<T> = [key: Key<T>, value: Value<T>];
export type Entries<T> = Entry<T>[];

export function fromEntries<T extends Accumulator>(data: Entries<T>): T {
	return data.reduce<T>((acc, [key, value]) => {
		acc[key] = value;
		return acc;
	}, <T>{});
}

export function entries<T extends Accumulator>(object: T): Entries<T> {
	return Object.keys(object).map((key: Key<T>) => [key, object[key]]);
}

export function values<T extends Accumulator>(object: T): Value<T>[] {
	return entries(object).map(([_, value]) => value);
}
