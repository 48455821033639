import { useIntl } from 'react-intl';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import PageTitle from '../../Shared/PageTitle/PageTitle';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';
import StatusSelect from '../StatusSelect/StatusSelect';
import { PayoutStatus } from '../../../../redux/MerchantsState/CryptoPayouts/PayoutsData/PayoutsDataTypes';
import HistoryBlock from '../../../../components/HistoryBlock/HistoryBlock';
import { HistoryType } from '../../../../hooks/useHistoryTableData';
import PayoutsListContext from '../Contexts/PayoutsListContext';
import usePayoutsListProvider from '../Providers/usePayoutListProvider';
import styles from './PayoutsHistory.module.scss';
import useQueryParams from '../../../../hooks/useQueryParams';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

const PayoutList = () => {
	const { formatMessage } = useIntl();
	const containerRef = useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [status, setStatus] = useState<PayoutStatus[]>([]);

	useOnClickOutside(
		containerRef,
		() => {
			setIsOpen(false);
		},
		true
	);

	const [_, update] = useQueryParams({ status: '' });

	const changeFilter = (param: PayoutStatus) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		setStatus(
			status.includes(param) ? status.filter((el) => el !== param) : [...status, param]
		);
	};

	useEffect(() => {
		update({ status: status.toString() });
	}, [status, update]);

	return (
		<WhiteContainer className={styles.container}>
			<PageTitle title={formatMessage(messages.cryptoPayouts)} />
			<p className={styles.post}>{formatMessage(messages.cryptoPayoutsPost)}</p>
			<>
				<div className={styles.controls}>
					<div className={styles.status}>
						<div
							className={classNames(styles.label, { [styles.open]: isOpen })}
							onClick={() => setIsOpen(!isOpen)}
							ref={containerRef}
						>
							{formatMessage(messages.status)}
							<FontAwesomeIcon
								className={styles.icon}
								icon={faChevronDown}
								size="1x"
							/>
							{isOpen && (
								<StatusSelect
									onChange={changeFilter}
									isOpen={isOpen}
									options={status}
								/>
							)}
						</div>
					</div>
				</div>
				<ul className={styles.list}>
					<li>
						<div>{formatMessage(messages.operation)}</div>
						<div>{formatMessage(messages.status)}</div>
						<div>{formatMessage(messages.addressAndNetwork)}</div>
						<div>{formatMessage(messages.payAmount)}</div>
					</li>
					<PayoutsListContext.Provider value={{}}>
						<div className={styles.container}>
							<HistoryBlock
								historyType={HistoryType.PAYOUTS}
								id="payouts"
								dataProvider={usePayoutsListProvider}
								isDateFilterVisible={false}
							/>
						</div>
					</PayoutsListContext.Provider>
				</ul>
			</>
		</WhiteContainer>
	);
};

export default PayoutList;
