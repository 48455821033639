import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RemoteData, RemoteError } from '../../../interfaces/RemoteData';

export enum PaymentOrderStatus {
	NEW = 'NEW',
	PENDING = 'PENDING',
	PAID = 'PAID',
	FAILED = 'FAILED',
	EXPIRED = 'EXPIRED',
	TEST = 'TEST',
}

export enum PaymentOrderAccess {
	VALID = 'VALID',
	UNVERIFIED_PAYER = 'UNVERIFIED_PAYER',
	INVALID_PAYER = 'INVALID_PAYER',
}

export type PaymentOrder = {
	id: string;
	orderId: string;
	projectName: string;
	status: PaymentOrderStatus;
	validUntil: string;
	payAmount: string;
	paidAmount: string;
	payCurrencyCode: CurrencyEnum;
	payCurrencyRate: string;
	callbackURL: string | null;
	successURL: string | null;
	failureURL: string | null;
	description: string | null;
	errorCode: string | null;
	errorMessage: string | null;
	access: PaymentOrderAccess;
};

export type OrderPaymentForm = {
	walletId: string | null;
};

export type OrderPaymentState = {
	order: RemoteData<PaymentOrder>;
	form: OrderPaymentForm;
	pay: RemoteData<void>;
};

export enum OrderPaymentActionType {
	FETCH_OREDER_PENDING = 'PAYMENTS/ORDERS/FETCH_OREDER/PENDING',
	FETCH_OREDER_REJECTED = 'PAYMENTS/ORDERS/FETCH_OREDER/REJECTED',
	FETCH_OREDER_FULFILLED = 'PAYMENTS/ORDERS/FETCH_OREDER/FULFILLED',
	PAY_OREDER_PENDING = 'PAYMENTS/ORDERS/PAY_OREDER/PENDING',
	PAY_OREDER_REJECTED = 'PAYMENTS/ORDERS/PAY_OREDER/REJECTED',
	PAY_OREDER_FULFILLED = 'PAYMENTS/ORDERS/PAY_OREDER/FULFILLED',
	SET_FORM = 'PAYMENTS/ORDERS/SET_FORM',
}

export type OrderPaymentFetchOrderPendingAction = {
	type: OrderPaymentActionType.FETCH_OREDER_PENDING;
};

export type OrderPaymentFetchOrderRejectedAction = {
	type: OrderPaymentActionType.FETCH_OREDER_REJECTED;
	payload?: RemoteError;
};

export type OrderPaymentFetchOrderFulfilledAction = {
	type: OrderPaymentActionType.FETCH_OREDER_FULFILLED;
	payload: PaymentOrder;
};

export type OrderPaymentPayOrderPendingAction = {
	type: OrderPaymentActionType.PAY_OREDER_PENDING;
};

export type OrderPaymentPayOrderRejectedAction = {
	type: OrderPaymentActionType.PAY_OREDER_REJECTED;
	payload?: RemoteError;
};

export type OrderPaymentPayOrderFulfilledAction = {
	type: OrderPaymentActionType.PAY_OREDER_FULFILLED;
};

export type OrderPaymentSetFormAction = {
	type: OrderPaymentActionType.SET_FORM;
	payload: Partial<OrderPaymentForm>;
};
