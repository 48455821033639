/* eslint-disable no-param-reassign */
import {
	AccountsActionType,
	SetWalletAction,
	SetCryptoAddressAction,
	AccountStateProps,
	SetIsLoadingAction,
	SetErrorAction,
	SetUpdateWalletAction,
	SetAccountByIdAction,
	SetAccountNetworkAction,
	SetSegWitAddressAction,
} from './AccountsTypes';

const initialState: AccountStateProps = {
	wallets: null,
	isLoading: false,
	error: null,
};

export default (
	state = initialState,
	action:
		| SetWalletAction
		| SetCryptoAddressAction
		| SetIsLoadingAction
		| SetErrorAction
		| SetUpdateWalletAction
		| SetAccountByIdAction
		| SetSegWitAddressAction
		| SetAccountNetworkAction
) => {
	switch (action.type) {
		case AccountsActionType.SET_WALLET_BY_ID:
			return {
				...state,
				wallets: [
					...state?.wallets!.filter((singleAccount) => {
						if (singleAccount.id === action.data.id) {
							singleAccount.cryptoAddress = action.data.cryptoAddress;
							singleAccount.message = action.data.message;
							singleAccount.balance = action.data.balance;
							singleAccount.availableBalance = action.data.availableBalance;
							singleAccount.reservedAmount = action.data.reservedAmount;
							singleAccount.iban = action.data.iban;
						}
						return singleAccount;
					}),
				],
			};
		case AccountsActionType.SET_WALLETS:
			return {
				...state,
				wallets: action.data,
			};
		case AccountsActionType.UPDATE_WALLET:
			return {
				...state,
				wallets: [
					...state?.wallets!.filter((singleAccount) => {
						if (singleAccount.id === action.data.id) {
							// eslint-disable-next-line no-param-reassign
							singleAccount.hideIfEmpty = action.data.hideIfEmpty;
							// eslint-disable-next-line no-param-reassign
						}
						return singleAccount;
					}),
				],
			};
		case AccountsActionType.SET_ACCOUNT_NETWORK:
			return {
				...state,
				wallets: [
					...state?.wallets!.filter((singleAccount) => {
						if (singleAccount.id === action.data.id) {
							// eslint-disable-next-line no-param-reassign
							singleAccount.networkList = action.data.networkList;
							// eslint-disable-next-line no-param-reassign
						}
						return singleAccount;
					}),
				],
			};
		case AccountsActionType.SET_CRYPTO_ADDRESS:
			return {
				...state,
				wallets: [
					...state?.wallets!.filter((singleAccount) => {
						if (singleAccount.id === action.data.id) {
							// eslint-disable-next-line no-param-reassign
							singleAccount.cryptoAddress = action.data.address;
							// eslint-disable-next-line no-param-reassign
							singleAccount.message = action.data.message;
						}
						return singleAccount;
					}),
				],
			};
		case AccountsActionType.SET_SEGWIT_ADDRESS:
			return {
				...state,
				wallets: [
					...state?.wallets!.filter((singleAccount) => {
						if (singleAccount.id === action.data.id) {
							// eslint-disable-next-line no-param-reassign
							singleAccount.cryptoAddressSegWit = action.data.address;
						}
						return singleAccount;
					}),
				],
			};
		case AccountsActionType.SET_IS_LOADING:
			return {
				...state,
				isLoading: action.data,
			};
		case AccountsActionType.SET_ERROR:
			return {
				...state,
				error: action.data,
			};
		default:
			return state;
	}
};
