export const isAlpha = (str: string) => {
	return /[A-Za-z]/.test(str);
};

export const isValidCardNumber = (numb: string) => {
	const regex = /^[0-9]{13,19}$/;
	if (!regex.test(numb.replaceAll(' ', ''))) {
		return false;
	}
	return luhncheck(numb.replaceAll(' ', ''));
};
const luhncheck = (val: string) => {
	let validsum = 0;
	let k = 1;
	for (let l = val.length - 1; l >= 0; l--) {
		let calck = 0;
		calck = Number(val.charAt(l)) * k;
		if (calck > 9) {
			validsum += 1;
			calck -= 10;
		}
		validsum += calck;
		if (k === 1) {
			k = 2;
		} else {
			k = 1;
		}
	}
	return validsum % 10 === 0;
};
