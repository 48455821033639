import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import countries from 'i18n-iso-countries';
import { useLocation } from 'react-router-dom';
import queryString, { ParsedQuery } from 'query-string';
import usePrevious from '../../../hooks/usePrevious';
import { RootState } from '../../../redux/Store';
import {
	setCurrentTopupMethod,
	setMobileTopupCountry,
} from '../../../redux/WithdrawState/WithdrawActions';
import PageTitle from '../../../components/PageTitle/PageTitle';
import WithdrawOptionList, { ListType } from '../Shared/WithdrawOptionList/WithdrawOptionList';
import Loader from '../../../components/Loader/Loader';
import FilterSelect from '../Shared/FilterSelect/FilterSelect';
import noDataImgPath from '../../../images/empty.svg';
import styles from './MobileTopups.module.scss';
import withdrawMessages from '../../../redux/WithdrawState/WithdrawMessages';
import Seo from '../../../components/Seo/Seo';

const messages = defineMessages({
	title: {
		id: 'mobileTopups.title',
		defaultMessage: 'Mobile Top-ups',
	},
	noData: {
		id: 'mobileTopups.noData',
		defaultMessage: 'Mobile Top-ups is not available in your country',
	},
});

const MobileTopups = (): JSX.Element => {
	const { locale, formatMessage } = useIntl();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const parsedQuery: ParsedQuery<any> = queryString.parse(search);
	const { paymentMethods } = useSelector((state: RootState) => state.WithdrawState);
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const { topups } = paymentMethods;
	const { data, isLoaded, countryCode } = topups;
	const [country, setCountry] = useState<string>(
		parsedQuery?.country
			? `${parsedQuery?.country}/${countries
					.getName(parsedQuery?.country, 'en')
					.replace(', Province of China', '')}`
			: user?.country
			? `${countries.alpha3ToAlpha2(user?.country)}/${countries
					.getName(user?.country, 'en')
					.replace(', Province of China', '')}`
			: ''
	);
	const prevCountry = usePrevious(country);

	useEffect(() => {
		if (country !== prevCountry && countryCode !== country.split('/')[0]) {
			dispatch(setMobileTopupCountry(country.split('/')[0]));
			dispatch(setCurrentTopupMethod(countries.alpha2ToAlpha3(country.split('/')[0])));
		}
	}, [country, countryCode, data.length, dispatch, prevCountry]);

	return (
		<>
			<Seo title={withdrawMessages.metaTopupTitle} />
			<PageTitle
				title={formatMessage(messages.title)}
				historyLink={`/${locale}/withdraw/history/mobile-top-ups`}
			/>
			<FilterSelect
				className={styles.countryFilter}
				value={country}
				onChange={setCountry}
				isCountry
			/>
			{!isLoaded && <Loader className={styles.loader} />}
			{isLoaded && data?.length === 0 && (
				<div className={styles.noData}>
					<img src={noDataImgPath} alt="No data" />
					<p>{formatMessage(messages.noData)}</p>
				</div>
			)}
			{data?.length > 0 && (
				<WithdrawOptionList
					type={ListType.TOPUP}
					list={data}
					country={country.split('/')[0]}
				/>
			)}
		</>
	);
};

export default MobileTopups;
