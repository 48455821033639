import classNames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import TestIds from '../../test/TestIds';
import MerchantsOrderHistoryList from '../../pages/Merchants/MerchantsOrders/MerchantsOrdersHistory/MerchantsOrderHistoryList/MerchantsOrderHistoryList';
import Pagination from '../Pagination/Pagination';
import Loader from '../Loader/Loader';
import noTransactionsImgPath from '../../images/empty.svg';
import styles from './HistoryTable.module.scss';
import { HistoryType } from '../../hooks/useHistoryTableData';
import CardsHistoryList from '../../pages/Cards/Shared/CardsHistoryList/CardsHistoryList';
import CryptoWithdrawHistory from '../../pages/Withdraw/CryptoWithdraws/CryptoWithdrawHistory/CryptoWithdrawHistory';
import VoucherWithdrawsHistory from '../../pages/Withdraw/VoucherWithdraws/VoucherWithdrawsHistory/VoucherWithdrawsHistory';
import CryptoDepositHistoryList from '../../pages/Deposit/CryptoDeposits/CryptoDepositHistoryList/CryptoDepositHistoryList';
import GiftCardsWithdrawHistory from '../../pages/Withdraw/GiftCards/GiftCardsWithdrawHistory/GiftCardsWithdrawHistory';
import MobileTopupsHistory from '../../pages/Withdraw/MobileTopups/MobileTopupsHistory/MobileTopupsHistory';
import BankWithdrawHistory from '../../pages/Withdraw/BankWithdraw/BankWithdrawHistory/BankWithdrawHistory';
import EWalletWithdrawHistory from '../../pages/Withdraw/EWalletWithdraw/EWalletWithdrawHistory/EWalletWithdrawHistory';
import WalletHistoryList from '../../pages/WalletHistory/WalletHistoryList/WalletHistoryList';
import FiatDepostHistoryList from '../../pages/Deposit/DepositHistory/FiatDepositHistoryList/FiatDepositHistoryList';
import OpenOrdersList from '../../pages/Convert/RecurringOrder/OrderHistory/OpenOrdersList/OpenOrdersList';
import InstantHistoryList from '../../pages/Convert/InstantExchange/InstantHistory/InstantHistoryList/InstantHistoryList';
import DocumentList from '../../pages/Settings/Documents/DocumentsHistory/DocumentList/DocumentList';
import PayoutList from '../../pages/Merchants/CryptoPayouts/PayoutList/PayoutList';
import {
	CardHistoryItem,
	PaymentDirection,
	OperationType,
} from '../../redux/CardHistoryState/CardHistoryTypes';

const messages = defineMessages({
	emptyHistory: {
		id: 'historyTable.emptyHistory',
		defaultMessage: 'There are no transactions yet',
	},
});

interface HistoryTableProps {
	currencyCode?: CurrencyEnum;
	data: any;
	isLoading: boolean;
	totalCount: number;
	pageSize?: number;
	historyType: HistoryType;
}

const HistoryTable = ({
	currencyCode,
	data,
	isLoading,
	totalCount,
	pageSize,
	historyType = HistoryType.WALLET,
}: HistoryTableProps) => {
	const { formatMessage } = useIntl();

	let filteredData = data;
	if (historyType === HistoryType.CARD) {
		filteredData =
			(data as CardHistoryItem[])?.filter(
				({ operationType, direction }) =>
					operationType !== OperationType.TOP_UP_BIN &&
					!(
						operationType === OperationType.TOP_UP_RHA &&
						direction === PaymentDirection.DEBIT
					)
			) ?? [];
	}

	return (
		<div data-cy={TestIds.HistoryTable} className={styles.container}>
			{isLoading && (
				<Loader data-cy={TestIds.HistoryTableLoading} className={styles.loader} />
			)}
			{!isLoading &&
				((data && data?.length === 0) ||
					(historyType === HistoryType.CARD && filteredData?.length === 0)) && (
					<div className={styles.imgContainer} data-cy={TestIds.HistoryTableEmpty}>
						<img src={noTransactionsImgPath} alt="No transactions found" />
						<p>{formatMessage(messages.emptyHistory)}</p>
					</div>
				)}
			<ul
				className={classNames(styles.list, {
					[styles.isLoading]: isLoading,
					[styles.WALLET]: historyType === HistoryType.WALLET,
					[styles.DEPOSITS]:
						historyType === HistoryType.CRYPTO || historyType === HistoryType.FIAT,
				})}
			>
				{historyType === HistoryType.CRYPTO_WITHDRAW && data?.length > 0 && (
					<CryptoWithdrawHistory data={data} />
				)}
				{historyType === HistoryType.VOUCHERS_WITHDRAW && data?.length > 0 && (
					<VoucherWithdrawsHistory data={data} />
				)}
				{historyType === HistoryType['GIFT-CARDS_WITHDRAW'] && data?.length > 0 && (
					<GiftCardsWithdrawHistory data={data} />
				)}
				{historyType === HistoryType['MOBILE-TOP-UPS_WITHDRAW'] && data?.length > 0 && (
					<MobileTopupsHistory data={data} />
				)}
				{historyType === HistoryType['E-WALLET_WITHDRAW'] && data?.length > 0 && (
					<EWalletWithdrawHistory data={data} />
				)}
				{historyType === HistoryType.BANK_WITHDRAW && data?.length > 0 && (
					<BankWithdrawHistory data={data} />
				)}
				{historyType === HistoryType.WALLET && currencyCode && data.length > 0 && (
					<WalletHistoryList currencyCode={currencyCode} data={data} />
				)}
				{historyType === HistoryType.CRYPTO && data && data.length > 0 && (
					<CryptoDepositHistoryList data={data} />
				)}
				{historyType === HistoryType.FIAT && data && data.length > 0 && (
					<FiatDepostHistoryList data={data} />
				)}
				{historyType === HistoryType.CARD && filteredData && filteredData.length > 0 && (
					<CardsHistoryList data={filteredData} />
				)}
				{historyType === HistoryType.ORDERS && data.length > 0 && (
					<MerchantsOrderHistoryList orders={data} />
				)}
				{historyType === HistoryType.OPEN_ORDERS && data.length > 0 && (
					<OpenOrdersList data={data} />
				)}
				{historyType === HistoryType.INSTANT_EXCHANGE && data.length > 0 && (
					<InstantHistoryList data={data} />
				)}
				{historyType === HistoryType.DOCUMENTS && data.length > 0 && (
					<DocumentList data={data} />
				)}
				{historyType === HistoryType.PAYOUTS && data.length > 0 && (
					<PayoutList data={data} />
				)}
			</ul>
			<Pagination totalCount={totalCount} pageSize={pageSize} />
		</div>
	);
};

export default HistoryTable;
