export const RTLLanguages = ['ar'];

export enum LanguageActionType {
	ACTIVE_LANGUAGE = 'ACTIVE_LANGUAGE',
	SET_LANGUAGE = 'SET_LANGUAGE',
}
export interface LanguageList {
	value: string;
	label: string;
}

export interface ChangeLanguageAction {
	type: typeof LanguageActionType.SET_LANGUAGE;
	lng: string;
}
