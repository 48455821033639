import { useEffect, useState } from 'react';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';
import SvgIcon from '../../components/SvgIcon/SvgIcon';
import WithdrawMessages from '../../redux/WithdrawState/WithdrawMessages';
import Seo from '../../components/Seo/Seo';
import config from '../../configs/config';
import styles from './TransfersSidebar.module.scss';

const messages = defineMessages({
	optionsAvailable: {
		id: 'depositOptions.optionsAvailable',
		defaultMessage: '{number, plural, one {# option} other {# options}} available',
	},
	depositOptions: {
		id: 'walletdeposit.deposit_options',
		defaultMessage: 'Deposit options',
	},
	withdrawOptions: {
		id: 'walletdeposit.withdraw_options',
		defaultMessage: 'Withdraw options',
	},
	exchangeOptions: {
		id: 'walletdeposit.exchange_options',
		defaultMessage: 'Exchange options',
	},
	settingsTitle: {
		id: 'settings.title',
		defaultMessage: 'Settings',
	},
});

export enum SidebarType {
	DEPOSIT = 'DEPOSIT',
	WITHDRAW = 'WITHDRAW',
	EXCHANGE = 'EXCHANGE',
	SETTINGS = 'SETTINGS',
}

interface OptionProps {
	icon: string;
	link: string;
	name: string;
	optionsCount?: number;
	sidebarType?: SidebarType;
	isVerified?: boolean;
	showOptionsCount?: boolean;
	subtitle?: string;
	isExternalLink?: boolean;
}

interface Types {
	[key: string]: OptionProps;
}

interface TransfersSidebarProps {
	sidebarType: SidebarType;
	types: Types;
	isOptionVisibleOnZeroCount?: boolean;
}

const Option = ({
	icon,
	name,
	optionsCount,
	link,
	sidebarType,
	subtitle,
	isVerified = true,
	showOptionsCount = true,
	isExternalLink = false,
}: OptionProps): JSX.Element => {
	const { locale } = useIntl();
	const [imgSrc, setImgSrc] = useState<string>('');

	useEffect(() => {
		void import(`./images/${sidebarType}_${icon}.svg`).then((img) => setImgSrc(img.default));
	}, [icon, imgSrc, sidebarType]);

	const renderOption = () => {
		return (
			<>
				<div className={styles.icon}>
					<SvgIcon src={imgSrc} />
				</div>
				<div>
					<div className={styles.optionName}>{name}</div>
					{showOptionsCount && (
						<div className={styles.optionCount}>
							{subtitle || (
								<FormattedMessage
									{...messages.optionsAvailable}
									values={{ number: optionsCount }}
								/>
							)}
						</div>
					)}
				</div>
			</>
		);
	};

	if (isExternalLink) {
		return (
			<a
				data-cy={`${sidebarType}Option_${icon}`}
				className={classNames(styles.option, styles[sidebarType!])}
				href={link}
			>
				{renderOption()}
			</a>
		);
	}
	if (optionsCount === 0 || optionsCount === null) {
		return (
			<div
				data-cy={`${sidebarType}Option_${icon}`}
				className={classNames(styles.option, styles.disabled)}
			>
				{renderOption()}
			</div>
		);
	}
	if (!isVerified) {
		return (
			<a
				data-cy={`${sidebarType}Option_${icon}`}
				className={styles.option}
				href={`${config.PROFILE_DOMAIN}/${locale}/details`}
				target="_blank"
				rel="noreferrer"
			>
				{renderOption()}
			</a>
		);
	}
	return (
		<NavLink
			data-cy={`${sidebarType}Option_${icon}`}
			activeClassName={styles.active}
			className={classNames(styles.option, styles[sidebarType!])}
			to={link}
		>
			{renderOption()}
		</NavLink>
	);
};

const TransfersSidebar = ({
	sidebarType,
	types,
	isOptionVisibleOnZeroCount = true,
}: TransfersSidebarProps): JSX.Element | null => {
	const { pathname } = useLocation();
	const { url } = useRouteMatch();
	const { isMobile, isTablet } = useSelector((state: RootState) => state.AppState);
	const [isVisible, setIsVisible] = useState<boolean>(url === pathname);

	useEffect(() => {
		setIsVisible(url === pathname);
	}, [url, pathname]);

	if (!isVisible && (isMobile || isTablet)) return null;
	return (
		<>
			{sidebarType === SidebarType.WITHDRAW && (
				<Seo title={WithdrawMessages.metaWithdrawsTitle} />
			)}
			<div className={styles.optionsSidebar}>
				<h3 className={styles.optionsTitle}>
					{sidebarType === SidebarType.DEPOSIT && (
						<FormattedMessage {...messages.depositOptions} />
					)}
					{sidebarType === SidebarType.WITHDRAW && (
						<FormattedMessage {...messages.withdrawOptions} />
					)}
					{sidebarType === SidebarType.EXCHANGE && (
						<FormattedMessage {...messages.exchangeOptions} />
					)}
					{sidebarType === SidebarType.SETTINGS && (
						<FormattedMessage {...messages.settingsTitle} />
					)}
				</h3>
				{Object.values(types).map((props: OptionProps) => {
					// commenting out for now, will be needed in 1.86 release most likely
					// if (
					// 	(props.optionsCount === null || props.optionsCount === 0) &&
					// 	!isOptionVisibleOnZeroCount
					// )
					// 	return null;
					return (
						<Option
							{...props}
							key={props.name}
							sidebarType={sidebarType}
							isVerified={props.isVerified}
							showOptionsCount={props.showOptionsCount}
							isExternalLink={props?.isExternalLink}
						/>
					);
				})}
			</div>
		</>
	);
};

export default TransfersSidebar;
