import { addMinutes, format } from 'date-fns';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import baseMsg from '../../../../messages/base.messages';
import useCountdown from '../../../../hooks/useCountdown';
import usePrevious from '../../../../hooks/usePrevious';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import styles from './ThirdParty2FA.module.scss';
import inputErrors from '../../../../messages/inputErrors.messages';

const messages = defineMessages({
	post: {
		id: 'twoFABox.post',
		defaultMessage:
			'You should receive your authentication code via text message. Enter the code to proceed further.',
	},
	cancel: {
		id: 'twoFABox.cancel',
		defaultMessage: 'Cancel',
	},
	label: {
		id: 'twoFABox.label',
		defaultMessage: 'Authentication code',
	},
	tooShort: {
		id: 'twoFABox.tooShort',
		defaultMessage: 'Code is too short',
	},
	resend: {
		id: 'twoFABox.resend',
		defaultMessage: 'Resend',
	},
});

interface ThirdParty2FAProps {
	onSubmit: (params: string) => void;
	isLoading?: boolean;
	error?: MessageDescriptor | null;
	resend?: (props?: any) => void;
	codeLength?: number;
}

const ThirdParty2FA = ({
	onSubmit,
	isLoading,
	error = null,
	resend,
	codeLength = 6,
}: ThirdParty2FAProps) => {
	const { formatMessage } = useIntl();
	const { url } = useRouteMatch();
	const [twoFa, setTwoFa] = useState<string>('');
	const prevIsLoading = usePrevious(isLoading);
	const [inputError, setInputError] = useState<MessageDescriptor | null>(error);
	const urlsLastPathname = url.split('/')[url.split('/').length - 1];
	const [expDate, setExpDate] = useState<string | null>(null);

	const { formattedTimeLeft } = useCountdown(expDate);

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();
		if (!twoFa) return setInputError(inputErrors.cannotBeEmpty);
		if (twoFa && twoFa.length < codeLength) return setInputError(messages.tooShort);

		return onSubmit(twoFa);
	};

	const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
		setInputError(null);
		const reg = /^[0-9]*$/;
		if (reg.test(value) && value.length <= codeLength) setTwoFa(value);
	};

	useEffect(() => {
		setInputError(error);
	}, [error]);

	useEffect(() => {
		if ((!prevIsLoading && !isLoading && !error) || (prevIsLoading && !isLoading && !error)) {
			const minutesToAdd = 3;
			const currentDate = new Date();
			const futureDate = format(
				addMinutes(
					new Date(currentDate.getTime() + minutesToAdd * 60000),
					new Date(currentDate.getTime() + minutesToAdd * 60000).getTimezoneOffset()
				),
				"yyyy-MM-dd'T'HH:mm:ss.SSSSSSSSS"
			);

			setExpDate(futureDate);
		}
	}, [prevIsLoading, isLoading, error]);

	return (
		<form className={styles.container} onSubmit={handleSubmit}>
			<p className={styles.post}>{formatMessage(messages.post)}</p>
			<Input
				label={messages.label}
				value={twoFa}
				onChangeEvent={handleChange}
				inputGroupClassName={styles.input}
				errorMessage={inputError}
			>
				{typeof resend === 'function' && (
					// eslint-disable-next-line react/jsx-no-useless-fragment
					<>
						{formattedTimeLeft === '00:00' ? (
							<div className={styles.resend} onClick={() => resend()}>
								{formatMessage(messages.resend)}
							</div>
						) : (
							<div className={styles.resend}>{formattedTimeLeft}</div>
						)}
					</>
				)}
			</Input>
			<Button
				className={styles.button}
				buttonStyle={ButtonStyle.PRIMARY}
				text={baseMsg.confirm}
				type={ButtonType.SUBMIT}
				isDisabled={isLoading}
				isLoading={isLoading}
			/>
			<Button
				className={classNames(styles.button, { [styles.hidden]: isLoading })}
				buttonStyle={ButtonStyle.BORDERLESS}
				text={messages.cancel}
				type={ButtonType.LINK}
				link={url.replace(urlsLastPathname, 'history')}
			/>
		</form>
	);
};

export default ThirdParty2FA;
