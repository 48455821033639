import { FC } from 'react';
import { Route, Switch } from 'react-router';
import useMerchantRoutes, { MerchantRoutes } from '../../../hooks/useMerchantRoutes';
import MerchantsProjectList from './MerchantsProjectList/MerchantsProjectList';
import NewMerchantsProject from './NewMerchantsProject/NewMerchantsProject';
import ViewMerchantsProject from './ViewMerchantsProject/ViewMerchantsProject';
import EditMerchantsProject from './EditMerchantsProject/EditMerchantsProject';
import MerchantOrders from '../MerchantsOrders/MerchantOrders';
import MerchantPreOrders from '../MerchantsPreOrders/MerchantPreOrders';
import MerchantsCallbacks from '../MerchantsCallbacks/MerchantsCallbacks';
import MerchantsTransactions from '../MerchantsTransactions/MerchantsTransactions';

const MerchantProject: FC = () => {
	const { getPath } = useMerchantRoutes();

	return (
		<>
			<Route
				exact
				path={getPath(MerchantRoutes.ProjectRoot)}
				component={ViewMerchantsProject}
			/>
			<Route
				exact
				path={getPath(MerchantRoutes.ProjectEdit)}
				component={EditMerchantsProject}
			/>
		</>
	);
};

const MerchantProjects: FC = () => {
	const { getPath } = useMerchantRoutes();

	return (
		<Switch>
			<Route
				exact
				path={getPath(MerchantRoutes.ProjectsList)}
				component={MerchantsProjectList}
			/>
			<Route
				exact
				path={getPath(MerchantRoutes.ProjectCreate)}
				component={NewMerchantsProject}
			/>
			<Route path={getPath(MerchantRoutes.CallbacksRoot)} component={MerchantsCallbacks} />
			<Route
				path={getPath(MerchantRoutes.TransactionsRoot)}
				component={MerchantsTransactions}
			/>
			<Route path={getPath(MerchantRoutes.OrdersRoot)} component={MerchantOrders} />
			<Route path={getPath(MerchantRoutes.PreOrdersRoot)} component={MerchantPreOrders} />
			<Route path={getPath(MerchantRoutes.ProjectRoot)} component={MerchantProject} />
		</Switch>
	);
};

export default MerchantProjects;
