import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useIntl, MessageDescriptor } from 'react-intl';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';
import { useId } from 'react';
import styles from './DatePicker.module.scss';
import TestIds, { formatTestId } from '../../test/TestIds';
import Tooltip from '../Tooltip/Tooltip';

interface Props<T extends boolean> extends ReactDatePickerProps<'', T> {
	label?: MessageDescriptor;
	tooltipContent?: MessageDescriptor;
	className?: string;
	labelClassName?: string;
}

const Day = (day: number) => {
	return (
		<span data-cy={formatTestId(TestIds.DatePickerDay_0, `${day}`)} className={styles.day}>
			{day}
		</span>
	);
};

export default <T extends boolean>({
	label,
	tooltipContent,
	className,
	labelClassName,
	...restProps
}: Props<T>) => {
	const { formatMessage } = useIntl();
	const inputId = useId();

	return (
		<div className={styles.datepicker} data-cy={TestIds.DatePicker}>
			{label && (
				<label className={classNames(styles.label, labelClassName)} htmlFor={inputId}>
					{formatMessage(label)}
					{tooltipContent && (
						<Tooltip
							infoIconVisible
							content={
								<div className={styles.tooltip}>
									{formatMessage(tooltipContent)}
								</div>
							}
						/>
					)}
				</label>
			)}
			<div className={classNames(styles.container, className)}>
				<DatePicker
					id={inputId}
					autoComplete="off"
					fixedHeight
					dateFormat="yyyy-MM-dd"
					formatWeekDay={(name) => name.slice(0, 1)}
					popperPlacement="bottom-start"
					isClearable
					placeholderText={restProps.placeholderText}
					className={styles.datePicker}
					popperClassName={styles.popper}
					renderDayContents={Day}
					{...restProps}
				/>
			</div>
		</div>
	);
};
