import { SigninResponse } from 'oidc-client-ts';

const OidcScope = 'openid';

// auth server returns scope as array instead of string because of reasons
// lib crashes because of that, quick fix

Object.defineProperty(SigninResponse.prototype, 'isOpenId', {
	get(): boolean {
		return this.scope.includes(OidcScope) || !!this.id_token;
	},
});
