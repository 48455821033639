import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import classNames from 'classnames';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RootState } from '../../../../redux/Store';
import { getShippingOptionsURL } from '../../../../redux/endpoints';
import { formatPrecision, toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import { setFormData, setOrderStep } from '../../../../redux/CardsState/CardsActions';
import {
	CardType,
	OrderSteps,
	ProductOption,
	ShippingType,
} from '../../../../redux/CardsState/CardsTypes';
import Loader from '../../../../components/Loader/Loader';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import BackButton from '../../../../components/BackButton/BackButton';
import arrowsImgPath from '../../../../images/expand_triangle.svg';
import mailImgPath from './images/mail.svg';
import regMailImgPath from './images/registeredMail.svg';
import courierImgPath from './images/courier.svg';
import styles from './Shipping.module.scss';
import inputErrors from '../../../../messages/inputErrors.messages';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	title: {
		id: 'shipping.title',
		defaultMessage: 'Shipping',
	},
	subTitle: {
		id: 'shipping.subTitle',
		defaultMessage: 'Choose your preferred card delivery type',
	},
	mail: {
		id: 'shipping.mail',
		defaultMessage: 'Mail',
	},
	free: {
		id: 'shipping.free',
		defaultMessage: 'Free',
	},
	registeredMail: {
		id: 'shipping.registeredMail',
		defaultMessage: 'Registered mail',
	},
	courier: {
		id: 'shipping.courier',
		defaultMessage: 'Courier',
	},
	price: {
		id: 'shipping.price',
		defaultMessage: 'Shipping: {price}',
	},
});

interface ShipmentOptionsProps {
	price: string;
	type: ShippingType;
}

interface ShippingProps {
	isRha?: boolean;
}

const Shipping = ({ isRha }: ShippingProps) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { productOptions } = useSelector((state: RootState) => state.CardsState);
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const eurWallet = wallets?.find((o: any) => {
		return o.currencyCode === CurrencyEnum.EUR;
	});
	const [shippmentOptions, setShippmentOptions] = useState<ShipmentOptionsProps[]>([]);
	const cardForOrder = productOptions?.find(
		(o: ProductOption) => CardType[o.type] === CardType.PLASTIC
	);

	const onSubmit = (option: ShippingType, price: string) => {
		dispatch(
			setFormData({
				shipping: { option, price },
			})
		);

		const nextStep = isRha ? OrderSteps.LINKED_WALLETS : OrderSteps.CONFIRM;
		return dispatch(setOrderStep(nextStep));
	};

	useEffectOnce(() => {
		void axiosInstance.get(getShippingOptionsURL(isRha)).then(({ data }) => {
			return setShippmentOptions(data);
		});
	});

	return (
		<div className={styles.container}>
			<BackButton
				className={styles.back}
				onClick={() => dispatch(setOrderStep(OrderSteps.DELIVERY_ADDRESS))}
			/>
			<InfoHead title={messages.title} subTitle={formatMessage(messages.subTitle)} />
			{shippmentOptions?.length === 0 ? (
				<Loader />
			) : (
				<ul className={styles.shippingOptions}>
					{shippmentOptions?.map(({ type, price }: ShipmentOptionsProps) => {
						const isEnoughMoney =
							eurWallet &&
							toDecimal(eurWallet?.availableBalance).greaterThanOrEqualTo(
								toDecimal(cardForOrder?.price || 0).add(toDecimal(price))
							);
						return (
							<li key={type}>
								<a
									onClick={() => onSubmit(type, price)}
									className={classNames({
										[styles.disabled]: !isEnoughMoney,
									})}
								>
									{type === ShippingType.POST && (
										<img className={styles.icon} src={mailImgPath} />
									)}
									{type === ShippingType.REGISTERED_POST && (
										<img className={styles.icon} src={regMailImgPath} />
									)}
									{type === ShippingType.COURIER && (
										<img className={styles.icon} src={courierImgPath} />
									)}
									<span className={styles.textBox}>
										<span className={styles.title}>
											{type === ShippingType.POST && (
												<FormattedMessage {...messages.mail} />
											)}
											{type === ShippingType.REGISTERED_POST && (
												<FormattedMessage {...messages.registeredMail} />
											)}
											{type === ShippingType.COURIER && (
												<FormattedMessage {...messages.courier} />
											)}
										</span>
										<span className={styles.price}>
											{toDecimal(price).equals(0) ? (
												<FormattedMessage {...messages.free} />
											) : (
												<FormattedMessage
													{...messages.price}
													values={{
														price: (
															<b className={styles.bold}>
																{formatPrecision(
																	price,
																	CurrencyEnum.EUR
																)}{' '}
																EUR
															</b>
														),
													}}
												/>
											)}
										</span>
										{!isEnoughMoney && (
											<span className={styles.error}>
												<FormattedMessage
													{...inputErrors.insufficientFunds}
												/>
											</span>
										)}
									</span>
									<img
										className={styles.arrow}
										src={arrowsImgPath}
										alt="Arrow icon"
									/>
								</a>
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
};

export default Shipping;
