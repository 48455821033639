import { ChangeEvent, FC, PropsWithChildren, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import { useSelector, useDispatch, Provider } from 'react-redux';
import { CurrencyEnum, CurrencyIcon } from '@spectrocoin/sc-currencies';
import PaymentMethod, { ProviderType } from '../../../../redux/DepositState/DepositTypes';
import SelectOption from '../../../../interfaces/SelectOption';
import { RootState } from '../../../../redux/Store';
import { UserCompany } from '../../../../redux/ProfileState/ProfileTypes';
import { IBANStatus, Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import Select from '../../../../components/Select/Select';
import CurrencyInput from '../../../../components/CurrencyInput/CurrencyInput';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import styles from './BankDepositDetails.module.scss';
import Loader from '../../../../components/Loader/Loader';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import CopyButton from '../../../../components/CopyButton/CopyButton';
import {
	getPaymentMethodDetailsURL,
	depositCalculateReceiveAmountURL,
	bankDepositURL,
	getPaymentMethodAdditionalInfoURL,
	getAddNewAccountURL,
} from '../../../../redux/endpoints';
import useDebounce from '../../../../hooks/useDebounce';
import Accordion from '../../../../components/Accordion/Accordion';
import { formatPrecision, toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import { isNumeric } from '../../../../helpers/globalFunctionHelpers/globalFunctionHelpers';
import depositMessages from '../../../../redux/DepositState/DepositMessages';
import inputStyles from '../../../../components/Input/Input.module.scss';
import errorMessages, {
	ErrorResponse,
} from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { toPlainAmount } from '../../../../helpers/currencyAmountHelper/currencyAmountHelper';
import baseMsg from '../../../../messages/base.messages';
import inputErrors from '../../../../messages/inputErrors.messages';
import Seo from '../../../../components/Seo/Seo';
import TestIds, { formatTestId } from '../../../../test/TestIds';
import { toggleStartingBlock } from '../../../../redux/AppState/AppActions';
import useFormatAmount from '../../../../hooks/useFormatAmount';
import TransfersFormLayout from '../../../../layout/TransfersFormLayout/TransfersFormLayout';
import { HowToType } from '../../../../layout/TransfersFormLayout/HowTo/HowTo';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	depositWallet: {
		id: 'base.depositWallet',
		defaultMessage: 'Deposit wallet',
	},
	shareDetailsViaEmail: {
		id: 'base.share_details_via_email',
		defaultMessage: 'Share details via email',
	},
	copyAllDetails: {
		id: 'base.copy_all_details',
		defaultMessage: 'Copy all details',
	},
	done: {
		id: 'base.done',
		defaultMessage: 'Done',
	},
	copy: {
		id: 'base.copy',
		defaultMessage: 'Copy',
	},
	depositWith: {
		id: 'walletdeposit.withTitle',
		defaultMessage: 'Deposit with {provider}',
	},
	depositAmount: {
		id: 'base.deposit_amount',
		defaultMessage: 'Deposit amount',
	},
	next: {
		id: 'base.next',
		defaultMessage: 'Next',
	},
	amount: {
		id: 'base.amount',
		defaultMessage: 'Amount',
	},
	name: {
		id: 'base.name',
		defaultMessage: 'Name',
	},
	iban: {
		id: 'base.iban',
		defaultMessage: 'IBAN',
	},
	goToWallet: {
		id: 'base.go_to_wallet',
		defaultMessage: 'Go to wallet',
	},
	institutionNameLabel: {
		id: 'base.institution_name',
		defaultMessage: 'Institution Name',
	},
	institutionName: {
		id: 'base.institution_name_value',
		defaultMessage: 'UAB Pervesk',
	},
	institutionAddressLabel: {
		id: 'base.institution_address',
		defaultMessage: 'Institution Address',
	},
	institutionBICLabel: {
		id: 'base.institution_bic_label',
		defaultMessage: 'Institution BIC code',
	},
	bankAddressLabel: {
		id: 'base.bank_address',
		defaultMessage: 'Bank Address',
	},
	institutionAddress: {
		id: 'base.institution_address_value',
		defaultMessage: 'Aušros al. 68, LT-76233 Šiauliai, Lietuva',
	},
	institutionAccountNumber: {
		id: 'base.institution_account_number',
		defaultMessage: 'LT273550020000000005',
	},
	institutionAccountNumberEE: {
		id: 'base.institution_account_number_ee',
		defaultMessage: 'LT783550020000002676',
	},
	institutionAccountNumberSFBVI: {
		id: 'base.institution_account_number_sf_bvi',
		defaultMessage: 'LT793550020000002658',
	},
	institutionSwiftLabel: {
		id: 'base.swift_code',
		defaultMessage: 'SWIFT CODE',
	},
	institutionSwift: {
		id: 'base.swift_code_value',
		defaultMessage: 'UAPELT21XXX',
	},
	depositError: {
		id: 'base.deposit_error',
		defaultMessage: 'Deposit failed',
	},
	unexpectedError: {
		id: 'scvalidationexception.validate.unexpected_error.key',
		defaultMessage: 'Unexpected error occurred.',
	},
	requestNumber: {
		id: 'base.request_number',
		defaultMessage: 'Request number',
	},
	accountNumber: {
		id: 'base.account_number',
		defaultMessage: 'Account number',
	},
	sortCode: {
		id: 'base.sort_code',
		defaultMessage: 'Sort code',
	},
	paymentInstructions: {
		id: 'base.memo_details_payment_instructions',
		defaultMessage: 'Memo/details/payment instructions',
	},
	clickOnTheBankDetails: {
		id: 'base.clickOnTheBankDetails',
		defaultMessage: 'Click on the bank name below to view payment details',
	},
	bankDetailsInstructions: {
		id: 'base.bankDetailsInstructions',
		defaultMessage:
			'If you can not find your bank below, you can still transfer from any other bank to any of these',
	},
	orderWithId: {
		id: 'base.orderWithId',
		defaultMessage: 'order {orderId}',
	},
	userCompanySFBVI: { id: 'base.userCompany.SFBVI', defaultMessage: 'Spectro Finance Limited' },
	userCompanyEE: { id: 'base.userComapny.EE', defaultMessage: 'Spectro Finance OÜ' },
	userCompanyLT: { id: 'base.userCompany.LT', defaultMessage: 'UAB Spectro Finance' },
	correspondentBankNameLabel: {
		id: 'ibanBlock.correspondentBankNameLabel',
		defaultMessage: 'Correspondent bank name',
	},
	correspondentBankAddressLabel: {
		id: 'ibanBlock.correspondentBankAddressLabel',
		defaultMessage: 'Correspondent bank address',
	},
	correspondentBankSwiftLabel: {
		id: 'ibanBlock.correspondentBankSwiftLabel',
		defaultMessage: 'Correspondent bank SWIFT/BIC',
	},
	correspondentBankNameValue: {
		id: 'ibanBlock.correspondentBankNameValue',
		defaultMessage: 'INCORE BANK AG',
	},
	correspondentBankAddressValue: {
		id: 'ibanBlock.correspondentBankAddressValue',
		defaultMessage: 'Wiesenstrasse 17, Zürich, Switzerland',
	},
	correspondentBankSwiftValue: {
		id: 'ibanBlock.correspondentBankSwiftValue',
		defaultMessage: 'INCOCHZZ',
	},
});

enum LegacyNonErrorErrors {
	SEPA_DEPOSIT_WITH_IBAN = 'DP_3',
}

interface FormState {
	depositAmount?: string;
	depositCurrencyCode?: string;
	receiveAmount: string | number;
	receiveCurrencyCode?: string;
}

interface FormErrorState {
	depositAmount?: MessageDescriptor | null;
	receiveAmount?: MessageDescriptor | null;
}

interface DepositCalculateResponse {
	payAmount: string;
	payCurrencyCode: string;
	receiveAmount: number;
	receiveCurrencyCode: string;
}

interface ProcessPaymentParams {
	depositCurrency: string;
	paymentMethodId: string;
	providerType: ProviderType | undefined;
	receiveAccountId: string;
	receiveAmount: string | number;
}

interface Params {
	paymentMethodId: string;
}

const currenciesSortPriority = {
	[CurrencyEnum.EUR]: 3,
	[CurrencyEnum.USD]: 2,
	[CurrencyEnum.GBP]: 1,
};

const BankDepositDetails = () => {
	const { formatMessage, locale } = useIntl();
	const { paymentMethodId } = useParams<Params>();
	const amountFormatter = useFormatAmount();
	const [isLoading, setIsLoading] = useState(true);
	const [paymentMethodDetails, setPaymentMethodDetails] = useState<PaymentMethod>();
	const [walletOptions, setWalletOptions] = useState<SelectOption[]>([]);
	const [errorMessage, setErrorMessage] = useState<string | null>();
	const [formState, setFormState] = useState<FormState>({
		depositAmount: '',
		depositCurrencyCode: '',
		receiveAmount: '0.00',
		receiveCurrencyCode: '',
	});
	const [formErrorState, setFormErrorState] = useState<FormErrorState>({
		depositAmount: null,
		receiveAmount: null,
	});
	const [orderId, setOrderId] = useState<string>();
	const [isDetailsViewVisible, setIsDetailsViewVisible] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { user: profile, company: userCompany } = useSelector(
		(state: RootState) => state.ProfileState
	);

	const { wallets: accounts, isLoading: isWalletLoading } = useSelector(
		(state: RootState) => state.AccountsState
	);
	const iban = accounts?.find((wallet: Wallet) => wallet.currencyCode === CurrencyEnum.EUR)
		?.ibanInfo;

	const { depositAmount, depositCurrencyCode, receiveAmount, receiveCurrencyCode } = formState;

	const debouncedDepositAmount = useDebounce(depositAmount, 1000);

	const handleDepositFailure = ({ errorCode }: ErrorResponse) => {
		setIsButtonDisabled(false);
		setErrorMessage(formatMessage(errorMessages[errorCode] || messages.unexpectedError));
	};

	const createPaymentRequest = (accountId: string) => {
		const params: ProcessPaymentParams = {
			depositCurrency: depositCurrencyCode!.toUpperCase(),
			paymentMethodId,
			providerType: paymentMethodDetails?.providerType,
			receiveAccountId: accountId,
			receiveAmount,
		};

		switch (paymentMethodDetails?.providerType) {
			case ProviderType.LOCAL:
			case ProviderType.SEPA:
			case ProviderType.WIRE: {
				return axiosInstance
					.post(bankDepositURL(), params)
					.then(({ data: userDepositId }) => {
						setErrorMessage(null);
						if (userDepositId) {
							setOrderId(userDepositId.id);
						}
						setIsDetailsViewVisible(true);
					})
					.catch((e) => {
						switch (e.response?.data.errorCode) {
							// this is supposed to be handled as a success case for some reason
							case LegacyNonErrorErrors.SEPA_DEPOSIT_WITH_IBAN:
								return setIsDetailsViewVisible(true);
							default:
								return handleDepositFailure(e.response?.data);
						}
					});
			}
			default: {
				return axiosInstance
					.post(bankDepositURL(), params)
					.then(({ data: location }: any) => {
						window.location.href = location;
					})
					.catch((e) => handleDepositFailure(e.response.data));
			}
		}
	};

	const processPayment = () => {
		const account = accounts?.find(({ currencyCode }) => currencyCode === receiveCurrencyCode);

		if (!account) {
			return axiosInstance
				.post(getAddNewAccountURL(), { currencyCode: receiveCurrencyCode, label: '' })
				.then(({ data }: AxiosResponse<Wallet>) => {
					return createPaymentRequest(data.id);
				})
				.catch((e) => handleDepositFailure(e.response.data));
		}

		return createPaymentRequest(account.id);
	};

	const handleSelectChange = ({ value }: SelectOption) =>
		setFormState((prevState: FormState) => ({
			...prevState,
			depositCurrencyCode: value,
			receiveCurrencyCode: value,
		}));

	const handleInputChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
		setFormErrorState((prevState: FormErrorState) => ({
			...prevState,
			[name]: null,
		}));
		setFormState((prevState: FormState) => ({
			...prevState,
			depositAmount: value,
		}));
	};

	useEffect(() => {
		setIsLoading(true);
		void axiosInstance
			.get<PaymentMethod>(getPaymentMethodDetailsURL(paymentMethodId))
			.then(({ data: paymentMethod }) => {
				if (!paymentMethod || !userCompany || !profile) return;

				const options = paymentMethod.paymentMethodCurrencies
					.map((currencyCode: CurrencyEnum) => ({
						label: (
							<div className={styles.option}>
								<CurrencyIcon
									currencyType={currencyCode}
									className={styles.currencyIcon}
								/>
								<label>{currencyCode}</label>
							</div>
						),
						value: currencyCode,
					}))
					.sort(
						({ value: a }, { value: b }) =>
							(currenciesSortPriority[b] || 0) - (currenciesSortPriority[a] || 0) ||
							a.localeCompare(b, undefined, {
								numeric: true,
								sensitivity: 'base',
							})
					);

				setWalletOptions(options);
				setPaymentMethodDetails(paymentMethod);
				setFormState((prevState: FormState) => ({
					...prevState,
					receiveCurrencyCode: options[0].value,
					depositCurrencyCode: options[0].value,
				}));
				setIsLoading(false);
			});
	}, [formatMessage, profile, userCompany, paymentMethodId]);

	useEffect(() => {
		if (!isLoading && debouncedDepositAmount && isNumeric(debouncedDepositAmount)) {
			void axiosInstance
				.post<DepositCalculateResponse>(depositCalculateReceiveAmountURL(), {
					payAmount: toPlainAmount(debouncedDepositAmount, true),
					payCurrencyCode: depositCurrencyCode,
					paymentMethodId,
					receiveCurrencyCode,
				})
				.then(({ data }) => {
					setFormState((prevState: FormState) => ({
						...prevState,
						receiveAmount: toDecimal(data.receiveAmount).toFixed(),
						receiveCurrencyCode: data.receiveCurrencyCode,
					}));
				});
		}
	}, [
		debouncedDepositAmount,
		depositCurrencyCode,
		isLoading,
		paymentMethodId,
		receiveCurrencyCode,
	]);

	useEffect(() => {
		dispatch(toggleStartingBlock(false));

		return () => {
			dispatch(toggleStartingBlock(true));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isLoading || isWalletLoading || !paymentMethodDetails)
		return <Loader className={styles.loader} />;

	const { isIBAN, maxAmount, minAmount } = paymentMethodDetails!;

	const validateForm = () => {
		if (!depositAmount || !receiveAmount) return true;
		let valid = true;
		let errorList = {};
		Object.keys(formState).forEach((item: any) => {
			if (!formState[item]) {
				errorList = {
					...errorList,
					[item]: inputErrors.cannotBeEmpty,
				};
				valid = false;
			}
		});
		if (!isNumeric(depositAmount)) {
			errorList = {
				...errorList,
				depositAmount: formatMessage(depositMessages.errorDepositAmountIsNumber),
			};
			valid = false;
		} else if (toDecimal(depositAmount, true).lessThanOrEqualTo(toDecimal(0))) {
			errorList = {
				...errorList,
				depositAmount: formatMessage(depositMessages.errorDepositAmountGreaterThanZero),
			};
			valid = false;
		} else if (
			minAmount &&
			toDecimal(receiveAmount.toString()).lessThan(toDecimal(minAmount.toString()))
		) {
			errorList = {
				...errorList,
				receiveAmount: formatMessage(depositMessages.errorDepositAmountBelowMin, {
					minAmount: toDecimal(minAmount.toString()).toFixed(),
				}),
			};
			valid = false;
		} else if (
			maxAmount &&
			toDecimal(receiveAmount).greaterThan(toDecimal(maxAmount.toString()))
		) {
			errorList = {
				...errorList,
				receiveAmount: formatMessage(depositMessages.errorDepositAmountAboveMax, {
					maxAmount: toDecimal(maxAmount.toString()).toFixed(),
				}),
			};
			valid = false;
		}

		setIsButtonDisabled(valid);
		setFormErrorState({
			...formErrorState,
			...errorList,
		});
		return valid;
	};

	const handleButtonClick = (e: any) => {
		e.preventDefault();
		if (validateForm()) return processPayment();
		return null;
	};

	return (
		<div data-cy={TestIds.bankDepositsDetailsView}>
			<Seo title={depositMessages.metaBankDepositsTitle} />
			<PageTitle
				title={formatMessage(messages.depositWith, {
					provider: paymentMethodDetails?.title,
				})}
				previousPageLink={`/${locale}/deposit/bank`}
				isPreviousPageLinkVisibleOnDesktop
				isPreviousPageLinkVisibleOnMobile={false}
			/>
			<TransfersFormLayout
				method={paymentMethodDetails}
				type={HowToType.DEPOSIT}
				title={paymentMethodDetails?.title}
				providerType={paymentMethodDetails?.providerType}
			>
				<div className={styles.form}>
					{isDetailsViewVisible ? (
						iban && isIBAN && iban.status === IBANStatus.ACTIVE ? (
							<IbanDetailsView
								accountName={profile?.beneficiaryName as string}
								accountNumber={iban?.iban}
								amount={formState.depositAmount!}
								currencyCode={formState.depositCurrencyCode!}
								providerType={paymentMethodDetails?.providerType}
							/>
						) : (
							<BankDetailsView
								accountName={profile?.beneficiaryName as string}
								currencyCode={formState.depositCurrencyCode!}
								amount={formState.depositAmount!}
								paymentMethodId={paymentMethodDetails?.id}
								orderId={orderId as string}
								providerType={paymentMethodDetails?.providerType}
								userCompany={userCompany as UserCompany}
							/>
						)
					) : (
						<div className={styles.column}>
							{errorMessage && (
								<NotificationMessage
									withIcon
									type={NotificationType.Error}
									style={NotificationStyle.Border}
									message={errorMessage}
								/>
							)}
							<Select
								className={styles.depositWalletSelect}
								labelClassName={inputStyles.label}
								id="wallet"
								label={formatMessage(messages.depositWallet)}
								name="wallet"
								onChangeObject={handleSelectChange}
								options={walletOptions}
								value={receiveCurrencyCode || ''}
							/>
							<CurrencyInput
								id="depositAmount"
								labelClassName={inputStyles.label}
								inputContainerClassName={styles.currencyInputContainer}
								currencyCode={CurrencyEnum[depositCurrencyCode!]}
								label={messages.depositAmount}
								onChange={handleInputChange}
								value={depositAmount as string}
								error={formErrorState.depositAmount}
							/>
							<div data-cy={TestIds.receiveAmount} className={styles.receiveAmount}>
								<div className={classNames(inputStyles.label)}>
									<FormattedMessage {...baseMsg.receiveAmount} />
								</div>
								<div className={styles.value} data-cy={TestIds.receiveAmountValue}>
									{amountFormatter(
										formatPrecision(receiveAmount, CurrencyEnum.EUR)
									)}{' '}
									{receiveCurrencyCode}
								</div>
								{formErrorState.receiveAmount &&
									typeof formErrorState.receiveAmount === 'string' && (
										<div
											className={classNames(
												inputStyles.error,
												styles.receiveError
											)}
											data-cy={TestIds.receiveAmountError}
										>
											{formErrorState.receiveAmount}
										</div>
									)}
							</div>
							<Button
								buttonStyle={ButtonStyle.PRIMARY}
								className={styles.nextButton}
								isDisabled={
									toDecimal(receiveAmount || 0).lessThanOrEqualTo(0) ||
									isButtonDisabled ||
									!depositAmount
								}
								onClick={handleButtonClick}
								text={formatMessage(messages.next)}
								type={ButtonType.BUTTON}
								data-cy={TestIds.button}
							/>
						</div>
					)}
				</div>
			</TransfersFormLayout>
		</div>
	);
};

interface ContainerProps {
	details: string;
}

const Container: FC<PropsWithChildren<ContainerProps>> = ({ details, children }) => {
	const { formatMessage, locale } = useIntl();
	return (
		<div className={styles.column}>
			{children}
			<Button
				buttonStyle={ButtonStyle.PRIMARY}
				className={styles.doneButton}
				link={`/${locale}/account`}
				text={formatMessage(messages.done)}
				type={ButtonType.LINK}
				data-cy={TestIds.button}
			/>
			<div className={styles.shareLinkWrapper}>
				<a
					className={styles.shareLink}
					href={`mailto:?body=${encodeURIComponent(details)}`}
					data-cy={TestIds.bankShareDetailsLink}
				>
					<FormattedMessage {...messages.shareDetailsViaEmail} />
				</a>
			</div>
			<CopyButton
				className={classNames(styles.copyButton, styles.copyAllDetails)}
				text={messages.copyAllDetails}
				dataToCopy={details}
			/>
		</div>
	);
};

interface ItemProps {
	label: MessageDescriptor;
	value: string | MessageDescriptor;
	valueToCopy?: string | null;
	isCopyButtonEnabled?: boolean;
}

const Item = ({ label, value, isCopyButtonEnabled = false, valueToCopy = null }: ItemProps) => (
	<div className={styles.item}>
		<div className={styles.copyButtonWrapper}>
			<div className={styles.copyButtonLabel}>
				<FormattedMessage {...label} />
			</div>
			{isCopyButtonEnabled && (
				<CopyButton
					className={styles.copyButton}
					text={messages.copy}
					dataToCopy={valueToCopy || (value as string)}
				/>
			)}
		</div>
		<div
			className={styles.copyButtonValue}
			data-cy={formatTestId(TestIds.bankDetailsItem, label.id as string)}
		>
			{!value || typeof value === 'string' ? value : <FormattedMessage {...value} />}
		</div>
	</div>
);

export default BankDepositDetails;

interface IbanDetailsViewProps {
	accountName: string;
	amount: string;
	accountNumber: string;
	currencyCode: string;
	providerType: string;
}

const IbanDetailsView = ({
	accountName,
	amount,
	accountNumber,
	currencyCode,
	providerType,
}: IbanDetailsViewProps) => {
	const { formatMessage } = useIntl();
	const amountFormatter = useFormatAmount();

	const getAllDetailsAsString = () => {
		let detailsString = '';
		detailsString += `${formatMessage(messages.amount)}: ${amountFormatter(
			formatPrecision(amount, CurrencyEnum.EUR, undefined, true)
		)} ${currencyCode} \n`;
		detailsString += `${formatMessage(baseMsg.receiver)}: ${accountName} \n`;
		detailsString += `${formatMessage(messages.iban)}: ${accountNumber} \n`;

		detailsString += `${formatMessage(messages.institutionSwiftLabel)}: ${formatMessage(
			messages.institutionSwift
		)} \n`;

		detailsString += `${formatMessage(messages.institutionNameLabel)}: ${formatMessage(
			messages.institutionName
		)} \n`;

		detailsString += `${formatMessage(messages.institutionAddressLabel)}: ${formatMessage(
			messages.institutionAddress
		)}`;

		if (providerType !== ProviderType.SEPA) {
			detailsString += `\n${formatMessage(
				messages.correspondentBankNameLabel
			)}: ${formatMessage(messages.correspondentBankNameValue)} \n`;

			detailsString += `${formatMessage(
				messages.correspondentBankAddressLabel
			)}: ${formatMessage(messages.correspondentBankAddressValue)} \n`;

			detailsString += `${formatMessage(
				messages.correspondentBankSwiftLabel
			)}: ${formatMessage(messages.correspondentBankSwiftValue)} \n`;
		}

		return detailsString;
	};

	const details = getAllDetailsAsString();
	return (
		<Container details={details}>
			<Item
				label={messages.amount}
				value={`${amountFormatter(
					formatPrecision(amount, CurrencyEnum.EUR, undefined, true)
				)} ${currencyCode}`}
			/>
			<Item label={baseMsg.receiver} value={accountName} />
			<Item label={messages.iban} value={accountNumber} isCopyButtonEnabled />
			<Item label={messages.institutionNameLabel} value={messages.institutionName} />
			<Item label={messages.institutionAddressLabel} value={messages.institutionAddress} />
			<Item label={messages.institutionSwiftLabel} value={messages.institutionSwift} />
			{providerType !== ProviderType.SEPA && (
				<>
					<Item
						label={messages.correspondentBankNameLabel}
						value={messages.correspondentBankNameValue}
					/>
					<Item
						label={messages.correspondentBankAddressLabel}
						value={messages.correspondentBankAddressValue}
					/>
					<Item
						label={messages.correspondentBankSwiftLabel}
						value={messages.correspondentBankSwiftValue}
					/>
				</>
			)}
		</Container>
	);
};

interface BankDetailsViewProps {
	accountName: string;
	amount: string;
	currencyCode: string;
	paymentMethodId: string;
	orderId: string;
	providerType?: string;
	userCompany: UserCompany;
}

interface BankDetailsInterface {
	title: string;
	receiver: string;
	receiverAccountNumber: string;
	receiverSWIFT: string;
	receiverAddress: string;
	correspondentBankName?: string;
	correspondentBIC?: string;
	correspondentAddress?: string;
	sortCode?: string;
	amount: string;
	paymentInstructions: string;
}

const BankDetailsView = ({
	accountName,
	amount,
	currencyCode,
	paymentMethodId,
	orderId,
	providerType,
	userCompany,
}: BankDetailsViewProps) => {
	const { formatMessage } = useIntl();
	const [bankDetails, setBankDetails] = useState<BankDetailsInterface[]>([]);
	const amountFormatter = useFormatAmount();

	useEffect(() => {
		if (providerType !== ProviderType.SEPA) {
			void axiosInstance
				.get(getPaymentMethodAdditionalInfoURL(paymentMethodId))
				.then(({ data }) => setBankDetails(JSON.parse(data)));
		}
	}, [paymentMethodId, providerType]);

	const getAccountNumber = () => {
		switch (userCompany) {
			case UserCompany.SF_BVI:
			case UserCompany.SF_SYSTEMS_BVI:
				return formatMessage(messages.institutionAccountNumberSFBVI);
			case UserCompany.EE:
				return formatMessage(messages.institutionAccountNumberEE);
			case UserCompany.BNK_SYSTEMS_BVI:
			case UserCompany.LT:
			default:
				return formatMessage(messages.institutionAccountNumber);
		}
	};

	const getDisplayedCompanyName = () => {
		switch (userCompany) {
			case UserCompany.SF_BVI:
				return formatMessage(messages.userCompanySFBVI);
			case UserCompany.EE:
				return formatMessage(messages.userCompanyEE);
			case UserCompany.LT:
				return formatMessage(messages.userCompanyLT);
			default:
				return accountName;
		}
	};

	const accountNumber = getAccountNumber();
	const getAllDetailsAsString = () => {
		let detailsString = '';

		detailsString += `${formatMessage(messages.requestNumber)}: ${orderId} \n`;
		detailsString += `${formatMessage(messages.amount)}: ${`${amountFormatter(
			formatPrecision(amount, CurrencyEnum.EUR, undefined, true)
		)} ${currencyCode}`} \n`;
		detailsString += `${formatMessage(baseMsg.receiver)}: ${getDisplayedCompanyName()} \n`;
		detailsString += `${formatMessage(messages.accountNumber)}: ${accountNumber} \n`;
		detailsString += `${formatMessage(messages.paymentInstructions)}: ${formatMessage(
			messages.orderWithId,
			{
				orderId,
			}
		)} \n`;

		detailsString += `${formatMessage(messages.institutionSwiftLabel)}: ${formatMessage(
			messages.institutionSwift
		)} \n`;

		detailsString += `${formatMessage(messages.institutionNameLabel)}: ${formatMessage(
			messages.institutionName
		)} \n`;

		detailsString += `${formatMessage(messages.institutionAddressLabel)}: ${formatMessage(
			messages.institutionAddress
		)}`;

		return detailsString;
	};

	const allDetails = getAllDetailsAsString();
	if (providerType === ProviderType.SEPA)
		return (
			<Container details={allDetails}>
				<Item label={messages.requestNumber} value={`#${orderId}`} />
				<Item
					label={messages.amount}
					value={`${amountFormatter(
						formatPrecision(amount, CurrencyEnum.EUR, undefined, true)
					)} ${currencyCode}`}
					valueToCopy={amountFormatter(
						formatPrecision(amount, CurrencyEnum.EUR, undefined, true)
					)}
				/>
				<Item label={baseMsg.receiver} value={getDisplayedCompanyName()} />
				<Item label={messages.accountNumber} value={accountNumber} isCopyButtonEnabled />
				<Item
					label={messages.paymentInstructions}
					value={formatMessage(messages.orderWithId, {
						orderId,
					})}
				/>
				<Item label={messages.institutionSwiftLabel} value={messages.institutionSwift} />
				<Item label={messages.institutionNameLabel} value={messages.institutionName} />
				<Item
					label={messages.institutionAddressLabel}
					value={messages.institutionAddress}
				/>
			</Container>
		);

	return (
		<div className={classNames(styles.column)}>
			{bankDetails.length !== 1 && (
				<>
					<h2 className={styles.heading}>
						<FormattedMessage {...messages.clickOnTheBankDetails} />
					</h2>
					<p className={styles.instructions}>
						<FormattedMessage {...messages.bankDetailsInstructions} />
					</p>
				</>
			)}
			<Accordion
				items={bankDetails.map((bn) => {
					return {
						title: bn.title || '',
						answer: (
							<>
								<Item
									label={baseMsg.receiver}
									value={bn.receiver}
									isCopyButtonEnabled
								/>
								<Item
									label={messages.accountNumber}
									value={bn.receiverAccountNumber}
									isCopyButtonEnabled
								/>
								{bn.sortCode && (
									<Item
										label={messages.sortCode}
										value={bn.sortCode}
										isCopyButtonEnabled
									/>
								)}
								<Item
									label={messages.institutionSwiftLabel}
									value={bn.receiverSWIFT}
									isCopyButtonEnabled
								/>
								<Item
									label={messages.bankAddressLabel}
									value={bn.receiverAddress}
									isCopyButtonEnabled
								/>
								{bn.correspondentBankName && (
									<Item
										label={messages.institutionNameLabel}
										value={bn.correspondentBankName}
										isCopyButtonEnabled
									/>
								)}
								{bn.correspondentAddress && (
									<Item
										label={messages.institutionAddressLabel}
										value={bn.correspondentAddress}
										isCopyButtonEnabled
									/>
								)}
								{bn.correspondentBIC && (
									<Item
										label={messages.institutionBICLabel}
										value={bn.correspondentBIC}
										isCopyButtonEnabled
									/>
								)}
								<Item
									label={messages.amount}
									value={`${amountFormatter(
										formatPrecision(amount, CurrencyEnum.EUR, undefined, true)
									)} ${currencyCode}`}
									valueToCopy={amountFormatter(
										formatPrecision(amount, CurrencyEnum.EUR, undefined, true)
									)}
									isCopyButtonEnabled
								/>
								<Item
									label={messages.paymentInstructions}
									value={formatMessage(messages.orderWithId, {
										orderId,
									})}
									isCopyButtonEnabled
								/>
							</>
						),
					};
				})}
			/>
		</div>
	);
};
