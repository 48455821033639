/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
import { useState, FormEvent, useEffect, useCallback, memo, FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString, { ParsedQuery } from 'query-string';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import classNames from 'classnames';
import { useIntl, MessageDescriptor, FormattedMessage } from 'react-intl';
import { CurrencyIcon, CurrencyEnum, currencyUtils } from '@spectrocoin/sc-currencies';
import { Link } from 'react-router-dom';
import { endOfDay, startOfDay } from 'date-fns';
import debounceHelper from '../../../../helpers/debounceHelper/debounceHelper';
import usePrevious from '../../../../hooks/usePrevious';
import {
	CalcType,
	ConvertPairsResponse,
	Frequency,
} from '../../../../redux/ConvertState/ConvertTypes';
import { RootState } from '../../../../redux/Store';
import { calcAmount, getConvertPair } from '../../../../redux/ConvertState/ConvertActions';
import { Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import {
	formatPrecision,
	swapSeparators,
	toDecimal,
} from '../../../../helpers/currencyHelper/currencyHelper';
import { RatesDetailsResponse } from '../../../../redux/RatesState/RatesTypes';
import Seo from '../../../../components/Seo/Seo';
import Button, { ButtonType } from '../../../../components/Button/Button';
import arrowsIconPath from './images/arrows.svg';
import ConvertLiveRates from '../ConvertLiveRates/ConvertLiveRates';
import ConvertInfo from '../ConvertInfo/ConvertInfo';
import ConvertInput from '../ConvertInput/ConvertInput';
import { arrayMoveItem } from '../../../../helpers/globalFunctionHelpers/globalFunctionHelpers';
import { toPlainAmount } from '../../../../helpers/currencyAmountHelper/currencyAmountHelper';
import { isAmountGreaterThanZero } from '../../../../helpers/inputValidation/inputValidation';
import baseMsg from '../../../../messages/base.messages';
import inputErrors from '../../../../messages/inputErrors.messages';
import { DeviceNameType } from '../../../../redux/AppState/AppTypes';
import styles from './ConvertForm.module.scss';
import PrecentOptions from '../PercentOptions/PercentOptions';
import convertMsg from '../../convertMsg';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import Select from '../../../../components/Select/Select';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import useFormatAmount from '../../../../hooks/useFormatAmount';

export enum ConvertType {
	INSTANT = 'INSTANT',
	RECURRING = 'RECURRING',
	ADVANCED = 'ADVANCED',
	CARD = 'CARD',
	LEVERAGE = 'LEVARAGE',
}

const selectStyles = {
	option: (provided: any, { isSelected, isFocused }: any) => ({
		...provided,
		background: isSelected || isFocused ? '#F5F8FF' : 'white',
		color: isSelected || isFocused ? 'white !important' : 'white !important',
	}),
};

const frequencyOptions = [
	{
		value: Frequency.DAILY,
		label: (
			<div className={styles.freqOption}>
				<FormattedMessage {...convertMsg[Frequency.DAILY]} />
				<span>
					<FormattedMessage {...convertMsg[`${Frequency.DAILY}text`]} />
				</span>
			</div>
		),
	},
	{
		value: Frequency.WEEKLY,
		label: (
			<div className={styles.freqOption}>
				<FormattedMessage {...convertMsg[Frequency.WEEKLY]} />
				<span>
					<FormattedMessage {...convertMsg[`${Frequency.WEEKLY}text`]} />
				</span>
			</div>
		),
	},
	{
		value: Frequency.BIWEEKLY,
		label: (
			<div className={styles.freqOption}>
				<FormattedMessage {...convertMsg[Frequency.BIWEEKLY]} />
				<span>
					<FormattedMessage {...convertMsg[`${Frequency.BIWEEKLY}text`]} />
				</span>
			</div>
		),
	},
	{
		value: Frequency.MONTHLY,
		label: (
			<div className={styles.freqOption}>
				<FormattedMessage {...convertMsg[Frequency.MONTHLY]} />
				<span>
					<FormattedMessage {...convertMsg[`${Frequency.MONTHLY}text`]} />
				</span>
			</div>
		),
	},
];

const ConvertForm: FC<{ convertType: ConvertType }> = ({ convertType }) => {
	const { formatMessage, locale } = useIntl();
	const location = useLocation();
	const dispatch = useDispatch();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const { push }: any = useHistory();
	const { url } = useRouteMatch();
	const { search } = useLocation();
	const amountFormatter = useFormatAmount();
	const { pairs } = useSelector((state: RootState) => state.ConvertState);
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const parsedQuery: ParsedQuery<any> = queryString.parse(location.search);
	const { referenceCurrency, deviceOS } = useSelector((state: RootState) => state.AppState);
	const isAppleOS = useMemo(() => deviceOS === DeviceNameType.IPHONE, [deviceOS]);
	const { ratesDetails } = useSelector((state: RootState) => state.RatesState);
	const { currency: payCurrFromURL, receiveCurrency: receiveCurrFromURL } = parsedQuery;
	const [payAccountSelect, setPayAccountSelect] = useState<CurrencyEnum | string>(
		CurrencyEnum[payCurrFromURL] || CurrencyEnum.USD
	);
	const prevPayAccountSelect = usePrevious(payAccountSelect);
	const [receiveAccountSelect, setReceiveAccountSelect] = useState<CurrencyEnum | string>(
		CurrencyEnum[receiveCurrFromURL] || ''
	);
	const prevReceiveAccountSelect = usePrevious(receiveAccountSelect);
	const [payAmount, setPayAmount] = useState('');
	const [receiveAmount, setReceiveAmount] = useState('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [payError, setPayError] = useState<MessageDescriptor | null>(null);
	const [payErrorProps, setPayErrorProps] = useState<any | null>(null);
	const [receiveError, setReceiveError] = useState<MessageDescriptor | null>(null);
	const [paySelectOptions, setPaySelectOptions] = useState<any>(null);
	const [receiveSelectOptions, setReceiveSelectOptions] = useState<any>(null);
	const prevReceiveSelectOptions = usePrevious(receiveSelectOptions);
	const [optionsForSwap, setOptionsForSwap] = useState<any>(null);
	const [isSwapEnabled, setIsSwapEnabled] = useState<boolean>(true);
	const [isPayLoading, setIsPayLoading] = useState<boolean>(false);
	const [isReceiveLoading, setIsReceiveLoading] = useState<boolean>(false);
	const [allAmount, setAllAmount] = useState<string>('0');
	const [frequency, setFrequency] = useState<Frequency | null>(
		frequencyOptions?.find(({ value }) => value === Frequency.WEEKLY)?.value || null
	);
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();

	const isButtonDisabled = useCallback(() => {
		if (isLoading) return true;
		if (!(payAmount && receiveAmount)) return true;
		if (ConvertType.RECURRING === convertType && !frequency) return true;
		return false;
	}, [convertType, frequency, isLoading, payAmount, receiveAmount]);

	const submitForm = (e: FormEvent<HTMLFormElement>) => {
		const newPayAmount = payAmount.toString();
		const newReceiveAmount = receiveAmount.toString();
		e.preventDefault();
		if (!newReceiveAmount || !newPayAmount) {
			if (!newPayAmount) setPayError(inputErrors.cannotBeEmpty);

			if (!newReceiveAmount) setReceiveError(inputErrors.cannotBeEmpty);
			return null;
		}

		if (
			!isAmountGreaterThanZero(newPayAmount, true) ||
			!isAmountGreaterThanZero(newReceiveAmount, true)
		) {
			if (!isAmountGreaterThanZero(newPayAmount, true))
				setPayError(convertMsg.greaterThenZero);
			if (!isAmountGreaterThanZero(newReceiveAmount, true))
				setReceiveError(convertMsg.greaterThenZero);
			return null;
		}

		const walletData = wallets?.find(({ currencyCode }: Wallet) => {
			return currencyCode === payAccountSelect;
		});

		if (
			walletData &&
			toDecimal(newPayAmount, true).greaterThan(toDecimal(walletData?.availableBalance))
		) {
			const { isVirtual } = currencyUtils.getConfigOrDefault(CurrencyEnum[payAccountSelect]);
			const walletId = wallets?.find(
				({ currencyCode }: Wallet) => currencyCode === payAccountSelect
			)?.id;
			const depositUrl = `/${locale}/deposit${
				isVirtual
					? `/crypto/${payAccountSelect}/${walletId}`
					: (!isVirtual && payAccountSelect === CurrencyEnum.EUR) ||
					  (!isVirtual && payAccountSelect === CurrencyEnum.GBP)
					? `/bank?currency=${payAccountSelect}`
					: `/e-wallet?currency=${payAccountSelect}`
			}`;
			setPayErrorProps({
				a: (text: string[]) => <Link to={depositUrl}>{text}</Link>,
			});
			return setPayError(inputErrors.insufficientFundsWithDeposit);
		}
		const currentPair = pairs?.filter((o: any) => {
			return (
				o?.payCurrency?.currencyCode === payAccountSelect &&
				o?.receiveCurrency?.currencyCode === receiveAccountSelect
			);
		});

		return push({
			state: {
				convertType,
				payAmount: toPlainAmount(payAmount, true),
				receiveAmount: toPlainAmount(receiveAmount, true),
				from: payAccountSelect,
				to: receiveAccountSelect,
				id: currentPair && currentPair[0].id,
				...(convertType === ConvertType.RECURRING && { frequency }),
				...(convertType === ConvertType.RECURRING && { startDate }),
				...(convertType === ConvertType.RECURRING && { endDate }),
			},
			pathname: `${url}/confirm`,
		});
	};

	const swapInputs = () => {
		if (isSwapEnabled) {
			setPayAccountSelect(receiveAccountSelect);
			setReceiveSelectOptions(optionsForSwap);
			setReceiveAccountSelect(payAccountSelect);
			setPayAmount(receiveAmount);
		}
		return null;
	};

	const getTitle: () => MessageDescriptor = useCallback(() => {
		switch (convertType) {
			case ConvertType.RECURRING:
				return convertMsg.recurringOrder;
			case ConvertType.INSTANT:
			default:
				return convertMsg.convert;
		}
	}, [convertType]);

	// eslint-disable-next-line consistent-return
	const onChangeInput = debounceHelper((value: string, type: CalcType, fromInput: boolean) => {
		if (type === CalcType.RECEIVE) {
			setPayErrorProps(null);
			setPayError(null);
			setPayAmount(isAppleOS && !fromInput ? swapSeparators(value) : value);
			if (!isNaN(parseFloat(value)) && payAmount !== value) {
				if (!isAmountGreaterThanZero(value || 0, true)) return null;
				return calc(CalcType.RECEIVE, fromInput ? toPlainAmount(value, true) : value);
			}
		}
		if (type === CalcType.PAY) {
			setReceiveError(null);
			setReceiveAmount(isAppleOS && !fromInput ? swapSeparators(value) : value);
			if (!isNaN(parseFloat(value)) && receiveAmount !== value) {
				if (!isAmountGreaterThanZero(value || 0, true)) return null;
				return calc(CalcType.PAY, fromInput ? toPlainAmount(value, true) : value);
			}
		}
	}, 500);

	const setPayOptions = useCallback(() => {
		const unique = [
			...Array.from(
				new Set(
					pairs?.map(({ payCurrency }: ConvertPairsResponse) => payCurrency?.currencyCode)
				)
			),
		];
		const optionsArray = unique.reduce((acc: any, curr: CurrencyEnum) => {
			const walletData = wallets?.find(({ currencyCode }: Wallet) => currencyCode === curr);

			const refCurrData = ratesDetails?.find(
				({ currencyCode }: RatesDetailsResponse) => currencyCode === curr
			);

			if (walletData) {
				const item = {
					value: curr,
					label: (
						<div className={styles.option}>
							<CurrencyIcon currencyType={curr} className={styles.currencyIcon} />
							<div>
								<label className={styles.currencyLabel}>
									{curr}{' '}
									<span className={styles.currencyName}>
										{walletData.currencyName}
									</span>
								</label>
								<div className={styles.amount}>
									{amountFormatter(
										formatPrecision(
											walletData.availableBalance!,
											CurrencyEnum[walletData?.currencyCode]
										)
									)}{' '}
									{curr}
								</div>
								<div className={styles.refAmount}>
									{refCurrData &&
										amountFormatter(
											formatPrecision(
												toDecimal(refCurrData?.value)
													.times(walletData.availableBalance)
													.toString(),
												CurrencyEnum[referenceCurrency!]
											)
										)}{' '}
									{referenceCurrency}
								</div>
							</div>
						</div>
					),
				};
				acc.push(item);
			}
			return acc;
		}, []);
		let sortedOptions = optionsArray.sort((a: any, b: any) => {
			if (a.value < b.value) return -1;
			if (a.value > b.value) return 1;
			return 0;
		});

		const USDTWallet = sortedOptions.filter(
			(o: any) => CurrencyEnum[o.value] === CurrencyEnum.USDT
		);
		if (USDTWallet) {
			const index = sortedOptions.indexOf(USDTWallet[0]);
			if (index > -1) {
				sortedOptions = arrayMoveItem(sortedOptions, index, 0);
			}
		}
		const ETHWallet = sortedOptions.filter(
			(o: any) => CurrencyEnum[o.value] === CurrencyEnum.ETH
		);
		if (ETHWallet) {
			const index = sortedOptions.indexOf(ETHWallet[0]);
			if (index > -1) {
				sortedOptions = arrayMoveItem(sortedOptions, index, 0);
			}
		}
		const BTCWallet = sortedOptions.filter(
			(o: any) => CurrencyEnum[o.value] === CurrencyEnum.BTC
		);
		if (BTCWallet) {
			const index = sortedOptions.indexOf(BTCWallet[0]);
			if (index > -1) {
				sortedOptions = arrayMoveItem(sortedOptions, index, 0);
			}
		}
		const EURWallet = sortedOptions.filter(
			(o: any) => CurrencyEnum[o.value] === CurrencyEnum.EUR
		);
		if (EURWallet) {
			const index = sortedOptions.indexOf(EURWallet[0]);
			if (index > -1) {
				sortedOptions = arrayMoveItem(sortedOptions, index, 0);
			}
		}
		const USDWallet = sortedOptions.filter(
			(o: any) => CurrencyEnum[o.value] === CurrencyEnum.USD
		);
		if (USDWallet) {
			const index = sortedOptions.indexOf(USDWallet[0]);
			if (index > -1) {
				sortedOptions = arrayMoveItem(sortedOptions, index, 0);
			}
		}
		setPaySelectOptions(sortedOptions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pairs, ratesDetails, referenceCurrency, wallets]);

	const setReceiveOptions = useCallback(() => {
		const optionsArray = pairs?.reduce(
			(acc: any, { payCurrency, receiveCurrency }: ConvertPairsResponse) => {
				if (payCurrency?.currencyCode === payAccountSelect) {
					const walletData = wallets?.find(
						({ currencyCode }: Wallet) => currencyCode === receiveCurrency?.currencyCode
					);

					const refCurrencyData = ratesDetails?.find(
						({ currencyCode }: RatesDetailsResponse) =>
							currencyCode === receiveCurrency?.currencyCode
					);
					if (walletData) {
						const item = {
							value: receiveCurrency?.currencyCode,
							label: (
								<div className={styles.option}>
									<CurrencyIcon
										currencyType={receiveCurrency?.currencyCode}
										className={styles.currencyIcon}
									/>
									<div>
										<label className={styles.currencyLabel}>
											{receiveCurrency?.currencyCode}{' '}
											<span className={styles.currencyName}>
												{walletData.currencyName}
											</span>
										</label>
										<div className={styles.amount}>
											{amountFormatter(
												formatPrecision(
													walletData.availableBalance!,
													CurrencyEnum[walletData.currencyCode]
												)
											)}{' '}
											{receiveCurrency?.currencyCode}
										</div>
										<div className={styles.refAmount}>
											{refCurrencyData &&
												amountFormatter(
													formatPrecision(
														toDecimal(refCurrencyData?.value)
															.times(walletData?.availableBalance)
															.toString(),
														CurrencyEnum[referenceCurrency!]
													)
												)}{' '}
											{referenceCurrency}
										</div>
									</div>
								</div>
							),
						};
						acc.push(item);
					}
				}
				return acc;
			},
			[]
		);
		let sortedOptions = optionsArray.sort((a: any, b: any) => {
			if (a.value < b.value) return -1;
			if (a.value > b.value) return 1;
			return 0;
		});

		const USDTWallet = sortedOptions.filter(
			(o: any) => CurrencyEnum[o.value] === CurrencyEnum.USDT
		);
		if (USDTWallet) {
			const index = sortedOptions.indexOf(USDTWallet[0]);
			if (index > -1) {
				sortedOptions = arrayMoveItem(sortedOptions, index, 0);
			}
		}
		const ETHWallet = sortedOptions.filter(
			(o: any) => CurrencyEnum[o.value] === CurrencyEnum.ETH
		);
		if (ETHWallet) {
			const index = sortedOptions.indexOf(ETHWallet[0]);
			if (index > -1) {
				sortedOptions = arrayMoveItem(sortedOptions, index, 0);
			}
		}
		const EURWallet = sortedOptions.filter(
			(o: any) => CurrencyEnum[o.value] === CurrencyEnum.EUR
		);
		if (EURWallet) {
			const index = sortedOptions.indexOf(EURWallet[0]);
			if (index > -1) {
				sortedOptions = arrayMoveItem(sortedOptions, index, 0);
			}
		}
		const USDWallet = sortedOptions.filter(
			(o: any) => CurrencyEnum[o.value] === CurrencyEnum.USD
		);
		if (USDWallet) {
			const index = sortedOptions.indexOf(USDWallet[0]);
			if (index > -1) {
				sortedOptions = arrayMoveItem(sortedOptions, index, 0);
			}
		}
		const BTCWallet = sortedOptions.filter(
			(o: any) => CurrencyEnum[o.value] === CurrencyEnum.BTC
		);
		if (BTCWallet) {
			const index = sortedOptions.indexOf(BTCWallet[0]);
			if (index > -1) {
				sortedOptions = arrayMoveItem(sortedOptions, index, 0);
			}
		}
		setReceiveSelectOptions(sortedOptions);
	}, [pairs, payAccountSelect, ratesDetails, referenceCurrency, wallets]);

	const setOptionsSwap = useCallback(() => {
		const optionsArray = pairs?.reduce(
			(acc: any, { payCurrency, receiveCurrency }: ConvertPairsResponse) => {
				if (payCurrency?.currencyCode === receiveAccountSelect) {
					const walletData = wallets?.find(
						({ currencyCode }: Wallet) => currencyCode === receiveCurrency?.currencyCode
					);

					const refCurrencyData = ratesDetails?.find(
						({ currencyCode }: RatesDetailsResponse) =>
							currencyCode === receiveCurrency?.currencyCode
					);
					if (walletData) {
						const item = {
							value: receiveCurrency?.currencyCode,
							label: (
								<div className={styles.option}>
									<CurrencyIcon
										currencyType={receiveCurrency?.currencyCode}
										className={styles.currencyIcon}
									/>
									<div>
										<label className={styles.currencyLabel}>
											{receiveCurrency?.currencyCode}{' '}
											<span className={styles.currencyName}>
												{walletData.currencyName}
											</span>
										</label>
										<div className={styles.amount}>
											{amountFormatter(
												formatPrecision(
													walletData.availableBalance!,
													CurrencyEnum[walletData.currencyCode]
												)
											)}{' '}
											{receiveCurrency?.currencyCode}
										</div>
										<div className={styles.refAmount}>
											{refCurrencyData &&
												amountFormatter(
													formatPrecision(
														toDecimal(refCurrencyData?.value)
															.times(walletData?.availableBalance)
															.toString(),
														CurrencyEnum[referenceCurrency!]
													)
												)}{' '}
											{referenceCurrency}
										</div>
									</div>
								</div>
							),
						};
						acc.push(item);
					}
				}
				return acc;
			},
			[]
		);
		setOptionsForSwap(optionsArray);
	}, [pairs, ratesDetails, receiveAccountSelect, referenceCurrency, wallets]);

	const calc = useCallback(
		(type: CalcType, value: string) => {
			const currentPair = pairs?.filter((o: any) => {
				return (
					o?.payCurrency?.currencyCode === payAccountSelect &&
					o?.receiveCurrency?.currencyCode === receiveAccountSelect
				);
			});
			if (value && currentPair) {
				setIsLoading(true);
				if (type === CalcType.RECEIVE) setIsReceiveLoading(true);
				if (type === CalcType.PAY) setIsPayLoading(true);
				void calcAmount(type, currentPair[0]?.id, value)
					.then(({ amount }) => {
						if (type === CalcType.RECEIVE) {
							setReceiveError(null);
							setReceiveAmount(
								deviceOS === DeviceNameType.IPHONE ? swapSeparators(amount) : amount
							);
						}
						if (type === CalcType.PAY) {
							setPayErrorProps(null);
							setPayError(null);
							setPayAmount(
								deviceOS === DeviceNameType.IPHONE ? swapSeparators(amount) : amount
							);
						}
					})
					.catch(() => {
						return null;
					})
					.then(() => {
						setIsPayLoading(false);
						setIsReceiveLoading(false);
						return setIsLoading(false);
					});
			}
			return null;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[pairs, payAccountSelect, receiveAccountSelect]
	);

	// get convert pairs + set pay account options + set receive account options
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		if (!pairs) dispatch<any>(getConvertPair());
		if (pairs && !paySelectOptions) {
			setPayOptions();
		}
		if (pairs && payAccountSelect) {
			setReceiveOptions();
		}
	}, [pairs, dispatch, payAccountSelect, paySelectOptions, setPayOptions, setReceiveOptions]);

	// set pay account options after reference currency change with new reference amount
	useEffect(() => {
		if (referenceCurrency && ratesDetails && pairs && paySelectOptions) {
			setPayOptions();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [referenceCurrency, ratesDetails]);

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		const searchRecieve = new URLSearchParams(search).get('recieve') as CurrencyEnum | null;

		if (
			payAccountSelect &&
			receiveSelectOptions?.length > 0 &&
			!receiveAccountSelect &&
			receiveSelectOptions.some(({ value }: any) => value === searchRecieve)
		) {
			setReceiveAccountSelect(searchRecieve!);
			return;
		}

		if (
			payAccountSelect &&
			receiveSelectOptions?.length > 0 &&
			!receiveAccountSelect &&
			receiveSelectOptions.some(({ value }: any) => value === CurrencyEnum.BTC)
		) {
			setReceiveAccountSelect(CurrencyEnum.BTC);
			return;
		}
		if (
			payAccountSelect &&
			receiveSelectOptions?.length > 0 &&
			!receiveAccountSelect &&
			!receiveSelectOptions.some(({ value }: any) => value === CurrencyEnum.BTC)
		) {
			setReceiveAccountSelect(receiveSelectOptions[0].value);
			return;
		}
		if (receiveAccountSelect) {
			setOptionsSwap();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		payAccountSelect,
		receiveAccountSelect,
		receiveSelectOptions,
		prevReceiveAccountSelect,
		setOptionsSwap,
		prevPayAccountSelect,
		prevReceiveSelectOptions,
	]);

	// set existing pay account if default does not exist in pairs
	useEffect(() => {
		if (
			payAccountSelect &&
			pairs &&
			!pairs?.find(
				(o: ConvertPairsResponse) => o?.payCurrency?.currencyCode === payAccountSelect
			)
		) {
			setPayAccountSelect(CurrencyEnum.USD);
		}
	}, [payCurrFromURL, pairs, payAccountSelect, prevPayAccountSelect]);

	// prevent pay and receive account to be the same
	useEffect(() => {
		if (
			!receiveCurrFromURL &&
			payAccountSelect === receiveAccountSelect &&
			receiveSelectOptions?.length > 0
		) {
			setReceiveAccountSelect(receiveSelectOptions[0].value);
		}
	}, [
		payAccountSelect,
		receiveAccountSelect,
		receiveSelectOptions,
		receiveCurrFromURL,
		payCurrFromURL,
	]);

	// check if swap enabled
	useEffect(() => {
		if (optionsForSwap?.some(({ value }: any) => value === payAccountSelect)) {
			return setIsSwapEnabled(true);
		}
		return setIsSwapEnabled(false);
	}, [optionsForSwap, payAccountSelect, receiveAccountSelect]);

	// recalculate receive amount on pay account change || receive account change
	useEffect(() => {
		if (
			(payAmount &&
				!isLoading &&
				payAccountSelect !== receiveAccountSelect &&
				prevReceiveSelectOptions !== receiveSelectOptions &&
				receiveSelectOptions?.some(({ value }: any) => value === receiveAccountSelect)) ||
			(!isLoading && receiveAccountSelect !== prevReceiveAccountSelect && payAmount)
		) {
			setPayErrorProps(null);
			setPayError(null);
			setReceiveError(null);
			calc(CalcType.RECEIVE, toPlainAmount(payAmount, true));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payAccountSelect, isLoading, receiveAccountSelect, receiveSelectOptions]);

	useEffect(() => {
		if (
			payCurrFromURL &&
			currencyUtils.getConfigOrDefault(payCurrFromURL).isVirtual &&
			receiveSelectOptions?.length > 0 &&
			!receiveAccountSelect
		) {
			setReceiveAccountSelect(CurrencyEnum.USD);
		}
		if (
			payCurrFromURL &&
			!currencyUtils.getConfigOrDefault(payCurrFromURL).isVirtual &&
			receiveSelectOptions?.length > 0 &&
			!receiveAccountSelect
		) {
			setReceiveAccountSelect(CurrencyEnum.BTC);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [receiveSelectOptions, receiveAccountSelect]);

	useEffect(() => {
		const wallet = wallets?.find(
			({ currencyCode }: Wallet) => currencyCode === payAccountSelect
		);
		setAllAmount(wallet?.availableBalance || '0');
	}, [setAllAmount, payCurrFromURL, wallets, payAccountSelect]);

	useEffect(() => {
		if (
			receiveCurrFromURL &&
			paySelectOptions?.length > 0 &&
			payAccountSelect !== receiveAccountSelect
		) {
			setPayAccountSelect(
				paySelectOptions?.find(({ value }: any) => value !== receiveCurrFromURL).value
			);
			setReceiveAccountSelect(receiveCurrFromURL);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paySelectOptions, receiveAccountSelect, receiveCurrFromURL]);

	if (!wallets?.length) return null;
	return (
		<>
			<Seo title={getTitle()} />
			<div className={styles.header}>
				<h2 className={styles.title}>{formatMessage(getTitle())}</h2>
				<Link to={`${url}/history?page=1`} className={styles.link}>
					{convertType === ConvertType.RECURRING &&
						formatMessage(convertMsg.openOrderHistory)}
					{convertType === ConvertType.INSTANT &&
						formatMessage(convertMsg.instantHistory)}
				</Link>
			</div>
			<form
				className={styles.form}
				onSubmit={(e: FormEvent<HTMLFormElement>) => submitForm(e)}
			>
				<ConvertInput
					inputId="pay"
					isSearchable
					currency={CurrencyEnum[payAccountSelect]}
					options={paySelectOptions}
					amount={payAmount}
					onChangeSelect={(val: CurrencyEnum) => setPayAccountSelect(val)}
					onChangeInput={(val: string, type: CalcType) => onChangeInput(val, type, true)}
					label={baseMsg.from}
					placeholder={convertMsg.enterAmount}
					error={payError}
					errorProps={payErrorProps}
					isLoading={isPayLoading}
				/>
				<div className={styles.availableBalance}>
					{formatMessage(convertMsg.available, {
						amount: (
							<span>{`${amountFormatter(
								formatPrecision(allAmount, CurrencyEnum[payAccountSelect])
							)} ${payAccountSelect}`}</span>
						),
					})}
				</div>
				<PrecentOptions onChange={onChangeInput} fullAmount={allAmount} />
				<div
					className={classNames(styles.arrows, {
						[styles.disabled]:
							!payAccountSelect || !receiveAccountSelect || !isSwapEnabled,
					})}
				>
					<img src={arrowsIconPath} onClick={swapInputs} alt="Convert Arrows" />
				</div>
				<ConvertInput
					inputId="receive"
					isSearchable
					currency={
						!receiveAccountSelect && !receiveSelectOptions?.length
							? receiveAccountSelect
							: receiveSelectOptions?.some(
									({ value }: any) => value === receiveAccountSelect
							  )
							? receiveAccountSelect
							: receiveSelectOptions
							? receiveSelectOptions[0]?.value
							: receiveAccountSelect
					}
					options={receiveSelectOptions}
					amount={receiveAmount}
					onChangeSelect={(val: CurrencyEnum) => setReceiveAccountSelect(val)}
					onChangeInput={(val: string, type: CalcType) => onChangeInput(val, type, true)}
					label={convertType === ConvertType.RECURRING ? convertMsg.to : baseMsg.to}
					labelTooltip={
						convertType === ConvertType.RECURRING ? (
							<Tooltip
								infoIconVisible
								content={
									<div className={styles.tooltip}>
										{formatMessage(convertMsg.toTooltip)}
									</div>
								}
							/>
						) : undefined
					}
					placeholder={convertMsg.enterAmount}
					error={receiveError}
					isLoading={isReceiveLoading}
				/>
				<ConvertLiveRates
					payCurrency={payAccountSelect}
					receiveCurrency={receiveAccountSelect}
					pairId={
						pairs?.filter((o: any) => {
							return (
								o?.payCurrency?.currencyCode === payAccountSelect &&
								o?.receiveCurrency?.currencyCode === receiveAccountSelect
							);
						})[0]?.id || null
					}
				/>
				{convertType === ConvertType.RECURRING && (
					<div className={styles.recurringContainer}>
						<label className={styles.label}>
							{formatMessage(convertMsg.selectOrderConditions)}
						</label>
						<div className={styles.recurringOrderDetails}>
							<Select
								options={frequencyOptions}
								value={frequency}
								className={styles.select}
								onChange={(val: string) => setFrequency(Frequency[val])}
								placeholder={formatMessage(convertMsg.orderFrequency)}
								customStyles={selectStyles}
							/>
							<div className={styles.twoColumns}>
								<DatePicker
									onChange={(date: any) => setStartDate(date)}
									minDate={new Date()}
									maxDate={
										endDate ? startOfDay(new Date(endDate || '')) : undefined
									}
									label={convertMsg.start}
									selected={startDate}
									labelClassName={styles.label}
									className={styles.datePicker}
									placeholderText={formatMessage(convertMsg.now)}
								/>
								<DatePicker
									onChange={(date: any) => setEndDate(date)}
									minDate={
										startDate ? endOfDay(new Date(startDate || '')) : new Date()
									}
									label={convertMsg.end}
									tooltipContent={convertMsg.endTooltip}
									selected={endDate}
									labelClassName={styles.label}
									className={styles.datePicker}
									placeholderText={formatMessage(convertMsg.never)}
								/>
							</div>
						</div>
					</div>
				)}
				<Button
					text={
						convertType === ConvertType.INSTANT
							? convertMsg.previewExchange
							: convertMsg.previewOrder
					}
					type={ButtonType.SUBMIT}
					className={styles.button}
					minWidth={176}
					isDisabled={isButtonDisabled()}
				/>
				<ConvertInfo />
			</form>
		</>
	);
};

export default memo(ConvertForm);
