/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { AxiosResponse } from 'axios';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { getIsFunctionalityAvailableURL } from '../../redux/endpoints';
import { UserCompany, UserType } from '../../redux/ProfileState/ProfileTypes';
import UserContractCompanyType, {
	UserPreviousPageForCompanyMovement,
} from '../../enums/UserContractCompanyEnum';
import FunctionalityAvailableType from '../../enums/FunctionalityAvailableEnum';
import IsFunctionalityAvailableResponse from '../../interfaces/IsFunctionalityAvailable';
import Button, { ButtonType } from '../Button/Button';
import Loader from '../Loader/Loader';
import CopyButton from '../CopyButton/CopyButton';
import Tooltip from '../Tooltip/Tooltip';
import { RootState } from '../../redux/Store';
import styles from './IBANBlock.module.scss';
import { companyAndPreviousPageHandler } from '../../helpers/storageHelper/storageHelper';
import useEffectOnce from '../../hooks/useEffectOnce';
import { IBANStatus } from '../../redux/AccountsState/AccountsTypes';
import statusMsg from '../../messages/status.messages';
import config from '../../configs/config';
import TestIds from '../../test/TestIds';
import IBANToggler from '../IBANToggler/IBANToggler';
import baseMsg from '../../messages/base.messages';
import axiosInstance from '../../helpers/axiosInstance';

const messages = defineMessages({
	accountDetails: {
		id: 'ibanBlock.accountDetails',
		defaultMessage: 'Account details',
	},
	newIbanRequest: {
		id: 'ibanBlock.requestNewIban',
		defaultMessage: 'Request for new IBAN',
	},
	name: {
		id: 'ibanBlock.name',
		defaultMessage: 'Name',
	},
	ibanLabel: {
		id: 'ibanBlock.ibanLabel',
		defaultMessage: 'IBAN',
	},
	claimIban: {
		id: 'ibanBlock.claimIban',
		defaultMessage: 'Claim IBAN',
	},
	verifyAccount: {
		id: 'ibanBlock.verifyAccount',
		defaultMessage: 'Verify your account',
	},
	whatIsIBAN: {
		id: 'ibanBlock.whatIsIBANInfo',
		description: 'whatIsIBANInfo',
		defaultMessage:
			'<strong>What is IBAN?</strong><br /><p>IBAN (International Bank Account Number) is an international format used to identify accounts held in the EU. With your personal dedicated IBAN you can make deposits to and withdrawals from your account.</p><br /><p>Dedicated IBAN service on SpectroCoin is powered by UAB Pervesk.</p>',
	},
	status: {
		id: 'cardsList.status',
		defaultMessage: 'Status',
	},
	whatIsIBANForUnverified: {
		id: 'ibanBlock.whatIsIBANForUnverifiedInfo',
		description: 'whatIsIBANForUnverifiedInfo',
		defaultMessage:
			'<strong>What is IBAN?</strong><br /><p>IBAN (International Bank Account Number) is an international format used to identify accounts held in the EU. With your IBAN you can make deposits to and withdrawals from your account.</p><br /><p>Dedicated IBAN service on SpectroCoin is powered by UAB Pervesk.</p><br /><p>You must verify your account and phone number to use the IBAN services.</p>',
	},
	institutionNameLabel: {
		id: 'ibanBlock.institutionNameLabel',
		defaultMessage: 'Institution Name',
	},
	institutionAddressLabel: {
		id: 'ibanBlock.institutionAddressLabel',
		defaultMessage: 'Institution Adress',
	},
	swiftCodeLabel: {
		id: 'ibanBlock.swiftCodeLabel',
		defaultMessage: 'SWIFT/BIC code',
	},
	institutionNameValue: {
		id: 'ibanBlock.institutionNameValue',
		defaultMessage: 'UAB Pervesk',
	},
	institutionAddressValue: {
		id: 'ibanBlock.institutionAddressValue',
		defaultMessage: 'Rinktines st. 5, LT-09234 Vilnius, Lithuania',
	},
	swiftCodeValue: {
		id: 'ibanBlock.swiftCodeValue',
		defaultMessage: 'UAPELT22XXX',
	},
	additionalInfo: {
		id: 'ibanBlock.additionalInfo',
		defaultMessage:
			'* Funds received to this IBAN will be forwarded to your SpectroCoin wallet.',
	},
	showDetails: {
		id: 'ibanBlock.showDetails',
		defaultMessage: 'Show details',
	},
	copyAllDetails: {
		id: 'ibanBlock.copyAllDetails',
		defaultMessage: 'Copy all details',
	},
	correspondentBankNameLabel: {
		id: 'ibanBlock.correspondentBankNameLabel',
		defaultMessage: 'Correspondent bank name',
	},
	correspondentBankAddressLabel: {
		id: 'ibanBlock.correspondentBankAddressLabel',
		defaultMessage: 'Correspondent bank address',
	},
	correspondentBankSwiftLabel: {
		id: 'ibanBlock.correspondentBankSwiftLabel',
		defaultMessage: 'Correspondent bank SWIFT/BIC',
	},
	correspondentBankNameValue: {
		id: 'ibanBlock.correspondentBankNameValue',
		defaultMessage: 'INCORE BANK AG',
	},
	correspondentBankAddressValue: {
		id: 'ibanBlock.correspondentBankAddressValue',
		defaultMessage: 'Wiesenstrasse 17, Zürich, Switzerland',
	},
	correspondentBankSwiftValue: {
		id: 'ibanBlock.correspondentBankSwiftValue',
		defaultMessage: 'INCOCHZZ',
	},
});

interface IBANBlockProps {
	currencyCode: CurrencyEnum;
	id: string;
	iban: string | null;
}

enum IbanOption {
	SEPA = 'SEPA',
	SWIFT = 'SWIFT',
}

const IBANBlock = ({ currencyCode, id, iban }: IBANBlockProps) => {
	const { formatMessage, locale } = useIntl();
	const { url } = useRouteMatch();

	const { user } = useSelector((state: RootState) => state?.ProfileState);
	const { ibanInfo } = useSelector((state: RootState) => state?.AccountsState).wallets?.find(
		(wallet) => wallet.currencyCode === currencyCode
	)!;
	const isIbanClosed = ibanInfo?.status === IBANStatus.CLOSED;

	const [verificationUrl, setVerificationUrl] = useState<string>('');
	const [isIBANAvailable, setIsIBANAvailable] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isDetailsVisible, setIsDetailsVisible] = useState<boolean>(false);
	const [ibanOption, setIbanOption] = useState<IbanOption>(IbanOption.SEPA);

	const fetchIsFunctionalityAvailable = () => {
		axiosInstance
			.get(getIsFunctionalityAvailableURL(FunctionalityAvailableType.CLAIM_VIBAN))
			.then(({ data }: AxiosResponse<IsFunctionalityAvailableResponse[]>) => {
				const { available, data: featureData } = data[0];
				companyAndPreviousPageHandler({
					toCompany:
						featureData && featureData?.toCompany
							? UserContractCompanyType[featureData.toCompany]
							: null,
					previousPage: UserPreviousPageForCompanyMovement.CLAIM_IBAN,
				});
				setIsLoading(false);
				return setIsIBANAvailable(available);
			})
			.catch(() => {
				return setIsLoading(false);
			});
	};

	const getVerificationUrl = () => {
		if (!isIBANAvailable)
			return setVerificationUrl(
				`${config.PROFILE_DOMAIN}/${locale}/verification/verify/${UserCompany.EE}`
			);
		if (!user?.phoneVerified)
			return setVerificationUrl(`${config.PROFILE_DOMAIN}/${locale}/verification/phone`);
		if (!user?.verified)
			return setVerificationUrl(`${config.PROFILE_DOMAIN}/${locale}/verification/account`);
		return setVerificationUrl('');
	};

	const getStatusString = (status: IBANStatus) => {
		switch (status) {
			case IBANStatus.ACTIVE:
				return statusMsg.ACTIVE;
			case IBANStatus.CLOSED:
				return statusMsg.CLOSED;
			default:
				return statusMsg.CLOSED;
		}
	};

	useEffect(() => {
		getVerificationUrl();
	}, [user, isIBANAvailable]);

	useEffectOnce(() => {
		fetchIsFunctionalityAvailable();
	});

	if (currencyCode !== CurrencyEnum.EUR || user?.type !== UserType.PRIVATE_PERSON) return null;
	if (isLoading) return <Loader className={styles.loader} />;
	if (iban && !isIbanClosed)
		return (
			<div className={classNames(styles.container, styles.withIban)}>
				<div className={styles.additionalInfo}>
					{formatMessage(messages.additionalInfo)}
				</div>
				<div className={styles.title}>
					{formatMessage(messages.accountDetails)}
					{isDetailsVisible && (
						<CopyButton
							dataToCopy={`${formatMessage(baseMsg.receiver)}: ${user?.name} ${
								user?.surname
							}\nIBAN: ${iban}\nSWIFT (BIC): ${formatMessage(
								messages.swiftCodeValue
							)}\n${formatMessage(messages.institutionNameLabel)}: ${formatMessage(
								messages.institutionNameValue
							)}\n${formatMessage(messages.institutionAddressLabel)}: ${formatMessage(
								messages.institutionAddressValue
							)}\n${
								ibanOption === IbanOption.SWIFT
									? `\n${formatMessage(
											messages.correspondentBankNameLabel
									  )}: ${formatMessage(
											messages.correspondentBankNameValue
									  )}\n${formatMessage(
											messages.correspondentBankAddressLabel
									  )}: ${formatMessage(
											messages.correspondentBankAddressValue
									  )}\n${formatMessage(
											messages.correspondentBankSwiftLabel
									  )}: ${formatMessage(messages.correspondentBankSwiftValue)}`
									: ''
							}`}
							className={styles.copyAllDetails}
							text={messages.copyAllDetails}
							data-cy={TestIds.CopyAllDetailsButton}
						/>
					)}
					{isDetailsVisible && (
						<IBANToggler onClick={setIbanOption} selected={ibanOption} />
					)}
				</div>
				<div className={styles.flexContainer}>
					<div className={classNames(styles.details, styles.minWidth)}>
						<div className={styles.label}>{formatMessage(messages.name)}</div>
						<div className={styles.value}>
							{user?.name} {user?.surname}
							<CopyButton
								dataToCopy={`${user?.name} ${user?.surname}`}
								className={styles.copyButton}
							/>
						</div>
					</div>
					<div className={styles.details}>
						<div className={styles.label}>{formatMessage(messages.ibanLabel)}</div>
						<div className={styles.value}>
							<div className={styles.value}>
								{iban}
								<CopyButton dataToCopy={iban} className={styles.copyButton} />
							</div>
						</div>
					</div>
					<div className={styles.details}>
						<div className={styles.label}>{formatMessage(messages.status)}</div>
						<div className={styles.value}>
							<div
								className={classNames(styles.status, {
									[styles.active]: ibanInfo?.status === IBANStatus.ACTIVE,
								})}
							>
								{formatMessage(
									getStatusString(ibanInfo?.status ?? IBANStatus.CLOSED)
								)}
							</div>
						</div>
					</div>
					<div
						className={classNames(styles.details, styles.showDetailsButton, {
							[styles.hidden]: isDetailsVisible,
						})}
					>
						<Button
							data-cy={TestIds.ShowDetailsButton}
							type={ButtonType.BUTTON}
							text={messages.showDetails}
							onClick={() => setIsDetailsVisible(true)}
						/>
					</div>
					{isDetailsVisible && (
						<div className={styles.moreDetails} data-cy={TestIds.MoreDetails}>
							<div className={classNames(styles.details, styles.minWidth)}>
								<div className={styles.label}>
									{formatMessage(messages.institutionNameLabel)}
								</div>
								<div className={styles.value}>
									<div className={styles.value}>
										{formatMessage(messages.institutionNameValue)}
										<CopyButton
											dataToCopy={formatMessage(
												messages.institutionNameValue
											)}
											className={styles.copyButton}
										/>
									</div>
								</div>
							</div>
							<div className={styles.details}>
								<div className={styles.label}>
									{formatMessage(messages.institutionAddressLabel)}
								</div>
								<div className={styles.value}>
									<div className={classNames(styles.value, styles.addressValue)}>
										{formatMessage(messages.institutionAddressValue)}
										<CopyButton
											dataToCopy={formatMessage(
												messages.institutionAddressValue
											)}
											className={styles.copyButton}
										/>
									</div>
								</div>
							</div>
							<div className={styles.details}>
								<div className={styles.label}>
									{formatMessage(messages.swiftCodeLabel)}
								</div>
								<div className={styles.value}>
									<div className={styles.value}>
										{formatMessage(messages.swiftCodeValue)}
										<CopyButton
											dataToCopy={formatMessage(messages.swiftCodeValue)}
											className={styles.copyButton}
										/>
									</div>
								</div>
							</div>
						</div>
					)}
					{ibanOption === IbanOption.SWIFT && (
						<div className={styles.moreDetails} data-cy={TestIds.MoreDetails}>
							<div className={classNames(styles.details, styles.minWidth)}>
								<div className={styles.label}>
									{formatMessage(messages.correspondentBankNameLabel)}
								</div>
								<div className={styles.value}>
									<div className={styles.value}>
										{formatMessage(messages.correspondentBankNameValue)}
										<CopyButton
											dataToCopy={formatMessage(
												messages.correspondentBankNameValue
											)}
											className={styles.copyButton}
										/>
									</div>
								</div>
							</div>
							<div className={styles.details}>
								<div className={styles.label}>
									{formatMessage(messages.correspondentBankAddressLabel)}
								</div>
								<div className={styles.value}>
									<div className={classNames(styles.value, styles.addressValue)}>
										{formatMessage(messages.correspondentBankAddressValue)}
										<CopyButton
											dataToCopy={formatMessage(
												messages.correspondentBankAddressValue
											)}
											className={styles.copyButton}
										/>
									</div>
								</div>
							</div>
							<div className={styles.details}>
								<div className={styles.label}>
									{formatMessage(messages.correspondentBankSwiftLabel)}
								</div>
								<div className={styles.value}>
									<div className={styles.value}>
										{formatMessage(messages.correspondentBankSwiftValue)}
										<CopyButton
											dataToCopy={formatMessage(
												messages.correspondentBankSwiftValue
											)}
											className={styles.copyButton}
										/>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	return (
		<div className={styles.container}>
			<div className={styles.title}>{formatMessage(messages.accountDetails)}</div>
			<div className={styles.flexContainer}>
				{user?.name && user?.surname && (
					<div className={styles.details}>
						<div className={styles.label}>{formatMessage(messages.name)}</div>
						<div className={styles.value}>
							{user?.name} {user?.surname}
						</div>
					</div>
				)}
				<div className={styles.details}>
					<div className={styles.label}>{formatMessage(messages.ibanLabel)}</div>
					<div className={classNames(styles.value, styles.ibanNumber)}>
						LT62 <span className={styles.blur}>#### #### #### ####</span>
						<Tooltip
							content={
								<div className={styles.tooltipContainer}>
									{user?.verified ? (
										<p
											dangerouslySetInnerHTML={{
												__html: formatMessage(messages.whatIsIBAN),
											}}
										/>
									) : (
										<p
											dangerouslySetInnerHTML={{
												__html: formatMessage(
													messages.whatIsIBANForUnverified
												),
											}}
										/>
									)}
								</div>
							}
						>
							<div className={styles.tooltipToggle}>
								<i className="fa fa-info" />
							</div>
						</Tooltip>
					</div>
				</div>
				{ibanInfo?.status && (
					<div className={styles.details}>
						<div className={styles.label}>{formatMessage(messages.status)}</div>
						<div className={styles.value}>
							{formatMessage(getStatusString(ibanInfo.status))}
						</div>
					</div>
				)}
				<div className={classNames(styles.details, styles.centered)}>
					<Button
						type={ButtonType.LINK}
						text={messages.claimIban}
						link={
							isIBANAvailable && user?.verified && user?.phoneVerified
								? `/${locale}/account/history/${currencyCode}/${id}/IBAN`
								: `${url}`
						}
						disabled={
							!isIBANAvailable ||
							!user?.verified ||
							!user?.phoneVerified ||
							isIbanClosed
						}
					/>
					{(!isIBANAvailable ||
						!user?.verified ||
						!user?.phoneVerified ||
						isIbanClosed) && (
						<a
							href={
								isIbanClosed ? 'mailto:operations@spectrocoin.com' : verificationUrl
							}
							className={styles.link}
						>
							{formatMessage(
								isIbanClosed ? messages.newIbanRequest : messages.verifyAccount
							)}
						</a>
					)}
				</div>
			</div>
		</div>
	);
};

export default IBANBlock;
