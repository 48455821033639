import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faXmark } from '@fortawesome/free-solid-svg-icons';
import styles from './AdditionalDocumentsModal.module.scss';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import upload from '../../../../images/upload.svg';
import baseMsg from '../../../../messages/base.messages';
import { getAdditionalFileUploadWithdraw } from '../../../../redux/endpoints';
import TwoFaContainer from '../../../../containers/TwoFaContainer/TwoFaContainer';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import { RemoteData, RemoteError, RemoteStatus } from '../../../../interfaces/RemoteData';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { fromEntries } from '../../../../helpers/objectHelper/objectHelper';
import CloseIcon from '../../../../components/CloseIcon/CloseIcon';
import axiosInstance from '../../../../helpers/axiosInstance';

interface AdditionalDocumentsModalProps {
	withdrawId: string;
	onSuccess: () => void;
}

const messages = defineMessages({
	title: {
		id: 'additionalDocumentsModal.title',
		defaultMessage: 'Upload additional documents',
	},
	upTo5Files: {
		id: 'additionalDocumentsModal.upTo5Files',
		defaultMessage: 'Up to 5 files allowed',
	},
	optional: {
		id: 'additionalDocumentsModal.optional',
		defaultMessage: 'File upload is optional',
	},
	dragAndDrop: {
		id: 'additionalDocumentsModal.dragAndDrop',
		defaultMessage: 'Drag and drop your files here',
	},
	allowedTypes: {
		id: 'additionalDocumentsModal.allowedTypes',
		defaultMessage: 'Allowed types: pdf, png, jpg',
	},
});

const AdditionalDocumentsModal: FC<AdditionalDocumentsModalProps> = ({ withdrawId, onSuccess }) => {
	const dispatch = useDispatch();
	const [files, setFiles] = useState<File[]>([]);
	const [action, setAction] = useState<RemoteData<void>>({ status: RemoteStatus.None });
	const { formatMessage } = useIntl();

	const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length) {
			if (files.find(({ name }) => name === e.target.files![0].name)) return;
			setFiles([...files, e.target.files[0]]);
		}
	};

	const handleError = (e: RemoteError) => {
		setAction({ status: RemoteStatus.Error, error: e });
	};

	const handleSuccess = () => {
		setAction({ status: RemoteStatus.Done });
		onSuccess();
		dispatch(toggleModal());
	};

	const handleSubmit = () => {
		setAction({ status: RemoteStatus.InProgress });
		const formData = new FormData();
		files.forEach((file) => formData.append('file', file));

		axiosInstance
			.post(getAdditionalFileUploadWithdraw(withdrawId), formData)
			.then(handleSuccess)
			.catch(handleError);
	};

	return (
		<div className={styles.container}>
			<CloseIcon
				onClick={() => action.status !== RemoteStatus.InProgress && dispatch(toggleModal())}
			/>
			<TwoFaContainer
				onSuccess={handleSuccess}
				onFailure={handleError}
				onSubmit={handleSubmit}
				isLoading={action.status === RemoteStatus.InProgress}
				isDisabled={files.length === 0 || files.length > 5}
				hideForm
				buttonText={baseMsg.submit}
			>
				<h1 className={styles.title}>{formatMessage(messages.title)}</h1>
				<ul>
					<li>{formatMessage(messages.upTo5Files)}</li>
					<li>{formatMessage(messages.optional)}</li>
					<li>{formatMessage(messages.allowedTypes)}</li>
				</ul>
				<div className={styles.fileUpload}>
					<input
						onChange={onFileChange}
						id="fileInput"
						type="file"
						accept=".pdf, .png, .jpg"
					/>
					<img src={upload} alt="" />
					<h3>{formatMessage(messages.dragAndDrop)}</h3>
				</div>
				<div className={styles.files}>
					{files.map((file) => (
						<div key={`${file.name}_additional_file`} className={styles.file}>
							<div className={styles.fileName}>
								<FontAwesomeIcon className={styles.fileIcon} icon={faFile} />
								{file.name}
							</div>
							<FontAwesomeIcon
								className={styles.xMark}
								onClick={() =>
									setFiles(files.filter(({ name }) => file.name !== name))
								}
								icon={faXmark}
							/>
						</div>
					))}
				</div>
				{action.status === RemoteStatus.Error && (
					<NotificationMessage
						withIcon
						type={NotificationType.Error}
						className={styles.error}
						style={NotificationStyle.Border}
						message={formatMessage(
							getErrorMessageOrDefault(action.error),
							action.error?.errorParameters
								? fromEntries<Record<string, string>>(
										action.error?.errorParameters?.map(({ key, value }) => [
											key,
											value,
										])
								  )
								: undefined
						)}
					/>
				)}
			</TwoFaContainer>
		</div>
	);
};

export default AdditionalDocumentsModal;
