import { RemoteData, RemoteError } from '../../../../interfaces/RemoteData';
import PageableResponse from '../../../../interfaces/PageableResponse';
import { Project } from '../ProjectsData/ProjectsDataTypes';

export enum ProjectsListMode {
	View = 1,
	Delete = 2,
}

export enum ProjectsListStatus {
	Initial,
	Loading,
	Empty,
	Error,
	Ready,
}

export type ProjectsListState = {
	pages: Record<number, RemoteData<string[]>>;
	totalCount: number;
	status: ProjectsListStatus;
	mode: ProjectsListMode;
	pageSize: number;
	filter: {
		search: string;
		tags: {
			enabled: boolean;
			disabled: boolean;
			fiat: boolean;
			crypto: boolean;
		};
	};
	delete: RemoteData<void>;
	error?: string;
};

export enum ProjectsListActionType {
	FETCH_PROJECTS_PENDING = `MERCHANTS/PROJECTS/LIST/FETCH_PROJECTS/PENDING`,
	FETCH_PROJECTS_REJECTED = 'MERCHANTS/PROJECTS/LIST/FETCH_PROJECTS/REJECTED',
	FETCH_PROJECTS_FULFILLED = 'MERCHANTS/PROJECTS/LIST/FETCH_PROJECTS/FULFILLED',
	RESET_PAGES = 'MERCHANTS/PROJECTS/LIST/RESET_PAGES',
	SET_TAGS = 'MERCHANTS/PROJECTS/LIST/SET_TAGS',
	SET_SEARCH = 'MERCHANTS/PROJECTS/LIST/SET_SEARCH',
	SET_STATUS = 'MERCHANTS/PROJECTS/LIST/SET_STATUS',
	SET_MODE = 'MERCHANTS/PROJECTS/LIST/SET_MODE',
	RESET_DELETE = 'MERCHANTS/PROJECTS/LIST/RESET_DELETE',
	DELETE_PENDING = 'MERCHANTS/PROJECTS/LIST/DELETE/PENDING',
	DELETE_REJECTED = 'MERCHANTS/PROJECTS/LIST/DELETE/REJECTED',
	DELETE_FULFILLED = 'MERCHANTS/PROJECTS/LIST/DELETE/FULFILLED',
}

export type ProjectsListFetchProjectsPendingAction = {
	type: ProjectsListActionType.FETCH_PROJECTS_PENDING;
	payload: number;
};
export type ProjectsListFetchProjectsRejectedAction = {
	type: ProjectsListActionType.FETCH_PROJECTS_REJECTED;
	payload: {
		page: number;
		error?: RemoteError;
	};
};
export type ProjectsListFetchProjectsFulfilledAction = {
	type: ProjectsListActionType.FETCH_PROJECTS_FULFILLED;
	payload: {
		page: number;
		projects: PageableResponse<Project[]>;
	};
};

export type ProjectsListResetPagesAction = {
	type: ProjectsListActionType.RESET_PAGES;
};

export type ProjectsListSetSearchAction = {
	type: ProjectsListActionType.SET_SEARCH;
	payload: string;
};

export type ProjectsListSetTagsAction = {
	type: ProjectsListActionType.SET_TAGS;
	payload: Partial<ProjectsListState['filter']['tags']>;
};

export type ProjectsListSetStatusAction = {
	type: ProjectsListActionType.SET_STATUS;
	payload: ProjectsListStatus;
};

export type ProjectsListSetModeAction = {
	type: ProjectsListActionType.SET_MODE;
	payload: ProjectsListMode;
};

export type ProjectsListResetDeleteAction = {
	type: ProjectsListActionType.RESET_DELETE;
};

export type ProjectsListProjectDeletePendingAction = {
	type: ProjectsListActionType.DELETE_PENDING;
};

export type ProjectsListProjectDeleteRejectedAction = {
	type: ProjectsListActionType.DELETE_REJECTED;
	payload?: RemoteError;
};

export type ProjectsListProjectDeleteFulfilledAction = {
	type: ProjectsListActionType.DELETE_FULFILLED;
	payload: string;
};
