import { useRef } from 'react';
import useEffectOnce from './useEffectOnce';

const useDidMount = () => {
	const didMount = useRef(false);
	useEffectOnce(() => {
		didMount.current = true;
	});

	return didMount.current;
};

export default useDidMount;
