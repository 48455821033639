import { FC } from 'react';
import { useSelector } from 'react-redux';
import Program from '../Shared/Program/Program';
import ProfitSummary from '../Shared/ProfitSummary/ProfitSummary';
import Tracking from '../Shared/Tracking/Tracking';
import Summary from '../Shared/Summary/Summary';
import DefaultLink from '../Shared/DefaultLink/DefaultLink';
import styles from './Home.module.scss';
import { RootState } from '../../../redux/Store';
import Sidebar from '../Shared/Sidebar/Sidebar';

const Desktop: FC = () => {
	return (
		<div className={styles.layout}>
			<Sidebar />
			<div className={styles.main}>
				<DefaultLink />
				<Tracking />
				<Summary />
			</div>
		</div>
	);
};

const Tablet: FC = () => {
	return (
		<div className={styles.main}>
			<Program />
			<ProfitSummary />
			<DefaultLink />
			<Tracking />
			<Summary />
		</div>
	);
};

const Home: FC = () => {
	const { isTablet } = useSelector((state: RootState) => state.AppState);
	return isTablet ? <Tablet /> : <Desktop />;
};

export default Home;
