import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import config from '../configs/config';

interface ReferralData {
	referralId?: string;
	refTrackId?: string;
}

const useReferral = () => {
	const [referralID, setReferralID] = useState<string | null>(null);
	const [refTrackID, setRefTrackID] = useState<string | null>(null);

	let cookieData: ReferralData = {};
	const mergedData: ReferralData = {};

	try {
		cookieData = JSON.parse(Cookies.get(config.REFERRAL_COOKIE_NAME!) || '');
	} catch (e) {
		// It's OK
	}
	const searchParams = new URLSearchParams(
		typeof window !== 'undefined' ? window.location.search : ''
	);

	mergedData.referralId = searchParams.get('referralId') || cookieData.referralId;
	mergedData.refTrackId = searchParams.get('refTrackId') || cookieData.refTrackId;

	useEffect(() => {
		if (
			(mergedData.referralId && cookieData.referralId !== mergedData.referralId) ||
			(mergedData.refTrackId && cookieData.refTrackId !== mergedData.refTrackId)
		) {
			Cookies.set(
				config.REFERRAL_COOKIE_NAME!,
				JSON.stringify({
					referralId: mergedData.referralId,
					refTrackId: mergedData.refTrackId,
				}),
				{
					expires: Number(config.REFERRAL_COOKIE_EXPIRATION_PERIOD_IN_DAYS),
					domain: 'spectrocoin.com',
				}
			);
		}
		setReferralID(mergedData.referralId || null);
		setRefTrackID(mergedData.refTrackId || null);
	}, [
		mergedData.referralId,
		mergedData.refTrackId,
		cookieData.referralId,
		cookieData.refTrackId,
	]);

	return { referralID, refTrackID };
};

export default useReferral;
