import { AxiosError } from 'axios';
import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RemoteError } from '../../../../interfaces/RemoteData';
import PageableQuery from '../../../../interfaces/PageableQuery';
import type { RootState } from '../../../Store';
import PageableResponse from '../../../../interfaces/PageableResponse';
import { getCryptoPayoutsUrl } from '../../../endpoints';
import {
	PayoutsHistoryFetchPayoutsFulfilledAction,
	PayoutsHistoryFetchPayoutsPendingAction,
	PayoutsHistoryFetchPayoutsRejectedAction,
	PayoutsHistoryActionType,
} from './PayoutsHistoryTypes';
import { Payout } from '../PayoutsData/PayoutsDataTypes';
import axiosInstance from '../../../../helpers/axiosInstance';

const fetchPayoutsPending: ActionCreator<PayoutsHistoryFetchPayoutsPendingAction> = () => ({
	type: PayoutsHistoryActionType.FETCH_PAYOUTS_PENDING,
});

const fetchPayoutsRejected: ActionCreator<PayoutsHistoryFetchPayoutsRejectedAction> = (
	error?: RemoteError
) => ({
	type: PayoutsHistoryActionType.FETCH_PAYOUTS_REJECTED,
	payload: error,
});

const fetchPayoutsFulfilled: ActionCreator<PayoutsHistoryFetchPayoutsFulfilledAction> = (
	payouts: PageableResponse<Payout[]>
) => ({
	type: PayoutsHistoryActionType.FETCH_PAYOUTS_FULFILLED,
	payload: payouts,
});

const fetchPayouts = (
	query: PageableQuery<any>
): ThunkAction<Promise<PageableResponse<Payout[]> | void>, RootState, undefined, any> => async (
	dispatch
) => {
	dispatch(fetchPayoutsPending());

	return axiosInstance
		.get<PageableResponse<Payout[]>>(getCryptoPayoutsUrl(), {
			params: {
				...query,
				sort: 'created,desc',
				size: 10,
			},
		})
		.then(({ data }) => {
			dispatch(fetchPayoutsFulfilled(data));
			return data;
		})
		.catch((e: AxiosError) => {
			dispatch(fetchPayoutsRejected(e.response?.data));
		});
};

export default {
	fetchPayouts,
};
