import { UserPreviousPageForCompanyMovement } from '../../enums/UserContractCompanyEnum';

interface CompanyAndPreviousPageHandlerParams {
	toCompany: string | null;
	previousPage: UserPreviousPageForCompanyMovement;
}

// eslint-disable-next-line import/prefer-default-export
export const companyAndPreviousPageHandler = ({
	toCompany,
	previousPage,
}: CompanyAndPreviousPageHandlerParams) => {
	if (toCompany) {
		localStorage.setItem('toCompany', toCompany);
		localStorage.setItem('previousPage', previousPage);
	} else {
		localStorage.removeItem('toCompany');
		localStorage.removeItem('previousPage');
	}
};
