import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import useMerchantRoutes, { MerchantRoutes } from '../../../../../hooks/useMerchantRoutes';
import messages from '../../../../../redux/MerchantsState/MerchantsMessages';
import CopyButton from '../../../../../components/CopyButton/CopyButton';
import toggleModal from '../../../../../redux/ModalState/ModalActions';

import styles from './PublicOrderLinkModal.module.scss';
import TestIds from '../../../../../test/TestIds';

type PublicOrderLinkModalProps = {
	id: string;
};

const PublicOrderLinkModal: FC<PublicOrderLinkModalProps> = ({ id }) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { getUrl } = useMerchantRoutes();

	const onClose = () => {
		dispatch(toggleModal());
	};

	const link = new URL(
		getUrl(MerchantRoutes.PublicPayment, { projectId: id }),
		window.location.origin
	).href;

	return (
		<div className={styles.container} data-cy={TestIds.ProjectViewPublicModal}>
			<div className={styles.header}>
				<FontAwesomeIcon
					data-cy={TestIds.ProjectViewPublicModalClose}
					className={styles.closeIcon}
					icon={faTimes}
					onClick={onClose}
					size="lg"
				/>
			</div>

			<div className={styles.body}>
				<div className={styles.label}>
					{formatMessage(messages.publicPaymentLink)}
					<CopyButton className={styles.copyButton} dataToCopy={link} />
				</div>
				<div data-cy={TestIds.ProjectViewPublicModalLink} className={styles.value}>
					{link}
				</div>
			</div>
		</div>
	);
};

export default PublicOrderLinkModal;
