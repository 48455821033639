import createSelector from '../../../createSelector';

const getState = createSelector((state) => state.MerchantState.preorders.create);

const getCreateForm = createSelector(getState, (state) => state.form);

const getAction = createSelector(getState, (state) => state.action);

export default {
	getState,
	getCreateForm,
	getAction,
};
