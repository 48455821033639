import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import Joi from 'joi';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import {
	isAmountGreaterThan,
	isAmountGreaterThanZero,
	isAmountLessThan,
} from '../../../../helpers/inputValidation/inputValidation';
import inputErrors from '../../../../messages/inputErrors.messages';
import { PaymentMethod, ProviderType } from '../../../../redux/WithdrawState/WithdrawTypes';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';
import errorMessages from '../../../../helpers/errorMessageHelper/errorMessageHelper';

const useEWalletFormValidator = (
	currency: string,
	currentMethod: PaymentMethod,
	providerType: ProviderType,
	balance: string
) => {
	const { formatMessage } = useIntl();
	return useMemo(
		() =>
			Joi.object({
				currency: Joi.string()
					.required()
					.messages({ 'string.empty': formatMessage(inputErrors.cannotBeEmpty) }),
				email: Joi.string()
					.required()
					.messages({
						'string.empty': formatMessage(inputErrors.cannotBeEmpty),
					}),
				...(providerType === ProviderType.SKRILL && {
					email: Joi.string()
						.required()
						.email({ tlds: { allow: false } })
						.messages({
							'string.empty': formatMessage(inputErrors.cannotBeEmpty),
							'string.email': formatMessage(errorMessages.WS_29),
						}),
				}),
				amount: Joi.string()
					.required()
					.custom((value: string, helper: any) => {
						if (!isAmountGreaterThanZero(value))
							return helper.message(formatMessage(inputErrors.amountTooLow));
						if (isAmountGreaterThan(value, balance))
							return helper.message(formatMessage(inputErrors.insufficientFunds));
						if (
							currentMethod?.maxAmount &&
							isAmountGreaterThan(value, currentMethod?.maxAmount || '0')
						)
							return helper.message(
								formatMessage(withdrawMessages.maxAmount, {
									amount: `${formatPrecision(
										currentMethod?.maxAmount || '0',
										CurrencyEnum[currency.split('/')[0]]
									)} ${currency.split('/')[0]}`,
								})
							);
						if (
							currentMethod?.minAmount &&
							isAmountLessThan(value, currentMethod?.minAmount || '0')
						)
							return helper.message(
								formatMessage(withdrawMessages.minAmount, {
									amount: `${formatPrecision(
										currentMethod?.minAmount || '0',
										CurrencyEnum[currency.split('/')[0]]
									)} ${currency.split('/')[0]}`,
								})
							);
						return true;
					})
					.messages({
						'string.empty': formatMessage(inputErrors.cannotBeEmpty),
					}),
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[currency, balance, providerType, currentMethod]
	);
};

export default useEWalletFormValidator;
