import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import AccessToken from '../../interfaces/AccessToken';

/* eslint-disable */
export const extractAuthMessageParemeters = (messageParameters: any[]) =>
	messageParameters
		? messageParameters.reduce((acc, param) => {
				acc[param.key] = param.value;
				return acc;
		  }, {})
		: {};
/* eslint-enable */

export const zeroPad = (digit: number, places = 2) => {
	const zerosCount = places - digit.toString().length + 1;
	const leadingZeros = new Array(+(zerosCount > 0 && zerosCount)).join('0');
	return `${leadingZeros}${digit}`;
};

export const getAccessToken = (): AccessToken | null => {
	const cookie = Cookies.get('ACCESS_TOKEN');
	const token = cookie ? (jwtDecode(cookie) as AccessToken) : null;
	return token;
};

export const makeAuthDateUTC = (date: string) => {
	// Our backend misses the "Z" at the end of the date, so JS doesn't know this is time in UTC
	let fixedDate = date;
	if (date[date.length - 1] !== 'Z') {
		fixedDate += 'Z';
	}
	return fixedDate;
};
