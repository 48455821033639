import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Wallet } from '../redux/AccountsState/AccountsTypes';
import { RootState } from '../redux/Store';

interface Params {
	id: string;
	currencyCode: CurrencyEnum;
}

export default function useCurrentWallet(currencyCode?: CurrencyEnum) {
	const { id } = useParams<Params>();
	const { wallets } = useSelector((state: RootState) => state?.AccountsState);
	const [wallet, setWallet] = useState<Wallet | null>(null);

	useEffect(() => {
		setWallet(
			wallets?.find((o: Wallet) => o.id === id || o.currencyCode === currencyCode) || null
		);
	}, [currencyCode, id, wallets]);

	return wallet;
}
