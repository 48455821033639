import { MessageDescriptor, useIntl } from 'react-intl';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import classNames from 'classnames';
import baseMsg from '../../messages/base.messages';
import Input from '../Input/Input';
import styles from './SearchBar.module.scss';

interface SearchBarProps {
	nameFilter: string;
	onChange: (val: string) => void;
	isLabelVisible?: boolean;
	className?: string;
}

const SearchBar = ({ nameFilter, onChange, isLabelVisible = true, className }: SearchBarProps) => {
	const { formatMessage } = useIntl();

	return (
		<div className={classNames(styles.searchRow, className)}>
			{isLabelVisible && <span>{formatMessage(baseMsg.search)}</span>}
			<Input
				inputGroupClassName={styles.searchInput}
				placeholder={baseMsg.search}
				faIcon={faSearch}
				value={nameFilter}
				onChange={onChange}
				data-cy="searchBar"
			/>
		</div>
	);
};

export default SearchBar;
