import { RemoteStatus } from '../../../../interfaces/RemoteData';
import createSelector from '../../../createSelector';

const getState = createSelector((state) => state.MerchantState.preorders.data);

const getPreorderById = (id: string) =>
	createSelector(getState, (state) => state.preorders[id] || { status: RemoteStatus.None });

const getPreorderDataById = (id: string) =>
	createSelector(getPreorderById(id), (state) => state?.data);

const getPreordersDataByIds = (ids: string[]) =>
	createSelector(getState, (state) =>
		ids
			.map((id) => state.preorders[id])
			.filter((x) => x?.status === RemoteStatus.Done)
			.map((x) => x.data!)
	);

export default {
	getState,
	getPreorderById,
	getPreorderDataById,
	getPreordersDataByIds,
};
