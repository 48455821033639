import PageableResponse from '../../interfaces/PageableResponse';

export enum DocumentsHistoryType {
	IS_LOADING = 'DOCUMENTS/IS_LOADING',
	SET_DATA = 'DOCUMENTS/SET_DATA',
	RESET_DATA = 'DOCUMENTS/RESET_DATA',
	DELETE_DOCUMENT = 'DOCUMENTS/DELETE_DOCUMENT',
}

export interface DocumentItem {
	comment: string | null;
	date: string;
	id: string;
	isGdprDoc: boolean;
	name: string;
	size: number;
	type: string;
	viewed: boolean;
}

export interface DocumentsHistoryResponse extends PageableResponse<DocumentItem[]> {}

export interface DocumentstHistoryState {
	isLoading: boolean;
	data: DocumentItem[];
	totalCount: number;
}

export interface SetIsDocumentsHistoryLoading {
	type: typeof DocumentsHistoryType.IS_LOADING;
	isLoading: boolean;
}

export interface SetDocumentsHistoryDataAction {
	type: typeof DocumentsHistoryType.SET_DATA;
	data: DocumentItem[];
	totalCount: number;
}

export interface ResetDocumentsHistoryAction {
	type: typeof DocumentsHistoryType.RESET_DATA;
}

export interface RemoveDocumentAction {
	type: typeof DocumentsHistoryType.DELETE_DOCUMENT;
	data: string;
}
