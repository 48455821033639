/* eslint-disable no-template-curly-in-string */
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { CurrencyEnum, CurrencyIcon } from '@spectrocoin/sc-currencies';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { AxiosResponse } from 'axios';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';
import styles from './PayoutSummary.module.scss';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import { DetailField } from '../../../../components/Modal/DetailsModal/DetailsModal';
import baseMsg from '../../../../messages/base.messages';
import { formatPrecision, toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import StatusLabel from '../StatusLabel/StatusLabel';
import MerchantsSelectors from '../../../../redux/MerchantsState/MerchantsSelectors';
import MerchantsActions from '../../../../redux/MerchantsState/MerchantsActions';
import { RemoteError, RemoteStatus } from '../../../../interfaces/RemoteData';
import { getAccountNetwork } from '../../../../redux/AccountsState/AccountsActions';
import useCurrentWallet from '../../../../hooks/useCurrentWallet';
import Loader from '../../../../components/Loader/Loader';
import Button, { ButtonStyle } from '../../../../components/Button/Button';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import {
	Payout,
	PayoutStatus,
} from '../../../../redux/MerchantsState/CryptoPayouts/PayoutsData/PayoutsDataTypes';
import CancelPayout from '../CancelPayout/CancelPayout';
import TwoFaContainer from '../../../../containers/TwoFaContainer/TwoFaContainer';
import { getCryptoPayoutApproveULR, getEstimatePayoutURL } from '../../../../redux/endpoints';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { resetTwoFaData } from '../../../../redux/TwoFaState/TwoFaActions';
import {
	ErrorMessageCodes,
	getErrorMessageOrDefault,
} from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import { TwoFaCode } from '../../../../redux/TwoFaState/TwoFaTypes';
import { fromEntries } from '../../../../helpers/objectHelper/objectHelper';
import axiosInstance from '../../../../helpers/axiosInstance';

interface NetworkInfo {
	publicName: string;
	currency: CurrencyEnum | null;
}

interface EstimateProps {
	feeAmount: string;
	currencyCode: CurrencyEnum;
	receiveAmount: string;
	receiveCurrencyCode: CurrencyEnum;
}

const PayoutSummary = () => {
	const { payoutId } = useParams<any>();
	const dispatch = useDispatch();
	const { formatMessage, locale } = useIntl();
	const { data, status } = useSelector(MerchantsSelectors.payouts.data.getPayoutById(payoutId));
	const wallet = useCurrentWallet(data?.receiveCurrencyCode);
	const [networkInfo, setNetworkInfo] = useState<NetworkInfo>({
		publicName: '--',
		currency: null,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [errorParams, setErrorParams] = useState<{ [key: string]: string }[]>([]);
	const [successMessage, setSuccessMessage] = useState<MessageDescriptor | null>(null);
	const [error, setError] = useState<MessageDescriptor | string | null>(null);
	const [isCancelVisible, setIsCancelVisible] = useState(true);
	const [estimateData, setEstimateData] = useState<EstimateProps | null>(null);

	const renderContent = ({
		id,
		payAmount,
		payCurrencyCode,
		receiveCurrencyCode,
		receiverAddress,
		receiverMessage,
		callbackUrl,
		expiresAt,
		status: payoutStatus,
	}: Payout) => (
		<>
			<DetailField label={baseMsg.id} value={id} data-cy="id" />
			<DetailField
				label={messages.payAmount}
				value={`${formatPrecision(payAmount, payCurrencyCode)} ${payCurrencyCode}`}
				data-cy="payAmount"
			/>
			<DetailField
				label={messages.receiveCurrency}
				value={
					<div className={styles.flex}>
						<CurrencyIcon currencyType={receiveCurrencyCode} className={styles.icon} />
						{receiveCurrencyCode}
					</div>
				}
				data-cy="receiveCurrency"
			/>
			<DetailField
				label={messages.receivingAddress}
				value={receiverAddress}
				data-cy="receiverAddress"
			/>
			<DetailField
				label={messages.receivingMessage}
				value={receiverMessage || '-'}
				data-cy="receivingMessage"
			/>
			{networkInfo.currency && networkInfo.publicName && (
				<DetailField
					label={baseMsg.network}
					value={
						<div className={styles.flex}>
							<CurrencyIcon
								currencyType={networkInfo.currency}
								className={styles.icon}
							/>
							{networkInfo.publicName}
						</div>
					}
					data-cy="network"
				/>
			)}
			<DetailField
				label={messages.status}
				value={<StatusLabel status={payoutStatus} />}
				data-cy="status"
			/>
			<DetailField
				label={messages.validUntil}
				value={format(new Date(expiresAt), 'yyyy-MM-dd HH:mm:ss')}
				data-cy="validUntil"
			/>
			<DetailField label={messages.callbackUrl} value={callbackUrl} data-cy="callbackUrl" />
		</>
	);

	const onSuccess = (o: Payout) => {
		dispatch(MerchantsActions.payouts.data.updatePayout(o));
		dispatch(resetTwoFaData());
		setSuccessMessage(messages.payoutSuccess);
		setIsLoading(false);
		setIsCancelVisible(true);
		setEstimateData(null);
	};

	const onFailure = (err: RemoteError) => {
		if (err.errorCode === ErrorMessageCodes.WS_59) setIsDisabled(true);
		setError(getErrorMessageOrDefault(err as RemoteError));
		if (err.errorParameters) {
			const errParamsParsed = err.errorParameters.map(({ key, value }) => {
				switch (key) {
					case 'min_receive_amount':
						return { key: 'MinReceiveAmount', value };
					default:
						return { key, value };
				}
			});
			setErrorParams(errParamsParsed);
		}
		setIsLoading(false);
		setIsCancelVisible(true);
	};

	const onSubmit = () => {
		setIsLoading(true);
		setIsCancelVisible(false);
		setError(null);
		setSuccessMessage(null);
		void axiosInstance
			.post(getCryptoPayoutApproveULR(payoutId), {})
			.then(({ data: res }) => {
				dispatch(MerchantsActions.payouts.data.updatePayout(res));
				dispatch(resetTwoFaData());
				setSuccessMessage(messages.payoutSuccess);
				setEstimateData(null);
			})
			.catch((e) => {
				if (e?.response?.data?.errorCode !== TwoFaCode.TWO_FA_REQUIRED) {
					setError(getErrorMessageOrDefault(e as RemoteError));
					if (e?.response?.data?.errorParameters) {
						const errParamsParsed = e?.response?.data?.errorParameters.map(
							({ key, value }: any) => {
								switch (key) {
									case '${min_receive_amount}':
										return { key: 'MinReceiveAmount', value };
									default:
										return { key, value };
								}
							}
						);
						setErrorParams(errParamsParsed);
					}
					setIsCancelVisible(true);
				}
			})
			.then(() => setIsLoading(false));
	};

	const estimate = () => {
		setIsLoading(true);
		setIsCancelVisible(false);
		void axiosInstance
			.get(getEstimatePayoutURL(payoutId))
			.then(({ data: res }: AxiosResponse<EstimateProps>) => setEstimateData(res))
			.catch((e) => console.log(e))
			.then(() => {
				setIsLoading(false);
				return setIsCancelVisible(true);
			});
	};

	useEffect(() => {
		void dispatch(MerchantsActions.payouts.data.fetchPayout(payoutId));
	}, [dispatch, payoutId]);

	useEffect(() => {
		if (wallet) dispatch(getAccountNetwork(wallet.currencyCode, wallet.id));
	}, [dispatch, wallet]);

	useEffect(() => {
		if (wallet?.networkList && wallet?.networkList?.length > 0) {
			const o = wallet?.networkList?.find(
				({ networkName }) => networkName === data?.networkCode
			)!;
			setNetworkInfo({
				publicName: o?.publicName || '--',
				currency: o?.feeCurrencyCode || null,
			});
		}
	}, [data?.networkCode, wallet?.networkList]);

	if (status === RemoteStatus.InProgress) return <Loader />;
	if (data) {
		return (
			<WhiteContainer>
				<PageTitle
					title={formatMessage(messages.payoutSummary)}
					previousPageLink={`/${locale}/merchants/crypto-payouts`}
					isPreviousPageLinkVisibleOnDesktop
					isPreviousPageLinkVisibleOnMobile
					className={styles.title}
				/>
				{data.status === PayoutStatus.NEW && estimateData ? (
					<>
						<TwoFaContainer
							onSuccess={onSuccess}
							onFailure={onFailure}
							onSubmit={onSubmit}
							buttonText={baseMsg.confirm}
							isLoading={isLoading}
							isDisabled={isDisabled}
						>
							{renderContent({ ...data })}
							{estimateData && (
								<div className={styles.estimate}>
									<DetailField
										label={messages.payoutFee}
										value={`${formatPrecision(
											estimateData.feeAmount,
											estimateData.currencyCode
										)} ${estimateData.currencyCode}`}
										data-cy="payoutFee"
									/>
									<DetailField
										label={messages.estimatedReceiveAmount}
										value={`${formatPrecision(
											estimateData.receiveAmount,
											estimateData.receiveCurrencyCode
										)} ${estimateData.receiveCurrencyCode}`}
										data-cy="estimatedReceiveAmount"
									/>
								</div>
							)}
						</TwoFaContainer>
						{isCancelVisible && data.status === PayoutStatus.NEW && (
							<Button
								className={styles.button}
								buttonStyle={ButtonStyle.BORDERLESS}
								text={baseMsg.cancel}
								onClick={() => dispatch(toggleModal(<CancelPayout id={data.id} />))}
							/>
						)}
						{error && (
							<NotificationMessage
								withIcon
								className={styles.success}
								message={
									typeof error === 'string' ? (
										error
									) : (
										<FormattedMessage
											values={
												errorParams &&
												fromEntries<Record<string, string>>(
													errorParams?.map(({ key, value }) => [
														key,
														value,
													])
												)
											}
											{...error}
										/>
									)
								}
								style={NotificationStyle.Border}
								type={NotificationType.Error}
							/>
						)}
					</>
				) : (
					<>
						{renderContent({ ...data })}
						{data.status === PayoutStatus.NEW && (
							<Button
								className={styles.button}
								text={messages.estimate}
								onClick={estimate}
								isLoading={isLoading}
							/>
						)}
						{isCancelVisible && data.status === PayoutStatus.NEW && (
							<Button
								className={styles.button}
								buttonStyle={ButtonStyle.BORDERLESS}
								text={baseMsg.cancel}
								onClick={() => dispatch(toggleModal(<CancelPayout id={data.id} />))}
							/>
						)}
						{successMessage && (
							<NotificationMessage
								withIcon
								className={styles.success}
								message={formatMessage(successMessage)}
								style={NotificationStyle.Border}
								type={NotificationType.Success}
							/>
						)}
					</>
				)}
			</WhiteContainer>
		);
	}
	return null;
};

export default PayoutSummary;
