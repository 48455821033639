import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './RangeSlider.module.scss';

interface RangeSliderProps {
	min: number;
	max: number;
	step: number;
	value: number;
	onChange: (value: number) => void;
}

const RangeSlider: React.FC<RangeSliderProps> = ({ min, max, step, value, onChange }) => {
	const possibleValues: number[] = Array.from(
		{ length: Math.floor((max - min) / step) + 1 },
		(_, index) => min + index * step
	);

	const selectedRangePercentage =
		(possibleValues.indexOf(value) / (possibleValues.length - 1)) * 100;

	return (
		<div className={styles.slider}>
			<input
				className={styles.input}
				type="range"
				min={min}
				max={max}
				step={step}
				value={value}
				onInput={(e: any) => onChange(+e.target.value)}
				onChange={(e) => onChange(+e.target.value)}
			/>
			<div
				className={styles.line}
				style={{
					background: `
                    linear-gradient(
                        to right,
                        #4972F4 0%,
                        #4972F4 ${selectedRangePercentage}%,
                        #C8D4FC ${selectedRangePercentage}%,
                        #C8D4FC 100%
                      )`,
				}}
			/>
			<div className={styles.bubbles}>
				{possibleValues.map((i, index) => (
					<div
						key={i}
						className={classNames(styles.bubble, {
							[styles.active]: i <= value,
							[styles.selected]: i === value,
						})}
						style={{ left: `${(index / (possibleValues.length - 1)) * 100}%` }}
					/>
				))}
			</div>
		</div>
	);
};

export default RangeSlider;
