/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { defineMessages, useIntl, FormattedMessage, MessageDescriptor } from 'react-intl';
import { AxiosResponse } from 'axios';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getClaimIBANURL, getConfirmDataTransferURL } from '../../../redux/endpoints';
import claimIBANIconPath from '../../../images/iban_icons/claim.svg';
import { RootState } from '../../../redux/Store';
import IbanProcessStatusProps from '../../../interfaces/IBANProcessStatus';
import Seo from '../../../components/Seo/Seo';
import Button, { ButtonType } from '../../../components/Button/Button';
import styles from './ClaimIBAN.module.scss';
import { useFormatRoute } from '../../../helpers/languagePathHelper/languagePathHelper';
import routes from '../../../route.messages';
import usePolicyRoute from '../../../hooks/usePolicyRoute';
import config from '../../../configs/config';
import axiosInstance from '../../../helpers/axiosInstance';

const messages = defineMessages({
	title: {
		id: 'claimIBAN.title',
		defaultMessage: 'Claim IBAN',
	},
	next: {
		id: 'claimIBAN.next',
		defaultMessage: 'Next',
	},
	somethingWentWrong: {
		id: 'claimIBAN.somethingWentWrong',
		defaultMessage: 'Something went wrong. Please try again later.',
	},
	metaTitle: {
		id: 'claimIBAN.metaTitle',
		defaultMessage: 'Claim IBAN',
	},
});

interface ClaimIBANProps {
	process: IbanProcessStatusProps | null;
}

const ClaimIBAN = ({ process }: ClaimIBANProps) => {
	const { formatMessage, locale } = useIntl();
	const { url } = useRouteMatch();
	const { push } = useHistory();
	const policyRoute = usePolicyRoute();
	const linkToPersonalIBAN = useFormatRoute(routes.personalIban);
	const { user } = useSelector((state: RootState) => state?.ProfileState);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<MessageDescriptor | null>(null);

	const nextStep = () => {
		setIsLoading(true);
		setErrorMessage(null);
		const { verified, phoneVerified } = user!;
		if (!verified || !phoneVerified)
			return window.location.assign(`${config.PROFILE_DOMAIN}/${locale}/verification/phone`);

		return axiosInstance
			.post(getConfirmDataTransferURL())
			.then(() => {
				axiosInstance
					.post(getClaimIBANURL())
					.then(({ data }: AxiosResponse<IbanProcessStatusProps>) => {
						setIsLoading(false);
						const location = {
							pathname: `${url.replace('claim', 'process')}`,
							state: {
								process: data,
							},
						};
						return push(location);
					})
					.catch(() => {
						setIsLoading(false);
						return setErrorMessage(messages.somethingWentWrong);
					});
			})
			.catch(() => {
				setIsLoading(false);
				return setErrorMessage(messages.somethingWentWrong);
			});
	};

	return (
		<div className={styles.container}>
			<Seo title={messages.metaTitle} />
			<h2 className={styles.title}>{formatMessage(messages.title)}</h2>
			<img src={claimIBANIconPath} className={styles.img} alt="" />
			<p className={styles.paragraph}>
				<FormattedMessage
					id="claimIBAN.paragraph1"
					defaultMessage="By claiming IBAN, I confirm to be duly informed that my verification data submitted or the data which shall be submitted to SpectroCoin later, will be submitted to Pervesk to open and manage IBAN account at Pervesk."
				/>
			</p>
			<p className={styles.paragraph}>
				<FormattedMessage
					id="claimIBAN.paragraph2"
					defaultMessage="For more information about your personal data processing at SpectroCoin, check our <a>Privacy Policy.</a>"
					values={{
						a: (...text) => <a href={policyRoute}>{text}</a>,
					}}
				/>
			</p>
			<p className={styles.paragraph}>
				<FormattedMessage
					id="claimIBAN.paragraph3"
					defaultMessage="You can find more information about the IBAN account <a>here.</a>"
					values={{
						a: (...text) => <a href={linkToPersonalIBAN}>{text}</a>,
					}}
				/>
			</p>
			<div className={styles.buttonContainer}>
				<Button
					text={formatMessage(messages.next)}
					isLoading={isLoading}
					isDisabled={isLoading}
					onClick={() => nextStep()}
					type={ButtonType.BUTTON}
				/>
				{errorMessage && <div className={styles.error}>{formatMessage(errorMessage)}</div>}
			</div>
		</div>
	);
};

export default ClaimIBAN;
