/* eslint-disable prettier/prettier */
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import classNames from 'classnames';
import { useState } from 'react';
import {
	CryptoWithdrawResponse,
	VoucherResponse,
	WithdrawStatus,
	WithdrawType,
} from '../../../../redux/WithdrawState/WithdrawTypes';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import WithdrawSummary from '../WithdrawSummary/WithdrawSummary';
import successImgPath from './images/success.svg';
import failedImgPath from './images/failed.svg';
import pendingImgPath from './images/pending.svg';
import styles from './WithdrawSuccess.module.scss';
import baseMsg from '../../../../messages/base.messages';
import TestIds from '../../../../test/TestIds';
import AdditionalDocumentsModal from '../AdditionalDocumentsModal/AdditionalDocumentsModal';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';

const messages = defineMessages({
	generalSuccess: {
		id: 'withdrawSuccess.generalSuccess',
		defaultMessage: 'Your withdrawal has been successful',
	},
	generalText: {
		id: 'withdrawSuccess.generalText',
		defaultMessage:
			'Your {currencyName} are on the way to the receiver. Please contact support if you have any questions regarding your transfer.',
	},
	cryptoTitlePAID: {
		id: 'withdrawSuccess.cryptoTitlePAID',
		defaultMessage: 'Your Request to send {currency} is submitted',
	},
	offchainTitlePAID: {
		id: 'withdrawSuccess.offchainTitlePAID',
		defaultMessage: 'Your offchain withdrawal has been successful',
	},
	ewalletTitlePAID: {
		id: 'withdrawSuccess.ewalletTitlePAID',
		defaultMessage: 'Your withdrawal has been successful',
	},
	bankTitlePAID: {
		id: 'withdrawSuccess.bankTitlePAID',
		defaultMessage: 'Your withdrawal has been successful',
	},
	giftCardsTitlePAID: {
		id: 'withdrawSuccess.giftCardsTitlePAID',
		defaultMessage: 'Your gift card purchase has been successful',
	},
	voucherTitlePAID: {
		id: 'withdrawSuccess.voucherTitlePAID',
		defaultMessage: 'Your voucher withdrawal has been successful',
	},
	mobileTopupsTitlePAID: {
		id: 'withdrawSuccess.mobileTopupsTitlePAID',
		defaultMessage: 'Your mobile top-up purchase has been successful',
	},
	cryptoTextPAID: {
		id: 'withdrawSuccess.cryptoTextPAID',
		defaultMessage: 'Your Request to send {currency} is submitted',
	},
	offchainTextPAID: {
		id: 'withdrawSuccess.offchainTextPAID',
		defaultMessage: 'Your offchain withdrawal has been successful',
	},
	ewalletTextPAID: {
		id: 'withdrawSuccess.ewalletTextPAID',
		defaultMessage: 'Your withdrawal has been successful',
	},
	bankTextPAID: {
		id: 'withdrawSuccess.bankTextPAID',
		defaultMessage: 'Your withdrawal has been successful',
	},
	giftCardsTextPAID: {
		id: 'withdrawSuccess.giftCardsTextPAID',
		defaultMessage: 'Your gift card purchase has been successful',
	},
	voucherTextPAID: {
		id: 'withdrawSuccess.voucherTextPAID',
		defaultMessage: 'Your voucher withdrawal has been successful',
	},
	mobileTopupsTextPAID: {
		id: 'withdrawSuccess.mobileTopupsTextPAID',
		defaultMessage: 'Your mobile top-up purchase has been successful',
	},
	viewSummary: {
		id: 'withdrawSuccess.viewSummary',
		defaultMessage: 'View withdrawal summary',
	},
	cryptoTitlePENDING: {
		id: 'withdrawSuccess.cryptoTitlePENDING',
		defaultMessage: 'Your Request to send {currency} is pending',
	},
	offchainTitlePENDING: {
		id: 'withdrawSuccess.offchainTitlePENDING',
		defaultMessage: 'Your offchain withdrawal is pending',
	},
	ewalletTitlePENDING: {
		id: 'withdrawSuccess.ewalletTitlePENDING',
		defaultMessage: 'Your withdrawal is pending',
	},
	bankTitlePENDING: {
		id: 'withdrawSuccess.bankTitlePENDING',
		defaultMessage: 'Your withdrawal is pending',
	},
	giftCardsTitlePENDING: {
		id: 'withdrawSuccess.giftCardsTitlePENDING',
		defaultMessage: 'Your gift card purchase is pending',
	},
	voucherTitlePENDING: {
		id: 'withdrawSuccess.voucherTitlePENDING',
		defaultMessage: 'Your voucher withdrawal is pending',
	},
	mobileTopupsTitlePENDING: {
		id: 'withdrawSuccess.mobileTopupsTitlePENDING',
		defaultMessage: 'Your mobile top-up purchase is pending',
	},
	cryptoTextPENDING: {
		id: 'withdrawSuccess.cryptoTextPENDING',
		defaultMessage: 'Your Request to send {currency} is pending',
	},
	offchainTextPENDING: {
		id: 'withdrawSuccess.offchainTextPENDING',
		defaultMessage: 'Your offchain withdrawal is pending',
	},
	ewalletTextPENDING: {
		id: 'withdrawSuccess.ewalletTextPENDING',
		defaultMessage: 'Your withdrawal is pending',
	},
	bankTextPENDING: {
		id: 'withdrawSuccess.bankTextPENDING',
		defaultMessage: 'Your withdrawal  is pending',
	},
	giftCardsTextPENDING: {
		id: 'withdrawSuccess.giftCardsTextPENDING',
		defaultMessage: 'Your gift card purchase is pending',
	},
	voucherTextPENDING: {
		id: 'withdrawSuccess.voucherTextPENDING',
		defaultMessage: 'Your voucher withdrawal is pending',
	},
	mobileTopupsTextPENDING: {
		id: 'withdrawSuccess.mobileTopupsTextPENDING',
		defaultMessage: 'Your mobile top-up purchase is pending',
	},
	cryptoTitleFAILED: {
		id: 'withdrawSuccess.cryptoTitleFAILED',
		defaultMessage: 'Your Request to send {currency} failed',
	},
	offchainTitleFAILED: {
		id: 'withdrawSuccess.offchainTitleFAILED',
		defaultMessage: 'Your offchain withdrawal has failed',
	},
	ewalletTitleFAILED: {
		id: 'withdrawSuccess.ewalletTitleFAILED',
		defaultMessage: 'Your withdrawal has failed',
	},
	bankTitleFAILED: {
		id: 'withdrawSuccess.bankTitleFAILED',
		defaultMessage: 'Your withdrawal has failed',
	},
	giftCardsTitleFAILED: {
		id: 'withdrawSuccess.giftCardsTitleFAILED',
		defaultMessage: 'Your gift card purchase has failed',
	},
	voucherTitleFAILED: {
		id: 'withdrawSuccess.voucherTitleFAILED',
		defaultMessage: 'Your voucher withdrawal has failed',
	},
	mobileTopupsTitleFAILED: {
		id: 'withdrawSuccess.mobileTopupsTitleFAILED',
		defaultMessage: 'Your mobile top-up purchase has failed',
	},
	cryptoTextFAILED: {
		id: 'withdrawSuccess.cryptoTextFAILED',
		defaultMessage: 'Your Request to send {currency} failed',
	},
	offchainTextFAILED: {
		id: 'withdrawSuccess.offchainTextFAILED',
		defaultMessage: 'Your offchain withdrawal has failed',
	},
	ewalletTextFAILED: {
		id: 'withdrawSuccess.ewalletTextFAILED',
		defaultMessage: 'Your withdrawal has failed',
	},
	bankTextFAILED: {
		id: 'withdrawSuccess.bankTextFAILED',
		defaultMessage: 'Your withdrawal has failed',
	},
	giftCardsTextFAILED: {
		id: 'withdrawSuccess.giftCardsTextFAILED',
		defaultMessage: 'Your gift card purchase has failed',
	},
	voucherTextFAILED: {
		id: 'withdrawSuccess.voucherTextFAILED',
		defaultMessage: 'Your voucher withdrawal has failed',
	},
	mobileTopupsTextFAILED: {
		id: 'withdrawSuccess.mobileTopupsTextFAILED',
		defaultMessage: 'Your mobile top-up purchase has failed',
	},
	addAdditionalDocuments: {
		id: 'withdrawSuccess.addAdditionalDocuments',
		defaultMessage: 'Provide additional documents',
	},
	uploadSuccess: {
		id: 'withdrawSuccess.uploadSuccess',
		defaultMessage: 'Your documents have been uploaded',
	},
});

interface WithdrawSuccessProps {
	data: CryptoWithdrawResponse[] | VoucherResponse[] | any;
	currency: CurrencyEnum;
	type: WithdrawType;
	provider?: string;
	providerTitle?: string;
	hasIban?: boolean;
	network?: string | null;
	feeAccountId?: string;
}

const urlMap = {
	[WithdrawType.OFFCHAIN]: 'offchain',
	[WithdrawType.CRYPTOCURRENCY]: 'crypto',
	[WithdrawType.EWALLET]: 'e-wallet',
	[WithdrawType.BANK]: 'bank',
	[WithdrawType.GIFT]: 'gift-cards',
	[WithdrawType.VOUCHER]: 'vouchers',
	[WithdrawType.TOPUP]: 'mobile-top-ups',
};

const WithdrawSuccess = ({
	data,
	currency,
	type,
	provider,
	providerTitle,
	hasIban,
	network,
	feeAccountId,
}: WithdrawSuccessProps): JSX.Element => {
	const { url } = useRouteMatch();
	const dispatch = useDispatch();
	const [successText, setSuccessText] = useState<MessageDescriptor>();
	const { formatMessage, locale } = useIntl();

	const getImgByStatus = () => {
		if (getStatus() === WithdrawStatus.PENDING) {
			return pendingImgPath;
		}
		if (getStatus() === WithdrawStatus.PAID) {
			return successImgPath;
		}
		return failedImgPath;
	};

	const getStatus = () => {
		if (data.find(({ status }: any) => status === WithdrawStatus.PENDING)) {
			return WithdrawStatus.PENDING;
		}
		if (data.find(({ status }: any) => status === WithdrawStatus.PAID)) {
			return WithdrawStatus.PAID;
		}
		return WithdrawStatus.FAILED;
	};

	const getTitle = (): string => {
		switch (type) {
			case WithdrawType.OFFCHAIN: {
				if (getStatus() === WithdrawStatus.PENDING) {
					return formatMessage(messages.offchainTitlePENDING, { currency });
				}
				if (getStatus() === WithdrawStatus.FAILED) {
					return formatMessage(messages.offchainTitlePAID, { currency });
				}
				return formatMessage(messages.offchainTitleFAILED, { currency });
			}
			case WithdrawType.CRYPTOCURRENCY: {
				if (getStatus() === WithdrawStatus.PENDING) {
					return formatMessage(messages.cryptoTitlePENDING, { currency });
				}
				if (getStatus() === WithdrawStatus.PAID) {
					return formatMessage(messages.cryptoTitlePAID, { currency });
				}
				return formatMessage(messages.cryptoTitleFAILED, { currency });
			}
			case WithdrawType.EWALLET: {
				if (getStatus() === WithdrawStatus.PENDING) {
					return formatMessage(messages.ewalletTitlePENDING, { currency });
				}
				if (getStatus() === WithdrawStatus.PAID) {
					return formatMessage(messages.ewalletTitlePAID, { currency });
				}
				return formatMessage(messages.ewalletTitleFAILED, { currency });
			}
			case WithdrawType.BANK: {
				if (getStatus() === WithdrawStatus.PENDING) {
					return formatMessage(messages.bankTitlePENDING, { currency });
				}
				if (getStatus() === WithdrawStatus.PAID) {
					return formatMessage(messages.bankTitlePAID, { currency });
				}
				return formatMessage(messages.bankTitleFAILED, { currency });
			}
			case WithdrawType.GIFT: {
				if (getStatus() === WithdrawStatus.PENDING) {
					return formatMessage(messages.giftCardsTitlePENDING, { currency });
				}
				if (getStatus() === WithdrawStatus.PAID) {
					return formatMessage(messages.giftCardsTitlePAID, { currency });
				}
				return formatMessage(messages.giftCardsTitleFAILED, { currency });
			}
			case WithdrawType.VOUCHER: {
				if (getStatus() === WithdrawStatus.PENDING) {
					return formatMessage(messages.voucherTitlePENDING, { currency });
				}
				if (getStatus() === WithdrawStatus.PAID) {
					return formatMessage(messages.voucherTitlePAID, { currency });
				}
				return formatMessage(messages.voucherTitleFAILED, { currency });
			}
			case WithdrawType.TOPUP: {
				if (getStatus() === WithdrawStatus.PENDING) {
					return formatMessage(messages.mobileTopupsTitlePENDING, { currency });
				}
				if (getStatus() === WithdrawStatus.PAID) {
					return formatMessage(messages.mobileTopupsTitlePAID, { currency });
				}
				return formatMessage(messages.mobileTopupsTitleFAILED, { currency });
			}
			default:
				return formatMessage(messages.generalSuccess);
		}
	};

	const getDescription = (): string => {
		switch (type) {
			case WithdrawType.OFFCHAIN: {
				if (getStatus() === WithdrawStatus.PENDING) {
					return formatMessage(messages.offchainTextPENDING, { currency });
				}
				if (getStatus() === WithdrawStatus.FAILED) {
					return formatMessage(messages.offchainTextPAID, { currency });
				}
				return formatMessage(messages.offchainTextFAILED, { currency });
			}
			case WithdrawType.CRYPTOCURRENCY: {
				if (getStatus() === WithdrawStatus.PENDING) {
					return formatMessage(messages.cryptoTextPENDING, { currency });
				}
				if (getStatus() === WithdrawStatus.PAID) {
					return formatMessage(messages.cryptoTextPAID, { currency });
				}
				return formatMessage(messages.cryptoTextFAILED, { currency });
			}
			case WithdrawType.EWALLET: {
				if (getStatus() === WithdrawStatus.PENDING) {
					return formatMessage(messages.ewalletTextPENDING, { currency });
				}
				if (getStatus() === WithdrawStatus.PAID) {
					return formatMessage(messages.ewalletTextPAID, { currency });
				}
				return formatMessage(messages.ewalletTextFAILED, { currency });
			}
			case WithdrawType.BANK: {
				if (getStatus() === WithdrawStatus.PENDING) {
					return formatMessage(messages.bankTextPENDING, { currency });
				}
				if (getStatus() === WithdrawStatus.PAID) {
					return formatMessage(messages.bankTextPAID, { currency });
				}
				return formatMessage(messages.bankTextFAILED, { currency });
			}
			case WithdrawType.GIFT: {
				if (getStatus() === WithdrawStatus.PENDING) {
					return formatMessage(messages.giftCardsTextPENDING, { currency });
				}
				if (getStatus() === WithdrawStatus.PAID) {
					return formatMessage(messages.giftCardsTextPAID, { currency });
				}
				return formatMessage(messages.giftCardsTextFAILED, { currency });
			}
			case WithdrawType.VOUCHER: {
				if (getStatus() === WithdrawStatus.PENDING) {
					return formatMessage(messages.voucherTextPENDING, { currency });
				}
				if (getStatus() === WithdrawStatus.PAID) {
					return formatMessage(messages.voucherTextPAID, { currency });
				}
				return formatMessage(messages.voucherTextFAILED, { currency });
			}
			case WithdrawType.TOPUP: {
				if (getStatus() === WithdrawStatus.PENDING) {
					return formatMessage(messages.mobileTopupsTextPENDING, { currency });
				}
				if (getStatus() === WithdrawStatus.PAID) {
					return formatMessage(messages.mobileTopupsTextPAID, { currency });
				}
				return formatMessage(messages.mobileTopupsTextFAILED, { currency });
			}
			default:
				return formatMessage(messages.generalSuccess);
		}
	};

	return (
		<div className={styles.container} data-cy={TestIds.WithdrawSuccessContainer}>
			<InfoHead title={getTitle()} text={getDescription()} img={getImgByStatus()} />
			<Button
				buttonStyle={ButtonStyle.TERTIARY}
				text={messages.addAdditionalDocuments}
				className={classNames(styles.button, styles.buttonWidth)}
				type={ButtonType.BUTTON}
				data-cy="addAdditionalDocuments"
				onClick={() =>
					dispatch(
						toggleModal(
							<AdditionalDocumentsModal
								onSuccess={() => setSuccessText(messages.uploadSuccess)}
								withdrawId={data[0].id}
							/>,
							false
						)
					)
				}
			/>
			{[WithdrawStatus.PAID, WithdrawStatus.PENDING].includes(getStatus()) ? (
				<Button
					buttonStyle={ButtonStyle.PRIMARY}
					text={messages.viewSummary}
					className={classNames(styles.button, styles.buttonWidth)}
					type={ButtonType.BUTTON}
					data-cy="viewSummary"
					onClick={() =>
						dispatch(
							toggleModal(
								<WithdrawSummary
									formData={data}
									currency={currency}
									type={type}
									provider={provider}
									providerTitle={providerTitle}
									hasIban={hasIban}
									network={network}
									feeAccountId={feeAccountId}
								/>
							)
						)
					}
				/>
			) : (
				<Button
					link={url}
					buttonStyle={ButtonStyle.PRIMARY}
					text={baseMsg.tryAgain}
					className={classNames(styles.button, styles.buttonWidth)}
					type={ButtonType.LINK}
					data-cy="tryAgain"
				/>
			)}
			<Button
				link={`/${locale}/withdraw/${urlMap[type]}`}
				buttonStyle={ButtonStyle.BORDERLESS}
				text={baseMsg.close}
				className={styles.button}
				type={ButtonType.LINK}
				data-cy="close"
			/>
			{successText && (
				<NotificationMessage
					message={formatMessage(successText)}
					className={styles.successMessage}
					style={NotificationStyle.Border}
					withIcon
					type={NotificationType.Success}
				/>
			)}
		</div>
	);
};

export default WithdrawSuccess;
