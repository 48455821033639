import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { formatPrecision, toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import {
	isCardFunctionsEnabled,
	GetCurrentCard,
	isCardOnHold,
	isCardRha,
} from '../../../../helpers/cardsHelper/cardsHelper';
import { RootState } from '../../../../redux/Store';
import { getUnlockCardURL } from '../../../../redux/endpoints';
import { CardStatusType, CardType, CardTypeFromBE } from '../../../../redux/CardsState/CardsTypes';
import { getCard } from '../../../../redux/CardsState/CardsActions';
import Button, { ButtonType } from '../../../../components/Button/Button';
import ActionButton, { ButtonIcon } from '../../../../components/ActionButton/ActionButton';
import plasticImgPath from './images/plastic.svg';
import plasticPrepaidImgPath from './images/plastic_old.svg';
import virtualImgPath from './images/virtual.svg';
import virtualPrepaidImgPath from './images/virtual_old.svg';
// import closeImgPath from './images/close.svg';
import lockImgPath from './images/lock.svg';
import onlineImgPath from './images/online.svg';
import pinImgPath from './images/pin.svg';
import reportImgPath from './images/report.svg';
import securityImgPath from './images/security.svg';
import viewImgPath from './images/view.svg';
import styles from './CardControl.module.scss';
import BackButton from '../../../../components/BackButton/BackButton';
import Seo from '../../../../components/Seo/Seo';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import LinkedWallets from '../../CardsAccount/LinkedWallets/LinkedWallets';
import useFormatAmount from '../../../../hooks/useFormatAmount';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	cardIssuedBy: {
		id: 'cardControl.cardIssuedBy',
		defaultMessage: 'Card issued by UAB {name}',
	},
	load: {
		id: 'balanceBlock.loadCard',
		defaultMessage: 'Load card',
	},
	unload: {
		id: 'balanceBlock.unloadCard',
		defaultMessage: 'Unload card',
	},
	suspendedPost: {
		id: 'cardControl.suspendedPost',
		defaultMessage: 'Please be informed that your card is blocked.',
	},
	activate: {
		id: 'cardControl.activate',
		defaultMessage: 'Activate card',
	},
	view: {
		id: 'cardControl.view',
		defaultMessage: 'View card details',
	},
	pin: {
		id: 'cardControl.pin',
		defaultMessage: 'View PIN',
	},
	security: {
		id: 'cardControl.security',
		defaultMessage: 'Card security',
	},
	online: {
		id: 'cardControl.online',
		defaultMessage: 'Online transactions password',
	},
	lock: {
		id: 'cardControl.lock',
		defaultMessage: 'Temporarily lock card',
	},
	report: {
		id: 'cardControl.report',
		defaultMessage: 'Report card as lost or stolen',
	},
	close: {
		id: 'cardControl.close',
		defaultMessage: 'Close card',
	},
	lockedCardText: {
		id: 'cardControl.lockedCardText',
		defaultMessage:
			'Please be informed that your card is locked. In order to use your card you would have to unlock it.',
	},
	unlock: {
		id: 'cardControl.unlock',
		defaultMessage: 'Unlock card',
	},
	back: {
		id: 'cardsList.backToCards',
		defaultMessage: 'Back to cards',
	},
	metaTitle: {
		id: 'cardControl.metaTitle',
		defaultMessage: 'Card',
	},
	cardBalanceIsUsedFirstInfo: {
		id: 'balanceBlock.cardBalanceIsUsedFirstInfo',
		defaultMessage:
			'Card balance is used first. If insufficient, crypto assets from linked wallets are sold to cover the remaining amount.',
	},
});

const CardControl = () => {
	const dispatch = useDispatch();
	const { locale, formatMessage } = useIntl();
	const amountFormatter = useFormatAmount();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { isMobile, isTablet } = useSelector((state: RootState) => state.AppState);
	const card = GetCurrentCard();

	const unlockCard = async (id: string) => {
		setIsLoading(true);
		await axiosInstance
			.put(getUnlockCardURL(id))
			.then(() => {
				return dispatch(getCard(id));
			})
			.catch(() => {
				return setIsLoading(false);
			});
	};

	useEffect(() => {
		if (card?.status === CardStatusType.SUSPENDED) setIsLoading(false);
	}, [card]);

	if (!card) return null;

	const getCardImage = () => {
		if (isCardFunctionsEnabled(card.cardType)) {
			if (card.type === CardType.PLASTIC) {
				return plasticImgPath;
			}
			return virtualImgPath;
		}

		if (card.type === CardType.PLASTIC) {
			return plasticPrepaidImgPath;
		}

		return virtualPrepaidImgPath;
	};

	return (
		<>
			<Seo title={messages.metaTitle} />
			{(isMobile || isTablet) && (
				<BackButton
					className={styles.backButton}
					link={`/${locale}/cards`}
					text={messages.back}
				/>
			)}

			<div
				className={classNames(styles.container, {
					[styles.paddingBottom]: card.status !== CardStatusType.ACTIVE,
				})}
			>
				<div className={styles.imgContainer}>
					{(card.status === CardStatusType.SUSPENDED ||
						(card.status === CardStatusType.BLOCKED &&
							card.cardType === CardTypeFromBE.C_PASTIC)) && (
						<div className={styles.lockContainer}>
							<img src={lockImgPath} alt="Lock" className={styles.lockedCard} />
						</div>
					)}
					{((card.status === CardStatusType.BLOCKED &&
						card.cardType !== CardTypeFromBE.C_PASTIC) ||
						card.status === CardStatusType.CLOSED) && (
						<div className={styles.lockContainer}>
							<img
								src={reportImgPath}
								alt="Suspended"
								className={styles.lockedCard}
							/>
						</div>
					)}
					<img
						src={getCardImage()}
						className={classNames({
							[styles.greyScale]: card.status !== CardStatusType.ACTIVE,
						})}
					/>
					<div className={styles.cardNumber}>{card.pan.slice(-4)}</div>
					<div className={styles.name}>{card.nameOnCard}</div>
				</div>
				<p className={styles.issued}>
					<FormattedMessage
						{...messages.cardIssuedBy}
						values={{
							name: isCardFunctionsEnabled(card.cardType)
								? '"Pervesk"'
								: 'Finansinės paslaugos "Contis"',
						}}
					/>
				</p>
				{isMobile && card.status !== CardStatusType.NOT_ACTIVATED && (
					<>
						<div className={styles.availableBalance}>
							<div className={styles.labelText}>
								<FormattedMessage
									id="balanceBlock.available"
									defaultMessage="Available balance"
								/>
								{isCardRha(card.cardType) && (
									<Tooltip
										infoIconVisible
										content={
											<div className={styles.tooltip}>
												{formatMessage(messages.cardBalanceIsUsedFirstInfo)}
											</div>
										}
									/>
								)}
							</div>
							<div className={styles.balance}>
								{`${amountFormatter(
									formatPrecision(card.accountBalance, CurrencyEnum.EUR)
								)} ${CurrencyEnum.EUR}`}
							</div>
						</div>
						<ul className={styles.buttonRow}>
							<li>
								<ActionButton
									buttonIcon={ButtonIcon.LOAD}
									link={`/${locale}/cards/account/${card?.id}/load`}
									text={messages.load}
									isDisabled={isCardOnHold(card.status)}
								/>
							</li>
							<li>
								<ActionButton
									buttonIcon={ButtonIcon.UNLOAD}
									link={`/${locale}/cards/account/${card?.id}/unload`}
									text={messages.unload}
									isDisabled={
										isCardOnHold(card.status) ||
										toDecimal(card?.accountBalance).equals(0)
									}
								/>
							</li>
						</ul>
					</>
				)}
				{isMobile && isCardRha(card.cardType) && <LinkedWallets id={card.id} />}
				{card.status === CardStatusType.BLOCKED &&
					card.cardType !== CardTypeFromBE.C_PASTIC && (
						<p className={styles.info}>{formatMessage(messages.suspendedPost)}</p>
					)}
				{(card.status === CardStatusType.SUSPENDED ||
					(card.cardType === CardTypeFromBE.C_PASTIC &&
						card.status === CardStatusType.BLOCKED)) && (
					<div>
						<p className={styles.info}>{formatMessage(messages.lockedCardText)}</p>
						<Button
							text={messages.unlock}
							type={ButtonType.BUTTON}
							isLoading={isLoading}
							isDisabled={isLoading}
							onClick={() => unlockCard(card.id)}
						/>
					</div>
				)}
				{card.status === CardStatusType.ACTIVE && (
					<ul className={styles.controlList}>
						{isCardFunctionsEnabled(card.cardType) && (
							<li>
								<NavLink
									to={`/${locale}/cards/account/${card.id}/view-card-details`}
									className={styles.listItem}
									activeClassName={styles.active}
								>
									<img src={viewImgPath} />
									<span>{formatMessage(messages.view)}</span>
								</NavLink>
							</li>
						)}
						{card.type === CardType.PLASTIC && (
							<li>
								<NavLink
									to={`/${locale}/cards/account/${card.id}/view-card-pin`}
									className={styles.listItem}
									activeClassName={styles.active}
								>
									<img src={pinImgPath} />
									<span>{formatMessage(messages.pin)}</span>
								</NavLink>
							</li>
						)}
						{isCardFunctionsEnabled(card.cardType) && (
							<li>
								<NavLink
									to={`/${locale}/cards/account/${card.id}/card-security`}
									className={styles.listItem}
									activeClassName={styles.active}
								>
									<img src={securityImgPath} />
									<span>{formatMessage(messages.security)}</span>
								</NavLink>
							</li>
						)}
						{isCardFunctionsEnabled(card.cardType) && (
							<li>
								<NavLink
									to={`/${locale}/cards/account/${card.id}/online-transactions-password`}
									className={styles.listItem}
									activeClassName={styles.active}
								>
									<img src={onlineImgPath} />
									<span>{formatMessage(messages.online)}</span>
								</NavLink>
							</li>
						)}
						<li
							className={classNames({
								[styles.noBottom]:
									!isMobile && isTablet && card?.type === CardType.PLASTIC,
							})}
						>
							<NavLink
								to={`/${locale}/cards/account/${card.id}/lock-card`}
								className={styles.listItem}
								activeClassName={styles.active}
							>
								<img src={lockImgPath} />
								<span>{formatMessage(messages.lock)}</span>
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`/${locale}/cards/account/${card.id}/report-card`}
								className={styles.listItem}
								activeClassName={styles.active}
							>
								<img src={reportImgPath} />
								<span>{formatMessage(messages.report)}</span>
							</NavLink>
						</li>
						{/* <li>
							<NavLink to={`/${locale}/cards`} className={styles.listItem} activeClassName={styles.active}>
								<img src={closeImgPath} />
								<span>{formatMessage(messages.close)}</span>
							</NavLink>
						</li> */}
					</ul>
				)}
			</div>
		</>
	);
};

export default CardControl;
