import { ActionCreator } from 'redux';
import { PaymentButton } from '../PaymentButtonsData/PaymentButtonsDataTypes';
import {
	PaymentButtonEditActionFulfilledAction,
	PaymentButtonEditActionType,
} from './PaymentButtonsEditTypes';

const setActionFulfilled: ActionCreator<PaymentButtonEditActionFulfilledAction> = (
	button: PaymentButton
) => ({
	type: PaymentButtonEditActionType.ACTION_FULFILLED,
	payload: button,
});

export default {
	setActionFulfilled,
};
