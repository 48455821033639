import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { useIntl } from 'react-intl';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import { OffchainSuccessResponse } from '../../../../redux/WithdrawState/WithdrawTypes';
import baseMsg from '../../../../messages/base.messages';
import useFormatAmount from '../../../../hooks/useFormatAmount';

interface OffchainSummaryProps {
	data: OffchainSuccessResponse[];
	currency: CurrencyEnum;
	infoClass?: string;
}

const OffchainSummary = ({ data, currency, infoClass }: OffchainSummaryProps) => {
	const { formatMessage } = useIntl();
	const amountFormatter = useFormatAmount();

	return (
		<>
			{data.map(({ receiveAmount, receiver, sendAmount, sendAccount, memo }) => {
				return (
					<li key={receiver}>
						{sendAccount && (
							<InfoInput
								label={baseMsg.sendAccount}
								text={currency}
								className={infoClass}
							/>
						)}
						{sendAmount && (
							<InfoInput
								label={formatMessage(baseMsg.withdrawAmount)}
								text={`${amountFormatter(
									formatPrecision(sendAmount, currency)
								)} ${currency}`}
								className={infoClass}
							/>
						)}
						{receiveAmount && (
							<InfoInput
								label={formatMessage(baseMsg.totalConvertedAmount)}
								text={`${amountFormatter(
									formatPrecision(receiveAmount, CurrencyEnum.BTC)
								)} ${CurrencyEnum.BTC}`}
								className={infoClass}
							/>
						)}
						{receiver && (
							<InfoInput
								label={formatMessage(baseMsg.receiver)}
								text={receiver}
								className={infoClass}
							/>
						)}
						{memo && (
							<InfoInput
								label={formatMessage(baseMsg.memo)}
								text={memo}
								className={infoClass}
							/>
						)}
					</li>
				);
			})}
		</>
	);
};

export default OffchainSummary;
