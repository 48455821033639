import { AxiosError, AxiosResponse } from 'axios';
import FunctionalityAvailableType from '../../enums/FunctionalityAvailableEnum';
import { toggleCardsSidebar } from '../AppState/AppActions';
import {
	OrderSteps,
	CardsActionType,
	SetOrderStepAction,
	SetOrderCardFormDataAction,
	ResetOrderCardFormAction,
	SetIsCardUserAction,
	SetAllCardsAction,
	SetProductOptions,
	SetOrderIdAction,
	CardItem,
	CardStatusType,
	OrderDataProps,
	FormData,
	SecurityReponse,
	SecurityType,
	SetCardByIdAction,
	ProductOption,
	SetGeneralErrorAction,
	SetCompanyMovementAction,
	VerificationType,
	SetVerificationTypeAction,
	SetAvailableCountriesAction,
	CardType,
	SetRequiresVerificationAction,
} from './CardsTypes';
import { AppDispatch, RootState } from '../Store';
import {
	getCardProductsURL,
	getDebitCardSecurityParamsURL,
	getDebitCardsURL,
	getDebitCardUpdateURL,
	getDeliveryCountriesListURL,
	getIsCardUserURL,
	getIsFunctionalityAvailableURL,
} from '../endpoints';
import IsFunctionalityAvailableResponse from '../../interfaces/IsFunctionalityAvailable';
import axiosInstance from '../../helpers/axiosInstance';

export const setGeneralCardError = (isError: boolean) => (dispatch: AppDispatch) => {
	dispatch<any>(toggleCardsSidebar(!isError));
	dispatch<SetGeneralErrorAction>({
		type: CardsActionType.SET_GENERAL_CARD_ERROR,
		data: isError,
	});
};

export const setCardListIsLoading = (isLoading: boolean) => ({
	type: CardsActionType.SET_CARD_LIST_IS_LOADING,
	isLoading,
});

export const setOrderStep = (step: OrderSteps) => (dispatch: AppDispatch) => {
	return dispatch<SetOrderStepAction>({ type: CardsActionType.SET_ORDER_STEP, step });
};

export const setVerificationType = (verificationType: VerificationType) => (
	dispatch: AppDispatch
) => {
	return dispatch<SetVerificationTypeAction>({
		type: CardsActionType.SET_VERIFICATION_TYPE,
		verificationType,
	});
};

export const setFormData = (data: FormData | null) => (dispatch: AppDispatch) => {
	return dispatch<SetOrderCardFormDataAction>({
		type: CardsActionType.SET_ORDER_CARD_FORM_DATA,
		data,
	});
};

export const resetOrderData = () => (dispatch: AppDispatch) => {
	return dispatch<ResetOrderCardFormAction>({
		type: CardsActionType.RESET_ORDER_CARD_FORM,
	});
};

export const setRequiresVerification = (requiresVerification: boolean) => (
	dispatch: AppDispatch
) => {
	return dispatch<SetRequiresVerificationAction>({
		type: CardsActionType.SET_REQUIRES_VERIFICATION,
		requiresVerification,
	});
};

export const getIsCardUser = () => async (dispatch: AppDispatch) => {
	await axiosInstance
		.get(getIsCardUserURL())
		.then(({ data }: AxiosResponse) => {
			const { isCardUser } = data;
			return dispatch<SetIsCardUserAction>({
				type: CardsActionType.SET_IS_CONTIS_USER,
				isCardUser,
			});
		})
		.catch((e: AxiosError) => {
			return console.log(e, `Couldn't fetch is card user`);
		});
};

export const getProductOptions = () => (dispatch: AppDispatch) => {
	return axiosInstance
		.get(getCardProductsURL())
		.then(({ data }: AxiosResponse<ProductOption[]>) => {
			return dispatch<SetProductOptions>({
				type: CardsActionType.SET_PRODUCT_OPTIONS,
				data,
			});
		});
};

export const getAvailableCountries = (type: CardType) => (dispatch: AppDispatch) => {
	return axiosInstance.get(getDeliveryCountriesListURL(type)).then(({ data }: AxiosResponse) => {
		return dispatch<SetAvailableCountriesAction>({
			type: CardsActionType.SET_AVAILABLE_COUNTRIES,
			availableCountries: data.deliveryCountries,
		});
	});
};

export const getAllCards = () => async (dispatch: AppDispatch) => {
	dispatch(setCardListIsLoading(true));
	await axiosInstance
		.get(getDebitCardsURL())
		.then(({ data }: AxiosResponse<CardItem[]>) => {
			const filteredData = data.reduce((acc: CardItem[], curr: CardItem) => {
				if (
					curr.status !== CardStatusType.SUBMITTED &&
					curr.status !== CardStatusType.WAITING_PINSET &&
					curr.status !== CardStatusType.FAILED &&
					curr.pan !== null &&
					curr.nameOnCard !== null
				) {
					acc.push(curr);
				}

				return acc;
			}, []);

			const sortedCards = filteredData
				.sort((a: CardItem, b: CardItem) => {
					return new Date(a.date) < new Date(b.date) ? 1 : -1;
				})
				.map((card) => ({
					...card,
					accountBalance: card.accountBalance ?? '0.00',
					reservationsBalance: card.reservationsBalance ?? '0.00',
					holdsBalance: card.holdsBalance ?? '0.00',
					blocksBalance: card.blocksBalance ?? '0.00',
				}));

			dispatch<SetAllCardsAction>({
				type: CardsActionType.SET_ALL_CARDS,
				data: sortedCards,
			});
		})
		.catch((e: AxiosError) => {
			console.log(`${e} occured`);
			return dispatch<any>(setGeneralCardError(true));
		});
};

export const getCard = (id: string) => async (dispatch: AppDispatch) => {
	await axiosInstance
		.get(getDebitCardsURL(id))
		.then(({ data }: AxiosResponse<CardItem>) => {
			return dispatch<SetCardByIdAction>({
				type: CardsActionType.SET_CARD_BY_ID,
				data,
			});
		})
		.catch((e: AxiosError) => {
			return console.log(`${e} Couldn't fetch debit card`);
		});
};

export const setOrderData = ({ pinId, cardId }: OrderDataProps) => (dispatch: AppDispatch) => {
	return dispatch<SetOrderIdAction>({
		type: CardsActionType.SET_ORDER_DATA,
		orderData: {
			pinId,
			cardId,
		},
	});
};

export const getCardsSecurityParams = (id: string) => async (
	dispatch: AppDispatch,
	getState: () => RootState
) => {
	await axiosInstance
		.get(getDebitCardSecurityParamsURL(id))
		.then(({ data }: AxiosResponse<SecurityReponse>) => {
			const { cardsList } = getState().CardsState;
			const card = cardsList?.find((o: CardItem) => o.id === id) || null;
			if (card)
				return dispatch<SetCardByIdAction>({
					type: CardsActionType.SET_CARD_BY_ID,
					data: {
						...card,
						cardSecurity: {
							...data,
							isLoading: false,
						},
					},
				});
			return null;
		})
		.catch((e: AxiosError) => console.log(`${e} occured`));
};

export const setSecurityParam = (id: string, type: SecurityType, isEnabled: boolean) => async (
	dispatch: AppDispatch,
	getState: () => RootState
) => {
	const { cardsList } = getState().CardsState;
	const card = cardsList?.find((o: CardItem) => o.id === id) || null;
	if (card)
		dispatch<SetCardByIdAction>({
			type: CardsActionType.SET_CARD_BY_ID,
			data: {
				...card,
				cardSecurity: {
					...card.cardSecurity!,
					isLoading: true,
				},
			},
		});
	await axiosInstance
		.put(getDebitCardSecurityParamsURL(id), {
			securityParameter: type,
			securityParameterStatus: isEnabled,
		})
		.then(() => {
			return dispatch<any>(getCardsSecurityParams(id));
		})
		.catch((e: AxiosError) => console.log(`${e} occured`));
};

export const updateDebitCard = (id: string) => axiosInstance.put(getDebitCardUpdateURL(id), {});

export const setCompanyMovement = () => (dispatch: AppDispatch) =>
	void axiosInstance
		.get(getIsFunctionalityAvailableURL(FunctionalityAvailableType.ORDER_DEBIT_CARD))
		.then(({ data }: AxiosResponse<IsFunctionalityAvailableResponse[]>) => {
			return dispatch<SetCompanyMovementAction>({
				type: CardsActionType.SET_COMPANY_MOVEMENT,
				data: data[0],
			});
		});
