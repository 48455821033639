import { FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { Link, useHistory } from 'react-router-dom';
import baseMsg from '../../../../../messages/base.messages';
import { RemoteStatus } from '../../../../../interfaces/RemoteData';
import Pagination from '../../../../../components/Pagination/Pagination';
import useQueryPagination from '../../../../../hooks/useQueryPagination';
import useDidMount from '../../../../../hooks/useDidMount';
import debounceHelper from '../../../../../helpers/debounceHelper/debounceHelper';
import TagFilter, { Tag } from '../../../../../components/TagFilter/TagFilter';
import { RootState } from '../../../../../redux/Store';
import Input from '../../../../../components/Input/Input';
import MerchantsActions from '../../../../../redux/MerchantsState/MerchantsActions';
import MerchantsMessages from '../../../../../redux/MerchantsState/MerchantsMessages';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';
import useMerchantRoutes, { MerchantRoutes } from '../../../../../hooks/useMerchantRoutes';
import Loading from '../../../Shared/Loading/Loading';
import NoProjects from '../../../Shared/NotFound/NotFound';
import MerchantsSelectors from '../../../../../redux/MerchantsState/MerchantsSelectors';
import {
	PaymentButtonsListMode,
	PaymentButtonsListStatus,
} from '../../../../../redux/MerchantsState/MerchantTypes';
import PageTitle from '../../../Shared/PageTitle/PageTitle';
import MobilePaymentButton from '../../../Shared/MobilePaymentButton/MobilePaymentButton';
import PaymentButton from '../../../Shared/PaymentButton/PaymentButton';

import styles from './PaymentButtonsList.module.scss';
import TestIds from '../../../../../test/TestIds';

const ListHeader: FC = () => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const [_, setPage] = useQueryPagination();

	const { search = '', tags } = useSelector(MerchantsSelectors.paymentButtons.list.getFilter);
	const { isMobile } = useSelector((state: RootState) => state.AppState);

	const tagFilterOptions = useMemo<Tag[]>(
		() => [
			{ id: 'crypto', isActive: tags.crypto, label: formatMessage(MerchantsMessages.crypto) },
			{ id: 'fiat', isActive: tags.fiat, label: formatMessage(MerchantsMessages.fiat) },
			{
				id: 'enabled',
				isActive: tags.enabled,
				label: formatMessage(MerchantsMessages.enabled),
			},
			{
				id: 'disabled',
				isActive: tags.disabled,
				label: formatMessage(MerchantsMessages.disabled),
			},
		],
		[tags, formatMessage]
	);

	const onChange = useCallback(
		(value: string) => {
			setPage(1);
			dispatch(MerchantsActions.paymentButtons.list.setSearch(value));
		},
		[dispatch, setPage]
	);

	const onToggle = useCallback(
		(tag: Tag) => {
			setPage(1);
			dispatch(
				MerchantsActions.paymentButtons.list.setTags({
					...tags,
					[tag.id]: !tag.isActive,
				})
			);
		},
		[setPage, dispatch, tags]
	);

	return (
		<div className={styles.tableHeader}>
			<div className={styles.tableControls}>
				<Input
					data-cy={TestIds.PaymentButtonListTableHeaderSearch}
					value={search}
					placeholder={baseMsg.search}
					faIcon={faSearch}
					onChange={onChange}
				/>
				{!isMobile && <TagFilter tags={tagFilterOptions} onToggle={onToggle} />}
			</div>
			{!isMobile && (
				<div className={styles.columns}>
					<span>
						<FormattedMessage {...MerchantsMessages.name} />
					</span>
					<span>
						<FormattedMessage {...MerchantsMessages.autoconvert} />
					</span>
					<span>
						<FormattedMessage {...MerchantsMessages.acceptedCurrencies} />
					</span>
					<span>
						<FormattedMessage {...MerchantsMessages.mode} />
					</span>
				</div>
			)}
		</div>
	);
};

const ListRow: FC<PropsWithChildren> = ({ children, ...rest }) => {
	const { isMobile } = useSelector((state: RootState) => state.AppState);
	return (
		<div
			data-cy={TestIds.PaymentButtonListRow}
			className={classNames({
				[styles.mobileListRow]: isMobile,
				[styles.listRow]: !isMobile,
			})}
			{...rest}
		>
			{children}
		</div>
	);
};

const Content: FC = () => {
	const { formatMessage } = useIntl();
	const [page] = useQueryPagination();

	const { isMobile } = useSelector((state: RootState) => state.AppState);
	const buttons = useSelector(
		MerchantsSelectors.paymentButtons.getListButtonsDataByPage(page - 1)
	);

	return (
		<>
			{buttons.length === 0 && (
				<NoProjects message={formatMessage(MerchantsMessages.emptyPaymentButtonTable)} />
			)}
			{buttons.length !== 0 && (
				<div className={styles.listBody}>
					{buttons.map((button) => (
						<ListRow key={button.id}>
							{isMobile ? (
								<MobilePaymentButton button={button} />
							) : (
								<PaymentButton button={button} />
							)}
						</ListRow>
					))}
				</div>
			)}
		</>
	);
};

const ButtonsList: FC = () => {
	const dispatch = useDispatch();

	const [queryPage, setPage] = useQueryPagination();
	const page = queryPage - 1;

	const { pageSize, totalCount, pages } = useSelector(
		MerchantsSelectors.paymentButtons.list.getState
	);
	const { status } = useSelector(MerchantsSelectors.paymentButtons.list.getButtonsByPage(page));
	const didMount = useDidMount();

	const {
		search,
		tags: { crypto, disabled, enabled, fiat },
	} = useSelector(MerchantsSelectors.paymentButtons.list.getFilter);

	const throttledSearchUpdate = useCallback(
		debounceHelper(() => {
			dispatch(MerchantsActions.paymentButtons.list.fetchButtons(page, true));
		}, 500),
		[]
	);

	useEffect(() => {
		if (didMount) throttledSearchUpdate(page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	useEffect(() => {
		if (status === RemoteStatus.None)
			dispatch(MerchantsActions.paymentButtons.list.fetchButtons(page));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	useEffect(() => {
		if (didMount) dispatch(MerchantsActions.paymentButtons.list.fetchButtons(page, true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [crypto, disabled, enabled, fiat]);

	useEffect(() => {
		if (status === RemoteStatus.Done && page > 0 && pages[page].data?.length === 0)
			setPage(page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	return (
		<>
			<div data-cy={TestIds.PaymentButtonListTable} className={styles.buttonsTable}>
				<ListHeader />
				{status === RemoteStatus.InProgress && <Loading />}
				{status === RemoteStatus.Done && <Content />}
			</div>
			{status === RemoteStatus.Done && (
				<Pagination totalCount={totalCount} pageSize={pageSize} />
			)}
		</>
	);
};

const RemoveButtons: FC = () => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const { mode } = useSelector(MerchantsSelectors.paymentButtons.list.getState);

	const actionText = (() => {
		switch (mode) {
			case PaymentButtonsListMode.View:
				return formatMessage(MerchantsMessages.removeButton);
			case PaymentButtonsListMode.Delete:
				return formatMessage(MerchantsMessages.cancel);
			default:
				return null;
		}
	})();

	const action = useCallback(() => {
		switch (mode) {
			case PaymentButtonsListMode.View:
				return dispatch(
					MerchantsActions.paymentButtons.list.setMode(PaymentButtonsListMode.Delete)
				);
			case PaymentButtonsListMode.Delete:
				return dispatch(
					MerchantsActions.paymentButtons.list.setMode(PaymentButtonsListMode.View)
				);
			default:
				return null;
		}
	}, [mode, dispatch]);

	// reset mode on cleanup
	useEffect(
		() => () => {
			dispatch(MerchantsActions.paymentButtons.list.setMode(PaymentButtonsListMode.View));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<div
			data-cy={TestIds.PaymentButtonListRemove}
			className={styles.removeButton}
			onClick={action}
		>
			{actionText}
		</div>
	);
};

const PaymentButtonsList: FC = () => {
	const { formatMessage } = useIntl();
	const { getUrl } = useMerchantRoutes();
	const { replace } = useHistory();
	const { mode, status } = useSelector(MerchantsSelectors.paymentButtons.list.getState);

	useEffect(() => {
		if (status === PaymentButtonsListStatus.Empty)
			replace(getUrl(MerchantRoutes.PaymentButtons));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	return (
		<WhiteContainer className={styles.container}>
			<PageTitle
				title={formatMessage(MerchantsMessages.allPaymentButtons)}
				backLink={getUrl(MerchantRoutes.IntegrationTools)}
			>
				{mode === PaymentButtonsListMode.View && (
					<Link to={getUrl(MerchantRoutes.PaymentButtonsCreate)}>
						<FormattedMessage {...MerchantsMessages.createButton} />
					</Link>
				)}
				<RemoveButtons />
			</PageTitle>
			<ButtonsList />
		</WhiteContainer>
	);
};

export default PaymentButtonsList;
