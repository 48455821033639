import { FC, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import PageTitle from '../../../Merchants/Shared/PageTitle/PageTitle';
import {
	ReferralCallback,
	callbacksQuery,
} from '../../../../redux/ReferralsState/Queries/ReferralQueries';
import useQueryParams from '../../../../hooks/useQueryParams';
import styles from './CallbacksList.module.scss';
import ReferralsMessages from '../../../../redux/ReferralsState/ReferralsMessages';
import Button, { ButtonStyle } from '../../../../components/Button/Button';
import useReferralRoutes, { ReferralRoutes } from '../../../../hooks/useReferralRoutes';
import SortableList, { ListCell } from '../SortableList/SortableList';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import SetCallbackUrl from '../SetCallbackUrl/SetCallbackUrl';
import convertToLocalTime from '../../../../helpers/dateHelper/dateHelper';
import { useSortableListRecord } from '../SortableList/SortableListContext';
import { RootState } from '../../../../redux/Store';
import Tooltip from '../../../../components/Tooltip/Tooltip';

const StatusCode: FC = () => {
	const { statusCode } = useSortableListRecord<ReferralCallback>()!;

	const [style] = useState<any>(() => {
		if (statusCode >= 400) return styles.error;
		if (statusCode >= 300) return styles.warning;
		if (statusCode >= 200) return styles.success;
		return null;
	});

	return <div className={classNames(styles.statusCode, style)}>{statusCode}</div>;
};

const Created: FC = () => {
	const { created } = useSortableListRecord<ReferralCallback>()!;
	return <>{convertToLocalTime(created, 'yyyy-MM-dd')}</>;
};

const CallbacksList: FC = () => {
	const { formatMessage } = useIntl();
	const { getUrl } = useReferralRoutes();
	const { isMobile } = useSelector((state: RootState) => state.AppState);
	const dispatch = useDispatch();

	const [[page = '1', sort = '']] = useQueryParams({
		page: '1',
		sort: 'created,desc',
		search: '',
	});
	const query = useQuery({
		queryKey: callbacksQuery.getKey(+page - 1, sort),
		queryFn: callbacksQuery.getFn(+page - 1, sort),
	});
	return (
		<WhiteContainer className={styles.container}>
			<PageTitle
				className={styles.header}
				title={formatMessage(ReferralsMessages.yourCallbacks)}
				backLink={getUrl(ReferralRoutes.Home)}
			>
				{!isMobile && (
					<Button
						buttonStyle={ButtonStyle.PRIMARY}
						className={styles.button}
						onClick={() => {
							dispatch(toggleModal(<SetCallbackUrl />));
						}}
					>
						{formatMessage(ReferralsMessages.editCallbackUrl)}
					</Button>
				)}
			</PageTitle>
			<div className={styles.body}>
				<span className={styles.note}>
					{formatMessage(ReferralsMessages.yourCallbacksNote)}
				</span>
				{isMobile && (
					<Button
						buttonStyle={ButtonStyle.PRIMARY}
						className={styles.button}
						onClick={() => {
							dispatch(toggleModal(<SetCallbackUrl />));
						}}
					>
						{formatMessage(ReferralsMessages.editCallbackUrl)}
					</Button>
				)}
				<div className={styles.content}>
					<SortableList
						query={query}
						emptyMessage={formatMessage(ReferralsMessages.notCallbacksYet)}
					>
						<ListCell<ReferralCallback>
							header={formatMessage(ReferralsMessages.email)}
							source="refEmail"
						/>
						<ListCell<ReferralCallback>
							header={formatMessage(ReferralsMessages.refId)}
							source="refId"
						/>
						<ListCell<ReferralCallback>
							header={formatMessage(ReferralsMessages.trackingId)}
							source="refTrackId"
						/>
						<ListCell<ReferralCallback>
							header={
								<span className={styles.statusHeader}>
									{formatMessage(ReferralsMessages.statusCode)}
									<Tooltip
										infoIconVisible
										content={
											<div className={styles.tooltip}>
												{formatMessage(
													ReferralsMessages.statusCodeTooltip,
													{
														b: (txt) => (
															<span className={styles.strong}>
																{txt}
															</span>
														),
													}
												)}
											</div>
										}
									/>
								</span>
							}
						>
							<StatusCode />
						</ListCell>
						<ListCell<ReferralCallback>
							header={formatMessage(ReferralsMessages.date)}
							sortBy="created"
						>
							<Created />
						</ListCell>
					</SortableList>
				</div>
			</div>
		</WhiteContainer>
	);
};

export default CallbacksList;
