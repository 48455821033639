import { ReactNode } from 'react';
import CardsSidebar from '../CardsSiderbar/CardsSidebar';
import styles from './CardsLayout.module.scss';

interface CardsLayoutProps {
	children: ReactNode | ReactNode[];
}

const CardsLayout = ({ children }: CardsLayoutProps) => {
	return (
		<div className={styles.container}>
			<div className={styles.flexGrid}>
				<CardsSidebar />
				{children}
			</div>
		</div>
	);
};

export default CardsLayout;
