import { FC } from 'react';
import { Route, Switch } from 'react-router';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useFormatRoute } from '../../../helpers/languagePathHelper/languagePathHelper';
import MerchantsMessages from '../../../redux/MerchantsState/MerchantsMessages';
import SvgIcon from '../../../components/SvgIcon/SvgIcon';
import WhiteContainer from '../../../components/WhiteContainer/WhiteContainer';
import useMerchantRoutes, { MerchantRoutes } from '../../../hooks/useMerchantRoutes';
import PageTitle from '../Shared/PageTitle/PageTitle';
import PaymentButtons from './PaymentButtons/PaymentButtons';
import routes from '../../../route.messages';
import TestIds from '../../../test/TestIds';

import apiSvg from './images/api.svg';
import librariesSvg from './images/libraries.svg';
import buttonsSvg from './images/payment_buttons.svg';
import pluginsSvg from './images/plugins.svg';

import styles from './IntegrationTools.module.scss';

const IntegrationTool: FC<{
	icon: string;
	label: string;
	link: string;
	external?: boolean;
}> = ({ icon, label, link, external = false, ...rest }) => {
	const content = (
		<li className={styles.listItem} {...rest}>
			<div className={styles.icon}>
				<SvgIcon src={icon} />
			</div>
			<div className={styles.label}>{label}</div>
		</li>
	);

	return external ? (
		<a className={styles.link} href={link}>
			{content}
		</a>
	) : (
		<Link className={styles.link} to={link}>
			{content}
		</Link>
	);
};

const IntegrationToolsView: FC = () => {
	const { getUrl } = useMerchantRoutes();
	const { formatMessage } = useIntl();
	const pluginsUrl = useFormatRoute(routes.plugins);
	const librariesUrl = useFormatRoute(routes.libraries);

	return (
		<WhiteContainer>
			<PageTitle title={formatMessage(MerchantsMessages.integrationTools)} />
			<ul className={styles.list}>
				<IntegrationTool
					external
					icon={apiSvg}
					label={formatMessage(MerchantsMessages.api)}
					link="https://docs.spectrocoin.com"
					data-cy={TestIds.IntegrationApi}
				/>
				<IntegrationTool
					icon={buttonsSvg}
					label={formatMessage(MerchantsMessages.paymentButtons)}
					link={getUrl(MerchantRoutes.PaymentButtonsRoot)}
					data-cy={TestIds.IntegrationPaymentButtons}
				/>
				<IntegrationTool
					external
					icon={pluginsSvg}
					label={formatMessage(MerchantsMessages.cryptoPaymentPlugins)}
					link={pluginsUrl}
					data-cy={TestIds.IntegrationPlugins}
				/>
				<IntegrationTool
					external
					icon={librariesSvg}
					label={formatMessage(MerchantsMessages.libraries)}
					link={librariesUrl}
					data-cy={TestIds.IntegrationLibraries}
				/>
			</ul>
		</WhiteContainer>
	);
};

const IntegrationTools: FC = () => {
	const { getPath } = useMerchantRoutes();

	return (
		<Switch>
			<Route path={getPath(MerchantRoutes.PaymentButtonsRoot)} component={PaymentButtons} />
			<Route
				exact
				path={getPath(MerchantRoutes.IntegrationTools)}
				component={IntegrationToolsView}
			/>
		</Switch>
	);
};

export default IntegrationTools;
