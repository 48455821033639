import {
	FC,
	ForwardedRef,
	forwardRef,
	PropsWithChildren,
	useCallback,
	useContext,
	useTransition,
} from 'react';
import classNames from 'classnames';
import { subDays, subMonths, subWeeks, subYears, startOfDay, endOfDay } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import SvgIcon from '../../../../../../components/SvgIcon/SvgIcon';
import styles from './ChartRange.module.scss';
import DatePicker from '../../../../../../components/DatePicker/DatePicker';
import MerchantProjectChartContext, { RangeType } from '../Context/MerchantProjectChartContext';
import TestIds, { formatTestId } from '../../../../../../test/TestIds';

import calendarWhiteSvg from './images/calendar_white.svg';
import calendarBlueSvg from './images/calendar_blue.svg';
import MerchantsMessages from '../../../../../../redux/MerchantsState/MerchantsMessages';

const ControlIcon = forwardRef(
	({ onClick, className, ...rest }: any, ref: ForwardedRef<HTMLDivElement>) => {
		const {
			range: { type },
		} = useContext(MerchantProjectChartContext);
		const icon = (() => {
			switch (type) {
				case 'Calendar':
					return calendarWhiteSvg;
				default:
					return calendarBlueSvg;
			}
		})();
		return (
			<SvgIcon
				onClick={onClick}
				className={classNames(styles.icon, className)}
				src={icon}
				ref={ref}
				{...rest}
			/>
		);
	}
);

const Option: FC<PropsWithChildren<{ rangeType: RangeType }>> = ({ rangeType, children }) => {
	const {
		range: { type, setFrom, setTo, setType },
	} = useContext(MerchantProjectChartContext);

	const [_, startTransition] = useTransition();

	const activate = useCallback(() => {
		if (rangeType === type) return;

		startTransition(() => {
			setType(rangeType);

			switch (rangeType) {
				case '1D':
					setTo(new Date());
					setFrom(subDays(new Date(), 1));
					break;
				case '1W':
					setTo(new Date());
					setFrom(subWeeks(new Date(), 1));
					break;
				case '1M':
					setTo(new Date());
					setFrom(subMonths(new Date(), 1));
					break;
				case '1Y':
					setTo(new Date());
					setFrom(subYears(new Date(), 1));
					break;
				case 'Calendar':
				default:
					break;
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rangeType, type]);

	return (
		<div
			data-cy={formatTestId(TestIds.ProjectviewChartRange_0, rangeType)}
			data-active={type === rangeType}
			className={classNames(styles.option, type === rangeType && styles.active)}
			onClick={activate}
		>
			{children}
		</div>
	);
};

const ChartRange = () => {
	const {
		range: { from, to, setFrom, setTo },
	} = useContext(MerchantProjectChartContext);
	const [_, startTransition] = useTransition();

	return (
		<div data-cy={TestIds.ProjectviewChartRange} className={styles.container}>
			<Option rangeType="1D">
				<FormattedMessage {...MerchantsMessages.range1D} />
			</Option>
			<Option rangeType="1W">
				<FormattedMessage {...MerchantsMessages.range1W} />
			</Option>
			<Option rangeType="1M">
				<FormattedMessage {...MerchantsMessages.range1M} />
			</Option>
			<Option rangeType="1Y">
				<FormattedMessage {...MerchantsMessages.range1Y} />
			</Option>
			<Option rangeType="Calendar">
				<DatePicker
					className={styles.picker}
					startDate={from}
					endDate={to}
					onChange={([fromUpdated, toUpdated]) => {
						startTransition(() => {
							setFrom(fromUpdated ? startOfDay(fromUpdated) : null);
							setTo(toUpdated ? endOfDay(toUpdated) : null);
						});
					}}
					selectsRange
					customInput={<ControlIcon />}
					popperContainer={({ children }) => (
						<div onClick={(e) => e.stopPropagation()}>{children}</div>
					)}
					disabledKeyboardNavigation
					openToDate={to || undefined}
				/>
			</Option>
		</div>
	);
};

export default ChartRange;
