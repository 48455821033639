import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import Joi from 'joi';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useQuery } from '@tanstack/react-query';
import styles from './SetTrackingId.module.scss';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import ReferralsMessages from '../../../../redux/ReferralsState/ReferralsMessages';
import Input from '../../../../components/Input/Input';
import useValidation from '../../../../hooks/useValidation';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import baseMsg from '../../../../messages/base.messages';
import { defaultCodeQuery } from '../../../../redux/ReferralsState/Queries/ReferralQueries';
import trackLogoSvg from './trackLogo.svg';
import inputErrors from '../../../../messages/inputErrors.messages';
import LabeledField from '../../../Merchants/Shared/LabeledField/LabeledField';

const useTrackIdValidator = () => {
	const { formatMessage } = useIntl();
	return useMemo(
		() =>
			Joi.object<{ trackId: string }>({
				trackId: Joi.string()
					.allow(null, '')
					.regex(/^[a-zA-Z0-9]+$/)
					.message(
						formatMessage(inputErrors.alphaNumericCharacters, {
							Field: formatMessage(ReferralsMessages.trackingId),
						})
					)
					.max(8)
					.message(
						formatMessage(inputErrors.x_lessOrEqual_y, {
							Field: formatMessage(ReferralsMessages.trackingId),
							Max: 8,
						})
					),
			}),
		[formatMessage]
	);
};

const SetTrackingId: FC = () => {
	const { data } = useQuery({
		queryKey: defaultCodeQuery.getKey(),
		queryFn: defaultCodeQuery.getFn(),
	});

	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const [trackId, setTrackId] = useState<string>('');
	const trackIdValidator = useTrackIdValidator();
	const referralUrl = data
		? `${window.location.origin}/?referralId=${data.id}&refTrackId=${trackId || 'xxx'}`
		: null;

	const [isValid, validationResult] = useValidation({ trackId }, trackIdValidator);

	const onClose = useCallback(() => {
		dispatch(toggleModal());
	}, []);

	return (
		<div className={styles.container}>
			<div>
				<SvgIcon src={trackLogoSvg} className={styles.icon} />
				<FontAwesomeIcon
					className={styles.closeIcon}
					icon={faTimes}
					onClick={() => onClose()}
				/>
			</div>
			<div className={styles.content}>
				<div className={styles.title}>{formatMessage(ReferralsMessages.setTrackId)}</div>
				<div>{formatMessage(ReferralsMessages.trackingInfo)}</div>
				<Input
					label={formatMessage(ReferralsMessages.trackingId)}
					className={styles.value}
					value={trackId}
					onChange={setTrackId}
					errorMessage={validationResult.trackId}
				/>
				<LabeledField
					className={styles.value}
					label={formatMessage(ReferralsMessages.linkWithTrackId)}
				>
					{referralUrl || '-'}
				</LabeledField>
			</div>
			<div className={styles.actions}>
				<CopyToClipboard text={referralUrl || ''}>
					<Button
						className={styles.button}
						buttonStyle={ButtonStyle.PRIMARY}
						type={ButtonType.SUBMIT}
						onClick={onClose}
						isDisabled={!trackId || !isValid}
					>
						{formatMessage(ReferralsMessages.copyClose)}
					</Button>
				</CopyToClipboard>
				<Button
					className={styles.button}
					buttonStyle={ButtonStyle.SECONDARY_WITHOUT_BORDER}
					type={ButtonType.SUBMIT}
					onClick={onClose}
				>
					{formatMessage(baseMsg.cancel)}
				</Button>
			</div>
		</div>
	);
};

export default SetTrackingId;
