import { FC, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { RootState } from '../../redux/Store';
import messages from '../../redux/MerchantsState/MerchantsMessages';
import MerchantsMenuPanel from '../../pages/Merchants/Shared/MerchantsMenuPanel/MerchantsMenuPanel';
import styles from './MerchantsLayout.module.scss';
import MerchantsSelectors from '../../redux/MerchantsState/MerchantsSelectors';
import MerchantsActions from '../../redux/MerchantsState/MerchantsActions';
import { RemoteStatus } from '../../interfaces/RemoteData';
import useEffectOnce from '../../hooks/useEffectOnce';

const MobileNavBar: FC = () => {
	const { url } = useRouteMatch();
	const { pathname } = useLocation();

	if (url === pathname) return null;

	return (
		<Link className={styles.navbar} to={url}>
			<FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
			<div>
				<FormattedMessage {...messages.backToMerchant} />
			</div>
		</Link>
	);
};

interface MerchantsLayoutProps {
	children: ReactNode | ReactNode[];
}

const MerchantsLayout: FC<MerchantsLayoutProps> = ({ children }) => {
	const dispatch = useDispatch();
	const { isTablet } = useSelector((state: RootState) => state.AppState);
	const { status } = useSelector(MerchantsSelectors.load);

	useEffectOnce(() => {
		void dispatch(MerchantsActions.loadData());
	});

	switch (status) {
		case RemoteStatus.InProgress:
			return <LoadingScreen />;
		case RemoteStatus.Done:
			return (
				<div className={styles.container}>
					{isTablet && <MobileNavBar />}
					<div className={styles.flexGrid}>
						{!isTablet && <MerchantsMenuPanel className={styles.sidebar} />}
						{children}
					</div>
				</div>
			);

		default:
			return null;
	}
};

export default MerchantsLayout;
