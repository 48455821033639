import {
	WalletHistoryState,
	WalletHistoryType,
	SetIsWalletHistoryLoading,
	SetWalletHistoryDataAction,
	ResetWalletHistoryAction,
} from './WalletHistoryTypes';

const initialState: WalletHistoryState = {
	isLoading: true,
	data: [],
	totalCount: 0,
};

export default (
	state = initialState,
	action: SetIsWalletHistoryLoading | SetWalletHistoryDataAction | ResetWalletHistoryAction
) => {
	switch (action.type) {
		case WalletHistoryType.SET_DATA:
			return {
				...state,
				data: action.data.content,
				totalCount: action.data.totalElements,
			};
		case WalletHistoryType.RESET_DATA:
			return initialState;
		case WalletHistoryType.IS_LOADING:
			return {
				...state,
				isLoading: action.isLoading,
			};
		default:
			return state;
	}
};
