import createSelector from '../../../createSelector';
import { RemoteStatus } from '../../../../interfaces/RemoteData';

const getState = createSelector((state) => state.MerchantState.payouts.data);

const getPayoutById = (id: string) =>
	createSelector(getState, (state) => state.payouts[id] || { status: RemoteStatus.None });

const getPayoutDataById = (id: string) => createSelector(getPayoutById(id), (state) => state?.data);

const getPayoutsDataByIds = (ids: string[]) =>
	createSelector(getState, (state) =>
		ids
			.map((id) => state.payouts[id])
			.filter((x) => x?.status === RemoteStatus.Done)
			.map((x) => x?.data!)
	);

export default {
	getState,
	getPayoutById,
	getPayoutDataById,
	getPayoutsDataByIds,
};
