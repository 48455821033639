import { RemoteData, RemoteError } from '../../../../interfaces/RemoteData';
import PageableResponse from '../../../../interfaces/PageableResponse';
import { Payout } from '../PayoutsData/PayoutsDataTypes';

export type PayoutsHistoryState = {
	totalCount: number;
	pageSize: number;
} & RemoteData<string[]>;

export enum PayoutsHistoryActionType {
	FETCH_PAYOUTS_PENDING = `MERCHANTS/PAYOUTS/HISTORY/FETCH_PAYOUTS/PENDING`,
	FETCH_PAYOUTS_REJECTED = 'MERCHANTS/PAYOUTS/HISTORY/FETCH_PAYOUTS/REJECTED',
	FETCH_PAYOUTS_FULFILLED = 'MERCHANTS/PAYOUTS/HISTORY/FETCH_PAYOUTS/FULFILLED',
}

export type PayoutsHistoryFetchPayoutsPendingAction = {
	type: PayoutsHistoryActionType.FETCH_PAYOUTS_PENDING;
};
export type PayoutsHistoryFetchPayoutsRejectedAction = {
	type: PayoutsHistoryActionType.FETCH_PAYOUTS_REJECTED;
	payload?: RemoteError;
};
export type PayoutsHistoryFetchPayoutsFulfilledAction = {
	type: PayoutsHistoryActionType.FETCH_PAYOUTS_FULFILLED;
	payload: PageableResponse<Payout[]>;
};
