/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable radix */
import { useEffect, useMemo, useState } from 'react';
import { Decimal } from 'decimal.js';
import { PieChart, pieChartDefaultProps } from 'react-minimal-pie-chart';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { CurrencyEnum, currencyUtils } from '@spectrocoin/sc-currencies';
import abbreviateNumber from '../../helpers/abbreviateNumber/abbreviateNumber';
import { RootState } from '../../redux/Store';
import { toDecimal } from '../../helpers/currencyHelper/currencyHelper';
import { RatesDetailsResponse } from '../../redux/RatesState/RatesTypes';
import { Wallet } from '../../redux/AccountsState/AccountsTypes';
import RefCurrencySelect from '../RefCurrencySelect/RefCurrencySelect';
import styles from './Portfolio.module.scss';
import Loader from '../Loader/Loader';
import WalletTestIds from '../../test/Wallets/WalletTestIds';

const SHIFT_SIZE = 7;
const HIDDEN_BALANCE = '********';
const OTHER = 'OTHER';
const MAXIMUM_SECTION_COUNT = 3;
const MINIMUM_SECTION_PERCENTAGE = 3;

const defaultLabelStyle = {
	fontSize: '5px',
	fontFamily: 'sans-serif',
};

interface PortfolioProps {
	refCurrency: CurrencyEnum | string;
}

interface BalanceAmountsProps {
	title: CurrencyEnum | string;
	amount: string | number;
	color: string;
	value: number;
}

interface Amount {
	currencyCode: CurrencyEnum;
	availableBalance: Decimal;
}

const getFullAmount = (array: Amount[]) => {
	return array.reduce((a, b) => a.add(b.availableBalance || toDecimal(0)), toDecimal(0));
};

const getPercentage = (partialValue: Decimal, array: Amount[]) => {
	const totalValue = getFullAmount(array);
	return toDecimal(100).times(partialValue).dividedBy(totalValue).toFixed(18).toString();
};

const Portfolio = ({ refCurrency }: PortfolioProps) => {
	const accounts = useSelector((state: RootState) => state?.AccountsState?.wallets);
	const { ratesDetails, isLoading } = useSelector((state: RootState) => state?.RatesState);
	const [chartPadding, setChartPadding] = useState<number>(0);
	const showBalance = useMemo(() => (accounts?.length ? !accounts[0].hideBalance : true), [
		accounts,
	]);
	const [balanceAmounts, setBalanceAmounts] = useState<BalanceAmountsProps[]>([]);
	const [fullAmount, setFullAmount] = useState<string>('0.00');
	const [chartData, setChartData] = useState<BalanceAmountsProps[]>([]);

	const calculateChartData = () => setChartData(balanceAmounts);

	const mergeAmounts = () => {
		return Array.from(
			accounts?.reduce(
				(m: any, { currencyCode, availableBalance }: Wallet) =>
					m.set(
						currencyCode,
						toDecimal(m.get(currencyCode) || 0).add(toDecimal(availableBalance))
					),
				new Map()
			),
			([currencyCode, availableBalance]) => ({ currencyCode, availableBalance })
		);
	};

	const convertRateToRefCurrency = (array: Amount[]) => {
		const convertedRates = array.reduce((acc: Amount[], cur: any) => {
			ratesDetails?.forEach(({ currencyCode, value }: RatesDetailsResponse) => {
				if (currencyCode === cur.currencyCode) {
					const newCur = {
						...cur,
						availableBalance: cur.availableBalance.times(toDecimal(value)).toFixed(2),
					};
					acc.push(newCur);
				}
			});
			return acc;
		}, []);
		return convertedRates;
	};

	const calculateAmounts = () => {
		const combinedBalanceInfoList = convertRateToRefCurrency(mergeAmounts())?.sort(
			(a: any, b: any) => {
				if (toDecimal(a.availableBalance).greaterThan(toDecimal(b.availableBalance)))
					return -1;
				return 1;
			}
		);
		const entries: any = [];
		const other: any = [];

		const availableBalancesList = combinedBalanceInfoList.filter(
			({ availableBalance }) => !!+availableBalance
		);

		combinedBalanceInfoList.forEach(({ availableBalance, currencyCode }: Amount) => {
			const amountPercentage = getPercentage(availableBalance, combinedBalanceInfoList);
			if (availableBalancesList.length - 1 !== MAXIMUM_SECTION_COUNT) {
				if (entries.length < MAXIMUM_SECTION_COUNT)
					return entries.push({
						color: currencyUtils.getConfigOrDefault(currencyCode).color,
						value: parseInt(amountPercentage),
						title: currencyCode,
						availableBalance: availableBalance.toString(),
					});
				return other.push({
					availableBalance,
				});
			}
			return entries.push({
				color: currencyUtils.getConfigOrDefault(currencyCode).color,
				value: parseInt(amountPercentage),
				title: currencyCode,
				availableBalance: availableBalance.toString(),
			});
		});
		if (
			entries.length === 1 &&
			parseInt(getPercentage(getFullAmount(other), combinedBalanceInfoList).toString()) <
				MINIMUM_SECTION_PERCENTAGE
		) {
			other.push(entries.slice(-1).pop());
			entries.pop();
		}
		return setBalanceAmounts([
			...entries,
			{
				title: OTHER,
				value: Number.isNaN(getPercentage(getFullAmount(other), combinedBalanceInfoList))
					? 100
					: parseInt(
							getPercentage(getFullAmount(other), combinedBalanceInfoList).toString()
					  ),
				color: currencyUtils.getConfig(CurrencyEnum.OTHER).color,
				availableBalance: getFullAmount(other).toString(),
			},
		]);
	};

	useEffect(() => {
		if (accounts && ratesDetails) calculateAmounts();
	}, [accounts, ratesDetails]);

	useEffect(() => {
		if (balanceAmounts?.length > 0) {
			calculateChartData();
			setFullAmount(getFullAmount(convertRateToRefCurrency(mergeAmounts())).toString());
			if (balanceAmounts?.length === 1) return setChartPadding(0);
			return setChartPadding(3);
		}
	}, [balanceAmounts]);

	if (balanceAmounts?.length === 0) return <Loader className={styles.loader} />;
	return (
		<div data-cy={WalletTestIds.PortfolioDiagram} className={styles.container}>
			<RefCurrencySelect containerClass={styles.selectContainer} />
			<div data-cy={WalletTestIds.PortfolioTotal} className={styles.total}>
				<span>
					<FormattedMessage id="portfolio.total" defaultMessage="Total" />
				</span>
				{showBalance ? (
					<span>
						{!isLoading && fullAmount ? abbreviateNumber(fullAmount) : '--'}{' '}
						{refCurrency}
					</span>
				) : (
					<span>{HIDDEN_BALANCE}</span>
				)}
			</div>
			{toDecimal(fullAmount).equals(0) || !showBalance ? (
				<PieChart
					data={[{ color: '#D9DBE5', value: 100, title: refCurrency, balance: 0 }]}
					lineWidth={15}
					paddingAngle={0}
					animate
					radius={pieChartDefaultProps.radius - SHIFT_SIZE}
					labelStyle={{
						...defaultLabelStyle,
					}}
				/>
			) : (
				<PieChart
					data={chartData.filter(({ value }) => !!value)}
					lineWidth={15}
					paddingAngle={chartData.some((item) => item.value === 100) ? 0 : chartPadding}
					animate
					radius={pieChartDefaultProps.radius - SHIFT_SIZE}
					labelStyle={{
						...defaultLabelStyle,
					}}
				/>
			)}
			<ul data-cy={WalletTestIds.PortfolioCurrList} className={styles.currencyList}>
				{balanceAmounts.map(({ title, amount, availableBalance, color }: any) => {
					if (!toDecimal(availableBalance).equals(0)) {
						return (
							<li key={`${amount}${title})`}>
								{title !== OTHER && (
									<div
										className={styles.line}
										style={{ backgroundColor: color }}
									/>
								)}
								{title === OTHER && (
									<div
										className={styles.line}
										style={{ backgroundColor: color }}
									/>
								)}
								<div className={styles.currencyCode}>
									{title === OTHER ? (
										<FormattedMessage
											id="portfolio.other"
											defaultMessage="Other"
										/>
									) : (
										title
									)}
								</div>
								{showBalance ? (
									<div className={styles.value}>
										{isLoading ? '--' : abbreviateNumber(availableBalance)}{' '}
										{refCurrency}
									</div>
								) : (
									<div className={styles.value}>{HIDDEN_BALANCE}</div>
								)}
							</li>
						);
					}
					return null;
				})}
			</ul>
		</div>
	);
};

export default Portfolio;
