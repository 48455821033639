import { FC } from 'react';
import { Route, Switch } from 'react-router';
import useMerchantRoutes, { MerchantRoutes } from '../../../hooks/useMerchantRoutes';
import ViewMerchantsTransaction from './ViewMerchantsTransaction/ViewMerchantsTransaction';

const MerchantsTransaction: FC = () => {
	const { getPath } = useMerchantRoutes();

	return (
		<>
			<Route
				exact
				path={getPath(MerchantRoutes.TransactionView)}
				component={ViewMerchantsTransaction}
			/>
		</>
	);
};

const MerchantsTransactions: FC = () => {
	const { getPath } = useMerchantRoutes();

	return (
		<Switch>
			<Route
				path={getPath(MerchantRoutes.TransactionRoot)}
				component={MerchantsTransaction}
			/>
		</Switch>
	);
};

export default MerchantsTransactions;
