import { ChangeEvent, useCallback, useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import Checkbox from '../../../components/Checkbox/Checkbox';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../components/NotificationMessage/NotificationMessage';
import PageTitle from '../../../components/PageTitle/PageTitle';
import errorMessages, {
	getErrorMessageOrDefault,
} from '../../../helpers/errorMessageHelper/errorMessageHelper';
import { getGdprURL } from '../../../redux/endpoints';
import DocumentsHistory from './DocumentsHistory/DocumentsHistory';
import styles from './Documents.module.scss';
import TwoFaContainer from '../../../containers/TwoFaContainer/TwoFaContainer';
import { TwoFaCode } from '../../../redux/TwoFaState/TwoFaTypes';
import { resetTwoFaData } from '../../../redux/TwoFaState/TwoFaActions';
import axiosInstance from '../../../helpers/axiosInstance';

const messages = defineMessages({
	title: {
		id: 'documents.title',
		defaultMessage: 'Download documents',
	},
	post: {
		id: 'documents.post',
		defaultMessage:
			'To exercise your right to personal data access under the Article 15 of the General Data Protection Regulation, you must fill in the ‘Data Request Form’. You will be notified when your documents are generated and ready for download.',
	},
	requestPending: {
		id: 'documents.requestPending',
		defaultMessage: 'Your request is pending',
	},
	buttonText: {
		id: 'documents.confirm',
		defaultMessage: 'Confirm request',
	},
});

export interface DocumentsState {
	personal: boolean;
	thirdParties: boolean;
	transactionHistory: boolean;
}

const ALL = 'all';

const Documents = () => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();

	const [state, setState] = useState<DocumentsState>({
		personal: false,
		thirdParties: false,
		transactionHistory: false,
	});
	const [isRequesting, setIsRequesting] = useState<boolean>(false);
	const [generalError, setGeneralError] = useState<MessageDescriptor | null>(null);
	const [successMessage, setSuccessMessage] = useState<MessageDescriptor | null>(null);

	const handleSubmit = () => {
		setIsRequesting(true);
		setGeneralError(null);
		setSuccessMessage(null);
		void axiosInstance
			.post(getGdprURL(), state)
			.then(() => {
				setSuccessMessage(messages.requestPending);
				dispatch(resetTwoFaData());
			})
			.catch((err) => {
				if (err.response.data.errorCode !== TwoFaCode.TWO_FA_REQUIRED) {
					setGeneralError(
						getErrorMessageOrDefault(errorMessages[err.response?.data?.errorCode])
					);
					dispatch(resetTwoFaData());
				}
			})
			.then(() => setIsRequesting(false));
	};

	const onSuccess = () => {
		setSuccessMessage(messages.requestPending);
		dispatch(resetTwoFaData());
	};

	const onFailure = (err: any) => {
		setGeneralError(getErrorMessageOrDefault(err));
		dispatch(resetTwoFaData());
	};

	const handleInputChange = useCallback(
		({ target: { id, checked } }: ChangeEvent<HTMLInputElement>) => {
			setGeneralError(null);
			setSuccessMessage(null);
			if (id === ALL) {
				return setState({
					personal: checked,
					thirdParties: checked,
					transactionHistory: checked,
				});
			}
			return setState({
				...state,
				[id]: checked,
			});
		},
		[state]
	);

	return (
		<>
			<PageTitle title={messages.title} className={styles.mobilePageTitle} />
			<p className={styles.post}>{formatMessage(messages.post)}</p>
			<TwoFaContainer
				onSuccess={onSuccess}
				onFailure={onFailure}
				onSubmit={handleSubmit}
				buttonText={messages.buttonText}
				isLoading={isRequesting}
				isDisabled={!state.personal && !state.thirdParties && !state.transactionHistory}
			>
				{generalError && (
					<NotificationMessage
						withIcon
						className={styles.error}
						type={NotificationType.Error}
						style={NotificationStyle.Border}
						message={
							typeof generalError === 'string' ? (
								generalError
							) : (
								<FormattedMessage {...generalError} />
							)
						}
					/>
				)}
				{successMessage && (
					<NotificationMessage
						withIcon
						className={styles.error}
						type={NotificationType.Success}
						style={NotificationStyle.Border}
						message={<FormattedMessage {...successMessage} />}
					/>
				)}
				<form onSubmit={handleSubmit} className={styles.form}>
					<Checkbox
						id="personal"
						checked={state.personal}
						onChange={handleInputChange}
						className={styles.checkbox}
						isDisabled={isRequesting}
					>
						<FormattedMessage
							id="documents.personalInformation"
							defaultMessage="Personal information"
						/>
					</Checkbox>
					<Checkbox
						id="transactionHistory"
						checked={state.transactionHistory}
						onChange={handleInputChange}
						className={styles.checkbox}
						isDisabled={isRequesting}
					>
						<FormattedMessage
							id="documents.transactionHistory"
							defaultMessage="Transaction history"
						/>
					</Checkbox>
					<Checkbox
						id="thirdParties"
						checked={state.thirdParties}
						onChange={handleInputChange}
						className={styles.checkbox}
						isDisabled={isRequesting}
					>
						<FormattedMessage
							id="documents.thirdParties"
							defaultMessage="Third parties"
						/>
					</Checkbox>
					<Checkbox
						id={ALL}
						checked={state.thirdParties && state.personal && state.transactionHistory}
						onChange={handleInputChange}
						className={styles.checkbox}
						isDisabled={isRequesting}
					>
						<FormattedMessage id="documents.all" defaultMessage="All" />
					</Checkbox>
				</form>
			</TwoFaContainer>
			<DocumentsHistory />
		</>
	);
};

export default Documents;
