/* eslint-disable consistent-return */
import { Route } from 'react-router-dom';
import IntegrationTools from '../../pages/Merchants/IntegrationTools/IntegrationTools';
import messages from '../../redux/MerchantsState/MerchantsMessages';
import useMerchantRoutes, { MerchantRoutes } from '../../hooks/useMerchantRoutes';
import MobileHomepage from '../../pages/Merchants/MobileHomepage/MobileHomepage';
import styles from './MerchantsContainer.module.scss';
import Seo from '../../components/Seo/Seo';
import MerchantProjects from '../../pages/Merchants/MerchantsProjects/MerchantProjects';
import CryptoPayouts from '../../pages/Merchants/CryptoPayouts/CryptoPayouts';

const MerchantsContainer = () => {
	const { getPath } = useMerchantRoutes();

	return (
		<div className={styles.container}>
			<Seo title={messages.metaTitle} />
			<div className={styles.flexGrid}>
				<div className={styles.content}>
					<Route
						exact
						path={getPath(MerchantRoutes.MobileHomepage)}
						component={MobileHomepage}
					/>
					<Route
						path={getPath(MerchantRoutes.ProjectsRoot)}
						component={MerchantProjects}
					/>
					<Route
						path={getPath(MerchantRoutes.IntegrationToolsRoot)}
						component={IntegrationTools}
					/>
					<Route path={getPath(MerchantRoutes.CryptoPayouts)} component={CryptoPayouts} />
				</div>
			</div>
		</div>
	);
};

export default MerchantsContainer;
