import { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { CurrencyIcon } from '@spectrocoin/sc-currencies';
import Loading from '../../Shared/Loading/Loading';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import { useOrderStatusTranslator } from '../../../../helpers/merchantsHelper/merchantsHelper';
import Button from '../../../../components/Button/Button';
import LabeledField, { LabeledFieldStyle } from '../../Shared/LabeledField/LabeledField';
import { RootState } from '../../../../redux/Store';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import { OrderCallback } from '../../../../redux/MerchantsState/MerchantTypes';
import MerchantsMessages from '../../../../redux/MerchantsState/MerchantsMessages';
import useMerchantRoutes, { MerchantRoutes } from '../../../../hooks/useMerchantRoutes';

import styles from './MerchantsCallbacksList.module.scss';
import convertToLocalTime from '../../../../helpers/dateHelper/dateHelper';
import TestIds, { formatTestId } from '../../../../test/TestIds';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import useFormatAmount from '../../../../hooks/useFormatAmount';
import {
	useOrderCallbacksListDataByOrderId,
	useRetryCallbackMutation,
} from '../../../../redux/MerchantsState/OrderCallbacks/OrderCallbacksQueries';

const Empty: FC = () => {
	return (
		<span data-cy={TestIds.CallbackListEmpty} className={styles.empty}>
			<FormattedMessage {...MerchantsMessages.noCallbacksToMerchant} />
		</span>
	);
};

const Header: FC = () => {
	return (
		<li className={classNames(styles.header, styles.row)}>
			<div>
				<FormattedMessage {...MerchantsMessages.id} />
			</div>
			<div>
				<FormattedMessage {...MerchantsMessages.status} />
			</div>
			<div>
				<FormattedMessage {...MerchantsMessages.received} />
			</div>
			<div>
				<FormattedMessage {...MerchantsMessages.responseStatusCode} />
			</div>
			<div>
				<FormattedMessage {...MerchantsMessages.date} />
			</div>
		</li>
	);
};

const ListItem: FC<{ callback: OrderCallback }> = ({
	callback: { id, orderStatus, receivedAmount, receiveCurrencyCode, statusCode, created },
}) => {
	const { getUrl } = useMerchantRoutes();
	const translateStatus = useOrderStatusTranslator();

	return (
		<li
			className={classNames(styles.listItem, styles.row)}
			data-cy={formatTestId(TestIds.CallbackListItem_0, id)}
		>
			<div className={styles.link}>
				<Link
					data-cy={TestIds.CallbackListItemId}
					to={getUrl(MerchantRoutes.CallbackView, { callbackId: id })}
				>
					{id}
				</Link>
			</div>
			<div data-cy={TestIds.CallbackListItemOrderStatus}>{translateStatus(orderStatus)}</div>
			<div className={styles.currency} data-cy={TestIds.CallbackListItemReceived}>
				<CurrencyIcon
					currencyType={receiveCurrencyCode}
					className={classNames(styles.currencyIcon)}
				/>
				{`${formatPrecision(receivedAmount, receiveCurrencyCode)} ${receiveCurrencyCode}`}
			</div>
			<div data-cy={TestIds.CallbackListItemStatusCode}>{statusCode}</div>
			<div data-cy={TestIds.CallbackListItemDate}>
				{convertToLocalTime(created, 'yyyy-MM-dd hh:mm:ss')}
			</div>
		</li>
	);
};

const MobileListItem: FC<{ callback: OrderCallback }> = ({
	callback: { id, orderStatus, receivedAmount, receiveCurrencyCode, statusCode, created },
}) => {
	const { formatMessage } = useIntl();
	const { getUrl } = useMerchantRoutes();
	const translateStatus = useOrderStatusTranslator();
	const amountFormatter = useFormatAmount();

	return (
		<li className={classNames(styles.listItem, styles.row, styles.mobileListItem)}>
			<LabeledField
				className={styles.mobileProperty}
				labelClassName={styles.mobileLabel}
				contentClassName={styles.mobileContent}
				style={LabeledFieldStyle.Row}
				label={formatMessage(MerchantsMessages.id)}
			>
				<div className={styles.link}>
					<Link to={getUrl(MerchantRoutes.CallbackView, { callbackId: id })}>{id}</Link>
				</div>
			</LabeledField>
			<LabeledField
				className={styles.mobileProperty}
				labelClassName={styles.mobileLabel}
				contentClassName={styles.mobileContent}
				style={LabeledFieldStyle.Row}
				label={formatMessage(MerchantsMessages.status)}
			>
				<div>{translateStatus(orderStatus)}</div>
			</LabeledField>
			<LabeledField
				className={styles.mobileProperty}
				labelClassName={styles.mobileLabel}
				contentClassName={styles.mobileContent}
				style={LabeledFieldStyle.Row}
				label={formatMessage(MerchantsMessages.received)}
			>
				<div className={styles.currency}>
					<CurrencyIcon
						currencyType={receiveCurrencyCode}
						className={classNames(styles.currencyIcon)}
					/>
					{`${amountFormatter(
						formatPrecision(receivedAmount, receiveCurrencyCode)
					)} ${receiveCurrencyCode}`}
				</div>
			</LabeledField>
			<LabeledField
				className={styles.mobileProperty}
				labelClassName={styles.mobileLabel}
				contentClassName={styles.mobileContent}
				style={LabeledFieldStyle.Row}
				label={formatMessage(MerchantsMessages.responseStatusCode)}
			>
				<div>{statusCode}</div>
			</LabeledField>
			<LabeledField
				className={styles.mobileProperty}
				labelClassName={styles.mobileLabel}
				contentClassName={styles.mobileContent}
				style={LabeledFieldStyle.Row}
				label={formatMessage(MerchantsMessages.date)}
			>
				{convertToLocalTime(created, 'yyyy-MM-dd hh:mm:ss')}
			</LabeledField>
		</li>
	);
};

const MobileContent: FC = () => {
	const { getParams } = useMerchantRoutes();

	const { orderId = '' } = getParams();
	const { data } = useOrderCallbacksListDataByOrderId(orderId);

	if (!data) return null;

	return (
		<ul className={styles.table}>
			{data.map((callback) => (
				<MobileListItem key={callback.id} callback={callback} />
			))}
		</ul>
	);
};

const Content: FC = () => {
	const { getParams } = useMerchantRoutes();

	const { orderId = '' } = getParams();
	const { data } = useOrderCallbacksListDataByOrderId(orderId);

	if (!data) return null;

	return (
		<ul className={styles.table}>
			<Header />
			{data.map((callback) => (
				<ListItem key={callback.id} callback={callback} />
			))}
		</ul>
	);
};

type Props = {
	showRetry?: boolean;
};

const MerchantsCallbacksList: FC<Props> = ({ showRetry = false }) => {
	const { isMobile } = useSelector((state: RootState) => state.AppState);
	const { formatMessage } = useIntl();
	const { getParams } = useMerchantRoutes();

	const { orderId = '' } = getParams();
	const { error, isFetching, data } = useOrderCallbacksListDataByOrderId(orderId);
	const { mutate, error: retryError, isPending } = useRetryCallbackMutation(orderId);

	return (
		<div className={styles.container} data-cy={TestIds.CallbackList}>
			<div className={styles.title}>
				<FormattedMessage {...MerchantsMessages.callbackToMerchant} />
			</div>
			{showRetry && (
				<div className={styles.retry}>
					<Button
						data-cy={TestIds.CallbackListRetry}
						className={styles.retryButton}
						isDisabled={isPending}
						onClick={() => mutate()}
					>
						<FormattedMessage {...MerchantsMessages.retry} />
					</Button>
					{retryError && (
						<NotificationMessage
							withIcon
							data-cy={TestIds.CallbackListRetryError}
							type={NotificationType.Error}
							style={NotificationStyle.Border}
							message={formatMessage(getErrorMessageOrDefault(retryError))}
						/>
					)}
				</div>
			)}
			<div className={styles.body}>
				{isFetching && <Loading />}
				{error && (
					<NotificationMessage
						withIcon
						data-cy={TestIds.CallbackListError}
						message={formatMessage(getErrorMessageOrDefault(error))}
						type={NotificationType.Error}
						style={NotificationStyle.Border}
					/>
				)}
				{data && !data.length && <Empty />}
				{data && !!data.length && (isMobile ? <MobileContent /> : <Content />)}
			</div>
		</div>
	);
};

export default MerchantsCallbacksList;
