/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, InputHTMLAttributes, ReactNode, useCallback, useState } from 'react';
import { MessageDescriptor, useIntl, FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import generateUUID from '../../helpers/generateUUID/generateUUID';
import styles from './Input.module.scss';
import TestIds, { formatTestId } from '../../test/TestIds';
import Tooltip from '../Tooltip/Tooltip';

interface InputProps
	extends Omit<InputHTMLAttributes<HTMLInputElement>, 'placeholder' | 'onChange'> {
	inputGroupClassName?: string;
	labelClassName?: string;
	className?: string;
	errorMessage?: MessageDescriptor | string | null;
	faIcon?: any;
	label?: MessageDescriptor | string;
	onChange?: (value: string) => void;
	onChangeEvent?: (e: ChangeEvent<HTMLInputElement>) => void;
	placeholder?: MessageDescriptor | string;
	value: string;
	children?: ReactNode;
	tooltipText?: MessageDescriptor | string;
}

const Input = ({
	inputGroupClassName,
	className,
	labelClassName,
	errorMessage,
	faIcon,
	label,
	onChange,
	onChangeEvent,
	placeholder,
	type = 'text',
	value,
	id,
	tooltipText = '',
	children,
	...props
}: InputProps) => {
	const { formatMessage } = useIntl();
	const [inputId] = useState(id || generateUUID());

	const onInputChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			if (typeof onChange === 'function') {
				onChange(event.target.value);
			}
			if (typeof onChangeEvent === 'function') onChangeEvent(event);
			return null;
		},
		[onChange, onChangeEvent]
	);

	return (
		<div className={classNames(styles.inputGroup, inputGroupClassName)}>
			{label && (
				<label htmlFor={inputId} className={classNames(styles.label, labelClassName)}>
					<span>
						{typeof label === 'string' && label}
						{typeof label !== 'string' && <FormattedMessage {...label} />}
					</span>
					{tooltipText && (
						<Tooltip
							infoIconVisible
							content={
								<div className={styles.toolTipContainer}>
									<div className={styles.toggleToolTip}>
										{typeof tooltipText === 'string'
											? tooltipText
											: formatMessage(tooltipText)}
									</div>
									<div className={styles.toolTipBottomArrow} />
								</div>
							}
						/>
					)}
				</label>
			)}
			<input
				className={classNames(styles.input, className, {
					[styles.errorInput]: errorMessage,
				})}
				placeholder={
					typeof placeholder === 'string'
						? placeholder
						: placeholder
						? formatMessage(placeholder)
						: ''
				}
				onChange={(event) => onInputChange(event)}
				value={value}
				type={type}
				id={inputId}
				data-cy={formatTestId(TestIds.Input, id)}
				{...props}
			/>
			{children && children}
			{faIcon && <FontAwesomeIcon className={styles.icon} icon={faIcon} size="1x" />}
			{errorMessage && (
				<div className={styles.error} data-cy={`${id || ''}InputErrorMessage`}>
					{typeof errorMessage === 'string' ? (
						errorMessage
					) : (
						<FormattedMessage {...errorMessage} />
					)}
				</div>
			)}
		</div>
	);
};

export default Input;
