/* eslint-disable no-param-reassign */
import { RemoteStatus } from '../../../interfaces/RemoteData';
import {
	OrderPaymentState,
	OrderPaymentActionType,
	OrderPaymentFetchOrderFulfilledAction,
	OrderPaymentFetchOrderPendingAction,
	OrderPaymentFetchOrderRejectedAction,
	OrderPaymentPayOrderPendingAction,
	OrderPaymentPayOrderRejectedAction,
	OrderPaymentPayOrderFulfilledAction,
	OrderPaymentSetFormAction,
} from './OrderPaymentTypes';

const initialState: OrderPaymentState = {
	order: { status: RemoteStatus.None },
	form: { walletId: null },
	pay: { status: RemoteStatus.None },
};

export default (
	state = initialState,
	action:
		| OrderPaymentFetchOrderFulfilledAction
		| OrderPaymentFetchOrderPendingAction
		| OrderPaymentFetchOrderRejectedAction
		| OrderPaymentPayOrderPendingAction
		| OrderPaymentPayOrderRejectedAction
		| OrderPaymentPayOrderFulfilledAction
		| OrderPaymentSetFormAction
): OrderPaymentState => {
	switch (action.type) {
		case OrderPaymentActionType.FETCH_OREDER_PENDING:
			return {
				...state,
				order: { status: RemoteStatus.InProgress },
			};
		case OrderPaymentActionType.FETCH_OREDER_REJECTED:
			return {
				...state,
				order: { status: RemoteStatus.Error, error: action.payload },
			};
		case OrderPaymentActionType.FETCH_OREDER_FULFILLED:
			return {
				...state,
				order: {
					status: RemoteStatus.Done,
					data: action.payload,
				},
			};
		case OrderPaymentActionType.PAY_OREDER_PENDING:
			return {
				...state,
				pay: { status: RemoteStatus.InProgress },
			};
		case OrderPaymentActionType.PAY_OREDER_REJECTED:
			return {
				...state,
				pay: { status: RemoteStatus.Error, error: action.payload },
			};
		case OrderPaymentActionType.PAY_OREDER_FULFILLED:
			return {
				...state,
				pay: { status: RemoteStatus.Done },
			};
		case OrderPaymentActionType.SET_FORM:
			return {
				...state,
				form: {
					...state.form,
					...action.payload,
				},
			};
		default:
			return state;
	}
};
