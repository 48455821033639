import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { Project, ProjectTestStatus } from '../ProjectsTypes';

export type ProjectCreateForm = {
	name: string;
	description: string | null;
	autoConvert: string | null;
	currencies: CurrencyEnum[];
	publicKey: string | null;
	testStatus: ProjectTestStatus | null;
	isEnabled: boolean;
	forVerifiedPayers: boolean;
	forPayerFee: boolean;
};

export enum ProjectsCreateActionType {
	PROJECT_CREATED = 'MERCHANTS/PROJECTS/CREATE/PROJECT_CREATED',
}

export enum ProjectsCreateViewState {
	None,
	Creating,
	Error,
	Success,
}

export type ProjectsCreateProjectCreatedAction = {
	type: ProjectsCreateActionType.PROJECT_CREATED;
	payload: Project;
};
