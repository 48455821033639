import { Action, Dispatch, MiddlewareAPI } from 'redux';
import { ProfileActionType } from './ProfileTypes';
import { resetIdleTimer } from './ProfileActions';

const ProfileMiddleware = (_: MiddlewareAPI) => (next: Dispatch) => (action: Action) => {
	const result = next(action);

	switch (action.type) {
		case ProfileActionType.SET_IS_SCA:
			_.dispatch(resetIdleTimer());
			break;
		default:
			break;
	}

	return result;
};

export default [ProfileMiddleware];
