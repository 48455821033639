import React, { useContext } from 'react';

interface SortableListRecordContextProps<T> {
	record: T;
}

const SortableListContext = React.createContext<SortableListRecordContextProps<any>>({
	record: {},
});

export const useSortableListRecord = <T>() =>
	useContext<SortableListRecordContextProps<T>>(SortableListContext)?.record || null;

export const SortableListContextProvider = SortableListContext.Provider;
