import { useIntl, defineMessages } from 'react-intl';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import Button, { ButtonType, ButtonStyle } from '../../../../components/Button/Button';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import notEnoughMoneyImgPath from './images/notEnoughMoney.svg';
import styles from './NotEnoughMoney.module.scss';

const messages = defineMessages({
	title: {
		id: 'notEnoughMoney.title',
		defaultMessage: 'Not enough money in your account',
	},
	paragraph: {
		id: 'notEnoughMoney.paragraph',
		defaultMessage:
			'Please top-up your SpectroCoin account in order to have sufficient funds for the ordering of the SpectroCoin card.',
	},
	buttonText: {
		id: 'notEnoughMoney.buttonText',
		defaultMessage: 'Deposit',
	},
	back: {
		id: 'cardsContainer.back',
		defaultMessage: 'Back',
	},
});

const NotEnoughMoney = () => {
	const { locale } = useIntl();

	return (
		<WhiteContainer>
			<InfoHead
				title={messages.title}
				text={messages.paragraph}
				img={notEnoughMoneyImgPath}
			/>
			<Button
				link={`/${locale}/deposit/bank?currency=${CurrencyEnum.EUR}`}
				type={ButtonType.LINK}
				text={messages.buttonText}
				buttonStyle={ButtonStyle.PRIMARY}
				className={styles.button}
			/>
		</WhiteContainer>
	);
};

export default NotEnoughMoney;
