import { useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { alpha2ToAlpha3, alpha3ToAlpha2, getName } from 'i18n-iso-countries';
import queryString, { ParsedQuery } from 'query-string';
import { useLocation } from 'react-router-dom';
import styles from './CashDeposits.module.scss';
import { RootState } from '../../../redux/Store';
import PaymentMethod, { PaymentMethodGroup } from '../../../redux/DepositState/DepositTypes';
import {
	fetchCountries,
	fetchPaymentMethodsByGroup,
} from '../../../redux/DepositState/DepositActions';
import Loader from '../../../components/Loader/Loader';
import PageTitle from '../../../components/PageTitle/PageTitle';
import depositMessages from '../../../redux/DepositState/DepositMessages';
import DepositList, { CashListItem } from '../Shared/DepositList/DepositList';
import Seo from '../../../components/Seo/Seo';
import TestIds from '../../../test/TestIds';
import useEffectOnce from '../../../hooks/useEffectOnce';
import FilterSelect from '../../Withdraw/Shared/FilterSelect/FilterSelect';

const messages = defineMessages({
	filterByCountry: {
		id: 'base.filterByCountry',
		defaultMessage: 'Filter by country',
	},
	availableInCountries: {
		id: 'cashDeposits.listItem.availableInCountries',
		defaultMessage: 'Available in countries: {countries}',
	},
	notAvailableInSelectedCountry: {
		id: 'cashDeposits.listItem.notAvailableInSelectedCountry',
		defaultMessage: 'Not available in selected country',
	},
});

const CashDeposits = () => {
	const { formatMessage, locale } = useIntl();
	const { search } = useLocation();
	const parsedQuery: ParsedQuery<any> = queryString.parse(search);
	const dispatch = useDispatch();
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const { countries, paymentMethods } = useSelector((state: RootState) => state.DepositState);

	const [activeMethods, setActiveMethods] = useState<PaymentMethod[]>([]);
	const [disabledMethods, setDisabledMethods] = useState<PaymentMethod[]>([]);
	const isUserVerified = !!user?.verified;

	const [country, setCountry] = useState<string>(
		parsedQuery?.country
			? `${parsedQuery?.country}/${getName(parsedQuery?.country, 'en').replace(
					', Province of China',
					''
			  )}`
			: user?.country
			? `${alpha3ToAlpha2(user?.country)}/${getName(user?.country, 'en').replace(
					', Province of China',
					''
			  )}`
			: ''
	);

	const isActive = useCallback(
		(method: PaymentMethod) => {
			return (
				(!method.enabledCountries && !method.disabledCountries) ||
				(method.enabledCountries === null &&
					!method.disabledCountries?.includes(alpha2ToAlpha3(country.split('/')[0]))) ||
				(method.disabledCountries === null &&
					method.enabledCountries?.includes(alpha2ToAlpha3(country.split('/')[0])))
			);
		},
		[country]
	);

	const addDisabledMessage = useCallback(
		(method: PaymentMethod) => {
			const disabledMessage = method.enabledCountries
				? formatMessage(messages.availableInCountries, {
						countries: method.enabledCountries,
				  })
				: formatMessage(messages.notAvailableInSelectedCountry);

			return {
				...method,
				disabledMessage,
			};
		},
		[formatMessage]
	);

	useEffectOnce(() => {
		void dispatch(fetchPaymentMethodsByGroup(PaymentMethodGroup.CASH));
		void dispatch(fetchCountries());
	});

	useEffect(() => {
		if (country && paymentMethods.cash.data.length > 0) {
			setActiveMethods(
				paymentMethods.cash.data.filter((method: PaymentMethod) => isActive(method))
			);
			setDisabledMethods(
				paymentMethods.cash.data
					.filter((method: PaymentMethod) => !isActive(method))
					.map((method: PaymentMethod) => addDisabledMessage(method))
			);
		}
	}, [addDisabledMessage, country, formatMessage, isActive, paymentMethods]);

	if (
		activeMethods.length === 0 &&
		disabledMethods.length === 0 &&
		Object.keys(countries?.supportedCountries || []).length === 0
	)
		return <Loader className={styles.loader} />;
	return (
		<div data-cy={TestIds.cashDepositsView}>
			<Seo title={depositMessages.metaCashDepositsTitle} />
			<PageTitle
				historyLink={{
					pathname: `/${locale}/deposit/history/fiat/`,
					state: { backUrl: window.location.pathname },
				}}
				title={depositMessages.cashDeposits}
				previousPageLink={`/${locale}/deposit`}
				isPreviousPageLinkVisibleOnMobile={false}
			/>
			<FilterSelect
				onChange={setCountry}
				value={country}
				availableCountries={Object.keys(countries?.supportedCountries || []).map((x) =>
					alpha3ToAlpha2(x)
				)}
				isCountry
			/>
			<DepositList
				group={PaymentMethodGroup.CASH}
				isUserVerified={isUserVerified}
				paymentMethods={activeMethods}
				unavailablePaymentMethods={disabledMethods as CashListItem[]}
			/>
		</div>
	);
};

export default CashDeposits;
