import { ActionCreator } from 'redux';
import { PaymentButton } from '../PaymentButtonsData/PaymentButtonsDataTypes';
import {
	PaymentButtonCreateActionFulfilledAction,
	PaymentButtonCreateActionType,
} from './PaymentButtonsCreateTypes';

const setActionFulfilled: ActionCreator<PaymentButtonCreateActionFulfilledAction> = (
	button: PaymentButton
) => ({
	type: PaymentButtonCreateActionType.ACTION_FULFILLED,
	payload: button,
});

export default {
	setActionFulfilled,
};
