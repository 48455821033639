import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Block from '../../Shared/Block/Block';
import Loading from '../../Shared/Loading/Loading';
import TextNode, { TextStyles } from '../../../../components/TextNode/TextNode';
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import TestIds, { formatTestId } from '../../../../test/TestIds';
import MerchantsOrdersHistory from '../../MerchantsOrders/MerchantsOrdersHistory/MerchantsOrdersHistory';
import useMerchantRoutes, { MerchantRoutes } from '../../../../hooks/useMerchantRoutes';
import { Project } from '../../../../redux/MerchantsState/MerchantTypes';
import AutoConvertCurrency from '../../Shared/AutoConvertCurrency/AutoConvertCurrency';
import ProjectMode from '../../Shared/Mode/Mode';
import CopyButton from '../../../../components/CopyButton/CopyButton';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import PageTitle from '../../Shared/PageTitle/PageTitle';
import NoProjects from '../../Shared/NotFound/NotFound';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';
import MerchantsActions from '../../../../redux/MerchantsState/MerchantsActions';
import MerchantsSelectors from '../../../../redux/MerchantsState/MerchantsSelectors';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import { RootState } from '../../../../redux/Store';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import PublicOrderLinkModal from './PublicOrderLinkModal/PublicOrderLinkModal';
import PropertyBlock from '../../Shared/PropertyBlock/PropertyBlock';

import styles from './ViewMerchantsProject.module.scss';
import MerchantProjectChart from './MerchantProjectChart/MerchantProjectChart';

const IdBlock: FC<{ label: string; value: string }> = ({ label, value, ...rest }) => {
	return (
		<Block {...rest} className={styles.idBlock}>
			<div className={styles.label}>
				<div>{label}</div>
				<CopyButton className={styles.copyButton} dataToCopy={value} />
			</div>
			<div className={styles.value}>{value}</div>
		</Block>
	);
};

const ProjectPropertyBlock: FC<{ project: Project }> = ({ project }) => {
	const { formatMessage } = useIntl();
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const wallet = wallets?.find((x) => x.id === project.receiveAccountId);
	const payCurrencies = project.payCurrencies
		? wallets?.filter((x) => project.payCurrencies?.includes(x.currencyCode)) || []
		: [];
	return (
		<PropertyBlock
			properties={[
				...(project.description
					? [
							{
								label: formatMessage(messages.description),
								attr: { 'data-cy': TestIds.ProjectViewDescription },
								content: (
									<TextNode style={TextStyles.BreakWord}>
										{project.description}
									</TextNode>
								),
							},
					  ]
					: []),
				{
					label: formatMessage(messages.autoConvertCurrency),
					attr: { 'data-cy': TestIds.ProjectViewAutoconvert },
					content: !wallet ? (
						<span>-</span>
					) : (
						<AutoConvertCurrency
							className={styles.autoconvert}
							iconClassName={styles.autoconvertIcon}
							currencyCode={wallet.currencyCode}
							currencyName={wallet.currencyName}
						/>
					),
				},
				{
					label: formatMessage(messages.acceptableCurrencies),
					attr: {
						'data-cy': TestIds.ProjectViewAcceptedCurrencies,
						'data-count': payCurrencies.length,
					},
					content: (
						<TextNode style={TextStyles.BreakWord}>
							{payCurrencies.length === 0 &&
								formatMessage(messages.allCurrenciesAvailable)}
							{payCurrencies.length === 1 && (
								<AutoConvertCurrency
									className={styles.autoconvert}
									iconClassName={styles.autoconvertIcon}
									currencyCode={payCurrencies[0].currencyCode}
									currencyName={payCurrencies[0].currencyName}
								/>
							)}
							{payCurrencies.length > 1 &&
								formatMessage(messages.x_currencies, {
									Count: payCurrencies.length,
								})}
						</TextNode>
					),
					expandableContent:
						payCurrencies.length > 1 ? (
							<div className={styles.acceptedCurrencies}>
								{payCurrencies.map((currency) => (
									<AutoConvertCurrency
										data-cy={formatTestId(
											TestIds.ProjectViewAcceptedCurrencies_0,
											currency.currencyCode
										)}
										key={currency.currencyCode}
										className={styles.autoconvert}
										iconClassName={styles.autoconvertIcon}
										currencyCode={currency.currencyCode}
										currencyName={currency.currencyName}
									/>
								))}
							</div>
						) : undefined,
				},
				{
					label: formatMessage(messages.mode),
					attr: { 'data-cy': TestIds.ProjectViewMode },
					content: <ProjectMode className={styles.mode} status={project} />,
				},
			]}
		/>
	);
};

const Content: FC = () => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const { getUrl, getParams } = useMerchantRoutes();
	const { projectId } = getParams();

	const userId = useSelector((state: RootState) => state.ProfileState.user?.id);
	const project = useSelector(MerchantsSelectors.projects.data.getProjectDataById(projectId!))!;

	return (
		<>
			<PageTitle title={project.name}>
				<Link data-cy={TestIds.ProjectViewEdit} to={getUrl(MerchantRoutes.ProjectEdit)}>
					<FormattedMessage {...messages.editProject} />
				</Link>
			</PageTitle>
			<div className={styles.content}>
				<div className={styles.actions}>
					<Button
						data-cy={TestIds.ProjectViewCreateOrder}
						className={styles.action}
						buttonStyle={ButtonStyle.PRIMARY}
						type={ButtonType.LINK}
						isDisabled={!project.enabled}
						link={getUrl(MerchantRoutes.PreOrderCreate)}
					>
						{formatMessage(messages.createOrder)}
					</Button>
					<Button
						data-cy={TestIds.ProjectViewCreatePublicOrder}
						className={styles.action}
						buttonStyle={ButtonStyle.SECONDARY_WITHOUT_BORDER}
						type={ButtonType.BUTTON}
						isDisabled={!project.enabled}
						onClick={() =>
							dispatch(toggleModal(<PublicOrderLinkModal id={project.id} />))
						}
					>
						{formatMessage(messages.createPublicPaymentLink)}
					</Button>
				</div>
				<IdBlock
					data-cy={TestIds.ProjectViewMerchantId}
					label={formatMessage(messages.merchantId)}
					value={userId || ''}
				/>
				<IdBlock
					data-cy={TestIds.ProjectViewMerchantApiId}
					label={formatMessage(messages.projectApiId)}
					value={project.id}
				/>
				<ProjectPropertyBlock project={project} />
				<Block>
					<MerchantProjectChart />
				</Block>
			</div>
		</>
	);
};

const ViewMerchantsProject: FC = () => {
	const { getParams } = useMerchantRoutes();
	const { projectId = '' } = getParams();
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const { status: projectStatus } = useSelector(
		MerchantsSelectors.projects.data.getProjectById(projectId)
	);

	useEffect(() => {
		if (projectStatus === RemoteStatus.None)
			dispatch(MerchantsActions.projects.data.fetchProject(projectId));
	}, [dispatch, projectId, projectStatus]);

	if (projectStatus === RemoteStatus.None) return null;

	return (
		<div className={styles.host}>
			<WhiteContainer className={styles.container}>
				{projectStatus === RemoteStatus.InProgress && <Loading />}
				{projectStatus === RemoteStatus.Error && (
					<NoProjects message={formatMessage(messages.projectNotFound)} />
				)}
				{projectStatus === RemoteStatus.Done && <Content />}
			</WhiteContainer>
			{projectStatus === RemoteStatus.Done && (
				<MerchantsOrdersHistory projectId={projectId} />
			)}
		</div>
	);
};

export default ViewMerchantsProject;
