import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { FC, useCallback, useState } from 'react';
import xIconImgPath from './img/x_icon.svg';
import styles from './CancelOrder.module.scss';
import convertMsg from '../../../convertMsg';
import Button, { ButtonStyle } from '../../../../../components/Button/Button';
import toggleModal from '../../../../../redux/ModalState/ModalActions';
import baseMsg from '../../../../../messages/base.messages';
import { updateOpenOrder } from '../../../../../redux/ConvertHistoryState/ConvertHistoryActions';
import { OpenOrderType } from '../../../../../redux/ConvertHistoryState/ConvertHistoryTypes';

const CancelOrder: FC<{ id: string }> = ({ id }) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const closeModal = useCallback(() => dispatch(toggleModal()), [dispatch]);

	const onClick = useCallback(async () => {
		setIsLoading(true);
		await dispatch(updateOpenOrder(OpenOrderType.CANCEL, id))
			.then(() => null)
			.catch(() => null)
			.then(() => {
				setIsLoading(false);
				return closeModal();
			});
	}, [closeModal, dispatch, id]);

	return (
		<div className={styles.container}>
			<img src={xIconImgPath} alt="Close icon" />
			<h2 className={styles.title}>{formatMessage(convertMsg.cancelOrderTitle)}</h2>
			<p className={styles.post}>{formatMessage(convertMsg.cancelOrderPost)}</p>
			<Button
				className={styles.button}
				text={formatMessage(convertMsg.cancelButton)}
				buttonStyle={ButtonStyle.PRIMARY}
				isLoading={isLoading}
				isDisabled={isLoading}
				onClick={onClick}
			/>
			<Button
				className={styles.button}
				text={formatMessage(baseMsg.back)}
				buttonStyle={ButtonStyle.SECONDARY_WITHOUT_BORDER}
				onClick={closeModal}
			/>
		</div>
	);
};

export default CancelOrder;
