import { RootState } from './Store';

function createSelector<RootOut, SubOut, RootIn = RootState>(
	rootSelector: (state: RootIn) => RootOut,
	subSelector: (state: RootOut) => SubOut
): (x: RootIn) => SubOut;

function createSelector<RootOut>(
	subSelector: (state: RootState) => RootOut
): (x: RootState) => RootOut;

function createSelector<RootOut, SubOut, RootIn = RootState>(
	rootSelector: (state: RootIn) => RootOut,
	subSelector?: (state: RootOut) => SubOut
) {
	return typeof subSelector !== 'undefined'
		? (state: RootIn) => subSelector(rootSelector(state))
		: (state: RootIn) => rootSelector(state);
}

export default createSelector;
