import ProjectsListSelectors from './ProjectsList/ProjectsListSelectors';
import ProjectsDataSelectors from './ProjectsData/ProjectsDataSelectors';
import { RootState } from '../../Store';

const getListProjectsDataByPage = (page: number) => (state: RootState) => {
	const ids = ProjectsListSelectors.getProjectIdsByPage(page)(state) || [];
	return ProjectsDataSelectors.getProjectsDataByIds(ids)(state);
};

export default {
	getListProjectsDataByPage,
	list: ProjectsListSelectors,
	data: ProjectsDataSelectors,
};
