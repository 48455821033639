import { FormattedMessage } from 'react-intl';
import HistoryTable from '../../../../components/HistoryTable/HistoryTable';
import { HistoryType } from '../../../../hooks/useHistoryTableData';
import styles from './DocumentsHistory.module.scss';
import useDocumentsHistoryDataProvider from './useDocumentsHistoryDataProvider';

export default () => {
	const history = useDocumentsHistoryDataProvider();

	return (
		<div className={styles.container}>
			<h3 className={styles.title}>
				<FormattedMessage id="documents.yourFiles" defaultMessage="Your files" />
			</h3>
			<HistoryTable
				data={history.data}
				isLoading={history.isLoading}
				totalCount={history.totalCount}
				pageSize={6}
				historyType={HistoryType.DOCUMENTS}
			/>
		</div>
	);
};
