import { RemoteData, RemoteError } from '../../interfaces/RemoteData';

export type APIKey = {
	clientId: string;
	name: string;
	enabled: boolean;
	created: string;
	scopes: string[];
	ipWhitelist: string[];
	additionalClaims: any;
	clientSecret?: string;
};

export enum SettingsActionTypes {
	API_KEYS_LOADING = 'SETTINGS/API_KEYS/LOADING',
	API_KEYS_SUCCESS = 'SETTINGS/API_KEYS/SUCCESS',
	API_KEYS_ERROR = 'SETTINGS/API_KEYS/ERROR',
	API_SIGNLE_KEY_LOADING = 'SETTINGS/API_KEYS/SINGLE/LOADING',
	API_SIGNLE_KEY_SUCCESS = 'SETTINGS/API_KEYS/SINGLE/SUCCESS',
	API_SIGNLE_KEY_ERROR = 'SETTINGS/API_KEYS/SINGLE/ERROR',
	API_KEY_CREATE_SUCCESS = 'SETTINGS/API_KEYS/CREATE/SUCCESS',
	API_KEY_CREATE_ERROR = 'SETTINGS/API_KEYS/CREATE/ERROR',
	API_KEY_EDIT_SUCCESS = 'SETTINGS/API_KEYS/EDIT/SUCCESS',
	API_KEY_EDIT_LOADING = 'SETTINGS/API_KEYS/EDIT/LOADING',
	API_KEY_EDIT_ERROR = 'SETTINGS/API_KEYS/EDIT/ERROR',
	API_KEY_DELETE_SUCCESS = 'SETTINGS/API_KEYS/DELETE/SUCCESS',
	API_KEY_DELETE_LOADING = 'SETTINGS/API_KEYS/DELETE/LOADING',
	RESET_API_KEYS_STATE = 'SETTINGS/API_KEYS/RESET',
}

export interface SettingsState {
	apiKeys: {
		data: APIKey | null;
		list: RemoteData<APIKey[]>;
		delete: RemoteData<APIKey[]>;
		edit: RemoteData<APIKey[]>;
		error: RemoteError | null;
	};
}

export enum SettingsTypes {
	API = 'api',
	DOCUMENTS = 'documents',
	REFERRAL = 'referral',
}

export interface ApiKeysPendingAction {
	type: typeof SettingsActionTypes.API_KEYS_LOADING;
}

export interface ApiKeysSuccessAction {
	type: typeof SettingsActionTypes.API_KEYS_SUCCESS;
	data: APIKey[];
}

export interface ApiKeysErrorAction {
	type: typeof SettingsActionTypes.API_KEYS_ERROR;
	error: RemoteError;
}
export interface ApiSingleKeyPendingAction {
	type: typeof SettingsActionTypes.API_SIGNLE_KEY_LOADING;
}

export interface ApiSingleKeySuccessAction {
	type: typeof SettingsActionTypes.API_SIGNLE_KEY_SUCCESS;
	data: APIKey;
}

export interface ApiSingleKeyErrorAction {
	type: typeof SettingsActionTypes.API_SIGNLE_KEY_ERROR;
	error: RemoteError;
}

export interface ApiKeyCreateSuccessAction {
	type: typeof SettingsActionTypes.API_KEY_CREATE_SUCCESS;
	data: APIKey;
}

export interface ApiKeyCreateErrorAction {
	type: typeof SettingsActionTypes.API_KEY_CREATE_ERROR;
	error: RemoteError;
}

export interface ApiKeyEditLoadingAction {
	type: typeof SettingsActionTypes.API_KEY_EDIT_LOADING;
}

export interface ApiKeyEditSuccessAction {
	type: typeof SettingsActionTypes.API_KEY_EDIT_SUCCESS;
	data: APIKey;
}

export interface ApiKeyEditErrorAction {
	type: typeof SettingsActionTypes.API_KEY_EDIT_ERROR;
	error: RemoteError;
}

export interface ApiKeyDeleteSuccessAction {
	type: typeof SettingsActionTypes.API_KEY_DELETE_SUCCESS;
	clientId: string;
}

export interface ApiKeyDeleteLoadingAction {
	type: typeof SettingsActionTypes.API_KEY_DELETE_LOADING;
}

export interface ResetApiKeysStateAction {
	type: typeof SettingsActionTypes.RESET_API_KEYS_STATE;
}
