import { useIntl } from 'react-intl';
import { WithdrawHistoryItem } from '../../../../redux/WithdrawHistoryState/WithdrawHistoryTypes';
import statusMsg from '../../../../messages/status.messages';
import { DetailsModalType } from '../../../../components/Modal/DetailsModal/DetailsModal';
import HistoryListItem from '../../../../components/HistoryTable/HistoryListItem/HistoryListItem';

interface Props {
	data: WithdrawHistoryItem[];
}

const MobileTopupsHistory = ({ data }: Props): JSX.Element => {
	const { formatMessage } = useIntl();
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{data?.length > 0 &&
				data.map(
					({
						id,
						date,
						payAmount,
						payCurrencyCode,
						status,
						receiver,
						productTitle,
						itemTitle,
					}: WithdrawHistoryItem) => (
						<HistoryListItem
							key={id}
							title={productTitle}
							transNo={id}
							dateCreated={date}
							currency={payCurrencyCode}
							status={formatMessage(statusMsg[status])}
							detailsModalType={DetailsModalType.TOPUP}
							withdrawAccount={receiver}
							amount={payAmount}
							purchaseItem={itemTitle}
						/>
					)
				)}
		</>
	);
};

export default MobileTopupsHistory;
