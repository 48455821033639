import { defineMessages, useIntl } from 'react-intl';
import { parse as parseDuration } from 'iso8601-duration';

const messages = defineMessages({
	years: {
		id: 'base.takes_up_to_years',
		defaultMessage: `{number, plural, one {# year} other {# years}}`,
	},
	months: {
		id: 'base.takes_up_to_months',
		defaultMessage: `{number, plural, one {# month} other {# months}}`,
	},
	weeks: {
		id: 'base.takes_up_to_weeks',
		defaultMessage: `{number, plural, one {# week} other {# weeks}}`,
	},
	days: {
		id: 'base.takes_up_to_days',
		defaultMessage: `{number, plural, one {# day} other {# days}}`,
	},
	hours: {
		id: 'base.takes_up_to_hours',
		defaultMessage: `{number, plural, one {# hour} other {# hours}}`,
	},
	minutes: {
		id: 'base.takes_up_to_minutes',
		defaultMessage: `{number, plural, one {# minute} other {# minutes}}`,
	},
	seconds: {
		id: 'base.takes_up_to_seconds',
		defaultMessage: `{number, plural, one {# second} other {# seconds}}`,
	},
	instant: {
		id: 'base.instant',
		defaultMessage: 'Instant',
	},
});

type DurationKey = 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds';

const useDurationString = (duration?: string | null) => {
	const { formatMessage } = useIntl();
	if (!duration) return null;

	try {
		const durationObject = parseDuration(duration);

		if (Object.values(durationObject).some((value) => value > 0)) {
			const durationString = Object.keys(durationObject).reduce(
				(accumulator: string, key: string) => {
					if (durationObject[key as DurationKey]! > 0) {
						// eslint-disable-next-line no-param-reassign
						accumulator += `${formatMessage(messages[key as DurationKey], {
							number: durationObject[key as DurationKey],
						})} `;
					}
					return accumulator;
				},
				''
			);
			return durationString;
		}

		return formatMessage(messages.instant);
	} catch {
		return null;
	}
};

export default useDurationString;
