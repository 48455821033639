import classNames from 'classnames';
import { FormEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import convertToLocalTime from '../../../../helpers/dateHelper/dateHelper';
import editIconPath from '../../../../images/settings/delete_modal_icon.svg';
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import {
	deleteApiKey,
	editApiKey,
	getApiKey,
} from '../../../../redux/SettingsState/SettingsActions';
import SettingsMessages from '../../../../redux/SettingsState/SettingsMessages';
import { APIKey } from '../../../../redux/SettingsState/SettingsTypes';
import { RootState } from '../../../../redux/Store';
import DetailField from '../Shared/DetailsField/DetailsField';
import Form, { ApiScopes, FormState } from '../Shared/Form/Form';
import Loading from '../Shared/Loading/Loading';
import Modal from '../Shared/Modal/Modal';
import styles from './Edit.module.scss';
import baseMsg from '../../../../messages/base.messages';
import { values } from '../../../../helpers/objectHelper/objectHelper';

interface Params {
	clientId: string;
}

const DeleteModal = (data: { name: string; created: string; clientId: string }) => {
	const { formatMessage } = useIntl();
	const { status } = useSelector((state: RootState) => state.SettingsState.apiKeys.delete);
	const dispatch = useDispatch();

	const { clientId, name, created } = data;

	return (
		<Modal
			title={formatMessage(SettingsMessages.deleteApi)}
			description={formatMessage(SettingsMessages.deleteApiDescription)}
			iconPath={editIconPath}
			body={
				<>
					<DetailField label={formatMessage(SettingsMessages.apiName)} value={name} />
					<DetailField
						label={formatMessage(SettingsMessages.apiKey)}
						value={convertToLocalTime(created, 'yyyy-MM-dd hh:mm:ss')}
					/>
				</>
			}
			footer={
				<>
					<Button
						text={formatMessage(SettingsMessages.deleteApiButton)}
						type={ButtonType.BUTTON}
						buttonStyle={ButtonStyle.PRIMARY}
						className={styles.modalCloseButton}
						onClick={() => dispatch(deleteApiKey(clientId))}
						isLoading={status === RemoteStatus.InProgress}
					/>
					<Button
						text={formatMessage(SettingsMessages.cancel)}
						type={ButtonType.BUTTON}
						buttonStyle={ButtonStyle.LINK}
						className={styles.modalCloseButton}
						onClick={() => dispatch(toggleModal())}
						disabled={status === RemoteStatus.InProgress}
					/>
				</>
			}
		/>
	);
};

const ApiEdit = () => {
	const dispatch = useDispatch();
	const { locale } = useIntl();
	const { push } = useHistory();
	const { clientId } = useParams<Params>();
	const { isTablet } = useSelector((state: RootState) => state.AppState);
	const {
		list: { status: listStatus, data: apiKeys },
		edit: { status: editStatus },
		delete: { status: deleteStatus },
		data: singleApiKey,
	} = useSelector((state: RootState) => state.SettingsState.apiKeys);
	const isLoading = [listStatus, editStatus].includes(RemoteStatus.InProgress);

	const [form, setForm] = useState<FormState>({
		name: '',
		created: '',
		enabled: false,
		ipAddress: '',
		ipWhitelist: [],
		scopes: values(ApiScopes).reduce((acc, key) => {
			acc[key] = { isChecked: false };
			return acc;
		}, {} as Record<ApiScopes, { isChecked: boolean }>),
	});

	const setFormState = (apiKey: APIKey) => {
		const { name, scopes, ipWhitelist, created, enabled } = apiKey!;
		setForm({
			created,
			enabled,
			name,
			ipAddress: '',
			ipWhitelist,
			scopes: values(ApiScopes).reduce((acc, key) => {
				acc[key] = { isChecked: scopes.includes(key) };
				return acc;
			}, {} as Record<ApiScopes, { isChecked: boolean }>),
		});
	};

	useEffect(() => {
		const apiKey = singleApiKey || apiKeys!.find(({ clientId: id }: APIKey) => id === clientId);
		if (!apiKey || apiKeys!.length === 0) {
			void dispatch(getApiKey(clientId));
			return;
		}

		setFormState(apiKey);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const apiKey = singleApiKey || apiKeys!.find(({ clientId: id }: APIKey) => id === clientId);

		if (apiKey && listStatus === RemoteStatus.Done) setFormState(apiKey);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listStatus]);

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();
		void dispatch(editApiKey(clientId, form));
	};

	useEffect(() => {
		if (editStatus === RemoteStatus.Done) push(`/${locale}/settings/api`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editStatus]);

	useEffect(() => {
		if (deleteStatus === RemoteStatus.Done) push(`/${locale}/settings/api`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteStatus]);

	if (isLoading) return <Loading />;
	return (
		<>
			<PageTitle
				title={SettingsMessages.apiEditTitle}
				previousPageLink={`/${locale}/settings/api`}
				isPreviousPageLinkVisibleOnDesktop
				isPreviousPageLinkVisibleOnMobile={false}
				extraActions={
					<Button
						className={styles.deleteButton}
						buttonStyle={ButtonStyle.LINK}
						type={ButtonType.LINK}
						onClick={() =>
							dispatch(
								toggleModal(
									<DeleteModal
										{...{
											name: form.name,
											created: form.created!,
											clientId,
										}}
									/>
								)
							)
						}
					>
						<FormattedMessage {...SettingsMessages.deleteApi} />
					</Button>
				}
			/>
			<div
				className={classNames(styles.container, {
					[styles.mobile]: isTablet,
				})}
			>
				<Form
					handleSubmit={handleSubmit}
					submitButtonText={SettingsMessages.apiEditButton}
					formState={form}
					setFormState={setForm}
					showCancel
					cancelButtonText={baseMsg.back}
					onCancel={() => push(`/${locale}/settings/api`)}
				/>
			</div>
		</>
	);
};

export default ApiEdit;
