import { createContext } from 'react';
import { RemoteData, RemoteStatus } from '../../../../../interfaces/RemoteData';
import { Project, ProjectEditForm } from '../../../../../redux/MerchantsState/MerchantTypes';

export default createContext<{
	form: ProjectEditForm;
	action: RemoteData<Project>;
	updateForm: (value: Partial<ProjectEditForm>) => void;
	setAction: (value: RemoteData<Project>) => void;
}>({
	form: {} as ProjectEditForm,
	action: { status: RemoteStatus.None },
	updateForm: () => {},
	setAction: () => {},
});
