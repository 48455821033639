import classNames from 'classnames';
import TestIds from '../../test/TestIds';
import styles from './Loader.module.scss';

interface LoaderProps {
	className?: string;
}
const Loader = ({ className, ...rest }: LoaderProps) => (
	<div className={classNames(styles.ldsRing, className)} data-cy={TestIds.Loader} {...rest}>
		<div />
		<div />
		<div />
		<div />
	</div>
);

export default Loader;
