import {
	FC,
	useMemo,
	ReactNode,
	PropsWithChildren,
	useState,
	useCallback,
	useTransition,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Joi from 'joi';
import classNames from 'classnames';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import TestIds from '../../../../test/TestIds';
import useValidation from '../../../../hooks/useValidation';
import inputErrors from '../../../../messages/inputErrors.messages';
import { RootState } from '../../../../redux/Store';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import MerchantsMessages from '../../../../redux/MerchantsState/MerchantsMessages';
import Input from '../../../../components/Input/Input';
import OptionalField from '../../../../components/OptionalField/OptionalField';
import AccountSelect from '../../../../components/AccountSelect/AccountSelect';
import { compareReceiveCurrencies } from '../../../../helpers/merchantsHelper/merchantsHelper';

import styles from './PaymentButtonForm.module.scss';
import MultiAccountSelect from '../../../../components/MultiAccountSelect/MultiAccountSelect';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { RemoteData, RemoteStatus } from '../../../../interfaces/RemoteData';
import { getPaymentButtonPaymentOptions } from '../../../../redux/endpoints';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import Toggle from '../../../../components/Toggle/Toggle';
import axiosInstance from '../../../../helpers/axiosInstance';

type Form = {
	name: string;
	description: string | null;
	autoConvert: string | null;
	isEnabled: boolean;
	acceptCurrencies: CurrencyEnum[] | null;
};

export const usePaymentButtonFormValidator = () => {
	const { formatMessage } = useIntl();

	return useMemo(
		() =>
			Joi.object<Partial<Form>>({
				name: Joi.string()
					.allow('', null)
					.max(255)
					.message(
						formatMessage(inputErrors.x_lessOrEqual_y, {
							Field: formatMessage(MerchantsMessages.name),
							Max: 255,
						})
					),
				description: Joi.string()
					.allow('', null)
					.max(255)
					.message(
						formatMessage(inputErrors.x_lessOrEqual_y, {
							Field: formatMessage(MerchantsMessages.description),
							Max: 255,
						})
					),
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
};

type PaymentButtonFormProps = {
	onSetName: (value: string) => void;
	onSetDescription: (value: string | null) => void;
	onSetAutoConvert?: (value: string | null) => void;
	onSetIsEnabled: (value: boolean) => void;
	onAcceptCurrenciesChange?: (value: CurrencyEnum[] | null) => void;
	className?: string;
	formError?: ReactNode;
} & Form;

const PaymentButtonForm: FC<PropsWithChildren<PaymentButtonFormProps>> = ({
	name,
	onSetName,
	description,
	onSetDescription,
	autoConvert,
	onSetAutoConvert,
	acceptCurrencies,
	onAcceptCurrenciesChange,
	isEnabled,
	onSetIsEnabled,
	className,
	children,
	formError,
}) => {
	const { formatMessage } = useIntl();
	const [_1, startTransition] = useTransition();
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const options = useMemo(
		() =>
			(
				wallets?.map(({ id, currencyCode, currencyName }) => ({
					value: id,
					currencyCode,
					currencyName,
				})) || []
			).sort((a, b) => compareReceiveCurrencies(a.currencyCode, b.currencyCode)),
		[wallets]
	);

	const [previousPayCurrencies] = useState<Array<CurrencyEnum>>(acceptCurrencies || []);

	const [availablePayCurrencies, setAvailablePayCurrencies] = useState<
		RemoteData<Array<CurrencyEnum>>
	>({ status: RemoteStatus.None });

	const fetchCurrencies = (value: string | null) => {
		const newWallet = wallets?.find((x) => x.id === value);
		setAvailablePayCurrencies({ status: RemoteStatus.InProgress });
		void axiosInstance
			.get<Array<{ code: CurrencyEnum }>>(
				getPaymentButtonPaymentOptions(newWallet?.currencyCode)
			)
			.then(({ data }) => {
				const newCurrencies = data.map((x) => x.code);
				setAvailablePayCurrencies({
					status: RemoteStatus.Done,
					data: newCurrencies,
				});

				if (!onAcceptCurrenciesChange) return;

				const supported = acceptCurrencies?.filter((x) => newCurrencies.includes(x)) || [];
				if (supported && supported.length !== (acceptCurrencies?.length || 0))
					onAcceptCurrenciesChange(supported);
			})
			.catch((e) => {
				setAvailablePayCurrencies({ status: RemoteStatus.Error, error: e.response.data });
			});
	};

	const currencyOptions = useMemo(
		() =>
			options
				.filter(
					(x) =>
						availablePayCurrencies.data?.includes(x.currencyCode) ||
						previousPayCurrencies.includes(x.currencyCode)
				)
				.map((option) => ({
					...option,
					value: option.currencyCode,
					disabled: !availablePayCurrencies.data?.includes(option.currencyCode),
				})),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[options, availablePayCurrencies.data]
	);

	const autoconvertChangeHandler = useCallback((value: string | null) => {
		startTransition(() => {
			onSetAutoConvert!(value);
			fetchCurrencies(value);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffectOnce(() => {
		fetchCurrencies(autoConvert);
	});

	const validator = usePaymentButtonFormValidator();
	const [_, validationResult] = useValidation({ name, description }, validator);

	return (
		<div className={classNames(styles.form, className)} data-cy={TestIds.PaymentButtonForm}>
			{formError}
			<Input
				data-cy={TestIds.PaymentButtonFormName}
				value={name}
				onChange={onSetName}
				label={MerchantsMessages.buttonName}
				errorMessage={validationResult.name}
			/>
			<OptionalField
				data-cy={TestIds.PaymentButtonFormDescriptionField}
				onChange={onSetDescription}
				id="merchant-buttons-description"
				name="merchant-buttons-description"
				label={MerchantsMessages.description}
				initialState={!!description}
			>
				{({ onChange }) => (
					<Input
						data-cy={TestIds.PaymentButtonFormDescriptionInput}
						value={description || ''}
						onChange={onChange}
						errorMessage={validationResult.description}
					/>
				)}
			</OptionalField>
			{onSetAutoConvert && (
				<OptionalField
					data-cy={TestIds.PaymentButtonFormAutoConvertField}
					id="merchant-buttons-autoconvert"
					name="merchant-buttons-autoconvert"
					label={
						<div className={styles.labelWithTooltip}>
							<span>
								<FormattedMessage {...MerchantsMessages.autoConvertCurrency} />
							</span>
							<Tooltip
								infoIconVisible
								content={
									<div className={styles.tooltip}>
										{formatMessage(MerchantsMessages.autoConvertTooltip)}
									</div>
								}
							/>
						</div>
					}
					onChange={autoconvertChangeHandler}
					initialState={!!autoConvert}
				>
					{({ onChange }) => (
						<AccountSelect
							options={options}
							label={formatMessage(MerchantsMessages.chooseCurrency)}
							value={autoConvert}
							onChange={onChange}
						/>
					)}
				</OptionalField>
			)}
			{onAcceptCurrenciesChange && (
				<OptionalField
					data-cy={TestIds.PaymentButtonFormAcceptCurrenciesField}
					id="merchant-buttons-currencies"
					name="merchant-buttons-currencies"
					label={
						<div className={styles.labelWithTooltip}>
							<span>
								<FormattedMessage {...MerchantsMessages.acceptAllCurrencies} />
							</span>
							<Tooltip
								infoIconVisible
								content={
									<div className={styles.tooltip}>
										{formatMessage(
											MerchantsMessages.acceptAllCurrenciesTooltip
										)}
									</div>
								}
							/>
						</div>
					}
					onChange={onAcceptCurrenciesChange}
					initialState={!acceptCurrencies?.length}
					inverted
				>
					{({ onChange }) => (
						<div className={styles.acceptCurrencies}>
							<MultiAccountSelect
								options={currencyOptions}
								label={formatMessage(MerchantsMessages.chooseCurrencyToAccept)}
								values={acceptCurrencies}
								onChange={onChange as (value: string[]) => void}
								isLoading={availablePayCurrencies.status !== RemoteStatus.Done}
							/>
							{previousPayCurrencies.length > 0 && (
								<NotificationMessage
									data-cy={TestIds.PaymentButtonFormFormAcceptCurrenciesFieldInfo}
									message={formatMessage(
										MerchantsMessages.payCurrenciesMutabilityInfo
									)}
									type={NotificationType.Info}
									style={NotificationStyle.Fill}
								/>
							)}
						</div>
					)}
				</OptionalField>
			)}
			<Toggle
				id="merchant-buttons-enable"
				name="merchant-buttons-enable"
				data-cy={TestIds.PaymentButtonFormEnabled}
				checked={isEnabled}
				onChange={(value) => {
					onSetIsEnabled(value.target.checked);
				}}
				className={styles.toggle}
			>
				<div className={classNames(styles.labelWithTooltip, styles.label)}>
					<span>
						<FormattedMessage {...MerchantsMessages.enabled} />
					</span>
					<Tooltip
						infoIconVisible
						content={
							<div className={styles.tooltip}>
								<FormattedMessage {...MerchantsMessages.pmbEnabledTooltip} />
							</div>
						}
					/>
				</div>
			</Toggle>
			{children}
		</div>
	);
};

export default PaymentButtonForm;
