import { CSSProperties, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import countries from 'i18n-iso-countries';
import ReactCountryFlag from 'react-country-flag';
import { CurrencyEnum, CurrencyIcon } from '@spectrocoin/sc-currencies';
import { RootState } from '../../../../redux/Store';
import Select from '../../../../components/Select/Select';
import styles from './FilterSelect.module.scss';
import baseMsg from '../../../../messages/base.messages';
import TestIds from '../../../../test/TestIds';

const selectStyles = () => ({
	valueContainer: (provided: CSSProperties) => ({
		...provided,
		padding: '0',
		minWidth: '55px',
		cursor: 'pointer',
		minHeight: '30px',
	}),
});

interface OptionProps {
	value: string;
	label: JSX.Element;
}

interface FilterSelectProps {
	value: string;
	onChange: (value: string) => void;
	currencyList?: CurrencyEnum[];
	availableCountries?: string[];
	className?: string;
	isCountry?: boolean;
}

const FilterSelect = ({
	value,
	onChange,
	className,
	currencyList,
	availableCountries,
	isCountry = false,
}: FilterSelectProps): JSX.Element => {
	const { formatMessage } = useIntl();
	const { replace } = useHistory();
	const { pathname } = useLocation();
	const [options, setOptions] = useState<OptionProps[]>([]);
	const { wallets } = useSelector((state: RootState) => state.AccountsState);

	const onSelectChange = (val: string) => onChange(val);

	useEffect(() => {
		replace({
			pathname,
			search: isCountry ? `?country=${value.split('/')[0]}` : `?currency=${value}`,
		});
	}, [value, isCountry, replace, pathname]);

	useEffect(() => {
		if (isCountry && options.length === 0) {
			const countriesList = Object.keys(countries.getAlpha2Codes());
			const countryOptions = countriesList.reduce((acc: OptionProps[], curr: string) => {
				if (!availableCountries || availableCountries?.includes(curr)) {
					const item = {
						value: `${curr}/${countries.getName(curr, 'en')}`,
						label: (
							<div className={styles.option}>
								<ReactCountryFlag
									svg
									style={{
										width: '25px',
										height: 'auto',
										transform: 'scaleY(1.3)',
										borderRadius: '50%',
									}}
									countryCode={curr}
									title={curr}
								/>
								{countries.getName(curr, 'en').replace(', Province of China', '')}
							</div>
						),
					};
					acc.push(item);
				}
				return acc;
			}, []);
			setOptions(countryOptions);
		}
	}, [availableCountries, isCountry, options.length, pathname, replace]);

	useEffect(() => {
		if (currencyList && currencyList.length > 0) {
			const currencies = currencyList.map((currencyCode: CurrencyEnum) => ({
				value: currencyCode,
				label: (
					<div className={styles.option}>
						<CurrencyIcon
							currencyType={CurrencyEnum[currencyCode]}
							className={styles.currencyIcon}
						/>
						<label className={styles.currencyLabel}>{currencyCode}</label>
					</div>
				),
			}));
			setOptions([
				{
					label: (
						<div className={styles.option}>
							<FormattedMessage {...baseMsg.all} />
						</div>
					),
					value: '',
				},
				...currencies,
			]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currencyList, wallets]);

	return (
		<Select
			value={value}
			options={options}
			labelClassName={styles.label}
			label={isCountry ? baseMsg.filterByCountry : baseMsg.filterByCurrency}
			inputGroupClassName={classNames(styles.select, className)}
			onChange={onSelectChange}
			customStyles={{ ...selectStyles() }}
			placeholder={formatMessage(baseMsg.selectCurrency)}
			data-cy={TestIds.FilterSelect}
		/>
	);
};

export default FilterSelect;
