import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString, { ParsedQuery } from 'query-string';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RootState } from '../../../redux/Store';
import Loader from '../../../components/Loader/Loader';
import PageTitle from '../../../components/PageTitle/PageTitle';
import FilterSelect from '../Shared/FilterSelect/FilterSelect';
import WithdrawOptionList, { ListType } from '../Shared/WithdrawOptionList/WithdrawOptionList';
import noDataImgPath from '../../../images/empty.svg';
import styles from './BankWithdraw.module.scss';
import withdrawMessages from '../../../redux/WithdrawState/WithdrawMessages';
import Seo from '../../../components/Seo/Seo';

const messages = defineMessages({
	title: {
		id: 'bankWithdraw.title',
		defaultMessage: 'Bank',
	},
	noData: {
		id: 'eWalletWithdraw.noData',
		defaultMessage: 'No options',
	},
});

const BankWithdraw = () => {
	const { formatMessage, locale } = useIntl();
	const { search } = useLocation();
	const parsedQuery: ParsedQuery<any> = queryString.parse(search);
	const { paymentMethods } = useSelector((state: RootState) => state.WithdrawState);
	const { bank } = paymentMethods;
	const { isLoaded, data } = bank;

	const [currency, setCurrency] = useState<string>(parsedQuery?.currency || '');
	const [currencyList, setCurrencyList] = useState<CurrencyEnum[]>([]);

	useEffect(() => {
		setCurrencyList(
			bank?.data
				.flatMap(({ baseCurrencyCode, paymentMethodCurrencies }) => [
					baseCurrencyCode,
					...paymentMethodCurrencies,
				])
				.filter(
					(currencyCode: CurrencyEnum, index: number, self: CurrencyEnum[]) =>
						self.findIndex((val) => val === currencyCode) === index
				)
		);
	}, [bank]);

	return (
		<>
			<Seo title={withdrawMessages.metaBankTitle} />
			<PageTitle
				title={formatMessage(messages.title)}
				historyLink={`/${locale}/withdraw/history/bank`}
			/>
			<FilterSelect value={currency} onChange={setCurrency} currencyList={currencyList} />
			{!isLoaded && <Loader className={styles.loader} />}
			{isLoaded && data?.length === 0 && (
				<div className={styles.noData}>
					<img src={noDataImgPath} alt="No data" />
					<p>{formatMessage(messages.noData)}</p>
				</div>
			)}
			{data?.length > 0 && (
				<WithdrawOptionList type={ListType.BANK} currency={currency} list={data} />
			)}
		</>
	);
};

export default BankWithdraw;
