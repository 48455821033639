import { UserManager } from 'oidc-client-ts';
import config from '../../configs/config';

/** 3rd party fixes */
import '../../_3rd_party_fix/oidc-client-ts';

const REQUIRED_CONFIGURATION_OPTIONS = [
	'AUTHORITY',
	'AUTHORIZATION_ENDPOINT',
	'CLIENT_ID',
	'ISSUER',
	'REDIRECT_URI',
	'SCOPE',
	'TOKEN_ENDPOINT',
];

const missingConfigs = REQUIRED_CONFIGURATION_OPTIONS.reduce((acc, option) => {
	if (!config[option]) acc.push(option);
	return acc;
}, [] as string[]);

if (missingConfigs.length) {
	throw new Error(`Missing oauth configs: ${missingConfigs.join(', ')}`);
}

const userManager = new UserManager({
	authority: config.AUTHORITY,
	client_id: config.CLIENT_ID,
	redirect_uri: config.REDIRECT_URI,
	scope: ' ',
	metadata: {
		issuer: config.ISSUER,
		token_endpoint: config.TOKEN_ENDPOINT,
		authorization_endpoint: config.AUTHORIZATION_ENDPOINT,
	},
	loadUserInfo: false,
	response_type: 'code',
});

export default userManager;
