/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import useQueryPagination from '../../hooks/useQueryPagination';
import styles from './Pagination.module.scss';
import TestIds, { formatTestId } from '../../test/TestIds';

const LIMIT = 10;

interface PaginationProps {
	totalCount?: number;
	pageSize?: number;
}

interface ArrowProps {
	className?: string;
}

const Arrow = ({ className }: ArrowProps) => (
	<svg width="24" height="24" viewBox="0 0 24 24" className={className}>
		<defs>
			<clipPath id="clip-path">
				<rect
					id="rectangle"
					width="24"
					height="24"
					transform="translate(1296 59) rotate(-90)"
					fill="rgba(139,64,64,0.55)"
				/>
			</clipPath>
		</defs>
		<g id="arrow" transform="translate(59 -1296) rotate(90)" clipPath="url(#clip-path)">
			<path
				id="Path_21651"
				className={styles.color}
				data-name="Path 21651"
				d="M-20983.658-13812l4,4-4,4"
				transform="translate(-12499.672 21028.996) rotate(90)"
				fill="none"
				stroke="#757575"
				strokeLinecap="round"
				strokeWidth="1"
			/>
		</g>
	</svg>
);

const Arrows = ({ className }: ArrowProps) => (
	<svg width="29" height="24" viewBox="0 0 29 24" className={className}>
		<defs>
			<clipPath id="clip-path">
				<rect
					id="rectangle"
					width="24"
					height="24"
					transform="translate(1296 59) rotate(-90)"
					fill="rgba(139,64,64,0.55)"
				/>
			</clipPath>
		</defs>
		<g id="arrows" transform="translate(-721 -1409)">
			<g
				id="Mask_Group_5915"
				data-name="Mask Group 5915"
				transform="translate(780 113) rotate(90)"
				clipPath="url(#clip-path)"
			>
				<path
					className={styles.color}
					id="Path_21652"
					data-name="Path 21652"
					d="M-20983.658-13812l4,4-4,4"
					transform="translate(-12499.672 21028.996) rotate(90)"
					fill="none"
					stroke="#757575"
					strokeLinecap="round"
					strokeWidth="1"
				/>
			</g>
			<g
				id="Mask_Group_5916"
				data-name="Mask Group 5916"
				transform="translate(785 113) rotate(90)"
				clipPath="url(#clip-path)"
			>
				<path
					className={styles.color}
					id="Path_21653"
					data-name="Path 21653"
					d="M-20983.658-13812l4,4-4,4"
					transform="translate(-12499.672 21028.996) rotate(90)"
					fill="none"
					stroke="#757575"
					strokeLinecap="round"
					strokeWidth="1"
				/>
			</g>
		</g>
	</svg>
);

const Pagination = ({ totalCount = 0, pageSize = LIMIT }: PaginationProps) => {
	const [page, changePath] = useQueryPagination();
	const [totalPages, setTotalPages] = useState<number[]>([]);

	useEffect(() => {
		const pageCount = Math.ceil(totalCount / pageSize);
		const pageArr = [];
		for (let i = 0; i < pageCount; i++) {
			pageArr.push(i + 1);
		}
		setTotalPages(pageArr);
	}, [totalCount]);

	if (totalCount === 0) return null;
	if (totalPages.length === 1) return null;
	return (
		<ul data-cy={formatTestId(TestIds.Pagination)} className={styles.pagination}>
			<li
				onClick={() => changePath(Number(page) !== 1 ? 1 : null)}
				className={classNames({ [styles.disabled]: Number(page) === 1 })}
				data-cy={TestIds.FirstPage}
			>
				<Arrows />
			</li>
			<li
				onClick={() => changePath(Number(page) !== 1 ? Number(page) - 1 : null)}
				className={classNames({ [styles.disabled]: Number(page) === 1 })}
				data-cy={TestIds.PrevPage}
			>
				<Arrow />
			</li>
			{totalPages.map((i) => {
				if (i + 2 === Number(page))
					return (
						<li
							key={i}
							onClick={() => changePath(i)}
							data-cy={formatTestId(TestIds.Page_0, i.toString())}
							className={classNames({ [styles.active]: Number(page) === i })}
						>
							{i}
						</li>
					);
				if (i + 1 === Number(page))
					return (
						<li
							key={i}
							onClick={() => changePath(i)}
							data-cy={formatTestId(TestIds.Page_0, i.toString())}
							className={classNames({ [styles.active]: Number(page) === i })}
						>
							{i}
						</li>
					);
				if (i === Number(page))
					return (
						<li
							key={i}
							onClick={() => changePath(i)}
							data-cy={formatTestId(TestIds.Page_0, i.toString())}
							className={classNames({ [styles.active]: Number(page) === i })}
						>
							{i}
						</li>
					);
				if (i - 1 === Number(page))
					return (
						<li
							key={i}
							onClick={() => changePath(i)}
							data-cy={formatTestId(TestIds.Page_0, i.toString())}
							className={classNames({ [styles.active]: Number(page) === i })}
						>
							{i}
						</li>
					);
				if (i - 2 === Number(page))
					return (
						<li
							key={i}
							onClick={() => changePath(i)}
							data-cy={formatTestId(TestIds.Page_0, i.toString())}
							className={classNames({ [styles.active]: Number(page) === i })}
						>
							{i}
						</li>
					);
				return null;
			})}
			<li
				onClick={() =>
					changePath(Number(page) !== totalPages.length ? Number(page) + 1 : null)
				}
				className={classNames({ [styles.disabled]: Number(page) === totalPages.length })}
				data-cy={TestIds.NextPage}
			>
				<Arrow className={styles.rotate} />
			</li>
			<li
				onClick={() =>
					changePath(Number(page) !== totalPages.length ? totalPages.length : null)
				}
				className={classNames({ [styles.disabled]: Number(page) === totalPages.length })}
				data-cy={TestIds.LastPage}
			>
				<Arrows className={styles.rotate} />
			</li>
		</ul>
	);
};

export default Pagination;
