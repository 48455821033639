import { MessageDescriptor } from 'react-intl';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';

export enum AccountsActionType {
	SET_WALLETS = 'SET_WALLETS',
	SET_CRYPTO_ADDRESS = 'SET_CRYPTO_ADDRESS',
	SET_SEGWIT_ADDRESS = 'SET_SEGWIT_ADDRESS',
	SET_IS_LOADING = 'SET_IS_LOADING',
	SET_ERROR = 'SET_ERROR',
	UPDATE_WALLET = 'UPDATE_WALLET',
	SET_WALLET_BY_ID = 'SET_WALLET_BY_ID',
	SET_ACCOUNT_NETWORK = 'SET_ACCOUNT_NETWORK',
}

export enum NetworkNameCode {
	BTC = 'BTC',
	ETH = 'ETH',
	XRP = 'XRP',
	XEM = 'XEM',
	XLM = 'XLM',
	DASH = 'DASH',
	ALGO = 'ALGO',
	LTC = 'LTC',
	BSC = 'BSC',
	TRX = 'TRX',
	ADA = 'ADA',
	SOL = 'SOL',
	BCH = 'BCH',
	AVAXC = 'AVAXC',
	DOT = 'DOT',
	NEAR = 'NEAR',
}

export enum IBANStatus {
	ACTIVE = 'ACTIVE',
	CLOSED = 'CLOSED',
}

export enum BTCAddressType {
	BECH32 = 'BECH32',
	LEGACY = 'LEGACY',
}

export interface CurrencyNetwork {
	feeCurrencyCode: CurrencyEnum;
	currencyCode: CurrencyEnum;
	isDefault: boolean;
	isEnabled: boolean;
	networkName: string;
	publicName: string;
}

export interface Wallet {
	availableBalance: string;
	balance: string;
	hideBalance: boolean;
	cryptoAddress: string | null;
	cryptoAddressSegWit: string | null;
	currencyCode: CurrencyEnum;
	currencyName: string;
	hideIfEmpty: boolean;
	iban: string | null;
	id: string;
	label: string | null;
	message: string | null;
	reservedAmount: string;
	sortIndex: number;
	isNew: boolean;
	ibanInfo: IbanInfo | null;
	networkList?: CurrencyNetwork[];
}

export interface IbanInfo {
	iban: string;
	status: IBANStatus;
	institutionName: string;
	institutionAddress: string;
	swiftCode: string;
}

export interface AccountStateProps {
	wallets: Wallet[] | null;
	isLoading: boolean;
	error: MessageDescriptor | null;
}

export interface CryptoAddress {
	address: string;
	message: string | null;
	id: string;
}

export interface SegWitCryptoAddress {
	address: string;
	id: string;
}

export interface DepositAddressResponse {
	accountGuid: string;
	addressType: BTCAddressType | null;
	depositAccount: string;
	message: string;
	id: string;
	networkType: CurrencyNetwork['networkName'];
}

export interface SetWalletAction {
	type: typeof AccountsActionType.SET_WALLETS;
	data: Wallet[];
}

export interface SetCryptoAddressAction {
	type: typeof AccountsActionType.SET_CRYPTO_ADDRESS;
	data: CryptoAddress;
}

export interface SetSegWitAddressAction {
	type: typeof AccountsActionType.SET_SEGWIT_ADDRESS;
	data: SegWitCryptoAddress;
}

export interface SetIsLoadingAction {
	type: typeof AccountsActionType.SET_IS_LOADING;
	data: boolean;
}

export interface SetErrorAction {
	type: typeof AccountsActionType.SET_ERROR;
	data: MessageDescriptor | null;
}

export interface SetUpdateWalletAction {
	type: typeof AccountsActionType.UPDATE_WALLET;
	data: Wallet;
}

export interface SetAccountByIdAction {
	type: typeof AccountsActionType.SET_WALLET_BY_ID;
	data: Wallet;
}

export interface SetAccountNetworkAction {
	type: typeof AccountsActionType.SET_ACCOUNT_NETWORK;
	data: {
		id: string;
		networkList: CurrencyNetwork[];
	};
}
