import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import { useIntl, defineMessages, MessageDescriptor } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import countries from 'i18n-iso-countries';
import baseMsg from '../../../../messages/base.messages';
import { RootState } from '../../../../redux/Store';
import { setFormData, setOrderStep } from '../../../../redux/CardsState/CardsActions';
import { CardType, OrderSteps } from '../../../../redux/CardsState/CardsTypes';
import BackButton from '../../../../components/BackButton/BackButton';
import Input from '../../../../components/Input/Input';
import Select, { SelectStyleType } from '../../../../components/Select/Select';
import Button, { ButtonType, ButtonStyle } from '../../../../components/Button/Button';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import styles from './Tin.module.scss';
import inputErrors from '../../../../messages/inputErrors.messages';
import useEffectOnce from '../../../../hooks/useEffectOnce';

const messages = defineMessages({
	title: {
		id: 'tin.title',
		defaultMessage: 'Taxpayer Identification Number',
	},
	subTitle: {
		id: 'tin.subTitle',
		defaultMessage: 'Please provide your Taxpayer Identification Number',
	},
	explainTitle: {
		id: 'tin.explainTitle',
		defaultMessage: 'What is TIN number?',
	},
	explainText: {
		id: 'tin.explainText',
		defaultMessage:
			'A Taxpayer Identification Number is an identifying number used for tax purposes in the United States and in other countries under the Common Reporting Standard. In the United States it is also known as a Tax Identification Number or Federal Taxpayer Identification Number.',
	},
	inputLabel: {
		id: 'tin.inputLabel',
		defaultMessage: 'Taxpayer Identification Number',
	},
	selectLabel: {
		id: 'tin.selectLabel',
		defaultMessage: 'Taxpayer country',
	},
});

interface TinProps {
	type: CardType;
	isRha?: boolean;
}

const Tin: FC<TinProps> = ({ type, isRha }) => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const { formData, availableCountries, requiresVerification } = useSelector(
		(state: RootState) => state.CardsState
	);
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const [countryOptions, setCountryOptions] = useState([]);
	const [tinCountry, setTinCountry] = useState<string | null>(null);
	const [countryError, setCountryError] = useState<MessageDescriptor | null>(null);
	const [tin, setTin] = useState<string>(formData?.tin || '');
	const [tinError, setTinError] = useState<MessageDescriptor | null>(null);

	const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
		const { value } = target;
		setTinError(null);
		return setTin(value);
	};

	const handleSelectChange = (val: string) => {
		setCountryError(null);
		return setTinCountry(val);
	};

	const validation = () => {
		if (!tin) setTinError(inputErrors.cannotBeEmpty);
		if (!tinCountry) setCountryError(inputErrors.cannotBeEmpty);
		if (!tin || !tinCountry) return false;
		return true;
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (validation()) {
			dispatch(setFormData({ tin, tinCountry }));
			let nextStep;

			if (availableCountries.includes(user?.country!) && type === CardType.VIRTUAL) {
				if (requiresVerification && formData?.cardType?.type === CardType.VIRTUAL) {
					nextStep = OrderSteps.DELIVERY_ADDRESS;
				} else if (isRha) {
					nextStep = OrderSteps.LINKED_WALLETS;
				} else {
					nextStep = OrderSteps.CONFIRM;
				}
			} else {
				nextStep = OrderSteps.DELIVERY_ADDRESS;
			}

			return dispatch(setOrderStep(nextStep));
		}
		return null;
	};

	useEffectOnce(() => {
		const countriesList = Object.keys(countries.getAlpha2Codes());
		const options = countriesList.reduce((acc: any, curr: any) => {
			const item = {
				value: curr,
				label: countries.getName(curr, 'en').replace(', Province of China', ''),
			};
			acc.push(item);
			return acc;
		}, []);
		setCountryOptions(options!);
	});

	useEffect(() => {
		if (countryOptions?.length > 0 && formData?.tinCountry) setTinCountry(formData.tinCountry);
	}, [countryOptions, formData]);

	return (
		<div className={styles.container}>
			<BackButton
				onClick={() => dispatch(setOrderStep(OrderSteps.TERMS))}
				className={styles.back}
			/>
			<InfoHead title={messages.title} subTitle={formatMessage(messages.subTitle)} />
			<form className={styles.form} onSubmit={handleSubmit}>
				<Select
					label={messages.selectLabel}
					labelClassName={styles.label}
					className={styles.input}
					options={countryOptions}
					onChange={handleSelectChange}
					value={tinCountry}
					errorMessage={countryError}
					styleType={SelectStyleType.BLOCK}
				/>
				<Input
					label={messages.inputLabel}
					labelClassName={styles.label}
					onChangeEvent={handleInputChange}
					value={tin}
					inputGroupClassName={styles.input}
					className={styles.fullWidth}
					errorMessage={tinError}
				/>
				<Button
					buttonStyle={ButtonStyle.PRIMARY}
					type={ButtonType.SUBMIT}
					text={baseMsg.submit}
					className={styles.button}
				/>
			</form>
			<h2 className={styles.title}>{formatMessage(messages.explainTitle)}</h2>
			<p className={styles.text}>{formatMessage(messages.explainText)}</p>
		</div>
	);
};

export default Tin;
