import { defineMessages } from 'react-intl';

export default defineMessages({
	metaTitle: {
		id: 'merchants.metaTitle',
		defaultMessage: 'Merchants',
	},
	options: {
		id: 'merchants.merchant_options',
		defaultMessage: 'Merchant options',
	},
	projects: {
		id: 'merchants.projects',
		defaultMessage: 'Projects',
	},
	allProjects: {
		id: 'merchants.all_projects',
		defaultMessage: 'All projects',
	},
	newProject: {
		id: 'merchants.new_project',
		defaultMessage: 'New project',
	},
	integrationTools: {
		id: 'merchants.integration_tools',
		defaultMessage: 'Integration tools',
	},
	noProjects: {
		id: 'merchants.no_projects',
		defaultMessage: 'There are no merchant projects yet',
	},
	emptyProjectTable: {
		id: 'merchants.empty_projects_table',
		defaultMessage: 'No projects match selected criteria',
	},
	emptyPaymentButtonTable: {
		id: 'merchants.empty_payment_buttons_table',
		defaultMessage: 'No payment buttons match selected criteria',
	},
	fiat: {
		id: 'manageWallets.fiat',
		defaultMessage: 'Fiat',
	},
	crypto: {
		id: 'manageWallets.crypto',
		defaultMessage: 'Crypto',
	},
	enabled: {
		id: 'merchants.enabled',
		defaultMessage: 'Enabled',
	},
	disabled: {
		id: 'merchants.disabled',
		defaultMessage: 'Disabled',
	},
	name: {
		id: 'walletBlock.name',
		defaultMessage: 'Name',
	},
	autoconvert: {
		id: 'merchants.autoconvert',
		defaultMessage: 'Autoconvert',
	},
	mode: {
		id: 'merchants.mode',
		defaultMessage: 'Mode',
	},
	test: {
		id: 'merchants.test',
		defaultMessage: 'Test',
	},
	forVerifiedPayers: {
		id: 'merchants.for_verified_payers_only',
		defaultMessage: 'For Verified Payers only',
	},
	forPayerFee: {
		id: 'merchants.for_payer_fee',
		defaultMessage: 'Fee paid by client',
	},
	status: {
		id: 'cardsList.status',
		defaultMessage: 'Status',
	},
	removeProject: {
		id: 'merchants.remove_project',
		defaultMessage: 'Remove project',
	},
	cancel: {
		id: 'load.cancel',
		defaultMessage: 'Cancel',
	},
	delete_x: {
		id: 'merchants.delete_x',
		defaultMessage: 'Delete {project}?',
	},
	createNewProject: {
		id: 'merchants.create_new_project',
		defaultMessage: 'Create new merchant project',
	},
	projectName: {
		id: 'merchants.project_name',
		defaultMessage: 'Project name',
	},
	description: {
		id: 'detailsModal.description',
		defaultMessage: 'Description',
	},
	autoConvertCurrency: {
		id: 'merchants.autoconvert_currency',
		defaultMessage: 'Autoconvert received currency',
	},
	chooseCurrency: {
		id: 'merchants.choose_currency_account',
		defaultMessage: 'Choose currency and settlement account',
	},
	publicKey: {
		id: 'merchants.public_key',
		defaultMessage: 'Public key',
	},
	copy: {
		id: 'base.copy',
		defaultMessage: 'Copy',
	},
	generate: {
		id: 'merchants.generate',
		defaultMessage: 'Generate',
	},
	projectCreateSuccess: {
		id: 'merchants.project_created',
		defaultMessage: 'Project successfully created',
	},
	tryAgain: {
		id: 'orderFlow.tryAgain',
		defaultMessage: 'Try again',
	},
	contactSupport: {
		id: 'convertFailed.contactSupport',
		defaultMessage: 'Contact support',
	},
	privateKey: {
		id: 'merchants.private_key',
		defaultMessage: 'Private key',
	},
	thisIsYourPrivateKey: {
		id: 'merchants.this_is_your_private_key',
		defaultMessage: 'This is your private key',
	},
	keepSafe: {
		id: 'merchants.private_key_keep_safe',
		defaultMessage: 'It should be saved and safely kept with your other passwords',
	},
	download: {
		id: 'merchants.download',
		defaultMessage: 'Download',
	},
	publicKeyTooltip: {
		id: 'merchants.public_key_tooltip',
		defaultMessage:
			'Your public and private keys are used for authentification. The public key will be available in your project information; however, you must save the private key as it will not be shown again.',
	},
	autoConvertTooltip: {
		id: 'merchants.autoconvert_tooltip',
		defaultMessage:
			'Autoconvert automatically converts the received currency to the selected one.',
	},
	testTooltip: {
		id: 'merchants.test_tooltip',
		defaultMessage:
			'Use this mode to test your projects without creating pending payment orders. While this mode is active, all your payment orders will automatically be marked as expired.',
	},
	testSelect: {
		id: 'merchants.test_select',
		defaultMessage: 'Select test mode',
	},
	forVerifiedPayersTooltip: {
		id: 'merchants.for_verified_payers_tooltip',
		defaultMessage: 'Orders can only be paid by verified SC accounts',
	},
	forPayerFeeTooltip: {
		id: 'merchants.for_payer_fee_tooltip',
		defaultMessage: 'Your customer will cover processing fees for the payment',
	},
	projectEnabledTooltip: {
		id: 'merchants.project_enabled_tooltip',
		defaultMessage:
			'Enabling your project will allow you to create payment orders and public order URLs.',
	},
	pmbEnabledTooltip: {
		id: 'merchants.pmb_enabled_tooltip',
		defaultMessage: 'Enabling your payment button will allow you to use it',
	},
	backToMerchant: {
		id: 'merchants.back_to_merchant',
		defaultMessage: 'Back to merchant',
	},
	projectNotFound: {
		id: 'merchants.project_not_found',
		defaultMessage: 'Merchant project not found',
	},
	editProject: {
		id: 'merchants.edit_project',
		defaultMessage: 'Edit project',
	},
	createOrder: {
		id: 'merchants.create_order',
		defaultMessage: 'Create order',
	},
	createPublicPaymentLink: {
		id: 'merchants.create_public_payment_url',
		defaultMessage: 'Create public payment link',
	},
	publicPaymentLink: {
		id: 'merchants.public_payment_url',
		defaultMessage: 'Public payment link',
	},
	merchantId: {
		id: 'merchants.merchant_id',
		defaultMessage: 'Merchant ID',
	},
	projectApiId: {
		id: 'merchants.project_api_id',
		defaultMessage: 'Project (API) ID',
	},
	orderHistory: {
		id: 'merchants.order_history',
		defaultMessage: 'Order history',
	},
	receivedX: {
		id: 'merchants.received_x',
		defaultMessage: 'Received {amount}',
	},
	orderStatusAll: {
		id: 'merchants.orderStatus.all',
		defaultMessage: 'All',
	},
	seeAll: {
		id: 'merchants.seeAll',
		defaultMessage: 'See all',
	},
	orderNotFound: {
		id: 'merchants.order_not_found',
		defaultMessage: 'Order not found',
	},
	preorderNotFound: {
		id: 'merchants.preorder_not_found',
		defaultMessage: 'Pre-Order not found',
	},
	transactionNotFound: {
		id: 'merchants.transaction_not_found',
		defaultMessage: 'Order deposit transaction not found',
	},
	callbackNotFound: {
		id: 'merchants.callback_not_found',
		defaultMessage: 'Order callback not found',
	},
	paymentButtonNotFound: {
		id: 'merchants.payment_button_not_found',
		defaultMessage: 'Payment button not found',
	},
	allowMultiplePayments: {
		id: 'merchants.allow_multiple_payments',
		defaultMessage: 'Allow multiple payments',
	},
	orderPrice: {
		id: 'merchants.order_price',
		defaultMessage: 'Order price',
	},
	orderId: {
		id: 'merchants.order_id',
		defaultMessage: 'Order ID',
	},
	callbackUrl: {
		id: 'merchants.callback_url',
		defaultMessage: 'Callback URL',
	},
	successUrl: {
		id: 'merchants.success_url',
		defaultMessage: 'Success URL',
	},
	failureUrl: {
		id: 'merchants.failure_url',
		defaultMessage: 'Failure URL',
	},
	emailNotification: {
		id: 'merchants.email_notificaiton',
		defaultMessage: 'Email notification',
	},
	allowMultiplePaymentsTooltip: {
		id: 'merchants.allow_multiple_payments_tooltip',
		defaultMessage:
			'These links work similarly to payment links; however, they allow for pre-orders to be opened multiple times; therefore, multiple orders can be created based on one pre-order.',
	},
	enterAmount: {
		id: 'merchants.enter_amount',
		defaultMessage: 'Enter amount',
	},
	preOrderDetails: {
		id: 'merchants.preorder_details',
		defaultMessage: '{project} Pre-Order details',
	},
	id: {
		id: 'merchants.id',
		defaultMessage: 'ID',
	},
	receive: {
		id: 'merchants.receive',
		defaultMessage: 'Receive',
	},
	date: {
		id: 'merchants.date',
		defaultMessage: 'Date',
	},
	publicPreOrderUrl: {
		id: 'merchants.public_preorder_url',
		defaultMessage: 'Public pre-order URL',
	},
	orderSummary: {
		id: 'merchants.order_summary',
		defaultMessage: 'Order summary',
	},
	depositAddress: {
		id: 'merchants.deposit_address',
		defaultMessage: 'Deposit address',
	},
	validUntil: {
		id: 'merchants.valid_until',
		defaultMessage: 'Valid until',
	},
	orderDeposits: {
		id: 'merchants.order_deposits',
		defaultMessage: 'Order deposits',
	},
	noOrderDeposits: {
		id: 'merchants.no_order_deposits',
		defaultMessage: 'There are no order deposits',
	},
	callbackToMerchant: {
		id: 'merchants.callback_to_merchant',
		defaultMessage: 'Callback to merchant',
	},
	retry: {
		id: 'base.retry',
		defaultMessage: 'Retry',
	},
	received: {
		id: 'merchants.received',
		defaultMessage: 'Received',
	},
	responseStatusCode: {
		id: 'merchants.response_status_code',
		defaultMessage: 'Response status code',
	},
	noCallbacksToMerchant: {
		id: 'merchants.no_callbacks_to_merchant',
		defaultMessage: 'There are no callbacks',
	},
	deposited: {
		id: 'merchants.deposited',
		defaultMessage: 'Deposited',
	},
	orderCallbackDetails: {
		id: 'merchants.order_callback_details',
		defaultMessage: 'Order callback details',
	},
	orderDepositTransactionDetails: {
		id: 'merchants.order_deposit_transaction_details',
		defaultMessage: 'Order deposit transaction details',
	},
	orderStatus: {
		id: 'merchants.order_status',
		defaultMessage: 'Order status',
	},
	response: {
		id: 'merchants.response',
		defaultMessage: 'Response',
	},
	address: {
		id: 'base.address',
		defaultMessage: 'Address',
	},
	acceptCrypoInYourBusinessTitle: {
		id: 'merchants.accept_crypto_in_your_business_title',
		defaultMessage: 'Accept cryptocurrency in your business',
	},
	acceptCrypoInYourBusinessDetails: {
		id: 'merchants.accept_crypto_in_your_business_details',
		defaultMessage:
			'Accept cryptocurrency payments at your online or physical store and avoid cryptocurrency price volatility by fixing the exchange rate.',
	},
	filterByStatus: {
		id: 'merchants.filter_by_status',
		defaultMessage: 'Filter by status',
	},
	selectStatus: {
		id: 'merchants.select_status',
		defaultMessage: 'Select status',
	},
	transactionStatusPending: {
		id: 'merchants.transaction_status_pending',
		defaultMessage: 'Pending',
	},
	transactionStatusChargedBack: {
		id: 'merchants.transaction_status_charged_back',
		defaultMessage: 'Charged back',
	},
	transactionStatusConfirmed: {
		id: 'merchants.transaction_status_confirmed',
		defaultMessage: 'Confirmed',
	},
	transactionStatusFailed: {
		id: 'merchants.transaction_status_failed',
		defaultMessage: 'Failed',
	},
	api: {
		id: 'merchants.api',
		defaultMessage: 'API',
	},
	paymentButtons: {
		id: 'merchants.payment_buttons',
		defaultMessage: 'Payment Buttons',
	},
	cryptoPaymentPlugins: {
		id: 'merchants.crypto_payment_plugins',
		defaultMessage: 'Crypto Payment Plugins',
	},
	libraries: {
		id: 'merchants.libraries',
		defaultMessage: 'Libraries',
	},
	paymentButtonsInfoBlock: {
		id: 'merchants.payment_buttons_info_block',
		defaultMessage: `A SpectroCoin payment button allows you to accept donations from other people! All
		you have to do is click “Create button”!
		<ul>
			<li>
				You can accept any cryptocurrency or have your received cryptocurrency
				automatically converted to any other currency available at SpectroCoin.
			</li>
			<li>
				Customize your payment button – add a title and description, and choose a
				button style that fits your Website or Blog!
			</li>
			<li>
				You will be provided with HTML code, which you can simply embed into your
				Website’s or Blog’s HTML.
			</li>
			<li>
				Once you set up your button, people will be able to send the amount of
				cryptocurrency they wish to pay or donate with a single click or QR scan.
			</li>
		</ul>`,
	},
	createButton: {
		id: 'merchants.create_button',
		defaultMessage: 'Create button',
	},
	viewAllPaymentButtons: {
		id: 'merchants.view_all_payment_buttons',
		defaultMessage: 'View all payment buttons',
	},
	createNewPaymentButton: {
		id: 'merchants.create_new_payment_button',
		defaultMessage: 'Create new payment button',
	},
	buttonName: {
		id: 'merchants.button_name',
		defaultMessage: 'Button name',
	},
	editButton: {
		id: 'merchants.edit_button',
		defaultMessage: 'Edit button',
	},
	editPaymentButton: {
		id: 'merchants.edit_payment_button',
		defaultMessage: 'Edit payment button',
	},
	publicUrl: {
		id: 'merchants.public_url',
		defaultMessage: 'Public URL',
	},
	paymentButtonStyle: {
		id: 'merchants.payment_button_style',
		defaultMessage: 'Payment button style',
	},
	donateCryptoWith_0: {
		id: 'merchants.donate_crypto_with_0',
		defaultMessage: 'Donate crypto with {Spectro}',
	},
	donateWithCrypto: {
		id: 'merchants.donate_with_crypto',
		defaultMessage: 'Donate with Crypto',
	},
	donate: {
		id: 'merchants.donate',
		defaultMessage: 'Donate',
	},
	save: {
		id: 'merchants.save',
		defaultMessage: 'Save',
	},
	removeButton: {
		id: 'merchants.remove_button',
		defaultMessage: 'Remove button',
	},
	allPaymentButtons: {
		id: 'merchants.all_payment_buttons',
		defaultMessage: 'All payment buttons',
	},
	yes: {
		id: 'base.yes',
		defaultMessage: 'Yes',
	},
	no: {
		id: 'base.no',
		defaultMessage: 'No',
	},
	view: {
		id: 'merchants.view',
		defaultMessage: 'view',
	},
	sendInvoice: {
		id: 'merchants.send_invoice',
		defaultMessage: 'Send invoice',
	},
	invoiceSuccess: {
		id: 'merchants.invoice_sent',
		defaultMessage: 'Invoice has been successfully sent',
	},
	acceptAllCurrencies: {
		id: 'merchants.accept_all_currencies',
		defaultMessage: 'Accept all currencies',
	},
	acceptAllCurrenciesTooltip: {
		id: 'merchants.accept_all_currencies_tooltip',
		defaultMessage:
			'Allow clients to pay you in their chosen currency. Disabling this will let you to select preferred currencies you want your clients to pay with',
	},
	acceptedCurrencies: {
		id: 'merchants.accepted_currencies',
		defaultMessage: 'Accepted currencies',
	},
	acceptableCurrencies: {
		id: 'merchants.acceptable_currencies',
		defaultMessage: 'Acceptable currencies',
	},
	chooseCurrencyToAccept: {
		id: 'merchants.choose_currency_to_accept',
		defaultMessage: 'Choose currency to accept',
	},
	x_currencies: {
		id: 'merchants.x_currencies',
		defaultMessage: '{Count} currencies',
	},
	allCurrencies: {
		id: 'merchants.all_currencies',
		defaultMessage: 'All currencies',
	},
	allCurrenciesAvailable: {
		id: 'merchants.all_currencies_available',
		defaultMessage: 'All currencies available',
	},
	payCurrenciesMutabilityInfo: {
		id: 'merchants.all_currencies_not_supported_for_autoconvert',
		defaultMessage:
			'Please be aware that not all currencies might be available due to your selected autoconvert currency.',
	},
	totalValueOfOrders: {
		id: 'merchants.total_value_of_orders',
		defaultMessage: 'Total value of orders processed',
	},
	dateRange: {
		id: 'merchants.date_range',
		defaultMessage: 'From {From} to {To}',
	},
	range1D: {
		id: 'merchants.range_1D',
		defaultMessage: '1D',
	},
	range1W: {
		id: 'merchants.range_1W',
		defaultMessage: '1W',
	},
	range1M: {
		id: 'merchants.range_1M',
		defaultMessage: '1M',
	},
	range1Y: {
		id: 'merchants.range_1Y',
		defaultMessage: '1Y',
	},
	paidOrders: {
		id: 'merchants.paid_orders',
		defaultMessage: 'Paid orders',
	},
	verifiedPayers: {
		id: 'merchants.verified_payers',
		defaultMessage: 'Verified payers',
	},
	cryptoPayouts: {
		id: 'merchants.cryptoPayouts',
		defaultMessage: 'Crypto payouts',
	},
	cryptoPayoutsPost: {
		id: 'merchants.cryptoPayoutsPost',
		defaultMessage:
			'Crypto payouts allow you to make payments to others using crypto. By offering crypto payments, your platform can stand out, attract more users, and facilitate faster transactions compared to traditional cash payments. Your platform can keep its funds in fiat currency, while SpectroCoin handles the conversion and paying out in crypto.',
	},
	operation: {
		id: 'payouts.operation',
		defaultMessage: 'Operation',
	},
	addressAndNetwork: {
		id: 'payouts.addressAndNetwork',
		defaultMessage: 'Address and network',
	},
	payAmount: {
		id: 'payouts.payAmount',
		defaultMessage: 'Pay amount',
	},
	payoutSummary: {
		id: 'payouts.payoutSummary',
		defaultMessage: 'Payout summary',
	},
	receiveCurrency: {
		id: 'payouts.receiveCurrency',
		defaultMessage: 'Convert currency',
	},
	receivingAddress: {
		id: 'payouts.receivingAddress',
		defaultMessage: 'Receiving address',
	},
	receivingMessage: {
		id: 'payouts.receivingMessage',
		defaultMessage: 'Memo/Destination Tag',
	},
	estimate: {
		id: 'payouts.estimate',
		defaultMessage: 'Estimate',
	},
	payoutSuccess: {
		id: 'payouts.payoutSuccess',
		defaultMessage: 'Payout successful',
	},
	payoutFee: {
		id: 'payouts.payoutFee',
		defaultMessage: 'Payout fee',
	},
	estimatedReceiveAmount: {
		id: 'payouts.estimatedReceiveAmount',
		defaultMessage: 'Estimated receive amount',
	},
});
