import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import queryString, { ParsedQuery } from 'query-string';
import { useLocation } from 'react-router';
import HistoryContext, {
	DefaultHistoryContextData,
} from '../contexts/HistoryContext/HistoryContext';
import { RootState } from '../redux/Store';
import { getCardHistory, resetCardHistory } from '../redux/CardHistoryState/CardHistoryActions';
import {
	getDepositCryptoHistory,
	getDepositFiatHistory,
	resetDepositsHistory,
} from '../redux/DepositHistoryState/DepositHistoryActions';
import useDidMount from './useDidMount';

export enum HistoryType {
	WALLET = 'WALLET',
	CARD = 'CARD',
	CRYPTO = 'CRYPTO',
	FIAT = 'FIAT',
	ORDERS = 'ORDERS',
	CRYPTO_WITHDRAW = 'CRYPTO_WITHDRAW',
	VOUCHERS_WITHDRAW = 'VOUCHERS_WITHDRAW',
	BANK_WITHDRAW = 'BANK_WITHDRAW',
	'GIFT-CARDS_WITHDRAW' = 'GIFT-CARDS_WITHDRAW',
	'MOBILE-TOP-UPS_WITHDRAW' = 'MOBILE-TOP-UPS_WITHDRAW',
	'E-WALLET_WITHDRAW' = 'E-WALLET_WITHDRAW',
	OPEN_ORDERS = 'OPEN_ORDERS',
	INSTANT_EXCHANGE = 'INSTANT_EXCHANGE',
	DOCUMENTS = 'DOCUMENTS',
	PAYOUTS = 'PAYOUTS',
}

export type HistoryTableData<T> = {
	data: T[] | null;
	isLoading: boolean;
	totalCount: number;
	pageSize?: number;
};

const useHistoryTableData = () => {
	const dispatch = useDispatch();
	const { search } = useLocation();
	const didMount = useDidMount();

	const { historyType, id, filter } = useContext(HistoryContext) as DefaultHistoryContextData;
	const [history, setHistory] = useState<HistoryTableData<any>>({
		data: null,
		isLoading: false,
		totalCount: 0,
	});
	const { startDate, endDate, page, type }: ParsedQuery<any> = queryString.parse(search);
	const walletHistory = useSelector((state: RootState) => state.WalletHistoryState);
	const cardsHistory = useSelector((state: RootState) => state.CardHistoryState);
	const depositsHistory = useSelector((state: RootState) => state.DepositHistoryState);

	useEffect(() => {
		if (didMount) {
			if (historyType === HistoryType.CARD && id)
				dispatch(getCardHistory(id, startDate, endDate, page));
			if (historyType === HistoryType.CRYPTO)
				void dispatch(getDepositCryptoHistory(startDate, endDate, page, filter));
			if (historyType === HistoryType.FIAT)
				void dispatch(getDepositFiatHistory(startDate, endDate, page));
		}
		return () => {
			if (historyType === HistoryType.CARD) dispatch(resetCardHistory());
			if ([HistoryType.CRYPTO, HistoryType.FIAT].includes(historyType))
				dispatch(resetDepositsHistory());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, startDate, endDate, page, historyType, filter, type]);

	useEffect(() => {
		if (historyType === HistoryType.CARD && cardsHistory) {
			const { data, isLoading, totalCount } = cardsHistory;
			setHistory({
				data,
				isLoading,
				totalCount,
			});
		}
		if ([HistoryType.CRYPTO, HistoryType.FIAT].includes(historyType) && depositsHistory) {
			const { data, isLoading, totalCount } = depositsHistory;
			setHistory({
				data,
				isLoading,
				totalCount,
			});
		}
	}, [walletHistory, historyType, cardsHistory, depositsHistory]);

	return {
		...history,
	};
};

export type HistoryTableDataProvider = typeof useHistoryTableData;

export default useHistoryTableData;
