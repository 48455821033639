import { Middleware } from 'redux';
import MerchantsProjectsMiddleware from './Projects/ProjectsMiddleware';
import MerchantsOrdersMiddleware from './Orders/OrdersMiddleware';
import MerchantsPreOrdersMiddleware from './Preorders/PreordersMiddleware';
import PaymentButtonsMiddleware from './PaymentButtons/PaymentButtonsMiddleware';
import {
	MerchantsActionType,
	MerchantsLoadDataFulfilledAction,
	MerchantsLoadDataPendingAction,
	MerchantsLoadDataRejectedAction,
	PaymentButtonsListStatus,
	ProjectsListStatus,
} from './MerchantTypes';
import { RootState } from '../Store';
import MerchantsActions from './MerchantsActions';

const MerchantMiddleware: Middleware<void, RootState> = (storeApi) => (next) => (
	action:
		| MerchantsLoadDataFulfilledAction
		| MerchantsLoadDataPendingAction
		| MerchantsLoadDataRejectedAction
) => {
	switch (action.type) {
		case MerchantsActionType.LOAD_DATA_PENDING:
			storeApi.dispatch(MerchantsActions.projects.list.setStatus(ProjectsListStatus.Loading));
			break;
		case MerchantsActionType.LOAD_DATA_REJECTED:
			storeApi.dispatch(MerchantsActions.projects.list.setStatus(ProjectsListStatus.Error));
			break;
		case MerchantsActionType.LOAD_DATA_FULFILLED:
			storeApi.dispatch(
				MerchantsActions.projects.list.setStatus(
					action.payload.projects.totalElements > 0
						? ProjectsListStatus.Ready
						: ProjectsListStatus.Empty
				)
			);
			storeApi.dispatch(
				MerchantsActions.paymentButtons.list.setStatus(
					action.payload.paymentButtons.totalElements > 0
						? PaymentButtonsListStatus.Ready
						: PaymentButtonsListStatus.Empty
				)
			);
			storeApi.dispatch(
				MerchantsActions.projects.data.updateProjects(action.payload.projects.content)
			);
			storeApi.dispatch(
				MerchantsActions.paymentButtons.data.updateButtons(
					action.payload.paymentButtons.content
				)
			);
			break;
		default:
			break;
	}

	next(action);
};

export default [
	MerchantMiddleware,
	...MerchantsProjectsMiddleware,
	...MerchantsOrdersMiddleware,
	...MerchantsPreOrdersMiddleware,
	...PaymentButtonsMiddleware,
];
