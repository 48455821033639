import { useState, useEffect, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import IBANStatusEnum from '../../../enums/IBANStatusEnum';
import IbanProcessStatusProps from '../../../interfaces/IBANProcessStatus';
import Loader from '../../../components/Loader/Loader';
import Seo from '../../../components/Seo/Seo';
import styles from './ProcessIBAN.module.scss';
import { getClaimIBANURL } from '../../../redux/endpoints';
import axiosInstance from '../../../helpers/axiosInstance';

const messages = defineMessages({
	title: {
		id: 'claimIBAN.title',
		defaultMessage: 'Claim IBAN',
	},
	metaTitle: {
		id: 'claimIBAN.processMeta',
		defaultMessage: 'IBAN Process',
	},
});

export enum IframeMessage {
	SUCCESS = 'success',
	FAILURE = 'failure',
	FORCE_REDIRECT = 'force_redirect',
	RESIZE = 'resize',
	NANO_RESIZE = 'resize-event',
	PAGE_CHANGE = 'page change',
}

interface ProcessIBANProps {
	process: IbanProcessStatusProps | null;
}

const ProcessIBAN = ({ process }: ProcessIBANProps) => {
	const { formatMessage } = useIntl();
	const [iframeHeight, setIframeHeight] = useState<number>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [iframeURL, setIframeURL] = useState<string | null>(null);
	const { push, location } = useHistory();
	const { url } = useRouteMatch();
	const [processData, setProcessData] = useState(process || location?.state?.process);
	const { verificationUrl, approvalUrl, status } = processData;
	const intervalRef = useRef<NodeJS.Timeout>();

	const onFailureCallback = () => push(`${url}/failure`);

	const onSuccessCallback = () => push(`${url}/success`);

	const onReceiveMessage = ({ origin, data }: MessageEvent) => {
		const isTrustedOrigin =
			iframeURL !== null && new URL(origin).hostname === new URL(iframeURL).hostname;

		if (isTrustedOrigin) {
			switch (data.event) {
				case IframeMessage.SUCCESS:
					return onSuccessCallback();
				case IframeMessage.FAILURE:
					return onFailureCallback();
				case IframeMessage.FORCE_REDIRECT:
					return window.location.assign(iframeURL!);
				case IframeMessage.NANO_RESIZE:
				case IframeMessage.RESIZE:
					return setIframeHeight(data.height);
				case IframeMessage.PAGE_CHANGE:
					setIsLoading(false);
					return window.scrollTo(0, 0);
				default:
					return null;
			}
		}
		return null;
	};

	useEffect(() => {
		if (iframeURL) {
			window.addEventListener('message', onReceiveMessage);
		}
		return () => window.removeEventListener('message', onReceiveMessage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [iframeURL]);

	useEffect(() => {
		if (processData && !iframeURL && (approvalUrl || verificationUrl)) {
			setIframeURL(
				status === IBANStatusEnum.VERIFICATION_SUCCESS && approvalUrl
					? `${approvalUrl}`
					: `${verificationUrl}`
			);
		}
		if (iframeURL) {
			window.addEventListener('message', onReceiveMessage, false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [processData, iframeURL, approvalUrl, verificationUrl, status]);

	useEffect(() => {
		if (iframeURL) setIsLoading(false);
	}, [iframeURL]);

	useEffect(() => {
		intervalRef.current = setInterval(() => {
			return axiosInstance
				.get(getClaimIBANURL())
				.then(({ data }: AxiosResponse<IbanProcessStatusProps>) => {
					if (data.status !== IBANStatusEnum.TEMPORARY_SUBMITTED_STATUS) {
						setIsLoading(false);
						clearInterval(intervalRef.current);
						return setProcessData(data);
					}

					return null;
				})
				.catch(() => {
					return null;
				});
		}, 1000);

		return () => {
			clearInterval(intervalRef.current);
		};
	}, []);

	return (
		<div className={styles.container}>
			<Seo title={messages.metaTitle} />
			<h2 className={styles.title}>{formatMessage(messages.title)}</h2>
			{isLoading && <Loader />}
			<iframe
				className={styles.iframe}
				height={iframeHeight}
				src={iframeURL!}
				allow="microphone; camera"
				title="Process IBAN form"
			/>
		</div>
	);
};

export default ProcessIBAN;
