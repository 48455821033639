import { CurrencyEnum } from '@spectrocoin/sc-currencies';

export enum ConvertActionTypes {
	SET_CONVERT_PAIRS = 'SET_CONVERT_PAIRS',
	SET_IS_RECURRING_ENABLED = 'SET_IS_RECURRING_ENABLED',
}

export enum CalcType {
	PAY = 'PAY',
	RECEIVE = 'RECEIVE',
}

export enum Frequency {
	DAILY = 'DAILY',
	WEEKLY = 'WEEKLY',
	BIWEEKLY = 'BIWEEKLY',
	MONTHLY = 'MONTHLY',
}

export enum ConvertStatusType {
	PAID = 'PAID',
	PENDING = 'PENDING',
	NEW = 'NEW',
	ACTIVE = 'ACTIVE',
	PAUSED = 'PAUSED',
	CANCELLED = 'CANCELLED',
}

export enum ExchangeType {
	INSTANT = 'instant',
	RECURRING = 'recurring',
	ADVANCED = 'advanced',
	CARD = 'card',
	LEVARAGE = 'levarage',
}

export interface ConvertCurrencyModel {
	currencyCode: CurrencyEnum;
	currencySymbol: string;
	name: string;
	scale: number;
	virtual: boolean;
}

export interface ConvertPairsResponse {
	id: string;
	payCurrency: ConvertCurrencyModel;
	receiveCurrency: ConvertCurrencyModel;
}

export interface ConvertState {
	pairs: ConvertPairsResponse[] | null;
	isRecurringEnabled: boolean;
}

export interface SetConvertPairsAction {
	type: typeof ConvertActionTypes.SET_CONVERT_PAIRS;
	data: ConvertPairsResponse[];
}

export interface SetIsRecurringEnabledAction {
	type: typeof ConvertActionTypes.SET_IS_RECURRING_ENABLED;
	data: boolean;
}
