import { FC } from 'react';
import { Payout } from '../../../../redux/MerchantsState/CryptoPayouts/PayoutsData/PayoutsDataTypes';
import PayoutListItem from './PayoutListItem/PayoutListItem';

const PayoutList: FC<{ data: Payout[] }> = ({ data }) => {
	if (data.length === 0) return null;
	return (
		<>
			{data.map(
				({
					callbackUrl,
					expiresAt,
					id,
					networkCode,
					payAmount,
					payCurrencyCode,
					receiveCurrencyCode,
					receiverAddress,
					receiverMessage,
					refId,
					status,
				}) => (
					<PayoutListItem
						key={id}
						callbackUrl={callbackUrl}
						expiresAt={expiresAt}
						id={id}
						networkCode={networkCode}
						payAmount={payAmount}
						payCurrencyCode={payCurrencyCode}
						receiveCurrencyCode={receiveCurrencyCode}
						receiverAddress={receiverAddress}
						receiverMessage={receiverMessage}
						refId={refId}
						status={status}
					/>
				)
			)}
		</>
	);
};

export default PayoutList;
