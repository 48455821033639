import { FC, PropsWithChildren } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { useDispatch, useSelector } from 'react-redux';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import PageTitle from '../../../Merchants/Shared/PageTitle/PageTitle';
import {
	DefaultReferralCode,
	ReferralComission,
	ReferralId,
	defaultCodeQuery,
	referralIdsQuery,
} from '../../../../redux/ReferralsState/Queries/ReferralQueries';
import useQueryParams from '../../../../hooks/useQueryParams';
import styles from './ReferralIdsList.module.scss';
import ReferralsMessages from '../../../../redux/ReferralsState/ReferralsMessages';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import useReferralRoutes, { ReferralRoutes } from '../../../../hooks/useReferralRoutes';
import CopyButton from '../../../../components/CopyButton/CopyButton';
import ModalActions from '../../../../redux/ModalState/ModalActions';
import NewReferralId from '../NewReferralId/NewReferralId';
import PageableResponse from '../../../../interfaces/PageableResponse';
import SortableList, { ListCell } from '../SortableList/SortableList';
import { RootState } from '../../../../redux/Store';
import { useSortableListRecord } from '../SortableList/SortableListContext';

const useListParams = () => {
	return useQueryParams({
		page: '1',
		sort: '',
	});
};

const Action: FC = () => {
	const { formatMessage } = useIntl();
	const {
		cashback,
		commission,
		default: isDefault,
		id,
		note,
		signups,
	} = useSortableListRecord<ReferralId>()!;
	const queryClient = useQueryClient();
	const [[page, sort]] = useListParams();

	const mutation = useMutation({
		mutationFn: defaultCodeQuery.mutationFn,
		onMutate: async () => {
			await queryClient.cancelQueries({ queryKey: defaultCodeQuery.getKey() });

			queryClient.setQueryData(defaultCodeQuery.getKey(), () => ({
				cashback,
				commission,
				default: true,
				id,
				note,
				signups,
			}));

			queryClient.setQueryData<PageableResponse<ReferralId[]>>(
				referralIdsQuery.getKey({ page: +page! - 1, sort: sort! }),
				(old) => {
					const oldDefault = old?.content.find((x) => x.default);
					if (oldDefault) oldDefault.default = false;
					const newDefault = old?.content.find((x) => x.id === id);
					if (newDefault) newDefault.default = true;
					return old;
				}
			);
		},
		onSuccess: (result) => {
			queryClient.setQueryData<DefaultReferralCode>(defaultCodeQuery.getKey(), () => result);
			queryClient.setQueryData<PageableResponse<ReferralId[]>>(
				referralIdsQuery.getKey({ page: +page! - 1, sort: sort! }),
				(old) => old
			);
		},
		onError: () => {
			queryClient.setQueryData<DefaultReferralCode>(defaultCodeQuery.getKey(), (old) => old);
			queryClient.setQueryData<PageableResponse<ReferralId[]>>(
				referralIdsQuery.getKey({ page: +page! - 1, sort: sort! }),
				(old) => old
			);
		},
	});

	if (isDefault)
		return <div className={styles.default}>{formatMessage(ReferralsMessages.default)}</div>;

	return (
		<Button
			className={styles.setDefault}
			buttonStyle={ButtonStyle.SECONDARY_WITHOUT_BORDER}
			type={ButtonType.BUTTON}
			onClick={() => mutation.mutate(id)}
			isDisabled={mutation.isPending}
		>
			{formatMessage(ReferralsMessages.setAsDefault)}
		</Button>
	);
};

const Id: FC = () => {
	const record = useSortableListRecord<ReferralId>()!;
	return (
		<>
			<span>{record.id}</span>
			<CopyButton className={styles.copy} dataToCopy={record.id} />
		</>
	);
};

const Percentage: FC<{ source: keyof ReferralId }> = ({ source }) => {
	const record = useSortableListRecord<ReferralId>()!;
	return <>{record[source]}%</>;
};

const ReferralIdsList: FC = () => {
	const { formatMessage } = useIntl();
	const { getUrl } = useReferralRoutes();
	const dispatch = useDispatch();
	const { isMobile } = useSelector((state: RootState) => state.AppState);
	const [[page, sort]] = useListParams();
	const query = useQuery({
		queryKey: referralIdsQuery.getKey({ page: +page! - 1, sort: sort! }),
		queryFn: referralIdsQuery.getFn({ page: +page! - 1, sort: sort! }),
	});

	return (
		<WhiteContainer className={styles.container}>
			<PageTitle
				className={styles.header}
				title={formatMessage(ReferralsMessages.yourRefIds)}
				backLink={getUrl(ReferralRoutes.Home)}
			>
				{!isMobile && (
					<Button
						buttonStyle={ButtonStyle.PRIMARY}
						className={styles.button}
						onClick={() => {
							dispatch(ModalActions(<NewReferralId />));
						}}
					>
						<div className={styles.withIcon}>
							<FontAwesomeIcon icon={faPlus} />
							<span>{formatMessage(ReferralsMessages.createNew)}</span>
						</div>
					</Button>
				)}
			</PageTitle>
			<div className={styles.body}>
				<span className={styles.note}>
					{formatMessage(ReferralsMessages.yourRefIdsNode)}
				</span>
				{isMobile && (
					<Button
						buttonStyle={ButtonStyle.PRIMARY}
						className={styles.button}
						onClick={() => {
							dispatch(ModalActions(<NewReferralId />));
						}}
					>
						<div className={styles.withIcon}>
							<FontAwesomeIcon icon={faPlus} />
							<span>{formatMessage(ReferralsMessages.createNew)}</span>
						</div>
					</Button>
				)}
				<div className={styles.content}>
					<SortableList
						query={query}
						emptyMessage={formatMessage(ReferralsMessages.empty)}
					>
						<ListCell
							className={styles.id}
							header={formatMessage(ReferralsMessages.refId)}
						>
							<Id />
						</ListCell>
						<ListCell<ReferralId>
							header={formatMessage(ReferralsMessages.youReceive)}
							sortBy="commission"
						>
							<Percentage source="commission" />
						</ListCell>
						<ListCell<ReferralId>
							header={formatMessage(ReferralsMessages.friendsReceive)}
							sortBy="cashback"
						>
							<Percentage source="cashback" />
						</ListCell>
						<ListCell<ReferralId>
							header={formatMessage(ReferralsMessages.friends)}
							sortBy="signups"
							source="signups"
						/>
						<ListCell<ReferralId>
							header={formatMessage(ReferralsMessages.note)}
							source="note"
						/>
						<ListCell<ReferralId> header={formatMessage(ReferralsMessages.action)}>
							<Action />
						</ListCell>
					</SortableList>
				</div>
			</div>
		</WhiteContainer>
	);
};

export default ReferralIdsList;
