import { useDispatch, useSelector } from 'react-redux';
import {
	// useContext,
	useEffect,
} from 'react';
import { useLocation } from 'react-router';
import queryString, { ParsedQuery } from 'query-string';
import { HistoryTableData } from '../../../../hooks/useHistoryTableData';
// import HistoryContext, {
// 	DefaultHistoryContextData,
// } from '../../../contexts/HistoryContext/HistoryContext';
import useDidMount from '../../../../hooks/useDidMount';
import useQueryParams from '../../../../hooks/useQueryParams';
import { RootState } from '../../../../redux/Store';
import {
	getOpenOrderHistory,
	resetConvertHistory,
} from '../../../../redux/ConvertHistoryState/ConvertHistoryActions';
import { ConvertStatusType } from '../../../../redux/ConvertState/ConvertTypes';
import { OpenOrder } from '../../../../redux/ConvertHistoryState/ConvertHistoryTypes';

const useOpenOrderDataProvider = (nameFilter: string): HistoryTableData<any> => {
	const { search } = useLocation();

	const didMount = useDidMount();
	const dispatch = useDispatch();
	const { type }: ParsedQuery<any> = queryString.parse(search);
	const [[page, status]] = useQueryParams({
		page: undefined,
		status: undefined,
	});

	const { totalCount, isLoading, data } = useSelector(
		(state: RootState) => state.ConvertHistoryState
	);

	useEffect(() => {
		if (didMount) {
			dispatch(
				getOpenOrderHistory(
					typeof page === 'string' ? +page : page,
					status ? ConvertStatusType[status] : undefined
				)
			);
		}
		return () => {
			dispatch(resetConvertHistory());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, didMount, status, type]);

	return {
		data: data.filter((openOrder: OpenOrder) => {
			if (
				openOrder.payCurrencyCode.toLowerCase().includes(nameFilter) ||
				openOrder.receiveCurrencyCode.toLowerCase().includes(nameFilter)
			)
				return openOrder;
			return null;
		}),
		isLoading,
		totalCount,
	};
};

export default useOpenOrderDataProvider;
