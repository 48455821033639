import { AxiosResponse } from 'axios';
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import countries from 'i18n-iso-countries';
import { isValidPhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import queryString, { ParsedQuery } from 'query-string';
import usePrevious from '../../../../hooks/usePrevious';
import baseMsg from '../../../../messages/base.messages';
import { formatPrecision, toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import {
	setCurrentTopupMethod,
	setMobileTopupCountry,
} from '../../../../redux/WithdrawState/WithdrawActions';
import { RootState } from '../../../../redux/Store';
import {
	getWithdrawTopupCalcURL,
	getWithdrawTopUpItemURL,
	getWithdrawTopupsURL,
} from '../../../../redux/endpoints';
import {
	PurchaseItem,
	PurchaseSuccessResponse,
	WithdrawType,
} from '../../../../redux/WithdrawState/WithdrawTypes';
import { Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import RadioBox, { RadioOption } from '../../../../components/RadioBox/RadioBox';
import Select from '../../../../components/Select/Select';
import Button, { ButtonType } from '../../../../components/Button/Button';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import PhoneNumberInput from '../../../../components/PhoneNumberInput/PhoneNumberInput';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import WithdrawConfirm from '../../Shared/WithdrawConfirm/WithdrawConfirm';
import WithdrawFormContainer, {
	WithdrawSteps,
} from '../../Shared/WithdrawFormContainer/WithdrawFormContainer';
import WithdrawSuccess from '../../Shared/WithdrawSuccess/WithdrawSuccess';
import styles from './MobileTopupsForm.module.scss';
import { isAmountGreaterThanZero } from '../../../../helpers/inputValidation/inputValidation';
import inputErrors from '../../../../messages/inputErrors.messages';
import AccountSelect from '../../Shared/AccountSelect/AccountSelect';
import Seo from '../../../../components/Seo/Seo';
import useFormatAmount from '../../../../hooks/useFormatAmount';
import TransfersFormLayout from '../../../../layout/TransfersFormLayout/TransfersFormLayout';
import { HowToType } from '../../../../layout/TransfersFormLayout/HowTo/HowTo';
import SelectOption from '../../../../interfaces/SelectOption';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	howToTitle: {
		id: 'mobileTopupForm.howToTitle',
		defaultMessage: 'Mobile Top-up',
	},
	topupAmount: {
		id: 'mobileTopupForm.topupAmount',
		defaultMessage: 'Top-up amount',
	},
	cannotBeZero: {
		id: 'cryptoWithdrawForm.cannotBeZero',
		defaultMessage: 'Amount should be greater then zero',
	},
	notEnoughBalance: {
		id: 'cryptoWithdrawForm.notEnoughBalance',
		defaultMessage: 'Not enough balance',
	},
	invalidPhoneNumber: {
		id: 'cryptoWithdrawForm.invalidPhoneNumber',
		defaultMessage: 'Invalid phone number',
	},
});

interface TopupResponse {
	code: string | null;
	date: string;
	id: string;
	itemTitle: string;
	payAmount: string;
	payCurrencyCode: CurrencyEnum;
	productTitle: string | null;
	receiver: string;
	sellAmount: string | null;
	sellCurrencyCode: CurrencyEnum;
	status: string;
}

interface Params {
	countryCode: string;
	id: string;
}

interface TopupItem {
	id: string;
	itemCurrency: string;
	itemIdentifier: string;
	maxPrice: string | null;
	minPrice: string | null;
	title: string;
}

interface FormProps {
	phone: string;
	currency: string;
	amount: string;
}

interface FormErrorsProps {
	phone: MessageDescriptor | string | null;
	currency: MessageDescriptor | string | null;
	amount: MessageDescriptor | string | null;
}

const MobileTopupsForm = () => {
	const { countryCode, id } = useParams<Params>();
	const dispatch = useDispatch();
	const { locale, formatMessage } = useIntl();
	const { pathname, search } = useLocation();
	const amountFormatter = useFormatAmount();
	const { push } = useHistory();
	const parsedQuery: ParsedQuery<string> = queryString.parse(search);
	const { step } = parsedQuery;
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const { paymentMethods } = useSelector((state: RootState) => state.WithdrawState);
	const { topups } = paymentMethods;
	const { data: topupData, isLoaded, countryCode: country } = topups;
	const [current, setCurrent] = useState<PurchaseItem | null>(null);
	const [amountOptions, setAmountOptions] = useState<SelectOption[]>([]);
	const [items, setItems] = useState<TopupItem[]>([]);
	const [form, setForm] = useState<FormProps>({
		phone: `+${getCountryCallingCode(countryCode as any)}` || '',
		currency: '',
		amount: '',
	});
	const [formErrors, setFormErrors] = useState<FormErrorsProps>({
		phone: null,
		currency: null,
		amount: null,
	});
	const [payAmount, setPayAmount] = useState<string>('0');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [successData, setSuccessData] = useState<PurchaseSuccessResponse[]>([]);
	const prevAmount = usePrevious(form.amount);
	const prevCurrency = usePrevious(form.currency);

	const currentWallet: Wallet[] = useMemo(() => {
		return (
			wallets?.filter(({ currencyCode }) => {
				return form.currency.split('/')[0] === currencyCode;
			}) || []
		);
	}, [form.currency, wallets]);

	const getIsFormValid = () => {
		const { phone, currency, amount } = form;
		let isValid = true;
		let errors = formErrors;
		if (!amount || !currency || !phone) {
			errors = {
				...errors,
				phone: !phone ? inputErrors.cannotBeEmpty : null,
				currency: !currency ? inputErrors.cannotBeEmpty : null,
				amount: !amount ? inputErrors.cannotBeEmpty : null,
			};
			isValid = false;
		}
		if (amount && !isAmountGreaterThanZero(amount.split('/')[0])) {
			errors = {
				...errors,
				amount: messages.cannotBeZero,
			};
			isValid = false;
		}
		if (payAmount) {
			if (toDecimal(payAmount).greaterThan(currentWallet[0]?.availableBalance || '0')) {
				errors = {
					...errors,
					currency: messages.notEnoughBalance,
				};
				isValid = false;
			}
		}
		if (phone && !isValidPhoneNumber(phone)) {
			errors = {
				...errors,
				phone: messages.invalidPhoneNumber,
			};
			isValid = false;
		}
		setFormErrors(errors);
		return isValid;
	};

	const onSuccess = (data: PurchaseSuccessResponse) => setSuccessData([data]);

	const calcPayAmount = useCallback(() => {
		setIsLoading(true);
		void axiosInstance
			.post(getWithdrawTopupCalcURL(), {
				id: form.amount.split('/')[2],
				payAmount: '',
				payCurrencyCode: form.currency.split('/')[0],
				receiveAmount: form.amount.split('/')[0],
				receiveCurrencyCode: form.amount.split('/')[1],
			})
			.then(
				({
					data,
				}: AxiosResponse<{
					payAmount: string | null;
					payCurrencyCode: CurrencyEnum;
					receiveAmount: string | null;
					receiveCurrencyCode: CurrencyEnum;
				}>) => {
					setPayAmount(data.payAmount || '0');
				}
			)
			.catch(() => null)
			.then(() => setIsLoading(false));
	}, [form]);

	const getTopupItems = useCallback(() => {
		void axiosInstance
			.get(getWithdrawTopUpItemURL(id))
			.then(({ data }: AxiosResponse<TopupItem[]>) => {
				setItems(
					data.sort((a: TopupItem, b: TopupItem) => {
						return toDecimal(a.itemIdentifier)
							.minus(toDecimal(b.itemIdentifier))
							.toNumber();
					})
				);
			});
	}, [id]);

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!getIsFormValid()) return null;
		return push({ pathname, search: `step=${WithdrawSteps.CONFIRM}` });
	};

	const postData = () =>
		axiosInstance.post(getWithdrawTopupsURL(), {
			payAccountId: form.currency.split('/')[2],
			purchaseProductItemId: form.amount.split('/')[2],
			receiveAmount: form.amount.split('/')[0],
			receiver: form.phone,
		}) as Promise<TopupResponse>;

	useEffect(() => {
		getTopupItems();
	}, [getTopupItems, id]);

	useEffect(() => {
		if (countryCode !== country && topupData?.length === 0) {
			dispatch(setMobileTopupCountry(countryCode));
			dispatch(setCurrentTopupMethod(countries.alpha2ToAlpha3(countryCode)));
		}
	}, [country, countryCode, topupData.length, dispatch]);

	useEffect(() => {
		if (topupData.length > 0 && id) {
			const currentTopup = topupData.filter((o: PurchaseItem) => o.id === id)[0];
			setCurrent(currentTopup || []);
		}
	}, [topupData, id]);

	useEffect(() => {
		if (items.length > 0) {
			const options = items.reduce(
				(
					acc: SelectOption[],
					{ id: itemId, title, itemIdentifier, itemCurrency }: TopupItem
				) => {
					const item = {
						value: `${itemIdentifier}/${itemCurrency}/${itemId}`,
						label: (
							<div className={styles.option}>
								<label className={styles.currencyLabel}>{`${title}`}</label>
								<div className={styles.amount}>
									{`(${amountFormatter(
										formatPrecision(itemIdentifier!, CurrencyEnum[itemCurrency])
									)} ${itemCurrency})`}
								</div>
							</div>
						),
					};
					acc.push(item);
					return acc;
				},
				[]
			);
			setAmountOptions(options);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	useEffect(() => {
		const { amount, currency } = form;
		if (amount && currency && (amount !== prevAmount || currency !== prevCurrency)) {
			calcPayAmount();
		}
	}, [calcPayAmount, form, prevAmount, prevCurrency]);

	useEffect(() => {
		if (!step && countryCode) {
			setIsLoading(false);
			setSuccessData([]);
			setForm({
				phone: `+${getCountryCallingCode(countryCode as any)}` || '',
				currency: '',
				amount: '',
			});
		}
	}, [countryCode, step]);

	return (
		<>
			<Seo title={current?.title} />
			<WithdrawFormContainer
				walletId={form.currency.split('/')[2]}
				isLoaded={isLoaded && !!current && items.length > 0}
				title={
					<PageTitle
						previousPageLink={`/${locale}/withdraw/mobile-top-ups?country=${countryCode}`}
						isPreviousPageLinkVisibleOnDesktop
						isPreviousPageLinkVisibleOnMobile
						title={current?.title || ''}
					/>
				}
				form={
					<TransfersFormLayout
						method={null}
						type={HowToType.WITHDRAW}
						title={formatMessage(messages.howToTitle)}
						providerType={'MobileTopup'}
					>
						<form onSubmit={handleSubmit} className={styles.form}>
							<PhoneNumberInput
								label={baseMsg.phoneNumber}
								country={countryCode}
								value={form.phone}
								setValue={(phone: string) => {
									setFormErrors({ ...formErrors, phone: null });
									setForm({
										...form,
										phone,
									});
								}}
								countries={[countryCode]}
								errorMessage={formErrors.phone}
							/>
							<AccountSelect
								value={form.currency}
								onChange={(val: string) => {
									setFormErrors({
										...formErrors,
										currency: null,
									});
									setForm({
										...form,
										currency: val,
									});
								}}
								errorMessage={formErrors.currency}
								type={WithdrawType.GIFT}
							/>
							{items.length > 7 ? (
								<Select
									value={form.amount}
									options={amountOptions}
									labelClassName={styles.label}
									label={messages.topupAmount}
									className={styles.select}
									onChange={(amount: string) => {
										setFormErrors({ ...formErrors, amount: null });
										setForm({
											...form,
											amount,
										});
									}}
									errorMessage={formErrors.amount}
								/>
							) : (
								<RadioBox
									label={messages.topupAmount}
									options={items.reduce(
										(
											acc: RadioOption[],
											{ id: itemId, title, itemIdentifier, itemCurrency }
										) => {
											const option = {
												value: `${itemIdentifier}/${itemCurrency}/${itemId}`,
												title,
											};
											acc.push(option);
											return acc;
										},
										[]
									)}
									checked={form.amount}
									name="topupAmount"
									onChange={({
										target: { value },
									}: ChangeEvent<HTMLInputElement>) =>
										setForm({
											...form,
											amount: value,
										})
									}
								/>
							)}
							<InfoInput
								label={baseMsg.payAmount}
								balance={payAmount}
								currency={CurrencyEnum[form.currency.split('/')[0]]}
							/>
							<Button
								className={styles.button}
								text={baseMsg.submit}
								type={ButtonType.SUBMIT}
								isLoading={isLoading}
								isDisabled={isLoading}
							/>
						</form>
					</TransfersFormLayout>
				}
				confirm={
					<WithdrawConfirm
						type={WithdrawType.TOPUP}
						currency={CurrencyEnum[form.currency.split('/')[0]]}
						formData={[
							{
								...form,
								payAmount,
								itemTitle: items?.filter(
									(o: { id: string; title: string }) =>
										o.id === form.amount.split('/')[2]
								)[0]?.title,
							},
						]}
						onSuccess={onSuccess}
						onSubmit={postData}
					/>
				}
				done={
					<WithdrawSuccess
						data={successData}
						currency={CurrencyEnum[form.currency.split('/')[0]]}
						type={WithdrawType.TOPUP}
					/>
				}
				isDone={successData.length > 0}
			/>
		</>
	);
};

export default MobileTopupsForm;
