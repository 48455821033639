import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import Loading from '../../Shared/Loading/Loading';
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import MerchantsTransactionsList from '../../MerchantsTransactions/MerchantsTransactionsList/MerchantsTransactionsList';
import MerchantsCallbacksList from '../../MerchantsCallbacks/MerchantsCallbacksList/MerchantsCallbacksList';
import CopyButton from '../../../../components/CopyButton/CopyButton';
import { useOrderStatusTranslator } from '../../../../helpers/merchantsHelper/merchantsHelper';
import LabeledField from '../../Shared/LabeledField/LabeledField';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import PageTitle from '../../Shared/PageTitle/PageTitle';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';
import NoProjects from '../../Shared/NotFound/NotFound';
import MerchantsActions from '../../../../redux/MerchantsState/MerchantsActions';
import MerchantsSelectors from '../../../../redux/MerchantsState/MerchantsSelectors';
import useMerchantRoutes, { MerchantRoutes } from '../../../../hooks/useMerchantRoutes';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';

import styles from './ViewMerchantsOrder.module.scss';
import convertToLocalTime from '../../../../helpers/dateHelper/dateHelper';
import TestIds from '../../../../test/TestIds';
import baseMsg from '../../../../messages/base.messages';
import useFormatAmount from '../../../../hooks/useFormatAmount';
import useCurrentWallet from '../../../../hooks/useCurrentWallet';
import { getAccountNetwork } from '../../../../redux/AccountsState/AccountsActions';

const Content: FC = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { getUrl, getParams } = useMerchantRoutes();
	const { orderId: id = '' } = getParams();
	const statusTranslator = useOrderStatusTranslator();
	const amountFormatter = useFormatAmount();
	const [networkPublicName, setNetworkPublicName] = useState<string | undefined>(undefined);

	const {
		orderId,
		payAmount,
		payCurrencyCode,
		depositAddress,
		receiveAmount,
		receiveCurrencyCode,
		payNetworkName,
		description,
		status,
		created,
		validUntil,
		callbackURL,
		successURL,
		failureURL,
	} = useSelector(MerchantsSelectors.orders.data.getOrderDataById(id))!;

	const currentWallet = useCurrentWallet(payCurrencyCode);

	useEffect(() => {
		if (currentWallet && !currentWallet?.networkList)
			dispatch(getAccountNetwork(currentWallet?.currencyCode, currentWallet?.id));
	}, [currentWallet, dispatch]);

	useEffect(() => {
		if (currentWallet?.networkList)
			setNetworkPublicName(
				currentWallet?.networkList?.find(
					({ networkName }) => networkName === payNetworkName
				)?.publicName
			);
	}, [currentWallet?.networkList, payNetworkName]);

	return (
		<>
			<PageTitle
				title={formatMessage(messages.orderSummary)}
				className={styles.title}
				backLink={getUrl(MerchantRoutes.ProjectView)}
			/>
			<div className={styles.contentGroup}>
				<div className={styles.content}>
					<LabeledField data-cy={TestIds.OrderViewId} label={formatMessage(messages.id)}>
						{orderId}
					</LabeledField>
					<LabeledField
						data-cy={TestIds.OrderViewPayAmount}
						label={
							<div className={styles.withCopy}>
								<span>
									<FormattedMessage {...baseMsg.payAmount} />
								</span>
								<CopyButton
									className={styles.copy}
									text={messages.copy}
									dataToCopy={payAmount}
								/>
							</div>
						}
					>{`${amountFormatter(
						formatPrecision(payAmount, payCurrencyCode)
					)} ${payCurrencyCode}`}</LabeledField>
					<LabeledField
						data-cy={TestIds.OrderViewNetwork}
						label={formatMessage(baseMsg.network)}
					>
						{networkPublicName || '-'}
					</LabeledField>
					<LabeledField
						data-cy={TestIds.OrderViewDepositAddress}
						label={formatMessage(messages.depositAddress)}
					>
						{depositAddress || '-'}
					</LabeledField>
					<LabeledField
						data-cy={TestIds.OrderViewReceiveAmount}
						label={formatMessage(baseMsg.receiveAmount)}
					>{`${amountFormatter(
						formatPrecision(receiveAmount, receiveCurrencyCode)
					)} ${receiveCurrencyCode}`}</LabeledField>
					<LabeledField
						data-cy={TestIds.OrderViewDescription}
						label={formatMessage(messages.description)}
					>
						{description || '-'}
					</LabeledField>
					<LabeledField
						data-cy={TestIds.OrderViewStatus}
						label={formatMessage(messages.status)}
					>
						{statusTranslator(status)}
					</LabeledField>
					<LabeledField
						data-cy={TestIds.OrderViewDate}
						label={formatMessage(messages.date)}
					>
						{convertToLocalTime(created, 'yyyy-MM-dd HH:mm:ss')}
					</LabeledField>
					<LabeledField
						data-cy={TestIds.OrderViewValidUntil}
						label={formatMessage(messages.validUntil)}
					>
						{validUntil ? convertToLocalTime(validUntil, 'yyyy-MM-dd HH:mm:ss') : '-'}
					</LabeledField>
					<LabeledField
						data-cy={TestIds.OrderViewCallbackUrl}
						label={formatMessage(messages.callbackUrl)}
					>
						{callbackURL || '-'}
					</LabeledField>
					<LabeledField
						data-cy={TestIds.OrderViewSuccessUrl}
						label={formatMessage(messages.successUrl)}
					>
						{successURL || '-'}
					</LabeledField>
					<LabeledField
						data-cy={TestIds.OrderViewFailureUrl}
						label={formatMessage(messages.failureUrl)}
					>
						{failureURL || '-'}
					</LabeledField>
				</div>
				<MerchantsTransactionsList />
				<MerchantsCallbacksList showRetry={!!callbackURL} />
			</div>
		</>
	);
};

const ViewMerchantsOrder: FC = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { getParams } = useMerchantRoutes();

	const { orderId = '' } = getParams();

	const { status } = useSelector(MerchantsSelectors.orders.data.getOrderById(orderId));

	useEffect(() => {
		if (status === RemoteStatus.None)
			void dispatch(MerchantsActions.orders.data.fetchOrder(orderId));
	}, [dispatch, orderId, status]);

	if (status === RemoteStatus.None) return null;

	return (
		<WhiteContainer className={styles.container} data-cy={TestIds.OrderView}>
			{status === RemoteStatus.InProgress && <Loading />}
			{status === RemoteStatus.Error && (
				<NoProjects message={formatMessage(messages.orderNotFound)} />
			)}
			{status === RemoteStatus.Done && <Content />}
		</WhiteContainer>
	);
};

export default ViewMerchantsOrder;
