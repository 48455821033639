import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, MessageDescriptor, defineMessages } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import HistoryContext from '../../contexts/HistoryContext/HistoryContext';
import styles from './PageTitle.module.scss';
import useWindowWidth from '../../hooks/useWindowWidth';
import { RootState } from '../../redux/Store';
import { setIsRemoveEnabled } from '../../redux/DepositState/DepositActions';
import HistoryExportButton from '../HistoryExportButton/HistoryExportButton';
import { HistoryType } from '../../hooks/useHistoryTableData';
import Button, { ButtonStyle, ButtonType } from '../Button/Button';
import TestIds from '../../test/TestIds';

const messages = defineMessages({
	viewHistory: {
		id: 'base.view_history',
		defaultMessage: 'View history',
	},
	removeCard: {
		id: 'linkedCards.removeCard',
		defaultMessage: 'Remove card',
	},
});

interface PageTitleProps {
	historyLink?:
		| string
		| { pathname: string; search?: string; hash?: string; state: { [key: string]: any } };
	isPreviousPageLinkVisibleOnDesktop?: boolean;
	isPreviousPageLinkVisibleOnMobile?: boolean;
	previousPageLink?: string;
	title: MessageDescriptor | string;
	moreOptions?: boolean;
	isExportEnabled?: boolean;
	historyType?: HistoryType;
	historyCount?: number;
	className?: string;
	extraActions?: React.ReactNode;
}

const PageTitle = ({
	historyLink,
	isPreviousPageLinkVisibleOnDesktop = false,
	isPreviousPageLinkVisibleOnMobile = true,
	previousPageLink,
	title,
	moreOptions = false,
	isExportEnabled = false,
	historyType,
	historyCount,
	className,
	extraActions,
}: PageTitleProps) => {
	const dispatch = useDispatch();
	const { linkedCards } = useSelector((state: RootState) => state.DepositState);
	const isMobile = useWindowWidth() < 1024;

	const closeRemoval = () => {
		return dispatch(setIsRemoveEnabled(linkedCards.isRemoveEnabled, dispatch));
	};

	return (
		<div className={classNames(styles.container, className)}>
			<div className={styles.title}>
				<div data-cy={TestIds.Title}>
					{((isMobile && previousPageLink && isPreviousPageLinkVisibleOnMobile) ||
						(isPreviousPageLinkVisibleOnDesktop && previousPageLink && !isMobile)) && (
						<Link to={previousPageLink}>
							<FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
						</Link>
					)}
					{typeof title === 'string' ? title : <FormattedMessage {...title} />}
				</div>
			</div>
			{linkedCards.isRemoveEnabled ? (
				<div className={styles.closeBtn} onClick={() => closeRemoval()}>
					<FormattedMessage id="linkedCards.cancel" defaultMessage="Cancel" />
				</div>
			) : (
				<div className={styles.topSection}>
					{historyLink && (
						<Link data-cy="history" className={styles.link} to={historyLink}>
							<FormattedMessage {...messages.viewHistory} />
						</Link>
					)}
					{isExportEnabled && (
						<HistoryContext.Provider value={{ historyType }}>
							<HistoryExportButton historyCount={historyCount!} />
						</HistoryContext.Provider>
					)}
					{moreOptions && (
						<Button
							data-cy={TestIds.removeCard}
							className={styles.removeCardButton}
							buttonStyle={ButtonStyle.LINK}
							type={ButtonType.BUTTON}
							onClick={() =>
								dispatch(setIsRemoveEnabled(linkedCards.isRemoveEnabled, dispatch))
							}
						>
							<FormattedMessage {...messages.removeCard} />
						</Button>
					)}
					{extraActions}
				</div>
			)}
		</div>
	);
};

export default PageTitle;
