import { useState } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import countries from 'i18n-iso-countries';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { CurrencyIcon, CurrencyEnum } from '@spectrocoin/sc-currencies';
import baseMsg from '../../../../messages/base.messages';
import { formatPrecision, toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import { RootState } from '../../../../redux/Store';
import { getDebitCardOrderIdURL, getOrderDebitCardURL } from '../../../../redux/endpoints';
import { Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import {
	setOrderData,
	setOrderStep,
	updateDebitCard,
} from '../../../../redux/CardsState/CardsActions';
import { CardStatusType, CardType, OrderSteps } from '../../../../redux/CardsState/CardsTypes';
import Button, { ButtonType, ButtonStyle } from '../../../../components/Button/Button';
import BackButton from '../../../../components/BackButton/BackButton';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import styles from './Confirm.module.scss';
import useFormatAmount from '../../../../hooks/useFormatAmount';
import axiosInstance from '../../../../helpers/axiosInstance';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';

const messages = defineMessages({
	error: {
		id: 'confirm.somethingWentWrong',
		defaultMessage: 'Something went wrong. Try again later.',
	},
	subTitle: {
		id: 'confirm.subTitle',
		defaultMessage: 'Double check the information before confirming the order',
	},
	cardType: {
		id: 'confirm.cardType',
		defaultMessage: 'Card type',
	},
	linkedCryptocurrencyWallet: {
		id: 'confirm.linkedCryptocurrencyWallet',
		defaultMessage: 'Linked cryptocurrency wallet',
	},
	deliverTo: {
		id: 'confirm.deliverTo',
		defaultMessage: 'Deliver to',
	},
	plasticCard: {
		id: 'confirm.plasticCard',
		defaultMessage: 'Plastic card',
	},
	virtualCard: {
		id: 'confirm.virtualCard',
		defaultMessage: 'Virtual card',
	},
	shipping: {
		id: 'confirm.shipping',
		defaultMessage: 'Shipping',
	},
	total: {
		id: 'confirm.total',
		defaultMessage: 'Total',
	},
	PLASTIC: {
		id: 'orderCard.plastic',
		defaultMessage: 'Plastic',
	},
	VIRTUAL: {
		id: 'orderCard.virtual',
		defaultMessage: 'Virtual',
	},
	plasticCryptoCard: {
		id: 'orderCard.plasticCryptoCard',
		defaultMessage: 'Plastic crypto card',
	},
	virtualCryptoCard: {
		id: 'orderCard.virtualCryptoCard',
		defaultMessage: 'Virtual crypto card',
	},
});

const CARD_LIMIT_REACHED = 'DC_19';

interface ConfirmProps {
	isRha?: boolean;
}

const Confirm = ({ isRha }: ConfirmProps) => {
	const dispatch = useDispatch();
	const { formatMessage, locale } = useIntl();
	const { push } = useHistory();
	const amountFormatter = useFormatAmount();
	const { formData, isCardUser } = useSelector((state: RootState) => state.CardsState);
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const eurWallet = wallets?.find((o: Wallet) => {
		return o.currencyCode === CurrencyEnum.EUR;
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<MessageDescriptor | null>(null);

	const {
		cardType,
		fullName,
		streetName,
		buildingNumber,
		apartmentNumber,
		city,
		postCode,
		deliveryCountry,
		shipping,
		tin,
		tinCountry,
		linkedAccounts,
		rha,
	} = formData!;

	const getCardByOrderId = (orderId: string) => {
		void axiosInstance
			.get(getDebitCardOrderIdURL(orderId))
			.then(async ({ data }: AxiosResponse) => {
				const { debitCard, status } = data;
				if (status === CardStatusType.FAILED && !debitCard) {
					setIsLoading(false);
					return dispatch(setOrderStep(OrderSteps.FAILURE));
				}
				if (status === CardStatusType.SUBMITTED || status === CardStatusType.NEW) {
					await new Promise((resolve) => {
						setTimeout(resolve, 2000);
					});
					return getCardByOrderId(orderId);
				}
				if (debitCard && status === CardStatusType.CONFIRMED) {
					dispatch(setOrderData({ pinId: debitCard.id, cardId: debitCard.cardId }));
					void updateDebitCard(debitCard.id).then(() => {
						setIsLoading(false);
						if (cardType?.type === CardType.VIRTUAL) {
							return dispatch(setOrderStep(OrderSteps.SET_PASSWORD));
						}
						return dispatch(setOrderStep(OrderSteps.SET_PIN));
					});
				}
				return null;
			});
	};

	const handleSubmit = () => {
		setIsLoading(true);
		const isPlastic = cardType?.type === CardType.PLASTIC;
		const postData = {
			accountId: eurWallet?.id,
			...(isPlastic && { deliveryApartmentNumber: apartmentNumber }),
			...(isPlastic && { deliveryBuildingNumber: buildingNumber }),
			...(isPlastic && { deliveryCity: city }),
			...(isPlastic && {
				deliveryCountry: countries.getSimpleAlpha3Code(deliveryCountry!, 'en'),
			}),
			...(isPlastic && { deliveryPostCode: postCode }),
			...(isPlastic && { deliveryStreet: streetName }),
			...(isPlastic && { deliveryType: shipping?.option }),
			...(!isCardUser && {
				taxCountry: countries
					.getSimpleAlpha3Code(countries.getName(tinCountry!, 'en'), 'en')
					.replace(', Province of China', ''),
			}),
			...(!isCardUser && { tin }),
			...(rha && {
				linkedAccounts,
				rha,
			}),
			type: cardType?.type,
		};
		return axiosInstance
			.post(getOrderDebitCardURL(), postData)
			.then(({ data }: AxiosResponse) => {
				const { id } = data;
				return getCardByOrderId(id);
			})
			.catch(({ response }) => {
				if (response?.data?.errorCode === CARD_LIMIT_REACHED) {
					return push(`/${locale}/cards/order/cards-limit-reached`);
				}
				return setError(getErrorMessageOrDefault(response?.data));
			});
	};

	const getCardTypeLabel = () => {
		if (!cardType) {
			return '';
		}

		if (isRha) {
			switch (CardType[cardType.type]) {
				case CardType.PLASTIC:
					return formatMessage(messages.plasticCryptoCard);
				case CardType.VIRTUAL:
					return formatMessage(messages.virtualCryptoCard);
				default:
					return formatMessage(messages[CardType[cardType.type]]);
			}
		}

		return formatMessage(messages[CardType[cardType.type]]);
	};

	return (
		<div className={styles.container}>
			<BackButton
				className={styles.back}
				onClick={() =>
					dispatch(
						setOrderStep(
							isRha
								? OrderSteps.LINKED_WALLETS
								: cardType?.type === CardType.PLASTIC
								? OrderSteps.SHIPPING
								: !isCardUser
								? OrderSteps.DELIVERY_ADDRESS
								: OrderSteps.TERMS
						)
					)
				}
			/>
			<InfoHead title={baseMsg.title} subTitle={formatMessage(messages.subTitle)} />
			<div className={styles.dataBox}>
				<div className={styles.label}>{formatMessage(messages.cardType)}</div>
				<div className={styles.value}>{cardType && getCardTypeLabel()}</div>
				{isRha && (
					<>
						<div className={styles.label}>
							{formatMessage(messages.linkedCryptocurrencyWallet)}
						</div>
						{linkedAccounts?.map(({ accountId }) => {
							const wallet = wallets?.find((o: Wallet) => o.id === accountId);
							if (!wallet) {
								return null;
							}

							const { currencyCode, currencyName } = wallet;
							return (
								<div
									key={accountId}
									className={classNames(styles.value, styles.linkedWalletItem)}
								>
									<CurrencyIcon
										currencyType={currencyCode}
										className={styles.currencyIcon}
									/>
									{currencyName}
								</div>
							);
						})}
					</>
				)}
				<div className={styles.label}>{formatMessage(baseMsg.currency)}</div>
				<div className={styles.value}>EUR</div>
				{cardType?.type === CardType.PLASTIC && (
					<>
						<div className={styles.label}>{formatMessage(messages.deliverTo)}</div>
						<div className={styles.value}>
							{fullName && fullName}
							<br />
							{deliveryCountry && deliveryCountry}
							<br />
							{streetName && streetName}
							<br />
							{buildingNumber && buildingNumber}
							<br />
							{apartmentNumber && (
								<>
									{apartmentNumber}
									<br />
								</>
							)}
							{postCode &&
								`${
									postCode?.search('-') > 0
										? ''
										: `${`${countries.getSimpleAlpha2Code(
												deliveryCountry!,
												'en'
										  )}-`}`
								}${postCode}`}
							<br />
							{city && city}
							<br />
							<br />
							<div className={styles.label}>{formatMessage(baseMsg.phoneNumber)}</div>
							{user?.phone && user?.phone}
						</div>
					</>
				)}
				<div className={styles.label}>
					{cardType?.type === CardType.PLASTIC
						? formatMessage(messages.plasticCard)
						: formatMessage(messages.virtualCard)}
				</div>
				<div className={styles.value}>{`${amountFormatter(
					formatPrecision(cardType?.price || 0, CurrencyEnum.EUR)
				)} EUR`}</div>
				{shipping && (
					<>
						<div className={styles.label}>{formatMessage(messages.shipping)}</div>
						<div className={styles.value}>
							{`${amountFormatter(
								formatPrecision(shipping.price, CurrencyEnum.EUR)
							)} EUR`}
						</div>
					</>
				)}
				{cardType?.type === CardType.PLASTIC && (
					<>
						<div className={styles.label}>{formatMessage(messages.total)}</div>
						<div className={styles.value}>
							{`${amountFormatter(
								formatPrecision(
									toDecimal(shipping?.price || 0)
										.add(toDecimal(cardType?.price))
										.toString(),
									CurrencyEnum.EUR
								)
							)} EUR`}
						</div>
					</>
				)}
			</div>
			<Button
				buttonStyle={ButtonStyle.PRIMARY}
				type={ButtonType.BUTTON}
				onClick={() => handleSubmit()}
				text={baseMsg.confirm}
				isLoading={isLoading}
				isDisabled={isLoading}
				className={styles.button}
			/>
			{error && <div className={styles.error}>{formatMessage(error)}</div>}
		</div>
	);
};

export default Confirm;
