import { toDecimal } from '../currencyHelper/currencyHelper';

export const validateEmail = (email: string) => {
	const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return regex.test(email);
};

export const isAmountGreaterThanZero = (amount: string | number, isInput = false): boolean =>
	toDecimal(amount.toString(), isInput).greaterThan(0);

export const isAmountGreaterThan = (
	amount: string | number,
	balance: string | number,
	isInput = false
): boolean => toDecimal(amount.toString(), isInput).greaterThan(balance.toString());

export const isAmountLessThan = (
	amount: string | number,
	balance: string | number,
	isInput = false
): boolean => toDecimal(amount.toString(), isInput).lessThan(balance.toString());

export const isIPAddressValid = (ipAddress: string): boolean => {
	const regex = /^([0-9]{1,3}\.){3}[0-9]{1,3}$/;
	return regex.test(ipAddress);
};
