import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RemoteError } from '../../../../interfaces/RemoteData';
import type { AppDispatch, RootState } from '../../../Store';
import { getMerchantsPreOrdersUrl } from '../../../endpoints';
import {
	PreordersCreateActionFulfilledAction,
	PreordersCreateResetAction,
	PreordersCreateActionPendingAction,
	PreordersCreateSetFormAction,
	PreordersCreateActionRejectedAction,
	PreordersCreateActionType,
	PreorderCreateForm,
} from './PreordersCreateTypes';
import { Preorder } from '../../MerchantTypes';
import PreordersCreateSelectors from './PreordersCreateSelectors';
import { toPlainAmount } from '../../../../helpers/currencyAmountHelper/currencyAmountHelper';
import axiosInstance from '../../../../helpers/axiosInstance';

const reset: ActionCreator<PreordersCreateResetAction> = () => ({
	type: PreordersCreateActionType.RESET,
});

const setForm: ActionCreator<PreordersCreateSetFormAction> = (
	state: Partial<PreorderCreateForm>
) => ({
	type: PreordersCreateActionType.SET_FORM,
	payload: state,
});

const setActionPending: ActionCreator<PreordersCreateActionPendingAction> = () => ({
	type: PreordersCreateActionType.ACTION_PENDING,
});

const setActionRejected: ActionCreator<PreordersCreateActionRejectedAction> = (
	error?: RemoteError
) => ({
	type: PreordersCreateActionType.ACTION_REJECTED,
	payload: error,
});

const setActionFulfilled: ActionCreator<PreordersCreateActionFulfilledAction> = (
	preorder: Preorder
) => ({
	type: PreordersCreateActionType.ACTION_FULFILLED,
	payload: preorder,
});

const createPreorder = (
	projectId: string
): ThunkAction<Promise<void>, RootState, undefined, any> => async (
	dispatch: AppDispatch,
	getState
) => {
	dispatch(setActionPending());

	const state = getState();
	const {
		price,
		currency,
		orderId,
		description,
		callbackUrl,
		failureUrl,
		successUrl,
		allowMultiplePayments,
		emailNotifications, // not implemented in BE,
	} = PreordersCreateSelectors.getCreateForm(state);

	await axiosInstance
		.post<Preorder>(getMerchantsPreOrdersUrl(), {
			receiveAmount: toPlainAmount(price, true),
			receiveCurrencyCode: currency,
			orderId,
			description,
			callbackURL: callbackUrl || null,
			successURL: successUrl || null,
			failureURL: failureUrl || null,
			isMultiple: allowMultiplePayments,
			isNotify: emailNotifications,
			merchantProjectId: projectId,
		})
		.then(({ data }) => {
			dispatch(setActionFulfilled(data));
		})
		.catch((e) => {
			dispatch(setActionRejected(e.response?.data));
		});
};

export default {
	reset,
	setForm,
	createPreorder,
};
