import { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { values } from '../helpers/objectHelper/objectHelper';

enum RouteTokens {
	Language = 'lng',
}

export enum ReferralRoutes {
	Root,
	Home,
	Terms,
	Callbacks,
	ReferralIds,
}

const ReferralRoutePaths = {
	get [ReferralRoutes.Root]() {
		return `/:${RouteTokens.Language}?/referrals`;
	},
	get [ReferralRoutes.Home]() {
		return `${ReferralRoutePaths[ReferralRoutes.Root]}`;
	},
	get [ReferralRoutes.Terms]() {
		return `${ReferralRoutePaths[ReferralRoutes.Root]}/terms`;
	},
	get [ReferralRoutes.Callbacks]() {
		return `${ReferralRoutePaths[ReferralRoutes.Root]}/callbacks`;
	},
	get [ReferralRoutes.ReferralIds]() {
		return `${ReferralRoutePaths[ReferralRoutes.Root]}/codes`;
	},
} as const;

type RouteParams = Partial<{ [key in RouteTokens]: string }>;

const useReferralRoutes = () => {
	const { params } = useRouteMatch<RouteParams>();

	const getPath = useCallback((route: ReferralRoutes) => {
		return ReferralRoutePaths[route];
	}, []);

	const getParams = useCallback(() => {
		return params;
	}, [JSON.stringify(params)]);

	const getUrl = useCallback(
		(route: ReferralRoutes, routeParams: RouteParams = {}) => {
			const mergedParams = { ...getParams(), ...routeParams };
			const path = getPath(route);
			const tokens = values(RouteTokens);
			return tokens
				.reduce((acc, token) => {
					return acc.replace(new RegExp(`:${token}\\?*`), mergedParams[token] || '');
				}, path)
				.replace(/\/\//g, '/');
		},
		[getPath, getParams]
	);

	return {
		getUrl,
		getPath,
		getParams,
	} as const;
};

export default useReferralRoutes;
