import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DeviceNameType } from '../redux/AppState/AppTypes';
import { RootState } from '../redux/Store';
import { swapSeparators } from '../helpers/currencyHelper/currencyHelper';

export default () => {
	const { deviceOS } = useSelector((state: RootState) => state.AppState);
	const isAppleOS = useMemo(() => deviceOS === DeviceNameType.IPHONE, [deviceOS]);

	return useCallback((value: string) => (isAppleOS ? swapSeparators(value) : value), [isAppleOS]);
};
