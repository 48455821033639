import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RootState } from '../../../../redux/Store';
import {
	getProductOptions,
	setRequiresVerification,
} from '../../../../redux/CardsState/CardsActions';
import { Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import { formatPrecision, toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import { useFormatRoute } from '../../../../helpers/languagePathHelper/languagePathHelper';
import { CardType, ProductOption, DeliveryType } from '../../../../redux/CardsState/CardsTypes';
import Loader from '../../../../components/Loader/Loader';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import plasticCardImgPath from './images/plastic_card.svg';
import virtualCardImgPath from './images/virtual_card.svg';
import debitCardImgPath from '../../../../images/cards/debitCardImg.svg';
import arrowsImgPath from '../../../../images/expand_triangle.svg';
import routes from '../../../../route.messages';
import styles from './OrderCard.module.scss';

const messages = defineMessages({
	PLASTIC: {
		id: 'orderCard.plasticLabel',
		defaultMessage: 'Plastic',
	},
	VIRTUAL: {
		id: 'orderCard.virtualLabel',
		defaultMessage: 'Virtual',
	},
	cryptoCard: {
		id: 'orderCard.cryptoCard',
		defaultMessage: 'Crypto card',
	},
	prepaidCard: {
		id: 'orderCard.prepaidCard',
		defaultMessage: 'Prepaid card',
	},
	virtualDelivery: {
		id: 'orderCard.virtualDelivery',
		defaultMessage: 'This card can be used instantly',
	},
	title: {
		id: 'verificationStatus.title',
		defaultMessage: 'Get SpectroCoin card',
	},
	paragraph: {
		id: 'verificationStatus.paragraph',
		defaultMessage:
			'Take your everyday payments to the next level. Exchange cryptocurrencies and spend with your Visa debit card in millions of shops around the world.',
	},
});

interface DeliveryProps {
	type: DeliveryType;
	value: string;
}

interface OptionsProps {
	type: CardType;
	rha: boolean;
	price: string;
	delivery: DeliveryProps;
}

const getTranslatedPeriod = (type: DeliveryType) => {
	switch (type) {
		case DeliveryType.DAY:
			return <FormattedMessage id="base.days" defaultMessage="days" />;
		case DeliveryType.WEEK:
			return <FormattedMessage id="base.weeks" defaultMessage="weeks" />;
		case DeliveryType.MONTH:
			return <FormattedMessage id="base.months" defaultMessage="months" />;
		default:
			return null;
	}
};

const OrderCard = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { url } = useRouteMatch();
	const { push } = useHistory();
	const { productOptions } = useSelector((state: RootState) => state.CardsState);
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const translatedLink = useFormatRoute(routes.card);

	useEffect(() => {
		if (!productOptions || productOptions?.length === 0) {
			dispatch(getProductOptions());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productOptions]);

	const selectOption = (type: CardType, rha: boolean) => {
		const eurWallet = wallets?.find((o: Wallet) => {
			return o.currencyCode === CurrencyEnum.EUR;
		});
		const selectedProduct = productOptions?.find((o: ProductOption) => o.type === type);
		dispatch(setRequiresVerification(selectedProduct?.requiresVerification ?? false));
		if (
			eurWallet &&
			selectedProduct &&
			toDecimal(selectedProduct?.price).greaterThan(toDecimal(eurWallet.availableBalance))
		) {
			return push(`${url}/not-enough-funds`);
		}

		let cardTypePath;

		if (type === CardType.PLASTIC) {
			cardTypePath = rha ? 'rhaPlastic' : 'plastic';
		} else {
			cardTypePath = rha ? 'rhaVirtual' : 'virtual';
		}

		return push(`${url}/${cardTypePath}`);
	};

	return (
		<div className={styles.container}>
			<InfoHead title={messages.title} text={messages.paragraph} img={debitCardImgPath} />
			{!productOptions || productOptions?.length === 0 ? (
				<Loader />
			) : (
				<ul className={styles.cardTypesList}>
					{productOptions
						?.sort((a, b) => {
							if (a.rha !== b.rha) {
								return a.rha ? -1 : 1;
							}
							return a.type > b.type ? 1 : -1;
						})
						.map((item: OptionsProps) => {
							const { type, rha, price, delivery } = item;
							return (
								<li key={type}>
									<a onClick={() => selectOption(type, rha)}>
										<img
											className={styles.cardImg}
											src={
												type === CardType.PLASTIC
													? plasticCardImgPath
													: virtualCardImgPath
											}
											alt={`${type} card icon`}
										/>
										<span className={styles.textBlock}>
											<span className={styles.type}>
												{rha
													? formatMessage(messages.cryptoCard)
													: formatMessage(messages.prepaidCard)}
												<span className={styles.label}>
													{formatMessage(messages[type])}
												</span>
											</span>
											<span className={styles.price}>
												<FormattedMessage
													id="orderCard.price"
													defaultMessage="<b>Card price:</b> {price} EUR"
													values={{
														b: (text) => <b>{text}</b>,
														price: formatPrecision(
															price,
															CurrencyEnum.EUR
														),
													}}
												/>
											</span>
											<span className={styles.delivery}>
												{delivery.type === DeliveryType.INSTANT ? (
													formatMessage(messages.virtualDelivery)
												) : (
													<FormattedMessage
														id="orderCard.plasticDelivery"
														defaultMessage="The delivery of the card can take {number} {period}"
														values={{
															number: delivery.value,
															period: getTranslatedPeriod(
																delivery?.type
															),
														}}
													/>
												)}
											</span>
										</span>
										<img
											className={styles.arrow}
											src={arrowsImgPath}
											alt="Arrow icon"
										/>
									</a>
								</li>
							);
						})}
				</ul>
			)}
			<p className={styles.infoText}>
				<FormattedMessage
					id="orderCard.infoText"
					defaultMessage="Learn more about our <a>Fees and limits</a>"
					values={{
						a: (text) => (
							<a href={translatedLink} target="_blank" rel="noopener noreferrer">
								{text}
							</a>
						),
					}}
				/>
			</p>
		</div>
	);
};

export default OrderCard;
