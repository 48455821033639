import classNames from 'classnames';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import { CurrencyIcon, CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RatesDetailsResponse } from '../../redux/RatesState/RatesTypes';
import { RootState } from '../../redux/Store';
import { Wallet } from '../../redux/AccountsState/AccountsTypes';
import sortArrowsImgPath from '../../images/sort_arrows.svg';
import { toDecimal, formatPrecision } from '../../helpers/currencyHelper/currencyHelper';
import styles from './ScrollableWalletList.module.scss';
import TestIds, { formatTestId } from '../../test/TestIds';
import useFormatAmount from '../../hooks/useFormatAmount';

const messages = defineMessages({
	name: {
		id: 'walletBlock.name',
		defaultMessage: 'Name',
	},
	balance: {
		id: 'walletBlock.balance',
		defaultMessage: 'Balance',
	},
});

export enum SortType {
	NAME = 'NAME',
	BALANCE = 'BALANCE',
}

export enum ListType {
	DEPOSIT = 'DEPOSIT',
	WITHDRAW = 'WITHDRAW',
}

interface ScrollableWalletListProps {
	changeSortType: any;
	list: Wallet[];
	type: ListType;
}

const ScrollableWalletList = ({ changeSortType, list, type }: ScrollableWalletListProps) => {
	const { referenceCurrency } = useSelector((state: RootState) => state.AppState);

	return (
		<>
			<div className={styles.sortRow}>
				<span
					onClick={() => changeSortType(SortType.NAME)}
					className={styles.sortText}
					data-cy={TestIds.SortByName}
				>
					<FormattedMessage {...messages.name} />
					<img src={sortArrowsImgPath} alt="" />
				</span>
				<span
					onClick={() => changeSortType(SortType.BALANCE)}
					className={styles.sortText}
					data-cy={TestIds.SortByBalance}
				>
					<FormattedMessage {...messages.balance} />
					<img src={sortArrowsImgPath} alt="" />
				</span>
			</div>
			<ul
				className={classNames(styles.list, {
					[styles.even]: list.length % 2 === 0,
				})}
			>
				{list.map((account: Wallet) => (
					<CryptoCurrencyItem
						key={account.id}
						type={type}
						refCurrency={referenceCurrency ?? 'EUR'}
						{...account}
					/>
				))}
			</ul>
		</>
	);
};

interface CryptoCurrencyItemProps {
	currencyName: string;
	currencyCode: string;
	availableBalance: string;
	refCurrency: string;
	id: string;
	type: ListType;
}

const CryptoCurrencyItem = ({
	currencyName,
	currencyCode,
	availableBalance,
	refCurrency,
	id,
	type,
}: CryptoCurrencyItemProps) => {
	const { locale } = useIntl();
	const rates = useSelector((state: RootState) => state?.RatesState?.ratesDetails);
	const amountFormatter = useFormatAmount();
	const { isLoading } = useSelector((state: RootState) => state?.RatesState);

	const refCurrencyRates = rates?.find(
		(o: RatesDetailsResponse) => o.currencyCode === refCurrency
	);
	const currentWalletRate = rates?.find(
		(o: RatesDetailsResponse) => o.currencyCode === currencyCode
	);

	return (
		<li className={styles.listItem}>
			<Link
				data-cy={formatTestId(TestIds.cryptoDepositOption, currencyCode)}
				className={styles.link}
				to={`/${locale}/${type.toLowerCase()}/crypto/${currencyCode}/${id}`}
			>
				<CurrencyIcon className={styles.icon} currencyType={CurrencyEnum[currencyCode]} />
				<div className={styles.infoRow}>
					<div
						className={styles.text}
						data-cy={TestIds.cryptoCurrencyName}
					>{`${currencyName}`}</div>
					<div className={styles.balancesRow} data-cy={TestIds.cryptoCurrencyBalanceRow}>
						<div
							data-cy={formatTestId(
								TestIds.cryptoCurrencyAvailableBalance,
								currencyCode
							)}
						>
							{amountFormatter(
								formatPrecision(availableBalance, CurrencyEnum[currencyCode])
							)}{' '}
							{currencyCode}
						</div>
						{currencyCode !== refCurrency && (
							<div
								className={styles.referenceBalance}
								data-cy={formatTestId(TestIds.cryptoCurrencyBalance, currencyCode)}
							>
								{isLoading
									? '--'
									: amountFormatter(
											formatPrecision(
												toDecimal(availableBalance)
													.times(toDecimal(currentWalletRate!.value))
													.toFixed(refCurrencyRates?.scale)
													.toString(),
												CurrencyEnum[refCurrency]
											)
									  )}{' '}
								{refCurrency}
							</div>
						)}
					</div>
				</div>
				<FontAwesomeIcon className={styles.chevronRight} icon={faChevronRight} />
			</Link>
		</li>
	);
};

export default ScrollableWalletList;
