import { AxiosError } from 'axios';
import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RemoteError } from '../../../../interfaces/RemoteData';
import type { RootState } from '../../../Store';
import PageableResponse from '../../../../interfaces/PageableResponse';
import { getMerchantsProjectsUrl, getMerchantsProjectUrl } from '../../../endpoints';
import {
	ProjectsListFetchProjectsFulfilledAction,
	ProjectsListFetchProjectsPendingAction,
	ProjectsListFetchProjectsRejectedAction,
	ProjectsListActionType,
	ProjectsListState,
	ProjectsListMode,
	ProjectsListStatus,
} from './ProjectsListTypes';
import { values } from '../../../../helpers/objectHelper/objectHelper';
import { getFilter } from './ProjectsListSelectors';
import { Project } from '../ProjectsTypes';
import axiosInstance from '../../../../helpers/axiosInstance';

const fetchProjectsPending: ActionCreator<ProjectsListFetchProjectsPendingAction> = (
	page: number
) => ({
	type: ProjectsListActionType.FETCH_PROJECTS_PENDING,
	payload: page,
});

const fetchProjectsRejected: ActionCreator<ProjectsListFetchProjectsRejectedAction> = (
	page: number,
	error?: RemoteError
) => ({
	type: ProjectsListActionType.FETCH_PROJECTS_REJECTED,
	payload: {
		page,
		error,
	},
});

const fetchProjectsFulfilled: ActionCreator<ProjectsListFetchProjectsFulfilledAction> = (
	page: number,
	projects: PageableResponse<Project[]>
) => ({
	type: ProjectsListActionType.FETCH_PROJECTS_FULFILLED,
	payload: {
		page,
		projects,
	},
});

const fetchProjects = (
	page: number,
	reset = false
): ThunkAction<Promise<PageableResponse<Project[]> | void>, RootState, undefined, any> => async (
	dispatch,
	getState
) => {
	dispatch(fetchProjectsPending(page));

	const state = getState();
	const { search, tags } = getFilter(state);

	const appendTags = values(tags).some((x) => x);

	const query = {
		page,
		name: search || undefined,
		...(appendTags
			? {
					enabled: tags.enabled === tags.disabled ? undefined : tags.enabled,
					virtual: tags.crypto === tags.fiat ? undefined : tags.crypto,
			  }
			: {}),
	};

	return axiosInstance
		.get<PageableResponse<Project[]>>(getMerchantsProjectsUrl(), {
			params: {
				...query,
				sort: 'created,desc',
				size: 10,
			},
		})
		.then(({ data }) => {
			if (reset) dispatch(resetPages());
			dispatch(fetchProjectsFulfilled(page, data));
			return data;
		})
		.catch((e: AxiosError) => {
			dispatch(fetchProjectsRejected(page, e.response?.data));
		});
};

const setStatus = (status: ProjectsListStatus) => ({
	type: ProjectsListActionType.SET_STATUS,
	payload: status,
});

const setTags = (tags: Partial<ProjectsListState['filter']['tags']>) => ({
	type: ProjectsListActionType.SET_TAGS,
	payload: tags,
});

const setSearch = (search: string) => ({
	type: ProjectsListActionType.SET_SEARCH,
	payload: search,
});

const setMode = (mode: ProjectsListMode) => ({
	type: ProjectsListActionType.SET_MODE,
	payload: mode,
});

const resetPages = () => ({
	type: ProjectsListActionType.RESET_PAGES,
});

const resetDelete = () => ({
	type: ProjectsListActionType.RESET_DELETE,
});

const deletePending = () => ({
	type: ProjectsListActionType.DELETE_PENDING,
});

const deleteRejected = (error?: RemoteError) => ({
	type: ProjectsListActionType.DELETE_REJECTED,
	payload: error,
});

const deleteFulfilled = (id: string) => ({
	type: ProjectsListActionType.DELETE_FULFILLED,
	payload: id,
});

const deleteProject = (
	id: string,
	page: number
): ThunkAction<Promise<void>, RootState, undefined, any> => async (dispatch) => {
	dispatch(deletePending());

	await axiosInstance
		.delete<PageableResponse<Project[]>>(getMerchantsProjectUrl(id))
		.then(() => {
			void dispatch(deleteFulfilled(id));
			void dispatch(fetchProjects(page));
		})
		.catch((e) => {
			dispatch(deleteRejected(e.response?.data));
		});
};

export default {
	fetchProjects,
	resetPages,
	setStatus,
	setSearch,
	setTags,
	setMode,
	deleteProject,
	resetDelete,
};
