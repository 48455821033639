import { useCallback, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from 'react-router';
import styles from './CardDeposits.module.scss';
import PaymentMethod, {
	LinkedCard,
	LinkedCardState,
	PaymentMethodGroup,
	ProviderType,
} from '../../../redux/DepositState/DepositTypes';
import { getCardDepositMethodsAndCount, getCardLogo } from './helpers';
import { RootState } from '../../../redux/Store';
import { companyAndPreviousPageHandler } from '../../../helpers/storageHelper/storageHelper';
import { UserPreviousPageForCompanyMovement } from '../../../enums/UserContractCompanyEnum';
import Loader from '../../../components/Loader/Loader';
import depositMessages from '../../../redux/DepositState/DepositMessages';
import PageTitle from '../../../components/PageTitle/PageTitle';
import {
	fetchLinkedCards,
	fetchLinkedCardStatus,
	fetchPaymentMethodsByGroup,
} from '../../../redux/DepositState/DepositActions';
import Seo from '../../../components/Seo/Seo';
import TestIds from '../../../test/TestIds';
import config from '../../../configs/config';
import { resolveUserCompany } from '../../../helpers/companyHelper/companyHelper';
import ListItem, { ListItemType } from '../../../components/ListItem/ListItem';
import { UserCompany } from '../../../redux/ProfileState/ProfileTypes';

const messages = defineMessages({
	verificationNeeded: {
		id: 'base.verification_needed',
		defaultMessage: 'Verification required',
	},
	activationNeeded: {
		id: 'base.activation_needed',
		defaultMessage: 'Activation required',
	},
	unavailableCards: {
		id: 'linkedcards.unavailable_cards',
		defaultMessage: 'Unavailable cards',
	},
	contactSupport: {
		id: 'walletdeposit.contact_support_to_proceed',
		defaultMessage: 'Contact our support team to proceed',
	},
	companyMovementNeeded: {
		id: 'walletdeposit.account_verification_needed',
		defaultMessage: 'Account verification required',
	},
	unexpectedError: {
		id: 'scvalidationexception.validate.unexpected_error.key',
		defaultMessage: 'Unexpected error occurred.',
	},
	linkNewCard: {
		id: 'linkedcards.deposit_new_card',
		defaultMessage: 'Deposit with new card',
	},
	ecommpay_google_pay: {
		id: 'linkedcards.deposit_google_pay',
		defaultMessage: 'Deposit from your card with Google Pay',
	},
	ecommpay_apple_pay: {
		id: 'linkedcards.deposit_apple_pay',
		defaultMessage: 'Deposit from your card with Apple Pay',
	},
});

export interface CardAndMethod {
	linkedCard: LinkedCard;
	linkedCardState: LinkedCardState;
	paymentMethod: PaymentMethod;
}

const CardDeposits = () => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();

	const [availableCards, setAvailableCards] = useState<CardAndMethod[]>([]);
	const [unavailableCards, setUnavailableCards] = useState<CardAndMethod[]>([]);
	const [isAddCardVisible, setIsAddCardVisible] = useState<boolean>(false);
	const [isAddCardEnabled, setIsAddCardEnabled] = useState<boolean>(false);
	const [isDataUpdateCalled, setIsDataUpdateCalled] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [isRequestPending, setIsRequestPending] = useState(false);
	const [possiblePaymentMethods, setPossiblePaymentMethods] = useState<PaymentMethod[]>([]);
	const { locale, formatMessage } = useIntl();

	const { user: profile, company } = useSelector((state: RootState) => state.ProfileState);
	const {
		linkedCards,
		paymentMethods: { card: cardPaymentMethods },
	} = useSelector((state: RootState) => state.DepositState);

	useEffect(() => {
		if (
			profile &&
			linkedCards.isLoaded &&
			linkedCards.status.isLoaded &&
			cardPaymentMethods.isLoaded &&
			isDataUpdateCalled
		) {
			const cardCountLimitExceeded = !!linkedCards.status.data?.limitExceeded;
			const enabledOrPossibleToEnablePaymentMethods = cardPaymentMethods.data.filter(
				(method) =>
					method.providerType !== ProviderType.ECOMMPAY &&
					(method.isEnabled || method.isEnabledForVerified)
			);

			const methodsUsableWithoutCompanyMovement = enabledOrPossibleToEnablePaymentMethods.filter(
				(method) => !method.companyMovementNeeded
			);
			const addCardVisible =
				enabledOrPossibleToEnablePaymentMethods.length > 0 && !cardCountLimitExceeded;

			const { available, unavailable } = getCardDepositMethodsAndCount(
				linkedCards.data,
				cardPaymentMethods.data,
				!!profile?.verified,
				addCardVisible
			);

			setAvailableCards(available);
			setUnavailableCards(unavailable);
			setIsAddCardVisible(addCardVisible);
			setIsAddCardEnabled(!!methodsUsableWithoutCompanyMovement.length);
			setPossiblePaymentMethods(enabledOrPossibleToEnablePaymentMethods);

			setIsLoading(false);
		}
	}, [linkedCards, cardPaymentMethods, profile, isLoading, isDataUpdateCalled]);

	const fetchCardsData = useCallback(() => {
		setIsLoading(true);
		dispatch(fetchPaymentMethodsByGroup(PaymentMethodGroup.CARD));
		dispatch(fetchLinkedCards());
		dispatch(fetchLinkedCardStatus());
		return setIsDataUpdateCalled(true);
	}, [dispatch]);

	useEffect(() => {
		if (!isDataUpdateCalled) {
			fetchCardsData();
		}
	}, [isDataUpdateCalled, fetchCardsData]);

	// Set up KYC verification using their helper
	const handleUserCompanyChangeNeededMethodClick = (
		event: React.MouseEvent<HTMLDivElement>,
		card: CardAndMethod | null,
		paymentMethod?: PaymentMethod
	) => {
		event.preventDefault();
		if (isRequestPending) return;
		if (company === UserCompany.LT) return;

		setIsRequestPending(true);
		companyAndPreviousPageHandler({
			toCompany: card?.paymentMethod?.toCompany || paymentMethod?.toCompany || null,
			previousPage: UserPreviousPageForCompanyMovement.PAYMENT_METHOD,
		});
		window.location.href = `${
			config.PROFILE_DOMAIN
		}/${locale}/verification/verify/${resolveUserCompany(
			card?.paymentMethod?.toCompany || paymentMethod?.toCompany
		)}`;
	};

	const getDepositListItemType = (card: CardAndMethod) => {
		switch (card.linkedCardState) {
			case LinkedCardState.USER_VERIFICATION_NEEDED:
			case LinkedCardState.COMPANY_MOVEMENT_NEEDED:
				return ListItemType.CLICK_HANDLER;
			case LinkedCardState.VERIFICATION_NEEDED:
				return ListItemType.ANCHOR_LINK;
			default:
				return ListItemType.LINK;
		}
	};

	const getUnavailableCardOnClick = (card: CardAndMethod) => {
		switch (card.linkedCardState) {
			case LinkedCardState.USER_VERIFICATION_NEEDED:
			case LinkedCardState.COMPANY_MOVEMENT_NEEDED:
				return (event: React.MouseEvent<HTMLDivElement>) =>
					handleUserCompanyChangeNeededMethodClick(event, card);
			default:
				return undefined;
		}
	};

	const ecomPayMethods = cardPaymentMethods?.data.filter(
		(x) => x.providerType === ProviderType.ECOMMPAY && (x.isEnabled || x.isEnabledForVerified)
	);

	if (isLoading) return <Loader />;

	return (
		<div data-cy={TestIds.cardDepositsView}>
			<Seo title={depositMessages.metaCardDepositsTitle} />
			<PageTitle
				historyLink={{
					pathname: `/${locale}/deposit/history/fiat`,
					state: { backUrl: pathname },
				}}
				previousPageLink={`/${locale}/deposit`}
				title={depositMessages.cardDeposits}
				moreOptions={linkedCards?.data?.length > 0}
				isPreviousPageLinkVisibleOnMobile={false}
			/>
			<div className={styles.listContainer}>
				{(availableCards.length > 0 || isAddCardVisible) && (
					<ul className={styles.list} data-cy={TestIds.availableCards}>
						{availableCards.map(({ linkedCard, paymentMethod }) => {
							return (
								<ListItem
									status={linkedCard.status}
									key={linkedCard.id}
									id={linkedCard.id}
									duration={paymentMethod.duration}
									disabled={false}
									limits={{
										fee: paymentMethod.fee,
										minDepositAmount: paymentMethod.minAmount,
										maxDepositAmount: paymentMethod.maxAmount,
										minFeeAmount: paymentMethod.minFee,
										maxFeeAmount: paymentMethod.maxFee,
										baseCurrencyCode: paymentMethod.baseCurrencyCode,
									}}
									link={`/${locale}/deposit/card/${linkedCard.id}`}
									paymentMethodCurrencies={paymentMethod.paymentMethodCurrencies}
									title={linkedCard.visibleCardNumber}
									logo={getCardLogo(linkedCard.cardBrand)}
									isRemovalEnabled={linkedCards.isRemoveEnabled}
									fetchData={setIsDataUpdateCalled}
									withDescription
								/>
							);
						})}
						{ecomPayMethods.map(
							(
								{
									id,
									logo,
									isEnabled,
									companyMovementNeeded,
									key,
									fee,
									minAmount,
									maxAmount,
									minFee,
									maxFee,
									baseCurrencyCode,
									providerType,
									paymentMethodCurrencies,
									duration,
								},
								index
							) => (
								<ListItem
									logo={logo}
									disabled={!isEnabled && companyMovementNeeded}
									clickableWhenDisabled
									withDescription
									id={id}
									title={formatMessage(messages[key])}
									key={key}
									limits={{
										fee,
										minDepositAmount: minAmount,
										maxDepositAmount: maxAmount,
										minFeeAmount: minFee,
										maxFeeAmount: maxFee,
										baseCurrencyCode,
									}}
									providerType={providerType}
									type={
										companyMovementNeeded
											? ListItemType.CLICK_HANDLER
											: ListItemType.LINK
									}
									onClick={
										companyMovementNeeded
											? (e) => {
													handleUserCompanyChangeNeededMethodClick(
														e,
														null,
														ecomPayMethods[index]
													);
											  }
											: undefined
									}
									link={
										companyMovementNeeded
											? undefined
											: `/${locale}/deposit/ecommpay/${id}`
									}
									paymentMethodCurrencies={paymentMethodCurrencies}
									duration={duration}
								/>
							)
						)}
						{isAddCardVisible && (
							<ListItem
								duration={null}
								status={null}
								disabled={!isAddCardEnabled}
								clickableWhenDisabled
								key="add_new_card"
								limits={{}}
								type={
									!isAddCardEnabled
										? ListItemType.CLICK_HANDLER
										: ListItemType.LINK
								}
								onClick={
									!isAddCardEnabled
										? (e) => {
												handleUserCompanyChangeNeededMethodClick(
													e,
													null,
													possiblePaymentMethods[0]
												);
										  }
										: undefined
								}
								link={!isAddCardEnabled ? undefined : `/${locale}/deposit/new-card`}
								logo="mastercard_and_visa"
								title={formatMessage(messages.linkNewCard)}
								isRemovalEnabled={linkedCards.isRemoveEnabled}
								fetchData={setIsDataUpdateCalled}
							/>
						)}
					</ul>
				)}
				{unavailableCards.length > 0 && (
					<>
						<h2 className={styles.heading}>
							<FormattedMessage {...messages.unavailableCards} />
						</h2>
						<ul className={styles.list} data-cy={TestIds.unavailableCards}>
							{unavailableCards.map((card) => {
								const { linkedCard, paymentMethod, linkedCardState } = card;
								return (
									<ListItem
										duration={paymentMethod.duration}
										disabled
										status={linkedCard.status}
										id={linkedCard.id}
										key={linkedCard.id}
										clickableWhenDisabled={
											linkedCardState !== LinkedCardState.FAILED &&
											linkedCardState !== LinkedCardState.CONTACT_SUPPORT
										}
										limits={{
											minDepositAmount: paymentMethod.minAmount,
											maxDepositAmount: paymentMethod.maxAmount,
											minFeeAmount: paymentMethod.minFee,
											maxFeeAmount: paymentMethod.maxFee,
											baseCurrencyCode: paymentMethod.baseCurrencyCode,
										}}
										link={undefined}
										onClick={
											getDepositListItemType(card) ===
											ListItemType.CLICK_HANDLER
												? getUnavailableCardOnClick(card)
												: undefined
										}
										paymentMethodCurrencies={
											paymentMethod.paymentMethodCurrencies
										}
										withDescription
										title={linkedCard.visibleCardNumber}
										type={getDepositListItemType(card)}
										logo={getCardLogo(linkedCard.cardBrand)}
										isRemovalEnabled={linkedCards.isRemoveEnabled}
										fetchData={setIsDataUpdateCalled}
									/>
								);
							})}
						</ul>
					</>
				)}
				{isRequestPending && (
					<Loader className={styles.transparentLoader} data-cy={TestIds.cardsLoader} />
				)}
			</div>
		</div>
	);
};

export default CardDeposits;
