import { useCallback, useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import queryString, { ParsedQuery } from 'query-string';
import { useLocation, useParams } from 'react-router';
import { defineMessages, useIntl } from 'react-intl';
import { format } from 'date-fns';
import HistoryContext, {
	DefaultHistoryContextData,
} from '../../contexts/HistoryContext/HistoryContext';
import styles from './HistoryExportButton.module.scss';
import { HistoryType } from '../../hooks/useHistoryTableData';
import { ExportType } from '../../redux/WalletHistoryState/WalletHistoryTypes';
import pdfImgPath from './images/pdf.svg';
import csvImgPath from './images/xsls.svg';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import {
	getAccountHistoryExportURL,
	getCryptoWithdrawExportURL,
	getDebitCardHistoryPDFURL,
	getDepositHistoryExportURL,
} from '../../redux/endpoints';
import { RemoteData, RemoteStatus } from '../../interfaces/RemoteData';
import WalletTestIds from '../../test/Wallets/WalletTestIds';
import axiosInstance from '../../helpers/axiosInstance';

const messages = defineMessages({
	export: {
		id: 'historyBlock.export',
		defaultMessage: 'Export',
	},
	pdf: {
		id: 'historyBlock.pdf',
		defaultMessage: 'PDF',
	},
	csv: {
		id: 'historyBlock.csv',
		defaultMessage: 'CSV',
	},
	exportCards: {
		id: 'export.cards',
		defaultMessage: 'cards',
	},
	exportDeposits: {
		id: 'export.deposits',
		defaultMessage: 'deposits',
	},
	exportFiatDeposits: {
		id: 'export.fiat_deposits',
		defaultMessage: 'fiat_deposits',
	},
	exportWallets: {
		id: 'export.wallets',
		defaultMessage: 'wallets',
	},
	exportWithdraws: {
		id: 'export.withdraws',
		defaultMessage: 'withdraws',
	},
});

export type ExportOptionConfig = {
	type: ExportType;
	url: string;
	filename: string;
	icon?: string;
	alt?: string;
	label?: string;
};

const useDefaultUrlExportOptions = (): Array<ExportOptionConfig> => {
	const { id, historyType } = useContext(HistoryContext) as DefaultHistoryContextData;
	const urlParams = useParams<any>();
	const { search } = useLocation();
	const { formatMessage } = useIntl();

	const parsedQuery: ParsedQuery<any> = queryString.parse(search);
	const { startDate, endDate, type, currencyCode } = parsedQuery;
	const generationTime = format(new Date(), `yyyy-MM-dd'T'HH:mm`);
	const filenameDateBlock = `${format(new Date(startDate || 0), 'yyyy-MM-dd')}-${format(
		endDate ? new Date(endDate) : new Date(),
		'yyyy-MM-dd'
	)}_${generationTime}`;

	switch (historyType) {
		case HistoryType.CARD:
			return [
				{
					type: ExportType.PDF,
					url: `${getDebitCardHistoryPDFURL(id!, startDate, endDate)}`,
					filename: `${formatMessage(messages.exportCards)}_${filenameDateBlock}.pdf`,
				},
			];
		case HistoryType.CRYPTO:
			return [
				{
					type: ExportType.PDF,
					url: `${getDepositHistoryExportURL(
						ExportType.PDF,
						generationTime,
						startDate,
						endDate,
						currencyCode
					)}`,
					filename: `${formatMessage(messages.exportDeposits)}_${filenameDateBlock}.pdf`,
				},
				{
					type: ExportType.CSV,
					url: `${getDepositHistoryExportURL(
						ExportType.CSV,
						generationTime,
						startDate,
						endDate,
						currencyCode
					)}`,
					filename: `${formatMessage(messages.exportDeposits)}_${filenameDateBlock}.csv`,
				},
			];

		case HistoryType.FIAT:
			return [
				{
					type: ExportType.FIAT_PDF,
					url: `${getDepositHistoryExportURL(
						ExportType.FIAT_PDF,
						generationTime,
						startDate,
						endDate
					)}`,
					filename: `${formatMessage(
						messages.exportFiatDeposits
					)}_${filenameDateBlock}.pdf`,
				},
				{
					type: ExportType.FIAT_CSV,
					url: `${getDepositHistoryExportURL(
						ExportType.FIAT_CSV,
						generationTime,
						startDate,
						endDate
					)}`,
					filename: `${formatMessage(
						messages.exportFiatDeposits
					)}_${filenameDateBlock}.csv`,
				},
			];

		case HistoryType.WALLET:
			return [
				{
					type: ExportType.PDF,
					url: `${getAccountHistoryExportURL(
						ExportType.PDF,
						id!,
						generationTime,
						startDate,
						endDate
					)}`,
					icon: pdfImgPath,
					alt: 'PDF Export',
					label: formatMessage(messages.pdf),
					filename: `${formatMessage(messages.exportWallets)}_${filenameDateBlock}.pdf`,
				},
				{
					type: ExportType.CSV,
					url: `${getAccountHistoryExportURL(
						ExportType.CSV,
						id!,
						generationTime,
						startDate,
						endDate
					)}`,
					icon: csvImgPath,
					alt: 'CSV Export',
					label: formatMessage(messages.csv),
					filename: `${formatMessage(messages.exportWallets)}_${filenameDateBlock}.csv`,
				},
			];

		case HistoryType.CRYPTO_WITHDRAW:
		case HistoryType.VOUCHERS_WITHDRAW:
		case HistoryType.BANK_WITHDRAW:
		case HistoryType['GIFT-CARDS_WITHDRAW']:
		case HistoryType['MOBILE-TOP-UPS_WITHDRAW']:
		case HistoryType['E-WALLET_WITHDRAW']:
			return [
				{
					type: ExportType.PDF,
					url: `${getCryptoWithdrawExportURL(
						historyType,
						generationTime,
						type,
						urlParams.filter,
						startDate,
						endDate,
						ExportType.PDF
					)}`,
					icon: pdfImgPath,
					alt: 'PDF Export',
					label: formatMessage(messages.pdf),
					filename: `${formatMessage(messages.exportWithdraws)}_${filenameDateBlock}.pdf`,
				},
				{
					type: ExportType.CSV,
					url: `${getCryptoWithdrawExportURL(
						historyType,
						generationTime,
						type,
						urlParams.filter,
						startDate,
						endDate,
						ExportType.CSV
					)}`,
					icon: csvImgPath,
					alt: 'CSV Export',
					label: formatMessage(messages.csv),
					filename: `${formatMessage(messages.exportWithdraws)}_${filenameDateBlock}.csv`,
				},
			];

		default:
			return [];
	}
};

export type ExportOptionsProvider = typeof useDefaultUrlExportOptions;

interface HistoryExportButtonProps {
	historyCount: number;
	exportOptionProvider?: ExportOptionsProvider;
}

const HistoryExportButton = ({
	historyCount,
	exportOptionProvider = useDefaultUrlExportOptions,
}: HistoryExportButtonProps) => {
	const { formatMessage } = useIntl();

	const exportOptionsRef = useRef<HTMLUListElement>(null);

	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	useOnClickOutside(exportOptionsRef, () => setIsMenuOpen(false));

	const exportUrls = exportOptionProvider();

	const [action, setAction] = useState<RemoteData<void>>({ status: RemoteStatus.None });

	const exporter = useCallback((url: string, filename: string) => {
		setAction({ status: RemoteStatus.InProgress });
		axiosInstance({ url, method: 'GET', responseType: 'blob' })
			.then(({ data }) => {
				const downloadUrl = URL.createObjectURL(new Blob([data]));
				const link = document.createElement('a');
				link.href = downloadUrl;
				link.setAttribute('download', filename);
				document.body.appendChild(link);
				link.click();
				link.remove();
				setAction({ status: RemoteStatus.Done });
			})
			.catch(() => {
				setAction({ status: RemoteStatus.Error });
			});
	}, []);

	const withDefaults = (config: ExportOptionConfig): ExportOptionConfig => {
		function getDefaults() {
			switch (config.type) {
				case ExportType.CSV:
				case ExportType.FIAT_CSV:
					return {
						icon: csvImgPath,
						label: formatMessage(messages.csv),
						alt: 'CSV Export',
					};
				case ExportType.PDF:
				case ExportType.FIAT_PDF:
					return {
						icon: pdfImgPath,
						label: formatMessage(messages.pdf),
						alt: 'PDF Export',
					};
				default:
					return {};
			}
		}

		return {
			...getDefaults(),
			...config,
		};
	};

	const options = exportUrls.map(withDefaults).map(({ url, icon, alt, label, filename }) => (
		<li key={url}>
			<a
				onClick={() => exporter(url, filename)}
				className={classNames({
					[styles.disabled]: action.status === RemoteStatus.InProgress,
				})}
			>
				<img src={icon} alt={alt} /> {label}
			</a>
		</li>
	));

	if (options.length === 0) return null;

	return (
		<>
			<a
				data-cy={WalletTestIds.ExportButton}
				className={classNames(styles.export, {
					[styles.disabled]: historyCount === 0,
				})}
				onClick={() => setIsMenuOpen(!isMenuOpen)}
			>
				{formatMessage(messages.export)}
			</a>
			{isMenuOpen && (
				<ul
					data-cy={WalletTestIds.OptionsList}
					className={styles.options}
					ref={exportOptionsRef}
				>
					{options}
				</ul>
			)}
		</>
	);
};

export default HistoryExportButton;
