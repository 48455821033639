import { FC, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { UserType } from '../../redux/ProfileState/ProfileTypes';
import { useFormatRoute } from '../../helpers/languagePathHelper/languagePathHelper';
import routes from '../../route.messages';
import Button, { ButtonStyle, ButtonType } from '../Button/Button';
import { RootState } from '../../redux/Store';
import styles from './StartingBlock.module.scss';
import welcomeImg from './images/welcome_img.png';
import ibanImg from './images/iban_img.png';
import useEffectOnce from '../../hooks/useEffectOnce';
import { IBANStatus } from '../../redux/AccountsState/AccountsTypes';
import TestIds from '../../test/TestIds';
import useIBANRedirect from '../../hooks/useIBANRedirect';

const messages = defineMessages({
	WELCOME_TITLE: {
		id: 'welcomeBlock.title',
		defaultMessage: 'Welcome to SpectroCoin',
	},
	WELCOME_PARAGRAPH: {
		id: 'welcomeBlock.paragraph',
		defaultMessage:
			'To start your journey at SpectroCoin you can deposit Bitcoin, Ethereum and other crypto or fiat currencies and start using your own SpectroCoin wallets.',
	},
	WELCOME_BUTTON: {
		id: 'balanceBlock.deposit',
		defaultMessage: 'Deposit',
	},
	IBAN_TITLE: {
		id: 'ibanBlock.title',
		defaultMessage: 'Get a free personal IBAN at SpectroCoin',
	},
	IBAN_PARAGRAPH: {
		id: 'ibanBlock.paragraph',
		defaultMessage:
			'Send and receive money to/from your friends, partners and customers directly to/from your EUR wallet at SpectroCoin. For more information about a personal IBAN at SpectroCoin <FaqLink>click here.</FaqLink>',
	},
	IBAN_BUTTON: {
		id: 'ibanBlock.claim',
		defaultMessage: 'Claim IBAN',
	},
});

export enum BlockTypes {
	WELCOME = 'WELCOME',
	IBAN = 'IBAN',
}

const BlockInfo = (locale: string, currencyID: string, currencyCode: string) => ({
	[BlockTypes.WELCOME]: {
		title: messages.WELCOME_TITLE,
		paragraph: messages.WELCOME_PARAGRAPH,
		buttonText: messages.WELCOME_BUTTON,
		image: welcomeImg,
		url: `/${locale}/deposit/crypto`,
		data: TestIds.WelcomeBlock,
	},
	[BlockTypes.IBAN]: {
		title: messages.IBAN_TITLE,
		paragraph: messages.IBAN_PARAGRAPH,
		buttonText: messages.IBAN_BUTTON,
		image: ibanImg,
		url: `/${locale}/account/history/${currencyCode}/${currencyID}/IBAN/claim`,
		data: TestIds.IBANBlock,
	},
});

const StartingBlock: FC = () => {
	const { formatMessage, locale } = useIntl();
	const { wallets } = useSelector((state: RootState) => state?.AccountsState);
	const { user } = useSelector((state: RootState) => state?.ProfileState);
	const checkIBANRedirect = useIBANRedirect();
	const { showStartingBlock } = useSelector((state: RootState) => state?.AppState);
	const { pathname } = useLocation();
	const [redirectURL, setRedirectURL] = useState({ isAnchor: false, url: '' });
	const linkToPersonalIBAN = useFormatRoute(routes.personalIban);
	const wallet = useMemo(
		() => wallets?.find(({ currencyCode }) => currencyCode === CurrencyEnum.EUR),
		[wallets]
	);
	const isAvailablevIBAN = useMemo(() => !wallet?.iban, [wallet]);
	const { isMobile } = useSelector((state: RootState) => state.AppState);
	const hasBalance = useMemo(
		() => !!wallets?.filter(({ availableBalance }) => !!+availableBalance).length,
		[wallets]
	);
	const type = useMemo(() => {
		if (user?.type === UserType.COMPANY_REPRESENTATIVE) return null;
		if (
			/\/deposit\/bank\/?$/g.test(pathname) &&
			!hasBalance &&
			isAvailablevIBAN &&
			wallet?.ibanInfo?.status === IBANStatus.ACTIVE
		)
			return BlockTypes.IBAN;
		if (!hasBalance) return BlockTypes.WELCOME;
		if (hasBalance && isAvailablevIBAN && wallet?.ibanInfo?.status !== IBANStatus.CLOSED)
			return BlockTypes.IBAN;

		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasBalance, isAvailablevIBAN, user?.type]);

	useEffectOnce(() => {
		if (type === BlockTypes.IBAN)
			void checkIBANRedirect().then(({ url, verified }) =>
				setRedirectURL({ isAnchor: !verified, url })
			);
		if (type === BlockTypes.WELCOME) setRedirectURL({ isAnchor: false, url });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	if (!type || !showStartingBlock) return null;

	const { id, currencyCode } = wallet!;
	const { title, paragraph, buttonText, image, url, data } = BlockInfo(locale, id, currencyCode)[
		type
	];

	return (
		<div className={styles.cardContainer} data-cy={data}>
			<div className={styles.flexContainer}>
				<div className={styles.textContainer}>
					<div className={styles.title}>{formatMessage(title)}</div>
					<div className={styles.paragraph}>
						<FormattedMessage
							{...paragraph}
							values={{
								FaqLink: (text) => (
									<a className={styles.faqLink} href={linkToPersonalIBAN}>
										{text}
									</a>
								),
							}}
						/>
					</div>
					{!isMobile && (
						<Button
							type={redirectURL.isAnchor ? ButtonType.ANCHOR_LINK : ButtonType.LINK}
							link={redirectURL.url}
							buttonStyle={ButtonStyle.PRIMARY}
							className={styles.button}
							text={buttonText}
						/>
					)}
				</div>
				<img src={image} />
			</div>
			{isMobile && (
				<Button
					type={redirectURL.isAnchor ? ButtonType.ANCHOR_LINK : ButtonType.LINK}
					link={redirectURL.url}
					buttonStyle={ButtonStyle.PRIMARY}
					className={styles.button}
					text={buttonText}
				/>
			)}
		</div>
	);
};

export default StartingBlock;
