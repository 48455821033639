import {
	DocumentstHistoryState,
	DocumentsHistoryType,
	SetIsDocumentsHistoryLoading,
	SetDocumentsHistoryDataAction,
	ResetDocumentsHistoryAction,
	RemoveDocumentAction,
} from './DocumentsHistoryTypes';

const initialState: DocumentstHistoryState = {
	isLoading: true,
	data: [],
	totalCount: 0,
};

export default (
	state = initialState,
	action:
		| SetIsDocumentsHistoryLoading
		| SetDocumentsHistoryDataAction
		| ResetDocumentsHistoryAction
		| RemoveDocumentAction
) => {
	switch (action.type) {
		case DocumentsHistoryType.SET_DATA:
			return {
				...state,
				data: action.data,
				totalCount: action.totalCount,
			};
		case DocumentsHistoryType.RESET_DATA:
			return initialState;
		case DocumentsHistoryType.IS_LOADING:
			return {
				...state,
				isLoading: action.isLoading,
			};
		case DocumentsHistoryType.DELETE_DOCUMENT:
			return {
				...state,
				data: [...state?.data!.filter(({ id }) => id !== action.data)],
				totalCount: state.totalCount - 1,
			};
		default:
			return state;
	}
};
