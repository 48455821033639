/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useRouteMatch, useLocation, Redirect, useHistory } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import WhiteContainer from '../../components/WhiteContainer/WhiteContainer';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { RootState } from '../../redux/Store';
import {
	getAllCards,
	setCompanyMovement,
	getIsCardUser,
} from '../../redux/CardsState/CardsActions';
import { UserType } from '../../redux/ProfileState/ProfileTypes';
import OrderPage from '../../pages/Cards/OrderPage/OrderPage';
import CardsHub from '../../pages/Cards/CardsHub/CardsHub';
import CardsAccount from '../../pages/Cards/CardsAccount/CardsAccount';
import CardStatus, { CardStatusEnum } from '../../pages/Cards/Shared/CardStatus/CardStatus';
import CompanyUser from '../../pages/Cards/Shared/CompanyUser/CompanyUser';
import styles from './CardsContainer.module.scss';
import Seo from '../../components/Seo/Seo';
import useEffectOnce from '../../hooks/useEffectOnce';

const messages = defineMessages({
	generalCardErrorTitle: {
		id: 'cardsContainer.generalCardErrorTitle',
		defaultMessage: 'Oops!',
	},
	generalCardError: {
		id: 'cardsContainer.generalCardError',
		defaultMessage: 'Something went wrong! Please try again later.',
	},
	metaTitle: {
		id: 'cardsList.metaTitle',
		defaultMessage: 'Cards',
	},
});

const CardsContainer = () => {
	const dispatch = useDispatch();
	const { locale } = useIntl();
	const { pathname } = useLocation();
	const { url }: { url: string } = useRouteMatch();
	const { showCardsSidebar } = useSelector((state: RootState) => state.AppState);
	const { isCardUser, cardsList, generalError, companyMovement } = useSelector(
		(state: RootState) => state.CardsState
	);
	const { replace } = useHistory();
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const [redirectURL, setRedirectURL] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		if (isCardUser === null) void dispatch(getIsCardUser());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cardsList, isCardUser]);

	useEffect(() => {
		if (isCardUser !== null && cardsList === null) void dispatch(getAllCards());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cardsList, isCardUser]);

	useEffect(() => {
		if (companyMovement && user && isCardUser !== null) {
			if (user.type === UserType.COMPANY_REPRESENTATIVE) {
				setIsLoading(false);
				return setRedirectURL(`${url}/forbidden`);
			}
			if (companyMovement.available === false) {
				setIsLoading(false);
				return setRedirectURL(`${url}/order/verify`);
			}
			if (companyMovement.available === true) {
				if (!user.verified || !user.phoneVerified) {
					setIsLoading(false);
					return setRedirectURL(`${url}/order/verify`);
				}
				if (user.verified && user.phoneVerified && cardsList && cardsList?.length === 0) {
					setIsLoading(false);
					return setRedirectURL(
						cardsList && cardsList?.length === 0 ? `${url}/order` : pathname
					);
				}
				if (user.verified && user.phoneVerified && cardsList && cardsList.length > 0) {
					setIsLoading(false);
					return setRedirectURL(pathname || url);
				}
			}
		}
	}, [isCardUser, cardsList, isLoading, locale, pathname, url, replace, companyMovement, user]);

	useEffectOnce(() => {
		dispatch(setCompanyMovement());
	});

	if (generalError)
		return (
			<WhiteContainer className={styles.errorContainer}>
				<CardStatus
					status={CardStatusEnum.FAILED}
					title={messages.generalCardErrorTitle}
					text={messages.generalCardError}
				/>
			</WhiteContainer>
		);
	if (!generalError && isLoading && !redirectURL)
		return (
			<div className={styles.loaderContainer}>
				<LoadingScreen className={styles.loader} />
			</div>
		);
	return (
		<div className={styles.container}>
			<Seo title={messages.metaTitle} />
			<div className={styles.flexGrid}>
				<div
					className={classNames(styles.content, {
						[styles.noSidebar]: !showCardsSidebar,
					})}
				>
					<Route exact path={url} component={CardsHub} />
					<Route exact path={`${url}/forbidden`} component={CompanyUser} />
					<Route path={`${url}/order`} component={OrderPage} />
					{cardsList && cardsList?.length > 0 && (
						<Route path={`${url}/account/:id`} component={CardsAccount} />
					)}
					<Redirect to={redirectURL} />
				</div>
			</div>
		</div>
	);
};

export default CardsContainer;
