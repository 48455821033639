/* eslint-disable import/prefer-default-export */
import { AxiosError } from 'axios';
import { ActionCreator } from 'redux';
import { getMerchantsOrderUrl } from '../../../endpoints';
import { AppDispatch } from '../../../Store';
import {
	OrderDataUpdateOrdersAction,
	OrderDataFetchOrderFulfilledAction,
	OrderDataFetchOrderPendingAction,
	OrderDataFetchOrderRejectedAction,
	OrdersDataActionType,
	Order,
	OrderDataUpdateOrderAction,
} from './OrdersDataTypes';
import axiosInstance from '../../../../helpers/axiosInstance';

const updateOrders: ActionCreator<OrderDataUpdateOrdersAction> = (orders: Order[]) => ({
	type: OrdersDataActionType.UPDATE_ORDERS,
	payload: orders,
});

const updateOrder: ActionCreator<OrderDataUpdateOrderAction> = (order: Order) => ({
	type: OrdersDataActionType.UPDATE_ORDER,
	payload: order,
});

const fetchOrderPending: ActionCreator<OrderDataFetchOrderPendingAction> = (id: string) => ({
	type: OrdersDataActionType.FETCH_ORDER_PENDING,
	payload: id,
});

const fetchOrderRejected: ActionCreator<OrderDataFetchOrderRejectedAction> = (id: string) => ({
	type: OrdersDataActionType.FETCH_ORDER_REJECTED,
	payload: id,
});

const fetchOrderFulfilled: ActionCreator<OrderDataFetchOrderFulfilledAction> = (order: Order) => ({
	type: OrdersDataActionType.FETCH_ORDER_FULFILLED,
	payload: order,
});

const fetchOrder = (id: string) => async (dispatch: AppDispatch) => {
	dispatch(fetchOrderPending(id));

	await axiosInstance
		.get<Order>(getMerchantsOrderUrl(id))
		.then(({ data }) => {
			dispatch(fetchOrderFulfilled(data));
		})
		.catch((e: AxiosError) => {
			dispatch(fetchOrderRejected(id));
		});
};

export default {
	updateOrders,
	updateOrder,
	fetchOrder,
};
