import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import baseMsg from '../../../../messages/base.messages';
import { RootState } from '../../../../redux/Store';
import { getDebitCardGetPinURL, getGIPSetPinCodeURL } from '../../../../redux/endpoints';
import {
	getAllCards,
	getIsCardUser,
	setOrderStep,
	updateDebitCard,
} from '../../../../redux/CardsState/CardsActions';
import { OrderSteps } from '../../../../redux/CardsState/CardsTypes';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import styles from './SetPin.module.scss';
import hideImg from '../../../../images/hide.svg';
import showImg from '../../../../images/show.svg';
import inputErrors from '../../../../messages/inputErrors.messages';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	pinTooShort: {
		id: 'setPin.pinTooShort',
		defaultMessage: 'Pin too short',
	},
	title: {
		id: 'setPin.title',
		defaultMessage: 'Create a PIN code for your card',
	},
	subTitle: {
		id: 'setPin.subTitle',
		defaultMessage: 'Make sure there is no one else looking at your screen',
	},
	label: {
		id: 'setPin.label',
		defaultMessage: 'Create PIN',
	},
	placeholder: {
		id: 'setPin.placeholder',
		defaultMessage: 'Enter 4-digit PIN',
	},
});

const SetPin = () => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const { orderData } = useSelector((state: RootState) => state.CardsState);
	const [pin, setPin] = useState<string>('');
	const [pinError, setPinError] = useState<MessageDescriptor | null>(null);
	const [auth, setAuth] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showPIN, setShowPIN] = useState(false);

	const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
		const { value } = target;
		setPinError(null);
		if (value && Number.isNaN(parseFloat(value))) {
			return null;
		}
		if (value.length > 4) {
			return null;
		}
		return setPin(value);
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!pin) {
			return setPinError(inputErrors.cannotBeEmpty);
		}
		if (pin.length !== 4) {
			return setPinError(messages.pinTooShort);
		}
		if (orderData) {
			setIsLoading(true);
			const url = getGIPSetPinCodeURL(orderData?.cardId);
			void axiosInstance
				.post(
					url,
					{ 'set-pin': pin },
					{
						headers: {
							Authorization: auth,
						},
					}
				)
				.then(() => {
					return updateDebitCard(orderData?.pinId).then(() => {
						dispatch(getAllCards());
						dispatch(getIsCardUser());
						return dispatch(setOrderStep(OrderSteps.SUCCESS));
					});
				})
				.catch(() => {
					return dispatch(setOrderStep(OrderSteps.FAILURE));
				})
				.then(() => {
					return setIsLoading(false);
				});
		}
		return null;
	};

	const getPinJwtToken = (id: string) =>
		axiosInstance.get(getDebitCardGetPinURL(id)).then(({ data }) => {
			setAuth(data.authorizationHeader);
		});

	useEffect(() => {
		if (orderData?.pinId) getPinJwtToken(orderData.pinId);
	}, [orderData]);

	return (
		<div className={styles.container}>
			<InfoHead title={messages.title} subTitle={formatMessage(messages.subTitle)} />
			<form onSubmit={handleSubmit} className={styles.form}>
				<Input
					label={messages.label}
					placeholder={messages.placeholder}
					value={pin}
					type={showPIN ? 'number' : 'password'}
					errorMessage={pinError}
					onChangeEvent={handleInputChange}
				>
					<div
						className={styles.showButton}
						onClick={(e) => {
							e.preventDefault();
							setShowPIN(!showPIN);
						}}
					>
						<img src={showPIN ? hideImg : showImg} />
					</div>
				</Input>

				<Button
					buttonStyle={ButtonStyle.PRIMARY}
					type={ButtonType.SUBMIT}
					text={baseMsg.submit}
					className={styles.button}
					isLoading={isLoading}
					isDisabled={isLoading}
				/>
			</form>
		</div>
	);
};

export default SetPin;
