import { useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import queryParams from 'query-string';
import { CurrencyIcon, CurrencyEnum } from '@spectrocoin/sc-currencies';
import Seo from '../../../../components/Seo/Seo';
import { ProviderType } from '../../../../redux/WithdrawState/WithdrawTypes';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';
import HistoryContext from '../../../../contexts/HistoryContext/HistoryContext';
import Select from '../../../../components/Select/Select';
import { Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import { RootState } from '../../../../redux/Store';
import { HistoryType } from '../../../../hooks/useHistoryTableData';
import HistoryTable from '../../../../components/HistoryTable/HistoryTable';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import HistoryFilter from '../../../../components/HistoryFilter/HistoryFilter';
import { providerName } from '../../EWalletWithdraw/EWalletWithdrawHistory/EWalletWithdrawHistory';
import styles from './WithdrawHistory.module.scss';
import baseMsg from '../../../../messages/base.messages';
import useWithdrawHistoryDataProvider from './Provider/withdrawHistoryDataProvider';
import SelectOption from '../../../../interfaces/SelectOption';

enum WithdrawHistoryType {
	CRYPTO = 'crypto',
	EWALLET = 'e-wallet',
	BANK = 'bank',
	GIFT_CARDS = 'gift-cards',
	VOUCHERS = 'vouchers',
	MOBILE_TOP_UPS = 'mobile-top-ups',
}

interface Params {
	historyType: HistoryType;
	filter: CurrencyEnum;
	withdrawType: WithdrawHistoryType;
}

const withdrawTypesNoCurrenciesSelect = [
	WithdrawHistoryType.MOBILE_TOP_UPS,
	WithdrawHistoryType.GIFT_CARDS,
];

const WithdrawHistoryContent = ({ historyType, filter, withdrawType }: Params): JSX.Element => {
	const history = useWithdrawHistoryDataProvider();
	const { locale, formatMessage } = useIntl();
	const { replace } = useHistory();
	const { pathname, search } = useLocation();
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const { paymentMethods } = useSelector((state: RootState) => state.WithdrawState);
	const { crypto, voucher } = paymentMethods;
	const [options, setOptions] = useState<SelectOption[]>([]);

	const getMetaTitle = () => {
		switch (historyType) {
			case HistoryType.CRYPTO_WITHDRAW:
				return withdrawMessages.metaCryptoHistoryTitle;
			case HistoryType.VOUCHERS_WITHDRAW:
				return withdrawMessages.metaVoucherHistoryTitle;
			case HistoryType.BANK_WITHDRAW:
				return withdrawMessages.metaBankHistoryTitle;
			case HistoryType['GIFT-CARDS_WITHDRAW']:
				return withdrawMessages.metaGiftHistoryTitle;
			case HistoryType['MOBILE-TOP-UPS_WITHDRAW']:
				return withdrawMessages.metaMobileTopupHistoryTitle;
			case HistoryType['E-WALLET_WITHDRAW']:
				return withdrawMessages.metaEwalletHistoryTitle;
			default:
				return withdrawMessages.metaCryptoHistoryTitle;
		}
	};

	const addAllOption = useCallback(
		(startOptions: SelectOption[] | undefined) => {
			return startOptions
				? [{ label: formatMessage(baseMsg.all), value: '' }, ...startOptions]
				: [{ label: formatMessage(baseMsg.all), value: '' }];
		},
		[formatMessage]
	);

	const onSelectChange = (value: string) => {
		if (withdrawType === WithdrawHistoryType.EWALLET) {
			const queryString = queryParams.parse(search);
			return replace({
				pathname,
				search: queryParams.stringify({ ...queryString, type: value }),
			});
		}
		return replace({
			pathname:
				pathname.search(filter) !== 0
					? !value
						? pathname.replace(filter, '').slice(0, -1)
						: pathname.replace(filter, value)
					: !value
					? pathname
					: `${pathname}/${value}`,
			search,
		});
	};

	useEffect(() => {
		if (!withdrawTypesNoCurrenciesSelect.includes(withdrawType)) {
			if (withdrawType === WithdrawHistoryType.CRYPTO) {
				setOptions(
					addAllOption(
						wallets?.reduce((acc: SelectOption[], { currencyCode: code }: Wallet) => {
							if (crypto.data.includes(code as CurrencyEnum)) {
								acc.push({
									label: (
										<div className={styles.option}>
											<CurrencyIcon
												currencyType={code}
												className={styles.currencyIcon}
											/>
											{code}
										</div>
									),
									value: code,
								});
							}
							return acc;
						}, [])
					) || []
				);
			}
			if (withdrawType === WithdrawHistoryType.VOUCHERS) {
				setOptions(
					addAllOption(
						wallets?.reduce((acc: SelectOption[], { currencyCode: code }: Wallet) => {
							if (
								voucher.data
									.map(({ currencyCode }) => currencyCode)
									.includes(code as CurrencyEnum)
							) {
								acc.push({
									label: (
										<div className={styles.option}>
											<CurrencyIcon
												currencyType={code}
												className={styles.currencyIcon}
											/>
											{code}
										</div>
									),
									value: code,
								});
							}
							return acc;
						}, [])
					) || []
				);
			}
			if (withdrawType === WithdrawHistoryType.EWALLET) {
				setOptions(
					addAllOption(
						[
							ProviderType.ADV_CASH,
							ProviderType.PAYEER,
							ProviderType.SKRILL,
							ProviderType.PERFECT_MONEY,
						].reduce((acc: SelectOption[], curr: ProviderType) => {
							acc.push({
								label: formatMessage(providerName[curr]),
								value: curr,
							});
							return acc;
						}, [])
					)
				);
			}
		}
	}, [addAllOption, crypto.data, formatMessage, voucher.data, wallets, withdrawType]);

	return (
		<>
			<Seo title={getMetaTitle()} />
			<PageTitle
				previousPageLink={`/${locale}/withdraw/${withdrawType.toLowerCase()}`}
				isPreviousPageLinkVisibleOnDesktop
				isPreviousPageLinkVisibleOnMobile={false}
				title={withdrawMessages[`${withdrawType}HistoryTitle`]}
				isExportEnabled
				historyType={historyType}
				historyCount={history.totalCount}
			/>
			<div className={styles.container}>
				<HistoryFilter>
					{options.length > 0 && (
						<Select
							label={
								withdrawType === WithdrawHistoryType.EWALLET
									? baseMsg.filterByProvider
									: baseMsg.filterByCurrency
							}
							labelClassName={styles.label}
							className={styles.select}
							options={options}
							value={filter}
							clearable
							onChange={(value: string) => onSelectChange(value)}
						/>
					)}
				</HistoryFilter>
				<HistoryTable
					currencyCode={filter}
					data={history?.data}
					isLoading={history?.isLoading}
					totalCount={history?.totalCount}
					historyType={HistoryType[`${withdrawType.toUpperCase()}_WITHDRAW`]}
				/>
			</div>
		</>
	);
};

const WithdrawHistory = (): JSX.Element => {
	const { withdrawType, filter } = useParams<Params>();
	const contextProps = useMemo(() => {
		return {
			historyType: HistoryType[`${withdrawType.toUpperCase()}_WITHDRAW`],
			filter,
		};
	}, [filter, withdrawType]);
	return (
		<HistoryContext.Provider value={contextProps}>
			<WithdrawHistoryContent
				historyType={HistoryType[`${withdrawType.toUpperCase()}_WITHDRAW`]}
				withdrawType={withdrawType}
				filter={filter}
			/>
		</HistoryContext.Provider>
	);
};

export default WithdrawHistory;
