/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-param-reassign */
// From: https://stackoverflow.com/questions/2685911/is-there-a-way-to-round-numbers-into-a-reader-friendly-format-e-g-1-1k
import decimal, { Decimal } from 'decimal.js';
import { swapSeparators, toDecimal } from '../currencyHelper/currencyHelper';
import store from '../../redux/Store';
import { DeviceNameType } from '../../redux/AppState/AppTypes';
/**
 * @function abbreviateNumber
 *
 * @description Converts a long number into abbreviated string
 *
 * @param {number} number - Number to abbreviate
 * @param {number} [decPlaces]
 *
 * @returns {string | number} Abbreviated string if value is over 1000 else return value
 */
export const abbreviateNumber = (number: string, decPlaces = 1): string | number => {
	const isAppleOS = store.getState().AppState.deviceOS === DeviceNameType.IPHONE;
	let result: string | Decimal = number;
	// 2 decimal places => 100, 3 => 1000, etc
	decPlaces = 10 ** decPlaces;
	// Enumerate number abbreviations
	const abbrev = ['K', 'M', 'B', 'T'];
	const isNeg = toDecimal(number).lessThan(0);

	if (isNeg) {
		number = toDecimal(number).times(toDecimal(-1)).toString();
	}

	// Go through the array backwards, so we do the largest first
	for (let i = abbrev.length - 1; i >= 0; i--) {
		// Convert array index to "1000", "1000000", etc
		const size = 10 ** ((i + 1) * 3);

		// If the number is bigger or equal do the abbreviation
		if (toDecimal(size).lessThanOrEqualTo(toDecimal(number))) {
			// Here, we multiply by decPlaces, round, and then divide by decPlaces.
			// This gives us nice rounding to a particular decimal place.
			decimal.set({ rounding: Decimal.ROUND_HALF_DOWN });
			number = toDecimal(number)
				.times(toDecimal(decPlaces))
				.dividedBy(toDecimal(size))
				.dividedBy(toDecimal(decPlaces))
				.toString();
			// number = Math.round((number * decPlaces) / size) / decPlaces;
			// Handle special case where we round up to the next abbreviation
			if (toDecimal(number).equals(1000) && i < abbrev.length - 1) {
				number = '1';
				i++;
			}

			// Add the letter for the abbreviation
			result = `${number.split('.')[0]}${
				typeof number.split('.')[1] !== 'undefined'
					? `.${number.split('.')[1].substr(0, 2)}${abbrev[i]}`
					: `.00${abbrev[i]}`
			}`;

			// We are done... stop
			break;
		}
	}

	const returnValue = isNeg
		? `-${number}`
		: `${result.split('.')[0]}${
				typeof result.split('.')[1] !== 'undefined' && result.split('.')[1].length !== 0
					? Number.isNaN(parseInt(result.split('.')[1].substr(0, 3).slice(-1)))
						? parseInt(result.split('.')[1].substr(0, 2)) === 0 &&
						  parseInt(result.split('.')[1].substr(0, 1)) === 0
							? `${result.split('.')[1].slice(-1)}`
							: `.${result.split('.')[1].substr(0, 3)}`
						: `.${result.split('.')[1].substr(0, 2)}`
					: `.00`
		  }`;

	return isAppleOS ? swapSeparators(returnValue) : returnValue;
};

export default abbreviateNumber;
