import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import HistoryListItem from '../../../components/HistoryTable/HistoryListItem/HistoryListItem';
import { DetailsModalType } from '../../../components/Modal/DetailsModal/DetailsModal';
import { WalletHistoryItem } from '../../../redux/WalletHistoryState/WalletHistoryTypes';

interface Props {
	data: WalletHistoryItem[];
	currencyCode: CurrencyEnum;
}

const WalletHistoryList = ({ data, currencyCode }: Props): JSX.Element => {
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{data?.length > 0 &&
				data.map(
					({
						amount,
						transferTypeName,
						transactionNo,
						processDate,
						status,
						description,
					}: WalletHistoryItem) => {
						return (
							<HistoryListItem
								key={transactionNo}
								transNo={transactionNo}
								dateCreated={processDate}
								status={status}
								amount={amount}
								currency={currencyCode}
								title={transferTypeName}
								detailsModalType={DetailsModalType.WALLET}
								description={description}
							/>
						);
					}
				)}
		</>
	);
};

export default WalletHistoryList;
