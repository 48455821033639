const routes = {
    'ar': require('./src/translations/routes/ar.json'),
'de': require('./src/translations/routes/de.json'),
'en': require('./src/translations/routes/en.json'),
'es-ES': require('./src/translations/routes/es-ES.json'),
'et': require('./src/translations/routes/et.json'),
'fr': require('./src/translations/routes/fr.json'),
'it': require('./src/translations/routes/it.json'),
'lt': require('./src/translations/routes/lt.json'),
'ru': require('./src/translations/routes/ru.json'),
'uk': require('./src/translations/routes/uk.json'),
'zh-TW': require('./src/translations/routes/zh-TW.json'),
};

module.exports = routes;
