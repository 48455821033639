import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import useQueryParams from '../../../hooks/useQueryParams';
import { HistoryTableData } from '../../../hooks/useHistoryTableData';
import useDidMount from '../../../hooks/useDidMount';
import HistoryContext, {
	DefaultHistoryContextData,
} from '../../../contexts/HistoryContext/HistoryContext';
import { getAccountHistory } from '../../../redux/WalletHistoryState/WalletHistoryActions';
import { RootState } from '../../../redux/Store';

const useWalletHistoryDataProvider = (): HistoryTableData<any> => {
	const { id } = useContext(HistoryContext) as DefaultHistoryContextData;
	const [callCount, setCallCount] = useState(0);

	const didMount = useDidMount();
	const dispatch = useDispatch();
	const [[page, startDate, endDate, status]] = useQueryParams({
		page: undefined,
		startDate: undefined,
		endDate: undefined,
		status: undefined,
	});

	const { totalCount: historyOrdersTotalCount, isLoading, data: historyOrders } = useSelector(
		(state: RootState) => state.WalletHistoryState
	);

	useEffect(() => {
		if (didMount && !(callCount === 0 && !startDate && !endDate) && id) {
			setCallCount(callCount + 1);
			dispatch(
				getAccountHistory(id, startDate, endDate, typeof page === 'string' ? +page : page)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endDate, page, id, startDate, status, didMount]);

	return {
		data: historyOrders,
		isLoading,
		totalCount: historyOrdersTotalCount,
	};
};

export default useWalletHistoryDataProvider;
