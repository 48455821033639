import classNames from 'classnames';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import BackButton from '../BackButton/BackButton';
import styles from './WhiteContainer.module.scss';

const messages = defineMessages({
	back: {
		id: 'whiteContainer.back',
		defaultMessage: 'Back',
	},
});

interface WhiteContainerProps {
	backButtonLink?: string | null;
	backButtonText?: MessageDescriptor | null;
	children: ReactNode;
	className?: string;
}

const WhiteContainer: FC<PropsWithChildren<WhiteContainerProps>> = ({
	backButtonLink = null,
	backButtonText = null,
	children,
	className,
	...rest
}) => (
	<>
		{backButtonLink && (
			<BackButton
				link={backButtonLink}
				className={styles.back}
				text={backButtonText || messages.back}
			/>
		)}
		<div {...rest} className={classNames(styles.container, className)}>
			{children}
		</div>
	</>
);

export default WhiteContainer;
