import PageableResponse from '../../interfaces/PageableResponse';

export enum WalletHistoryType {
	IS_LOADING = 'IS_LOADING',
	SET_DATA = 'SET_DATA',
	RESET_DATA = 'RESET_DATA',
}

export enum ExportType {
	CSV = 'csv',
	PDF = 'pdf',
	FIAT_PDF = 'fiat-pdf',
	FIAT_CSV = 'fiat-csv',
}

export interface WalletHistoryItem {
	amount: string | number;
	description: string;
	processDate: string;
	status: string;
	transactionNo: string;
	transferTypeName: string;
}

export interface WalletHistoryResponse extends PageableResponse<WalletHistoryItem[]> {}

export interface WalletHistoryState {
	isLoading: boolean;
	data: WalletHistoryItem[];
	totalCount: number;
}

export interface SetIsWalletHistoryLoading {
	type: typeof WalletHistoryType.IS_LOADING;
	isLoading: boolean;
}

export interface SetWalletHistoryDataAction {
	type: typeof WalletHistoryType.SET_DATA;
	data: WalletHistoryResponse;
}

export interface ResetWalletHistoryAction {
	type: typeof WalletHistoryType.RESET_DATA;
}
