import {
	RatesType,
	SetRatesAction,
	SetRatesDetailsAction,
	SetIsLoadingRates,
	RatesState,
} from './RatesTypes';

const initialState: RatesState = {
	rates: null,
	ratesDetails: null,
	isLoading: true,
};

export default (
	state = initialState,
	action: SetRatesAction | SetRatesDetailsAction | SetIsLoadingRates
) => {
	switch (action.type) {
		case RatesType.SET_RATES:
			return {
				...state,
				rates: action.data,
			};
		case RatesType.SET_RATES_DETAILS:
			return {
				...state,
				ratesDetails: [...action.data],
			};
		case RatesType.IS_LOADING:
			return {
				...state,
				isLoading: action.isLoading,
			};
		default:
			return state;
	}
};
