import { RemoteStatus } from '../../../../interfaces/RemoteData';
import createSelector from '../../../createSelector';
import { ProjectsListMode } from './ProjectsListTypes';

const getState = createSelector((state) => state.MerchantState.projects.list);

export const getProjectsByPage = (page: number) =>
	createSelector(getState, (state) => state.pages[page] || { status: RemoteStatus.None });

export const getProjectIdsByPage = (page: number) =>
	createSelector(getProjectsByPage(page), (state) => state?.data);

export const getIsInDeleteMode = createSelector(
	getState,
	({ mode }) => mode === ProjectsListMode.Delete
);

export const getFilter = createSelector(getState, (state) => state.filter);
export const getFilterTags = createSelector(getFilter, (state) => state.tags);
export const getDelete = createSelector(getState, (state) => state.delete);

export default {
	getState,
	getProjectsByPage,
	getProjectIdsByPage,
	getIsInDeleteMode,
	getFilter,
	getFilterTags,
	getDelete,
};
