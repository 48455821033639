enum IBANStatusEnum {
	TEMPORARY_SUBMITTED_STATUS = 'SUBMITTED',
	VERIFICATION_PENDING = 'VERIFICATION_PENDING',
	VERIFICATION_SUBMITTED = 'VERIFICATION_SUBMITTED',
	VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS',
	VERIFICATION_UPDATE_NEEDED = 'VERIFICATION_UPDATE_NEEDED',
	VERIFICATION_FAILED = 'VERIFICATION_FAILED',
	CLAIMED = 'CLAIMED',
}

export default IBANStatusEnum;
