import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { defineMessages, useIntl } from 'react-intl';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import convertToLocalTime from '../../../../helpers/dateHelper/dateHelper';
import { VoucherResponse } from '../../../../redux/WithdrawState/WithdrawTypes';
import baseMsg from '../../../../messages/base.messages';
import useFormatAmount from '../../../../hooks/useFormatAmount';

const messages = defineMessages({
	voucherWithdrawNumber: {
		id: 'voucherSummary.voucherWithdrawNumber',
		defaultMessage: 'Voucher withdraw number',
	},
});

interface VoucherSummaryProps {
	data: VoucherResponse[];
	currency: CurrencyEnum;
	dateClass?: string;
	infoClass?: string;
}

const VoucherSummary = ({ data, currency, dateClass, infoClass }: VoucherSummaryProps) => {
	const { formatMessage } = useIntl();
	const amountFormatter = useFormatAmount();

	return (
		<>
			{data.map(({ date, id, receiveAmount, withdrawAmount }) => {
				return (
					<li key={id}>
						{date && (
							<div className={dateClass}>
								{convertToLocalTime(date, 'yyyy-MM-dd HH:mm:ss')}
							</div>
						)}
						{id && (
							<InfoInput
								label={formatMessage(messages.voucherWithdrawNumber, { currency })}
								text={id}
								className={infoClass}
							/>
						)}
						{withdrawAmount && (
							<InfoInput
								label={formatMessage(baseMsg.withdrawAmount)}
								text={`${amountFormatter(
									formatPrecision(withdrawAmount, currency)
								)} ${currency}`}
								className={infoClass}
							/>
						)}
						{receiveAmount && (
							<InfoInput
								label={formatMessage(baseMsg.receiveAmount)}
								text={`${amountFormatter(
									formatPrecision(receiveAmount, currency)
								)} ${currency}`}
								className={infoClass}
							/>
						)}
					</li>
				);
			})}
		</>
	);
};

export default VoucherSummary;
