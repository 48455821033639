import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RemoteData, RemoteError } from '../../../../interfaces/RemoteData';

export type PaymentButton = {
	created: string;
	deleted: string;
	description: string | null;
	enabled: boolean;
	id: string;
	name: string;
	receiveAccountId: string | null;
	receiveCurrencyCode: CurrencyEnum | null;
	updated: string;
	userId: string;
	payCurrencyCodes: CurrencyEnum[] | null;
	isUsed: boolean | null; // only present in GET /payment-buttons/{id}
};

export type PaymentButtonsDataState = {
	paymentButtons: Record<string, RemoteData<PaymentButton>>;
};

export enum PaymentButtonsDataActionType {
	FETCH_PAYMENT_BUTTON_PENDING = 'MERCHANTS/PAYMENT_BUTTONS/DATA/FETCH_PAYMENT_BUTTON/PENDING',
	FETCH_PAYMENT_BUTTON_REJECTED = 'MERCHANTS/PAYMENT_BUTTONS/DATA/FETCH_PAYMENT_BUTTON/REJECTED',
	FETCH_PAYMENT_BUTTON_FULFILLED = 'MERCHANTS/PAYMENT_BUTTONS/DATA/FETCH_PAYMENT_BUTTON/FULFILLED',

	UPDATE_PAYMENT_BUTTON = 'MERCHANTS/PAYMENT_BUTTONS/DATA/UPDATE_PAYMENT_BUTTON',
	UPDATE_PAYMENT_BUTTONS = 'MERCHANTS/PAYMENT_BUTTONS/DATA/UPDATE_PAYMENT_BUTTONS',
	DELETE_PAYMENT_BUTTON = 'MERCHANTS/PAYMENT_BUTTONS/DATA/DELETE_PAYMENT_BUTTON',
}

export type PaymentButtonDataFetchPaymentButtonPendingAction = {
	type: PaymentButtonsDataActionType.FETCH_PAYMENT_BUTTON_PENDING;
	payload: string;
};

export type PaymentButtonDataFetchPaymentButtonRejectedAction = {
	type: PaymentButtonsDataActionType.FETCH_PAYMENT_BUTTON_REJECTED;
	payload: { id: string; error?: RemoteError };
};

export type PaymentButtonDataFetchPaymentButtonFulfilledAction = {
	type: PaymentButtonsDataActionType.FETCH_PAYMENT_BUTTON_FULFILLED;
	payload: PaymentButton;
};

export type PaymentButtonDataUpdatePaymentButtonAction = {
	type: PaymentButtonsDataActionType.UPDATE_PAYMENT_BUTTON;
	payload: PaymentButton;
};

export type PaymentButtonDataUpdatePaymentButtonsAction = {
	type: PaymentButtonsDataActionType.UPDATE_PAYMENT_BUTTONS;
	payload: PaymentButton[];
};

export type PaymentButtonDataDeletePaymentButtonAction = {
	type: PaymentButtonsDataActionType.DELETE_PAYMENT_BUTTON;
	payload: string;
};
