import { FC, createRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheck,
	faChevronDown,
	faChevronUp,
	faEyeSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';
import Button, { ButtonStyle, ButtonType } from '../Button/Button';
import hideIfEmptySrc from '../../images/hideIfEmpty.svg';
import settingsSrc from '../../images/settings.svg';
import baseMsg from '../../messages/base.messages';
import Toggle from '../Toggle/Toggle';
import styles from './GestureDrawer.module.scss';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import TestIds from '../../test/TestIds';

export interface DropdownOption {
	label: MessageDescriptor | string;
	value: string;
}

export enum WidthType {
	LARGER = 'largerWidth',
	SMALLER = 'smallerWidth',
}

interface Props {
	options: DropdownOption[];
	onChange: (val: DropdownOption) => void;
	widthType: WidthType;
	selectedOption: DropdownOption;
	settingsDropdown?: boolean;
	isBalanceHidden?: boolean;
	isWalletsHidden?: boolean;
	isLoading?: boolean;
	dataTag?: string;
	hideIfEmptyCall?: () => void;
	hiddenBalanceCall?: () => void;
}

const messages = defineMessages({
	hideIfEmpty: {
		id: 'walletBlock.hideIfEmpty',
		defaultMessage: 'Hide if empty',
	},
	addWallets: {
		id: 'walletBlock.addNewWallet',
		defaultMessage: 'Add wallet',
	},
	hideBalance: {
		id: 'walletBlock.hideBalance',
		defaultMessage: 'Hide balance',
	},
});

const GestureDrawer: FC<Props> = ({
	selectedOption,
	options,
	onChange,
	hiddenBalanceCall,
	hideIfEmptyCall,
	widthType,
	dataTag,
	isLoading = false,
	isWalletsHidden = false,
	isBalanceHidden = false,
	settingsDropdown = false,
}) => {
	const { locale } = useIntl();
	const { formatMessage } = useIntl();
	const resizableRef = createRef<HTMLDivElement>();
	const dragableRef = createRef<HTMLDivElement>();
	const optionsContainerRef = createRef<HTMLDivElement>();
	const [open, setOpen] = useState(false);
	const { isMobile } = useSelector((state: RootState) => state.AppState);
	useOnClickOutside(optionsContainerRef, () => !isMobile && setOpen(false));
	useOnClickOutside(resizableRef, () => isMobile && setOpen(false));

	const touchMove = (e: TouchEvent) => {
		const y = Math.floor(Math.abs(e.touches[0].clientY - window.innerHeight));
		if (resizableRef.current) {
			resizableRef.current.style.transition = '';
			if (y <= resizableRef.current.clientHeight) {
				resizableRef.current.style.bottom = `${y - resizableRef.current.clientHeight}px`;
			}
		}
	};

	const touchEnd = (e: TouchEvent) => {
		const y = Math.floor(Math.abs(e.changedTouches[0].clientY - window.innerHeight));
		if (resizableRef.current) {
			if (y < resizableRef.current.clientHeight / 2) {
				setOpen(false);
			} else {
				resizableRef.current.style.transition = 'bottom 0.2s';
				resizableRef.current.style.bottom = '0px';
			}
		}
	};

	const toggleChange = (val: DropdownOption) => {
		onChange(val);
		setOpen(false);
	};

	useEffect(() => {
		if (dragableRef.current) {
			dragableRef.current.addEventListener('touchmove', touchMove);
			dragableRef.current.addEventListener('touchend', touchEnd);

			return () => {
				dragableRef.current?.removeEventListener('touchmove', touchMove);
				dragableRef.current?.removeEventListener('touchend', touchEnd);
			};
		}

		return () => {};
	}, [resizableRef]);

	return (
		<div className={styles.content} ref={optionsContainerRef}>
			{settingsDropdown ? (
				<div
					onClick={() => setOpen(!open)}
					className={classNames(styles.settingsBtn, {
						[styles.active]: open,
					})}
					data-cy={TestIds.SettingsWheel}
				>
					<img src={settingsSrc} alt="Settings wheel" />
				</div>
			) : (
				<div
					className={classNames(styles.container, styles[widthType], {
						[styles.active]: open,
					})}
					onClick={() => setOpen(!open)}
					data-cy={dataTag}
				>
					{typeof selectedOption.label === 'string'
						? selectedOption.label
						: formatMessage(selectedOption.label)}
					<FontAwesomeIcon
						className={styles.chevronRight}
						icon={open ? faChevronUp : faChevronDown}
					/>
				</div>
			)}
			{open &&
				(options.length || settingsDropdown) &&
				(!isMobile ? (
					<div
						className={classNames(styles.optionsContainer, {
							[styles.settingsAlign]: settingsDropdown,
						})}
						data-cy={TestIds.SettingsList}
					>
						{settingsDropdown ? (
							<div className={styles.settingsDropdown}>
								<Button
									className={styles.addWalletBtn}
									buttonStyle={ButtonStyle.DEFAULT}
									type={ButtonType.ANCHOR_LINK}
									data-cy={TestIds.AddWalletBtn}
									link={`/${locale}/account/manage-wallets`}
								>
									<div className={styles.option}>
										<div className={styles.contentContainer}>
											<div className={styles.icon}>+</div>
											{formatMessage(messages.addWallets)}
										</div>
									</div>
								</Button>
								<div className={styles.option}>
									<div className={styles.contentContainer}>
										<div className={styles.icon}>
											<FontAwesomeIcon width={10} icon={faEyeSlash} />
										</div>
										{formatMessage(messages.hideBalance)}
									</div>
									<Toggle
										disabled={isLoading}
										id="HIDE_BALANCE_TOGGLE"
										name="HIDE_BALANCE_TOGGLE"
										onChange={hiddenBalanceCall}
										checked={isBalanceHidden}
										data-cy={TestIds.HideBalanceToggle}
									/>
								</div>
								<div className={styles.option}>
									<div className={styles.contentContainer}>
										<div className={styles.icon}>
											<img src={hideIfEmptySrc} />
										</div>
										{formatMessage(messages.hideIfEmpty)}
									</div>
									<Toggle
										disabled={isLoading}
										id="HIDE_EMPTY_TOGGLE"
										name="HIDE_EMPTY_TOGGLE"
										onChange={hideIfEmptyCall}
										checked={isWalletsHidden}
										data-cy={TestIds.HideEmptyToggle}
									/>
								</div>
							</div>
						) : (
							options.map((option) => (
								<span
									key={option.value}
									className={classNames({
										[styles.selected]: option === selectedOption,
									})}
									onClick={() => toggleChange(option)}
								>
									{typeof option.label === 'string'
										? option.label
										: formatMessage(option.label)}
								</span>
							))
						)}
					</div>
				) : (
					<div className={styles.mobileDropdownContainer}>
						<div className={styles.resizable} ref={resizableRef}>
							<div className={classNames(styles.draggable)} ref={dragableRef} />
							{settingsDropdown ? (
								<div className={styles.settingsDropdown}>
									<Button
										buttonStyle={ButtonStyle.DEFAULT}
										type={ButtonType.ANCHOR_LINK}
										link={`/${locale}/account/manage-wallets`}
									>
										<div className={styles.option}>
											<div className={styles.contentContainer}>
												<div className={styles.icon}>+</div>
												{formatMessage(messages.addWallets)}
											</div>
										</div>
									</Button>
									<div className={styles.option}>
										<div className={styles.contentContainer}>
											<div className={styles.icon}>
												<FontAwesomeIcon width={10} icon={faEyeSlash} />
											</div>
											{formatMessage(messages.hideBalance)}
										</div>
										<Toggle
											id="HIDE_BALANCE_TOGGLE"
											name="HIDE_BALANCE_TOGGLE"
											onChange={hiddenBalanceCall}
											checked={isBalanceHidden}
											disabled={isLoading}
										/>
									</div>
									<div className={styles.option}>
										<div className={styles.contentContainer}>
											<div className={styles.icon}>
												<img src={hideIfEmptySrc} />
											</div>
											{formatMessage(messages.hideIfEmpty)}
										</div>
										<Toggle
											id="HIDE_EMPTY_TOGGLE"
											name="HIDE_EMPTY_TOGGLE"
											onChange={hideIfEmptyCall}
											checked={isWalletsHidden}
											disabled={isLoading}
										/>
									</div>
								</div>
							) : (
								options.map((option, i) => (
									<span
										key={option.value}
										className={classNames({
											[styles.topMargin]: !i,
											[styles.selected]: option === selectedOption,
										})}
										onClick={() => toggleChange(option)}
									>
										{typeof option.label === 'string'
											? option.label
											: formatMessage(option.label)}
										{option === selectedOption && (
											<FontAwesomeIcon icon={faCheck} />
										)}
									</span>
								))
							)}
							<Button
								text={formatMessage(baseMsg.close)}
								className={classNames(styles.closeBtn, {
									[styles.settingsClosebtn]: settingsDropdown,
								})}
								buttonStyle={ButtonStyle.TERTIARY}
								onClick={() => setOpen(false)}
							/>
						</div>
					</div>
				))}
		</div>
	);
};

export default GestureDrawer;
