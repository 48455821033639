import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RemoteData } from '../../../../interfaces/RemoteData';

export enum PayoutStatus {
	NEW = 'NEW',
	PENDING = 'PENDING',
	CANCELLED = 'CANCELLED',
	EXPIRED = 'EXPIRED',
	PAID = 'PAID',
	FAILED = 'FAILED',
}

export type Payout = {
	callbackUrl: string;
	expiresAt: string;
	id: string;
	networkCode: string;
	payAmount: number | string;
	payCurrencyCode: CurrencyEnum;
	receiveCurrencyCode: CurrencyEnum;
	receiverAddress: string;
	receiverMessage: string;
	refId: string;
	status: PayoutStatus;
};

export type PayoutsDataState = {
	payouts: Record<string, RemoteData<Payout>>;
};

export enum PayoutsDataActionType {
	FETCH_PAYOUT_PENDING = 'MERCHANTS/PAYOUTS/DATA/FETCH_PAYOUT/PENDING',
	FETCH_PAYOUT_REJECTED = 'MERCHANTS/PAYOUTS/DATA/FETCH_PAYOUT/REJECTED',
	FETCH_PAYOUT_FULFILLED = 'MERCHANTS/PAYOUTS/DATA/FETCH_PAYOUT/FULFILLED',

	UPDATE_PAYOUT = 'MERCHANTS/PAYOUTS/DATA/UPDATE_PAYOUT',
	UPDATE_PAYOUTS = 'MERCHANTS/PAYOUTS/DATA/UPDATE_PAYOUTS',
}

export type PayoutsDataFetchPayoutPendingAction = {
	type: PayoutsDataActionType.FETCH_PAYOUT_PENDING;
	payload: string;
};

export type PayoutsDataFetchPayoutRejectedAction = {
	type: PayoutsDataActionType.FETCH_PAYOUT_REJECTED;
	payload: string;
};

export type PayoutsDataFetchPayoutFulfilledAction = {
	type: PayoutsDataActionType.FETCH_PAYOUT_FULFILLED;
	payload: Payout;
};

export type PayoutsDataUpdatePayoutAction = {
	type: PayoutsDataActionType.UPDATE_PAYOUT;
	payload: Payout;
};

export type PayoutsDataUpdatePayoutsAction = {
	type: PayoutsDataActionType.UPDATE_PAYOUTS;
	payload: Payout[];
};
