/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-var-requires */
import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';

const languages = require('../../../generatedMessages');
const routes = require('../../../generatedRoutes');

let allTranslations = {};

for (const key in languages) {
	const newObj = { ...routes[key], ...languages[key] };
	allTranslations = {
		...allTranslations,
		[key]: {
			...newObj,
		},
	};
}

interface LangProviderProps {
	children: ReactNode;
}

const LangProvider = ({ children }: LangProviderProps) => {
	const activeLanguage = useSelector((state: RootState) => state?.LanguageState?.activeLanguage);

	return (
		<IntlProvider
			defaultLocale="en"
			locale={activeLanguage}
			messages={allTranslations[activeLanguage]}
		>
			{children}
		</IntlProvider>
	);
};

export default LangProvider;
