/* eslint-disable import/prefer-default-export */
import { AxiosError } from 'axios';
import { ActionCreator } from 'redux';
import { getCryptoPayoutUrl } from '../../../endpoints';
import { AppDispatch } from '../../../Store';
import {
	PayoutsDataUpdatePayoutsAction,
	PayoutsDataFetchPayoutFulfilledAction,
	PayoutsDataFetchPayoutPendingAction,
	PayoutsDataFetchPayoutRejectedAction,
	PayoutsDataActionType,
	Payout,
	PayoutsDataUpdatePayoutAction,
} from './PayoutsDataTypes';
import axiosInstance from '../../../../helpers/axiosInstance';

const updatePayouts: ActionCreator<PayoutsDataUpdatePayoutsAction> = (orders: Payout[]) => ({
	type: PayoutsDataActionType.UPDATE_PAYOUTS,
	payload: orders,
});

const updatePayout: ActionCreator<PayoutsDataUpdatePayoutAction> = (order: Payout) => ({
	type: PayoutsDataActionType.UPDATE_PAYOUT,
	payload: order,
});

const fetchOrderPending: ActionCreator<PayoutsDataFetchPayoutPendingAction> = (id: string) => ({
	type: PayoutsDataActionType.FETCH_PAYOUT_PENDING,
	payload: id,
});

const fetchOrderRejected: ActionCreator<PayoutsDataFetchPayoutRejectedAction> = (id: string) => ({
	type: PayoutsDataActionType.FETCH_PAYOUT_REJECTED,
	payload: id,
});

const fetchOrderFulfilled: ActionCreator<PayoutsDataFetchPayoutFulfilledAction> = (
	payout: Payout
) => ({
	type: PayoutsDataActionType.FETCH_PAYOUT_FULFILLED,
	payload: payout,
});

const fetchPayout = (id: string) => async (dispatch: AppDispatch) => {
	dispatch(fetchOrderPending(id));

	await axiosInstance
		.get<Payout>(getCryptoPayoutUrl(id))
		.then(({ data }) => {
			dispatch(fetchOrderFulfilled(data));
		})
		.catch((e: AxiosError) => {
			dispatch(fetchOrderRejected(id));
		});
};

export default {
	updatePayouts,
	updatePayout,
	fetchPayout,
};
