import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useValidation from '../../../../../hooks/useValidation';
import PageTitle from '../../../Shared/PageTitle/PageTitle';
import Button, { ButtonType } from '../../../../../components/Button/Button';
import PaymentButtonForm, {
	usePaymentButtonFormValidator,
} from '../../../Shared/PaymentButtonForm/PaymentButtonForm';
import NoProjects from '../../../Shared/NotFound/NotFound';
import Loading from '../../../Shared/Loading/Loading';
import { RemoteData, RemoteStatus } from '../../../../../interfaces/RemoteData';
import useMerchantRoutes, { MerchantRoutes } from '../../../../../hooks/useMerchantRoutes';
import MerchantsActions from '../../../../../redux/MerchantsState/MerchantsActions';
import MerchantsMessages from '../../../../../redux/MerchantsState/MerchantsMessages';
import MerchantsSelectors from '../../../../../redux/MerchantsState/MerchantsSelectors';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';

import styles from './PaymentButtonsEdit.module.scss';
import TestIds from '../../../../../test/TestIds';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../../components/NotificationMessage/NotificationMessage';
import { getErrorMessageOrDefault } from '../../../../../helpers/errorMessageHelper/errorMessageHelper';
import PaymentButtonsEditContext from './Context/PaymentButtonsEditContext';
import {
	PaymentButton,
	PaymentButtonEditForm,
} from '../../../../../redux/MerchantsState/MerchantTypes';
import { getPaymentButtonUrl } from '../../../../../redux/endpoints';
import axiosInstance from '../../../../../helpers/axiosInstance';

const Content: FC = () => {
	const { push } = useHistory();
	const dispatch = useDispatch();
	const { getParams, getUrl } = useMerchantRoutes();
	const { paymentButtonId = '' } = getParams();
	const { formatMessage } = useIntl();

	const { form, updateForm, action, setAction } = useContext(PaymentButtonsEditContext);
	const { accountId, description, currencies, isEnabled, name } = form;

	const button = useSelector(
		MerchantsSelectors.paymentButtons.data.getPaymentButtonDataById(paymentButtonId)
	)!;
	const { status, error } = action;

	const isDirty =
		button.name !== name ||
		button.description !== description ||
		button.receiveAccountId !== accountId ||
		button.enabled !== isEnabled ||
		JSON.stringify(button.payCurrencyCodes || []) !== JSON.stringify(currencies || []);

	const edit = useCallback(() => {
		setAction({ status: RemoteStatus.InProgress });
		void axiosInstance
			.put<PaymentButton>(getPaymentButtonUrl(paymentButtonId), {
				description,
				enabled: isEnabled,
				payCurrencyCodes: currencies,
				name,
				receiveAccountId: accountId,
			})
			.then(({ data }) => {
				dispatch(MerchantsActions.paymentButtons.edit.setActionFulfilled(data));
				setAction({ status: RemoteStatus.Done, data });
			})
			.catch((e) => {
				setAction({ status: RemoteStatus.Error, error: e.response?.data });
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountId, currencies, description, isEnabled, name, paymentButtonId]);

	const validator = usePaymentButtonFormValidator();
	const [isValid] = useValidation({ name, description }, validator);

	useEffect(() => {
		if (status === RemoteStatus.Done)
			push(getUrl(MerchantRoutes.PaymentButton, { paymentButtonId }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	return (
		<>
			<PageTitle
				title={formatMessage(MerchantsMessages.editPaymentButton)}
				backLink={getUrl(MerchantRoutes.PaymentButton, { paymentButtonId })}
			/>
			<div className={styles.content}>
				<PaymentButtonForm
					formError={
						status === RemoteStatus.Error && (
							<NotificationMessage
								withIcon
								data-cy={TestIds.PaymentButtonEditError}
								type={NotificationType.Error}
								style={NotificationStyle.Border}
								message={formatMessage(getErrorMessageOrDefault(error))}
							/>
						)
					}
					autoConvert={accountId}
					onSetAutoConvert={
						button.isUsed === false
							? (value) => {
									updateForm({ accountId: value });
							  }
							: undefined
					}
					acceptCurrencies={currencies}
					onAcceptCurrenciesChange={
						button.isUsed === false
							? (value) => {
									updateForm({ currencies: value || [] });
							  }
							: undefined
					}
					description={description}
					onSetDescription={(value) => {
						updateForm({ description: value });
					}}
					isEnabled={isEnabled}
					onSetIsEnabled={(value) => {
						updateForm({ isEnabled: value });
					}}
					name={name}
					onSetName={(value) => {
						updateForm({ name: value });
					}}
				>
					<Button
						data-cy={TestIds.PaymentButtonEditSave}
						className={styles.button}
						isDisabled={status === RemoteStatus.InProgress || !isDirty || !isValid}
						type={ButtonType.BUTTON}
						onClick={edit}
					>
						<FormattedMessage {...MerchantsMessages.save} />
					</Button>
				</PaymentButtonForm>
			</div>
		</>
	);
};

const PaymentButtonsEdit: FC = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { getParams } = useMerchantRoutes();

	const { paymentButtonId = '' } = getParams();

	const [form, setForm] = useState<PaymentButtonEditForm | null>(null);
	const updateForm = useCallback((value: Partial<PaymentButtonEditForm>) => {
		setForm((current) => (current ? { ...current, ...value } : null));
	}, []);

	const [action, setAction] = useState<RemoteData<PaymentButton>>({ status: RemoteStatus.None });

	const { status } = useSelector(
		MerchantsSelectors.paymentButtons.data.getPaymentButtonById(paymentButtonId)
	);

	const button = useSelector(
		MerchantsSelectors.paymentButtons.data.getPaymentButtonDataById(paymentButtonId)
	);

	useEffect(() => {
		if (
			status === RemoteStatus.None ||
			(status === RemoteStatus.Done && button?.isUsed === null)
		)
			void dispatch(MerchantsActions.paymentButtons.data.fetchButton(paymentButtonId));
	}, [dispatch, paymentButtonId, status]);

	useEffect(() => {
		if (button) {
			setForm({
				name: button.name,
				description: button.description,
				accountId: button.receiveAccountId,
				isEnabled: button.enabled,
				currencies: button.payCurrencyCodes || [],
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [button]);

	if (status === RemoteStatus.None) return null;

	// eslint-disable-next-line react/jsx-no-constructed-context-values
	const context = { form: form!, updateForm, action, setAction };

	return (
		<PaymentButtonsEditContext.Provider value={context}>
			<div className={styles.host}>
				<WhiteContainer className={styles.container}>
					{status === RemoteStatus.InProgress && <Loading />}
					{status === RemoteStatus.Error && (
						<NoProjects
							message={formatMessage(MerchantsMessages.paymentButtonNotFound)}
						/>
					)}
					{status === RemoteStatus.Done && form && <Content />}
				</WhiteContainer>
			</div>
		</PaymentButtonsEditContext.Provider>
	);
};

export default PaymentButtonsEdit;
