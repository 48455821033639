import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { FC, PropsWithChildren } from 'react';
import { MessageDescriptor } from 'react-intl';
import HowTo, { HowToType } from './HowTo/HowTo';
import PaymentMethodLimits from './PaymentMethodLimits/PaymentMethodLimits';
import styles from './TransfersFormLayout.module.scss';

interface Props {
	title?: MessageDescriptor | string;
	providerType: string | null | undefined;
	duration?: string;
	method: any;
	type: HowToType;
	feeCurrency?: CurrencyEnum;
}

const TransfersFormLayout: FC<PropsWithChildren<Props>> = ({
	title = '',
	providerType = '',
	children,
	method,
	type,
	feeCurrency,
	duration,
}) => {
	return (
		<div className={styles.container}>
			<div className={styles.column}>{children}</div>
			<div className={styles.column}>
				<HowTo
					title={title}
					paymentMethodName={method?.title}
					duration={duration}
					providerType={providerType}
					type={type}
				/>
				{method && (
					<PaymentMethodLimits
						depositCurrencyCode={feeCurrency || method?.baseCurrencyCode}
						activePaymentMethodFees={method?.activePaymentMethodFees}
						baseCurrencyCode={feeCurrency || method?.baseCurrencyCode}
						limits={method?.paymentMethodLimits}
						maxAmount={method?.maxAmount}
						minAmount={method?.minAmount}
						minFee={method?.minFee}
						maxFee={method?.maxFee}
						fee={method?.fee}
						paymentMethodName={method?.title}
						type={type}
					/>
				)}
			</div>
		</div>
	);
};

export default TransfersFormLayout;
