import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { createContext, Dispatch, SetStateAction } from 'react';
import DurationType from '../../../../../../enums/DurationType';
import { RemoteData, RemoteStatus } from '../../../../../../interfaces/RemoteData';

export type RangeType = '1D' | '1W' | '1M' | '1Y' | 'Calendar';

export type MerchantApiChartData = {
	totalAmount: string;
	totalCount: number;
	currencyCode: CurrencyEnum;
	itemDuration: DurationType;
	items: Array<{ amount: string; count: number; timestamp: string }>;
};

export default createContext<{
	chartData: RemoteData<MerchantApiChartData>;
	range: {
		type: RangeType;
		from: Date | null;
		to: Date | null;
		setFrom: Dispatch<SetStateAction<Date | null>>;
		setTo: Dispatch<SetStateAction<Date | null>>;
		setType: Dispatch<SetStateAction<RangeType>>;
	};
}>({
	chartData: { status: RemoteStatus.None },
	range: {
		type: '1M',
		from: null,
		to: null,
		setFrom: (x) => x,
		setTo: (x) => x,
		setType: (x) => x,
	},
});
