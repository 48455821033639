import { FC, FormEvent, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { MessageDescriptor } from 'react-intl';
import { RootState } from '../../redux/Store';
import TwoFa from '../../components/TwoFa/TwoFa';
import styles from './TwoFaContainer.module.scss';
import Button, { ButtonStyle, ButtonType } from '../../components/Button/Button';
import baseMsg from '../../messages/base.messages';

interface Props {
	onSuccess: (props?: any) => void;
	onFailure: (props?: any) => void;
	onSubmit: () => void;
	buttonText?: MessageDescriptor;
	cancelButtonLink?: string;
	isLoading?: boolean;
	isDisabled?: boolean;
	buttonTestId?: string;
	hideForm?: boolean;
}

const TwoFaContainer: FC<PropsWithChildren<Props>> = ({
	children,
	onSuccess,
	onFailure,
	onSubmit,
	buttonText = null,
	cancelButtonLink = null,
	isLoading = false,
	isDisabled = false,
	hideForm = false,
	buttonTestId,
}) => {
	const { authType, txId, requestData } = useSelector((state: RootState) => state.TwoFaState);

	const isWaiting2FA = authType && txId && requestData;

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();
		return onSubmit();
	};

	return (
		<>
			<form
				onSubmit={handleSubmit}
				className={classNames({ [styles.isWaiting]: isWaiting2FA })}
			>
				{(!hideForm || !isWaiting2FA) && children}
				{!isWaiting2FA && (
					<Button
						text={buttonText || baseMsg.confirm}
						type={ButtonType.SUBMIT}
						className={styles.button}
						isDisabled={isLoading || isDisabled}
						isLoading={isLoading}
						data-cy={buttonTestId || 'confirm'}
					/>
				)}
				{!isWaiting2FA && cancelButtonLink && (
					<Button
						text={baseMsg.cancel}
						type={ButtonType.LINK}
						link={cancelButtonLink}
						buttonStyle={ButtonStyle.BORDERLESS}
						className={classNames(styles.button, {
							[styles.hidden]: isLoading,
						})}
						isDisabled={isLoading || isDisabled}
						isLoading={isLoading}
					/>
				)}
			</form>
			{isWaiting2FA && (
				<TwoFa onSuccess={onSuccess} onFailure={onFailure} isLoading={isLoading} />
			)}
		</>
	);
};

export default TwoFaContainer;
