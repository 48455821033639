import { Fragment } from 'react';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { useIntl } from 'react-intl';
import { ProviderType } from '../../../../redux/WithdrawState/WithdrawTypes';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';
import baseMsg from '../../../../messages/base.messages';
import useFormatAmount from '../../../../hooks/useFormatAmount';

interface DataProps {
	[key: string]: string;
}

interface BankWithdrawConfirmProps {
	data: DataProps[];
	currency: CurrencyEnum;
	providerType?: ProviderType;
	hasIban?: boolean;
	className: string;
}

const BankWithdrawConfirm = ({
	data,
	currency,
	providerType,
	hasIban,
	className,
}: BankWithdrawConfirmProps): JSX.Element => {
	const { formatMessage } = useIntl();
	const amountFormatter = useFormatAmount();
	const isSEPAWithIBAN = providerType === ProviderType.SEPA && hasIban;
	const isSWIFTWithIBAN = providerType === ProviderType.WIRE && hasIban;

	return (
		<>
			{data.map(
				({
					account,
					amount,
					totalAmount,
					details,
					receiver,
					swift,
					address,
					receiverBankName,
					receiverBankCountry,
					receiverAddress,
					receiverCountry,
					receiverProvince,
					receiverCity,
					receiverPostalCode,
				}: DataProps) => (
					<Fragment key={account}>
						{isSWIFTWithIBAN && receiver && (
							<InfoInput
								className={className}
								label={formatMessage(baseMsg.receiver)}
								text={receiver}
							/>
						)}
						{!isSEPAWithIBAN && account && (
							<InfoInput
								className={className}
								label={
									isSWIFTWithIBAN
										? formatMessage(withdrawMessages.receiverAccountLabel)
										: formatMessage(withdrawMessages.accountLabel)
								}
								text={account}
							/>
						)}
						{swift && (
							<InfoInput
								className={className}
								label={formatMessage(withdrawMessages.swiftLabel)}
								text={swift}
							/>
						)}
						{receiverBankName && (
							<InfoInput
								className={className}
								label={withdrawMessages.receiverBankNameLabel}
								text={receiverBankName}
							/>
						)}
						{receiverBankCountry && (
							<InfoInput
								className={className}
								label={withdrawMessages.receiverBankCountryLabel}
								text={receiverBankCountry.split('/')[1]}
							/>
						)}
						{address && (
							<InfoInput
								className={className}
								label={withdrawMessages.receiverBankAddressLabel}
								text={address}
							/>
						)}
						{receiverAddress && (
							<InfoInput
								className={className}
								label={withdrawMessages.receiverAddressLabel}
								text={receiverAddress}
							/>
						)}
						{receiverCountry && (
							<InfoInput
								className={className}
								label={withdrawMessages.receiverCountryLabel}
								text={receiverCountry.split('/')[1]}
							/>
						)}
						{receiverProvince && (
							<InfoInput
								className={className}
								label={withdrawMessages.receiverProvinceLabel}
								text={receiverProvince}
							/>
						)}
						{receiverCity && (
							<InfoInput
								className={className}
								label={withdrawMessages.receiverCityLabel}
								text={receiverCity}
							/>
						)}
						{receiverPostalCode && (
							<InfoInput
								className={className}
								label={withdrawMessages.receiverPostCodeLabel}
								text={receiverPostalCode}
							/>
						)}
						{!isSWIFTWithIBAN && receiver && (
							<InfoInput
								className={className}
								label={formatMessage(baseMsg.receiver)}
								text={receiver}
							/>
						)}
						{isSEPAWithIBAN && account && (
							<InfoInput
								className={className}
								label={formatMessage(withdrawMessages.accountNumberIbanLabel)}
								text={account}
							/>
						)}
						{isSEPAWithIBAN && (
							<InfoInput
								className={className}
								label={formatMessage(withdrawMessages.withdrawalMethod)}
								text={providerType}
							/>
						)}
						{amount && (
							<InfoInput
								className={className}
								label={baseMsg.receiveAmount}
								text={`${amountFormatter(
									formatPrecision(amount, currency, undefined, true)
								)} ${currency}`}
							/>
						)}
						{totalAmount && (
							<InfoInput
								className={className}
								label={baseMsg.totalAmountWithFee}
								text={`${amountFormatter(
									formatPrecision(totalAmount, currency)
								)} ${currency}`}
							/>
						)}
						{details && (
							<InfoInput
								className={className}
								label={withdrawMessages.details}
								text={details}
							/>
						)}
					</Fragment>
				)
			)}
		</>
	);
};

export default BankWithdrawConfirm;
