/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback, useEffect, FC, PropsWithChildren } from 'react';
import { endOfDay, startOfDay, format, addMonths } from 'date-fns';
import { defineMessages } from 'react-intl';
import useDidMount from '../../hooks/useDidMount';
import useQueryParams from '../../hooks/useQueryParams';
import DatePicker from '../DatePicker/DatePicker';
import styles from './HistoryFilter.module.scss';
import useEffectOnce from '../../hooks/useEffectOnce';

const messages = defineMessages({
	from: {
		id: 'historyBlock.from',
		defaultMessage: 'From',
	},
	to: {
		id: 'historyBlock.to',
		defaultMessage: 'To',
	},
});

enum DateType {
	FROM = 'startDate',
	TO = 'endDate',
}

const HistoryFilter: FC<PropsWithChildren> = ({ children }) => {
	const [[startDate, endDate], updateParams] = useQueryParams({
		startDate: undefined,
		endDate: undefined,
	});

	const didMount = useDidMount();

	const [inputDate, setInputDate] = useState({
		[DateType.FROM]: startDate ? new Date(startDate) : addMonths(new Date(), -1),
		[DateType.TO]: endDate ? new Date(endDate) : new Date(),
	});

	const formatDate = useCallback((value: Date | null) => {
		const dateToMillis = value ? new Date(endOfDay(new Date(value))).getTime() : null;
		return dateToMillis ? format(dateToMillis, 'yyyy-MM-dd') : null;
	}, []);

	useEffect(() => {
		if (didMount)
			updateParams({
				startDate: formatDate(inputDate.startDate),
				endDate: formatDate(inputDate.endDate),
				page: '1',
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputDate.startDate, inputDate.endDate]);

	useEffectOnce(() => {
		// on render load 1 month interval by default
		if (!startDate && !endDate) {
			updateParams(
				{
					startDate: formatDate(inputDate.startDate),
					endDate: formatDate(inputDate.endDate),
					page: '1',
				},
				true
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	});

	const onInputChange = useCallback(
		(date: any, type: DateType) => {
			setInputDate({
				...inputDate,
				[type]: date,
			});
		},
		[inputDate]
	);

	return (
		<div className={styles.container}>
			<DatePicker
				label={messages.from}
				maxDate={
					inputDate?.endDate ? startOfDay(new Date(inputDate.endDate || '')) : undefined
				}
				placeholderText="YYYY-MM-DD"
				onChange={(date) => onInputChange(date, DateType.FROM)}
				selected={inputDate.startDate}
				data-cy="startDate"
			/>
			<DatePicker
				label={messages.to}
				minDate={
					inputDate.startDate ? endOfDay(new Date(inputDate.startDate || '')) : undefined
				}
				placeholderText="YYYY-MM-DD"
				onChange={(date) => onInputChange(date, DateType.TO)}
				selected={inputDate.endDate}
				data-cy="endDate"
			/>
			{children}
		</div>
	);
};

export default HistoryFilter;
