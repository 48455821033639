import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { DetailsModalType } from '../../../../components/Modal/DetailsModal/DetailsModal';
import statusMsg from '../../../../messages/status.messages';
import { DepositCryptoHistoryItem } from '../../../../redux/DepositHistoryState/DepositHistoryTypes';
import { DepositStatus } from '../../DepositHistory/DepositHistory';
import depositMessages from '../../../../redux/DepositState/DepositMessages';
import styles from './CryptoDepositHistoryList.module.scss';
import HistoryListItem from '../../../../components/HistoryTable/HistoryListItem/HistoryListItem';

const PendingCategoryDepositStatuses = [
	'NEW',
	'PENDING',
	'SUSPENDED_HIGH_RISK',
	'FROZEN',
	'PENDING_AML_VERIFICATION',
	'FROZEN_AML_VERIFICATION',
	'SUSPENDED_AML_VERIFICATION',
	'CHARGED_BACK',
];

const ConfirmedCategoryDepositStatuses = ['CONFIRMED', 'FAILED', 'PAID'];

const depositHistoryCategories = {
	[DepositStatus.PENDING]: PendingCategoryDepositStatuses,
	[DepositStatus.CONFIRMED]: ConfirmedCategoryDepositStatuses,
};

interface Props {
	data: DepositCryptoHistoryItem[];
}

const CryptoDepositHistoryList = ({ data }: Props) => {
	const { formatMessage } = useIntl();

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{data &&
				data.length > 0 &&
				Object.keys(depositHistoryCategories).map((c: DepositStatus) => {
					const cryptoDepositData = data.filter((h: DepositCryptoHistoryItem) =>
						depositHistoryCategories[c].includes(h.status)
					);

					if (cryptoDepositData.length < 1) return null;
					return (
						<>
							<div className={styles.depositCategoryTitle}>
								{formatMessage(statusMsg[DepositStatus[c]])}
							</div>

							{cryptoDepositData.map(
								({
									amount,
									transNo,
									depositAccount,
									created,
									description,
									currency,
									network,
								}: DepositCryptoHistoryItem) => {
									return (
										<HistoryListItem
											className={classNames({
												[styles.pendingTran]: c === DepositStatus.PENDING,
											})}
											key={transNo}
											transNo={transNo}
											isTransNoVisible={false}
											dateCreated={created}
											status={formatMessage(statusMsg[DepositStatus[c]])}
											amount={amount}
											currency={currency}
											title={formatMessage(depositMessages.genericDeposit, {
												option: currency,
											})}
											receivedTo={depositAccount}
											detailsModalType={DetailsModalType.CRYPTO}
											description={description}
											network={network}
										/>
									);
								}
							)}
						</>
					);
				})}
		</>
	);
};

export default CryptoDepositHistoryList;
