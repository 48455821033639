import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import queryString, { ParsedQuery } from 'query-string';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import styles from './BankDeposits.module.scss';
import PaymentMethod, { PaymentMethodGroup } from '../../../redux/DepositState/DepositTypes';
import { RootState } from '../../../redux/Store';
import { fetchPaymentMethodsByGroup } from '../../../redux/DepositState/DepositActions';
import Loader from '../../../components/Loader/Loader';
import PageTitle from '../../../components/PageTitle/PageTitle';
import depositMessages from '../../../redux/DepositState/DepositMessages';
import DepositList from '../Shared/DepositList/DepositList';
import Seo from '../../../components/Seo/Seo';
import TestIds from '../../../test/TestIds';
import useEffectOnce from '../../../hooks/useEffectOnce';
import FilterSelect from '../../Withdraw/Shared/FilterSelect/FilterSelect';

const BankDeposits = () => {
	const { locale } = useIntl();
	const { search } = useLocation();

	const dispatch = useDispatch();
	const { paymentMethods } = useSelector((state: RootState) => state.DepositState);
	const { user: profile } = useSelector((state: RootState) => state.ProfileState);
	const { bank } = paymentMethods;

	const isUserVerified = !!profile?.verified;

	const parsedQuery: ParsedQuery<any> = queryString.parse(search);
	const [currency, setCurrency] = useState(parsedQuery?.currency || '');
	const [currencyList, setCurrencyList] = useState<CurrencyEnum[]>([]);

	const filteredPaymentMethods = useMemo(
		() =>
			paymentMethods.bank.data.filter((method: PaymentMethod) => {
				if (currency) {
					return (
						method.baseCurrencyCode === currency ||
						method.paymentMethodCurrencies.includes(currency)
					);
				}
				return method;
			}),
		[currency, paymentMethods.bank.data]
	);

	useEffect(() => {
		setCurrencyList(
			Array.from(
				new Set(
					bank?.data.flatMap(({ paymentMethodCurrencies }: any) => [
						...paymentMethodCurrencies,
					])
				)
			) || []
		);
	}, [bank]);

	useEffectOnce(() => {
		void dispatch(fetchPaymentMethodsByGroup(PaymentMethodGroup.BANK));
	});

	if (paymentMethods.bank.data.length === 0 || !profile)
		return <Loader className={styles.loader} />;
	return (
		<div className={styles.container} data-cy={TestIds.bankDepositsView}>
			<Seo title={depositMessages.metaBankDepositsTitle} />
			<PageTitle
				historyLink={{
					pathname: `/${locale}/deposit/history/fiat`,
					state: { backUrl: window.location.pathname },
				}}
				previousPageLink={`/${locale}/deposit`}
				isPreviousPageLinkVisibleOnMobile={false}
				title={depositMessages.bankDeposits}
			/>
			<FilterSelect value={currency} onChange={setCurrency} currencyList={currencyList} />
			<DepositList
				group={PaymentMethodGroup.BANK}
				paymentMethods={filteredPaymentMethods}
				isUserVerified={isUserVerified}
			/>
		</div>
	);
};

export default BankDeposits;
