import { Fragment } from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/Store';
import { PurchaseItem } from '../../../../redux/WithdrawState/WithdrawTypes';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';
import baseMsg from '../../../../messages/base.messages';
import useFormatAmount from '../../../../hooks/useFormatAmount';

interface DataProps {
	[key: string]: string;
}

interface MobileTopupsConfirmProps {
	data: DataProps[];
	currency: CurrencyEnum;
	className: string;
}

const MobileTopupsConfirm = ({
	data,
	currency,
	className,
}: MobileTopupsConfirmProps): JSX.Element => {
	const { id } = useParams<{ id: string }>();
	const { paymentMethods } = useSelector((state: RootState) => state.WithdrawState);
	const amountFormatter = useFormatAmount();
	const { topups } = paymentMethods;
	const { data: topupData } = topups;
	const currentTopup = topupData.filter((o: PurchaseItem) => o.id === id)[0];

	return (
		<>
			{data.map(({ amount, payAmount, phone, itemTitle }: DataProps) => (
				<Fragment key={phone}>
					<InfoInput
						className={className}
						label={withdrawMessages.receiversPhone}
						text={formatPhoneNumberIntl(phone)}
					/>
					{currentTopup && (
						<InfoInput
							className={className}
							label={withdrawMessages.purchaseProduct}
							text={currentTopup.title}
						/>
					)}
					<InfoInput
						className={className}
						label={withdrawMessages.purchaseItem}
						text={itemTitle}
					/>
					<InfoInput
						className={className}
						label={baseMsg.payAmount}
						text={`${amountFormatter(
							formatPrecision(payAmount, currency)
						)} ${currency} (${amountFormatter(
							formatPrecision(
								amount.split('/')[0],
								amount.split('/')[1] as CurrencyEnum
							)
						)} ${amount.split('/')[1]})`}
					/>
				</Fragment>
			))}
		</>
	);
};

export default MobileTopupsConfirm;
