import { ChangeEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import { CurrencyIcon, CurrencyEnum } from '@spectrocoin/sc-currencies';
import styles from './ECommPayDetails.module.scss';
import PaymentMethod from '../../../../redux/DepositState/DepositTypes';
import SelectOption from '../../../../interfaces/SelectOption';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Loader from '../../../../components/Loader/Loader';
import { Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import Select from '../../../../components/Select/Select';
import useDebounce from '../../../../hooks/useDebounce';
import { RootState } from '../../../../redux/Store';
import {
	applePayDepositURL,
	depositCalculateReceiveAmountURL,
	getPaymentMethodsDetailsURL,
	googlePayDepositURL,
} from '../../../../redux/endpoints';
import CurrencyInput from '../../../../components/CurrencyInput/CurrencyInput';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import { formatPrecision, toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import { isNumeric } from '../../../../helpers/globalFunctionHelpers/globalFunctionHelpers';
import depositMessages from '../../../../redux/DepositState/DepositMessages';
import inputStyles from '../../../../components/Input/Input.module.scss';
import errorMessages, {
	ErrorResponse,
} from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { toPlainAmount } from '../../../../helpers/currencyAmountHelper/currencyAmountHelper';
import baseMsg from '../../../../messages/base.messages';
import inputErrors from '../../../../messages/inputErrors.messages';
import Seo from '../../../../components/Seo/Seo';
import TestIds from '../../../../test/TestIds';
import useFormatAmount from '../../../../hooks/useFormatAmount';
import TransfersFormLayout from '../../../../layout/TransfersFormLayout/TransfersFormLayout';
import { HowToType } from '../../../../layout/TransfersFormLayout/HowTo/HowTo';
import usePolicyRoute from '../../../../hooks/usePolicyRoute';
import { useFormatRoute } from '../../../../helpers/languagePathHelper/languagePathHelper';
import routes from '../../../../route.messages';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	unexpectedError: {
		id: 'scvalidationexception.validate.unexpected_error.key',
		defaultMessage: 'Unexpected error occurred.',
	},
	depositWallet: {
		id: 'base.depositWallet',
		defaultMessage: 'Deposit wallet',
	},
	depositAmount: {
		id: 'base.deposit_amount',
		defaultMessage: 'Deposit amount',
	},
	next: {
		id: 'base.next',
		defaultMessage: 'Next',
	},
	depositWith: {
		id: 'walletdeposit.withTitle',
		defaultMessage: 'Deposit with {provider}',
	},
	depositError: {
		id: 'base.deposit_error',
		defaultMessage: 'Deposit failed',
	},
	terms: {
		id: 'ecommpay.terms',
		defaultMessage:
			'By clicking ‘Next’ you agree to the <gtc>Terms and Conditions</gtc> and <pp>Privacy Policy</pp> of SpectroCoin',
	},
	disclaimer: {
		id: 'ecommpay.disclaimer',
		defaultMessage:
			'Apple Pay is supported on Apple devices with Safari browser. Use on incompatible devices or browsers will cause errors.',
	},
});

interface FormState {
	depositAmount?: string;
	depositCurrencyCode?: string;
	receiveAmount: string;
	receiveCurrencyCode?: string;
	wallet?: string;
}

interface FormErrorState {
	depositAmount?: MessageDescriptor | null;
	receiveAmount?: MessageDescriptor | null;
}

interface Params {
	paymentMethodId: string;
}

interface DepositCalculateResponse {
	payAmount: string;
	payCurrencyCode: string;
	receiveAmount: number;
	receiveCurrencyCode: string;
}

export interface ProcessPaymentParams {
	depositCurrency: string;
	paymentMethodId: string;
	receiveAccountId: string;
	receiveAmount: number;
}

const ECommPayUrlMap = {
	ecommpay_google_pay: googlePayDepositURL(),
	ecommpay_apple_pay: applePayDepositURL(),
};

const ECommPayDetails = () => {
	const { formatMessage, locale } = useIntl();
	const { paymentMethodId } = useParams<Params>();
	const amountFormatter = useFormatAmount();
	const policyRoute = usePolicyRoute();
	const gtcRoute = useFormatRoute(routes.gtcTerms);

	const [isLoading, setIsLoading] = useState(true);
	const [paymentMethodDetails, setPaymentMethodDetails] = useState<PaymentMethod>();
	const [walletOptions, setWalletOptions] = useState<SelectOption[]>([]);
	const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
	const [formState, setFormState] = useState<FormState>({
		depositAmount: '',
		depositCurrencyCode: '',
		receiveAmount: '0.00',
		receiveCurrencyCode: '',
		wallet: '',
	});
	const [formErrorState, setFormErrorState] = useState<FormErrorState>({
		depositAmount: null,
		receiveAmount: null,
	});
	const { wallets } = useSelector((state: RootState) => state.AccountsState);

	const [errorMessage, setErrorMessage] = useState<string | null>();
	const {
		depositAmount,
		depositCurrencyCode,
		receiveAmount,
		receiveCurrencyCode,
		wallet,
	} = formState;

	const debouncedDepositAmount = useDebounce(depositAmount, 1000);

	const handleDepositFailure = ({ errorCode }: ErrorResponse) => {
		setIsButtonDisabled(false);
		setErrorMessage(formatMessage(errorMessages[errorCode] || messages.unexpectedError));
	};

	const processPayment = () => {
		const params: ProcessPaymentParams = {
			depositCurrency: depositCurrencyCode!,
			paymentMethodId,
			receiveAccountId: wallet!,
			receiveAmount: +receiveAmount,
		};

		axiosInstance
			.post(ECommPayUrlMap[paymentMethodDetails!.key], params)
			.then(({ data }: any) => {
				window.location.href = data.redirectUri;
			})
			.catch((e) => handleDepositFailure(e.response.data));
	};

	const handleSelectChange = ({ value }: SelectOption) => {
		const currencyCode = wallets?.find((account: Wallet) => account.id === value)?.currencyCode;

		setFormState((prevState: FormState) => ({
			...prevState,
			wallet: value,
			depositCurrencyCode: currencyCode,
			receiveCurrencyCode: currencyCode,
		}));
	};

	const handleInputChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
		setFormErrorState((prevState: FormErrorState) => ({
			...prevState,
			[name]: null,
		}));
		setFormState((prevState: FormState) => ({
			...prevState,
			depositAmount: value,
		}));
	};

	const validateForm = () => {
		if (!depositAmount || !receiveAmount) return true;
		let valid = true;
		let errorList = {};
		Object.keys(formState).forEach((item: any) => {
			if (!formState[item]) {
				errorList = {
					...errorList,
					[item]: inputErrors.cannotBeEmpty,
				};
				valid = false;
			}
		});
		if (!isNumeric(depositAmount)) {
			errorList = {
				...errorList,
				depositAmount: formatMessage(depositMessages.errorDepositAmountIsNumber),
			};
			valid = false;
		} else if (toDecimal(depositAmount, true).lessThanOrEqualTo(toDecimal(0))) {
			errorList = {
				...errorList,
				depositAmount: formatMessage(depositMessages.errorDepositAmountGreaterThanZero),
			};
			valid = false;
		} else if (minAmount && toDecimal(receiveAmount).lessThan(toDecimal(minAmount))) {
			errorList = {
				...errorList,
				receiveAmount: formatMessage(depositMessages.errorDepositAmountBelowMin, {
					minAmount: toDecimal(minAmount).toFixed(),
				}),
			};
			valid = false;
		} else if (maxAmount && toDecimal(receiveAmount).greaterThan(toDecimal(maxAmount))) {
			errorList = {
				...errorList,
				receiveAmount: formatMessage(depositMessages.errorDepositAmountAboveMax, {
					maxAmount: toDecimal(maxAmount).toFixed(),
				}),
			};
			valid = false;
		}

		setIsButtonDisabled(valid);
		setFormErrorState({
			...formErrorState,
			...errorList,
		});
		return valid;
	};

	const handleButtonClick = (e: any) => {
		e.preventDefault();
		if (validateForm()) processPayment();
	};

	useEffect(() => {
		setIsLoading(true);
		void axiosInstance
			.get<PaymentMethod>(getPaymentMethodsDetailsURL(paymentMethodId))
			.then(({ data: paymentMethod }) => {
				if (!wallets || !paymentMethod) return;

				const options = wallets
					.filter((account: Wallet) =>
						paymentMethod.paymentMethodCurrencies.includes(account.currencyCode)
					)
					.reduce((accumulator: SelectOption[], { currencyCode, id, label }: Wallet) => {
						const labelName = label ? `${label} (${currencyCode})` : `${currencyCode}`;

						accumulator.push({
							label: (
								<div className={styles.option}>
									<CurrencyIcon
										currencyType={currencyCode}
										className={styles.currencyIcon}
									/>
									<label>{labelName}</label>
								</div>
							),
							value: id,
						});
						return accumulator;
					}, []);

				const selectedWalletCurrencyCode = wallets.find(
					(account: Wallet) => account.id === options[0].value
				)!.currencyCode;

				setWalletOptions(options);
				setPaymentMethodDetails(paymentMethod);
				setFormState((prevState: FormState) => ({
					...prevState,
					receiveCurrencyCode: selectedWalletCurrencyCode,
					depositCurrencyCode: selectedWalletCurrencyCode,
					wallet: options[0].value!,
				}));
				setIsLoading(false);
			});
	}, [formatMessage, paymentMethodId, wallets]);

	useEffect(() => {
		if (!isLoading && debouncedDepositAmount && isNumeric(debouncedDepositAmount)) {
			void axiosInstance
				.post(depositCalculateReceiveAmountURL(), {
					payAmount: toPlainAmount(debouncedDepositAmount, true),
					payCurrencyCode: depositCurrencyCode,
					paymentMethodId,
					receiveCurrencyCode,
				})
				.then(({ data }: AxiosResponse<DepositCalculateResponse>) => {
					setFormState((prevState: FormState) => ({
						...prevState,
						receiveAmount: data.receiveAmount.toString(),
						receiveCurrencyCode: data.receiveCurrencyCode,
					}));
				});
		}
	}, [
		debouncedDepositAmount,
		depositCurrencyCode,
		isLoading,
		paymentMethodId,
		receiveCurrencyCode,
		wallet,
	]);

	if (isLoading) return <Loader className={styles.loader} />;
	const { maxAmount, minAmount, key } = paymentMethodDetails!;
	return (
		<div data-cy={TestIds.ecommpayDepositsDetailsView}>
			<Seo title={depositMessages.metaCardDepositsTitle} />
			<PageTitle
				title={formatMessage(messages.depositWith, {
					provider: paymentMethodDetails?.title,
				})}
				previousPageLink={`/${locale}/deposit/card`}
				isPreviousPageLinkVisibleOnDesktop
				isPreviousPageLinkVisibleOnMobile={false}
			/>
			{key === 'ecommpay_apple_pay' && (
				<NotificationMessage
					className={styles.disclaimer}
					message={formatMessage(messages.disclaimer)}
					style={NotificationStyle.Border}
					type={NotificationType.Warning}
					withIcon
				/>
			)}
			<TransfersFormLayout
				method={paymentMethodDetails}
				type={HowToType.DEPOSIT}
				title={paymentMethodDetails?.title}
				providerType={paymentMethodDetails?.key}
			>
				<div className={styles.form}>
					{errorMessage && (
						<NotificationMessage
							withIcon
							type={NotificationType.Error}
							style={NotificationStyle.Border}
							message={errorMessage}
						/>
					)}
					<Select
						className={styles.depositWalletSelect}
						labelClassName={inputStyles.label}
						id="wallet"
						label={formatMessage(messages.depositWallet)}
						name="wallet"
						onChangeObject={handleSelectChange}
						options={walletOptions}
						value={wallet || ''}
					/>
					<CurrencyInput
						id="depositAmount"
						labelClassName={inputStyles.label}
						inputContainerClassName={styles.currencyInputContainer}
						currencyCode={CurrencyEnum[depositCurrencyCode!]}
						label={messages.depositAmount}
						onChange={handleInputChange}
						value={depositAmount!}
						error={formErrorState.depositAmount}
					/>
					<div data-cy={TestIds.receiveAmount} className={styles.receiveAmount}>
						<div className={inputStyles.label}>
							<FormattedMessage {...baseMsg.receiveAmount} />
						</div>
						<div className={styles.value} data-cy={TestIds.receiveAmountValue}>
							{amountFormatter(
								formatPrecision(toPlainAmount(`${receiveAmount}`), CurrencyEnum.EUR)
							)}{' '}
							{receiveCurrencyCode}
						</div>
						{formErrorState.receiveAmount &&
							typeof formErrorState.receiveAmount === 'string' && (
								<div
									className={classNames(inputStyles.error, styles.receiveError)}
									data-cy={TestIds.receiveAmountError}
								>
									{formErrorState.receiveAmount}
								</div>
							)}
					</div>
					<div className={styles.terms}>
						{formatMessage(messages.terms, {
							gtc: (txt) => (
								<a className={styles.link} href={gtcRoute}>
									{txt}
								</a>
							),
							pp: (txt) => (
								<a className={styles.link} href={policyRoute}>
									{txt}
								</a>
							),
						})}
					</div>
					<Button
						buttonStyle={ButtonStyle.PRIMARY}
						className={styles.button}
						isDisabled={
							isButtonDisabled || toDecimal(receiveAmount).lessThanOrEqualTo(0)
						}
						type={ButtonType.BUTTON}
						onClick={handleButtonClick}
						text={formatMessage(messages.next)}
						data-cy={TestIds.button}
					/>
				</div>
			</TransfersFormLayout>
		</div>
	);
};

export default ECommPayDetails;
