import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { RootState } from '../../../../redux/Store';
import {
	getAllCards,
	getCard,
	getIsCardUser,
	setOrderStep,
} from '../../../../redux/CardsState/CardsActions';
import { get3dsPasswordTokenURL, getSet3dsPasswordTokenURL } from '../../../../redux/endpoints';
import { CardStatusType, OrderSteps } from '../../../../redux/CardsState/CardsTypes';
import BackButton from '../../../../components/BackButton/BackButton';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import { GetCurrentCard } from '../../../../helpers/cardsHelper/cardsHelper';
import CardsText, { TextAlignment } from '../CardsText/CardsText';
import CardStatus, { CardStatusEnum } from '../CardStatus/CardStatus';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import styles from './OnlineTransactionsPassword.module.scss';
import Seo from '../../../../components/Seo/Seo';
import inputErrors from '../../../../messages/inputErrors.messages';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	somethingWentWrong: {
		id: 'onlineTransactionsPassword.somethingWentWrong',
		defaultMessage: 'Something went wrong! Please try again later.',
	},
	cancel: {
		id: 'cardActivate.cancel',
		defaultMessage: 'Cancel',
	},
	back: {
		id: 'cardActivate.back',
		defaultMessage: 'Back to cards',
	},
	createTitle: {
		id: 'onlineTransactionsPassword.createTitle',
		defaultMessage: 'Create online transactions password',
	},
	updateTitle: {
		id: 'onlineTransactionsPassword.updateTitle',
		defaultMessage: 'Update online transactions password',
	},
	post: {
		id: 'onlineTransactionsPassword.post',
		defaultMessage:
			'Create a password for your online transactions with a minimum of <b>6 symbols</b>',
	},
	postUpdate: {
		id: 'onlineTransactionsPassword.postUpdate',
		defaultMessage:
			'Update a password for your online transactions with a minimum of <b>6 symbols</b>',
	},
	next: {
		id: 'onlineTransactionsPassword.next',
		defaultMessage: 'Next',
	},
	enterPassword: {
		id: 'onlineTransactionsPassword.enterPassword',
		defaultMessage: 'Enter password',
	},
	createPassword: {
		id: 'onlineTransactionsPassword.createPassword',
		defaultMessage: 'Create password',
	},
	repeatPassword: {
		id: 'onlineTransactionsPassword.repeatPassword',
		defaultMessage: 'Repeat password',
	},
	tooShort: {
		id: 'onlineTransactionsPassword.tooShortPassword',
		defaultMessage: 'Password must contain at least 6 symbols',
	},
	dontMatch: {
		id: 'onlineTransactionsPassword.dontMatch',
		defaultMessage: 'Passwords not match',
	},
	successMsg: {
		id: 'onlineTransactionsPassword.success',
		defaultMessage: 'Set password successfully',
	},
	passwordTheSame: {
		id: 'onlineTransactionsPassword.passwordTheSame',
		defaultMessage: 'New and old 3D-Secure password is the same',
	},
	mobileBack: {
		id: 'load.back',
		defaultMessage: 'Back to card',
	},
	metaTitle: {
		id: 'onlineTransactionsPassword.metaTitle',
		defaultMessage: 'Online transactions password',
	},
});

interface OnlineTransactionsPasswordProps {
	isOrder?: boolean;
}

interface FormProps {
	createPassword: string;
	repeatPassword: string;
}

interface FormErrorsProps {
	createPassword: MessageDescriptor | null;
	repeatPassword: MessageDescriptor | null;
}

const OnlineTransactionsPassword = ({ isOrder = false }: OnlineTransactionsPasswordProps) => {
	const { url } = useRouteMatch();
	const { formatMessage } = useIntl();
	const { state } = useLocation();
	const { isTablet } = useSelector((store: RootState) => store.AppState);
	const dispatch = useDispatch();
	const card = GetCurrentCard();
	const [form, setForm] = useState<FormProps>({
		createPassword: '',
		repeatPassword: '',
	});
	const [formErrors, setFormErrors] = useState<FormErrorsProps>({
		createPassword: null,
		repeatPassword: null,
	});
	const [generalError, setGeneralError] = useState<MessageDescriptor | null>(null);
	const [isFromSecurity, setIsFromSecurity] = useState<boolean>(false);
	const [isSuccess, setIsSuccess] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { orderData } = useSelector((store: RootState) => store.CardsState);

	const validateForm = (formToValidate: FormProps) => {
		const { createPassword, repeatPassword } = formToValidate;
		let errors: FormErrorsProps = {
			createPassword: null,
			repeatPassword: null,
		};
		if (!createPassword)
			errors = {
				...errors,
				createPassword: inputErrors.cannotBeEmpty,
			};
		if (!repeatPassword)
			errors = {
				...errors,
				repeatPassword: inputErrors.cannotBeEmpty,
			};
		if (createPassword && createPassword.length < 6)
			errors = {
				...errors,
				createPassword: messages.tooShort,
			};
		if (repeatPassword && repeatPassword.length < 6)
			errors = {
				...errors,
				repeatPassword: messages.tooShort,
			};
		if (createPassword && repeatPassword && createPassword !== repeatPassword)
			errors = {
				createPassword: messages.dontMatch,
				repeatPassword: messages.dontMatch,
			};
		setFormErrors(errors);
		return Object.values(errors).every((value: any) => {
			if (value === null) {
				return true;
			}
			return false;
		});
	};

	const postPassword = useCallback(
		(auth: string) => {
			void axiosInstance
				.post(
					getSet3dsPasswordTokenURL(card?.cardId || orderData?.cardId || ''),
					{ password: form.createPassword },
					{
						headers: {
							Authorization: auth,
						},
					}
				)
				.then(() => {
					if (isOrder) {
						dispatch(getAllCards());
						dispatch(getIsCardUser());
						return dispatch(setOrderStep(OrderSteps.SUCCESS));
					}
					return (new Promise((resolve) => {
						resolve(dispatch(getCard(card?.id || '')));
					}) as any).then(() => {
						return setIsSuccess(true);
					});
				})
				.catch(({ response }) => {
					const { data } = response;
					if (isOrder) {
						return dispatch(setOrderStep(OrderSteps.FAILURE));
					}
					if (data && data.metadata.messages[0].code === 'GIP203E018') {
						return setGeneralError(messages.passwordTheSame);
					}
					return setGeneralError(messages.somethingWentWrong);
				})
				.then(() => {
					return setIsLoading(false);
				});
		},
		[card?.cardId, card?.id, dispatch, form.createPassword, isOrder, orderData?.cardId]
	);

	const handleSubmit = useCallback(
		(event: FormEvent) => {
			event.preventDefault();
			if (validateForm(form)) {
				setIsLoading(true);
				void axiosInstance
					.get(get3dsPasswordTokenURL(card?.id || orderData?.pinId || ''))
					.then(({ data }) => {
						return postPassword(data.authorizationHeader);
					})
					.catch(() => {
						return setGeneralError(messages.somethingWentWrong);
					})
					.then(() => {
						return setIsLoading(false);
					});
			}
		},
		[card?.id, form, orderData?.pinId, postPassword]
	);

	const handleChange = ({ target: { value, name } }: ChangeEvent<HTMLInputElement>) => {
		setGeneralError(null);
		setFormErrors({
			...formErrors,
			[name]: null,
		});
		if (value.length <= 32)
			return setForm({
				...form,
				[name]: value,
			});
		return null;
	};

	const renderView = () => (
		<>
			<Seo title={messages.metaTitle} />
			{isSuccess ? (
				<CardStatus
					status={CardStatusEnum.COMPLETED}
					title={messages.successMsg}
					fullLink={
						isFromSecurity
							? url.replace('online-transactions-password', 'card-security')
							: url.replace('online-transactions-password', 'history')
					}
				/>
			) : (
				<>
					<InfoHead
						title={formatMessage(
							card?.created3dsPassword ? messages.updateTitle : messages.createTitle
						)}
					/>
					<CardsText align={TextAlignment.CENTER}>
						{card?.created3dsPassword ? (
							<FormattedMessage
								{...messages.postUpdate}
								values={{ b: (...text) => <b>{text}</b> }}
							/>
						) : (
							<FormattedMessage
								{...messages.post}
								values={{ b: (...text) => <b>{text}</b> }}
							/>
						)}
					</CardsText>
					<form onSubmit={handleSubmit} className={styles.form}>
						{generalError && (
							<NotificationMessage
								withIcon
								type={NotificationType.Error}
								style={NotificationStyle.Border}
								message={<FormattedMessage {...generalError} />}
							/>
						)}
						<Input
							label={messages.createPassword}
							labelClassName={styles.label}
							onChangeEvent={handleChange}
							placeholder={messages.enterPassword}
							value={form.createPassword}
							name="createPassword"
							type="password"
							className={styles.input}
							errorMessage={formErrors.createPassword}
						/>
						<Input
							label={messages.repeatPassword}
							labelClassName={styles.label}
							onChangeEvent={handleChange}
							placeholder={messages.enterPassword}
							value={form.repeatPassword}
							name="repeatPassword"
							type="password"
							className={styles.input}
							errorMessage={formErrors.repeatPassword}
						/>
						<Button
							type={ButtonType.SUBMIT}
							text={messages.next}
							className={styles.button}
							isLoading={isLoading}
							isDisabled={isLoading}
						/>
						{card?.status !== CardStatusType.PENDING && !isOrder && (
							<Button
								type={ButtonType.LINK}
								text={messages.cancel}
								className={styles.button}
								buttonStyle={ButtonStyle.BORDERLESS}
								link={url.replace('online-transactions-password', 'history')}
							/>
						)}
					</form>
				</>
			)}
		</>
	);

	useEffect(() => {
		if (state?.fromSecurity) setIsFromSecurity(state?.fromSecurity);
	}, [state]);

	if (isOrder)
		return (
			<div className={styles.container}>
				<BackButton
					className={styles.back}
					onClick={() => dispatch(setOrderStep(OrderSteps.CONFIRM))}
				/>
				{renderView()}
			</div>
		);
	return (
		<WhiteContainer
			backButtonLink={isTablet ? url.replace('online-transactions-password', 'history') : ''}
			backButtonText={messages.mobileBack}
		>
			{renderView()}
		</WhiteContainer>
	);
};

export default OnlineTransactionsPassword;
