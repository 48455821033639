/* eslint-disable import/prefer-default-export */
import { getApiKeysURL, getSignleApiKeyURL } from '../endpoints';
import { AppDispatch } from '../Store';
import { SettingsActionTypes, ApiKeysPendingAction } from './SettingsTypes';
import toggleModal from '../ModalState/ModalActions';
import {
	ErrorMessageCodes,
	ErrorResponse,
} from '../../helpers/errorMessageHelper/errorMessageHelper';
import SettingsMessages from './SettingsMessages';
import axiosInstance from '../../helpers/axiosInstance';

const getError = (error: ErrorResponse) => {
	const { errorCode } = error;
	if (errorCode === ErrorMessageCodes.A_22) {
		return SettingsMessages.apiKeyAlreadyExists;
	}
	return error;
};

export const getApiKey = (clientId: string) => async (dispatch: AppDispatch) => {
	dispatch({
		type: SettingsActionTypes.API_SIGNLE_KEY_LOADING,
	});
	await axiosInstance
		.get(getSignleApiKeyURL(clientId))
		.then((res) => {
			return dispatch({
				type: SettingsActionTypes.API_SIGNLE_KEY_SUCCESS,
				data: res.data,
			});
		})
		.catch(({ response: { data: errorData } }) => {
			return dispatch({
				type: SettingsActionTypes.API_SIGNLE_KEY_ERROR,
				errorData,
			});
		});
};

export const getApiKeys = () => async (dispatch: AppDispatch) => {
	dispatch<ApiKeysPendingAction>({
		type: SettingsActionTypes.API_KEYS_LOADING,
	});
	await axiosInstance
		.get(getApiKeysURL())
		.then(({ data }) => {
			dispatch({
				type: SettingsActionTypes.API_KEYS_SUCCESS,
				data,
			});
		})
		.catch(({ response: { data: errorData } }) => {
			dispatch({
				type: SettingsActionTypes.API_KEYS_ERROR,
				errorData,
			});
		});
};

export const createApiKey = (data: any, Modal: React.ComponentType<any>) => async (
	dispatch: AppDispatch
) => {
	dispatch<ApiKeysPendingAction>({
		type: SettingsActionTypes.API_KEYS_LOADING,
	});

	const ipWhitelist = data.ipAddress ? [data.ipAddress, ...data.ipWhitelist] : data.ipWhitelist;
	await axiosInstance
		.post(getApiKeysURL(), {
			name: data.name,
			scopes: Object.keys(data.scopes).filter((scope) => data.scopes[scope].isChecked),
			ipWhitelist,
		})
		.then((res) => {
			dispatch(toggleModal(<Modal {...res.data} />, false));
			dispatch({
				type: SettingsActionTypes.API_KEY_CREATE_SUCCESS,
				data: res.data,
			});
		})
		.catch(({ response: { data: errorData } }) => {
			dispatch({
				type: SettingsActionTypes.API_KEY_CREATE_ERROR,
				error: getError(errorData),
			});
		});
};

export const editApiKey = (clientId: string, data: any) => async (dispatch: AppDispatch) => {
	dispatch({
		type: SettingsActionTypes.API_KEY_EDIT_LOADING,
	});

	const ipWhitelist = data.ipAddress ? [data.ipAddress, ...data.ipWhitelist] : data.ipWhitelist;
	await axiosInstance
		.put(getSignleApiKeyURL(clientId), {
			name: data.name,
			scopes: Object.keys(data.scopes).filter((scope) => data.scopes[scope].isChecked),
			ipWhitelist,
			enabled: data.enabled,
		})
		.then((res) => {
			dispatch({
				type: SettingsActionTypes.API_KEY_EDIT_SUCCESS,
				data: res.data,
			});
		})
		.catch(({ response: { data: errorData } }) => {
			dispatch({
				type: SettingsActionTypes.API_KEY_EDIT_ERROR,
				error: getError(errorData),
			});
		});
};

export const deleteApiKey = (clientId: string) => async (dispatch: AppDispatch) => {
	dispatch({
		type: SettingsActionTypes.API_KEY_DELETE_LOADING,
	});
	await axiosInstance
		.delete(getSignleApiKeyURL(clientId))
		.then(() => {
			dispatch({
				type: SettingsActionTypes.API_KEY_DELETE_SUCCESS,
				clientId,
			});
			dispatch(toggleModal());
		})
		.catch(({ response: { data: errorData } }) => {
			dispatch({
				type: SettingsActionTypes.API_KEY_EDIT_ERROR,
				errorData,
			});
		});
};

export const enableDisableApiKey = (clientId: string, enabled: boolean) => async (
	dispatch: AppDispatch
) => {
	dispatch({
		type: SettingsActionTypes.API_KEY_EDIT_LOADING,
	});
	await axiosInstance
		.patch(getSignleApiKeyURL(clientId), {
			enabled,
		})
		.then((res) => {
			dispatch({
				type: SettingsActionTypes.API_KEY_EDIT_SUCCESS,
				data: res.data,
			});
		})
		.catch(({ response: { data: errorData } }) => {
			dispatch({
				type: SettingsActionTypes.API_KEY_EDIT_ERROR,
				errorData,
			});
		});
};

export const resetApiState = () => (dispatch: AppDispatch) => {
	dispatch({
		type: SettingsActionTypes.RESET_API_KEYS_STATE,
	});
};
