import classNames from 'classnames';
import { FC } from 'react';
import styles from './StatusLabel.module.scss';

interface Props {
	status: string;
	className?: string;
}

const StatusLabel: FC<Props> = ({ status, className }) => {
	return (
		<div className={classNames(styles.status, styles[status?.toUpperCase()], className)}>
			{status}
		</div>
	);
};

export default StatusLabel;
