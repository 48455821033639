import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useValidation from '../../../../hooks/useValidation';
import Loading from '../../Shared/Loading/Loading';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { RemoteData, RemoteStatus } from '../../../../interfaces/RemoteData';
import TestIds from '../../../../test/TestIds';
import useMerchantRoutes, { MerchantRoutes } from '../../../../hooks/useMerchantRoutes';
import NoProjects from '../../Shared/NotFound/NotFound';
import MerchantsActions from '../../../../redux/MerchantsState/MerchantsActions';
import MerchantsSelectors from '../../../../redux/MerchantsState/MerchantsSelectors';
import Button, { ButtonType } from '../../../../components/Button/Button';
import ProjectForm, { useProjectFormValidator } from '../../Shared/ProjectForm/ProjectForm';
import PageTitle from '../../Shared/PageTitle/PageTitle';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';
import { Project, ProjectEditForm } from '../../../../redux/MerchantsState/MerchantTypes';

import styles from './EditMerchantsProject.module.scss';
import baseMsg from '../../../../messages/base.messages';
import { getMerchantsProjectUrl } from '../../../../redux/endpoints';
import EditMerchantsProjectContext from './Context/EditMerchantsProjectContext';
import axiosInstance from '../../../../helpers/axiosInstance';

const EditMerchantsProjectContent: FC = () => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const { push } = useHistory();
	const { getUrl, getParams } = useMerchantRoutes();

	const { projectId = '' } = getParams();

	const { form, action, updateForm, setAction } = useContext(EditMerchantsProjectContext);
	const {
		name,
		description,
		autoConvert,
		currencies,
		publicKey,
		testStatus,
		isEnabled,
		forVerifiedPayers,
		forPayerFee,
	} = form!;

	const project = useSelector(MerchantsSelectors.projects.data.getProjectDataById(projectId))!;
	const { status, error } = action;

	const onSubmit = useCallback(() => {
		setAction({ status: RemoteStatus.InProgress });
		axiosInstance
			.put<Project>(getMerchantsProjectUrl(projectId), {
				description,
				name,
				publicKey,
				testStatus,
				enabled: isEnabled,
				receiveAccountId: autoConvert,
				payCurrencies: currencies,
				forVerifiedPayers,
				forPayerFee,
			})
			.then(({ data }) => {
				dispatch(MerchantsActions.projects.edit.setActionFulfilled(data));
				setAction({ status: RemoteStatus.Done, data });
			})
			.catch((e) => {
				setAction({ status: RemoteStatus.Error, error: e.response?.data });
			});
	}, [
		projectId,
		name,
		description,
		autoConvert,
		currencies?.join(),
		publicKey,
		testStatus,
		isEnabled,
		forVerifiedPayers,
		forPayerFee,
	]);

	const validator = useProjectFormValidator();
	const [isValid] = useValidation({ name, description }, validator);

	const isDirty =
		project.name !== name ||
		project.description !== description ||
		project.receiveAccountId !== autoConvert ||
		project.publicKey !== publicKey ||
		project.testStatus !== testStatus ||
		project.enabled !== isEnabled ||
		project.forVerifiedPayers !== forVerifiedPayers ||
		project.forPayerFee !== forPayerFee ||
		JSON.stringify(project.payCurrencies || []) !== JSON.stringify(currencies || []);

	useEffect(() => {
		if (status === RemoteStatus.Done) push(getUrl(MerchantRoutes.ProjectView));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	if (!projectId) return null;

	return (
		<>
			<PageTitle
				title={formatMessage(messages.editProject)}
				className={styles.title}
				backLink={getUrl(MerchantRoutes.ProjectView, { projectId })}
			/>
			<ProjectForm
				formError={
					status === RemoteStatus.Error && (
						<NotificationMessage
							withIcon
							data-cy={TestIds.ProjectEditError}
							type={NotificationType.Error}
							style={NotificationStyle.Border}
							message={formatMessage(getErrorMessageOrDefault(error))}
						/>
					)
				}
				name={name}
				onNameChange={(value) => updateForm({ name: value })}
				description={description}
				onDescriptionChange={(value) => updateForm({ description: value })}
				autoConvert={autoConvert}
				onAutoConvertChange={
					project.isUsed === false
						? (value) => updateForm({ autoConvert: value })
						: undefined
				}
				acceptCurrencies={currencies}
				onAcceptCurrenciesChange={
					project.isUsed === false
						? (value) => updateForm({ currencies: value ?? [] })
						: undefined
				}
				publicKey={publicKey}
				onPublicKeyChange={(value) => updateForm({ publicKey: value })}
				testStatus={testStatus}
				onTestStatusChange={(value) => updateForm({ testStatus: value })}
				isEnabled={isEnabled}
				onIsEnabledChange={(value) => updateForm({ isEnabled: value })}
				forVerifiedPayers={forVerifiedPayers}
				onForVerifiedPayersChange={
					project.isUsed === false
						? (value) => updateForm({ forVerifiedPayers: value })
						: undefined
				}
				forPayerFee={forPayerFee}
				onForPayerFeeChange={
					project.isUsed === false
						? (value) => updateForm({ forPayerFee: value })
						: undefined
				}
			>
				<Button
					data-cy={TestIds.ProjectEditSubmit}
					isDisabled={status === RemoteStatus.InProgress || !isDirty || !isValid}
					className={styles.submit}
					type={ButtonType.BUTTON}
					text={baseMsg.submit}
					onClick={onSubmit}
				/>
			</ProjectForm>
		</>
	);
};

const EditMerchantsProject: FC = () => {
	const { getParams } = useMerchantRoutes();
	const { projectId = '' } = getParams();
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();

	const [form, setForm] = useState<ProjectEditForm | null>(null);
	const [action, setAction] = useState<RemoteData<Project>>({ status: RemoteStatus.None });
	const updateForm = useCallback((value: Partial<ProjectEditForm>) => {
		setForm((current) => (current ? { ...current, ...value } : null));
	}, []);

	const project = useSelector(MerchantsSelectors.projects.data.getProjectDataById(projectId));
	const { status: projectStatus } = useSelector(
		MerchantsSelectors.projects.data.getProjectById(projectId)
	);

	useEffect(() => {
		if (
			projectStatus === RemoteStatus.None ||
			(projectStatus === RemoteStatus.Done && project?.isUsed === null)
		)
			dispatch(MerchantsActions.projects.data.fetchProject(projectId));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectStatus]);

	useEffect(() => {
		if (project) {
			const {
				name,
				description,
				receiveAccountId: autoConvert,
				publicKey,
				testStatus,
				enabled: isEnabled,
				payCurrencies: currencies,
				forVerifiedPayers,
				forPayerFee,
			} = project;

			setForm({
				name,
				description,
				autoConvert,
				publicKey,
				testStatus,
				isEnabled,
				currencies: currencies || [],
				forVerifiedPayers,
				forPayerFee,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project]);

	if (projectStatus === RemoteStatus.None) return null;

	const state = { form: form!, updateForm, action, setAction };

	return (
		<EditMerchantsProjectContext.Provider value={state}>
			<WhiteContainer className={styles.container}>
				{projectStatus === RemoteStatus.InProgress && <Loading />}
				{projectStatus === RemoteStatus.Error && (
					<NoProjects message={formatMessage(messages.projectNotFound)} />
				)}
				{projectStatus === RemoteStatus.Done && form && <EditMerchantsProjectContent />}
			</WhiteContainer>
		</EditMerchantsProjectContext.Provider>
	);
};

export default EditMerchantsProject;
