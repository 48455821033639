import classNames from 'classnames';
import { ReactNode } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import styles from './CardsText.module.scss';

export enum TextAlignment {
	RIGHT = 'tar',
	LEFT = 'tal',
	CENTER = 'tac',
}

interface CardsTextProps {
	text?: MessageDescriptor;
	align?: TextAlignment;
	children?: ReactNode;
}

const CardsText = ({ text, align = TextAlignment.LEFT, children }: CardsTextProps) => {
	const { formatMessage } = useIntl();

	return (
		<p className={classNames(styles.post, [styles[align]])}>
			{text && !children && formatMessage(text)}
			{!text && children && children}
		</p>
	);
};

export default CardsText;
