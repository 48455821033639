import { useEffect, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import {
	DepositActionTypes,
	ResetVoucher,
	SetAccountId,
	SetVoucherCode,
	VoucherDepositView,
} from '../../../redux/DepositState/DepositTypes';
import VoucherDepositErrorView from './views/VoucherDepositErrorView';
import SelectVoucherAccountForm from './views/SelectVoucherAccountForm';
import EnterVoucherCodeForm from './views/EnterVoucherCodeForm';
import depositMessages from '../../../redux/DepositState/DepositMessages';
import Loader from '../../../components/Loader/Loader';
import { depositVoucher } from '../../../redux/DepositState/DepositActions';
import { RootState } from '../../../redux/Store';
import styles from './VoucherDeposits.module.scss';
import PageTitle from '../../../components/PageTitle/PageTitle';
import DepositResult, { ResultStatus } from '../DepositResult/DepositResult';
import { Wallet } from '../../../redux/AccountsState/AccountsTypes';
import InfoInput from '../../../components/InfoInput/InfoInput';
import baseMsg from '../../../messages/base.messages';
import Seo from '../../../components/Seo/Seo';
import TestIds from '../../../test/TestIds';

const messages = defineMessages({
	receiveAccount: {
		id: 'base.receive_account',
		defaultMessage: 'Receive account',
	},
});

const VoucherDeposits = () => {
	const dispatch = useDispatch();
	const { replace }: any = useHistory();
	const { search, pathname } = useLocation();
	const { locale } = useIntl();

	const { wallets: accounts } = useSelector((state: RootState) => state.AccountsState);
	const { voucher } = useSelector((state: RootState) => state.DepositState);
	const {
		accountId,
		accountListForSelection,
		isRequestPending,
		view,
		voucherCode,
		amount,
	} = voucher;

	const depositAccount = useMemo(() => {
		return accounts?.find((account: Wallet) => account.id === accountId);
	}, [accounts, accountId]);

	if (search.includes('init')) {
		replace({ pathname, state: {} });
		dispatch<ResetVoucher>({ type: DepositActionTypes.RESET_VOUCHER });
	}

	useEffect(() => {
		if (
			!isRequestPending &&
			voucherCode &&
			(view === VoucherDepositView.ENTER_CODE ||
				(view === VoucherDepositView.SELECT_ACCOUNT && accountId))
		) {
			void dispatch(depositVoucher(accountId, voucherCode));
		}
	}, [accountId, dispatch, isRequestPending, view, voucherCode]);

	if (isRequestPending) return <Loader />;

	return (
		<section data-cy={TestIds.voucherDepositsView}>
			<Seo title={depositMessages.metaVoucherDepositsTitle} />
			<PageTitle title={depositMessages.voucherDeposit} />
			<div className={styles.container}>
				{view === VoucherDepositView.ENTER_CODE && (
					<EnterVoucherCodeForm
						onSubmit={({
							voucherCode: submittedVoucherCode,
						}: {
							voucherCode: string;
						}) => {
							dispatch<SetVoucherCode>({
								type: DepositActionTypes.SET_VOUCHER_CODE,
								voucherCode: submittedVoucherCode,
							});
						}}
						isRequestPending={isRequestPending}
					/>
				)}
				{view === VoucherDepositView.SELECT_ACCOUNT && (
					<SelectVoucherAccountForm
						onSubmit={({ accountId: submittedAccountId }: { accountId: string }) => {
							dispatch<SetAccountId>({
								type: DepositActionTypes.SET_ACCOUNT_ID,
								accountId: submittedAccountId,
							});
						}}
						accountList={accountListForSelection}
						isRequestPending={isRequestPending}
					/>
				)}
				{view === VoucherDepositView.SUCCESS && depositAccount && (
					<DepositResult
						status={ResultStatus.SUCCESS}
						previousPageLink={`/${locale}/account/history/${depositAccount.currencyCode}/${depositAccount.id}`}
						className={styles.depositResultContainer}
					>
						<div className={styles.voucherSuccessViewInfo}>
							<InfoInput
								id="receiveAccount"
								label={messages.receiveAccount}
								text={depositAccount.currencyName}
								currency={CurrencyEnum[depositAccount.currencyCode]}
								isTextWithIcon
							/>
							<InfoInput
								id="receiveAmount"
								label={baseMsg.receiveAmount}
								currency={CurrencyEnum[depositAccount.currencyCode]}
								balance={amount!}
							/>
						</div>
					</DepositResult>
				)}
				{view === VoucherDepositView.ERROR && (
					<VoucherDepositErrorView accountId={accountId as string} />
				)}
			</div>
		</section>
	);
};

export default VoucherDeposits;
