import { FC } from 'react';
import emptySvg from '../../../../images/empty.svg';
import TestIds from '../../../../test/TestIds';

import styles from './NotFound.module.scss';

type NotFoundProps = {
	message: string;
};

const NotFound: FC<NotFoundProps> = ({ message }) => {
	return (
		<div data-cy={TestIds.NotFound} className={styles.noProjects}>
			<img src={emptySvg} alt="Not found" />
			<span>{message}</span>
		</div>
	);
};

export default NotFound;
