/* eslint-disable no-param-reassign */
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import { fromEntries } from '../../../../helpers/objectHelper/objectHelper';
import {
	OrdersDataActionType,
	OrderDataUpdateOrdersAction,
	OrderDataFetchOrderFulfilledAction,
	OrderDataFetchOrderPendingAction,
	OrderDataFetchOrderRejectedAction,
	OrderDataUpdateOrderAction,
	OrdersDataState,
} from './OrdersDataTypes';

const initialState: OrdersDataState = {
	orders: {},
};

export default (
	state = initialState,
	action:
		| OrderDataUpdateOrdersAction
		| OrderDataFetchOrderFulfilledAction
		| OrderDataFetchOrderPendingAction
		| OrderDataFetchOrderRejectedAction
		| OrderDataUpdateOrderAction
): OrdersDataState => {
	switch (action.type) {
		case OrdersDataActionType.FETCH_ORDER_PENDING:
			return {
				...state,
				orders: {
					...state.orders,
					[action.payload]: { status: RemoteStatus.InProgress },
				},
			};
		case OrdersDataActionType.FETCH_ORDER_REJECTED:
			return {
				...state,
				orders: {
					...state.orders,
					[action.payload]: { status: RemoteStatus.Error },
				},
			};
		case OrdersDataActionType.FETCH_ORDER_FULFILLED:
			return {
				...state,
				orders: {
					...state.orders,
					[action.payload.id]: {
						status: RemoteStatus.Done,
						data: {
							...action.payload,
							created: action.payload.created,
							validUntil: action.payload.validUntil,
						},
					},
				},
			};
		case OrdersDataActionType.UPDATE_ORDER:
			return {
				...state,
				orders: {
					...state.orders,
					[action.payload.id]: {
						status: RemoteStatus.Done,
						data: {
							...action.payload,
							created: action.payload.created,
							validUntil: action.payload.validUntil,
						},
					},
				},
			};
		case OrdersDataActionType.UPDATE_ORDERS:
			return {
				...state,
				orders: {
					...state.orders,
					...fromEntries(
						action.payload.map((x) => [
							x.id,
							{
								status: RemoteStatus.Done,
								data: {
									...x,
									created: x.created,
									validUntil: x.validUntil,
								},
							},
						])
					),
				},
			};
		default:
			return state;
	}
};
