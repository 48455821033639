import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';
import styles from './VoucherViews.module.scss';
import Select from '../../../../components/Select/Select';
import SelectOption from '../../../../interfaces/SelectOption';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import { Wallet } from '../../../../redux/AccountsState/AccountsTypes';

const messages = defineMessages({
	useAccount: {
		id: 'base.use_account',
		defaultMessage: 'Use Account',
	},
	submitting: {
		id: 'base.submitting',
		defaultMessage: 'Submitting...',
	},
	receiveAccount: {
		id: 'base.receive_account',
		defaultMessage: 'Receive account',
	},
});

interface SelectVoucherAccountFormProps {
	accountList: Wallet[];
	isRequestPending: boolean;
	onSubmit: ({ accountId }: { accountId: string }) => void;
}

const SelectVoucherAccountForm = ({
	accountList,
	isRequestPending,
	onSubmit,
}: SelectVoucherAccountFormProps) => {
	const { formatMessage } = useIntl();
	const [value, setValue] = useState<string | null>(null);
	const options = accountList.map((account) => ({ label: account.label, value: account.id }));
	return (
		<div className={styles.container}>
			<Select
				name="accountId"
				label={formatMessage(messages.receiveAccount)}
				options={options}
				value={value}
				onChangeObject={(option: SelectOption) => {
					setValue(option.value);
				}}
				data-cy="selectVoucherAccount"
			/>
			<div className={styles.centeredContainer}>
				<Button
					data-cy="selectVoucherAccountButton"
					type={ButtonType.BUTTON}
					className={classNames(ButtonStyle.PRIMARY, styles.button, {
						disabled: isRequestPending || !value,
					})}
					onClick={() => onSubmit({ accountId: value! })}
					text={formatMessage(
						!isRequestPending ? messages.useAccount : messages.submitting
					)}
				/>
			</div>
		</div>
	);
};

export default SelectVoucherAccountForm;
