import { useIntl, defineMessages } from 'react-intl';
import Button, { ButtonType } from '../../../../components/Button/Button';
import { KeywordType } from '../../CardsHub/CardsHub';
import styles from './CardsFilter.module.scss';

const messages = defineMessages({
	notActive: {
		id: 'cardsFilter.notActive',
		defaultMessage: 'not active',
	},
	active: {
		id: 'cardsFilter.active',
		defaultMessage: 'active',
	},
	locked: {
		id: 'cardsFilter.locked',
		defaultMessage: 'locked',
	},
	expired: {
		id: 'cardsFilter.expired',
		defaultMessage: 'expired',
	},
	plastic: {
		id: 'cardsFilter.plastic',
		defaultMessage: 'plastic',
	},
	virtual: {
		id: 'cardsFilter.virtual',
		defaultMessage: 'virtual',
	},
	blocked: {
		id: 'cardsFilter.blocked',
		defaultMessage: 'blocked',
	},
	filterTitle: {
		id: 'manageWallets.filterTitle',
		defaultMessage: 'Filter by Keywords',
	},
	orderCard: {
		id: 'cardsFilter.orderNew',
		defaultMessage: 'Order new card',
	},
});

interface CardsFilterProps {
	changeFilter: (keyword: KeywordType) => void;
	keywords: KeywordType[];
}

const CardsFilter = ({ changeFilter, keywords }: CardsFilterProps) => {
	const { locale } = useIntl();
	// const { isMobile } = useSelector((state: RootState) => state.AppState);
	return (
		<div className={styles.container}>
			<Button
				link={`/${locale}/cards/order`}
				text={messages.orderCard}
				type={ButtonType.LINK}
			/>
			{/* NOT FOR MVP */}
			{/* <div className={styles.filterContainer}>
				<div className={styles.filterTitle}>{formatMessage(messages.filterTitle)}</div>
				<ul className={styles.filter}>
					<li
						onClick={() => changeFilter(KeywordType.NOT_ACTIVE)}
						className={classNames({
							[styles.active]: keywords.includes(KeywordType.NOT_ACTIVE),
						})}
					>
						{formatMessage(messages.notActive)}
					</li>
					<li
						onClick={() => changeFilter(KeywordType.ACTIVE)}
						className={classNames({
							[styles.active]: keywords.includes(KeywordType.ACTIVE),
						})}
					>
						{formatMessage(messages.active)}
					</li>
					<li
						onClick={() => changeFilter(KeywordType.LOCKED)}
						className={classNames({
							[styles.noMargin]: isMobile,
							[styles.active]: keywords.includes(KeywordType.LOCKED),
						})}
					>
						{formatMessage(messages.locked)}
					</li>
					{isMobile && <br />}
					<li
						onClick={() => changeFilter(KeywordType.EXPIRED)}
						className={classNames({
							[styles.active]: keywords.includes(KeywordType.EXPIRED),
						})}
					>
						{formatMessage(messages.expired)}
					</li>
					<li
						onClick={() => changeFilter(KeywordType.PLASTIC)}
						className={classNames({
							[styles.active]: keywords.includes(KeywordType.PLASTIC),
						})}
					>
						{formatMessage(messages.plastic)}
					</li>
					<li
						onClick={() => changeFilter(KeywordType.VIRTUAL)}
						className={classNames({
							[styles.active]: keywords.includes(KeywordType.VIRTUAL),
						})}
					>
						{formatMessage(messages.virtual)}
					</li>
					<li
						onClick={() => changeFilter(KeywordType.BLOCKED)}
						className={classNames({
							[styles.active]: keywords.includes(KeywordType.BLOCKED),
						})}
					>
						{formatMessage(messages.blocked)}
					</li>
				</ul>
			</div> */}
		</div>
	);
};

export default CardsFilter;
