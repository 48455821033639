import { Fragment } from 'react';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';
import baseMsg from '../../../../messages/base.messages';
import useFormatAmount from '../../../../hooks/useFormatAmount';

interface DataProps {
	[key: string]: string;
}

interface VoucherConfirmProps {
	data: DataProps[];
	currency: CurrencyEnum;
	className: string;
}

const VoucherConfirm = ({ data, currency, className }: VoucherConfirmProps): JSX.Element => {
	const amountFormatter = useFormatAmount();

	return (
		<>
			{data.map(({ amount, receive }: DataProps) => (
				<Fragment key={amount}>
					<InfoInput
						className={className}
						label={withdrawMessages.withdrawalAmount}
						text={`${amountFormatter(formatPrecision(amount, currency))} ${currency}`}
					/>
					<InfoInput
						className={className}
						label={baseMsg.receiveAmount}
						text={`${amountFormatter(
							formatPrecision(receive, currency, undefined, true)
						)} ${currency}`}
					/>
				</Fragment>
			))}
		</>
	);
};

export default VoucherConfirm;
