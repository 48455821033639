import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RemoteData, RemoteError } from '../../../interfaces/RemoteData';

export type PaymentButton = {
	description: string;
	id: string;
	name: string;
	receiveCurrencyCode: CurrencyEnum;
};

export type DonationCurrency = {
	code: CurrencyEnum;
	name: string;
};

export type DonationForm = {
	walletId: string | null;
	amount: string;
};

export type DonationState = {
	button: RemoteData<PaymentButton>;
	currencies: RemoteData<DonationCurrency[]>;
	form: DonationForm;
	pay: RemoteData<void>;
};

export enum DonationActionType {
	FETCH_BUTTON_PENDING = 'PAYMENTS/DONATIONS/FETCH_BUTTON/PENDING',
	FETCH_BUTTON_REJECTED = 'PAYMENTS/DONATIONS/FETCH_BUTTON/REJECTED',
	FETCH_BUTTON_FULFILLED = 'PAYMENTS/DONATIONS/FETCH_BUTTON/FULFILLED',
	FETCH_CURRENCIES_PENDING = 'PAYMENTS/DONATIONS/FETCH_CURRENCIES/PENDING',
	FETCH_CURRENCIES_REJECTED = 'PAYMENTS/DONATIONS/FETCH_CURRENCIES/REJECTED',
	FETCH_CURRENCIES_FULFILLED = 'PAYMENTS/DONATIONS/FETCH_CURRENCIES/FULFILLED',
	PAY_DONATION_PENDING = 'PAYMENTS/DONATIONS/PAY_OREDER/PENDING',
	PAY_DONATION_REJECTED = 'PAYMENTS/DONATIONS/PAY_OREDER/REJECTED',
	PAY_DONATION_FULFILLED = 'PAYMENTS/DONATIONS/PAY_OREDER/FULFILLED',
	SET_FORM = 'PAYMENTS/DONATIONS/SET_FORM',
}

export type DonationFetchButtonPendingAction = {
	type: DonationActionType.FETCH_BUTTON_PENDING;
};

export type DonationFetchButtonRejectedAction = {
	type: DonationActionType.FETCH_BUTTON_REJECTED;
	payload?: RemoteError;
};

export type DonationFetchButtonFulfilledAction = {
	type: DonationActionType.FETCH_BUTTON_FULFILLED;
	payload: PaymentButton;
};

export type DonationFetchCurrenciesPendingAction = {
	type: DonationActionType.FETCH_CURRENCIES_PENDING;
};

export type DonationFetchCurrenciesRejectedAction = {
	type: DonationActionType.FETCH_CURRENCIES_REJECTED;
	payload?: RemoteError;
};

export type DonationFetchCurrenciesFulfilledAction = {
	type: DonationActionType.FETCH_CURRENCIES_FULFILLED;
	payload: DonationCurrency[];
};

export type DonationPayDonationPendingAction = {
	type: DonationActionType.PAY_DONATION_PENDING;
};

export type DonationPayDonationRejectedAction = {
	type: DonationActionType.PAY_DONATION_REJECTED;
	payload?: RemoteError;
};

export type DonationPayDonationFulfilledAction = {
	type: DonationActionType.PAY_DONATION_FULFILLED;
};

export type DonationSetFormAction = {
	type: DonationActionType.SET_FORM;
	payload: Partial<DonationForm>;
};
