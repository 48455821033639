import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import type { ChartOptions, ChartDataset, ScriptableContext, Point } from 'chart.js';
import classNames from 'classnames';
import {
	subMonths,
	format,
	differenceInCalendarDays,
	differenceInHours,
	addHours,
	addDays,
	differenceInMonths,
	addMonths,
	differenceInWeeks,
	addWeeks,
	startOfWeek,
	startOfHour,
	endOfWeek,
	endOfMonth,
	startOfMonth,
} from 'date-fns';
import { FC, useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import DurationType from '../../../../../enums/DurationType';
import { dateFixer } from '../../../../../helpers/dateHelper/dateHelper';
import useMerchantRoutes from '../../../../../hooks/useMerchantRoutes';
import { RemoteData, RemoteStatus } from '../../../../../interfaces/RemoteData';
import baseMsg from '../../../../../messages/base.messages';
import { getMerchantsProjectChartUrl } from '../../../../../redux/endpoints';
import MerchantsMessages from '../../../../../redux/MerchantsState/MerchantsMessages';
import MerchantsSelectors from '../../../../../redux/MerchantsState/MerchantsSelectors';
import { RootState } from '../../../../../redux/Store';
import TestIds from '../../../../../test/TestIds';
import ChartDetails from './ChartDetails/ChartDetails';
import ChartRange from './ChartRange/ChartRange';
import MerchantProjectChartContext, {
	MerchantApiChartData,
	RangeType,
} from './Context/MerchantProjectChartContext';
import styles from './MerchantProjectChart.module.scss';
import axiosInstance from '../../../../../helpers/axiosInstance';

const defaultDataOptions: Omit<ChartDataset<'line'>, 'data'> = {
	fill: true,
	pointBackgroundColor: '#4B73F4',
	showLine: true,
	pointRadius: 0,
	borderWidth: 1,
	borderColor: '#4B74F4',
	backgroundColor: (context: ScriptableContext<'line'>): CanvasGradient | undefined => {
		const { chart } = context;
		const { ctx, chartArea } = chart;

		if (!chartArea) return;

		const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
		gradient.addColorStop(0, 'rgba(75,116,244,0)');
		gradient.addColorStop(1, 'rgba(75,116,244,0.25)');
		// eslint-disable-next-line consistent-return
		return gradient;
	},
};

const MerchantProjectChart: FC = () => {
	const { getParams } = useMerchantRoutes();
	const { formatMessage } = useIntl();
	const { projectId } = getParams();
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const { referenceCurrency } = useSelector((state: RootState) => state.AppState);
	const { receiveAccountId } = useSelector(
		MerchantsSelectors.projects.data.getProjectDataById(projectId!)
	)!;
	const autoconvertCurrency = wallets?.find((x) => x.id === receiveAccountId)?.currencyCode;

	const [from, setFrom] = useState(subMonths(new Date(), 1));
	const [to, setTo] = useState(new Date());
	const [type, setType] = useState<RangeType>('1M');
	const [chartData, setChartData] = useState<RemoteData<MerchantApiChartData>>({
		status: RemoteStatus.None,
	});
	const isMobile = useSelector(({ AppState }: RootState) => AppState.isMobile);
	const chartLabels = (() => {
		if (!from || !to || !chartData.data?.itemDuration) return [];

		switch (chartData.data?.itemDuration) {
			case DurationType.HOURS:
				return Array(differenceInHours(to, from) + 1)
					.fill(0)
					.map((_, index) => addHours(startOfHour(from), index));
			case DurationType.DAYS:
				return Array(differenceInCalendarDays(to, from) + 1)
					.fill(0)
					.map((_, index) => addDays(from, index));
			case DurationType.WEEKS:
				return Array(
					differenceInWeeks(endOfWeek(to), startOfWeek(from, { weekStartsOn: 1 })) + 1
				)
					.fill(0)
					.map((_, index) => addWeeks(startOfWeek(from, { weekStartsOn: 1 }), index));
			case DurationType.MONTHS:
				return Array(differenceInMonths(endOfMonth(to), startOfMonth(from)) + 1)
					.fill(0)
					.map((_, index) => addMonths(startOfMonth(from), index));
			default:
				return [];
		}
	})();

	const dateFormats = (() => {
		switch (chartData.data?.itemDuration) {
			case DurationType.HOURS:
				return {
					label: (date: Date) => format(date, 'HH:mm'),
					comparer: (date: Date) => format(date, 'yyyy-MM-dd HH:mm'),
					title: (date: Date) => format(date, 'yyyy-MM-dd HH:mm'),
				};
			case DurationType.DAYS:
				return {
					label: (date: Date) => format(date, 'dd MMM'),
					comparer: (date: Date) => format(date, 'yyyy-MM-dd'),
					title: (date: Date) => format(date, 'yyyy-MM-dd'),
				};
			case DurationType.WEEKS:
				return {
					label: (date: Date) => format(date, 'dd MMM'),
					comparer: (date: Date) => format(date, 'yyyy-MM-dd'),
					title: (date: Date) =>
						`${format(date, 'yyyy-MM-dd')} - ${format(
							endOfWeek(date, { weekStartsOn: 1 }),
							'yyyy-MM-dd'
						)}`,
				};
			case DurationType.MONTHS:
				return {
					label: (date: Date) => format(date, 'MMM yy'),
					comparer: (date: Date) => format(date, 'yyyy-MM'),
					title: (date: Date) => format(date, 'yyyy-MM'),
				};
			default:
				return null;
		}
	})();

	const options = useMemo<ChartOptions<'line'>>(() => {
		return {
			responsive: true,
			maintainAspectRatio: false,
			interaction: {
				intersect: false,
				mode: 'nearest',
				axis: 'x',
			},
			spanGaps: true,
			elements: {
				line: {
					cubicInterpolationMode: 'monotone',
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: true,
					callbacks: {
						title(item) {
							const point = item[0].dataset.data[item[0].dataIndex] as Point;
							const date = new Date(point.x);
							return dateFormats?.title(date) || '';
						},
						label(context) {
							return formatMessage(baseMsg.labeledValue, {
								Label: formatMessage(MerchantsMessages.paidOrders),
								Value: `${(context.raw as any).y}`,
							});
						},
					},
					displayColors: false,
					backgroundColor: '#102D6F',
					padding: {
						x: 20,
						y: 14,
					},
				},
			},
			scales: {
				y: {
					offset: true,
					ticks: {
						color: '#000',
						font: {
							family: 'Red Hat Text',
							size: 12,
							weight: 'normal',
						},
						callback(label) {
							if (Math.floor(+label) === label) {
								return label;
							}
							return null;
						},
					},
					grid: {
						color: '#e6e8f0',
						lineWidth: 1,
					},
					border: {
						dash: [4, 4],
					},
					beginAtZero: true,
				},
				x: {
					ticks: {
						color: '#000',
						font: {
							family: 'Red Hat Text',
							size: 12,
							weight: 'normal',
						},
						autoSkip: true,
						maxRotation: 0,
						maxTicksLimit: isMobile ? 4 : 12,
						align: 'start',
					},
					labels: chartLabels.map((x) => dateFormats?.label(new Date(x)) || ''),
					grid: {
						display: false,
					},
				},
			},
		};
	}, [chartData.data, isMobile]);

	useEffect(() => {
		if (!from || !to) return;
		setChartData({ status: RemoteStatus.InProgress, data: chartData.data });
		void axiosInstance
			.get(
				getMerchantsProjectChartUrl(
					projectId!,
					from,
					to,
					(autoconvertCurrency || referenceCurrency || CurrencyEnum.EUR) as CurrencyEnum
				)
			)
			.then(({ data }) => {
				const fixedData = {
					...data,
					items: data.items.map((x: any) => ({
						...x,
						timestamp: dateFixer(x.timestamp),
					})),
				};
				setChartData({ status: RemoteStatus.Done, data: fixedData });
			})
			.catch((e) => {
				setChartData({
					status: RemoteStatus.Error,
					error: e.response?.data,
					data: chartData.data,
				});
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [from, to]);

	return (
		<MerchantProjectChartContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				chartData,
				range: { from, to, type, setFrom, setTo, setType },
			}}
		>
			<div data-cy={TestIds.ProjectviewChart} className={styles.container}>
				<div className={styles.header}>
					<ChartDetails />
					<ChartRange />
				</div>
				<div
					className={classNames(
						styles.body,
						// styles.loading,
						chartData.status === RemoteStatus.InProgress && styles.loading
					)}
				>
					{chartData.status === RemoteStatus.InProgress && (
						<div className={styles.loader} />
					)}
					<Line
						data-cy={TestIds.ProjectviewChartCanvas}
						className={classNames(styles.chart)}
						options={options}
						data={{
							datasets: [
								{
									...defaultDataOptions,
									data: dateFormats
										? chartLabels.map((timestamp) => ({
												y:
													chartData.data?.items.find(
														(x) =>
															dateFormats.comparer(
																new Date(x.timestamp)
															) ===
															dateFormats.comparer(
																new Date(timestamp)
															)
													)?.count || 0,
												x: timestamp,
										  }))
										: [],
								},
							],
						}}
					/>
				</div>
			</div>
		</MerchantProjectChartContext.Provider>
	);
};

export default MerchantProjectChart;
