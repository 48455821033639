/* eslint-disable import/prefer-default-export */

import { DeviceNameType } from '../../redux/AppState/AppTypes';
import store from '../../redux/Store';

export const toPlainAmount = (amount: string, isInput = false) => {
	const isAppleOS = store.getState().AppState.deviceOS === DeviceNameType.IPHONE;
	let newAmount = amount;
	if (['.', ','].includes(newAmount.toString())) newAmount = '0';
	if (isAppleOS && isInput) {
		return newAmount.replace(/[. ]+/g, '').replace(',', '.');
	}

	return newAmount.replace(/[, ]+/g, '');
};
