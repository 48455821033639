import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons/faUserPlus';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import programSvg from './program.svg';
import styles from './Program.module.scss';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import ReferralsMessages from '../../../../redux/ReferralsState/ReferralsMessages';
import CopyField from '../../../../components/CopyField/CopyField';
import { defaultCodeQuery } from '../../../../redux/ReferralsState/Queries/ReferralQueries';
import routes from '../../../../route.messages';
import { useFormatRoute } from '../../../../helpers/languagePathHelper/languagePathHelper';
import Invite from '../Invite/Invite';
import toggleModal from '../../../../redux/ModalState/ModalActions';

const Program: FC = () => {
	const { data } = useQuery({
		queryKey: defaultCodeQuery.getKey(),
		queryFn: defaultCodeQuery.getFn(),
	});

	const { formatMessage } = useIntl();
	const dispatch = useDispatch();

	const referralUrl = data ? `${window.location.host}/?referralId=${data.id}` : '-';
	const mediaKitUrl = useFormatRoute(routes.mediaKit);

	const onInvite = useCallback(() => {
		dispatch(toggleModal(<Invite />));
	}, []);

	return (
		<WhiteContainer className={styles.container}>
			<div className={styles.header}>
				<SvgIcon src={programSvg} className={styles.icon} />
			</div>
			<div className={styles.body}>
				<div className={styles.title}>
					<span>{formatMessage(ReferralsMessages.referralProgram)}</span>
					<div className={styles.earn}>{formatMessage(ReferralsMessages.earnUpTo20)}</div>
				</div>
				<div className={styles.text}>{formatMessage(ReferralsMessages.recommentSC)}</div>
				<div>
					<CopyField copyContent={referralUrl}>
						<span className={styles.refLink}>{referralUrl}</span>
					</CopyField>
				</div>
				<div className={styles.actions}>
					<Button
						className={styles.button}
						buttonStyle={ButtonStyle.PRIMARY}
						type={ButtonType.BUTTON}
						onClick={onInvite}
					>
						<FontAwesomeIcon icon={faUserPlus} className={styles.icon} />
						<span>{formatMessage(ReferralsMessages.inviteFriends)}</span>
					</Button>
					<Button
						className={styles.button}
						buttonStyle={ButtonStyle.SECONDARY_WITHOUT_BORDER}
						link={mediaKitUrl}
						type={ButtonType.ANCHOR_LINK}
						target="_blank"
					>
						{formatMessage(ReferralsMessages.scMediaKit)}
						<FontAwesomeIcon className={styles.icon} icon={faArrowRight} />
					</Button>
				</div>
			</div>
		</WhiteContainer>
	);
};

export default Program;
