import { useQuery } from '@tanstack/react-query';
import { getTwoFaInfo } from '../endpoints';
import { throwLoaderError } from '../../helpers/loaderUtils';
import { TwoFaType } from './TwoFaTypes';
import axiosInstance from '../../helpers/axiosInstance';

type TwoFa = {
	scaDevice: boolean;
	scaRequired: boolean;
	type: TwoFaType;
};

export const twofaQuery = {
	getKey: () => ['twofa'],
	getFn: () => () =>
		axiosInstance
			.get<TwoFa>(getTwoFaInfo())
			.then(({ data }) => data)
			.catch(throwLoaderError),
};

type QueryHookProps = {
	enabled?: boolean;
};

export const useTwoFaQuery = ({ enabled = true }: QueryHookProps) =>
	useQuery({ queryKey: twofaQuery.getKey(), queryFn: twofaQuery.getFn(), enabled });
