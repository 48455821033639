import CopyButton from '../../../../../components/CopyButton/CopyButton';
import styles from './DetailsField.module.scss';

interface DetailFieldProps {
	label: string;
	value: string;
	isHTML?: boolean;
	isCopyable?: boolean;
	isCryptoWithdraw?: boolean;
}

const DetailField = ({ label, value, isCopyable = false, ...rest }: DetailFieldProps) => {
	return (
		<div {...rest}>
			<div id="label" className={styles.label}>
				{label}
				{isCopyable && <CopyButton dataToCopy={value} />}
			</div>
			<div id="value" className={styles.value}>
				{value}
			</div>
		</div>
	);
};

export default DetailField;
