import { AxiosResponse } from 'axios';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/Store';
import {
	getInitCardPinURL,
	getOldDebitCardPinURL,
	getPinTokenURL,
	getPinURL,
} from '../../../../redux/endpoints';
import {
	GetCurrentCard,
	isCardFunctionsEnabled,
} from '../../../../helpers/cardsHelper/cardsHelper';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import Input from '../../../../components/Input/Input';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import ThirdParty2FA from '../../Shared/ThirdParty2FA/ThirdParty2FA';
import styles from './ViewCardPin.module.scss';
import Seo from '../../../../components/Seo/Seo';
import baseMsg from '../../../../messages/base.messages';
import inputErrors from '../../../../messages/inputErrors.messages';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	enterCode: {
		id: 'cardActivate.enterCode',
		defaultMessage: 'Enter code',
	},
	wrongCvv: {
		id: 'cardActivate.wrongCvv',
		defaultMessage: 'Wrong CVV code',
	},
	cvv: {
		id: 'cardActivate.cvv',
		defaultMessage: 'CVV code',
	},
	cvvError: {
		id: 'cardsActivate.cvvError',
		defaultMessage: 'Incorrect CVV code',
	},
	title: {
		id: 'viewCardPin.title',
		defaultMessage: 'View PIN',
	},
	pin: {
		id: 'viewCardPin.pin',
		defaultMessage: 'PIN',
	},
	timerText: {
		id: 'viewCardPin.timerText',
		defaultMessage: 'PIN will disappear in <span></span> s.',
	},
	showPin: {
		id: 'viewCardPin.showPin',
		defaultMessage: 'Show PIN',
	},
	wrongPin: {
		id: 'viewCardPin.wrongPin',
		defaultMessage: 'Wrong 2FA code',
	},
	somethingWentWrong: {
		id: 'viewCardPin.somethingWentWrong',
		defaultMessage: 'Something went wrong. Please try again later.',
	},
	mobileBack: {
		id: 'load.back',
		defaultMessage: 'Back to card',
	},
	metaTitle: {
		id: 'viewCardPin.metaTitle',
		defaultMessage: 'View PIN',
	},
});

const oneSec = 1000;
const hiddenPIN = '****';

const ViewCardPin = () => {
	const { formatMessage } = useIntl();
	const card = GetCurrentCard();
	const { url } = useRouteMatch();
	const timer = useRef<number>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [pin, setPin] = useState<string>('');
	const { isTablet } = useSelector((store: RootState) => store.AppState);
	const [time, setTime] = useState<number>(20);
	const [error, setError] = useState<MessageDescriptor | null>(null);
	const [cvv, setCvv] = useState<string>('');
	const [cvvError, setCvvError] = useState<MessageDescriptor | null>(null);

	const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
		if (value.length > 3) return null;
		setCvvError(null);
		return setCvv(value);
	};

	const handelSubmit = async (event: FormEvent) => {
		event.preventDefault();
		if (!cvv) return setCvvError(inputErrors.cannotBeEmpty);
		if (cvv.length !== 3) return setCvvError(messages.cvvError);
		if (card)
			await axiosInstance
				.put(getOldDebitCardPinURL(card.id), { cvv })
				.then(({ data }: AxiosResponse) => {
					return setPin(data.pin);
				})
				.catch(() => {
					return setCvvError(messages.wrongCvv);
				})
				.then(() => setIsLoading(false));
		return null;
	};

	const initPin = async (id: string) => {
		setIsLoading(true);
		await axiosInstance.post(getInitCardPinURL(id), {}).then(() => setIsLoading(false));
	};

	const getPin = async (auth: string) => {
		await axiosInstance
			.get(getPinURL(card?.cardId || ''), {
				headers: {
					Authorization: auth,
				},
			})
			.then(({ data }: AxiosResponse) => {
				setTime(20);
				return setPin(data);
			})
			.catch(() => {
				return setError(messages.somethingWentWrong);
			})
			.then(() => {
				return setIsLoading(false);
			});
	};

	const getPinToken = async (password: string, id: string) => {
		setIsLoading(true);
		await axiosInstance
			.put(getPinTokenURL(id), { password })
			.then(({ data }: AxiosResponse) => {
				return getPin(data.authorizationHeader);
			})
			.catch(() => {
				setIsLoading(false);
				return setError(messages.wrongPin);
			});
	};

	useEffect(() => {
		if (card && isCardFunctionsEnabled(card?.cardType) && card?.id && !pin) initPin(card?.id);
	}, [card, pin]);

	useEffect(() => {
		if (pin && pin !== hiddenPIN) {
			timer.current = window.setInterval(() => {
				setTime(time - 1);
			}, oneSec);
		}

		return () => clearInterval(timer.current);
	}, [pin, time]);

	useEffect(() => {
		if (time === 0 && pin && pin !== hiddenPIN) {
			setPin(hiddenPIN);
		}
	}, [time, pin]);

	if (!card) return null;
	return (
		<WhiteContainer
			backButtonLink={isTablet ? url.replace('view-card-pin', 'history') : ''}
			backButtonText={messages.mobileBack}
		>
			<Seo title={messages.metaTitle} />
			<>
				<InfoHead title={messages.title} />
				{pin && (
					<div className={styles.container}>
						<span className={styles.pin}>
							<div>
								<div>{formatMessage(messages.pin)}</div>
								<div className={styles.pinCode}>{pin}</div>
							</div>
							{time === 0 && pin === hiddenPIN && (
								<Button
									type={ButtonType.BUTTON}
									buttonStyle={ButtonStyle.BORDERLESS}
									text={messages.showPin}
									className={styles.showPin}
									onClick={() => setPin('')}
								/>
							)}
						</span>
						{time !== 0 && pin !== hiddenPIN && (
							<div className={styles.timer}>
								<FormattedMessage
									{...messages.timerText}
									values={{ span: () => <span>{time}</span> }}
								/>
							</div>
						)}
						<Button
							text={baseMsg.close}
							className={styles.button}
							type={ButtonType.LINK}
							link={url.replace('view-card-pin', 'history')}
						/>
					</div>
				)}
				{!pin && isCardFunctionsEnabled(card?.cardType) && (
					<ThirdParty2FA
						onSubmit={(twoFa: string) => getPinToken(twoFa, card?.id)}
						isLoading={isLoading}
						error={error}
						resend={() => initPin(card?.id)}
					/>
				)}
				{!pin && !isCardFunctionsEnabled(card.cardType) && (
					<form className={styles.form} onSubmit={handelSubmit}>
						<Input
							label={messages.cvv}
							labelClassName={styles.label}
							onChangeEvent={handleChange}
							placeholder={messages.enterCode}
							value={cvv}
							name="cvv"
							className={styles.input}
							errorMessage={cvvError}
							type="number"
						/>
						<Button
							text={baseMsg.submit}
							type={ButtonType.SUBMIT}
							className={styles.button}
							minWidth={176}
							isLoading={isLoading}
							isDisabled={isLoading}
						/>
					</form>
				)}
			</>
		</WhiteContainer>
	);
};

export default ViewCardPin;
