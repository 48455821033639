import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useIntl, defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import { useHistory, useLocation } from 'react-router';
import queryString, { ParsedQuery } from 'query-string';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import { isAmountGreaterThanZero } from '../../../../helpers/inputValidation/inputValidation';
import { getOffchainCalcURL, getOffchainURL } from '../../../../redux/endpoints';
import {
	EWalletSuccessResponse,
	WithdrawType,
} from '../../../../redux/WithdrawState/WithdrawTypes';
import { RootState } from '../../../../redux/Store';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';
import Button, { ButtonType } from '../../../../components/Button/Button';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Input from '../../../../components/Input/Input';
import CustomInput from '../../Shared/CustomInput/CustomInput';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import WithdrawFormContainer, {
	WithdrawSteps,
} from '../../Shared/WithdrawFormContainer/WithdrawFormContainer';
import WithdrawConfirm from '../../Shared/WithdrawConfirm/WithdrawConfirm';
import WithdrawSuccess from '../../Shared/WithdrawSuccess/WithdrawSuccess';
import styles from './OffchainForm.module.scss';
import { toPlainAmount } from '../../../../helpers/currencyAmountHelper/currencyAmountHelper';
import baseMsg from '../../../../messages/base.messages';
import AccountSelect from '../../Shared/AccountSelect/AccountSelect';
import Seo from '../../../../components/Seo/Seo';
import useDebounce from '../../../../hooks/useDebounce';
import useOffchainFormValidator from './useOffchainFormValidator';
import useValidation from '../../../../hooks/useValidation';
import TransfersFormLayout from '../../../../layout/TransfersFormLayout/TransfersFormLayout';
import { HowToType } from '../../../../layout/TransfersFormLayout/HowTo/HowTo';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	title: {
		id: 'offchain.title',
		defaultMessage: 'Offchain withdrawals',
	},
	amount: {
		id: 'offchain.amount',
		defaultMessage: 'Send amount',
	},
	totalAmount: {
		id: 'offchain.totalAmount',
		defaultMessage: 'Total amount (converted)',
	},
	email: {
		id: 'offchain.email',
		defaultMessage: 'Email',
	},
	emailPlaceholder: {
		id: 'offchain.emailPlaceholder',
		defaultMessage: 'spectrco@user.com',
	},
});

export interface FormProps {
	currency: string;
	amount: string;
	email: string;
	memo: string;
}

interface FormErrorsProps {
	currency: MessageDescriptor | string | null;
	amount: MessageDescriptor | string | null;
	email: MessageDescriptor | string | null;
	memo: MessageDescriptor | string | null;
}

interface CalcResponse {
	currencyCode: string;
	receiveAmount: string;
	totalAmount: string;
}

const OffchainForm = (): JSX.Element => {
	const { push } = useHistory();
	const { formatMessage, locale } = useIntl();
	const { pathname, search } = useLocation();
	const parsedQuery: ParsedQuery<string> = queryString.parse(search);
	const { step } = parsedQuery;
	const [form, setForm] = useState<FormProps>({
		currency: '',
		amount: '',
		email: '',
		memo: '',
	});
	const [formErrors, setFormErrors] = useState<FormErrorsProps>({
		currency: null,
		amount: null,
		email: null,
		memo: null,
	});
	const [receiveAmount, setReceiveAmount] = useState<string>('');
	const [totalAmount, setTotalAmount] = useState<string>('0');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [successData, setSuccessData] = useState<EWalletSuccessResponse[]>([]);
	const [maxValue, setMaxValue] = useState<string>('0');
	const [balance, setBalance] = useState<string>('0');
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const { paymentMethods } = useSelector((state: RootState) => state.WithdrawState);
	const { offchain } = paymentMethods;

	const debounceAmount = useDebounce(form.amount, 1000);

	const validator = useOffchainFormValidator(balance);

	const [isValid, validationResult] = useValidation(
		{
			currency: form.currency,
			amount: toPlainAmount(form.amount, true),
			email: form.email,
		},
		validator
	);

	const calc = useCallback(() => {
		setIsLoading(true);
		return axiosInstance.get(
			getOffchainCalcURL(form.currency.split('/')[2], toPlainAmount(debounceAmount, true))
		) as Promise<AxiosResponse<CalcResponse>>;
	}, [debounceAmount, form.currency]);

	const onSuccess = (data: EWalletSuccessResponse) => setSuccessData([data]);

	const getIsFormValid = useCallback(() => {
		setFormErrors({ ...formErrors, ...validationResult });
		return isValid;
	}, [formErrors, isValid, validationResult]);

	const postData = () =>
		axiosInstance.post(getOffchainURL(), {
			memo: form.memo,
			receiveAmount,
			receiver: form.email,
			sendAccount: form.currency.split('/')[2],
			sendAmount: toPlainAmount(form.amount, true),
		});

	const onInputChange = (value: string, id: string) => {
		setFormErrors({
			...formErrors,
			[id]: null,
		});
		return setForm({
			...form,
			[id]: value,
			...(id === 'amount' && toDecimal(value || '0', true).equals(0) && { receive: '0' }),
		});
	};

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!getIsFormValid()) return null;
		return calc()
			.then(() => {
				push({
					pathname,
					search: `?step=${WithdrawSteps.CONFIRM}`,
				});
			})
			.catch(() => null)
			.then(() => setIsLoading(false));
	};

	useEffect(() => {
		if (form.currency && debounceAmount)
			void calc()
				.then(({ data }) => {
					setReceiveAmount(data.receiveAmount);
					return setTotalAmount(data.totalAmount);
				})
				.catch(() => null)
				.then(() => setIsLoading(false));
	}, [calc, debounceAmount, form.currency]);

	useEffect(() => {
		if (form.currency) {
			const calcValue = toDecimal(balance).toString();
			setMaxValue(isAmountGreaterThanZero(calcValue) ? calcValue : '0');
		}
	}, [balance, form.currency]);

	useEffect(() => {
		if (form.currency)
			setBalance(
				wallets?.find(({ id }) => id === form.currency.split('/')[2])?.availableBalance ||
					'0'
			);
	}, [form.currency, wallets]);

	useEffect(() => {
		if (!step) {
			setIsLoading(false);
			setSuccessData([]);
			setForm({
				...form,
				amount: '',
				email: '',
				memo: '',
			});
		}
	}, [form, step]);

	return (
		<>
			<Seo title={withdrawMessages.metaOffchainTitle} />
			<WithdrawFormContainer
				walletId={form.currency.split('/')[2]}
				isLoaded={offchain ? offchain?.isLoaded : false}
				title={
					<PageTitle
						title={formatMessage(messages.title)}
						previousPageLink={
							step === WithdrawSteps.CONFIRM
								? `/${locale}/withdraw/offchain?step=${WithdrawSteps.PREPARE}`
								: undefined
						}
						isPreviousPageLinkVisibleOnDesktop={step !== WithdrawSteps.DONE}
						isPreviousPageLinkVisibleOnMobile={step !== WithdrawSteps.DONE}
					/>
				}
				form={
					<TransfersFormLayout
						title={formatMessage(messages.title)}
						providerType="Offchain"
						method={null}
						type={HowToType.WITHDRAW}
					>
						<p className={styles.text}>
							<FormattedMessage
								id="offchain.infoText"
								defaultMessage="The selected currency will be converted to bitcoins and sent out to the receiver.{br}{br} You can use offchain withdrawals feature for sending assets to an email address that has a SpectroCoin account associated with it."
								values={{ br: <br /> }}
							/>
						</p>
						<form onSubmit={handleSubmit} className={styles.form}>
							<AccountSelect
								value={form.currency}
								label={withdrawMessages.withdrawalWallet}
								onChange={(currency: string) => {
									setFormErrors({
										...formErrors,
										currency: null,
									});
									setForm({
										...form,
										currency,
									});
								}}
								errorMessage={formErrors.currency}
								type={WithdrawType.OFFCHAIN}
								paymentCurrencies={offchain?.data}
							/>
							<CustomInput
								maxValue={maxValue}
								id="amount"
								label={messages.amount}
								currency={CurrencyEnum[form.currency.split('/')[0]]}
								onChange={({
									target: { value, id },
								}: ChangeEvent<HTMLInputElement>) => onInputChange(value, id)}
								isLoading={isLoading}
								value={form.amount}
								errorMessage={formErrors.amount}
							/>
							<InfoInput
								label={messages.totalAmount}
								balance={totalAmount}
								currency={CurrencyEnum.BTC}
							/>
							<Input
								label={messages.email}
								labelClassName={styles.label}
								value={form.email}
								id="email"
								onChangeEvent={({
									target: { value, id },
								}: ChangeEvent<HTMLInputElement>) => onInputChange(value, id)}
								placeholder={messages.emailPlaceholder}
								errorMessage={formErrors.email}
							/>
							<Input
								id="memo"
								label={baseMsg.memo}
								labelClassName={styles.label}
								value={form.memo}
								onChangeEvent={({
									target: { value, id },
								}: ChangeEvent<HTMLInputElement>) => onInputChange(value, id)}
								placeholder={baseMsg.transactionMemoPlaceholder}
							/>
							<Button
								isDisabled={isLoading}
								isLoading={isLoading}
								type={ButtonType.SUBMIT}
								className={styles.button}
								text={baseMsg.submit}
							/>
						</form>
					</TransfersFormLayout>
				}
				confirm={
					<WithdrawConfirm
						type={WithdrawType.OFFCHAIN}
						currency={CurrencyEnum[form.currency.split('/')[0]]}
						formData={[{ ...form, receiveAmount }]}
						onSuccess={onSuccess}
						onSubmit={postData}
					/>
				}
				done={
					<WithdrawSuccess
						data={successData}
						currency={CurrencyEnum[form.currency.split('/')[0]]}
						type={WithdrawType.OFFCHAIN}
					/>
				}
				isDone={successData.length > 0}
			/>
		</>
	);
};
export default OffchainForm;
