/* eslint-disable no-template-curly-in-string */
import { useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { AxiosError } from 'axios';
import { RemoteError } from '../../../../interfaces/RemoteData';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { WithdrawType, ProviderType } from '../../../../redux/WithdrawState/WithdrawTypes';
import {
	ErrorMessageCodes,
	getErrorMessageOrDefault,
	isRemoteError,
} from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import OffchainConfirm from '../../Offchain/OffchainConfirm/OffchainConfirm';
import BankWithdrawConfirm from '../../BankWithdraw/BankWithdrawConfirm/BankWithdrawConfirm';
import EWalletConfirm from '../../EWalletWithdraw/EWalletConfirm/EWalletConfirm';
import GiftCardsConfirm from '../../GiftCards/GiftCardsConfirm/GiftCardsConfirm';
import MobileTopupsConfirm from '../../MobileTopups/MobileTopupsConfirm/MobileTopupsConfirm';
import VoucherConfirm from '../../VoucherWithdraws/VoucherConfirm/VoucherConfirm';
import CryptoConfirm from '../../CryptoWithdraws/CryptoConfirm/CryptoConfirm';
import { FormData, FeeResponse } from '../../CryptoWithdraws/CryptoWithdrawForm/CryptoWithdrawForm';
import styles from './WithdrawConfirm.module.scss';
import { fromEntries } from '../../../../helpers/objectHelper/objectHelper';
import TwoFaContainer from '../../../../containers/TwoFaContainer/TwoFaContainer';
import { TwoFaCode } from '../../../../redux/TwoFaState/TwoFaTypes';

interface CryptoWithdrawConfirmProps {
	type: WithdrawType;
	formData: FormData[] | any;
	providerType?: ProviderType;
	hasIban?: boolean;
	currency: CurrencyEnum;
	fee?: FeeResponse | null;
	memo?: string;
	onSuccess: (data: any) => void;
	onSubmit?: any;
	provider?: string;
	providerTitle?: string;
	network?: string | null;
	feeAccountId?: string;
}

const WithdrawConfirm = ({
	type,
	formData,
	providerType,
	hasIban,
	currency,
	fee = null,
	memo,
	onSuccess,
	onSubmit,
	provider,
	providerTitle,
	network,
	feeAccountId,
}: CryptoWithdrawConfirmProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<MessageDescriptor | string | null>(null);
	const [isDisabled, setIsDisabled] = useState(false);
	const [errorParams, setErrorParams] = useState<{ [key: string]: string }[] | undefined>([]);

	const handleSubmit = () => {
		setIsLoading(true);
		setError(null);
		onSubmit()
			.then(() => null)
			.catch((err: any) => {
				if (err?.response?.data?.errorCode !== TwoFaCode.TWO_FA_REQUIRED)
					return onFailure(err);
				return null;
			})
			.then(() => setIsLoading(false));
	};

	const onFailure = (e: RemoteError | AxiosError<RemoteError>) => {
		const err = isRemoteError(e) ? e : e.response?.data;
		if (err?.errorParameters) {
			const errParamsParsed = err.errorParameters.map(({ key, value }) => {
				switch (key) {
					case '${currencyCode}':
					case '${currency_name}':
						return { key: 'CurrencyName', value };
					case '${amount}':
					case '${available_amount}':
						return { key: 'AvailableAmount', value };
					case '${daily_limit}':
						return { key: 'DailyLimit', value };
					case '${min_receive_amount}':
						return { key: 'MinReceiveAmount', value };
					default:
						return { key, value };
				}
			});
			setErrorParams(errParamsParsed);
		}

		if (err?.errorCode === ErrorMessageCodes.WS_59) setIsDisabled(true);

		return setError(getErrorMessageOrDefault(err));
	};

	return (
		<TwoFaContainer
			onSuccess={onSuccess}
			onFailure={onFailure}
			isLoading={isLoading}
			isDisabled={isDisabled}
			onSubmit={handleSubmit}
		>
			<div className={styles.container}>
				<div>
					{type === WithdrawType.BANK && (
						<BankWithdrawConfirm
							data={formData}
							currency={currency}
							providerType={providerType}
							hasIban={hasIban}
							className={styles.data}
						/>
					)}
					{type === WithdrawType.OFFCHAIN && (
						<OffchainConfirm
							data={formData}
							currency={currency}
							className={styles.data}
						/>
					)}
					{type === WithdrawType.EWALLET && (
						<EWalletConfirm
							data={formData}
							currency={currency}
							className={styles.data}
							provider={provider}
							providerTitle={providerTitle}
						/>
					)}
					{type === WithdrawType.GIFT && (
						<GiftCardsConfirm
							data={formData}
							currency={currency}
							className={styles.data}
						/>
					)}
					{type === WithdrawType.TOPUP && (
						<MobileTopupsConfirm
							data={formData}
							currency={currency}
							className={styles.data}
						/>
					)}
					{type === WithdrawType.VOUCHER && (
						<VoucherConfirm
							data={formData}
							currency={currency}
							className={styles.data}
						/>
					)}
					{type === WithdrawType.CRYPTOCURRENCY && (
						<CryptoConfirm
							data={formData}
							currency={currency}
							className={styles.data}
							memoMsg={memo}
							fee={fee}
							network={network}
							feeAccountId={feeAccountId}
						/>
					)}
					{error && (
						<NotificationMessage
							className={styles.errorContainer}
							withIcon
							type={NotificationType.Error}
							style={NotificationStyle.Border}
							message={
								typeof error === 'string' ? (
									error
								) : (
									<FormattedMessage
										{...error}
										values={
											errorParams
												? fromEntries<Record<string, string>>(
														errorParams.map(({ key, value }) => [
															key,
															value,
														])
												  )
												: undefined
										}
									/>
								)
							}
						/>
					)}
				</div>
			</div>
		</TwoFaContainer>
	);
};

export default WithdrawConfirm;
