import { useIntl } from 'react-intl';
import { toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import { WithdrawHistoryItem } from '../../../../redux/WithdrawHistoryState/WithdrawHistoryTypes';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';
import { DetailsModalType } from '../../../../components/Modal/DetailsModal/DetailsModal';
import statusMsg from '../../../../messages/status.messages';
import HistoryListItem from '../../../../components/HistoryTable/HistoryListItem/HistoryListItem';

interface Props {
	data: WithdrawHistoryItem[];
}

const VoucherWithdrawsHistory = ({ data }: Props): JSX.Element => {
	const { formatMessage } = useIntl();
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{data?.length > 0 &&
				data.map(
					({
						id,
						transNo,
						date,
						status,
						receiveAmount,
						currencyCode,
						withdrawAccount,
						receiveCurrencyCode,
						code,
						withdrawAmount,
					}: WithdrawHistoryItem) => (
						<HistoryListItem
							fee={toDecimal(withdrawAmount)
								.minus(toDecimal(receiveAmount))
								.toString()}
							feeCurrency={currencyCode || receiveCurrencyCode}
							key={id}
							transNo={transNo || id}
							dateCreated={date}
							status={formatMessage(statusMsg[status])}
							amount={receiveAmount}
							currency={currencyCode || receiveCurrencyCode}
							title={formatMessage(withdrawMessages.voucherTitle, {
								receiveCurrencyCode,
							})}
							code={code}
							detailsModalType={DetailsModalType.CRYPTO_WITHDRAW}
							withdrawAccount={withdrawAccount}
							totalAmount={withdrawAmount}
						/>
					)
				)}
		</>
	);
};

export default VoucherWithdrawsHistory;
