/* eslint-disable @typescript-eslint/no-explicit-any */
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import Cookies from 'js-cookie';
import { RatesType, SetRatesAction, SetRatesDetailsAction } from './RatesTypes';
import { getRatesURL, getRatesDetailsURL, getRatesHistoryURL } from '../endpoints';
import { AppDispatch } from '../Store';
import axiosInstance from '../../helpers/axiosInstance';

const BTC = 'BITCOIN';
const LTC = 'LITECOIN';

const setIsLoadingRates = (isLoading: boolean) => ({ type: RatesType.IS_LOADING, isLoading });

export const getRates = () => (dispatch: AppDispatch) => {
	return axiosInstance.get(getRatesURL()).then(({ data }) => {
		return dispatch<SetRatesAction>({ type: RatesType.SET_RATES, data });
	});
};

export const getRatesDetails = (baseCurrency: CurrencyEnum | string) => (dispatch: AppDispatch) => {
	dispatch<any>(setIsLoadingRates(true));
	return axiosInstance
		.get(getRatesDetailsURL(baseCurrency))
		.then(({ data }) => {
			const { content } = data;
			return dispatch<SetRatesDetailsAction>({
				type: RatesType.SET_RATES_DETAILS,
				data: content,
			});
		})
		.catch(() => {
			return null;
		})
		.then(() => {
			return dispatch<any>(setIsLoadingRates(false));
		});
};

export const getRatesHistory = (currencyCode: CurrencyEnum | string) => {
	const referenceCurrency = Cookies.get('primaryCurrency') || CurrencyEnum.USD;
	let dateFrom: string | Date | null = null;
	let dateTo: string | null = null;

	const todayDate = new Date();
	dateFrom = new Date(todayDate);
	dateFrom.setMonth(dateFrom.getMonth() - 1);

	dateFrom = dateFrom.toISOString();
	dateTo = todayDate.toISOString();

	return axiosInstance
		.get(
			getRatesHistoryURL(
				currencyCode === CurrencyEnum.BTC
					? BTC
					: currencyCode === CurrencyEnum.LTC
					? LTC
					: currencyCode,
				referenceCurrency === CurrencyEnum.BTC
					? BTC
					: currencyCode === CurrencyEnum.LTC
					? LTC
					: referenceCurrency,
				dateFrom,
				dateTo
			)
		)
		.then(({ data }) => {
			return data;
		});
};
