import { useState } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setOrderStep } from '../../../../../redux/CardsState/CardsActions';
import { OrderSteps } from '../../../../../redux/CardsState/CardsTypes';
import { getFinalizePhoneVerificationURL } from '../../../../../redux/endpoints';
import { getErrorMessageOrDefault } from '../../../../../helpers/errorMessageHelper/errorMessageHelper';
import Button, { ButtonType } from '../../../../../components/Button/Button';
import inputErrors from '../../../../../messages/inputErrors.messages';
import Input from '../../../../../components/Input/Input';
import styles from './VerificationInput.module.scss';
import axiosInstance from '../../../../../helpers/axiosInstance';

const messages = defineMessages({
	verificationCodeLabel: {
		id: 'phoneVerification.verificationCodeLabel',
		defaultMessage: 'Verification code',
	},
	next: {
		id: 'base.next',
		defaultMessage: 'Next',
	},
});

interface VerificationInputProps {
	isRha?: boolean;
}

const VerificationInput = ({ isRha }: VerificationInputProps) => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const [code, setCode] = useState<string>('');
	const [error, setError] = useState<MessageDescriptor | null>();

	const handleVerificationSubmit = () => {
		setError(null);

		if (!code) {
			return setError(inputErrors.cannotBeEmpty);
		}

		return axiosInstance
			.post(getFinalizePhoneVerificationURL(), { pinCode: code })
			.then(() => {
				if (isRha) {
					return dispatch(setOrderStep(OrderSteps.LINKED_WALLETS));
				}
				return dispatch(setOrderStep(OrderSteps.CONFIRM));
			})
			.catch((err) => setError(getErrorMessageOrDefault(err)));
	};

	return (
		<>
			<Input
				label={messages.verificationCodeLabel}
				value={code}
				type="number"
				errorMessage={error}
				onChange={(val) => setCode(val)}
			/>
			<Button
				onClick={handleVerificationSubmit}
				type={ButtonType.BUTTON}
				className={styles.submit}
			>
				{formatMessage(messages.next)}
			</Button>
		</>
	);
};

export default VerificationInput;
