import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import FunctionalityAvailableType from '../enums/FunctionalityAvailableEnum';
import UserContractCompanyType, {
	UserPreviousPageForCompanyMovement,
} from '../enums/UserContractCompanyEnum';
import { companyAndPreviousPageHandler } from '../helpers/storageHelper/storageHelper';
import { UserCompany } from '../redux/ProfileState/ProfileTypes';
import { getIsFunctionalityAvailableURL } from '../redux/endpoints';
import { RootState } from '../redux/Store';
import config from '../configs/config';
import axiosInstance from '../helpers/axiosInstance';

const useIBANRedirect = () => {
	const { locale } = useIntl();
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const wallet = useSelector((state: RootState) => state?.AccountsState).wallets?.find(
		(o) => o.currencyCode === CurrencyEnum.EUR
	)!;

	return useCallback(() => {
		return axiosInstance
			.get(getIsFunctionalityAvailableURL(FunctionalityAvailableType.CLAIM_VIBAN))
			.then(({ data: funcAvailableData }) => {
				const { available, data } = funcAvailableData[0];
				if (!available && data && data?.toCompany) {
					companyAndPreviousPageHandler({
						toCompany: UserContractCompanyType[data.toCompany],
						previousPage: UserPreviousPageForCompanyMovement.CLAIM_IBAN,
					});
				}
				if (!available) {
					return {
						verified: false,
						url: `${config.PROFILE_DOMAIN}/${locale}/verification/verify/${UserCompany.EE}`,
					};
				}
				if (!user?.phoneVerified) {
					return {
						verified: false,
						url: `${config.PROFILE_DOMAIN}/${locale}/verification/phone`,
					};
				}
				if (!user?.verified || !available) {
					return {
						verified: false,
						url: `${config.PROFILE_DOMAIN}/${locale}/verification/account`,
					};
				}
				if (!data && user?.verified && available && user?.phoneVerified && !wallet?.iban)
					return {
						verified: true,
						url: `/${locale}/account/history/${CurrencyEnum.EUR}/${wallet.id}/IBAN/claim`,
					};

				return {
					verified: true,
					url: `/${locale}/account/history/${CurrencyEnum.EUR}/${wallet.id}/IBAN/claim`,
				};
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export default useIBANRedirect;
