import {
	Reset2FaDataAction,
	Set2FaDataAction,
	Set2FaErrorCodeAction,
	TwoFaActionType,
	TwoFaState,
} from './TwoFaTypes';

const initialState: TwoFaState = {
	authType: null,
	txId: null,
	twoFaCode: null,
	requestData: null,
	errorCode: null,
	expDate: null,
	email: null,
};

export default (
	state = initialState,
	action: Set2FaDataAction | Reset2FaDataAction | Set2FaErrorCodeAction
) => {
	switch (action.type) {
		case TwoFaActionType.SET_2FA_DATA: {
			return {
				...state,
				...action.data,
			};
		}
		case TwoFaActionType.SET_2FA_ERROR_CODE:
			return {
				...state,
				errorCode: action.code,
			};
		case TwoFaActionType.RESET_2FA_DATA: {
			return initialState;
		}
		default:
			return state;
	}
};
