/* eslint-disable no-param-reassign */
export const capitalizeFirstLetter = (s: string) => {
	return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const arrayMoveItem = (arr: any, oldIndex: number, newIndex: number) => {
	while (oldIndex < 0) {
		newIndex += arr.length;
	}
	while (newIndex < 0) {
		newIndex += arr.length;
	}
	if (newIndex >= arr.length) {
		const k: number = newIndex - arr.length + 1;
		while (k - 1) {
			arr.push(undefined);
		}
	}
	arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
	return arr;
};

export const isNumeric = (num: string) => /^[0-9]+([,.][0-9]+)*$/.test(num);
