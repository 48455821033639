import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import queryString, { ParsedQuery } from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { getWithdrawVoucherCalcURL, getWithdrawVouchersURL } from '../../../../redux/endpoints';
import { VoucherResponse, WithdrawType } from '../../../../redux/WithdrawState/WithdrawTypes';
import { RootState } from '../../../../redux/Store';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Button, { ButtonType } from '../../../../components/Button/Button';
import CustomInput from '../../Shared/CustomInput/CustomInput';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import WithdrawSuccess from '../../Shared/WithdrawSuccess/WithdrawSuccess';
import WithdrawFormContainer, {
	WithdrawSteps,
} from '../../Shared/WithdrawFormContainer/WithdrawFormContainer';
import WithdrawConfirm from '../../Shared/WithdrawConfirm/WithdrawConfirm';
import styles from './VoucherWithdrawsForm.module.scss';
import { toPlainAmount } from '../../../../helpers/currencyAmountHelper/currencyAmountHelper';
import baseMsg from '../../../../messages/base.messages';
import AccountSelect from '../../Shared/AccountSelect/AccountSelect';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';
import Seo from '../../../../components/Seo/Seo';
import useDebounce from '../../../../hooks/useDebounce';
import useValidation from '../../../../hooks/useValidation';
import useVoucherFormValidator from './useVoucherFormValidator';
import TransfersFormLayout from '../../../../layout/TransfersFormLayout/TransfersFormLayout';
import { HowToType } from '../../../../layout/TransfersFormLayout/HowTo/HowTo';
import { isAmountGreaterThan } from '../../../../helpers/inputValidation/inputValidation';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	voucher: {
		id: 'voucher.voucherForTitle',
		defaultMessage: 'voucher',
	},
	createVoucher: {
		id: 'voucher.createVoucher',
		defaultMessage: 'Create your Voucher',
	},
	amountIsTooLow: {
		id: 'cryptoWithdrawForm.amountIsTooLow',
		defaultMessage: 'Amount cannot be equal to 0',
	},
	amountIsTooBig: {
		id: 'cryptoWithdrawForm.amountIsTooBig',
		defaultMessage: 'Amount is bigger then it could be',
	},
	voucherConfirmTitle: {
		id: 'voucher.voucherConfirmTitle',
		defaultMessage: 'Confirm {currency} voucher',
	},
});

interface FormProps {
	currency: string;
	amount: string;
	receive: string;
}

interface FormErrorsProps {
	currency: MessageDescriptor | string | null;
	amount: MessageDescriptor | string | null;
	receive: MessageDescriptor | string | null;
}

const VoucherWithdraw = () => {
	const { formatMessage, locale } = useIntl();
	const { push } = useHistory();
	const { pathname, search } = useLocation();
	const parsedQuery: ParsedQuery<string> = queryString.parse(search);
	const { step } = parsedQuery;
	const { paymentMethods } = useSelector((state: RootState) => state.WithdrawState);
	const { voucher } = paymentMethods;
	const { isLoaded } = voucher;
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const [form, setForm] = useState<FormProps>({
		currency: '',
		amount: '0',
		receive: '',
	});
	const [formErrors, setFormErrors] = useState<FormErrorsProps>({
		currency: null,
		amount: null,
		receive: null,
	});
	const [balance, setBalance] = useState<string>('0');
	const [successData, setSuccessData] = useState<VoucherResponse[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const debounceAmount = useDebounce(form.receive, 1000);

	const validator = useVoucherFormValidator(balance);

	const [isValid, validationResult] = useValidation(
		{
			currency: form.currency,
			receive: toPlainAmount(form.receive, true),
		},
		validator
	);

	const estimateFee = useCallback(() => {
		setIsLoading(true);
		return axiosInstance.get(
			getWithdrawVoucherCalcURL(
				CurrencyEnum[form.currency.split('/')[0]],
				toPlainAmount(debounceAmount, true)
			)
		);
	}, [debounceAmount, form.currency]);

	const onInputChange = useCallback(
		(value: string, id: string) => {
			setFormErrors({
				...formErrors,
				[id]: null,
			});
			return setForm({
				...form,
				[id]: value,
			});
		},
		[form, formErrors]
	);

	const onSuccess = (data: VoucherResponse) => setSuccessData([data]);

	const getIsFormValid = useCallback(() => {
		setFormErrors({ ...formErrors, ...validationResult });
		return isValid;
	}, [formErrors, isValid, validationResult]);

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!getIsFormValid()) return null;
		return estimateFee()
			.then(() => {
				return push({
					pathname,
					search: `step=${WithdrawSteps.CONFIRM}`,
				});
			})
			.catch(() => null)
			.then(() => setIsLoading(false));
	};

	const postData = () => {
		const accountId = form.currency.split('/')[2];
		const voucherWithdrawId = voucher.data.filter(
			({ currencyCode }) => currencyCode === form.currency.split('/')[0]
		)[0].id;
		return axiosInstance.post(getWithdrawVouchersURL(), {
			accountId,
			voucherWithdrawId,
			withdrawAmount: toPlainAmount(form.amount),
		});
	};

	useEffect(() => {
		if (form.currency && debounceAmount) {
			void estimateFee()
				.then(({ data }) => {
					if (isAmountGreaterThan(data.withdrawAmount, balance)) {
						setFormErrors({
							...formErrors,
							receive: formatMessage(messages.amountIsTooBig),
						});
					}
					return setForm({
						...form,
						amount: data.withdrawAmount,
					});
				})
				.catch(() => null)
				.then(() => setIsLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debounceAmount, estimateFee, form.currency]);

	useEffect(() => {
		if (!step) {
			setIsLoading(false);
			setSuccessData([]);
			setForm({
				currency: '',
				amount: '0',
				receive: '',
			});
		}
	}, [form, setForm, step]);

	useEffect(() => {
		if (form.currency)
			setBalance(
				wallets?.find(({ id }) => id === form.currency.split('/')[2])?.availableBalance ||
					'0'
			);
	}, [form.currency, wallets]);

	return (
		<>
			<Seo title={withdrawMessages.metaVoucherTitle} />
			<WithdrawFormContainer
				walletId={form.currency.split('/')[2]}
				isLoaded={isLoaded}
				title={
					<PageTitle
						previousPageLink={
							step === WithdrawSteps.CONFIRM
								? `/${locale}/withdraw/vouchers?step=${WithdrawSteps.PREPARE}`
								: undefined
						}
						isPreviousPageLinkVisibleOnDesktop={step !== WithdrawSteps.DONE}
						isPreviousPageLinkVisibleOnMobile={step !== WithdrawSteps.DONE}
						title={
							step === WithdrawSteps.CONFIRM
								? formatMessage(messages.voucherConfirmTitle, {
										currency: form.currency.split('/')[0],
								  })
								: step === WithdrawSteps.DONE
								? ''
								: formatMessage(messages.createVoucher)
						}
						historyLink={
							step === WithdrawSteps.CONFIRM || step === WithdrawSteps.DONE
								? undefined
								: `/${locale}/withdraw/history/vouchers`
						}
					/>
				}
				form={
					<TransfersFormLayout
						title={formatMessage(messages.voucher)}
						method={null}
						type={HowToType.WITHDRAW}
						providerType="Voucher"
					>
						<form onSubmit={handleSubmit} className={styles.form}>
							<AccountSelect
								value={form.currency}
								onChange={(currency: string) => {
									setFormErrors({
										...formErrors,
										currency: null,
									});
									setForm({
										...form,
										currency,
									});
								}}
								errorMessage={formErrors.currency}
								type={WithdrawType.VOUCHER}
							/>
							<CustomInput
								maxValue={balance}
								id="receive"
								label={baseMsg.receiveAmount}
								currency={CurrencyEnum[form.currency.split('/')[0]]}
								onChange={({
									target: { value, id },
								}: ChangeEvent<HTMLInputElement>) => {
									onInputChange(value, id);
								}}
								isLoading={isLoading}
								value={form.receive}
								errorMessage={formErrors.receive}
							/>
							<InfoInput
								label={baseMsg.withdrawAmount}
								balance={form.amount}
								currency={CurrencyEnum[form.currency.split('/')[0]]}
							/>
							<Button
								className={styles.button}
								text={baseMsg.submit}
								type={ButtonType.SUBMIT}
								isLoading={isLoading}
								isDisabled={isLoading || formErrors.receive !== null}
								data-cy="voucherSubmit"
							/>
						</form>
					</TransfersFormLayout>
				}
				confirm={
					<WithdrawConfirm
						type={WithdrawType.VOUCHER}
						currency={CurrencyEnum[form.currency.split('/')[0]]}
						formData={[form]}
						onSuccess={onSuccess}
						onSubmit={postData}
					/>
				}
				done={
					<WithdrawSuccess
						data={successData}
						currency={CurrencyEnum[form.currency.split('/')[0]]}
						type={WithdrawType.VOUCHER}
					/>
				}
				isDone={successData.length > 0}
			/>
		</>
	);
};

export default VoucherWithdraw;
