import { FC, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import CardStatus, { CardStatusEnum } from '../../Shared/CardStatus/CardStatus';
import { toggleCardsSidebar } from '../../../../redux/AppState/AppActions';
import {
	CardType,
	OrderSteps,
	ProductOption,
	VerificationType,
} from '../../../../redux/CardsState/CardsTypes';
import {
	getProductOptions,
	resetOrderData,
	setFormData,
} from '../../../../redux/CardsState/CardsActions';
import { toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import { RootState } from '../../../../redux/Store';
import Loader from '../../../../components/Loader/Loader';
import OnlineTransactionsPassword from '../../Shared/OnlineTransactionsPassword/OnlineTransactionsPassword';
import OrderProgress from '../OrderProgress/OrderProgress';
import Terms from '../Terms/Terms';
import Tin from '../Tin/Tin';
import DeliveryAddress from '../DeliveryAddress/DeliveryAddress';
import Shipping from '../Shipping/Shipping';
import SelectLinkedWallets from '../SelectLinkedWallets/SelectLinkedWallets';
import Confirm from '../Confirm/Confirm';
import SetPin from '../SetPin/SetPin';
import routes from '../../../../route.messages';
import { useFormatRoute } from '../../../../helpers/languagePathHelper/languagePathHelper';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import ChooseVerification from '../ChooseVerification/ChooseVerification';
import UploadPOA from '../UploadPOA/UploadPOA';
import PhoneVerification from '../PhoneVerification/PhoneVerification';
import styles from './OrderFlow.module.scss';

const messages = defineMessages({
	tryAgain: {
		id: 'orderFlow.tryAgain',
		defaultMessage: 'Try again',
	},
	failureTitle: {
		id: 'orderFlow.failureTitle',
		defaultMessage: 'Error occured',
	},
	failureText: {
		id: 'orderFlow.failureText',
		defaultMessage: 'Something went wrong. Please try again or contact our customer support.',
	},
	successTitle: {
		id: 'orderFlow.successTitle',
		defaultMessage: 'Your order has been successful',
	},
	plasticSuccessText: {
		id: 'orderFlow.plasticSuccessText',
		defaultMessage:
			'Your card is going to be delivered soon! More information about the card delivery process you can find <FaqLink>here</FaqLink>',
	},
	virtualSuccessText: {
		id: 'orderFlow.virtualSuccessText',
		defaultMessage: 'The virtual card will be ready to use shortly.',
	},
});

interface OrderFlowProps {
	type: CardType;
	isRha?: boolean;
}

const OrderFlow: FC<OrderFlowProps> = ({ type, isRha }) => {
	const { replace } = useHistory();
	const linkToFaq = useFormatRoute(routes.orderProcess);
	const { locale } = useIntl();
	const dispatch = useDispatch();
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const { orderStep, productOptions, verificationType } = useSelector(
		(state: RootState) => state.CardsState
	);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		if (!productOptions || productOptions?.length === 0) {
			void dispatch(getProductOptions());
		}
		if (productOptions && productOptions?.length > 0) {
			if (!productOptions?.find((o: ProductOption) => CardType[o.type] === CardType[type])) {
				replace(`/${locale}/cards/order`);
			}
			dispatch(resetOrderData());
			dispatch(
				setFormData({
					cardType: productOptions?.find(
						(o: ProductOption) => CardType[o.type] === CardType[type]
					),
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, locale, productOptions, replace]);

	useEffect(() => {
		if (type) {
			const eurWallet = wallets?.find((o: Wallet) => o.currencyCode === CurrencyEnum.EUR);
			const option =
				(productOptions &&
					productOptions?.find(
						(o: ProductOption) => CardType[o.type] === CardType[type]
					)) ||
				null;

			if (
				eurWallet &&
				!toDecimal(eurWallet?.availableBalance).greaterThanOrEqualTo(
					toDecimal(option?.price || 0)
				)
			) {
				replace(`/${locale}/cards/order/not-enough-funds`);
			}
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale, productOptions, replace, wallets]);

	useEffectOnce(() => {
		dispatch(toggleCardsSidebar(false));
	});

	if (isLoading) {
		return <Loader />;
	}

	return (
		<div className={styles.container}>
			{orderStep !== OrderSteps.FAILURE && orderStep !== OrderSteps.SUCCESS && (
				<OrderProgress type={type} isRha={isRha} />
			)}
			{orderStep === OrderSteps.TERMS && <Terms type={type} isRha={isRha} />}
			{orderStep === OrderSteps.TIN && <Tin type={type} isRha={isRha} />}
			{orderStep === OrderSteps.DELIVERY_ADDRESS && (
				<DeliveryAddress type={type} isRha={isRha} />
			)}
			{orderStep === OrderSteps.SHIPPING &&
				(CardType[type] === CardType.PLASTIC ? (
					<Shipping isRha={isRha} />
				) : (
					<ChooseVerification />
				))}
			{orderStep === OrderSteps.VERIFICATION && verificationType === VerificationType.POA && (
				<UploadPOA isRha={isRha} />
			)}
			{orderStep === OrderSteps.VERIFICATION &&
				verificationType === VerificationType.PHONE && <PhoneVerification isRha={isRha} />}
			{orderStep === OrderSteps.LINKED_WALLETS && isRha && (
				<SelectLinkedWallets type={type} />
			)}
			{orderStep === OrderSteps.CONFIRM && <Confirm isRha={isRha} />}
			{orderStep === OrderSteps.SET_PIN && <SetPin />}
			{orderStep === OrderSteps.SET_PASSWORD && <OnlineTransactionsPassword isOrder />}
			{orderStep === OrderSteps.SUCCESS && (
				<CardStatus
					status={CardStatusEnum.COMPLETED}
					title={messages.successTitle}
					text={
						CardType[type] === CardType.PLASTIC
							? {
									...messages.plasticSuccessText,
									values: {
										FaqLink: (chunks: string) => (
											<a href={linkToFaq} target="_blank" rel="noreferrer">
												{chunks}
											</a>
										),
									},
							  }
							: messages.virtualSuccessText
					}
					fullLink={`/${locale}/cards`}
				/>
			)}
			{orderStep === OrderSteps.FAILURE && (
				<CardStatus
					status={CardStatusEnum.FAILED}
					title={messages.failureTitle}
					text={messages.failureText}
					fullLink={`/${locale}/cards/order`}
					contactSupport
					linkText={messages.tryAgain}
				/>
			)}
		</div>
	);
};

export default OrderFlow;
