/* eslint-disable @typescript-eslint/no-explicit-any */
import { useIntl, MessageDescriptor } from 'react-intl';

import { FC, ReactNode } from 'react';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import TestIds from '../../test/TestIds';
import HistoryContext from '../../contexts/HistoryContext/HistoryContext';
import HistoryFilter from '../HistoryFilter/HistoryFilter';
import HistoryTable from '../HistoryTable/HistoryTable';
import styles from './HistoryBlock.module.scss';
import useHistoryTableData, {
	HistoryTableDataProvider,
	HistoryType,
} from '../../hooks/useHistoryTableData';
import HistoryExportButton, {
	ExportOptionsProvider,
} from '../HistoryExportButton/HistoryExportButton';

interface HistoryBlockProps {
	title?: MessageDescriptor;
	currencyCode?: CurrencyEnum;
	id: string;
	historyType: HistoryType;
	customFilters?: ReactNode;
	dataProvider?: HistoryTableDataProvider;
	exportOptionsProvider?: ExportOptionsProvider;
	isDateFilterVisible?: boolean;
}

const HistoryBlockContent: FC<HistoryBlockProps> = ({
	title,
	historyType,
	currencyCode,
	customFilters,
	dataProvider = useHistoryTableData,
	exportOptionsProvider,
	isDateFilterVisible = true,
}) => {
	const history = dataProvider();
	const { formatMessage } = useIntl();

	if (!history.data) return null;

	return (
		<div data-cy={TestIds.HistoryBlock} className={styles.container} id="historyFilter">
			<div className={styles.title}>
				{title && formatMessage(title)}
				<HistoryExportButton
					historyCount={history.data.length}
					exportOptionProvider={exportOptionsProvider}
				/>
			</div>
			{isDateFilterVisible && <HistoryFilter>{customFilters}</HistoryFilter>}
			<HistoryTable
				currencyCode={currencyCode}
				data={history.data}
				isLoading={history.isLoading}
				totalCount={history.totalCount}
				historyType={historyType}
				pageSize={history.pageSize}
			/>
		</div>
	);
};

const HistoryBlock: FC<HistoryBlockProps> = (props) => {
	return (
		// eslint-disable-next-line react/jsx-no-constructed-context-values, react/destructuring-assignment
		<HistoryContext.Provider value={{ id: props.id, historyType: props.historyType }}>
			<HistoryBlockContent {...props} />
		</HistoryContext.Provider>
	);
};

export default HistoryBlock;
