import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import styles from './PaymentMethodLimits.module.scss';
import LimitDuration from '../../../../enums/LimitDuration';
import {
	ActivePaymentMethodFee,
	PaymentMethodLimit,
} from '../../../../redux/DepositState/DepositTypes';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import baseMsg from '../../../../messages/base.messages';
import TestIds from '../../../../test/TestIds';
import useFormatAmount from '../../../../hooks/useFormatAmount';

const messages = defineMessages({
	limits: {
		id: 'paymentProviderLimits.heading',
		defaultMessage: '{paymentMethodName} limits',
	},
	minDepositAmount: {
		id: 'base.minDepositAmount',
		defaultMessage: 'Min deposit amount',
	},
	maxDepositAmount: {
		id: 'base.maxDepositAmount',
		defaultMessage: 'Max deposit amount',
	},
	minFee: {
		id: 'base.minFee',
		defaultMessage: 'Min fee',
	},
	maxFee: {
		id: 'base.maxFee',
		defaultMessage: 'Max fee',
	},
	limitDAILY: {
		id: 'base.dailyLimit',
		defaultMessage: 'Daily limit',
	},
	limitWEEKLY: {
		id: 'base.weeklyLimit',
		defaultMessage: 'Weekly limit',
	},
	limitMONTHLY: {
		id: 'base.monthlyLimit',
		defaultMessage: 'Monthly limit',
	},
	limitANNUAL: {
		id: 'base.yearlyLimit',
		defaultMessage: 'Annual limit',
	},
});

const durationSortOrder = {
	[LimitDuration.DAILY]: 0,
	[LimitDuration.WEEKLY]: 1,
	[LimitDuration.MONTHLY]: 2,
	[LimitDuration.ANNUAL]: 3,
};

interface PaymentMethodLimitsProps {
	depositCurrencyCode?: string;
	activePaymentMethodFees?: ActivePaymentMethodFee[];
	baseCurrencyCode?: string;
	fee?: number;
	limits: PaymentMethodLimit[];
	maxAmount?: number;
	maxFee?: number;
	minAmount?: number;
	minFee?: number;
	paymentMethodName?: MessageDescriptor | string;
	title?: string;
}

interface Fee {
	percent: number | undefined;
	min: number | undefined;
	max: number | undefined;
}

const PaymentMethodLimits = ({
	depositCurrencyCode,
	activePaymentMethodFees,
	baseCurrencyCode,
	fee: feePercent,
	limits,
	maxAmount,
	maxFee,
	minAmount,
	minFee,
	paymentMethodName,
	title,
}: PaymentMethodLimitsProps) => {
	const amountFormatter = useFormatAmount();
	const depositCurrencyBasedFee = activePaymentMethodFees?.find(
		(activeFee) => activeFee.currencyType === depositCurrencyCode
	);

	const fee: Fee = {
		percent: depositCurrencyBasedFee?.fee || feePercent,
		min: depositCurrencyBasedFee?.minFee || minFee,
		max: depositCurrencyBasedFee?.maxFee || maxFee,
	};

	if (limits.length === 0 && !maxAmount && !minAmount && !fee.percent && !fee.min && !fee.max) {
		return null;
	}

	const sortedPaymentMethodLimits = [...limits].sort((a, b) =>
		durationSortOrder[a.limitDuration] > durationSortOrder[b.limitDuration] ? 1 : -1
	);
	return (
		<div data-cy={TestIds.paymentMethodLimist}>
			<h2 className={styles.heading}>
				{title || (
					<FormattedMessage
						{...messages.limits}
						values={{ paymentMethodName: paymentMethodName as any }}
					/>
				)}
			</h2>
			{!!minAmount && (
				<RowItem
					label={messages.minDepositAmount}
					value={`${amountFormatter(
						formatPrecision(minAmount, CurrencyEnum[baseCurrencyCode!])
					)} ${baseCurrencyCode}`}
				/>
			)}
			{!!maxAmount && (
				<RowItem
					label={messages.maxDepositAmount}
					value={`${amountFormatter(
						formatPrecision(maxAmount, CurrencyEnum[baseCurrencyCode!])
					)} ${baseCurrencyCode}`}
				/>
			)}
			{!!fee.percent && (
				<RowItem label={baseMsg.fee} value={`${parseFloat(String(fee.percent))}%`} />
			)}
			{!!fee.min && (
				<RowItem
					label={messages.minFee}
					value={`${amountFormatter(
						formatPrecision(fee.min, CurrencyEnum[baseCurrencyCode!])
					)} ${baseCurrencyCode}`}
				/>
			)}
			{!!fee.max && (
				<RowItem
					label={messages.maxFee}
					value={`${formatPrecision(
						fee.max,
						CurrencyEnum[baseCurrencyCode!]
					)} ${baseCurrencyCode}`}
				/>
			)}
			{sortedPaymentMethodLimits
				// .filter((limit: PaymentMethodLimit) => limit.currencyCode === baseCurrencyCode)
				.map((limit: PaymentMethodLimit) => (
					<RowItem
						key={limit.limitDuration}
						label={messages[`limit${limit.limitDuration}`]}
						value={`${formatPrecision(
							limit.limitAmount,
							CurrencyEnum[baseCurrencyCode!]
						)} ${baseCurrencyCode}`}
					/>
				))}
		</div>
	);
};

export default PaymentMethodLimits;

interface RowItemProps {
	label: MessageDescriptor;
	value: string;
}

const RowItem = ({ label, value }: RowItemProps) => (
	<div data-cy={label.id} className={styles.row}>
		<div className={styles.label}>
			<FormattedMessage {...label} />
		</div>
		<div className={styles.value}>{value}</div>
	</div>
);
