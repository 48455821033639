const FeeSetConstructor = (
	fee: string | null,
	minFee: string | null,
	maxFee: string | null
): { [key: string]: string | null } => ({
	fee,
	minFee,
	maxFee,
});

export default FeeSetConstructor;
