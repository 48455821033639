import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import TextNode, { TextStyles } from '../../../../components/TextNode/TextNode';
import Toggle from '../../../../components/Toggle/Toggle';
import convertToLocalTime from '../../../../helpers/dateHelper/dateHelper';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import MainImage from '../../../../images/settings/api_main.svg';
import {
	enableDisableApiKey,
	getApiKeys,
	resetApiState,
} from '../../../../redux/SettingsState/SettingsActions';
import SettingsMessages from '../../../../redux/SettingsState/SettingsMessages';
import { APIKey } from '../../../../redux/SettingsState/SettingsTypes';
import { RootState } from '../../../../redux/Store';
import Loading from '../Shared/Loading/Loading';
import styles from './View.module.scss';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import { RemoteStatus } from '../../../../interfaces/RemoteData';

type ApiKeyItemProps = {
	apiKey: APIKey;
	onEnableChange: (value: boolean, clientId: string) => void;
};

const ApiKeyItem: FC<ApiKeyItemProps> = ({ apiKey, onEnableChange }) => {
	const { formatMessage, locale } = useIntl();
	const { push } = useHistory();
	const { enabled, created, name, clientId } = apiKey;

	return (
		<div className={styles.apiListItemContainer}>
			<div className={styles.apiListItemDesktop}>
				<div className={styles.apiNameCol}>
					<TextNode className={styles.apiName}>{name}</TextNode>
					<TextNode>{convertToLocalTime(created, 'yyyy-MM-dd HH:mm:ss')}</TextNode>
				</div>
				<div className={styles.clientIdCol}>
					<TextNode style={TextStyles.BreakWord}>{clientId}</TextNode>
				</div>
				<div className={styles.enabledCol}>
					<Toggle
						id={`settings-api-item-${clientId}`}
						name="settings-api-item"
						checked={enabled}
						onChange={() => onEnableChange(!enabled, clientId)}
						className={styles.toggle}
					/>
					<TextNode>
						{formatMessage(
							enabled ? SettingsMessages.enabled : SettingsMessages.disabled
						)}
					</TextNode>
				</div>
				<div
					className={styles.arrowCol}
					onClick={() => {
						push(`/${locale}/settings/api/${clientId}/edit`);
					}}
				>
					<FontAwesomeIcon className={styles.icon} icon={faChevronRight} size="lg" />
				</div>
			</div>
		</div>
	);
};

const MobileApiKeyItem: FC<ApiKeyItemProps> = ({ apiKey, onEnableChange }) => {
	const { formatMessage, locale } = useIntl();
	const { push } = useHistory();
	const { enabled, created, name, clientId } = apiKey;

	return (
		<div className={classNames(styles.apiListItemContainer, styles.mobile)}>
			<div className={styles.mobileListItemRow}>
				<div className={styles.colTitle}>
					<FormattedMessage
						{...SettingsMessages.apiNameAndDateCreated}
						values={{
							br: <br />,
						}}
					/>
				</div>
				<div className={styles.descriptionCol}>
					<TextNode className={styles.apiName}>{name}</TextNode>
					<TextNode>{convertToLocalTime(created, 'yyyy-MM-dd HH:mm:ss')}</TextNode>
				</div>
			</div>
			<div
				className={styles.mobileListItemRow}
				onClick={() => {
					push(`/${locale}/settings/api/${clientId}/edit`);
				}}
			>
				<TextNode className={styles.colTitle}>
					<FormattedMessage {...SettingsMessages.clientId} />
				</TextNode>
				<div className={styles.descriptionCol}>
					<TextNode style={TextStyles.BreakWord}>{clientId}</TextNode>
				</div>
			</div>
			<div className={styles.mobileListItemRow}>
				<TextNode className={styles.colTitle}>
					<FormattedMessage {...SettingsMessages.enableDisable} />
				</TextNode>
				<div className={classNames(styles.descriptionCol, styles.enabledCol)}>
					<Toggle
						id={`settings-api-item-${clientId}`}
						name="settings-api-item"
						checked={enabled}
						onChange={() => onEnableChange(!enabled, clientId)}
						className={styles.toggle}
					/>
					<TextNode>
						{formatMessage(
							enabled ? SettingsMessages.enabled : SettingsMessages.disabled
						)}
					</TextNode>
				</div>
			</div>
		</div>
	);
};

type ApiKeysListProps = {
	apiKeys: APIKey[];
};

const ApiKeysList: FC<ApiKeysListProps> = ({ apiKeys }) => {
	const { formatMessage, locale } = useIntl();
	const dispatch = useDispatch();
	const { isTablet } = useSelector((state: RootState) => state.AppState);
	const {
		list: { status: listStatus },
		edit: { status: editStatus },
	} = useSelector((state: RootState) => state.SettingsState.apiKeys);

	const isLoading = [listStatus, editStatus].includes(RemoteStatus.InProgress);

	const onEnableChange = (value: boolean, clientId: string) => {
		dispatch(enableDisableApiKey(clientId, value));
	};

	return (
		<>
			<TextNode className={styles.apiListTitle}>
				{formatMessage(SettingsMessages.myApis)}
			</TextNode>
			<TextNode className={styles.description}>
				<FormattedMessage
					{...SettingsMessages.myApisDescription}
					values={{
						link: (t) => (
							<Button
								text={t.toString()}
								type={ButtonType.ANCHOR_LINK}
								buttonStyle={ButtonStyle.LINK}
								className={styles.apiLink}
								link={`/${locale}/walletAPI.html`}
							/>
						),
					}}
				/>
			</TextNode>
			<div className={styles.apiKeys}>
				{/* Desktop version table */}
				{!isTablet && (
					<div className={styles.apiKeysTableHeader}>
						<TextNode className={classNames(styles.apiNameCol, styles.colTitle)}>
							<FormattedMessage
								{...SettingsMessages.apiNameAndDateCreated}
								values={{
									br: <br />,
								}}
							/>
						</TextNode>
						<TextNode className={classNames(styles.clientIdCol, styles.colTitle)}>
							<FormattedMessage {...SettingsMessages.clientId} />
						</TextNode>
						<TextNode className={classNames(styles.enabledCol, styles.colTitle)}>
							<FormattedMessage {...SettingsMessages.enableDisable} />
						</TextNode>
					</div>
				)}

				{isLoading && <Loading />}

				{!isLoading &&
					apiKeys.map((apiKey) =>
						isTablet ? (
							<MobileApiKeyItem
								key={apiKey.name}
								apiKey={apiKey}
								onEnableChange={onEnableChange}
							/>
						) : (
							<ApiKeyItem
								key={apiKey.name}
								apiKey={apiKey}
								onEnableChange={onEnableChange}
							/>
						)
					)}
			</div>
		</>
	);
};

const ApiSettings = () => {
	const { locale, formatMessage } = useIntl();
	const dispatch = useDispatch();
	const { isTablet } = useSelector((state: RootState) => state.AppState);
	const {
		list: { data: apiKeys, status },
		error,
	} = useSelector((state: RootState) => state.SettingsState.apiKeys);

	const hasApiKeys = apiKeys!.length !== 0;
	const isLoading = status === RemoteStatus.InProgress;

	useEffectOnce(() => {
		dispatch(resetApiState());
		if (!hasApiKeys) dispatch(getApiKeys());
	});

	return (
		<>
			<PageTitle title={SettingsMessages.apiTitle} />
			<div
				className={classNames(styles.container, {
					[styles.mobile]: isTablet,
				})}
			>
				{error && (
					<NotificationMessage
						type={NotificationType.Error}
						style={NotificationStyle.Border}
						message={formatMessage(getErrorMessageOrDefault(error))}
						withIcon
					/>
				)}
				{!isLoading && !hasApiKeys && (
					<img src={MainImage} alt="spectrocoin api" className={styles.mainImage} />
				)}
				<TextNode className={styles.description}>
					<FormattedMessage
						{...SettingsMessages.apiDescription}
						values={{
							link: (t) => (
								<Button
									text={t.toString()}
									type={ButtonType.ANCHOR_LINK}
									buttonStyle={ButtonStyle.LINK}
									className={styles.apiLink}
									link="https://docs.spectrocoin.com"
								/>
							),
						}}
					/>
				</TextNode>
				<Button
					text={
						hasApiKeys
							? SettingsMessages.apiCreateNewButton
							: SettingsMessages.apiCreateButton
					}
					type={ButtonType.LINK}
					buttonStyle={ButtonStyle.PRIMARY}
					className={styles.createButton}
					link={`/${locale}/settings/api/create`}
					isLoading={isLoading}
				/>

				{hasApiKeys && <ApiKeysList apiKeys={apiKeys!} />}
			</div>
		</>
	);
};

export default ApiSettings;
