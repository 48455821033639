import { FC } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import dangerSvg from '../../../../images/message_icons/danger.svg';
import successSvg from '../../../../images/message_icons/success.svg';
import infoSvg from '../../../../images/message_icons/info.svg';
import forVerifiedSvg from './forVerified.svg';
import forPayerFeeSvg from './forPayerFee.svg';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';

import styles from './Mode.module.scss';
import TestIds from '../../../../test/TestIds';
import { ProjectTestStatus } from '../../../../redux/MerchantsState/MerchantTypes';

const Status: FC<{ icon: string; label: string }> = ({ icon, label, ...rest }) => {
	return (
		<div className={styles.mode} {...rest}>
			<SvgIcon className={styles.icon} src={icon} />
			<span className={styles.label}>{label}</span>
		</div>
	);
};

type ModeProps = {
	className?: string;
	status: {
		enabled: boolean;
		testStatus?: ProjectTestStatus | null;
		forVerifiedPayers?: boolean;
		forPayerFee?: boolean;
	};
};
const Mode: FC<ModeProps> = ({ status, className }) => {
	const { formatMessage } = useIntl();
	return (
		<div className={classNames(styles.container, className)} data-cy={TestIds.FieldMode}>
			{status.enabled ? (
				<Status
					icon={successSvg}
					label={formatMessage(messages.enabled)}
					data-cy={TestIds.FieldModeEnabled}
				/>
			) : (
				<Status
					icon={dangerSvg}
					label={formatMessage(messages.disabled)}
					data-cy={TestIds.FieldModeDisabled}
				/>
			)}
			{status.testStatus && (
				<Status
					icon={infoSvg}
					label={formatMessage(messages.test)}
					data-cy={TestIds.FieldModeTest}
				/>
			)}
			{status.forPayerFee && (
				<Status
					icon={forPayerFeeSvg}
					label={formatMessage(messages.forPayerFee)}
					data-cy={TestIds.FieldModePayerFee}
				/>
			)}
			{status.forVerifiedPayers && (
				<Status
					icon={forVerifiedSvg}
					label={formatMessage(messages.verifiedPayers)}
					data-cy={TestIds.FieldModeVerifiedPayers}
				/>
			)}
		</div>
	);
};

export default Mode;
