import { ChangeEvent, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getAlpha3Code } from 'i18n-iso-countries';
import BackButton from '../../../../components/BackButton/BackButton';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import { setOrderStep } from '../../../../redux/CardsState/CardsActions';
import {
	LoadStatus,
	OrderSteps,
	POAVerificationStatus,
} from '../../../../redux/CardsState/CardsTypes';
import Button, { ButtonType } from '../../../../components/Button/Button';
import NotificationMessage, {
	NotificationType,
	NotificationStyle,
} from '../../../../components/NotificationMessage/NotificationMessage';
import {
	getFinalizePOAVerificationURL,
	getInitPOAVerificationURL,
	getPOAStatusURL,
	uploadPOAFileURL,
} from '../../../../redux/endpoints';
import CardStatus from '../../Shared/CardStatus/CardStatus';
import { RootState } from '../../../../redux/Store';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import config from '../../../../configs/config';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import Loader from '../../../../components/Loader/Loader';
import upload from '../../../../images/upload.svg';
import styles from './UploadPOA.module.scss';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	title: {
		id: 'uploadPOA.title',
		defaultMessage: 'Address verification',
	},
	subTitle: {
		id: 'uploadPOA.subTitle',
		defaultMessage:
			'As a proof of your address we kindly ask you to upload either of these documents',
	},
	bankStatement: {
		id: 'uploadPOA.options.bankStatement',
		defaultMessage: 'Bank or other payment institution statement;',
	},
	utilityBill: {
		id: 'uploadPOA.options.utilityBill',
		defaultMessage: 'Utility bill;',
	},
	mortgage: {
		id: 'uploadPOA.options.mortgage',
		defaultMessage: 'Mortgage agreement;',
	},
	propertyTax: {
		id: 'uploadPOA.options.propertyTax',
		defaultMessage: 'Property tax statements;',
	},
	taxDeclaration: {
		id: 'uploadPOA.option.taxDeclaration',
		defaultMessage: 'Tax/Income declaration.',
	},
	poa: {
		id: 'uploadPOA.poa',
		defaultMessage: 'Proof of address',
	},
	dragDrop: {
		id: 'uploadPOA.dragDrop',
		defaultMessage: 'Drag and drop files here or click to upload.',
	},
	details: {
		id: 'uploadPOA.details',
		defaultMessage:
			'Supported file formats are .jpeg, .png and .pdf, while the maximum allowed file size is 8 MB.',
	},
	submit: {
		id: 'base.submit',
		defaultMessage: 'Submit',
	},
	fileTooBig: {
		id: 'uploadPOA.fileTooBig',
		defaultMessage: 'File is too big!',
	},
	successMessage: {
		id: 'uploadPOA.success',
		defaultMessage:
			'Your proof of address has been submitted and is waiting approval. We will let you know when your verification will be processed.',
	},
	close: {
		id: 'base.close',
		defaultMessage: 'Close',
	},
	documentContains: {
		id: 'uploadPOA.contains',
		defaultMessage: 'Please make sure that the document:',
	},
	fullName: {
		id: 'uploadPOA.containsName',
		defaultMessage: 'Contains your full name;',
	},
	address: {
		id: 'uploadPOA.containsAddress',
		defaultMessage: 'Contains your address;',
	},
	companyProvided: {
		id: 'uploadPOA.companyProvided',
		defaultMessage: 'Contains name and information of the company that provided the document;',
	},
	date: {
		id: 'uploadPOA.date',
		defaultMessage: 'Date of issue is within the last 90 days;',
	},
	latinLetters: {
		id: 'uploadPOA.latinLetters',
		defaultMessage: 'Latin letters;',
	},
	fullDocument: {
		id: 'uploadPOA.fullDocument',
		defaultMessage:
			'Full document (not a screenshot or a picture of the document). However this only applies for the screenshots or pictures of a computer screen. We can accept a picture of a physical document.',
	},
});

const typeOfFile = [
	messages.bankStatement,
	messages.utilityBill,
	messages.mortgage,
	messages.propertyTax,
	messages.taxDeclaration,
];
const requirements = [
	messages.fullName,
	messages.address,
	messages.companyProvided,
	messages.date,
	messages.latinLetters,
	messages.fullDocument,
];

const ALLOWED_SIZE = config.FILE_SIZE_POA!;

interface UploadPOAProps {
	isRha?: boolean;
}

const UploadPOA = ({ isRha }: UploadPOAProps) => {
	const [poaFile, setPoaFile] = useState<File>();
	const [additionalVerificationGUID, setAdditionalGuid] = useState('');
	const [userVerificationGUID, setUserVerificationGUID] = useState('');
	const [success, setSuccess] = useState(false);
	const [alreadyCalled, setAlreadyCalled] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const dispatch = useDispatch();
	const [fileError, setFileError] = useState<MessageDescriptor | null>();
	const { formData } = useSelector((state: RootState) => state.CardsState);
	const { formatMessage, locale } = useIntl();

	const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files![0].size > ALLOWED_SIZE) {
			setFileError(messages.fileTooBig);
		} else {
			setFileError(null);
			setPoaFile(e.target.files![0]);
		}
	};

	const handleSubmit = () => {
		if (poaFile) {
			const sendData = new FormData();
			sendData.append('name', 'file');
			sendData.append('file', poaFile);

			axiosInstance
				.post(uploadPOAFileURL(), sendData)
				.then(({ data }) => {
					setAdditionalGuid(data.id);
					setSuccess(true);
				})
				.catch((err) => setFileError(getErrorMessageOrDefault(err)));
		}
	};

	useEffectOnce(() => {
		void axiosInstance
			.get(getPOAStatusURL())
			.then(({ data }) => {
				if (data.status === POAVerificationStatus.PENDING) {
					setSuccess(true);
				} else if (data.status === POAVerificationStatus.CONFIRMED) {
					if (isRha) {
						dispatch(setOrderStep(OrderSteps.LINKED_WALLETS));
					} else {
						dispatch(setOrderStep(OrderSteps.CONFIRM));
					}
				} else if (data.status === POAVerificationStatus.NEW) {
					setAlreadyCalled(true);
				}
			})
			.catch(() => null)
			.then(() => setIsLoading(false));
	});

	useEffect(() => {
		if (formData && !isLoading && !success && !alreadyCalled) {
			void axiosInstance
				.post(getInitPOAVerificationURL(), {
					city: formData.city,
					country: getAlpha3Code(formData.deliveryCountry!, 'en'),
					firstAddressLine: `${formData.streetName} ${formData.buildingNumber}`,
					postCode: formData.postCode,
				})
				.then(({ data: initData }) => setUserVerificationGUID(initData.id))
				.catch((err) => setFileError(getErrorMessageOrDefault(err)));
		}
	}, [isLoading]);

	useEffect(() => {
		if (additionalVerificationGUID && userVerificationGUID) {
			void axiosInstance
				.post(getFinalizePOAVerificationURL(), {
					additionalVerificationGuid: additionalVerificationGUID,
					userAddressVerificationGuid: userVerificationGUID,
				})
				.catch((err) => setFileError(getErrorMessageOrDefault(err)));
		}
	}, [additionalVerificationGUID, userVerificationGUID]);

	if (isLoading) return <Loader />;

	return (
		<div className={styles.container}>
			<BackButton
				className={styles.back}
				onClick={() => dispatch(setOrderStep(OrderSteps.SHIPPING))}
			/>
			<div className={styles.alignmentContainer}>
				<InfoHead title={messages.title} />
				{!success ? (
					<>
						<p>{formatMessage(messages.subTitle)}</p>
						<ul>
							{typeOfFile.map((type) => (
								<li key={type.id}>{formatMessage(type)}</li>
							))}
						</ul>
						<div className={styles.fileUpload}>
							<input
								onChange={onFileChange}
								id="fileInput"
								type="file"
								accept=".pdf, .png, .jpg"
							/>
							<img src={upload} alt="" />
							<h3>{formatMessage(messages.poa)}</h3>
							<p>{formatMessage(messages.dragDrop)}</p>
							<p>{formatMessage(messages.details)}</p>
						</div>
						<Button
							onClick={handleSubmit}
							isDisabled={!poaFile}
							type={ButtonType.BUTTON}
							className={styles.submit}
						>
							{formatMessage(messages.submit)}
						</Button>
						{fileError && (
							<NotificationMessage
								withIcon
								type={NotificationType.Error}
								style={NotificationStyle.Border}
								message={<FormattedMessage {...fileError} />}
							/>
						)}
						<p>{formatMessage(messages.documentContains)}</p>
						<ul>
							{requirements.map((type) => (
								<li key={type.id}>{formatMessage(type)}</li>
							))}
						</ul>
					</>
				) : (
					<div className={styles.successContainer}>
						<CardStatus status={LoadStatus.PENDING} />
						<p>{formatMessage(messages.successMessage)}</p>
						<Button type={ButtonType.LINK} link={`/${locale}/account/`}>
							{formatMessage(messages.close)}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

export default UploadPOA;
