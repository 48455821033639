import { Middleware } from 'redux';
import { RootState } from '../../Store';
import PaymentButtonsActions from './PaymentButtonsActions';
import PaymentButtonsSelectors from './PaymentButtonsSelectors';

import {
	PaymentButtonCreateActionFulfilledAction,
	PaymentButtonEditActionFulfilledAction,
	PaymentButtonsListFetchButtonsFulfilledAction,
	PaymentButtonsListDeleteFulfilledAction,
	PaymentButtonCreateActionType,
	PaymentButtonEditActionType,
	PaymentButtonsListActionType,
	PaymentButtonsListStatus,
} from './PaymentButtonsTypes';

const PreordersMiddleware: Middleware<void, RootState> = (storeApi) => (next) => (
	action:
		| PaymentButtonCreateActionFulfilledAction
		| PaymentButtonEditActionFulfilledAction
		| PaymentButtonsListFetchButtonsFulfilledAction
		| PaymentButtonsListDeleteFulfilledAction
) => {
	switch (action.type) {
		case PaymentButtonsListActionType.FETCH_BUTTONS_FULFILLED:
			storeApi.dispatch(
				PaymentButtonsActions.data.updateButtons(action.payload.buttons.content)
			);
			break;
		case PaymentButtonsListActionType.DELETE_FULFILLED: {
			storeApi.dispatch(PaymentButtonsActions.data.deleteButton(action.payload));
			if (!PaymentButtonsSelectors.data.getHasAny(storeApi.getState()))
				storeApi.dispatch(
					PaymentButtonsActions.list.setStatus(PaymentButtonsListStatus.Empty)
				);
			storeApi.dispatch(PaymentButtonsActions.list.resetPages());
			break;
		}
		case PaymentButtonCreateActionType.ACTION_FULFILLED:
			storeApi.dispatch(PaymentButtonsActions.data.updateButton(action.payload));
			storeApi.dispatch(PaymentButtonsActions.list.resetPages());
			storeApi.dispatch(PaymentButtonsActions.list.setStatus(PaymentButtonsListStatus.Ready));
			break;
		case PaymentButtonEditActionType.ACTION_FULFILLED:
			storeApi.dispatch(PaymentButtonsActions.data.updateButton(action.payload));
			storeApi.dispatch(PaymentButtonsActions.list.resetPages());
			break;
		default:
			break;
	}

	next(action);
};

export default [PreordersMiddleware];
