import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import convertToLocalTime from '../../../../helpers/dateHelper/dateHelper';
import Loading from '../../Shared/Loading/Loading';
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import CopyButton from '../../../../components/CopyButton/CopyButton';
import Textarea from '../../../../components/Textarea/Textarea';
import Button, { ButtonType } from '../../../../components/Button/Button';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import { Preorder } from '../../../../redux/MerchantsState/MerchantTypes';
import PageTitle from '../../Shared/PageTitle/PageTitle';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';
import NoProjects from '../../Shared/NotFound/NotFound';
import MerchantsActions from '../../../../redux/MerchantsState/MerchantsActions';
import MerchantsSelectors from '../../../../redux/MerchantsState/MerchantsSelectors';
import useMerchantRoutes, { MerchantRoutes } from '../../../../hooks/useMerchantRoutes';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import LabeledField from '../../Shared/LabeledField/LabeledField';
import toggleModal from '../../../../redux/ModalState/ModalActions';

import styles from './ViewMerchantsPreOrder.module.scss';
import TestIds from '../../../../test/TestIds';
import InvoiceModal from '../../Shared/InvoiceModal/InvoiceModal';
import { getMerchantsPreOrderInvoiceUrl } from '../../../../redux/endpoints';
import useFormatAmount from '../../../../hooks/useFormatAmount';

const PreOrder: FC<{ preorder: Preorder; publicLink: string }> = ({
	preorder: { orderId, receiveAmount, receiveCurrencyCode, created, isMultiple },
	publicLink,
}) => {
	const { formatMessage } = useIntl();
	const amountFormatter = useFormatAmount();

	return (
		<div className={styles.preorder}>
			<LabeledField data-cy={TestIds.PreOrderViewId} label={formatMessage(messages.id)}>
				{orderId}
			</LabeledField>
			<LabeledField
				data-cy={TestIds.PreOrderViewReceive}
				label={formatMessage(messages.receive)}
			>{`${amountFormatter(
				formatPrecision(receiveAmount, receiveCurrencyCode)
			)} ${receiveCurrencyCode}`}</LabeledField>
			<LabeledField data-cy={TestIds.PreOrderViewDate} label={formatMessage(messages.date)}>
				{convertToLocalTime(created, 'yyyy-MM-dd HH:mm:ss')}
			</LabeledField>
			<LabeledField
				data-cy={TestIds.PreOrderViewUrl}
				label={
					<div className={styles.public}>
						<span>
							<FormattedMessage {...messages.publicPreOrderUrl} />
						</span>
						<CopyButton
							className={styles.copy}
							text={messages.copy}
							dataToCopy={publicLink}
						/>
					</div>
				}
			>
				<Textarea className={styles.publicLink} value={publicLink} />
			</LabeledField>
			{isMultiple && (
				<LabeledField
					data-cy={TestIds.PreOrderViewMultiple}
					className={styles.allowMultiple}
					label={formatMessage(messages.allowMultiplePayments)}
				>
					<FontAwesomeIcon icon={faCheckCircle} />
				</LabeledField>
			)}
			<Button
				data-cy={TestIds.PreOrderViewViewButton}
				className={styles.viewButton}
				type={ButtonType.ANCHOR_LINK}
				link={publicLink}
			>
				{formatMessage(messages.view)}
			</Button>
		</div>
	);
};

const Content: FC = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { getUrl, getParams } = useMerchantRoutes();
	const { projectId = '', preorderId = '' } = getParams();

	const preorder = useSelector(
		MerchantsSelectors.preorders.data.getPreorderDataById(preorderId)
	)!;
	const { name } = useSelector(MerchantsSelectors.projects.data.getProjectDataById(projectId))!;
	const link = new URL(getUrl(MerchantRoutes.PublicPreOrder), window.location.origin).href;

	const sendInvoice = useCallback(() => {
		dispatch(
			toggleModal(
				<InvoiceModal
					onClose={() => dispatch(toggleModal())}
					url={getMerchantsPreOrderInvoiceUrl(preorderId)}
				/>
			)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [preorderId]);

	return (
		<>
			<PageTitle
				className={styles.title}
				title={formatMessage(messages.preOrderDetails, { project: name })}
				backLink={getUrl(MerchantRoutes.ProjectView)}
			>
				<div
					data-cy={TestIds.PreOrderViewSendInvoice}
					className={styles.sendInvoice}
					onClick={sendInvoice}
				>
					{formatMessage(messages.sendInvoice)}
				</div>
			</PageTitle>
			<div className={styles.content}>
				<PreOrder preorder={preorder} publicLink={link} />
			</div>
		</>
	);
};

const ViewMerchantsPreOrder: FC = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { getParams } = useMerchantRoutes();

	const { projectId = '', preorderId = '' } = getParams();

	const { status: projectStatus } = useSelector(
		MerchantsSelectors.projects.data.getProjectById(projectId)
	);

	const { status: preorderStatus } = useSelector(
		MerchantsSelectors.preorders.data.getPreorderById(preorderId)
	);

	useEffect(() => {
		if (projectStatus === RemoteStatus.None)
			dispatch(MerchantsActions.projects.data.fetchProject(projectId));
	}, [dispatch, projectId, projectStatus]);

	useEffect(() => {
		if (preorderStatus === RemoteStatus.None)
			dispatch(MerchantsActions.preorders.data.fetchPreorder(preorderId));
	}, [dispatch, preorderId, preorderStatus]);

	if ([projectStatus, preorderStatus].every((x) => x === RemoteStatus.None)) return null;

	return (
		<WhiteContainer className={styles.container} data-cy={TestIds.PreOrderView}>
			{[projectStatus, preorderStatus].includes(RemoteStatus.InProgress) && <Loading />}
			{[projectStatus, preorderStatus].includes(RemoteStatus.Error) && (
				<NoProjects message={formatMessage(messages.preorderNotFound)} />
			)}
			{[projectStatus, preorderStatus].every((x) => x === RemoteStatus.Done) && <Content />}
		</WhiteContainer>
	);
};

export default ViewMerchantsPreOrder;
