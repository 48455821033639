/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { MessageDescriptor, useIntl } from 'react-intl';

interface SeoProps {
	children?: Element;
	title?: MessageDescriptor | string;
	currency?: any;
}

const Seo = ({ children, title, currency }: SeoProps) => {
	const { formatMessage } = useIntl();
	const [metaTitle, setMetaTitle] = useState('');

	useEffect(() => {
		if (title && typeof title === 'string') setMetaTitle(title);
		if (title && typeof title !== 'string') setMetaTitle(formatMessage(title));
	}, [title, formatMessage]);

	return (
		<HelmetProvider>
			<Helmet>
				<>
					{metaTitle && (
						<title>
							{`
							${currency ? `${currency} ` : ''}
							${`${metaTitle}`}
							| SpectroCoin
						`}
						</title>
					)}
					{children}
				</>
			</Helmet>
		</HelmetProvider>
	);
};

export default Seo;
