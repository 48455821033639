import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import styles from './VoucherViews.module.scss';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import failureIconPath from '../../../../images/status/failure.svg';
import TestIds from '../../../../test/TestIds';

const messages = defineMessages({
	goToWallet: {
		id: 'base.go_to_wallet',
		defaultMessage: 'Go to wallet',
	},
	goToWallets: {
		id: 'base.go_to_wallets',
		defaultMessage: 'Go to wallets',
	},
	contactSupport: {
		id: 'base.contactSupport',
		defaultMessage: 'Contact support',
	},
});

interface VoucherDepositErrorViewProps {
	accountId: string;
}

const VoucherDepositErrorView = ({ accountId }: VoucherDepositErrorViewProps) => {
	const { locale, formatMessage } = useIntl();

	return (
		<div className={styles.successBox}>
			<img src={failureIconPath} alt="failure" />
			<div className={styles.statusText} data-cy={TestIds.voucherFailureText}>
				<FormattedMessage
					id="walletdepositvouchercode.failure_message"
					defaultMessage="Failed to use voucher code. Please try again, or contact support."
				/>
			</div>

			<div className={styles.primaryButtonWrapper}>
				<Button
					className={styles.button}
					buttonStyle={ButtonStyle.PRIMARY}
					type={ButtonType.LINK}
					link={`/${locale}/account`}
					text={
						accountId
							? formatMessage(messages.goToWallet)
							: formatMessage(messages.goToWallets)
					}
					data-cy={TestIds.voucherGoToWalletButton}
				/>
				<Button
					className={styles.button}
					buttonStyle={ButtonStyle.LINK}
					type={ButtonType.ANCHOR_LINK}
					link="mailto:helpdesk@spectrocoin.com"
					text={formatMessage(messages.contactSupport)}
					data-cy={TestIds.voucherContactSupportButton}
				/>
			</div>
		</div>
	);
};

export default VoucherDepositErrorView;
