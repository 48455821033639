import { FC } from 'react';
import { useIntl } from 'react-intl';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons/faLongArrowRight';
import { CurrencyIcon } from '@spectrocoin/sc-currencies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { InstantHistoryItemProps } from '../../../../../redux/ConvertHistoryState/ConvertHistoryTypes';
import transferIconPath from '../../../../../components/Modal/DetailsModal/images/transfer.svg';
import toggleModal from '../../../../../redux/ModalState/ModalActions';
import styles from './InstantHistoryModal.module.scss';
import convertToLocalTime from '../../../../../helpers/dateHelper/dateHelper';
import statusMsg from '../../../../../messages/status.messages';
import { DetailField } from '../../../../../components/Modal/DetailsModal/DetailsModal';
import convertMsg from '../../../convertMsg';
import { formatPrecision } from '../../../../../helpers/currencyHelper/currencyHelper';
import useFormatAmount from '../../../../../hooks/useFormatAmount';

const InstantHistoryModal: FC<InstantHistoryItemProps> = ({
	id,
	created,
	exchangeRate,
	payAmount,
	payCurrencyCode,
	receiveAmount,
	receiveCurrencyCode,
	status,
}) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const amountFormatter = useFormatAmount();

	return (
		<div className={styles.modal}>
			<div
				className={styles.closeIcon}
				onClick={() => dispatch(toggleModal())}
				data-cy="closeModal"
			/>
			<div className={styles.header}>
				<img src={transferIconPath} alt="transfer icon" />
				<h2 className={styles.transactionName} data-cy="transactionName">
					<span className={styles.assets}>
						<CurrencyIcon currencyType={payCurrencyCode} className={styles.icon} />
						<CurrencyIcon
							currencyType={receiveCurrencyCode}
							className={classNames(styles.icon, styles.margin)}
						/>
						{payCurrencyCode}
						<FontAwesomeIcon icon={faLongArrowRight} className={styles.arrow} />
						{receiveCurrencyCode}
					</span>
				</h2>
				<h4 className={styles.id}>{id}</h4>
				<h5 className={styles.date} data-cy="date">
					{created && convertToLocalTime(created, 'yyyy-MM-dd HH:mm:ss')}
				</h5>
				<h6 className={styles.status} data-cy="status">
					{formatMessage(statusMsg[status])}
				</h6>
			</div>
			<div className={styles.content}>
				<DetailField
					label={convertMsg.sellAmount}
					value={`${amountFormatter(
						formatPrecision(payAmount, payCurrencyCode)
					)} ${payCurrencyCode}`}
					data-cy="payAmount"
				/>
				<DetailField
					label={convertMsg.receiveAmount}
					value={`${amountFormatter(
						formatPrecision(receiveAmount, receiveCurrencyCode)
					)} ${receiveCurrencyCode}`}
					data-cy="receiveAmount"
				/>
				<DetailField
					label={convertMsg.exchangeRate}
					value={`1 ${receiveCurrencyCode} = ${amountFormatter(
						formatPrecision(exchangeRate, payCurrencyCode)
					)} ${payCurrencyCode}`}
					data-cy="exchangeRate"
				/>
			</div>
		</div>
	);
};

export default InstantHistoryModal;
