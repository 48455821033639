import { Fragment } from 'react';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { useIntl } from 'react-intl';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';
import baseMsg from '../../../../messages/base.messages';
import { ProviderType } from '../../../../redux/WithdrawState/WithdrawTypes';
import useFormatAmount from '../../../../hooks/useFormatAmount';

interface DataProps {
	[key: string]: string;
}

interface EWalletConfirmProps {
	data: DataProps[];
	currency: CurrencyEnum;
	className: string;
	provider?: string;
	providerTitle?: string;
}

const EWalletConfirm = ({
	data,
	currency,
	className,
	provider,
	providerTitle,
}: EWalletConfirmProps): JSX.Element => {
	const { formatMessage } = useIntl();
	const amountFormatter = useFormatAmount();

	return (
		<>
			{data.map(({ amount, email, note, totalAmount }: DataProps) => (
				<Fragment key={email}>
					<InfoInput
						className={className}
						label={
							provider === ProviderType.SKRILL
								? formatMessage(withdrawMessages.emailLabel, {
										providerType: providerTitle,
								  })
								: provider === ProviderType.ADV_CASH
								? formatMessage(withdrawMessages.emailOrAccountLabel, {
										providerType: providerTitle,
								  })
								: formatMessage(withdrawMessages.ewalletAccountLabel, {
										providerType: providerTitle,
								  })
						}
						text={email}
					/>
					<InfoInput
						className={className}
						label={withdrawMessages.withdrawalAmount}
						text={`${amountFormatter(
							formatPrecision(totalAmount, currency)
						)} ${currency}`}
					/>
					<InfoInput
						className={className}
						label={baseMsg.receiveAmount}
						text={`${amountFormatter(
							formatPrecision(amount, currency, undefined, true)
						)} ${currency}`}
					/>
					{note && (
						<InfoInput
							className={className}
							label={withdrawMessages.note}
							text={note}
						/>
					)}
				</Fragment>
			))}
		</>
	);
};

export default EWalletConfirm;
