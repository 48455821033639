import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import UserContractCompany from '../enums/UserContractCompanyEnum';
import { RootState } from '../redux/Store';

const POLICY_ROUTES = [UserContractCompany.SF_BVI, UserContractCompany.SF_SYSTEMS_BVI];

const usePolicyRoute = () => {
	const { locale } = useIntl();
	const { company } = useSelector((state: RootState) => state.ProfileState);

	return `/${locale}/terms/privacy-${
		POLICY_ROUTES.includes(company as UserContractCompany) ? 'policy' : 'policy-eu'
	}.html`;
};

export default usePolicyRoute;
