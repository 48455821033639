import { useIntl } from 'react-intl';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';
import { BankSuccessResponse, ProviderType } from '../../../../redux/WithdrawState/WithdrawTypes';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import convertToLocalTime from '../../../../helpers/dateHelper/dateHelper';
import baseMsg from '../../../../messages/base.messages';
import statusMsg from '../../../../messages/status.messages';
import useFormatAmount from '../../../../hooks/useFormatAmount';

interface BankWithdrawSummaryProps {
	data: BankSuccessResponse[];
	dateClass?: string;
	infoClass?: string;
	statusClass?: string;
	provider?: string;
	hasIban?: boolean;
}

const BankWithdrawSummary = ({
	data,
	dateClass,
	infoClass,
	statusClass,
	provider,
	hasIban,
}: BankWithdrawSummaryProps) => {
	const { formatMessage } = useIntl();
	const amountFormatter = useFormatAmount();
	const isSEPAWithIBAN = provider === ProviderType.SEPA && hasIban;

	return (
		<>
			{data.map(
				({
					date,
					id,
					status,
					receiveAmount,
					withdrawAmount,
					accountNumber,
					withdrawCurrencyCode,
					receiveCurrencyCode,
					swift,
					other,
					receiver,
					sortCode,
				}) => {
					return (
						<li key={id}>
							{date && (
								<div data-cy="date" className={dateClass}>
									{convertToLocalTime(date, 'yyyy-MM-dd HH:mm:ss')}
								</div>
							)}
							{status && (
								<div data-cy="status" className={statusClass}>
									{formatMessage(statusMsg[status])}
								</div>
							)}
							{id && (
								<InfoInput
									label={formatMessage(baseMsg.transactionHash)}
									text={id}
									className={infoClass}
									data-cy="id"
								/>
							)}
							{!isSEPAWithIBAN && accountNumber && (
								<InfoInput
									label={formatMessage(withdrawMessages.accountLabel)}
									text={accountNumber}
									className={infoClass}
									data-cy="accountNumber"
								/>
							)}
							{sortCode && (
								<InfoInput
									label={formatMessage(withdrawMessages.sortCodeLabel)}
									text={sortCode}
									className={infoClass}
									data-cy="sortCode"
								/>
							)}
							{swift && (
								<InfoInput
									label={formatMessage(withdrawMessages.swiftLabel)}
									text={swift}
									className={infoClass}
									data-cy="swiftCode"
								/>
							)}
							{receiver && (
								<InfoInput
									label={formatMessage(baseMsg.receiver)}
									text={receiver}
									className={infoClass}
									data-cy="receiver"
								/>
							)}
							{isSEPAWithIBAN && accountNumber && (
								<InfoInput
									className={infoClass}
									label={formatMessage(withdrawMessages.accountNumberIbanLabel)}
									text={accountNumber}
								/>
							)}
							{isSEPAWithIBAN && (
								<InfoInput
									className={infoClass}
									label={formatMessage(withdrawMessages.withdrawalMethod)}
									text={provider}
								/>
							)}
							{receiveAmount && (
								<InfoInput
									label={formatMessage(baseMsg.receiveAmount)}
									text={`${amountFormatter(
										formatPrecision(receiveAmount, receiveCurrencyCode)
									)} ${receiveCurrencyCode}`}
									className={infoClass}
									data-cy="receiveAmount"
								/>
							)}
							{withdrawAmount && (
								<InfoInput
									label={formatMessage(baseMsg.totalAmountWithFee)}
									text={`${amountFormatter(
										formatPrecision(withdrawAmount, withdrawCurrencyCode)
									)} ${withdrawCurrencyCode}`}
									className={infoClass}
									data-cy="withdrawAmount"
								/>
							)}
							{other && (
								<InfoInput
									label={formatMessage(withdrawMessages.details)}
									text={other}
									className={infoClass}
									data-cy="details"
								/>
							)}
						</li>
					);
				}
			)}
		</>
	);
};

export default BankWithdrawSummary;
