import { useDispatch } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { toggleCardsSidebar } from '../../../../redux/AppState/AppActions';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import Button, { ButtonType } from '../../../../components/Button/Button';
import forbiddenImgPath from './images/forbidden.svg';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import styles from './CompanyUser.module.scss';
import baseMsg from '../../../../messages/base.messages';
import useEffectOnce from '../../../../hooks/useEffectOnce';

const messages = defineMessages({
	title: {
		id: 'companyUser.title',
		defaultMessage: 'Business cards are coming soon',
	},
	text: {
		id: 'companyUser.text',
		defaultMessage: 'Currently cards are only available for private users',
	},
});

const CompanyUser = () => {
	const { locale } = useIntl();
	const dispatch = useDispatch();

	useEffectOnce(() => {
		dispatch(toggleCardsSidebar(false));
	});

	return (
		<WhiteContainer backButtonLink={`/${locale}/account`}>
			<InfoHead title={messages.title} img={forbiddenImgPath} text={messages.text} />
			<Button
				type={ButtonType.LINK}
				link={`/${locale}/account`}
				className={styles.button}
				text={baseMsg.close}
			/>
		</WhiteContainer>
	);
};

export default CompanyUser;
