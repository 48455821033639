import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RootState } from '../../redux/Store';
import Accounts from '../../pages/Accounts/Accounts';
import WalletHistory from '../../pages/WalletHistory/WalletHistory';
import ManageWallets from '../../pages/ManageWallets/ManageWallets';
import MarketingSlider from '../../components/MarketingSlider/MarketingSlider';
import RatesBanner from '../../components/RatesBanner/RatesBanner';
import IBANContainer from '../IBANContainer/IBANContainer';
import styles from './WalletsContainer.module.scss';

const WalletsContainer = () => {
	const referenceCurrency = (Cookies.get('primaryCurrency') || CurrencyEnum.USD) as CurrencyEnum;
	const { showWalletSidebar, showCurrentRates, isMobile, isTablet } = useSelector(
		(state: RootState) => state.AppState
	);
	const { url }: { url: string } = useRouteMatch();
	const { pathname } = useLocation();
	const { locale } = useIntl();

	return (
		<div
			className={classNames(styles.content, {
				[styles.noSidebar]:
					!showWalletSidebar && pathname !== `/${locale}/account/manage-wallets`,
				[styles.ibans]: pathname.indexOf('IBAN') > -1,
			})}
		>
			<Route exact path={url} render={() => <Accounts refCurrency={referenceCurrency} />} />
			<Route
				exact
				path={`${url}/history/:currencyCode/:id`}
				render={() => <WalletHistory refCurrency={referenceCurrency} />}
			/>
			<Route path={`${url}/history/:currencyCode/:id/IBAN`} component={IBANContainer} />
			<Route
				exact
				path={`${url}/manage-wallets`}
				render={() => <ManageWallets refCurrency={referenceCurrency} />}
			/>
			{showWalletSidebar && (isMobile || isTablet) && <MarketingSlider />}
			{showWalletSidebar && showCurrentRates && (isMobile || isTablet) && (
				<RatesBanner refCurrency={referenceCurrency} />
			)}
		</div>
	);
};

export default WalletsContainer;
