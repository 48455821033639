import {
	WithdrawHistoryState,
	WithdrawHistoryActionType,
	SetHistoryIsLoadingAction,
	SetHistoryDataAction,
	SetInitialStateAction,
} from './WithdrawHistoryTypes';

const initialState: WithdrawHistoryState = {
	isLoading: true,
	data: [],
	totalCount: 0,
};

export default (
	state = initialState,
	action: SetHistoryIsLoadingAction | SetHistoryDataAction | SetInitialStateAction
) => {
	switch (action.type) {
		case WithdrawHistoryActionType.SET_DATA:
			return {
				...state,
				totalCount: action.totalCount,
				data: action.data,
			};
		case WithdrawHistoryActionType.RESET_DATA:
			return initialState;
		case WithdrawHistoryActionType.IS_LOADING:
			return {
				...state,
				isLoading: action.data,
			};
		default:
			return state;
	}
};
