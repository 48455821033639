import {
	SetWithdrawPaymentMethodsByGroup,
	WithdrawActionType,
	WithdrawState,
	ResetWithdrawPaymentMethodsByGroup,
	SetMobileTopupCountryAction,
} from './WithdrawTypes';

const initialState: WithdrawState = {
	paymentMethods: {
		crypto: {
			isLoaded: false,
			data: [],
			totalCount: 0,
		},
		ewallet: {
			isLoaded: false,
			data: [],
			totalCount: 0,
		},
		bank: {
			isLoaded: false,
			data: [],
			totalCount: 0,
		},
		gift_cards: {
			isLoaded: false,
			data: [],
			totalCount: 0,
		},
		voucher: {
			isLoaded: false,
			data: [],
			totalCount: 0,
		},
		topups: {
			isLoaded: false,
			data: [],
			totalCount: 0,
			countryCode: null,
		},
		offchain: {
			isLoaded: false,
			data: [],
		},
	},
};
export default (
	state = initialState,
	action:
		| SetWithdrawPaymentMethodsByGroup
		| ResetWithdrawPaymentMethodsByGroup
		| SetMobileTopupCountryAction
) => {
	switch (action.type) {
		case WithdrawActionType.SET_PAYMENT_METHODS_BY_GROUP:
			return {
				...state,
				paymentMethods: {
					...state.paymentMethods,
					[action.group.toLowerCase()]: {
						...state.paymentMethods[action.group.toLowerCase()],
						isLoaded: true,
						data:
							action?.data?.paymentMethods ||
							state?.paymentMethods[action.group.toLowerCase()]?.data,

						totalCount:
							action?.data?.count ||
							state?.paymentMethods[action.group.toLowerCase()]?.totalCount,
					},
				},
			};
		case WithdrawActionType.RESET_PAYMENT_BY_GROUP:
			return {
				...state,
				paymentMethods: {
					...state.paymentMethods,
					[action.group.toLowerCase()]: {
						...state.paymentMethods[action.group.toLowerCase()],
						isLoaded: false,
						data: [],
						totalCount: state.paymentMethods[action.group.toLowerCase()].totalCount,
					},
				},
			};
		case WithdrawActionType.SET_TOPUP_COUNTRY:
			return {
				...state,
				paymentMethods: {
					...state.paymentMethods,
					topups: {
						...state.paymentMethods.topups,
						countryCode: action.countryCode,
					},
				},
			};
		default:
			return state;
	}
};
