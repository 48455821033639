/* eslint-disable import/prefer-default-export */
import {
	getReferralCallbacksUrl,
	getReferralCodesLimitUrl,
	getReferralCodessUrl,
	getReferralConsentUrl,
	getReferralDefaultCodeUrl,
	getReferralProfileSummaryCommissionsUrl,
	getReferralProfileSummaryTotalsUrl,
} from '../../endpoints';
import PageableResponse from '../../../interfaces/PageableResponse';
import { throwLoaderError } from '../../../helpers/loaderUtils';
import axiosInstance from '../../../helpers/axiosInstance';

export const profitSummaryQuery = {
	getKey: () => ['referrals.profitSummary'],
	getFn: () => () =>
		axiosInstance
			.get<{
				cashbacks: number;
				commissions: number;
				signups: number;
			}>(getReferralProfileSummaryTotalsUrl())
			.then(({ data }) => data)
			.catch(throwLoaderError),
};

export type DefaultReferralCode = {
	cashback: number;
	commission: number;
	default: true;
	id: string;
	note: string;
	signups: number;
};

export const defaultCodeQuery = {
	getKey: () => ['referrals.defaultCode'],
	getFn: () => () =>
		axiosInstance
			.get<DefaultReferralCode>(getReferralDefaultCodeUrl())
			.then(({ data }) => data)
			.catch(throwLoaderError),
	mutationFn: (id: string) =>
		axiosInstance
			.post<DefaultReferralCode>(getReferralDefaultCodeUrl(), {}, { params: { id } })
			.then(({ data }) => data)
			.catch(throwLoaderError),
};

export type ReferralComission = {
	amount: number;
	count: number;
	email: string;
	refId: string;
	trackId: string;
};

export const commissionsQuery = {
	getKey: (page: number, sort: string) => ['referrals.commissions', page, sort],
	getFn: (page: number, sort: string) => () =>
		axiosInstance
			.get<PageableResponse<Array<ReferralComission>>>(
				getReferralProfileSummaryCommissionsUrl(),
				{
					params: {
						page,
						size: 10,
						sort,
					},
				}
			)
			.then(({ data }) => data)
			.catch(throwLoaderError),
};

export type ReferralCallback = {
	created: string;
	id: number;
	refEmail: string;
	refId: string;
	refTrackId: string;
	refUserGuid: string;
	statusCode: number;
};

export const callbacksQuery = {
	getKey: (page: number, sort: string) => ['referrals.callbacks', page, sort],
	getFn: (page: number, sort: string) => () =>
		axiosInstance
			.get<PageableResponse<Array<ReferralCallback>>>(getReferralCallbacksUrl(), {
				params: {
					page,
					size: 10,
					sort,
				},
			})
			.then(({ data }) => data)
			.catch(throwLoaderError),
};

export type ReferralId = {
	cashback: number;
	commission: number;
	default: boolean;
	id: string;
	note: string;
	signups: number;
};

export const referralIdsQuery = {
	getKey: (filters?: { page: number; sort: string }) => [
		'referrals.referral_ids',
		...(filters ? [filters.page, filters.sort] : []),
	],
	getFn: (filters?: { page: number; sort: string }) => () =>
		axiosInstance
			.get<PageableResponse<Array<ReferralId>>>(getReferralCodessUrl(), {
				params: {
					page: filters?.page,
					size: 10,
					sort: filters?.sort,
				},
			})
			.then(({ data }) => data)
			.catch(throwLoaderError),
	mutationFn: (code: {
		cashback: number;
		commission: number;
		isDefault: boolean;
		note: string;
	}) =>
		axiosInstance
			.post<ReferralId>(getReferralCodessUrl(), code)
			.then(({ data }) => data)
			.catch(throwLoaderError),
};

export const referralCodeLimitQuery = {
	getKey: () => ['referrals.codes.limit'],
	getFn: () => () =>
		axiosInstance
			.get<{ limit: number }>(getReferralCodesLimitUrl())
			.then(({ data }) => data.limit)
			.catch(throwLoaderError),
};

export const consentQuery = {
	getKey: () => ['referrals.consent'],
	getFn: () => () =>
		axiosInstance
			.get<{
				accepted: boolean;
			}>(getReferralConsentUrl())
			.then(({ data }) => data)
			.catch(throwLoaderError),
};
