import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useMerchantRoutes, { MerchantRoutes } from '../../../../hooks/useMerchantRoutes';
import { PaymentButton } from '../../../../redux/MerchantsState/MerchantTypes';
import AutoconvertField from '../AutoconvertField/AutoconvertField';
import ProjectMode from '../Mode/Mode';
import NameField from '../NameField/NameField';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';
import LabeledField, { LabeledFieldStyle } from '../LabeledField/LabeledField';

import styles from './MobilePaymentButton.module.scss';
import PaymentButtonAction from '../PaymentButtonAction/PaymentButtonAction';
import AcceptedCurrenciesField from '../AcceptedCurrenciesField/AcceptedCurrenciesField';

type ProjectProps = {
	button: PaymentButton;
};

const MobilePaymentButton: FC<ProjectProps> = ({ button }) => {
	const { getUrl } = useMerchantRoutes();
	const { formatMessage } = useIntl();

	return (
		<Link
			className={styles.button}
			to={getUrl(MerchantRoutes.PaymentButton, { paymentButtonId: button.id })}
		>
			<div className={styles.data}>
				<LabeledField
					className={styles.labeled}
					style={LabeledFieldStyle.Row}
					label={formatMessage(messages.name)}
				>
					<NameField name={button.name} />
				</LabeledField>
				<LabeledField
					className={styles.labeled}
					style={LabeledFieldStyle.Row}
					label={formatMessage(messages.autoconvert)}
				>
					<AutoconvertField accountId={button.receiveAccountId} />
				</LabeledField>
				<LabeledField
					className={styles.labeled}
					style={LabeledFieldStyle.Row}
					label={formatMessage(messages.acceptedCurrencies)}
				>
					<AcceptedCurrenciesField currencies={button.payCurrencyCodes} />
				</LabeledField>
				<LabeledField
					className={styles.labeled}
					style={LabeledFieldStyle.Row}
					label={formatMessage(messages.mode)}
				>
					<ProjectMode status={{ enabled: button.enabled }} />
				</LabeledField>
			</div>
			<div className={styles.action}>
				<PaymentButtonAction button={button} />
			</div>
		</Link>
	);
};

export default MobilePaymentButton;
