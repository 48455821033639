import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { throwLoaderError } from '../../../helpers/loaderUtils';
import PageableResponse from '../../../interfaces/PageableResponse';
import {
	getMerchantsCallbackUrl,
	getMerchantsCallbacksUrl,
	getMerchantsOrderCallbackRetryUrl,
} from '../../endpoints';
import { OrderCallback } from './OrderCallbacksTypes';
import { RemoteError } from '../../../interfaces/RemoteData';
import axiosInstance from '../../../helpers/axiosInstance';

const getListCallbackData = {
	getKey: (orderId: string) => ['merchants.callbacks', orderId],
	getFn: (orderId: string) => () =>
		axiosInstance
			.get<PageableResponse<OrderCallback[]>>(getMerchantsCallbacksUrl(), {
				params: {
					orderId,
					size: 2000,
					sort: 'created,desc',
				},
			})
			.then(({ data: { content } }) => content)
			.catch(throwLoaderError),
};

export const useOrderCallbacksListDataByOrderId = (orderId: string) =>
	useQuery<OrderCallback[], RemoteError>({
		queryKey: getListCallbackData.getKey(orderId),
		queryFn: getListCallbackData.getFn(orderId),
	});

const getOrderCallbackByCallbackId = {
	getKey: (callbackid: string) => ['merchants.callback', callbackid],
	getFn: (callbackId: string) => () =>
		axiosInstance
			.get<OrderCallback>(getMerchantsCallbackUrl(callbackId))
			.then(({ data }) => data)
			.catch(throwLoaderError),
};

export const useOrderCallbackDataByCallbackId = (callbackId: string) =>
	useQuery<OrderCallback, RemoteError>({
		queryKey: getOrderCallbackByCallbackId.getKey(callbackId),
		queryFn: getOrderCallbackByCallbackId.getFn(callbackId),
	});

export const retryCallback = (orderId: string) =>
	axiosInstance
		.post(getMerchantsOrderCallbackRetryUrl(orderId))
		.then(({ data }) => data)
		.catch(throwLoaderError);

export const useRetryCallbackMutation = (orderId: string) => {
	const queryClient = useQueryClient();

	return useMutation<any, RemoteError>({
		mutationFn: () => retryCallback(orderId),
		onSuccess: () =>
			void queryClient.invalidateQueries({ queryKey: getListCallbackData.getKey(orderId) }),
	});
};
