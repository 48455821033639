/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { AxiosRequestConfig, AxiosError, AxiosResponse, CreateAxiosDefaults } from 'axios';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { ProfileActionType } from '../redux/ProfileState/ProfileTypes';
import AuthErrorCode from '../enums/AuthErrorCode';
import AuthErrorKey from '../enums/AuthErrorKey';
import store from '../redux/Store';
import { resetIdleTimer } from '../redux/ProfileState/ProfileActions';
import { TwoFaCode } from '../redux/TwoFaState/TwoFaTypes';
import handle2FA from '../middleware/twoFaMiddleware';

const currentExecutingRequest = {};

const SESSION_INVALID_EVENT = 'SESSION_INVALID';

type AxiosResponseInterceptor = [
	(response: AxiosResponse) => AxiosResponse,
	(error: AxiosError) => Promise<AxiosError> | void
];

declare module 'axios' {
	export interface AxiosRequestConfig {
		skip2FA?: boolean;
		skipIdle?: boolean;
	}
}

interface AccessToken {
	sub: string;
	'auth-proxy-group-id': string;
	user_name?: string;
	session_id: string;
	login_ip: string;
	authorities: string[];
	client_id: string;
	user_id?: string;
	scope?: string[];
	user_level?: number;
	environment_code?: string;
	exp: number;
	admin_environments?: string[];
	jti: string;
}

const isUserLoggedIn = () => {
	const accessToken = Cookies.get('ACCESS_TOKEN');
	if (accessToken && (jwtDecode(accessToken) as AccessToken).user_id) return true;
	return false;
};

export function getAxiosRequestInterceptor(): any {
	return [
		({ skipIdle = false, ...config }: AxiosRequestConfig) => {
			if (!skipIdle) {
				store.dispatch<any>(resetIdleTimer());
			}
			const newConfig: any = { ...config };
			if (config.url?.search('spectrocoin') === -1) {
				delete newConfig.xsrfCookieName;
				delete newConfig.xsrfHeaderName;
				delete newConfig.headers?.common?.Pragma;
			}
			if (currentExecutingRequest[config.url!]) {
				currentExecutingRequest[config.url!].abort();
				delete currentExecutingRequest[config.url!];
			}

			currentExecutingRequest[config.url!] = new AbortController();

			return newConfig;
		},
		(error: AxiosError) => {
			return Promise.reject(error);
		},
	];
}

export function getAxiosResponseInterceptor(): AxiosResponseInterceptor {
	const redirectToLogin = () => {
		const url = new URL(`${window.location.origin}/login`);
		// when coming using a link without an active session,
		if (!isUserLoggedIn()) url.searchParams.append('redirect', window.location.href);
		window.location.assign(url.href);
	};

	return [
		(response: AxiosResponse) => response,
		(error: AxiosError<any>) => {
			// const accessToken = Cookies.get('ACCESS_TOKEN');
			if (error && error.response) {
				const { response } = error;
				if (
					response?.data?.errorCode === TwoFaCode.TWO_FA_REQUIRED &&
					!response.config.skip2FA
				) {
					handle2FA(store, response?.config, response.data, response?.data?.errorCode);
				}
				if (response.status === 401) {
					const { data } = response;
					// Auth server session expired
					if (
						(data.error === AuthErrorKey.UNAUTHORIZED &&
							data.error_description === AuthErrorKey.FULL_AUTHENTICATION_REQUIRED) ||
						data.errorCode === AuthErrorCode.AP_7
					) {
						redirectToLogin();
						window.dispatchEvent(new CustomEvent(SESSION_INVALID_EVENT));
						store.dispatch({ type: ProfileActionType.SET_IS_LOGGED_IN, data: false });
					}
				}

				if (response.status === 403 && response.data) {
					const { data } = response;

					if (data.key === AuthErrorKey.ACCESS_DENIED && !isUserLoggedIn()) {
						// window.logoutUser({ redirectHome: false, redirectToLogin: true });
						redirectToLogin();
						store.dispatch({ type: ProfileActionType.SET_IS_LOGGED_IN, data: false });
					}
				}
			}
			return Promise.reject(error);
		},
	];
}

export const axiosConfig: CreateAxiosDefaults = {
	headers: { 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json' },
	withCredentials: true,
	transformRequest: (data, headers) => {
		if (!data || data instanceof FormData || data instanceof URLSearchParams) return data;

		if (headers && !Object.keys(data).includes('set-pin')) {
			headers.set('Content-Type', 'application/json;charset=utf-8');
		}

		if (Object.keys(data).includes('set-pin')) {
			return `"${data['set-pin']}"`;
		}
		if (Object.keys(data).includes('password')) {
			return `"${data.password}"`;
		}

		// Set headers to json, as axios doesn't do that with custom requests
		return JSON.stringify(
			Object.entries(data).reduce(
				(returnObject, [key, value]) => ({
					...returnObject,
					[key]:
						value && typeof value === 'string' && !['password'].includes(key)
							? value.trim()
							: value,
				}),
				{}
			)
		);
	},
};
