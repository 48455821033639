import ProjectsCreateActions from './ProjectsCreate/ProjectsCreateActions';
import ProjectsDataActions from './ProjectsData/ProjectsDataActions';
import ProjectsListActions from './ProjectsList/ProjectsListActions';
import ProjectsEditActions from './ProjectsEdit/ProjectsEditActions';

export default {
	create: ProjectsCreateActions,
	data: ProjectsDataActions,
	list: ProjectsListActions,
	edit: ProjectsEditActions,
};
