/* eslint-disable @typescript-eslint/no-explicit-any */
import { MessageDescriptor } from 'react-intl';
import { AxiosResponse } from 'axios';
import { CurrencyEnum, NetworkEnum } from '@spectrocoin/sc-currencies';
import {
	SetWalletAction,
	AccountsActionType,
	SetIsLoadingAction,
	SetErrorAction,
	Wallet,
	SetUpdateWalletAction,
	SetAccountByIdAction,
	SetAccountNetworkAction,
	CurrencyNetwork,
	BTCAddressType,
	DepositAddressResponse,
} from './AccountsTypes';
import { AppDispatch, RootState } from '../Store';
import {
	getAccountByIdURL,
	getAccountsURL,
	getCurrencyNetworkURL,
	getDepositAddressURL,
	getIsNewAddressAvailableURL,
} from '../endpoints';
import { setGeneralError } from '../AppState/AppActions';
import axiosInstance from '../../helpers/axiosInstance';

const setIsLoading = (isLoading: boolean) => (dispatch: AppDispatch) => {
	return dispatch<SetIsLoadingAction>({
		type: AccountsActionType.SET_IS_LOADING,
		data: isLoading,
	});
};

export const setError = (data: MessageDescriptor | null) => (dispatch: AppDispatch) => {
	return dispatch<SetErrorAction>({
		type: AccountsActionType.SET_ERROR,
		data,
	});
};

export const getAccounts = () => async (dispatch: AppDispatch) => {
	await axiosInstance
		.get(getAccountsURL(), { params: { paged: true, size: 1000 } })
		.then(({ data: { content } }: AxiosResponse) => {
			return dispatch<SetWalletAction>({
				type: AccountsActionType.SET_WALLETS,
				data: content.sort((a: Wallet, b: Wallet) => {
					return a.sortIndex - b.sortIndex;
				}),
			});
		})
		.catch(({ response }) => {
			if (response?.status === 401 || response?.status === 403) {
				return null;
			}
			// eslint-disable-next-line no-console
			console.error(`Couldn't get accounts from endpoint`);
			return dispatch<any>(setGeneralError(true));
		})
		.then(() => {
			return dispatch<any>(setIsLoading(false));
		});
};

export const updateWallet = (wallet: Wallet) => (dispatch: AppDispatch) => {
	return dispatch<SetUpdateWalletAction>({
		type: AccountsActionType.UPDATE_WALLET,
		data: wallet,
	});
};

export const addNewWallet = ({
	isNew,
	sortIndex,
	id,
	hideIfEmpty,
	currencyCode,
	currencyName,
	hideBalance,
}: Wallet) => (dispatch: AppDispatch, getState: () => RootState) => {
	const newWallet = {
		id,
		currencyCode,
		currencyName,
		label: '',
		hideIfEmpty,
		hideBalance,
		balance: '0',
		availableBalance: '0',
		reservedAmount: '0',
		sortIndex,
		cryptoAddress: null,
		cryptoAddressSegWit: null,
		message: null,
		iban: null,
		ibanInfo: null,
		isNew,
	};

	const walletArray = getState().AccountsState?.wallets;
	walletArray?.push(newWallet);
	dispatch<SetWalletAction>({
		type: AccountsActionType.SET_WALLETS,
		data: walletArray!,
	});

	return dispatch(getAccounts());
};

export const getAccountById = (id: string) => (dispatch: AppDispatch) => {
	return axiosInstance.get(getAccountByIdURL(id)).then(({ data }: AxiosResponse<Wallet>) => {
		return dispatch<SetAccountByIdAction>({
			type: AccountsActionType.SET_WALLET_BY_ID,
			data,
		});
	});
};

export const getWalletAddress = (
	id: string,
	networkName: string,
	currencyCode: CurrencyEnum,
	addressType?: BTCAddressType
) => (dispatch: AppDispatch) => {
	dispatch(setIsLoading(true));
	dispatch(setError(null));
	return axiosInstance
		.get(getDepositAddressURL(id, networkName, addressType))
		.then(({ data }: AxiosResponse<DepositAddressResponse[]>) => {
			if (data?.length === 0) {
				void dispatch(getNewCryptoAddress(id, networkName, addressType));
			} else {
				dispatch({
					type:
						addressType === BTCAddressType.BECH32
							? AccountsActionType.SET_SEGWIT_ADDRESS
							: AccountsActionType.SET_CRYPTO_ADDRESS,
					data: {
						address: data[0].depositAccount,
						message: data[0].message,
						id,
					},
				});
			}
		})
		.catch(() => null)
		.then(() => dispatch(setIsLoading(false)));
};

export const getAccountNetwork = (currencyCode: CurrencyEnum, id: string) => (
	dispatch: AppDispatch
) => {
	void axiosInstance.get(getCurrencyNetworkURL(currencyCode)).then(({ data: networkList }) => {
		dispatch<SetAccountNetworkAction>({
			type: AccountsActionType.SET_ACCOUNT_NETWORK,
			data: {
				id,
				networkList: [
					...networkList
						.filter((item: CurrencyNetwork) => {
							if (item.isEnabled) return item;
							return null;
						})
						.sort((a: any, b: any) => {
							return b.isDefault - a.isDefault;
						}),
				],
			},
		});
	});
};

export const getIsNewAddressAvailable = async (id: string, network?: NetworkEnum) => {
	const response = await axiosInstance
		.get(getIsNewAddressAvailableURL(id, network))
		.then(({ data }: AxiosResponse<{ canCreate: boolean }>) => data.canCreate)
		.catch(() => false);
	return response;
};

export const getNewCryptoAddress = (
	accountId: string,
	network: CurrencyNetwork['networkName'],
	addressType: BTCAddressType | null = null
) => (dispatch: AppDispatch) => {
	dispatch(setIsLoading(true));
	dispatch(setError(null));
	void axiosInstance
		.post(getDepositAddressURL(), {
			accountId,
			addressType,
			network,
			callbackUrl: null,
		})
		.then(({ data }) => {
			dispatch({
				type:
					addressType === BTCAddressType.BECH32
						? AccountsActionType.SET_SEGWIT_ADDRESS
						: AccountsActionType.SET_CRYPTO_ADDRESS,
				data: {
					address: data?.depositAccount,
					message: data?.message,
					id: accountId,
				},
			});
		})
		.catch(() => null)
		.then(() => dispatch(setIsLoading(false)));
};
