import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { toDecimal } from '../../../helpers/currencyHelper/currencyHelper';
import { RootState } from '../../../redux/Store';
import { Wallet } from '../../../redux/AccountsState/AccountsTypes';
import withdrawMessages from '../../../redux/WithdrawState/WithdrawMessages';
import Loader from '../../../components/Loader/Loader';
import PageTitle from '../../../components/PageTitle/PageTitle';
import SearchBar from '../../../components/SearchBar/SearchBar';
import ScrollableWalletList, {
	ListType,
	SortType,
} from '../../../components/ScrollableWalletList/ScrollableWalletList';
import styles from './CryptoWithdraws.module.scss';
import Seo from '../../../components/Seo/Seo';

interface CryptoProps {
	accounts: CurrencyEnum[] | string[];
}

const currenciesSortPriority = {
	[CurrencyEnum.BTC]: 5,
	[CurrencyEnum.ETH]: 4,
	[CurrencyEnum.USDT]: 3,
	[CurrencyEnum.USDC]: 2,
	[CurrencyEnum.XRP]: 1,
};

const CryptoWithdraws = ({ accounts }: CryptoProps) => {
	const { locale } = useIntl();
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const [list, setList] = useState<Wallet[] | null>(null);
	const [sortType, setSortType] = useState<SortType | null>(null);
	const [sortAsc, setSortAsc] = useState(true);
	const [nameFilter, setNameFilter] = useState('');

	const changeSortType = (type: SortType) => {
		setSortType(type);
		return setSortAsc(!sortAsc);
	};

	const handleSearchInputChange = (val: string) => setNameFilter(val);

	useEffect(() => {
		if (accounts.length > 0 && wallets && wallets?.length > 0) {
			const filteredAccounts = wallets
				.filter(({ currencyCode }: Wallet) =>
					accounts.includes(currencyCode as CurrencyEnum)
				)
				.filter(({ currencyCode, currencyName }: Wallet) => {
					if (nameFilter) {
						return (
							currencyName.toLowerCase().includes(nameFilter.toLowerCase()) ||
							currencyCode.toLowerCase().includes(nameFilter.toLowerCase())
						);
					}
					return true;
				})
				.sort((a: Wallet, b: Wallet) => {
					if (sortType) {
						if (sortType === SortType.NAME) {
							if (sortAsc) return a.currencyCode > b.currencyCode ? 1 : -1;
							if (!sortAsc) return a.currencyCode < b.currencyCode ? 1 : -1;
						}
						if (sortType === SortType.BALANCE) {
							return sortAsc
								? (toDecimal(a.availableBalance).minus(
										toDecimal(b.availableBalance)
								  ) as any)
								: (toDecimal(b.availableBalance).minus(
										toDecimal(a.availableBalance)
								  ) as any);
						}
					}
					return (
						(currenciesSortPriority[b.currencyCode] || 0) -
							(currenciesSortPriority[a.currencyCode] || 0) ||
						a.currencyCode.localeCompare(b.currencyCode, undefined, {
							numeric: true,
							sensitivity: 'base',
						})
					);
				});
			setList(filteredAccounts);
		}
	}, [accounts, nameFilter, sortAsc, sortType, wallets]);

	if (!list) return <Loader className={styles.loader} />;
	return (
		<>
			<Seo title={withdrawMessages.metaCryptoTitle} />
			<PageTitle
				historyLink={`/${locale}/withdraw/history/crypto`}
				title={withdrawMessages.cryptoWithdraws}
			/>
			<SearchBar nameFilter={nameFilter} onChange={handleSearchInputChange} />
			<ScrollableWalletList
				changeSortType={changeSortType}
				list={list}
				type={ListType.WITHDRAW}
			/>
		</>
	);
};

export default CryptoWithdraws;
