import { useIntl } from 'react-intl';
import { WithdrawHistoryItem } from '../../../../redux/WithdrawHistoryState/WithdrawHistoryTypes';
import { DetailsModalType } from '../../../../components/Modal/DetailsModal/DetailsModal';
import statusMsg from '../../../../messages/status.messages';
import HistoryListItem from '../../../../components/HistoryTable/HistoryListItem/HistoryListItem';

interface Props {
	data: WithdrawHistoryItem[];
}

const BankWithdrawHistory = ({ data }: Props): JSX.Element => {
	const { formatMessage } = useIntl();
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{data?.length > 0 &&
				data.map(
					({
						accountNumber,
						date,
						feeAmount,
						id,
						other,
						receiveAmount,
						receiver,
						receiverAddress,
						sortCode,
						status,
						swift,
						withdrawAmount,
						withdrawCurrencyCode,
					}: WithdrawHistoryItem) => (
						<HistoryListItem
							key={id}
							title={accountNumber}
							fee={feeAmount}
							feeCurrency={withdrawCurrencyCode}
							dateCreated={date}
							currency={withdrawCurrencyCode}
							status={formatMessage(statusMsg[status])}
							detailsModalType={DetailsModalType.BANK_WITHDRAW}
							totalAmount={withdrawAmount}
							amount={receiveAmount}
							receiver={receiver}
							other={other}
							accountNumber={accountNumber}
							swift={swift}
							sortCode={sortCode}
							receiverAddress={receiverAddress}
						/>
					)
				)}
		</>
	);
};

export default BankWithdrawHistory;
