import { FC } from 'react';
import classNames from 'classnames';
import AirlinesIcon from './images/mcc_airlines.svg';
import AtmIcon from './images/mcc_atm.svg';
import BakariesIcon from './images/mcc_bakaries.svg';
import BarsIcon from './images/mcc_bars.svg';
import CarRentalIcon from './images/mcc_carRental.svg';
import ClothingIcon from './images/mcc_clothing.svg';
import DairyIcon from './images/mcc_dairy.svg';
import FastFoodIcon from './images/mcc_fastFood.svg';
import FoodIcon from './images/mcc_food.svg';
import HotelsIcon from './images/mcc_hotels.svg';
import PackageIcon from './images/mcc_package.svg';
import ParkingIcon from './images/mcc_parking.svg';
import PetrolIcon from './images/mcc_petrol.svg';
import PosCashIcon from './images/mcc_posCash.svg';
import RestaurantsIcon from './images/mcc_restaurant.svg';
import SupermarketIcon from './images/mcc_supermarket.svg';
import UtilityServiceIcon from './images/mcc_utilityService.svg';
import ContractorsIcon from './images/mcc_contractors.svg';
import ShipsIcon from './images/mcc_ships.svg';
import TrainsIcon from './images/mcc_trains.svg';
import PublicTransportIcon from './images/mcc_publicTransport.svg';
import TechnologyIcon from './images/mcc_technology.svg';
import ShopsIcon from './images/mcc_shops.svg';
import SportsIcon from './images/mcc_sports.svg';
import PharmacyIcon from './images/mcc_pharmacy.svg';
import EntertainmentIcon from './images/mcc_entertainment.svg';
import FinancialIcon from './images/mcc_financial.svg';
import PhototgraphyIcon from './images/mcc_photography.svg';
import PressIcon from './images/mcc_press.svg';
import BeautyIcon from './images/mcc_beauty.svg';
import BooksIcon from './images/mcc_books.svg';
import IngoingsIcon from './images/mcc_ingoings.svg';
import OutgoingsIcon from './images/mcc_outgoings.svg';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';

interface MerchantCodeIconProps {
	merchantCode: string;
	isIngoings: boolean;
	size?: number;
	className?: string;
}

const MerchantCodeIcon: FC<MerchantCodeIconProps> = ({
	merchantCode,
	isIngoings,
	size = 28,
	className,
}) => {
	const mccNumber = +merchantCode;

	const style = {
		width: size,
		height: size,
	};

	return (
		<>
			{(mccNumber > 2999 && mccNumber < 3300) ||
			mccNumber === 4511 ||
			mccNumber === 4582 ||
			mccNumber === 4722 ||
			mccNumber === 4723 ? (
				<SvgIcon className={classNames(className)} style={style} src={AirlinesIcon} />
			) : (mccNumber > 3350 && mccNumber < 3500) ||
			  (mccNumber > 5510 && mccNumber < 5534) ||
			  (mccNumber > 5560 && mccNumber < 5599) ||
			  (mccNumber > 7511 && mccNumber < 7520) ||
			  (mccNumber > 7530 && mccNumber < 7550) ||
			  mccNumber === 4121 ||
			  mccNumber === 4784 ? (
				<SvgIcon className={classNames(className)} style={style} src={CarRentalIcon} />
			) : mccNumber > 7522 && mccNumber < 7525 ? (
				<SvgIcon className={classNames(className)} style={style} src={ParkingIcon} />
			) : (mccNumber > 4799 && mccNumber < 5000) ||
			  (mccNumber > 7260 && mccNumber < 7277) ||
			  (mccNumber > 9398 && mccNumber < 9951) ||
			  mccNumber === 2741 ||
			  mccNumber === 2791 ||
			  mccNumber === 4214 ||
			  mccNumber === 4215 ||
			  mccNumber === 4900 ||
			  mccNumber === 7299 ||
			  mccNumber === 9222 ||
			  mccNumber === 9223 ||
			  mccNumber === 9311 ? (
				<SvgIcon className={classNames(className)} style={style} src={UtilityServiceIcon} />
			) : (mccNumber > 5599 && mccNumber < 5700) ||
			  (mccNumber > 5130 && mccNumber < 5140) ||
			  mccNumber === 7251 ||
			  mccNumber === 7296 ? (
				<SvgIcon className={classNames(className)} style={style} src={ClothingIcon} />
			) : (mccNumber > 5540 && mccNumber < 5543) || mccNumber === 5172 ? (
				<SvgIcon className={classNames(className)} style={style} src={PetrolIcon} />
			) : (mccNumber > 3499 && mccNumber < 4000) || (mccNumber > 7010 && mccNumber < 7034) ? (
				<SvgIcon className={classNames(className)} style={style} src={HotelsIcon} />
			) : mccNumber === 5499 ||
			  mccNumber === 763 ||
			  mccNumber === 5422 ||
			  mccNumber === 5441 ? (
				<SvgIcon className={classNames(className)} style={style} src={FoodIcon} />
			) : mccNumber === 5300 ||
			  mccNumber === 9751 ||
			  mccNumber === 5411 ||
			  mccNumber === 5310 ||
			  mccNumber === 5311 ||
			  mccNumber === 5399 ||
			  mccNumber === 5331 ? (
				<SvgIcon className={classNames(className)} style={style} src={SupermarketIcon} />
			) : mccNumber === 5812 || mccNumber === 5811 ? (
				<SvgIcon className={classNames(className)} style={style} src={RestaurantsIcon} />
			) : mccNumber === 5814 ? (
				<SvgIcon className={classNames(className)} style={style} src={FastFoodIcon} />
			) : mccNumber === 5813 ? (
				<SvgIcon className={classNames(className)} style={style} src={BarsIcon} />
			) : mccNumber === 6011 ? (
				<SvgIcon className={classNames(className)} style={style} src={AtmIcon} />
			) : mccNumber > 6009 && mccNumber < 6052 ? (
				<SvgIcon className={classNames(className)} style={style} src={PosCashIcon} />
			) : mccNumber === 5462 ? (
				<SvgIcon className={classNames(className)} style={style} src={BakariesIcon} />
			) : mccNumber === 5451 ? (
				<SvgIcon className={classNames(className)} style={style} src={DairyIcon} />
			) : mccNumber === 5921 || mccNumber === 5309 ? (
				<SvgIcon className={classNames(className)} style={style} src={PackageIcon} />
			) : (mccNumber > 8000 && mccNumber < 8100) ||
			  mccNumber === 5912 ||
			  mccNumber === 5047 ||
			  mccNumber === 742 ||
			  mccNumber === 4119 ||
			  mccNumber === 5122 ? (
				<SvgIcon className={classNames(className)} style={style} src={PharmacyIcon} />
			) : (mccNumber > 1519 && mccNumber < 1800) ||
			  (mccNumber > 5050 && mccNumber < 5086) ||
			  (mccNumber > 7209 && mccNumber < 7218) ||
			  (mccNumber > 7341 && mccNumber < 7350) ||
			  mccNumber === 780 ||
			  mccNumber === 2842 ||
			  mccNumber === 4225 ||
			  mccNumber === 4789 ||
			  mccNumber === 5013 ||
			  mccNumber === 5021 ||
			  mccNumber === 5039 ||
			  mccNumber === 5169 ||
			  mccNumber === 7394 ||
			  mccNumber === 8734 ||
			  mccNumber === 8911 ? (
				<SvgIcon className={classNames(className)} style={style} src={ContractorsIcon} />
			) : mccNumber === 4411 ||
			  mccNumber === 4457 ||
			  mccNumber === 4468 ||
			  mccNumber === 5551 ? (
				<SvgIcon className={classNames(className)} style={style} src={ShipsIcon} />
			) : mccNumber === 4112 ? (
				<SvgIcon className={classNames(className)} style={style} src={TrainsIcon} />
			) : mccNumber === 4111 || mccNumber === 4131 ? (
				<SvgIcon
					className={classNames(className)}
					style={style}
					src={PublicTransportIcon}
				/>
			) : (mccNumber > 4811 && mccNumber < 4822) ||
			  (mccNumber > 7371 && mccNumber < 7380) ||
			  mccNumber === 4899 ||
			  mccNumber === 5045 ||
			  mccNumber === 5732 ||
			  mccNumber === 5734 ? (
				<SvgIcon className={classNames(className)} style={style} src={TechnologyIcon} />
			) : (mccNumber > 5192 && mccNumber < 5272) ||
			  (mccNumber > 5711 && mccNumber < 5723) ||
			  (mccNumber > 5930 && mccNumber < 5938) ||
			  (mccNumber > 5946 && mccNumber < 5951) ||
			  (mccNumber > 5969 && mccNumber < 5994) ||
			  (mccNumber > 5996 && mccNumber < 6000) ||
			  mccNumber === 5046 ||
			  mccNumber === 5094 ||
			  mccNumber === 5099 ||
			  mccNumber === 5733 ||
			  mccNumber === 5735 ||
			  mccNumber === 5944 ||
			  mccNumber === 5945 ||
			  mccNumber === 5995 ||
			  mccNumber === 7278 ? (
				<SvgIcon className={classNames(className)} style={style} src={ShopsIcon} />
			) : mccNumber === 5940 || mccNumber === 5941 || mccNumber === 5996 ? (
				<SvgIcon className={classNames(className)} style={style} src={SportsIcon} />
			) : (mccNumber > 7828 && mccNumber < 8000) ||
			  mccNumber === 5815 ||
			  mccNumber === 5968 ? (
				<SvgIcon className={classNames(className)} style={style} src={EntertainmentIcon} />
			) : (mccNumber > 6210 && mccNumber < 6400) ||
			  (mccNumber > 8640 && mccNumber < 8700) ||
			  mccNumber === 4829 ||
			  mccNumber === 7277 ||
			  mccNumber === 7311 ||
			  mccNumber === 7321 ||
			  mccNumber === 7339 ||
			  mccNumber === 7361 ||
			  mccNumber === 7392 ||
			  mccNumber === 7393 ||
			  mccNumber === 7399 ||
			  mccNumber === 8111 ||
			  mccNumber === 8398 ||
			  mccNumber === 8931 ||
			  mccNumber === 8999 ||
			  mccNumber === 9211 ? (
				<SvgIcon className={classNames(className)} style={style} src={FinancialIcon} />
			) : (mccNumber > 7331 && mccNumber < 7339) ||
			  mccNumber === 5044 ||
			  mccNumber === 5946 ||
			  mccNumber === 7221 ||
			  mccNumber === 7395 ? (
				<SvgIcon className={classNames(className)} style={style} src={PhototgraphyIcon} />
			) : mccNumber === 5192 || mccNumber === 5994 ? (
				<SvgIcon className={classNames(className)} style={style} src={PressIcon} />
			) : mccNumber === 7230 || mccNumber === 7297 || mccNumber === 7298 ? (
				<SvgIcon className={classNames(className)} style={style} src={BeautyIcon} />
			) : (mccNumber > 8210 && mccNumber < 8300) ||
			  mccNumber === 5111 ||
			  mccNumber === 5942 ||
			  mccNumber === 5943 ||
			  mccNumber === 8351 ? (
				<SvgIcon className={classNames(className)} style={style} src={BooksIcon} />
			) : isIngoings ? (
				<SvgIcon className={classNames(className)} style={style} src={IngoingsIcon} />
			) : (
				<SvgIcon className={classNames(className)} style={style} src={OutgoingsIcon} />
			)}
		</>
	);
};

export default MerchantCodeIcon;
