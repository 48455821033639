import ProjectsSelectors from './Projects/ProjectsSelectors';
import OrdersSelectors from './Orders/OrdersSelectors';
import PreordersSelectors from './Preorders/PreordersSelectors';
import PaymentButtonsSelectors from './PaymentButtons/PaymentButtonsSelectors';
import createSelector from '../createSelector';
import PayoutsSelectors from './CryptoPayouts/PayoutsSelectors';

const getState = createSelector((state) => state.MerchantState);
const load = createSelector(getState, (state) => state.load);

export default {
	getState,
	load,
	projects: ProjectsSelectors,
	orders: OrdersSelectors,
	preorders: PreordersSelectors,
	paymentButtons: PaymentButtonsSelectors,
	payouts: PayoutsSelectors,
};
