export enum DepositHistoryActionType {
	IS_LOADING = 'DEPOSITS_HISTORY/IS_LOADING',
	SET_DATA = 'DEPOSITS_HISTORY/SET_DATA',
	RESET_DATA = 'DEPOSITS_HISTORY/RESET_DATA',
}

export enum ExportType {
	CSV = 'csv',
	PDF = 'pdf',
}

export interface DepositCryptoHistoryItem {
	description: string;
	created: string;
	status: string;
	transNo: string;
	transferTypeName: string;
	depositAccount: string;
	amount: string | number;
	currency: string;
	network?: string;
}

export interface DepositFiatHistoryItem {
	id: string;
	depositReference: string;
	created: string;
	status: string;
	depositAmount: string | number;
	feeAmount: string | number;
	receiveAmount: string | number;
	depositCurrency: string;
	receiveCurrency: string;
	title: string;
	network?: string;
	sender?: string;
	senderAccount?: string;
	paymentDetails?: string;
	originalPaymentAmount?: string;
	originalPaymentCurrency?: string;
	originalPaymentFee?: string;
	originalPaymentFeeCurrency?: string;
}

export interface DepositHistoryResponse {
	content: DepositFiatHistoryItem[] | DepositCryptoHistoryItem[];
	totalElements: number;
}

export interface DepositHistoryState {
	isLoading: boolean;
	data: DepositFiatHistoryItem[] | DepositCryptoHistoryItem[] | null;
	totalCount: number;
}

export interface SetIsDepositHistoryLoading {
	type: typeof DepositHistoryActionType.IS_LOADING;
	isLoading: boolean;
}

export interface SetDepositHistoryDataAction {
	type: typeof DepositHistoryActionType.SET_DATA;
	data: DepositHistoryResponse;
}

export interface ResetDepositHistoryAction {
	type: typeof DepositHistoryActionType.RESET_DATA;
}
