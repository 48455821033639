import { FC } from 'react';
import { Route, Switch } from 'react-router';
import useMerchantRoutes, { MerchantRoutes } from '../../../../hooks/useMerchantRoutes';
import PaymentButtonsCreate from './PaymentButtonsCreate/PaymentButtonsCreate';
import PaymentButtonsEdit from './PaymentButtonsEdit/PaymentButtonsEdit';
import PaymentButtonsHome from './PaymentButtonsHome/PaymentButtonsHome';
import PaymentButtonsList from './PaymentButtonsList/PaymentButtonsList';
import PaymentButtonsView from './PaymentButtonsView/PaymentButtonsView';

const PaymentButton: FC = () => {
	const { getPath } = useMerchantRoutes();

	return (
		<Switch>
			<Route
				exact
				path={getPath(MerchantRoutes.PaymentButtonEdit)}
				component={PaymentButtonsEdit}
			/>
			<Route
				exact
				path={getPath(MerchantRoutes.PaymentButton)}
				component={PaymentButtonsView}
			/>
		</Switch>
	);
};

const PaymentButtons: FC = () => {
	const { getPath } = useMerchantRoutes();

	return (
		<Switch>
			<Route
				exact
				path={getPath(MerchantRoutes.PaymentButtonsCreate)}
				component={PaymentButtonsCreate}
			/>
			<Route
				exact
				path={getPath(MerchantRoutes.PaymentButtonsList)}
				component={PaymentButtonsList}
			/>
			<Route path={getPath(MerchantRoutes.PaymentButtonRoot)} component={PaymentButton} />
			<Route
				exact
				path={getPath(MerchantRoutes.PaymentButtons)}
				component={PaymentButtonsHome}
			/>
		</Switch>
	);
};

export default PaymentButtons;
