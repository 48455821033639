import { useCallback, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/Store';
import CardsFilter from '../Shared/CardsFilter/CardsFilter';
import CardsList from '../../../components/CardsList/CardsList';
import styles from './CardsHub.module.scss';
import Seo from '../../../components/Seo/Seo';

export enum KeywordType {
	NOT_ACTIVE = 'NOT_ACTIVE',
	ACTIVE = 'ACTIVE',
	LOCKED = 'LOCKED',
	EXPIRED = 'EXPIRED',
	PLASTIC = 'PLASTIC',
	VIRTUAL = 'VIRTUAL',
	BLOCKED = 'BLOCKED',
}

export enum SortType {
	DATE = 'DATE',
	BALANCE = 'BALANCE',
}

const messages = defineMessages({
	metaTitle: {
		id: 'cardsList.metaTitle',
		defaultMessage: 'Cards',
	},
});

const CardsHub = () => {
	const { cardsList } = useSelector((state: RootState) => state.CardsState);
	const [keywords, setKeywords] = useState<KeywordType[]>([]);
	const [sortType, setSortType] = useState<SortType | null>(null);
	const [sortAsc, setSortAsc] = useState<boolean>(true);

	const changeSortType = (type: SortType) => {
		setSortType(type);
		return setSortAsc(!sortAsc);
	};

	const changeFilter = useCallback(
		(type: KeywordType) => {
			const newArr = keywords;
			const index = newArr.indexOf(type);
			if (index > -1) {
				newArr.splice(index, 1);
			} else {
				newArr.push(type);
			}
			return setKeywords([...newArr]);
		},
		[keywords, setKeywords]
	);

	return (
		<div className={styles.container}>
			<Seo title={messages.metaTitle} />
			<h3 className={styles.title}>
				<FormattedMessage id="cards.cardsTitle" defaultMessage="Cards" />
			</h3>
			<div className={styles.content}>
				<CardsFilter changeFilter={changeFilter} keywords={keywords} />
				<CardsList
					list={cardsList}
					keywordFilter={keywords}
					sortType={sortType}
					sortAsc={sortAsc}
					changeSortType={changeSortType}
				/>
			</div>
		</div>
	);
};

export default CardsHub;
