import classNames from 'classnames';
import { useIntl, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/Store';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import UserContractCompanyType, {
	UserPreviousPageForCompanyMovement,
} from '../../../../enums/UserContractCompanyEnum';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import accountVerificationImgPath from './images/account_verification.svg';
import phoneVerificationImgPath from './images/phone_number_verification.svg';
import debitCardImgPath from '../../../../images/cards/debitCardImg.svg';
import arrowsImgPath from '../../../../images/expand_triangle.svg';
import styles from './VerificationStatus.module.scss';
import { companyAndPreviousPageHandler } from '../../../../helpers/storageHelper/storageHelper';
import config from '../../../../configs/config';
import { resolveUserCompany } from '../../../../helpers/companyHelper/companyHelper';

const messages = defineMessages({
	title: {
		id: 'verificationStatus.title',
		defaultMessage: 'Get SpectroCoin card',
	},
	paragraph: {
		id: 'verificationStatus.paragraph',
		defaultMessage:
			'Take your everyday payments to the next level. Exchange cryptocurrencies and spend with your Visa debit card in millions of shops around the world.',
	},
	subtitle: {
		id: 'verificationStatus.subtitle',
		defaultMessage:
			'To order a SpectroCoin card the following verification steps must be completed:',
	},
	verified: {
		id: 'verificationStatus.verified',
		defaultMessage: 'verified',
	},
	notVerified: {
		id: 'verificationStatus.notVerified',
		defaultMessage: 'not verified',
	},
	accountVerification: {
		id: 'verificationStatus.accountVerification',
		defaultMessage: 'Account verification',
	},
	phoneVerification: {
		id: 'verificationStatus.phoneVerification',
		defaultMessage: 'Phone number verification',
	},
	requestForAdditionalServices: {
		id: 'verificationStatus.requestForAdditionalServices',
		defaultMessage: 'Request for additional services',
	},
});

const VerificationStatus = () => {
	const { formatMessage, locale } = useIntl();
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const { companyMovement } = useSelector((state: RootState) => state.CardsState);

	const sendToVerification = () => {
		if (
			companyMovement &&
			!companyMovement?.available &&
			companyMovement?.data &&
			companyMovement?.data?.toCompany
		) {
			companyAndPreviousPageHandler({
				toCompany: UserContractCompanyType[companyMovement?.data?.toCompany],
				previousPage: UserPreviousPageForCompanyMovement.ORDER_DEBIT_CARD,
			});
		}
		return window.location.assign(
			`${config.PROFILE_DOMAIN}/${locale}/verification/verify/${resolveUserCompany(
				companyMovement?.data?.toCompany
			)}`
		);
	};

	return (
		<WhiteContainer>
			<InfoHead title={messages.title} text={messages.paragraph} img={debitCardImgPath} />
			<h6 className={styles.subtitle}>{formatMessage(messages.subtitle)}</h6>
			<ul className={styles.verificationStatusList}>
				<li>
					<a onClick={() => sendToVerification()}>
						<img
							className={styles.verificationStatusIcon}
							src={accountVerificationImgPath}
							alt="Account Verification Icon"
						/>
						<span className={styles.textBlock}>
							<span className={styles.text}>
								{formatMessage(messages.accountVerification)}
							</span>
							<span
								className={classNames(styles.status, {
									[styles.green]:
										user?.verified &&
										companyMovement &&
										companyMovement?.available,
								})}
							>
								{companyMovement && !companyMovement?.available ? (
									formatMessage(messages.requestForAdditionalServices)
								) : (
									<>
										{user?.verified
											? formatMessage(messages.verified)
											: formatMessage(messages.notVerified)}
									</>
								)}
							</span>
						</span>
						<img className={styles.arrow} src={arrowsImgPath} alt="Arrow icon" />
					</a>
				</li>
				<li>
					<a href={`${config.PROFILE_DOMAIN}/${locale}/verification/phone`}>
						<img
							className={styles.verificationStatusIcon}
							src={phoneVerificationImgPath}
							alt="Phone Number Verification Icon"
						/>
						<span className={styles.textBlock}>
							<span className={styles.text}>
								{formatMessage(messages.phoneVerification)}
							</span>
							<span
								className={classNames(styles.status, {
									[styles.green]: user?.phoneVerified,
								})}
							>
								{user?.phoneVerified
									? formatMessage(messages.verified)
									: formatMessage(messages.notVerified)}
							</span>
						</span>
						<img className={styles.arrow} src={arrowsImgPath} alt="Arrow icon" />
					</a>
				</li>
			</ul>
		</WhiteContainer>
	);
};

export default VerificationStatus;
