import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { values } from '../helpers/objectHelper/objectHelper';

enum RouteTokens {
	Language = 'lng',
	ProjectId = 'projectId',
	OrderId = 'orderId',
	PreOrderId = 'preorderId',
	CallbackId = 'callbackId',
	TransactionId = 'transactionId',
	PaymentButtonId = 'paymentButtonId',
	PayoutId = 'payoutId',
}

export enum MerchantRoutes {
	Root,
	MobileHomepage,
	ProjectsRoot,
	ProjectsList,
	ProjectCreate,
	ProjectRoot,
	ProjectView,
	ProjectEdit,
	PreOrdersRoot,
	PreOrderRoot,
	PreOrderCreate,
	PreOrderView,
	OrdersRoot,
	OrderRoot,
	OrderView,
	CallbacksRoot,
	CallbackRoot,
	CallbackView,
	TransactionsRoot,
	TransactionRoot,
	TransactionView,
	IntegrationToolsRoot,
	IntegrationTools,
	PaymentButtonsRoot,
	PaymentButtons,
	PaymentButtonsList,
	PaymentButtonsCreate,
	PaymentButtonRoot,
	PaymentButton,
	PaymentButtonEdit,
	PublicPreOrder,
	PublicOrder,
	PublicPayment,
	PublicDonation,
	CryptoPayouts,
	CryptoPayoutById,
}

const MerchantRoutePaths = {
	get [MerchantRoutes.Root]() {
		return `/:${RouteTokens.Language}?/merchants`;
	},
	get [MerchantRoutes.MobileHomepage]() {
		return `${MerchantRoutePaths[MerchantRoutes.Root]}`;
	},

	get [MerchantRoutes.ProjectsRoot]() {
		return `${MerchantRoutePaths[MerchantRoutes.Root]}/projects`;
	},
	get [MerchantRoutes.ProjectsList]() {
		return `${MerchantRoutePaths[MerchantRoutes.ProjectsRoot]}`;
	},
	get [MerchantRoutes.ProjectCreate]() {
		return `${MerchantRoutePaths[MerchantRoutes.ProjectsRoot]}/new`;
	},
	get [MerchantRoutes.ProjectRoot]() {
		return `${MerchantRoutePaths[MerchantRoutes.ProjectsRoot]}/:${RouteTokens.ProjectId}`;
	},
	get [MerchantRoutes.ProjectView]() {
		return `${MerchantRoutePaths[MerchantRoutes.ProjectRoot]}`;
	},
	get [MerchantRoutes.ProjectEdit]() {
		return `${MerchantRoutePaths[MerchantRoutes.ProjectRoot]}/edit`;
	},

	get [MerchantRoutes.PreOrdersRoot]() {
		return `${MerchantRoutePaths[MerchantRoutes.ProjectRoot]}/preorders`;
	},
	get [MerchantRoutes.PreOrderCreate]() {
		return `${MerchantRoutePaths[MerchantRoutes.PreOrdersRoot]}/new`;
	},
	get [MerchantRoutes.PreOrderRoot]() {
		return `${MerchantRoutePaths[MerchantRoutes.PreOrdersRoot]}/:${RouteTokens.PreOrderId}`;
	},
	get [MerchantRoutes.PreOrderView]() {
		return `${MerchantRoutePaths[MerchantRoutes.PreOrderRoot]}`;
	},

	get [MerchantRoutes.OrdersRoot]() {
		return `${MerchantRoutePaths[MerchantRoutes.ProjectRoot]}/orders`;
	},
	get [MerchantRoutes.OrderRoot]() {
		return `${MerchantRoutePaths[MerchantRoutes.OrdersRoot]}/:${RouteTokens.OrderId}`;
	},
	get [MerchantRoutes.OrderView]() {
		return `${MerchantRoutePaths[MerchantRoutes.OrderRoot]}`;
	},

	get [MerchantRoutes.TransactionsRoot]() {
		return `${MerchantRoutePaths[MerchantRoutes.OrderRoot]}/tx`;
	},
	get [MerchantRoutes.TransactionRoot]() {
		return `${MerchantRoutePaths[MerchantRoutes.TransactionsRoot]}/:${
			RouteTokens.TransactionId
		}`;
	},
	get [MerchantRoutes.TransactionView]() {
		return `${MerchantRoutePaths[MerchantRoutes.TransactionRoot]}`;
	},

	get [MerchantRoutes.CallbacksRoot]() {
		return `${MerchantRoutePaths[MerchantRoutes.OrderRoot]}/callbacks`;
	},
	get [MerchantRoutes.CallbackRoot]() {
		return `${MerchantRoutePaths[MerchantRoutes.CallbacksRoot]}/:${RouteTokens.CallbackId}`;
	},
	get [MerchantRoutes.CallbackView]() {
		return `${MerchantRoutePaths[MerchantRoutes.CallbackRoot]}`;
	},

	get [MerchantRoutes.IntegrationToolsRoot]() {
		return `${MerchantRoutePaths[MerchantRoutes.Root]}/integration`;
	},
	get [MerchantRoutes.IntegrationTools]() {
		return `${MerchantRoutePaths[MerchantRoutes.IntegrationToolsRoot]}`;
	},

	get [MerchantRoutes.PaymentButtonsRoot]() {
		return `${MerchantRoutePaths[MerchantRoutes.IntegrationToolsRoot]}/buttons`;
	},
	get [MerchantRoutes.PaymentButtons]() {
		return `${MerchantRoutePaths[MerchantRoutes.PaymentButtonsRoot]}`;
	},
	get [MerchantRoutes.PaymentButtonRoot]() {
		return `${MerchantRoutePaths[MerchantRoutes.PaymentButtonsRoot]}/:${
			RouteTokens.PaymentButtonId
		}`;
	},
	get [MerchantRoutes.PaymentButton]() {
		return `${MerchantRoutePaths[MerchantRoutes.PaymentButtonsRoot]}/:${
			RouteTokens.PaymentButtonId
		}`;
	},
	get [MerchantRoutes.PaymentButtonEdit]() {
		return `${MerchantRoutePaths[MerchantRoutes.PaymentButton]}/edit`;
	},
	get [MerchantRoutes.PaymentButtonsCreate]() {
		return `${MerchantRoutePaths[MerchantRoutes.PaymentButtonsRoot]}/new`;
	},
	get [MerchantRoutes.PaymentButtonsList]() {
		return `${MerchantRoutePaths[MerchantRoutes.PaymentButtonsRoot]}/list`;
	},

	get [MerchantRoutes.PublicPayment]() {
		return `/:${RouteTokens.Language}?/payment/:${RouteTokens.ProjectId}`;
	},
	get [MerchantRoutes.PublicPreOrder]() {
		return `/:${RouteTokens.Language}?/payment/preorder/:${RouteTokens.PreOrderId}`;
	},
	get [MerchantRoutes.PublicOrder]() {
		return `/:${RouteTokens.Language}?/payment/order/:${RouteTokens.OrderId}`;
	},
	get [MerchantRoutes.PublicDonation]() {
		return `/:${RouteTokens.Language}?/payment/donation/:${RouteTokens.PaymentButtonId}`;
	},

	get [MerchantRoutes.CryptoPayouts]() {
		return `${MerchantRoutePaths[MerchantRoutes.Root]}/crypto-payouts`;
	},
	get [MerchantRoutes.CryptoPayoutById]() {
		return `${MerchantRoutePaths[MerchantRoutes.Root]}/crypto-payouts/:${RouteTokens.PayoutId}`;
	},
} as const;

type RouteParams = Partial<{ [key in RouteTokens]: string }>;

const useMerchantRoutes = () => {
	const { params } = useRouteMatch<RouteParams>();
	const { locale } = useIntl();

	const getPath = useCallback((route: MerchantRoutes) => {
		return MerchantRoutePaths[route];
	}, []);

	const getParams = useCallback(() => {
		return params;
	}, [params]);

	const getUrl = useCallback(
		(route: MerchantRoutes, routeParams: RouteParams = {}) => {
			const mergedParams = { ...getParams(), ...routeParams, [RouteTokens.Language]: locale };
			const path = getPath(route);
			const tokens = values(RouteTokens);
			return tokens
				.reduce((acc, token) => {
					return acc.replace(new RegExp(`:${token}\\?*`), mergedParams[token] || '');
				}, path)
				.replace(/\/\//g, '/');
		},
		[locale, getParams, getPath]
	);

	return {
		getUrl,
		getPath,
		getParams,
	} as const;
};

export default useMerchantRoutes;
