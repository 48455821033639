import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { useDispatch } from 'react-redux';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { removeLinkedCardURL } from '../../../redux/endpoints';
import toggleModal from '../../../redux/ModalState/ModalActions';
import styles from './RemoveCardModal.module.scss';
import Button, { ButtonStyle, ButtonType } from '../../Button/Button';
import TestIds from '../../../test/TestIds';
import axiosInstance from '../../../helpers/axiosInstance';

const messages = defineMessages({
	somethingWentWrong: {
		id: 'linkedCards.somethingWentWrong',
		defaultMessage: 'Something went wrong. Please try again later.',
	},
});

interface RemoveCardModalProps {
	cardId: string | null;
	setIsLoading: (isLoading: boolean) => void;
	setError: (msg: MessageDescriptor | null) => void;
	fetchData?: (data: boolean) => void;
	cardNumber: string;
}

const RemoveCardModal = ({
	cardId,
	setIsLoading,
	setError,
	fetchData,
	cardNumber,
}: RemoveCardModalProps) => {
	const dispatch = useDispatch();

	const removeCard = () => {
		setError(null);
		if (cardId) {
			setIsLoading(true);
			return axiosInstance
				.delete(removeLinkedCardURL(cardId))
				.then(() => {
					if (typeof fetchData !== 'undefined') return fetchData(false);
					return dispatch(toggleModal());
				})
				.catch(() => {
					setError(messages.somethingWentWrong);
				})
				.then(() => {
					setIsLoading(false);
					dispatch(toggleModal());
				});
		}
		return null;
	};

	return (
		<div className={styles.container}>
			<div className={styles.modalIcon} data-cy={TestIds.Toggle}>
				<div className={styles.outterBg}>
					<div className={styles.innerBg}>
						<FontAwesomeIcon icon={faXmark} color="white" />
					</div>
				</div>
			</div>
			<p className={styles.post} data-cy={TestIds.removeCardNumber}>
				<FormattedMessage
					id="depositListItem.removeCard"
					defaultMessage="Remove card {cardNumber}?"
					values={{ cardNumber }}
				/>
			</p>
			<p className={styles.details}>
				<FormattedMessage
					id="depositListItem.readCard"
					defaultMessage="You will be able to re-add this card later."
				/>
			</p>
			<Button
				type={ButtonType.SUBMIT}
				buttonStyle={ButtonStyle.PRIMARY}
				onClick={() => removeCard()}
				className={styles.submitButton}
				data-cy={TestIds.button}
			>
				<FormattedMessage id="depositListItem.yes" defaultMessage="Yes" />
			</Button>
			<div
				className={styles.no}
				onClick={() => dispatch(toggleModal())}
				data-cy={TestIds.Toggle}
			>
				<FormattedMessage id="depositListItem.no" defaultMessage="No" />
			</div>
		</div>
	);
};

export default RemoveCardModal;
