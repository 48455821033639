import { AxiosResponse } from 'axios';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { ProviderType } from '../WithdrawState/WithdrawTypes';
import { HistoryType } from '../../hooks/useHistoryTableData';
import { getWithdrawHistoryURL } from '../endpoints';
import { AppDispatch } from '../Store';
import {
	SetHistoryDataAction,
	WithdrawHistoryActionType,
	WithdrawCryptoResponse,
} from './WithdrawHistoryTypes';
import axiosInstance from '../../helpers/axiosInstance';

const setIsLoading = (isLoading: boolean) => ({
	type: WithdrawHistoryActionType.IS_LOADING,
	data: isLoading,
});

export const resetWithdrawHistory = () => ({
	type: WithdrawHistoryActionType.RESET_DATA,
});

export const getWithdrawCryptoHistory = (
	historyType: HistoryType,
	startDate?: string,
	endDate?: string,
	page?: number,
	currencyCode?: CurrencyEnum,
	isPurchase?: boolean,
	type?: ProviderType
) => (dispatch: AppDispatch) => {
	dispatch(setIsLoading(true));
	void axiosInstance
		.get(
			getWithdrawHistoryURL(
				historyType,
				currencyCode,
				startDate,
				endDate,
				page,
				isPurchase,
				type
			)
		)
		.then(({ data }: AxiosResponse<WithdrawCryptoResponse>) => {
			const { content, totalElements } = data;
			dispatch<SetHistoryDataAction>({
				type: WithdrawHistoryActionType.SET_DATA,
				data: content.sort((a: any, b: any) => {
					if (new Date(a.date) > new Date(b.date)) return -1;
					return 1;
				}),
				totalCount: totalElements,
			});
		})
		.catch((e) => console.log(`${e} happened`))
		.then(() => {
			dispatch(setIsLoading(false));
		});
};
