import { defineMessages, useIntl } from 'react-intl';
import successIBANIconPath from '../../../images/iban_icons/success.svg';
import Button, { ButtonType } from '../../../components/Button/Button';
import Seo from '../../../components/Seo/Seo';
import styles from './ClaimedIBAN.module.scss';

const messages = defineMessages({
	title: {
		id: 'claimIBAN.success',
		defaultMessage: 'Success!',
	},
	successMessage: {
		id: 'claimIBAN.claimedMessage',
		defaultMessage: 'Your dedicated IBAN number will show up in your EUR wallet soon.',
	},
	back: {
		id: 'claimIBAN.backButton',
		defaultMessage: 'Back to wallets',
	},
	metaTitle: {
		id: 'claimIBAN.metaClaimed',
		defaultMessage: 'IBAN Claimed',
	},
});

const ClaimedIBAN = () => {
	const { formatMessage, locale } = useIntl();

	return (
		<div className={styles.container}>
			<Seo title={messages.metaTitle} />
			<h2 className={styles.title}>{formatMessage(messages.title)}</h2>
			<img src={successIBANIconPath} />
			<p className={styles.paragraph}>{formatMessage(messages.successMessage)}</p>
			<div className={styles.buttonContainer}>
				<Button text={messages.back} type={ButtonType.LINK} link={`/${locale}/account`} />
			</div>
		</div>
	);
};

export default ClaimedIBAN;
