import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import countries from 'i18n-iso-countries';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import notAvailableImgPath from './images/notAvailable.svg';
import tooltipImgPath from './images/tooltip.svg';
import styles from './CountryUnavailable.module.scss';
import baseMsg from '../../../../messages/base.messages';

const messages = defineMessages({
	title: {
		id: 'countryUnavailable.title',
		defaultMessage: 'Cards to your country are coming soon',
	},
	text: {
		id: 'countryUnavailable.text',
		defaultMessage:
			'Currently cards are only available for residents of European Economic Area (EEA) <span></span>',
	},
	edit: {
		id: 'countryUnavailable.edit',
		defaultMessage: 'Edit address',
	},
});

interface CountryUnavailableProps {
	editAction: (props: boolean) => void;
	countriesList: string[];
}

const CountryUnavailable = ({ editAction, countriesList }: CountryUnavailableProps) => {
	const { locale } = useIntl();

	return (
		<div className={styles.wrapper}>
			<InfoHead title={messages.title} img={notAvailableImgPath} />
			<div className={styles.postContainer}>
				<p className={styles.post}>
					<FormattedMessage
						{...messages.text}
						values={{
							span: () => (
								<Tooltip
									content={
										<ul className={styles.tooltipContainer}>
											{countriesList.map((item: string) => (
												<li key={item}>{`${countries
													.getName(item, 'en')
													.replace(', Province of China', '')},`}</li>
											))}
										</ul>
									}
								>
									<span className={styles.tooltipToggle}>
										<img src={tooltipImgPath} />
									</span>
								</Tooltip>
							),
						}}
					/>
				</p>
			</div>
			<Button
				type={ButtonType.BUTTON}
				onClick={() => editAction(true)}
				text={messages.edit}
				className={styles.button}
			/>
			<Button
				type={ButtonType.LINK}
				link={`/${locale}/cards/order`}
				buttonStyle={ButtonStyle.BORDERLESS}
				text={baseMsg.close}
				className={styles.button}
			/>
		</div>
	);
};

export default CountryUnavailable;
