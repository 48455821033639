import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';
import { useRouteMatch, useHistory } from 'react-router';
import { GetCurrentCard } from '../../../../helpers/cardsHelper/cardsHelper';
import {
	getCardsSecurityParams,
	setSecurityParam,
} from '../../../../redux/CardsState/CardsActions';
import { CardType, SecurityType } from '../../../../redux/CardsState/CardsTypes';
import Toggle from '../../../../components/Toggle/Toggle';
import Loader from '../../../../components/Loader/Loader';
import Button, { ButtonType } from '../../../../components/Button/Button';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import cashlessImgPath from './images/cashless.svg';
import contactlessImgPath from './images/contactless_payments.svg';
import onlineImgPath from './images/online_transactions.svg';
import styles from './CardSecurity.module.scss';
import { RootState } from '../../../../redux/Store';
import Seo from '../../../../components/Seo/Seo';
import baseMsg from '../../../../messages/base.messages';

const messages = defineMessages({
	title: {
		id: 'cardSecurity.title',
		defaultMessage: 'Card security',
	},
	contactlessPaymentsTitle: {
		id: 'cardSecurity.contactlessPaymentsTitle',
		defaultMessage: 'Contactless payments',
	},
	contactlessPaymentsPost: {
		id: 'cardSecurity.contactlessPaymentsPost',
		defaultMessage: 'Enable/disable the contactless payment feature for the card.',
	},
	contactlessPaymentsInfo: {
		id: 'cardSecurity.contactlessPaymentsInfo',
		defaultMessage:
			'Contactless payments will be enabled after the first successfully chip and pin transaction.',
	},
	cashlessTitle: {
		id: 'cardSecurity.cashlessTitle',
		defaultMessage: 'Cashless',
	},
	cashlessPost: {
		id: 'cardSecurity.cashlessPost',
		defaultMessage: 'Enable/disable the cash withdrawal feature for ATMs.',
	},
	onlineTransactionsTitle: {
		id: 'cardSecurity.onlineTransactionsTitle',
		defaultMessage: 'Online transactions',
	},
	onlineTransactionsPost: {
		id: 'cardSecurity.onlineTransactionsPost',
		defaultMessage: 'Enable/disable the online transactions feature for the card.',
	},
	mobileBack: {
		id: 'load.back',
		defaultMessage: 'Back to card',
	},
	metaTitle: {
		id: 'cardSecurity.metaTitle',
		defaultMessage: 'Card security',
	},
});

const CardSecurity = () => {
	const dispatch = useDispatch();
	const card = GetCurrentCard();
	const { push }: any = useHistory();
	const { url } = useRouteMatch();
	const { isTablet } = useSelector((store: RootState) => store.AppState);
	const { formatMessage } = useIntl();
	const [loadingItem, setLoadingItem] = useState<SecurityType | null>(null);

	const handleChange = ({ target: { checked, name } }: ChangeEvent<HTMLInputElement>) => {
		if (
			SecurityType[name] === SecurityType.ALLOW_ECOMMERCE &&
			!card?.cardSecurity?.allowEcommerce &&
			!card?.created3dsPassword
		) {
			return push({
				pathname: url.replace('card-security', 'online-transactions-password'),
				state: {
					fromSecurity: true,
				},
			});
		}
		setLoadingItem(SecurityType[name]);
		return dispatch(setSecurityParam(card?.id || '', SecurityType[name], checked));
	};

	useEffect(() => {
		setLoadingItem(null);
		if (card && !card?.cardSecurity) dispatch(getCardsSecurityParams(card.id));
	}, [dispatch, card]);

	if (!card) return null;
	return (
		<WhiteContainer
			backButtonLink={isTablet ? url.replace('card-security', 'history') : ''}
			backButtonText={messages.mobileBack}
		>
			<Seo title={messages.metaTitle} />
			<>
				<InfoHead title={messages.title} />
				<div
					className={classNames(styles.options, {
						[styles.loading]: !card?.cardSecurity || card?.cardSecurity?.isLoading,
					})}
				>
					{card?.type === CardType.PLASTIC && (
						<>
							<Toggle
								className={styles.toggle}
								id={SecurityType.ALLOW_CONTACTLESS}
								checked={card.cardSecurity?.allowContactless || false}
								name={SecurityType.ALLOW_CONTACTLESS}
								onChange={handleChange}
								disabled={
									loadingItem === SecurityType.ALLOW_CONTACTLESS &&
									card?.cardSecurity?.isLoading
								}
							>
								<div className={styles.label}>
									<SvgIcon src={contactlessImgPath} className={styles.icon} />
									<div>
										<h4 className={styles.title}>
											{formatMessage(messages.contactlessPaymentsTitle)}
										</h4>
										<p className={styles.post}>
											{formatMessage(messages.contactlessPaymentsPost)}
										</p>
									</div>
								</div>
								{(!card?.cardSecurity ||
									(loadingItem === SecurityType.ALLOW_CONTACTLESS &&
										card?.cardSecurity?.isLoading)) && <Loader />}
							</Toggle>
							<div className={styles.additionalInfo}>
								<div className={styles.exclamationMark} />
								<p className={styles.info}>
									{formatMessage(messages.contactlessPaymentsInfo)}
								</p>
							</div>
						</>
					)}
					{card?.type === CardType.PLASTIC && (
						<Toggle
							className={styles.toggle}
							id={SecurityType.ALLOW_CASHOUT}
							checked={card?.cardSecurity?.allowCashout || false}
							name={SecurityType.ALLOW_CASHOUT}
							onChange={handleChange}
							disabled={
								loadingItem === SecurityType.ALLOW_CASHOUT &&
								card?.cardSecurity?.isLoading
							}
						>
							<div className={styles.label}>
								<SvgIcon src={cashlessImgPath} className={styles.icon} />
								<div>
									<h4 className={styles.title}>
										{formatMessage(messages.cashlessTitle)}
									</h4>
									<p className={styles.post}>
										{formatMessage(messages.cashlessPost)}
									</p>
								</div>
							</div>
							{(!card?.cardSecurity ||
								(loadingItem === SecurityType.ALLOW_CASHOUT &&
									card?.cardSecurity?.isLoading)) && <Loader />}
						</Toggle>
					)}
					<Toggle
						className={styles.toggle}
						id={SecurityType.ALLOW_ECOMMERCE}
						checked={card?.cardSecurity?.allowEcommerce || false}
						name={SecurityType.ALLOW_ECOMMERCE}
						onChange={handleChange}
						disabled={
							loadingItem === SecurityType.ALLOW_ECOMMERCE &&
							card?.cardSecurity?.isLoading
						}
					>
						<div className={styles.label}>
							<SvgIcon src={onlineImgPath} className={styles.icon} />
							<div>
								<h4 className={styles.title}>
									{formatMessage(messages.onlineTransactionsTitle)}
								</h4>
								<p className={styles.post}>
									{formatMessage(messages.onlineTransactionsPost)}
								</p>
							</div>
						</div>
						{(!card?.cardSecurity ||
							(loadingItem === SecurityType.ALLOW_ECOMMERCE &&
								card?.cardSecurity?.isLoading)) && <Loader />}
					</Toggle>
				</div>
				<Button
					type={ButtonType.LINK}
					link={url.replace('card-security', 'history')}
					text={baseMsg.close}
					className={styles.button}
				/>
			</>
		</WhiteContainer>
	);
};

export default CardSecurity;
