import { createContext, FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import Select, { components, Theme, StylesConfig, GroupBase } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import baseMsg from '../../messages/base.messages';
import { RootState } from '../../redux/Store';
import toggleModal from '../../redux/ModalState/ModalActions';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import AutoConvertCurrency from '../../pages/Merchants/Shared/AutoConvertCurrency/AutoConvertCurrency';
import { RTLLanguages } from '../../redux/LanguageState/LanguageTypes';
import TestIds, { formatTestId } from '../../test/TestIds';

import styles from './MultiAccountSelect.module.scss';
import useEffectOnce from '../../hooks/useEffectOnce';
import Loader from '../Loader/Loader';

const messages = defineMessages({
	noResult: {
		id: 'convertInput.noResult',
		defaultMessage: 'No result',
	},
	chooseCurrency: {
		id: 'merchants.x_currencies_selected',
		defaultMessage: '{Count} currencies selected',
	},
});

type AccountOption = {
	currencyCode: CurrencyEnum;
	currencyName: string;
	value: string;
	disabled?: boolean;
};

const selectStyles: StylesConfig<AccountOption, false, GroupBase<AccountOption>> = {
	control: (provided) => ({
		...provided,
		boxShadow: 'none',
		border: 'none',
		background: 'transparent',
		color: '#102D6F',
		fontSize: '12px',
		display: 'flex',
		cursor: 'pointer',
		position: 'absolute',
		width: '100%',
	}),
	input: (provided, props) => ({
		...provided,
		visibility: 'visible',
		cursor: 'pointer',
		fontSize: '14px',
		height: '30px',
		zIndex: 50,
		marginLeft: '10px',
		marginRight: '10px',
		marginTop: '12px',
		// eslint-disable-next-line @typescript-eslint/dot-notation, dot-notation
		textAlign: props['isRtl'] ? 'right' : 'left',
		// eslint-disable-next-line @typescript-eslint/dot-notation, dot-notation
		padding: props['isRtl'] ? '5px 5px 5px 30px' : '5px 30px 5px 5px',
		borderRadius: '3px',
		color: 'black',
		border: '1px solid #D9DBE5',
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: '0',
		minWidth: '155px',
		cursor: 'pointer',
		height: '45px',
		textAlign: 'right',
		alignItem: 'center',
		flexFlow: 'row wrap',
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	dropdownIndicator: (prodived) => ({
		...prodived,
		display: 'none',
	}),
	menu: (provided) => ({
		...provided,
		background: '#ffffff',
		borderRadius: '4px',
		padding: '0',
		zIndex: '10',
		cursor: 'pointer',
		textAlign: 'left',
		border: '0',
		paddingTop: '45px',
	}),
	menuList: (provided) => ({
		...provided,
		background: '#ffffff',
		cursor: 'pointer',
		padding: '0',
		border: '0',
	}),
};

const selectThemeColors = {
	primary: '#4972f4',
	primary25: '#f5f8ff',
	primary50: '#f5f8ff',
	primary75: '#4972f4',
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type MultiAccountSelectComponentProps<T = {}> = {
	isSelected: boolean;
	data: {
		value: string;
		currencyCode: CurrencyEnum;
		currencyName: string;
	} & T;
	innerProps?: any;
};

const optionFilter = ({ currencyCode, currencyName }: AccountOption, input: string) =>
	input
		.split(' ')
		.every((inputToken) =>
			[currencyName, currencyCode].some((searchToken) =>
				new RegExp(inputToken.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i').test(
					searchToken
				)
			)
		);

type MobileMultiAccountSelectModalProps = {
	options: AccountOption[];
	isSelected: (option: AccountOption) => boolean;
	onChangeSelect: (val: AccountOption[]) => void;
	setIsSelectOpen: (isOpen: boolean) => void;
	componentOverrides: {
		Option: FC<MultiAccountSelectComponentProps>;
	};
};

const MobileMultiAccountSelectModal: FC<MobileMultiAccountSelectModalProps> = ({
	options,
	isSelected,
	onChangeSelect,
	setIsSelectOpen,
	componentOverrides: { Option },
}) => {
	const [selected, setSelected] = useState(options.filter(isSelected));
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const [nameFilter, setNameFilter] = useState<string>('');

	const onClick = (val: AccountOption) => {
		const newValue = !selected.includes(val)
			? [...selected, val]
			: selected.filter((item) => item !== val);
		onChangeSelect(newValue);
		setSelected(newValue);
	};

	const closeModal = () => {
		setIsSelectOpen(false);
		return dispatch<any>(toggleModal());
	};

	return (
		<div className={styles.modal}>
			<div className={styles.closeIcon} onClick={() => closeModal()} />
			<div className={classNames(styles.mobileInputContainer)}>
				<input
					className={styles.searchInput}
					placeholder={formatMessage(baseMsg.search)}
					onChange={(event: any) => setNameFilter(event.target.value)}
					type="text"
				/>
			</div>
			<ul className={styles.mobileSelect}>
				{options
					.filter((o: AccountOption) => optionFilter(o, nameFilter))
					.map((o: AccountOption) => {
						return (
							<li
								key={o.value}
								className={classNames({
									[styles.selected]: selected.includes(o),
								})}
							>
								<Option
									data={o}
									isSelected={selected.includes(o)}
									innerProps={{ onClick: () => onClick(o) }}
								/>
							</li>
						);
					})}
			</ul>
		</div>
	);
};

type MultiAccountSelectContextType = {
	isOpen: boolean;
	options: AccountOption[];
	maxSelectedShown: number;
};

const MultiAccountSelectContext = createContext<MultiAccountSelectContextType>({
	isOpen: false,
	options: [],
	maxSelectedShown: 4,
});

const SearchInput = ({ ...props }: any) => {
	const { formatMessage } = useIntl();
	const { isOpen } = useContext(MultiAccountSelectContext);
	const enabledOptions = props.options.filter((x: AccountOption) => !x.disabled);

	if (!isOpen) return null;
	const allSelected = props.getValue().length === enabledOptions.length;
	return (
		<div
			className={styles.inputContainer}
			onClick={(e) => e.nativeEvent.stopImmediatePropagation()}
		>
			<div className={styles.inputGroup}>
				<components.Input
					{...props}
					data-cy={TestIds.MultiAccountSelectSearch}
					id="searchInput"
					className={styles.searchInput}
					placeholder={formatMessage(baseMsg.search)}
				/>
				<input
					className={styles.checkbox}
					type="checkbox"
					checked={allSelected}
					onChange={() => {
						props.selectProps.onChange(allSelected ? [] : enabledOptions);
					}}
				/>
			</div>
		</div>
	);
};

const MenuList = ({ ...props }: any) => {
	const [overflow, setOverflow] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	// intentionally without deps. trigger delayed evaluation on each render
	useEffect(() => {
		setOverflow((ref.current?.scrollHeight || 0) > (ref.current?.clientHeight || 0));
	});

	return (
		<div
			className={classNames(styles.menu, overflow && styles.overflow)}
			data-cy={TestIds.MultiAccountSelectOptions}
		>
			<components.MenuList {...props} innerRef={ref} />
		</div>
	);
};

const AutoConvertCurrencyOption = ({
	isSelected,
	innerProps: { onClick, ...innerProps },
	data: { value, currencyCode, currencyName, disabled },
}: MultiAccountSelectComponentProps<any>) => {
	return (
		<div
			className={classNames(styles.selectOption, disabled && styles.disabled)}
			data-cy={formatTestId(TestIds.MultiAccountSelectOption_0, value)}
			data-selected={isSelected}
			{...innerProps}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				if (!disabled) onClick(e);
			}}
		>
			<AutoConvertCurrency
				className={classNames(styles.autoConvertOption, 'convert_option')}
				iconClassName={styles.autoConvertIcon}
				currencyCode={currencyCode}
				currencyName={currencyName}
			/>
			{!disabled && (
				<input
					key={`${isSelected}`} // checkbox handles changes internally, easier to re-render it than to workaround the change
					className={styles.checkbox}
					type="checkbox"
					checked={isSelected || false}
					onChange={() => null}
				/>
			)}
		</div>
	);
};

const AutoCovertCurrencySelectedOptions = ({
	values,
	onChange,
	isLoading,
}: {
	values: AccountOption[];
	onChange: (values: AccountOption[]) => void;
	isLoading: boolean;
}) => {
	const { maxSelectedShown } = useContext(MultiAccountSelectContext);

	if (isLoading)
		return (
			<div className={styles.loader}>
				<Loader />
			</div>
		);

	if (values.length > maxSelectedShown)
		return (
			<div
				className={classNames(styles.autoConvertSelectedOptionsInfo)}
				data-cy={TestIds.MultiAccountSelectValueInfo}
				data-count={values.length}
			>
				<FormattedMessage {...messages.chooseCurrency} values={{ Count: values.length }} />
			</div>
		);
	return (
		<div
			className={classNames(styles.autoConvertSelectedOptions)}
			data-cy={TestIds.MultiAccountSelectValues}
			data-count={values.length}
		>
			{values.map(({ currencyCode, currencyName }, index) => (
				<div
					data-cy={formatTestId(TestIds.MultiAccountSelectValue_0, currencyCode)}
					className={classNames(styles.autoConvertSelectedOption)}
					key={currencyCode}
				>
					<AutoConvertCurrency
						className={classNames(styles.autoConvertOption, 'convert_option')}
						iconClassName={styles.autoConvertIcon}
						currencyCode={currencyCode}
						currencyName={currencyName}
						shortLabel
					/>
					<FontAwesomeIcon
						icon={faTimes}
						className={styles.remove}
						onClick={(e) => {
							e.stopPropagation();
							values.splice(index, 1);
							onChange(values);
						}}
					/>
				</div>
			))}
		</div>
	);
};

type MultiAccountSelectProps = {
	label: string;
	labelClassName?: string;
	values: string[] | null;
	onChange: (value: string[]) => void;
	componentOverrides?: {
		Option?: FC<MultiAccountSelectComponentProps>;
	};
	options: AccountOption[];
	isLoading?: boolean;
};

const MultiAccountSelect: FC<MultiAccountSelectProps> = ({
	values,
	onChange,
	label,
	labelClassName,
	componentOverrides = {},
	options,
	isLoading = false,
}) => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const dispatch = useDispatch();
	const containerRef = useRef<HTMLDivElement>(null);
	const selectRef = useRef<any /* types are garbage on this version */>(null);
	const { isTablet } = useSelector((state: RootState) => state?.AppState);
	const { activeLanguage } = useSelector((state: RootState) => state.LanguageState);

	const isRTL = RTLLanguages.includes(activeLanguage.toLowerCase());

	const nonNullValues = values || [];

	useOnClickOutside(
		containerRef,
		() => {
			setIsOpen(false);
		},
		true
	);

	const selectValue = options.filter((x) => !x.disabled && nonNullValues.includes(x.value));

	const onSelectChange = useCallback(
		(newValues: AccountOption[]) => {
			onChange(newValues.map((x) => x.value!));
		},
		[onChange]
	);

	useEffect(() => {
		if (isTablet && isOpen) {
			dispatch<any>(
				toggleModal(
					<MobileMultiAccountSelectModal
						options={options}
						isSelected={(o) => selectValue.includes(o)}
						onChangeSelect={(val: any) => onSelectChange(val)}
						setIsSelectOpen={setIsOpen}
						componentOverrides={{
							Option: componentOverrides?.Option || AutoConvertCurrencyOption,
						}}
					/>
				)
			);
		}
	}, [isOpen, isTablet]);

	useEffectOnce(() => {
		if (!values) onSelectChange([]);
	});

	useEffect(() => {
		if (isOpen) selectRef.current?.focus();
	}, [isOpen]);

	return (
		<MultiAccountSelectContext.Provider
			value={{ isOpen, options, maxSelectedShown: isTablet ? 2 : 4 }}
		>
			<div
				className={styles.host}
				data-cy={TestIds.MultiAccountSelect}
				data-loading={isLoading}
			>
				<label className={classNames(styles.label, labelClassName)}>{label}</label>
				<div className={styles.container}>
					<div
						data-cy={TestIds.MultiAccountSelectValue}
						className={classNames(styles.selectedValue, {
							[styles.selectActive]: isOpen,
						})}
						ref={containerRef}
						onClick={() => !isLoading && setIsOpen((current) => !current)}
					>
						<AutoCovertCurrencySelectedOptions
							values={selectValue}
							onChange={onSelectChange}
							isLoading={isLoading}
						/>
					</div>
					{isOpen && !isTablet && (
						<Select
							ref={selectRef}
							noOptionsMessage={() => formatMessage(messages.noResult)}
							value={selectValue}
							onChange={onSelectChange}
							options={options}
							isSearchable
							isClearable={false}
							isMulti
							isLoading={isLoading}
							hideSelectedOptions={false}
							closeMenuOnSelect={false}
							components={{
								Option: AutoConvertCurrencyOption,
								...componentOverrides,
								Input: SearchInput,
								MultiValue: () => null,
								Placeholder: () => null,
								MenuList,
							}}
							menuIsOpen={isOpen}
							filterOption={({ data }, input) => optionFilter(data, input)}
							styles={selectStyles}
							classNamePrefix="select_account"
							isRtl={isRTL}
							theme={(theme: Theme) => ({
								...theme,
								colors: {
									...theme.colors,
									...selectThemeColors,
								},
							})}
						/>
					)}
				</div>
			</div>
		</MultiAccountSelectContext.Provider>
	);
};

export default MultiAccountSelect;
