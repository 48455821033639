/* eslint-disable @typescript-eslint/no-explicit-any */
import { CSSProperties, useMemo } from 'react';
import classNames from 'classnames';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import ReactSelect, {
	InputProps,
	Theme,
	components,
	OptionProps,
	ContainerProps,
	MenuListProps,
	SingleValueProps,
} from 'react-select';
import styles from './Select.module.scss';
import TestIds, { formatTestId } from '../../test/TestIds';

export enum SelectStyleType {
	INLINE = 'inline',
	BLOCK = 'block',
}

export interface SelectChangeEvent {
	target: {
		checked?: boolean;
		name: string;
		value: string;
	};
}

const messages = defineMessages({
	placeholder: {
		id: 'select.placeholder',
		defaultMessage: 'Select...',
	},
	noResult: {
		id: 'select.noResult',
		defaultMessage: 'No results found',
	},
});

const selectStyles = (styleType: SelectStyleType) => ({
	control: (provided: CSSProperties) => ({
		...provided,
		minHeight: '25px',
		boxShadow: 'none',
		border: 'none',
		background: 'transparent',
		color: '#122E70',
		fontSize: '12px',
		display: 'flex',
		width: '100%',
		cursor: 'pointer',
	}),
	input: (provided: CSSProperties) => ({
		...provided,
		visibility: 'visible',
		padding: '0',
		cursor: 'pointer',
	}),
	singleValue: (provided: CSSProperties) => ({
		...provided,
		color: '#122E70',
		fontWeight: styleType === SelectStyleType.INLINE ? 500 : 400,
		fontSize: 14,
		cursor: 'pointer',
		paddingRight: '5px',
	}),
	valueContainer: (provided: CSSProperties) => ({
		...provided,
		padding: '0',
		overflow: 'visible',
		minWidth: '55px',
		cursor: 'pointer',
		justifyContent: styleType === SelectStyleType.INLINE ? 'flex-end' : 'flex-start',
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	dropdownIndicator: (provided: CSSProperties) => ({
		...provided,
		padding: '0',
		width: '20px',
		color: '#122E70',
		cursor: 'pointer',
	}),
	menu: (provided: CSSProperties) => ({
		...provided,
		background: '#ffffff',
		borderRadius: '4px',
		marginLeft: '-10px',
		padding: '0',
		zIndex: '10',
		cursor: 'pointer',
	}),
	menuList: (provided: CSSProperties) => ({
		...provided,
		cursor: 'pointer',
	}),
});

const selectThemeColors = {
	primary: '#4972f4',
	primary25: '#F6F8FF',
	primary50: '#F6F8FF',
	primary75: '#4972f4',
};

type SelectDropdownProps = {
	selectProps: {
		inputProps: {
			id: string;
		};
	};
} & InputProps;

const CustomInput = (props: SelectDropdownProps) => (
	<components.Input
		{...props}
		data-cy={formatTestId(TestIds.SelectInput, props.selectProps.inputProps.id)}
	/>
);

const CustomOption = (props: OptionProps<any>) => {
	const {
		isSelected,
		data: { value, label },
		innerProps,
	} = props;
	return (
		<components.Option {...props}>
			<span
				data-selected={isSelected}
				data-cy={formatTestId(TestIds.SelectOption, value)}
				key={innerProps.key}
			>
				{label}
			</span>
		</components.Option>
	);
};

const SelectContainer = <Option, Multi extends boolean>(props: ContainerProps<Option, Multi>) => {
	const { innerProps, selectProps } = props;
	return (
		<components.SelectContainer
			{...props}
			innerProps={
				{
					...innerProps,
					'data-cy': selectProps['data-cy'] || TestIds.Select,
				} as any
			}
		/>
	);
};

const MenuList = <Option, Multi extends boolean>(props: MenuListProps<Option, Multi>) => {
	const { innerProps } = props;
	return (
		<components.MenuList
			{...props}
			innerProps={{ ...innerProps, 'data-cy': TestIds.SelectOptions } as any}
		/>
	);
};

const SingleValue = <Option, Multi extends boolean>(props: SingleValueProps<Option, Multi>) => {
	const { innerProps, data } = props;
	return (
		<components.SingleValue
			{...props}
			innerProps={
				{
					...innerProps,
					'data-cy': formatTestId(TestIds.SelectValue_0, (data as any)?.value),
				} as any
			}
		/>
	);
};

const Select = ({
	styleType = SelectStyleType.BLOCK,
	className,
	disabled,
	label,
	searchable = false,
	clearable = false,
	name,
	id,
	rtl,
	onChange,
	onChangeObject,
	options,
	value,
	placeholder,
	customStyles,
	errorMessage,
	labelClassName,
	inputGroupClassName,
	showLabelInTitle = false,
	componentOverrides,
	colors,
	...props
}: any) => {
	const { formatMessage } = useIntl();

	const onSelectChange = (option: any) => {
		if (typeof onChange === 'function') return onChange(option.value, name);
		return typeof onChangeObject === 'function' ? onChangeObject(option, name) : undefined;
	};

	const getLabel = () => {
		if (!label) return null;
		return (
			<label
				htmlFor={id}
				className={classNames(styles[`label__${styleType}`], labelClassName)}
			>
				{typeof label === 'string' ? label : <FormattedMessage {...label} />}
			</label>
		);
	};

	const selectedValue = useMemo(() => {
		return options?.filter((option: any) => option.value === value)[0];
	}, [value, options]);

	return (
		<div
			className={classNames(styles[`inputGroup__${styleType}`], inputGroupClassName)}
			title={showLabelInTitle ? selectedValue?.label || '' : undefined}
			data-cy={`${id}Select`}
		>
			{getLabel()}
			<ReactSelect
				{...props}
				inputProps={{ id }}
				components={{
					Input: CustomInput,
					Option: CustomOption,
					SelectContainer,
					MenuList,
					SingleValue,
					...componentOverrides,
				}}
				className={classNames(styles[`select__${styleType}`], className, {
					[styles.error]: errorMessage,
				})}
				isDisabled={disabled}
				styles={{ ...selectStyles(styleType), ...customStyles }}
				onChange={onSelectChange}
				options={options}
				searchable={searchable}
				clearable={clearable}
				value={selectedValue}
				name={name}
				placeholder={placeholder || formatMessage(messages.placeholder)}
				noResultsText={formatMessage(messages.noResult)}
				rtl={rtl}
				theme={(theme: Theme) => ({
					...theme,
					colors: {
						...theme.colors,
						...(colors || selectThemeColors),
					},
				})}
			/>
			{errorMessage && (
				<div
					className={classNames({
						[styles[`error__${styleType}`]]: styleType,
						[styles.selectError]: errorMessage,
					})}
				>
					{typeof errorMessage === 'string' ? (
						errorMessage
					) : (
						<FormattedMessage {...errorMessage} />
					)}
				</div>
			)}
		</div>
	);
};

export default Select;
