import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import DetailsModal, {
	DetailsModalType,
} from '../../../../components/Modal/DetailsModal/DetailsModal';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import {
	getAmountWithSign,
	getTranslatedCardOperationType,
	getTranslatedCardStatus,
} from '../../../../helpers/cardsHelper/cardsHelper';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import convertToLocalTime from '../../../../helpers/dateHelper/dateHelper';
import {
	CardHistoryItem,
	PaymentDirection,
} from '../../../../redux/CardHistoryState/CardHistoryTypes';
import MerchantCodeIcon from '../MerchantCodeIcon/MerchantCodeIcon';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import CardLoadIcon from './images/mcc_load.svg';
import CardUnloadIcon from './images/mcc_unload.svg';
import styles from './CardsHistoryList.module.scss';
import useFormatAmount from '../../../../hooks/useFormatAmount';

interface Props {
	data: CardHistoryItem[];
}

const CardsHistoryList = ({ data }: Props): JSX.Element => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const amountFormatter = useFormatAmount();

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{data?.length > 0 &&
				data.map(
					({
						amount,
						status,
						operationId,
						date,
						operationType,
						currency,
						direction,
						transactionAmount,
						transactionId,
						localTranDate,
						transactionType,
						merchantName,
						serviceType,
						transactionCurrency,
						exchangeRate,
						feeAmount,
						updatedOn,
						merchantCategoryCode,
						description,
					}: CardHistoryItem) => {
						const isAbsoluteAmount = direction === PaymentDirection.CREDIT;
						const formattedAmount = amountFormatter(
							transactionAmount && transactionType
								? getAmountWithSign(transactionAmount, transactionType)
								: `${isAbsoluteAmount ? `+` : `-`} ${formatPrecision(
										amount,
										currency
								  )}`
						);
						return (
							<li
								className={styles.item}
								key={transactionId || operationId}
								onClick={() =>
									dispatch(
										toggleModal(
											<DetailsModal
												type={DetailsModalType.CARD}
												merchantName={merchantName}
												operationType={operationType}
												amount={formattedAmount}
												transactionAmount={transactionAmount}
												transactionCurrency={transactionCurrency}
												serviceType={serviceType || transactionType}
												exchangeRate={exchangeRate}
												fee={feeAmount}
												completedDate={updatedOn || date || localTranDate}
												reservedDate={date}
												status={formatMessage(
													getTranslatedCardStatus(status)
												)}
												currencyCode={currency || CurrencyEnum.EUR}
												merchantCategoryCode={merchantCategoryCode}
												description={description}
											/>
										)
									)
								}
							>
								{!merchantCategoryCode && (
									<SvgIcon
										className={styles.icon}
										style={{ width: 28, heigth: 28 }}
										src={
											formattedAmount.indexOf('+') > -1
												? CardLoadIcon
												: CardUnloadIcon
										}
									/>
								)}
								{merchantCategoryCode && (
									<MerchantCodeIcon
										className={styles.icon}
										merchantCode={merchantCategoryCode || '0'}
										isIngoings={isAbsoluteAmount}
									/>
								)}
								<span className={styles.description}>
									{merchantName && merchantName}
									{!merchantName &&
										operationType &&
										formatMessage(
											getTranslatedCardOperationType(operationType)
										)}
									{!merchantName &&
										!operationType &&
										transactionType &&
										transactionType}
									{!merchantName &&
										!operationType &&
										!transactionType &&
										serviceType &&
										serviceType}
									<span className={styles.details}>
										<span>
											{convertToLocalTime(
												localTranDate || date,
												'yyyy-MM-dd HH:mm'
											)}
										</span>
									</span>
								</span>
								<span
									className={classNames(styles.amount, {
										[styles.green]: formattedAmount.indexOf('+') > -1,
									})}
								>
									{`${formattedAmount} ${currency || CurrencyEnum.EUR}`}
									<span className={styles.details}>
										{formatMessage(getTranslatedCardStatus(status))}
									</span>
								</span>
							</li>
						);
					}
				)}
		</>
	);
};

export default CardsHistoryList;
