/* eslint-disable no-useless-escape */
import { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Button, { ButtonType } from '../../../../components/Button/Button';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import icon from './images/letter.svg';
import successIcon from '../../../../images/status/success.svg';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import MultiEmailInput from '../../../../components/MultiEmailInput/MultiEmailInput';
import { RemoteError } from '../../../../interfaces/RemoteData';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';

import styles from './InvoiceModal.module.scss';
import MerchantsMessages from '../../../../redux/MerchantsState/MerchantsMessages';
import baseMsg from '../../../../messages/base.messages';
import axiosInstance from '../../../../helpers/axiosInstance';

type InvoiceModalProps = {
	url: string;
	onClose: () => void;
};

const InvoiceModal: FC<InvoiceModalProps> = ({ onClose, url }) => {
	const [viewState, setViewState] = useState<'form' | 'success'>('form');
	const [items, setItems] = useState<string[]>([]);
	const [isSending, setIsSending] = useState(false);
	const [error, setError] = useState<RemoteError | null>(null);

	const sendToAll = useCallback(async () => {
		setIsSending(true);
		setError(null);

		(async () => {
			for (let index = 0; index < items.length; index++) {
				// eslint-disable-next-line no-await-in-loop
				await axiosInstance.post(url, {
					email: items[index],
				});
			}
		})()
			.then(() => {
				setViewState('success');
			})
			.catch((e) => {
				setError(e.response?.data);
				setIsSending(false);
			});
	}, [items, url]);

	return (
		<div className={classNames(styles.container, styles[viewState])}>
			<div className={styles.closeIcon} onClick={() => onClose()} />
			{(() => {
				switch (viewState) {
					case 'form': {
						return (
							<>
								<SvgIcon className={styles.icon} src={icon} />
								<h2 className={styles.title}>
									<FormattedMessage {...MerchantsMessages.sendInvoice} />
								</h2>
								{error && (
									<NotificationMessage
										withIcon
										message={
											<FormattedMessage
												{...getErrorMessageOrDefault(error)}
											/>
										}
										type={NotificationType.Error}
										style={NotificationStyle.Border}
									/>
								)}
								<div className={styles.inputGroup}>
									<div className={styles.label}>
										<FormattedMessage {...baseMsg.emailAddress} />
									</div>
									<MultiEmailInput items={items} onChange={setItems} />
								</div>
								<Button
									className={styles.button}
									isDisabled={isSending || !items.length}
									type={ButtonType.BUTTON}
									onClick={sendToAll}
								>
									<FormattedMessage {...baseMsg.send} />
								</Button>
							</>
						);
					}
					case 'success':
						return (
							<>
								<SvgIcon className={styles.icon} src={successIcon} />
								<h2 className={styles.title}>
									<FormattedMessage {...MerchantsMessages.invoiceSuccess} />
								</h2>
								<Button
									className={styles.button}
									type={ButtonType.BUTTON}
									onClick={onClose}
								>
									<FormattedMessage {...baseMsg.close} />
								</Button>
							</>
						);
					default:
						return null;
				}
			})()}
		</div>
	);
};

export default InvoiceModal;
