import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { CurrencyEnum, currencyUtils } from '@spectrocoin/sc-currencies';
import { useMemo } from 'react';
import { toDecimal, formatPrecision } from '../../helpers/currencyHelper/currencyHelper';
import { GetCurrentCard } from '../../helpers/cardsHelper/cardsHelper';
import { RootState } from '../../redux/Store';
import { RatesDetailsResponse } from '../../redux/RatesState/RatesTypes';
import toggleModal from '../../redux/ModalState/ModalActions';
import ActionButton, { ButtonIcon } from '../ActionButton/ActionButton';
import styles from './BalanceBlock.module.scss';
import TestIds, { formatTestId } from '../../test/TestIds';
import useFormatAmount from '../../hooks/useFormatAmount';
import { CurrencyNetwork, NetworkNameCode } from '../../redux/AccountsState/AccountsTypes';
import getIframeLink, {
	formatNetworkName,
	noBuy,
} from '../../helpers/onRamperHelper/onRamperHelper';
import Button, { ButtonStyle, ButtonType } from '../Button/Button';
import Tooltip from '../Tooltip/Tooltip';
import baseMsg from '../../messages/base.messages';

const messages = defineMessages({
	exchange: {
		id: 'balanceBlock.exchange',
		defaultMessage: 'Exchange',
	},
	deposit: {
		id: 'balanceBlock.deposit',
		defaultMessage: 'Deposit',
	},
	withdraw: {
		id: 'balanceBlock.withdraw',
		defaultMessage: 'Withdraw',
	},
	load: {
		id: 'balanceBlock.loadCard',
		defaultMessage: 'Load card',
	},
	unload: {
		id: 'balanceBlock.unloadCard',
		defaultMessage: 'Unload card',
	},
	buy: {
		id: 'balanceBlock.buy',
		defaultMessage: 'Buy',
	},
	cardBalanceIsUsedFirstInfo: {
		id: 'balanceBlock.cardBalanceIsUsedFirstInfo',
		defaultMessage:
			'Card balance is used first. If insufficient, crypto assets from linked wallets are sold to cover the remaining amount.',
	},
});

export enum BalanceBlockType {
	WALLET = 'WALLET',
	CARD = 'CARD',
}

interface BalanceBlockProps {
	availableBalance: string;
	reservedAmount?: string;
	currencyCode: CurrencyEnum;
	refCurrency?: CurrencyEnum;
	currentCurrencyRates?: RatesDetailsResponse;
	refCurrencyRates?: RatesDetailsResponse;
	isVirtual?: boolean;
	id: string;
	walletAddress?: string;
	selectedNetwork?: CurrencyNetwork['networkName'];
	type?: BalanceBlockType;
	isCardOptionsEnabled?: boolean;
	isRhaTooltipVisible?: boolean;
}

const BalanceBlock = ({
	availableBalance,
	currencyCode,
	refCurrency,
	currentCurrencyRates,
	refCurrencyRates,
	reservedAmount,
	isVirtual,
	walletAddress,
	selectedNetwork,
	id,
	type = BalanceBlockType.WALLET,
	isCardOptionsEnabled,
	isRhaTooltipVisible,
}: BalanceBlockProps) => {
	const { locale, formatMessage } = useIntl();
	const { isLoading } = useSelector((state: RootState) => state?.RatesState);
	const amountFormatter = useFormatAmount();
	const dispatch = useDispatch();
	const parsedWalletNameForOnramper = useMemo(
		() => formatNetworkName(selectedNetwork as NetworkNameCode, currencyCode),
		[selectedNetwork, currencyCode]
	);

	const handleBuyClick = () => {
		dispatch(
			toggleModal(
				<div className={styles.buyModal}>
					<iframe
						src={getIframeLink(
							refCurrency || CurrencyEnum.USD,
							parsedWalletNameForOnramper,
							walletAddress!
						)}
						title="Onramper Widget"
						height="630px"
						width="420px"
						allow="accelerometer; autoplay; camera; gyroscope; payment"
					/>
					<Button
						type={ButtonType.BUTTON}
						buttonStyle={ButtonStyle.BORDERLESS}
						text={baseMsg.close}
						onClick={() => dispatch(toggleModal())}
					/>
				</div>
			)
		);
	};

	return (
		<div className={styles.container}>
			<div className={styles.balanceContainer}>
				<div className={styles.availableBalance}>
					{type === BalanceBlockType.CARD && (
						<div className={styles.labelText}>
							<FormattedMessage
								id="balanceBlock.available"
								defaultMessage="Available balance"
							/>
							{isRhaTooltipVisible && (
								<Tooltip
									infoIconVisible
									content={
										<div className={styles.tooltip}>
											{formatMessage(messages.cardBalanceIsUsedFirstInfo)}
										</div>
									}
								/>
							)}
						</div>
					)}
					<div
						className={classNames(styles.balance, {
							[styles.biggerFz]: type === BalanceBlockType.CARD,
						})}
					>
						{`${availableBalance.search('-') !== -1 ? '-' : ''}  ${amountFormatter(
							formatPrecision(availableBalance, currencyCode)
						)} ${currencyCode}`}
					</div>
					{type === BalanceBlockType.WALLET &&
						currencyCode !== refCurrency &&
						!toDecimal(availableBalance).equals(0) && (
							<div
								className={styles.convertedAmount}
								data-cy={TestIds.ConvertedAmount}
							>
								≈{' '}
								{!isLoading && currentCurrencyRates && refCurrency
									? `${amountFormatter(
											formatPrecision(
												toDecimal(availableBalance)
													.times(toDecimal(currentCurrencyRates?.value))
													.toFixed(refCurrencyRates?.scale)
													.toString(),
												CurrencyEnum[refCurrency]
											)
									  )} `
									: '--'}
								{refCurrency}
							</div>
						)}
					{reservedAmount && !toDecimal(reservedAmount).equals(0) && (
						<div className={styles.reservedAmount}>
							<FormattedMessage
								id="balanceBlock.reservedBalance"
								defaultMessage="Reserved balance"
							/>{' '}
							{`${amountFormatter(
								formatPrecision(reservedAmount, currencyCode)
							)} ${currencyCode}`}
						</div>
					)}
				</div>
				<ul
					className={classNames(styles.buttonRow, {
						[styles.cards]: type === BalanceBlockType.CARD,
					})}
				>
					{type === BalanceBlockType.WALLET &&
						!currencyUtils.getConfigOrDefault(currencyCode).noAddress && (
							<li>
								<ActionButton
									data-cy={formatTestId(TestIds.BalanceDeposit_0, currencyCode)}
									buttonIcon={ButtonIcon.DEPOSIT}
									link={`/${locale}/deposit${
										isVirtual
											? `/crypto/${currencyCode}/${id}`
											: (!isVirtual && currencyCode === CurrencyEnum.EUR) ||
											  (!isVirtual && currencyCode === CurrencyEnum.GBP)
											? `/bank?currency=${currencyCode}`
											: `/e-wallet?currency=${currencyCode}`
									}`}
									text={messages.deposit}
								/>
							</li>
						)}
					{type === BalanceBlockType.WALLET && (
						<li>
							<ActionButton
								data-cy={formatTestId(TestIds.BalanceExchange_0, currencyCode)}
								buttonIcon={ButtonIcon.EXCHANGE}
								link={`/${locale}/convert/instant-exchange?currency=${currencyCode}`}
								text={messages.exchange}
							/>
						</li>
					)}
					{type === BalanceBlockType.WALLET &&
						!currencyUtils.getConfigOrDefault(currencyCode).noAddress && (
							<li>
								<ActionButton
									data-cy={formatTestId(TestIds.BalanceWithdraw_0, currencyCode)}
									buttonIcon={ButtonIcon.WITHDRAW}
									link={`/${locale}/withdraw${
										isVirtual
											? `/crypto/${currencyCode.toUpperCase()}/${id}`
											: [
													CurrencyEnum.EUR,
													CurrencyEnum.USD,
													CurrencyEnum.GBP,
											  ].includes(currencyCode)
											? `/bank?currency=${currencyCode.toUpperCase()}`
											: ''
									}`}
									text={messages.withdraw}
								/>
							</li>
						)}
					{type === BalanceBlockType.WALLET &&
						walletAddress &&
						!noBuy.includes(currencyCode) && (
							<li>
								<ActionButton
									data-cy={formatTestId(TestIds.BalanceBuy_0, currencyCode)}
									buttonIcon={ButtonIcon.BUY}
									text={messages.buy}
									onClick={handleBuyClick}
								/>
							</li>
						)}
					{type === BalanceBlockType.CARD && (
						<li>
							<ActionButton
								buttonIcon={ButtonIcon.LOAD}
								link={`/${locale}/cards/account/${GetCurrentCard()?.id}/load`}
								text={messages.load}
								isDisabled={!isCardOptionsEnabled}
								data-cy="action_load"
							/>
						</li>
					)}
					{type === BalanceBlockType.CARD && (
						<li>
							<ActionButton
								data-cy="action_unload"
								buttonIcon={ButtonIcon.UNLOAD}
								link={`/${locale}/cards/account/${GetCurrentCard()?.id}/unload`}
								text={messages.unload}
								isDisabled={
									!isCardOptionsEnabled || toDecimal(availableBalance).equals(0)
								}
							/>
						</li>
					)}
				</ul>
			</div>
		</div>
	);
};

export default BalanceBlock;
