import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/Store';
import PageTitle from '../../../components/PageTitle/PageTitle';
import WithdrawOptionList, { ListType } from '../Shared/WithdrawOptionList/WithdrawOptionList';
import withdrawMessages from '../../../redux/WithdrawState/WithdrawMessages';
import Seo from '../../../components/Seo/Seo';

const messages = defineMessages({
	title: {
		id: 'giftCards.title',
		defaultMessage: 'Gift Cards',
	},
});

const GiftCards = () => {
	const { formatMessage, locale } = useIntl();
	const { paymentMethods } = useSelector((state: RootState) => state.WithdrawState);
	// eslint-disable-next-line camelcase
	const { gift_cards } = paymentMethods;

	return (
		<>
			<Seo title={withdrawMessages.metaGiftTitle} />
			<PageTitle
				title={formatMessage(messages.title)}
				historyLink={`/${locale}/withdraw/history/gift-cards`}
			/>
			<WithdrawOptionList type={ListType.GIFT_CARDS} list={gift_cards.data} height={635} />
		</>
	);
};

export default GiftCards;
