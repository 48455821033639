import {
	faArrowDown,
	faArrowUp,
	faChevronDown,
	faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import {
	Children,
	FC,
	ForwardedRef,
	HTMLAttributes,
	PropsWithChildren,
	ReactNode,
	RefObject,
	cloneElement,
	isValidElement,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UseQueryResult } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import sortSvg from './sort.svg';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import useQueryParams from '../../../../hooks/useQueryParams';
import Pagination from '../../../../components/Pagination/Pagination';
import styles from './SortableList.module.scss';
import Loading from '../../../Merchants/Shared/Loading/Loading';
import NotFound from '../../../Merchants/Shared/NotFound/NotFound';
import PageableResponse from '../../../../interfaces/PageableResponse';
import { SortableListContextProvider, useSortableListRecord } from './SortableListContext';
import { RootState } from '../../../../redux/Store';
import Button, { ButtonStyle } from '../../../../components/Button/Button';
import baseMsg from '../../../../messages/base.messages';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

const SortDrawer = <T,>({
	children,
	onClose,
}: Omit<Props<T>, 'emptyMessage' | 'query'> & { onClose: () => void }) => {
	const { formatMessage } = useIntl();
	const drawerRef = useRef<HTMLDivElement>(null);
	const [[sort], setParams] = useQueryParams({
		sort: '',
	});
	const [sortBy, order] = sort!.split(',') as [string, 'asc' | 'desc'];

	const onSort = useCallback(
		(prop: keyof T) => {
			setParams({
				sort: `${String(prop)},${
					prop === sortBy ? (order === 'asc' ? 'desc' : 'asc') : `desc`
				}`,
			});
		},
		[setParams, sortBy, order]
	);

	useOnClickOutside(drawerRef, onClose);

	const sortableFields = Children.toArray(children).filter(
		(child) => isValidElement(child) && child.props.sortBy
	);
	return (
		<div ref={drawerRef} className={styles.sortDrawer}>
			<div className={styles.drawerHandle} />
			{sortableFields.map(
				(field) =>
					isValidElement(field) && (
						<div
							key={field.key}
							className={classNames(styles.drawerField, {
								[styles.active]: field.props.sortBy === sortBy,
							})}
							onClick={() => onSort(field.props.sortBy)}
						>
							{field.props.header}
							{field.props.sortBy === sortBy && (
								<FontAwesomeIcon
									className={styles.icon}
									icon={order === 'asc' ? faArrowUp : faArrowDown}
								/>
							)}
						</div>
					)
			)}
			<Button className={styles.close} buttonStyle={ButtonStyle.TERTIARY} onClick={onClose}>
				{formatMessage(baseMsg.close)}
			</Button>
		</div>
	);
};

type Props<T> = PropsWithChildren<{
	emptyMessage: string;
	query: UseQueryResult<PageableResponse<T[]>, Error>;
}>;

type CellProps<T> = {
	header: string | ReactNode;
	source?: keyof T;
	sortBy?: keyof T;
} & HTMLAttributes<HTMLDivElement>;

export const ListCell = <T,>({ className, children, source, ...props }: CellProps<T>) => {
	const record = useSortableListRecord<T>();
	if (!record) return null;

	return (
		<div className={classNames(styles.cell, className)} {...props}>
			{source ? `${record[source] || ''}` : children}
		</div>
	);
};

const HeaderCell = <T,>({
	header,
	sortBy: sortProp,
	sortable = false,
	className,
	...props
}: Omit<CellProps<T>, 'children'> & { sortable?: boolean }) => {
	const [[sort], setParams] = useQueryParams({
		sort: '',
	});
	const [sortBy, order] = sort!.split(',') as [string, 'asc' | 'desc'];

	const isSortable = sortable && !!sortProp;
	const isCurrent = sortBy === sortProp;

	const onClick = useCallback(() => {
		if (!isSortable) return;

		setParams({
			sort: `${String(sortProp)},${isCurrent ? (order === 'asc' ? 'desc' : 'asc') : `desc`}`,
		});
	}, [isSortable, setParams, sortProp, isCurrent, order]);

	const sortIcon = useMemo(() => {
		if (!isSortable) return null;
		if (!isCurrent) return <SvgIcon src={sortSvg} className={styles.sortIcon} />;
		return (
			<FontAwesomeIcon
				className={styles.sortIcon}
				icon={order === 'asc' ? faChevronUp : faChevronDown}
			/>
		);
	}, [isCurrent, isSortable, order]);

	return (
		<div
			className={classNames(styles.headerCell, className, { [styles.sortable]: isSortable })}
			{...props}
			onClick={onClick}
		>
			{header}
			{sortIcon}
		</div>
	);
};

const DesktopHeaderCell = <T,>(props: Omit<CellProps<T>, 'children'>) => (
	<HeaderCell {...props} sortable />
);

const MobileHeaderCell = <T,>(props: Omit<CellProps<T>, 'children'>) => <HeaderCell {...props} />;

const MobileListRow = <T,>({ children }: Omit<Props<T>, 'emptyMessage' | 'query'>) => {
	return (
		<div className={styles.mobileRow}>
			{Children.map(children, (child) => (
				<div className={styles.mobileProperty}>
					{isValidElement(child) ? <MobileHeaderCell {...child.props} /> : child}
					{isValidElement(child) ? cloneElement(child) : child}
				</div>
			))}
		</div>
	);
};

const DesktopListHeader = <T,>({ children }: Omit<Props<T>, 'emptyMessage' | 'query'>) => {
	return (
		<div className={classNames(styles.header, styles.row)}>
			{Children.map(children, (child) =>
				isValidElement(child) ? (
					<DesktopHeaderCell
						{...child.props}
						style={{ flexBasis: `calc(${100 / Children.count(children)}%)` }}
					/>
				) : (
					child
				)
			)}
		</div>
	);
};

const DesktopListRow = <T,>({ children }: Omit<Props<T>, 'emptyMessage' | 'query'>) => {
	return (
		<div className={styles.row}>
			{Children.map(children, (child) =>
				isValidElement(child)
					? cloneElement(child, {
							style: {
								flexBasis: `calc(${100 / Children.count(children)}%)`,
								...(child.props.style || {}),
							},
							...child.props,
					  })
					: child
			)}
		</div>
	);
};

const MobileList = <T,>({ emptyMessage, query, children }: Props<T>) => {
	const { formatMessage } = useIntl();
	const { data, isLoading, isFetching } = query;
	const [showDrawer, setShowDrawer] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	return (
		<div className={styles.list} ref={ref}>
			{isLoading ? (
				<Loading />
			) : data?.empty ? (
				<NotFound message={emptyMessage} />
			) : (
				<>
					<div className={styles.body}>
						{isFetching ? (
							<Loading />
						) : (
							<>
								<Button
									className={styles.sortButton}
									buttonStyle={ButtonStyle.TERTIARY}
									onClick={() => setShowDrawer(true)}
								>
									<div className={styles.sortButtonContent}>
										<span>{formatMessage(baseMsg.sort)}</span>
										<FontAwesomeIcon icon={faChevronDown} />
									</div>
								</Button>
								{data?.content.map((item, index) => (
									<SortableListContextProvider
										key={(item as any).id || index}
										value={{ record: item }}
									>
										<MobileListRow>{children}</MobileListRow>
									</SortableListContextProvider>
								))}
							</>
						)}
					</div>
					<Pagination pageSize={data?.size} totalCount={data?.totalElements} />
				</>
			)}
			{showDrawer && <SortDrawer onClose={() => setShowDrawer(false)}>{children}</SortDrawer>}
		</div>
	);
};

const DesktopList = <T,>({ emptyMessage, query, children }: Props<T>) => {
	const { data, isFetching } = query;
	return (
		<div className={styles.list}>
			{data?.empty ? (
				<NotFound message={emptyMessage} />
			) : (
				<>
					<DesktopListHeader>{children}</DesktopListHeader>
					<div className={styles.body}>
						{isFetching ? (
							<Loading />
						) : (
							data?.content.map((item, index) => (
								<SortableListContextProvider
									key={(item as any).id || index}
									value={{ record: item }}
								>
									<DesktopListRow>{children}</DesktopListRow>
								</SortableListContextProvider>
							))
						)}
					</div>
					<Pagination pageSize={data?.size} totalCount={data?.totalElements} />
				</>
			)}
		</div>
	);
};

const SortableList = <T,>(props: Props<T>) => {
	const { isMobile } = useSelector((state: RootState) => state.AppState);
	if (isMobile) return <MobileList {...props} />;
	return <DesktopList {...props} />;
};

export default SortableList;
