import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';
import LiveChat from './liveChat';

export function isLiveEnvironment({ allowPP } = { allowPP: false }) {
	if (process.env.NODE_ENV === 'production') {
		const environments = ['local', 'test', 'dev'];
		if (!allowPP) environments.push('pp');
		return !environments.some((value) => window.location.host.includes(value));
	}
	return false;
}

/**
 * @param {string} userReferralCode
 * @param {string} utmTracking
 * @param {string | undefined} gtmId
 * @param {boolean} marketingCookies
 * @param {boolean} statisticalCookies
 */
export function getGoogleAnalyticsScript(
	userReferralCode,
	utmTracking,
	gtmId,
	marketingCookies,
	statisticalCookies
) {
	if (!gtmId || !isLiveEnvironment({ allowPP: true })) return;

	window.dataLayer = window.dataLayer || [];

	function gtag() {
		// eslint-disable-next-line prefer-rest-params
		window.dataLayer.push(arguments);
	}

	window.gtag = gtag;

	gtag('consent', 'default', {
		ad_storage: marketingCookies ? 'granted' : 'denied',
		analytics_storage: statisticalCookies ? 'granted' : 'denied',
		wait_for_update: 500,
	});

	gtag('set', 'page_path', window.location.pathname);
	gtag('set', 'allow_google_signals', marketingCookies || statisticalCookies);
	gtag('set', 'allow_ad_personalization_signals', marketingCookies);

	if (marketingCookies || statisticalCookies) {
		gtag(
			'set',
			'user_id',
			userReferralCode || (utmTracking === '' ? NaN : Cookies.get('uaid'))
		);
	} else {
		window[`ga-disable-${gtmId}`] = true;
	}

	TagManager.initialize({
		gtmId,
	});
}

/**
 * @param {boolean} includeGA
 * @param {string} facebookPixelId
 */
export function getFacebookPixelScript(includeFBPixel, facebookPixelId) {
	if (includeFBPixel) {
		/* eslint-disable */
		!(function (f, b, e, v, n, t, s) {
			if (f.fbq) return;
			n = f.fbq = function () {
				n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
			};
			if (!f._fbq) f._fbq = n;
			n.push = n;
			n.loaded = !0;
			n.version = '2.0';
			n.queue = [];
			t = b.createElement(e);
			t.async = !0;
			t.src = v;
			s = b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t, s);
		})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
		/* eslint-enable */
		window.fbq('consent', 'grant');
		window.fbq('init', facebookPixelId);
		window.fbq('track', 'PageView');
	}
}

/**
 * @param {object} userObject
 * @param {string} siftScienceKey
 */
export function getSiftScienceScript(userEmail, siftScienceKey) {
	const userId = userEmail || '';
	const sessionId = Cookies.get('uaid');

	// eslint-disable-next-line no-underscore-dangle
	const sift = window._sift || [];
	sift.push(['_setAccount', siftScienceKey]);
	sift.push(['_setUserId', userId]);
	sift.push(['_setSessionId', sessionId]);
	sift.push(['_trackPageview']);
	(function () {
		function ls() {
			const e = document.createElement('script');
			e.type = 'text/javascript';
			e.async = true;
			e.src = `${
				document.location.protocol === 'https:' ? 'https://' : 'http://'
			}cdn.siftscience.com/s.js`;
			const s = document.getElementsByTagName('script')[0];
			s.parentNode.insertBefore(e, s);
		}
		ls();
	})();
}

/**
 * @param {boolean} isHotjarTrackingEnabled
 * @param {string} hotjarId
 */
export function getHotjarScript(isHotjarTrackingEnabled, hotjarId) {
	if (isHotjarTrackingEnabled) {
		/* eslint-disable */
		(function (h, o, t, j, a, r) {
			h.hj =
				h.hj ||
				function () {
					(h.hj.q = h.hj.q || []).push(arguments);
				};
			h._hjSettings = {
				hjid: hotjarId,
				hjsv: 6,
			};
			a = o.getElementsByTagName('head')[0];
			r = o.createElement('script');
			r.async = 1;
			r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
			a.appendChild(r);
		})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
		/* eslint-enable */
	}
}

/**
 * @param {string} twitterPixelId
 */
export function getTwitterScript(twitterPixelId) {
	/* eslint-disable */
	!(function (e, t, n, s, u, a) {
		e.twq ||
			((s = e.twq = function () {
				s.exe ? s.exe(...arguments) : s.queue.push(arguments);
			}),
			(s.version = '1.1'),
			(s.queue = []),
			(u = t.createElement(n)),
			(u.async = !0),
			(u.src = '//static.ads-twitter.com/uwt.js'),
			(a = t.getElementsByTagName(n)[0]),
			a.parentNode.insertBefore(u, a));
	})(window, document, 'script');
	/* eslint-enable */
	// Insert Twitter Pixel ID and Standard Event data below
	window.twq('init', twitterPixelId);
	window.twq('track', 'PageView');
}

export function getAdformScript() {
	// Click tracker script
	const { head } = document;
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = 'https://a1.adform.net/serving/scripts/trackpoint/';
	head.appendChild(script);

	// Page tracker script
	const s = document.createElement('script');
	s.type = 'text/javascript';
	s.async = true;
	s.src = 'https://a1.adform.net/serving/scripts/trackpoint/async/';
	const x = document.getElementsByTagName('script')[0];
	x.parentNode.insertBefore(s, x);
}

/**
 * @param {string} userEmail
 * @param {string} siftScienceKey
 */

export function loadNecessaryCookieScripts(userEmail, siftScienceKey) {
	// eslint-disable-next-line
	if (window.debugMode) console.log('Loading necessary cookies');
	if (isLiveEnvironment()) {
		getSiftScienceScript(userEmail, siftScienceKey);
	}
}

/**
 * @param {string} userEmail
 * @param {string} userName
 * @param {string} userSurname
 * @param {string} livechatKey
 * @param {string} authDomain
 */

export function loadPreferenceCookieScripts(
	userEmail,
	userName,
	userSurname,
	livechatKey,
	authDomain
) {
	// eslint-disable-next-line
	if (window.debugMode) console.log('Loading preference cookies');
	if (isLiveEnvironment({ allowPP: true })) {
		LiveChat(userEmail, userName, userSurname, livechatKey, authDomain);
	}
}

/**
 * @param {string} userReferralCode
 * @param {string} utmTracking
 * @param {boolean} isHotjarTrackingEnabled
 * @param {string} hotjarId
 * @param {string} googleAnalyticsId
 */

export function loadStatisticalCookieScripts(isHotjarTrackingEnabled, hotjarId) {
	// eslint-disable-next-line
	if (window.debugMode) console.log('Loading statistical cookies');
	if (isLiveEnvironment()) {
		getHotjarScript(isHotjarTrackingEnabled, hotjarId);
	}
}

/**
 * @param {boolean} includeGA
 * @param {boolean} includeFBPixel
 * @param {string} facebookPixelId
 * @param {string} twitterPixelId
 */

export function loadMarketingCookieScripts(includeFBPixel, facebookPixelId, twitterPixelId) {
	// eslint-disable-next-line
	if (window.debugMode) console.log('Loading marketing cookies');
	if (isLiveEnvironment()) {
		getFacebookPixelScript(includeFBPixel, facebookPixelId);
		getTwitterScript(twitterPixelId);
		getAdformScript();
	}
}
