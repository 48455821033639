import createSelector from '../../createSelector';

const getState = createSelector((state) => state.PaymentState.orders);

const getOrder = createSelector(getState, (state) => state.order);
const getForm = createSelector(getState, (state) => state.form);
const getOrderData = createSelector(getOrder, (state) => state.data);
const getPay = createSelector(getState, (state) => state.pay);

export default {
	getState,
	getOrder,
	getForm,
	getOrderData,
	getPay,
};
