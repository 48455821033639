import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import convertMsg from '../../convertMsg';
import styles from './InstantHistory.module.scss';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import HistoryTable from '../../../../components/HistoryTable/HistoryTable';
import { HistoryType } from '../../../../hooks/useHistoryTableData';
import useOpenOrderDataProvider from './useInstantHistoryDataProvider';
import HistoryFilter from '../../../../components/HistoryFilter/HistoryFilter';

const InstantHistory: FC = () => {
	const { url }: { url: string } = useRouteMatch();
	const history = useOpenOrderDataProvider();
	const { formatMessage } = useIntl();

	return (
		<div>
			<PageTitle
				previousPageLink={url.replace('/history', '')}
				isPreviousPageLinkVisibleOnDesktop
				isPreviousPageLinkVisibleOnMobile
				title={formatMessage(convertMsg.instantHistoryBack)}
				className={styles.noPadding}
			/>
			<HistoryFilter />
			<HistoryTable
				data={history.data}
				isLoading={history.isLoading}
				totalCount={history.totalCount}
				historyType={HistoryType.INSTANT_EXCHANGE}
			/>
		</div>
	);
};

export default InstantHistory;
