/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, RefObject } from 'react';

const useOnClickOutside = (
	ref: RefObject<HTMLElement>,
	handler: (param: any) => void,
	useClick = false
): void => {
	useEffect(() => {
		const listener = (event: Event) => {
			if (!ref.current || ref.current.contains(event.target as Node)) {
				return;
			}
			handler(event);
		};

		const event = useClick ? 'click' : 'mousedown';

		document.addEventListener(event, listener);
		document.addEventListener('touchstart', listener);

		return () => {
			document.removeEventListener(event, listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [ref, handler, useClick]);
};

export default useOnClickOutside;
