import { useState, useRef } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { faLongArrowDown } from '@fortawesome/pro-regular-svg-icons/faLongArrowDown';
import { faLongArrowUp } from '@fortawesome/pro-regular-svg-icons/faLongArrowUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { CurrencyIcon, CurrencyEnum, currencyUtils } from '@spectrocoin/sc-currencies';
import Button, { ButtonStyle, ButtonType } from '../Button/Button';
import Loader from '../Loader/Loader';
import {
	toDecimal,
	formatPrecision,
	currencySymbols,
} from '../../helpers/currencyHelper/currencyHelper';
import { RatesDetailsResponse } from '../../redux/RatesState/RatesTypes';
import { RootState } from '../../redux/Store';
import styles from './SingleWallet.module.scss';
import moreDots from './images/moreDots.svg';
import depositImg from './images/deposit.svg';
import withdrawImg from './images/withdraw.svg';
import convertImg from './images/exchange.svg';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import TestIds, { formatTestId } from '../../test/TestIds';
import { RateAmount } from '../../redux/AppState/AppTypes';
import { MobileCol } from '../WalletBlocks/WalletBlocks';
import useFormatAmount from '../../hooks/useFormatAmount';

interface SingleWalletProps {
	currencyCode: CurrencyEnum;
	currencyName: string;
	availableBalance: string;
	id: string;
	mobileColValue: MobileCol;
	linkSmaller?: boolean;
	refCurrency: CurrencyEnum | string;
	disabledLinks?: boolean;
	isToggleVisible?: boolean;
	isNew?: boolean;
	addButton?: boolean;
	isBalanceVisible?: boolean;
	isRatesVisible?: boolean;
	buyButton?: boolean;
	isActionsVisible?: boolean;
	addNewWalletMethod?: (currencyCode: string) => Promise<any>;
}

enum ActionType {
	DEPOSIT = 'DEPOSIT',
	CONVERT = 'CONVERT',
	WITHDRAW = 'WITHDRAW',
}

const HIDDEN_BALANCE = '********';

const messages = defineMessages({
	addNewWallet: {
		id: 'walletBlock.addNewWallet',
		defaultMessage: 'Add wallet',
	},
	add: {
		id: 'walletBlock.add',
		defaultMessage: 'Add',
	},
	noDataToShow: {
		id: 'rateGraphWidget.noDataToShow',
		defaultMessage: 'Currenty there is no data to show',
	},
	buttonBuy: {
		id: 'walletBlocks.buy',
		defaultMessage: 'BUY',
	},
	deposit: {
		id: 'balanceBlock.deposit',
		defaultMessage: 'Deposit',
	},
	convert: {
		id: 'convert.convert',
		defaultMessage: 'Convert',
	},
	withdraw: {
		id: 'balanceBlock.withdraw',
		defaultMessage: 'Withdraw',
	},
	new: {
		id: 'walletBlock.new',
		defaultMessage: 'New',
	},
});

const SingleWallet = ({
	currencyCode,
	currencyName,
	availableBalance,
	id,
	refCurrency,
	mobileColValue,
	isBalanceVisible = false,
	disabledLinks = false,
	isToggleVisible = false,
	isNew = false,
	addButton = false,
	isRatesVisible = false,
	buyButton = false,
	linkSmaller = false,
	isActionsVisible = false,
	addNewWalletMethod,
}: SingleWalletProps) => {
	const { locale, formatMessage } = useIntl();
	const { push } = useHistory();
	const rates = useSelector((state: RootState) => state?.RatesState?.ratesDetails);
	const { isMobile, rateAmount } = useSelector((state: RootState) => state?.AppState);
	const { isLoading } = useSelector((state: RootState) => state?.RatesState);
	const amountFormatter = useFormatAmount();
	const [isUpdateLoading, setIsUpdateIsLoading] = useState<boolean>(false);
	const [showMoreActions, setShowMoreActions] = useState(false);
	const actionsDropdownRef = useRef(null);
	useOnClickOutside(actionsDropdownRef, () => setShowMoreActions(false));

	const addWallet = () => {
		if (typeof addNewWalletMethod !== 'function') return;

		setIsUpdateIsLoading(true);
		addNewWalletMethod(currencyCode)
			.then(() => setIsUpdateIsLoading(false))
			.catch(() => {
				console.error(`Couldn't add new wallet`);
				setIsUpdateIsLoading(false);
			});
	};

	if (!rates) return null;
	const refCurrencyRates = rates?.find(
		(o: RatesDetailsResponse) => o.currencyCode === refCurrency
	);
	const currentWalletRate = rates?.find(
		(o: RatesDetailsResponse) => o.currencyCode === currencyCode
	);

	const getRate = (week: string, day: string, month: string) => {
		switch (rateAmount) {
			case RateAmount.DAY:
				return day;
			case RateAmount.MONTH:
				return month;
			case RateAmount.WEEK:
				return week;
			default:
				return day;
		}
	};

	// eslint-disable-next-line consistent-return
	const handleActionClick = (
		type: ActionType,
		event: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		event.preventDefault();

		const isVirtual = currentWalletRate?.virtual || false;
		switch (type) {
			case ActionType.DEPOSIT:
				return push(
					`/${locale}/deposit${
						isVirtual
							? `/crypto/${currencyCode}/${id}`
							: (!isVirtual && currencyCode === CurrencyEnum.EUR) ||
							  (!isVirtual && currencyCode === CurrencyEnum.GBP)
							? `/bank?currency=${currencyCode}`
							: `/e-wallet`
					}`
				);
			case ActionType.CONVERT:
				return push(`/${locale}/convert/instant-exchange?currency=${currencyCode}`);
			case ActionType.WITHDRAW:
				return push(
					`/${locale}/withdraw${
						isVirtual
							? `/crypto/${currencyCode.toUpperCase()}/${id}`
							: [CurrencyEnum.EUR, CurrencyEnum.USD, CurrencyEnum.GBP].includes(
									currencyCode
							  )
							? `/bank?currency=${currencyCode.toUpperCase()}`
							: ''
					}`
				);
			default:
				return null;
		}
	};

	const walletItem = () => {
		const { last24HChange, lastMonthChange, lastWeekChange, name, value } =
			rates.find(({ currencyCode: rateCurrencyCode }) => rateCurrencyCode === currencyCode) ??
			rates[0];

		const isNegative = toDecimal(
			getRate(lastWeekChange, last24HChange, lastMonthChange)
		).lessThan(0);

		return (
			<>
				{isUpdateLoading && <Loader className={styles.walletLoader} />}
				{addButton || isToggleVisible ? (
					<div className={styles.linkContainer}>
						<div
							className={classNames(styles.link, {
								[styles.disabledLink]: disabledLinks,
								[styles.manageWallets]: isToggleVisible,
								[styles.addNewWallets]: addButton,
								[styles.noWidth]: linkSmaller,
							})}
						>
							<CurrencyIcon
								newText={isNew ? formatMessage(messages.new) : undefined}
								currencyType={currencyCode}
								data-cy={formatTestId(TestIds.WalletOption_0, currencyCode)}
							/>
							<div
								className={classNames(styles.currencyName, {
									[styles.manageName]: addButton,
								})}
							>
								{currencyName}
							</div>
						</div>
						{!isMobile && isRatesVisible && currencyCode !== refCurrency && (
							<div
								key={name}
								className={classNames({
									[styles.percentageValue]: !addButton && !isToggleVisible,
									[styles.opacity]: addButton,
									[styles.manageWalletRates]: addButton || isToggleVisible,
								})}
							>
								<div>
									<div className={styles.currencyText}>
										{`${currencySymbols[refCurrency]}${amountFormatter(
											formatPrecision(
												toDecimal(value).toFixed(2).toString(),
												CurrencyEnum[refCurrency]
											)
										)}`}
									</div>
									<div className={styles.changeContainer}>
										<FontAwesomeIcon
											className={classNames(styles.arrows, {
												[styles.negative]: isNegative,
											})}
											width={10}
											icon={isNegative ? faLongArrowDown : faLongArrowUp}
										/>
										<div className={styles.currencyText}>{`${getRate(
											lastWeekChange,
											last24HChange,
											lastMonthChange
										)}%`}</div>
									</div>
								</div>
							</div>
						)}
					</div>
				) : (
					<>
						<div
							className={classNames(styles.link, {
								[styles.disabledLink]: disabledLinks,
								[styles.manageWallets]: isToggleVisible || addButton,
								[styles.noWidth]: linkSmaller,
							})}
						>
							<CurrencyIcon
								newText={isNew ? formatMessage(messages.new) : undefined}
								currencyType={currencyCode}
								className={styles.marginRight}
							/>
							<div className={styles.currencyCode}>
								{currencyCode}
								<br />
								<span className={styles.currencyName}>{currencyName}</span>
							</div>
						</div>
						{!isMobile && isRatesVisible && currencyCode !== refCurrency && (
							<div
								key={name}
								className={classNames(styles.percentageValue, {
									[styles.opacity]: addButton,
								})}
							>
								<div data-cy={TestIds.WalletRatesText}>
									<div className={styles.currencyText}>
										{`${currencySymbols[refCurrency]}${formatPrecision(
											toDecimal(value).toFixed(2).toString(),
											CurrencyEnum[refCurrency]
										)}`}
									</div>
								</div>
								<div className={styles.changeContainer}>
									<FontAwesomeIcon
										className={classNames(styles.arrows, {
											[styles.negative]: isNegative,
										})}
										width={10}
										icon={isNegative ? faLongArrowDown : faLongArrowUp}
									/>
									<div
										className={classNames(
											styles.currencyText,
											styles.ratesText,
											{
												[styles.negative]: isNegative,
											}
										)}
									>{`${getRate(
										lastWeekChange,
										last24HChange,
										lastMonthChange
									)}%`}</div>
								</div>
							</div>
						)}
					</>
				)}
				{(!addButton || !isMobile) && (
					<div
						className={classNames(styles.balance, {
							[styles.mt20]: currencyCode === refCurrency,
							[styles.opacity]: addButton,
						})}
					>
						{toDecimal(availableBalance).equals(0) && !isMobile && buyButton ? (
							<Button
								type={ButtonType.LINK}
								buttonStyle={ButtonStyle.PRIMARY}
								className={styles.buyButton}
								link={`/${locale}/convert/instant-exchange?receiveCurrency=${CurrencyEnum[currencyCode]}`}
								text={messages.buttonBuy}
							/>
						) : mobileColValue === MobileCol.BALANCE || !isMobile ? (
							<div>
								{isBalanceVisible
									? `${amountFormatter(
											formatPrecision(
												availableBalance,
												CurrencyEnum[currencyCode]
											)
									  )} ${currencyCode}`
									: HIDDEN_BALANCE}
								{currencyCode !== refCurrency && (
									<div
										className={styles.referenceBalance}
										data-cy={TestIds.WalletRefBalance}
									>
										{isLoading
											? '--'
											: isBalanceVisible
											? `${amountFormatter(
													formatPrecision(
														toDecimal(availableBalance)
															.times(
																toDecimal(currentWalletRate!.value)
															)
															.toFixed(refCurrencyRates?.scale)
															.toString(),
														CurrencyEnum[refCurrency]
													)
											  )} ${refCurrency}`
											: HIDDEN_BALANCE}
									</div>
								)}
							</div>
						) : (
							<div className={styles.mobilePrice}>
								{`${currencySymbols[refCurrency]}${amountFormatter(
									formatPrecision(
										toDecimal(value).toFixed(2).toString(),
										CurrencyEnum[refCurrency]
									)
								)}`}
								<p
									className={classNames(styles.arrows, {
										[styles.negative]: toDecimal(
											getRate(lastWeekChange, last24HChange, lastMonthChange)
										).lessThan(0),
									})}
								>
									<FontAwesomeIcon
										className={classNames(styles.arrows, {
											[styles.negative]: toDecimal(
												getRate(
													lastWeekChange,
													last24HChange,
													lastMonthChange
												)
											).lessThan(0),
										})}
										icon={
											toDecimal(
												getRate(
													lastWeekChange,
													last24HChange,
													lastMonthChange
												)
											).lessThan(0)
												? faLongArrowDown
												: faLongArrowUp
										}
									/>
									{`${amountFormatter(
										getRate(lastWeekChange, last24HChange, lastMonthChange)
									)}%`}
								</p>
							</div>
						)}
					</div>
				)}
				{addButton && (
					<div className={styles.toggle}>
						<Button
							buttonStyle={ButtonStyle.TERTIARY}
							text={isMobile ? messages.add : messages.addNewWallet}
							plusIcon={!isMobile}
							className={styles.addWalletButton}
							onClick={addWallet}
							isLoading={isUpdateLoading}
							isDisabled={isUpdateLoading}
							minWidth={isMobile ? 50 : 182}
						/>
					</div>
				)}
				{isActionsVisible && !isMobile && !toDecimal(availableBalance).equals(0) && (
					<div ref={actionsDropdownRef} className={styles.actionsContainer}>
						<div
							className={styles.moreActions}
							onClick={(e) => {
								e.preventDefault();
								setShowMoreActions(!showMoreActions);
							}}
						>
							<img src={moreDots} />
						</div>
						{showMoreActions && (
							<div className={styles.moreActionsContainer}>
								{!currencyUtils.getConfigOrDefault(currencyCode).noAddress && (
									<div
										onClick={(e) => handleActionClick(ActionType.DEPOSIT, e)}
										className={styles.actionsButton}
									>
										<img src={depositImg} />
										<div className={styles.actionsText}>
											{formatMessage(messages.deposit)}
										</div>
									</div>
								)}
								<div
									onClick={(e) => handleActionClick(ActionType.CONVERT, e)}
									className={styles.actionsButton}
								>
									<img src={convertImg} />
									<div className={styles.actionsText}>
										{formatMessage(messages.convert)}
									</div>
								</div>
								{!currencyUtils.getConfigOrDefault(currencyCode).noAddress && (
									<div
										onClick={(e) => handleActionClick(ActionType.WITHDRAW, e)}
										className={styles.actionsButton}
									>
										<img src={withdrawImg} />
										<div className={styles.actionsText}>
											{formatMessage(messages.withdraw)}
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				)}
			</>
		);
	};

	const renderWallet = () => {
		if (disabledLinks) return walletItem();
		return (
			<Link
				to={`/${locale}/account/history/${currencyCode}/${id}`}
				className={classNames(styles.walletContainer, {
					[styles.manageWalletContainer]: isToggleVisible,
				})}
				data-cy={formatTestId(TestIds.WalletOption_0, currencyCode)}
			>
				{walletItem()}
			</Link>
		);
	};

	return (
		<li
			className={classNames({
				[styles.isUpdateLoading]: isUpdateLoading,
				[styles.walletManage]: disabledLinks,
			})}
		>
			{renderWallet()}
		</li>
	);
};

export default SingleWallet;
