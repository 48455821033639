import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import Button, { ButtonStyle, ButtonType } from '../../../../../components/Button/Button';
import baseMsg from '../../../../../messages/base.messages';
import { removeDocumentItem } from '../../../../../redux/DocumentsHistoryState/DocumentsHistoryActions';
import { getUserFilesURL } from '../../../../../redux/endpoints';
import toggleModal from '../../../../../redux/ModalState/ModalActions';
import styles from './DocumentsModal.module.scss';
import axiosInstance from '../../../../../helpers/axiosInstance';

const DocumentsModal: FC<{ id: string }> = ({ id }) => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const onClick = () => {
		setIsLoading(true);
		void axiosInstance
			.delete(getUserFilesURL({ id }))
			.then(() => {
				dispatch(removeDocumentItem(id));
				dispatch(toggleModal());
			})
			.catch((e) => console.log(e))
			.then(() => setIsLoading(false));
	};

	return (
		<div className={styles.container}>
			<p className={styles.text}>
				<FormattedMessage
					id="documents.areYouSure"
					defaultMessage="Are you sure you want to delete document?"
				/>
			</p>
			<Button
				type={ButtonType.BUTTON}
				text={baseMsg.yes}
				onClick={onClick}
				isLoading={isLoading}
			/>
			<Button
				type={ButtonType.BUTTON}
				buttonStyle={ButtonStyle.BORDERLESS}
				text={baseMsg.no}
				onClick={() => dispatch(toggleModal())}
			/>
		</div>
	);
};

export default DocumentsModal;
