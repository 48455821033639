/* eslint-disable @typescript-eslint/no-explicit-any */
export enum ModalType {
	TOGGLE_MODAL = 'TOGGLE_MODAL',
}

export interface ToggleModalAction {
	type: typeof ModalType.TOGGLE_MODAL;
	isOutsideClickable: boolean;
	isHeightAuto: boolean;
	content?: any;
}

export interface ModalState {
	TOGGLE_MODAL: {
		content: any;
		isOutsideClickable: boolean;
		isHeightAuto: boolean;
	};
}
