import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { ConvertStatusType, Frequency } from '../ConvertState/ConvertTypes';

export enum ConvertHistoryActionType {
	IS_LOADING = 'CONVERT_HISTORY_IS_LOADING',
	SET_DATA = 'CONVERT_SET_DATA',
	RESET_DATA = 'CONVERT_RESET_DATA',
	UPDATE_OPEN_ORDER = 'UPDATE_OPEN_ORDER',
	REMOVE_OPEN_ORDER = 'REMOVE_OPEN_ORDER',
}

export enum OpenOrderType {
	RESUME = 'resume',
	PAUSE = 'pause',
	CANCEL = 'cancel',
}

export interface OpenOrder {
	currencyExchangeId: string;
	endDate: string | null;
	id: string;
	frequency: Frequency;
	payAccountId: string;
	payAmount: string;
	payCurrencyCode: CurrencyEnum;
	receiveAccountId: string;
	receiveCurrencyCode: CurrencyEnum;
	startDate: string | null;
	status: ConvertStatusType;
}

export enum HistoryStatus {
	PENDING = 'PENDING',
	PAID = 'PAID',
	FAILED = 'FAILED',
}

export interface InstantHistoryItemProps {
	created: string;
	exchangePairId?: string;
	exchangeRate: string;
	id: string;
	payAmount: string;
	payCurrencyCode: CurrencyEnum;
	receiveAmount: string;
	receiveCurrencyCode: CurrencyEnum;
	status: HistoryStatus;
}

export interface ConvertHistoryState {
	data: OpenOrder[];
	isLoading: boolean;
	totalCount: number;
}

export interface SetHistoryIsLoadingAction {
	type: typeof ConvertHistoryActionType.IS_LOADING;
	data: boolean;
}

export interface SetHistoryDataAction {
	type: typeof ConvertHistoryActionType.SET_DATA;
	data: OpenOrder[];
	totalCount: number;
}

export interface UpdateOpenOrderAction {
	type: typeof ConvertHistoryActionType.UPDATE_OPEN_ORDER;
	data: OpenOrder;
}

export interface RemoveOpenOrderAction {
	type: typeof ConvertHistoryActionType.REMOVE_OPEN_ORDER;
	data: OpenOrder;
}

export interface SetInitialStateAction {
	type: typeof ConvertHistoryActionType.RESET_DATA;
}
