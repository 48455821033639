import { FC } from 'react';
import { toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import { CalcType } from '../../../../redux/ConvertState/ConvertTypes';
import styles from './PrecentOptions.module.scss';

interface Props {
	onChange: (value: string, type: CalcType, isInput: boolean) => void;
	fullAmount: string;
}

enum Percent {
	P25 = 0.25,
	P50 = 0.5,
	P75 = 0.75,
	P100 = 1,
}

const PrecentOptions: FC<Props> = ({ onChange, fullAmount }) => {
	const onClick = (percent: Percent) =>
		onChange(toDecimal(fullAmount).times(percent).toString(), CalcType.RECEIVE, false);

	return (
		<ul className={styles.list}>
			<li onClick={() => onClick(Percent.P25)}>25%</li>
			<li onClick={() => onClick(Percent.P50)}>50%</li>
			<li onClick={() => onClick(Percent.P75)}>75%</li>
			<li onClick={() => onClick(Percent.P100)}>100%</li>
		</ul>
	);
};

export default PrecentOptions;
