import { defineMessages } from 'react-intl';

export default defineMessages({
	metaTitle: {
		id: 'payments.metaTitle',
		defaultMessage: 'Payments',
	},
	payWithWallet: {
		id: 'payments.pay_using_sc',
		defaultMessage: 'Pay using your Spectrocoin Wallet',
	},
	payFromWallet: {
		id: 'payments.pay_from_wallet',
		defaultMessage: 'Pay from wallet',
	},
	id: {
		id: 'merchants.id',
		defaultMessage: 'ID',
	},
	description: {
		id: 'detailsModal.description',
		defaultMessage: 'Description',
	},
	paymentAmount: {
		id: 'payments.paymentAmount',
		defaultMessage: 'Payment amount',
	},
	optional: {
		id: 'payments.optional',
		defaultMessage: 'optional',
	},
	orderNotFound: {
		id: 'payments.order_not_found',
		defaultMessage: 'Order not found',
	},
	donationNotFound: {
		id: 'payments.donation_not_found',
		defaultMessage: 'Donation not found',
	},
	donateUsingWallet: {
		id: 'payments.donate_using_wallet',
		defaultMessage: 'Donate using your SpectroCoin Wallet',
	},
	donateFromWallet: {
		id: 'payments.donate_from_wallet',
		defaultMessage: 'Donate from wallet',
	},
	donate: {
		id: 'payments.donate',
		defaultMessage: 'Donate',
	},
	donationBeingProcessed: {
		id: 'payments.donation_being_processed',
		defaultMessage: 'Donation is being processed',
	},
	informationDoesNotMatch: {
		id: 'payments.information_does_not_match',
		defaultMessage: 'Information does not match',
	},
});
