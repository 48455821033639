import {
	CardHistoryState,
	CardHistoryActionType,
	SetCardHistoryIsLoadingAction,
	SetCardHistoryDataAction,
	ResetCardHistoryDataAction,
} from './CardHistoryTypes';

const initialState: CardHistoryState = {
	pendingData: [],
	isLoading: true,
	data: [],
	totalCount: 0,
	pendingAuthorizationCount: 0,
};

export default (
	state = initialState,
	action: SetCardHistoryIsLoadingAction | SetCardHistoryDataAction | ResetCardHistoryDataAction
) => {
	switch (action.type) {
		case CardHistoryActionType.CARD_HISTORY_IS_LOADING:
			return {
				...state,
				isLoading: action.isLoading,
			};
		case CardHistoryActionType.RESET_CARD_DATA:
			return initialState;
		case CardHistoryActionType.SET_CARD_DATA:
			return {
				...state,
				data: action.data?.transactions,
				pendingData: action.data?.pendingAuthorizations,
				totalCount: action.data?.totalCount,
				pendingAuthorizationCount: action.data?.pendingAuthorizationCount,
			};
		default:
			return state;
	}
};
