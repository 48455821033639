import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RootState } from '../../redux/Store';
import Portfolio from '../../components/Portfolio/Portfolio';
import RatesBanner from '../../components/RatesBanner/RatesBanner';
import MarketingSlider from '../../components/MarketingSlider/MarketingSlider';
import RateGraphWidget from '../../components/RateGraphWidget/RateGraphWidget';
import styles from './WalletSidebar.module.scss';
import WalletTestIds from '../../test/Wallets/WalletTestIds';

const WalletSidebar = () => {
	const referenceCurrency = (Cookies.get('primaryCurrency') || CurrencyEnum.USD) as CurrencyEnum;
	const {
		showWalletSidebar,
		showCurrentRates,
		showCurrentRatesGraph,
		showMarketingSlider,
		isMobile,
		isTablet,
	} = useSelector((state: RootState) => state.AppState);
	if (showWalletSidebar)
		return (
			<div data-cy={WalletTestIds.Sidebar} className={styles.sidebar}>
				<Portfolio refCurrency={referenceCurrency} />
				{showCurrentRatesGraph && !isMobile && !isTablet && (
					<RateGraphWidget refCurrency={referenceCurrency} />
				)}
				{!isMobile && !isTablet && showMarketingSlider && <MarketingSlider />}
				{showCurrentRates && !isMobile && !isTablet && (
					<RatesBanner refCurrency={referenceCurrency} />
				)}
			</div>
		);
	return null;
};

export default WalletSidebar;
