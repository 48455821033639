import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RemoteData } from '../../../../interfaces/RemoteData';

export enum OrderTransactionStatus {
	FAILED = 'FAILED',
	PENDING = 'PENDING',
	CONFIRMED = 'CONFIRMED',
	CHARGED_BACK = 'CHARGED_BACK',
}

export type OrderTransaction = {
	id: string;
	merchantOrderId: string;
	status: OrderTransactionStatus;
	created: string;
	currencyCode: CurrencyEnum;
	depositValue: string;
	inputAddress: string;
	inputTransactionHash: string;
};

export type OrderTransactionsDataState = {
	transactions: Record<string, RemoteData<OrderTransaction>>;
};

export enum OrderTransactionsDataActionType {
	FETCH_TRANSACTION_PENDING = 'MERCHANTS/TRANSACTIONS/DATA_FETCH_TRANSACTION/PENDING',
	FETCH_TRANSACTION_REJECTED = 'MERCHANTS/TRANSACTIONS/DATA_FETCH_TRANSACTION/REJECTED',
	FETCH_TRANSACTION_FULFILLED = 'MERCHANTS/TRANSACTIONS/DATA_FETCH_TRANSACTION/FULFILLED',

	UPDATE_TRANSACTION = 'MERCHANTS/TRANSACTIONS/DATA/UPDATE_TRANSACTION',
	UPDATE_TRANSACTIONS = 'MERCHANTS/TRANSACTIONS/DATA/UPDATE_TRANSACTIONS',
}
