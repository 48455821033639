import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import Loader from '../../../components/Loader/Loader';
import depositMessages from '../../../redux/DepositState/DepositMessages';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { Wallet } from '../../../redux/AccountsState/AccountsTypes';
import SearchBar from '../../../components/SearchBar/SearchBar';
import ScrollableWalletList, {
	ListType,
} from '../../../components/ScrollableWalletList/ScrollableWalletList';
import styles from './CryptoDeposits.module.scss';
import Seo from '../../../components/Seo/Seo';
import TestIds from '../../../test/TestIds';

enum SortType {
	NAME = 'NAME',
	BALANCE = 'BALANCE',
}

interface CryptoDepositsProps {
	accounts: Wallet[];
}

const currenciesSortPriority = {
	[CurrencyEnum.BTC]: 5,
	[CurrencyEnum.ETH]: 4,
	[CurrencyEnum.USDT]: 3,
	[CurrencyEnum.USDC]: 2,
	[CurrencyEnum.XRP]: 1,
};

const CryptoDeposits = ({ accounts }: CryptoDepositsProps) => {
	const { locale } = useIntl();
	const [sortType, setSortType] = useState<SortType | null>(null);
	const [sortAsc, setSortAsc] = useState(true);
	const [nameFilter, setNameFilter] = useState('');

	const changeSortType = (type: SortType) => {
		setSortType(type);
		return setSortAsc(!sortAsc);
	};

	const handleSearchInputChange = (value: string) => {
		setNameFilter(value);
	};

	const filteredAccounts = useMemo(() => {
		return accounts
			.filter(
				(account: Wallet, index: number, accountList: Wallet[]) =>
					accountList.findIndex(
						(item: Wallet) => item.currencyCode === account.currencyCode
					) === index
			)
			.filter((account: Wallet) => {
				if (nameFilter) {
					return (
						account.currencyName.toLowerCase().includes(nameFilter.toLowerCase()) ||
						account.currencyCode.toLowerCase().includes(nameFilter.toLowerCase())
					);
				}
				return true;
			})
			.sort((a: Wallet, b: Wallet) => {
				if (sortType) {
					if (sortType === SortType.NAME) {
						if (sortAsc) return a.currencyCode > b.currencyCode ? 1 : -1;
						if (!sortAsc) return a.currencyCode < b.currencyCode ? 1 : -1;
					}
					if (sortType === SortType.BALANCE) {
						return sortAsc
							? (a.availableBalance as any) - (b.availableBalance as any)
							: (b.availableBalance as any) - (a.availableBalance as any);
					}
				}
				return (
					(currenciesSortPriority[b.currencyCode] || 0) -
						(currenciesSortPriority[a.currencyCode] || 0) ||
					a.currencyCode.localeCompare(b.currencyCode, undefined, {
						numeric: true,
						sensitivity: 'base',
					})
				);
			});
	}, [accounts, nameFilter, sortAsc, sortType]);

	if (!filteredAccounts) return <Loader className={styles.loader} />;
	return (
		<div data-cy={TestIds.cryptoDepositsView}>
			<Seo title={depositMessages.metaCryptoDepositsTitle} />
			<PageTitle
				historyLink={`/${locale}/deposit/history/crypto`}
				title={depositMessages.cryptocurrencyDeposits}
				previousPageLink={`/${locale}/deposit`}
				isPreviousPageLinkVisibleOnMobile={false}
			/>
			<SearchBar nameFilter={nameFilter} onChange={handleSearchInputChange} />
			<ScrollableWalletList
				changeSortType={changeSortType}
				list={filteredAccounts}
				type={ListType.DEPOSIT}
			/>
		</div>
	);
};

export default CryptoDeposits;
