import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import styles from './Message.module.scss';

export enum MessageType {
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
}

interface MessageProps {
	type: MessageType;
	className?: string;
}

const Message: FC<PropsWithChildren<MessageProps>> = ({ children, type, className }) => (
	<div className={classNames(styles[type], className)}>{children}</div>
);

export default Message;
