import { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import usePrevious from '../../../hooks/usePrevious';
import { getProfileURL } from '../../../redux/endpoints';
import toggleModal from '../../../redux/ModalState/ModalActions';
import { logout } from '../../../redux/ProfileState/ProfileActions';
import { RootState } from '../../../redux/Store';
import Button, { ButtonStyle, ButtonType } from '../../Button/Button';
import useEffectOnce from '../../../hooks/useEffectOnce';
import styles from './SessionExpirationModal.module.scss';
import axiosInstance from '../../../helpers/axiosInstance';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const animationData = require('./sc_sand_clock.json');

const oneSec = 1000;

const SessionExpirationModal = () => {
	const dispatch = useDispatch();
	const timer = useRef<number>();
	const element = useRef<HTMLDivElement>(null);
	const { TOGGLE_MODAL } = useSelector((state: RootState) => state.ModalState);
	const { idleTimer, isLoggedIn } = useSelector((state: RootState) => state.ProfileState);
	const [time, setTime] = useState(60);
	const previousIdle = usePrevious(idleTimer);

	const stay = () =>
		axiosInstance.get(`${getProfileURL()}/ping`).then(() => dispatch(toggleModal()));

	useEffectOnce(() => {
		lottie.loadAnimation({
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData,
			container: element.current!,
		});
	});

	useEffect(() => {
		timer.current = window.setInterval(() => {
			setTime(time > 0 ? time - 1 : 0);
		}, oneSec);

		return () => clearInterval(timer.current);
	}, [time]);

	useEffect(() => {
		if (idleTimer > previousIdle! && isLoggedIn) {
			if (TOGGLE_MODAL) dispatch(toggleModal());
		}
	}, [idleTimer, TOGGLE_MODAL, isLoggedIn, dispatch, previousIdle]);

	useEffect(() => {
		if (time === 0) {
			dispatch(logout());
		}
	}, [dispatch, time]);

	return (
		<div className={styles.container}>
			<h3 className={styles.title}>
				<FormattedMessage
					id="sessionExpirationModal.title"
					defaultMessage="Your session will expire due to inactivity…"
				/>
			</h3>
			<div ref={element} className={styles.timerImg} />
			<p className={styles.paragraph}>
				<FormattedMessage
					id="sessionExpirationModal.paragraph"
					defaultMessage={`Your session will expire in <span></span> seconds due to inactivity and you will be logged out. How would you like to proceed?`}
					values={{ span: () => <span className={styles.time}>{time}</span> }}
				/>
			</p>
			<Button
				buttonStyle={ButtonStyle.PRIMARY}
				type={ButtonType.BUTTON}
				onClick={() => stay()}
				className={styles.button}
			>
				<FormattedMessage id="sessionExpirationModal.continue" defaultMessage="Continue" />
			</Button>
			<a className={styles.logout} onClick={() => dispatch(logout())}>
				<FormattedMessage
					id="sessionExpirationModal.logoutNow"
					defaultMessage="Log out now"
				/>
			</a>
		</div>
	);
};

export default SessionExpirationModal;
