import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons/faLongArrowRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { CurrencyIcon } from '@spectrocoin/sc-currencies';
import classNames from 'classnames';
import { FC } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import CopyButton from '../../../../../components/CopyButton/CopyButton';
import {
	currencySymbols,
	formatPrecision,
} from '../../../../../helpers/currencyHelper/currencyHelper';
import { Payout } from '../../../../../redux/MerchantsState/CryptoPayouts/PayoutsData/PayoutsDataTypes';
import styles from './PayoutListItem.module.scss';
import useWindowWidth from '../../../../../hooks/useWindowWidth';
import messages from '../../../../../redux/MerchantsState/MerchantsMessages';

const PayoutListItem: FC<Payout> = ({
	payCurrencyCode,
	receiveCurrencyCode,
	status,
	payAmount,
	receiverAddress,
	id,
}) => {
	const { url } = useRouteMatch();
	const isMobile = useWindowWidth() <= 630;
	const { formatMessage } = useIntl();
	return (
		<li className={styles.item}>
			<div>
				{isMobile && <div>{formatMessage(messages.operation)}</div>}
				<span className={styles.assets}>
					<CurrencyIcon currencyType={payCurrencyCode} className={styles.icon} />
					<CurrencyIcon
						currencyType={receiveCurrencyCode}
						className={classNames(styles.icon, styles.margin)}
					/>
					{payCurrencyCode}
					<FontAwesomeIcon icon={faLongArrowRight} className={styles.arrow} />
					{receiveCurrencyCode}
				</span>
			</div>
			<div className={styles.grid}>
				{isMobile && <div>{formatMessage(messages.status)}</div>}
				<div className={classNames(styles.status, styles[status?.toUpperCase()])}>
					{status}
				</div>
			</div>
			<div className={styles.grid}>
				{isMobile && <div>{formatMessage(messages.addressAndNetwork)}</div>}
				<div>
					<div className={styles.addressContainer}>
						<div className={styles.address}>{receiverAddress}</div>
						<CopyButton dataToCopy={receiverAddress} className={styles.copy} />
					</div>
					{/* <div className={classNames(styles.status, styles.network)}>{network}</div> */}
				</div>
			</div>
			<div className={styles.grid}>
				{isMobile && <div>{formatMessage(messages.payAmount)}</div>}
				<div className={styles.amount}>
					{`${currencySymbols[payCurrencyCode]} ${formatPrecision(
						payAmount,
						payCurrencyCode
					)}`}
				</div>
				<Link to={`${url}/${id}`} className={styles.link}>
					<FontAwesomeIcon icon={faChevronRight} />
				</Link>
			</div>
		</li>
	);
};

export default PayoutListItem;
