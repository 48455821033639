import {
	ConvertHistoryState,
	ConvertHistoryActionType,
	SetHistoryIsLoadingAction,
	SetHistoryDataAction,
	SetInitialStateAction,
	UpdateOpenOrderAction,
	RemoveOpenOrderAction,
} from './ConvertHistoryTypes';

const initialState: ConvertHistoryState = {
	isLoading: true,
	data: [],
	totalCount: 0,
};

export default (
	state = initialState,
	action:
		| SetHistoryIsLoadingAction
		| SetHistoryDataAction
		| SetInitialStateAction
		| UpdateOpenOrderAction
		| RemoveOpenOrderAction
) => {
	switch (action.type) {
		case ConvertHistoryActionType.SET_DATA:
			return {
				...state,
				totalCount: action.totalCount,
				data: action.data,
			};
		case ConvertHistoryActionType.RESET_DATA:
			return initialState;
		case ConvertHistoryActionType.IS_LOADING:
			return {
				...state,
				isLoading: action.data,
			};
		case ConvertHistoryActionType.UPDATE_OPEN_ORDER:
			return {
				...state,
				data: [
					...state?.data!.filter((openOrder) => {
						if (openOrder.id === action.data.id) {
							// eslint-disable-next-line no-param-reassign
							openOrder.status = action.data.status;
						}
						return openOrder;
					}),
				],
			};
		case ConvertHistoryActionType.REMOVE_OPEN_ORDER:
			return {
				...state,
				data: [
					...state?.data!.filter((openOrder) => {
						return openOrder.id !== action.data.id;
					}),
				],
				totalCount: state.totalCount - 1,
			};
		default:
			return state;
	}
};
