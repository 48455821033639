import { defineMessages, MessageDescriptor } from 'react-intl';

interface Messages {
	[key: string]: MessageDescriptor;
}

const inputErrors: Messages = defineMessages({
	cannotBeEmpty: {
		id: 'inputErrors.cannotBeEmpty',
		defaultMessage: 'This field cannot be empty',
	},
	insufficientFundsWithDeposit: {
		id: 'inputErrors.insufficientFundsWithDeposit',
		defaultMessage: 'Insufficient funds. Deposit more <a>here</a>',
	},
	insufficientFunds: {
		id: 'inputErrors.insufficientFunds',
		defaultMessage: 'Insufficient funds',
	},
	amountTooLow: {
		id: 'inputErrors.amountTooLow',
		defaultMessage: 'Amount should be greater than zero',
	},
	x_lessOrEqual_y: {
		id: 'inputErrors.x_less_or_equal_y',
		defaultMessage: '"{Field}" length must be less than or equal to {Max} characters long',
	},
	x_lessAndNotMoreThen_y: {
		id: 'inputErrors.x_lessAndNotMoreThen_y',
		defaultMessage: '"{Field}" must be {Min} or {Max} characters long.',
	},
	x_mustBe_y_Length: {
		id: 'inputErrors.x_mustBe_y_Length',
		defaultMessage: '"{Field}" must be {Length} characters long.',
	},
	alphaNumericCharacters: {
		id: 'inputErrors.alphaNumericCharacters',
		defaultMessage: '"{Field}" must only contain alpha-numeric characters',
	},
	mustAddValidAddress: {
		id: 'inputErrors.mustAddValidAddress',
		defaultMessage: 'Must add valid home address',
	},
	latinLetters: {
		id: 'inputErrors.latinLetters',
		defaultMessage: 'We allow latin letters and / - ? : ( ) . , ‘ + space only',
	},
	invalidUrl: {
		id: 'inputErrors.invalidUrl',
		defaultMessage: 'Invalid URL',
	},
});

export default inputErrors;
