import { ReactNode } from 'react';
import { Route } from 'react-router-dom';

interface PrivateRouteProps {
	children: ReactNode;
	exact?: boolean;
	path: string | string[];
}

const PrivateRoute = ({ children, exact = false, path }: PrivateRouteProps) => (
	<Route
		exact={exact}
		path={typeof path === 'string' ? path : [...path]}
		render={() => {
			return children;
		}}
	/>
);

export default PrivateRoute;
