import { FC } from 'react';
import { DocumentItem } from '../../../../../redux/DocumentsHistoryState/DocumentsHistoryTypes';
import DocumentsItem from '../DocumentsItem/DocumentsItem';

const DocumentList: FC<{ data: DocumentItem[] }> = ({ data }) => (
	// eslint-disable-next-line react/jsx-no-useless-fragment
	<>
		{data?.length > 0 &&
			data.map(({ comment, date, id, isGdprDoc, name, size, type, viewed }: DocumentItem) => (
				<DocumentsItem
					key={id}
					comment={comment}
					date={date}
					id={id}
					isGdprDoc={isGdprDoc}
					name={name}
					size={size}
					type={type}
					viewed={viewed}
				/>
			))}
	</>
);

export default DocumentList;
