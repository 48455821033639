import classNames from 'classnames';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Link } from 'react-router-dom';
import styles from './ActionButton.module.scss';

export enum ButtonIcon {
	LOAD = 'LOAD',
	UNLOAD = 'UNLOAD',
	WITHDRAW = 'WITHDRAW',
	DEPOSIT = 'DEPOSIT',
	EXCHANGE = 'EXCHANGE',
	BUY = 'BUY',
}

interface ActionButtonProps {
	buttonIcon: ButtonIcon;
	isAnchor?: boolean;
	onClick?: () => void;
	link?: string;
	text?: MessageDescriptor;
	isDisabled?: boolean;
}

const ActionButton = ({
	link,
	text,
	buttonIcon,
	onClick,
	isAnchor = false,
	isDisabled = false,
	...props
}: ActionButtonProps) => {
	const renderButtonContent = () => (
		<>
			<div className={classNames(styles.icon, styles[buttonIcon])} />
			<FormattedMessage {...text} />
		</>
	);

	if (isAnchor || onClick)
		return (
			<a
				{...props}
				href={link}
				className={classNames(styles.button, {
					[styles.disabled]: isDisabled,
				})}
				onClick={onClick}
			>
				{renderButtonContent()}
			</a>
		);
	return (
		<Link
			{...props}
			to={link}
			className={classNames(styles.button, {
				[styles.disabled]: isDisabled,
			})}
		>
			{renderButtonContent()}
		</Link>
	);
};

export default ActionButton;
