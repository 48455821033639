import { defineMessages } from 'react-intl';
import { GetCurrentCard } from '../../../../helpers/cardsHelper/cardsHelper';
import HistoryBlock from '../../../../components/HistoryBlock/HistoryBlock';
import { HistoryType } from '../../../../hooks/useHistoryTableData';
import Seo from '../../../../components/Seo/Seo';

const messages = defineMessages({
	title: {
		id: 'cardsHistory.title',
		defaultMessage: 'Card History',
	},
	metaTitle: {
		id: 'cardsHistory.metaTitle',
		defaultMessage: 'Card History',
	},
});

const CardsHistory = () => {
	const card = GetCurrentCard();

	if (!card) return null;
	return (
		<>
			<Seo title={messages.metaTitle} />
			<HistoryBlock title={messages.title} id={card.id} historyType={HistoryType.CARD} />
		</>
	);
};

export default CardsHistory;
