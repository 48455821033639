import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import classNames from 'classnames';
import usePrevious from '../../../../hooks/usePrevious';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import Loader from '../../../../components/Loader/Loader';
import styles from './ConvertLiveRates.module.scss';
import { calcAmount } from '../../../../redux/ConvertState/ConvertActions';
import { CalcType } from '../../../../redux/ConvertState/ConvertTypes';
import convertMsg from '../../convertMsg';
import useFormatAmount from '../../../../hooks/useFormatAmount';

interface ConvertLiveRatesProps {
	payCurrency: CurrencyEnum | string;
	receiveCurrency: CurrencyEnum | string;
	pairId: string | null;
	isWide?: boolean;
}

const intervalTime = 60000;

const ConvertLiveRates = ({
	payCurrency,
	receiveCurrency,
	pairId,
	isWide = false,
}: ConvertLiveRatesProps) => {
	const { formatMessage } = useIntl();
	const amountFormatter = useFormatAmount();
	const timer = useRef<number>();
	const [rates, setRates] = useState<string>('');
	const prevReceiveCurrency = usePrevious(receiveCurrency);
	const prevPayCurrency = usePrevious(payCurrency);

	const getRatesDetails = useCallback(async (id: string, skipIdleCheck?: boolean) => {
		await calcAmount(CalcType.PAY, id, '1', skipIdleCheck).then(({ amount }) =>
			setRates(amount)
		);
	}, []);

	useEffect(() => {
		if (
			payCurrency &&
			receiveCurrency &&
			(payCurrency !== prevPayCurrency || receiveCurrency !== prevReceiveCurrency) &&
			pairId
		) {
			setRates('');
			void getRatesDetails(pairId);
		}
	}, [
		payCurrency,
		receiveCurrency,
		prevReceiveCurrency,
		getRatesDetails,
		prevPayCurrency,
		pairId,
	]);

	useEffect(() => {
		if (pairId) {
			timer.current = window.setInterval(() => getRatesDetails(pairId, true), intervalTime);
		}
		return () => clearInterval(timer.current);
	}, [getRatesDetails, pairId]);

	return (
		<div className={classNames(styles.liveRates, { [styles.wide]: isWide })}>
			<h6>{formatMessage(convertMsg.estimatedRate)}</h6>
			<div className={classNames(styles.flexRates, { [styles.wide]: isWide })}>
				<div className={styles.payRate}>1 {receiveCurrency} ≈</div>
				<div className={styles.receiveRate}>
					{rates ? (
						<>
							{amountFormatter(formatPrecision(rates, CurrencyEnum[payCurrency]))}{' '}
							{payCurrency}
						</>
					) : (
						<Loader className={styles.loader} />
					)}
				</div>
			</div>
		</div>
	);
};

export default ConvertLiveRates;
