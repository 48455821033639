import { defineMessages, MessageDescriptor } from 'react-intl';

interface Routes {
	[key: string]: MessageDescriptor;
}

const routes: Routes = defineMessages({
	card: {
		id: 'routes.card',
		defaultMessage: 'bitcoin-debit-card',
	},
	bugBounty: {
		id: 'routes.bugBounty',
		defaultMessage: 'bug-bounty',
	},
	rates: {
		id: 'routes.rates',
		defaultMessage: 'bitcoin-price-rates',
	},
	feesAndLimits: {
		id: 'routes.feesAndLimits',
		defaultMessage: 'fees-and-limits',
	},
	supportedCountries: {
		id: 'routes.supportedCountries',
		defaultMessage: 'countries',
	},
	referral: {
		id: 'routes.referral',
		defaultMessage: 'earn-bitcoins',
	},
	terms: {
		id: 'routes.terms',
		defaultMessage: 'terms',
	},
	referralTerms: {
		id: 'routes.referrals.terms',
		defaultMessage: 'terms/referralPolicy',
	},
	gtcTerms: {
		id: 'routes.gtcTerms',
		defaultMessage: 'terms/general-terms-and-conditions',
	},
	howToBuyBitcoins: {
		id: 'routes.faqs.howToBuyBitcoins',
		defaultMessage: 'how-to-buy-bitcoin',
	},
	whatIsBitcoin: {
		id: 'routes.faqs.whatIsBitcoin',
		defaultMessage: 'faqs/bitcoins/what-is-bitcoin',
	},
	faq: {
		id: 'routes.faq',
		defaultMessage: 'help',
	},
	contact: {
		id: 'routes.contact',
		defaultMessage: 'contact',
	},
	news: {
		id: 'routes.news',
		defaultMessage: 'news',
	},
	about: {
		id: 'routes.about',
		defaultMessage: 'about',
	},
	loans: {
		id: 'routes.loans',
		defaultMessage: 'crypto-loans',
	},
	personalIban: {
		id: 'routes.faqs.personalIban',
		defaultMessage: 'faqs/personal-iban',
	},
	orderProcess: {
		id: 'routes.faqs.informationAboutPrepaidCardDelivery',
		defaultMessage: 'faqs/prepaid-card/information-about-prepaid-card-delivery-process',
	},
	libraries: {
		id: 'routes.libraries',
		defaultMessage: 'integration/libraries',
	},
	plugins: {
		id: 'routes.plugins',
		defaultMessage: 'integration/plugins',
	},
	integrationApi: {
		id: 'routes.integrationApi',
		defaultMessage: 'integration/spectrocoin',
	},
	mediaKit: {
		id: 'routes.mediaKit',
		defaultMessage: 'media-kit',
	},
});

export default routes;
