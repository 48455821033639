import { values } from '../../../../helpers/objectHelper/objectHelper';
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import createSelector from '../../../createSelector';

const getState = createSelector((state) => state.MerchantState.paymentButtons.data);

const getPaymentButtonById = (id: string) =>
	createSelector(getState, (state) => state.paymentButtons[id] || { status: RemoteStatus.None });

const getPaymentButtonDataById = (id: string) =>
	createSelector(getPaymentButtonById(id), (state) => state.data);

const getPaymentButtonDataByIds = (ids: string[]) =>
	createSelector(getState, (state) =>
		ids
			.map((id) => state.paymentButtons[id])
			.filter((x) => !!x.data)
			.map((x) => x.data!)
	);

const getHasAny = createSelector(getState, (state) =>
	values(state.paymentButtons).some((x) => x.status === RemoteStatus.Done)
);

export default {
	getState,
	getHasAny,
	getPaymentButtonById,
	getPaymentButtonDataById,
	getPaymentButtonDataByIds,
};
