import { FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { useHistory } from 'react-router';
import Loading from '../../Shared/Loading/Loading';
import useMerchantRoutes, { MerchantRoutes } from '../../../../hooks/useMerchantRoutes';
import { RemoteStatus } from '../../../../interfaces/RemoteData';
import useDidMount from '../../../../hooks/useDidMount';
import debounceHelper from '../../../../helpers/debounceHelper/debounceHelper';
import TestIds from '../../../../test/TestIds';
import useQueryPagination from '../../../../hooks/useQueryPagination';
import {
	ProjectsListMode,
	ProjectsListStatus,
} from '../../../../redux/MerchantsState/MerchantTypes';
import NoProjects from '../../Shared/NotFound/NotFound';
import MobileProject from '../../Shared/MobileProject/MobileProject';
import Project from '../../Shared/Project/Project';
import { RootState } from '../../../../redux/Store';
import TagFilter, { Tag } from '../../../../components/TagFilter/TagFilter';
import Input from '../../../../components/Input/Input';
import Pagination from '../../../../components/Pagination/Pagination';
import MerchantsActions from '../../../../redux/MerchantsState/MerchantsActions';
import PageTitle from '../../Shared/PageTitle/PageTitle';
import MerchantsSelectors from '../../../../redux/MerchantsState/MerchantsSelectors';
import messages from '../../../../redux/MerchantsState/MerchantsMessages';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';

import styles from './MerchantsProjectList.module.scss';
import baseMsg from '../../../../messages/base.messages';

const ListHeader: FC = () => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const [_, setPage] = useQueryPagination();

	const { search = '', tags } = useSelector(MerchantsSelectors.projects.list.getFilter);
	const { isMobile } = useSelector((state: RootState) => state.AppState);

	const tagFilterOptions = useMemo<Tag[]>(
		() => [
			{ id: 'crypto', isActive: tags.crypto, label: formatMessage(messages.crypto) },
			{ id: 'fiat', isActive: tags.fiat, label: formatMessage(messages.fiat) },
			{ id: 'enabled', isActive: tags.enabled, label: formatMessage(messages.enabled) },
			{ id: 'disabled', isActive: tags.disabled, label: formatMessage(messages.disabled) },
		],
		[tags, formatMessage]
	);

	const onChange = useCallback(
		(value: string) => {
			setPage(1);
			dispatch(MerchantsActions.projects.list.setSearch(value));
		},
		[dispatch, setPage]
	);

	const onToggle = useCallback(
		(tag: Tag) => {
			setPage(1);
			dispatch(
				MerchantsActions.projects.list.setTags({
					...tags,
					[tag.id]: !tag.isActive,
				})
			);
		},
		[setPage, dispatch, tags]
	);

	return (
		<div className={styles.tableHeader}>
			<div className={styles.tableControls}>
				<Input
					data-cy={TestIds.ProjectListSearch}
					value={search}
					placeholder={baseMsg.search}
					faIcon={faSearch}
					onChange={onChange}
				/>
				{!isMobile && <TagFilter tags={tagFilterOptions} onToggle={onToggle} />}
			</div>
			{!isMobile && (
				<div className={styles.columns}>
					<span>
						<FormattedMessage {...messages.name} />
					</span>
					<span>
						<FormattedMessage {...messages.autoconvert} />
					</span>
					<span>
						<FormattedMessage {...messages.acceptedCurrencies} />
					</span>
					<span>
						<FormattedMessage {...messages.mode} />
					</span>
				</div>
			)}
		</div>
	);
};

const ListRow: FC<PropsWithChildren> = ({ children, ...rest }) => {
	const { isMobile } = useSelector((state: RootState) => state.AppState);
	return (
		<div
			className={classNames({
				[styles.mobileListRow]: isMobile,
				[styles.listRow]: !isMobile,
			})}
			{...rest}
		>
			{children}
		</div>
	);
};

const Content: FC = () => {
	const { formatMessage } = useIntl();
	const [page] = useQueryPagination();

	const { isMobile } = useSelector((state: RootState) => state.AppState);
	const projects = useSelector(MerchantsSelectors.projects.getListProjectsDataByPage(page - 1));

	return (
		<>
			{projects.length === 0 && (
				<NoProjects message={formatMessage(messages.emptyProjectTable)} />
			)}
			{projects.length !== 0 && (
				<div className={styles.listBody}>
					{projects.map((project) => (
						<ListRow key={project.id} data-cy={TestIds.ProjectListRow}>
							{isMobile ? (
								<MobileProject project={project} />
							) : (
								<Project project={project} />
							)}
						</ListRow>
					))}
				</div>
			)}
		</>
	);
};

const ProjectsList: FC = () => {
	const dispatch = useDispatch();

	const [queryPage, setPage] = useQueryPagination();
	const page = queryPage - 1;

	const { pageSize, totalCount, pages } = useSelector(MerchantsSelectors.projects.list.getState);
	const { status } = useSelector(MerchantsSelectors.projects.list.getProjectsByPage(page));
	const didMount = useDidMount();

	const {
		search,
		tags: { crypto, disabled, enabled, fiat },
	} = useSelector(MerchantsSelectors.projects.list.getFilter);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const throttledSearchUpdate = useCallback(
		debounceHelper(() => {
			void dispatch(MerchantsActions.projects.list.fetchProjects(page, true));
		}, 500),
		[]
	);

	useEffect(() => {
		if (didMount) throttledSearchUpdate(page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	useEffect(() => {
		if (status === RemoteStatus.None)
			void dispatch(MerchantsActions.projects.list.fetchProjects(page));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	useEffect(() => {
		if (didMount) void dispatch(MerchantsActions.projects.list.fetchProjects(page, true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [crypto, disabled, enabled, fiat]);

	useEffect(() => {
		if (status === RemoteStatus.Done && page > 0 && pages[page].data?.length === 0)
			setPage(page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	return (
		<>
			<div className={styles.projectsTable} data-cy={TestIds.ProjectListTable}>
				<ListHeader />
				{status === RemoteStatus.InProgress && <Loading />}
				{status === RemoteStatus.Done && <Content />}
			</div>
			{status === RemoteStatus.Done && (
				<Pagination totalCount={totalCount} pageSize={pageSize} />
			)}
		</>
	);
};

const RemoveProjects: FC = () => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const { mode } = useSelector(MerchantsSelectors.projects.list.getState);

	const actionText = (() => {
		switch (mode) {
			case ProjectsListMode.View:
				return formatMessage(messages.removeProject);
			case ProjectsListMode.Delete:
				return formatMessage(messages.cancel);
			default:
				return null;
		}
	})();

	const action = useCallback(() => {
		switch (mode) {
			case ProjectsListMode.View:
				return dispatch(MerchantsActions.projects.list.setMode(ProjectsListMode.Delete));
			case ProjectsListMode.Delete:
				return dispatch(MerchantsActions.projects.list.setMode(ProjectsListMode.View));
			default:
				return null;
		}
	}, [mode, dispatch]);

	// reset mode on cleanup
	useEffect(
		() => () => {
			dispatch(MerchantsActions.projects.list.setMode(ProjectsListMode.View));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<div data-cy={TestIds.ProjectListRemove} className={styles.removeProject} onClick={action}>
			{actionText}
		</div>
	);
};

const MerchantsProjectList: FC = () => {
	const { formatMessage } = useIntl();
	const { push } = useHistory();
	const { getUrl } = useMerchantRoutes();

	const { status } = useSelector(MerchantsSelectors.projects.list.getState);

	const content = useMemo(() => {
		switch (status) {
			case ProjectsListStatus.Empty:
				return <NoProjects message={formatMessage(messages.noProjects)} />;
			case ProjectsListStatus.Loading:
				return <Loading />;
			case ProjectsListStatus.Ready:
				return <ProjectsList />;
			default:
				return null;
		}
	}, [formatMessage, status]);

	useEffect(() => {
		if (status === ProjectsListStatus.Empty) push(getUrl(MerchantRoutes.ProjectCreate));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	return (
		<WhiteContainer className={styles.container}>
			<PageTitle title={formatMessage(messages.allProjects)}>
				<RemoveProjects />
			</PageTitle>
			{content}
		</WhiteContainer>
	);
};

export default MerchantsProjectList;
