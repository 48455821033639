import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { AxiosResponse } from 'axios';
import queryString, { ParsedQuery } from 'query-string';
import classNames from 'classnames';
import FeeSetConstructor from '../../Shared/FeeSetConstructor/FeeSetConstructor';
import { getEWalletURL } from '../../../../redux/endpoints';
import debounceHelper from '../../../../helpers/debounceHelper/debounceHelper';
import { toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import { RootState } from '../../../../redux/Store';
import {
	EWalletSuccessResponse,
	PaymentMethod,
	ProviderType,
	WithdrawType,
} from '../../../../redux/WithdrawState/WithdrawTypes';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Input from '../../../../components/Input/Input';
import Button, { ButtonType } from '../../../../components/Button/Button';
import WithdrawFormContainer, {
	WithdrawSteps,
} from '../../Shared/WithdrawFormContainer/WithdrawFormContainer';
import CustomInput from '../../Shared/CustomInput/CustomInput';
import WithdrawSuccess from '../../Shared/WithdrawSuccess/WithdrawSuccess';
import WithdrawConfirm from '../../Shared/WithdrawConfirm/WithdrawConfirm';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import styles from './EWalletWithdrawForm.module.scss';
import { toPlainAmount } from '../../../../helpers/currencyAmountHelper/currencyAmountHelper';
import baseMsg from '../../../../messages/base.messages';
import AccountSelect from '../../Shared/AccountSelect/AccountSelect';
import Seo from '../../../../components/Seo/Seo';
import useEWalletFormValidator from './useEWalletFormValidator';
import useValidation from '../../../../hooks/useValidation';
import TransfersFormLayout from '../../../../layout/TransfersFormLayout/TransfersFormLayout';
import { HowToType } from '../../../../layout/TransfersFormLayout/HowTo/HowTo';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	title: {
		id: 'eWalletWithdrawForm.title',
		defaultMessage: 'Withdraw to {providerType}',
	},
	next: {
		id: 'eWalletWithdrawForm.next',
		defaultMessage: 'Next',
	},
	note: {
		id: 'cryptoWithdrawForm.note',
		defaultMessage: 'Note',
	},
	notePlaceholder: {
		id: 'cryptoWithdrawForm.notePlaceholder',
		defaultMessage: 'Add some notes to your transaction',
	},
});

interface FormProps {
	currency: string;
	email: string;
	amount: string;
	note: string;
}

interface FormErrorsProps {
	currency: MessageDescriptor | null | string;
	email: MessageDescriptor | null | string;
	amount: MessageDescriptor | null | string;
	note: MessageDescriptor | null | string;
}

const EWalletWithdrawForm = () => {
	const { id } = useParams<{ id: string }>();
	const { locale, formatMessage } = useIntl();
	const { push } = useHistory();
	const { pathname, search } = useLocation();
	const parsedQuery: ParsedQuery<string> = queryString.parse(search);
	const { step } = parsedQuery;
	const [successData, setSuccessData] = useState<EWalletSuccessResponse[]>([]);
	const [form, setForm] = useState<FormProps>({
		currency: '',
		email: '',
		amount: '',
		note: '',
	});
	const [formErrors, setFormErrors] = useState<FormErrorsProps>({
		currency: null,
		email: null,
		amount: null,
		note: null,
	});
	const [totalAmount, setTotalAmount] = useState<string>('0');
	const [balance, setBalance] = useState<string>('0');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [currentMethod, setCurrentMethod] = useState<PaymentMethod | null>(null);
	const [useAllValue, setUseAllValue] = useState<string>('0');
	const { paymentMethods } = useSelector((state: RootState) => state.WithdrawState);
	const { ewallet } = paymentMethods;
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const [providerType, setProviderType] = useState<ProviderType>();

	const validator = useEWalletFormValidator(
		form.currency,
		currentMethod!,
		providerType!,
		balance
	);

	const [isValid, validationResult] = useValidation(
		{
			currency: form.currency,
			email: form.email,
			amount: toPlainAmount(form.amount, true),
		},
		validator
	);

	const onSuccess = (data: EWalletSuccessResponse) => setSuccessData([data]);

	const debounceInputChange = debounceHelper(
		(value: string, inputId: string) =>
			onChange({ target: { value, name: inputId } } as ChangeEvent<HTMLInputElement>),
		1000
	);

	const onChange = useCallback(
		({ target: { value, name } }: ChangeEvent<HTMLInputElement>) => {
			setFormErrors({
				...formErrors,
				[name]: null,
			});
			setForm({
				...form,
				[name]: value,
			});
		},
		[form, formErrors]
	);

	const calc = useCallback(() => {
		setIsLoading(true);
		return axiosInstance.post(getEWalletURL(providerType!, true), {
			paymentMethodId: id,
			receiveAmount: toPlainAmount(form.amount, true),
			withdrawAccountId: form.currency.split('/')[2],
		});
	}, [providerType, form.amount, form.currency, id]);

	const getIsFormValid = useCallback(() => {
		setFormErrors({ ...formErrors, ...validationResult });
		return isValid;
	}, [formErrors, isValid, validationResult]);

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!getIsFormValid()) return null;
		return calc()
			.then(() => push({ pathname, search: `?step=${WithdrawSteps.CONFIRM}` }))
			.catch(() => null)
			.then(() => setIsLoading(false));
	};

	const postData = () => {
		const { note, email, amount, currency } = form;
		return axiosInstance.post(getEWalletURL(providerType!), {
			...(providerType === ProviderType.ADV_CASH && { note }),
			paymentMethodId: id,
			receiveAmount: toPlainAmount(amount, true),
			recipient: email,
			withdrawAccountId: currency.split('/')[2],
		}) as Promise<EWalletSuccessResponse>;
	};

	// set currentMethod
	useEffect(() => {
		if (ewallet?.data?.length > 0 && !currentMethod) {
			setCurrentMethod(ewallet?.data.filter((o: PaymentMethod) => o.id === id)[0]);
		}
	}, [currentMethod, ewallet?.data, ewallet?.data?.length, id]);

	// set available balance
	useEffect(() => {
		if (form.currency)
			setBalance(
				wallets?.find(({ id: walletId }) => walletId === form.currency.split('/')[2])
					?.availableBalance || '0'
			);
	}, [form.currency, wallets]);

	// set total amount
	useEffect(() => {
		if (currentMethod && form.currency && form.amount) {
			void calc()
				.then(({ data }: AxiosResponse<{ payAmount: string }>) => {
					setTotalAmount(data.payAmount);
				})
				.catch(() => null)
				.then(() => setIsLoading(false));
		}
	}, [calc, currentMethod, form.currency, form.amount]);

	// minus out fee from balance
	useEffect(() => {
		if (balance && currentMethod) {
			const feeObj = currentMethod.activePaymentMethodFees?.find(
				({ currencyType }) => currencyType === form.currency.split('/')[0]
			);
			let feeSet: { [key: string]: string | null };
			if (feeObj) {
				feeSet = FeeSetConstructor(feeObj.fee, feeObj.minFee, feeObj.maxFee);
			} else {
				feeSet = FeeSetConstructor(
					currentMethod?.fee,
					currentMethod.minFee,
					currentMethod.maxFee
				);
			}
			const { fee, minFee, maxFee } = feeSet;
			const feeAmount = toDecimal(balance)
				.times(fee || 0)
				.dividedBy(100);
			const feeForCalc =
				minFee && feeAmount.lessThan(toDecimal(minFee))
					? minFee
					: maxFee && feeAmount.greaterThan(toDecimal(maxFee))
					? maxFee
					: feeAmount || 0;
			const allAmount = toDecimal(balance).minus(feeForCalc);
			setUseAllValue(allAmount.greaterThan(0) ? allAmount.toFixed(2).toString() : '0');
		}
	}, [balance, currentMethod, form.amount, form.currency]);

	// set provider type
	useEffect(() => {
		if (currentMethod) setProviderType(currentMethod.providerType);
	}, [currentMethod]);

	useEffect(() => {
		if (!step) {
			setSuccessData([]);
			setForm({
				currency: '',
				email: '',
				amount: '',
				note: '',
			});
		}
		setIsLoading(false);
	}, [step]);

	useEffect(() => {
		if (!form.amount) setTotalAmount('0');
	}, [form.amount]);

	return (
		<>
			<Seo currency={currentMethod?.title} title={withdrawMessages.metaSingleCryptoTitle} />
			<WithdrawFormContainer
				walletId={form.currency.split('/')[2]}
				isLoaded={!!currentMethod}
				title={
					<PageTitle
						title={formatMessage(messages.title, {
							providerType: currentMethod?.title,
						})}
						previousPageLink={
							step === WithdrawSteps.CONFIRM
								? `/${locale}/withdraw/e-wallet/${id}?step=${WithdrawSteps.PREPARE}`
								: `/${locale}/withdraw/e-wallet`
						}
						isPreviousPageLinkVisibleOnDesktop={step !== WithdrawSteps.DONE}
						isPreviousPageLinkVisibleOnMobile={step !== WithdrawSteps.DONE}
					/>
				}
				form={
					<TransfersFormLayout
						method={currentMethod}
						type={HowToType.WITHDRAW}
						title={currentMethod?.title}
						providerType={currentMethod?.providerType}
					>
						<form
							onSubmit={handleSubmit}
							className={classNames(styles.column, styles.form)}
						>
							<AccountSelect
								value={form.currency}
								label={withdrawMessages.withdrawalWallet}
								onChange={(currency: string) => {
									setFormErrors({
										...formErrors,
										currency: null,
									});
									setForm({
										...form,
										currency,
									});
								}}
								errorMessage={formErrors.currency}
								type={WithdrawType.EWALLET}
								paymentCurrencies={currentMethod?.paymentMethodCurrencies}
							/>
							<Input
								name="email"
								id="email"
								value={form.email}
								labelClassName={styles.label}
								label={
									providerType === ProviderType.SKRILL
										? formatMessage(withdrawMessages.emailLabel, {
												providerType: currentMethod?.title,
										  })
										: providerType === ProviderType.ADV_CASH
										? formatMessage(withdrawMessages.emailOrAccountLabel, {
												providerType: currentMethod?.title,
										  })
										: formatMessage(withdrawMessages.ewalletAccountLabel, {
												providerType: currentMethod?.title,
										  })
								}
								onChangeEvent={onChange}
								placeholder={
									providerType === ProviderType.SKRILL
										? formatMessage(withdrawMessages.emailPlaceholder, {
												providerType: currentMethod?.title,
										  })
										: providerType === ProviderType.ADV_CASH
										? formatMessage(
												withdrawMessages.emailOrAccountPlaceholder,
												{
													providerType: currentMethod?.title,
												}
										  )
										: formatMessage(
												withdrawMessages.ewalletAccountPlaceholder,
												{
													providerType: currentMethod?.title,
												}
										  )
								}
								errorMessage={formErrors.email}
							/>
							<CustomInput
								value={form.amount}
								label={baseMsg.receiveAmount}
								currency={CurrencyEnum[form.currency.split('/')[0]]}
								onChange={({
									target: { value, id: inputId },
								}: ChangeEvent<HTMLInputElement>) =>
									debounceInputChange(value, inputId)
								}
								id="amount"
								errorMessage={formErrors.amount}
								isLoading={isLoading}
								maxValue={useAllValue}
							/>
							<InfoInput
								label={baseMsg.totalAmountWithFee}
								balance={totalAmount}
								currency={CurrencyEnum[form.currency.split('/')[0]]}
							/>
							{providerType === ProviderType.ADV_CASH && (
								<Input
									name="note"
									id="note"
									value={form.note}
									labelClassName={styles.label}
									label={formatMessage(messages.note)}
									onChangeEvent={onChange}
									placeholder={messages.notePlaceholder}
									errorMessage={formErrors.note}
								/>
							)}
							<Button
								text={messages.next}
								type={ButtonType.SUBMIT}
								className={styles.button}
								isLoading={isLoading}
								isDisabled={isLoading}
							/>
						</form>
					</TransfersFormLayout>
				}
				confirm={
					<WithdrawConfirm
						type={WithdrawType.EWALLET}
						currency={CurrencyEnum[form.currency.split('/')[0]]}
						formData={[{ ...form, totalAmount, title: currentMethod?.title }]}
						onSuccess={onSuccess}
						onSubmit={postData}
						provider={providerType}
						providerTitle={currentMethod?.title}
					/>
				}
				done={
					<WithdrawSuccess
						data={successData}
						currency={CurrencyEnum[form.currency.split('/')[0]]}
						type={WithdrawType.EWALLET}
						provider={currentMethod?.title}
						providerTitle={currentMethod?.title}
					/>
				}
				isDone={successData.length > 0}
			/>
		</>
	);
};

export default EWalletWithdrawForm;
