import queryString from 'query-string';
import { CurrencyEnum, NetworkEnum } from '@spectrocoin/sc-currencies';
import FunctionalityAvailableType from '../enums/FunctionalityAvailableEnum';
import { ExportType } from './WalletHistoryState/WalletHistoryTypes';
import { CalcType, ConvertStatusType } from './ConvertState/ConvertTypes';
import { CardType, LockType } from './CardsState/CardsTypes';
import { HistoryType } from '../hooks/useHistoryTableData';
import { ProviderType } from './WithdrawState/WithdrawTypes';
import PublicSetting from '../enums/PublicSetting';
import { BTCAddressType } from './AccountsState/AccountsTypes';
import config from '../configs/config';

export const getBaseURL = () => {
	if (process.env.REACT_APP_ENV === 'local') return 'http://spectrocoin.local:9999';
	if (process.env.NODE_ENV === 'test') return '';
	return window.location.origin;
};

export const getGIPURL = () => config.GIP_URL;

const URLPrefix = '/api/fe';

export const getProfileURL = () => `${getBaseURL()}${URLPrefix}/profile`;

export const getPsProfileURL = () => `${getBaseURL()}${URLPrefix}/ps-profile`;

export const getPsProfileAccountsURL = () => `${getBaseURL()}${URLPrefix}/ps-profile/available`;

export const getAccountsURL = () => `${getBaseURL()}${URLPrefix}/accounts`;

export const getRatesURL = () => `${getBaseURL()}${URLPrefix}/currency/active`;

export const getCurrencyConvertURL = () => `${getBaseURL()}${URLPrefix}/exchange/calculate`;

export const getRatesDetailsURL = (
	baseCurrency: CurrencyEnum | string,
	quoteCurrency: CurrencyEnum | null = null
) =>
	`${getBaseURL()}${URLPrefix}/currency/${baseCurrency}/details?size=100${
		quoteCurrency ? `&currencyCode=${quoteCurrency}` : ''
	}`;

export const getRatesHistoryURL = (
	currencyCode: CurrencyEnum | string,
	refCurrency: CurrencyEnum | string,
	dateFrom: string,
	dateTo: string
) =>
	`${getBaseURL()}${URLPrefix}/currency/history?primaryCurrency=${currencyCode}&secondaryCurrency=${refCurrency}&dateFrom=${dateFrom}&dateTo=${dateTo}`;

export const getIsFunctionalityAvailableURL = (type: FunctionalityAvailableType) =>
	`${getBaseURL()}${URLPrefix}/user/available-features?functionalityType=${type}`;

export const getClaimIBANURL = () => `${getBaseURL()}${URLPrefix}/iban/claim`;

export const getConfirmDataTransferURL = () =>
	`${getBaseURL()}${URLPrefix}/contract/confirm-data-transfer`;

export const getLogoutURL = () => `${getBaseURL()}${URLPrefix}/proxy/logout`;

export const getUserLoginURL = () => `${getBaseURL()}${URLPrefix}/user/login`;
export const getUserLoginStatusURL = () => `${getBaseURL()}${URLPrefix}/user/login/status`;

export const getAccountHistoryURL = (
	id: string,
	startDate?: string,
	endDate?: string,
	page?: number
) => {
	const updatedQuery = queryString.stringify({
		...(startDate && { from: startDate }),
		...(endDate && { to: endDate }),
		page: page ? page - 1 : 0,
		size: 10,
	});
	return `${getBaseURL()}${URLPrefix}/accounts/history?id=${id}&${updatedQuery}`;
};

export const getAccountHistoryExportURL = (
	type: ExportType,
	id: string,
	generationTime: string,
	from?: string,
	to?: string
) => {
	const updatedQuery = queryString.stringify({
		generationTime,
		...(from && { from }),
		...(to && { to }),
	});
	return `${getBaseURL()}${URLPrefix}/accounts/history/${type}?id=${id}&${updatedQuery}`;
};

export const getAccountTypesURL = () => `${getBaseURL()}${URLPrefix}/accounts/types`;

export const getAddNewAccountURL = () => `${getBaseURL()}${URLPrefix}/accounts`;

export const getAccountByIdURL = (id: string) => `${getBaseURL()}${URLPrefix}/accounts/${id}`;

export const getCompanyURL = () => `${getBaseURL()}${URLPrefix}/users/company`;

export const getHideIfEmptyURL = (value: boolean) =>
	`${getBaseURL()}${URLPrefix}/accounts/update-hide-if-empty?hideIfEmpty=${value}`;

export const getHiddenBalanceURL = (value: boolean) =>
	`${getBaseURL()}${URLPrefix}/accounts/hide-balance?hideBalance=${value}`;

export const getNotificationsURL = () => `${getBaseURL()}${URLPrefix}/notifications`;
export const getNotificationsMarkURL = () => `${getBaseURL()}${URLPrefix}/notifications/read`;

export const getSignUpContractURL = (country: string) =>
	`${getBaseURL()}${URLPrefix}/signup-contracts/${country}`;

export const getUnsignedContractsURL = () => `${getBaseURL()}${URLPrefix}/user-contracts/unsigned`;

export const getSignContractsURL = () => `${getBaseURL()}${URLPrefix}/user-contracts/sign`;

export const getRestrictedCountriesURL = () =>
	`${getBaseURL()}${URLPrefix}/uaa/api/restricted-countries/details`;

export const getSecretQuestionsURL = () => `${getBaseURL()}${URLPrefix}/profile/secret-questions`;

export const getSecretQuestionSetupURL = () => `${getBaseURL()}${URLPrefix}/profile/user-secrets`;

export const getProfileVerificationStatusURL = () =>
	`${getBaseURL()}${URLPrefix}/profile/verification/status`;

export const getConvertPairsURL = () => `${getBaseURL()}${URLPrefix}/exchange/pairs`;

export const getCalcURL = (type: CalcType) => {
	const url = type === CalcType.PAY ? 'calculate-pay-amount' : 'calculate-receive-amount';
	return `${getBaseURL()}${URLPrefix}/exchange/${url}`;
};

export const getExchangeURL = (page?: number, createdFrom?: string, createdTo?: string) => {
	const updatedQuery = queryString.stringify({
		...(page && { page: page ? page - 1 : 0 }),
		...(createdFrom && { createdFrom }),
		...(createdTo && { createdTo }),
		...(page && { size: 10 }),
	});
	return `${getBaseURL()}${URLPrefix}/exchange${
		page || createdFrom || createdTo ? `?${updatedQuery}` : ''
	}`;
};

export const getUnreadNotificationCountURL = () =>
	`${getBaseURL()}${URLPrefix}/notifications/unread-count`;

export const getReferralCodeURL = () => `${getBaseURL()}${URLPrefix}/referrals`;

export const getReferralProfileSummaryTotalsUrl = () =>
	`${getBaseURL()}${URLPrefix}/referrals/rewards/totals`;

export const getReferralProfileSummaryCommissionsUrl = () =>
	`${getBaseURL()}${URLPrefix}/referrals/rewards/commissions`;

export const getReferralDefaultCodeUrl = () =>
	`${getBaseURL()}${URLPrefix}/referrals/codes/default`;

export const getReferralCallbacksUrl = () => `${getBaseURL()}${URLPrefix}/referrals/callbacks`;
export const getReferralCallbacksURLUrl = () =>
	`${getBaseURL()}${URLPrefix}/referrals/callbacks/url`;
export const getReferralCodessUrl = () => `${getBaseURL()}${URLPrefix}/referrals/codes`;

export const getReferralInviteUrl = () => `${getBaseURL()}${URLPrefix}/referrals/invite`;

export const getReferralCodesLimitUrl = () => `${getBaseURL()}${URLPrefix}/referrals/codes/limit`;

export const getReferralConsentUrl = () => `${getBaseURL()}${URLPrefix}/consents/referral`;

export const getReferralConsentAcceptUrl = () =>
	`${getBaseURL()}${URLPrefix}/consents/referral/accept`;

export const getSetPlayUserCacheURL = () => `${getBaseURL()}${URLPrefix}/user-cache`;

export const getRemovePlayUserCacheURL = () => `${getBaseURL()}${URLPrefix}/remove-user-cache`;

export const getGiveCookieConsentURL = () => `${getBaseURL()}${URLPrefix}/consents/cookie`;

export const getCookieRevokeURL = () => `${getBaseURL()}${URLPrefix}/consents/cookie/revoke`;

export const getIsCardUserURL = () => `${getBaseURL()}${URLPrefix}/debit-cards/is-card-user`;

export const getCardProductsURL = () => `${getBaseURL()}${URLPrefix}/debit-cards/order/products`;

export const getShippingOptionsURL = (rha?: boolean) => {
	const shippingOptionsURL = `${getBaseURL()}${URLPrefix}/debit-cards/order/delivery-options`;
	return rha ? `${shippingOptionsURL}?rha=${rha}` : shippingOptionsURL;
};

export const getCardContractURL = () => `${getBaseURL()}${URLPrefix}/contract/confirm-card-terms`;

export const getDebitCardsURL = (id: string | null = null) => {
	return `${getBaseURL()}${URLPrefix}/debit-cards${id ? `/${id}` : ''}`;
};

export const getOrderDebitCardURL = () => `${getBaseURL()}${URLPrefix}/debit-cards/order`;

export const getDebitCardOrderIdURL = (orderId: string) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/order/${orderId}`;

export const getDebitCardGetPinURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/${id}/set-pin-token`;

export const getDebitCardUpdateURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/${id}/update-card`;

export const getGIPSetPinCodeURL = (id: string) => `${getGIPURL()}/cards/${id}/pin`;

export const getDebitCardSecurityParamsURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/${id}/security-parameters`;

export const getDebitCardHistoryByIDURL = (
	id: string,
	isContis: boolean,
	startDate?: string,
	endDate?: string,
	page?: number
) => {
	const contisBlock = {
		filters: [
			'EXCEPT_INACTIVE_BLOCKS',
			'EXCEPT_ZERO_FEES',
			'EXCEPT_DECLINED_CARD_TRANSACTIONS',
			'EXCEPT_CARD_ACCOUNT_VERIFICATION_TYPE',
			'EXCEPT_INITIATED',
		],
		accountNumber: 'LTXXXX',
		accountCurrency: 'EUR',
	};

	const updatedQuery = queryString.stringify({
		cardId: id,
		...(startDate && { from: startDate }),
		...(endDate && { to: endDate }),
		...(!isContis && contisBlock),
		page: page || 1,
		pageSize: 10,
	});
	return `${getBaseURL()}${URLPrefix}/debit-cards/history?${updatedQuery}`;
};

export const getUnlockCardURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/${id}/unlock`;

export const get3dsPasswordTokenURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/${id}/set-3ds-password-token`;

export const getInitCardPinURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/${id}/pin/init`;

export const getLockCardURL = (id: string, type: LockType) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/${id}/lock?lockType=${type}`;

export const getSet3dsPasswordTokenURL = (cardContractNumber: string) =>
	`${getGIPURL()}/cards/${cardContractNumber}/3ds/password`;

export const getPinTokenURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/${id}/get-pin-token`;

export const getPinURL = (cardContractNumber: string) =>
	`${getGIPURL()}/cards/${cardContractNumber}/pin`;

export const getCardDetailsInitURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/${id}/details/init`;

export const getCardDetailsTokenURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/${id}/get-card-details-token`;

export const getCardDetailsURL = (cardContractNumber: string) =>
	`${getGIPURL()}/cards/${cardContractNumber}/details`;

export const getCardActivationTokenURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/${id}/activation-token`;

export const getCardActivationURL = (cardContractNumber: string) =>
	`${getGIPURL()}/cards/${cardContractNumber}/activation/user`;

export const getDebitCardLoadURL = (id: string, is2FARequired: boolean) =>
	`${getBaseURL()}${URLPrefix}/${
		is2FARequired ? 'debit-load' : 'debit-cards'
	}/${id}/load?confirm=true`;

export const getDebitCardLoadStatusURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/loads/${id}`;

export const getDebitCardUnloadURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/${id}/unload`;

export const getDebitCardUnloadAuthURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/unloads/${id}/authorize`;

export const getDebitCardUnloadStatusURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/unloads/${id}`;

export const getDebitCardHistoryPDFURL = (id: string, from?: string, to?: string) => {
	const updatedQuery = queryString.stringify({
		...(from && { from }),
		...(to && { to }),
	});
	return `${getBaseURL()}${URLPrefix}/debit-cards/history/pdf?cardId=${id}${
		from || to ? `&${updatedQuery}` : ''
	}`;
};

export const getOldDebitCardActivateURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/${id}/activate`;

export const getOldDebitCardPinURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/${id}/pin`;

export const getDeliveryCountriesListURL = (type: CardType) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/order/delivery-countries?cardType=${type}`;

export const getDebitCardLinkedAccountsURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/debit-cards/${id}/linked-accounts`;

export const getCountriesURL = () => `${URLPrefix}/settings/supported-countries`;
export const getPaymentMethodsURL = () => `${getBaseURL()}${URLPrefix}/payments/methods`;
export const getPaymentMethodsDetailsURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/payments/methods/${id}`;

export const getDepositsHistoryURL = (
	startDate?: string,
	endDate?: string,
	page?: number,
	filter?: CurrencyEnum
) => {
	const updatedQuery = queryString.stringify({
		...(startDate && { createdFrom: startDate }),
		...(endDate && { createdTo: endDate }),
		page: page ? page - 1 : 0,
		currencyCode: filter,
		size: 10,
	});
	return `${getBaseURL()}${URLPrefix}/deposits/history?${updatedQuery}`;
};

export const getDepositsFiatHistoryURL = (startDate?: string, endDate?: string, page?: number) => {
	const updatedQuery = queryString.stringify({
		...(startDate && { from: startDate }),
		...(endDate && { to: endDate }),
		page: page ? page - 1 : 0,
		size: 10,
	});
	return `${getBaseURL()}${URLPrefix}/deposits/history/fiat?${updatedQuery}`;
};

export const getDepositHistoryExportURL = (
	type: ExportType,
	generationTime: string,
	from?: string,
	to?: string,
	currency?: string
) => {
	const updatedQuery = queryString.stringify({
		generationTime,
		...(currency && { currency }),
		...(from && { from }),
		...(to && { to }),
	});
	return `${getBaseURL()}${URLPrefix}/deposits/history/${type}?${updatedQuery}`;
};
export const depositCalculateReceiveAmountURL = () =>
	`${getBaseURL()}${URLPrefix}/deposits/calculate/receive`;

export const getInitPhoneVerificationURL = () =>
	`${getBaseURL()}${URLPrefix}/profile/verification/phone/init`;
export const getUpdatePhoneNumberURL = () => `${getBaseURL()}${URLPrefix}/profile/phone-number`;
export const getFinalizePhoneVerificationURL = () =>
	`${getBaseURL()}${URLPrefix}/profile/verification/phone/finalize`;
export const uploadPOAFileURL = () =>
	`${getBaseURL()}${URLPrefix}/verifications/upload-docs/address-verification`;
export const getIsNonEEAVirtualFeatureEnabledURL = () =>
	`${getBaseURL()}${URLPrefix}/profile/verification/address/enabled-non-eea`;
export const getPOAStatusURL = () => `${getBaseURL()}${URLPrefix}/profile/verification/address`;
export const getInitPOAVerificationURL = () =>
	`${getBaseURL()}${URLPrefix}/profile/verification/address/init`;
export const getFinalizePOAVerificationURL = () =>
	`${getBaseURL()}${URLPrefix}/profile/verification/address/finalize`;
export const depositsURL = () => `${getBaseURL()}${URLPrefix}/deposits`;
export const googlePayDepositURL = () => `${getBaseURL()}${URLPrefix}/deposits/google-pay`;
export const applePayDepositURL = () => `${getBaseURL()}${URLPrefix}/deposits/apple-pay`;
export const skrillDepositURL = () => `${getBaseURL()}${URLPrefix}/deposits/skrill`;
export const advCashDepositURL = () => `${getBaseURL()}${URLPrefix}/deposits/adv-cash`;
export const netellerDepositURL = () => `${getBaseURL()}${URLPrefix}/deposits/neteller`;
export const perfectMoneyDepositURL = () => `${getBaseURL()}${URLPrefix}/deposits/perfect-money`;
export const payeerDepositURL = () => `${getBaseURL()}${URLPrefix}/deposits/payeer`;
export const getBankDescriptionURL = (orderId: string, lang: string) =>
	`${getBaseURL()}${URLPrefix}/deposits/${orderId}/bank-description?language=${lang}`;
export const bankDepositURL = () => `${getBaseURL()}${URLPrefix}/deposits/bank`;
export const getPaymentMethodDetailsURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/payments/methods/${id}`;
export const getPaymentMethodAdditionalInfoURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/payments/methods/${id}/additional-info`;

export const depositVoucherURL = () => `${getBaseURL()}${URLPrefix}/deposits/voucher`;
export const getWithdrawAvailableCryptosURL = () =>
	`${getBaseURL()}${URLPrefix}/withdraws/crypto/available-currencies`;
export const createWithdrawCryptosURL = () => `${getBaseURL()}${URLPrefix}/withdraws/crypto`;

export const getValidateWithdrawAddressURL = () =>
	`${getBaseURL()}${URLPrefix}/withdraws/crypto/validate-address`;

export const getEstimateFeeURL = () => `${getBaseURL()}${URLPrefix}/withdraws/crypto/estimate-fee`;

export const getWithdrawHistoryURL = (
	historyType: HistoryType,
	currencyCode?: CurrencyEnum,
	startDate?: string,
	endDate?: string,
	page?: number,
	isPurchase?: boolean,
	type?: ProviderType
) => {
	const urlMap = {
		[HistoryType.CRYPTO_WITHDRAW]: 'crypto',
		[HistoryType.VOUCHERS_WITHDRAW]: 'vouchers',
		[HistoryType.BANK_WITHDRAW]: 'bank',
		[HistoryType['GIFT-CARDS_WITHDRAW']]: 'gift-cards',
		[HistoryType['MOBILE-TOP-UPS_WITHDRAW']]: 'top-ups',
		[HistoryType['E-WALLET_WITHDRAW']]: 'e-wallets',
	};
	const updatedQuery = queryString.stringify({
		...(startDate && { dateFrom: startDate }),
		...(endDate && { dateTo: endDate }),
		...(currencyCode && { currencyCode }),
		...(type && { type }),
		page: page ? page - 1 : 0,
		size: 10,
	});

	if (historyType === HistoryType.CRYPTO_WITHDRAW)
		return getCryptoWithdrawHistoryURL(updatedQuery);

	return `${getBaseURL()}${URLPrefix}/${isPurchase ? 'purchases' : 'withdraws'}/${
		urlMap[historyType]
	}/history?${updatedQuery}`;
};

export const getCryptoWithdrawHistoryURL = (query: string) =>
	`${getBaseURL()}${URLPrefix}/withdraws/crypto?${query}`;

export const addLinkedCardsURL = () => `${getBaseURL()}${URLPrefix}/linked-cards`;
export const getLinkedCardsURL = () => `${getBaseURL()}${URLPrefix}/linked-cards`;
export const createLinkedCardURL = () => `${getBaseURL()}${URLPrefix}/linked-cards`;
export const removeLinkedCardURL = (id: string) => `${getBaseURL()}${URLPrefix}/linked-cards/${id}`;
export const getLinkedCardPaymentMethodURL = (cardNumber: string) =>
	`${getBaseURL()}${URLPrefix}/linked-cards/${cardNumber}/payment-method`;

export const depositLinkedCardURL = () => `${getBaseURL()}${URLPrefix}/linked-cards/deposit`;
export const verifyLinkedCardURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/linked-cards/${id}/verify-amount`;
export const getLinkedCardStatusURL = () =>
	`${getBaseURL()}${URLPrefix}/linked-cards/limits/status`;

export const getWithdrawVouchersURL = () => `${getBaseURL()}${URLPrefix}/withdraws/vouchers`;

export const getWithdrawVoucherCalcURL = (currencyCode: CurrencyEnum, amount: string) => {
	const updatedQuery = queryString.stringify({
		...(currencyCode && { currencyCode }),
		...(amount && { receiveAmount: amount }),
	});
	return `${getBaseURL()}${URLPrefix}/withdraws/vouchers/calculate?${updatedQuery}`;
};

export const getOffchainURL = () => `${getBaseURL()}${URLPrefix}/withdraws/fixed-send`;

export const getOffchainCalcURL = (accountId: string, sendAmount: string) =>
	`${getBaseURL()}${URLPrefix}/withdraws/fixed-send/calculate?accountId=${accountId}&sendAmount=${sendAmount}`;

export const getWithdrawGiftURL = (countryCode?: CurrencyEnum, offset?: number) => {
	const updatedQuery = queryString.stringify({
		...(countryCode && { countryCode }),
		...(offset && { offset }),
	});
	return `${getBaseURL()}${URLPrefix}/purchases/gift-cards?${updatedQuery}`;
};

export const getWithdrawGiftCalcURL = () =>
	`${getBaseURL()}${URLPrefix}/purchases/gift-cards/calculate`;

export const getWithdrawGiftItemURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/purchases/gift-cards/${id}/items`;

export const getWithdrawTopupsURL = (countryCode?: string | null, offset?: number) => {
	const updatedQuery = queryString.stringify({
		...(countryCode && { countryCode }),
		...(offset && { offset }),
	});
	return `${getBaseURL()}${URLPrefix}/purchases/top-ups?${updatedQuery}`;
};

export const getWithdrawTopUpItemURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/purchases/top-ups/${id}/items`;

export const getWithdrawTopupCalcURL = () =>
	`${getBaseURL()}${URLPrefix}/purchases/top-ups/calculate`;

export const getMerchantsProjectsUrl = () => `${getBaseURL()}${URLPrefix}/merchants/projects`;
export const getMerchantsProjectUrl = (id: string) =>
	`${getBaseURL()}${URLPrefix}/merchants/projects/${id}`;
export const getMerchantsProjectChartUrl = (
	id: string,
	from: Date,
	to: Date,
	currencyCode?: CurrencyEnum
) =>
	`${getBaseURL()}${URLPrefix}/merchants/projects/${id}/chart?${queryString.stringify({
		from: from.toISOString(),
		to: to.toISOString(),
		currencyCode,
	})}`;
export const getMerchantsProjectKeyPairUrl = () => `${getMerchantsProjectsUrl()}/key-pairs`;
export const getMerchantsOrdersUrl = () => `${getBaseURL()}${URLPrefix}/merchants/orders`;
export const getMerchantsOrderUrl = (id: string) =>
	`${getBaseURL()}${URLPrefix}/merchants/orders/${id}`;
export const getMerchantsPreOrderUrl = (id: string) =>
	`${getBaseURL()}${URLPrefix}/merchants/preorders/${id}`;
export const getMerchantsPreOrderInvoiceUrl = (id: string) =>
	`${getBaseURL()}${URLPrefix}/merchants/preorders/${id}/invoice`;
export const getMerchantsPreOrdersUrl = () => `${getBaseURL()}${URLPrefix}/merchants/preorders`;

export const getMerchantsTransactionsUrl = () => `${getBaseURL()}${URLPrefix}/merchants/deposit-tx`;
export const getMerchantsTransactionUrl = (id: string) =>
	`${getBaseURL()}${URLPrefix}/merchants/deposit-tx/${id}`;
export const getMerchantsCallbacksUrl = () => `${getBaseURL()}${URLPrefix}/merchants/callbacks`;
export const getMerchantsCallbackUrl = (id: string) =>
	`${getBaseURL()}${URLPrefix}/merchants/callbacks/${id}`;
export const getMerchantsOrderCallbackRetryUrl = (id: string) =>
	`${getBaseURL()}${URLPrefix}/merchants/orders/${id}/retry-callback`;
export const getMerchantsOrderPaymentUrl = (id: string) =>
	`${getBaseURL()}${URLPrefix}/merchants/orders/${id}/pay`;
export const getMerchantsPaymentOptions = (code?: CurrencyEnum) =>
	`${getBaseURL()}${URLPrefix}/merchants/payment-options${
		code ? `?receiveCurrencyCode=${code}` : ''
	}`;

export const getPaymentButtonsUrl = () => `${getBaseURL()}${URLPrefix}/payment-buttons`;
export const getPaymentButtonUrl = (id: string) =>
	`${getBaseURL()}${URLPrefix}/payment-buttons/${id}`;
export const getDonationPaymentUrl = (id: string) =>
	`${getBaseURL()}${URLPrefix}/payment-buttons/${id}/pay`;
export const getPublicPaymentButtonCurrenciesUrl = (id: string) =>
	`${getBaseURL()}${URLPrefix}/payment-buttons/p/${id}/currencies`;
export const getPaymentButtonPaymentOptions = (code?: CurrencyEnum) =>
	`${getBaseURL()}${URLPrefix}/payment-buttons/currencies/${code || ''}`;

export const getEWalletURL = (provider: ProviderType, isCalc = false) => {
	const mapProvider = {
		[ProviderType.ADV_CASH]: 'advanced-cash',
		[ProviderType.PAYEER]: 'payeer',
		[ProviderType.PERFECT_MONEY]: 'perfect-money',
		[ProviderType.SKRILL]: 'skrill',
	};
	return `${getBaseURL()}${URLPrefix}/withdraws/e-wallets/${mapProvider[provider]}${
		isCalc ? '/calculate' : '/'
	}`;
};

export const getBankWithdrawCalcURL = () => `${getBaseURL()}${URLPrefix}/withdraws/bank/calculate`;

export const getBankWithdrawEstimateFeeURL = () =>
	`${getBaseURL()}${URLPrefix}/withdraws/bank/estimate-fee`;

export const getBankWithdrawValidateIbanURL = () =>
	`${getBaseURL()}${URLPrefix}/withdrawals/bank/validate-iban`;

export const getBankWithdrawCreateURL = (isIBANExist: boolean) =>
	`${getBaseURL()}${URLPrefix}/withdraws${isIBANExist ? '-bank' : ''}/bank/create`;

export const getCryptoWithdrawExportURL = (
	type: HistoryType,
	generationTime: string,
	providerType?: ProviderType,
	currencyCode?: CurrencyEnum,
	dateFrom?: string,
	dateTo?: string,
	exportType: ExportType = ExportType.CSV
) => {
	const urlMap = {
		[HistoryType.CRYPTO_WITHDRAW]: 'crypto',
		[HistoryType.VOUCHERS_WITHDRAW]: 'vouchers',
		[HistoryType.BANK_WITHDRAW]: 'bank',
		[HistoryType['GIFT-CARDS_WITHDRAW']]: 'gift-cards',
		[HistoryType['MOBILE-TOP-UPS_WITHDRAW']]: 'top-ups',
		[HistoryType['E-WALLET_WITHDRAW']]: 'e-wallets',
	};

	const updatedQuery = queryString.stringify({
		generationTime,
		...(currencyCode && { currencyCode }),
		...(dateFrom && { dateFrom }),
		...(dateTo && { dateTo }),
		...(type && { type: providerType }),
	});

	if (
		[HistoryType['GIFT-CARDS_WITHDRAW'], HistoryType['MOBILE-TOP-UPS_WITHDRAW']].includes(type)
	) {
		return `${getBaseURL()}${URLPrefix}/purchases/${
			urlMap[type]
		}/history/${exportType}?${updatedQuery}`;
	}

	if (type === HistoryType.CRYPTO_WITHDRAW)
		return getCryptoWithdrawHistoryExportURL(updatedQuery, exportType);

	return `${getBaseURL()}${URLPrefix}/withdraws/${
		urlMap[type]
	}/history/${exportType}?${updatedQuery}`;
};

export const getCryptoWithdrawHistoryExportURL = (query: string, exportType: ExportType) =>
	`${getBaseURL()}${URLPrefix}/withdraws/crypto/${exportType}?${query}`;

export const getPublicSettingUrl = (setting: PublicSetting) =>
	`${getBaseURL()}${URLPrefix}/settings/${encodeURIComponent(setting)}`;

export const getCurrencyNetworkURL = (currencyCode: CurrencyEnum) =>
	`${getBaseURL()}${URLPrefix}/currency-network?currencyCode=${currencyCode}`;

export const getDepositAddressURL = (
	accountId?: string,
	network?: string,
	addressType?: BTCAddressType
) => {
	const updatedQuery = queryString.stringify({
		...(accountId && { accountId }),
		...(network && { network }),
		...(addressType && { addressType }),
	});
	return `${getBaseURL()}${URLPrefix}/deposits/user-crypto-deposits?${updatedQuery}`;
};

export const getIsNewAddressAvailableURL = (id: string, network?: NetworkEnum) =>
	`${getBaseURL()}${URLPrefix}/deposits/user-crypto-deposits/can-create?${queryString.stringify({
		...(id && { accountId: id }),
		...(network && { network }),
	})}`;

export const getIsRecurringOrderURL = () =>
	`${getBaseURL()}${URLPrefix}/recurring-orders/is-enabled`;

export const getRecurringOrderURL = (page?: number, status?: ConvertStatusType) => {
	const updatedQuery = queryString.stringify({
		...(page && { page: page ? page - 1 : 0 }),
		...(status && { status }),
		...(page && { size: 10 }),
	});

	return `${getBaseURL()}${URLPrefix}/recurring-orders${
		page || status ? `?${updatedQuery}` : ''
	}`;
};
export const getApiKeysURL = () => `${getBaseURL()}${URLPrefix}/uaa/api/apikey`;
export const getSignleApiKeyURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/uaa/api/apikey/${id}`;

export const getTwoFaInfo = () => `${getBaseURL()}${URLPrefix}/uaa/api/2fa/`;

export const getGdprURL = () => `${getBaseURL()}${URLPrefix}/user-gdpr-requests/process`;

export const getUserFilesURL = ({ page, id }: { page?: number; id?: string }) => {
	const updatedQuery = queryString.stringify({
		page: page ? page - 1 : 0,
		size: 6,
	});
	return `${getBaseURL()}${URLPrefix}/user-files${id ? `/${id}` : ''}?${
		page ? updatedQuery : ''
	}`;
};

export const getCryptoPayoutsUrl = () => `${getBaseURL()}${URLPrefix}/withdrawals/with-exchange`;

export const getCryptoPayoutUrl = (id: string) =>
	`${getBaseURL()}${URLPrefix}/withdrawals/with-exchange/${id}`;

export const getCryptoPayoutCancelURL = (id: string) => `${getCryptoPayoutUrl(id)}/cancel`;

export const getCryptoPayoutApproveULR = (id: string) => `${getCryptoPayoutUrl(id)}/approve`;

export const getEstimatePayoutURL = (id: string) =>
	`${getBaseURL()}${URLPrefix}/withdrawals/with-exchange/${id}/estimate-fee`;

export const getAdditionalFileUploadWithdraw = (id: string) =>
	`${getBaseURL()}${URLPrefix}/user-withdraw-docs/${id}/upload`;
