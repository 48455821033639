import { RemoteData, RemoteError } from '../../../../interfaces/RemoteData';
import PageableResponse from '../../../../interfaces/PageableResponse';
import { Order } from '../OrdersData/OrdersDataTypes';

export type OrdersHistoryState = {
	totalCount: number;
	pageSize: number;
} & RemoteData<string[]>;

export enum OrdersHistoryActionType {
	FETCH_ORDERS_PENDING = `MERCHANTS/ORDERS/HISTORY/FETCH_ORDERS/PENDING`,
	FETCH_ORDERS_REJECTED = 'MERCHANTS/ORDERS/HISTORY/FETCH_ORDERS/REJECTED',
	FETCH_ORDERS_FULFILLED = 'MERCHANTS/ORDERS/HISTORY/FETCH_ORDERS/FULFILLED',
}

export type OrdersHistoryFetchOrdersPendingAction = {
	type: OrdersHistoryActionType.FETCH_ORDERS_PENDING;
};
export type OrdersHistoryFetchOrdersRejectedAction = {
	type: OrdersHistoryActionType.FETCH_ORDERS_REJECTED;
	payload?: RemoteError;
};
export type OrdersHistoryFetchOrdersFulfilledAction = {
	type: OrdersHistoryActionType.FETCH_ORDERS_FULFILLED;
	payload: PageableResponse<Order[]>;
};
