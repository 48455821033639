import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowUp, faLongArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { CurrencyIcon, CurrencyEnum } from '@spectrocoin/sc-currencies';
import { toDecimal, formatPrecision } from '../../helpers/currencyHelper/currencyHelper';
import { RootState } from '../../redux/Store';
import { useFormatRoute } from '../../helpers/languagePathHelper/languagePathHelper';
import routes from '../../route.messages';
import styles from './RatesBanner.module.scss';
import TestIds from '../../test/TestIds';
import useFormatAmount from '../../hooks/useFormatAmount';

interface RatesBannerProps {
	refCurrency: CurrencyEnum | string;
}

const ratesConfig = [CurrencyEnum.BTC, CurrencyEnum.ETH, CurrencyEnum.XRP, CurrencyEnum.LTC];

const RatesBanner = ({ refCurrency }: RatesBannerProps) => {
	const rates = useSelector((state: RootState) => state?.RatesState?.ratesDetails);
	const linkToRates = useFormatRoute(routes.rates);
	const amountFormatter = useFormatAmount();

	const renderRates = () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return rates?.map(({ currencyCode, value, last24HChange }: any) => {
			if (ratesConfig.includes(currencyCode))
				return (
					<li key={`${currencyCode}`}>
						<CurrencyIcon currencyType={currencyCode} className={styles.marginRight} />
						<div className={styles.label}>
							{currencyCode}
							<div className={styles.value}>
								{amountFormatter(
									formatPrecision(
										toDecimal(value).toFixed(2).toString(),
										CurrencyEnum[refCurrency]
									)
								)}{' '}
								{refCurrency}
							</div>
						</div>
						<div className={classNames(styles.percentageValue)}>
							<span>{`${last24HChange}%`}</span>
							<FontAwesomeIcon
								className={classNames(styles.arrows, {
									[styles.negative]: last24HChange < 0,
								})}
								icon={last24HChange < 0 ? faLongArrowDown : faLongArrowUp}
							/>
						</div>
					</li>
				);
			return null;
		});
	};

	if (!rates?.length) return null;
	return (
		<>
			<div className={styles.content}>
				<h3 className={styles.title}>
					<FormattedMessage
						id="ratesBanner.currentRates"
						defaultMessage="Current rates"
					/>
					<a
						href={linkToRates}
						data-cy={TestIds.ShowCurrenciesBtn}
						className={styles.seeAllLink}
					>
						<FormattedMessage id="ratesBanner.seeAll" defaultMessage="See all" />
					</a>
				</h3>
			</div>
			<div className={styles.container}>
				<ul className={styles.ratesList} data-cy={TestIds.RatesBannerList}>
					{renderRates()}
				</ul>
			</div>
		</>
	);
};

export default RatesBanner;
