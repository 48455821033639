import { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { RootState } from '../../redux/Store';
import StartingBlock from '../../components/StartingBlock/StartingBlock';
import PageTitle from '../../components/PageTitle/PageTitle';
import styles from './TransfersOptionContainer.module.scss';

interface TransfersOptionContainerProps {
	children: ReactNode;
	title: string;
	previousPageLink?: string;
	showStartingBlock?: boolean;
	optionContainerClassName?: string;
}

const TransfersOptionContainer = ({
	children,
	title,
	previousPageLink,
	showStartingBlock = false,
	optionContainerClassName,
}: TransfersOptionContainerProps) => {
	const { pathname } = useLocation();
	const { url } = useRouteMatch();
	const { isMobile, isTablet } = useSelector((state: RootState) => state.AppState);
	const [isVisible, setIsVisible] = useState<boolean>(url !== pathname);

	useEffect(() => {
		setIsVisible(url !== pathname);
	}, [url, pathname]);

	if (!isVisible && (isMobile || isTablet)) return null;

	return (
		<div className={styles.flexCol}>
			{showStartingBlock && <StartingBlock />}
			{(isMobile || isTablet) && (
				<PageTitle
					title={title}
					className={styles.mobilePageTitle}
					previousPageLink={previousPageLink}
				/>
			)}
			<div className={classNames(styles.optionContainer, optionContainerClassName)}>
				{children}
			</div>
		</div>
	);
};

export default TransfersOptionContainer;
