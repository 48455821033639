import { FC } from 'react';
import { useIntl } from 'react-intl';
import { OpenOrder } from '../../../../../redux/ConvertHistoryState/ConvertHistoryTypes';
import convertMsg from '../../../convertMsg';
import OpenOrdersItem from '../OpenOrdersItem/OpenOrdersItem';
import styles from './OpenOrdersList.module.scss';

const OpenOrdersList: FC<{ data: OpenOrder[] }> = ({ data }) => {
	const { formatMessage } = useIntl();

	return (
		<ul className={styles.list}>
			<li>
				<div>{formatMessage(convertMsg.assets)}</div>
				<div>{formatMessage(convertMsg.interval)}</div>
				<div>{formatMessage(convertMsg.payAmountHistory)}</div>
				<div>{formatMessage(convertMsg.status)}</div>
			</li>
			{data?.length > 0 &&
				data?.map(
					({
						id,
						payCurrencyCode,
						receiveCurrencyCode,
						frequency,
						payAmount,
						status,
						startDate,
						endDate,
					}: any) => (
						<OpenOrdersItem
							key={id}
							id={id}
							payCurrencyCode={payCurrencyCode}
							receiveCurrencyCode={receiveCurrencyCode}
							frequency={frequency}
							payAmount={payAmount}
							status={status}
							startDate={startDate}
							endDate={endDate}
						/>
					)
				)}
		</ul>
	);
};

export default OpenOrdersList;
