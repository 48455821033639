import { CurrencyEnum } from '@spectrocoin/sc-currencies';

export enum CardHistoryActionType {
	CARD_HISTORY_IS_LOADING = 'CARD_HISTORY_IS_LOADING',
	SET_CARD_DATA = 'SET_CARD_DATA',
	RESET_CARD_DATA = 'RESET_CARD_DATA',
}

export enum PaymentDirection {
	DEBIT = 'DEBIT',
	CREDIT = 'CREDIT',
}

export enum TransactionType {
	LOAD = 'LOAD',
	UNLOAD = 'UNLOAD',
	PAY = 'PAY',
	RECEIVE = 'RECEIVE',
	ADMINISTRATION = 'ADMINISTRATION',
	OTHER = 'OTHER',
}

export enum TransactionStatus {
	AUTHORIZED = 'AUTHORIZED',
	COMPLETED = 'COMPLETED',
	REVERSED = 'REVERSED',
	FAILED = 'FAILED',
	REJECTED = 'REJECTED',
	REMOVED = 'REMOVED',
	CANCELED = 'CANCELED',
	PENDING = 'PENDING',
	INITIATED = 'INITIATED',
	CONFIRMED = 'CONFIRMED',
	PROCESSING = 'PROCESSING',
	WAITING = 'WAITING',
	VERIFICATION = 'VERIFICATION',
	SUSPENDED = 'SUSPENDED',
}

export enum OperationType {
	TOPUP = 'TOPUP',
	TOP_UP_RHA = 'TOP_UP_RHA',
	TOP_UP_BIN = 'TOP_UP_BIN',
	WITHDRAWAL = 'WITHDRAWAL',
	CARD_PURCHASE = 'CARD_PURCHASE',
	CARD_PURCHASE_REVERSAL = 'CARD_PURCHASE_REVERSAL',
	CARD_PURCHASE_WITH_CASHBACK = 'CARD_PURCHASE_WITH_CASHBACK',
	CARD_PURCHASE_WITH_CASHBACK_REVERSAL = 'CARD_PURCHASE_WITH_CASHBACK_REVERSAL',
	CARD_PURCHASE_UNIQUE = 'CARD_PURCHASE_UNIQUE',
	CARD_PURCHASE_UNIQUE_REVERSAL = 'CARD_PURCHASE_UNIQUE_REVERSAL',
	CARD_REFUND = 'CARD_REFUND',
	CARD_REFUND_REVERSAL = 'CARD_REFUND_REVERSAL',
	CARD_CASH_WITHDRAWAL = 'CARD_CASH_WITHDRAWAL',
	CARD_CASH_WITHDRAWAL_REVERSAL = 'CARD_CASH_WITHDRAWAL_REVERSAL',
	CARD_CASH_WITHDRAWAL_POS = 'CARD_CASH_WITHDRAWAL_POS',
	CARD_CASH_WITHDRAWAL_POS_REVERSAL = 'CARD_CASH_WITHDRAWAL_POS_REVERSAL',
	CARD_CASH_ADVANCE = 'CARD_CASH_ADVANCE',
	CARD_CASH_ADVANCE_REVERSAL = 'CARD_CASH_ADVANCE_REVERSAL',
	CARD_CASH_IN = 'CARD_CASH_IN',
	CARD_CASH_IN_REVERSAL = 'CARD_CASH_IN_REVERSAL',
	CARD_ADJUSTMENT_CREDIT = 'CARD_ADJUSTMENT_CREDIT',
	CARD_ADJUSTMENT_CREDIT_REVERSAL = 'CARD_ADJUSTMENT_CREDIT_REVERSAL',
	CARD_ADJUSTMENT_DEBIT = 'CARD_ADJUSTMENT_DEBIT',
	CARD_ADJUSTMENT_DEBIT_REVERSAL = 'CARD_ADJUSTMENT_DEBIT_REVERSAL',
	CARD_PAYMENT_CREDIT = 'CARD_PAYMENT_CREDIT',
	CARD_PAYMENT_CREDIT_REVERSAL = 'CARD_PAYMENT_CREDIT_REVERSAL',
	CARD_ACCOUNT_FUNDING_TRANSACTION = 'CARD_ACCOUNT_FUNDING_TRANSACTION',
	CARD_ACCOUNT_FUNDING_TRANSACTION_REVERSAL = 'CARD_ACCOUNT_FUNDING_TRANSACTION_REVERSAL',
	CARD_ACCOUNT_VERIFICATION = 'CARD_ACCOUNT_VERIFICATION',
	DEBT = 'DEBT',
}

export interface CardHistoryItem {
	transactionId: string | null;
	accountNumber: string | null;
	sortCode: string | null;
	iban: string | null;
	bic: string | null;
	localTranDate: string | null;
	tranDate: string | null;
	description: string;
	transactionType: TransactionType | null;
	referenceNumber: string | null;
	clientReferenceNumber: string | null;
	isDebit: boolean | null;
	transactionAmount: string | null;
	transactionCurrency: CurrencyEnum | string | null;
	runningAccountBalance: string | null;
	date: string;
	updatedOn: string | null;
	productId: string | null;
	cardProductType: string | null;
	cardPaymentNetwork: string | null;
	operationId: string;
	operationType: OperationType | null;
	initiatorType: string | null;
	direction: PaymentDirection | null;
	payerName: string | null;
	amount: string;
	currency: CurrencyEnum;
	feeAmount: string | null;
	feeCurrency: string | null;
	exchangeRate: string | null;
	status: TransactionStatus;
	createdOn: string | null;
	conversionRate: string | null;
	merchantName: string | null;
	merchantCategoryCode: string | null;
	merchantCity: string | null;
	merchantCountry: string | null;
	pan: string;
	cardId: string;
	serviceType: string | null;
}

export interface CardHistoryResponse {
	transactions: CardHistoryItem[] | null;
	pendingAuthorizations: CardHistoryItem[] | null;
	totalCount: number;
	pendingAuthorizationCount: number;
}

export interface CardHistoryState {
	isLoading: boolean;
	data: CardHistoryItem[];
	pendingData: CardHistoryItem[];
	totalCount: number;
	pendingAuthorizationCount: number;
}

export interface SetCardHistoryIsLoadingAction {
	type: typeof CardHistoryActionType.CARD_HISTORY_IS_LOADING;
	isLoading: boolean;
}

export interface SetCardHistoryDataAction {
	type: typeof CardHistoryActionType.SET_CARD_DATA;
	data: CardHistoryResponse;
}

export interface ResetCardHistoryDataAction {
	type: typeof CardHistoryActionType.RESET_CARD_DATA;
}
