import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import queryString, { ParsedQuery } from 'query-string';
import { HistoryTableData, HistoryType } from '../../../../../hooks/useHistoryTableData';
import HistoryContext, {
	DefaultHistoryContextData,
} from '../../../../../contexts/HistoryContext/HistoryContext';
import useDidMount from '../../../../../hooks/useDidMount';
import useQueryParams from '../../../../../hooks/useQueryParams';
import { RootState } from '../../../../../redux/Store';
import {
	getWithdrawCryptoHistory,
	resetWithdrawHistory,
} from '../../../../../redux/WithdrawHistoryState/WithdrawHistoryActions';

const useWithdrawHistoryDataProvider = (): HistoryTableData<any> => {
	const { id, historyType, filter } = useContext(HistoryContext) as DefaultHistoryContextData;
	const { search } = useLocation();

	const didMount = useDidMount();
	const dispatch = useDispatch();
	const { type }: ParsedQuery<any> = queryString.parse(search);
	const [[page, startDate, endDate, status]] = useQueryParams({
		page: undefined,
		startDate: undefined,
		endDate: undefined,
		status: undefined,
	});
	const isPurchase = [
		HistoryType['GIFT-CARDS_WITHDRAW'],
		HistoryType['MOBILE-TOP-UPS_WITHDRAW'],
	].includes(historyType);

	const { totalCount: historyOrdersTotalCount, isLoading, data: historyOrders } = useSelector(
		(state: RootState) => state.WithdrawHistoryState
	);

	useEffect(() => {
		if (didMount)
			dispatch(
				getWithdrawCryptoHistory(
					historyType,
					startDate,
					endDate,
					typeof page === 'string' ? +page : page,
					filter,
					isPurchase,
					type
				)
			);
		return () => {
			dispatch(resetWithdrawHistory());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endDate, page, id, startDate, didMount, status, filter, isPurchase, type]);

	return {
		data: historyOrders,
		isLoading,
		totalCount: historyOrdersTotalCount,
	};
};

export default useWithdrawHistoryDataProvider;
