/* eslint-disable react/no-array-index-key */
import { useCallback, useEffect, useState } from 'react';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/Store';
import noDataImgPath from '../../../../images/empty.svg';
import styles from './WithdrawOptionList.module.scss';
import ListItem, { ListItemType } from '../../../../components/ListItem/ListItem';
import PaymentMethod from '../../../../redux/DepositState/DepositTypes';
import { UserPreviousPageForCompanyMovement } from '../../../../enums/UserContractCompanyEnum';
import { companyAndPreviousPageHandler } from '../../../../helpers/storageHelper/storageHelper';
import config from '../../../../configs/config';
import { resolveUserCompany } from '../../../../helpers/companyHelper/companyHelper';

const messages = defineMessages({
	additionalVerificationNeeded: {
		id: 'base.additionalVerificationNeeded',
		defaultMessage: 'Additional verification needed',
	},
	minWithdrawAmount: {
		id: 'base.minWithdrawAmount',
		defaultMessage: 'Min withdraw amount',
	},
	maxWithdrawAmount: {
		id: 'base.maxWithdrawAmount',
		defaultMessage: 'Max withdraw amount',
	},
	minFee: {
		id: 'base.minFee',
		defaultMessage: 'Min fee',
	},
	maxFee: {
		id: 'base.maxFee',
		defaultMessage: 'Max fee',
	},
	duration: {
		id: 'base.takes_up_to',
		defaultMessage: 'Takes up to: {duration}',
	},
	noData: {
		id: 'base.noData',
		defaultMessage: 'No options',
	},
});

export enum ListType {
	EWALLET = 'EWALLET',
	BANK = 'BANK',
	TOPUP = 'TOPUP',
	GIFT_CARDS = 'GIFT_CARDS',
}

interface WithdrawOptionListProps {
	type: ListType;
	currency?: CurrencyEnum | string;
	list: any;
	height?: number;
	country?: string;
}

const WithdrawOptionList = ({
	type,
	currency,
	list,
	height,
	country,
}: WithdrawOptionListProps): JSX.Element => {
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const { locale } = useIntl();
	const [availableMethods, setAvailableMethods] = useState([]);
	const [unavailableMethods, setUnavailableMethods] = useState([]);

	const isMethodAvailable = useCallback(
		(method: PaymentMethod) =>
			method.isEnabledForVerified &&
			user?.verified &&
			!method.companyMovementNeeded &&
			!method.toCompany,
		[user?.verified]
	);

	const generateLists = useCallback(
		(curr: CurrencyEnum) => {
			if ([ListType.BANK, ListType.EWALLET].includes(type)) {
				setAvailableMethods(
					list?.filter((method: PaymentMethod) => {
						if (!curr) return isMethodAvailable(method);
						if (method.paymentMethodCurrencies.includes(curr)) {
							return isMethodAvailable(method);
						}
						return null;
					})
				);
				setUnavailableMethods(
					list?.filter((method: PaymentMethod) => {
						if (!curr) return !isMethodAvailable(method);
						if (method.paymentMethodCurrencies.includes(curr)) {
							return !isMethodAvailable(method);
						}
						return null;
					})
				);
			}
			if ([ListType.GIFT_CARDS, ListType.TOPUP].includes(type)) setAvailableMethods(list);
		},
		[isMethodAvailable, list, type]
	);

	// Set up KYC verification using their helper
	const handleUserCompanyChangeNeededMethodClick = (
		event: React.MouseEvent<HTMLDivElement>,
		toCompany: string
	) => {
		event.preventDefault();
		companyAndPreviousPageHandler({
			toCompany: toCompany || null,
			previousPage: UserPreviousPageForCompanyMovement.PAYMENT_METHOD,
		});
		window.location.href = `${
			config.PROFILE_DOMAIN
		}/${locale}/verification/verify/${resolveUserCompany(toCompany)}`;
	};

	const getListItemLink = (id: string) => {
		switch (type) {
			case ListType.GIFT_CARDS:
				return `/${locale}/withdraw/gift-cards/${id}?step=1`;
			case ListType.BANK:
				return `/${locale}/withdraw/bank/${id}?step=1`;
			case ListType.TOPUP:
				return `/${locale}/withdraw/mobile-top-ups/${country}/${id}?step=1`;
			case ListType.EWALLET:
				return `/${locale}/withdraw/e-wallet/${id}?step=1`;
			default:
				return '';
		}
	};

	useEffect(() => {
		generateLists(currency as CurrencyEnum);
	}, [currency, generateLists, list]);

	return (
		<div className={styles.scrollableContent} style={{ maxHeight: `${height}px` }}>
			{availableMethods.length === 0 && unavailableMethods.length === 0 && (
				<div className={styles.noData}>
					<img src={noDataImgPath} alt="No data to show" />
					<p>
						<FormattedMessage {...messages.noData} />
					</p>
				</div>
			)}
			{availableMethods.length > 0 && (
				<ul className={styles.list}>
					{availableMethods.map(
						({
							baseCurrencyCode,
							duration,
							fee,
							id,
							logo,
							maxAmount,
							maxFee,
							minAmount,
							minFee,
							paymentMethodCurrencies,
							title,
							isIBAN,
							providerType,
							key,
						}: PaymentMethod) => (
							<ListItem
								key={id}
								id={id}
								duration={duration}
								link={getListItemLink(id)}
								logo={logo}
								paymentMethodCurrencies={paymentMethodCurrencies}
								title={title}
								dynamicIcon={type === ListType.TOPUP}
								withDescription={
									type !== ListType.TOPUP && type !== ListType.GIFT_CARDS
								}
								giftCards={type === ListType.GIFT_CARDS}
								limits={{
									baseCurrencyCode,
									fee,
									maxDepositAmount: maxAmount,
									maxFeeAmount: maxFee,
									minDepositAmount: minAmount,
									minFeeAmount: minFee,
								}}
								providerType={providerType}
								isIBAN={isIBAN}
								providerKey={key}
							/>
						)
					)}
				</ul>
			)}
			{unavailableMethods.length > 0 && (
				<>
					<h2 className={styles.heading}>
						<FormattedMessage {...messages.additionalVerificationNeeded} />
					</h2>
					<ul className={styles.list}>
						{unavailableMethods.map(
							({
								paymentMethodCurrencies,
								logo,
								title,
								id,
								baseCurrencyCode,
								duration,
								fee,
								maxAmount,
								maxFee,
								minAmount,
								minFee,
								toCompany,
								isIBAN,
								providerType,
								key,
							}: PaymentMethod) => {
								return (
									<li key={id}>
										<ListItem
											duration={duration}
											limits={{
												baseCurrencyCode,
												fee,
												maxDepositAmount: maxAmount,
												maxFeeAmount: maxFee,
												minDepositAmount: minAmount,
												minFeeAmount: minFee,
											}}
											id={id}
											logo={logo}
											dynamicIcon={type === ListType.TOPUP}
											clickableWhenDisabled
											withDescription={
												type !== ListType.TOPUP &&
												type !== ListType.GIFT_CARDS
											}
											onClick={(event: React.MouseEvent<HTMLDivElement>) => {
												handleUserCompanyChangeNeededMethodClick(
													event,
													toCompany
												);
											}}
											type={ListItemType.CLICK_HANDLER}
											giftCards={type === ListType.GIFT_CARDS}
											title={title}
											paymentMethodCurrencies={paymentMethodCurrencies}
											providerType={providerType}
											isIBAN={isIBAN}
											providerKey={key}
											disabled
										/>
									</li>
								);
							}
						)}
					</ul>
				</>
			)}
		</div>
	);
};

export default WithdrawOptionList;
