import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import MerchantsMessages from '../../../../../redux/MerchantsState/MerchantsMessages';
import { entries, values } from '../../../../../helpers/objectHelper/objectHelper';
import { Order, OrderStatus } from '../../../../../redux/MerchantsState/MerchantTypes';
import MerchantsOrdersHistoryItem from '../MerchantsOrdersHistoryItem/MerchantsOrdersHistoryItem';

import styles from './MerchantsOrderHistoryList.module.scss';
import TestIds, { formatTestId } from '../../../../../test/TestIds';

enum OrderCategories {
	PENDING = 'pending',
	COMPLETED = 'completed',
}

const StatusToCategoryMap = {
	[OrderCategories.COMPLETED]: [
		OrderStatus.EXPIRED,
		OrderStatus.PAID,
		OrderStatus.FAILED,
		OrderStatus.TEST,
	],
	[OrderCategories.PENDING]: [OrderStatus.NEW, OrderStatus.PENDING],
};

const MerchantsOrdersHistoryCategory: FC<
	PropsWithChildren<{
		category: OrderCategories;
	}>
> = ({ category, children }) => {
	const { formatMessage } = useIntl();
	const label =
		(MerchantsMessages[category] && formatMessage(MerchantsMessages[category])) || category;
	return (
		<div
			className={classNames(styles.category, styles[category])}
			data-cy={formatTestId(TestIds.OrderHistoryCategory_0, category)}
		>
			<div className={styles.label}>{label}</div>
			<div className={styles.items}>{children}</div>
		</div>
	);
};

type MerchantsOrderHistoryListProps = {
	orders: Order[];
};

const MerchantsOrderHistoryList: FC<MerchantsOrderHistoryListProps> = ({ orders }) => {
	const categories = orders.reduce(
		(map, order) => {
			const orderCategory = [
				OrderCategories.PENDING,
				OrderCategories.COMPLETED,
			].find((category) => StatusToCategoryMap[category].includes(order.status));
			if (orderCategory) map[orderCategory].push(order);
			return map;
		},
		{ [OrderCategories.PENDING]: [], [OrderCategories.COMPLETED]: [] } as Record<
			OrderCategories,
			Order[]
		>
	);

	const disableCategories =
		values(categories)
			.map((x) => x.length)
			.filter((x) => x > 0).length <= 1;

	if (disableCategories)
		return (
			<>
				{orders.map((order) => (
					<MerchantsOrdersHistoryItem key={order.id} order={order} />
				))}
			</>
		);

	return (
		<>
			{entries(categories).map(([category, items]) => (
				<MerchantsOrdersHistoryCategory category={category} key={category}>
					{items.map((order) => (
						<MerchantsOrdersHistoryItem key={order.id} order={order} />
					))}
				</MerchantsOrdersHistoryCategory>
			))}
		</>
	);
};

export default MerchantsOrderHistoryList;
