import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import TextNode, { TextStyles } from '../../../../components/TextNode/TextNode';
import MerchantTestIds from '../../../../test/TestIds';

import styles from './PageTitle.module.scss';

export enum TextPosition {
	Left = 'left',
	Center = 'center',
}

type PageTitleProps = {
	title: string;
	children?: ReactNode;
	className?: string;
	backLink?: string;
	position?: TextPosition;
};

const PageTitle: FC<PageTitleProps> = ({
	title,
	children,
	className,
	backLink,
	position = TextPosition.Left,
}) => {
	return (
		<div
			data-cy={MerchantTestIds.MerchantTitle}
			className={classNames(styles.container, styles[position], className)}
		>
			{backLink && (
				<Link
					data-cy={MerchantTestIds.TitleBackLink}
					className={styles.backLink}
					to={backLink}
				>
					<FontAwesomeIcon className={styles.backIcon} icon={faChevronLeft} scale={2} />
				</Link>
			)}
			<div className={styles.title}>
				<TextNode style={TextStyles.BreakWord}>{title}</TextNode>
			</div>
			{children && <div className={styles.actions}>{children}</div>}
		</div>
	);
};

export default PageTitle;
