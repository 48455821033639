import { FormEvent, useEffect, useState, useMemo } from 'react';
import { useIntl, defineMessages, MessageDescriptor } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { CurrencyIcon } from '@spectrocoin/sc-currencies';
import baseMsg from '../../../../messages/base.messages';
import { RootState } from '../../../../redux/Store';
import { Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import { setFormData, setOrderStep } from '../../../../redux/CardsState/CardsActions';
import { CardType, OrderSteps } from '../../../../redux/CardsState/CardsTypes';
import BackButton from '../../../../components/BackButton/BackButton';
import Select, { SelectStyleType } from '../../../../components/Select/Select';
import Button, { ButtonType, ButtonStyle } from '../../../../components/Button/Button';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import styles from './SelectLinkedWallets.module.scss';
import inputErrors from '../../../../messages/inputErrors.messages';

const messages = defineMessages({
	title: {
		id: 'selectLinkedWallets.title',
		defaultMessage: 'Choose cryptocurrency',
	},
	subTitle: {
		id: 'selectLinkedWallets.subTitle',
		defaultMessage: 'Select the cryptocurrency to link your card with.',
	},
});

interface SelectLinkedWalletsProps {
	type: CardType;
}

const SelectLinkedWallets = ({ type }: SelectLinkedWalletsProps) => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const { wallets } = useSelector((state: RootState) => state?.AccountsState);
	const { formData, isCardUser } = useSelector((state: RootState) => state.CardsState);
	const [currency, setCurrency] = useState<string | null>(null);
	const [currencyError, setCurrencyError] = useState<MessageDescriptor | null>(null);

	const currencyOptions = useMemo(() => {
		return (
			wallets?.map(({ currencyCode, currencyName }: Wallet) => ({
				value: currencyCode,
				label: (
					<div className={styles.option}>
						<CurrencyIcon currencyType={currencyCode} className={styles.currencyIcon} />
						<div className={styles.currencyColumn}>
							<span className={styles.currencyCode}>{currencyCode}</span>
							<span className={styles.currencyName}>{currencyName}</span>
						</div>
					</div>
				),
			})) || []
		);
	}, [wallets]);

	const handleSelectChange = (val: string) => {
		setCurrencyError(null);
		return setCurrency(val);
	};

	const validation = () => {
		if (!currency) {
			setCurrencyError(inputErrors.cannotBeEmpty);
		}
		return true;
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const { id } = wallets?.find((o: Wallet) => o.currencyCode === currency) || {};
		if (validation() && currency && id) {
			dispatch(
				setFormData({
					rha: true,
					linkedAccounts: [
						{
							accountId: id,
							priorityIndex: 1,
						},
					],
				})
			);
			return dispatch(setOrderStep(OrderSteps.CONFIRM));
		}
		return null;
	};

	useEffect(() => {
		if (currencyOptions?.length > 0 && formData?.linkedAccounts?.length) {
			const accountId = formData.linkedAccounts[0]?.accountId;
			const { currencyCode: linkedAccountCurrency } =
				wallets?.find((o: Wallet) => o.id === accountId) || {};
			setCurrency(linkedAccountCurrency || null);
		}
	}, [currencyOptions, formData, wallets]);

	const previousStep =
		type === CardType.PLASTIC
			? OrderSteps.SHIPPING
			: !isCardUser
			? OrderSteps.DELIVERY_ADDRESS
			: OrderSteps.TERMS;

	return (
		<div className={styles.container}>
			<BackButton
				onClick={() => dispatch(setOrderStep(previousStep))}
				className={styles.back}
			/>
			<InfoHead title={messages.title} subTitle={formatMessage(messages.subTitle)} />
			<form className={styles.form} onSubmit={handleSubmit}>
				<Select
					labelClassName={styles.label}
					placeholder={formatMessage(baseMsg.selectCurrency)}
					className={styles.input}
					options={currencyOptions}
					onChange={handleSelectChange}
					value={currency}
					errorMessage={currencyError}
					styleType={SelectStyleType.BLOCK}
				/>
				<Button
					buttonStyle={ButtonStyle.PRIMARY}
					type={ButtonType.SUBMIT}
					text={baseMsg.submit}
					className={styles.button}
				/>
			</form>
		</div>
	);
};

export default SelectLinkedWallets;
