import { defineMessages, MessageDescriptor } from 'react-intl';

interface Messages {
	[key: string]: MessageDescriptor;
}

const baseMsg: Messages = defineMessages({
	from: {
		id: 'base.from',
		defaultMessage: 'From',
	},
	to: {
		id: 'base.to',
		defaultMessage: 'To',
	},
	id: {
		id: 'base.id',
		defaultMessage: 'ID',
	},
	cardAmount: {
		id: 'base.cardAmount',
		defaultMessage: 'Card amount',
	},
	back: {
		id: 'base.back',
		defaultMessage: 'Go back',
	},
	all: {
		id: 'base.all',
		defaultMessage: 'All',
	},
	confirm: {
		id: 'base.confirm',
		defaultMessage: 'Confirm',
	},
	search: {
		id: 'base.search',
		defaultMessage: 'Search',
	},
	submit: {
		id: 'base.submit',
		defaultMessage: 'Submit',
	},
	close: {
		id: 'base.close',
		defaultMessage: 'Close',
	},
	message: {
		id: 'base.message',
		defaultMessage: 'Message',
	},
	memo: {
		id: 'base.memo',
		defaultMessage: 'Memo',
	},
	destinationTag: {
		id: 'base.destination_tag',
		defaultMessage: 'Destination tag',
	},
	fee: {
		id: 'base.fee',
		defaultMessage: 'Fee',
	},
	receiver: {
		id: 'base.receiver',
		defaultMessage: 'Receiver',
	},
	sendAccount: {
		id: 'base.sendAccount',
		defaultMessage: 'Send account',
	},
	payAmount: {
		id: 'base.payAmount',
		defaultMessage: 'Pay amount',
	},
	phoneNumber: {
		id: 'base.phoneNumberLabel',
		defaultMessage: 'Phone number',
	},
	filterByCurrency: {
		id: 'base.filterByCurrency',
		defaultMessage: 'Filter by currency',
	},
	filterByProvider: {
		id: 'base.filterByProvider',
		defaultMessage: 'Filter by provider',
	},
	filterByCountry: {
		id: 'base.filterByCountry',
		defaultMessage: 'Filter by country',
	},
	receiveAmount: {
		id: 'base.receive_amount',
		defaultMessage: 'Receive amount',
	},
	withdrawAmount: {
		id: 'base.withdrawAmount',
		defaultMessage: 'Withdrawal amount',
	},
	selectCurrency: {
		id: 'base.selectCurrency',
		defaultMessage: 'Select currency',
	},
	currency: {
		id: 'base.currency',
		defaultMessage: 'Currency',
	},
	transactionMemoPlaceholder: {
		id: 'base.transactionMemoPlaceholder',
		defaultMessage: 'Enter short transaction memo',
	},
	totalAmountWithFee: {
		id: 'base.totalAmountWithFee',
		defaultMessage: 'Total withdrawal amount (with fee)',
	},
	totalConvertedAmount: {
		id: 'withdraw.totalConvertedAmount',
		defaultMessage: 'Total amount (converted)',
	},
	transactionHash: {
		id: 'base.transactionHash',
		defaultMessage: 'Transaction Hash',
	},
	transactionNumber: {
		id: 'base.transactionNumber',
		defaultMessage: 'Transaction number',
	},
	tryAgain: {
		id: 'base.tryAgain',
		defaultMessage: 'Try again',
	},
	labeledValue: {
		id: 'base.label_value',
		defaultMessage: '{Label}: {Value}',
	},
	purchaseOrderNumber: {
		id: 'base.purchaseOrderNumber',
		defaultMessage: 'Purchase Order number',
	},
	purchaseItem: {
		id: 'base.purchaseItem',
		defaultMessage: 'Purchase item',
	},
	send: {
		id: 'base.send',
		defaultMessage: 'Send',
	},
	emailAddress: {
		id: 'base.email_address',
		defaultMessage: 'Email address',
	},
	network: {
		id: 'base.network',
		defaultMessage: 'Network',
	},
	copy: {
		id: 'base.copy',
		defaultMessage: 'Copy',
	},
	DAILY: {
		id: 'base.DAILY',
		defaultMessage: 'Daily',
	},
	WEEKLY: {
		id: 'base.WEEKLY',
		defaultMessage: 'Weekly',
	},
	MONTHLY: {
		id: 'base.MONTHLY',
		defaultMessage: 'Monthly',
	},
	cancel: {
		id: 'base.cancel',
		defaultMessage: 'Cancel',
	},
	yes: {
		id: 'base.yes',
		defaultMessage: 'Yes',
	},
	no: {
		id: 'base.no',
		defaultMessage: 'No',
	},
	sort: {
		id: 'base.sort',
		defaultMessage: 'Sort',
	},
	or: {
		id: 'base.or',
		defaultMessage: 'or',
	},
});

export default baseMsg;
