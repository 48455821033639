/* eslint-disable no-new */
import { CurrencyEnum } from '@spectrocoin/sc-currencies';

import {
	getOffchainURL,
	getPaymentMethodsURL,
	getWithdrawAvailableCryptosURL,
	getWithdrawGiftURL,
	getWithdrawTopupsURL,
	getWithdrawVouchersURL,
} from '../endpoints';
import { AppDispatch } from '../Store';
import {
	PaymentMethodGroup,
	PaymentMethodResponse,
	SetWithdrawPaymentMethodsByGroup,
	WithdrawActionType,
	AvailableVouchersResponse,
	AvailablePurchaseResponse,
	PurchaseItem,
} from './WithdrawTypes';
import axiosInstance from '../../helpers/axiosInstance';

const getAvailableWithdrawCryptos = async () => {
	const response = await axiosInstance.get(getWithdrawAvailableCryptosURL());
	return response.data as CurrencyEnum[];
};

const getAvailableVouchers = async () => {
	const response = await axiosInstance.get(getWithdrawVouchersURL());
	return response.data as AvailableVouchersResponse[];
};

const getAvailableOffchainWallets = async () => {
	const response = await axiosInstance.get(getOffchainURL());
	return response.data as CurrencyEnum[];
};

const getAvailableGiftCards = async () => {
	const response = await axiosInstance.get(getWithdrawGiftURL());
	return response.data as AvailablePurchaseResponse;
};

const getAvailableTopups = async (countryCode: string | null = null) => {
	const response = await axiosInstance.get(getWithdrawTopupsURL(countryCode));
	return response.data as AvailablePurchaseResponse;
};

const getAvailableEWallets = async () => {
	const response = await axiosInstance.get(
		`${getPaymentMethodsURL()}?group=${PaymentMethodGroup.EWALLET}&type=WITHDRAW`
	);
	return response.data as PaymentMethodResponse;
};

const getAvailableBankMethods = async () => {
	const response = await axiosInstance.get(
		`${getPaymentMethodsURL()}?group=${PaymentMethodGroup.BANK}&type=WITHDRAW`
	);
	return response.data as PaymentMethodResponse;
};

const resetPaymentMethodsByGroup = (group: PaymentMethodGroup) => ({
	type: WithdrawActionType.RESET_PAYMENT_BY_GROUP,
	group,
});

export const setCurrentTopupMethod = (countryCode: string) => (dispatch: AppDispatch) => {
	dispatch(resetPaymentMethodsByGroup(PaymentMethodGroup.TOPUPS));
	void new Promise((resolve) => {
		resolve(getAvailableTopups(countryCode));
	}).then(({ content }: any) => {
		const formattedData = content.map(
			({ id, logo, title, minPrice, maxPrice, fee }: PurchaseItem) => {
				return {
					id,
					logo,
					title,
					maxAmount: maxPrice,
					minAmount: minPrice,
					fee,
				};
			}
		) as PurchaseItem[];
		dispatch<SetWithdrawPaymentMethodsByGroup>({
			type: WithdrawActionType.SET_PAYMENT_METHODS_BY_GROUP,
			group: PaymentMethodGroup.TOPUPS,
			data: {
				paymentMethods: formattedData,
			},
		});
	});
};

export const setMobileTopupCountry = (countryCode: string) => ({
	type: WithdrawActionType.SET_TOPUP_COUNTRY,
	countryCode,
});

export const fetchAllWithdrawMethods = () => async (dispatch: AppDispatch) => {
	void Promise.all([
		getAvailableWithdrawCryptos().catch((e) => {
			console.log(`${e} occured fetching CRYPTO payment method`);
		}),
		getAvailableEWallets().catch((e) => {
			console.log(`${e} occured fetching E-WALLET payment method`);
		}),
		getAvailableBankMethods().catch((e) => {
			console.log(`${e} occured fetching BANK payment method`);
		}),
		getAvailableGiftCards().catch((e) => {
			console.log(`${e} occured fetching GIFT CARDS payment method`);
		}),
		getAvailableVouchers().catch((e) => {
			console.log(`${e} occured fetching VOUCHER payment method`);
		}),
		getAvailableTopups().catch((e) => {
			console.log(`${e} occured fetching TOPUPS payment method`);
		}),
		getAvailableOffchainWallets().catch((e) => {
			console.log(`${e} occured fetching OFFCHAIN payment method`);
		}),
	]).then(([crypto, ewallet, bank, gift, voucher, topup, offchain]: any) => {
		if (crypto) {
			dispatch<SetWithdrawPaymentMethodsByGroup>({
				type: WithdrawActionType.SET_PAYMENT_METHODS_BY_GROUP,
				group: PaymentMethodGroup.CRYPTO,
				data: {
					paymentMethods: crypto,
					count: crypto.length,
				},
			});
		}
		if (ewallet) {
			dispatch<SetWithdrawPaymentMethodsByGroup>({
				type: WithdrawActionType.SET_PAYMENT_METHODS_BY_GROUP,
				group: PaymentMethodGroup.EWALLET,
				data: ewallet,
			});
		}
		if (bank) {
			dispatch<SetWithdrawPaymentMethodsByGroup>({
				type: WithdrawActionType.SET_PAYMENT_METHODS_BY_GROUP,
				group: PaymentMethodGroup.BANK,
				data: bank,
			});
		}
		if (gift) {
			const formattedData = gift.content.map(
				({ id, logo, title, minPrice, maxPrice, fee }: PurchaseItem) => {
					return {
						id,
						logo: logo.split('/').slice(-1)[0].split('.')[0],
						title,
						maxAmount: maxPrice,
						minAmount: minPrice,
						fee,
					};
				}
			);
			dispatch<SetWithdrawPaymentMethodsByGroup>({
				type: WithdrawActionType.SET_PAYMENT_METHODS_BY_GROUP,
				group: PaymentMethodGroup.GIFT_CARDS,
				data: {
					paymentMethods: formattedData,
					count: gift.totalElements,
				},
			});
		}
		if (voucher) {
			dispatch<SetWithdrawPaymentMethodsByGroup>({
				type: WithdrawActionType.SET_PAYMENT_METHODS_BY_GROUP,
				group: PaymentMethodGroup.VOUCHER,
				data: {
					paymentMethods: voucher,
					count: 1,
				},
			});
		}
		if (topup) {
			dispatch<SetWithdrawPaymentMethodsByGroup>({
				type: WithdrawActionType.SET_PAYMENT_METHODS_BY_GROUP,
				group: PaymentMethodGroup.TOPUPS,
				data: {
					count: topup.totalElements,
				},
			});
		}
		if (offchain) {
			dispatch<SetWithdrawPaymentMethodsByGroup>({
				type: WithdrawActionType.SET_PAYMENT_METHODS_BY_GROUP,
				group: PaymentMethodGroup.OFFCHAIN,
				data: {
					paymentMethods: offchain,
				},
			});
		}
	});
};
