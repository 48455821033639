import { FC, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { CurrencyIcon, CurrencyEnum } from '@spectrocoin/sc-currencies';
import Decimal from 'decimal.js';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../components/NotificationMessage/NotificationMessage';
import {
	ErrorMessageCodes,
	getErrorMessageOrDefault,
} from '../../../helpers/errorMessageHelper/errorMessageHelper';
import Loading from '../../Merchants/Shared/Loading/Loading';
import useMerchantRoutes, { MerchantRoutes } from '../../../hooks/useMerchantRoutes';
import { PaymentOrderAccess, PaymentOrderStatus } from '../../../redux/PaymentsState/PaymentsTypes';
import AccountSelectOption from '../../Merchants/Shared/AccountSelectOption/AccountSelectOption';
import LabeledField from '../../Merchants/Shared/LabeledField/LabeledField';
import { formatPrecision, toDecimal } from '../../../helpers/currencyHelper/currencyHelper';
import AccountSelect, {
	AccountSelectComponentProps,
} from '../../../components/AccountSelect/AccountSelect';
import { RootState } from '../../../redux/Store';
import PaymentsMessages from '../../../redux/PaymentsState/PaymentsMessages';
import PageTitle from '../../../components/PageTitle/PageTitle';
import NoProjects from '../../Merchants/Shared/NotFound/NotFound';
import WhiteContainer from '../../../components/WhiteContainer/WhiteContainer';
import PaymentsActions from '../../../redux/PaymentsState/PaymentsActions';
import PaymentsSelectors from '../../../redux/PaymentsState/PaymentsSelectors';
import { RemoteStatus } from '../../../interfaces/RemoteData';
import usePaymentRoutes from '../../../hooks/usePaymentRoutes';
import PayAmount from '../Shared/PayAmount/PayAmount';

import styles from './OrderPayment.module.scss';
import baseMsg from '../../../messages/base.messages';
import TestIds, { formatTestId } from '../../../test/TestIds';
import config from '../../../configs/config';
import useFormatAmount from '../../../hooks/useFormatAmount';
import TwoFaContainer from '../../../containers/TwoFaContainer/TwoFaContainer';

const SelectedWallet: FC<AccountSelectComponentProps<{ balance: string }>> = ({
	innerProps,
	data: { value, currencyCode, currencyName: name, balance },
}) => {
	const amountFormatter = useFormatAmount();

	return (
		<div
			className={styles.option}
			{...innerProps}
			data-cy={formatTestId(TestIds.PaymentsOrderWallet_0, value)}
		>
			<CurrencyIcon currencyType={currencyCode} className={styles.optionIcon} />
			<div className={styles.optionlabel}>
				<div className={styles.optionCurrency}>{`${name} (${amountFormatter(
					formatPrecision(balance, currencyCode as CurrencyEnum)
				)} ${currencyCode})`}</div>
			</div>
		</div>
	);
};

const SingleSelectedWallet: FC<AccountSelectComponentProps<{ balance: string }>> = (props) => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(PaymentsActions.orders.setForm({ walletId: props.data.value }));
	}, [dispatch, props.data.value]);

	return (
		<LabeledField label={formatMessage(PaymentsMessages.payFromWallet)}>
			<div className={styles.singleWallet}>
				<SelectedWallet {...props} />
			</div>
		</LabeledField>
	);
};

const Content: FC = () => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const { getUrl } = useMerchantRoutes();
	const { wallets = [] } = useSelector((state: RootState) => state.AccountsState)!;

	const {
		id,
		orderId,
		payCurrencyCode,
		projectName,
		description,
		payAmount,
		paidAmount,
		access,
	} = useSelector(PaymentsSelectors.orders.getOrderData)!;
	const toPay = Decimal.max(toDecimal(payAmount).sub(paidAmount), 0);
	const amountFormatter = useFormatAmount();

	const canAccess = access === PaymentOrderAccess.VALID;

	const options = useMemo(() => {
		return (
			wallets
				?.filter(({ currencyCode }) => currencyCode === payCurrencyCode)
				.map(({ currencyCode, currencyName, availableBalance, id: accountId }) => ({
					currencyCode,
					currencyName,
					balance: availableBalance,
					value: accountId,
				})) || []
		);
	}, [payCurrencyCode, wallets]);

	const { walletId } = useSelector(PaymentsSelectors.orders.getForm);
	const { status: payStatus, error: payError } = useSelector(PaymentsSelectors.orders.getPay);

	const selectedOption = useMemo(() => options.find((x) => x.value === walletId)!, [
		options,
		walletId,
	]);

	useEffect(() => {
		// on success return to payment service
		if (payStatus === RemoteStatus.Done)
			window.location.assign(getUrl(MerchantRoutes.PublicOrder, { orderId: id }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payStatus]);

	return (
		<>
			<PageTitle
				className={styles.title}
				title={formatMessage(PaymentsMessages.payWithWallet)}
			/>
			<TwoFaContainer
				isDisabled={
					!canAccess ||
					!walletId ||
					payStatus === RemoteStatus.InProgress ||
					toDecimal(selectedOption.balance).lessThan(toDecimal(payAmount))
				}
				buttonText={baseMsg.submit}
				buttonTestId={TestIds.PaymentsOrderPay}
				onSuccess={(data) => dispatch(PaymentsActions.orders.payFulfilled(data))}
				onFailure={(error) => dispatch(PaymentsActions.orders.payRejected(error))}
				onSubmit={() => dispatch(PaymentsActions.orders.pay(id))}
			>
				<div className={styles.content}>
					{access === PaymentOrderAccess.INVALID_PAYER && (
						<NotificationMessage
							withIcon
							data-cy={TestIds.PaymentsOrderPayErrorInvalidPayer}
							message={formatMessage(PaymentsMessages.informationDoesNotMatch)}
							type={NotificationType.Error}
							style={NotificationStyle.Border}
						/>
					)}
					{payStatus === RemoteStatus.Error && (
						<NotificationMessage
							withIcon
							data-cy={TestIds.PaymentsOrderPayError}
							message={formatMessage(getErrorMessageOrDefault(payError))}
							type={NotificationType.Error}
							style={NotificationStyle.Border}
						/>
					)}
					{options.length > 1 ? (
						<AccountSelect
							options={options}
							label={formatMessage(PaymentsMessages.payFromWallet)}
							labelClassName={styles.label}
							value={walletId}
							onChange={(value) =>
								dispatch(PaymentsActions.orders.setForm({ walletId: value }))
							}
							componentOverrides={{
								Option: AccountSelectOption,
								SingleValue: SelectedWallet,
							}}
						/>
					) : (
						<SingleSelectedWallet data={options[0]} />
					)}
					<LabeledField
						data-cy={TestIds.PaymentsOrderReceiver}
						contentClassName={styles.field}
						label={formatMessage(baseMsg.receiver)}
					>
						{projectName}
					</LabeledField>
					<LabeledField
						data-cy={TestIds.PaymentsOrderId}
						contentClassName={styles.field}
						label={formatMessage(PaymentsMessages.id)}
					>
						{orderId}
					</LabeledField>
					{description && (
						<LabeledField
							data-cy={TestIds.PaymentsOrderDescription}
							contentClassName={styles.field}
							label={formatMessage(PaymentsMessages.description)}
						>
							{description}
						</LabeledField>
					)}
					<LabeledField label={formatMessage(PaymentsMessages.paymentAmount)}>
						<PayAmount
							amount={amountFormatter(
								formatPrecision(toPay.toString(), payCurrencyCode)
							)}
							currencyCode={payCurrencyCode}
						/>
					</LabeledField>
				</div>
			</TwoFaContainer>
		</>
	);
};

const OrderPayment: FC = () => {
	const dispatch = useDispatch();
	const { formatMessage, locale } = useIntl();
	const { getParams } = usePaymentRoutes();
	const { getUrl } = useMerchantRoutes();

	const { orderId = '' } = getParams();

	const { status, data: order, error } = useSelector(PaymentsSelectors.orders.getOrder);

	useEffect(() => {
		if (status === RemoteStatus.None) void dispatch(PaymentsActions.orders.fetchOrder(orderId));
	}, [dispatch, status, orderId]);

	useEffect(() => {
		if (order?.access === PaymentOrderAccess.UNVERIFIED_PAYER)
			window.location.assign(`${config.PROFILE_DOMAIN}/${locale}/verification/verify`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order]);

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		const getOutOfHere = () =>
			window.location.assign(getUrl(MerchantRoutes.PublicOrder, { orderId }));

		// something was already done with the order, go back from where you came from
		if (error?.errorCode === ErrorMessageCodes.MA_18) return getOutOfHere();
		// eslint-disable-next-line consistent-return
		if (!order) return;
		if (![PaymentOrderStatus.NEW, PaymentOrderStatus.PENDING].includes(order.status))
			return getOutOfHere();
		if (toDecimal(order.paidAmount).greaterThanOrEqualTo(order.payAmount))
			return getOutOfHere();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order, error]);

	if (status === RemoteStatus.None) return null;

	return (
		<WhiteContainer className={styles.container} data-cy={TestIds.PaymentsOrder}>
			{status === RemoteStatus.InProgress && <Loading />}
			{status === RemoteStatus.Error && (
				<NoProjects message={formatMessage(PaymentsMessages.orderNotFound)} />
			)}
			{status === RemoteStatus.Done &&
				order &&
				order.access !== PaymentOrderAccess.UNVERIFIED_PAYER && <Content />}
		</WhiteContainer>
	);
};

export default OrderPayment;
