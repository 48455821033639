import { RemoteStatus } from '../../../../interfaces/RemoteData';
import createSelector from '../../../createSelector';
import { PaymentButtonsListMode } from './PaymentButtonsListTypes';

const getState = createSelector((state) => state.MerchantState.paymentButtons.list);

export const getButtonsByPage = (page: number) =>
	createSelector(getState, (state) => state.pages[page] || { status: RemoteStatus.None });

export const getButtonIdsByPage = (page: number) =>
	createSelector(getButtonsByPage(page), (state) => state?.data);

export const getIsInDeleteMode = createSelector(
	getState,
	({ mode }) => mode === PaymentButtonsListMode.Delete
);

export const getFilter = createSelector(getState, (state) => state.filter);
export const getFilterTags = createSelector(getFilter, (state) => state.tags);
export const getDelete = createSelector(getState, (state) => state.delete);

export default {
	getState,
	getButtonsByPage,
	getButtonIdsByPage,
	getIsInDeleteMode,
	getFilter,
	getFilterTags,
	getDelete,
};
