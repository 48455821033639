import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { useQueryClient, useMutation, useQueries } from '@tanstack/react-query';
import styles from './NewReferralId.module.scss';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import ReferralsMessages from '../../../../redux/ReferralsState/ReferralsMessages';
import Input from '../../../../components/Input/Input';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import baseMsg from '../../../../messages/base.messages';
import {
	defaultCodeQuery,
	referralCodeLimitQuery,
	referralIdsQuery,
} from '../../../../redux/ReferralsState/Queries/ReferralQueries';
import newLogoSvg from './newLoog.svg';
import LabeledField from '../../../Merchants/Shared/LabeledField/LabeledField';
import RangeSlider from '../../../../components/RangeSlider/RangeSlider';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import { RemoteError } from '../../../../interfaces/RemoteData';

const MAX_FRIEND_SPLIT = 20;
const MIN_FRIEND_SPLIT = 0;
const SPLIT_STEP = 5;
const INITIAL_SPLIT_VALUE = 5;

const NewReferralId: FC = () => {
	const queryClient = useQueryClient();
	const [note, setNote] = useState<string>('');
	const [isDefault, setIsDefault] = useState<boolean>(true);
	const codesRemaining = useQueries({
		queries: [
			{ queryKey: referralCodeLimitQuery.getKey(), queryFn: referralCodeLimitQuery.getFn() },
			{ queryKey: referralIdsQuery.getKey(), queryFn: referralIdsQuery.getFn() },
		],
		combine: ([limit, codes]) =>
			!codes.isSuccess || !limit.isSuccess
				? 0
				: limit.data === null
				? null
				: Math.max(limit.data - codes.data!.totalElements, 0),
	});

	const mutation = useMutation({
		mutationFn: referralIdsQuery.mutationFn,
		onSuccess: () => {
			void queryClient.invalidateQueries({ queryKey: referralIdsQuery.getKey() });
			if (isDefault)
				void queryClient.invalidateQueries({ queryKey: defaultCodeQuery.getKey() });
		},
	});

	const [friendSplitRatio, setFriendSplitRatio] = useState<number>(INITIAL_SPLIT_VALUE);
	const mySplitRatio = MAX_FRIEND_SPLIT - friendSplitRatio;
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const onClose = useCallback(() => {
		dispatch(toggleModal());
	}, [dispatch]);

	const onCreate = useCallback(() => {
		mutation.mutate(
			{
				cashback: friendSplitRatio,
				commission: mySplitRatio,
				isDefault,
				note,
			},
			{ onSuccess: () => onClose() }
		);
	}, [friendSplitRatio, mySplitRatio, isDefault, note, mutation, onClose]);

	return (
		<div className={styles.container}>
			<div>
				<SvgIcon src={newLogoSvg} className={styles.icon} />
				<FontAwesomeIcon
					className={styles.closeIcon}
					icon={faTimes}
					onClick={() => onClose()}
				/>
			</div>
			<div className={styles.content}>
				<div className={styles.title}>
					<span>{formatMessage(ReferralsMessages.createNewRefLink)}</span>
					{codesRemaining !== null && (
						<div className={styles.remainingLinks}>
							{`${formatMessage(
								ReferralsMessages.linksRemaining
							)}: ${codesRemaining}`}
						</div>
					)}
				</div>

				<Input
					inputGroupClassName={styles.inputGroup}
					label={formatMessage(ReferralsMessages.note)}
					className={styles.value}
					value={note}
					onChange={setNote}
					placeholder={ReferralsMessages.notToSelf}
				/>
				<LabeledField
					className={styles.value}
					label={formatMessage(ReferralsMessages.splitRatio)}
				>
					<div className={styles.split}>
						<RangeSlider
							max={MAX_FRIEND_SPLIT}
							min={MIN_FRIEND_SPLIT}
							step={SPLIT_STEP}
							value={friendSplitRatio}
							onChange={setFriendSplitRatio}
						/>
						<div className={styles.splitInfo}>
							<span>
								{`${formatMessage(ReferralsMessages.mine)}: `}
								<strong>{mySplitRatio}%</strong>
							</span>
							<span>
								{`${formatMessage(ReferralsMessages.friend)}: `}
								<strong>{friendSplitRatio}%</strong>
							</span>
						</div>
					</div>
				</LabeledField>
				<Checkbox
					checked={isDefault}
					onChange={(e) => setIsDefault(e.target.checked)}
					labelClassName={styles.label}
				>
					{formatMessage(ReferralsMessages.defaultRefLink)}
				</Checkbox>
			</div>
			<div className={styles.actions}>
				{mutation.isError && (
					<NotificationMessage
						message={formatMessage(
							getErrorMessageOrDefault((mutation.error as unknown) as RemoteError)
						)}
						style={NotificationStyle.Fill}
						type={NotificationType.Error}
						withIcon
					/>
				)}
				<Button
					className={styles.button}
					buttonStyle={ButtonStyle.PRIMARY}
					type={ButtonType.SUBMIT}
					onClick={onCreate}
					isDisabled={
						mutation.isPending || (codesRemaining !== null && codesRemaining <= 0)
					}
				>
					{formatMessage(ReferralsMessages.createNewLink)}
				</Button>
				<Button
					className={styles.button}
					buttonStyle={ButtonStyle.SECONDARY_WITHOUT_BORDER}
					type={ButtonType.SUBMIT}
					onClick={onClose}
				>
					{formatMessage(baseMsg.cancel)}
				</Button>
			</div>
		</div>
	);
};

export default NewReferralId;
