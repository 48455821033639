import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import toggleModal from '../../../redux/ModalState/ModalActions';
import { formatPrecision, toDecimal } from '../../../helpers/currencyHelper/currencyHelper';
import convertToLocalTime from '../../../helpers/dateHelper/dateHelper';
import DetailsModal, { DetailsModalType } from '../../Modal/DetailsModal/DetailsModal';
import styles from './HistoryListItem.module.scss';
import useFormatAmount from '../../../hooks/useFormatAmount';

interface ListItemInterface {
	withdrawId?: string;
	transNo?: string;
	amount: string | number;
	receiveAmount?: string | number;
	receivedFrom?: string;
	receivedTo?: string;
	status: string;
	dateCreated: string;
	currency: string;
	title: string;
	description?: string;
	className?: string;
	detailsModalType: DetailsModalType;
	isTransNoVisible?: boolean;
	fee?: string | null;
	feeCurrency?: CurrencyEnum | null;
	withdrawAccount?: string;
	totalAmount?: string;
	sellAmount?: string;
	sellCurrencyCode?: CurrencyEnum;
	purchaseItem?: string;
	receiver?: string;
	other?: string;
	accountNumber?: string;
	swift?: string;
	sortCode?: string;
	receiverAddress?: string;
	code?: string;
	network?: string;
	areFieldsCopyable?: boolean;
	isCryptoWithdraw?: boolean;
	sender?: string;
	senderAccount?: string;
	paymentDetails?: string;
	originalPaymentAmount?: string;
	originalPaymentCurrency?: string;
	originalPaymentFee?: string;
	originalPaymentFeeCurrency?: string;
}

export const HistoryListItem = ({
	withdrawId,
	code,
	transNo,
	isTransNoVisible,
	amount,
	receiveAmount,
	receivedFrom,
	receivedTo,
	status,
	dateCreated,
	currency,
	title,
	detailsModalType,
	description,
	className,
	fee,
	feeCurrency,
	withdrawAccount,
	totalAmount,
	sellAmount,
	sellCurrencyCode,
	purchaseItem,
	receiver,
	other,
	accountNumber,
	swift,
	sortCode,
	receiverAddress,
	network,
	sender,
	senderAccount,
	paymentDetails,
	originalPaymentAmount,
	originalPaymentCurrency,
	originalPaymentFee,
	originalPaymentFeeCurrency,
	areFieldsCopyable = false,
	isCryptoWithdraw = false,
}: ListItemInterface) => {
	const dispatch = useDispatch();
	const amountFormatter = useFormatAmount();

	const isTransactionIncoming =
		toDecimal(amount).isPositive() &&
		![
			DetailsModalType.CRYPTO_WITHDRAW,
			DetailsModalType.GIFT,
			DetailsModalType.TOPUP,
			DetailsModalType.BANK_WITHDRAW,
		].includes(detailsModalType);
	return (
		<li
			className={className}
			key={transNo}
			onClick={() =>
				dispatch(
					toggleModal(
						<DetailsModal
							withdrawId={withdrawId}
							network={network}
							type={detailsModalType}
							amount={formatPrecision(amount, CurrencyEnum[currency])}
							transactionAmount={receiveAmount}
							status={status}
							id={transNo}
							date={dateCreated}
							transactionName={title}
							currencyCode={CurrencyEnum[currency]}
							description={description}
							receivedFrom={receivedFrom}
							receivedTo={receivedTo}
							fee={fee}
							originalPaymentAmount={originalPaymentAmount}
							originalPaymentCurrency={originalPaymentCurrency}
							originalPaymentFee={originalPaymentFee}
							originalPaymentFeeCurrency={originalPaymentFeeCurrency}
							sender={sender}
							senderAccount={senderAccount}
							paymentDetails={paymentDetails}
							feeCurrency={feeCurrency}
							withdrawAccount={withdrawAccount}
							totalAmount={totalAmount}
							sellAmount={sellAmount}
							sellCurrencyCode={sellCurrencyCode}
							purchaseItem={purchaseItem}
							receiver={receiver}
							other={other}
							accountNumber={accountNumber}
							swift={swift}
							sortCode={sortCode}
							receiverAddress={receiverAddress}
							code={code}
							areFieldsCopyable={areFieldsCopyable}
							isCryptoWithdraw={isCryptoWithdraw}
						/>
					)
				)
			}
			data-cy={`transaction_${title}`}
		>
			<span className={styles.description} data-cy="transactionDescription">
				{detailsModalType === DetailsModalType.BANK_WITHDRAW ? (
					<FormattedMessage id="history.bankWithdraw" defaultMessage="Bank withdraw" />
				) : (
					title
				)}
				<span className={styles.details}>
					{isTransNoVisible && transNo && <span>{isTransNoVisible && transNo}</span>}
					<span>{convertToLocalTime(dateCreated, 'yyyy-MM-dd HH:mm')}</span>
				</span>
			</span>
			<div className={styles.amountContainer}>
				<span
					className={classNames(styles.amount, {
						[styles.green]: isTransactionIncoming,
					})}
					data-cy="transactionAmount"
				>
					{isTransactionIncoming ? '+' : '-'}
					{[
						DetailsModalType.CRYPTO_WITHDRAW,
						DetailsModalType.GIFT,
						DetailsModalType.TOPUP,
						DetailsModalType.BANK_WITHDRAW,
					].includes(detailsModalType)
						? `${amountFormatter(
								formatPrecision(totalAmount || amount, CurrencyEnum[currency!])
						  )} ${currency}`
						: `${amountFormatter(
								formatPrecision(amount, CurrencyEnum[currency!])
						  )} ${currency}`}
				</span>
				<span className={styles.statusText} data-cy="transactionStatus">
					{status}
				</span>
			</div>
		</li>
	);
};

export default HistoryListItem;
