import { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import PageTitle from '../../../Merchants/Shared/PageTitle/PageTitle';
import {
	ReferralComission,
	commissionsQuery,
} from '../../../../redux/ReferralsState/Queries/ReferralQueries';
import useQueryPagination from '../../../../hooks/useQueryPagination';
import useQueryParams from '../../../../hooks/useQueryParams';
import styles from './Summary.module.scss';
import ReferralsMessages from '../../../../redux/ReferralsState/ReferralsMessages';
import SortableList, { ListCell } from '../SortableList/SortableList';
import { useSortableListRecord } from '../SortableList/SortableListContext';
import useFormatAmount from '../../../../hooks/useFormatAmount';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';

const Amount: FC = () => {
	const record = useSortableListRecord<ReferralComission>()!;
	const formatter = useFormatAmount();
	return <>{formatter(formatPrecision(record.amount, CurrencyEnum.EUR))} €</>;
};

const Summary: FC = () => {
	const { formatMessage } = useIntl();
	useQueryPagination();
	const [[page = '1', sort = '']] = useQueryParams({
		page: '1',
		sort: '',
		search: '',
	});
	const query = useQuery({
		queryKey: commissionsQuery.getKey(+page - 1, sort),
		queryFn: commissionsQuery.getFn(+page - 1, sort),
	});
	return (
		<WhiteContainer className={styles.container}>
			<PageTitle title={formatMessage(ReferralsMessages.refSummary)} />
			<div className={styles.body}>
				<span className={styles.note}>
					{formatMessage(ReferralsMessages.refSummeryNote)}
				</span>
				<div className={styles.content}>
					<SortableList
						query={query}
						emptyMessage={formatMessage(ReferralsMessages.notReferralsYet)}
					>
						<ListCell<ReferralComission>
							className={styles.stronk}
							header={formatMessage(ReferralsMessages.email)}
							source="email"
						/>
						<ListCell<ReferralComission>
							header={formatMessage(ReferralsMessages.trackId)}
							source="trackId"
						/>
						<ListCell<ReferralComission>
							header={formatMessage(ReferralsMessages.commissionsCount)}
							source="count"
							sortBy="count"
						/>
						<ListCell<ReferralComission>
							className={styles.stronk}
							header={formatMessage(ReferralsMessages.totalCommissions)}
							sortBy="amount"
						>
							<Amount />
						</ListCell>
					</SortableList>
				</div>
			</div>
		</WhiteContainer>
	);
};

export default Summary;
