import { useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input';
import { useSelector, useDispatch } from 'react-redux';
import BackButton from '../../../../components/BackButton/BackButton';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import NotificationMessage, {
	NotificationType,
	NotificationStyle,
} from '../../../../components/NotificationMessage/NotificationMessage';
import PhoneNumberInput from '../../../../components/PhoneNumberInput/PhoneNumberInput';
import countries from '../../../../helpers/countryHelper/countryHelper';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import useEffectOnce from '../../../../hooks/useEffectOnce';
import { RemoteError } from '../../../../interfaces/RemoteData';
import baseMsg from '../../../../messages/base.messages';
import { setOrderStep } from '../../../../redux/CardsState/CardsActions';
import { CardStatusType, OrderSteps } from '../../../../redux/CardsState/CardsTypes';
import { getInitPhoneVerificationURL, getUpdatePhoneNumberURL } from '../../../../redux/endpoints';
import { RootState } from '../../../../redux/Store';
import VerificationInput from './VerificationInput/VerificationInput';
import styles from './PhoneVerification.module.scss';
import TwoFaContainer from '../../../../containers/TwoFaContainer/TwoFaContainer';
import { TwoFaCode } from '../../../../redux/TwoFaState/TwoFaTypes';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	title: {
		id: 'phoneVerification.title',
		defaultMessage: 'Phone number authentication',
	},
	enterPhoneSubTitle: {
		id: 'phoneVerification.enterPhoneSubTitle',
		defaultMessage: 'Please enter your phone number',
	},
	enterVerificationCodeTitle: {
		id: 'phoneVerification.enterVerificationCodeTitle',
		defaultMessage: 'Please enter the verification code sent to your phone',
	},
	wrongCountryError: {
		id: 'phoneVerification.wrongCountry',
		defaultMessage: 'Wrong Country!',
	},
});

const validStatuses = [
	CardStatusType.ACTIVE,
	CardStatusType.WAITING_PINSET,
	CardStatusType.SUBMITTED,
	CardStatusType.CONFIRMED,
	CardStatusType.NEW,
	CardStatusType.SUSPENDED,
	CardStatusType.PENDING,
];

interface PhoneVerificationProps {
	isRha?: boolean;
}

const PhoneVerification = ({ isRha }: PhoneVerificationProps) => {
	const { formData, cardsList } = useSelector((state: RootState) => state.CardsState);
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const countryCode = countries.getAlpha2Code(formData?.deliveryCountry!, 'en'); // deliveryCountry will always be present it's entered at the start of the flow
	const minPhoneVal = `+${getCountryCallingCode(countryCode as any)}`;
	const isPhoneChangeEnabled =
		cardsList && cardsList?.length > 0
			? !cardsList.some(({ status }) => validStatuses.includes(status))
			: true;
	const [phone, setPhone] = useState(isPhoneChangeEnabled ? minPhoneVal : user?.phone!);
	const [isInit, setIsInit] = useState(false);
	const [phoneError, setPhoneError] = useState<MessageDescriptor | null>(null);
	const [generalError, setGeneralError] = useState<MessageDescriptor>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { formatMessage, locale } = useIntl();

	const handlePhoneSubmit = () => {
		setPhoneError(null);
		if (!phone.includes(minPhoneVal)) {
			setPhoneError(messages.wrongCountryError);
		} else {
			setIsLoading(true);
			void axiosInstance
				.put(getUpdatePhoneNumberURL(), {
					phoneNumber: phone,
				})
				.then(() => setIsInit(true))
				.catch((err) => {
					if (err.response.data.errorCode !== TwoFaCode.TWO_FA_REQUIRED) {
						return setGeneralError(getErrorMessageOrDefault(err));
					}
					return null;
				})
				.then(() => setIsLoading(false));
		}
	};

	const onSuccess = () => {
		axiosInstance
			.post(getInitPhoneVerificationURL(), {
				language: locale,
				phoneNumber: phone,
			})
			.then(() => setIsInit(true))
			.catch((err) => setGeneralError(getErrorMessageOrDefault(err)));
	};

	const onFailure = (err: RemoteError) => setPhoneError(getErrorMessageOrDefault(err));

	useEffectOnce(() => {
		if (user?.phone?.includes(minPhoneVal) && user?.phoneVerified) {
			if (isRha) {
				dispatch(setOrderStep(OrderSteps.LINKED_WALLETS));
			} else {
				dispatch(setOrderStep(OrderSteps.CONFIRM));
			}
		}
	});

	if (!countryCode) return null;

	return (
		<div className={styles.container}>
			<BackButton
				className={styles.back}
				onClick={() => dispatch(setOrderStep(OrderSteps.SHIPPING))}
			/>
			<div className={styles.verificationContainer}>
				<InfoHead
					title={messages.title}
					subTitle={
						isPhoneChangeEnabled
							? formatMessage(
									isInit
										? messages.enterVerificationCodeTitle
										: messages.enterPhoneSubTitle
							  )
							: ''
					}
				/>
				{isInit ? (
					<VerificationInput isRha={isRha} />
				) : (
					<TwoFaContainer
						isDisabled={phone ? !isValidPhoneNumber(phone) : true}
						buttonText={baseMsg.submit}
						onSuccess={onSuccess}
						onFailure={onFailure}
						onSubmit={handlePhoneSubmit}
						isLoading={isLoading}
					>
						<div className={styles.input}>
							<PhoneNumberInput
								label={baseMsg.phoneNumber}
								country={countryCode}
								errorMessage={phoneError}
								countries={[countryCode]}
								value={phone}
								readOnly={!isPhoneChangeEnabled}
								setValue={(val: string) => setPhone(val)}
							/>
						</div>
						{generalError && (
							<NotificationMessage
								withIcon
								type={NotificationType.Error}
								style={NotificationStyle.Border}
								message={<FormattedMessage {...generalError} />}
								className={styles.margins}
							/>
						)}
					</TwoFaContainer>
				)}
			</div>
		</div>
	);
};

export default PhoneVerification;
