/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-dynamic-require */
import Cookies from 'js-cookie';
import { createBrowserHistory } from 'history';
import { AppDispatch } from '../Store';
import config from '../../configs/config';
import { LanguageActionType, ChangeLanguageAction, RTLLanguages } from './LanguageTypes';

export const changeLanguage = (lng: string) => (dispatch: AppDispatch) => {
	const history = createBrowserHistory();
	Cookies.remove(config.USER_LANG_COOKIE_KEY!, { domain: config.USER_LANG_COOKIE_DOMAIN });
	Cookies.set(config.USER_LANG_COOKIE_KEY!, lng, {
		domain: config.USER_LANG_COOKIE_DOMAIN,
	});

	const { pathname } = window.location;
	const newURL = pathname.replace(pathname.split('/')[1], lng);

	if (RTLLanguages.includes(lng.toLowerCase())) {
		document.body.setAttribute('dir', 'rtl');
	} else {
		document.body.setAttribute('dir', 'ltr');
	}

	history.push(newURL);
	return dispatch<ChangeLanguageAction>({ type: LanguageActionType.SET_LANGUAGE, lng });
};
