import {
	DepositHistoryActionType,
	SetIsDepositHistoryLoading,
	SetDepositHistoryDataAction,
	ResetDepositHistoryAction,
	DepositHistoryState,
} from './DepositHistoryTypes';

const initialState: DepositHistoryState = {
	isLoading: true,
	data: null,
	totalCount: 0,
};

export default (
	state = initialState,
	action: SetIsDepositHistoryLoading | SetDepositHistoryDataAction | ResetDepositHistoryAction
) => {
	switch (action.type) {
		case DepositHistoryActionType.IS_LOADING:
			return {
				...state,
				isLoading: action.isLoading,
			};
		case DepositHistoryActionType.RESET_DATA:
			return initialState;
		case DepositHistoryActionType.SET_DATA:
			return {
				...state,
				data: action.data?.content,
				totalCount: action.data?.totalElements,
			};
		default:
			return state;
	}
};
