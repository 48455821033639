import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './CloseIcon.module.scss';

interface CloseIconProps {
	onClick: () => void;
}

const CloseIcon = ({ onClick }: CloseIconProps) => (
	<FontAwesomeIcon size="lg" className={styles.close} icon={faXmark} onClick={onClick} />
);

export default CloseIcon;
