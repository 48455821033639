/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import { useIntl, MessageDescriptor, defineMessages } from 'react-intl';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { getReferralDefaultCodeUrl } from '../../redux/endpoints';
import bannerImagePath from './images/banner.svg';
import styles from './MarketingSlider.module.scss';
import 'slick-carousel/slick/slick.css';
import useEffectOnce from '../../hooks/useEffectOnce';
import TestIds from '../../test/TestIds';
import { DefaultReferralCode } from '../../redux/ReferralsState/Queries/ReferralQueries';
import axiosInstance from '../../helpers/axiosInstance';

let controller: any;

const messages = defineMessages({
	buttonTweet: {
		id: 'marketginSlider.buttonTweet',
		defaultMessage: 'Tweet',
	},
	buttonShare: {
		id: 'marketingSlider.buttonShare',
		defaultMessage: 'Share',
	},
	moreInformation: {
		id: 'marketingSlider.moreInformation',
		defaultMessage: 'more information',
	},
	earnBitcoins: {
		id: 'marketingSlider.earnBitcoins',
		defaultMessage: 'Earn bitcoin by recommending or sharing SpectroCoin',
	},
});

const baseSliderSettings = {
	arrows: false,
	autoplay: true,
	autoplaySpeed: 5000,
	cssEase: 'linear',
	draggable: false,
	infinite: true,
	pauseOnHover: true,
	slide: 'ul',
	speed: 700,
	swipe: false,
	fade: true,
	swipeToSlide: false,
	touchMove: true,
	variableWidth: true,
	settings: {
		initialSlide: 1,
		slidesToShow: 1,
	},
	dots: true,
};

interface ListItemProps {
	imgPath: string;
	text: MessageDescriptor;
	url?: string | null;
	isShareButtonsVisible?: boolean;
	referralCode?: string;
}

const ListItem = ({
	imgPath,
	text,
	url = null,
	isShareButtonsVisible = true,
	referralCode,
}: ListItemProps) => {
	const { formatMessage } = useIntl();
	return (
		<div className={styles.slideContainer}>
			<div className={styles.imageContainer}>
				<img src={imgPath} alt="" />
			</div>
			<div className={styles.contentContainer}>
				{formatMessage(text)}
				{isShareButtonsVisible && (
					<div className={styles.buttonRow}>
						<a
							className={styles.twitter}
							href={`https://twitter.com/share?url=https://spectrocoin.com/invite?referralId=${referralCode}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<FontAwesomeIcon icon={faXTwitter} />
							{formatMessage(messages.buttonTweet)}
						</a>
						<a
							href={`https://www.facebook.com/sharer/sharer.php?u=https://spectrocoin.com/en/invite?referralId=${referralCode}`}
							className={styles.fb}
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fa fa-facebook-square" />
							{formatMessage(messages.buttonShare)}
						</a>
					</div>
				)}
			</div>
			{url && (
				<a href={url} className={styles.moreButton}>
					{formatMessage(messages.moreInformation)}
				</a>
			)}
		</div>
	);
};

const MarketingSlider = () => {
	const { locale } = useIntl();
	const [referralCode, setReferralCode] = useState('');
	const bannerConfig = [
		{
			imgPath: bannerImagePath,
			text: messages.earnBitcoins,
			url: `/${locale}/referrals`,
		},
		// { imgPath: bannerImagePath, text: messages.earnBitcoins, url: '#' },
		// { imgPath: bannerImagePath, text: messages.earnBitcoins },
	];

	const getReferralData = async () => {
		controller = new AbortController();
		await axiosInstance
			.get<DefaultReferralCode>(getReferralDefaultCodeUrl(), { signal: controller.signal })
			.then(({ data }) => {
				return setReferralCode(data.id);
			})
			.catch(() => {
				// let abort controller do his job
			});
	};

	useEffectOnce(() => {
		getReferralData();
		return () => {
			if (controller) {
				controller.abort();
				console.info(`Component unmounted - aborted referral request`);
			}
		};
	});

	const renderCards = () =>
		bannerConfig.map(({ imgPath, text, url }: ListItemProps, i: number) => (
			<ListItem
				key={`${imgPath}-${i}`}
				imgPath={imgPath}
				text={text}
				url={url}
				referralCode={referralCode}
			/>
		));

	return (
		<div className={styles.container} data-cy={TestIds.SocialBlock}>
			<Slider {...baseSliderSettings}>{renderCards()}</Slider>
		</div>
	);
};

export default MarketingSlider;
