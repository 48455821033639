/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-new */
/* eslint-disable no-use-before-define */
import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl, defineMessages, MessageDescriptor } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { ChartOptions, Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowUp, faLongArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { CurrencyIcon, CurrencyEnum, currencyUtils } from '@spectrocoin/sc-currencies';
import usePrevious from '../../hooks/usePrevious';
import { toDecimal, formatPrecision } from '../../helpers/currencyHelper/currencyHelper';
import { RootState } from '../../redux/Store';
import { RatesDetailsResponse } from '../../redux/RatesState/RatesTypes';
import { getRatesHistory } from '../../redux/RatesState/RatesActions';
import Loader from '../Loader/Loader';
import styles from './RateGraphWidget.module.scss';
import { isAmountGreaterThanZero } from '../../helpers/inputValidation/inputValidation';
import WalletTestIds from '../../test/Wallets/WalletTestIds';
import useFormatAmount from '../../hooks/useFormatAmount';

Chart.register(...registerables);

const messages = defineMessages({
	noDataToShow: {
		id: 'rateGraphWidget.noDataToShow',
		defaultMessage: 'Currenty there is no data to show',
	},
});

interface RateGraphWidgetProps {
	refCurrency: CurrencyEnum;
}
interface RateGraphWidgetComponentProps {
	currencyCode: CurrencyEnum;
	refCurrency: CurrencyEnum;
	isLoading: boolean;
	chartData: any;
	error: MessageDescriptor | null;
}

const defaultDataOptions = {
	fill: true,
	lineTension: 0.5,
	pointBackgroundColor: 'transparent',
	showLine: true,
	pointRadius: 0,
	padding: 0,
	steppedLine: false,
};

const options: ChartOptions<'line'> = {
	layout: {
		padding: {
			left: -11,
			bottom: -10,
		},
	},
	plugins: {
		legend: {
			display: false,
		},
	},
	scales: {
		y: {
			ticks: {
				display: false,
			},
			grid: {
				display: false,
			},
		},
		x: {
			ticks: {
				display: false,
			},
			grid: {
				display: false,
			},
		},
	},
};

const RateGraphWidgetComponent = memo(
	({ currencyCode, refCurrency, isLoading, chartData, error }: RateGraphWidgetComponentProps) => {
		const { formatMessage } = useIntl();
		const { isTablet } = useSelector((state: RootState) => state?.AppState);
		const rates = useSelector((state: RootState) => state?.RatesState.ratesDetails);
		const [last24HChange, setLast24HChange] = useState<string | null>(null);
		const [value, setValue] = useState<string | null>(null);
		const amountFormatter = useFormatAmount();

		useEffect(() => {
			setLast24HChange(null);
			setValue(null);
			const obj = rates?.find((o: RatesDetailsResponse) => o.currencyCode === currencyCode);
			if (obj) {
				setLast24HChange(obj?.last24HChange);
				setValue(obj?.value);
			}
		}, [currencyCode, rates, setLast24HChange, setValue]);

		return (
			<div data-cy={WalletTestIds.RateGraphWidget} className={styles.container}>
				<div data-cy={WalletTestIds.RatesDetails} className={styles.ratesDetails}>
					<CurrencyIcon currencyType={currencyCode} className={styles.marginRight} />
					<div data-cy={WalletTestIds.RatesLabel} className={styles.label}>
						{currencyCode}
						<div className={styles.value}>
							{!value
								? '--'
								: amountFormatter(
										formatPrecision(
											toDecimal(value || 0)
												.toFixed(2)
												.toString(),
											CurrencyEnum[refCurrency]
										)
								  )}{' '}
							{refCurrency}
						</div>
					</div>
					<div
						data-cy={WalletTestIds.RatesPercentage}
						className={classNames(styles.percentageValue)}
					>
						<span>{`${last24HChange || '--'}%`}</span>
						<FontAwesomeIcon
							className={classNames(styles.arrows, {
								[styles.negative]: !isAmountGreaterThanZero(last24HChange || 0),
							})}
							icon={
								isAmountGreaterThanZero(last24HChange || 0)
									? faLongArrowUp
									: faLongArrowDown
							}
						/>
					</div>
				</div>
				{isLoading && <Loader className={styles.loader} />}
				{chartData && (
					<Line data={chartData} options={options} height={isTablet ? 97 : undefined} />
				)}
				{error && <div className={styles.errorMessage}>{formatMessage(error)}</div>}
			</div>
		);
	}
);

const RateGraphWidget = ({ refCurrency }: RateGraphWidgetProps) => {
	const { pathname } = useLocation();
	const accounts = useSelector((state: RootState) => state?.AccountsState?.wallets);
	const rates = useSelector((state: RootState) => state?.RatesState.ratesDetails);
	const walletId = pathname.split('/').splice(-1).pop();
	const [chartData, setChartData] = useState<any>(null);
	const [error, setError] = useState<MessageDescriptor | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [currentWallet, setCurrentWallet] = useState<any>(null);
	const [currentCurrencyRates, setCurrentCurrencyRates] = useState<any>(null);
	const prevRefCurrency = usePrevious(refCurrency);

	const fetchRatesHistory = useCallback(
		(currency: CurrencyEnum) => {
			setError(null);
			setChartData(null);
			return getRatesHistory(currency)
				.then((response) => {
					setIsLoading(false);
					if (refCurrency === currency)
						return setChartData({
							labels: [0, 1, 2, 3, 4],
							datasets: [
								{
									...defaultDataOptions,
									data: [1, 1, 1, 1, 1],
									borderColor: currencyUtils.getConfigOrDefault(currency).color,
									backgroundColor: `${currencyUtils
										.getConfigOrDefault(currency)
										.color.slice(0, -2)}.2)`,
								},
							],
						});
					if (response.length === 0) return setError(messages.noDataToShow);

					const labels = response.reduce((acc: any, cur: any) => {
						acc.push(cur.date);
						return acc;
					}, []);
					const datapoints = response.reduce((acc: any, cur: any) => {
						acc.push(cur.value);
						return acc;
					}, []);
					const data = {
						labels,
						datasets: [
							{
								...defaultDataOptions,
								data: datapoints,
								borderColor: currencyUtils.getConfigOrDefault(currency).color,
								backgroundColor: `${currencyUtils
									.getConfigOrDefault(currency)
									.color.slice(0, -2)}.2)`,
							},
						],
					};
					return setChartData(data);
				})
				.catch(() => {
					setIsLoading(false);
					return setError(messages.noDataToShow);
				});
		},
		[refCurrency]
	);

	useEffect(() => {
		if (currentWallet && rates && !chartData) {
			setCurrentCurrencyRates(
				rates?.find(
					(o: RatesDetailsResponse) => o.currencyCode === currentWallet?.currencyCode
				)
			);
			fetchRatesHistory(currentWallet?.currencyCode);
		}
	}, [currentWallet, chartData, rates, fetchRatesHistory]);

	useEffect(() => {
		if (refCurrency) {
			setChartData(null);
		}
	}, [refCurrency, prevRefCurrency]);

	useEffect(() => {
		if (accounts?.length && !chartData) {
			setCurrentWallet(accounts?.find((o) => o.id === walletId));
		}
	}, [accounts, walletId, chartData]);

	if (
		currentWallet?.id === walletId &&
		currentCurrencyRates &&
		refCurrency &&
		chartData?.labels?.length
	) {
		const { currencyCode } = currentWallet;
		return (
			<RateGraphWidgetComponent
				currencyCode={currencyCode}
				refCurrency={refCurrency}
				isLoading={isLoading}
				chartData={chartData}
				error={error}
			/>
		);
	}
	return null;
};

export default RateGraphWidget;
