/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { QRCodeSVG } from 'qrcode.react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faXTwitter, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faComment } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

import { CurrencyEnum, CurrencyIcon, NetworkEnum, currencyUtils } from '@spectrocoin/sc-currencies';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import { getQRValue } from '../../../../helpers/currencyHelper/currencyHelper';
import { RootState } from '../../../../redux/Store';
import depositImg from './images/deposit.svg';
import {
	BTCAddressType,
	CurrencyNetwork,
	NetworkNameCode,
} from '../../../../redux/AccountsState/AccountsTypes';
import depositMessages from '../../../../redux/DepositState/DepositMessages';
import {
	getAccountNetwork,
	getIsNewAddressAvailable,
	getNewCryptoAddress,
	getWalletAddress,
	setError,
} from '../../../../redux/AccountsState/AccountsActions';
import Loader from '../../../../components/Loader/Loader';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import CopyButton from '../../../../components/CopyButton/CopyButton';
import currencyMessages from '../../../../messages/currency.messages';
import styles from './CryptoDepositDetails.module.scss';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import baseMsg from '../../../../messages/base.messages';
import Seo from '../../../../components/Seo/Seo';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import TestIds from '../../../../test/TestIds';
import useCurrentWallet from '../../../../hooks/useCurrentWallet';
import SelectOption from '../../../../interfaces/SelectOption';
import Select from '../../../../components/Select/Select';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import getIframeLink, {
	formatNetworkName,
	noBuy,
} from '../../../../helpers/onRamperHelper/onRamperHelper';

const messages = defineMessages({
	selectLabel: {
		id: 'base.depositWallet',
		defaultMessage: 'Deposit wallet',
	},
	address: {
		id: 'base.address',
		defaultMessage: 'Address',
	},
	shareAddress: {
		id: 'base.share_address',
		defaultMessage: 'Share address',
	},
	copy: {
		id: 'base.copy',
		defaultMessage: 'Copy',
	},
	getNewAddress: {
		id: 'base.get_new_address',
		defaultMessage: 'Get new address',
	},
	getNewMessage: {
		id: 'base.get_new_message',
		defaultMessage: 'Get new message',
	},
	messageDestinationTag: {
		id: 'walletsendxrp.memo_warning',
		defaultMessage:
			'The Destination tag (if receiving wallet requires it) must be included in the payment, or your funds will be lost',
	},
	messageMemo: {
		id: 'walletsendxlm.memo_warning',
		defaultMessage:
			'The memo (if receiving wallet requires it) must be included in the payment, or your funds will be lost',
	},
	messageERC20: {
		id: 'cryptoCurrencyDepositDetails.eth_network_warning',
		defaultMessage: 'This wallet only supports transactions on the Ethereum network (ERC20).',
	},
	cryptoAddressGenerationLimitReached: {
		id: 'scvalidationexception.validate.crypto_address_generation_limit_reached',
		defaultMessage: 'New crypto address generation limit reached',
	},
	userAccountNotEnabled: {
		id: 'scvalidationexception.validate.user_account_not_enabled',
		defaultMessage: 'User account is not enabled',
	},
	unexpectedError: {
		id: 'scvalidationexception.validate.unexpected_error.key',
		defaultMessage: 'Unexpected error occurred.',
	},
	addressType: {
		id: 'cryptoAddressBlock.addressType',
		defaultMessage: 'Address type',
	},
	depositCrypto: {
		id: 'cryptoDeposit.depositCrypto',
		defaultMessage: 'Deposit fiat to crypto on-ramp',
	},
});

const { getConfigOrDefault } = currencyUtils;

interface Params {
	currency: CurrencyEnum;
	id: string;
}

const CryptoDepositDetails = () => {
	const { currency, id } = useParams<Params>();
	const dispatch = useDispatch();
	const wallet = useCurrentWallet();
	const isBTC = currency === CurrencyEnum.BTC;
	const { formatMessage, locale } = useIntl();
	const { referenceCurrency: refCurrency } = useSelector((state: RootState) => state.AppState);
	const { isLoading, error } = useSelector((state: RootState) => state.AccountsState);
	const [isSegWit, setIsSegWit] = useState(isBTC);
	const [list, setList] = useState<SelectOption[]>([]);
	const [network, setNetwork] = useState<CurrencyNetwork['networkName'] | null>(null);
	const [isNewAddressAvailable, setIsNewAddressAvailable] = useState<boolean>(false);
	const address = isSegWit ? wallet?.cryptoAddressSegWit : wallet?.cryptoAddress || '';
	const parsedWalletNameForOnramper = useMemo(
		() => formatNetworkName(network as NetworkNameCode, currency),
		[network, currency]
	);

	const handleBuy = () =>
		dispatch(
			toggleModal(
				<div className={styles.buyModal}>
					<iframe
						src={getIframeLink(
							(refCurrency as CurrencyEnum) || CurrencyEnum.USD,
							parsedWalletNameForOnramper,
							address!
						)}
						title="Onramper Widget"
						height="630px"
						width="420px"
						allow="accelerometer; autoplay; camera; gyroscope; payment"
					/>
					<Button
						type={ButtonType.BUTTON}
						buttonStyle={ButtonStyle.BORDERLESS}
						text={baseMsg.close}
						onClick={() => dispatch(toggleModal())}
					/>
				</div>
			)
		);

	const getAddress = () => {
		if (network)
			return dispatch(
				getNewCryptoAddress(
					id,
					network,
					isBTC ? (isSegWit ? BTCAddressType.BECH32 : BTCAddressType.LEGACY) : null
				)
			);
		return null;
	};

	useEffect(() => {
		if (error !== null) setTimeout(() => dispatch(setError(null)), 2500);
	}, [error]);

	useEffect(() => {
		if (wallet && !wallet?.networkList) void dispatch(getAccountNetwork(currency, id));
	}, [wallet]);

	useEffect(() => {
		if (wallet?.networkList && wallet?.networkList.length !== 0) {
			setList(
				wallet?.networkList?.map(({ networkName, feeCurrencyCode, publicName }) => {
					return {
						label: (
							<div className={styles.option}>
								<CurrencyIcon
									currencyType={CurrencyEnum[feeCurrencyCode]}
									className={styles.icon}
								/>
								<span className={styles.text}>{publicName}</span>
							</div>
						),
						value: networkName,
					};
				})
			);
		}
	}, [wallet?.networkList]);

	useEffect(() => {
		if (list?.length > 0) {
			setNetwork(list[0].value);
		}
	}, [list]);

	useEffect(() => {
		if (network) {
			void dispatch(
				getWalletAddress(id, network, currency, isBTC ? BTCAddressType.LEGACY : undefined)
			);
			if (isBTC) {
				void dispatch(getWalletAddress(id, network, currency, BTCAddressType.BECH32));
			}
		}
	}, [network]);

	useEffect(() => {
		void (async () => {
			setIsNewAddressAvailable(await getIsNewAddressAvailable(id, network as NetworkEnum));
		})();
	}, [network]);

	if (
		!currencyUtils.getConfigOrDefault(currency).noAddress &&
		!address &&
		!wallet?.message &&
		list.length === 0
	)
		return <Loader className={styles.loader} />;
	return (
		<div data-cy={TestIds.cryptoDepositsDetailsView}>
			<Seo title={depositMessages.metaCryptoDepositsTitle} />
			<PageTitle
				previousPageLink={`/${locale}/deposit/crypto`}
				isPreviousPageLinkVisibleOnDesktop
				isPreviousPageLinkVisibleOnMobile={false}
				title={formatMessage(depositMessages.genericCryptoDetailsTitle, {
					currencyCode: currency,
					currencyName: formatMessage(currencyMessages[currency]),
				})}
			/>
			<div className={styles.body}>
				<div className={styles.column}>
					<Select
						label={formatMessage(baseMsg.network)}
						inputGroupClassName={styles.selectInputGroup}
						className={styles.select}
						labelClassName={styles.label}
						disabled={list.length === 0 || list.length === 1}
						value={network}
						options={list}
						onChange={setNetwork}
					/>
					{isBTC && (
						<>
							<div className={styles.label}>
								{formatMessage(messages.addressType)}
							</div>
							<ul className={styles.list}>
								<li
									className={classNames({
										[styles.selected]: isSegWit,
									})}
									onClick={() => setIsSegWit(true)}
									data-cy={TestIds.segwitButton}
								>
									{formatMessage(depositMessages.segWitAddress)}
								</li>
								<li
									className={classNames({
										[styles.selected]: !isSegWit,
									})}
									onClick={() => setIsSegWit(false)}
									data-cy={TestIds.legacyButton}
								>
									{formatMessage(depositMessages.legacyAddress)}
								</li>
							</ul>
						</>
					)}
					<div className={styles.qrCode}>
						<QRCodeSVG
							size={142}
							level="M"
							className={classNames({ [styles.opacity]: isLoading })}
							value={getQRValue(
								CurrencyEnum[currency],
								address!,
								wallet?.message!,
								null
							)}
							data-cy={TestIds.qrCode}
						/>
					</div>
					<div>
						<div className={styles.label} data-cy={TestIds.cryptoAddressCopyButton}>
							{formatMessage(messages.address)}
							<CopyButton
								text={messages.copy}
								dataToCopy={address || ''}
								className={styles.copyButton}
							/>
						</div>
						<div
							className={classNames(styles.copyButtonValue, {
								[styles.opacity]: isLoading,
							})}
							data-cy={TestIds.cryptoAddress}
						>
							{isSegWit ? wallet?.cryptoAddressSegWit : wallet?.cryptoAddress}
						</div>
					</div>
					{wallet?.message && (
						<>
							<div className={classNames(styles.label, styles.mt10)}>
								{CurrencyEnum[currency] === CurrencyEnum.XEM &&
									formatMessage(baseMsg.message)}
								{getConfigOrDefault(CurrencyEnum[currency]).memo &&
									formatMessage(baseMsg.memo)}
								{getConfigOrDefault(CurrencyEnum[currency]).destinationTag &&
									formatMessage(baseMsg.destinationTag)}
								<CopyButton
									text={messages.copy}
									dataToCopy={wallet?.message || ''}
									className={styles.copyButton}
								/>
							</div>
							<div
								className={classNames(styles.value, {
									[styles.opacity]: isLoading,
								})}
								data-cy={TestIds.cryptoMessage}
							>
								{wallet?.message}
							</div>
						</>
					)}
					{getConfigOrDefault(CurrencyEnum[currency]).destinationTag && (
						<p data-cy={TestIds.disclaimerERC20} className={styles.paragraph}>
							<NotificationMessage
								withIcon
								type={NotificationType.Info}
								style={NotificationStyle.Border}
								message={formatMessage(messages.messageDestinationTag)}
							/>
						</p>
					)}
					{getConfigOrDefault(CurrencyEnum[currency]).memo && (
						<p data-cy={TestIds.disclaimerERC20} className={styles.paragraph}>
							<NotificationMessage
								withIcon
								type={NotificationType.Info}
								style={NotificationStyle.Border}
								message={formatMessage(messages.messageMemo)}
							/>
						</p>
					)}
					{isNewAddressAvailable && (
						<button
							data-cy={TestIds.getNewAddressButton}
							className={styles.getNewAddressButton}
							onClick={getAddress}
							disabled={isLoading}
							type="button"
						>
							<FormattedMessage
								{...(currency === CurrencyEnum.XEM
									? messages.getNewMessage
									: messages.getNewAddress)}
							/>
							{isLoading && (
								<Loader
									data-cy={TestIds.getNewAddressButtonLoader}
									className={styles.loader}
								/>
							)}
							{error && (
								<div
									data-cy={TestIds.getNewAddressButtonError}
									className={styles.error}
								>
									{formatMessage(error)}
								</div>
							)}
						</button>
					)}
					{!noBuy.includes(currency) && (
						<>
							<div className={styles.orSeparator}>
								<div className={styles.line} />
								<span>{formatMessage(baseMsg.or)}</span>
								<div className={styles.line} />
							</div>
							<Button
								type={ButtonType.BUTTON}
								buttonStyle={ButtonStyle.TERTIARY}
								className={styles.buyButton}
								onClick={handleBuy}
							>
								<img src={depositImg} alt="Deposit icon" />
								{formatMessage(messages.depositCrypto)}
							</Button>
						</>
					)}
				</div>
				<ShareAddressList
					className={classNames(styles.column, styles.shareAddressColumn)}
					address={getQRValue(CurrencyEnum[currency], address!, wallet?.message!, null)}
				/>
			</div>
		</div>
	);
};

export default CryptoDepositDetails;

interface ShareAddressListProps {
	className?: string;
	address: string;
}

const ShareAddressList = ({ className, address }: ShareAddressListProps) => {
	const isMobile = useWindowWidth() < 768;
	return (
		<div className={className}>
			<div className={classNames(styles.label, styles.shareAddressLabel)}>
				<FormattedMessage {...messages.shareAddress} />
			</div>
			<ul className={styles.shareAddressList}>
				<ShareAddressListItem
					icon={faFacebookF}
					name={'Facebook'}
					link={`https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(
						'https://spectrocoin.com'
					)}&quote=${address}`}
					openInNewTab
				/>
				<ShareAddressListItem
					icon={faXTwitter}
					link={`https://twitter.com/share?text=${encodeURIComponent(address)}`}
					name={'Twitter'}
					openInNewTab
				/>
				<ShareAddressListItem
					icon={faGoogle}
					link={`https://mail.google.com/mail/u/0/?view=cm&body=${encodeURIComponent(
						address
					)}&fs=1&tf=1`}
					name={'Gmail'}
					openInNewTab
				/>
				<ShareAddressListItem
					icon={faEnvelope}
					link={`mailto:?body=${encodeURIComponent(address)}`}
					name={'Mail'}
				/>
				{isMobile && (
					<ShareAddressListItem
						icon={faComment}
						link={`sms:&body=${encodeURIComponent(address)}`}
						name={'Message'}
					/>
				)}
			</ul>
		</div>
	);
};

interface ShareAddressListItemProps {
	icon: IconProp;
	link: string;
	name: string;
	openInNewTab?: boolean;
}

const ShareAddressListItem = ({
	icon,
	name,
	link,
	openInNewTab = false,
}: ShareAddressListItemProps) => {
	const linkProps = openInNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {};
	return (
		<li className={styles.shareAddressListItem}>
			<a
				data-cy={`shareAddress_${name}`}
				className={styles.shareAddressListItemLink}
				href={link}
				{...linkProps}
			>
				<FontAwesomeIcon className={styles.shareAddressListItemIcon} icon={icon} />
				<div className={styles.shareAddressListItemName}>{name}</div>
			</a>
		</li>
	);
};
