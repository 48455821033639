import { Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InstantExchange from '../../pages/Convert/InstantExchange/InstantExchange';
import TransfersSidebar, { SidebarType } from '../../layout/TransfersSidebar/TransfersSidebar';
import TransfersOptionContainer from '../TransfersOptionContainer/TransfersOptionContainer';
import { ExchangeType } from '../../redux/ConvertState/ConvertTypes';
import RecurringOrder from '../../pages/Convert/RecurringOrder/RecurringOrder';
import { RootState } from '../../redux/Store';
import { getIsRecurringEnabled } from '../../redux/ConvertState/ConvertActions';

const messages = defineMessages({
	instant: {
		id: 'exchange.instant',
		defaultMessage: 'Instant exchange',
	},
	recurring: {
		id: 'exchange.recurring',
		defaultMessage: 'Recurring order',
	},
	advanced: {
		id: 'exchange.advanced',
		defaultMessage: 'Advanced orders',
	},
	card: {
		id: 'exchange.card',
		defaultMessage: 'Buy with card',
	},
	levarage: {
		id: 'exchange.levarage',
		defaultMessage: 'Leverage your crypto',
	},
	comingSoon: {
		id: 'exchange.comingSoon',
		defaultMessage: 'Coming soon...',
	},
	instantSubtitle: {
		id: 'exchange.instantSubtitle',
		defaultMessage: 'Instant exchange at the best rate',
	},
	recurringSubtitle: {
		id: 'exchange.recurringSubtitle',
		defaultMessage: 'Set up a repeating exchange',
	},
	back: {
		id: 'exchange.backButton',
		defaultMessage: 'Back to Exchange options',
	},
});

const ConvertContainer = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { replace } = useHistory();
	const { pathname } = useLocation();
	const { url }: { url: string } = useRouteMatch();
	const { isMobile, isTablet } = useSelector((state: RootState) => state.AppState);
	const { isRecurringEnabled } = useSelector((state: RootState) => state.ConvertState);

	const exchangeTypes = {
		[ExchangeType.INSTANT]: {
			icon: ExchangeType.INSTANT,
			link: `${url}/instant-exchange`,
			name: formatMessage(messages.instant),
			subtitle: formatMessage(messages.instantSubtitle),
			optionsCount: 1,
		},
		...(isRecurringEnabled && {
			[ExchangeType.RECURRING]: {
				icon: ExchangeType.RECURRING,
				link: `${url}/recurring-order`,
				name: formatMessage(messages.recurring),
				subtitle: formatMessage(messages.recurringSubtitle),
				optionsCount: 1,
			},
		}),
		// [ExchangeType.ADVANCED]: {
		// 	icon: ExchangeType.ADVANCED,
		// 	name: formatMessage(messages.advanced),
		// 	subtitle: formatMessage(messages.comingSoon),
		// 	link: '',
		// 	optionsCount: 0,
		// },
		// [ExchangeType.CARD]: {
		// 	icon: ExchangeType.CARD,
		// 	name: formatMessage(messages.card),
		// 	subtitle: formatMessage(messages.comingSoon),
		// 	link: '',
		// 	optionsCount: 0,
		// },
		// [ExchangeType.LEVARAGE]: {
		// 	icon: ExchangeType.LEVARAGE,
		// 	name: formatMessage(messages.levarage),
		// 	subtitle: formatMessage(messages.comingSoon),
		// 	link: '',
		// 	optionsCount: 0,
		// },
	};

	// always redirect to instant exchange page as default on desktop
	useEffect(() => {
		if (!(isMobile || isTablet) && pathname === url) {
			replace(`${url}/instant-exchange`);
		}
	}, [url, replace, isMobile, pathname, isTablet]);

	useEffect(() => {
		void dispatch(getIsRecurringEnabled());
	}, [dispatch]);

	return (
		<>
			<TransfersSidebar sidebarType={SidebarType.EXCHANGE} types={exchangeTypes} />
			<TransfersOptionContainer title={formatMessage(messages.back)} previousPageLink={url}>
				<Route path={`${url}/instant-exchange`} render={() => <InstantExchange />} />
				{isRecurringEnabled && (
					<Route path={`${url}/recurring-order`} render={() => <RecurringOrder />} />
				)}
			</TransfersOptionContainer>
		</>
	);
};

export default ConvertContainer;
