/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { AppDispatch } from '../Store';
import {
	WalletHistoryType,
	SetWalletHistoryDataAction,
	ResetWalletHistoryAction,
} from './WalletHistoryTypes';
import { getAccountHistoryURL } from '../endpoints';
import axiosInstance from '../../helpers/axiosInstance';

const setWalletHistoryIsLoading = (isLoading: boolean) => ({
	type: WalletHistoryType.IS_LOADING,
	isLoading,
});

export const getAccountHistory = (
	id: string,
	startDate?: string,
	endDate?: string,
	page?: number
) => (dispatch: AppDispatch) => {
	dispatch<any>(setWalletHistoryIsLoading(true));
	return axiosInstance
		.get(getAccountHistoryURL(id, startDate, endDate, page))
		.then(({ data }: AxiosResponse) => {
			dispatch<any>(setWalletHistoryIsLoading(false));
			return dispatch<SetWalletHistoryDataAction>({ type: WalletHistoryType.SET_DATA, data });
		});
};

export const resetAccountHistory = () => (dispatch: AppDispatch) => {
	return dispatch<ResetWalletHistoryAction>({ type: WalletHistoryType.RESET_DATA });
};
