import { FC } from 'react';
import { useSelector } from 'react-redux';
import styles from './ReferralIds.module.scss';
import { RootState } from '../../../redux/Store';
import Sidebar from '../Shared/Sidebar/Sidebar';
import ReferralIdsList from '../Shared/ReferralIdsList/ReferralIdsList';

const Desktop: FC = () => {
	return (
		<div className={styles.layout}>
			<Sidebar />
			<div className={styles.main}>
				<ReferralIdsList />
			</div>
		</div>
	);
};

const Tablet: FC = () => {
	return (
		<div className={styles.main}>
			<ReferralIdsList />
		</div>
	);
};

const ReferralIds: FC = () => {
	const { isTablet } = useSelector((state: RootState) => state.AppState);
	return isTablet ? <Tablet /> : <Desktop />;
};

export default ReferralIds;
