import { useCallback, useEffect, useState } from 'react';
import { MessageDescriptor, defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderStep } from '../../../../redux/CardsState/CardsActions';
import {
	CardStatusType,
	CardType,
	LoadStatus,
	OrderSteps,
} from '../../../../redux/CardsState/CardsTypes';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import successImgPath from './images/success.svg';
import pendingImgPath from './images/pending.svg';
import failureImgPath from './images/failure.svg';
import styles from './CardStatus.module.scss';
import orderVirtualBannerImg from './images/order_virtual.svg';
import { RootState } from '../../../../redux/Store';
import baseMsg from '../../../../messages/base.messages';

const messages = defineMessages({
	orderCard: {
		id: 'cardBanner.order',
		defaultMessage: 'Order card',
	},
	maybeNextTime: {
		id: 'cardStatus.nextTime',
		defaultMessage: 'Maybe next time!',
	},
	getCard: {
		id: 'cardStatus.getCard',
		defaultMessage: 'Get your virtual card',
	},
	useInstant: {
		id: 'cardStatus.useInstant',
		defaultMessage: 'Get a virtual card for online payments instantly!',
	},
});

export enum CardStatusEnum {
	PENDING = 'PENDING',
	COMPLETED = 'COMPLETED',
	FAILED = 'FAILED',
}

interface CardStatusProps {
	status: LoadStatus | CardStatusEnum;
	title?: MessageDescriptor;
	text?:
		| (MessageDescriptor & { values?: Record<string, (chunks: string) => JSX.Element> })
		| string;
	link?: string;
	fullLink?: string;
	contactSupport?: boolean;
	linkText?: MessageDescriptor | null;
}

const CardStatus = ({
	status,
	title,
	text,
	link,
	fullLink,
	contactSupport = false,
	linkText = null,
}: CardStatusProps) => {
	const { url } = useRouteMatch();
	const dispatch = useDispatch();
	const { locale, formatMessage } = useIntl();
	const [canShowBanner, setCanShowBanner] = useState(false);
	const { productOptions } = useSelector((state: RootState) => state.CardsState);
	const { cardsList } = useSelector((state: RootState) => state.CardsState);
	const hasVirtual = cardsList?.some(
		(card) =>
			card.type === CardType.VIRTUAL &&
			(card.status === CardStatusType.ACTIVE || card.status === CardStatusType.SUSPENDED)
	);
	const [img, setImg] = useState<string>('');
	const [linkTo, setLinkTo] = useState<string | null>(null);
	const splitUrl = url.split('/');
	const urlsLastPathname = splitUrl[splitUrl.length - 1];
	const isOrderPage = splitUrl.includes('order');

	const handleOrderVirtual = () => {
		dispatch(setOrderStep(OrderSteps.TERMS));
	};

	const setImgByStatus = useCallback(() => {
		switch (status) {
			case CardStatusEnum.PENDING:
			case LoadStatus.PENDING:
				setCanShowBanner(false);
				return setImg(pendingImgPath);
			case CardStatusEnum.COMPLETED:
			case LoadStatus.COMPLETED:
				setCanShowBanner(
					urlsLastPathname !== 'virtual' &&
						isOrderPage &&
						!hasVirtual &&
						!!productOptions?.filter(({ type }) => type === 'VIRTUAL').length
				);
				return setImg(successImgPath);
			case CardStatusEnum.FAILED:
			case LoadStatus.FAILED:
				setCanShowBanner(false);
				return setImg(failureImgPath);
			default:
				return null;
		}
	}, [isOrderPage, status, urlsLastPathname, hasVirtual]);

	useEffect(() => {
		setImgByStatus();
	}, [setImgByStatus, status, urlsLastPathname]);

	useEffect(() => {
		if (fullLink) setLinkTo(fullLink);
		if (link) setLinkTo(url.replace(urlsLastPathname, link));
	}, [fullLink, link, url, urlsLastPathname]);

	return (
		<>
			<InfoHead img={img} title={title} text={text} />
			{canShowBanner && (
				<div className={styles.getVirtualContainer}>
					<div className={styles.getVirtualContent}>
						<img src={orderVirtualBannerImg} />
						<div className={styles.getVirtualText}>
							<h1>{formatMessage(messages.getCard)}</h1>
							<h2>{formatMessage(messages.useInstant)}</h2>
						</div>
					</div>
					<Button
						className={styles.smallerButton}
						text={messages.orderCard}
						type={ButtonType.LINK}
						onClick={handleOrderVirtual}
						link={`/${locale}/cards/order/virtual`}
					/>
					<Button
						className={classNames(styles.smallerButton, styles.margin)}
						buttonStyle={ButtonStyle.BORDERLESS}
						text={messages.maybeNextTime}
						type={ButtonType.LINK}
						link={`/${locale}/cards`}
					/>
				</div>
			)}
			{linkTo && !canShowBanner && (
				<Button
					link={linkTo}
					type={ButtonType.LINK}
					text={linkText || baseMsg.close}
					className={styles.button}
				/>
			)}
			{contactSupport && (
				<a href="mailto:helpdesk@spectrocoin.com" className={styles.link}>
					<FormattedMessage
						id="cardStatus.contactSupport"
						defaultMessage="Contact support"
					/>
				</a>
			)}
		</>
	);
};

export default CardStatus;
