import { FC, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CurrencyIcon, currencyUtils, CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RootState } from '../../redux/Store';
import {
	toggleCurrentRates,
	toggleCurrentRatesGraph,
	toggleWalletSidebar,
} from '../../redux/AppState/AppActions';
import { RatesDetailsResponse } from '../../redux/RatesState/RatesTypes';
import Seo from '../../components/Seo/Seo';
import BalanceBlock from '../../components/BalanceBlock/BalanceBlock';
import CryptoAddressBlock from '../../components/CryptoAddressBlock/CryptoAddressBlock';
import IBANBlock from '../../components/IBANBlock/IBANBlock';
import BackButton from '../../components/BackButton/BackButton';
import HistoryBlock from '../../components/HistoryBlock/HistoryBlock';
import RateGraphWidget from '../../components/RateGraphWidget/RateGraphWidget';
import MarketingSlider from '../../components/MarketingSlider/MarketingSlider';
import RefCurrencySelect from '../../components/RefCurrencySelect/RefCurrencySelect';
import styles from './WalletHistory.module.scss';
import { HistoryType } from '../../hooks/useHistoryTableData';
import useWalletHistoryDataProvider from './Providers/walletHistoryDataProvider';
import { formatTestId } from '../../test/TestIds';
import WalletTestIds from '../../test/Wallets/WalletTestIds';
import useCurrentWallet from '../../hooks/useCurrentWallet';
import { CurrencyNetwork } from '../../redux/AccountsState/AccountsTypes';

const messages = defineMessages({
	back: {
		id: 'history.backToPortfolio',
		defaultMessage: 'Back to Portfolio',
	},
	metaTitle: {
		id: 'history.metaTitle',
		defaultMessage: 'wallets',
	},
	historyTitle: {
		id: 'historyBlock.historyTitle',
		defaultMessage: 'Wallet history',
	},
});

const WalletHistory: FC<{ refCurrency: CurrencyEnum }> = ({ refCurrency }) => {
	const dispatch = useDispatch();
	const { currencyCode } = useParams<{ currencyCode: CurrencyEnum }>();
	const { isMobile, isTablet } = useSelector((state: RootState) => state.AppState);
	const rates = useSelector((state: RootState) => state?.RatesState.ratesDetails);
	const [network, setNetwork] = useState<CurrencyNetwork['networkName'] | null>(null);
	const [refCurrRates, setRefCurrRates] = useState<RatesDetailsResponse | null>(null);
	const [currentCurrRates, setCurrentCurrRates] = useState<RatesDetailsResponse | null>(null);
	const currentWallet = useCurrentWallet();
	const { locale } = useIntl();

	useEffect(() => {
		if (isMobile || isTablet) {
			dispatch(toggleWalletSidebar(false));
			dispatch(toggleCurrentRates(false));
			dispatch(toggleCurrentRatesGraph(false));
		} else {
			dispatch(toggleWalletSidebar(true));
			dispatch(toggleCurrentRates(false));
			dispatch(toggleCurrentRatesGraph(true));
		}
	}, [dispatch, isMobile, isTablet]);

	useEffect(() => {
		if (rates)
			setRefCurrRates(
				rates?.find((o: RatesDetailsResponse) => o.currencyCode === refCurrency) || null
			);
	}, [rates, refCurrency]);

	useEffect(() => {
		if (currentWallet) {
			const currentCurrencyRates = rates?.find(
				(o: RatesDetailsResponse) => o.currencyCode === currencyCode
			);
			setCurrentCurrRates(currentCurrencyRates || null);
		}
	}, [currencyCode, currentWallet, rates]);

	if (!currentWallet || !currentCurrRates || !refCurrRates) return null;
	const { currencyName, availableBalance, reservedAmount, id, iban } = currentWallet;
	return (
		<>
			<Seo title={messages.metaTitle} currency={currencyCode} />
			<div className={styles.header}>
				<BackButton
					text={messages.back}
					link={`/${locale}/account`}
					className={styles.historyBack}
					data-cy={formatTestId(WalletTestIds.BackButton)}
				/>
				{(isMobile || isTablet) && <RefCurrencySelect containerClass={styles.position} />}
			</div>
			<div className={styles.head} data-cy={formatTestId(WalletTestIds.WalletTitle)}>
				<div
					className={styles.iconContainer}
					style={{
						backgroundColor: `${currencyUtils
							.getConfigOrDefault(currencyCode)
							.color.slice(0, -2)}.2)`,
					}}
				>
					<CurrencyIcon className={styles.icon} currencyType={currencyCode} />
				</div>
				<h3 className={styles.title}>
					<FormattedMessage
						id="walletBlocks.wallet"
						defaultMessage="{currencyCode} Wallet"
						values={{ currencyCode: currencyName }}
					/>
				</h3>
			</div>
			<BalanceBlock
				availableBalance={availableBalance}
				reservedAmount={reservedAmount}
				currencyCode={CurrencyEnum[currencyCode]}
				refCurrency={CurrencyEnum[refCurrency]}
				currentCurrencyRates={currentCurrRates!}
				refCurrencyRates={refCurrRates!}
				walletAddress={
					(currencyCode === CurrencyEnum.BTC
						? currentWallet.cryptoAddressSegWit
						: currentWallet.cryptoAddress) ?? undefined
				}
				selectedNetwork={network ?? undefined}
				isVirtual={currentCurrRates?.virtual || false}
				id={id}
			/>
			<CryptoAddressBlock
				id={id}
				network={network}
				setNetwork={setNetwork}
				currencyCode={CurrencyEnum[currencyCode]}
				isVirtual={currentCurrRates?.virtual || false}
			/>
			{(isMobile || isTablet) && (
				<div className={styles.mobileContainer}>
					<div>
						<RateGraphWidget refCurrency={refCurrency} />
					</div>
					{!isMobile && (
						<div>
							<MarketingSlider />
						</div>
					)}
				</div>
			)}
			<IBANBlock currencyCode={CurrencyEnum[currencyCode]} id={id} iban={iban} />
			<HistoryBlock
				title={messages.historyTitle}
				currencyCode={CurrencyEnum[currencyCode]}
				id={id}
				dataProvider={useWalletHistoryDataProvider}
				historyType={HistoryType.WALLET}
			/>
			{isMobile && <MarketingSlider />}
		</>
	);
};

export default WalletHistory;
