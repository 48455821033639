/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { CurrencyIcon, CurrencyEnum } from '@spectrocoin/sc-currencies';
import PaymentMethod, { CardType, LinkedCard } from '../../../../redux/DepositState/DepositTypes';
import SelectOption from '../../../../interfaces/SelectOption';
import { RootState } from '../../../../redux/Store';
import useDebounce from '../../../../hooks/useDebounce';
import { Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import {
	depositCalculateReceiveAmountURL,
	depositLinkedCardURL,
} from '../../../../redux/endpoints';
import { getCardTypeFromBrand } from '../helpers';
import Loader from '../../../../components/Loader/Loader';
import Select from '../../../../components/Select/Select';
import CurrencyInput from '../../../../components/CurrencyInput/CurrencyInput';
import CardCvvInput from '../CardCvvInput/CardCvvInput';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import PaymentMethodLimits from '../../Shared/PaymentMethodLimits/PaymentMethodLimits';
import styles from './CardDepositDetails.module.scss';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import { formatPrecision, toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import HowToDeposit from '../../Shared/HowToDeposit/HowToDeposit';
import { isNumeric } from '../../../../helpers/globalFunctionHelpers/globalFunctionHelpers';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import depositMessages from '../../../../redux/DepositState/DepositMessages';
import inputStyles from '../../../../components/Input/Input.module.scss';
import errorMessages from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import { toPlainAmount } from '../../../../helpers/currencyAmountHelper/currencyAmountHelper';
import baseMsg from '../../../../messages/base.messages';
import inputErrors from '../../../../messages/inputErrors.messages';
import Seo from '../../../../components/Seo/Seo';
import TestIds from '../../../../test/TestIds';
import { fetchLinkedCards } from '../../../../redux/DepositState/DepositActions';
import useFormatAmount from '../../../../hooks/useFormatAmount';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	cardNumber: {
		id: 'creditcardlink.cardNumber',
		defaultMessage: 'Card number',
	},
	fullName: {
		id: 'creditcardlink.full_name',
		defaultMessage: 'Full name (as displayed on card)',
	},
	validUntil: {
		id: 'linkedcards.valid',
		defaultMessage: 'Valid until',
	},
	title: {
		id: 'walletdeposit.deposit_with_card',
		defaultMessage: 'Deposit with card',
	},
	card: {
		id: 'base.card',
		defaultMessage: 'Card',
	},
	depositAmount: {
		id: 'walletdepositbanksummary.deposit_amount',
		defaultMessage: 'Deposit amount',
	},
	cardLimitsTitle: {
		id: 'linkedcards.card_limits',
		defaultMessage: 'Card limits',
	},
	next: {
		id: 'base.next',
		defaultMessage: 'Next',
	},
	receiveAccount: {
		id: 'base.receive_account',
		defaultMessage: 'Receive account',
	},
	errorInvalidField: {
		id: 'base.invalidField',
		defaultMessage: 'Field is not valid',
	},
	errorDefault: {
		id: 'scvalidationexception.validate.unexpected_error.key',
		defaultMessage: 'Unexpected error occurred.',
	},
	errorCardNotFound: {
		id: 'linkedcards.card_not_found',
		defaultMessage: 'Card not found',
	},
	errorDepositAmountBelowMin: {
		id: 'walletdeposit.deposit_amount_below_mininum',
		defaultMessage: 'Min. deposit amount for this card is {minAmount}',
	},
	errorDepositAmountAboveMax: {
		id: 'walletdeposit.deposit_amount_above_maximum',
		defaultMessage: 'Max. deposit amount for this card is {maxAmount}',
	},
	cvv: {
		id: 'debitCardCredentials.cvv',
		defaultMessage: 'CVV',
	},
	emptyCvv: {
		id: 'walletdeposit.emptyCvv',
		defaultMessage: 'CVV can not be empty',
	},
	depositCurrency: {
		id: 'linkedcards.depositCurrency',
		defaultMessage: 'Deposit currency',
	},
	howToHeading: {
		id: 'linkedcards.how_to_deposit_with_card_title',
		defaultMessage: 'How to deposit with your card',
	},
});

interface FormState {
	depositAmount?: string;
	depositCurrencyCode?: string;
	receiveAmount: string;
	receiveCurrencyCode?: string;
	cvv?: string;
}

interface FormErrorState {
	depositAmount?: MessageDescriptor | null;
	receiveAmount?: MessageDescriptor | null;
	cvv?: MessageDescriptor | null;
}

interface DepositCalculateResponse {
	payAmount: string;
	payCurrencyCode: string;
	receiveAmount: number;
	receiveCurrencyCode: string;
}

interface ProcessCardDepositResponse {
	depositAmount: number;
	depositCurrencyCode: string;
	id: string;
	receiveAmount: number;
	receiveCurrencyCode: string;
	redirectUri: string;
}

const CardDepositDetails = () => {
	const { push } = useHistory();
	const dispatch = useDispatch();
	const amountFormatter = useFormatAmount();
	const { cardId } = useParams<{ cardId: string }>();
	const { formatMessage } = useIntl();
	const { locale } = useIntl();
	const [formSubmitError, setFormSubmitError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [isRequestPending, setIsRequestPending] = useState(false);
	const [linkedCard, setLinkedCard] = useState<LinkedCard>();
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
	const [receiveAccountId, setReceiveAccountId] = useState('');
	const [receiveAccountOptions, setReceiveAccountOptions] = useState<SelectOption[]>([]);
	const [cardType, setCardType] = useState<CardType | null>(null);
	const [depositCurrenciesList, setDepositCurrenciesList] = useState<any>([]);

	const { wallets: accounts } = useSelector((state: RootState) => state.AccountsState);
	const {
		linkedCards,
		paymentMethods: { card: cardPaymentMethods },
	} = useSelector((state: RootState) => state.DepositState);

	const isDataLoaded = cardPaymentMethods.isLoaded && linkedCards.isLoaded && accounts;
	const card = linkedCards.data.find(({ id }) => id === cardId);

	const [formState, setFormState] = useState<FormState>({
		depositAmount: '',
		depositCurrencyCode: '',
		receiveAmount: '0.00',
		receiveCurrencyCode: '',
		cvv: '',
	});
	const [formErrorState, setFormErrorState] = useState<FormErrorState>({
		depositAmount: null,
		receiveAmount: null,
		cvv: null,
	});

	const { depositAmount, depositCurrencyCode, receiveAmount, receiveCurrencyCode } = formState;
	const debouncedDepositAmount = useDebounce(depositAmount, 1000);

	const handleDepositAmountChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
		setFormState((prevState: FormState) => ({
			...prevState,
			depositAmount: value,
		}));
	};

	const handleSelectChange = ({ value }: SelectOption) => {
		const wallet = accounts?.find((account: Wallet) => account.currencyCode === value);

		setReceiveAccountId(wallet!.id);
		setFormState((prevState: FormState) => ({
			...prevState,
			depositCurrencyCode: wallet?.currencyCode,
			receiveCurrencyCode: wallet?.currencyCode,
		}));
	};

	useEffect(() => {
		if (!cardId) push(`/${locale}/deposit/new-card`);
		if (isDataLoaded && isLoading && depositCurrencyCode) {
			setIsLoading(false);
			if (!card) {
				setFormSubmitError(formatMessage(messages.errorCardNotFound));
				return;
			}
			const method = cardPaymentMethods.data.find(({ id }) => id === card.paymentMethodId);
			const selectedAccounts: Wallet[] = Object.values(accounts!).filter((w: Wallet) => {
				return w.currencyCode === depositCurrencyCode;
			});
			const selectedAccountId = selectedAccounts[0].id;
			const accountOptions = selectedAccounts.map((w: Wallet) => ({
				label: w.label as string,
				value: w.id,
			}));

			setLinkedCard(card);
			setPaymentMethod(method);
			setReceiveAccountOptions(accountOptions);
			setReceiveAccountId(selectedAccountId);
			setFormState((prevState: FormState) => ({
				...prevState,
				receiveCurrencyCode: card?.currencyCode,
				depositCurrencyCode: card?.currencyCode,
			}));
		}
	}, [
		cardPaymentMethods,
		linkedCards,
		isDataLoaded,
		cardId,
		isLoading,
		locale,
		accounts,
		formatMessage,
		card,
		depositCurrencyCode,
		receiveCurrencyCode,
		push,
	]);

	const validateForm = () => {
		let valid = true;
		let errorList = {};
		Object.keys(formState).forEach((item: any) => {
			if (!formState[item]) {
				errorList = {
					...errorList,
					[item]: inputErrors.cannotBeEmpty,
				};
				valid = false;
			}
		});
		if (!isNumeric(depositAmount as string)) {
			errorList = {
				...errorList,
				depositAmount: formatMessage(depositMessages.errorDepositAmountIsNumber),
			};
			valid = false;
		} else if (toDecimal(depositAmount!, true).lessThanOrEqualTo(toDecimal(0))) {
			errorList = {
				...errorList,
				depositAmount: formatMessage(depositMessages.errorDepositAmountGreaterThanZero),
			};
			valid = false;
		} else if (minAmount && toDecimal(receiveAmount).lessThan(toDecimal(minAmount))) {
			errorList = {
				...errorList,
				receiveAmount: formatMessage(depositMessages.errorDepositAmountBelowMin, {
					minAmount: toDecimal(minAmount).toFixed(),
				}),
			};
			valid = false;
		} else if (maxAmount && toDecimal(receiveAmount).greaterThan(toDecimal(maxAmount))) {
			errorList = {
				...errorList,
				receiveAmount: formatMessage(depositMessages.errorDepositAmountAboveMax, {
					maxAmount: toDecimal(maxAmount).toFixed(),
				}),
			};
			valid = false;
		}
		setIsButtonDisabled(valid);
		setFormErrorState({
			...formErrorState,
			...errorList,
		});
		return valid;
	};

	const processDeposit = (e: any) => {
		e.preventDefault();
		if (!validateForm()) return;
		setIsButtonDisabled(true);
		setIsRequestPending(true);
		void axiosInstance
			.post(depositLinkedCardURL(), {
				cvv: formState.cvv,
				receiveAccountId,
				receiveAmount,
				userLinkedCardId: linkedCard?.id,
			})
			.then(({ data }: { data: ProcessCardDepositResponse }) => {
				return window.location.assign(data.redirectUri);
			})
			.catch(({ response: { data } }) => {
				if (data?.errorCode === 'LC_8') void dispatch(fetchLinkedCards());
				return setFormSubmitError(
					formatMessage(errorMessages[data?.errorCode] || messages.errorDefault)
				);
			})
			.then(() => {
				setIsButtonDisabled(false);
				return setIsRequestPending(false);
			});
	};

	useEffect(() => {
		if (!isLoading && debouncedDepositAmount && isNumeric(debouncedDepositAmount)) {
			void axiosInstance
				.post(depositCalculateReceiveAmountURL(), {
					payAmount: toPlainAmount(debouncedDepositAmount, true),
					payCurrencyCode: depositCurrencyCode,
					receiveCurrencyCode: depositCurrencyCode,
					paymentMethodId: paymentMethod?.id,
				})
				.then(({ data }: AxiosResponse<DepositCalculateResponse>) => {
					setFormState((prevState: FormState) => ({
						...prevState,
						receiveAmount: toDecimal(data.receiveAmount).toFixed(),
						receiveCurrencyCode: data.receiveCurrencyCode,
					}));
				});
		}
	}, [debouncedDepositAmount, depositCurrencyCode, isLoading, paymentMethod]);

	useEffect(() => {
		if (card?.cardBrand) {
			setCardType(CardType[getCardTypeFromBrand(card?.cardBrand)]);
		}
	}, [card]);

	useEffect(() => {
		const currencies = paymentMethod?.paymentMethodCurrencies.reduce(
			(acc: SelectOption[], curr: CurrencyEnum) => {
				const item = {
					label: (
						<div className={styles.option}>
							<CurrencyIcon currencyType={curr} className={styles.currencyIcon} />
							<label>{curr}</label>
						</div>
					),
					value: curr,
				};
				acc.push(item);
				return acc;
			},
			[]
		);
		setDepositCurrenciesList(currencies);
	}, [card, paymentMethod]);

	useEffect(() => {
		if (card) {
			setFormState((prevState: FormState) => ({
				...prevState,
				depositCurrencyCode: card.currencyCode,
			}));
		}
	}, [card]);

	if (isLoading || !paymentMethod) return <Loader className={styles.loader} />;

	const {
		activePaymentMethodFees,
		duration,
		fee,
		providerType,
		maxAmount,
		maxFee,
		minAmount,
		minFee,
		paymentMethodLimits,
		title,
		baseCurrencyCode,
	} = paymentMethod!;

	return (
		<div data-cy={TestIds.cardDepositsDetailsView}>
			<Seo title={depositMessages.metaCardDepositsTitle} />
			<PageTitle
				historyLink={{
					pathname: `/${locale}/deposit/history/fiat`,
					state: { backUrl: window.location.pathname },
				}}
				previousPageLink={`/${locale}/deposit/card`}
				isPreviousPageLinkVisibleOnDesktop
				isPreviousPageLinkVisibleOnMobile={false}
				title={formatMessage(messages.title)}
			/>
			<div className={styles.container}>
				<div className={styles.column}>
					{formSubmitError && (
						<NotificationMessage
							withIcon
							type={NotificationType.Error}
							style={NotificationStyle.Border}
							message={formSubmitError || formatMessage(messages.errorDefault)}
						/>
					)}
					<form onSubmit={(e) => e.preventDefault()} className={styles.form}>
						{receiveAccountOptions.length > 1 && (
							<Select
								className={styles.select}
								name="accountId"
								label={formatMessage(messages.receiveAccount)}
								options={receiveAccountOptions}
								value={receiveAccountId}
								onChangeObject={(option: SelectOption) => {
									setReceiveAccountId(option.value || '');
								}}
							/>
						)}
						<Select
							className={styles.input}
							labelClassName={styles.label}
							id="wallet"
							label={formatMessage(messages.depositCurrency)}
							name="currencyCode"
							onChangeObject={handleSelectChange}
							options={depositCurrenciesList || []}
							value={depositCurrencyCode}
						/>
						<CurrencyInput
							inputContainerClassName={styles.input}
							currencyCode={CurrencyEnum[depositCurrencyCode!]}
							id="depositAmount"
							label={messages.depositAmount}
							onChange={handleDepositAmountChange}
							value={depositAmount as string}
							error={formErrorState.depositAmount}
						/>
						<div data-cy={TestIds.receiveAmount} className={styles.receiveAmount}>
							<div className={styles.label}>
								<FormattedMessage {...baseMsg.receiveAmount} />
							</div>
							<div className={styles.value} data-cy={TestIds.receiveAmountValue}>
								{amountFormatter(formatPrecision(receiveAmount, CurrencyEnum.EUR))}{' '}
								{receiveCurrencyCode}
							</div>
							{formErrorState.receiveAmount &&
								typeof formErrorState.receiveAmount === 'string' && (
									<div
										className={classNames(
											inputStyles.error,
											styles.receiveError
										)}
										data-cy={TestIds.receiveAmountError}
									>
										{formErrorState.receiveAmount}
									</div>
								)}
						</div>
						<div className={styles.label}>
							<FormattedMessage {...messages.cardNumber} />
						</div>
						<div className={styles.value} data-cy={TestIds.cardNumber}>
							{card?.visibleCardNumber}
						</div>
						<div className={styles.label}>
							<FormattedMessage {...messages.fullName} />
						</div>
						<div className={styles.value} data-cy={TestIds.cardHolderName}>
							{card?.holderName}
						</div>
						<div className={styles.label}>
							<FormattedMessage {...messages.validUntil} />
						</div>
						<div className={styles.value} data-cy={TestIds.cardExpirationDate}>
							{card?.expMonth}/{card?.expYear.toString().slice(-2)}
						</div>
						<CardCvvInput
							className={styles.input}
							label={formatMessage(messages.cvv)}
							name="cvv"
							value={formState.cvv}
							onChange={({ target: { value } }: any) =>
								setFormState((prevState: FormState) => ({
									...prevState,
									cvv: value,
								}))
							}
							cvvDigitCount={cardType === CardType.amex ? 4 : 3}
							errorMessage={formErrorState.cvv}
							data-cy={TestIds.cardCvv}
						/>
						<Button
							buttonStyle={ButtonStyle.PRIMARY}
							className={styles.nextButton}
							isLoading={isLoading || isRequestPending}
							isDisabled={
								!receiveAmount ||
								!depositAmount ||
								isButtonDisabled ||
								formState.cvv?.length !== (cardType === CardType.amex ? 4 : 3)
							}
							type={ButtonType.BUTTON}
							onClick={processDeposit}
							text={formatMessage(messages.next)}
							data-cy={TestIds.button}
						/>
					</form>
				</div>
				<div className={classNames(styles.column, styles.howToDepositColumn)}>
					<HowToDeposit
						duration={duration}
						paymentMethodName={title || ''}
						providerType={providerType}
						howToHeading={messages.howToHeading}
					/>
					<PaymentMethodLimits
						depositCurrencyCode={depositCurrencyCode}
						activePaymentMethodFees={activePaymentMethodFees}
						baseCurrencyCode={baseCurrencyCode}
						maxAmount={maxAmount}
						minAmount={minAmount}
						title={formatMessage(messages.cardLimitsTitle)}
						limits={paymentMethodLimits || []}
						fee={fee}
						minFee={minFee}
						maxFee={maxFee}
						paymentMethodName={formatMessage(messages.card)}
					/>
				</div>
			</div>
		</div>
	);
};

export default CardDepositDetails;
