import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import TestIds from '../../../../../test/TestIds';
import Textarea from '../../../../../components/Textarea/Textarea';
import messages from '../../../../../redux/MerchantsState/MerchantsMessages';
import CopyButton from '../../../../../components/CopyButton/CopyButton';
import SvgIcon from '../../../../../components/SvgIcon/SvgIcon';
import toggleModal from '../../../../../redux/ModalState/ModalActions';

import infoIcon from './Images/info.svg';

import styles from './PrivateKeyModal.module.scss';

type PrivateKeyModalProps = {
	privateKey: string;
};

const PrivateKeyModal: FC<PrivateKeyModalProps> = ({ privateKey }) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();

	const onClose = () => {
		dispatch(toggleModal());
	};

	return (
		<div data-cy={TestIds.ProjectFormPrivateKeyModal} className={styles.container}>
			<div className={styles.header}>
				<FontAwesomeIcon
					data-cy={TestIds.ProjectFormPrivateKeyModalClose}
					className={styles.closeIcon}
					icon={faTimes}
					onClick={onClose}
					size="lg"
				/>
			</div>

			<div className={styles.body}>
				<SvgIcon className={styles.icon} src={infoIcon} />
				<div className={styles.title}>{formatMessage(messages.thisIsYourPrivateKey)}</div>
				<div className={styles.details}>{formatMessage(messages.keepSafe)}</div>
				<div className={styles.privateKey}>
					<div className={styles.label}>{formatMessage(messages.privateKey)}</div>
					<Textarea
						data-cy={TestIds.ProjectFormPrivateKeyModalTextarea}
						className={styles.key}
						value={privateKey}
						readOnly
					/>
					<CopyButton
						className={classNames(styles.copy, styles.action)}
						dataToCopy={privateKey}
						text={messages.copy}
					/>
					<a
						download={`privateKey.txt`}
						className={classNames(styles.download, styles.action)}
						href={`data:text/plain;charset=utf-8,${encodeURIComponent(privateKey)}`}
					>
						{formatMessage(messages.download)}
					</a>
				</div>
			</div>
		</div>
	);
};

export default PrivateKeyModal;
