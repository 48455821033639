import { FC } from 'react';
import { Route, Switch } from 'react-router';
import useMerchantRoutes, { MerchantRoutes } from '../../../hooks/useMerchantRoutes';
import ViewMerchantsOrder from './ViewMerchantsOrder/ViewMerchantsOrder';

const MerchantOrder: FC = () => {
	const { getPath } = useMerchantRoutes();

	return (
		<>
			<Route exact path={getPath(MerchantRoutes.OrderView)} component={ViewMerchantsOrder} />
		</>
	);
};

const MerchantPreOrders: FC = () => {
	const { getPath } = useMerchantRoutes();

	return (
		<Switch>
			<Route path={getPath(MerchantRoutes.OrderRoot)} component={MerchantOrder} />
		</Switch>
	);
};

export default MerchantPreOrders;
