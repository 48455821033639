// Only URLs allowed with http/https, mailto, ftp, tel, file or sms
const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi;
// Check against malicious content, like html tags, javascript etc.
const MALICIOUS_REGEX = /(\b)(on\S+)(\s*)=|javascript|<(|\/|[^/>][^>]+|\/[^>][^>]+)>/gi;
// words which is safe to use inside the URL
const NAMES_WHITELIST = ['exchange.spectrocoin.com', window.location.hostname];
// additional words or any char which is not safe to use inside the URL
const BLACKLIST = ['{', 'script'];

export const isURLSafe = (key: string): boolean => {
	const url = new URL(key);
	return (
		NAMES_WHITELIST.some((i) => url.hostname === i) &&
		!BLACKLIST.some((i) => key.includes(i)) &&
		!!url.protocol.match(SAFE_URL_PATTERN) &&
		!key.match(MALICIOUS_REGEX)
	);
};

export const isKeySafe = (key: string): boolean => {
	return (
		NAMES_WHITELIST.some((i) => key.includes(i)) &&
		!BLACKLIST.some((i) => key.includes(i)) &&
		!key.match(MALICIOUS_REGEX)
	);
};

export const isValidUrl = (key: string) => {
	let url: URL;
	try {
		url = new URL(key);
	} catch {
		return false;
	}
	return url.protocol === 'http:' || url.protocol === 'https:';
};

export const isRedirectSafe = (key: string) => {
	if (isValidUrl(key)) return isURLSafe(key);
	return isKeySafe(key);
};
