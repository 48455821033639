import { FC, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { CurrencyIcon, CurrencyEnum, currencyUtils } from '@spectrocoin/sc-currencies';
import Decimal from 'decimal.js';
import Joi from 'joi';
import { toPlainAmount } from '../../../helpers/currencyAmountHelper/currencyAmountHelper';
import Loading from '../../Merchants/Shared/Loading/Loading';
import Button, { ButtonStyle, ButtonType } from '../../../components/Button/Button';
import AccountSelectOption from '../../Merchants/Shared/AccountSelectOption/AccountSelectOption';
import LabeledField from '../../Merchants/Shared/LabeledField/LabeledField';
import {
	formatPrecision,
	swapSeparators,
	toDecimal,
} from '../../../helpers/currencyHelper/currencyHelper';
import AccountSelect, {
	AccountSelectComponentProps,
} from '../../../components/AccountSelect/AccountSelect';
import { RootState } from '../../../redux/Store';
import PaymentsMessages from '../../../redux/PaymentsState/PaymentsMessages';
import PageTitle from '../../../components/PageTitle/PageTitle';
import NoProjects from '../../Merchants/Shared/NotFound/NotFound';
import WhiteContainer from '../../../components/WhiteContainer/WhiteContainer';
import PaymentsActions from '../../../redux/PaymentsState/PaymentsActions';
import PaymentsSelectors from '../../../redux/PaymentsState/PaymentsSelectors';
import { RemoteError, RemoteStatus } from '../../../interfaces/RemoteData';
import usePaymentRoutes from '../../../hooks/usePaymentRoutes';
import PayAmount from '../Shared/PayAmount/PayAmount';

import styles from './Donation.module.scss';
import baseMsg from '../../../messages/base.messages';
import InfoHead from '../../../components/InfoHead/InfoHead';
import pendingIconPath from '../../Withdraw/Shared/WithdrawSuccess/images/pending.svg';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../components/NotificationMessage/NotificationMessage';
import errorMessages, {
	ErrorMessageCodes,
	getErrorMessageOrDefault,
} from '../../../helpers/errorMessageHelper/errorMessageHelper';
import useValidation from '../../../hooks/useValidation';
import useEffectOnce from '../../../hooks/useEffectOnce';
import TestIds, { formatTestId } from '../../../test/TestIds';
import config from '../../../configs/config';
import { DeviceNameType } from '../../../redux/AppState/AppTypes';
import useFormatAmount from '../../../hooks/useFormatAmount';
import TwoFaContainer from '../../../containers/TwoFaContainer/TwoFaContainer';

const SelectedWallet: FC<AccountSelectComponentProps<{ balance: string }>> = ({
	innerProps,
	data: { value, currencyCode, currencyName: name, balance },
}) => {
	const amountFormatter = useFormatAmount();

	return (
		<div
			className={styles.option}
			{...innerProps}
			data-cy={formatTestId(TestIds.PaymentsDonationWallet_0, value)}
		>
			<CurrencyIcon currencyType={currencyCode} className={styles.optionIcon} />
			<div className={styles.optionlabel}>
				<div className={styles.optionCurrency}>{`${name} (${amountFormatter(
					formatPrecision(balance, currencyCode as CurrencyEnum)
				)} ${currencyCode})`}</div>
			</div>
		</div>
	);
};

const SingleSelectedWallet: FC<AccountSelectComponentProps<{ balance: string }>> = (props) => {
	const { formatMessage } = useIntl();

	return (
		<LabeledField label={formatMessage(PaymentsMessages.donateFromWallet)}>
			<div className={styles.singleWallet}>
				<SelectedWallet {...props} />
			</div>
		</LabeledField>
	);
};
function throwExpression(): never {
	throw Error();
}

export const useFormValidator = (error?: RemoteError) => {
	const { formatMessage } = useIntl();

	const errorCode = error?.errorCode || null;

	return useMemo(
		() =>
			Joi.object<Partial<{ amount: string }>>({
				amount: Joi.string()
					.allow('', null)
					.custom((v) => (errorCode === 'PB_5' ? throwExpression() : v))
					.message(formatMessage(errorMessages.PB_5)),
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[errorCode]
	);
};

const Content: FC = () => {
	const { formatMessage, locale } = useIntl();
	const dispatch = useDispatch();
	const { deviceOS } = useSelector((state: RootState) => state.AppState);
	const { wallets = [] } = useSelector((state: RootState) => state.AccountsState)!;

	const currencies = useSelector(PaymentsSelectors.donations.getCurrenciesData)!;
	const { name, description, id } = useSelector(PaymentsSelectors.donations.getButtonData)!;

	const options = useMemo(() => {
		return (
			wallets
				?.filter(({ currencyCode }) =>
					currencies.some((currency) => currency.code === currencyCode)
				)
				.map(({ currencyCode, currencyName, availableBalance, id: accountId }) => ({
					currencyCode,
					currencyName,
					balance: availableBalance,
					value: accountId,
				})) || []
		);
	}, [currencies, wallets]);

	const { walletId, amount } = useSelector(PaymentsSelectors.donations.getForm);
	const { status: payStatus, error: payError } = useSelector(PaymentsSelectors.donations.getPay);

	const selectedOption = useMemo(() => options.find((x) => x.value === walletId) || options[0], [
		options,
		walletId,
	]);

	const validator = useFormValidator(payError);
	const [_, validationResult] = useValidation({ amount }, validator);

	useEffectOnce(() => {
		if (options.length === 1)
			dispatch(PaymentsActions.donations.setForm({ walletId: options[0].value, amount: '' }));
	});

	useEffect(() => {
		if (payStatus === RemoteStatus.Error && payError?.errorCode === ErrorMessageCodes.PB_6)
			window.location.assign(`${config.PROFILE_DOMAIN}/${locale}/verification/verify`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payStatus]);

	if (payStatus === RemoteStatus.Done) {
		return (
			<>
				<InfoHead
					data-cy={TestIds.PaymentsDonationSuccess}
					title={PaymentsMessages.donationBeingProcessed}
					text={PaymentsMessages.donationBeingProcessed}
					img={pendingIconPath}
				/>
				<Button
					data-cy={TestIds.PaymentsDonationSuccessClose}
					className={styles.button}
					buttonStyle={ButtonStyle.SECONDARY_WITHOUT_BORDER}
					type={ButtonType.LINK}
					link={`/${locale}/account`}
				>
					{formatMessage(baseMsg.close)}
				</Button>
			</>
		);
	}

	return (
		<>
			<PageTitle
				className={styles.title}
				title={formatMessage(PaymentsMessages.donateUsingWallet)}
			/>
			<TwoFaContainer
				isDisabled={
					!selectedOption ||
					!amount ||
					payStatus === RemoteStatus.InProgress ||
					toDecimal(amount).lessThanOrEqualTo(0) ||
					toDecimal(selectedOption.balance).lessThan(toDecimal(amount))
				}
				buttonText={baseMsg.submit}
				buttonTestId={TestIds.PaymentsDonationPay}
				onSuccess={(data) => dispatch(PaymentsActions.donations.payFulfilled(data))}
				onFailure={(error) => dispatch(PaymentsActions.donations.payRejected(error))}
				onSubmit={() => dispatch(PaymentsActions.donations.pay(id))}
			>
				<div className={styles.content}>
					{payStatus === RemoteStatus.Error && (
						<NotificationMessage
							withIcon
							data-cy={TestIds.PaymentsDonationPayError}
							message={formatMessage(getErrorMessageOrDefault(payError))}
							type={NotificationType.Error}
							style={NotificationStyle.Border}
						/>
					)}
					{options.length > 1 ? (
						<AccountSelect
							options={options}
							label={formatMessage(PaymentsMessages.donateFromWallet)}
							labelClassName={styles.label}
							value={walletId}
							onChange={(value) =>
								dispatch(
									PaymentsActions.donations.setForm({
										walletId: value,
										amount: '',
									})
								)
							}
							componentOverrides={{
								Option: AccountSelectOption,
								SingleValue: SelectedWallet,
							}}
						/>
					) : (
						<SingleSelectedWallet data={options[0]} />
					)}
					<LabeledField
						data-cy={TestIds.PaymentsDonationReceiver}
						contentClassName={styles.field}
						label={formatMessage(baseMsg.receiver)}
					>
						{name}
					</LabeledField>
					<LabeledField
						data-cy={TestIds.PaymentsDonationId}
						contentClassName={styles.field}
						label={formatMessage(PaymentsMessages.id)}
					>
						{id}
					</LabeledField>
					{description && (
						<LabeledField
							data-cy={TestIds.PaymentsDonationDescription}
							contentClassName={styles.field}
							label={formatMessage(PaymentsMessages.description)}
						>
							{description}
						</LabeledField>
					)}
					<LabeledField label={formatMessage(PaymentsMessages.paymentAmount)}>
						<PayAmount
							amount={
								deviceOS === DeviceNameType.IPHONE ? swapSeparators(amount) : amount
							}
							currencyCode={selectedOption.currencyCode}
							onSetAmount={(value) =>
								dispatch(
									PaymentsActions.donations.setForm({
										amount: toPlainAmount(value, true),
									})
								)
							}
							maxValue={
								amount
									? Decimal.max(
											toDecimal(selectedOption.balance).sub(
												toDecimal(amount)
											),
											0
									  ).toString()
									: selectedOption.balance
							}
							errorMessage={validationResult.amount}
						/>
					</LabeledField>
				</div>
			</TwoFaContainer>
		</>
	);
};

const Donation: FC = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { getParams } = usePaymentRoutes();

	const { buttonId = '', currency } = getParams();
	const { status: buttonStatus } = useSelector(PaymentsSelectors.donations.getButton);
	const { status: currenciesStatus } = useSelector(PaymentsSelectors.donations.getCurrencies);

	useEffect(() => {
		if (buttonStatus === RemoteStatus.None)
			void dispatch(PaymentsActions.donations.fetchButton(buttonId));
	}, [dispatch, buttonStatus, buttonId]);

	useEffect(() => {
		if (currenciesStatus !== RemoteStatus.None) return;
		if (currency && currencyUtils.tryGetConfig(currency as CurrencyEnum))
			dispatch(PaymentsActions.donations.fetchCurrenciesFulfilled([{ code: currency }]));
		else void dispatch(PaymentsActions.donations.fetchCurrencies(buttonId));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currency, currenciesStatus, buttonId]);

	if (buttonStatus === RemoteStatus.None || currenciesStatus === RemoteStatus.None) return null;

	const isLoading = [buttonStatus, currenciesStatus].includes(RemoteStatus.InProgress);
	const hasError = [buttonStatus, currenciesStatus].includes(RemoteStatus.Error);
	const isLoaded = [buttonStatus, currenciesStatus].every((x) => x === RemoteStatus.Done);

	return (
		<WhiteContainer className={styles.container} data-cy={TestIds.PaymentsDonation}>
			{isLoading && !hasError && <Loading />}
			{hasError && <NoProjects message={formatMessage(PaymentsMessages.donationNotFound)} />}
			{isLoaded && <Content />}
		</WhiteContainer>
	);
};

export default Donation;
