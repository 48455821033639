import queryString, { ParsedQuery } from 'query-string';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getAccountById } from '../../../../redux/AccountsState/AccountsActions';
import Loader from '../../../../components/Loader/Loader';
import styles from './WithdrawFormContainer.module.scss';

export enum WithdrawSteps {
	PREPARE = '1',
	CONFIRM = '2',
	DONE = 'done',
}

interface WithdrawFormContainerProps {
	isLoaded: boolean;
	title?: JSX.Element;
	form?: JSX.Element;
	confirm?: JSX.Element;
	done?: JSX.Element;
	isDone?: boolean;
	walletId: string;
}

const WithdrawFormContainer = ({
	isLoaded,
	title,
	form,
	confirm,
	done,
	isDone = false,
	walletId,
}: WithdrawFormContainerProps) => {
	const dispatch = useDispatch();
	const { push, replace } = useHistory();
	const { pathname, search } = useLocation();
	const parsedQuery: ParsedQuery<string> = queryString.parse(search);
	const { step } = parsedQuery;

	useEffect(() => {
		if (!step || (step && !confirm)) {
			replace({ pathname, search: `step=${WithdrawSteps.PREPARE}` });
		}
	}, [confirm, pathname, replace, step]);

	useEffect(() => {
		replace({ pathname, search: `step=${WithdrawSteps.PREPARE}` });
	}, [pathname, replace]);

	useEffect(() => {
		if (isDone) {
			if (walletId) void dispatch(getAccountById(walletId));
			push({ pathname, search: `step=${WithdrawSteps.DONE}` });
		}
	}, [dispatch, isDone, pathname, push, walletId]);

	if (!isLoaded) return <Loader className={styles.loader} />;

	return (
		<div className={styles.container}>
			{title && title}
			{step === WithdrawSteps.PREPARE && form && form}
			{step === WithdrawSteps.CONFIRM && confirm && confirm}
			{step === WithdrawSteps.DONE && done && done}
		</div>
	);
};

export default WithdrawFormContainer;
