import { FC } from 'react';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { MessageDescriptor } from 'react-intl';
import CustomInput from '../../../Withdraw/Shared/CustomInput/CustomInput';

import styles from './PayAmount.module.scss';
import TestIds from '../../../../test/TestIds';

type PayAmountProps = {
	currencyCode: CurrencyEnum;
	amount: string;
	onSetAmount?: (value: string) => void;
	maxValue?: string;
	errorMessage?: string | MessageDescriptor | null;
};

const PayAmount: FC<PayAmountProps> = ({
	currencyCode,
	amount,
	onSetAmount,
	maxValue,
	errorMessage,
}) => {
	return (
		<CustomInput
			data-cy={TestIds.PayAmount}
			className={styles.container}
			currency={currencyCode}
			value={amount || ''}
			onChange={({ target }) => onSetAmount && onSetAmount(target.value)}
			id="PayAmount"
			isRefAmountVisible
			isLoading={false}
			disabled={!onSetAmount}
			maxValue={maxValue}
			errorMessage={errorMessage}
			isIconVisible
		/>
	);
};

export default PayAmount;
