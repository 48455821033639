import { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropertyBlock from '../../../Shared/PropertyBlock/PropertyBlock';
import AutoConvertCurrency from '../../../Shared/AutoConvertCurrency/AutoConvertCurrency';
import { RootState } from '../../../../../redux/Store';
import ProjectMode from '../../../Shared/Mode/Mode';
import CopyButton from '../../../../../components/CopyButton/CopyButton';
import Block from '../../../Shared/Block/Block';
import PageTitle from '../../../Shared/PageTitle/PageTitle';
import MerchantsMessages from '../../../../../redux/MerchantsState/MerchantsMessages';
import Loading from '../../../Shared/Loading/Loading';
import NoProjects from '../../../Shared/NotFound/NotFound';
import { RemoteStatus } from '../../../../../interfaces/RemoteData';
import useMerchantRoutes, { MerchantRoutes } from '../../../../../hooks/useMerchantRoutes';
import MerchantsActions from '../../../../../redux/MerchantsState/MerchantsActions';
import MerchantsSelectors from '../../../../../redux/MerchantsState/MerchantsSelectors';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';

import styles from './PaymentButtonsView.module.scss';
import PaymentButtonStyle from './PaymentButtonStyle/PaymentButtonStyle';
import TestIds, { formatTestId } from '../../../../../test/TestIds';
import TextNode, { TextStyles } from '../../../../../components/TextNode/TextNode';

const Content: FC = () => {
	const { formatMessage } = useIntl();
	const { getParams, getUrl } = useMerchantRoutes();
	const { paymentButtonId = '' } = getParams();
	const {
		name,
		receiveAccountId,
		enabled,
		id,
		description,
		payCurrencyCodes: buttonPayCurrencies,
	} = useSelector(
		MerchantsSelectors.paymentButtons.data.getPaymentButtonDataById(paymentButtonId)
	)!;
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const wallet = wallets?.find((x) => x.id === receiveAccountId);
	const payCurrencies = buttonPayCurrencies
		? wallets?.filter((x) => buttonPayCurrencies?.includes(x.currencyCode)) || []
		: [];

	const publicUrl = new URL(
		getUrl(MerchantRoutes.PublicDonation, { paymentButtonId }),
		window.location.origin
	).href;

	return (
		<>
			<PageTitle title={name} backLink={getUrl(MerchantRoutes.PaymentButtonsList)}>
				<Link
					data-cy={TestIds.PaymentButtonViewEdit}
					className={styles.editLink}
					to={getUrl(MerchantRoutes.PaymentButtonEdit)}
				>
					<FormattedMessage {...MerchantsMessages.editButton} />
				</Link>
			</PageTitle>
			<div className={styles.content}>
				<Block className={styles.idBlock}>
					<div className={styles.label}>
						<div>{formatMessage(MerchantsMessages.publicUrl)}</div>
						<CopyButton className={styles.copyButton} dataToCopy={publicUrl} />
					</div>
					<div data-cy={TestIds.PaymentButtonViewPublicUrl} className={styles.value}>
						{publicUrl}
					</div>
				</Block>
				<PropertyBlock
					properties={[
						{
							label: formatMessage(MerchantsMessages.id),
							content: (
								<span className={classNames(styles.value, styles.right)}>{id}</span>
							),
							attr: { 'data-cy': TestIds.PaymentButtonViewId },
						},
						{
							label: formatMessage(MerchantsMessages.name),
							content: (
								<span className={classNames(styles.value, styles.right)}>
									{name}
								</span>
							),
							attr: { 'data-cy': TestIds.PaymentButtonViewName },
						},
						{
							label: formatMessage(MerchantsMessages.description),
							content: (
								<span className={classNames(styles.value, styles.right)}>
									{description || '-'}
								</span>
							),
							attr: { 'data-cy': TestIds.PaymentButtonViewDescription },
						},
						{
							label: formatMessage(MerchantsMessages.autoConvertCurrency),
							content: !wallet ? (
								<span className={styles.value}>-</span>
							) : (
								<AutoConvertCurrency
									className={styles.autoconvert}
									iconClassName={styles.autoconvertIcon}
									currencyCode={wallet.currencyCode}
									currencyName={wallet.currencyName}
								/>
							),
							attr: { 'data-cy': TestIds.PaymentButtonViewAutoconvert },
						},
						{
							label: formatMessage(MerchantsMessages.acceptableCurrencies),
							attr: {
								'data-cy': TestIds.PaymentButtonViewAcceptedCurrencies,
								'data-count': payCurrencies.length,
							},
							content: (
								<TextNode style={TextStyles.BreakWord}>
									{payCurrencies.length === 0 &&
										formatMessage(MerchantsMessages.allCurrenciesAvailable)}
									{payCurrencies.length === 1 && (
										<AutoConvertCurrency
											className={styles.autoconvert}
											iconClassName={styles.autoconvertIcon}
											currencyCode={payCurrencies[0].currencyCode}
											currencyName={payCurrencies[0].currencyName}
										/>
									)}
									{payCurrencies.length > 1 &&
										formatMessage(MerchantsMessages.x_currencies, {
											Count: payCurrencies.length,
										})}
								</TextNode>
							),
							expandableContent:
								payCurrencies.length > 1 ? (
									<div className={styles.acceptedCurrencies}>
										{payCurrencies.map((currency) => (
											<AutoConvertCurrency
												data-cy={formatTestId(
													TestIds.PaymentButtonViewAcceptedCurrencies_0,
													currency.currencyCode
												)}
												key={currency.currencyCode}
												className={styles.autoconvert}
												iconClassName={styles.autoconvertIcon}
												currencyCode={currency.currencyCode}
												currencyName={currency.currencyName}
											/>
										))}
									</div>
								) : undefined,
						},
						{
							label: formatMessage(MerchantsMessages.mode),
							content: <ProjectMode className={styles.mode} status={{ enabled }} />,
							attr: { 'data-cy': TestIds.PaymentButtonViewMode },
						},
					]}
				/>
			</div>
		</>
	);
};

const PaymentButtonsView: FC = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const { getParams } = useMerchantRoutes();

	const { paymentButtonId = '' } = getParams();

	const { status } = useSelector(
		MerchantsSelectors.paymentButtons.data.getPaymentButtonById(paymentButtonId)
	);

	useEffect(() => {
		if (status === RemoteStatus.None)
			dispatch(MerchantsActions.paymentButtons.data.fetchButton(paymentButtonId));
	}, [dispatch, paymentButtonId, status]);

	if (status === RemoteStatus.None) return null;

	return (
		<div className={styles.host}>
			<WhiteContainer className={styles.container} data-cy={TestIds.PaymentButtonView}>
				{status === RemoteStatus.InProgress && <Loading />}
				{status === RemoteStatus.Error && (
					<NoProjects message={formatMessage(MerchantsMessages.paymentButtonNotFound)} />
				)}
				{status === RemoteStatus.Done && <Content />}
			</WhiteContainer>
			{status === RemoteStatus.Done && <PaymentButtonStyle />}
		</div>
	);
};

export default PaymentButtonsView;
