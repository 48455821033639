import { MessageDescriptor, useIntl } from 'react-intl';
import classNames from 'classnames';
import { NumericFormat } from 'react-number-format';
import { CurrencyEnum, currencyUtils } from '@spectrocoin/sc-currencies';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import styles from './CurrencyInput.module.scss';
import inputStyles from '../Input/Input.module.scss';
import TestIds, { formatTestId } from '../../test/TestIds';
import { RootState } from '../../redux/Store';
import { DeviceNameType } from '../../redux/AppState/AppTypes';

interface CurrencyInputProps {
	id?: string;
	labelClassName?: string;
	inputContainerClassName?: string;
	value: string;
	label?: MessageDescriptor;
	currencyCode?: CurrencyEnum;
	isLoading?: boolean;
	onChange: (event: any) => void;
	error?: MessageDescriptor | string | null;
}

const CurrencyInput = ({
	labelClassName,
	inputContainerClassName,
	id,
	value,
	label,
	currencyCode,
	isLoading = false,
	onChange,
	error,
}: CurrencyInputProps) => {
	const { formatMessage } = useIntl();
	const { deviceOS } = useSelector((state: RootState) => state.AppState);
	const isAppleOS = useMemo(() => deviceOS === DeviceNameType.IPHONE, [deviceOS]);
	const [showAmount, setShowAmount] = useState('');
	const [fixedDecimal, setFixedDecimal] = useState(!!value);

	const onChangeEvent = (event: any) => {
		setShowAmount(event.target.value);
		onChange(event);
	};

	useEffect(() => setShowAmount(value), [value, currencyCode]);

	return (
		<div className={styles.container}>
			{label && (
				<label className={classNames(styles.label, labelClassName)} htmlFor={id}>
					{formatMessage(label)}
				</label>
			)}
			<div className={classNames(styles.inputContainer, inputContainerClassName)}>
				<NumericFormat
					className={classNames(inputStyles.input, {
						[inputStyles.errorInput]: error,
					})}
					decimalSeparator={isAppleOS ? ',' : '.'}
					decimalScale={currencyUtils.getConfig(CurrencyEnum.EUR).precision}
					allowNegative={false}
					onKeyUp={onChangeEvent}
					fixedDecimalScale={fixedDecimal}
					onBlur={() => setFixedDecimal(true)}
					onFocus={() => setFixedDecimal(false)}
					inputMode="decimal"
					thousandSeparator={isAppleOS ? '.' : ','}
					id={id}
					name={id}
					disabled={!currencyCode || isLoading}
					value={showAmount}
					autoComplete="off"
					placeholder={`0${isAppleOS ? ',' : '.'}00`}
					data-cy={formatTestId(TestIds.CurrencyInput, id)}
				/>
				{currencyCode && (
					<div className={styles.currencyCode} data-cy={TestIds.CurrencyCode}>
						{currencyCode}
					</div>
				)}
			</div>
			{error && (
				<div className={inputStyles.error}>
					{typeof error === 'string' ? error : formatMessage(error)}
				</div>
			)}
		</div>
	);
};

export default CurrencyInput;
