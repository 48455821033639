export enum RatesType {
	SET_RATES = 'SET_RATES',
	SET_RATES_DETAILS = 'SET_RATES_DETAILS',
	IS_LOADING = 'IS_LOADING_RATES',
}

export interface RatesState {
	rates: RatesResponse[] | null;
	ratesDetails: RatesDetailsResponse[] | null;
	isLoading: boolean;
}

export interface RatesResponse {
	currencyCode: string;
	currencySymbol: string;
	virtual: boolean;
	name: string;
	value: number;
	buyValue: number;
	sellValue: number;
	type: string;
	precision: number;
	scale: number;
}

export interface RatesDetailsResponse {
	currencyCode: string;
	currencySymbol: string;
	virtual: boolean;
	name: string;
	value: string;
	buyValue: number;
	sellValue: number;
	type: string;
	precision: number;
	scale: number;
	lastMonthChange: string;
	lastWeekChange: string;
	last24HChange: string;
}

export interface SetRatesAction {
	type: typeof RatesType.SET_RATES;
	data: RatesResponse[];
}

export interface SetRatesDetailsAction {
	type: typeof RatesType.SET_RATES_DETAILS;
	data: RatesDetailsResponse[];
}

export interface SetIsLoadingRates {
	type: typeof RatesType.IS_LOADING;
	isLoading: boolean;
}
