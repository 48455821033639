import { FC } from 'react';
import { useIntl } from 'react-intl';
import TestIds from '../../../../../test/TestIds';
import useMerchantRoutes, { MerchantRoutes } from '../../../../../hooks/useMerchantRoutes';
import Button, { ButtonType } from '../../../../../components/Button/Button';
import SvgIcon from '../../../../../components/SvgIcon/SvgIcon';
import messages from '../../../../../redux/MerchantsState/MerchantsMessages';

import successIcon from '../../../../../images/iban_icons/success.svg';

import styles from './NewMerchantsProjectSuccess.module.scss';
import baseMsg from '../../../../../messages/base.messages';

const NewMerchantsProjectSuccess: FC = () => {
	const { formatMessage } = useIntl();
	const { getUrl } = useMerchantRoutes();

	return (
		<div data-cy={TestIds.ProjectCreateSuccess} className={styles.container}>
			<SvgIcon className={styles.icon} src={successIcon} />
			<p className={styles.text}>{formatMessage(messages.projectCreateSuccess)}</p>
			<Button
				className={styles.button}
				text={baseMsg.close}
				type={ButtonType.LINK}
				link={getUrl(MerchantRoutes.ProjectsList)}
			/>
		</div>
	);
};

export default NewMerchantsProjectSuccess;
