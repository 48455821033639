import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import baseMsg from '../../../messages/base.messages';
import { RootState } from '../../../redux/Store';
import Button, { ButtonType } from '../../../components/Button/Button';
import Seo from '../../../components/Seo/Seo';
import styles from './PhoneConfirmIBAN.module.scss';

const messages = defineMessages({
	title: {
		id: 'claimIBAN.title',
		defaultMessage: 'Claim IBAN',
	},
	changePhone: {
		id: 'IBANPhoneConfirm.changePhone',
		defaultMessage: 'Change phone number',
	},
	paragraph: {
		id: 'IBANPhoneConfirm.paragraph',
		defaultMessage: 'Please confirm if your phone number matches our records.',
	},
	somethingWentWrong: {
		id: 'claimIBAN.somethingWentWrong',
		defaultMessage: 'Something went wrong. Please try again later.',
	},
	metaTitle: {
		id: 'claimIBAN.phoneConfirmMeta',
		defaultMessage: 'IBAN Phone Confirm',
	},
});

const PhoneConfirmIBAN = () => {
	const { formatMessage, locale } = useIntl();
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const { url } = useRouteMatch();

	return (
		<div className={styles.container}>
			<Seo title={messages.metaTitle} />
			<h2 className={styles.title}>{formatMessage(messages.title)}</h2>
			<p className={styles.paragraph}>{formatMessage(messages.paragraph)}</p>
			<p className={styles.paragraph}>
				<span className={styles.label}>{formatMessage(baseMsg.phoneNumber)}</span>
				<span className={styles.phone}>{user?.phone}</span>
			</p>
			<div className={styles.buttonContainer}>
				<Button
					text={baseMsg.confirm}
					type={ButtonType.LINK}
					link={url.replaceAll('/phone-confirm', '')}
				/>
			</div>
			<a href={`/${locale}/profile/phoneUpdate.html`} className={styles.link}>
				{formatMessage(messages.changePhone)}
			</a>
		</div>
	);
};
export default PhoneConfirmIBAN;
