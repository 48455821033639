import {
	NotificationsActionType,
	NotificationsSetUnreadAction,
	NotificationsSetUnreadCountAction,
	NotificationsState,
} from './NotificationsTypes';

const initialState: NotificationsState = {
	count: 0,
	notifications: [],
};

export default (
	state = initialState,
	action: NotificationsSetUnreadAction | NotificationsSetUnreadCountAction
): NotificationsState => {
	switch (action.type) {
		case NotificationsActionType.SET_UNREAD_NOTIFICATIONS:
			return {
				...state,
				notifications: action.payload.notifications,
			};
		case NotificationsActionType.SET_UNREAD_NOTIFICATION_COUNT:
			return {
				...state,
				count: action.payload.count,
			};

		default:
			return state;
	}
};
