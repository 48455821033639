import { useIntl } from 'react-intl';
import { WithdrawHistoryItem } from '../../../../redux/WithdrawHistoryState/WithdrawHistoryTypes';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';
import { DetailsModalType } from '../../../../components/Modal/DetailsModal/DetailsModal';
import statusMsg from '../../../../messages/status.messages';
import HistoryListItem from '../../../../components/HistoryTable/HistoryListItem/HistoryListItem';

interface Props {
	data: WithdrawHistoryItem[];
}

const CryptoWithdrawHistory = ({ data }: Props): JSX.Element => {
	const { formatMessage } = useIntl();
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{data?.length > 0 &&
				data.map(
					({
						id,
						transNo,
						date,
						status,
						receiveAmount,
						currencyCode: currCode,
						feeCurrencyCode,
						feeAmount,
						memo,
						withdrawAccount,
						receiveCurrencyCode,
						withdrawAmount,
						network,
					}) => (
						<HistoryListItem
							withdrawId={id}
							transNo={transNo || ''}
							withdrawAccount={withdrawAccount}
							amount={receiveAmount}
							fee={feeAmount}
							feeCurrency={feeCurrencyCode}
							key={id}
							dateCreated={date}
							status={formatMessage(statusMsg[status])}
							currency={currCode || receiveCurrencyCode}
							title={formatMessage(withdrawMessages.withdrawTitle, { currCode })}
							detailsModalType={DetailsModalType.CRYPTO_WITHDRAW}
							description={memo || ''}
							totalAmount={withdrawAmount}
							network={network}
							areFieldsCopyable
							isCryptoWithdraw
						/>
					)
				)}
		</>
	);
};

export default CryptoWithdrawHistory;
