import { combineReducers } from 'redux';
import { RemoteStatus } from '../../interfaces/RemoteData';
import MerchantsProjectsReducer from './Projects/ProjectsReducer';
import MerchantsOrdersReducer from './Orders/OrdersReducer';
import MerchantsPreOrdersReducer from './Preorders/PreordersReducer';
import PaymentButtonsReducer from './PaymentButtons/PaymentButtonsReducer';
import {
	MerchantsActionType,
	MerchantsLoadState,
	MerchantsLoadDataPendingAction,
	MerchantsLoadDataRejectedAction,
	MerchantsLoadDataFulfilledAction,
} from './MerchantTypes';
import PayoutsReducer from './CryptoPayouts/PayoutsReducer';

const initialState: MerchantsLoadState = {
	status: RemoteStatus.None,
};

const reducer = (
	state = initialState,
	action:
		| MerchantsLoadDataPendingAction
		| MerchantsLoadDataRejectedAction
		| MerchantsLoadDataFulfilledAction
): MerchantsLoadState => {
	switch (action.type) {
		case MerchantsActionType.LOAD_DATA_PENDING:
			return {
				...state,
				status: RemoteStatus.InProgress,
			};
		case MerchantsActionType.LOAD_DATA_REJECTED:
			return {
				...state,
				status: RemoteStatus.Error,
				error: action.error,
			};
		case MerchantsActionType.LOAD_DATA_FULFILLED:
			return {
				...state,
				status: RemoteStatus.Done,
			};
		default:
			return state;
	}
};

export default combineReducers({
	load: reducer,
	projects: MerchantsProjectsReducer,
	orders: MerchantsOrdersReducer,
	preorders: MerchantsPreOrdersReducer,
	paymentButtons: PaymentButtonsReducer,
	payouts: PayoutsReducer,
});
