import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { NotificationDto } from '@spectrocoin/user-notifications';
import { AppDispatch, RootState } from '../Store';
import { getNotificationsURL, getUnreadNotificationCountURL } from '../endpoints';
import {
	NotificationsActionType,
	NotificationsSetUnreadCountAction,
	NotificationsSetUnreadAction,
} from './NotificationsTypes';
import PageableResponse from '../../interfaces/PageableResponse';
import axiosInstance from '../../helpers/axiosInstance';

const setUnreadNotifications: ActionCreator<NotificationsSetUnreadAction> = (
	notifications: NotificationDto[]
) => ({
	type: NotificationsActionType.SET_UNREAD_NOTIFICATIONS,
	payload: {
		notifications,
	},
});

const setUnreadNotificationsCount: ActionCreator<NotificationsSetUnreadCountAction> = (
	count: number
) => ({
	type: NotificationsActionType.SET_UNREAD_NOTIFICATION_COUNT,
	payload: {
		count,
	},
});

const getUnreadNotifications = (): ThunkAction<
	Promise<PageableResponse<NotificationDto[]> | void>,
	RootState,
	undefined,
	any
> => async (dispatch) => {
	const query = {
		page: 0,
		isRead: false,
		sort: 'created,desc',
		size: 3,
	};

	return axiosInstance
		.get<PageableResponse<NotificationDto[]>>(getNotificationsURL(), {
			params: query,
		})
		.then(({ data }) => {
			dispatch(setUnreadNotifications(data.content));
			return data;
		})
		.catch(() => {});
};

const getUnreadNotificationCount = () => (dispatch: AppDispatch) => {
	return axiosInstance
		.get<{ count: number }>(getUnreadNotificationCountURL())
		.then(({ data }) => {
			return dispatch(setUnreadNotificationsCount(data.count));
		})
		.catch(() => {});
};

export default {
	getUnreadNotifications,
	getUnreadNotificationCount,
};
