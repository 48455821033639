import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import Joi from 'joi';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import {
	isAmountGreaterThan,
	isAmountGreaterThanZero,
	isAmountLessThan,
} from '../../../../helpers/inputValidation/inputValidation';
import inputErrors from '../../../../messages/inputErrors.messages';
import { PaymentMethod, ProviderType } from '../../../../redux/WithdrawState/WithdrawTypes';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';

const useBankFormValidator = (
	currency: string,
	balance: string,
	providerType: ProviderType,
	isIBANExist: boolean,
	currentMethod: PaymentMethod
) => {
	const { formatMessage } = useIntl();
	return useMemo(
		() =>
			Joi.object({
				currency: Joi.string()
					.required()
					.messages({ 'string.empty': formatMessage(inputErrors.cannotBeEmpty) }),
				...(!(
					providerType === ProviderType.SEPA &&
					isIBANExist &&
					currentMethod.isIBAN
				) && {
					account: Joi.string()
						.alphanum()
						.required()
						.messages({
							'string.empty': formatMessage(inputErrors.cannotBeEmpty),
							'string.alphanum': formatMessage(inputErrors.alphaNumericCharacters, {
								Field: formatMessage(withdrawMessages.accountLabel),
							}),
						}),
				}),
				...(providerType === ProviderType.SEPA &&
					isIBANExist &&
					currentMethod.isIBAN && {
						account: Joi.string()
							.alphanum()
							.required()
							.messages({
								'string.empty': formatMessage(inputErrors.cannotBeEmpty),
								'string.alphanum': formatMessage(
									inputErrors.alphaNumericCharacters,
									{
										Field: formatMessage(
											withdrawMessages.accountNumberIbanLabel
										),
									}
								),
							}),
					}),
				...(providerType &&
					[ProviderType.WIRE, ProviderType.LOCAL].includes(providerType) && {
						swift: Joi.string()
							.min(8)
							.max(11)
							.alphanum()
							.required()
							.messages({
								'string.empty': formatMessage(inputErrors.cannotBeEmpty),
								'string.min': formatMessage(inputErrors.x_lessAndNotMoreThen_y, {
									Field: formatMessage(withdrawMessages.swiftLabel),
									Min: 8,
									Max: 11,
								}),
								'string.max': formatMessage(inputErrors.x_lessAndNotMoreThen_y, {
									Field: formatMessage(withdrawMessages.accountLabel),
									Min: 8,
									Max: 11,
								}),
								'string.alphanum': formatMessage(
									inputErrors.alphaNumericCharacters,
									{
										Field: formatMessage(withdrawMessages.swiftLabel),
									}
								),
							}),
					}),
				...(providerType &&
					[ProviderType.WIRE, ProviderType.LOCAL].includes(providerType) && {
						address: Joi.string()
							.required()
							.regex(/^[#.0-9a-zA-Z \\s,-]+$/)
							.messages({
								'string.empty': formatMessage(inputErrors.cannotBeEmpty),
								'string.pattern.base': formatMessage(
									inputErrors.mustAddValidAddress
								),
							}),
					}),

				...(providerType === ProviderType.WIRE &&
					isIBANExist &&
					currentMethod.isIBAN && {
						receiverBankName: Joi.string()
							.required()
							.messages({
								'string.empty': formatMessage(inputErrors.cannotBeEmpty),
							}),
						receiverBankCountry: Joi.string()
							.required()
							.messages({
								'string.empty': formatMessage(inputErrors.cannotBeEmpty),
							}),
						receiverAddress: Joi.string()
							.required()
							.regex(/^[#.0-9a-zA-Z \\s,-]+$/)
							.messages({
								'string.empty': formatMessage(inputErrors.cannotBeEmpty),
								'string.pattern.base': formatMessage(
									inputErrors.mustAddValidAddress
								),
							}),
						receiverCountry: Joi.string()
							.required()
							.messages({
								'string.empty': formatMessage(inputErrors.cannotBeEmpty),
							}),
						receiverProvince: Joi.string()
							.required()
							.messages({
								'string.empty': formatMessage(inputErrors.cannotBeEmpty),
							}),
						receiverCity: Joi.string()
							.required()
							.messages({
								'string.empty': formatMessage(inputErrors.cannotBeEmpty),
							}),
						receiverPostalCode: Joi.string()
							.required()
							.messages({
								'string.empty': formatMessage(inputErrors.cannotBeEmpty),
							}),
					}),
				...(!(
					providerType === ProviderType.SEPA &&
					isIBANExist &&
					currentMethod.isIBAN
				) && {
					receiver: Joi.string()
						.required()
						.messages({
							'string.empty': formatMessage(inputErrors.cannotBeEmpty),
						}),
				}),
				...(providerType === ProviderType.SEPA &&
					isIBANExist &&
					currentMethod.isIBAN && {
						receiver: Joi.string()
							.required()
							.min(2)
							.max(70)
							.messages({
								'string.empty': formatMessage(inputErrors.cannotBeEmpty),
								'string.min': formatMessage(inputErrors.x_mustBe_y_Length, {
									Field: formatMessage(withdrawMessages.receiverLabel),
									Length: 2,
								}),
								'string.max': formatMessage(inputErrors.x_mustBe_y_Length, {
									Field: formatMessage(withdrawMessages.receiverLabel),
									Length: 70,
								}),
							}),
					}),
				amount: Joi.string()
					.required()
					.custom((value: string, helper: any) => {
						if (!isAmountGreaterThanZero(value))
							return helper.message(formatMessage(inputErrors.amountTooLow));
						if (isAmountGreaterThan(value, balance))
							return helper.message(formatMessage(inputErrors.insufficientFunds));
						if (
							currentMethod?.maxAmount &&
							isAmountGreaterThan(value, currentMethod?.maxAmount || '0')
						)
							return helper.message(
								formatMessage(withdrawMessages.maxAmount, {
									amount: `${formatPrecision(
										currentMethod?.maxAmount || '0',
										CurrencyEnum[currency.split('/')[0]]
									)} ${currency.split('/')[0]}`,
								})
							);
						if (
							currentMethod?.minAmount &&
							isAmountLessThan(value, currentMethod?.minAmount || '0')
						)
							return helper.message(
								formatMessage(withdrawMessages.minAmount, {
									amount: `${formatPrecision(
										currentMethod?.minAmount || '0',
										CurrencyEnum[currency.split('/')[0]]
									)} ${currency.split('/')[0]}`,
								})
							);
						return true;
					})
					.messages({
						'string.empty': formatMessage(inputErrors.cannotBeEmpty),
					}),
				details: Joi.string()
					.required()
					.max(125)
					.regex(/^[0-9a-zA-Z/\-?:().,'+ ]+$/)
					.messages({
						'string.empty': formatMessage(inputErrors.cannotBeEmpty),
						'string.max': formatMessage(inputErrors.x_lessOrEqual_y, {
							Field: formatMessage(withdrawMessages.details),
							Max: 125,
						}),
						'string.alphanum': formatMessage(inputErrors.alphaNumericCharacters, {
							Field: formatMessage(withdrawMessages.details),
						}),
						'string.pattern.base': formatMessage(inputErrors.latinLetters),
					}),
				...(providerType === ProviderType.SEPA &&
					!isIBANExist &&
					!currentMethod.isIBAN && {
						country: Joi.string()
							.required()
							.messages({
								'string.empty': formatMessage(inputErrors.cannotBeEmpty),
							}),
					}),
				...(providerType === ProviderType.UK_LOCAL && {
					sortCode: Joi.string()
						.required()
						.min(6)
						.max(6)
						.messages({
							'string.empty': formatMessage(inputErrors.cannotBeEmpty),
							'string.min': formatMessage(inputErrors.x_mustBe_y_Length, {
								Field: formatMessage(withdrawMessages.sortCodeLabel),
								Length: 6,
							}),
							'string.max': formatMessage(inputErrors.x_mustBe_y_Length, {
								Field: formatMessage(withdrawMessages.sortCodeLabel),
								Length: 6,
							}),
						}),
				}),
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[currency, balance, providerType, isIBANExist, currentMethod]
	);
};

export default useBankFormValidator;
