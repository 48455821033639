import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import { AxiosResponse } from 'axios';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import queryString, { ParsedQuery } from 'query-string';
import {
	getWithdrawGiftCalcURL,
	getWithdrawGiftItemURL,
	getWithdrawGiftURL,
} from '../../../../redux/endpoints';
import { RootState } from '../../../../redux/Store';
import {
	PurchaseItem,
	PurchaseSuccessResponse,
	WithdrawType,
} from '../../../../redux/WithdrawState/WithdrawTypes';
import Button, { ButtonType } from '../../../../components/Button/Button';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import CustomInput from '../../Shared/CustomInput/CustomInput';
import WithdrawConfirm from '../../Shared/WithdrawConfirm/WithdrawConfirm';
import styles from './GiftCardsForm.module.scss';
import WithdrawFormContainer, {
	WithdrawSteps,
} from '../../Shared/WithdrawFormContainer/WithdrawFormContainer';
import WithdrawSuccess from '../../Shared/WithdrawSuccess/WithdrawSuccess';
import { toPlainAmount } from '../../../../helpers/currencyAmountHelper/currencyAmountHelper';
import baseMsg from '../../../../messages/base.messages';
import AccountSelect from '../../Shared/AccountSelect/AccountSelect';
import Seo from '../../../../components/Seo/Seo';
import useDebounce from '../../../../hooks/useDebounce';
import useGiftCardsFormValidator from './useGiftCardsFormValidator';
import useValidation from '../../../../hooks/useValidation';
import usePrevious from '../../../../hooks/usePrevious';
import { DeviceNameType } from '../../../../redux/AppState/AppTypes';
import { swapSeparators } from '../../../../helpers/currencyHelper/currencyHelper';
import TransfersFormLayout from '../../../../layout/TransfersFormLayout/TransfersFormLayout';
import { HowToType } from '../../../../layout/TransfersFormLayout/HowTo/HowTo';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	howToTitle: {
		id: 'giftCardsForm.howToTitle',
		defaultMessage: 'Gift Card',
	},
	payWallet: {
		id: 'giftCardsForm.payWallet',
		defaultMessage: 'Pay wallet',
	},
	receiveAmount: {
		id: 'giftCardsForm.receiveAmount',
		defaultMessage: 'Gift card amount',
	},
	amountIsTooLow: {
		id: 'giftCardsForm.amountIsTooLow',
		defaultMessage: 'Amount must be greater then {amount}',
	},
	amountIsTooBig: {
		id: 'giftCardsForm.amountIsTooBig',
		defaultMessage: 'Max gift card amount {amount}',
	},
});

interface Params {
	id: string;
}

interface FormProps {
	payWallet: string;
	payAmount: string;
	receiveAmount: string;
}

interface FormErrorsProps {
	payWallet: MessageDescriptor | string | null;
	payAmount: MessageDescriptor | string | null;
	receiveAmount: MessageDescriptor | string | null;
}

export interface GiftItemResponse {
	id: string;
	itemCurrency: CurrencyEnum;
	itemIdentifier: string;
	maxPrice: string;
	minPrice: string;
	title: string;
}

enum CalcType {
	payAmount = 'payAmount',
	receiveAmount = 'receiveAmount',
}

interface CalcResponse {
	payAmount: string | null;
	payCurrencyCode: CurrencyEnum;
	receiveAmount: string | null;
	receiveCurrencyCode: CurrencyEnum;
}

const GiftCardsForm = () => {
	const { id } = useParams<Params>();
	const { locale, formatMessage } = useIntl();
	const { pathname, search } = useLocation();
	const { push } = useHistory();
	const parsedQuery: ParsedQuery<string> = queryString.parse(search);
	const { step } = parsedQuery;
	const refCurrency = Cookies.get('primaryCurrency') || CurrencyEnum.USD;
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const { deviceOS } = useSelector((state: RootState) => state.AppState);
	const isAppleOS = useMemo(() => deviceOS === DeviceNameType.IPHONE, [deviceOS]);
	const { paymentMethods } = useSelector((state: RootState) => state.WithdrawState);
	const { user } = useSelector((state: RootState) => state.ProfileState);
	// eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
	const { gift_cards } = paymentMethods;
	// eslint-disable-next-line camelcase
	const { data: giftCardsData, isLoaded } = gift_cards;
	const currentGC = giftCardsData.filter((o: PurchaseItem) => o.id === id)[0];
	const [form, setForm] = useState<FormProps>({
		payWallet: '',
		payAmount: '',
		receiveAmount: '',
	});
	const [formErrors, setFormErrors] = useState<FormErrorsProps>({
		payWallet: null,
		payAmount: null,
		receiveAmount: null,
	});
	const [balance, setBalance] = useState<string>('0');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [item, setItem] = useState<GiftItemResponse | null>(null);
	const [successData, setSuccessData] = useState<PurchaseSuccessResponse[]>([]);
	const [currentMethod, setCurrentMethod] = useState<PurchaseItem | null>(null);
	const [calcType, setCalcType] = useState<CalcType | null>(null);
	const debouncedPayAmount = useDebounce(form.payAmount, 1000);
	const debouncedReceiveAmount = useDebounce(form.receiveAmount, 1000);
	const prevPay = usePrevious(debouncedPayAmount);
	const prevReceive = usePrevious(debouncedReceiveAmount);

	const validator = useGiftCardsFormValidator(form.payWallet, item!, balance);

	const [isValid, validationResult] = useValidation(
		{
			payWallet: form.payWallet,
			payAmount: form.payAmount,
			receiveAmount: form.receiveAmount,
		},
		validator
	);

	const getIsFormValid = useCallback(() => {
		setFormErrors({ ...formErrors, ...validationResult });
		return isValid;
	}, [formErrors, isValid, validationResult]);

	const calc = useCallback(
		(type: CalcType) => {
			setIsLoading(true);
			return axiosInstance.post(getWithdrawGiftCalcURL(), {
				id: item?.id,
				payCurrencyCode: form.payWallet.split('/')[0],
				payAmount: type === CalcType.payAmount ? form.payAmount : '',
				receiveAmount: type === CalcType.receiveAmount ? form.receiveAmount : '',
			});
		},
		[form, item]
	);

	const onInputChange = (value: string, inputId: string) => {
		setFormErrors({
			...formErrors,
			[inputId]: null,
		});
		setCalcType(CalcType[inputId]);
		return setForm({
			...form,
			[inputId]: toPlainAmount(value, true),
		});
	};

	const onSuccess = (data: PurchaseSuccessResponse) => setSuccessData([data]);

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!getIsFormValid()) return null;
		return calc(CalcType.payAmount)
			?.then(({ data }: AxiosResponse<CalcResponse>) => {
				const { payAmount, receiveAmount } = data;
				setFormErrors({
					payWallet: null,
					payAmount: null,
					receiveAmount: null,
				});
				setForm({
					...form,
					receiveAmount: receiveAmount || form.receiveAmount,
					payAmount: payAmount || form.payAmount,
				});
				if (getIsFormValid()) {
					return push({ pathname, search: `step=${WithdrawSteps.CONFIRM}` });
				}
				return null;
			})
			.catch(() => null)
			.then(() => setIsLoading(false));
	};

	const postData = () =>
		axiosInstance.post(getWithdrawGiftURL(), {
			receiver: user?.email || '',
			payAccountId: form.payWallet.split('/')[2],
			purchaseProductItemId: item?.id,
			receiveAmount: toPlainAmount(form.receiveAmount),
		});

	useEffect(() => {
		void axiosInstance.get(getWithdrawGiftItemURL(id)).then(({ data }) => {
			setItem(data[0]);
		});
	}, [id]);

	useEffect(() => {
		if (form.payWallet && form.payAmount && form.receiveAmount)
			void calc(CalcType.payAmount)
				.then(({ data }: AxiosResponse<CalcResponse>) => {
					const { payAmount, receiveAmount } = data;
					setFormErrors({
						payWallet: null,
						payAmount: null,
						receiveAmount: null,
					});
					setForm({
						...form,
						receiveAmount: receiveAmount || form.receiveAmount,
						payAmount: payAmount || form.payAmount,
					});
				})
				.catch(() => null)
				.then(() => setIsLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.payWallet]);

	// set available balance
	useEffect(() => {
		if (form.payWallet)
			setBalance(
				wallets?.find(({ id: walletId }) => walletId === form.payWallet.split('/')[2])
					?.availableBalance || '0'
			);
	}, [form.payWallet, wallets]);

	useEffect(() => {
		if (!step) {
			setSuccessData([]);
			setForm({
				payWallet: '',
				payAmount: '',
				receiveAmount: '',
			});
		}
		setIsLoading(false);
	}, [step]);

	useEffect(() => {
		if (
			form.payWallet &&
			debouncedPayAmount &&
			debouncedPayAmount !== prevPay &&
			calcType === CalcType.payAmount
		) {
			void calc(CalcType.payAmount)
				.then(({ data }: AxiosResponse<CalcResponse>) => {
					setFormErrors({
						payWallet: null,
						payAmount: null,
						receiveAmount: null,
					});
					return setForm({
						...form,
						receiveAmount: data.receiveAmount || '0',
					});
				})
				.catch(() => null)
				.then(() => {
					setCalcType(null);
					setIsLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedPayAmount, prevPay, calcType]);

	useEffect(() => {
		if (
			form.payWallet &&
			debouncedReceiveAmount &&
			prevReceive !== debouncedReceiveAmount &&
			calcType === CalcType.receiveAmount
		) {
			void calc(CalcType.receiveAmount)
				.then(({ data }: AxiosResponse<CalcResponse>) => {
					setFormErrors({
						payWallet: null,
						payAmount: null,
						receiveAmount: null,
					});
					return setForm({
						...form,
						payAmount: data.payAmount || '0',
					});
				})
				.catch(() => null)
				.then(() => {
					setCalcType(null);
					setIsLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedReceiveAmount, prevReceive, calcType]);

	useEffect(() => {
		if (gift_cards?.data?.length > 0 && !currentMethod) {
			setCurrentMethod(gift_cards?.data?.filter((o: PurchaseItem) => o?.id === id)[0]);
		}
	}, [currentMethod, gift_cards?.data, gift_cards?.data?.length, id]);

	return (
		<>
			<Seo title={currentGC?.title} />
			<WithdrawFormContainer
				walletId={form.payWallet.split('/')[2]}
				isLoaded={isLoaded && currentGC !== null}
				title={
					<PageTitle
						previousPageLink={
							step === WithdrawSteps.CONFIRM
								? `/${locale}/withdraw/gift-cards/${id}?step=1`
								: `/${locale}/withdraw/gift-cards`
						}
						isPreviousPageLinkVisibleOnDesktop
						isPreviousPageLinkVisibleOnMobile
						title={currentGC?.title}
					/>
				}
				form={
					<TransfersFormLayout
						method={currentMethod}
						type={HowToType.WITHDRAW}
						feeCurrency={CurrencyEnum.EUR}
						title={formatMessage(messages.howToTitle)}
						providerType="GiftCard"
					>
						<form onSubmit={handleSubmit} className={styles.form}>
							<AccountSelect
								value={form.payWallet}
								onChange={(payWallet: string) => {
									setFormErrors({
										...formErrors,
										payWallet: null,
									});
									setForm({
										...form,
										payWallet,
									});
								}}
								errorMessage={formErrors.payWallet}
								type={WithdrawType.GIFT}
							/>
							<CustomInput
								id="payAmount"
								label={baseMsg.payAmount}
								currency={CurrencyEnum[form.payWallet.split('/')[0]]}
								onChange={({
									target: { value, id: inputId },
								}: ChangeEvent<HTMLInputElement>) => onInputChange(value, inputId)}
								isLoading={isLoading}
								value={isAppleOS ? swapSeparators(form.payAmount) : form.payAmount}
								errorMessage={formErrors.payAmount}
							/>
							<CustomInput
								id="receiveAmount"
								label={messages.receiveAmount}
								currency={
									item
										? CurrencyEnum[item.itemCurrency]
										: CurrencyEnum[refCurrency]
								}
								onChange={({
									target: { value, id: inputId },
								}: ChangeEvent<HTMLInputElement>) => onInputChange(value, inputId)}
								isLoading={isLoading}
								value={
									isAppleOS
										? swapSeparators(form.receiveAmount)
										: form.receiveAmount
								}
								isRefAmountVisible={false}
								errorMessage={formErrors.receiveAmount}
							/>
							<Button
								text={baseMsg.submit}
								type={ButtonType.SUBMIT}
								isLoading={isLoading || !item}
								isDisabled={isLoading || !item}
								className={styles.button}
							/>
						</form>
					</TransfersFormLayout>
				}
				confirm={
					<WithdrawConfirm
						type={WithdrawType.GIFT}
						currency={CurrencyEnum[form.payWallet.split('/')[0]]}
						formData={[
							{
								...form,
								giftTitle: currentGC?.title,
								receiveCurrency: item
									? CurrencyEnum[item.itemCurrency]
									: CurrencyEnum[refCurrency],
							},
						]}
						onSuccess={onSuccess}
						onSubmit={postData}
					/>
				}
				done={
					<WithdrawSuccess
						data={successData}
						currency={CurrencyEnum[form.payWallet.split('/')[0]]}
						type={WithdrawType.GIFT}
					/>
				}
				isDone={successData.length > 0}
			/>
		</>
	);
};

export default GiftCardsForm;
