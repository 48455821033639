import { RemoteStatus } from '../../../../interfaces/RemoteData';
import { values } from '../../../../helpers/objectHelper/objectHelper';
import createSelector from '../../../createSelector';

const getState = createSelector((state) => state.MerchantState.projects.data);

const getProjectById = (id: string) =>
	createSelector(getState, (state) => state.projects[id] || { status: RemoteStatus.None });

const getProjectDataById = (id: string) =>
	createSelector(getProjectById(id), (state) => state?.data);

const getProjectsDataByIds = (ids: string[]) =>
	createSelector(getState, (state) =>
		ids
			.map((id) => state.projects[id])
			.filter((x) => x?.status === RemoteStatus.Done)
			.map((x) => x.data!)
	);
const getProjectsData = () =>
	createSelector(getState, (state) =>
		values(state.projects)
			.filter((x) => x?.status === RemoteStatus.Done)
			.map((x) => x.data!)
	);
const getEnabledProjects = () =>
	createSelector(getState, (state) =>
		values(state.projects)
			.filter((x) => x?.status === RemoteStatus.Done && x.data?.enabled)
			.map((x) => x.data!)
	);

const getHasAny = createSelector(getState, (state) =>
	values(state.projects).some((x) => x.status === RemoteStatus.Done)
);

export default {
	getState,
	getHasAny,
	getProjectById,
	getProjectDataById,
	getProjectsDataByIds,
	getProjectsData,
	getEnabledProjects,
};
