import { AxiosResponse } from 'axios';
import {
	getCalcURL,
	getConvertPairsURL,
	getIsRecurringOrderURL,
	getExchangeURL,
	getRecurringOrderURL,
} from '../endpoints';
import { AppDispatch } from '../Store';
import {
	CalcType,
	ConvertActionTypes,
	ConvertCurrencyModel,
	Frequency,
	SetIsRecurringEnabledAction,
} from './ConvertTypes';
import axiosInstance from '../../helpers/axiosInstance';

export const getConvertPair = () => (dispatch: AppDispatch) => {
	return axiosInstance
		.get(getConvertPairsURL())
		.then(({ data }: AxiosResponse<ConvertCurrencyModel[]>) => {
			return dispatch({ type: ConvertActionTypes.SET_CONVERT_PAIRS, data });
		});
};

export const calcAmount = (
	type: CalcType,
	exchangePairId: string,
	value: string,
	skipIndleCheck?: boolean
) => {
	return axiosInstance
		.post(
			getCalcURL(type),
			{
				exchangePairId,
				...(type === CalcType.PAY && { receiveAmount: value }),
				...(type === CalcType.RECEIVE && { payAmount: value }),
			},
			{
				skipIdle: skipIndleCheck,
			}
		)
		.then(({ data }) => {
			const response = {
				...(type === CalcType.RECEIVE && {
					amount: data.receiveAmount,
					currency: data.receiveCurrencyCode,
				}),
				...(type === CalcType.PAY && {
					amount: data.payAmount,
					currency: data.payCurrencyCode,
				}),
			};
			return response;
		});
};

export const postExchange = (
	id: string,
	payAmount: string,
	payAccountId: string,
	receiveAccountId: string
) => {
	return axiosInstance
		.post(getExchangeURL(), {
			id,
			payAmount,
			payAccountId,
			receiveAccountId,
		})
		.then(({ data }) => data)
		.catch(({ response }) => response.data);
};

export const getIsRecurringEnabled = () => (dispatch: AppDispatch) => {
	return axiosInstance
		.get(getIsRecurringOrderURL())
		.then(({ data: { enabled } }) => {
			return dispatch<SetIsRecurringEnabledAction>({
				type: ConvertActionTypes.SET_IS_RECURRING_ENABLED,
				data: enabled,
			});
		})
		.catch((e) => console.log(`${e} occurred`));
};

export const postRecurringOrder = (
	currencyExchangeId: string,
	frequency: Frequency,
	payAccountId: string,
	receiveAccountId: string,
	payAmount: string,
	startDate: string = '',
	endDate: string = ''
) => {
	return axiosInstance
		.post(getRecurringOrderURL(), {
			currencyExchangeId,
			frequency,
			payAccountId,
			receiveAccountId,
			payAmount,
			startDate,
			endDate,
		})
		.then(({ data }) => data)
		.catch(({ response }) => response.data);
};
