/* eslint-disable import/prefer-default-export */
import UserContractCompany from '../../enums/UserContractCompanyEnum';
import { UserCompany } from '../../redux/ProfileState/ProfileTypes';

export const resolveUserCompany = (
	payloadCompany: UserContractCompany | string | UserCompany | null | undefined
): UserCompany | null => {
	switch (payloadCompany) {
		case UserContractCompany.BNK_OPERATIONS_BVI:
		case UserCompany.BNK_OPERATIONS_BVI:
			return UserCompany.BNK_OPERATIONS_BVI;
		case UserContractCompany.BNK_SYSTEMS_BVI:
		case UserCompany.BNK_SYSTEMS_BVI:
			return UserCompany.BNK_SYSTEMS_BVI;
		case UserContractCompany.BNK_SOLUTIONS_BVI:
		case UserCompany.BNK_SOLUTIONS_BVI:
			return UserCompany.BNK_SOLUTIONS_BVI;
		case UserContractCompany.EE:
		case UserCompany.EE:
			return UserCompany.EE;
		case UserContractCompany.LT:
		case UserCompany.LT:
			return UserCompany.LT;
		case UserContractCompany.SF_BVI:
		case UserCompany.SF_BVI:
			return UserCompany.SF_BVI;
		case UserContractCompany.SF_SYSTEMS_BVI:
		case UserCompany.SF_SYSTEMS_BVI:
			return UserCompany.SF_SYSTEMS_BVI;
		default:
			return null;
	}
};
