/* eslint-disable react/jsx-no-useless-fragment */
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons/faLongArrowRight';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CurrencyEnum, CurrencyIcon } from '@spectrocoin/sc-currencies';
import classNames from 'classnames';
import { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import Toggle from '../../../../../components/Toggle/Toggle';
import { formatPrecision } from '../../../../../helpers/currencyHelper/currencyHelper';
import { updateOpenOrder } from '../../../../../redux/ConvertHistoryState/ConvertHistoryActions';
import { OpenOrderType } from '../../../../../redux/ConvertHistoryState/ConvertHistoryTypes';
import { ConvertStatusType, Frequency } from '../../../../../redux/ConvertState/ConvertTypes';
import toggleModal from '../../../../../redux/ModalState/ModalActions';
import convertMsg from '../../../convertMsg';
import CancelOrder from '../CancelOrder/CancelOrder';
import styles from './OpenOrdersItem.module.scss';
import useFormatAmount from '../../../../../hooks/useFormatAmount';

interface Props {
	id: string;
	payCurrencyCode: CurrencyEnum;
	receiveCurrencyCode: CurrencyEnum;
	frequency: Frequency;
	payAmount: string;
	startDate: string | null;
	endDate: string | null;
	status: ConvertStatusType;
}

const OpenOrdersItem: FC<Props> = ({
	id,
	payCurrencyCode,
	receiveCurrencyCode,
	frequency,
	payAmount,
	startDate,
	endDate,
	status,
}) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const amountFormatter = useFormatAmount();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onChange = useCallback(async () => {
		setIsLoading(true);
		await dispatch(
			updateOpenOrder(
				status === ConvertStatusType.ACTIVE ? OpenOrderType.PAUSE : OpenOrderType.RESUME,
				id
			)
		)
			.then(() => null)
			.catch(() => null)
			.then(() => setIsLoading(false));
	}, [dispatch, id, status]);

	const openModal = useCallback(() => dispatch(toggleModal(<CancelOrder id={id} />)), [
		dispatch,
		id,
	]);

	return (
		<li className={styles.item}>
			<div>
				<span className={styles.assets}>
					<CurrencyIcon currencyType={payCurrencyCode} className={styles.icon} />
					<CurrencyIcon
						currencyType={receiveCurrencyCode}
						className={classNames(styles.icon, styles.margin)}
					/>
					{payCurrencyCode}
					<FontAwesomeIcon icon={faLongArrowRight} className={styles.arrow} />
					{receiveCurrencyCode}
					<div className={styles.frequency}>{formatMessage(convertMsg[frequency])}</div>
					<FontAwesomeIcon
						icon={faTimes}
						className={classNames(styles.close, styles.firstCol)}
						onClick={openModal}
					/>
				</span>
			</div>
			<div className={styles.grid}>
				<span>{formatMessage(convertMsg.interval)}</span>
				<span className={styles.interval}>
					<div>
						{formatMessage(convertMsg[`historyPeriodText${frequency}`])}
						<span>
							{formatMessage(convertMsg.openOrdersStart)}: {startDate}
						</span>
						<span>
							{formatMessage(convertMsg.openOrdersEnd)}:{' '}
							{endDate || formatMessage(convertMsg.untilCancel)}
						</span>
					</div>
				</span>
			</div>
			<div className={styles.grid}>
				<span>{formatMessage(convertMsg.payAmountHistory)}</span>
				<span className={styles.amount}>
					<div>{`${amountFormatter(
						formatPrecision(payAmount, payCurrencyCode)
					)} ${payCurrencyCode}`}</div>
				</span>
			</div>
			<div className={styles.grid}>
				<span>{formatMessage(convertMsg.status)}</span>
				<span className={styles.status}>
					<div>
						<Toggle
							name="status"
							id={id}
							checked={status === ConvertStatusType.ACTIVE}
							onChange={onChange}
							disabled={isLoading}
						/>
						<span className={styles.label}>
							{status === ConvertStatusType.ACTIVE
								? formatMessage(convertMsg.active)
								: formatMessage(convertMsg.paused)}
						</span>
					</div>
					<FontAwesomeIcon
						icon={faTimes}
						className={classNames(styles.close, styles.lastCol)}
						onClick={openModal}
					/>
				</span>
			</div>
		</li>
	);
};

export default OpenOrdersItem;
