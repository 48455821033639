import { FC } from 'react';
import { useSelector } from 'react-redux';
import styles from './Callbacks.module.scss';
import { RootState } from '../../../redux/Store';
import Sidebar from '../Shared/Sidebar/Sidebar';
import CallbacksList from '../Shared/CallbacksList/CallbacksList';

const Desktop: FC = () => {
	return (
		<div className={styles.layout}>
			<Sidebar />
			<div className={styles.main}>
				<CallbacksList />
			</div>
		</div>
	);
};

const Tablet: FC = () => {
	return (
		<div className={styles.main}>
			<CallbacksList />
		</div>
	);
};

const Callbacks: FC = () => {
	const { isTablet } = useSelector((state: RootState) => state.AppState);
	return isTablet ? <Tablet /> : <Desktop />;
};

export default Callbacks;
