import { defineMessages } from 'react-intl';

const messages = defineMessages({
	api: {
		id: 'settings.api.mainTitle',
		defaultMessage: 'API',
	},
	documents: {
		id: 'settings.documents',
		defaultMessage: 'Download documents',
	},
	referral: {
		id: 'settings.referral',
		defaultMessage: 'Referral',
	},
	metaSettingsTitle: {
		id: 'settings.metaSettingsTitle',
		defaultMessage: 'User Settings',
	},
	title: {
		id: 'settings.title',
		defaultMessage: 'Settings',
	},
	apiTitle: {
		id: 'settings.api.title',
		defaultMessage: 'SpectroCoin API',
	},
	apiDescription: {
		id: 'settings.api.description',
		defaultMessage:
			'SpectroCoin Account Application Programming Interface (API) offers a new way of managing your SpectroCoin account. On this page, you can create and customize API keys. The full API documentation is available <link>here</link>.',
	},
	apiCreateButton: {
		id: 'settings.api.createButton',
		defaultMessage: 'Create API',
	},
	apiCreateNewButton: {
		id: 'settings.api.createNewButton',
		defaultMessage: 'Create new API',
	},
	myApis: {
		id: 'settings.api.myApis',
		defaultMessage: 'My APIs',
	},
	myApisDescription: {
		id: 'settings.api.myApisDescription',
		defaultMessage:
			'If you do not find your API information in the table below, you might have been using the previous version of SpectroCoin API. You can check older API users <link>here</link>.',
	},
	enabled: {
		id: 'settings.api.enabled',
		defaultMessage: 'Enabled',
	},
	disabled: {
		id: 'settings.api.disabled',
		defaultMessage: 'Disabled',
	},
	enableDisable: {
		id: 'settings.api.enableDisable',
		defaultMessage: 'Enable/Disable',
	},
	apiNameAndDateCreated: {
		id: 'settings.api.nameAndDateCreated',
		defaultMessage: 'API name,{br}Date created',
	},
	clientId: {
		id: 'settings.api.clientId',
		defaultMessage: 'Client ID',
	},
	apiCreateTitle: {
		id: 'settings.api.create.title',
		defaultMessage: 'Create new API',
	},
	apiEditTitle: {
		id: 'settings.api.edit.title',
		defaultMessage: 'View and edit API',
	},
	apiEditButton: {
		id: 'settings.api.edit.button',
		defaultMessage: 'Save changes',
	},
	deleteApi: {
		id: 'settings.api.delete.title',
		defaultMessage: 'Delete API',
	},
	back: {
		id: 'base.back',
		defaultMessage: 'Go back',
	},
	apiName: {
		id: 'settings.api.name',
		defaultMessage: 'API name',
	},
	apiKey: {
		id: 'settings.api.key',
		defaultMessage: 'API key',
	},
	apiSecret: {
		id: 'settings.api.secret',
		defaultMessage: 'Secret',
	},
	apiNamePlaceholder: {
		id: 'settings.api.namePlaceholder',
		defaultMessage: 'Enter API name',
	},
	scopeGroups: {
		id: 'settings.api.scopeGroups',
		defaultMessage: 'Scope groups',
	},
	scopesGroupDescription: {
		id: 'settings.api.scopesGroupDescription',
		defaultMessage: 'Choose the API scope groups that you will require.',
	},
	userAccountReadScope: {
		id: 'settings.api.userAccountReadScope',
		defaultMessage: 'View account details',
	},
	userAccountReadScopeDescription: {
		id: 'settings.api.userAccountReadScopeDescription',
		defaultMessage:
			"Allows read access to the user's account information, including profile details and settings",
	},
	accountHistoryReadScope: {
		id: 'settings.api.accountHistoryReadScope',
		defaultMessage: 'View transaction history',
	},
	accountHistoryReadScopeDescription: {
		id: 'settings.api.accountHistoryReadScopeDescription',
		defaultMessage:
			"Grants permission to view the user's account history, including past transactions and activities",
	},
	exchangeReadScope: {
		id: 'settings.api.exchangeReadScope',
		defaultMessage: 'View currency exchanges',
	},
	exchangeReadScopeDescription: {
		id: 'settings.api.exchangeReadScopeDescription',
		defaultMessage: 'Grants the ability to view currency exchange related information',
	},
	exchangeCreateScope: {
		id: 'settings.api.exchangeCreateScope',
		defaultMessage: 'Create currency exchanges',
	},
	exchangeCreateScopeDescription: {
		id: 'settings.api.exchangeCreateScopeDescription',
		defaultMessage: `Allows the user to create or initiate new currency exchange orders`,
	},
	depositReadScope: {
		id: 'settings.api.depositReadScope',
		defaultMessage: 'View deposits',
	},
	depositReadScopeDescription: {
		id: 'settings.api.depositReadScopeDescription',
		defaultMessage:
			'Enables reading of deposit information, such as viewing past and pending deposit transactions',
	},
	depositCreateScope: {
		id: 'settings.api.depositCreateScope',
		defaultMessage: 'Deposit - create',
	},
	depositCreateScopeDescription: {
		id: 'settings.api.depositCreateScopeDescription',
		defaultMessage: 'Get generate new deposit address',
	},
	withdrawCryptoReadScope: {
		id: 'settings.api.withdrawCryptoReadScope',
		defaultMessage: 'View crypto withdrawals',
	},
	withdrawCryptoReadScopeDescription: {
		id: 'settings.api.withdrawCryptoReadScopeDescription',
		defaultMessage:
			'Permits viewing of cryptocurrency withdrawal transactions and related details',
	},
	withdrawCryptoCreateScope: {
		id: 'settings.api.withdrawCryptoCreateScope',
		defaultMessage: 'Create crypto withdrawals',
	},
	withdrawCryptoCreateScopeDescription: {
		id: 'settings.api.withdrawCryptoCreateScopeDescription',
		defaultMessage: 'Enables the creation of cryptocurrency withdrawal requests',
	},
	depositCryptoAddressRead: {
		id: 'settings.api.depositCryptoAddressRead',
		defaultMessage: 'View crypto deposit addresses',
	},
	depositCryptoAddressReadDescription: {
		id: 'settings.api.depositCryptoAddressReadDescription',
		defaultMessage:
			"Allows reading of cryptocurrency deposit addresses associated with the user's account",
	},
	depositCryptoAddressCreate: {
		id: 'settings.api.depositCryptoAddressCreate',
		defaultMessage: 'Generate new crypto deposit addresses',
	},
	depositCryptoAddressCreateDescription: {
		id: 'settings.api.depositCryptoAddressCreateDescription',
		defaultMessage: 'Permits the creation of new cryptocurrency deposit addresses',
	},
	withdrawBankRead: {
		id: 'settings.api.withdrawBankRead',
		defaultMessage: 'View bank withdrawals',
	},
	withdrawBankReadDescription: {
		id: 'settings.api.withdrawBankReadDescription',
		defaultMessage:
			'Grants permission to view bank withdrawal transactions and related information',
	},
	withdrawBankCreate: {
		id: 'settings.api.withdrawBankCreate',
		defaultMessage: 'Create bank withdrawals',
	},
	withdrawBankCreateDescription: {
		id: 'settings.api.withdrawBankCreateDescription',
		defaultMessage: 'Allows the setup or initiation of bank withdrawal requests',
	},
	withdrawWithExchangeRead: {
		id: 'settings.api.withdrawWithExchangeRead',
		defaultMessage: 'View withdraw-with-exchange transactions',
	},
	withdrawWithExchangeReadDescription: {
		id: 'settings.api.withdrawWithExchangeReadDescription',
		defaultMessage:
			'Grants permission to view withdrawal-with-exchange transactions and related information',
	},
	withdrawWithExchangeCreate: {
		id: 'settings.api.withdrawWithExchangeCreate',
		defaultMessage: 'Create withdraw-with-exchange transactions',
	},
	withdrawWithExchangeCreateDescription: {
		id: 'settings.api.withdrawWithExchangeCreateDescription',
		defaultMessage: 'Allows the creation of withdraw-with-exchange transactions',
	},
	merchantsPreordersRead: {
		id: 'settings.api.merchantsPreordersRead',
		defaultMessage: 'View merchant preorders',
	},
	merchantsPreordersReadDescription: {
		id: 'settings.api.merchantsPreordersReadDescription',
		defaultMessage: "Permits viewing of merchant projects' preorders and related transactions",
	},
	merchantsPreordersCreate: {
		id: 'settings.api.merchantsPreordersCreate',
		defaultMessage: 'Create merchant preorders',
	},
	merchantsPreordersCreateDescription: {
		id: 'settings.api.merchantsPreordersCreateDescription',
		defaultMessage:
			'Allows the creation or initiation of preorder transactions for merchant projects',
	},
	merchantsDepositTxRead: {
		id: 'settings.api.merchantsDepositTxRead',
		defaultMessage: 'View merchant order deposit transactions',
	},
	merchantsDepositTxReadDescription: {
		id: 'settings.api.merchantsDepositTxReadDescription',
		defaultMessage: 'Allows the creation of new orders for merchant projects',
	},
	merchantsOrderRead: {
		id: 'settings.api.merchantsOrderRead',
		defaultMessage: 'View merchant orders',
	},
	merchantsOrderReadDescription: {
		id: 'settings.api.merchantsOrderReadDescription',
		defaultMessage: 'Enables viewing of orders placed for merchant projects',
	},
	merchantsOrderCreate: {
		id: 'settings.api.merchantsOrderCreate',
		defaultMessage: 'Create merchant orders',
	},
	merchantsOrderCreateDescription: {
		id: 'settings.api.merchantsOrderCreateDescription',
		defaultMessage: 'Allows the creation of new orders for merchant projects',
	},
	merchantsOrderCancel: {
		id: 'settings.api.merchantsOrderCancel',
		defaultMessage: 'Cancel merchant orders',
	},
	merchantsOrderCancelDescription: {
		id: 'settings.api.merchantsOrderCancelDescription',
		defaultMessage: 'Grants permission to cancel existing orders',
	},
	accountTransactionRead: {
		id: 'settings.api.accountTransactionRead',
		defaultMessage: 'View account transactions',
	},
	accountTransactionReadDescription: {
		id: 'settings.api.accountTransactionReadDescription',
		defaultMessage: 'Allows the viewing of account transactions',
	},
	paymentMethodRead: {
		id: 'settings.api.paymentMethodRead',
		defaultMessage: 'View payment methods',
	},
	paymentMethodReadDescription: {
		id: 'settings.api.paymentMethodReadDescription',
		defaultMessage: 'Grants permission to view payment methods associated with the user',
	},
	merchantsProjectsRead: {
		id: 'settings.api.merchantsProjectsRead',
		defaultMessage: 'View merchant projects',
	},
	merchantsProjectsReadDescription: {
		id: 'settings.api.merchantsProjectsReadDescription',
		defaultMessage: 'Allows the viewing of merchant projects',
	},
	merchantsProjectsCreate: {
		id: 'settings.api.merchantsProjectsCreate',
		defaultMessage: 'Create merchant projects',
	},
	merchantsProjectsCreateDescription: {
		id: 'settings.api.merchantsProjectsCreateDescription',
		defaultMessage: 'Allows the creation of new merchant projects',
	},
	merchantsProjectsWrite: {
		id: 'settings.api.merchantsProjectsWrite',
		defaultMessage: 'Write to merchant projects',
	},
	merchantsProjectsWriteDescription: {
		id: 'settings.api.merchantsProjectsWriteDescription',
		defaultMessage: 'Allows writing to merchant projects',
	},
	merchantsOrdersPay: {
		id: 'settings.api.merchantsOrdersPay',
		defaultMessage: 'Pay merchant orders',
	},
	merchantsOrdersPayDescription: {
		id: 'settings.api.merchantsOrdersPayDescription',
		defaultMessage: 'Allows payment for merchant orders',
	},
	merchantsOrdersCallback: {
		id: 'settings.api.merchantsOrdersCallback',
		defaultMessage: 'Merchant orders callback',
	},
	merchantsOrdersCallbackDescription: {
		id: 'settings.api.merchantsOrdersCallbackDescription',
		defaultMessage: 'Allows the callback for merchant orders',
	},
	merchantsCallbacksRead: {
		id: 'settings.api.merchantsCallbacksRead',
		defaultMessage: 'View merchant callbacks',
	},
	merchantsCallbacksReadDescription: {
		id: 'settings.api.merchantsCallbacksReadDescription',
		defaultMessage: 'Allows the viewing of merchant callbacks',
	},
	merchantsTransactionsRead: {
		id: 'settings.api.merchantsTransactionsRead',
		defaultMessage: 'View merchant transactions',
	},
	merchantsTransactionsReadDescription: {
		id: 'settings.api.merchantsTransactionsReadDescription',
		defaultMessage: 'Allows the viewing of merchant transactions',
	},
	depositFiatCreate: {
		id: 'settings.api.depositFiatCreate',
		defaultMessage: 'Create fiat deposits',
	},
	depositFiatCreateDescription: {
		id: 'settings.api.depositFiatCreateDescription',
		defaultMessage: 'Allows the creation of new fiat deposit transactions',
	},
	depositFiatRead: {
		id: 'settings.api.depositFiatRead',
		defaultMessage: 'View fiat deposits',
	},
	depositFiatReadDescription: {
		id: 'settings.api.depositFiatReadDescription',
		defaultMessage: 'Allows the viewing of fiat deposit transactions',
	},
	depositCryptoRead: {
		id: 'settings.api.depositCryptoRead',
		defaultMessage: 'View crypto deposits',
	},
	depositCryptoReadDescription: {
		id: 'settings.api.depositCryptoReadDescription',
		defaultMessage: 'Allows the viewing of cryptocurrency deposit transactions',
	},
	purchaseGiftCardRead: {
		id: 'settings.api.purchaseGiftCardRead',
		defaultMessage: 'View gift card purchases',
	},
	purchaseGiftCardReadDescription: {
		id: 'settings.api.purchaseGiftCardReadDescription',
		defaultMessage: 'Allows the viewing of gift card purchases',
	},
	purchaseGiftCardPay: {
		id: 'settings.api.purchaseGiftCardPay',
		defaultMessage: 'Pay for gift card purchases',
	},
	purchaseGiftCardPayDescription: {
		id: 'settings.api.purchaseGiftCardPayDescription',
		defaultMessage: 'Allows payment for gift card purchases',
	},
	withdrawVoucherRead: {
		id: 'settings.api.withdrawVoucherRead',
		defaultMessage: 'View voucher withdrawals',
	},
	withdrawVoucherReadDescription: {
		id: 'settings.api.withdrawVoucherReadDescription',
		defaultMessage: 'Allows the viewing of voucher withdrawal transactions',
	},
	withdrawVoucherCreate: {
		id: 'settings.api.withdrawVoucherCreate',
		defaultMessage: 'Create voucher withdrawals',
	},
	withdrawVoucherCreateDescription: {
		id: 'settings.api.withdrawVoucherCreateDescription',
		defaultMessage: 'Allows the creation of new voucher withdrawal transactions',
	},
	withdrawEWalletRead: {
		id: 'settings.api.withdrawEWalletRead',
		defaultMessage: 'View e-wallet withdrawals',
	},
	withdrawEWalletReadDescription: {
		id: 'settings.api.withdrawEWalletReadDescription',
		defaultMessage: 'Allows the viewing of e-wallet withdrawal transactions',
	},
	withdrawEWalletCreate: {
		id: 'settings.api.withdrawEWalletCreate',
		defaultMessage: 'Create e-wallet withdrawals',
	},
	withdrawEWalletCreateDescription: {
		id: 'settings.api.withdrawEWalletCreateDescription',
		defaultMessage: 'Allows the creation of new e-wallet withdrawal transactions',
	},
	whitelistedIps: {
		id: 'settings.api.whitelistedIps',
		defaultMessage: 'Whitelisted IP addresses',
	},
	whitelistedIpsDescription: {
		id: 'settings.api.whitelistedIpsDescription',
		defaultMessage:
			'Provide the full list of IP addresses that you would like to whitelist. API services can only be accessed from whitelisted IPs.',
	},
	enterIpAddressPlaceholder: {
		id: 'settings.api.enterIpAddressPlaceholder',
		defaultMessage: 'Enter IP address',
	},
	additionalIpAddresses: {
		id: 'settings.api.additionalIpAddresses',
		defaultMessage: 'Additional IP addresses',
	},
	acceptTerms: {
		id: 'settings.api.acceptTerms',
		defaultMessage: 'I have read and accept <link>API usage agreement.</link>',
	},
	acceptTermsRequired: {
		id: 'settings.api.acceptTermsRequired',
		defaultMessage: 'You must read and accept API usage agreement before continuing.',
	},
	ipAddressRequired: {
		id: 'settings.api.ipAddressRequired',
		defaultMessage: 'IP address is required',
	},
	invalidIpAddress: {
		id: 'settings.api.invalidIpAddress',
		defaultMessage: 'Invalid IP address',
	},
	ipAddressAlreadyAdded: {
		id: 'settings.api.ipAddressAlreadyAdded',
		defaultMessage: 'IP address already added',
	},
	apiNameRequired: {
		id: 'settings.api.apiNameRequired',
		defaultMessage: 'API name is required',
	},
	apiSecretWarning: {
		id: 'settings.api.apiSecretWarning',
		defaultMessage:
			'Make sure to save your Secret before closing this window – it will not be shown anywhere else. Store your Secret securely and do not disclose it to others.',
	},
	apiCreateModalTitle: {
		id: 'settings.api.create.modal.title',
		defaultMessage: 'Your API details',
	},
	deleteApiDescription: {
		id: 'settings.api.delete.description',
		defaultMessage: 'Are you sure you want to delete this API?',
	},
	deleteApiButton: {
		id: 'settings.api.delete.button',
		defaultMessage: 'Yes, remove',
	},
	cancel: {
		id: 'load.cancel',
		defaultMessage: 'Cancel',
	},
	apiKeyAlreadyExists: {
		id: 'settings.api.apiKeyAlreadyExists',
		defaultMessage: 'API key already exists',
	},
});

export default messages;
