/* eslint-disable consistent-return */
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MerchantsActions from '../../redux/MerchantsState/MerchantsActions';
import styles from './NotificationsContainer.module.scss';
import Seo from '../../components/Seo/Seo';
import useEffectOnce from '../../hooks/useEffectOnce';
import NotificationsMessages from '../../redux/NotificationsState/NotificationsMessages';
import NotificationsList from '../../pages/Notifications/NotificationsList/NotificationsList';
import useNotificationRoutes, { NotificationsRoutes } from '../../hooks/useNotificationRoutes';

const PaymentsContainer = () => {
	const dispatch = useDispatch();
	const { getPath, getUrl } = useNotificationRoutes();

	useEffectOnce(() => {
		void dispatch(MerchantsActions.loadData());
	});

	return (
		<div className={styles.container}>
			<Seo title={NotificationsMessages.metaTitle} />
			<div className={styles.flexGrid}>
				<div className={styles.content}>
					<Switch>
						<Route
							exact
							path={getPath(NotificationsRoutes.All)}
							component={NotificationsList}
						/>
						<Redirect to={getUrl(NotificationsRoutes.All)} />
					</Switch>
				</div>
			</div>
		</div>
	);
};

export default PaymentsContainer;
