import { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { values } from '../helpers/objectHelper/objectHelper';

enum RouteTokens {
	Language = 'lng',
}

export enum NotificationsRoutes {
	Root,
	All,
}

const NotificationsRoutePaths = {
	get [NotificationsRoutes.Root]() {
		return `/:${RouteTokens.Language}?/notifications`;
	},

	get [NotificationsRoutes.All]() {
		return `${NotificationsRoutePaths[NotificationsRoutes.Root]}/all`;
	},
} as const;

type RouteParams = Partial<{ [key in RouteTokens]: string }>;

const useNotificationRoutes = () => {
	const { params } = useRouteMatch<RouteParams>();

	const getPath = useCallback((route: NotificationsRoutes) => {
		return NotificationsRoutePaths[route];
	}, []);

	const getParams = useCallback(() => {
		return params;
	}, [params]);

	const getUrl = useCallback(
		(route: NotificationsRoutes, routeParams: RouteParams = {}) => {
			const mergedParams = { ...getParams(), ...routeParams };
			const path = getPath(route);
			const tokens = values(RouteTokens);
			return tokens
				.reduce((acc, token) => {
					return acc.replace(new RegExp(`:${token}\\?*`), mergedParams[token] || '');
				}, path)
				.replace(/\/\//g, '/');
		},
		[getPath, getParams]
	);

	return {
		getUrl,
		getPath,
		getParams,
	} as const;
};

export default useNotificationRoutes;
