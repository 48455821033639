import { defineMessages, useIntl } from 'react-intl';
import Button, { ButtonType } from '../../../../components/Button/Button';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import limitImgPath from './images/limit.svg';
import styles from './CardsLimitReached.module.scss';
import baseMsg from '../../../../messages/base.messages';

const messages = defineMessages({
	title: {
		id: 'cardsLimitReached.title',
		defaultMessage: 'Cards limit reached',
	},
	text: {
		id: 'cardsLimitReached.text',
		defaultMessage: 'Too many cards attached to this account',
	},
});

const CardsLimitReached = () => {
	const { locale } = useIntl();

	return (
		<WhiteContainer>
			<div className={styles.wrapper}>
				<InfoHead title={messages.title} img={limitImgPath} text={messages.text} />
				<Button
					type={ButtonType.LINK}
					link={`/${locale}/cards`}
					text={baseMsg.close}
					className={styles.button}
				/>
			</div>
		</WhiteContainer>
	);
};

export default CardsLimitReached;
