import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { FC, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import Button, { ButtonStyle } from '../../../../components/Button/Button';
import baseMsg from '../../../../messages/base.messages';
import { getCryptoPayoutCancelURL } from '../../../../redux/endpoints';
import MerchantsActions from '../../../../redux/MerchantsState/MerchantsActions';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import styles from './CancelPayout.module.scss';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	yesCancel: {
		id: 'payouts.yesCancel',
		defaultMessage: 'Yes, cancel',
	},
	title: {
		id: 'payouts.cancelPayoutTitle',
		defaultMessage: 'Cancel payout?',
	},
	post: {
		id: 'payouts.cancelPayoutPost',
		defaultMessage: 'The payout will be permanently canceled.',
	},
});

const CancelPayout: FC<{ id: string }> = ({ id }) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const [isLoading, setIsLoading] = useState(false);

	const cancel = () => {
		setIsLoading(true);
		void axiosInstance
			.post(getCryptoPayoutCancelURL(id || ''), {})
			.then(({ data }) => {
				dispatch(MerchantsActions.payouts.data.updatePayout(data));
			})
			.catch((e) => console.log(e))
			.then(() => {
				dispatch(toggleModal());
				setIsLoading(false);
			});
	};
	return (
		<div className={styles.container}>
			<div className={styles.close}>
				<FontAwesomeIcon icon={faPlus} />
			</div>
			<h2 className={styles.title}>{formatMessage(messages.title)}</h2>
			<p className={styles.post}>{formatMessage(messages.post)}</p>
			<Button
				className={styles.button}
				text={messages.yesCancel}
				onClick={cancel}
				isLoading={isLoading}
			/>
			<Button
				className={styles.button}
				text={baseMsg.back}
				buttonStyle={ButtonStyle.BORDERLESS}
				onClick={() => dispatch(toggleModal())}
			/>
		</div>
	);
};

export default CancelPayout;
