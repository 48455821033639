import { MessageDescriptor, useIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import backArrowIconPath from '../../images/arrow_down.svg';
import styles from './BackButton.module.scss';

const messages = defineMessages({
	back: {
		id: 'back.back',
		defaultMessage: 'Back',
	},
});

interface BackButtonProps {
	link?: string;
	text?: MessageDescriptor;
	className?: string;
	onClick?: (() => void) | null;
}

const BackButton = ({ link, text, className, onClick = null, ...props }: BackButtonProps) => {
	const { formatMessage } = useIntl();

	const btnClick = () => {
		if (typeof onClick === 'function') return onClick();
		return null;
	};

	return link ? (
		<Link
			to={link}
			onClick={btnClick}
			className={classNames(styles.backButton, className)}
			{...props}
		>
			<img src={backArrowIconPath} />
			{text ? formatMessage(text) : formatMessage(messages.back)}
		</Link>
	) : (
		<a onClick={btnClick} className={classNames(styles.backButton, className)} {...props}>
			<img src={backArrowIconPath} />
			{text ? formatMessage(text) : formatMessage(messages.back)}
		</a>
	);
};

export default BackButton;
