/* eslint-disable @typescript-eslint/no-var-requires */
import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import lottie from 'lottie-web';
import styles from './LoadingScreen.module.scss';
import useEffectOnce from '../../hooks/useEffectOnce';

const animationData = require('./spectrocoin_loader.json');

interface LoadingScreenProps {
	className?: string;
}

const LoadingScreen = ({ className }: LoadingScreenProps) => {
	const element = useRef<HTMLDivElement>(null);

	useEffectOnce(() => {
		lottie.loadAnimation({
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData,
			container: element.current!,
		});
	});

	return <div ref={element} className={classNames(styles.loader, className)} />;
};

export default LoadingScreen;
