import { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import SelectOption from '../../../../interfaces/SelectOption';
import { RootState } from '../../../../redux/Store';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import styles from './ConvertSelectModal.module.scss';
import { DeviceNameType } from '../../../../redux/AppState/AppTypes';
import baseMsg from '../../../../messages/base.messages';

const nonAlphaNumericRegex = /[\W_]+/g;

interface ConvertSelectModalProps {
	options: SelectOption[];
	selected: string;
	onChangeSelect: (val: string) => void;
	setIsSelectOpen: (isOpen: boolean) => void;
}

const ConvertSelectModal = memo(
	({ options, selected, onChangeSelect, setIsSelectOpen }: ConvertSelectModalProps) => {
		const dispatch = useDispatch();
		const { formatMessage } = useIntl();
		const [nameFilter, setNameFilter] = useState<string>('');
		const { deviceOS } = useSelector((state: RootState) => state.AppState);

		const onClick = (val: string) => {
			onChangeSelect(val);
			setIsSelectOpen(false);
			return dispatch<any>(toggleModal());
		};

		const closeModal = () => {
			setIsSelectOpen(false);
			return dispatch<any>(toggleModal());
		};

		return (
			<div className={styles.modal}>
				<div className={styles.closeIcon} onClick={() => closeModal()} />
				<div className={styles.mobileInputContainer}>
					{deviceOS !== DeviceNameType.ANDROID && (
						<>
							<div className={styles.inputContainer}>
								<i className="fa fa-search" aria-hidden="true" />
							</div>
							<input
								className={styles.searchInput}
								placeholder={formatMessage(baseMsg.search)}
								onChange={(event: any) => setNameFilter(event.target.value)}
								type="text"
							/>
						</>
					)}
				</div>
				<ul className={styles.mobileSelect}>
					{options
						?.filter((o: SelectOption) => {
							if (nameFilter) {
								return o.value
									.replace(nonAlphaNumericRegex, '')
									.toLowerCase()
									.includes(
										nameFilter.replace(nonAlphaNumericRegex, '').toLowerCase()
									);
							}
							return true;
						})
						.map((o: SelectOption) => {
							return (
								<li
									key={o.value}
									className={classNames({
										[styles.selected]: selected === o.value,
									})}
									onClick={() => onClick(o.value)}
								>
									{o.label}
								</li>
							);
						})}
				</ul>
			</div>
		);
	}
);

export default ConvertSelectModal;
