import { AxiosRequestConfig } from 'axios';
import { AppDispatch } from '../Store';
import { Reset2FaDataAction, Set2FaDataAction, TwoFaActionType, TwoFaCode } from './TwoFaTypes';

interface SetTwoFaDataProps {
	requestData: AxiosRequestConfig;
	authType: any;
	txId: string;
	twoFaCode: string;
	expDate: string;
	errorCode: TwoFaCode;
	email: string | null;
}

export const setTwoFaData = ({
	requestData,
	authType,
	txId,
	twoFaCode,
	expDate,
	errorCode,
	email,
}: SetTwoFaDataProps) => (dispatch: AppDispatch) => {
	return dispatch<Set2FaDataAction>({
		type: TwoFaActionType.SET_2FA_DATA,
		data: {
			authType,
			txId,
			twoFaCode,
			requestData,
			errorCode,
			expDate,
			email,
		},
	});
};

export const setTwoFaErrorCode = (code: TwoFaCode) => ({
	type: TwoFaActionType.SET_2FA_ERROR_CODE,
	code,
});

export const resetTwoFaData = () => (dispatch: AppDispatch) => {
	return dispatch<Reset2FaDataAction>({
		type: TwoFaActionType.RESET_2FA_DATA,
	});
};
