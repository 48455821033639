import { currencyUtils } from '@spectrocoin/sc-currencies';
import { format } from 'date-fns';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { formatPrecision } from '../../../../../../helpers/currencyHelper/currencyHelper';
import MerchantsMessages from '../../../../../../redux/MerchantsState/MerchantsMessages';
import TestIds from '../../../../../../test/TestIds';
import MerchantProjectChartContext from '../Context/MerchantProjectChartContext';
import styles from './ChartDetails.module.scss';
import useFormatAmount from '../../../../../../hooks/useFormatAmount';

const ChartDetails = () => {
	const {
		range: { from, to },
		chartData: { data },
	} = useContext(MerchantProjectChartContext);
	const { formatMessage } = useIntl();
	const amountFormatter = useFormatAmount();

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				{formatMessage(MerchantsMessages.totalValueOfOrders)}
			</div>
			<div className={styles.amount} data-cy={TestIds.ProjectviewChartAmount}>
				{data
					? `${amountFormatter(
							formatPrecision(
								data.totalAmount,
								data.currencyCode,
								true,
								false,
								currencyUtils.getConfigOrDefault(data.currencyCode).isVirtual
									? 8
									: 2
							)
					  )} ${data.currencyCode}`
					: '-'}
			</div>
			<div className={styles.date}>
				{formatMessage(MerchantsMessages.dateRange, {
					From: from ? format(from, 'yyyy-MM-dd') : '-',
					To: to ? format(to, 'yyyy-MM-dd') : '-',
				})}
			</div>
		</div>
	);
};

export default ChartDetails;
