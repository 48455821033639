import { ChangeEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { CurrencyIcon, CurrencyEnum } from '@spectrocoin/sc-currencies';
import styles from './EwalletDepositDetails.module.scss';
import PaymentMethod, { ProviderType } from '../../../../redux/DepositState/DepositTypes';
import SelectOption from '../../../../interfaces/SelectOption';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Loader from '../../../../components/Loader/Loader';
import { Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import Select from '../../../../components/Select/Select';
import useDebounce from '../../../../hooks/useDebounce';
import { RootState } from '../../../../redux/Store';
import {
	advCashDepositURL,
	depositCalculateReceiveAmountURL,
	depositsURL,
	getPaymentMethodsDetailsURL,
	netellerDepositURL,
	payeerDepositURL,
	perfectMoneyDepositURL,
	skrillDepositURL,
} from '../../../../redux/endpoints';
import CurrencyInput from '../../../../components/CurrencyInput/CurrencyInput';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import { entries } from '../../../../helpers/objectHelper/objectHelper';
import { formatPrecision, toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import { isNumeric } from '../../../../helpers/globalFunctionHelpers/globalFunctionHelpers';
import depositMessages from '../../../../redux/DepositState/DepositMessages';
import inputStyles from '../../../../components/Input/Input.module.scss';
import errorMessages, {
	ErrorResponse,
} from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { toPlainAmount } from '../../../../helpers/currencyAmountHelper/currencyAmountHelper';
import baseMsg from '../../../../messages/base.messages';
import inputErrors from '../../../../messages/inputErrors.messages';
import Seo from '../../../../components/Seo/Seo';
import TestIds from '../../../../test/TestIds';
import useFormatAmount from '../../../../hooks/useFormatAmount';
import TransfersFormLayout from '../../../../layout/TransfersFormLayout/TransfersFormLayout';
import { HowToType } from '../../../../layout/TransfersFormLayout/HowTo/HowTo';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	unexpectedError: {
		id: 'scvalidationexception.validate.unexpected_error.key',
		defaultMessage: 'Unexpected error occurred.',
	},
	depositWallet: {
		id: 'base.depositWallet',
		defaultMessage: 'Deposit wallet',
	},
	depositAmount: {
		id: 'base.deposit_amount',
		defaultMessage: 'Deposit amount',
	},
	country: {
		id: 'base.country',
		defaultMessage: 'Country',
	},
	next: {
		id: 'base.next',
		defaultMessage: 'Next',
	},
	depositWith: {
		id: 'walletdeposit.withTitle',
		defaultMessage: 'Deposit with {provider}',
	},
	depositError: {
		id: 'base.deposit_error',
		defaultMessage: 'Deposit failed',
	},
});

interface FormState {
	depositAmount?: string;
	depositCurrencyCode?: string;
	receiveAmount: string;
	receiveCurrencyCode?: string;
	wallet?: string;
}

interface FormErrorState {
	depositAmount?: MessageDescriptor | null;
	receiveAmount?: MessageDescriptor | null;
}

interface Params {
	paymentMethodId: string;
}

const mapProviderTypeToNumber = {
	[ProviderType.LOCAL]: 3,
	[ProviderType.EGOPAY]: 4,
	[ProviderType.PERFECT_MONEY]: 6,
	[ProviderType.MAXIMA]: 8,
	[ProviderType.PERLAS]: 9,
	[ProviderType.PAYPOST]: 10,
	[ProviderType.CASHU]: 12,
	[ProviderType.PAYEER]: 13,
	[ProviderType.LT_SPAUDA]: 15,
	[ProviderType.FOXBOX]: 17,
	[ProviderType.SEPA]: 18,
	[ProviderType.WIRE]: 19,
	[ProviderType.LT_PASTAS]: 20,
	[ProviderType.QIWI]: 21,
	[ProviderType.SKRILL]: 26,
	[ProviderType.NETELLER]: 28,
	[ProviderType.TRUST_PAY]: 30,
	[ProviderType.SPECTROCOIN_VOUCHER]: 31,
	[ProviderType.SPECTROCOIN_CRYPTO]: 32,
	[ProviderType.PAYBOX]: 33,
	[ProviderType.ADV_CASH]: 35,
};

interface DepositCalculateResponse {
	payAmount: string;
	payCurrencyCode: string;
	receiveAmount: number;
	receiveCurrencyCode: string;
}

export interface ProcessPaymentParams {
	depositCurrency: string;
	paymentMethodId: string;
	lng: string;
	providerTypeNumber: number;
	receiveAccountId: string;
	receiveAmount: string;
}

const postForm = (path: string, params: any) => {
	/*
	 * Generates a form with hidden fields from params and sends
	 * POST request to specific path
	 */
	const form = document.createElement('form');
	form.setAttribute('method', 'POST');
	form.setAttribute('action', path);
	if (params != null) {
		entries(params).forEach(([key, value]) => {
			const hiddenField = document.createElement('input');
			hiddenField.setAttribute('type', 'hidden');
			hiddenField.setAttribute('name', key as string);
			hiddenField.setAttribute('value', value as string);

			form.appendChild(hiddenField);
		});
	}

	document.body.appendChild(form);
	form.submit();
};

const EWalletDepositDetails = () => {
	const { formatMessage, locale } = useIntl();
	const { paymentMethodId } = useParams<Params>();
	const amountFormatter = useFormatAmount();
	const [isLoading, setIsLoading] = useState(true);
	const [paymentMethodDetails, setPaymentMethodDetails] = useState<PaymentMethod>();
	const [walletOptions, setWalletOptions] = useState<SelectOption[]>([]);
	const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
	const [formState, setFormState] = useState<FormState>({
		depositAmount: '',
		depositCurrencyCode: '',
		receiveAmount: '0.00',
		receiveCurrencyCode: '',
		wallet: '',
	});
	const [formErrorState, setFormErrorState] = useState<FormErrorState>({
		depositAmount: null,
		receiveAmount: null,
	});
	const { wallets } = useSelector((state: RootState) => state.AccountsState);

	const [errorMessage, setErrorMessage] = useState<string | null>();
	const {
		depositAmount,
		depositCurrencyCode,
		receiveAmount,
		receiveCurrencyCode,
		wallet,
	} = formState;

	const debouncedDepositAmount = useDebounce(depositAmount, 1000);

	const handleDepositFailure = ({ errorCode }: ErrorResponse) => {
		setIsButtonDisabled(false);
		setErrorMessage(formatMessage(errorMessages[errorCode] || messages.unexpectedError));
	};

	const skrillDeposit = (endpointURL: string, path: string, params: ProcessPaymentParams) => {
		axiosInstance
			.post(endpointURL, params)
			.then(({ data }) => {
				postForm(path, { sid: data.sessionId });
			})
			.catch((e) => handleDepositFailure(e.response.data));
	};

	const processPayment = () => {
		const params: ProcessPaymentParams = {
			depositCurrency: depositCurrencyCode!.toUpperCase(),
			paymentMethodId,
			lng: locale,
			providerTypeNumber: mapProviderTypeToNumber[paymentMethodDetails!.providerType],
			receiveAccountId: wallet!,
			receiveAmount: receiveAmount!,
		};

		switch (paymentMethodDetails?.providerType) {
			case ProviderType.ADV_CASH: {
				axiosInstance
					.post(advCashDepositURL(), params)
					.then(({ data }) => {
						postForm('https://wallet.advcash.com/sci/', {
							ac_account_email: data.accountEmail,
							ac_amount: data.amount,
							ac_comments: data.comments,
							ac_currency: data.currency,
							ac_fail_url: data.failUrl,
							ac_fail_url_method: data.failUrlMethod,
							ac_order_id: data.orderId,
							ac_ps: data.ps,
							ac_sci_name: data.sciName,
							ac_sign: data.sign,
							ac_success_url: data.successUrl,
							ac_success_url_method: data.successUrlMethod,
						});
					})
					.catch((e) => handleDepositFailure(e.response.data));
				break;
			}
			case ProviderType.PERFECT_MONEY: {
				axiosInstance
					.post(perfectMoneyDepositURL(), params)
					.then(({ data }) => {
						postForm('https://perfectmoney.is/api/step1.asp', {
							NOPAYMENT_URL: data.noPaymentUrl,
							NOPAYMENT_URL_METHOD: data.noPaymentUrlMethod,
							PAYEE_ACCOUNT: data.payeeAccount,
							PAYEE_NAME: data.payeeName,
							PAYMENT_AMOUNT: data.paymentAmount,
							PAYMENT_ID: data.paymentId,
							PAYMENT_UNITS: data.paymentUnits,
							PAYMENT_URL: data.paymentUrl,
							PAYMENT_URL_METHOD: data.paymentUrlMethod,
							STATUS_URL: data.statusUrl,
							SUGGESTED_MEMO: data.suggestedMemo,
							SUGGESTED_MEMO_NOCHANGE: data.suggestedMemoNoChange,
						});
					})
					.catch((e) => handleDepositFailure(e.response.data));
				break;
			}
			case ProviderType.PAYEER: {
				axiosInstance
					.post(payeerDepositURL(), params)
					.then(({ data }) => {
						window.location.href = `https://payeer.com/merchant/?${queryString.stringify(
							{
								m_shop: data.shop,
								m_orderid: data.orderId,
								m_amount: data.amount,
								m_curr: data.curr,
								m_desc: data.desc,
								m_sign: data.sign,
							}
						)}`;
					})
					.catch((e) => handleDepositFailure(e.response.data));
				break;
			}
			case ProviderType.SKRILL: {
				skrillDeposit(skrillDepositURL(), 'https://pay.skrill.com', params);
				break;
			}
			case ProviderType.NETELLER: {
				skrillDeposit(netellerDepositURL(), 'https://pay.skrill.com', params);
				break;
			}
			default: {
				axiosInstance
					.post(depositsURL(), params)
					.then(({ data: location }: any) => {
						window.location.href = location;
					})
					.catch((e) => handleDepositFailure(e.response.data));
			}
		}
	};

	const handleSelectChange = ({ value }: SelectOption) => {
		const currencyCode = wallets?.find((account: Wallet) => account.id === value)?.currencyCode;

		setFormState((prevState: FormState) => ({
			...prevState,
			wallet: value,
			depositCurrencyCode: currencyCode,
			receiveCurrencyCode: currencyCode,
		}));
	};

	const handleInputChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
		setFormErrorState((prevState: FormErrorState) => ({
			...prevState,
			[name]: null,
		}));
		setFormState((prevState: FormState) => ({
			...prevState,
			depositAmount: value,
		}));
	};

	const validateForm = () => {
		if (!depositAmount || !receiveAmount) return true;
		let valid = true;
		let errorList = {};
		Object.keys(formState).forEach((item: any) => {
			if (!formState[item]) {
				errorList = {
					...errorList,
					[item]: inputErrors.cannotBeEmpty,
				};
				valid = false;
			}
		});
		if (!isNumeric(depositAmount)) {
			errorList = {
				...errorList,
				depositAmount: formatMessage(depositMessages.errorDepositAmountIsNumber),
			};
			valid = false;
		} else if (toDecimal(depositAmount, true).lessThanOrEqualTo(toDecimal(0))) {
			errorList = {
				...errorList,
				depositAmount: formatMessage(depositMessages.errorDepositAmountGreaterThanZero),
			};
			valid = false;
		} else if (minAmount && toDecimal(receiveAmount).lessThan(toDecimal(minAmount))) {
			errorList = {
				...errorList,
				receiveAmount: formatMessage(depositMessages.errorDepositAmountBelowMin, {
					minAmount: toDecimal(minAmount).toFixed(),
				}),
			};
			valid = false;
		} else if (maxAmount && toDecimal(receiveAmount).greaterThan(toDecimal(maxAmount))) {
			errorList = {
				...errorList,
				receiveAmount: formatMessage(depositMessages.errorDepositAmountAboveMax, {
					maxAmount: toDecimal(maxAmount).toFixed(),
				}),
			};
			valid = false;
		}

		setIsButtonDisabled(valid);
		setFormErrorState({
			...formErrorState,
			...errorList,
		});
		return valid;
	};

	const handleButtonClick = (e: any) => {
		e.preventDefault();
		if (validateForm()) processPayment();
	};

	useEffect(() => {
		setIsLoading(true);
		void axiosInstance
			.get<PaymentMethod>(getPaymentMethodsDetailsURL(paymentMethodId))
			.then(({ data: paymentMethod }) => {
				if (!wallets || !paymentMethod) return;

				const options = wallets
					.filter((account: Wallet) =>
						paymentMethod.paymentMethodCurrencies.includes(account.currencyCode)
					)
					.reduce((accumulator: SelectOption[], { currencyCode, id, label }: Wallet) => {
						const labelName = label ? `${label} (${currencyCode})` : `${currencyCode}`;

						accumulator.push({
							label: (
								<div className={styles.option}>
									<CurrencyIcon
										currencyType={currencyCode}
										className={styles.currencyIcon}
									/>
									<label>{labelName}</label>
								</div>
							),
							value: id,
						});
						return accumulator;
					}, []);

				const selectedWalletCurrencyCode = wallets.find(
					(account: Wallet) => account.id === options[0].value
				)!.currencyCode;

				setWalletOptions(options);
				setPaymentMethodDetails(paymentMethod);
				setFormState((prevState: FormState) => ({
					...prevState,
					receiveCurrencyCode: selectedWalletCurrencyCode,
					depositCurrencyCode: selectedWalletCurrencyCode,
					wallet: options[0].value!,
				}));
				setIsLoading(false);
			});
	}, [formatMessage, paymentMethodId, wallets]);

	useEffect(() => {
		if (!isLoading && debouncedDepositAmount && isNumeric(debouncedDepositAmount)) {
			void axiosInstance
				.post(depositCalculateReceiveAmountURL(), {
					payAmount: toPlainAmount(debouncedDepositAmount, true),
					payCurrencyCode: depositCurrencyCode,
					paymentMethodId,
					receiveCurrencyCode,
				})
				.then(({ data }: AxiosResponse<DepositCalculateResponse>) => {
					setFormState((prevState: FormState) => ({
						...prevState,
						receiveAmount: data.receiveAmount.toString(),
						receiveCurrencyCode: data.receiveCurrencyCode,
					}));
				});
		}
	}, [
		debouncedDepositAmount,
		depositCurrencyCode,
		isLoading,
		paymentMethodId,
		receiveCurrencyCode,
		wallet,
	]);

	if (isLoading) return <Loader className={styles.loader} />;
	const { maxAmount, minAmount } = paymentMethodDetails!;
	return (
		<div data-cy={TestIds.ewalletDepositsDetailsView}>
			<Seo title={depositMessages.metaEWalletDepositsTitle} />
			<PageTitle
				title={formatMessage(messages.depositWith, {
					provider: paymentMethodDetails?.title,
				})}
				previousPageLink={`/${locale}/deposit/e-wallet`}
				isPreviousPageLinkVisibleOnDesktop
				isPreviousPageLinkVisibleOnMobile={false}
			/>
			<TransfersFormLayout
				method={paymentMethodDetails}
				type={HowToType.DEPOSIT}
				title={paymentMethodDetails?.title}
				providerType={paymentMethodDetails?.providerType}
			>
				<div className={styles.form}>
					{errorMessage && (
						<NotificationMessage
							withIcon
							type={NotificationType.Error}
							style={NotificationStyle.Border}
							message={errorMessage}
						/>
					)}
					<Select
						className={styles.depositWalletSelect}
						labelClassName={inputStyles.label}
						id="wallet"
						label={formatMessage(messages.depositWallet)}
						name="wallet"
						onChangeObject={handleSelectChange}
						options={walletOptions}
						value={wallet || ''}
					/>
					<CurrencyInput
						id="depositAmount"
						labelClassName={inputStyles.label}
						inputContainerClassName={styles.currencyInputContainer}
						currencyCode={CurrencyEnum[depositCurrencyCode!]}
						label={messages.depositAmount}
						onChange={handleInputChange}
						value={depositAmount!}
						error={formErrorState.depositAmount}
					/>
					<div data-cy={TestIds.receiveAmount} className={styles.receiveAmount}>
						<div className={inputStyles.label}>
							<FormattedMessage {...baseMsg.receiveAmount} />
						</div>
						<div className={styles.value} data-cy={TestIds.receiveAmountValue}>
							{amountFormatter(
								formatPrecision(toPlainAmount(receiveAmount), CurrencyEnum.EUR)
							)}{' '}
							{receiveCurrencyCode}
						</div>
						{formErrorState.receiveAmount &&
							typeof formErrorState.receiveAmount === 'string' && (
								<div
									className={classNames(inputStyles.error, styles.receiveError)}
									data-cy={TestIds.receiveAmountError}
								>
									{formErrorState.receiveAmount}
								</div>
							)}
					</div>
					<Button
						buttonStyle={ButtonStyle.PRIMARY}
						className={styles.button}
						isDisabled={isButtonDisabled || !depositAmount}
						type={ButtonType.BUTTON}
						onClick={handleButtonClick}
						text={formatMessage(messages.next)}
						data-cy={TestIds.button}
					/>
				</div>
			</TransfersFormLayout>
		</div>
	);
};

export default EWalletDepositDetails;
