import DepositsTestIds from './Deposits/DepositsTestIds';
import MerchantTestIds from './Merchants/MerchantTestIds';
import WalletTestIds from './Wallets/WalletTestIds';

const TestIds = {
	CopyButton: 'copyButton',
	Toggle: 'Toggle',
	Title: 'title',
	Loader: 'Loader',
	Close: 'Close',
	Select: 'Select',
	HistoryBlock: 'HistoryBlock',
	HistoryTable: 'HistoryTable',
	HistoryViewButton: 'history',
	HistoryTableLoading: 'HistoryTable.loading',
	HistoryTableEmpty: 'noTransactionFound',
	ConvertInput: 'ConvertInput',
	ConvertInputCurrency: 'ConvertInput.Currency',
	ConvertInputNumberFormat: 'ConvertInput.NumberFormat',
	AccountSelect: 'AccountSelect',
	AccountSelectSearch: 'AccountSelect.search',
	AccountSelectOptions: 'AccountSelect.Options',
	AccountSelectOption_0: 'AccountSelect.Options.0',
	AccountSelectValue: 'AccountSelect.Value',
	AccountSelectValue_0: 'AccountSelect.Value.0',
	MultiAccountSelect: 'MultiAccountSelect',
	MultiAccountSelectSearch: 'MultiAccountSelect.search',
	MultiAccountSelectOptions: 'MultiAccountSelect.Options',
	MultiAccountSelectOption_0: 'MultiAccountSelect.Option.0',
	MultiAccountSelectValue: 'MultiAccountSelect.Value',
	MultiAccountSelectValues: 'MultiAccountSelect.Values',
	MultiAccountSelectValue_0: 'MultiAccountSelect.Value.0',
	MultiAccountSelectValueInfo: 'MultiAccountSelect.Value.Info',
	EWalletCurrencySelect: 'eWalletCurrencySelect',
	BankDepositCurrencySelect: 'bankDepositCurrencySelect',
	CountrySelect: 'countrySelect',
	SelectInput: 'SelectInput.0',
	SelectOption: 'SelectOption.0',
	Input: 'Input.0',
	CurrencySelectValue: 'CurrencySelect.Value.0',
	WalletSelect: 'walletSelect',
	CurrencyInput: 'CurrencyInput.0',
	TagFilterTag_0: 'TagFilterTag.0',
	CurrencyCode: 'currencyCode',
	SearchBar: 'searchBar',
	SortByName: 'sortByName',
	SortByBalance: 'sortByBalance',
	InfoInputLabel: 'InfoInput.Label.0',
	InfoInputValue: 'InfoInput.Value.0',
	TwoFA: '2fa',
	TwoFAInput: '2fa.input',
	TwoFAConfirm: '2fa.confirm',
	CustomInputValue: 'customInput.value',
	CustomInputRef: 'customInput.ref',
	PropertyBlock: 'propertyBlock',
	PropertyBlockProperty: 'propertyBlock.property',
	PropertyBlockPropertyExpanded: 'propertyBlock.property.expanded',
	DatePicker: 'datePicker',
	DatePickerDay_0: 'datePicker.day.0',
	SelectOptions: 'select.options',
	SelectValue_0: 'select.value.0',
	Pagination: 'pagination',
	FirstPage: 'pagination.first',
	PrevPage: 'pagination.prev',
	NextPage: 'pagination.next',
	LastPage: 'pagination.last',
	Page_0: 'pagination.0',
	WithdrawSuccessContainer: 'withdraw.success',
	ShowCurrenciesBtn: 'wallet.sidebar.showMoreBtn',
	RatesBannerList: 'wallet.sidebar.ratesList',
	WalletList: 'wallet.list',
	WalletRefBalance: 'wallet.refBalance',
	WalletRatesText: 'wallet.rate',
	WalletIfEmptyBtn: 'wallet.ifEmptyBtn',
	WalletBalanceBtn: 'wallet.balanceBtn',
	WelcomeBlock: 'wallets.welcome',
	IBANBlock: 'wallets.iban',
	FilterSelect: 'transaction.filterSelect',
	ShowDetailsButton: 'ibanBlock.showDetails',
	CopyAllDetailsButton: 'ibanBlock.copyAllDetailsbutton',
	MoreDetails: 'ibanBlock.moreDetails',
	ConvertedAmount: 'balance.convertedAmount',
	listItemError: 'deposit.listItem.error',
	listItem: 'deposit.listItem.0',
	listItemCurrencies: 'deposit.listItem.currencies.0',
	currencyIcon_0: 'currency.icon.0',
	...MerchantTestIds,
	...DepositsTestIds,
	...WalletTestIds,
} as const;

export const formatTestId = (token: typeof TestIds[keyof typeof TestIds], insert?: string) =>
	token.replace('0', insert || 'default') as typeof TestIds[keyof typeof TestIds];

export default TestIds;
