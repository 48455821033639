import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import countries from 'i18n-iso-countries';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { AxiosResponse } from 'axios';
import queryString, { ParsedQuery } from 'query-string';
import ReactCountryFlag from 'react-country-flag';
import useValidation from '../../../../hooks/useValidation';
import { toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import { toPlainAmount } from '../../../../helpers/currencyAmountHelper/currencyAmountHelper';
import {
	getBankWithdrawCalcURL,
	getBankWithdrawCreateURL,
	getBankWithdrawEstimateFeeURL,
	getBankWithdrawValidateIbanURL,
} from '../../../../redux/endpoints';
import { RootState } from '../../../../redux/Store';
import {
	BankSuccessResponse,
	PaymentMethod,
	ProviderType,
	WithdrawType,
} from '../../../../redux/WithdrawState/WithdrawTypes';
import withdrawMessages from '../../../../redux/WithdrawState/WithdrawMessages';
import Button, { ButtonType } from '../../../../components/Button/Button';
import Textarea from '../../../../components/Textarea/Textarea';
import Select from '../../../../components/Select/Select';
import Input from '../../../../components/Input/Input';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import InfoInput from '../../../../components/InfoInput/InfoInput';
import WithdrawFormContainer, {
	WithdrawSteps,
} from '../../Shared/WithdrawFormContainer/WithdrawFormContainer';
import CustomInput from '../../Shared/CustomInput/CustomInput';
import styles from './BankWithdrawForm.module.scss';
import WithdrawConfirm from '../../Shared/WithdrawConfirm/WithdrawConfirm';
import WithdrawSuccess from '../../Shared/WithdrawSuccess/WithdrawSuccess';
import baseMsg from '../../../../messages/base.messages';
import AccountSelect from '../../Shared/AccountSelect/AccountSelect';
import Seo from '../../../../components/Seo/Seo';
import NotificationMessage, {
	NotificationStyle,
	NotificationType,
} from '../../../../components/NotificationMessage/NotificationMessage';
import { getErrorMessageOrDefault } from '../../../../helpers/errorMessageHelper/errorMessageHelper';
import useBankFormValidator from './useBankFormValidator';
import useDebounce from '../../../../hooks/useDebounce';
import FeeSetConstructor from '../../Shared/FeeSetConstructor/FeeSetConstructor';
import { UserType } from '../../../../redux/ProfileState/ProfileTypes';
import WithdrawTestIds from '../../../../test/Withdraw/WithdrawTestIds';
import { IBANStatus } from '../../../../redux/AccountsState/AccountsTypes';
import { toggleStartingBlock } from '../../../../redux/AppState/AppActions';
import TransfersFormLayout from '../../../../layout/TransfersFormLayout/TransfersFormLayout';
import { HowToType } from '../../../../layout/TransfersFormLayout/HowTo/HowTo';
import axiosInstance from '../../../../helpers/axiosInstance';

const messages = defineMessages({
	withdrawCountry: {
		id: 'bankWithdrawForm.withdrawCountry',
		defaultMessage: 'Withdrawal country',
	},
	accountPlaceholder: {
		id: 'bankWithdrawForm.accountPlaceholder',
		defaultMessage: 'IBAN/Account number',
	},
	receiverAccountPlaceholder: {
		id: 'bankWithdrawForm.receiverAccountPlaceholder',
		defaultMessage: 'Account number',
	},
	sepaInfo: {
		id: 'bankWithdrawForm.sepaInfo',
		defaultMessage: 'Withdraw from my IBAN {iban}',
	},
	totalAmount: {
		id: 'bankWithdrawForm.totalAmount',
		defaultMessage: 'Total withdrawal amount (fees included)',
	},
	detailsPlaceholder: {
		id: 'bankWithdrawForm.detailsPlaceholder',
		defaultMessage: 'Withdraw details',
	},
	receiverLabel: {
		id: 'bankWithdrawForm.receiverLabel',
		defaultMessage: 'Receiver (name and surname or company name)',
	},
	receiverPlaceholder: {
		id: 'bankWithdrawForm.receiverPlaceholder',
		defaultMessage: 'Name Surname or Company name',
	},
	receiverTooltipText: {
		id: 'bankWithdrawForm.receiverTooltipText',
		defaultMessage:
			'If you would like to send bank transfers to your friends, partners or customers, please claim a free personal IBAN at SpectroCoin',
	},
	somethingWentWrong: {
		id: 'bankWithdrawForm.somethingWentWrong',
		defaultMessage: 'Something went wrong. Please contact support',
	},
});

interface ValidatedIbanInfo {
	iban: string;
	bankName: string;
	bankBicCode: string;
	bankCountry: string;
	bankAddress: string;
}

interface FormProps {
	country: string;
	currency: string;
	account: string;
	amount: string;
	details: string;
	receiver: string;
	swift: string;
	address: string;
	sortCode: string;
	receiverBankName: string;
	receiverBankCountry: string;
	receiverAddress: string;
	receiverCountry: string;
	receiverProvince: string;
	receiverCity: string;
	receiverPostalCode: string;
}

type ErrorFieldType = MessageDescriptor | null | string;

interface FormErrorsProps {
	country: ErrorFieldType;
	currency: ErrorFieldType;
	account: ErrorFieldType;
	amount: ErrorFieldType;
	details: ErrorFieldType;
	receiver: ErrorFieldType;
	swift: ErrorFieldType;
	address: ErrorFieldType;
	sortCode: ErrorFieldType;
	receiverBankName: ErrorFieldType;
	receiverBankCountry: ErrorFieldType;
	receiverAddress: ErrorFieldType;
	receiverCountry: ErrorFieldType;
	receiverProvince: ErrorFieldType;
	receiverCity: ErrorFieldType;
	receiverPostalCode: ErrorFieldType;
}

interface OptionProps {
	label: JSX.Element;
	value: string;
}

const getCountryName = (value: string) =>
	countries.getName(value, 'en').replace(', Province of China', '');

const BankWithdrawForm = () => {
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const { locale, formatMessage } = useIntl();
	const { push } = useHistory();
	const { pathname, search } = useLocation();
	const parsedQuery: ParsedQuery<string> = queryString.parse(search);
	const { step } = parsedQuery;
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const [form, setForm] = useState<FormProps>({
		country: user?.country ? `${user?.country}/${getCountryName(user?.country)}` : '',
		currency: '',
		account: '',
		amount: '',
		details: '',
		receiver: `${user?.name} ${user?.surname}`,
		swift: '',
		address: '',
		sortCode: '',
		receiverBankName: '',
		receiverBankCountry: '',
		receiverAddress: '',
		receiverCountry: '',
		receiverProvince: '',
		receiverCity: '',
		receiverPostalCode: '',
	});
	const [formErrors, setFormErrors] = useState<FormErrorsProps>({
		country: null,
		currency: null,
		account: null,
		amount: null,
		details: null,
		receiver: null,
		swift: null,
		address: null,
		sortCode: null,
		receiverBankName: null,
		receiverBankCountry: null,
		receiverAddress: null,
		receiverCountry: null,
		receiverProvince: null,
		receiverCity: null,
		receiverPostalCode: null,
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [balance, setBalance] = useState<string>('0');
	const [totalAmount, setTotalAmount] = useState<string>('0');
	const [useAllValue, setUseAllValue] = useState<string>('0');
	const [successData, setSuccessData] = useState<BankSuccessResponse[]>([]);
	const [countryOptions, setCountryOptions] = useState<OptionProps[]>([]);
	const [currentMethod, setCurrentMethod] = useState<PaymentMethod | null>(null);
	const [generalError, setGeneralError] = useState<MessageDescriptor | null>(null);
	const [providerType, setProviderType] = useState<ProviderType>();
	const [validatedIbanInfo, setValidatedIbanInfo] = useState<ValidatedIbanInfo | null>(null);
	const { paymentMethods } = useSelector((state: RootState) => state.WithdrawState);
	const { bank } = paymentMethods;
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const ibanInfo = useMemo(
		() => wallets?.find(({ currencyCode }) => currencyCode === CurrencyEnum.EUR)?.ibanInfo,
		[wallets]
	);

	const debounceAmount = useDebounce(form.amount, 1000);
	const debounceAccount = useDebounce(form.account, 1000);

	const isIBANExist = ibanInfo?.status === IBANStatus.ACTIVE;
	const isSEPAWithIBAN =
		providerType === ProviderType.SEPA && isIBANExist && !!currentMethod?.isIBAN;
	const isSWIFTWithIBAN =
		providerType === ProviderType.WIRE && isIBANExist && !!currentMethod?.isIBAN;

	const validator = useBankFormValidator(
		form.currency,
		balance,
		providerType!,
		isIBANExist,
		currentMethod!
	);

	const [isValid, validationResult] = useValidation(
		{
			currency: form.currency,
			account: form.account,
			...(providerType &&
				[ProviderType.WIRE, ProviderType.LOCAL].includes(providerType) && {
					swift: form.swift,
				}),
			...(providerType &&
				[ProviderType.WIRE, ProviderType.LOCAL].includes(providerType) && {
					address: form.address,
				}),
			...(isSWIFTWithIBAN && {
				receiverBankName: form.receiverBankName,
				receiverBankCountry: form.receiverBankCountry,
				receiverAddress: form.receiverAddress,
				receiverCountry: form.receiverCountry,
				receiverProvince: form.receiverProvince,
				receiverCity: form.receiverCity,
				receiverPostalCode: form.receiverPostalCode,
			}),
			receiver: form.receiver,
			amount: toPlainAmount(form.amount, true),
			details: form.details,
			...(providerType === ProviderType.SEPA &&
				!isIBANExist &&
				!currentMethod?.isIBAN && { country: form.country }),
			...(providerType === ProviderType.UK_LOCAL && {
				sortCode: form.sortCode,
			}),
		},
		validator
	);

	const calc = useCallback(() => {
		setGeneralError(null);
		setIsLoading(true);
		if (!isSEPAWithIBAN && !isSWIFTWithIBAN) {
			return axiosInstance.post(getBankWithdrawCalcURL(), {
				paymentMethodId: id,
				currencyCode: form.currency.split('/')[0],
				receiveAmount: toPlainAmount(debounceAmount, true),
			});
		}

		return axiosInstance.post(getBankWithdrawEstimateFeeURL(), {
			paymentMethodId: id,
			receiveAmount: toPlainAmount(debounceAmount, true),
			accountId: form.currency.split('/')[2],
			beneficiaryAccountNumber: form.account,
		});
	}, [debounceAmount, form.currency, form.account, id, isSEPAWithIBAN, isSWIFTWithIBAN]);

	const getIsFormValid = useCallback(() => {
		setFormErrors({ ...formErrors, ...validationResult });
		return isValid;
	}, [formErrors, isValid, validationResult]);

	const onSuccess = (data: BankSuccessResponse) => setSuccessData([data]);

	const onSelectChange = (country: string, name: string) => {
		setFormErrors({
			...formErrors,
			[name]: null,
		});
		return setForm({
			...form,
			[name]: country,
		});
	};

	const onChange = useCallback(
		({ target: { value, name } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setFormErrors({
				...formErrors,
				[name]: null,
			});
			setForm({
				...form,
				[name]: value,
			});
		},
		[form, formErrors]
	);

	const postData = () => {
		const {
			details,
			account,
			amount,
			currency,
			country,
			receiver,
			swift,
			address,
			sortCode,
			receiverAddress,
			receiverCountry,
			receiverProvince,
			receiverCity,
			receiverPostalCode,
			receiverBankName,
			receiverBankCountry,
		} = form;
		return axiosInstance.post<BankSuccessResponse>(getBankWithdrawCreateURL(false), {
			accountId: currency.split('/')[2],
			...(providerType === ProviderType.SEPA &&
				!isIBANExist &&
				!currentMethod?.isIBAN && {
					country: country.split('/')[0],
				}),
			...(isSEPAWithIBAN || isSWIFTWithIBAN
				? { paymentDetails: details }
				: { other: details }),
			paymentMethodId: id,
			receiveAmount: toPlainAmount(amount, true),
			receiver,
			receiverAccount: account,
			...(providerType === ProviderType.UK_LOCAL && {
				sortCode,
			}),
			...(isSWIFTWithIBAN
				? {
						receiverBankName,
						receiverBankCountry: countries.alpha3ToAlpha2(
							receiverBankCountry.split('/')[0]
						),
						receiverBankAddress: address,
						receiverAddress,
						receiverCountry: countries.alpha3ToAlpha2(receiverCountry.split('/')[0]),
						receiverProvince,
						receiverCity,
						receiverPostalCode,
						swiftCode: swift,
				  }
				: {
						receiverAddress: address,
						swift,
				  }),
		});
	};

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!getIsFormValid()) {
			return null;
		}

		return calc()
			.then(() => push({ pathname, search: `?step=${WithdrawSteps.CONFIRM}` }))
			.catch(() => setGeneralError(messages.somethingWentWrong))
			.then(() => setIsLoading(false));
	};

	const validateIban = useCallback(async () => {
		setValidatedIbanInfo(null);
		setFormErrors((prevErrors) => ({
			...prevErrors,
			account: null,
		}));
		try {
			const { data } = await axiosInstance.post<ValidatedIbanInfo>(
				getBankWithdrawValidateIbanURL(),
				{
					paymentMethodId: id,
					iban: debounceAccount,
				}
			);
			if (isSWIFTWithIBAN) {
				setFormErrors((prevFormErrors) => ({
					...prevFormErrors,
					...(data.bankBicCode && { swift: null }),
					...(data.bankName && { receiverBankName: null }),
					...(data.bankCountry && { receiverBankCountry: null }),
					...(data.bankAddress && { address: null }),
				}));
				setForm((prevForm) => ({
					...prevForm,
					swift: data.bankBicCode || '',
					receiverBankName: data.bankName || '',
					receiverBankCountry: data.bankCountry
						? `${countries.alpha2ToAlpha3(data.bankCountry)}/${getCountryName(
								data.bankCountry
						  )}`
						: '',
					address: data.bankAddress || '',
				}));
			}
			if (isSEPAWithIBAN) {
				setValidatedIbanInfo(data);
			}
		} catch (error) {
			setFormErrors((prevErrors) => ({
				...prevErrors,
				account: formatMessage(getErrorMessageOrDefault(error)),
			}));
		}
	}, [isSEPAWithIBAN, isSWIFTWithIBAN, debounceAccount, id, formatMessage]);

	useEffect(() => {
		if ((isSEPAWithIBAN || isSWIFTWithIBAN) && debounceAccount) {
			validateIban();
		}
	}, [isSEPAWithIBAN, isSWIFTWithIBAN, debounceAccount, validateIban]);

	useEffect(() => {
		if (isSEPAWithIBAN || isSWIFTWithIBAN) {
			setForm((prevForm) => ({
				...prevForm,
				receiver: '',
			}));
		}
	}, [isSEPAWithIBAN, isSWIFTWithIBAN]);

	// set currentMethod
	useEffect(() => {
		if (bank?.data?.length > 0 && !currentMethod) {
			setCurrentMethod(bank?.data.filter((o: PaymentMethod) => o.id === id)[0]);
		}
	}, [wallets, form, bank?.data, id, currentMethod]);

	// set total amount
	useEffect(() => {
		if (currentMethod && form.currency && debounceAmount) {
			void calc()
				.then(({ data }: AxiosResponse<{ withdrawAmount: string }>) => {
					setTotalAmount(data.withdrawAmount);
				})
				.catch(() => setGeneralError(messages.somethingWentWrong))
				.then(() => setIsLoading(false));
		}
	}, [calc, currentMethod, form.currency, debounceAmount]);

	// set available balance
	useEffect(() => {
		if (form.currency) {
			setBalance(
				wallets?.find(({ id: walletId }) => walletId === form.currency.split('/')[2])
					?.availableBalance || '0'
			);
		}
	}, [form.currency, wallets]);

	// minus out fee from balance
	useEffect(() => {
		if (balance && currentMethod) {
			const feeObj = currentMethod.activePaymentMethodFees?.find(
				({ currencyType }) => currencyType === form.currency.split('/')[0]
			);
			let feeSet: { [key: string]: string | null };
			if (feeObj) {
				feeSet = FeeSetConstructor(feeObj.fee, feeObj.minFee, feeObj.maxFee);
			} else {
				feeSet = FeeSetConstructor(
					currentMethod?.fee,
					currentMethod.minFee,
					currentMethod.maxFee
				);
			}
			const { fee, minFee, maxFee } = feeSet;
			const feeAmount = toDecimal(balance)
				.times(fee || 0)
				.dividedBy(100);
			const feeForCalc =
				minFee && feeAmount.lessThan(toDecimal(minFee))
					? minFee
					: maxFee && feeAmount.greaterThan(toDecimal(maxFee))
					? maxFee
					: feeAmount || 0;
			const allAmount = toDecimal(balance).minus(feeForCalc);
			setUseAllValue(allAmount.greaterThan(0) ? allAmount.toFixed(2).toString() : '0');
		}
	}, [balance, currentMethod, form.currency]);

	// set provider type
	useEffect(() => {
		if (currentMethod) {
			setProviderType(currentMethod.providerType);
		}
	}, [currentMethod]);

	// reset state
	useEffect(() => {
		if (!step && user) {
			setIsLoading(false);
			setSuccessData([]);
			setForm({
				country: user?.country ? `${user?.country}/${getCountryName(user?.country)}` : '',
				currency: '',
				account: '',
				amount: '',
				details: '',
				receiver: `${user?.name} ${user?.surname}`,
				swift: '',
				address: '',
				sortCode: '',
				receiverBankName: '',
				receiverBankCountry: '',
				receiverAddress: '',
				receiverCountry: '',
				receiverProvince: '',
				receiverCity: '',
				receiverPostalCode: '',
			});
		}
		if (user && !(form.receiver || user?.country)) {
			setForm({
				...form,
				country: user?.country ? `${user?.country}/${getCountryName(user?.country)}` : '',
				receiver: `${user?.name} ${user?.surname}`,
			});
		}
		if (!form.amount) {
			setTotalAmount('0');
		}
	}, [form, step, user]);

	useEffect(() => {
		if (countryOptions.length === 0) {
			const countriesList = Object.keys(countries.getAlpha2Codes());
			setCountryOptions(
				countriesList.reduce((acc: OptionProps[], curr: string) => {
					const item = {
						value: `${countries.alpha2ToAlpha3(curr)}/${getCountryName(curr)}`,
						label: (
							<div className={styles.option}>
								<ReactCountryFlag
									svg
									style={{
										width: '20px',
										height: 'auto',
										transform: 'scaleY(1.3)',
										borderRadius: '50%',
									}}
									countryCode={curr}
									title={curr}
								/>
								<span>{getCountryName(curr)}</span>
							</div>
						),
					};
					acc.push(item);
					return acc;
				}, [])
			);
		}
	}, [countryOptions]);

	useEffect(() => {
		if (user?.type === UserType.COMPANY_REPRESENTATIVE || user?.companyName) {
			setForm({ ...form, receiver: user.companyName ?? `${user?.name} ${user?.surname}` });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.type, user?.companyName]);

	useEffect(() => {
		dispatch(toggleStartingBlock(false));

		return () => {
			dispatch(toggleStartingBlock(true));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const currencyEnumValue =
		CurrencyEnum[form.currency.split('/')[0] as keyof typeof CurrencyEnum];

	return (
		<>
			<Seo currency={currentMethod?.title} title={withdrawMessages.metaSingleCryptoTitle} />
			<WithdrawFormContainer
				walletId={form.currency.split('/')[2]}
				isLoaded={!!providerType}
				title={
					<PageTitle
						previousPageLink={
							step === WithdrawSteps.CONFIRM
								? `/${locale}/withdraw/bank/${id}?step=1`
								: `/${locale}/withdraw/bank`
						}
						isPreviousPageLinkVisibleOnDesktop
						isPreviousPageLinkVisibleOnMobile={false}
						title={currentMethod?.title || ''}
					/>
				}
				form={
					<TransfersFormLayout
						method={currentMethod}
						type={HowToType.WITHDRAW}
						title={currentMethod?.title}
						providerType={currentMethod?.providerType}
					>
						<form
							data-cy={WithdrawTestIds.bankWithdrawForm}
							onSubmit={handleSubmit}
							className={styles.form}
						>
							{providerType === ProviderType.SEPA &&
								!isIBANExist &&
								!currentMethod?.isIBAN && (
									<Select
										data-cy={WithdrawTestIds.bankWithdrawCountrySelect}
										label={messages.withdrawCountry}
										labelClassName={styles.label}
										options={countryOptions}
										value={form.country}
										onChange={onSelectChange}
										errorMessage={formErrors.country}
										id="country"
										name="country"
									/>
								)}
							<AccountSelect
								value={form.currency}
								data-cy={WithdrawTestIds.bankWithdrawCurrencySelect}
								label={withdrawMessages.withdrawalWallet}
								onChange={(currency: string) => {
									setFormErrors({
										...formErrors,
										currency: null,
									});
									setForm({
										...form,
										currency,
									});
								}}
								errorMessage={formErrors.currency}
								type={WithdrawType.BANK}
								paymentCurrencies={currentMethod?.paymentMethodCurrencies}
							/>
							{isSWIFTWithIBAN && (
								<Input
									labelClassName={styles.label}
									className={styles.input}
									value={form.receiver}
									label={messages.receiverLabel}
									onChangeEvent={onChange}
									data-cy={WithdrawTestIds.bankWithdrawReceiverInput}
									errorMessage={formErrors.receiver}
									placeholder={messages.receiverPlaceholder}
									id="receiver"
									name="receiver"
								/>
							)}
							{!isSEPAWithIBAN && (
								<Input
									labelClassName={styles.label}
									className={styles.input}
									value={form.account}
									data-cy={WithdrawTestIds.bankWithdrawAccountInput}
									label={
										isSWIFTWithIBAN
											? withdrawMessages.receiverAccountLabel
											: withdrawMessages.accountLabel
									}
									onChangeEvent={onChange}
									errorMessage={formErrors.account}
									placeholder={
										isSWIFTWithIBAN
											? messages.receiverAccountPlaceholder
											: messages.accountPlaceholder
									}
									id="account"
									name="account"
								/>
							)}
							{providerType &&
								[ProviderType.WIRE, ProviderType.LOCAL].includes(providerType) && (
									<>
										<Input
											labelClassName={styles.label}
											className={styles.input}
											value={form.swift}
											label={withdrawMessages.swiftLabel}
											onChangeEvent={onChange}
											errorMessage={formErrors.swift}
											data-cy={WithdrawTestIds.bankWithdrawSwiftInput}
											placeholder={withdrawMessages.swiftLabel}
											id="swift"
											name="swift"
										/>
										{isSWIFTWithIBAN && (
											<Input
												labelClassName={styles.label}
												className={styles.input}
												value={form.receiverBankName}
												data-cy={
													WithdrawTestIds.bankWithdrawReceiverBankNameInput
												}
												label={withdrawMessages.receiverBankNameLabel}
												onChangeEvent={onChange}
												errorMessage={formErrors.receiverBankName}
												placeholder={withdrawMessages.receiverBankNameLabel}
												id="receiverBankName"
												name="receiverBankName"
											/>
										)}
										{isSWIFTWithIBAN && (
											<Select
												data-cy={
													WithdrawTestIds.bankWithdrawReceiverBankCountrySelect
												}
												label={withdrawMessages.receiverBankCountryLabel}
												labelClassName={styles.label}
												options={countryOptions}
												value={form.receiverBankCountry}
												onChange={onSelectChange}
												errorMessage={formErrors.receiverBankCountry}
												id="receiverBankCountry"
												name="receiverBankCountry"
											/>
										)}
										<Input
											labelClassName={styles.label}
											className={styles.input}
											value={form.address}
											data-cy={WithdrawTestIds.bankWithdrawBankAccountInput}
											label={withdrawMessages.receiverBankAddressLabel}
											onChangeEvent={onChange}
											errorMessage={formErrors.address}
											placeholder={withdrawMessages.receiverBankAddressLabel}
											id="address"
											name="address"
										/>
										{isSWIFTWithIBAN && (
											<Input
												labelClassName={styles.label}
												className={styles.input}
												value={form.receiverAddress}
												data-cy={
													WithdrawTestIds.bankWithdrawreceiverAddressInput
												}
												label={withdrawMessages.receiverAddressLabel}
												onChangeEvent={onChange}
												errorMessage={formErrors.receiverAddress}
												placeholder={withdrawMessages.receiverAddressLabel}
												id="receiverAddress"
												name="receiverAddress"
											/>
										)}
										{isSWIFTWithIBAN && (
											<Select
												data-cy={
													WithdrawTestIds.bankWithdrawReceiverCountrySelect
												}
												label={withdrawMessages.receiverCountryLabel}
												labelClassName={styles.label}
												options={countryOptions}
												value={form.receiverCountry}
												onChange={onSelectChange}
												errorMessage={formErrors.receiverCountry}
												id="receiverCountry"
												name="receiverCountry"
											/>
										)}
										{isSWIFTWithIBAN && (
											<Input
												labelClassName={styles.label}
												className={styles.input}
												value={form.receiverProvince}
												data-cy={
													WithdrawTestIds.bankWithdrawReceiverProvinceInput
												}
												label={withdrawMessages.receiverProvinceLabel}
												onChangeEvent={onChange}
												errorMessage={formErrors.receiverProvince}
												placeholder={withdrawMessages.receiverProvinceLabel}
												id="receiverProvince"
												name="receiverProvince"
											/>
										)}
										{isSWIFTWithIBAN && (
											<Input
												labelClassName={styles.label}
												className={styles.input}
												value={form.receiverCity}
												data-cy={
													WithdrawTestIds.bankWithdrawReceiverCityInput
												}
												label={withdrawMessages.receiverCityLabel}
												onChangeEvent={onChange}
												errorMessage={formErrors.receiverCity}
												placeholder={withdrawMessages.receiverCityLabel}
												id="receiverCity"
												name="receiverCity"
											/>
										)}
										{isSWIFTWithIBAN && (
											<Input
												labelClassName={styles.label}
												className={styles.input}
												value={form.receiverPostalCode}
												data-cy={
													WithdrawTestIds.bankWithdrawReceiverPostCodeInput
												}
												label={withdrawMessages.receiverPostCodeLabel}
												onChangeEvent={onChange}
												errorMessage={formErrors.receiverPostalCode}
												placeholder={withdrawMessages.receiverPostCodeLabel}
												id="receiverPostalCode"
												name="receiverPostalCode"
											/>
										)}
									</>
								)}
							{!isSEPAWithIBAN && !isSWIFTWithIBAN && (
								<Input
									labelClassName={styles.label}
									className={styles.input}
									value={form.receiver}
									label={messages.receiverLabel}
									onChangeEvent={onChange}
									data-cy={WithdrawTestIds.bankWithdrawReceiverInput}
									errorMessage={formErrors.receiver}
									placeholder={messages.receiverPlaceholder}
									id="receiver"
									name="receiver"
									readOnly
									tooltipText={messages.receiverTooltipText}
								/>
							)}
							{isSEPAWithIBAN && (
								<p className={styles.infoBox}>
									{formatMessage(messages.sepaInfo, { iban: ibanInfo?.iban })}
								</p>
							)}
							{isSEPAWithIBAN && (
								<Input
									labelClassName={styles.label}
									className={styles.input}
									value={form.receiver}
									label={withdrawMessages.receiverNameOrCompanyNameLabel}
									onChangeEvent={onChange}
									data-cy={WithdrawTestIds.bankWithdrawReceiverInput}
									errorMessage={formErrors.receiver}
									placeholder={messages.receiverPlaceholder}
									id="receiver"
									name="receiver"
								/>
							)}
							{isSEPAWithIBAN && (
								<Input
									labelClassName={styles.label}
									className={styles.input}
									value={form.account}
									data-cy={WithdrawTestIds.bankWithdrawAccountInput}
									label={withdrawMessages.accountNumberIbanLabel}
									onChangeEvent={onChange}
									errorMessage={formErrors.account}
									placeholder={messages.accountPlaceholder}
									id="account"
									name="account"
								/>
							)}
							{isSEPAWithIBAN && validatedIbanInfo && (
								<p className={styles.infoBox}>{validatedIbanInfo.bankName}</p>
							)}
							{providerType === ProviderType.UK_LOCAL && (
								<Input
									labelClassName={styles.label}
									className={styles.input}
									value={form.sortCode}
									label={withdrawMessages.sortCodeLabel}
									onChangeEvent={onChange}
									data-cy={WithdrawTestIds.bankWithdrawSortCodeInput}
									errorMessage={formErrors.sortCode}
									placeholder={withdrawMessages.sortCodeLabel}
									id="sortCode"
									name="sortCode"
								/>
							)}
							<CustomInput
								value={form.amount}
								label={baseMsg.receiveAmount}
								currency={currencyEnumValue}
								onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event)}
								id="amount"
								data-cy={WithdrawTestIds.bankWithdrawAmountInput}
								errorMessage={formErrors.amount}
								isLoading={isLoading}
								maxValue={useAllValue}
							/>
							<InfoInput
								label={messages.totalAmount}
								balance={totalAmount}
								data-cy={WithdrawTestIds.bankWithdrawTotalAmountText}
								currency={currencyEnumValue}
							/>
							<Textarea
								value={form.details}
								label={withdrawMessages.details}
								labelClassName={styles.label}
								onChangeEvent={onChange}
								id="details"
								name="details"
								data-cy={WithdrawTestIds.bankWithdrawDescriptionText}
								errorMessage={formErrors.details}
								placeholder={messages.detailsPlaceholder}
							/>
							<Button
								type={ButtonType.SUBMIT}
								isDisabled={isLoading}
								data-cy={WithdrawTestIds.bankWithdrawSubmitButton}
								isLoading={isLoading}
								className={styles.button}
								text={baseMsg.submit}
							/>
							{generalError && (
								<NotificationMessage
									withIcon
									type={NotificationType.Error}
									style={NotificationStyle.Border}
									message={
										typeof generalError === 'string' ? (
											generalError
										) : (
											<FormattedMessage {...generalError} />
										)
									}
								/>
							)}
						</form>
					</TransfersFormLayout>
				}
				confirm={
					<WithdrawConfirm
						type={WithdrawType.BANK}
						currency={currencyEnumValue}
						formData={[{ ...form, totalAmount }]}
						providerType={providerType}
						hasIban={isIBANExist && !!currentMethod?.isIBAN}
						onSuccess={onSuccess}
						onSubmit={postData}
					/>
				}
				done={
					<WithdrawSuccess
						data={successData}
						currency={currencyEnumValue}
						type={WithdrawType.BANK}
						provider={providerType}
						hasIban={isIBANExist && !!currentMethod?.isIBAN}
					/>
				}
				isDone={successData.length > 0}
			/>
		</>
	);
};

export default BankWithdrawForm;
