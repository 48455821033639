import Joi from 'joi';
import { useCallback, useDeferredValue } from 'react';
import { useIntl } from 'react-intl';
import {
	ErrorMessageCodesType,
	getErrorMessageOrDefault,
} from '../helpers/errorMessageHelper/errorMessageHelper';

type Result<T> = [boolean, Record<keyof T, string>];

export const validate = <T>(
	form: T,
	validator: Joi.AnySchema<T>,
	context?: Joi.Context
): Result<T> => {
	const { error } = validator.validate(form, { abortEarly: false, context });

	const errorObject =
		error?.details.reduce((acc: any, x: any) => {
			acc[`${x.path}`] = x.message;
			return acc;
		}, {}) || {};

	return [!error, errorObject as Record<keyof T, string>];
};

export const useApiErrorValidator = () => {
	const { formatMessage } = useIntl();
	return useCallback(
		(contextKey: string, errorCodes: Array<ErrorMessageCodesType>) =>
			Joi.when(`$${contextKey}`, {
				is: errorCodes,
				then: Joi.string()
					.custom((v, { prefs }) => {
						if (!prefs.context) return v;
						throw new Error();
					})
					.error((errors) => {
						const error = errors[0];
						error.message = formatMessage(
							getErrorMessageOrDefault(
								error.prefs.context && error.prefs.context[contextKey]
							)
						);
						return error;
					}),
			}),
		[formatMessage]
	);
};

const useValidation = <T>(
	form: T,
	validator: Joi.AnySchema<T>,
	context?: Joi.Context
): Result<T> => {
	return useDeferredValue(validate(form, validator, context));
};

export default useValidation;
