import React, { useState } from 'react';
import classNames from 'classnames';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import styles from './VoucherViews.module.scss';
import Input from '../../../../components/Input/Input';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import TestIds from '../../../../test/TestIds';

const messages = defineMessages({
	voucherCode: {
		id: 'base.voucher_code',
		defaultMessage: 'Voucher code',
	},
	useVoucher: {
		id: 'base.use_voucher',
		defaultMessage: 'Use Voucher',
	},
	voucherCodePlaceHolder: {
		id: 'base.voucherCodePlaceHolder',
		defaultMessage: '4c9Bdfa9',
	},
	submitting: {
		id: 'base.submitting',
		defaultMessage: 'Submitting...',
	},
	howToUseVoucherCodesTitle: {
		id: 'depositVoucher.how_to_use_voucher_codes_title',
		defaultMessage: 'How to use voucher codes',
	},
	howToUseVoucherCodesInstructions: {
		id: 'depositVoucher.how_to_use_voucher_codes_instructions',
		defaultMessage: `
			<li>Insert the voucher code and press “Use voucher”.</li>
			<li>The voucher amount will be deposited to your wallet instantly.</li>
			<instructions> With any questions, be sure to contact our customer support team. </instructions>
		`,
	},
	howToUseVoucherCodesNotice: {
		id: 'depositVoucher.how_to_use_voucher_codes_notice',
		defaultMessage: `Note: SpectroCoin voucher can be purchased <cta>here</cta>. If you have already purchased the voucher, the code can be found in the <a>withdraw history</a>.`,
	},
});

interface EnterVoucherCodeFormProps {
	isRequestPending: boolean;
	onSubmit: ({ voucherCode }: { voucherCode: string }) => void;
}

const EnterVoucherCodeForm = ({ onSubmit, isRequestPending }: EnterVoucherCodeFormProps) => {
	const { formatMessage, locale } = useIntl();
	const [voucherCode, setVoucherCode] = useState('');

	return (
		<div className={styles.container}>
			<div className={styles.column}>
				<div className={styles.centeredContainer}>
					<Input
						label={messages.voucherCode}
						inputGroupClassName={styles.inputGroup}
						placeholder={messages.voucherCodePlaceHolder}
						name="voucherCode"
						type="text"
						value={voucherCode}
						onChangeEvent={(event: React.ChangeEvent<HTMLInputElement>) =>
							setVoucherCode(event.target.value)
						}
						data-cy={TestIds.voucherCode}
					/>
					<Button
						type={ButtonType.BUTTON}
						className={classNames(ButtonStyle.PRIMARY, styles.button)}
						isDisabled={isRequestPending || !voucherCode}
						onClick={() => onSubmit({ voucherCode })}
						text={formatMessage(
							!isRequestPending ? messages.useVoucher : messages.submitting
						)}
						data-cy={TestIds.button}
					/>
				</div>
			</div>
			<div
				data-cy={TestIds.howToUseVoucherCodes}
				className={classNames(styles.column, styles.instructionsColumn)}
			>
				<h2 className={styles.heading}>
					<FormattedMessage {...messages.howToUseVoucherCodesTitle} />
				</h2>
				<ol className={styles.list}>
					<FormattedMessage
						{...messages.howToUseVoucherCodesInstructions}
						values={{
							li: (...text) => (
								<li key={text.toString()} className={styles.listItem}>
									{text}
								</li>
							),
							br: <br />,
							instructions: (text) => (
								<p className={styles.instructionsText}>{text}</p>
							),
						}}
					/>
				</ol>
				<div className={styles.paragraph}>
					<FormattedMessage
						{...messages.howToUseVoucherCodesNotice}
						values={{
							cta: (...content) => (
								<Button
									className={styles.link}
									type={ButtonType.LINK}
									text={content.toString()}
									link={`/${locale}/withdraw/vouchers`}
									buttonStyle={ButtonStyle.LINK}
									data-cy={TestIds.withdrawVoucherLink}
								/>
							),
							a: (...content) => (
								<Button
									className={styles.link}
									type={ButtonType.LINK}
									text={content.toString()}
									link={`/${locale}/withdraw/history/vouchers`}
									buttonStyle={ButtonStyle.LINK}
									data-cy={TestIds.withdrawHistory}
								/>
							),
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default EnterVoucherCodeForm;
