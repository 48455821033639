import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

import styles from './TextNode.module.scss';

export enum TextStyles {
	None = 0,
	BreakWord = 1 << 0,
	Ellipsis = 1 << 1,
	NoWrap = 1 << 2,
}

type TextNodeProps = {
	style?: TextStyles;
	className?: string;
};

const TextNode: FC<PropsWithChildren<TextNodeProps>> = ({
	className,
	style = TextStyles.None,
	children,
}) => {
	const styleFlags = Object.keys(TextStyles)
		.filter((key) => typeof TextStyles[key] === 'number')
		.filter((key) => (TextStyles[key] & style) === TextStyles[key])
		.map((key) => styles[key]);

	return <span className={classNames(className, ...styleFlags)}>{children}</span>;
};

export default TextNode;
