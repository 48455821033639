import { ActionCreator } from 'redux';
import { ProjectsEditActionType, ProjectsEditActionFulfilledAction } from './ProjectsEditTypes';

import { Project } from '../ProjectsTypes';

const setActionFulfilled: ActionCreator<ProjectsEditActionFulfilledAction> = (
	project: Project
) => ({
	type: ProjectsEditActionType.ACTION_FULFILLED,
	payload: project,
});

export default {
	setActionFulfilled,
};
