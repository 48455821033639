import PaymentButtonsCreateActions from './PaymentButtonsCreate/PaymentButtonsCreateActions';
import PaymentButtonsDataActions from './PaymentButtonsData/PaymentButtonsDataActions';
import PaymentButtonsEditActions from './PaymentButtonsEdit/PaymentButtonsEditActions';
import PaymentButtonsListActions from './PaymentButtonsList/PaymentButtonsListActions';

export default {
	create: PaymentButtonsCreateActions,
	data: PaymentButtonsDataActions,
	edit: PaymentButtonsEditActions,
	list: PaymentButtonsListActions,
};
