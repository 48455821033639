const MerchantTestIds = {
	// Shared
	DeleteConfirmation: 'merchants.deleteConfirmation',
	DeleteConfirmationYes: 'merchants.deleteConfirmation.yes',
	DeleteConfirmationNo: 'merchants.deleteConfirmation.no',
	FieldName: 'merchants.field.name',
	FieldAutoconvert: 'merchants.field.autoconvert',
	FieldAcceptedCurrencies: 'merchants.field.acceptedCurrencies',
	FieldMode: 'merchants.field.mode',
	FieldModeEnabled: 'merchants.field.mode.enabled',
	FieldModeDisabled: 'merchants.field.mode.disabled',
	FieldModeTest: 'merchants.field.mode.test',
	FieldModeVerifiedPayers: 'merchants.field.mode.verifiedPayers',
	FieldModePayerFee: 'merchants.field.mode.payerFee',
	Loading: 'merchants.loading',
	NotFound: 'merchants.notFound',
	MerchantTitle: 'merchants.title',
	TitleBackLink: 'merechants.title.backLink',
	// Projects
	ProjectForm: 'merchants.project.form',
	ProjectFormNameInput: 'merchants.project.form.nameInput',
	ProjectFormDescriptionField: 'merchants.project.form.description.field',
	ProjectFormDescriptionInput: 'merchants.project.form.description.input',
	ProjectFormAutoConvertField: 'merchants.project.form.autoconvert.field',
	ProjectFormAcceptCurrenciesField: 'merchants.project.form.acceptCurrencies.field',
	ProjectFormAcceptCurrenciesFieldInfo: 'merchants.project.form.acceptCurrencies.field.info',
	ProjectFormPublicKeyField: 'merchants.project.form.publicKey.field',
	ProjectFormPublicKeyTextarea: 'merchants.project.form.publicKey.textArea',
	ProjectFormPublicKeyGenerate: 'merchants.project.form.publicKey.generateButton',
	ProjectFormPrivateKeyModal: 'merchants.project.form.privateKey.modal',
	ProjectFormPrivateKeyModalClose: 'merchants.project.form.privateKey.modal.close',
	ProjectFormPrivateKeyModalTextarea: 'merchants.project.form.privateKeyModal.textarea',
	ProjectFormTest: 'merchants.project.form.testCheckbox',
	ProjectFormTest_0: 'merchants.project.form.testCheckbox.0',
	ProjectFormVerifiedPayers: 'merchants.project.form.verifiedPayers',
	ProjectFormPayerFee: 'merchants.project.form.payerFee',
	ProjectFormEnabled: 'merchants.project.form.enabledCheckbox',
	ProjectEditSubmit: 'merchants.project.edit.submit',
	ProjectEditError: 'merchants.project.edit.error',
	ProjectViewEdit: 'merchants.project.view.edit',
	ProjectViewCreateOrder: 'merchants.project.view.createOrder',
	ProjectViewCreatePublicOrder: 'merchants.project.view.createPublicOrder',
	ProjectViewMerchantId: 'merchants.project.view.merchantId',
	ProjectViewMerchantApiId: 'merchants.project.view.projectApiId',
	ProjectViewDescription: 'merchants.project.view.description',
	ProjectViewAutoconvert: 'merchants.project.view.autoconvert',
	ProjectviewChart: 'merchants.project.view.chart',
	ProjectviewChartAmount: 'merchants.project.view.chart.amount',
	ProjectviewChartRange: 'merchants.project.view.chart.range',
	ProjectviewChartRange_0: 'merchants.project.view.chart.range.0',
	ProjectviewChartCanvas: 'merchants.project.view.chart.canvas',
	ProjectViewAcceptedCurrencies: 'merchants.project.view.acceptedCurrencies',
	ProjectViewAcceptedCurrencies_0: 'merchants.project.view.acceptedCurrencies.0',
	ProjectViewPublicModal: 'merchants.project.view.publicModal',
	ProjectViewPublicModalClose: 'merchants.project.view.publicModal.close',
	ProjectViewPublicModalLink: 'merchants.project.view.publicModal.link',
	ProjectViewMode: 'merchants.project.view.mode',
	ProjectCreateSubmit: 'merchants.project.create.submit',
	ProjectCreateSuccess: 'merchants.project.create.success',
	ProjectCreateFailure: 'merchants.project.create.failure',
	ProjectRemoveError: 'merchants.project.removeError',
	ProjectListTable: 'merchants.project.list.table',
	ProjectListSearch: 'merchants.project.list.search',
	ProjectListRow: 'merchants.project.list.row',
	ProjectListProject_0: 'merchants.project.list.project.0',
	ProjectListRemove: 'merchants.project.list.remove',
	ProjectListRowDelete: 'merchants.project.list.row.delete',

	// Panel
	PanelSeeAll: 'merchants.panel.seeAll',
	PanelItem_0: 'merchants.panel.0',
	// Integration
	IntegrationApi: 'merchants.integration.api',
	IntegrationPlugins: 'merchants.integration.plugins',
	IntegrationLibraries: 'merchants.integration.libraries',
	IntegrationPaymentButtons: 'merchants.integration.paymentButtons',
	// Preorders
	PreOrderForm: 'merchants.preorder.form',
	PreOrderCreateSubmit: 'merchants.preorder.create.submit',
	PreOrderCreateError: 'DANGERNotification',
	PreOrderFormAutoconvertPriceField: 'merchants.preorder.form.autoconvertPrice.field',
	PreOrderFormAutoconvertPriceInput: 'merchants.preorder.form.autoconvertPrice.input',
	PreOrderFormAutoconvertPriceCurrency: 'merchants.preorder.form.autoconvertPrice.currency',
	PreOrderFormSelectPriceField: 'merchants.preorder.form.selectPrice.field',
	PreOrderFormSelectPriceNumberFormat: 'merchants.preorder.form.selectPrice.numberFormat',
	PreOrderFormSelectPriceCurrency: 'merchants.preorder.form.selectPrice.currency',
	PreOrderFormIdField: 'merchants.preorder.form.id.field',
	PreOrderFormDescriptionField: 'merchants.preorder.form.description.field',
	PreOrderFormCallbackField: 'merchants.preorder.form.callback.field',
	PreOrderFormSuccessField: 'merchants.preorder.form.success.field',
	PreOrderFormFailureField: 'merchants.preorder.form.failure.field',
	PreOrderFormEmailNotificationsInput: 'merchants.preorder.form.emailNotifications.input',
	PreOrderFormMultiplePaymentsInput: 'merchants.preorder.form.multiplePayments.input',
	PreOrderView: 'merchants.preorder.view',
	PreOrderViewId: 'merchants.preorder.view.id',
	PreOrderViewReceive: 'merchants.preorder.view.receive',
	PreOrderViewDate: 'merchants.preorder.view.date',
	PreOrderViewUrl: 'merchants.preorder.view.url',
	PreOrderViewMultiple: 'merchants.preorder.view.multiple',
	PreOrderViewViewButton: 'merchants.preorder.view.viewButton',
	PreOrderViewSendInvoice: 'merchants.preorder.view.sendInvoice',
	// Payment buttons
	PaymentButtonsHome: 'merchants.paymentButtons.home',
	PaymentButton_0: 'merchants.paymentButtons.paymentButton.0',
	PaymentButtonRemoveError: 'merchants.paymentButtons.paymentButton.removeError',
	PaymentButtonForm: 'merchants.paymentButtons.form',
	PaymentButtonFormName: 'merchants.paymentButtons.form.name',
	PaymentButtonFormDescriptionField: 'merchants.paymentButtons.form.description.field',
	PaymentButtonFormDescriptionInput: 'merchants.paymentButtons.form.description.input',
	PaymentButtonFormAutoConvertField: 'merchants.paymentButtons.form.autoconvert.field',
	PaymentButtonFormAcceptCurrenciesField: 'merchants.paymentButtons.form.acceptCurrencies.field',
	PaymentButtonFormFormAcceptCurrenciesFieldInfo:
		'merchants.paymentButtons.form.acceptCurrencies.field.info',
	PaymentButtonFormEnabled: 'merchants.paymentButtons.form.enabledCheckbox',
	PaymentButtonCreateSubmit: 'merchants.paymentButtons.create.submit',
	PaymentButtonCreateError: 'merchants.paymentButtons.create.error',
	PaymentButtonEditSave: 'merchants.paymentButtons.edit.save',
	PaymentButtonEditError: 'merchants.paymentButtons.edit.error',
	PaymentButtonListTable: 'merchants.paymentButtons.list.table',
	PaymentButtonListTableHeaderSearch: 'merchants.paymentButtons.list.table.header.search',
	PaymentButtonListRemove: 'merchants.paymentButtons.list.remove',
	PaymentButtonListRow: 'merchants.paymentButtons.list.row',
	PaymentButtonListRowDelete: 'merchants.paymentButtons.list.row.delete',
	PaymentButtonView: 'merchants.paymentButtons.view',
	PaymentButtonViewEdit: 'merchants.paymentButtons.view.edit',
	PaymentButtonViewPublicUrl: 'merchants.paymentButtons.view.publicUrl',
	PaymentButtonViewId: 'merchants.paymentButtons.view.id',
	PaymentButtonViewName: 'merchants.paymentButtons.view.name',
	PaymentButtonViewDescription: 'merchants.paymentButtons.view.description',
	PaymentButtonViewAutoconvert: 'merchants.paymentButtons.view.autoconvert',
	PaymentButtonViewAcceptedCurrencies: 'merchants.paymentButtons.view.acceptedCurrencies',
	PaymentButtonViewAcceptedCurrencies_0: 'merchants.paymentButtons.view.acceptedCurrencies.0',
	PaymentButtonViewMode: 'merchants.paymentButtons.view.mode',
	PaymentButtonStyle: 'merchants.paymentButtons.style',
	// Callbacks
	CallbackList: 'merchants.callback.list',
	CallbackListEmpty: 'merchants.callback.list.empty',
	CallbackListItem_0: 'merchants.callback.list.item.0',
	CallbackListItemId: 'merchants.callback.list.item.id',
	CallbackListItemOrderStatus: 'merchants.callback.list.item.orderStatus',
	CallbackListItemReceived: 'merchants.callback.list.item.received',
	CallbackListItemStatusCode: 'merchants.callback.list.item.statusCode',
	CallbackListItemDate: 'merchants.callback.list.item.date',
	CallbackListRetry: 'merchants.callback.list.retry',
	CallbackListRetryError: 'merchants.callback.list.retry.error',
	CallbackListError: 'merchants.callback.list.error',
	CallbackView: 'merchants.callback.view',
	CallbackViewId: 'merchants.callback.view.id',
	CallbackViewOrderStatus: 'merchants.callback.view.orderStatus',
	CallbackViewReceive: 'merchants.callback.view.receive',
	CallbackViewStatusCode: 'merchants.callback.view.statusCode',
	CallbackViewDate: 'merchants.callback.view.date',
	CallbackViewResponse: 'merchants.callback.view.response',
	// Orders
	OrderView: 'merchants.order.view',
	OrderViewId: 'merchants.order.view.id',
	OrderViewPayAmount: 'merchants.order.view.payAmount',
	OrderViewNetwork: 'merchants.order.view.network',
	OrderViewDepositAddress: 'merchants.order.view.depositAddress',
	OrderViewReceiveAmount: 'merchants.order.view.receiveAmount',
	OrderViewDescription: 'merchants.order.view.description',
	OrderViewStatus: 'merchants.order.view.status',
	OrderViewDate: 'merchants.order.view.date',
	OrderViewValidUntil: 'merchants.order.view.validUntil',
	OrderViewCallbackUrl: 'merchants.order.view.callbackUrl',
	OrderViewSuccessUrl: 'merchants.order.view.successUrl',
	OrderViewFailureUrl: 'merchants.order.view.failureUrl',
	OrderHistoryCategory_0: 'merchants.order.history.category.0',
	OrderHistoryItem_0: 'merchants.order.history.item.0',
	OrderHistoryItemLabel: 'merchants.order.history.item.label',
	OrderHistoryItemAmount: 'merchants.order.history.item.amount',
	OrderHistoryItemDetails: 'merchants.order.history.item.details',
	OrderHistoryItemStatus: 'merchants.order.history.item.status',
	// Transactions
	TransactionList: 'merchants.transaction.list',
	TransactionListEmpty: 'merchants.transaction.list.empty',
	TransactionListError: 'merchants.transaction.list.error',
	TransactionListItem_0: 'merchants.transaction.list.item.0',
	TransactionListItemId: 'merchants.transaction.list.item.id',
	TransactionListItemStatus: 'merchants.transaction.list.item.status',
	TransactionListItemDeposited: 'merchants.transaction.list.item.deposited',
	TransactionListItemDate: 'merchants.transaction.list.item.date',
	TransactionView: 'merchants.transaction.view',
	TransactionViewId: 'merchants.transaction.view.id',
	TransactionViewStatus: 'merchants.transaction.view.status',
	TransactionViewDeposited: 'merchants.transaction.view.deposited',
	TransactionViewAddress: 'merchants.transaction.view.address',
	TransactionViewDate: 'merchants.transaction.view.date',
	TransactionViewHash: 'merchants.transaction.view.hash',
	// Payments
	PayAmount: 'payments.payAmount',
	// Order
	PaymentsOrder: 'payments.order',
	PaymentsOrderPayError: 'payments.order.pay.error',
	PaymentsOrderPayErrorInvalidPayer: 'payments.order.pay.error.invalidPayer',
	PaymentsOrderWallet_0: 'payments.order.wallet.0',
	PaymentsOrderReceiver: 'payments.order.receiver',
	PaymentsOrderId: 'payments.order.id',
	PaymentsOrderDescription: 'payments.order.description',
	PaymentsOrderPay: 'payments.order.pay',
	// Donation
	PaymentsDonation: 'payments.donation',
	PaymentsDonationPayError: 'payments.donation.pay.error',
	PaymentsDonationWallet_0: 'payments.donation.wallet.0',
	PaymentsDonationReceiver: 'payments.donation.receiver',
	PaymentsDonationId: 'payments.donation.id',
	PaymentsDonationDescription: 'payments.donation.description',
	PaymentsDonationPay: 'payments.donation.pay',
	PaymentsDonationSuccess: 'payments.donation.success',
	PaymentsDonationSuccessClose: 'payments.donation.success.close',
} as const;

export default MerchantTestIds;
