import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import LimitDuration from '../../enums/LimitDuration';
import { Wallet } from '../AccountsState/AccountsTypes';

export type PaymentMethodStateGroup = 'ewallet' | 'bank' | 'cash' | 'card';

export enum DepositActionTypes {
	SET_VIRTUAL_ACCOUNTS = 'DEPOSITS/SET_VIRTUAL_ACCOUNTS',
	SET_LINKED_CARDS = 'DEPOSITS/SET_LINKED_CARDS',
	SET_LINKED_CARD_STATUS = 'DEPOSITS/SET_LINKED_CARD_STATUS',
	SET_USER_PROFILE = 'DEPOSITS/SET_USER_PROFILE',
	SET_COUNTRIES = 'DEPOSITS/SET_COUNTRIES',
	SET_PAYMENT_METHODS_BY_GROUP = 'DEPOSITS/SET_PAYMENT_METHODS_BY_GROUP',
	SET_IS_REMOVE_ENABLED = 'DEPOSITS/SET_IS_REMOVE_ENABLED',
	SET_VOUCHER = 'DEPOSITS/SET_VOUCHER',
	VOUCHER_ERROR = 'DEPOSITS/VOUCHER_ERROR',
	RESET_VOUCHER = 'DEPOSITS/RESET_VOUCHER',
	REQUIRE_ACCOUNT_SELECT = 'DEPOSITS/REQUIRE_ACCOUNT_SELECT',
	SET_ACCOUNT_ID = 'DEPOSITS/SET_ACCOUNT_ID',
	SET_VOUCHER_CODE = 'DEPOSITS/SET_VOUCHER_CODE',
	SET_DEPOSITS_HISTORY = 'DEPOSITS/SET_DEPOSITS_HISTORY',
}

export enum CardType {
	amex = 'amex',
	dankort = 'dankort',
	diners = 'diners',
	discover = 'discover',
	instapayment = 'instapayment',
	jcb = 'jcb',
	mastercard = 'mastercard',
	mir = 'mir',
	uatp = 'uatp',
	unionPay = 'unionPay',
	visa = 'visa',
	// maestro = 'maestro',
	// unknown = 'unknown',
	// other = 'other',
}
// amex mastercard visa diners discover jcb dankort instapayment uatp mir unionPay
export enum CardBrand {
	AMERICAN_EXPRESS = 'AMERICAN_EXPRESS',
	AMEX = 'AMEX',
	DELTA = 'DELTA',
	ELECTRON = 'ELECTRON',
	MAESTRO = 'MAESTRO',
	MASTERCARD = 'MASTERCARD',
	MASTERCARDDEBIT = 'MASTERCARDDEBIT',
	PURCHASING = 'PURCHASING',
	VISA = 'VISA',
	VPAY = 'VPAY',
	VISAELECTRON = 'VISAELECTRON',
}

export enum DepositType {
	CRYPTOCURRENCY = 'crypto',
	EWALLET = 'e-wallet',
	BANK = 'bank',
	CARD = 'card',
	CASH = 'cash',
	VOUCHER = 'voucher',
	FIAT = 'fiat',
}

export enum LinkedCardState {
	AVAILABLE = 'AVAILABLE',
	USER_VERIFICATION_NEEDED = 'USER_VERIFICATION_NEEDED',
	COMPANY_MOVEMENT_NEEDED = 'COMPANY_MOVEMENT_NEEDED',
	VERIFICATION_NEEDED = 'VERIFICATION_NEEDED',
	ACTIVATION_NEEDED = 'ACTIVATION_NEEDED',
	ACTIVATION_INIT_NEEDED = 'ACTIVATION_INIT_NEEDED',
	CONTACT_SUPPORT = 'CONTACT_SUPPORT',
	FAILED = 'FAILED',
}

export enum LinkedCardStatus {
	CREATED = 'CREATED',
	THREEDSECURE = 'THREEDSECURE',
	VERIFIED = 'VERIFIED',
	BLOCKED = 'BLOCKED',
	EXPIRED = 'EXPIRED',
	COUNTRY_NOT_MATCHED = 'COUNTRY_NOT_MATCHED',
	PENDING_FOR_APPROVAL = 'PENDING_FOR_APPROVAL',
	RISKY_CARD = 'RISKY_CARD',
	FAILED_CVV = 'FAILED_CVV',
	FAILED_3D_SECURE_CODE = 'FAILED_3D_SECURE_CODE',
	REQUIRES_VERIFICATION = 'REQUIRES_VERIFICATION',
}

export enum ProviderType {
	LOCAL = 'LOCAL',
	EGOPAY = 'EGOPAY',
	PERFECT_MONEY = 'PERFECT_MONEY',
	MAXIMA = 'MAXIMA',
	PERLAS = 'PERLAS',
	PAYPOST = 'PAYPOST',
	CASHU = 'CASHU',
	PAYEER = 'PAYEER',
	LT_SPAUDA = 'LT_SPAUDA',
	FOXBOX = 'FOXBOX',
	SEPA = 'SEPA',
	WIRE = 'WIRE',
	LT_PASTAS = 'LT_PASTAS',
	QIWI = 'QIWI',
	SKRILL = 'SKRILL',
	NETELLER = 'NETELLER',
	TRUST_PAY = 'TRUST_PAY',
	SPECTROCOIN_VOUCHER = 'SPECTROCOIN_VOUCHER',
	SPECTROCOIN_CRYPTO = 'SPECTROCOIN_CRYPTO',
	PAYBOX = 'PAYBOX',
	ADV_CASH = 'ADV_CASH',
	DECTA = 'DECTA',
	SIMPLEX = 'SIMPLEX',
	LINKED_CARD = 'LINKED_CARD',
	ECOMMPAY = 'ECOMMPAY',
}

export enum VoucherDepositView {
	ENTER_CODE = 'enterCode',
	SELECT_ACCOUNT = 'selectAccount',
	SUCCESS = 'success',
	ERROR = 'error',
}

export const erc20Currencies = [
	CurrencyEnum.WBTC,
	CurrencyEnum.LINK,
	CurrencyEnum.DAI,
	CurrencyEnum.BNB,
	CurrencyEnum.BUSD,
	CurrencyEnum.MASK,
	CurrencyEnum.SAND,
	CurrencyEnum.EURC,
	CurrencyEnum.UNI,
	CurrencyEnum.MKR,
	CurrencyEnum.AAVE,
	CurrencyEnum.COMP,
	CurrencyEnum.ZRX,
	CurrencyEnum.BAT,
	CurrencyEnum.SUSHI,
	CurrencyEnum.SHIB,
	CurrencyEnum.MATIC,
	CurrencyEnum.MANA,
	CurrencyEnum.UMA,
	CurrencyEnum.HOT,
	CurrencyEnum.CHZ,
	CurrencyEnum.GRT,
	CurrencyEnum.SNX,
	CurrencyEnum.ENJ,
	CurrencyEnum.USDT,
	CurrencyEnum.USDC,
	CurrencyEnum.USDP,
	CurrencyEnum.LINK,
	CurrencyEnum.TUSD,
];

export enum PaymentMethodGroup {
	BANK = 'BANK',
	CASH = 'CASH',
	CARD = 'CARD',
	EWALLET = 'EWALLET',
}

export interface Account {
	currencyCode: string;
	id: string;
	label: string;
	iban: string;
}

export interface VirtualAccounts {
	isLoading: boolean;
	isLoaded: boolean;
	isError: boolean;
	data: Account[];
}

export interface Countries {
	[countryCode: string]: string;
}

export interface LinkedCardStatusState {
	limitExceeded: boolean;
}

export interface PaymentMethodLimit {
	currencyCode: string;
	limitAmount: number;
	limitDuration: LimitDuration;
}

export interface PaymentMethodResponse {
	count: number;
	paymentMethods: PaymentMethod[];
}

export interface ActivePaymentMethodFee {
	currencyType: string;
	fee: number;
	maxFee: number;
	minFee: number;
}

export default interface PaymentMethod {
	activePaymentMethodFees: ActivePaymentMethodFee[];
	baseCurrencyCode: CurrencyEnum;
	companyMovementNeeded: boolean;
	companies: string | null;
	disabledCountries: string | string[] | null;
	enabledCountries: string | string[] | null;
	duration: string;
	fee: number;
	id: string;
	isIBAN: boolean;
	isEnabled: boolean;
	isEnabledForVerified: boolean;
	key: string;
	logo: string;
	maxAmount: number;
	maxFee: number;
	minAmount: number;
	minFee: number;
	paymentMethodCurrencies: string[];
	paymentMethodLimits: PaymentMethodLimit[];
	providerType: ProviderType;
	title: string;
	toCompany: string;
}

export interface UserProfile {
	beneficiaryName: string;
	companyName: string;
	country: string;
	verified: number;
}

export interface LinkedCard {
	cardBrand: CardBrand;
	cvvVerificationCount: number;
	currencyCode: string;
	expMonth: number;
	expYear: number;
	holderName: string; // includes First and Last name
	id: string;
	providerType: ProviderType; // This field can be mapped to Payment Method.
	status: LinkedCardStatus;
	userGuid: string; // This is actually user Auth Server ID
	verificationCurrencyId: number;
	visibleCardNumber: string; // 528560 **** 0326
	paymentMethodId: string;
}

export interface DepositState {
	countries: null | Countries;
	linkedCards: {
		isRemoveEnabled: boolean;
		isLoaded: boolean;
		status: {
			isLoaded: boolean;
			data: null | LinkedCardStatusState;
		};
		data: LinkedCard[];
		totalCount: number;
	};
	paymentMethods: {
		ewallet: {
			isLoaded: boolean;
			data: PaymentMethod[];
			totalCount: number;
		};
		bank: {
			isLoaded: boolean;
			data: PaymentMethod[];
			totalCount: number;
		};
		cash: {
			isLoaded: boolean;
			data: PaymentMethod[];
			totalCount: number;
		};
		card: {
			isLoaded: boolean;
			data: PaymentMethod[];
			totalCount: number;
		};
	};
	voucher: VoucherDepositDetailState;
}

export interface VoucherDepositDetailState {
	accountId: string | null;
	accountListForSelection: Wallet[];
	isRequestPending: boolean;
	view: VoucherDepositView;
	voucherCode: string | null;
	error: string | null;
	amount: string | null;
	isAccountSelectVisible: boolean;
}

export interface SetLinkedCards {
	type: typeof DepositActionTypes.SET_LINKED_CARDS;
	data: LinkedCard[];
}

export interface SetLinkedCardsStatus {
	type: typeof DepositActionTypes.SET_LINKED_CARD_STATUS;
	data: LinkedCardStatusState;
}

export interface SetUserProfile {
	type: typeof DepositActionTypes.SET_USER_PROFILE;
	data: UserProfile;
}

export interface SetCountries {
	type: typeof DepositActionTypes.SET_COUNTRIES;
	data: Countries;
}

export interface SetPaymentMethodsByGroup {
	type: typeof DepositActionTypes.SET_PAYMENT_METHODS_BY_GROUP;
	data: {
		paymentMethods: PaymentMethod[];
		count: number;
	};
	group: PaymentMethodGroup;
}

export interface SetIsRemoveEnabled {
	type: typeof DepositActionTypes.SET_IS_REMOVE_ENABLED;
	isRemoveEnabled: boolean;
}

export interface SetVoucher {
	type: typeof DepositActionTypes.SET_VOUCHER;
	accountId: string | null;
	amount: string;
}

export interface ResetVoucher {
	type: typeof DepositActionTypes.RESET_VOUCHER;
}

export interface RequireAccountSelect {
	type: typeof DepositActionTypes.REQUIRE_ACCOUNT_SELECT;
	accountListForSelection: Wallet[];
}

export interface SetAccountId {
	type: typeof DepositActionTypes.SET_ACCOUNT_ID;
	accountId: string;
}

export interface SetVoucherCode {
	type: typeof DepositActionTypes.SET_VOUCHER_CODE;
	voucherCode: string;
}

export interface SetVoucherError {
	type: typeof DepositActionTypes.VOUCHER_ERROR;
}
