import { FC } from 'react';
import { Link } from 'react-router-dom';
import useMerchantRoutes, { MerchantRoutes } from '../../../../hooks/useMerchantRoutes';
import { Project as MerchantProject } from '../../../../redux/MerchantsState/MerchantTypes';
import ProjectAction from '../ProjectAction/ProjectAction';
import ProjectAutoconvert from '../AutoconvertField/AutoconvertField';
import ProjectMode from '../Mode/Mode';
import NameField from '../NameField/NameField';

import styles from './Project.module.scss';
import TestIds, { formatTestId } from '../../../../test/TestIds';
import AcceptedCurrenciesField from '../AcceptedCurrenciesField/AcceptedCurrenciesField';

type ProjectProps = {
	project: MerchantProject;
};

const Project: FC<ProjectProps> = ({ project }) => {
	const { getUrl } = useMerchantRoutes();

	return (
		<Link
			data-cy={`${formatTestId(TestIds.ProjectListProject_0, project.id)}`}
			className={styles.project}
			to={getUrl(MerchantRoutes.ProjectView, { projectId: project.id })}
		>
			<NameField name={project.name} />
			<ProjectAutoconvert accountId={project.receiveAccountId} />
			<AcceptedCurrenciesField currencies={project.payCurrencies} />
			<ProjectMode status={project} />
			<ProjectAction project={project} />
		</Link>
	);
};

export default Project;
