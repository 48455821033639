import { defineMessages, useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import InfoHead from '../../../../components/InfoHead/InfoHead';
import newCardImgPath from './images/new_card.png';
import styles from './NewCard.module.scss';

const messages = defineMessages({
	title: {
		id: 'newCard.title',
		defaultMessage: 'Card was blocked successfully',
	},
	post: {
		id: 'newCard.post',
		defaultMessage: 'Would you like to order a new card?',
	},
	yes: {
		id: 'newCard.yes',
		defaultMessage: 'Yes',
	},
	no: {
		id: 'newCard.no',
		defaultMessage: 'No',
	},
});

const NewCard = () => {
	const { locale } = useIntl();
	const { url } = useRouteMatch();

	return (
		<WhiteContainer>
			<>
				<InfoHead title={messages.title} img={newCardImgPath} text={messages.post} />
				<Button
					text={messages.yes}
					className={styles.button}
					type={ButtonType.LINK}
					link={`/${locale}/cards/order`}
				/>
				<Button
					text={messages.no}
					className={styles.button}
					buttonStyle={ButtonStyle.BORDERLESS}
					type={ButtonType.LINK}
					link={url.replace('new-card', 'history')}
				/>
			</>
		</WhiteContainer>
	);
};

export default NewCard;
