import { ReactNode, useState, ChangeEvent } from 'react';
import classNames from 'classnames';
import generateUUID from '../../helpers/generateUUID/generateUUID';
import styles from './Checkbox.module.scss';

interface CheckboxProps {
	name?: string;
	checked: boolean;
	children?: ReactNode;
	className?: string;
	checkboxClassName?: string;
	labelClassName?: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	id?: string;
	isDisabled?: boolean;
	isRadio?: boolean;
}

const Checkbox = ({
	name,
	checked,
	children,
	className,
	labelClassName,
	checkboxClassName,
	onChange,
	id,
	isDisabled = false,
	isRadio = false,
	...props
}: CheckboxProps) => {
	const [inputId] = useState(id || generateUUID());

	return (
		<label
			htmlFor={inputId}
			className={classNames(styles.label, className, {
				[styles.disabled]: isDisabled,
				[styles.radio]: isRadio,
			})}
		>
			<input
				id={inputId}
				name={name}
				checked={checked}
				className={styles.input}
				onChange={onChange}
				disabled={isDisabled}
				data-cy={`${inputId}Checkbox`}
				type={isRadio ? 'radio' : 'checkbox'}
				{...props}
			/>
			<span className={classNames(styles.checkmark, checkboxClassName)} />
			{children && <span className={labelClassName}>{children}</span>}
		</label>
	);
};

export default Checkbox;
