import { ActionCreator } from 'redux';
import { ProjectsCreateActionType } from './ProjectsCreateTypes';
import { ProjectsCreateProjectCreatedAction, Project } from '../ProjectsTypes';

const projectCreated: ActionCreator<ProjectsCreateProjectCreatedAction> = (project: Project) => ({
	type: ProjectsCreateActionType.PROJECT_CREATED,
	payload: project,
});

export default {
	projectCreated,
};
