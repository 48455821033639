import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from './Block.module.scss';

const Block: FC<PropsWithChildren<{ className?: string }>> = ({ children, className, ...rest }) => {
	return (
		<div {...rest} className={classNames(styles.block, className)}>
			{children}
		</div>
	);
};

export default Block;
