import { NotificationDto } from '@spectrocoin/user-notifications';

export type NotificationsState = {
	notifications: NotificationDto[];
	count: number;
};

export enum NotificationsActionType {
	SET_UNREAD_NOTIFICATIONS = `NOTIFICATIONS/SET_UNREAD`,
	SET_UNREAD_NOTIFICATION_COUNT = 'NOTIFICATIONS/SET_UNREAD_COUNT',
}

export type NotificationsSetUnreadCountAction = {
	type: NotificationsActionType.SET_UNREAD_NOTIFICATION_COUNT;
	payload: {
		count: number;
	};
};

export type NotificationsSetUnreadAction = {
	type: NotificationsActionType.SET_UNREAD_NOTIFICATIONS;
	payload: {
		notifications: NotificationDto[];
	};
};
