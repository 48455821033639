import { defineMessages, useIntl } from 'react-intl';
import errorIBANIconPath from '../../../images/iban_icons/error.svg';
import Button, { ButtonType } from '../../../components/Button/Button';
import Seo from '../../../components/Seo/Seo';
import styles from './FailureIBAN.module.scss';

const messages = defineMessages({
	title: {
		id: 'claimIBAN.error',
		defaultMessage: 'Error occured',
	},
	failureMessage: {
		id: 'claimIBAN.failureMessage',
		defaultMessage: 'Something went wrong. Please try again later.',
	},
	back: {
		id: 'claimIBAN.backButton',
		defaultMessage: 'Back to wallets',
	},
	metaTitle: {
		id: 'claimIBAN.metaFailure',
		defaultMessage: 'IBAN Failure',
	},
});

const FailureIBAN = () => {
	const { formatMessage, locale } = useIntl();

	return (
		<div className={styles.container}>
			<Seo title={messages.metaTitle} />
			<h2 className={styles.title}>{formatMessage(messages.title)}</h2>
			<img src={errorIBANIconPath} alt="" />
			<p className={styles.paragraph}>{formatMessage(messages.failureMessage)}</p>
			<div className={styles.buttonContainer}>
				<Button text={messages.back} type={ButtonType.LINK} link={`/${locale}/account`} />
			</div>
		</div>
	);
};

export default FailureIBAN;
