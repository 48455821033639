import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import PageTitle from '../../../Merchants/Shared/PageTitle/PageTitle';
import ReferralsMessages from '../../../../redux/ReferralsState/ReferralsMessages';
import Button, { ButtonStyle, ButtonType } from '../../../../components/Button/Button';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import iconSvg from './paper_planes.svg';
import styles from './Tracking.module.scss';
import SetTrackingId from '../SetTrackingId/SetTrackingId';
import toggleModal from '../../../../redux/ModalState/ModalActions';
import useReferralRoutes, { ReferralRoutes } from '../../../../hooks/useReferralRoutes';
import { RootState } from '../../../../redux/Store';

const Tracking: FC = () => {
	const { getUrl } = useReferralRoutes();
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const onCustomize = useCallback(() => {
		dispatch(toggleModal(<SetTrackingId />));
	}, []);
	const { isMobile } = useSelector((state: RootState) => state.AppState);

	return (
		<WhiteContainer className={styles.body}>
			<div className={styles.left}>
				<div className={styles.headerContainer}>
					<div className={styles.text}>
						<PageTitle
							className={styles.title}
							title={formatMessage(ReferralsMessages.inviteFriendsWithTrackId)}
						/>
						<span>{formatMessage(ReferralsMessages.trackingInfo)}</span>
					</div>
					{isMobile && (
						<div className={styles.right}>
							<SvgIcon className={styles.icon} src={iconSvg} />
						</div>
					)}
				</div>
				<div className={styles.actions}>
					<Button
						className={styles.button}
						buttonStyle={ButtonStyle.TERTIARY}
						onClick={onCustomize}
					>
						{formatMessage(ReferralsMessages.customizeTrackId)}
					</Button>
					<Button
						className={styles.button}
						buttonStyle={ButtonStyle.SECONDARY_WITHOUT_BORDER}
						type={ButtonType.LINK}
						link={getUrl(ReferralRoutes.Callbacks)}
					>
						{formatMessage(ReferralsMessages.viewCallbacks)}
					</Button>
				</div>
			</div>
			{!isMobile && (
				<div className={styles.right}>
					<SvgIcon className={styles.icon} src={iconSvg} />
				</div>
			)}
		</WhiteContainer>
	);
};

export default Tracking;
