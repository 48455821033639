import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import statusMsg from '../../messages/status.messages';
import messages from '../../redux/MerchantsState/MerchantsMessages';
import { OrderStatus } from '../../redux/MerchantsState/MerchantTypes';
import { CurrencyType, getCurrencyType } from '../currencyHelper/currencyHelper';
import { OrderTransactionStatus } from '../../redux/MerchantsState/OrderTransactions/OrderTransactionsData/OrderTransactionsDataTypes';

export const OrderStatusToTranslationMap = {
	'': messages.orderStatusAll,
	[OrderStatus.EXPIRED]: statusMsg.EXPIRED,
	[OrderStatus.NEW]: statusMsg.NEW,
	[OrderStatus.FAILED]: statusMsg.FAILED,
	[OrderStatus.PAID]: statusMsg.PAID,
	[OrderStatus.PENDING]: statusMsg.PENDING,
	[OrderStatus.TEST]: statusMsg.TEST,
} as const;

export const useOrderStatusTranslator = () => {
	const { formatMessage } = useIntl();

	const translator = useCallback(
		(status: OrderStatus) => {
			const translation = OrderStatusToTranslationMap[status];
			return translation ? formatMessage(translation) : status;
		},
		[formatMessage]
	);

	return translator;
};

export const TransactionStatusToTranslationMap = {
	'': messages.orderStatusAll,
	[OrderTransactionStatus.PENDING]: messages.transactionStatusPending,
	[OrderTransactionStatus.CHARGED_BACK]: messages.transactionStatusChargedBack,
	[OrderTransactionStatus.CONFIRMED]: messages.transactionStatusConfirmed,
	[OrderTransactionStatus.FAILED]: messages.transactionStatusFailed,
} as const;

export const useTransactionStatusTranslator = () => {
	const { formatMessage } = useIntl();

	const translator = useCallback(
		(status: OrderTransactionStatus) => {
			const translation = TransactionStatusToTranslationMap[status];
			return translation ? formatMessage(translation) : status;
		},
		[formatMessage]
	);

	return translator;
};

const currencyTypeSortPriority = {
	[CurrencyType.Virtual]: 3,
	[CurrencyType.Fiat]: 2,
	[CurrencyType.None]: 1,
} as const;

const compareCurrencies = (
	priorityMap: Record<CurrencyEnum, number>,
	currencyA: CurrencyEnum,
	currencyB: CurrencyEnum
) => {
	return (
		(priorityMap[currencyB] || 0) - (priorityMap[currencyA] || 0) ||
		currencyTypeSortPriority[getCurrencyType(currencyB)] -
			currencyTypeSortPriority[getCurrencyType(currencyA)] ||
		currencyA.localeCompare(currencyB, undefined, {
			numeric: true,
			sensitivity: 'base',
		})
	);
};

const receiveCurrencyCodeSortPriority = {
	[CurrencyEnum.BTC]: 8,
	[CurrencyEnum.ETH]: 7,
	[CurrencyEnum.USDT]: 6,
	[CurrencyEnum.USDC]: 5,
	[CurrencyEnum.EUR]: 4,
	[CurrencyEnum.USD]: 3,
	[CurrencyEnum.GBP]: 2,
	[CurrencyEnum.XRP]: 1,
} as Record<CurrencyEnum, number>;

export const compareReceiveCurrencies = (currencyA: CurrencyEnum, currencyB: CurrencyEnum) => {
	return compareCurrencies(receiveCurrencyCodeSortPriority, currencyA, currencyB);
};

const payCurrencyCodeSortPriority = {
	[CurrencyEnum.BTC]: 4,
	[CurrencyEnum.ETH]: 3,
	[CurrencyEnum.USDT]: 2,
	[CurrencyEnum.USDC]: 1,
} as Record<CurrencyEnum, number>;

export const comparePayCurrencies = (currencyA: CurrencyEnum, currencyB: CurrencyEnum) => {
	return compareCurrencies(payCurrencyCodeSortPriority, currencyA, currencyB);
};
