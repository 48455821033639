import { FC } from 'react';
import { Route, Switch } from 'react-router';
import useMerchantRoutes, { MerchantRoutes } from '../../../hooks/useMerchantRoutes';
import NewMerchantsPreOrder from './NewMerchantsPreOrder/NewMerchantsPreOrder';
import ViewMerchantsPreOrder from './ViewMerchantsPreOrder/ViewMerchantsPreOrder';

const MerchantPreOrder: FC = () => {
	const { getPath } = useMerchantRoutes();

	return (
		<>
			<Route
				exact
				path={getPath(MerchantRoutes.PreOrderView)}
				component={ViewMerchantsPreOrder}
			/>
		</>
	);
};

const MerchantPreOrders: FC = () => {
	const { getPath } = useMerchantRoutes();

	return (
		<Switch>
			<Route
				exact
				path={getPath(MerchantRoutes.PreOrderCreate)}
				component={NewMerchantsPreOrder}
			/>
			<Route path={getPath(MerchantRoutes.PreOrderRoot)} component={MerchantPreOrder} />
		</Switch>
	);
};

export default MerchantPreOrders;
