import axiosInstance from '../../helpers/axiosInstance';
import { ConvertStatusType } from '../ConvertState/ConvertTypes';
import { getExchangeURL, getRecurringOrderURL } from '../endpoints';
import { AppDispatch } from '../Store';
import {
	ConvertHistoryActionType,
	OpenOrderType,
	SetHistoryDataAction,
} from './ConvertHistoryTypes';

const setIsLoading = (isLoading: boolean) => ({
	type: ConvertHistoryActionType.IS_LOADING,
	data: isLoading,
});

const changeOrderStatus = async (type: OpenOrderType, id: string) => {
	const response = await axiosInstance.post(`${getRecurringOrderURL()}/${id}/${type}`, {});
	return response.data;
};

export const resetConvertHistory = () => ({
	type: ConvertHistoryActionType.RESET_DATA,
});

export const getOpenOrderHistory = (page?: number, status?: ConvertStatusType) => (
	dispatch: AppDispatch
) => {
	dispatch(setIsLoading(true));
	void axiosInstance
		.get(getRecurringOrderURL(page, status))
		.then(({ data }) => {
			const { content, totalElements } = data;
			dispatch<SetHistoryDataAction>({
				type: ConvertHistoryActionType.SET_DATA,
				data: content.sort((a: any, b: any) => {
					if (new Date(a.date) > new Date(b.date)) return -1;
					return 1;
				}),
				totalCount: totalElements,
			});
		})
		.catch((e) => console.log(`${e} happened`))
		.then(() => dispatch(setIsLoading(false)));
};

export const updateOpenOrder = (type: OpenOrderType, id: string) => async (
	dispatch: AppDispatch
) => {
	await changeOrderStatus(type, id).then((data) => {
		dispatch({
			type:
				data.status === ConvertStatusType.CANCELLED
					? ConvertHistoryActionType.REMOVE_OPEN_ORDER
					: ConvertHistoryActionType.UPDATE_OPEN_ORDER,
			data,
		});
	});
};

export const getInstantHistory = (page?: number, dateFrom?: string, dateTo?: string) => (
	dispatch: AppDispatch
) => {
	dispatch(setIsLoading(true));
	void axiosInstance
		.get(getExchangeURL(page, dateFrom, dateTo))
		.then(({ data }) => {
			const { content, totalElements } = data;
			dispatch<SetHistoryDataAction>({
				type: ConvertHistoryActionType.SET_DATA,
				data: content.sort((a: any, b: any) => {
					if (new Date(a.created) > new Date(b.created)) return -1;
					return 1;
				}),
				totalCount: totalElements,
			});
		})
		.catch((e) => console.log(`${e} happened`))
		.then(() => dispatch(setIsLoading(false)));
};
