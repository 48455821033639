import { useQuery } from '@tanstack/react-query';
import { getPsProfileAccountsURL, getPsProfileURL } from '../endpoints';
import { throwLoaderError } from '../../helpers/loaderUtils';
import { UserCompany, UserType } from './ProfileTypes';
import axiosInstance from '../../helpers/axiosInstance';

enum PsProfileStatus {
	BANNED = 'BANNED',
	ACTIVE = 'ACTIVE',
}

type PsProfile = {
	username: string;
	firstname: string | null;
	lastname: string | null;
	dateOfBirth: string | null;
	country: string;
	type: UserType;
	companyName: string | null;
	companyCode: string | null;
	companyDateOfRegistration: string | null;
	companyRegistrationCountry: string | null;
	companyRegistrationAddress: string | null;
	contractCompany: UserCompany;
	phoneVerified: boolean;
	phone: string | null;
	status: PsProfileStatus;
	scaRequired: boolean;
	fullAccess: boolean;
};

export const psProfileQuery = {
	getKey: () => ['profile'],
	getFn: () => () =>
		axiosInstance
			.get<PsProfile>(getPsProfileURL())
			.then(({ data }) => data)
			.catch(throwLoaderError),
};

type QueryHookProps = {
	enabled?: boolean;
};

export const usePsProfileQuery = ({ enabled = true }: QueryHookProps) =>
	useQuery({ queryKey: psProfileQuery.getKey(), queryFn: psProfileQuery.getFn(), enabled });

export const psAvailableAccountsQuery = {
	getKey: () => ['profile.availableAccounts'],
	getFn: () => () =>
		axiosInstance
			.get<{ availableProfiles: PsProfile[] }>(getPsProfileAccountsURL())
			.then(({ data }) => data.availableProfiles)
			.catch(throwLoaderError),
};

export const usePsAvailableAccountsQuery = ({ enabled = true }: QueryHookProps) =>
	useQuery({
		queryKey: psAvailableAccountsQuery.getKey(),
		queryFn: psAvailableAccountsQuery.getFn(),
		enabled,
	});
