import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString, { ParsedQuery } from 'query-string';
import { entries, fromEntries } from '../helpers/objectHelper/objectHelper';

const useQueryParams = (params: Record<string, string | undefined>) => {
	const location = useLocation();
	const { push, replace } = useHistory();

	const values = useMemo<Array<string | undefined>>(() => {
		const parsedQuery: ParsedQuery<string> = queryString.parse(location.search);
		return entries(params).map(
			([key, defaultValue]) => (parsedQuery[key] as string) || defaultValue
		);
	}, [location.search, params]);

	const update = useCallback(
		(partial: Record<string, string | null>, useReplace: boolean = false) => {
			const parsedQuery: ParsedQuery<string> = queryString.parse(location.search);
			const updatedQuery = queryString.stringify(
				fromEntries(
					entries({
						...parsedQuery,
						...partial,
					}).filter(([_, value]) => value !== null)
				)
			);

			const updatedLocation = {
				pathname: location.pathname,
				search: `?${updatedQuery}`,
			};

			if (useReplace) replace(updatedLocation);
			else push(updatedLocation);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[location.pathname, location.search]
	);

	return [values, update] as const;
};

export default useQueryParams;
