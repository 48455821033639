import { FC } from 'react';
import TextNode, { TextStyles } from '../../../../components/TextNode/TextNode';
import TestIds from '../../../../test/TestIds';

import styles from './NameField.module.scss';

type NameFieldProps = {
	name: string;
};

const NameField: FC<NameFieldProps> = ({ name }) => {
	return (
		<div data-cy={TestIds.FieldName} className={styles.container}>
			<TextNode style={TextStyles.Ellipsis}>{name}</TextNode>
		</div>
	);
};

export default NameField;
