/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useCallback } from 'react';
import { Route, useRouteMatch, Redirect } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getIsFunctionalityAvailableURL, getClaimIBANURL } from '../../redux/endpoints';
import { RootState } from '../../redux/Store';
import { toggleWalletSidebar } from '../../redux/AppState/AppActions';
import { Wallet } from '../../redux/AccountsState/AccountsTypes';
import IsFunctionalityAvailableResponse from '../../interfaces/IsFunctionalityAvailable';
import IBANStatusEnum from '../../enums/IBANStatusEnum';
import FunctionalityAvailableType from '../../enums/FunctionalityAvailableEnum';
import UserContractCompanyType, {
	UserPreviousPageForCompanyMovement,
} from '../../enums/UserContractCompanyEnum';
import ClaimIBAN from '../../pages/Ibans/ClaimIBAN/ClaimIBAN';
import ProcessIBAN from '../../pages/Ibans/ProcessIBAN/ProcessIBAN';
import SuccessIBAN from '../../pages/Ibans/SuccessIBAN/SuccessIBAN';
import FailureIBAN from '../../pages/Ibans/FailureIBAN/FailureIBAN';
import ClaimedIBAN from '../../pages/Ibans/ClaimedIBAN/ClaimedIBAN';
import PhoneConfirmIBAN from '../../pages/Ibans/PhoneConfirmIBAN/PhoneConfirmIBAN';
import BackButton from '../../components/BackButton/BackButton';
import Loader from '../../components/Loader/Loader';
import styles from './IBANContainer.module.scss';
import { companyAndPreviousPageHandler } from '../../helpers/storageHelper/storageHelper';
import useEffectOnce from '../../hooks/useEffectOnce';
import config from '../../configs/config';
import { resolveUserCompany } from '../../helpers/companyHelper/companyHelper';
import axiosInstance from '../../helpers/axiosInstance';

const messages = defineMessages({
	back: {
		id: 'ibanContainer.back',
		defaultMessage: 'Back to EUR wallet',
	},
});

export interface IbanProcessStatusProps {
	approvalUrl: string | null;
	status: string;
	id: string;
	verificationUrl: string | null;
}

const IBANContainer = () => {
	const { url } = useRouteMatch();
	const dispatch = useDispatch();
	const { locale } = useIntl();
	const walletId = url.split('/')[5];
	const wallets = useSelector((state: RootState) => state?.AccountsState?.wallets);
	const { user } = useSelector((state: RootState) => state?.ProfileState);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [redirectURL, setRedirectURL] = useState<string | null>(null);
	const [IBANProcess, setIBANProcess] = useState<IbanProcessStatusProps | null>(null);
	const currentWallet = wallets?.find((o: Wallet) => o.id === walletId);

	const fetchIsFunctionalityAvailable = useCallback(() => {
		return new Promise<IsFunctionalityAvailableResponse>(
			(resolve: (value: IsFunctionalityAvailableResponse) => void) => {
				void axiosInstance
					.get(getIsFunctionalityAvailableURL(FunctionalityAvailableType.CLAIM_VIBAN))
					.then(({ data }: AxiosResponse<IsFunctionalityAvailableResponse[]>) => {
						return resolve(data[0]);
					});
			}
		);
	}, []);

	const checkIfIBANProcessExist = useCallback(() => {
		return new Promise<IbanProcessStatusProps | null>(
			(resolve: (value: IbanProcessStatusProps | null) => void) => {
				axiosInstance
					.get(getClaimIBANURL())
					.then(({ data }: AxiosResponse<IbanProcessStatusProps>) => {
						return resolve(data);
					})
					.catch(() => {
						return resolve(null);
					});
			}
		);
	}, []);

	useEffect(() => {
		if (user && currentWallet) {
			void fetchIsFunctionalityAvailable().then(
				(isFunctionalityAvailableData: IsFunctionalityAvailableResponse) => {
					const { available, data: featureData } = isFunctionalityAvailableData;
					// eslint-disable-next-line consistent-return
					void checkIfIBANProcessExist().then((data: IbanProcessStatusProps | null) => {
						setIBANProcess(data);
						setIsLoading(false);
						if (!available && featureData && featureData?.toCompany) {
							companyAndPreviousPageHandler({
								toCompany: UserContractCompanyType[featureData.toCompany],
								previousPage: UserPreviousPageForCompanyMovement.CLAIM_IBAN,
							});
							return window.location.assign(
								`${
									config.PROFILE_DOMAIN
								}/${locale}/verification/verify/${resolveUserCompany(
									featureData.toCompany
								)}`
							);
						}
						if (!data && user?.verified && user?.phoneVerified) {
							return setRedirectURL(`${url}/claim`);
						}
						if (data) {
							if (
								data.status === IBANStatusEnum.VERIFICATION_PENDING ||
								data.status === IBANStatusEnum.VERIFICATION_UPDATE_NEEDED
							) {
								return setRedirectURL(`${url}/process`);
							}
							if (data.status === IBANStatusEnum.VERIFICATION_SUBMITTED) {
								setRedirectURL(`${url}/process/success`);
							}
							if (data.status === IBANStatusEnum.CLAIMED) {
								setRedirectURL(`${url}/process/claimed`);
							}
							if (data.status === IBANStatusEnum.CLAIMED && currentWallet?.iban) {
								return setRedirectURL(`${locale}/account`);
							}
							if (data.status === IBANStatusEnum.VERIFICATION_SUCCESS) {
								return setRedirectURL(`${url}/process/phone-confirm`);
							}
						}
					});
				}
			);
		}
	}, [user, currentWallet, fetchIsFunctionalityAvailable, checkIfIBANProcessExist, url, locale]);

	useEffectOnce(() => {
		dispatch<any>(toggleWalletSidebar(false));
	});

	if (isLoading || !redirectURL)
		return (
			<div className={styles.loaderContainer}>
				<Loader className={styles.loader} />
			</div>
		);
	return (
		<>
			<BackButton
				link={`/${locale}/account/history/EUR/${walletId}`}
				text={messages.back}
				className={styles.iban}
			/>
			<Route exact path={`${url}/claim`} render={() => <ClaimIBAN process={IBANProcess} />} />
			<Route
				exact
				path={`${url}/process`}
				render={() => <ProcessIBAN process={IBANProcess} />}
			/>
			<Route exact path={`${url}/process/success`} render={() => <SuccessIBAN />} />
			<Route exact path={`${url}/process/failure`} render={() => <FailureIBAN />} />
			<Route exact path={`${url}/process/claimed`} render={() => <ClaimedIBAN />} />
			<Route
				exact
				path={`${url}/process/phone-confirm`}
				render={() => <PhoneConfirmIBAN />}
			/>
			<Redirect to={redirectURL} />
		</>
	);
};

export default IBANContainer;
