import { AxiosResponse } from 'axios';
import {
	PaymentMethodResponse,
	PaymentMethodGroup,
	Countries,
	DepositActionTypes,
	SetPaymentMethodsByGroup,
	SetCountries,
	LinkedCard,
	SetLinkedCards,
	SetLinkedCardsStatus,
	SetIsRemoveEnabled,
	RequireAccountSelect,
	SetVoucherError,
	SetVoucher,
	LinkedCardStatusState,
} from './DepositTypes';
import { AppDispatch } from '../Store';
import {
	getPaymentMethodsURL,
	getCountriesURL,
	getLinkedCardsURL,
	getLinkedCardStatusURL,
	depositVoucherURL,
} from '../endpoints';
import axiosInstance from '../../helpers/axiosInstance';

const getPaymentMethodsByGroup = async (group: PaymentMethodGroup) => {
	const response = await axiosInstance.get(`${getPaymentMethodsURL()}?group=${group}`, {
		withCredentials: true,
	});
	return response.data as PaymentMethodResponse;
};

export const fetchPaymentMethodsByGroup = (group: PaymentMethodGroup) => async (
	dispatch: AppDispatch
) => {
	await axiosInstance
		.get(`${getPaymentMethodsURL()}?group=${group}`, {
			withCredentials: true,
		})
		.then(({ data }: AxiosResponse<PaymentMethodResponse>) => {
			return dispatch<SetPaymentMethodsByGroup>({
				type: DepositActionTypes.SET_PAYMENT_METHODS_BY_GROUP,
				data: {
					paymentMethods: data?.paymentMethods,
					count: data.count,
				},
				group,
			});
		})
		.catch((e) => {
			console.log(`${e} occured`);
		});
};

export const fetchCountries = () => async (dispatch: AppDispatch) => {
	await axiosInstance
		.get(getCountriesURL(), { withCredentials: true })
		.then(({ data }: AxiosResponse<Countries>) => {
			return dispatch<SetCountries>({
				type: DepositActionTypes.SET_COUNTRIES,
				data,
			});
		})
		.catch((e) => {
			console.log(`${e} occured`);
		});
};

export const fetchLinkedCards = () => async (dispatch: AppDispatch) => {
	await axiosInstance
		.get(getLinkedCardsURL(), { withCredentials: true })
		.then(({ data }: AxiosResponse<LinkedCard[]>) => {
			return dispatch<SetLinkedCards>({
				type: DepositActionTypes.SET_LINKED_CARDS,
				data,
			});
		})
		.catch((e) => {
			console.log(`${e} occured`);
		});
};

export const fetchLinkedCardStatus = () => async (dispatch: AppDispatch) => {
	await axiosInstance
		.get(getLinkedCardStatusURL(), { withCredentials: true })
		.then(({ data }: AxiosResponse<LinkedCardStatusState>) => {
			return dispatch<SetLinkedCardsStatus>({
				type: DepositActionTypes.SET_LINKED_CARD_STATUS,
				data,
			});
		})
		.catch((e) => {
			console.log(`${e} occured`);
		});
};

export const fetchAllPaymentMethods = () => async (dispatch: AppDispatch) => {
	void Promise.all([
		getPaymentMethodsByGroup(PaymentMethodGroup.EWALLET).catch((e) => {
			console.log(`${e} occured fetching EWALLET payment method`);
		}),
		getPaymentMethodsByGroup(PaymentMethodGroup.BANK).catch((e) => {
			console.log(`${e} occured fetching BANK payment method`);
		}),
		getPaymentMethodsByGroup(PaymentMethodGroup.CASH).catch((e) => {
			console.log(`${e} occured fetching CASH payment method`);
		}),
		getPaymentMethodsByGroup(PaymentMethodGroup.CARD).catch((e) =>
			console.log(`${e} occured fetching CARD payment method`)
		),
	]).then(([ewallet, bank, cash, card]: any) => {
		if (ewallet) {
			dispatch<SetPaymentMethodsByGroup>({
				type: DepositActionTypes.SET_PAYMENT_METHODS_BY_GROUP,
				group: PaymentMethodGroup.EWALLET,
				data: ewallet,
			});
		}
		if (bank) {
			dispatch<SetPaymentMethodsByGroup>({
				type: DepositActionTypes.SET_PAYMENT_METHODS_BY_GROUP,
				group: PaymentMethodGroup.BANK,
				data: bank,
			});
		}
		if (cash) {
			dispatch<SetPaymentMethodsByGroup>({
				type: DepositActionTypes.SET_PAYMENT_METHODS_BY_GROUP,
				group: PaymentMethodGroup.CASH,
				data: cash,
			});
		}
		if (card) {
			dispatch<SetPaymentMethodsByGroup>({
				type: DepositActionTypes.SET_PAYMENT_METHODS_BY_GROUP,
				group: PaymentMethodGroup.CARD,
				data: card,
			});
		}
	});
};

export const setIsRemoveEnabled = (isRemoveEnabled: boolean, dispatch: AppDispatch) =>
	dispatch<SetIsRemoveEnabled>({
		type: DepositActionTypes.SET_IS_REMOVE_ENABLED,
		isRemoveEnabled: !isRemoveEnabled,
	});

export const depositVoucher = (accountId: string | null, voucherCode: string) => async (
	dispatch: AppDispatch
) => {
	await axiosInstance
		.post(depositVoucherURL(), {
			account: accountId !== null ? accountId : undefined,
			voucherCode,
		})
		.then(({ data }) => {
			if (data.message === 'SELECT_ACCOUNT') {
				dispatch<RequireAccountSelect>({
					type: DepositActionTypes.REQUIRE_ACCOUNT_SELECT,
					accountListForSelection: data.accounts,
				});
			} else {
				dispatch<SetVoucher>({
					type: DepositActionTypes.SET_VOUCHER,
					amount: data.voucherValue,
					accountId: data.accountGuid,
				});
			}
		})
		.catch(() => {
			dispatch<SetVoucherError>({
				type: DepositActionTypes.VOUCHER_ERROR,
			});
		});
};
