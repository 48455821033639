import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import queryString, { ParsedQuery } from 'query-string';
import { useLocation } from 'react-router';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import { fetchPaymentMethodsByGroup } from '../../../redux/DepositState/DepositActions';
import PaymentMethod, { PaymentMethodGroup } from '../../../redux/DepositState/DepositTypes';
import { RootState } from '../../../redux/Store';
import Loader from '../../../components/Loader/Loader';
import styles from './EWalletDeposits.module.scss';
import PageTitle from '../../../components/PageTitle/PageTitle';
import DepositList from '../Shared/DepositList/DepositList';
import depositMessages from '../../../redux/DepositState/DepositMessages';
import Seo from '../../../components/Seo/Seo';
import TestIds from '../../../test/TestIds';
import useEffectOnce from '../../../hooks/useEffectOnce';
import FilterSelect from '../../Withdraw/Shared/FilterSelect/FilterSelect';

const EWalletDeposits = () => {
	const { locale } = useIntl();
	const { search } = useLocation();
	const dispatch = useDispatch();
	const { user: profile } = useSelector((state: RootState) => state.ProfileState);
	const { paymentMethods } = useSelector((state: RootState) => state.DepositState);
	const { ewallet } = paymentMethods;

	const parsedQuery: ParsedQuery<any> = queryString.parse(search);
	const [currencyList, setCurrencyList] = useState<CurrencyEnum[]>([]);
	const [currency, setCurrency] = useState<string>(parsedQuery?.currency || '');

	const filteredPaymentMethods = useMemo(
		() =>
			paymentMethods.ewallet.data.filter((method: PaymentMethod) => {
				if (currency) {
					return (
						method.baseCurrencyCode === currency ||
						method.paymentMethodCurrencies.includes(currency)
					);
				}
				return method;
			}),
		[currency, paymentMethods.ewallet.data]
	);
	const isUserVerified = !!profile?.verified;

	useEffect(() => {
		setCurrencyList(
			ewallet?.data
				.flatMap(({ baseCurrencyCode, paymentMethodCurrencies }: any) => [
					baseCurrencyCode,
					...paymentMethodCurrencies,
				])
				.filter(
					(currencyCode: CurrencyEnum, index: number, self: CurrencyEnum[]) =>
						self.findIndex((val) => val === currencyCode) === index
				)
		);
	}, [ewallet]);

	useEffectOnce(() => {
		void dispatch(fetchPaymentMethodsByGroup(PaymentMethodGroup.EWALLET));
	});

	if (paymentMethods.ewallet.data.length === 0 || !profile)
		return <Loader className={styles.loader} />;
	return (
		<div data-cy={TestIds.ewalletDepositsView}>
			<Seo title={depositMessages.metaEWalletDepositsTitle} />
			<PageTitle
				historyLink={{
					pathname: `/${locale}/deposit/history/fiat`,
					state: { backUrl: window.location.pathname },
				}}
				title={depositMessages.eWalletDeposits}
				previousPageLink={`/${locale}/deposit`}
				isPreviousPageLinkVisibleOnMobile={false}
			/>
			<FilterSelect currencyList={currencyList} onChange={setCurrency} value={currency} />
			<DepositList
				group={PaymentMethodGroup.EWALLET}
				isUserVerified={isUserVerified}
				paymentMethods={filteredPaymentMethods}
			/>
		</div>
	);
};

export default EWalletDeposits;
