import { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import PaymentMethod, { PaymentMethodGroup } from '../../../../redux/DepositState/DepositTypes';
import { RootState } from '../../../../redux/Store';

import styles from './DepositList.module.scss';
import { companyAndPreviousPageHandler } from '../../../../helpers/storageHelper/storageHelper';
import { UserPreviousPageForCompanyMovement } from '../../../../enums/UserContractCompanyEnum';
import config from '../../../../configs/config';
import { resolveUserCompany } from '../../../../helpers/companyHelper/companyHelper';
import ListItem, { ListItemType } from '../../../../components/ListItem/ListItem';

const messages = defineMessages({
	additionalVerificationNeeded: {
		id: 'base.additionalVerificationNeeded',
		defaultMessage: 'Additional verification Needed',
	},
});

interface DepositListProps {
	group: PaymentMethodGroup;
	isUserVerified: boolean;
	paymentMethods: PaymentMethod[];
	unavailablePaymentMethods?: CashListItem[];
}

export interface CashListItem extends PaymentMethod {
	disabledMessage: string;
}

const DepositList = ({
	group,
	isUserVerified,
	paymentMethods,
	unavailablePaymentMethods = [],
}: DepositListProps) => {
	const { locale } = useIntl();
	const { wallets: accounts, isLoading: isWalletsLoading } = useSelector(
		(state: RootState) => state.AccountsState
	);
	const [filteredPaymentMethods, setFilteredPaymentMethods] = useState<PaymentMethod[]>([]);

	useEffect(() => {
		if (!isWalletsLoading && accounts) {
			const currencyCodes = Object.keys(accounts).reduce(
				(accumulator: string[], key: string) => {
					accumulator.push(accounts[key].currencyCode);
					return accumulator;
				},
				[]
			);

			const methods = paymentMethods.filter(
				(method: PaymentMethod) =>
					currencyCodes.includes(method.baseCurrencyCode) ||
					currencyCodes.some((currencyCode: string) =>
						method.paymentMethodCurrencies.includes(currencyCode)
					)
			);

			setFilteredPaymentMethods(methods);
		}
	}, [accounts, isWalletsLoading, paymentMethods]);

	const isPaymentMethodEnabled = (method: PaymentMethod) =>
		(method.isEnabled || (method.isEnabledForVerified && isUserVerified)) &&
		!method.companyMovementNeeded;

	const getListItemLink = (type: PaymentMethodGroup, id: string) => {
		switch (type) {
			case PaymentMethodGroup.BANK:
				return `/${locale}/deposit/bank/${id}`;
			case PaymentMethodGroup.CASH:
				return `/${locale}/deposit/cash/${id}`;
			case PaymentMethodGroup.EWALLET:
				return `/${locale}/deposit/e-wallet/${id}`;
			default:
				return '';
		}
	};

	// Set up KYC verification using their helper
	const handleUserCompanyChangeNeededMethodClick = (
		event: React.MouseEvent<HTMLDivElement>,
		toCompany: string
	) => {
		event.preventDefault();
		companyAndPreviousPageHandler({
			toCompany: toCompany || null,
			previousPage: UserPreviousPageForCompanyMovement.PAYMENT_METHOD,
		});
		window.location.href = `${
			config.PROFILE_DOMAIN
		}/${locale}/verification/verify/${resolveUserCompany(toCompany)}`;
	};

	const activePaymentMethods = filteredPaymentMethods.filter((method: PaymentMethod) =>
		isPaymentMethodEnabled(method)
	);

	const disabledPaymentMethods = filteredPaymentMethods.filter(
		(method: PaymentMethod) => !isPaymentMethodEnabled(method)
	);

	return (
		<div className={styles.container}>
			<ul className={styles.list}>
				{activePaymentMethods.map(
					({
						baseCurrencyCode,
						duration,
						fee,
						id,
						logo,
						maxAmount,
						maxFee,
						minAmount,
						minFee,
						paymentMethodCurrencies,
						title,
						isIBAN,
						providerType,
						key,
					}: PaymentMethod) => (
						<ListItem
							providerType={providerType}
							key={id}
							id={id}
							duration={duration}
							link={getListItemLink(group, id)}
							logo={logo}
							withDescription={group !== PaymentMethodGroup.CARD}
							paymentMethodCurrencies={paymentMethodCurrencies}
							title={title}
							limits={{
								baseCurrencyCode,
								fee,
								maxDepositAmount: maxAmount,
								maxFeeAmount: maxFee,
								minDepositAmount: minAmount,
								minFeeAmount: minFee,
							}}
							isIBAN={isIBAN}
							providerKey={key}
						/>
					)
				)}
				{group === PaymentMethodGroup.CASH &&
					unavailablePaymentMethods.map(
						({
							baseCurrencyCode,
							duration,
							fee,
							id,
							logo,
							maxAmount,
							maxFee,
							minAmount,
							minFee,
							paymentMethodCurrencies,
							title,
							isIBAN,
							providerType,
							key,
						}: CashListItem) => (
							<ListItem
								providerType={providerType}
								key={id}
								id={id}
								duration={duration}
								link={getListItemLink(group, id)}
								logo={logo}
								withDescription
								paymentMethodCurrencies={paymentMethodCurrencies}
								title={title}
								type={ListItemType.NON_CLICKABLE}
								limits={{
									baseCurrencyCode,
									fee,
									maxDepositAmount: maxAmount,
									maxFeeAmount: maxFee,
									minDepositAmount: minAmount,
									minFeeAmount: minFee,
								}}
								isIBAN={isIBAN}
								providerKey={key}
								disabled
								clickableWhenDisabled={false}
							/>
						)
					)}
			</ul>
			{disabledPaymentMethods.length > 0 && (
				<>
					<h2 className={styles.heading}>
						<FormattedMessage {...messages.additionalVerificationNeeded} />
					</h2>
					<ul className={styles.list}>
						{disabledPaymentMethods.map(
							({
								id,
								logo,
								paymentMethodCurrencies,
								title,
								toCompany,
								baseCurrencyCode,
								fee,
								maxAmount,
								maxFee,
								minAmount,
								minFee,
								providerType,
								isIBAN,
								key,
								duration,
							}: PaymentMethod) => (
								<ListItem
									disabled
									key={id}
									id={id}
									logo={logo}
									limits={{
										baseCurrencyCode,
										fee,
										maxDepositAmount: maxAmount,
										maxFeeAmount: maxFee,
										minDepositAmount: minAmount,
										minFeeAmount: minFee,
									}}
									paymentMethodCurrencies={paymentMethodCurrencies}
									clickableWhenDisabled
									title={title}
									duration={duration}
									withDescription={group !== PaymentMethodGroup.CARD}
									type={ListItemType.CLICK_HANDLER}
									onClick={(event: React.MouseEvent<HTMLDivElement>) => {
										handleUserCompanyChangeNeededMethodClick(event, toCompany);
									}}
									providerType={providerType}
									isIBAN={isIBAN}
									providerKey={key}
								/>
							)
						)}
					</ul>
				</>
			)}
		</div>
	);
};

export default DepositList;
