import { useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import { CurrencyIcon, CurrencyEnum } from '@spectrocoin/sc-currencies';
import { RootState } from '../../../../redux/Store';
import { Wallet } from '../../../../redux/AccountsState/AccountsTypes';
import { RatesDetailsResponse } from '../../../../redux/RatesState/RatesTypes';
import { getDebitCardLinkedAccountsURL } from '../../../../redux/endpoints';
import axiosInstance from '../../../../helpers/axiosInstance';
import { toDecimal, formatPrecision } from '../../../../helpers/currencyHelper/currencyHelper';
import { throwLoaderError } from '../../../../helpers/loaderUtils';
import useFormatAmount from '../../../../hooks/useFormatAmount';
import PageableResponse from '../../../../interfaces/PageableResponse';
import Loader from '../../../../components/Loader/Loader';
import styles from './LinkedWallets.module.scss';

const messages = defineMessages({
	linkedWallets: {
		id: 'linkedWalletsBlock.linkedWallets',
		defaultMessage: 'Linked wallets',
	},
});

interface LinkedAccounts {
	id: string;
	accountId: string;
	priorityIndex: number;
}

const linkedWalletsQuery = {
	getKey: (id: string) => ['debitCards.linkedAccounts', id],
	getFn: (id: string) => () =>
		axiosInstance
			.get<PageableResponse<LinkedAccounts[]>>(getDebitCardLinkedAccountsURL(id))
			.then(({ data }) => data.content)
			.catch(throwLoaderError),
};

const LinkedWalletItem = ({ wallet }: { wallet: Wallet }) => {
	const amountFormatter = useFormatAmount();
	const rates = useSelector((state: RootState) => state?.RatesState?.ratesDetails);
	const { referenceCurrency: refCurrency } = useSelector((state: RootState) => state.AppState);
	const { currencyCode, currencyName, availableBalance } = wallet;

	const refCurrencyRates = rates?.find(
		(o: RatesDetailsResponse) => o.currencyCode === refCurrency
	);
	const currentWalletRate = rates?.find(
		(o: RatesDetailsResponse) => o.currencyCode === currencyCode
	);

	return (
		<div className={styles.linkedWalletItemContainer}>
			<div className={styles.row}>
				<CurrencyIcon currencyType={currencyCode} />
				<div className={styles.currencyColumn}>
					<span className={styles.currencyCode}>{currencyCode}</span>
					<span className={styles.currencyName}>{currencyName}</span>
				</div>
			</div>
			<div className={styles.balanceColumn}>
				<span className={styles.currencyValue}>
					{`${amountFormatter(
						formatPrecision(availableBalance, CurrencyEnum[currencyCode])
					)} ${currencyCode}`}
				</span>
				<span className={styles.balanceCurrencyValue}>{`${
					currentWalletRate && refCurrencyRates
						? amountFormatter(
								formatPrecision(
									toDecimal(availableBalance)
										.times(toDecimal(currentWalletRate.value))
										.toFixed(refCurrencyRates.scale)
										.toString(),
									CurrencyEnum[refCurrency!]
								)
						  )
						: '--'
				} ${refCurrency}`}</span>
			</div>
		</div>
	);
};

interface LinkedWalletsProps {
	id: string;
}

const LinkedWallets = ({ id }: LinkedWalletsProps) => {
	const { formatMessage } = useIntl();
	const accounts = useSelector((state: RootState) => state?.AccountsState?.wallets);
	const { data, isFetching } = useQuery({
		queryKey: linkedWalletsQuery.getKey(id),
		queryFn: linkedWalletsQuery.getFn(id),
	});

	if (isFetching) {
		return <Loader />;
	}

	const linkedWallets =
		accounts?.filter((wallet) => data?.some((item) => item.accountId === wallet.id)) || [];

	return (
		<div className={styles.container}>
			<span className={styles.title}>{formatMessage(messages.linkedWallets)}</span>
			{linkedWallets?.map((wallet) => (
				<LinkedWalletItem key={wallet.id} wallet={wallet} />
			))}
		</div>
	);
};

export default LinkedWallets;
