/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages } from 'react-intl';
import { CurrencyEnum } from '@spectrocoin/sc-currencies';
import Select, { SelectStyleType } from '../Select/Select';
import { RootState } from '../../redux/Store';
import { getRatesDetails } from '../../redux/RatesState/RatesActions';
import { setReferenceCurrency } from '../../redux/AppState/AppActions';
import styles from './RefCurrencySelect.module.scss';
import WalletTestIds from '../../test/Wallets/WalletTestIds';

const messages = defineMessages({
	refCurrency: {
		id: 'manageWallets.refCurrency',
		defaultMessage: 'Reference currency:',
	},
});

interface RefCurrencySelectProps {
	containerClass?: string;
}

const RefCurrencySelect = ({ containerClass }: RefCurrencySelectProps) => {
	const dispatch = useDispatch();
	const { referenceCurrency } = useSelector((state: RootState) => state?.AppState);
	const [selectOptions] = useState(
		[CurrencyEnum.GBP, CurrencyEnum.EUR, CurrencyEnum.USD].map((x) => ({ value: x, label: x }))
	);

	useEffect(() => {
		if (referenceCurrency) dispatch<any>(getRatesDetails(referenceCurrency!.toString()));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [referenceCurrency]);

	return (
		<div className={containerClass}>
			<Select
				value={referenceCurrency}
				options={selectOptions}
				label={messages.refCurrency}
				labelClassName={styles.dropdownLabel}
				onChange={(value: string) => dispatch(setReferenceCurrency(value))}
				isSearchable={false}
				className={styles.select}
				data-cy={WalletTestIds.PorftolioSelect}
				styleType={SelectStyleType.INLINE}
			/>
		</div>
	);
};

export default RefCurrencySelect;
