import { useSelector } from 'react-redux';
import { CurrencyIcon, CurrencyEnum } from '@spectrocoin/sc-currencies';
import { formatPrecision, toDecimal } from '../../../../helpers/currencyHelper/currencyHelper';
import { AccountSelectComponentProps } from '../../../../components/AccountSelect/AccountSelect';
import { RootState } from '../../../../redux/Store';

import styles from './AccountSelectOption.module.scss';
import TestIds, { formatTestId } from '../../../../test/TestIds';
import useFormatAmount from '../../../../hooks/useFormatAmount';

const AccountSelectOption = ({
	innerProps,
	data: { currencyCode, currencyName: name, balance },
}: AccountSelectComponentProps<{ balance: string }>) => {
	const { rates } = useSelector((state: RootState) => state.RatesState);
	const { referenceCurrency = CurrencyEnum.EUR } = useSelector(
		(state: RootState) => state.AppState
	)!;
	const currentRate = rates?.find((rate) => rate.currencyCode === currencyCode)?.value;
	const amountFormatter = useFormatAmount();

	return (
		<div
			className={styles.option}
			{...innerProps}
			data-cy={formatTestId(TestIds.AccountSelectOption_0, currencyCode)}
		>
			<CurrencyIcon currencyType={currencyCode} className={styles.optionIcon} />
			<div className={styles.optionDetails}>
				<div className={styles.optionCurrency}>{`${name} (${currencyCode})`}</div>
				<div className={styles.optionBalance}>{`${amountFormatter(
					formatPrecision(balance, currencyCode as CurrencyEnum)
				)} ${currencyCode}`}</div>
				{currentRate && (
					<div className={styles.optionConverted}>{`${amountFormatter(
						formatPrecision(
							toDecimal(balance).div(currentRate).toString(),
							referenceCurrency as CurrencyEnum
						)
					)} ${referenceCurrency}`}</div>
				)}
			</div>
		</div>
	);
};

export default AccountSelectOption;
