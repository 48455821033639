import { ChangeEvent, FormEvent, useCallback } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import baseMsg from '../../messages/base.messages';
import { RemoteError, RemoteStatus } from '../../interfaces/RemoteData';
import { RootState } from '../../redux/Store';
import { resetTwoFaData, setTwoFaErrorCode } from '../../redux/TwoFaState/TwoFaActions';
import { TwoFaType, TwoFaCode } from '../../redux/TwoFaState/TwoFaTypes';
import Input from '../Input/Input';
import Button, { ButtonStyle, ButtonType } from '../Button/Button';
import styles from './TwoFa.module.scss';
import TestIds from '../../test/TestIds';
import useEffectOnce from '../../hooks/useEffectOnce';
import useTwoFaProcessor from '../../hooks/useTwoFaProcessor';

const messages = defineMessages({
	post: {
		id: 'twoFABox.post',
		defaultMessage:
			'You should receive your authentication code via text message. Enter the code to proceed further.',
	},
	cancel: {
		id: 'twoFABox.cancel',
		defaultMessage: 'Cancel',
	},
	label: {
		id: 'twoFABox.label',
		defaultMessage: 'Authentication code',
	},
	tooShort: {
		id: 'twoFABox.tooShort',
		defaultMessage: 'Code is too short',
	},
	resend: {
		id: 'twoFABox.resend',
		defaultMessage: 'Resend',
	},
	twoFAPushMeLabel: {
		id: 'twoFABox.twoFaTokenLabelPushMe',
		defaultMessage:
			'To access your Spectrocoin account, confirm your login with a second-factor authentication method.{br}{br}{br}Please check your smartphone for a push notification by the SpectroCoin app. Confirm your login on the app to proceed further.{br}{br}{br}Transaction ID: {tx_id}',
	},
	temporaryBanned: {
		id: 'twoFABox.termporaryBanned',
		defaultMessage: 'User temporary banned',
	},
	twoFAExpired: {
		id: 'twoFABox.twoFAExpired',
		defaultMessage: 'Some errors occurred: SpectroCoin app 2FA was cancelled or has expired.',
	},
	emailConfirm: {
		id: 'authConfirm.email',
		defaultMessage:
			'Enter 6-digit security code sent to {email} to confirm the transaction: {id}',
	},
	wrongCode: {
		id: 'authConfirm.wrongCode',
		defaultMessage: 'Wrong 2FA code',
	},
	smsConfirm: {
		id: 'authConfirm.smsAuth',
		defaultMessage: `Enter 6-digit security code from your {br}mobile phone to confirm the transaction: {id}`,
	},
	googleAuthConfirm: {
		id: 'authConfirm.googleAuthenticator',
		defaultMessage: `Enter 6-digit security code from your {br}Google Authenticator app to confirm the transaction: {id}`,
	},
	telegramConfirm: {
		id: 'authConfirm.telegram',
		defaultMessage: `Enter 6-digit security code from your {br}Telegram app to confirm the transaction: {id}`,
	},
	somethingWentWrong: {
		id: 'authConfirm.somethingWentWrong',
		defaultMessage: 'Something went wrong. Please try again later',
	},
	title: {
		id: 'twoFAAttemptsUsed.title',
		defaultMessage: 'You have used up all authentication attempts',
	},
	description: {
		id: 'twoFAAttemptsUsed.description',
		defaultMessage:
			'Please go back to the previous page and try to confirm your activity again.',
	},
	back: {
		id: 'cardsContainer.back',
		defaultMessage: 'Back',
	},
	requestSMS: {
		id: 'twoFABox.requestSMS',
		defaultMessage: 'Request an SMS code',
	},
});

interface TwoFABoxProps {
	onSuccess: (data: any) => void;
	onFailure: (error: RemoteError) => void;
	isLoading?: boolean;
	className?: string;
}

const TwoFa = ({ onSuccess, onFailure, isLoading, className }: TwoFABoxProps) => {
	const dispatch = useDispatch();
	const { errorCode, authType, txId, twoFaCode, requestData, expDate, email } = useSelector(
		(state: RootState) => state.TwoFaState
	);
	const { user } = useSelector((state: RootState) => state.ProfileState);
	const { formatMessage } = useIntl();

	const onErrorCodeChange = useCallback(
		(value: TwoFaCode) => dispatch(setTwoFaErrorCode(value)),
		[dispatch]
	);

	const onTwoFaFailure = useCallback(
		(error: AxiosError<RemoteError>) => onFailure(error.response?.data!),
		[onFailure]
	);

	const {
		code,
		onCodeChange,
		onSubmit,
		onFallback,
		inputError,
		actionStatus,
		isResubmitRequired,
		timeRemaining,
		isExpired,
	} = useTwoFaProcessor({
		config: { errorCode, authType, txId, twoFaCode, requestData, expDate, email },
		onSuccess,
		onFailure: onTwoFaFailure,
		onErrorCodeChange,
	});

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();
		return onSubmit();
	};

	const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
		onCodeChange(value);
	};

	useEffectOnce(() => {
		return () => dispatch<any>(resetTwoFaData());
	});

	if (isResubmitRequired) {
		return (
			<form className={classNames(styles.container, className)}>
				<p className={styles.description}>
					<FormattedMessage {...messages.description} />
				</p>
				<Button
					buttonStyle={ButtonStyle.PRIMARY}
					className={styles.button}
					text={formatMessage(messages.back)}
					type={ButtonType.SUBMIT}
					onClick={() => dispatch(resetTwoFaData())}
				/>
			</form>
		);
	}

	return (
		<form
			data-cy={TestIds.TwoFA}
			data-txid={txId}
			className={classNames(styles.container, className)}
			onSubmit={handleSubmit}
		>
			{authType === TwoFaType.DUO_PUSH || authType === TwoFaType.PUSH_ME ? (
				<>
					<p className={styles.post}>
						{formatMessage(messages.twoFAPushMeLabel, {
							br: <br />,
							tx_id: <span className={styles.blue}>{txId}</span>,
						})}
					</p>
					{isExpired ? (
						<Button
							className={classNames(styles.button, styles.small)}
							buttonStyle={ButtonStyle.BORDERLESS}
							text={messages.resend}
							type={ButtonType.BUTTON}
							onClick={() => onSubmit(true)}
						/>
					) : (
						<div className={styles.timer}>{timeRemaining}</div>
					)}
					<Button
						className={classNames(styles.button, styles.small)}
						buttonStyle={ButtonStyle.BORDERLESS}
						text={messages.requestSMS}
						type={ButtonType.BUTTON}
						onClick={() => {
							void onFallback(isExpired);
						}}
					/>
					{!isExpired && (
						<Button
							className={classNames(styles.button, styles.small)}
							buttonStyle={ButtonStyle.BORDERLESS}
							text={messages.cancel}
							type={ButtonType.BUTTON}
							onClick={() => {
								dispatch<any>(resetTwoFaData());
							}}
						/>
					)}
				</>
			) : (
				<>
					<p className={styles.post}>
						{authType === TwoFaType.EMAIL && (
							<FormattedMessage
								{...messages.emailConfirm}
								values={{ email: email || user?.email, id: txId }}
							/>
						)}
						{authType === TwoFaType.SMS && (
							<FormattedMessage
								{...messages.smsConfirm}
								values={{ id: txId, br: <br /> }}
							/>
						)}
						{authType === TwoFaType.GOOGLE_AUTH && (
							<FormattedMessage
								{...messages.googleAuthConfirm}
								values={{ id: txId, br: <br /> }}
							/>
						)}
						{authType === TwoFaType.TELEGRAM && (
							<FormattedMessage
								{...messages.telegramConfirm}
								values={{ id: txId, br: <br /> }}
							/>
						)}
					</p>
					<Input
						label={messages.label}
						value={code}
						onChangeEvent={handleChange}
						inputGroupClassName={styles.input}
						errorMessage={inputError}
						data-cy={TestIds.TwoFAInput}
					>
						{isExpired ? (
							<div className={styles.resend} onClick={() => onSubmit(true)}>
								{formatMessage(messages.resend)}
							</div>
						) : (
							<div className={styles.resend}>{timeRemaining}</div>
						)}
					</Input>
					<Button
						className={styles.button}
						buttonStyle={ButtonStyle.PRIMARY}
						text={baseMsg.confirm}
						type={ButtonType.SUBMIT}
						isDisabled={
							isLoading ||
							actionStatus === RemoteStatus.InProgress ||
							code.length !== 6
						}
						isLoading={isLoading || actionStatus === RemoteStatus.InProgress}
						data-cy={TestIds.TwoFAConfirm}
					/>
					<Button
						className={classNames(styles.button, {
							[styles.hidden]: isLoading || actionStatus === RemoteStatus.InProgress,
						})}
						buttonStyle={ButtonStyle.BORDERLESS}
						text={messages.cancel}
						type={ButtonType.BUTTON}
						onClick={() => dispatch(resetTwoFaData())}
						isDisabled={isLoading || actionStatus === RemoteStatus.InProgress}
						isLoading={isLoading || actionStatus === RemoteStatus.InProgress}
					/>
				</>
			)}
		</form>
	);
};

export default TwoFa;
