import { MessageDescriptor, useIntl } from 'react-intl';
import { FC, useState } from 'react';
import { CurrencyIcon, currencyUtils } from '@spectrocoin/sc-currencies';
import { useSelector } from 'react-redux';
import HistoryContext from '../../../contexts/HistoryContext/HistoryContext';
import PageTitle from '../../../components/PageTitle/PageTitle';
import Select from '../../../components/Select/Select';
import HistoryFilter from '../../../components/HistoryFilter/HistoryFilter';
import HistoryTable from '../../../components/HistoryTable/HistoryTable';
import useHistoryTableData, { HistoryType } from '../../../hooks/useHistoryTableData';
import depositMessages from '../../../redux/DepositState/DepositMessages';
import Seo from '../../../components/Seo/Seo';
import baseMsg from '../../../messages/base.messages';
import { Wallet } from '../../../redux/AccountsState/AccountsTypes';
import { RootState } from '../../../redux/Store';
import SelectOption from '../../../interfaces/SelectOption';
import useQueryParams from '../../../hooks/useQueryParams';
import styles from './DepositHistory.module.scss';

interface DepositHistoryProps {
	titleMessage: MessageDescriptor;
	historyType: HistoryType;
	previousPageLink?: string;
}

export enum DepositStatus {
	NEW = 'NEW',
	PENDING = 'PENDING',
	CONFIRMED = 'CONFIRMED',
	PAID = 'PAID',
	FAILED = 'FAILED',
	SUSPENDED_HIGH_RISK = 'SUSPENDED_HIGH_RISK',
	FROZEN = 'FROZEN',
	PENDING_AML_VERIFICATION = 'PENDING_AML_VERIFICATION',
	FROZEN_AML_VERIFICATION = 'FROZEN_AML_VERIFICATION',
	SUSPENDED_AML_VERIFICATION = 'SUSPENDED_AML_VERIFICATION',
	CHARGED_BACK = 'CHARGED_BACK',
}

const DepositHistoryContent: FC<DepositHistoryProps> = ({
	historyType,
	titleMessage,
	previousPageLink,
}) => {
	const history = useHistoryTableData();
	const { wallets } = useSelector((state: RootState) => state.AccountsState);
	const [[currencyCode], updateParams] = useQueryParams({
		currencyCode: undefined,
	});
	const { formatMessage } = useIntl();
	const [options] = useState<SelectOption[]>(() => [
		{ label: formatMessage(baseMsg.all), value: '' },
		...(wallets
			?.filter(
				({ currencyCode: code }: Wallet) =>
					currencyUtils.getConfigOrDefault(code).isVirtual &&
					!!currencyUtils.getConfigOrDefault(code).noAddress
			)
			.map(({ currencyCode: code }: Wallet) => {
				return {
					label: (
						<div className={styles.option}>
							<CurrencyIcon currencyType={code} className={styles.currencyIcon} />
							{code}
						</div>
					),
					value: code,
				};
			}, []) || []),
	]);

	const onSelectChange = (value: string) => updateParams({ currencyCode: value });

	return (
		<>
			<Seo title={depositMessages.metaDepositsHistoryTitle} />
			<PageTitle
				previousPageLink={previousPageLink}
				title={titleMessage}
				isExportEnabled
				historyType={historyType}
				historyCount={history.totalCount}
				isPreviousPageLinkVisibleOnDesktop
				isPreviousPageLinkVisibleOnMobile
			/>
			<div className={styles.container}>
				<HistoryFilter>
					{historyType === HistoryType.CRYPTO && options.length > 0 && (
						<Select
							label={baseMsg.filterByCurrency}
							labelClassName={styles.label}
							className={styles.select}
							options={options}
							value={currencyCode}
							clearable
							onChange={(value: string) => onSelectChange(value)}
						/>
					)}
				</HistoryFilter>
				<HistoryTable
					data={history.data}
					isLoading={history.isLoading}
					totalCount={history.totalCount}
					historyType={historyType}
				/>
			</div>
		</>
	);
};

const DepositHistory: FC<DepositHistoryProps> = (props) => {
	const [[currencyCode]] = useQueryParams({
		currencyCode: undefined,
	});

	return (
		<HistoryContext.Provider value={{ historyType: props.historyType, filter: currencyCode }}>
			<DepositHistoryContent {...props} />
		</HistoryContext.Provider>
	);
};

export default DepositHistory;
